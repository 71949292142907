import { Component, OnInit, Input, ViewChild, AfterViewInit, HostListener, ViewEncapsulation } from '@angular/core';
import { MapMarker } from '../../../modals/map/MapMarker';
import { MapMetadata } from '../../../modals/map/map-metadata';
import { HoardingLightEnum } from '../../constants/hoarding-light-enum';
import { DateUtil } from '../../../helpers/date.util';
import * as utils from '../../../helpers/utils';
import { GridPaginationEvent, GridColumn, Alignment, EditableGridFieldTypes, GridConfig } from '../../../components/sib-forms/grid/grid.config';
import { PaginationData } from '../../../modals/paginationdata';
import { SearchFilter } from '../photo-interface/photo-interface/photo-interface.component';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { AppUrls } from '../../../services/urls';
import { concat, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HoardingService } from '../../../services/shared/hoardings.service';
import { DataScroller, SelectItem } from 'primeng/primeng';
import { MapComponent } from '../map/map.component';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import { Subject, Observable } from 'rxjs';
import { HoardingStatusEnum } from '../../constants/hoarding-status-enum';
import { KioskService } from '../../../services/shared/kiosk.service';
import { HoardingGroupService } from '../../../services/shared/hoardingGroup.service';
import { HoardingGroup } from '../../../modals/hoardings/hoardings-group';
import { ContractHoardings } from '../../../modals/contracts/contract-hoarding';
import { Kiosk } from '../../../modals/hoardings/kiosk';
import { MediaSubCategoryEnum } from '../../constants/media-sub-category-enum';
import * as _ from 'lodash';
import { Plan } from '../../../modals/plans/plan';
import { PlanWrapper } from '../../../modals/plans/planWrapper';
import { HoardingFunctionalService } from '../../../hoardings/services/hoarding-functional.service';
import { SavePlanService } from '../../../services/shared/savePlan.service';
import { UpdatePlanService } from '../../../services/shared/updatePlan.service';
import { Router } from '@angular/router';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';

@Component({
  selector: 'sib-hoarding-map-view',
  templateUrl: './hoarding-map-view.component.html',
  styleUrls: ['./hoarding-map-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HoardingMapViewComponent implements OnInit, AfterViewInit {

  noImageUrl = 'https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89';
  // itemsToShow: any[] = [];
  mapMarker: MapMarker = new MapMarker();
  overlays: any[] = [];
  options: any;
  scrollHeight: string;
  isLoading = false;
  showMap = false;
  mapMetadata: MapMetadata = new MapMetadata();
  itemsPerPage = 50;
  pageSize = 50;
  firstIndex = 0;
  totalItemsCount: number;
  monthlyRateFrom = 0;
  monthlyRateTo = 500000;
  monthlyRateValues: number[] = [0, 500000];
  dscrollerPaginationEvent: GridPaginationEvent;
  // globalSearchData: Subject<any> = new Subject();
  globalSearchData: string;
  sitesFromDate: Date;

  viewSidebar = false;
  selectedInventories: any[] = []; // for sidebar menu
  selectedInventoryIds: string[] = [];
  hoardingItems: ContractHoardings[] = [];
  hoardingGroupItems: HoardingGroup[] = [];
  kioskItems: Kiosk[] = [];
  filteredInventories: any[] = []; // list of hoardings, hoarding groups and kiosks
  selectedInventoryType: any; // inventory type selected from dropdown
  lastInventoryType: any; // type of the last inventory selected
  inventoryTypeDropdown: SelectItem[] = [];
  inventoryStatusDropdown: SelectItem[] = [];
  selectedInventoryStatus: any; // inventory status selected from dropdown
  loading = false; // for sidebar menu data table
  dataTableColumns: any[] = [];
  checked = [];
  globalFilterData: Subject<any> = new Subject();
  globalFilterValue: any;
  onInlineScroll: any;
  displayPlanDialog = false;
  planWrapper: PlanWrapper = new PlanWrapper();
  currentHoardingPage: number;
  currentHoardingGroupPage: number;
  currentKioskPage: number;
  inventoryTypeChanged = false;

  mapFromHoarding: boolean = false;
  planCreationMap: boolean = true;
  page: number;
  searchedWithGlobalFilter: boolean = false;
  removeMarker: any;


  @ViewChild('dscroller') dscroller: DataScroller;
  @ViewChild(MapComponent) mapComponent: MapComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScrollHeightAndImagesPerPage();
  }

  @HostListener('scroll', ['$event'])
  onscroll($event) {
    this.setScrollHeightAndImagesPerPage();
  }

  constructor(
    private hoardingService: HoardingService,
    private hoardingGroupService: HoardingGroupService,
    private kioskService: KioskService,
    private notificationServcie: NotificatoinsService,
    private hmColService: HoardingMasterColService,
    private hoardingFunctionalService: HoardingFunctionalService,
    private savePlanService: SavePlanService,
    private updatePlanService: UpdatePlanService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.setInventoryTypeDropdown();
    this.setInventoryStatusDropdown();
    this.setDataTableColumns();
    this.filteredInventories = [];
    this.hmColService.siteFromDate.subscribe((sitesFromDate) => {
      this.sitesFromDate = sitesFromDate;
      this.overlays = [];
      this.getData();
    });
    this.hmColService.monthlyRateValues.subscribe((monthlyRateValues) => {
      this.monthlyRateValues = monthlyRateValues;
      this.overlays = [];
      this.getData();
    });

    this.globalFilterData
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchValue) => {
        this.globalFilterValue = searchValue;
        this.getData();
      });
    this.setInitialPaginationData();
    // this.onInlineScroll = this.inlineScrollerListener.bind(this);
    // this.dscroller.contentViewChild.nativeElement.addEventListener('scroll', this.onInlineScroll);
  }

  ngAfterViewInit() {
    this.setScrollHeightAndImagesPerPage();
    this.onInlineScroll = this.inlineScrollerListener.bind(this);
    this.dscroller.contentViewChild.nativeElement.addEventListener('scroll', this.onInlineScroll);
    this.inlineScrollerListener();
    // this.getData();
  }

  setInventoryTypeDropdown() {
    this.inventoryTypeDropdown = utils.createDropdown(MediaSubCategoryEnum, false);
    this.setDefaultSelectedInventoryType();
  }

  setInventoryStatusDropdown() {
    this.inventoryStatusDropdown = utils.createDropdown(HoardingStatusEnum, true);
    this.inventoryStatusDropdown = this.inventoryStatusDropdown.filter(item => item.label === "All" || item.label === HoardingStatusEnum.AVAILABLE || item.label === HoardingStatusEnum.NOT_AVAILABLE || item.label === HoardingStatusEnum.BLOCKED);
    this.selectedInventoryStatus = this.inventoryStatusDropdown[0].value;
  }

  setDefaultSelectedInventoryType() {
    this.selectedInventoryType = this.inventoryTypeDropdown[0].value;
    this.lastInventoryType = this.inventoryTypeDropdown[0].value;
    this.currentHoardingPage = 0;
    this.currentHoardingGroupPage = 0;
    this.currentKioskPage = 0;
  }

  onInventoryDropdownChange(event) {
    this.selectedInventoryType = event.value;
    this.filteredInventories = [];
    this.setPageForLastInventory();
    this.dscrollerPaginationEvent.page = this.getCurrentPageForInventory();
    this.inventoryTypeChanged = true;
    this.getData();
  }

  onInventoryStatusDropdownChange(event) {
    this.selectedInventoryStatus = event.value;
    this.filteredInventories = [];
    this.getData();
  }

  setPageForLastInventory() {
    switch (this.lastInventoryType) {
      case 'HOARDING': {
        this.currentHoardingPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case 'HOARDING_GROUP': {
        this.currentHoardingGroupPage = this.dscrollerPaginationEvent.page;
        break;
      }
      case 'KIOSK': {
        this.currentKioskPage = this.dscrollerPaginationEvent.page;
        break;
      }
    }
  }

  getCurrentPageForInventory() {
    switch (this.selectedInventoryType) {
      case 'HOARDING': {
        return this.currentHoardingPage;
      }
      case 'HOARDING_GROUP': {
        return this.currentHoardingGroupPage;
      }
      case 'KIOSK': {
        return this.currentKioskPage;
      }
    }
  }

  setDataTableColumns() {
    this.dataTableColumns = [         // passing value of datatable columns
      { name: 'customId', style: { 'width': '20vw !important', 'text-align': 'left !important', 'word-break': 'break-all !important', 'white-space': 'normal !important', 'word-wrap': 'break-all !important' }, header: 'Id', filter: false, filterMatchMode: 'contains', source: 'hoardings', type: 'text', hidden: false, permanent: true, sortable: false },
      { name: 'location', style: { 'width': '50vw !important', 'text-align': 'left !important', 'word-wrap': 'break-all !important', 'white-space': 'normal !important', 'word-break': 'break-all !important' }, header: 'Location', filter: false, filterMatchMode: 'contains', source: 'hoardings', type: 'text', hidden: false, permanent: true, sortable: false },
      { name: 'remove', style: { 'width': '20vw !important', 'text-align': 'right !important' }, header: '', filter: false, filterMatchMode: 'contains', source: 'hoardings', type: 'text', hidden: false, permanent: true, sortable: false }
    ];
  }

  inlineScrollerListener() {
    const scrollTop = this.dscroller.contentViewChild.nativeElement.scrollTop;
    const scrollHeight = this.dscroller.contentViewChild.nativeElement.scrollHeight;
    const viewportHeight = this.dscroller.contentViewChild.nativeElement.clientHeight;

    if ((scrollTop >= ((scrollHeight) - (viewportHeight)))) {
      // this.displayLoaderButton(true);
      this.dscrollerPaginationEvent.page += 1;
      this.inventoryTypeChanged = true;
      this.isLoading = true;

      this.getData();

    } else {
      // this.displayLoaderButton(false);
    }
  }

  handleMonthlyRateRange(event, values) {

    this.hoardingItems = [];
    this.hoardingGroupItems = [];
    this.kioskItems = [];
    this.monthlyRateFrom = values[0];
    this.monthlyRateTo = values[1];
    this.hmColService.monthlyRateValues.next(this.monthlyRateValues);
  }
  lazyLoadHandler(event) {
    console.log("lazy load data...", event);
    // this.itemsPerPage = event.rows;
    this.pageSize = event.rows;
    this.firstIndex = this.globalSearchData ? 0 : event.first;
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.overlays = [];
    this.setPaginationData();
    switch (this.selectedInventoryType) {
      case 'HOARDING': {
        this.getHoardingData();
        break;
      }
      case 'HOARDING_GROUP': {
        this.getHoardingGroupData();
        break;
      }
      case 'KIOSK': {
        this.getKioskData();
        break;
      }
      default: {
        this.getHoardingData();
      }
    }
  }

  getHoardingData() {

    this.hoardingService.create(this.dscrollerPaginationEvent, this.dscrollerPaginationEvent)
      .subscribe((response) => {
        if (response) {

          this.dscrollerPaginationEvent.page = this.page;
          // this.dscrollerPaginationEvent.page = this.page;
          if (this.dscrollerPaginationEvent.page === 0 || this.dscrollerPaginationEvent.globalFilter) {
            this.hoardingItems = [];
          }
          this.hoardingItems = [...this.hoardingItems, ...response['content']];
          this.totalItemsCount = response.totalElements;
          this.filteredInventories = this.hoardingItems;
          if (this.hoardingItems.length) {
            this.setMapMarker();
            this.setCheckedInventory();
          } else {
            this.isLoading = false;
          }

        }
      },
        (error) => {
          this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        });
  }

  getHoardingGroupData() {
    this.hoardingGroupService.create(this.dscrollerPaginationEvent, this.dscrollerPaginationEvent)
      .subscribe((response) => {
        if (response) {
          this.dscrollerPaginationEvent.page = this.page;
          if (this.dscrollerPaginationEvent.page === 0 || this.dscrollerPaginationEvent.globalFilter) {
            this.hoardingGroupItems = [];
          }
          this.hoardingGroupItems = [...this.hoardingGroupItems, ...response['content']];
          this.totalItemsCount = response.totalElements;
          this.filteredInventories = this.hoardingGroupItems;
          if (this.hoardingGroupItems.length) {
            this.setMapMarker();
            this.setCheckedInventory();
          } else {
            this.isLoading = false;
          }

        }
      },
        (error) => {
          this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        });
  }

  getKioskData() {
    this.kioskService.create(this.dscrollerPaginationEvent, this.dscrollerPaginationEvent, AppUrls.SEARCH)
      .subscribe((response) => {
        if (response) {
          this.dscrollerPaginationEvent.page = this.page;
          if (this.dscrollerPaginationEvent.page === 0 || this.dscrollerPaginationEvent.globalFilter) {
            this.kioskItems = [];
          }
          this.kioskItems = [...this.kioskItems, ...response['content']];
          this.totalItemsCount = response.totalElements;
          this.filteredInventories = this.kioskItems;
          if (this.kioskItems.length) {
            this.setMapMarker();
            this.setCheckedInventory();
          } else {
            this.isLoading = false;
          }

        }
      },
        (error) => {
          this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
        });
  }

  setInitialPaginationData() {
    this.dscrollerPaginationEvent = {
      filters: {},
      globalFilter: "",
      page: 0,
      // size: this.itemsPerPage,
      size: this.pageSize,
      sortField: 'updatedDate',
      sortOrder: 1,
    };
  }

  setPaginationData() {

    this.dscrollerPaginationEvent.globalFilter = this.globalFilterValue;
    Object.assign(this.dscrollerPaginationEvent.filters, this.setPriceRangeFilter());
    Object.assign(this.dscrollerPaginationEvent.filters, this.setSitesAvailableFromFilter());
    Object.assign(this.dscrollerPaginationEvent.filters, { 'status': { value: this.selectedInventoryStatus, matchMode: 'is' } });
    // if (this.dscrollerPaginationEvent.filters.status.value === 'AVAILABLE' || this.dscrollerPaginationEvent.filters.status.value === 'NOT_AVAILABLE' || this.dscrollerPaginationEvent.filters.status.value === 'BLOCKED') {
    //   this.dscrollerPaginationEvent.page = 0;
    // }
    if (!this.dscrollerPaginationEvent.filters.status || this.dscrollerPaginationEvent.filters.status.value === 'ALL') {
      Object.assign(this.dscrollerPaginationEvent.filters, { 'status': { value: ['MIGRATED', 'EXPIRED', 'INVALID', 'INACTIVE'], matchMode: 'notIn' } });

    }
    // this.dscrollerPaginationEvent.size = this.itemsPerPage;
    this.dscrollerPaginationEvent.size = this.pageSize;
    // this.dscrollerPaginationEvent.page = Number(this.firstIndex / this.itemsPerPage);
    if (this.inventoryTypeChanged) {
      this.dscrollerPaginationEvent.page = this.dscrollerPaginationEvent.page;
    } else {

      if (this.dscrollerPaginationEvent.page === 1) {
        this.firstIndex = 100;
      }
      this.dscrollerPaginationEvent.page = Number(this.firstIndex / this.pageSize);
    }
    this.inventoryTypeChanged = false;


    if (this.dscrollerPaginationEvent.globalFilter) {
      this.searchedWithGlobalFilter = true;
      this.page = _.cloneDeep(this.dscrollerPaginationEvent.page);
      this.dscrollerPaginationEvent.page = undefined;
      this.dscroller.contentViewChild.nativeElement.removeEventListener('scroll', this.onInlineScroll, true);
    } else {
      this.page = this.dscrollerPaginationEvent.page;
      this.dscroller.contentViewChild.nativeElement.addEventListener('scroll', this.onInlineScroll);
      if (this.searchedWithGlobalFilter) {
        this.page = 0;
        this.dscrollerPaginationEvent.page = this.page;
        this.searchedWithGlobalFilter = false;
      }
    }
  }

  setSitesAvailableFromFilter() {
    return (this.sitesFromDate ? { 'campaignEndDate': { value: this.sitesFromDate, matchMode: 'availableFrom' } } : {});
  }
  setPriceRangeFilter() {
    return (this.monthlyRateValues ? { 'grossPrice': { value: this.monthlyRateValues, matchMode: 'between' } } : {});
  }

  setScrollHeightAndImagesPerPage() {
    this.scrollHeight = (window.innerHeight - 100) + 'px';
    // this.itemsPerPage = 50;
    this.itemsPerPage = utils.getIntegerValue(Number(this.scrollHeight.split('px')[0]) / 100) * 5; // 100 - random vlaue < 150 (image height), 5 - images in a line
  }

  searchBoxChanges(event: string) {
    // let places = event.split(',');
    // console.log(places[0]);
    this.globalSearchData = event.split(",")[0];
    this.firstIndex = 0;

    // var globalfilterData = places.join('');
    // console.log(globalfilterData);
    // this.dscrollerPaginationEvent = {
    //   filters: {},
    //   globalFilter: places[0],
    //   page: this.dscroller.page,
    //   size: this.itemsPerPage,
    //   sortField: 'updatedDate',
    //   sortOrder: 1,
    // };
    // this.setPaginationData(null);
    // Object.assign(this.dscrollerPaginationEvent.globalFilter, globalfilterData);
    // this.itemsToShow = [];
    this.overlays = [];
    this.getData();
  }

  setMapMarker() {
    // let latSum = 0;
    // let longSum = 0;
    this.filteredInventories.forEach((inventory) => {
      if (inventory && inventory.geoCoordinate) {
        // latSum += Number(inventory.geoCoordinate.latitude);
        // longSum += Number(inventory.geoCoordinate.longitude);
        this.mapMarker = new MapMarker().setMapMarkerFromInventory(inventory);
        this.overlays.push(this.mapMarker);
      }
    });
    // console.log("latsum", latSum, latSum / this.filteredInventories.length);
    // const centLat = this.filteredInventories.length ? latSum / this.filteredInventories.length : latSum;
    // const centLong = this.filteredInventories.length ? longSum / this.filteredInventories.length : longSum;
    this.overlays = _.cloneDeep(this.overlays);
    this.options = {
      center: { lat: typeof (this.overlays[0].latitude) === 'string' ? parseFloat(this.overlays[0].latitude) : this.overlays[0].latitude, lng: typeof (this.overlays[0].longitude) === 'string' ? parseFloat(this.overlays[0].longitude) : this.overlays[0].longitude },
      // center: { lat: centLat, lng: centLong },
      zoom: 8
    };
    this.showMap = true;
    this.isLoading = false;
  }

  inputSitesAvailableFrom(event) {
    if (event instanceof Date) {
      this.hmColService.siteFromDate.next(event);
    } else {
      this.hmColService.siteFromDate.next(null);
    }

  }

  globalFiltering(value) {
    this.globalFilterData.next(value);
  }

  getLightType(lightType) {
    return HoardingLightEnum[lightType];
  }
  cardHover(item) {
    this.mapComponent.setBounce(item);
  }

  cardOut(item) {
    this.mapComponent.resetBounce(item);
  }



  toggleSidebar() {
    this.viewSidebar = !this.viewSidebar;
  }

  // removeInventory(event) {
  //   // resetting the checkbox
  //   this.setSelectedInventoriesFromCheckbox(false, event.rowData);
  // }
  removeInventory(inventory) {
    // resetting the checkbox
    this.setSelectedInventoriesFromCheckbox(false, inventory["rowData"]);
    this.removeMarker = inventory["rowData"];
  }

  onCheckboxSelectionChange(event, item, index) {
    this.setSelectedInventoriesFromCheckbox(event, item);
  }

  setCheckedInventory() {
    this.checked = [];
    this.filteredInventories.forEach((inventory, index) => {
      const ind = this.selectedInventories.findIndex(inv => inventory.customId === inv.customId);
      if (ind !== -1) {
        this.checked[index] = true;
      } else {
        this.checked[index] = false;
      }
    });
  }


  setSelectedInventoriesFromCheckbox(event, item) {
    const index = this.selectedInventories.findIndex(inventory => inventory.customId === item.customId);
    if (event) { // checkbox is checked
      // this.checked[ind] = true; // setting the checked boolean against the item index as true
      if (index === -1) { // selected inventries do not include the checked item
        this.selectedInventories.push(item);
      } else { // selected inventories already include the checked item --> should never happen
        // do nothing
      }
    } else { // checkbox is unchecked
      // this.checked[ind] = false; // setting the checked boolean against the item index as false
      if (index === -1) { // selected inventories do not include the unchecked item --> should never happen
        // do nothing
      } else { // selected inventories already include the checked item
        this.selectedInventories.splice(index, 1);
      }
    }
    this.selectedInventories = JSON.parse(JSON.stringify(this.selectedInventories));
    this.selectedInventoryIds = this.selectedInventories.map(inventory => inventory.customId);
    this.setCheckedInventory();
    console.log("selected inventories", this.selectedInventories);
  }

  setSelectedInventoriesFromMap(ids) {

    // adding selected map markers to selected inventory list
    ids.forEach((id) => {
      if (this.selectedInventories.findIndex(inventory => inventory.customId === id) !== -1) {
        // do nothing
      } else {
        console.log("push", id);
        const inventory = this.filteredInventories.find(item => item.customId === id);
        this.selectedInventories.push(inventory);
        // this.selectedInventories = JSON.parse(JSON.stringify(this.selectedInventories));
        // this.setCheckedInventory();
      }
    });

    // removing unselected map markers from selected inventory list
    const idsToRemove = this.selectedInventoryIds.filter(id => ids.findIndex(mapMarkerId => mapMarkerId === id) === -1);
    idsToRemove.forEach((id) => {
      const index = this.selectedInventories.findIndex(item => item.customId === id);
      if (index !== -1) {
        this.selectedInventories.splice(index, 1);
      } else {
        // do nothing -> will not happen
      }
    });


    this.selectedInventories = JSON.parse(JSON.stringify(this.selectedInventories));
    this.selectedInventoryIds = this.selectedInventories.map(inventory => inventory.customId);
    this.setCheckedInventory();
  }

  assignToPlan() {
    this.displayPlanDialog = true;
  }

  showPlanDialog() {
    this.displayPlanDialog = true;
  }

  hidePlanDialog() {
    this.displayPlanDialog = false;
  }

  createPlan(plan: Plan) {
    const planItemBo = this.hoardingFunctionalService.getPlanItemBo(this.selectedInventories);
    this.getPlanWrapper(plan, planItemBo);
    this.savePlanService.create(this.planWrapper).subscribe(
      (response) => {
        if (response) {
          console.log("response is", response);
          this.notificationServcie.success('Plan Created Successfully', 'Plan Creation');
          this.hidePlanDialog();
          this.router.navigate(['/plans/view/' + response['plan']['id']]);
        }
      },
      (error) => {
        console.log("error is", error);
        this.hidePlanDialog();
        this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
      }
    );
  }

  savePlan(plan: Plan) {
    const planItemBo = this.hoardingFunctionalService.getPlanItemBo(this.selectedInventories);
    this.getPlanWrapper(plan, planItemBo);
    this.updatePlanService.create(this.planWrapper).subscribe(
      (response) => {
        if (response) {
          console.log("response is", response);
          this.notificationServcie.success('Plan Updated Successfully', 'Plan Update');
          this.hidePlanDialog();
          this.router.navigate(['/plans/view/' + plan.id, { itemAdded: true }]);
        }
      },
      (error) => {
        console.log("error is", error);
        this.hidePlanDialog();
        this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
      }
    );
  }

  getPlanWrapper(plan, planItemBo) {
    this.planWrapper = new PlanWrapper();
    this.planWrapper.plan = plan;
    this.planWrapper.items = planItemBo;
  }

  clearSidebar() {
    if (this.selectedInventories.length) {
      this.setSelectedInventoriesFromCheckbox(false, this.selectedInventories[0]);
      this.clearSidebar();
    } else {
      // do nothing
    }
  }

}
