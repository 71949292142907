<div class="ui-g ui-fluid">
    <div class="ui-g-12 ui-lg-6">
        <div class="card">
            <h1 class="centerText">Linear Chart</h1>
            <p-chart type="line" [data]="lineData"></p-chart>
        </div>
        
        <div class="card">
            <h1 class="centerText">Pie Chart</h1>
            <p-chart type="pie" [data]="pieData"></p-chart>
        </div>
        
        <div class="card">
            <h1 class="centerText">Polar Area Chart</h1>
            <p-chart type="polarArea" [data]="polarData"></p-chart>
        </div>
    </div>
    <div class="ui-g-12 ui-lg-6">
        <div class="card">
            <h1 class="centerText">Bar Chart</h1>
            <p-chart type="bar" [data]="barData"></p-chart>
        </div>
        
        <div class="card">
            <h1 class="centerText">Doughnut Chart</h1>
            <p-chart type="doughnut" [data]="pieData"></p-chart>
        </div>
        
        <div class="card">
            <h1 class="centerText">Radar Chart</h1>
            <p-chart type="radar" [data]="radarData"></p-chart>
        </div>
    </div>
</div>