export class MenuConstants {
    public static CLONE_TO_PLAN = "Copy to Plan";
    public static ADD_HOARDINGS = "Add Hoarding(s)";
    public static VIEW_IMAGES = "Slideshow";
    public static XLSX = "XLSX";
    public static DOWNLOAD = "Download";
    public static DOWNLOAD_ALL = "Download All";
    public static PHOTOS = "Photos";
    public static DOWNLOAD_XLSX_L = "Download XLSX";
    public static DOWNLOAD_XLSX_I = "fa fa-file-excel-o";
    public static DOWNLOAD_PHTOTS_L = "Download Photos";
    public static DOWNLOAD_PHTOTS_I = "fa-image";
    public static DOWNLOAD_PPT_L = "Download PPT";
    public static DOWNLOAD_PPT_L_S1 = "Download PPT(Server 1)";
    public static DOWNLOAD_PPT_L_S2 = "Download PPT(Server 2)";
    public static DOWNLOAD_PPT_L_BETA = "Download PPT Beta"
    public static DOWNLOAD_PPT_I = "fa-file";
    public static SEND_L = "Send Email";
    public static SEND_I = "fa-envelope";
    public static EDIT_L = "Edit";
    public static EDIT_CONTINUE_L = "Edit & Continue";
    public static EDIT_I = "fa-pencil";
    public static CANCEL_L = "Cancel";
    public static CANCEL_RESTORE_L = "Cancel & Restore"
    public static CANCEL_I = "fa-ban";
    public static DISCARD_L = "Discard";
    public static DELETE_M = "Delete";
    public static DISCARD_I = "fa-close";
    public static APPLY_CN_L = "Full CN";
    public static APPLY_CN_I = "fa-circle";
    public static APPLY_PARTIALLY_CN_L = "Part CN";
    public static APPLY_PARTIALLY_CN_I = "fa-adjust";
    public static UPDATE_INVOICE_L = "Update";
    public static UPDATE_INVOICE_I = "fa-edit";
    public static COPY_PLAN = "Copy Plan";
    public static SHARE_PLAN = "Share Plan";
    public static SHARE_CAMPAIGN_AS_PLAN = "Share Campaign ";
    public static CAMPAIGN_TRACKER = "Campaign Tracker"
    public static QUICK_UPDATE_INVOICE_L = "Quick Update";
}
