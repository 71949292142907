
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule, OverlayPanelModule, DropdownModule, CheckboxModule, InputSwitchModule } from "primeng/primeng";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { SuperbillTasksComponent } from './superbill-tasks.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusVisualizationModule } from '../status-visualization/status-visualization.module';
import { RouterModule } from '@angular/router';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';
import { PendingInvoiceService } from '../../../services/shared/pending-invoice.service';
import { BillingUIService } from '../../../billings/services/billingUI.service';
import { InvoiceTaskUIService } from './services/invoice-task-UI.service';
import { InvoiceChartService } from '../../../services/shared/invoice-chart.service';
import { CreditNotesService } from '../../../services/shared/credit-note.service';
import { SuperBillsService } from '../../../billings/services/superbill.service';
import { CapitalizePipe } from '../../helpers/capitalize-pipe/capitalize-pipe';
import { PrintPannelLayoutModule } from '../print-pannel-layout/print-pannel-layout.module';


@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        ReactiveFormsModule,
        FormsModule,
        StatusVisualizationModule,
        OverlayPanelModule,
        RouterModule,
        CurrencyMainPipe,
        DropdownModule,
        CheckboxModule,
        InputSwitchModule,
        PrintPannelLayoutModule
    ],

    declarations: [
        SuperbillTasksComponent,
        CapitalizePipe
    ],

    exports: [
        SuperbillTasksComponent
    ],

    providers: [
        PendingInvoiceService,
        BillingUIService,
        InvoiceTaskUIService,
        InvoiceChartService,
        CreditNotesService,
        SuperBillsService,
    ]
})

export class SuperbillTasksModule { }
