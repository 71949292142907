<div class="cn-summary-grid">
    <div class="ui-g">
        <form [formGroup]="cNSummaryGroup">
            <sib-grid [showFilter]="false" name="cnSummaryGrid" #cnSummaryGrid [columns]="columns"
                [buttons]="buttonConfig" [config]="gridConfig" [totalRecords]="totalRecords"
                formControlName="cnSummaryGridControl" ngDefaultControl>
                <sib-grid-column field="particulars">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span>
                            <b>CREDIT NOTE (SALES)</b>
                        </span>
                    </ng-template>
                </sib-grid-column>
                <sib-grid-column field="amount">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span>
                            {{getRoundedOffValue(rowData?.totalAmount - rowData?.taxAmount) | CurrencyConverter}}
                        </span>
                    </ng-template>
                </sib-grid-column>
            </sib-grid>
        </form>
    </div>
</div>