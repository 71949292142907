/**
 * @file Plans
 * @author Pulkit (pulkitb@meditab.com)
 * @export
 * @class Tax
 */

export class Tax {
    rate: number;
    amount: number;
    startDate: Date;
    endDate: Date;
}
