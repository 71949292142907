<div class="editPO" >
    <form #editPOForm="ngForm">
        <div class="ui-g">

            <div class="ui-g-4">
                <span class="md-inputfield">
                    <!-- [disabled]="true" -->
                    <!-- (blur)="onNameChange($event.target.value)" -->
                    <input id="name" trim="blur" type="text" pInputText name="name" [(ngModel)]="row.purchaseOrders.name"
                        (input)="onNameInput($event.target.value)" pTooltip="{{name.hasError('required') ? 'Required Field' : ''}}"
                        #name="ngModel" required changes
                        [changedValue]="row.purchaseOrders.name" [changedType]="getChangedType()" [changedField]="'purchaseOrders.name'"
                        [changeDescription]="'-'" [changeAdditionalDescription]="'-'" [changedFieldDisplayName]="'Doc Name'" />
                    <label for="name">Doc Name
                        <span class="required">*</span>
                    </label>
                </span>
            </div>

            <div class="ui-g-2 ctext-center">
                <p-checkbox #poCheckbox="ngModel" name="po" binary="true" label="IS PO?" inputId="po" [(ngModel)]="row.purchaseOrders.po" changes
                [changedValue]="row.purchaseOrders.po" [changedType]="getChangedType()" [changedField]="'purchaseOrders.po'"
                [changeDescription]="'-'" [changeAdditionalDescription]="'-'" [changedFieldDisplayName]="'PO'"
                    (onChange)="onCheckboxSelectionChange($event)"></p-checkbox>
            </div>

            <div class="ui-g-4">
                <span class="md-inputfield">
                    <div class="width-90 padding-0">
                        <input id="poNumber" trim="blur" type="text" pInputText name="poNumber" [(ngModel)]="row.purchaseOrders.poNumber"
                            [disabled]="!poCheckbox.value" #poNumber="ngModel" pTooltip="{{poNumber.hasError('required')? 'Required Field' :''}}"
                            maxlength="50" required  changes
                            [changedValue]="row.purchaseOrders.poNumber" [changedType]="getChangedType()" [changedField]="'purchaseOrders.poNumber'"
                            [changeDescription]="'-'" [changeAdditionalDescription]="'-'" [changedFieldDisplayName]="'PO Number'"  />
                        <label for="poNumber">PO Number
                            <span class="required">*</span>
                        </label>
                    </div>
                </span>
            </div>

            <div class="ui-g-2">
                <span class="md-inputfield">
                    <p-calendar #poDate="ngModel" id="poDate" name="poDate" dateFormat="ddMy" [showIcon]="true"
                        [autoZIndex]="true" [baseZIndex]="200000" monthNavigator="true" [disabled]="!poCheckbox.value"
                        [utc]="true" yearNavigator="true" [yearRange]="'2017:2050'" appendTo="body" pTooltip="{{poDate.hasError('required')? 'Ex - 01Jan18' :''}}"
                        [(ngModel)]="row?.purchaseOrders.poDate" required  changes
                        [changedValue]="row?.purchaseOrders.poDate" [changedType]="getChangedType()" [changedField]="'purchaseOrders.poDate'"
                        [changeDescription]="'-'" [changeAdditionalDescription]="'-'" [changedFieldDisplayName]="'PO Date'" ></p-calendar>
                    <label for="poDate"> PO Date
                        <span class="required">*</span>
                    </label>
                </span>
            </div>
        </div>
    </form>
    <div class="ctext-center padding-top-10">
        <button pButton label="SAVE" class="mtab-save margin-hori-5" (click)="saveChanges()" [disabled]="!editPOForm.form.valid || disableSave">
        </button>
        <button pButton label="CANCEL" class="mtab-secondary" (click)="cancelChanges()" [disabled]="disableSave">
        </button>
    </div>
</div>