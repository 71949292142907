import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { CampaingWrapper } from "./campaign-wrapper";
import { CampaignItemWrapper } from "./campaign-item-wrapper";
import { JournalCategory } from "../../shared/constants/journal-category-enum";
import { JournalSubCategory } from "../../shared/constants/journal-sub-category-enum";

export class SalesJournal extends SibBaseObject {
    companyName: string;
    campaign: CampaingWrapper;
    campaignItem: CampaignItemWrapper;
    amount: number;
    invoiceNo: string;
    billed: boolean;
    serviceProvided: boolean;
    tax: number;
    category: JournalCategory;
    subCategory: JournalSubCategory;
    serviceDate: Date;
}