import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { InvoiceChartService } from '../../services/shared/invoice-chart.service';
import { AppUrls } from '../../services/urls';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { ReservedInvoiceMetadata } from '../../modals/billings/reservedInvoiceMetadata';
import { FormGroup, FormControl } from '@angular/forms';
import { GridConfig, SelectionMode, GridPaginationEvent, GridColumn, EditableGridFieldTypes, Alignment } from '../../components/sib-forms/grid/grid.config';
import { GridActionButtonConfig } from '../../components/sib-forms/grid/grid-action-button.config';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { SelectItem, Dropdown, ConfirmationService } from 'primeng/primeng';
import * as utils from '../../helpers/utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { DateUtil } from '../../helpers/date.util';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import * as _ from "lodash";
import { MonthEnum } from '../../shared/constants/month.enum';
import { CustomerUiService } from '../../customers/services/customer-ui.service';
import { map } from 'rxjs/operators';
import { BillingUIService } from '../services/billingUI.service';
import { GridNameEnum } from '../../shared/constants/grid-name.enum';
import { UserGridConfigService } from '../../services/shared/user-grid-config.service';
import { ImageFolderConfig } from '../../modals/image/image-folder-config';
import { DocumentComponent } from '../../shared/components/documents/document.component';
import { DocumentService } from '../../shared/components/documents/services/document.service';

@Component({
    selector: 'sib-reserve-invoices',
    templateUrl: './reserve-invoices.component.html',
    styleUrls: ['./reserve-invoices.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReserveInvoicesComponent implements OnInit, AfterViewInit, OnDestroy {

    _showEmployee: boolean;
    @Input() set showEmployee(showEmployee) {
        this._showEmployee = showEmployee;
        this.setColumns();
    }

    get showEmployee() {
        return this._showEmployee;
    }

    _isReserveInvoiceChartFilter: boolean;
    @Input() set isReserveInvoiceChartFilter(reserveInvoiceChartFilter) {
        this._isReserveInvoiceChartFilter = reserveInvoiceChartFilter;
    }
    get isReserveInvoiceChartFilter() {
        return this._isReserveInvoiceChartFilter;
    }

    _reserveInvoiceChartEvent: any;
    @Input() set reserveInvoiceChartEvent(reserveInvoiceChartEvent) {
        this._reserveInvoiceChartEvent = reserveInvoiceChartEvent;
    }
    get reserveInvoiceChartEvent() {
        return this._reserveInvoiceChartEvent;
    }

    @Input() isFinancialYearChanged: boolean = false;

    _financialYear: any;
    @Input() set financialYear(financialYear) {
        this._financialYear = financialYear;
        this.gridViewChildRef.refresh();
    }

    get financialYear() {
        return this._financialYear;
    }

    @Input() showTitle: boolean = true;
    @Input() showAction: boolean = true
    @Output() eEmitIsLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('sibGrid')
    gridViewChildRef: GridComponent<ReservedInvoiceMetadata>;
    @ViewChild("documentGrid") documentComponent: DocumentComponent;
    @ViewChild("groupedDropdown") groupedDropdown: Dropdown;

    isLoading = false;
    lastInvoiceId: string = null;
    reservedInvoices: ReservedInvoiceMetadata[] = [];
    reserveCount = 0;
    rowData: ReservedInvoiceMetadata = new ReservedInvoiceMetadata(); // to store the row marked/unmarked as utilized
    title = "Reserved Invoices";
    totalRecords = 100;
    utilizedDropdown: SelectItem[] = [];
    displayReserveInvoiceDetailDialog = false;
    isFileDownloading = false;
    xlsLoader: PPTLoader = new PPTLoader();
    searchEvent: any = {
        sortField: undefined,
        sortOrder: undefined,
        filters: {}
    };
    event: any = {
        filteredValue: [],
        filters: {}
    }
    reservedInvoiceGroup: FormGroup;
    gridConfig: GridConfig<ReservedInvoiceMetadata> = new GridConfig<ReservedInvoiceMetadata>();
    buttonConfig: GridActionButtonConfig;
    userEmailId: string;
    invoiceFilterDates: Date[] = [];
    utilized: any;
    displayUploadDocumentDialog: boolean = false;
    folderConfig: ImageFolderConfig = new ImageFolderConfig();

    displayDocumentComponent: boolean = false;
    documentEdit: boolean = false;
    viewDocumentsFromBilling: boolean = false;
    showDelete: boolean = false;

    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'invoiceId',
            name: 'invoiceId',
            header: 'Invoice Id',
            required: true,
            editable: false,
            width: '9.5vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        // {
        //     field: 'customer.company',
        //     name: 'customer.company',
        //     header: 'Company',
        //     required: true,
        //     editable: false,
        //     width: '8vw',
        //     permanent: false,
        //     hidden: false,
        //     sortable: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     displayFn: (data) => {
        //         if (data && data.customer && data.customer.company) {
        //             return data.customer.company;
        //         } else {
        //             return '-';
        //         }
        //     },
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        // },
        {
            field: 'displayName',
            name: 'displayName',
            header: 'Display Name',
            required: true,
            editable: false,
            width: '10vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.displayName) {
                    return data.displayName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'customer.billingName',
            name: 'customer.billingName',
            header: 'Billing Name',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.customer && data.customer.billingName) {
                    return data.customer.billingName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'employee.fullName',
            name: 'employee.fullName',
            header: 'Employee',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.employee && data.employee.fullName) {
                    return data.employee.fullName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'billGeneratedDate',
            name: 'billGeneratedDate',
            header: 'Inv Gen Date',
            required: true,
            editable: false,
            width: '8vw',
            permanent: true,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.billGeneratedDate) {
                    return DateUtil.dategridFormatter(data.billGeneratedDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'totalAmount',
            name: 'totalAmount',
            header: 'Amount',
            required: true,
            editable: false,
            width: '6vw',
            hidden: false,
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.totalAmount !== undefined && data.totalAmount !== null) {
                    return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.totalAmount));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'poNumber',
            name: 'poNumber',
            header: 'PO No',
            required: true,
            editable: false,
            hidden: false,
            permanent: true,
            width: '6vw',
            sortable: false,
            styleClass: 'word-break-wrap',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.poNumber) {
                    return data.poNumber;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'reservedBy.fullName',
            name: 'reservedBy',
            header: 'Reserved By',
            required: true,
            editable: false,
            width: '7vw',
            permanent: false,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.reservedBy) {
                    return data.reservedBy.fullName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'reservedDate',
            name: 'reservedDate',
            header: 'Reserved Date',
            required: true,
            editable: false,
            width: '8vw',
            permanent: false,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.reservedDate) {
                    return DateUtil.dategridFormatter(data.reservedDate) + ' (' + DateUtil.getTimeFromDate(data.reservedDate) + ')';
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'utilized',
            name: 'utilized',
            header: 'Utilized',
            required: true,
            editable: false,
            // width: '4vw',
            hidden: false,
            permanent: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'is',
                placeHolder: 'Search',
                custom: true
            },
        },
        {
            field: 'utilizedBy.fullName',
            name: 'reservedBy',
            header: 'Utilized By',
            required: true,
            editable: false,
            width: '7vw',
            permanent: false,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.utilizedBy) {
                    return data.utilizedBy.fullName;
                } else {
                    return '-';
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'utilizedDate',
            name: 'utilizedDate',
            header: 'Utilized Date',
            required: true,
            editable: false,
            width: '8vw',
            permanent: false,
            hidden: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.utilizedDate) {
                    return DateUtil.dategridFormatter(data.utilizedDate) + ' (' + DateUtil.getTimeFromDate(data.utilizedDate) + ')';
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'uploadDoc',
            name: 'uploadDoc',
            header: '',
            permanent: true,
            sortable: false,
            hidden: false,
            required: true,
            editable: false,
            width: '4vw',
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
        },
        {
            field: 'viewDoc',
            name: 'viewDoc',
            header: '',
            permanent: true,
            sortable: false,
            hidden: false,
            required: true,
            editable: false,
            width: '4vw',
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
        },
        {
            field: 'id',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '4vw',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
        {
            field: 'delete',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '50px',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
    ];

    constructor(
        private pageTitle: Title,
        private invoiceChartService: InvoiceChartService,
        private notificationServcie: NotificatoinsService,
        private currencyPipe: CurrencyHelperPipe,
        private loaderSubjects: LoaderSubjects,
        private customerUiService: CustomerUiService,
        private billingUIService: BillingUIService,
        private userGridConfigService: UserGridConfigService,
        private documentService: DocumentService,
        private confirmationService: ConfirmationService,
    ) { }

    ngOnInit() {
        let appTitle = utils.getAppTitle();
        !appTitle ? this.pageTitle.setTitle(SIBConstants.RESERVE_INVOICES_MENU) : this.pageTitle.setTitle(SIBConstants.RESERVE_INVOICES_MENU + ' - ' + appTitle);
        this.userEmailId = this.customerUiService.getUserEmail();
        this.setDeleteColumnVisibility();
        this.userGridConfigService.get(null, '/' + GridNameEnum['RESERVED_INVOICES'])
            .subscribe((reservedInvoicesGridConfig) => {
                this.columns = utils.getUserConfiguredColumns(reservedInvoicesGridConfig, this.columns);
                this.gridViewChildRef.ngOnInit();
            });
        this.setUnutilizedDropdown();
        this.setFormGroupObjects();
        this.setGridConfigObject();
        this.getLastInvoiceId();
    }

    ngAfterViewInit() { }

    setUnutilizedDropdown() {
        if (this.showAction) {
            this.utilizedDropdown = utils.createTrueFalseDropdown(true);
        } else {
            this.utilizedDropdown.push({ label: 'True', value: true });
        }
    }

    setFormGroupObjects() {
        this.reservedInvoiceGroup = new FormGroup({
            gridControl: new FormControl()
        });
    }

    setGridConfigObject() {
        this.gridConfig.getDefaultModel = () => new ReservedInvoiceMetadata();
        this.gridConfig.model = ReservedInvoiceMetadata;
        this.gridConfig.selectionMode = SelectionMode.None;
        this.gridConfig.editable = true;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.showColorCode = false;
        this.gridConfig.lazy = true;
        this.gridConfig.enablePagination = true;
        this.gridConfig.scrollable = true;
        // this.gridConfig.sortField = 'updatedDate';
        // this.gridConfig.sortOrder = -1;
        // this.gridConfig.sortMode = 'single';
        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen;

            this.isLoading = true;
            // if (this.showAction === true) {
            //     // this.utilized = undefined;
            // } else if (this.showAction === false) {
            //     this.utilized = true;
            // }
            if (!this.checkPermission('readAll')) {
                this.utilized = true;
                Object.assign(paginationEvent.filters, { 'employee.emailId': { value: this.userEmailId, matchMode: 'is' } });
                Object.assign(paginationEvent.filters, { 'utilized': { value: this.utilized, matchMode: 'is' } });
            }
            else if (this.checkPermission('readAll') && this.showAction === false) {
                this.utilized = true;
                if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
                    paginationEvent.sortField = 'billGeneratedDate';
                }
                Object.assign(paginationEvent.filters, { 'utilized': { value: this.utilized, matchMode: 'is' } });
            }

            // if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
            //     paginationEvent.sortField = 'billGeneratedDate';
            // }

            if (this.isReserveInvoiceChartFilter) {
                // paginationEvent.sortOrder = -1;
                paginationEvent = Object.assign(paginationEvent, paginationEvent);
                this.setInvoiceChartFilter(paginationEvent)
            } else if (!this.isReserveInvoiceChartFilter && this.financialYear) {
                let startDate = new Date(Date.UTC(Number(this.financialYear.split('-')[0]), 3, 1, 0, 0, 0, 0));
                let endDate = new Date(Date.UTC(Number(this.financialYear.split('-')[1]), 2, 31, 0, 0, 0, 0));
                this.invoiceFilterDates = [startDate, endDate];
                var chartFilter = { "billGeneratedDate": { value: this.invoiceFilterDates, matchMode: 'dateBetween' } }
                Object.assign(paginationEvent.filters, chartFilter);
            }

            if (this.isFinancialYearChanged) {
                if (paginationEvent.sortField !== 'billGeneratedDate') {
                    paginationEvent.sortField = 'billGeneratedDate'
                }
                this.setInvoiceChartFilter(paginationEvent);
            }

            return this.invoiceChartService.create(paginationEvent, paginationEvent, '/search/reservedInvoiceMetadata').pipe(map((response) => {
                if (response) {
                    this.searchEvent = paginationEvent;
                    this.reservedInvoices = response['data']['content'];
                    this.totalRecords = response['data'].totalElements;
                    this.isLoading = false;
                    return this.reservedInvoices;
                } else {
                    this.isLoading = false;
                }
            },
                (error) => {
                    this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!')
                    this.isLoading = false;
                }));
        };
    }

    onSort(event) {
        this.reservedInvoices = this.gridViewChildRef.getDataTableInstance()['_value'];
        this.searchEvent.sortField = event.field;
        //to download the xls as per the sorting order because the sorting order of backend and frontend are opposite
        this.searchEvent.sortOrder = event.order * -1;
    }

    onFilter(event) {
        this.reservedInvoices = event.filteredValue;
        this.searchEvent.filters = this.setFiltersForSearchEvent(_.cloneDeep(event.filters));
    }

    setFiltersForSearchEvent(filters) {
        const keys = Object.keys(filters);
        keys.forEach((key) => {
            if (filters[key].matchMode === "equals") {
                filters[key].matchMode = "is";
            }
        });
        return filters;
    }

    getLastInvoiceId() {
        this.isLoading = true;
        this.invoiceChartService.get(null, AppUrls.GET_LAST_INVOICE_ID).subscribe(
            (response) => {
                if (response) {
                    this.lastInvoiceId = response['data'];
                    this.isLoading = false;
                }
            },
            (error) => {
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    submitReserveCount() {
        this.isLoading = true;
        this.invoiceChartService.get({ reservedCount: this.reserveCount }, AppUrls.RESERVE_INVOICE_IDS).subscribe(
            (response) => {
                if (response) {
                    this.reserveCount = 0; // resetting the reserve count for display
                    this.showSuccessNotification('Invoices Reserved Successfully', 'Invoice Reservation');
                    // this.getReservedInvoices();
                    this.gridViewChildRef.refresh();
                    this.getLastInvoiceId();
                    this.isLoading = false;
                }
            },
            (error) => {
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    addReserveInvoiceDetails(rowData) {
        this.rowData = rowData;
        this.displayReserveInvoiceDetailDialog = true;
    }

    toggleUtilized(reservedInvoiceMetadata: ReservedInvoiceMetadata) {
        this.isLoading = true;
        this.invoiceChartService.create(reservedInvoiceMetadata, null, AppUrls.MARK_UTILIZED_OR_NOT).subscribe(
            (response) => {
                if (response) {
                    this.hideInvoiceDetailsDialog();
                    (reservedInvoiceMetadata.utilized) ? this.showSuccessNotification('Marked as Utilized successfully', 'Mark Invoice As Utilized') : this.showSuccessNotification('Unmarked as Utilized successfully', 'Unmark Invoice As Utilized');
                    this.isLoading = false;
                    this.eEmitIsLoading.emit(false);
                }
            },
            (error) => {
                this.hideInvoiceDetailsDialog();
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    exportXlsx() {
        this.isFileDownloading = true;
        this.createLoaderObject("XLS", this.xlsLoader);
        this.loaderSubjects.reservedInvoiceXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
    }

    createLoaderObject(fileType, object) {
        object.isLoading = true;
        object.text = 'Downloading XLS...';
        object.fileType = fileType;
        object.searchEvent = this.searchEvent;
    }

    saveInvoiceDetails(rowData) {
        this.eEmitIsLoading.emit(true);
        this.toggleUtilized(rowData);
    }

    hideInvoiceDetailsDialog() {
        this.displayReserveInvoiceDetailDialog = false;
        // this.getReservedInvoices();
        this.gridViewChildRef.refresh();
        this.getLastInvoiceId();
    }

    showErrorNotification(error) {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
    }

    showSuccessNotification(message, header) {
        this.notificationServcie.success(message, header);
    }

    checkPermission(permission) {
        return this.billingUIService.checkPermission(permission);
    }


    setColumns() {
        this.columns.filter((col) => {
            switch (col.field) {
                case 'employee.fullName': {
                    col.permanent = !this.showEmployee;
                    col.hidden = !this.showEmployee;
                    break;
                }
                case 'id': {
                    col.hidden = this.showAction;
                    break;
                }
                case 'uploadDoc': {
                    col.hidden = this.showAction;
                    break;
                }
                // case 'viewDoc': {
                //     col.hidden = this.showAction;
                //     break;
                // }
                case 'delete': {
                    col.hidden = !this.showDelete && this.showAction;
                    break;
                }
            }
            return col;
        });
        if (this.gridViewChildRef) {
            this.gridViewChildRef.setAllColumns();
        }
    }

    updatingUserGridConfig(columnArray) {
        var reservedInvoicesGridConfig: Map<string, string> = new Map();
        reservedInvoicesGridConfig = utils.getGridConfigMap(columnArray, this.columns);
        this.userGridConfigService.patch(utils.buildObject(reservedInvoicesGridConfig), null, GridNameEnum['RESERVED_INVOICES'])
            .subscribe((result) => {
            })
    }

    setInvoiceChartFilter(paginationEvent) {
        if (this.isFinancialYearChanged) {
            let startDate = new Date(Date.UTC(Number(this.financialYear.split('-')[0]), 3, 1, 0, 0, 0, 0));
            let endDate = new Date(Date.UTC(Number(this.financialYear.split('-')[1]), 2, 31, 0, 0, 0, 0));
            this.invoiceFilterDates = [startDate, endDate];
        } else {
            paginationEvent.sortOrder = -1;
            paginationEvent.sortField = 'billGeneratedDate';
            this.invoiceFilterDates = DateUtil.getMonthStartAndEndDate(Number(MonthEnum[this.reserveInvoiceChartEvent[0]]), this.financialYear);
        }

        var chartFilter = { "billGeneratedDate": { value: this.invoiceFilterDates, matchMode: 'dateBetween' } }
        Object.assign(paginationEvent.filters, chartFilter)
    }

    /**
     * opens the upload document 
     *
     * @param {*} row
     * @memberof ReserveCreditNotesComponent
     */
    uploadDoc(row) {
        this.setFolderConfig(row);
        this.displayUploadDocumentDialog = true;
    }

    viewDocs(row) {

    }


    /**
     * closes the upload document dialog
     *
     * @memberof ReserveCreditNotesComponent
     */
    hideUploadDocumentDialog() {
        this.displayUploadDocumentDialog = false;
    }

    /**
     * setting Folder configuration for file upload
     *
     * @param {*} row
     * @memberof ReserveCreditNotesComponent
     */
    setFolderConfig(row) {
        this.folderConfig.itemId = row.id;
        this.folderConfig.subModuleName = SIBConstants.RESERVE_INV;
        this.folderConfig.year = new Date(row.billGeneratedDate).getFullYear();
        this.folderConfig.month = DateUtil.setMonthName(new Date(row.billGeneratedDate).getMonth());
        this.folderConfig.moduleName = SIBConstants.BILLINGS + '/' + this.folderConfig.year + '/' + this.folderConfig.month + '/' + this.folderConfig.subModuleName;
    }

    /**
     * upload the files to the database
     *
     * @param {*} event
     * @memberof ReserveInvoicesComponent
     */
    setUploadedFiles(event) {
        if (event && event.length > 0) {
            this.invoiceChartService.create(event, null, AppUrls.RESERVED + AppUrls.UPLOAD_DOCUMENT + '/' + this.folderConfig.itemId).subscribe((response) => {
                this.notificationServcie.success('Document Uploaded Successfully', 'Document Upload');
                this.hideUploadDocumentDialog();
                this.gridViewChildRef.refresh();
            });
        }
    }

    viewDocuments(rowData) {
        this.rowData = rowData;
        this.displayDocumentComponent = true;
    }

    hideViewDocumentDialog() {
        this.displayDocumentComponent = false;
        this.documentEdit = false;
    }

    editPurchaseOrder(event) {
        if (event) {
            this.invoiceChartService.patch(event.purchaseOrders, null, AppUrls.RESERVE + AppUrls.EDIT_DOCUMENT + '/' + event.id).subscribe((response) => {
                this.gridViewChildRef.refresh();
                this.notificationServcie.success('File Edited Successfully', 'File Editing');
            });
        }
    }

    deletePurchaseOrder(event) {
        if (event) {
            this.documentService.deleteReserveInvoiceDocument(event.row.id, event.row.purchaseOrders.id).subscribe((response) => {
                this.gridViewChildRef.refresh();
            });
        }
    }

    /**
     * @description delete reserved invoice 
     * @author Divya Sachan
     * @date 2019-10-17
     * @param {*} rowData
     * @memberof ReserveInvoicesComponent
     */
    deleteReservedInvoice(rowData) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Reserved Invoice?',
            accept: () => {
                this.isLoading = true;
                this.invoiceChartService.delete(null, null, null, AppUrls.RESERVED_INVOICE + AppUrls.DELETE + '/' + rowData.id).subscribe(
                    (response) => {
                        if (response) {
                            this.notificationServcie.success("Reserved Invoice Deleted Successfully", "Reserved Invoice Deletion");
                            this.gridViewChildRef.refresh();
                            this.isLoading = false;
                        }
                    },
                    (error) => {
                        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Reserved Invoice Deleted unsuccessful', 'Reserved Invoice Deletion');
                        this.isLoading = false;
                    }
                );
            },
            reject: () => {
                // do nothing
            }
        });
    }

    /**
     * @description set delete column visibility
     * @author Divya Sachan
     * @date 2019-10-18
     * @memberof ReserveInvoicesComponent
     */
    setDeleteColumnVisibility() {
        if (this.checkPermission('delete:reserved-invoice')) {
            this.showDelete = true;
        } else {
            this.showDelete = false;
        }

    }

    ngOnDestroy() { }

}
