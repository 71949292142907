import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService } from "../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class FilterService extends ApiService<any, any, any> {
    urlPath: string = 'userConfigurations';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }

    post(item: any) {
        // console.log("inside post", item);
        if (navigator.onLine) {
            this.http.post('http://172.16.5.149:8000/userConfigurations', item);

        }
    }

}