import { ApiService } from "../../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Injector, Injectable } from "@angular/core";
import { Http } from "@angular/http";

@Injectable()
export class HttpConfigurationService extends ApiService<any, any, any> {
    urlPath: string = 'configurations';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }
}
