export class SIBConstants {
    //App Title
    public static APP_TITLE = "ShowItBig";

    //Customer Module Constant
    public static CUST_CREATED_SUCC = "Customer Created Successfully.";
    public static CUSTOMERS = "Customers";
    public static CUSTOMER = "Customer";
    public static CUSTOMER_GROUPS = "Customer Groups";
    public static POINT_OF_CONTACTS = "Point of Contacts";

    //Contract Module
    public static CONTRACT_NEW = "New Contract";
    public static CONTRACT_DETAILS = "Contract Details";

    //Inventory Master Module Constant
    public static INVENTORY_TITLE = "Inventory";
    public static HOARDINGS = "Hoardings";
    public static HOARDING_GROUPS = "Hoarding Groups";
    public static KIOSKS = "Kiosks";

    //Menu constants
    public static CUSTOMER_MENU = "Customer";
    public static CUSTOMERS_MENU = "Customers";
    public static CUSTOMER_GROUPS_MENU = "Customer Groups";
    public static CUSTOMER_POC_MENU = "Point of Contacts";
    public static CONTRACT_MENU = "Contract";
    public static INVENTORY_MENU = "Inventory";
    public static PLAN_MENU = "Plan";
    public static CAMPAIGN_MENU = "Campaign";
    public static OPERATIONS_MENU = "Operation";
    public static OP_KANBAN_MENU = "Operations KANBAN";
    public static OP_GRID_MENU = "Operations Grid";
    public static BILLING_MENU = "Billing";
    public static SUPERBILL_SUBMENU = "Superbills";
    public static RESERVE_INVOICES_MENU = "Reserved Invoices";
    public static RESERVE_CN_MENU = "Reserved Credit Notes";
    // public static RESERVE_INVOICES_MENU = "menu:reserve-invoices";
    public static USER_MENU = "User";
    public static USER_TERRITORY_MENU = "Territory";
    public static USER_TERRITORY_BM_MENU = "Branch Manager";
    public static USER_TERRITORY_PHOTOGRAPHER_MENU = "Photographer";
    public static USER_TERRITORY_WAREHOUSE_MENU = "Warehouse";
    public static USER_TERRITORY_MOUNTER_MENU = "Mounter";
    public static USER_USERS_MENU = "Users";
    public static USER_USERS_ROLES_MENU = "Roles";
    public static USER_USERS_PERMISSIONS_MENU = "Permissions";
    public static MASTERS_MENU = "Masters";
    public static MASTERS_CONTRACT_TYPE_MENU = "Contract Type";
    public static MASTERS_CUSTOM_AREAS_MENU = "Custom Area";
    public static MASTERS_CUSTOM_DISTRICTS_MENU = "Custom District"
    public static MASTERS_CUSTOM_CITY_MENU = "Custom City"
    public static MASTERS_INDUSTRY_MENU = "Industry/Segment";
    public static MASTERS_SETTINGS_MENU = "Settings";
    public static MASTERS_ARTICLES_MENU = "Articles";
    public static PHOTO_INTERFACE_MENU = "Photo Interface";
    public static ACTIVITY_MENU = "Activity";
    public static DOCUMENT_MENU = "Document";
    public static TASKS_MENU = "Tasks";
    public static PASSBOOK = "Passbook";
    public static TRANSACTION = "Transaction";
    public static SUGGESTION = "Suggestion";
    public static ANALYTICS_MENU = "Analytics";
    public static CAPACITY_CHART_SUBMENU = "Capacity Chart";
    public static SALES_CHART_SUBMENU = "Sales Chart";
    public static ROI_SUBMENU = "ROI";
    //Plan Module
    public static VIEW_PLAN = "View Plan";

    //Campaign Module
    public static VIEW_CAMPAIGN = "View Campaign";

    //Billing Module
    public static VIEW_SUPER_BILL = "Superbill";
    public static GENERATE_INVOICE = "Generate Invoice";
    public static VIEW_TASKS = "Invoice Tasks";

    // premissions
    public static READ_CAMPAIGNS = "read:campaigns";
    public static CREATE_CAMPAIGNS = "create:campaigns";
    public static READ_CUSTOMERS = "read:customers";
    public static CREATE_CUSTOMERS = "create:customers";
    public static READ_POCS = "read:pocs";
    public static READ_PLANS = "read:plans";
    public static CREATE_PLANS = "create:plans";
    public static READ_CONTRACTS = "read:contracts";
    public static CREATE_CONTRACTS = "create:contracts";
    public static UPDATE_CONTRACTS = "update:contracts";
    public static READ_HOARDINGS = "read:hoardings";
    public static READ_HOARDING_GROUPS = "read:hoarding-groups";
    public static UPDATE_CAMPAIGN_ITEMS = "update:campaignItems";
    public static READ_SUPERBILLS = "read:superbills";
    public static READ_INVOICE_DETAILS = "read:invoice-details";
    public static CREATE_INVOICES = "create:invoices";
    public static EDIT_INVOICES = 'edit:invoices';
    public static READ_HOARDING_OPERATIONS = 'menu:operations-hoardings';
    public static READ_OPERATIONS = 'menu:operations';
    public static VIEW_RESERVE_INVOICES = "menu:reserve-invoices";
    public static VIEW_RESERVE_CN = "menu:reserve-invoices";

    //SuperBill Module
    //Invoice list 
    public static STATUS_GEN_ICON_TOOLTIP = "New";
    public static STATUS_CAN_ICON_TOOLTIP = "Cancellation";
    public static STATUS_MOD_ICON_TOOLTIP = "Edit";
    public static STATUS_FULL_CN_APPROVED_ICON_TOOLTIP = "Full CN Approved";
    public static STATUS_PARTIAL_CN_APPROVED_ICON_TOOLTIP = "Partial CN Approved";

    //Chat Server URL
    public static CHAT_BASE_URL = "http://localhost:9009";

    // Constant for Menu (label and icon)
    public static EXPORT_XLSX_L = "Export XLSX";
    public static EXPORT_XLSX_I = "fa fa-file-excel-o";
    public static DOWNLOAD_XLSX_L = "Download XLSX";
    public static DOWNLOAD_PHTOTS_L = "Download Photos";
    public static DOWNLOAD_PHTOTS_I = "fa-image";
    public static DOWNLOAD_PPT_L = "Download PPT";
    public static DOWNLOAD_PPT_I = "fa-file";
    public static SEND_L = "Send";
    public static SEND_I = "fa-envelope";

    // passbook

    public static READ_PASSBOOK = 'read:passbook';

    public static OTHERS = "Others";
    public static ARTICLES = "Articles";

    //Sales chart constants
    public static MONTH = 'Month';
    public static SUPERBILL_AMOUNT = 'Superbill Amount';
    public static BILLED_AMOUNT = 'Billed Amount';
    public static UNBILLED_AMOUNT = 'Unbilled Amount';
    public static CAMPAIGN_DIFFERENCE = 'Campaign Difference';
    public static NEG_CAMPAIGN_DIFFERENCE = 'Campaign Difference(-ve)';
    public static POS_CAMPAIGN_DIFFERENCE = 'Campaign Difference(+ve)';
    public static INVOICE_AMOUNT = "Invoice Amount";

    // city sales chart constants
    public static CITY = 'City';

    //chart element id
    public static SALES_CHART_ID = "salesChart";
    public static CITY_SALES_CHART_ID = "citySalesChart";
    public static SUPERBILL_CHART_ID = "superbillChart";
    public static INVOICE_CHART_ID = "columnChart";

    // roles
    public static SALES_REPRESENTATIVE = "SALES_REPRESENTATIVE";

    // set module name in imagefolderconfig
    public static BILLINGS = "billings";
    public static RESERVE_CN = "reservedCN";
    public static RESERVE_INV = "reservedInvoice"

    //miscellaneous
    public static TOTAL = "Total";
    public static INVOICE = "Invoice";
    public static RESERVED_INVOICE = "Reserved Invoice";
    public static TOOLTIP = "tooltip";
    public static YEAR = "Year";
    public static INVOICE_PARAM = "invoice";
    public static MIGRATED_INVOICE_PARAM = "migratedInvoice";
    public static CREDIT_NOTE_PARAM = "creditNote";


    //users
    public static DELETION_INFORMATION = "User can not be deleted due to running Campaigns/Superbill.";
    public static RUNNING_CAMPAIGNS = "User have running campaigns!";
    public static RUNNING_SUPERBILLS = "User have open super bills!";
    public static RUNNING_CAMP_SUPERBILLS = "User have running campaigns and open super bills!";

    public static FROM = "From";
    public static TO = "To";

    public static TEMP = "TEMP";
    public static MIG = "MIG";

    public static CAMPAIGN_OPERATION = "Campaign Operation";
    public static GROUP = "Group";
    public static MONITORING = "Monitoring";

    public static PUBLIC_PAGE = "public-page/"

}


