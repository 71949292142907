import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SibChartComponent } from "./sib-chart.component";
import { ProgressSpinnerModule } from 'primeng/primeng';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProgressSpinnerModule
    ],
    declarations: [
        SibChartComponent
    ],
    exports: [
        SibChartComponent
    ]
})
export class SibChartModule{}
