import { Injectable } from "@angular/core";
import { ApiService, BASE_URL } from '../services/base-service/api.service';
import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { Suggestion } from '../modals/master/suggestion';
import { AppUrls } from '../services/urls';

@Injectable()
export class SuggestionService extends ApiService<any, any, any> {

    urlPath: string = 'suggestions';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    createSuggestion(suggestion :Suggestion){
        return this.create(suggestion, null, AppUrls.SAVE);
    }
    
}