import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sib-top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
