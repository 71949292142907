import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class TasksService extends ApiService<any, any, any> {
    urlPath: string = 'tasks';

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
    }

    getPendingTaskCount(searchEvent) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'tasks/count', searchEvent);

        }
    }
}    