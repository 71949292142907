import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { AuthGuardService } from "../../login-service/auth-guard.service";
import { AuthService } from "../../login-service/auth.service";
import { Observable } from "rxjs";
import { SIBConstants } from "../../shared/constants/SIBConstant";

@Injectable()
export class CanActivateBillViewGuardService implements CanActivate {

    constructor(
        private notificationServcie: NotificatoinsService,
        private ag: AuthGuardService,
        private as: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this.ag.canActivate(route, state)) {
            if (this.as.checkPermission(SIBConstants.READ_INVOICE_DETAILS)) {
                return true;
            } else {
                this.notificationServcie.info('Not Authorized', 'Permission not allowed');
            }
        }
    }
}
