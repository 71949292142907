<!-- {{_overlays | json}} -->
<!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyADnXYikeAj_6sZ3S2F-YWyqw-IJxuJ6Pw&libraries=places&callback=initAutocomplete"
async defer></script> -->


<div *ngIf="allowAddMarker" class="ui-g">
  <div class="ui-g-12" style="padding-top:10px;">
    <!-- <div class="ui-g-2 map-note">
      #Click on the map to add location.
    </div> -->
    <!-- <div class="ui-g-3">
      <span class="ui-float-label ui-fluid">
          <p-autoComplete [(ngModel)]="selectedPlace" [suggestions]="places" (completeMethod)="search($event)"
              styleClass="wid10" [minLength]="0" field="description" [multiple]="false" [dropdown]="true" name="place-autocomplete"
            forceSelection="true" (onSelect)="onPlaceSelect()">
          </p-autoComplete>
          <label>Search Places</label>
      </span>

    </div> -->
    <!-- <div class="ui-g-7 map-button-container"> -->
    <div class="ui-dialog-buttonpane ui-helper-clearfix map-button">
      <button type="button" class="mtab-save" [disabled]="_overlays.length === 0" pButton label="ADD LOCATION"
        (click)="addLocation()"></button>
    </div>
    <!-- </div> -->
  </div>

</div>

<!-- 
<div *ngIf="showNotification" class="ui-g">
  <div class="ui-g-12" style="padding-top:10px;">

    <div class="ui-dialog-buttonpane ui-helper-clearfix map-button">
      Latitude and Longitude are not available for the hoardings
    </div>
  </div>

</div> -->

<input #searchinput id="pac-input" class="controls" type="text" placeholder="Search Box">
<!-- <div id="map"> -->
<p-gmap #gmap [options]="options" [overlays]="overlays"
  [styleClass]="showSearch ?' hoarding-map-height' :' plan-map-height'" (onMapClick)="handleMapClick($event)"
  (onOverlayClick)="handleOverlayClick($event)" (onOverlayDragEnd)="handleDragEnd($event)"
  (onMapReady)="onMapReady($event)"></p-gmap>
<!-- </div> -->

<div class="card" id="mapLegend" *ngIf="!campaignTrackerMap">
  <img class="legendImage" src="assets/layout/images/green32.png">&nbsp; <span
    class="legendTitle padding-right-15">Available</span>
  <img class="legendImage" src="assets/layout/images/red32.png"> &nbsp;<span class="legendTitle padding-right-15">Not
    Available</span>
  <img class="legendImage" src="assets/layout/images/orange32.png"> &nbsp;<span
    class="legendTitle padding-right-15">Blocked</span>
  <img *ngIf="planCreationMap" class="legendImage" src="assets/layout/images/location-pointer.png"> &nbsp;<span
    *ngIf="planCreationMap" class="legendTitle">Selected</span>

</div>

<div class="card" id="mapLegend" *ngIf="campaignTrackerMap">
  <img class="legendImage" src="assets/layout/images/orange32.png"> &nbsp;<span
    class="legendTitle padding-right-15">Mounted</span>
  <img class="legendImage" src="assets/layout/images/green32.png"> &nbsp;<span
    class="legendTitle padding-right-15">Other sites</span>
</div>
<!-- <button type="button" pButton label="Add Location" icon="fa-plus" (click)="addLocation()"></button> -->

<p-dialog showEffect="fade" [(visible)]="dialogVisible" header="New Location" [width]="300">
  <div class="ui-g ui-fluid" *ngIf="selectedPosition">

    <div class="ui-g-2">
      <label for="lat">Lat</label>
    </div>
    <div class="ui-g-10">
      <input id="lat" type="text" readonly pInputText [ngModel]="selectedPosition.lat().toFixed(6)">
    </div>

    <div class="ui-g-2">
      <label for="lng">Lng</label>
    </div>
    <div class="ui-g-10">
      <input id="lng" type="text" readonly pInputText [ngModel]="selectedPosition.lng().toFixed(6)">
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton label="Add Marker" icon="fa-plus" (click)="addMarker()"></button>
    </div>
  </p-footer>
</p-dialog>