import { Customer } from '../KYC/customer';
import { PurchaseOrder } from '../campaigns/purchaseOrder';
import { InvoiceGstDetail } from './invoice-gst-detail';

export class InvoiceWrapper {
    id: string;
    purchaseOrders: PurchaseOrder[] = [];
    invoiceGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    customer: Customer = new Customer();
    displayName: string;
    invoiceId: string;
    isPoEdited: boolean;
}
