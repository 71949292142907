import { Customer } from "../KYC/customer";
import { Campaign } from "../campaigns/campaign";
import { User } from '../campaigns/user';

export class CampaingWrapper {
    id: string;
    displayName: string;
    campaignId: string;
    customer: Customer = new Customer();
    employee: User = new User();

    setCampaignWrapper(campaign: Campaign): CampaingWrapper {
        this.id = campaign.id;
        this.displayName = campaign.displayName;
        this.campaignId = campaign.campaignId;
        this.customer = campaign.customer;
        this.employee = campaign.employee;
        return this;
    }
}
