import { User } from "./user";

export class RemoteUser {
    user_id: string;
    connection: string = "Username-Password-Authentication";
    email:string;
    user_metadata: any;
    password:string;
    email_verified:boolean = false;
    verify_email: boolean = false;
    app_metadata: any;
    blocked: boolean = false;

    toRemote(user: User) : RemoteUser{
        this.user_id = user.authId && user.authId? user.authId: '';
        // this.connection = user.connection;
        this.email = user.emailId.toLowerCase();
        this.user_metadata = {
            firstname: user.firstName,
            lastname: user.lastName,
            number: user.number,
            gender: user.gender,
            bloodGroup: user.bloodGroup,
            city: user.city,
            state: user.state,
            district: user.district,
            postalCode: user.postalCode,
            emergencyNumber: user.emergencyContactNumber,
            contactPerson: user.emergencyContactPersonName
        },
        this.password = 'secret';
        this.email_verified;
        this.verify_email;
        // this.blocked = user.isActive;
        // this.app_metadata.roles = user.roles;
        // this.app_metadata.permissions = user.permissions;

        return this;
    }

    toRemoteUpdate(user:User): RemoteUser {
        // this.connection = user.connection;
        this.email = user.emailId.toLowerCase();
        this.user_metadata = {
            firstname: user.firstName,
            lastname: user.lastName,
            number: user.number,
            gender: user.gender,
            bloodGroup: user.bloodGroup,
            dateOfBirth: user.dateOfBirth,
            city: user.city,
            state: user.state,
            district: user.district,
            postalCode: user.postalCode,
            emergencyNumber: user.emergencyContactNumber,
            contactPerson: user.emergencyContactPersonName
        },
        this.email_verified;
        this.verify_email;
        // this.app_metadata.roles = user.roles;
        // this.app_metadata.permissions = user.permissions;

        return this;
    }

    profileUpdate(user:User): RemoteUser {
        this.user_metadata = {
            firstname: user.firstName,
            lastname: user.lastName,
            number: user.number,
            gender: user.gender,
            dateOfBirth: user.dateOfBirth,
            bloodGroup: user.bloodGroup,
            city: user.city,
            state: user.state,
            district: user.district,
            postalCode: user.postalCode,
            emergencyNumber: user.emergencyContactNumber,
            contactPerson: user.emergencyContactPersonName

        }
        return this;
    }

}