import { Component, ViewEncapsulation, AfterViewInit, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { MenuItem } from "primeng/primeng";
import { Campaign } from "../../modals/campaigns/campaign";
import { Subscription } from "rxjs";
import { Invoice } from "../../modals/billings/invoice";
import { BillingActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { AdditionalItem } from "../../modals/billings/additional-item";
import { CampaingWrapper } from "../../modals/billings/campaign-wrapper";
import { InvoiceService } from "../../services/shared/invoice.service";
import { InvoiceGstDetail } from "../../modals/billings/invoice-gst-detail";
import { AdditionalItemWrapper } from "../../modals/billings/AdditionalItemWrapper";
import { CalculationService } from "../../services/shared/calculationService";
import * as utils from '../../helpers/utils';
import { MenuConstants } from '../../shared/constants/menu-constants';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { MigratedInvoice } from '../../modals/billings/migrated-invoice';
import { MigratedInvoiceService } from '../services/migrated-invoice.service';
import { ChangesService } from '../../services/shared/changes.service';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { AppUrls } from '../../services/urls';
import { ActivityLogModuleEnum } from '../../shared/constants/activity-log-module-enum';
import * as _ from 'lodash';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { PrintConfig } from '../../modals/billings/print-config';
import * as $ from 'jquery';
import { CustomerUiService } from '../../customers/services/customer-ui.service';
import { InvoicePreviewComponent } from '../invoice-preview/invoice-preview.component';

@Component({
    selector: 'sib-generate-bill',
    templateUrl: './generate-bill.component.html',
    styleUrls: ['./generate-bill.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class GenerateBillComponent implements OnInit, AfterViewInit, OnDestroy {

    isLoading = false;
    invoiceStepItems: MenuItem[] = [];
    activeIndex = 0;
    subscription: Subscription[] = [];

    campaign: Campaign = new Campaign();

    rentRows: any[] = [];
    printRows: any[] = [];
    mountRows: any[] = [];

    disableButton = false;
    // invoice: Invoice = new Invoice();
    invoice: any;
    aInvoice: Invoice = new Invoice();
    mInvoice: MigratedInvoice = new MigratedInvoice();
    startDate: Date = null;
    endDate: Date = null;

    // selectedCompany: any;
    selectedBillingName: any;
    selectedBranch: any;
    selectedSupplierBranch: any;
    selectedPO: any;
    userRole: string = '';

    menu: MenuItem[];

    editMode = false; // to check if the component is opened in edit mode
    migratedInvoice: boolean;
    @ViewChild('invoiceDetails')
    invoiceDetails;
    noOfDays: number;
    preview = false;

    @ViewChild('invoicePreview')
    invoicePreview: InvoicePreviewComponent;
    manualGeneratedInvoiceId: string;
    invIdCreationStrategy: string;
    initialInvoiceId: string;

    // @ViewChild('editableSuperbillHoardings')
    // editableSuperbillHoardings: ElementRef;

    constructor(
        private billingActionDispatcher: BillingActionDispatcher,
        public router: Router,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private invoiceService: InvoiceService,
        private migratedInvoiceService: MigratedInvoiceService,
        private calculationService: CalculationService,
        // private nextState: RouterStateSnapshot
        private pageTitle: Title,
        private changesService: ChangesService,
        private notificationServcie: NotificatoinsService,
        private customerUiService: CustomerUiService,
    ) { }

    ngOnInit() {
        let appTitle = utils.getAppTitle();
        !appTitle ? this.pageTitle.setTitle(SIBConstants.GENERATE_INVOICE) : this.pageTitle.setTitle(SIBConstants.GENERATE_INVOICE + ' - ' + appTitle);
        this.userRole = this.customerUiService.getUserRole();
        this.menu = [
            {
                label: MenuConstants.DISCARD_L,
                icon: MenuConstants.DISCARD_I,
                command: (event) => {
                    this.discardEdit();
                }
            }
        ];


        this.invoiceStepItems = [
            { label: "Add" },
            { label: "Customize" },
            { label: "Details and Preview" },
        ];

        this.subscription.push(this.route.params.subscribe((value: { campaign: boolean, invoiceId: string, migrated: boolean }) => {
            if (value.migrated) {
                this.migratedInvoice = true;
                this.invoice = this.mInvoice;
                if (value.campaign) {
                    this.subscription.push(this.route.params.subscribe((params: { invoiceId: string }) => {
                        if (value.invoiceId) {
                            this.editMode = true;
                            this.subscription.push(this.migratedInvoiceService.getMigratedInvoice(params.invoiceId).subscribe((invoice: any) => {
                                if (invoice) {
                                    this.invoice = invoice;
                                    this.changesService.setInitialMigratedInvoice(_.cloneDeep(this.invoice));
                                    this.selectedRentItems(this.invoice.rentItems);
                                    this.selectedPrinitingItems(this.invoice.printingItems);
                                    this.selectedMountingItems(this.invoice.mountingItems);
                                }
                            }));
                        }
                    }));
                }
            } else {
                this.migratedInvoice = false;
                this.invoice = this.aInvoice;
                if (value.campaign) {
                    this.subscription.push(this.route.params.subscribe((params: { invoiceId: string }) => {
                        if (value.invoiceId) {
                            this.editMode = true;
                            this.subscription.push(this.invoiceService.getInvoice(params.invoiceId).subscribe((invoice: any) => {
                                if (invoice) {
                                    this.invoice = invoice;
                                    this.initialInvoiceId = this.invoice.invoiceId;
                                    this.changesService.setInitialInvoice(_.cloneDeep(this.invoice));
                                    this.selectedRentItems(this.invoice.rentItems);
                                    this.selectedPrinitingItems(this.invoice.printingItems);
                                    this.selectedMountingItems(this.invoice.mountingItems);
                                }
                            }));
                        }
                    }));
                }
            }

        }));
    }

    ngAfterViewInit() { }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    setCampaign(event) {
        this.campaign = event;
        if (this.invoice && this.invoice.id) {
            // do nothing
        } else {
            this.invoice.campaign = new CampaingWrapper().setCampaignWrapper(this.campaign);
            // this.invoice.billStartDate = new Date(this.campaign.campaignStartDate);
            // this.invoice.billEndDate = new Date(this.campaign.campaignEndDate);
        }
    }

    changeSelectedRentRowsValidationDates(row) {
        this.rentRows.forEach((r) => {
            if (r.customId === row.customId && new Date(this.calculationService.setToBeginning(r.validationItemStartDate)).valueOf() >= new Date(this.calculationService.setToBeginning(row.validationItemStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(r.validationItemEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(row.validationItemEndDate)).valueOf()) {
                r.validationItemStartDate = new Date(row.validationItemStartDate);
                r.validationItemEndDate = new Date(row.validationItemEndDate);
            }
        });
    }

    selectedRentItems(event) {
        // to retain the previous selected value
        if (event && event.length) {
            event.forEach((ev) => {
                let found = false;
                this.rentRows.forEach((row) => {
                    if (row.customId === ev.customId && new Date(this.calculationService.setToBeginning(row.validationItemStartDate)).valueOf() >= new Date(this.calculationService.setToBeginning(ev.validationItemStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.validationItemEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(ev.validationItemEndDate)).valueOf()) {
                        ev.validationItemStartDate = new Date(row.validationItemStartDate);
                        ev.validationItemEndDate = new Date(row.validationItemEndDate);
                        found = true;
                    }
                });
                if (!found) {
                    this.rentRows.push(ev);
                }
            });
        }

        // to delete any value which is unselected
        if (event && event.length) {
            this.rentRows.forEach((row, index) => {
                let found = false;
                event.forEach((ev) => {
                    if (row.customId === ev.customId && new Date(this.calculationService.setToBeginning(row.validationItemStartDate)).valueOf() >= new Date(this.calculationService.setToBeginning(ev.validationItemStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.validationItemEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(ev.validationItemEndDate)).valueOf()) {
                        found = true;
                    }
                });
                if (!found) {
                    this.rentRows.splice(index, 1);
                }
            });
        } else {
            this.rentRows.splice(0);
        }

        this.invoice.rentItems = this.rentRows;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
        this.changesService.createItemChangesObjectWhileInvoiceEdit("rentItems", (_.cloneDeep(this.invoice.rentItems)), this.migratedInvoice);
    }

    onRowsReorder(reorderedRows) {
        this.invoice.rowsOrder = reorderedRows;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    selectedPrinitingItems(event) {
        // to retain the previous selected value
        if (event && event.length) {
            event.forEach((ev) => {
                let found = false;
                this.printRows.forEach((row) => {
                    if (row.customId === ev.customId && ev.creative === row.creative && new Date(this.calculationService.setToBeginning(row.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(ev.serviceDate)).valueOf()) {
                        found = true;
                    }
                });
                if (!found) {
                    // this.printRows.push(ev);
                    this.printRows.push(new AdditionalItemWrapper().setWrapper(ev));
                }
            });
        }

        // to delete any value which is unselected
        if (event && event.length) {
            this.printRows.forEach((row, index) => {
                let found = false;
                event.forEach((ev) => {
                    if (ev.customId === row.customId && ev.creative === row.creative && new Date(this.calculationService.setToBeginning(row.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(ev.serviceDate)).valueOf()) {
                        found = true;
                    }
                });
                if (!found) {
                    this.printRows.splice(index, 1);
                }
            });
        } else {
            this.printRows.splice(0);
        }

        this.invoice.printingItems = [];
        this.printRows.forEach((row) => {
            this.invoice.printingItems.push(new AdditionalItem().setItem(row));
        });
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
        this.changesService.createItemChangesObjectWhileInvoiceEdit("printingItems", (_.cloneDeep(this.invoice.printingItems)), this.migratedInvoice);
    }

    selectedMountingItems(event) {
        // to retain the previous selected value
        if (event && event.length) {
            event.forEach((ev) => {
                let found = false;
                this.mountRows.forEach((row) => {
                    if (row.customId === ev.customId && ev.creative === row.creative && new Date(this.calculationService.setToBeginning(row.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(ev.serviceDate)).valueOf()) {
                        found = true;
                    }
                });
                if (!found) {
                    // this.mountRows.push(ev);
                    this.mountRows.push(new AdditionalItemWrapper().setWrapper(ev));
                }
            });
        }

        // to delete any value which is unselected
        if (event && event.length) {
            this.mountRows.forEach((row, index) => {
                let found = false;
                event.forEach((ev) => {
                    if (ev.customId === row.customId && ev.creative === row.creative && new Date(this.calculationService.setToBeginning(row.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(ev.serviceDate)).valueOf()) {
                        found = true;
                    }
                });
                if (!found) {
                    this.mountRows.splice(index, 1);
                }
            });
        } else {
            this.mountRows.splice(0);
        }

        this.invoice.mountingItems = [];
        this.mountRows.forEach((row) => {
            this.invoice.mountingItems.push(new AdditionalItem().setItem(row));
        });
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
        this.changesService.createItemChangesObjectWhileInvoiceEdit("mountingItems", (_.cloneDeep(this.invoice.mountingItems)), this.migratedInvoice);
    }

    setEditedRentRows(event) {
        this.rentRows.forEach((row) => {
            if (row.id === event.id) {
                row = event;
            }
        });
        this.invoice.rentItems = this.rentRows;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setEditedPrintingRows(event) {
        this.printRows.forEach((row) => {
            if (row.id === event.id) {
                row = event;
            }
        });
        this.invoice.printingItems = [];
        this.printRows.forEach((row) => {
            console.log("printnig cost", row.printnigCost, row);
            this.invoice.printingItems.push(new AdditionalItem().setItem(row));
        });
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setEditedMountingRows(event) {
        event.mountingCost = utils.parseValueToDecimalTwoPlaces(event.mountingCost);
        this.mountRows.forEach((row) => {
            if (row.id === event.id) {
                row = event;
            }
        });
        this.invoice.mountingItems = [];
        this.mountRows.forEach((row) => {
            this.invoice.mountingItems.push(new AdditionalItem().setItem(row));
        });
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setStartAndEndDates(event) {
        // this.startDate = new Date(event.startDate) !== null ? new Date(event.startDate) : new Date(this.campaign.campaignStartDate);
        // this.endDate = new Date(event.endDate) !== null ? new Date(event.endDate) : new Date(this.campaign.campaignEndDate);
        this.startDate = event.startDate !== null ? new Date(event.startDate) : null;
        this.endDate = event.endDate !== null ? new Date(event.endDate) : null;
        this.invoice.billStartDate = this.startDate !== null ? new Date(this.startDate) : null;
        this.invoice.billEndDate = this.endDate !== null ? new Date(this.endDate) : null;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    onActiveIndexChange() { }


    previousClick() {
        this.activeIndex = this.activeIndex - 1;
        // if (this.activeIndex === 1) {
        //     this.editableSuperbillHoardings.nativeElement.style.display = 'block';
        // } else {
        //     this.editableSuperbillHoardings.nativeElement.style.display = 'none';
        // }
    }

    nextClick() {
        this.activeIndex = this.activeIndex + 1;
        // if (this.activeIndex === 1) {
        //     this.editableSuperbillHoardings.nativeElement.style.display = 'block';
        // } else {
        //     this.editableSuperbillHoardings.nativeElement.style.display = 'none';
        // }
    }

    isFormValid(invoiceDetails) {
        if (invoiceDetails !== undefined && invoiceDetails.checkFormValidity() && (this.rentRows.length > 0 || this.printRows.length > 0 || this.mountRows.length > 0)) {
            this.disableButton = false;
        } else {
            this.disableButton = true;
        }
    }

    setSelectedCompany(event) {
        // this.selectedCompany = event;
        // this.invoice.campaign.customer = this.selectedCompany;
        this.selectedBillingName = event;
        this.invoice.campaign.customer = this.selectedBillingName;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setSelectedBranch(event) {
        this.selectedBranch = event;
        this.invoice.invoiceGstDetail = new InvoiceGstDetail().setInvoiceGstDetail(this.selectedBranch);
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setSupplier(event) {
        this.invoice.supplier = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setSelectedSupplierBranch(event) {
        this.selectedSupplierBranch = event;
        this.invoice.supplier.supplierGstDetail = new InvoiceGstDetail().setInvoiceGstDetail(this.selectedSupplierBranch);
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setSelectedPO(event) {
        this.invoice.purchaseOrders = [];
        this.selectedPO = event;
        // this.invoice.purchaseOrders.push(this.selectedPO);
        this.invoice.purchaseOrders = this.selectedPO;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setPlaceOfSupply(event) {
        this.invoice.placeOfSupply = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setInvoiceNote(event) {
        this.invoice.note = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setInternalNote(event) {
        this.invoice.internalNotes = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setSelectedInvioceFormat(event) {
        this.invoice.invoiceFormat = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setInvoiceRefId(event) {
        this.invoice.refInvoiceId = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice))
    }

    setGrouped(event) {
        this.invoice.grouped = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    setPrintConfiguration(event) {
        this.invoice.printConfiguration = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    onPrintTypeChange(event) {
        this.invoice.printConfiguration.original = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    onPrintLetterHeadChange(event) {
        this.invoice.printConfiguration.withLetterHead = event;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    previewPrintItems(printConfig: PrintConfig) {
        this.preview = true;
        this.printInvoice(printConfig);
    }

    /**
     * @description set invoiceId for manual invoice id creation flow
     * @author Divya Sachan
     * @date 2019-12-17
     * @param {string} invoiceId
     * @memberof GenerateBillComponent
     */
    setManualInvoiceId(invoiceId: string) {
        this.manualGeneratedInvoiceId = invoiceId;
        if (this.invIdCreationStrategy === 'MANUAL' && !this.migratedInvoice) {
            this.invoice.invoiceId = this.manualGeneratedInvoiceId;
            this.invoice = JSON.parse(JSON.stringify(this.invoice));
        }
    }

    /**
     * @description get invoice id creation strategy
     * @author Divya Sachan
     * @date 2019-12-20
     * @memberof GenerateBillComponent
     */
    getInvoiceIdCreationStrategy(event) {
        this.invIdCreationStrategy = event;
    }

    printItems(printConfig: PrintConfig) {
        this.preview = false;
        this.printInvoice(printConfig);
    }

    printInvoice(printConfig: PrintConfig) {
        this.checkForAddBlock();
        if (this.userRole === 'Sales Rep') {
            printConfig.withLetterHead = true;
            this.invoice.printConfiguration = printConfig;
        }
        this.invoice.rowsOrder = this.getInvoiceItemIds();
        // if (this.invIdCreationStrategy === 'MANUAL' && !this.migratedInvoice) {
        //     this.invoice.invoiceId = this.manualGeneratedInvoiceId;
        // }
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
        this.invoiceService.printInvoiceBeforeSubmission(this.invoice)
            .subscribe(
                (response) => {
                    if (response) {
                        const blob = new Blob([response], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        this.isLoading = false;
                        window.open(url, "_blank").focus(); // need to disable adblock for this
                    }
                },
                (error) => {
                    this.notificationServcie.error(error.message ? error.message : "Please refresh and retry", "Print Error");
                }
            );
    }

    checkForAddBlock() {
        if ($("#bottomAd").height() > 0) {
            console.log("ad block inctive");
        } else {
            console.log("ad block active");
            this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
        }
    }

    getInvoiceItemIds() {
        if (this.invoicePreview && this.invoicePreview.summaryGrid) {
            return this.invoicePreview.summaryGrid.getItemIds();
        }
    }

    createInvoice() {
        this.isLoading = true;
        // if (this.invIdCreationStrategy === 'MANUAL' && !this.migratedInvoice) {
        //     this.invoice.invoiceId = this.manualGeneratedInvoiceId;
        //     this.invoice = JSON.parse(JSON.stringify(this.invoice));
        // }
        if (this.invoice.id === null || this.invoice.id === undefined) {
            if (this.migratedInvoice) {
                this.billingActionDispatcher.generateMigratedBill(this.invoice);
            } else {
                this.billingActionDispatcher.generateBill(this.invoice);
            }

        } else {
            if (this.migratedInvoice) {
                this.billingActionDispatcher.submitMigratedBill(this.invoice);
            } else {
                this.billingActionDispatcher.submitBill(this.invoice);
            }

        }
        if (this.migratedInvoice) {
            this.subscription.push(this.billingActionDispatcher.isMigratedBillGenerated.subscribe((isMigratedBillGenerated) => {
                if (isMigratedBillGenerated) {
                    this.isLoading = false;
                    this.billingActionDispatcher.resetIsMigratedBillGenerated();
                    this.router.navigate(['billings/view/' + this.campaign.id]);
                }
                this.subscription.push(this.billingActionDispatcher.isMigratedBillSubmitted.subscribe((isMigratedBillSubmitted) => {
                    if (isMigratedBillSubmitted) {
                        this.billingActionDispatcher.resetIsMigratedBillSubmitted();
                        this.isLoading = false;
                        this.editMode = false;
                        this.setActivityLog();
                        // this.ngOnInit();
                        this.router.navigate(['billings/view/' + this.campaign.id]);
                    }
                }));
            }));

        } else {
            this.subscription.push(this.billingActionDispatcher.isBillGenerated.subscribe((isBillGenerated) => {
                if (isBillGenerated) {
                    this.isLoading = false;
                    this.billingActionDispatcher.resetIsBillGenerated();
                    this.router.navigate(['billings/view/' + this.campaign.id]);
                }
            }));
            this.subscription.push(this.billingActionDispatcher.isBillSubmitted.subscribe((isBillSubmitted) => {
                if (isBillSubmitted) {
                    this.billingActionDispatcher.resetIsBillSubmitted();
                    this.isLoading = false;
                    this.editMode = false;
                    this.setActivityLog();
                    // this.ngOnInit();
                    this.router.navigate(['billings/view/' + this.campaign.id]);
                }
            }));


        }

        this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
            if (isError) {
                this.isLoading = false;
                this.billingActionDispatcher.resetError();
            }
        }));
    }

    discardEdit() {
        this.isLoading = true;


        if (this.editMode) {
            if (!this.migratedInvoice) {
                this.billingActionDispatcher.editDiscardRequest(this.invoice);
            } else if (this.migratedInvoice) {
                this.migratedInvoiceService.create(this.invoice, null, '/edit/discard').subscribe((response) => {
                    this.discardInvoice();
                });
            }

        } else {
            this.router.navigate(['billings/view/' + this.campaign.id]);
        }

        if (!this.migratedInvoice) {
            this.routeDiscardInvoice();
            this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
                if (isError) {
                    this.isLoading = false;
                    this.billingActionDispatcher.resetError();
                }
            }));
        } else {

        }
    }

    routeDiscardInvoice() {
        this.subscription.push(this.billingActionDispatcher.isEditDiscardRequestSuccess.subscribe((isEditDiscardRequestSuccess) => {
            if (isEditDiscardRequestSuccess) {
                this.isLoading = false;
                this.editMode = false;
                this.billingActionDispatcher.resetIsEditDiscardRequestSuccess();
                this.discardInvoice();
            }
        }));
    }

    discardInvoice() {
        let nextState: ActivatedRouteSnapshot;
        if (nextState && nextState.url) {
            this.router.navigate([nextState.url]);
        } else {
            this.router.navigate(['billings/view/' + this.campaign.id]);
        }
    }

    setCampAndLspDiff(event) {
        this.invoice.lspDiff = event.lspDiff;
        this.invoice.campaignDiff = event.campDiff;
        this.invoice.lspDiffPctg = event.lspDiffPctg;
        this.invoice.campaignDiffPctg = event.campDiffPctg;
        this.invoice = JSON.parse(JSON.stringify(this.invoice));
    }

    resetInvoiceDetails() {
        this.invoiceDetails.resetDetails();
    }

    getChangedType() {
        if (!this.migratedInvoice) {
            return ActivityLogModuleEnum.INVOICE;
        } else {
            return ActivityLogModuleEnum.MIGRATED_INVOICE;
        }
    }

    onRentGridRowEdit(event) {
        this.callChangeServiceUpdate(this.getChangedFieldNameFromRentGridEvent(event), this.getRentChangedFieldDisplayName(event.column), this.getChangedValueFromGridEvent(event), null, this.getChagnedDescriptionFromGridEvent(event, 'customId'), "-", null);
    }

    onRentGridNumberRowEdit(event) {
        this.callChangeServiceUpdate(this.getChangedFieldNameFromRentGridEvent(event), this.getRentChangedFieldDisplayName(event.column), this.getChangedValueFromGridEvent(event), "currency", this.getChagnedDescriptionFromGridEvent(event, 'customId'), "-", null);
    }

    onRentGridCalendarChange(event) {
        this.callChangeServiceUpdate(this.getChangedFieldNameFromRentGridEvent(event), this.getRentChangedFieldDisplayName(event.column), this.getChangedValueFromGridEvent(event), "date", this.getChagnedDescriptionFromGridEvent(event, 'customId'), "-", null);
    }

    onPrintGridRowEdit(event) {
        this.callChangeServiceUpdate(this.getChangedFieldNameFromPrintGridEvent(event), this.getPrintChangedFieldDisplayName(event.column), this.getChangedValueFromGridEvent(event), null, this.getChagnedDescriptionFromGridEvent(event, 'customId'), "-", null);
    }

    onPrintGridNumberRowEdit(event) {
        this.callChangeServiceUpdate(this.getChangedFieldNameFromPrintGridEvent(event), this.getPrintChangedFieldDisplayName(event.column), this.getChangedValueFromGridEvent(event), "currency", this.getChagnedDescriptionFromGridEvent(event, 'customId'), "-", null);
    }

    onMountGridRowEdit(event) {
        this.callChangeServiceUpdate(this.getChangedFieldNameFromMountGridEvent(event), this.getMountChangedFieldDisplayName(event.column), this.getChangedValueFromGridEvent(event), null, this.getChagnedDescriptionFromGridEvent(event, 'customId'), "-", null);
    }

    onMountGridNumberRowEdit(event) {
        this.callChangeServiceUpdate(this.getChangedFieldNameFromMountGridEvent(event), this.getMountChangedFieldDisplayName(event.column), this.getChangedValueFromGridEvent(event), "currency", this.getChagnedDescriptionFromGridEvent(event, 'customId'), "-", null);
    }

    getChangedFieldNameFromRentGridEvent(event) {
        return String("rentItems/" + event.rowIndex + "." + event.column.field);
    }

    getChangedFieldNameFromPrintGridEvent(event) {
        return String("printingItems/" + event.rowIndex + "." + this.getFieldNameForTransientFields(event));
    }

    getChangedFieldNameFromMountGridEvent(event) {
        return String("mountingItems/" + event.rowIndex + "." + this.getFieldNameForTransientFields(event));
    }

    getFieldNameForTransientFields(event) {
        switch (event.column.field) {
            case "printingCost": {
                if (HoardingLightEnum[event.rowData.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[event.rowData.lightType] === HoardingLightEnum.N_L) {
                    return "printingPrice.flNlPrice";
                } else {
                    return "printingPrice.blPrice";
                }
            }
            case "mountingCost": {
                return "mountingPrice.pricePerSqFt";
            }
            default: {
                return event.column.field;
            }
        }
    }

    getChangedValueFromGridEvent(event) {
        return event.rowData[event.column.field];
    }

    getChagnedDescriptionFromGridEvent(event, field: string) {
        return String(event.rowData[field]);
    }

    getRentChangedFieldDisplayName(column) {
        switch (column.field) {
            case "rate": {
                return "Item Rate";
            }
            case "itemStartDate": {
                return "Item Start Date";
            }
            case "itemEndDate": {
                return "Item End Date";
            }
            case "cost": {
                return "Item Cost(RSD)";
            }
            case "note": {
                return "Item Note";
            }
        }
    }

    getPrintChangedFieldDisplayName(column) {
        switch (column.field) {
            case "printingCost": {
                return "Item Print Price/SqFt";
            }
            case "amount": {
                return "Item Print Charge";
            }
            case "note": {
                return "Item Note";
            }
        }
    }

    getMountChangedFieldDisplayName(column) {
        switch (column.field) {
            case "mountingCost": {
                return "Item Mount Price/SqFt";
            }
            case "amount": {
                return "Item Mount Charge";
            }
            case "note": {
                return "Item Note";
            }
        }
    }

    callChangeServiceUpdate(field: string, changedFieldDisplayName: string, value: any, formatterType: any, changeDescription: any, changeAdditionalDescription: any, changedFieldArrayVariable: string) {
        this.changesService.updateObjectMap(this.getChangedType(), field, changedFieldDisplayName, value, formatterType, changeDescription, changeAdditionalDescription, changedFieldArrayVariable);
    }

    setActivityLog() {
        this.changesService.create(this.changesService.getActivityLogs(), null, AppUrls.SAVE).subscribe(
            (response) => {
                console.log("response", response);
                this.changesService.resetData();
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
            }
        );
    }

    setNoOfDays(event) {
        this.noOfDays = event;
    }


    /**
     * sets invoice rent items
     *
     * @param {*} event
     * @memberof GenerateBillComponent
     */
    setInvoiceRentItems(event) {
        this.invoice.rentItems = event;
    }

    ngOnDestroy() {
        this.subscription.forEach(s => s.unsubscribe());
    }
}
