/**
 * @description to create an object of print config
 * @author Pulkit Bansal
 * @date 2019-10-18
 * @export
 * @class PrintConfig
 */
export class PrintConfig {
    original: boolean;
    withLetterHead: boolean;
}
