import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { PPTLoader } from "../../modals/loader-subjects/pptloader";
import { XlsLoader } from "../../modals/loader-subjects/xls-loader";
import { GridLoader } from "../../modals/loader-subjects/grid-loader";
import { PhotoLoader } from "../../modals/loader-subjects/photo-loader";

@Injectable({
    providedIn: 'root'
})
export class LoaderSubjects {
    pptLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    xlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    gridLoader: Subject<GridLoader> = new Subject<GridLoader>();
    campaignXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    invoiceXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    invoiceTallyXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    pendingUnbilledXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    photoLoader: Subject<PhotoLoader> = new Subject<PhotoLoader>();
    mountingPhotoLoader: Subject<PhotoLoader> = new Subject<PhotoLoader>();
    monitoringPhotoLoader: Subject<PhotoLoader> = new Subject<PhotoLoader>();
    flexItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    mountingItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    monitoringItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    unmountingItemsXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    superbillListXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    reservedInvoiceXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    migratedInvoiceXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    creditNoteXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    reservedCreditNoteXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    campaignPptLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    pocXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    hoardingXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    hoardingGroupXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    kioskXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    contractXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    salesChartXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    contractListXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    campaignTrackerXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    campaignTrackerPptLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    roiContractDataXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    roiContractInventoryDataXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    untappedRoiDataXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

    templateHoardingXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    templateHoardingGroupXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();
    templateKioskXlsLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

    campaignRollbackLoader: Subject<PPTLoader> = new Subject<PPTLoader>();

    loadingSubject: Subject<boolean> = new Subject<boolean>();
}