import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CreditNotesTaskCardsService extends ApiService<any, any, any> {
  urlPath: string = 'creditNotes';

  constructor(private Http: HttpClient, private Injector: Injector) {
    super(Http, Injector);
  }

  getCnTaskCount(searchEvent) {
    if (navigator.onLine) {
      return this.Http.get(this.baseUrl + 'creditNotes/task/count', searchEvent)

    }
  }
}