import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/shared/user.service';
import { User } from '../../modals/user/user';
import { SelectItem } from 'primeng/primeng';
import { ActionDispatcher } from '../../app state/action-dispatcher/action-dispatcher';
import { AreaQueryParams } from '../../modals/queryparams/area-queryparams';
import { StateService } from '../../services/shared/states.service';
import * as utils from '../../helpers/utils';
import { DistrictService } from '../../services/shared/district.service';
import { CityService } from '../../services/shared/cities.service';
import { BloodGroupEnum } from '../../shared/constants/blood-group-enum';
import { GenderEnum } from '../../shared/constants/gender-enum';
import { RemoteUser } from '../../modals/user/user-remote';
import { AuthUserAPIService } from '../../services/shared/auth-user.service';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { EmailConfigService } from '../../services/shared/email-config.service';

@Component({
  selector: 'sib-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, AfterViewInit {

  user: User = new User();
  imageName: string = 'assets/layout/images/customer-image.png';
  genders: SelectItem[] = [];
  bloodGroups: SelectItem[] = [];

  /**
   * storing state List
   * 
   * @type {any[]}
   * @memberof NewCustomerComponent
   */
  stateList: any[] = []
  filteredStateList: any[] = [];

  /**
   * storing district list and Filtered district List of autocomplete
   * 
   * @type {any[]}
   * @memberof NewCustomerComponent
   */
  districtList: any[] = [];
  filteredDistrictList: any[] = [];

  /**
   * stroing cities list and filtered City list
   * 
   * @type {any[]}
   * @memberof NewCustomerComponent
   */
  citiesList: any[] = [];
  filteredCitiesList: any[] = [];

  areaQueryParams: AreaQueryParams = new AreaQueryParams();
  sibPassword: string;
  sibConfirmPassword: string;
  sibPasswordVerified: boolean = false;

  emailSelected: any;
  isUserEmailVerified: boolean = false;
  organizationEmail: string;
  isloading: boolean = false;

  firstName: string;
  lastName: string;

  constructor(
    private userService: UserService,
    private appActionDispatcher: ActionDispatcher,
    private stateService: StateService,
    private districtService: DistrictService,
    private cityService: CityService,
    private notificationServcie: NotificatoinsService,
    private authUserApiService: AuthUserAPIService,
    private emailConfigService: EmailConfigService
  ) { }

  ngOnInit() {

    const userDetails = JSON.parse(localStorage.getItem('userInfo'));

    this.userService.get(null, '/search/byEmailId?emailId=' + userDetails[0].email)
      .subscribe((user) => {
        if (user) {
          if(user.dateOfBirth) {
            user.dateOfBirth = new Date(user.dateOfBirth);
          }
          this.user = user;
          this.firstName = this.user.firstName;
          this.lastName = this.user.lastName;
          if(this.user.organizationEmail) {
            this.emailSelected = 'orgEmail'
          } else {
            this.emailSelected = 'userEmail'
          }
        }
      })

    this.emailConfigService.get(null, "/search/emailConfig")
      .subscribe((emailConfig) => {
        this.organizationEmail = emailConfig['data']['username'];
      })

    this.fetchStates();

    Object.keys(BloodGroupEnum).forEach((key) => {
      this.bloodGroups.push({ label: BloodGroupEnum[key], value: key });
    });

    Object.keys(GenderEnum).forEach((key) => {
      this.genders.push({ label: GenderEnum[key], value: key });
    });
  }

  ngAfterViewInit() {
    /* if(this.user.organizationEmail) {
      this.emailSelected = 'orgEmail'
    } else {
      this.emailSelected = 'userEmail'
    } */
  }

  fetchStates() {
    this.areaQueryParams.countryCode = "IN";
    this.stateService.get(this.areaQueryParams)
      .subscribe((states) => {
        this.stateList = states['_embedded']['states'];
        this.filteredStateList = this.stateList;
      })
  }

  filterStateList(event) {
    this.filteredStateList = [];
    this.filteredStateList = utils.filterData(this.stateList, 'name', event);
  }

  onStateSelect() {
    this.areaQueryParams = new AreaQueryParams();
    if (this.user.state) {
      this.areaQueryParams.stateId = this.user.state.id;
      this.fetchDistricts();
    }
  }

  onSelectDistrict() {
    this.areaQueryParams = new AreaQueryParams();
    if (this.user.district) {
      this.areaQueryParams.districtId = this.user.district.id;
      this.fetchCities();
    }
  }

  fetchDistricts() {
    this.districtService.get(this.areaQueryParams)
      .subscribe((districts) => {
        this.districtList = districts['_embedded']['districts'];
        this.filteredDistrictList = this.districtList;
      })
  }

  fetchCities() {
    this.cityService.get(this.areaQueryParams)
      .subscribe((cities) => {
        this.citiesList = cities['_embedded']['cities'];
        this.filteredCitiesList = this.citiesList;
      })
  }

  filterDistrictList(event) {
    this.filteredDistrictList = [];
    this.filteredDistrictList = utils.filterData(this.districtList, 'name', event);
  }

  filterCityList(event) {
    this.filteredCitiesList = [];
    this.filteredCitiesList = utils.filterData(this.citiesList, 'name', event);
  }

  verifySibPassword() {
    if (this.sibPassword === this.sibConfirmPassword) {
      this.sibPasswordVerified = true;
    } else {
      this.sibPasswordVerified = false;
    }
  }

  verifyUserPassword(value) {
    if (value === this.user.emailConfig.password) {
      this.isUserEmailVerified = true;
    } else {
      this.isUserEmailVerified = false;
    }
  }

  updateUser() {
    this.isloading = true;
    if(this.emailSelected === 'orgEmail') {
      this.user.organizationEmail = true;
    } else {
      this.user.organizationEmail = false;
      this.user.emailConfig.username=this.user.emailId;
    }

    const userDetails = new RemoteUser().profileUpdate(this.user);
    userDetails['password'] = this.sibPassword;
    delete userDetails['email_verified'];
    delete userDetails['email'];

    this.authUserApiService.patch(userDetails, null, this.user.authId)
      .subscribe((Authuser) => {
        if (Authuser) {
          //calling the service to create user.
          this.userService.create(this.user, null, '/update')
            .subscribe(
            (user) => {
              this.notificationServcie.success("User saved Successfully", 'User Save')
              this.isloading = false;
              // this.displayUserDetailDialog = false;
            }, (error) => {
              this.isloading = false;
              this.notificationServcie.success("User save Failed", 'User Save')
            })
        }
      })
  }

  onFileSelection(event) {

  }

}
