import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { CurrencyHelperPipe } from './currencyHelperPipe';



@NgModule({
  declarations: [CurrencyHelperPipe],
  imports: [CommonModule],
  exports: [CurrencyHelperPipe]
})

export class CurrencyMainPipe { }