<div class="superbillInvoices">

    <div class="action-button-container margin-bottom--41">
        <ul class="cinline-block">

            <li class="padding-top-5">
                <button class="mtab-save" (click)="generateBill()" pButton label="GENERATE INVOICE"
                    [disabled]="!showGenerate"></button>
            </li>
            <li class="padding-top-5">

                <button *ngIf="userRole === 'Admin'" class="mtab-save" (click)="generateMigratedBill()" pButton
                    label="MIGRATED INVOICE" [disabled]="!showGenerate"></button>
            </li>
            <li class="padding-top-5 " *ngIf="itemRateChanged ">
                <i style=" color: blue; position: relative;  top: 6px;" class="fa fa-info"
                    pTooltip="Newly Approved Rates Effective"></i>
            </li>
        </ul>
    </div>

    <p-tabView (onChange)="onTabChange($event)">
        <p-tabPanel header="Invoices">
            <form [formGroup]="campaignInvoicesGroup">
                <sib-grid [showFilter]="true" name="campaignInvoices" #campaignInvoicesSibGrid
                    [columns]="campaignInvoicesColumns" [buttons]="campaignInvoicesButtonConfig"
                    [config]="campaignInvoicesGridConfig" [totalRecords]="campaignInvoicesTotalRecords"
                    (eFilterObject)="onFilter($event)" formControlName="campaignInvoicesGridControl" ngDefaultControl
                    (eEmitColumnSelection)="updatingUserGridConfig($event)" (eSort)="onSort($event)">

                    <sib-grid-column field="srNo">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <span>
                                {{rowIndex+1}}
                            </span>
                        </ng-template>
                    </sib-grid-column>

                    <sib-grid-column field="invoiceId">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <!-- (click)="viewInvoiceDetails(rowData, $event)" -->
                            <a class="link-1  after"
                                [routerLink]="['/billings/bills/view/', getEncodedURI(rowData.id)]">
                                <span
                                    *ngIf="rowData.status === 'INVOICE_CANCEL' || rowData.status === 'INVOICE_CANCEL_WITH_RETENTION'; else defaultTemplate">
                                    <!-- Sanchit Mirg 18-04-2019 Now showing invoiceId -->
                                    <!-- <del>{{rowData.id}}</del> -->
                                    <del>{{rowData.invoiceId}}</del>
                                </span>
                                <ng-template #defaultTemplate>
                                    <!-- {{rowData.id}} -->
                                    {{rowData.invoiceId}}
                                </ng-template>
                            </a>
                        </ng-template>
                    </sib-grid-column>

                    <!-- <sib-grid-column field="campaign.displayName">
                                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
                                        <div class="link-wrapper" *ngIf="admin">
                                            <a class="link-1  after" (click)="viewCampaign(rowData)">{{rowData?.campaign?.displayName}}</a>
                                        </div>
                                        <div class="link-wrapper" *ngIf="!admin">
                                            {{rowData?.campaign?.displayName}}
                                        </div>
                                    </ng-template>
                                </sib-grid-column> -->

                    <sib-grid-column field="PO">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <img class="custom-grid-icon" src="assets/layout/images/details.svg" alt="" title=""
                                (click)="showOverlay($event, rowdata, rowIndex)">
                        </ng-template>
                    </sib-grid-column>

                    <!-- <sib-grid-column field="details">
                                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
                                                <img class="custom-grid-icon" src="assets/layout/images/next.svg" alt="" title="View Details" (click)="viewInvoiceDetails(rowData)">
                                            </ng-template>
                                        </sib-grid-column> -->

                    <sib-grid-column field="status">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <div class="col-xs-0 padding-0 c-inline">
                                <span class="padding-0 float-left">
                                    <div
                                        class="square-text-small background-{{getClassForCharacter(rowIndex % rowsPerPage)}}">
                                        <span title="{{charTitleAt[rowIndex % rowsPerPage]}}"
                                            class="text-style-small pointer {{getClassForCharacter(rowIndex % rowsPerPage)}}">
                                            {{charAt[rowIndex % rowsPerPage]}}
                                        </span>
                                    </div>
                                </span>
                                <span class="padding-0">
                                    <sib-status-visualization [nodes]="statusVisualizationNodes"
                                        [selected]="pendingAt[rowIndex % rowsPerPage]"></sib-status-visualization>
                                </span>
                            </div>
                        </ng-template>
                        <!-- campaignInvoicesSibGrid.filter($event.value,col.field,col.filterMatchMode)" -->
                        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                            <p-dropdown [options]="invoiceStatusDropdown" [style]="{'width':'90%','margin-top':'8px'}"
                                appendTo="body" placeholder="Status"
                                (onChange)="billingUIService.filterInvoiceStatus(campaignInvoicesSibGrid, $event.value,col.field,col.filterMatchMode)">
                            </p-dropdown>
                        </ng-template>
                    </sib-grid-column>

                    <sib-grid-column field="campaignDiff">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <div *ngIf="rowData.campaignDiff < 0" class="negDiff">
                                {{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiff)) | CurrencyConverter}}
                            </div>
                            <div *ngIf="rowData.campaignDiff >= 0" class="posDiff">
                                {{parseValueToDecimal(rowData.campaignDiff) | CurrencyConverter}}
                            </div>
                        </ng-template>
                    </sib-grid-column>

                    <sib-grid-column field="lspDiff">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <div *ngIf="rowData.lspDiff < 0" class="negDiff">
                                {{getAbsoluteValue(parseValueToDecimal(rowData.lspDiff)) | CurrencyConverter}}
                            </div>
                            <div *ngIf="rowData.lspDiff >= 0" class="posDiff">
                                {{parseValueToDecimal(rowData.lspDiff) | CurrencyConverter}}
                            </div>
                        </ng-template>
                    </sib-grid-column>
                    <sib-grid-column field="delTempInvoice">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <div *ngIf="rowData?.status=== 'ADMIN_GEN_PENDING'">
                                <i class="fa fa-trash-o info-red size-16" title="Discard Temporary Invoice"
                                    (click)="deleteTempInvoice(rowData)" aria-hidden="true"></i>
                            </div>
                        </ng-template>
                    </sib-grid-column>

                </sib-grid>
            </form>
        </p-tabPanel>
        <p-tabPanel header="Migrated Invoices">
            <sib-superbill-migrated-list *ngIf="selectedTabIndex === 1 " [migratedInvoice]="migratedInvoice"
                [campaignId]="campaignId">
            </sib-superbill-migrated-list>
        </p-tabPanel>
        <p-tabPanel header="Credit Notes">
            <sib-credit-notes *ngIf="selectedTabIndex === 2" [showEmployee]="showEmployee" [campaignId]="campaignId"
                [showXlsExport]="false" [showTab]="showTab">
            </sib-credit-notes>
        </p-tabPanel>
    </p-tabView>



    <p-dialog header="No Address Added" [(visible)]="showAddressDialog" [modal]="true" width="425"
        (onHide)="showAddressDialog = false">
        Please add complete customer address to start generating invoices.
    </p-dialog>

</div>