<div>
  <sib-grid [title]="'ROI'" name="untappedRoiGrid" #sibGrid [totalRecords]="totalRecords"
    [columns]="untappedRoiDataColumns" [config]="gridConfig" [showFilter]="true" [showXlsExport]="showXlsExport"
    (eEmitExportXlsx)="exportXlsx()" (eEmitColumnSelection)="updatingUserGridConfig($event)">
    <sib-grid-column field="srNo">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <span>
          {{rowIndex+1}}
        </span>
      </ng-template>
    </sib-grid-column>
    <sib-grid-column field="cheques.chequeName">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        {{(rowData?.cheques?.length> 0 && rowData?.cheques[0]?.chequeName !== null)? rowData?.cheques[0]?.chequeName:"-"}}
        <a class="cheque-detail-button" (mouseleave)="chequeDetail.toggle($event)"
          (mouseenter)="openChequeDetails(rowData); chequeDetail.toggle($event)"
          (click)="openChequeDetails(rowData); chequeDetail.toggle($event)"
          *ngIf="rowData?.cheques?.length> 1">+{{rowData?.cheques?.length - 1 }} more</a>
      </ng-template>
    </sib-grid-column>
  </sib-grid>

  <p-overlayPanel [dismissable]="true" [styleClass]="'cheque-detail'" #chequeDetail>
    <h3>Cheque Details</h3>
    <ng-template let-cheque let-last="last" ngFor [ngForOf]="chequeDetails" let-i="index">
      <div class="cheque-row">{{i+1}}. {{cheque.chequeName}}</div>
    </ng-template>
  </p-overlayPanel>
</div>