import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, DropdownModule, CalendarModule, TooltipModule, ProgressSpinnerModule, DialogModule, CheckboxModule, RadioButtonModule } from "primeng/primeng";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { AddMonitoringDialogComponent } from "./add-monitoring-dialog.component";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        CheckboxModule,
        DialogModule,
        SibGridModule,
        RadioButtonModule
    ],

    declarations: [
        AddMonitoringDialogComponent
    ],

    exports: [
        AddMonitoringDialogComponent
    ],
    providers: []
})

export class AddMonitoringDialogModule { }
