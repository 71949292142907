
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { CustomerProfilePageComponent } from "./customer-profile-page.component";
import { CustomerDetailsComponent } from "./customer-details/customer-details.component";
import { CustomerPlansComponent } from "./customer-plans/customer-plans.component";
import { CustomerCampaignsComponent } from "./customer-campaigns/customer-campaigns.component";

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule
    ],

    declarations: [
        CustomerProfilePageComponent,
        CustomerDetailsComponent,
        CustomerPlansComponent,
        CustomerCampaignsComponent
    ],

    exports: [
        CustomerProfilePageComponent,
        CustomerDetailsComponent,
        CustomerPlansComponent,
        CustomerCampaignsComponent
    ]
})

export class CustomerProfilePageModule { }
