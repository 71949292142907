import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../../services/base-service/api.service";

@Injectable()
export class CityService extends ApiService<any, any, any> {
    urlPath: string = 'cities';
    baseUrl: string;


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
    }


}