
import { empty as observableEmpty, of as observableOf, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { NotificatoinsService } from "../notifications/notifications.service";
import { InvoiceChartService } from './invoice-chart.service';
import { PrintWrapper } from '../../modals/billings/print-wrapper';
import { Invoice } from '../../modals/billings/invoice';

@Injectable()
export class InvoiceService extends ApiService<any, any, any> {
    urlPath: string = 'invoices/search';
    baseUrl: string;

    constructor(
        private Http: HttpClient,
        private Injector: Injector,
        private notificationServcie: NotificatoinsService,
        private invoiceChartService: InvoiceChartService
    ) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getInvoice(id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/byId', { params: { id: decodeURIComponent(id) } });

        }
    }

    getTempInvoice(id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'tempInvoices/' + id + '?projection=tempInvoiceProjection');

        }
    }

    getAdminPendingInvoices(paginationEvent, searchEvent, subpath) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + subpath, searchEvent);

        }
    }

    getAccountantPendingInvoices(paginationEvent, searchEvent, subpath) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + subpath, searchEvent);

        }
    }

    printInvoiceWithDetailsWithLetterhead(invoiceId, isDuplicate, itemIds = null) {
        // headers: { fileName: 'newFile.pdf' }
        // return this.Http.get(this.baseUrl + 'invoices/generatePDF/withDetail', { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } }).pipe(map((response) => {

        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generatePDF/withDetail', itemIds, { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } }).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                console.log(err);
                this.notificationServcie.error("Print Error", "Please refresh and retry");
                return observableOf(err);
            }));
        }

    }

    printInvoiceWithDetailsWithoutLetterhead(invoiceId, isDuplicate, itemIds = null) {
        // headers: { fileName: 'newFile.pdf' }
        // return this.Http.get(this.baseUrl + 'invoices/generatePDF/withoutLetterHead/withDetail', { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } }).pipe(map((response) => {

        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generatePDF/withoutLetterHead/withDetail', itemIds, { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } }).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                console.log(err);
                this.notificationServcie.error("Print Error", "Please refresh and retry");
                return observableOf(err);
            }));
        }

    }

    printInvoiceWithoutDetailsWithLetterhead(invoiceId, isDuplicate, itemIds = null) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/generatePDF/withoutDetail', { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } }).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                console.log(err);
                this.notificationServcie.error("Print Error", "Please refresh and retry");
                return observableOf(err);
            }));
        }

    }

    printInvoiceWithoutDetailsWithoutLetterhead(invoiceId, isDuplicate, itemIds = null) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/generatePDF/withoutLetterHead/withoutDetail', { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } }).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                console.log(err);
                this.notificationServcie.error("Print Error", "Please refresh and retry");
                return observableOf(err);
            }));
        }

    }

    printInvoice(printWrapper: PrintWrapper, id: string): Observable<any> {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generatePDF', printWrapper, { responseType: "arraybuffer", params: { id: id } });

        }
    }

    printInvoiceBeforeSubmission(invoice: Invoice): Observable<any> {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generatePDFBeforeSubmission', invoice, { responseType: "arraybuffer" });
        }
    }

    sendMail(correspondence) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/send/mail', correspondence).pipe(map((response) => {
                return response;
            }),
                catchError((err) => {
                    this.notificationServcie.error(err.error.message, 'Send Mail');
                    return observableEmpty();
                }));
        }
    }

    markInvoiceAsPrinted(value, id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/mark/printed', { params: { 'invoiceId': id, 'printed': value } }).pipe(map((response) => {
                return response;
            }), catchError((error) => {
                console.log(error);
                this.notificationServcie.error(error.error.message, "Please refresh and retry");
                return observableOf(error);
            }));
        }
    }

    markInvoiceAsCustomerReceived(value, id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/mark/customerReceived', { params: { 'invoiceId': id, 'received': value } }).pipe(map((response) => {
                return response;
            }), catchError((error) => {
                console.log(error);
                this.notificationServcie.error(error.error.message, "Please refresh and retry");
                return observableOf(error);
            }));
        }
    }

    markInvoiceAsTallyEntered(value, id, tallyId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/mark/tallyEntered', { params: { 'invoiceId': id, 'tallyEntered': value, 'tallyId': tallyId } }).pipe(map((response) => {
                return response;
            }), catchError((error) => {
                console.log(error);
                this.notificationServcie.error(error.error.message, "Please refresh and retry");
                return observableOf(error);
            }));
        }
    }

    markInvoiceAsGrouped(invoiceId, grouped) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/mark/grouped', { params: { 'invoiceId': invoiceId, 'grouped': grouped } });
        }
    }

    deleteInvoiceItem(subpath) {

        this.urlPath = 'invoices';
        if (navigator.onLine) {
            return this.delete(null, null, null, subpath)
        }
    }

    getSrTaskCount(searchEvent) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/search/sr/pending/count', searchEvent);
        }
    }
}
