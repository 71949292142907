<div class="count-card">
    <div class="header">
        <h3 class="heading-tertiary">
            Customers
        </h3>
    </div>
    <hr>
    <div class="counts">
        <div class="total-counts">
                <div class="total-count-child">
                        <a [routerLink]="['/customers']" title="Go to Customers">
                            {{total}}
                        </a>
                </div>
        </div>
        <div class="individual-count">
            <table>
                <tr *ngFor="let item of customerCounts">
                    <td class="detail-text">{{item?.status }}</td>
                    <td class="detail-count">{{item?.count}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>