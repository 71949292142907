<div class="pendingUnbilledTasks">
    <!-- <div *ngIf="isLoading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div> -->
    <div class="flex-item-container">
        <ul class="filter-cards-list"
            [ngClass]="{'list-margin': !(pendingUnbilledTasks && pendingUnbilledTasks.length)}">
            <li *ngFor="let card of filterCards; let i = index"
                [ngClass]="{'selected':check(card, i),'':!check(card, i)}" class="card-dim card card-color"
                (click)="filterData(card, i)">
                <div class="card-value">
                    {{card.value}}
                </div>
            </li>
        </ul>
    </div>
    <ul class="showTotal showColMargin">
        <!-- [ngClass]="{'showColMargin': checkPermission('readAll'), 'notShowColMargin': !checkPermission('readAll')}" -->
        <li *ngIf="pendingUnbilledTasks && pendingUnbilledTasks.length" class="showTotalText showTotalMargin">
            Total Unbilled Amount = ₹ {{getIntegerRoundedOffValue(totalUnbilledAmount) | CurrencyConverter}}
        </li>
        <li class="xlsMargin">
            <!--  [ngClass]="{'xlsMargin': checkPermission('readAll')}" -->
            <label class="font-12 font-bold margin-right-5">Download XLSX</label>
            <button pButton class="mtab-secondary c-edit-btn border-rad-2" title="Download XLSX"
                (click)="downloadXLSX()" icon="fa fa-file-excel-o"></button>
        </li>
    </ul>
    <div>
        <form [formGroup]="pendingUnbilledTaskGroup">
            <sib-grid name="pendingUnbilledTaskGrid" #sibGrid [buttons]="buttonConfig" [totalRecords]="totalRecords"
                [showFilter]="true" [showColorLegends]="false" (eEmitColumnSelection)="updatingUserGridConfig($event)"
                [columns]="columns" [config]="gridConfig" formControlName="pendingUnbilledTaskGridControl"
                ngDefaultControl>
                <!-- [showFilter]="checkPermission('readAll')"  -->
                <sib-grid-column field="srNo">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span>
                            {{rowIndex+1}}
                        </span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="displayName">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <div class="link-wrapper">
                            <a class="link-1  after" target="_blank"
                                [routerLink]="['/billings/view/', rowData.campaignId]">{{rowData.displayName}}</a>
                        </div>
                    </ng-template>
                </sib-grid-column>

            </sib-grid>
        </form>
    </div>
</div>