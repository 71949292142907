
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, DialogModule, SharedModule, ProgressBarModule, ProgressSpinnerModule, InputTextModule, TabViewModule, CalendarModule, DropdownModule, TooltipModule, PaginatorModule } from "primeng/primeng";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PassbookComponent } from './passbook.component';
import { PassbookService } from './services/passbook.service';
import { PassbookUIService } from './services/passbook-ui.service';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        ReactiveFormsModule,
        FormsModule,
        CalendarModule,
        DropdownModule,
        TooltipModule,
        CurrencyMainPipe,
        PaginatorModule,
        DialogModule,
    ],

    declarations: [
        PassbookComponent,
    ],

    exports: [
        PassbookComponent
    ],

    providers: [
        PassbookService,
        PassbookUIService
    ]
})

export class PassbookModule { }
