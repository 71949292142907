import { Injectable } from "../../../../../../node_modules/@angular/core";
import { GridColumn, GridConfig } from '../../../../components/sib-forms/grid/grid.config';

Injectable()
export class SetGridService {

    setColumns(module) {

    }

    setGridConfig(module) {

    }

    setGridButtonConfig(module) {

    }

}

