import { Campaign } from '../campaigns/campaign';
import { User } from '../campaigns/user';
import { InvoiceStatus } from '../../shared/constants/invoice-status-enum';
import { RentItem } from '../billings/rent-item';

export class SalesHoardings {
    billEndDate: Date;
    billGeneratedDate: Date;
    billStartDate: Date;
    campaign: Campaign = new Campaign();
    employee: User = new User();
    invoiceId: string;
    rentItems: RentItem = new RentItem();
    printingItems: RentItem = new RentItem();
    mountingItems: RentItem = new RentItem();
    status: InvoiceStatus;
}