export enum ModulesEnum {
    CUSTOMER = 'Customer',
    CONTRACT = 'Contract',
    INVENTORY = 'Inventory',
    PLAN = 'Plan',
    CAMPAIGN = 'Campaign',
    OPERATION = 'Operation',
    BILLING = 'Billing',
    USER = 'User',
    MASTERS = 'Masters',
    PHOTOINTERFACE = 'Photo Interface',
    ACTIVITY = 'Activity',
    DOCUMENTS = 'Document'
}