import { Component, OnInit, ElementRef, ViewEncapsulation, Output, EventEmitter, OnDestroy, ViewChild, HostListener, AfterViewInit, Input } from '@angular/core';
import { MonitoringImage } from '../../../../../modals/photo-interface/monitoring-image';
import { PhotoInterfaceService } from '../../../../../services/shared/photo-interface.service';
import * as fileUtils from '../../../../../helpers/file.util';
import { MenuItem, SelectItem, DataScroller } from 'primeng/primeng';
import { PaginationData } from '../../../../../modals/paginationdata';
import { MenuConstants } from '../../../../constants/menu-constants';
import { Subscription } from 'rxjs';
import { PhotoInterfaceGlobalSrevice } from '../services/photo-interface-global.service';
import * as utils from '../../../../../helpers/utils';
import { ImageTypeEnum } from '../../../../constants/image-type-enum';
import { PhotoDialogComponent } from '../../../photo-dialog/photo-dialog.component';
import { ImageDataDisplay } from '../../../../../modals/image/image-data';
import { AppUrls } from '../../../../../services/urls';
import { SearchFilter } from '../photo-interface.component';
import * as _ from "lodash";

@Component({
  selector: 'sib-monitoring-image',
  templateUrl: './monitoring-image.component.html',
  styleUrls: ['./monitoring-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MonitoringImageComponent implements OnInit, AfterViewInit, OnDestroy {

  _activeIndex = -1;
  @Input() set activeIndex(activeIndex) {
    this._activeIndex = activeIndex;
    this.getData();
  }

  get activeIndex() {
    return this._activeIndex;
  }

  @Output() eEmitSelectedMonitoringImages: EventEmitter<any> = new EventEmitter();
  @Output() eEmitImagesAndIndex: EventEmitter<any> = new EventEmitter();

  monitoringImages: MonitoringImage[] = [];
  imageObjs: any[];
  index: number;
  selectedMonitoringImages: MonitoringImage[] = [];
  subscription: Subscription[] = [];
  // searchData: PaginationData = {
  //   globalFilter: ''
  // };
  searchData: SearchFilter = {
    filters: '',
    globalFilter: ''
  };
  filteredImages: MonitoringImage[] = [];
  globalFilterFields = ['customId', 'city', 'location', 'size', 'campaign.displayName'];
  monitoringFilterOptions: SelectItem[] = [];
  scrollHeight: string = (window.innerHeight - 100) + 'px';
  filterField = "monitoringItems.images.imageType"; // used to set filter object in parent component
  isLoading = false; // for loader
  showMonitoringPhotoDialog = false;
  imagesPerPage = 20; // no of images to display at a time
  checked: any[] = []; // to show pre selected images
  email: string; // to get the logged in user email
  authorized = false; // to check if the logged in user is authorized for all - currently admin and accountant

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScrollHeightAndImagesPerPage();
  }

  totalMonitoringImagesCount: number;
  @ViewChild('dscroller') dscroller: DataScroller;
  // @ViewChild('loadButton') loadbutton: ElementRef;
  onInlineScroll: any;
  dscrollerPaginationEvent:PaginationData
  constructor(
    private photoInterfaceService: PhotoInterfaceService,
    private photoInterfaceGlobalSrevice: PhotoInterfaceGlobalSrevice
  ) { }

  ngOnInit() {
    this.setMonitoringFilterOptions();
    // this.getData(); // commented for tab load
    // this.setMenus();
    this.subscription.push(this.photoInterfaceGlobalSrevice.globalFilter.subscribe((globalFilterData) => {
      this.searchData.globalFilter = globalFilterData;
      // this.getData();
      this.setGlobalFilterInPaginationData()
      // this.filterData(this.searchData);
    }));
    this.subscription.push(this.photoInterfaceGlobalSrevice.filterObject.subscribe((filterValue) => {
      if (filterValue.selectedFilter.tabIndex === 1 && filterValue.selectedFilter.filter) {
        // this.onFilterChange(filterValue.selectedFilter.filter);
        this.searchData.filters = [...filterValue.selectedFilter.filter];
        this.setImageTypeFilter()
        // this.filterData(this.searchData);
      }
    }));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const roles = userInfo[0].app_metadata.authorization.roles;
    this.email = userInfo[0].email;
    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    });
  }

  ngAfterViewInit() {
    this.setScrollHeightAndImagesPerPage();
    this.onInlineScroll = this.inlineScrollerListener.bind(this);
    this.dscroller.contentViewChild.nativeElement.addEventListener('scroll', this.onInlineScroll);
    // this.displayLoaderButton(false);
  }

  inlineScrollerListener() {
    let scrollTop = this.dscroller.contentViewChild.nativeElement.scrollTop;
    let scrollHeight = this.dscroller.contentViewChild.nativeElement.scrollHeight;
    let viewportHeight = this.dscroller.contentViewChild.nativeElement.clientHeight;

    /* if((scrollTop >= ((scrollHeight) - (viewportHeight)))) {
      this.displayLoaderButton(true);
    } else {
      this.displayLoaderButton(false);
    } */
  }

  /* displayLoaderButton(show: boolean) {
    if(!show) {
      this.loadbutton.nativeElement.style.display = 'none'      
    } else if(show) {
      this.loadbutton.nativeElement.style.display = 'inline'
      
    }
  } */

  setScrollHeightAndImagesPerPage() {
    this.scrollHeight = (window.innerHeight - 50) + 'px';
    this.imagesPerPage = utils.getIntegerValue(Number(this.scrollHeight.split('px')[0]) / 100) * 5; // 100 - random vlaue < 150 (image height), 5 - images in a line
  }

  setMonitoringFilterOptions() {
    this.monitoringFilterOptions = utils.createDropdown(ImageTypeEnum, false);
  }

  getData() {
    if (this.activeIndex === 1) { // added for tab load
      this.isLoading = true;
      // this.photoInterfaceService.create(this.searchData, this.searchData, AppUrls.MONITORING_ITEM)
      this.photoInterfaceService.create(this.dscrollerPaginationEvent, this.dscrollerPaginationEvent, AppUrls.MONITORING_ITEM)
        .subscribe((response) => {
          this.checked = [];
          // this.monitoringImages = [];
          if(this.dscrollerPaginationEvent.page === 0) {
            this.monitoringImages = [];
          }
          this.monitoringImages = [...this.monitoringImages,...response['data']['content']];
          this.monitoringImages.sort(this.customSort);
          this.totalMonitoringImagesCount = response['data'].totalElements;
          this.filteredImages = _.cloneDeep(this.monitoringImages);
          this.filteredImages = _.uniqBy(this.filteredImages, 'monitoringItems.images.id');
          this.imageObjs = [];
          this.monitoringImages.forEach((img) => {
            this.imageObjs.push({ source: img.monitoringItems.images.url, alt: fileUtils.createImageDescription(img) });
          });
          this.setSelectedImages();
          // this.filterData(this.searchData);
          this.isLoading = false;
        });
    }
  }

  customSort(item1, item2) {
    return (new Date(item2.monitoringItems.monitoringDate).valueOf() - new Date(item1.monitoringItems.monitoringDate).valueOf());
  }

  onFilterChange(event) {
    if (event) {
      // this.searchData.filters = { "monitoringItems.images.imageType": { value: [...event], matchMode: 'in' } };
      // this.getData();
      this.searchData.filters = [...event];
      this.filterData(this.searchData);
    }
  }

  filterData(event: SearchFilter) {
    const filteredDataFromDropdownMultiSelect = this.filterDropdown(event.filters);
    const filteredDataFromGlobalFilter = this.filterGlobal(event.globalFilter);
    if (filteredDataFromDropdownMultiSelect && filteredDataFromGlobalFilter) {
      this.filteredImages = filteredDataFromDropdownMultiSelect.filter(item => filteredDataFromGlobalFilter.some(itm => item.monitoringItems.images.id === itm.monitoringItems.images.id));
    } else {
      if (!filteredDataFromDropdownMultiSelect && !filteredDataFromGlobalFilter) {
        this.filteredImages = _.cloneDeep(this.monitoringImages);
      } else if (!filteredDataFromDropdownMultiSelect) {
        this.filteredImages = filteredDataFromGlobalFilter;
      } else {
        this.filteredImages = filteredDataFromDropdownMultiSelect;
      }
    }
    this.imageObjs = [];
    this.filteredImages.forEach((img) => {
      this.imageObjs.push({ source: img.monitoringItems.images.url, alt: fileUtils.createImageDescription(img) });
    });
    this.setSelectedImages();
  }

  filterDropdown(value) {
    if (value && value.length > 0) {
      return this.monitoringImages.filter((item) => {
        if (value.includes(item.monitoringItems.images.imageType)) {
          return item;
        }
      });
    } else {
      return null;
    }
  }

  filterGlobal(value) {
    if (value && value.length > 0) {
      return this.monitoringImages.filter((item, index) => {
        let found = false;
        this.globalFilterFields.forEach((field) => {
          // let splitField: any = [];
          // if (field.includes('.')) {
          //     splitField = field.split('.');
          //     if (item[splitField[0]] !== null && item[splitField[0]][splitField[1]] !== null && item[splitField[0]][splitField[1]].includes(value)) {
          //         found = true;
          //     }
          // } else
          if (field === 'campaign.displayName') {
            if (item.campaign && item.campaign.displayName.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
              found = true;
            }
          } else if (item[field].toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
            found = true;
          }
        });
        if (found) {
          return item;
        }
      });
    } else {
      return null;
    }
  }

  // setGlobalFilter(value) {
  //   this.searchData.globalFilter = value;
  //   this.getData();
  // }

  downloadSelectedImages() {
    const imgs: { name: string, url: string, description?: string }[] = [];
    const fileName = "monitoring";
    this.selectedMonitoringImages.forEach((image) => {
      imgs.push({ name: image.monitoringItems.images.name, url: image.monitoringItems.images.url, description: fileUtils.createImageDescription(image, image.monitoringItems.images.name) });
    });
    fileUtils.createFileBlobs(imgs, fileName);
  }

  /**
   *called from photo interface component
   *
   * @memberof MonitoringImageComponent
   */
  downloadAllImages() {
    const imgs: { name: string, url: string | ArrayBuffer, description?: string }[] = [];
    const fileName = "monitoring";
    this.filteredImages.forEach((image) => {
      imgs.push({ name: image.monitoringItems.images.name, url: image.monitoringItems.images.url, description: fileUtils.createImageDescription(image, image.monitoringItems.images.name) });
    });
    fileUtils.createFileBlobs(imgs, fileName);
  }

  downloadImage(image, index) {
    const url = image.monitoringItems.images.url;
    const name = this.imageObjs[index].alt;
    const extension = fileUtils.getImageExtension(image.monitoringItems.images.name);
    fileUtils.downloadImageFile(url, name, extension);
  }

  // viewCampaign(imageObj) {
  //   window.open('/campaigns/view/' + imageObj.campaign.id);
  // }

  onCheckboxSelectionChange(event, imageObj, index) {
    if (event) {
      this.selectedMonitoringImages.push(imageObj);
    } else {
      this.selectedMonitoringImages = this.selectedMonitoringImages.filter((item) => {
        if (item.monitoringItems.images.id !== imageObj.monitoringItems.images.id) {
          return item;
        }
      });
    }
    // this.setMoreMenu();
    this.eEmitSelectedMonitoringImages.emit(this.selectedMonitoringImages);
  }

  onImageClick(index) {
    this.index = index;
    const imageData: any[] = [];
    this.filteredImages.forEach((img) => {
      imageData.push(new ImageDataDisplay().getImageData(img, img.monitoringItems.images));
    });
    this.eEmitImagesAndIndex.emit({ images: imageData, index: index });
  }

  setSelectedImages() {
    // this.monitoringImages.forEach((image) => {
    this.checked = [];
    this.filteredImages.forEach((image) => {
      const index = this.selectedMonitoringImages.findIndex(item => item.monitoringItems.images.id === image.monitoringItems.images.id);
      this.checked.push((index !== -1) ? true : false);
    });
  }

  lazyLoadHandler(event) {
    this.setScrollHeightAndImagesPerPage();
    this.setPaginationData(event)
    this.getData()
  }

  setPaginationData(event) {
    this.dscrollerPaginationEvent = {
      page: this.dscroller.page,
      size: this.imagesPerPage,
      filters: this.searchData.filters? {'monitoringItems.images.imageType': {value:this.searchData.filters, matchMode: 'in'}}: {},
      globalFilter: this.searchData.globalFilter? this.searchData.globalFilter: '',
      sortField: 'monitoringItems.monitoringDate',
      sortOrder: 1
    }
  }

  setGlobalFilterInPaginationData() {
    if(this.searchData.globalFilter || this.searchData.globalFilter === '') {
      this.dscrollerPaginationEvent.globalFilter = this.searchData.globalFilter;
      this.dscrollerPaginationEvent.page = 0;
      this.getData();
    }
  }

  setImageTypeFilter() {
    if(this.searchData.filters.length>0) {
      Object.assign(this.dscrollerPaginationEvent.filters, {'monitoringItems.images.imageType': {value:this.searchData.filters, matchMode: 'in'}})
      this.dscrollerPaginationEvent.page = 0;
      this.getData();
    } else if(this.searchData.filters.length ===0) {
      this.dscrollerPaginationEvent.filters= {};
      this.dscrollerPaginationEvent.page = 0;
      this.getData()
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
  }

}
