import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, InputTextModule, SharedModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ContractTypeDialogComponent } from "./contract-type-dialog.component";
import { DirectiveModules } from '../../../modules/directive.modules';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        ProgressSpinnerModule,
        DirectiveModules
    ],

    declarations: [

        ContractTypeDialogComponent
    ],

    exports: [
        ContractTypeDialogComponent
    ]
})

export class ContractTypeDialogModule { }