import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[sibTitlecase]'
})
export class TitlecaseDirective {

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('keyup') onKeyUp() {
    var str = this.el.nativeElement.value;
    var end = this.el.nativeElement.selectionEnd;
    var pos =this.el.nativeElement.selectionStart;
   if (pos === end) {
     //can allow the whole text in the uppercase
    // str = str.toLowerCase();
    str = str.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    this.el.nativeElement.value = str.join(' ')
    this.el.nativeElement.selectionEnd= pos;
   }
  }
  
}
