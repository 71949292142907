<div class="sib-grid">
  <form class="sib-grid-form">
    <div
      *ngIf="showSearch || showFilter || sitesAvailableFrom || showColorLegends || _actionButtons?.length > 0 || config.showGlobalFilter || showDefaultSort || showXlsExport || showJsonExport || showXmlExport || showTallyXlsExport"
      class="ui-g grid-header-div">
      <!-- *ngIf="!showSearch" -->
      <!-- <div >
        <div class="ui-g-6 ui-md-1 padding-0">
          <h2>Testing</h2>
        </div>
      </div> -->

      <div class="ui-g-12 ui-md-12 padding-0   margin-bottom-4">

        <div class="ctext-right">
          <!-- margin-left-10 -->
          <ul class="cinline-block action-item-list"
            *ngIf="showFilter || sitesAvailableFrom || showColorLegends || _actionButtons?.length > 0 || config.showGlobalFilter || showDefaultSort || showXlsExport || showJsonExport || showXmlExport || showTallyXlsExport">
            <!-- float-left  -->
            <li *ngIf="showToggleSwitch" class="margin-top-5 padding-left-5">
              <label
                class="font-12 font-bold label-vCenter padding-right-5 toggle-switch-label">{{toggleSwitchLabel}}</label>
              <p-inputSwitch [styleClass]="'toggle-switch'" name="toggleSwitch" [(ngModel)]="checked"
                (onChange)="onToggle($event)">
              </p-inputSwitch>
            </li>
            <li *ngIf="config.showGlobalFilter" class="margin-left-0 margin-right-10">
              <div class="ui-widget-header global-search-container">

                <input #gb type="text" pInputText size="50" placeholder="Global Filter"
                  (keyup)="globalFiltering(gb.value)" class="global-search-textbox"
                  pTooltip="{{getCommaSeparatedFields()}}" tooltipPosition="left">
                <span class="search-wrapper">
                  <i class="fa fa-search"></i>
                </span>


              </div>
            </li>

            <li *ngIf="showTallyXlsExport" class="margin-top-5 padding-left-5">
              <label class="font-12 font-bold label-vCenter padding-right-5">Export TALLY</label>
              <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn" (click)="exportTallyXls()"
                title="Export Tally Xls" icon="fa fa-download">
              </button>
            </li>

            <li *ngIf="showXlsExport" class="margin-top-5 padding-left-5">
              <label class="font-12 font-bold label-vCenter padding-right-5">Export XLSX</label>
              <!-- <button class="mtab-primary" pButton label="EXPORT XLSX" (click)="exportXls()"></button> -->
              <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn" (click)="exportXls()"
                title="Export Xlsx" icon="fa fa-file-excel-o">
              </button>
            </li>

            <li *ngIf=" showJsonExport" class="margin-top-5 padding-left-5">
              <label class="font-12 font-bold label-vCenter padding-right-5">Export JSON</label>
              <!-- <button class="mtab-primary" pButton label="EXPORT XLSX" (click)="exportXls()"></button> -->
              <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn" (click)="exportJson()"
                title="Export Json" icon="fa fa-download">
              </button>
            </li>

            <li *ngIf="showXmlExport" class="margin-top-5 padding-left-5">
              <label class="font-12 font-bold label-vCenter padding-right-5">Export XML</label>
              <!-- <button class="mtab-primary" pButton label="EXPORT XLSX" (click)="exportXls()"></button> -->
              <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn" (click)="exportXml()"
                title="Export Xml" icon="fa fa-download">
              </button>
            </li>

            <li *ngIf="showXslxImport" class="margin-top-5 padding-left-5">
              <label class="font-12 font-bold label-vCenter padding-right-5">Import XLSX</label>
              <!-- <button class="mtab-primary" pButton label="EXPORT XLSX" (click)="exportXls()"></button> -->
              <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn" (click)="importXlsx()"
                title="Import Xslx" icon="fa fa-download">
              </button>
            </li>
            <!-- float-left -->
            <li *ngIf="showFilter" class="margin-top-5 padding-left-5">
              <!-- <div> -->
              <!-- selectedItemsLabel="{0} selected" -->
              <p-multiSelect [options]="modifiedColumn" defaultLabel="Show Columns" [style]="{'width':'132px'}"
                maxSelectedLabels="0" (onChange)="setColumnsToDisplay()" (onPanelHide)="onHideColumnFilter()"
                name="name" [(ngModel)]="selectedColumn" [dropdownIcon]="'fa fa-cog'" appendTo="body"
                [displaySelectedLabel]="false" [styleClass]="'show-column-multi'">
              </p-multiSelect>
              <!-- </div> -->
            </li>

            <li *ngIf="!title && config.showRefreshIcon"><i class="fa fa-repeat refreshIconNoTitle"
                (click)="refreshFormTable()" pTooltip="Refresh"></i>
            </li>

            <li *ngIf="sitesAvailableFrom" class="float-left margin-left-10 margin-top-5">
              <div *ngIf="sitesAvailableFrom" class="margin-top--2">
                <p-calendar shortYearCutoff="100" styleClass="calWidth" pTooltip="Ex - 01Jan18" dateFormat="ddMy"
                  appendTo="body" placeholder="Sites From" (onSelect)="selectSitesAvailableFrom($event)"
                  (onInput)="inputSitesAvailableFrom($event)" [utc]="true" [showIcon]="true"></p-calendar>
              </div>
            </li>
            <li *ngIf="showColorLegends && colorLegendsStatus">
              <div id="legend-div">
                <div *ngIf="showColorLegends && colorLegendsStatus">
                  <ul class="horizontal-list">
                    <!-- <li *ngFor="let status of colorLegendsStatus">
                      <a href="javascript:void(0)" class="{{status.value}} legends" (click)="filterByStatus(status.value)">
                        <span class='one' data-icon="refresh">
                          {{status.label}}
                        </span>
                      </a>
                    </li> -->
                    <!-- {{selectedStatus | json}} -->
                    <li *ngFor="let status of selectedStatus; let i = index">
                      <div class="{{status}} status-label">
                        <span class="status-background">
                          {{getStatus(status)}}
                        </span>
                        <i class="fa fa-times" (click)="removeStatus(i)"></i>
                      </div>
                    </li>

                    <li style="margin-left:20px;">
                      <p-multiSelect [options]="colorLegendsStatus" defaultLabel="" [style]="{'width':'132px'}"
                        maxSelectedLabels="0" [(ngModel)]="selectedStatus" [dropdownIcon]="'fa fa-ellipsis-v'"
                        name="status-field" appendTo="body" [displaySelectedLabel]="false"
                        [panelStyleClass]="'grid-showcol-drop-down'" [styleClass]="'show-column-multi-status'"
                        (onChange)="filterByStatuses(selectedStatus)">
                        <ng-template let-status pTemplate="item">
                          <div class="{{status.value}}" style="font-size:14px;float:right;margin-top:4px;">
                            {{status.label}}</div>
                        </ng-template>
                      </p-multiSelect>
                    </li>

                  </ul>
                </div>
              </div>
              <!-- <div>
                (onChange)="setColumnsToDisplay()"
                 (onPanelHide)="onHideColumnFilter()"
                <p-multiSelect [options]="colorLegendsStatus" defaultLabel="" [style]="{'width':'132px'}" maxSelectedLabels="0" [(ngModel)]="selectedColumn"
                  [dropdownIcon]="'fa fa-ellipsis-v'" appendTo="body" [displaySelectedLabel]="false" [styleClass]="'show-column-multi-status'">
                  <ng-template let-status pTemplate="item">
                    <div class="{{status.value}}" style="font-size:14px;float:right;margin-top:4px;">{{status.label}}</div>
                  </ng-template>
                </p-multiSelect>
              </div> -->

            </li>
            <li class="padding-right-0">
              <div *ngIf="_actionButtons?.length > 0" class="button-container">
                <ng-container *ngFor="let actionButton of _actionButtons">
                  <button #singleButton pButton [class]="actionButton.styleClass"
                    [disabled]="actionButton.disabled ? actionButton.disabled() : false"
                    *ngIf="!actionButton.hide && (!actionButton.children || actionButton.children.length === 0)"
                    [label]="actionButton.label" [icon]="actionButton.icon"
                    (click)="actionButton.onClick && actionButton.onClick($event)">
                  </button>
                </ng-container>
              </div>
            </li>
            <!-- <li>
              <div *ngIf="showFilter">
                <p-multiSelect [options]="modifiedColumn" defaultLabel="Columns" [style]="{'width':'97px'}" maxSelectedLabels="0"
                  selectedItemsLabel="{0} selected" (onChange)="setColumnsToDisplay()" name="name" [(ngModel)]="selectedColumn"
                  appendTo="body">
                </p-multiSelect>
              </div>
            </li> -->
            <li *ngIf="showDefaultSort">
              <div>
                <label class="font-12 font-bold label-vCenter padding-right-5">Default Sort</label>
                <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-15" (click)="onDefaultSort()"
                  icon="fa fa-sort">
                </button>
              </div>
            </li>
            <!-- Sanchit: 07-03-19 Commented because functionality still pending to export only user related data-->
            <!-- <li *ngIf="showMoreMenu">
              <div class="padding-0 float-right moreMenu">
                <p-tieredMenu class="margin-left-10 moreMenuContent" #showMenu popup="popup" [model]="_menuItem"></p-tieredMenu>
                <i class="fa fa-ellipsis-v more-icon   more-icon-wrapper  margin-top-7" (click)="showMenu.toggle($event)"
                  aria-hidden="true" title="Menu"></i>
              </div>
            </li> -->

          </ul>
        </div>
        <div *ngIf="title" class="padding-0">
          <h2 class="margin-0 grid-header">{{title}}</h2>
          <i *ngIf="config.showRefreshIcon" class="fa fa-repeat grid-refreshIcon" (click)="refreshFormTable()"
            pTooltip="Refresh"> </i>
        </div>
      </div>
    </div>

    <!--   <div class="ui-widget-header" style="padding:4px 10px;border-bottom: 0 none">
      <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
      <input #gb type="text" pInputText size="50" placeholder="Global Filter">
    </div> -->
    <!-- [rowTrackBy]="rowTrackBy"   [globalFilter]="gb"-->

    <p-dataTable id="data-table" #dt [value]="value" [loading]="loading" [emptyMessage]="config.emptyMessage"
      [editable]="config.editable" [paginator]="config.paginatorConfig.alwaysShowPaginator" [immutable]="true"
      [rows]="config.rowsPerPage" [rowsPerPageOptions]="config.paginatorConfig.rowsPerPageOptions"
      [alwaysShowPaginator]="config.paginatorConfig.alwaysShowPaginator" [resizableColumns]="config.resizableColumns"
      [columnResizeMode]="config.columnResizeMode" [(selection)]="selected" [lazy]="this.config.lazy"
      [selectionMode]="config.editable || config.selectionMode == 0  ? null : ( config.selectionMode === 1 ? 'single' : 'multiple')"
      [scrollable]="config.scrollable" [responsive]="config.responsive" [metaKeySelection]="false"
      [expandableRows]="config.expandableRows" [dataKey]="dataKey" [rowExpandMode]="config.rowExpandMode"
      [rowHover]="false" (onLazyLoad)="lazyLoadHandler($event)" [exportFilename]="config.exportFilename"
      (onRowSelect)="rowSelectHandler($event)" (onRowUnselect)="rowUnselectHandler($event)"
      (onRowClick)="rowClickHandler($event)" (onRowDblclick)="rowDoubleClickHandler($event)"
      (onHeaderCheckboxToggle)="headerCheckboxToggleHandler($event)" (onRowExpand)="rowExpandHandler($event)"
      (onRowCollapse)="rowCollapseHandler($event)" (onEditInit)="editInitHandler($event)" (onEdit)="editHandler($event)"
      (onEditComplete)="editCompleteHandler($event)" (onPageChange)="paginationHandler($event)"
      [totalRecords]="totalRecords" (onFilter)="onFilter($event)" (onSort)="onSort($event)"
      [sortMode]="config?.sortMode" [sortOrder]="config.sortOrder" [sortField]="config.sortField"
      [multiSortMeta]="config.multiSortMeta" (onValueChange)="onValueChange($event)">

      <!-- [style.background]="'green'" -->
      <!-- , 'background-color':'green' -->
      <!-- (rowData.status === 'AVAILABLE') ? 'green' : 'black' -->
      <!-- (rowData?.mountingItems?.imageStatus === 'WITHOUT_IMAGE')?'yellow':
      (rowData?.mountingItems?.imageStatus === 'DONE')?'green':
      (rowData?.mountingItems?.imageStatus === 'PENDING_MOUNTING')?'orange': -->
      <p-column *ngIf="config.showColorCode" [style]="{ 'width':'0vw','padding':'0px','position':'relative'}">
        <ng-template #colorTemplate let-rowIndex="rowIndex" let-col let-rowData="rowData" pTemplate="body">


          <!-- (rowData.status === 'ACTIVE')?'green': -->
          <!-- (rowData.status === 'APPROVED')?'yellow': -->
          <!-- (rowData.status === 'APPROVED_RUNNING')?'teal': -->
          <!-- (rowData.status === 'RUNNING')?'green': -->
          <div #colorCode [style.background]="
          (source !== 'campaignTracker' && rowData.rateApprovalPending) ? 'rgb(255, 118, 0)' : 
                (rowData.campaign && rowData.mountingItems && rowData.mountingItems && (rowData.mountingItems.imageStatus === 'WITH_IMAGE' || rowData.mountingItems.imageStatus === 'WITHOUT_IMAGE' || rowData.mountingItems.imageStatus === 'DONE')) ? 'rgb(251, 237, 52)': 
                (rowData.campaign && rowData.mountingItems && rowData.mountingItems.imageStatus)?'':
              
                (rowData.campaign && rowData.itemStatus && (rowData.itemStatus === 'EXPIRED'))? 'rgb(46, 59, 66)':
                (rowData.campaign && rowData.itemStatus && (rowData.itemStatus === 'PENDING_FLEX' || rowData.itemStatus === 'PENDING_MOUNTING'))? 'rgb(87, 194, 102)':
                (rowData.campaign && rowData.itemStatus && (rowData.itemStatus === 'PENDING_APPROVE'))? 'rgb(225, 39, 39)':
                (rowData.campaign && rowData.itemStatus === 'MOUNTED') ? 'rgb(251, 237, 52)' :
                (rowData.campaign && rowData.itemStatus)? '':
                (rowData.itemStatus && (rowData.itemStatus === 'EXPIRED' ||  rowData.itemStatus === 'PENDING_UNMOUNTING' || rowData.itemStatus === 'UNMOUNTED'))? 'rgb(255, 0, 0)':
                (rowData.itemStatus)? '':
                (rowData.status === 'AVAILABLE' || rowData.status === 'ACTIVE' || rowData.status === 'APPROVED') ? 'rgb(87,194,102)' : 
                (rowData.status === 'BLOCKED' || rowData.status === 'APPROVED_RUNNING')?'rgb(232, 149, 63)':
                (rowData.status === 'PENDING')?'rgb(225,39,39)':
                (rowData.status === 'PENDING_APPROVED_RUNNING')?'rgb(45,87,87)':
                (rowData.status === 'RUNNING')?'rgb(208, 200, 76)':
                (rowData.status === 'NOT_AVAILABLE')?'':                      
                (rowData.status === 'APPROVED_PENDING')?'rgb(225,39,39)':
                (rowData.status === 'PENDING_RUNNING')?'rgb(153,85,154)':
                (rowData.status === 'EXPIRED' || rowData.status === 'BLACKLISTED')?'rgb(46,59,66)':
                (rowData.status === 'REJECTED' || rowData.status === 'INACTIVE')?'rgb(182,73,57)':''"
            style="width:4px;height:100%;position:absolute;top:0">
            &nbsp;
          </div>
        </ng-template>
      </p-column>

      <!-- <p-column *ngIf="config.strikeRow" [style]="{ 'width':'0vw','padding':'0px','position':'relative'}">
        <ng-template #strikeTemplate let-rowIndex="rowIndex" let-col let-rowData="rowData" pTemplate="body">
          <div *ngIf="rowData.status === 'INVOICE_CANCEL' || rowData.status === 'INVOICE_CANCEL_WITH_RETENTION'">
            <hr style="width: 1290px">
          </div>
        </ng-template>
      </p-column> -->

      <!-- expander for row expansion -->
      <p-column [style]="{ 'width':'1vw','padding':'7px'}" *ngIf="config.expandableRows" expander="true"
        styleClass="col-icon"></p-column>


      <!-- <p-column *ngIf="config.showSrNo" [style]="{ 'width':'45px' }">
        <ng-template let-rowIndex="rowIndex" let-col let-data="rowData" pTemplate="body">
          <span class="">{{rowIndex+1}}</span>
        </ng-template>
      </p-column> -->




      <!-- Selection Checkbox or radio button depending on the configuration Shoule be present when checkBoxSelection is true and selectionMode is not set to none -->
      <p-column [style]="{ 'width':'2vw' }" [selectionMode]="config.selectionMode === 1 ? 'single' : 'multiple'"
        *ngIf="config.selectionMode !== 0 && config.checkboxSelection === true"></p-column>
      <!--<p-column  selectionMode="single"></p-column>-->
      <!-- 'word-break': 'break-all', -->
      <!-- ,  'word-wrap': 'break-word' -->
      <p-column [style]="{'overflow':'visible', 'width' : (column.width) ? column.width : '6vw'}"
        *ngFor="let column of columnsToDisplay; let columnIndex=index" field="{{ column.field }}"
        header="{{ column.header }}" [editable]="config.editable && column.editable" [filter]="column.filter"
        [filterPlaceholder]="column.filter ? column.filter.placeHolder : ''"
        [filterMatchMode]="column.filter ? column.filter.matchMode : ''" [sortable]="column.sortable"
        [styleClass]="column.styleClass">


        <!-- <p-column [style]="{'overflow':'visible', 'width' : column.width || 'inherit'}" *ngFor="let column of columnsToDisplay; let columnIndex=index"
          field="{{ column.field }}" header="{{ column.header }}" [editable]="config.editable && column.editable" [filter]="column.filter"
          [filterPlaceholder]="column.filter ? column.filter.placeHolder : ''" [filterMatchMode]="column.filter ? column.filter.matchMode : ''"> -->

        <!-- text -->


        <ng-container *ngIf="column.type === 1 || column.type === undefined">

          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">

            <div class="mtab-grid-cell-display"
              [attr.title]="column.displayTitleFn ? column.displayTitleFn(data, column) : ''"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="textDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>

          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="editor">

            <span *ngIf="config.editable && column.editable" class="mtab-grid-cell-editor"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <!-- If cell should be editable -->
              <ng-container
                *ngIf="(!column.isCellEditable || (column.isCellEditable && column.isCellEditable(rowData, column)))">
                <ng-container
                  *ngTemplateOutlet="inputTextTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>

              <ng-container *ngIf="column.isCellEditable && !column.isCellEditable(rowData, column)">
                <ng-container
                  *ngTemplateOutlet="inputTextTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>
              <!-- If cell should be editable -->
            </span>
          </ng-template>

          <!-- <ng-template *ngIf="config.editable && column.editable && column.displayFn" let-col let-rowIndex="rowIndex" let-rowData="rowData"
            pTemplate="editor">
            <span class="mtab-grid-cell-editor" [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container *ngTemplateOutlet="textEditWithDisplayFn; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}"></ng-container>
            </span>
          </ng-template> -->
        </ng-container>

        <!-- Dropdown -->
        <ng-container *ngIf="column.type === 2">
          <ng-template let-col let-rowIndex="ri" let-data="rowData" pTemplate="body">
            <div class="mtab-grid-cell-display"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <span class="{{rowIndex}}" *ngIf="column.displayFn">{{ column.displayFn (data, column) }}</span>
              <span class="{{rowIndex}}" *ngIf="!column.displayFn && data[col.field] && !data[col.field].text">{{
                data[col.field] }}</span>
              <span class="{{rowIndex}}" *ngIf="!column.displayFn && data[col.field] && data[col.field].text">{{
                data[col.field].text }}</span>
            </div>
          </ng-template>
          <ng-template *ngIf="config.editable && column.editable" let-col let-rowIndex="rowIndex" let-rowData="rowData"
            pTemplate="editor">
            <div class="mtab-grid-cell-editor"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="dropdownTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>
        </ng-container>


        <!-- Checkbox -->
        <ng-container *ngIf="column.type === 3">
          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">
            <div class="mtab-grid-cell-display"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="checkboxDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>
          <ng-template *ngIf="config.editable && column.editable" let-col let-rowIndex="rowIndex" let-rowData="rowData"
            pTemplate="editor">
            <div class="mtab-grid-cell-editor"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <!-- If cell should be editable -->
              <ng-container
                *ngIf="(!column.isCellEditable || (column.isCellEditable && column.isCellEditable(rowData, column)))">
                <ng-container
                  *ngTemplateOutlet="checkboxEditTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.isCellEditable && !column.isCellEditable(rowData, column)">
                <ng-container
                  *ngTemplateOutlet="checkboxDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>
              <!-- If cell should be editable -->
            </div>
          </ng-template>
        </ng-container>

        <!-- Date -->
        <!-- <ng-container *ngIf="column.type === 4">

          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">
            <div class="mtab-grid-cell-display" [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container *ngTemplateOutlet="dateDisplay; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}"></ng-container>
            </div>
          </ng-template>
          <ng-template *ngIf="config.editable && column.editable" let-col let-rowIndex="rowIndex" let-rowData="rowData"
            pTemplate="editor">
            <div class="mtab-grid-cell-editor" [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}"
              (keydown)="dt.onCellEditorKeydown($event, col, rowData, rowIndex)" (blur)="blurHandler($event, col, rowData, rowIndex)">

              <ng-container *ngIf="(!column.isCellEditable || (column.isCellEditable && column.isCellEditable(rowData, column)))">
                <ng-container *ngTemplateOutlet="dateInputTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.isCellEditable && !column.isCellEditable(rowData, column)">
                <ng-container *ngTemplateOutlet="dateInputTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}"></ng-container>
              </ng-container>
            </div>
          </ng-template>
        </ng-container> -->

        <!-- ===================================================================================================== -->

        <ng-container *ngIf="column.type === 4">

          <!-- <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">

            <div *ngIf="!column.editable" class="mtab-grid-cell-display" [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container *ngTemplateOutlet="dateDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}"></ng-container>
            </div>
            <div *ngIf="config.editable && column.editable" class="mtab-grid-cell-editor" [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}"
              (keydown)="dt.onCellEditorKeydown($event, col, rowData, rowIndex)" (blur)="blurHandler($event, col, rowData, rowIndex)">

              <ng-container *ngIf="(!column.isCellEditable || (column.isCellEditable && column.isCellEditable(rowData, column)))">
                <ng-container *ngTemplateOutlet="dateInputTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.isCellEditable && !column.isCellEditable(rowData, column)">
                <ng-container *ngTemplateOutlet="dateInputTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}"></ng-container>
              </ng-container>
            </div>
          </ng-template> -->

          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">
            <div class="mtab-grid-cell-display"
              [attr.title]="column.displayTitleFn ? column.displayTitleFn(data, column) : ''"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="dateDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>

          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="editor">
            <span *ngIf="config.editable && column.editable" class="mtab-grid-cell-editor"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <!-- If cell should be editable -->
              <ng-container
                *ngIf="(!column.isCellEditable || (column.isCellEditable && column.isCellEditable(rowData, column)))">
                <ng-container
                  *ngTemplateOutlet="dateInputTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>

              <ng-container *ngIf="column.isCellEditable && !column.isCellEditable(rowData, column)">
                <ng-container
                  *ngTemplateOutlet="dateInputTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>
              <!-- If cell should be editable -->
            </span>
          </ng-template>

        </ng-container>

        <!-- ================================================================================================================= -->

        <!-- Custom Template -->
        <ng-container *ngIf="column.type === 5">

          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">
            <div class="mtab-grid-cell-display"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="gridColumnMap[column.field].bodyTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>
          <ng-template *ngIf="config.editable && column.editable" let-col let-rowIndex="rowIndex" let-rowData="rowData"
            pTemplate="editor">
            <div class="mtab-grid-cell-editor myEditClass"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}"
              (keydown)="dataTable.onCellEditorKeydown($event, col, rowData, rowIndex)">
              <ng-container
                *ngTemplateOutlet="gridColumnMap[column.field].editorTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>
        </ng-container>

        <!-- Autocomplete -->
        <ng-container *ngIf="column.type === 6">
          <ng-template let-col let-rowIndex="ri" let-data="rowData" pTemplate="body">
            <div class="mtab-grid-cell-display"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <span class="{{rowIndex}}" *ngIf="column.displayFn">{{ column.displayFn(data, column) }}</span>
              <span class="{{rowIndex}}" *ngIf="!column.displayFn && data[col.field] && !data[col.field].text">{{
                data[col.field] }}</span>
              <span class="{{rowIndex}}" *ngIf="!column.displayFn && data[col.field] && data[col.field].text">{{
                data[col.field].text }}</span>
            </div>
          </ng-template>
          <ng-template *ngIf="config.editable && column.editable" let-col let-rowIndex="rowIndex" let-rowData="rowData"
            pTemplate="editor">
            <div class="mtab-grid-cell-editor"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="autocompleteTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>
        </ng-container>

        <!-- Multiselect -->
        <ng-container *ngIf="column.type === 7">
          <ng-template let-col let-rowIndex="ri" let-rowData="rowData" pTemplate="body">
            <div class="mtab-grid-cell-display"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="multiSelectDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>
          <ng-template *ngIf="config.editable && column.editable" let-col let-rowIndex="rowIndex" let-rowData="rowData"
            pTemplate="editor">
            <div class="mtab-grid-cell-editor"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="multiselectTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>
        </ng-container>


        <!-- filter template -->
        <ng-container *ngIf="column.filter && column.filter.custom">
          <ng-template let-col pTemplate="filter">
            <ng-container
              *ngTemplateOutlet="gridColumnMap[column.field].filterTemplate; context: {'col': col, 'column' : column}">
            </ng-container>
          </ng-template>
        </ng-container>

        <!-- edit display template -->
        <!-- <ng-container *ngIf="column.type === 8 ">
  
              <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">
                <div class="mtab-grid-cell-display" [attr.title]="column.displayTitleFn ? column.displayTitleFn(data, column) : ''" [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
                  <ng-container *ngTemplateOutlet="textEditDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}"></ng-container>
                </div>
              </ng-template>
    
            
            </ng-container> -->

        <ng-container *ngIf="column.type === 8">


          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="body">

            <div class="mtab-grid-cell-display"
              [attr.title]="column.displayTitleFn ? column.displayTitleFn(data, column) : ''"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <ng-container
                *ngTemplateOutlet="numberDisplayTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
              </ng-container>
            </div>
          </ng-template>

          <ng-template let-col let-rowIndex="rowIndex" let-rowData="rowData" pTemplate="editor">

            <span *ngIf="config.editable && column.editable" class="mtab-grid-cell-editor"
              [ngClass]="{'align-left': column.align === 1, 'align-center': column.align === 2, 'align-right': column.align === 3}">
              <!-- If cell should be editable -->
              <ng-container
                *ngIf="(!column.isCellEditable || (column.isCellEditable && column.isCellEditable(rowData, column)))">
                <ng-container
                  *ngTemplateOutlet="inputNumberTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>

              <ng-container *ngIf="column.isCellEditable && !column.isCellEditable(rowData, column)">
                <ng-container
                  *ngTemplateOutlet="inputNumberTemplate; context: {'rowData': rowData, 'col': col, 'rowIndex': rowIndex, 'column' : column}">
                </ng-container>
              </ng-container>
              <!-- If cell should be editable -->
            </span>
          </ng-template>
        </ng-container>



      </p-column>

      <!-- <p-column field="salesPerson" header="Person Assigned">
            <ng-template let-rowData="rowData" let-rowIndex="rowIndex" let-col pTemplate="body">
              {{(rowData[col.field][0].firstname)}}
            </ng-template>
  
        </p-column> -->

    </p-dataTable>
  </form>
</div>

<!-- Text Control -->
<ng-template #textDisplayTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
  <span class="{{rowIndex}}" *ngIf="column.displayFn">{{ column.displayFn(rowData, column) }}</span>
  <span class="{{rowIndex}}" *ngIf="!column.displayFn">{{ rowData[col.field] }}</span>
</ng-template>

<!-- <ng-template #textEditDisplayTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <input type="text" style="width:'100%'" [ngClass]="column.editable ? 'editable-text' : 'noneditable-text'" (keyup)="onEditChangeWithDisplayFn($event, column, rowData, rowIndex)"
          class="ui-inputtext ui-corner-all ui-state-default ui-widget" [name]="getFieldName(col, rowData)" [required]="column.required"
          [(ngModel)]="rowData[col.field]">
      </ng-template> -->


<ng-template #numberDisplayTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
  <span class="{{rowIndex}}" *ngIf="column.displayFn">{{ column.displayFn(rowData, column) }}</span>
  <span class="{{rowIndex}}" *ngIf="!column.displayFn">{{ rowData[col.field] }}</span>
</ng-template>



<ng-template #inputTextTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
  <input type="text" style="width:'100%'" [ngClass]="column.editable ? 'editable-text' : 'noneditable-text'"
    (keyup)="onEditChange($event,rowData, rowIndex, col, column)"
    class="ui-inputtext ui-corner-all ui-state-default ui-widget" [name]="getFieldName(col, rowData)"
    [required]="column.required" [(ngModel)]="rowData[col.field]" onfocus="this.select();">
</ng-template>

<ng-template #inputNumberTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
  <input #numberInput="ngModel" type="text" style="width:'100%'"
    [disabled]="!column.editable || (column.isCellEditable && !column.isCellEditable(rowData, column))"
    [ngClass]="column.editable ? 'editable-text' : 'noneditable-text'"
    (keyup)="onEditNumberChange($event,rowData, rowIndex, col, column)"
    class="ui-inputtext ui-corner-all ui-state-default ui-widget" [name]="getFieldName(col, rowData)"
    [required]="column.required" [pattern]="decimalNumbers" [(ngModel)]="rowData[col.field]"
    pTooltip="{{numberInput.hasError('pattern')? 'Positive Numbers Only' :''}}" onfocus="this.select();" />
</ng-template>

<ng-template #dateDisplayTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
  <span class="{{rowIndex}}" *ngIf="column.displayFn">{{ column.displayFn(rowData, column) |
    date:'ddMMMyy' }}</span>
  <span class="{{rowIndex}}" *ngIf="!column.displayFn">{{ rowData[col.field] | date:'ddMMMyy' }}</span>
  <span *ngIf="column.editable" style="margin-left: 10px">
    <i class="fa fa-calendar"></i>
  </span>
  <!-- <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}" [disabled]="true" (onSelect)="onCalenderChange($event,rowData, rowIndex)"
    (onBlur)="onCalenderChange($event,rowData, rowIndex)" [utc]="true" [(ngModel)]="rowData[col.field]" showIcon="true"
    dateFormat="ddMy" appendTo="body"></p-calendar> -->
</ng-template>


<ng-template #dateInputTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
  <div *ngIf="minDate !== null && maxDate === null && !setMinDate && !setBothDates">
    <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}" [disabled]="!column.editable"
      (onSelect)="onCalenderChange($event,rowData, rowIndex)" [utc]="true" [(ngModel)]="rowData[col.field]"
      [minDate]="minDate <= currentDate ? minDate : currentDate" showIcon="true" dateFormat="ddMy" appendTo="body">
    </p-calendar>
  </div>
  <div *ngIf="minDate === null && maxDate === null && !setMinDate && !setBothDates">
    <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}" [disabled]="!column.editable"
      (onSelect)="onCalenderChange($event,rowData, rowIndex)" [utc]="true" [(ngModel)]="rowData[col.field]"
      showIcon="true" dateFormat="ddMy" appendTo="body"></p-calendar>
  </div>
  <div *ngIf="minDate !== null && maxDate !== null && !datesDisabled && !setBothDates">
    <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}" [disabled]="!column.editable"
      (onSelect)="onCalenderChange($event,rowData, rowIndex)" [utc]="true" [(ngModel)]="rowData[col.field]"
      [minDate]="minDate" [maxDate]="maxDate" showIcon="true" dateFormat="ddMy" appendTo="body"></p-calendar>
  </div>
  <div *ngIf="minDate !== null && maxDate !== null && datesDisabled && !setBothDates">
    <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}" [disabled]="!column.editable"
      (onSelect)="onCalenderChange($event,rowData, rowIndex)" [utc]="true"
      (click)="onClickCalendar($event, rowData, rowIndex)" [(ngModel)]="rowData[col.field]" [minDate]="minDate"
      [maxDate]="maxDate" showIcon="true" dateFormat="ddMy" appendTo="body" [disabledDates]="disabledDates">
    </p-calendar>
  </div>
  <div *ngIf="maxDate === null && setMinDate && !setBothDates">
    <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}" [disabled]="!column.editable"
      (onSelect)="onCalenderChange($event,rowData, rowIndex)" [minDate]="minDate" [utc]="true"
      [(ngModel)]="rowData[col.field]" showIcon="true" dateFormat="ddMy" appendTo="body"
      (click)="setMinDateOnClickCalendar($event, rowData, rowIndex, column)"></p-calendar>
  </div>
  <div *ngIf="setBothDates">
    <!-- (click)="setBothDatesOnClick($event,rowData, rowIndex, column)" -->
    <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}"
      [disabled]="!column.editable || (column.isCellEditable && !column.isCellEditable(rowData, column))"
      (onSelect)="onCalenderChange($event,rowData, rowIndex, col, column)"
      (onBlur)="blur($event,rowData, rowIndex, col, column)"
      (onFocus)="setBothDatesOnClick($event,rowData, rowIndex, column)" [minDate]="minDate" [maxDate]="maxDate"
      [readonlyInput]="disableMaualDateEntry" [disabledDates]="disabledDates" [utc]="true"
      [(ngModel)]="rowData[col.field]" showIcon="true" dateFormat="ddMy" appendTo="body"></p-calendar>

    <!-- <p-calendar shortYearCutoff="100" [inputStyle]="{'width':'90%'}"
      [disabled]="!column.editable || (column.isCellEditable && !column.isCellEditable(rowData, column))"
      (onSelect)="onCalenderChange($event,rowData, rowIndex)" (onBlur)="blur($event,rowData, rowIndex)"
      (onFocus)="setBothDatesOnClick($event,rowData, rowIndex, column)" [minDate]="minDate" [maxDate]="maxDate"
      [readonlyInput]="disableMaualDateEntry" [disabledDates]="disabledDates" [utc]="true"
      [(ngModel)]="rowData[col.field]" showIcon="true" dateFormat="ddMy" appendTo="body"></p-calendar> -->
  </div>
</ng-template>


<p-growl [(value)]="msgs"></p-growl>