
import { catchError, map, switchMap } from 'rxjs/operators';

import { empty as observableEmpty, Observable } from 'rxjs';
/**
* @file BillingEffects
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/

import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { BillingActions } from "../actions/billing-actions";
// import { toPayload } from "@ngrx/effects/src/util";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { BillingModuleState } from "../billing-module.reducers";
import { Store } from "@ngrx/store";
import { BillingGridService } from "../services/billing-grid.service";
import { AppUrls } from "../../services/urls";
import { UploadPOService } from "../../services/shared/uploadPO.service";
import { ActionExtended } from '../../shared/actionextended';
import { ofType } from '@ngrx/effects';
import { MigratedInvoiceService } from '../services/migrated-invoice.service';
import { ErrorUtil } from '../../helpers/error.utils';

@Injectable()
export class BillingEffects {

    constructor(
        private actions: Actions,
        private billingActions: BillingActions,
        private store: Store<BillingModuleState>,
        private notificationServcie: NotificatoinsService,
        private billingGridService: BillingGridService,
        private migratedInvoiceService: MigratedInvoiceService,
        private uploadPOService: UploadPOService
    ) { }

    @Effect()
    getRentItems = this.actions.pipe(
        ofType(BillingActions.GET_RENT_ITEMS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.create(payload, null, AppUrls.GET_RENT_ITEMS).pipe(
                map((rentItems) => {
                    // this.notificationServcie.success('Rent Items Fetched', 'Items Fetched Successfully');
                    return this.billingActions.getRentItemsSuccess(rentItems);
                }), catchError(error => {
                    this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getRentItemsFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    getPrintingItems = this.actions.pipe(
        ofType(BillingActions.GET_PRINTING_ITEMS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.create(payload, null, AppUrls.GET_PRINTING_ITEMS).pipe(
                map((printingItems) => {
                    // this.notificationServcie.success('Printing Items Fetched', 'Items Fetched Successfully');
                    return this.billingActions.getPrintingItemsSuccess(printingItems);
                }), catchError(error => {
                    this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getPrintingItemsFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    getMountingItems = this.actions.pipe(
        ofType(BillingActions.GET_MOUNTING_ITEMS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.create(payload, null, AppUrls.GET_MOUNTING_ITEMS).pipe(
                map((mountingItems) => {
                    // this.notificationServcie.success('Mounting Items Fetched', 'Items Fetched Successfully');
                    return this.billingActions.getMountingItemsSuccess(mountingItems);
                }), catchError(error => {
                    this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getMountingItemsFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    generateBill = this.actions.pipe(
        ofType(BillingActions.GENERATE_BILL),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.generateBill(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Bill Generated Successfully', 'Bill Generation');
                    return this.billingActions.generateBillSuccess(invoice);
                }).catch(error => {
                    if (error.error.code === 412 || error.error.code === 417) {
                        this.notificationServcie.info(error.error.message ? error.error.message : 'Some technical Error', 'Information');
                    } else {
                        this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical Error', 'Error');
                    }
                    this.store.dispatch(this.billingActions.generateBillFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    generateMigratedBill = this.actions.pipe(
        ofType(BillingActions.GENERATE_MIGRATED_BILL),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.generateMigratedBill(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Bill Generated Successfully', 'Bill Generation');
                    return this.billingActions.generateMigratedBillSuccess(invoice);
                }).catch(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.generateMigratedBillFailure());
                    return observableEmpty();
                });

        }));

    @Effect()
    getInvoices = this.actions.pipe(
        ofType(BillingActions.GET_INVOICES),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.getInvoices(payload)
                .map((invoice) => {
                    // this.notificationServcie.success('Invoices Fetched', 'Invoices Fetched Successfully');
                    return this.billingActions.getInvoicesSuccess(invoice);
                }).catch(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getInvoicesFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    getMigratedInvoices = this.actions.pipe(
        ofType(BillingActions.GET_MIGRATED_INVOICES),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.getMigratedInvoice(payload)
                .map((invoice) => {
                    // this.notificationServcie.success('Invoices Fetched', 'Invoices Fetched Successfully');
                    return this.billingActions.getMigratedInvoicesSuccess(invoice);
                }).catch(error => {
                    this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getMigratedInvoicesFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    getPendingRentItems = this.actions.pipe(
        ofType(BillingActions.GET_PENDING_RENT_ITEMS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.create(payload, null, AppUrls.GET_RENT_ITEMS).pipe(
                map((rentItems) => {
                    // this.notificationServcie.success('Rent Items Fetched', 'Items Fetched Successfully');
                    return this.billingActions.getPendingRentItemsSuccess(rentItems);
                }), catchError(error => {
                    this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getPendingRentItemsFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    getPendingPrintingItems = this.actions.pipe(
        ofType(BillingActions.GET_PENDING_PRINTING_ITEMS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.create(payload, null, AppUrls.GET_PRINTING_ITEMS).pipe(
                map((printingItems) => {
                    // this.notificationServcie.success('Printing Items Fetched', 'Items Fetched Successfully');
                    return this.billingActions.getPendingPrintingItemsSuccess(printingItems);
                }), catchError(error => {
                    this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getPendingPrintingItemsFailure());
                    return observableEmpty();
                }));
        }));

    @Effect()
    getPendingMountingItems = this.actions.pipe(
        ofType(BillingActions.GET_PENDING_MOUNTING_ITEMS),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.create(payload, null, AppUrls.GET_MOUNTING_ITEMS).pipe(
                map((mountingItems) => {
                    // this.notificationServcie.success('Mounting Items Fetched', 'Items Fetched Successfully');
                    return this.billingActions.getPendingMountingItemsSuccess(mountingItems);
                }), catchError(error => {
                    this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getPendingMountingItemsFailure());
                    return observableEmpty();
                }));
        }));

    // @Effect()
    // editRequest = this.actions
    //     .ofType(BillingActions.EDIT_REQUEST)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.editRequest(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Edit Request Sent Successfully', 'Edit Request Sent');
    //                 return this.billingActions.editRequestSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.editRequestFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // cancelRequest = this.actions
    //     .ofType(BillingActions.CANCEL_REQUEST)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.cancelRequest(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Cancel Request Sent Successfully', 'Cancel Request Sent');
    //                 return this.billingActions.cancelRequestSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.cancelRequestFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // approveRequestAccountant = this.actions
    //     .ofType(BillingActions.APPROVE_REQUEST_ACCOUNTANT)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.approveRequestAccountant(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Request Approved Successfully', 'Request Approve');
    //                 return this.billingActions.approveRequestAccountantSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.approveRequestAccountantFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // rejectRequestAccountant = this.actions
    //     .ofType(BillingActions.REJECT_REQUEST_ACCOUNTANT)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         console.log("inside reject req acc");
    //         return this.billingGridService.rejectRequestAccountant(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Request Rejected Successfully', 'Request Reject');
    //                 return this.billingActions.rejectRequestAccountantSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.rejectRequestAccountantFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // approveRequestAdmin = this.actions
    //     .ofType(BillingActions.APPROVE_REQUEST_ADMIN)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.approveRequestAdmin(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Request Approved Successfully', 'Request Approve');
    //                 return this.billingActions.approveRequestAdminSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.approveRequestAdminFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // rejectRequestAdmin = this.actions
    //     .ofType(BillingActions.REJECT_REQUEST_ADMIN)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.rejectRequestAdmin(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Request Rejected Successfully', 'Request Rejecte');
    //                 return this.billingActions.rejectRequestAdminSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.rejectRequestAdminFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // rejectEditRequestAccountant = this.actions
    //     .ofType(BillingActions.REJECT_EDIT_REQUEST_ACCOUNTANT)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.rejectEditRequestAccountant(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Request Approved Successfully', 'Request Approve');
    //                 return this.billingActions.rejectEditRequestAccountantSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.rejectEditRequestAccountantFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // rejectCancelRequestAccountant = this.actions
    //     .ofType(BillingActions.REJECT_CANCEL_REQUEST_ACCOUNTANT)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         console.log("inside reject cancel request acc");
    //         return this.billingGridService.rejectCancelRequestAccountant(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Request Approved Successfully', 'Request Approve');
    //                 return this.billingActions.rejectCancelRequestAccountantSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.rejectCancelRequestAccountantFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // approveEditRequestAccountant = this.actions
    //     .ofType(BillingActions.APPROVE_EDIT_REQUEST_ACCOUNTANT)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.approveEditRequestAccountant(payload)
    //             .map((invoice) => {
    //                 // this.notificationServcie.success('Request Approved Successfully', 'Request Approve');
    //                 return this.billingActions.approveEditRequestAccountantSuccess(invoice);
    //             }).catch(error => {
    //                 // this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.approveEditRequestAccountantFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // approveCancelRequestAccountant = this.actions
    //     .ofType(BillingActions.APPROVE_CANCEL_REQUEST_ACCOUNTANT)
    //     .map(toPayload)
    //     .switchMap((payload) => {
    //         return this.billingGridService.approveCancelRequestAccountant(payload)
    //             .map((invoice) => {
    //                 this.notificationServcie.success('Request Approved Successfully', 'Request Approve');
    //                 return this.billingActions.approveCancelRequestAccountantSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.approveCancelRequestAccountantFailure());
    //                 return Observable.empty();
    //             });
    //     });

    // @Effect()
    // getTemporaryInvoice = this.actions.pipe(
    //     ofType(BillingActions.GET_TEMPORARY_INVOICE),
    //     map((action: ActionExtended) => action.payload),
    //     switchMap((payload) => {
    //         return this.billingGridService.getTempInvoice(payload)
    //             .map((invoice) => {
    //                 return this.billingActions.getTemporaryInvoiceSuccess(invoice);
    //             }).catch(error => {
    //                 this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
    //                 this.store.dispatch(this.billingActions.getTemporaryInvoiceFailure());
    //                 return observableEmpty();
    //             });
    //     }));

    @Effect()
    submitBill = this.actions.pipe(
        ofType(BillingActions.SUBMIT_BILL),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.submitBill(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Invoice Submitted Successfully', 'Invoice Submit');
                    return this.billingActions.submitBillSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Invoice Submit Unsuccessful', 'Invoice Submit');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.submitBillFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    submitMigratedBill = this.actions.pipe(
        ofType(BillingActions.SUBMIT_MIGRATED_BILL),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.submitMigratedBill(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Invoice Submitted Successfully', 'Invoice Submit');
                    return this.billingActions.submitMigratedBillSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Invoice Submit Unsuccessful', 'Invoice Submit');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.submitMigratedBillFailure());
                    return observableEmpty();
                });
        }));


    @Effect()
    uploadInvoicePO = this.actions.pipe(
        ofType(BillingActions.UPLOAD_INVOICE_PO),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.uploadPOService.create(payload.purchseOrders, null, payload.campaignId).pipe(
                map((purchseOrders) => {
                    this.notificationServcie.success('PO Uploaded Successfully', 'PO Upload');
                    return this.billingActions.uploadPOSuccess(purchseOrders);
                }), catchError(error => {
                    this.notificationServcie.error(error.message ? error.message : 'Some technical issue', 'Error!!');
                    this.store.dispatch(this.billingActions.uploadPOFailure());
                    return observableEmpty();
                }));
        }));


    // ==============================================================================================================================

    @Effect()
    editRequest = this.actions.pipe(
        ofType(BillingActions.EDIT_REQUEST),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.editRequest(payload)
                .map((invoice) => {
                    // this.notificationServcie.success('Edit Request Sent Successfully', 'Edit Request Sent');
                    return this.billingActions.editRequestSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Edit Request Successfully', 'Edit Request Sent');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.editRequestFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    editDiscardRequest = this.actions.pipe(
        ofType(BillingActions.EDIT_DISCARD_REQUEST),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.editDiscardRequest(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Changes Discarded Successfully', 'Changes Discarded');
                    return this.billingActions.editDiscardRequestSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Changes Discard Unsuccessful', 'Changes Discarded');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.editDiscardRequestFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    cancelRequest = this.actions.pipe(
        ofType(BillingActions.CANCEL_REQUEST),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.cancelRequest(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Cancel Request Sent Successfully', 'Cancel Request Sent');
                    return this.billingActions.cancelRequestSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Cancel Request Unsuccessful', 'Cancel Request Sent');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.cancelRequestFailure());
                    return observableEmpty();
                });
        }));




    @Effect()
    approveGenerateRequestAdmin = this.actions.pipe(
        ofType(BillingActions.APPROVE_GEN_ADMIN),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.approveGenerateRequestAdmin(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Generated Invoice Approved Successfully by Admin', 'Generation Approve');
                    return this.billingActions.approveGenerateRequestAdminSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Generated Invoice Approve Unsuccessful by Admin', 'Generation Approve');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.approveGenerateRequestAdminFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    approveGenerateRequestAccountant = this.actions.pipe(
        ofType(BillingActions.APPROVE_GEN_ACCOUNTANT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.approveGenerateRequestAccountant(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Generated Invoice Approved Successfully by Accountant', 'Generation Approve');
                    return this.billingActions.approveGenerateRequestAccountantSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Generated Invoice Approve Unsuccessful by Accountant', 'Generation Approve');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.approveGenerateRequestAccountantFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    approveEditRequestAdmin = this.actions.pipe(
        ofType(BillingActions.APPROVE_EDIT_ADMIN),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.approveEditRequestAdmin(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Edited Invoice Approved Successfully by Admin', 'Editing Approve');
                    return this.billingActions.approveEditRequestAdminSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Edited Invoice Approve Unsuccessful by Admin', 'Editing Approve');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.approveEditRequestAdminFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    approveEditRequestAccountant = this.actions.pipe(
        ofType(BillingActions.APPROVE_EDIT_ACCOUNTANT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.approveEditRequestAccountant(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Edited Invoice Approved Successfully by Accountant', 'Editing Approve');
                    return this.billingActions.approveEditRequestAccountantSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Edited Invoice Approve Unsuccessful by Accountant', 'Editing Approve');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.approveEditRequestAccountantFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    approveCancelRequestAdmin = this.actions.pipe(
        ofType(BillingActions.APPROVE_CANCEL_ADMIN),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.approveCancelRequestAdmin(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Cancel Invoice Request Approved Successfully by Admin', 'Cancel Invoice Approve');
                    return this.billingActions.approveCancelRequestAdminSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Cancel Invoice Request Approve Unsuccessful by Admin', 'Cancel Invoice Approve');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.approveCancelRequestAdminFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    approveCancelRequestAccountant = this.actions.pipe(
        ofType(BillingActions.APPROVE_CANCEL_ACCOUNTANT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.approveCancelRequestAccountant(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Cancel Invoice Request Approved Successfully by Accountant', 'Cancel Invoice Approve');
                    return this.billingActions.approveCancelRequestAccountantSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Cancel Invoice Request Approve Unsuccessful by Accountant', 'Cancel Invoice Approve');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.approveCancelRequestAccountantFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    rejectGenerateRequestAdmin = this.actions.pipe(
        ofType(BillingActions.REJECT_GEN_ADMIN),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.rejectGenerateRequestAdmin(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Generated Invoice Rejected Successfully by Admin', 'Generation Rejection');
                    return this.billingActions.rejectGenerateRequestAdminSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Generated Invoice Rejection Unsuccessful by Admin', 'Generation Rejection');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.rejectGenerateRequestAdminFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    rejectGenerateRequestAccountant = this.actions.pipe(
        ofType(BillingActions.REJECT_GEN_ACCOUNTANT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.rejectGenerateRequestAccountant(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Generated Invoice Rejected Successfully by Accountant', 'Generation Rejection');
                    return this.billingActions.rejectGenerateRequestAccountantSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Generated Invoice Rejection Unsuccessful by Accountant', 'Generation Rejection');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.rejectGenerateRequestAccountantFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    rejectEditRequestAdmin = this.actions.pipe(
        ofType(BillingActions.REJECT_EDIT_ADMIN),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.rejectEditRequestAdmin(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Edited Invoice Rejected Successfully by Admin', 'Editing Reject');
                    return this.billingActions.rejectEditRequestAdminSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Edited Invoice Rejection Unsuccessful by Admin', 'Editing Reject');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.rejectEditRequestAdminFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    rejectEditRequestAccountant = this.actions.pipe(
        ofType(BillingActions.REJECT_EDIT_ACCOUNTANT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.rejectEditRequestAccountant(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Edited Invoice Rejected Successfully by Accountant', 'Editing Reject');
                    return this.billingActions.rejectEditRequestAccountantSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Edited Invoice Rejection Unsuccessful by Accountant', 'Editing Reject');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.rejectEditRequestAccountantFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    rejectCancelRequestAdmin = this.actions.pipe(
        ofType(BillingActions.REJECT_CANCEL_ADMIN),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.rejectCancelRequestAdmin(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Cancel Invoice Request Rejected Successfully by Admin', 'Cancel Invoice Reejction');
                    return this.billingActions.rejectCancelRequestAdminSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Cancel Invoice Request Rejection Unsuccessful by Admin', 'Cancel Invoice Reejction');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.rejectCancelRequestAdminFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    rejectCancelRequestAccountant = this.actions.pipe(
        ofType(BillingActions.REJECT_CANCEL_ACCOUNTANT),
        map((action: ActionExtended) => action.payload),
        switchMap((payload) => {
            return this.billingGridService.rejectCancelRequestAccountant(payload)
                .map((invoice) => {
                    this.notificationServcie.success('Cancel Invoice Request Rejected Successfully by Accountant', 'Cancel Invoice Rejection');
                    return this.billingActions.rejectCancelRequestAccountantSuccess(invoice);
                }).catch(error => {
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 412 || errorObject.code === 417) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : 'Cancel Invoice Request Rejection Unsuccessful by Accountant', 'Cancel Invoice Rejection');
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : 'Some technical issue', 'Error!!');
                    }
                    this.store.dispatch(this.billingActions.rejectCancelRequestAccountantFailure());
                    return observableEmpty();
                });
        }));

    @Effect()
    getInvoiceTaskCards = this.actions.pipe(
        ofType(BillingActions.GET_INVOICE_TASK_CARDS),
        switchMap(() => {
            return this.billingGridService.getInvoiceTaskCards()
                .map((invoiceTaskCards) => {
                    return this.billingActions.getInvoiceTaskCardsSuccess(invoiceTaskCards);
                }).catch(error => {
                    this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                    this.store.dispatch(this.billingActions.getInvoiceTaskCardsFailure());
                    return observableEmpty();
                });
        }));


}
