import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, TooltipModule, ProgressSpinnerModule, DropdownModule, MultiSelectModule, CalendarModule, FileUploadModule, DragDropModule, RadioButtonModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { DirectiveModules } from '../../../modules/directive.modules';
import { UpdateInvoiceDialogComponent } from './update-invoice-dialog.component';
import { DialogAppendDirective } from '../../../directives/dialog-append-body.directive';
@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        DirectiveModules,
        DropdownModule,
        MultiSelectModule,
        CalendarModule,
        FileUploadModule,
        DragDropModule,
        RadioButtonModule,
    ],

    declarations: [
        UpdateInvoiceDialogComponent,
        DialogAppendDirective
    ],

    exports: [
        UpdateInvoiceDialogComponent
    ]
})

export class UpdateInvoiceDialogModule { }
