import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { GridColumn } from '../../../components/sib-forms/grid/grid.config';

/**
 * @description implements order list primeng component to order a list
 * @author Pulkit Bansal
 * @date 2019-10-06
 * @export
 * @class RowsRearrangeComponent
 */
@Component({
    selector: 'sib-rows-rearrange',
    templateUrl: './rows-rearrange.component.html',
    styleUrls: ['./rows-rearrange.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RowsRearrangeComponent implements OnInit {

    /**
     * @description array of rows to rearrange
     * @type {Array<any>}
     * @memberof RowsRearrangeComponent
     */
    @Input() rows: Array<any> = [];

    /**
      * @description setting the _columns
      * @memberof RowsRearrangeComponent
      */
    @Input() set columns(columns: Array<GridColumn>) {
        this._columns = columns;
        if (this.header) {
            this._columns.filter((col) => {
                col = this.setColumnWidth(col);
            });
        }
    }

    get columns() {
        return this._columns;
    }

    /**
     * @description header for the order list
     * @memberof RowsRearrangeComponent
     */
    @Input() set header(header: string) {
        this._header = header;
        if (this.columns) {
            this._columns.filter((col) => {
                col = this.setColumnWidth(col);
            });
        }
    }

    get header() {
        return this._header;
    }

    /**
     * @description to emit the new order of rows
     * @type {EventEmitter<any>}
     * @memberof RowsRearrangeComponent
     */
    @Output() eEmitSaveRowsConfig: EventEmitter<any> = new EventEmitter();

    /**
     * @description to close the dialog
     * @type {EventEmitter<boolean>}
     * @memberof RowsRearrangeComponent
     */
    @Output() eEmitCancelRowsConfig: EventEmitter<boolean> = new EventEmitter();

    _columns: Array<GridColumn> = [];

    _header: string = null;

    /**
      * @description array of item ids based on the current row order
      * @type {String[]}
      * @memberof RowsRearrangeComponent
      */
    rowsOrder: String[] = [];

    constructor() { }

    ngOnInit(): void { }

    /**
     * @description to get the value corresponding to a particular column for a row
     * @param {any} row
     * @param {string} field
     * @returns {string}
     * @memberof RowsRearrangeComponent
     */
    getValueForACol(row: any, field: string): string {
        return row[field];
    }

    /**
     * @description to set the width of a column
     * @author Pulkit Bansal
     * @date 2019-10-16
     * @param {*} column
     * @memberof RowsRearrangeComponent
     */
    setColumnWidth(column: any) {
        switch (this.header) {
            case "Invoice Rows": {
                switch (column.field) {
                    case "srNo": {
                        column.width = '3%';
                        break;
                    }
                    case "inventory": {
                        column.width = (this.columns.length === 7) ? "52%" : "87%";
                        break;
                    }
                    case "itemStartDate": {
                        column.width = (this.columns.length === 7) ? "10%" : "0%";
                        break;
                    }
                    case "itemEndDate": {
                        column.width = (this.columns.length === 7) ? "10%" : "0%";
                        break;
                    }
                    case "days": {
                        column.width = (this.columns.length === 7) ? "5%" : "0%";
                        break;
                    }
                    case "rate": {
                        column.width = (this.columns.length === 7) ? "10%" : "0%";
                        break;
                    }
                    case "amount": {
                        column.width = (this.columns.length === 7) ? "10%" : "10%";
                        break;
                    }
                }
                return column;
                break;
            }
        }
    }


    saveRowsConfig() {
        this.rowsOrder = this.rows.map(row => row.id);
        this.eEmitSaveRowsConfig.emit(this.rowsOrder);
        this.cancelRowsConfig();
    }

    cancelRowsConfig() {
        this.eEmitCancelRowsConfig.emit(false);
    }

}
