import { CampaignItemWrapper } from "./campaign-item-wrapper";
import { PrintingPrice } from "../plans/printingPrice";
import { MountingPrice } from "../plans/mountingPrice";
import { AdditionalItem } from "./additional-item";
import * as utils from '../../helpers/utils';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';

export class AdditionalItemWrapper extends CampaignItemWrapper {
    printingPrice: PrintingPrice = new PrintingPrice();
    mountingPrice: MountingPrice = new MountingPrice();
    printingCost: number;
    mountingCost: number;
    amount: number;
    serviceDate: Date;
    actualAmount: number;
    creative: string;
    note: string;

    setWrapper(item: AdditionalItem): AdditionalItemWrapper {
        // this.amount = printingItem.amount;
        // this.city = printingItem.city;
        // this.cost = printingItem.cost;
        // this.customId = printingItem.customId;
        // this.id = printingItem.id;
        // this.location = printingItem.location;
        // this.printingPrice = printingItem.printingPrice;
        // this.mountingPrice = printingItem.mountingPrice;
        // this.rate = printingItem.rate;
        // this.size = printingItem.size;
        // this.squareFeet = printingItem.squareFeet;
        // this.actualAmount = printingItem.actualAmount;
        // if (printingItem.printingPrice.flNlPrice !== 0) {
        //     this.printingCost = printingItem.printingPrice.flNlPrice;
        // } else if (printingItem.printingPrice.blPrice !== 0) {
        //     this.printingCost = printingItem.printingPrice.blPrice;
        // }

        this.amount = item.amount;
        this.city = item.city;
        this.cost = utils.parseValueToDecimalTwoPlaces(item.cost);
        this.customId = item.customId;
        this.id = item.id;
        this.location = item.location;
        this.printingPrice = item.printingPrice;
        this.mountingPrice = item.mountingPrice;
        this.rate = utils.parseValueToDecimalTwoPlaces(item.rate);
        this.size = item.size;
        this.squareFeet = item.squareFeet;
        this.lightType = item.lightType;
        this.mediaSubCategory = item.mediaSubCategory;
        this.serviceDate = new Date(item.serviceDate);
        this.note = item.note;
        this.actualAmount = utils.parseValueToDecimalTwoPlaces(item.actualAmount);
        this.creative = item.creative;
        if (item.printingPrice.flNlPrice !== 0 && (HoardingLightEnum[this.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[this.lightType] === HoardingLightEnum.N_L)) {
            this.printingCost = utils.parseValueToDecimalTwoPlaces(item.printingPrice.flNlPrice);
        } else if (item.printingPrice.blPrice !== 0 && HoardingLightEnum[this.lightType] === HoardingLightEnum.B_L) {
            this.printingCost = utils.parseValueToDecimalTwoPlaces(item.printingPrice.blPrice);
        }
        if (item.mountingPrice.pricePerSqFt !== 0) {
            this.mountingCost = utils.parseValueToDecimalTwoPlaces(item.mountingPrice.pricePerSqFt);
        } else if (item.mountingPrice.pricePerUnit !== 0) {
            this.mountingCost = utils.parseValueToDecimalTwoPlaces(item.mountingPrice.pricePerUnit);
        }
        return this;
    }
}
