import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, GalleriaModule, ProgressSpinnerModule, OverlayPanelModule, TooltipModule, CheckboxModule, CalendarModule, InputTextModule, ConfirmationService, ConfirmDialogModule } from "primeng/primeng";
import { HttpModule } from "@angular/http";
import { FirebaseService } from "../../../services/shared/firebase.service";
import { DocumentComponent } from "./document.component";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { EditPoModule } from "../edit-po/edit-po.module";
import { DocumentService } from "./services/document.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectiveModules } from "../../../modules/directive.modules";



@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        SibGridModule,
        GalleriaModule,
        InputTextModule,
        ProgressSpinnerModule,
        OverlayPanelModule,
        EditPoModule,
        TooltipModule,
        CheckboxModule,
        DirectiveModules,
        CalendarModule,
        EditPoModule,
        ConfirmDialogModule
    ],

    declarations: [
        DocumentComponent
    ],

    exports: [
        DocumentComponent,
    ],
    providers: [
        FirebaseService,
        DocumentService,
        // ConfirmationService
    ]
})

export class DocumentModule { }
