import { Component, OnInit } from '@angular/core';
import { DemoCarService } from '../services/demo-car.service';
import { Car } from '../modals/car';
import { DataListHook } from '../modals/hooks/data-list-hook';
import { PaginatorHook } from '../modals/hooks/paginator-hook';

@Component({
  selector: 'sib-test-list-comp',
  templateUrl: './test-list-comp.component.html',
  styleUrls: ['./test-list-comp.component.css']
})
export class TestListCompComponent implements OnInit {
  cars: Car[] = [];
  cols: any[] = [];
  dataListHook: DataListHook = new DataListHook();
  paginatorHook: PaginatorHook = new PaginatorHook();

  constructor(private carService: DemoCarService) { }

  ngOnInit() {

    this.cols = [
      { field: 'vin', header: 'Vin' },
      { field: 'year', header: 'Year' },
      { field: 'brand', header: 'Brand' },
      { field: 'color', header: 'Color' }
    ]

    this.carService.getCars().then(cars => {

      this.cars = cars;
      this.dataListHook.data = this.cars;
      this.dataListHook.columns = this.cols;
      
      this.paginatorHook.paginator = true;
      this.paginatorHook.rows = 2;
      this.paginatorHook.totalRecords = this.dataListHook.data.length;
      this.paginatorHook.url = 'assets/data/cars-small.json'
    });

    

  }
}


