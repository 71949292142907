import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";

/**
 * Service to handle all the API calls related to user.
 * 
 * @export
 * @class UserService
 * @extends {ApiService<any, any, any>}
 */
@Injectable()

export class UserService extends ApiService<any, any, any> {
    urlPath: string = 'users'
    user: Subject<any> = new Subject();
    constructor(private http: HttpClient, private Injector: Injector) {
        super(http, Injector);
    }

    //calling the api to validate user by email for first time login.
    validateUserByEmail(email) {
        if (navigator.onLine) {
            return this.http.get<boolean>(this.baseUrl + 'users/search/existsByEmailId?emailId=' + email);

        }
    }

    deleteUser(emailId) {
        if (navigator.onLine) {
            return this.http.delete(this.baseUrl + 'users/remove?emailId=' + emailId)

        }
    }
}