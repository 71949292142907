import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { CampaignTaskUIService } from './services/campaign-task-ui.service';
import * as campaignColumnConfig from '../../../modals/column-configurations/campaigns-column-config';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { Campaign } from '../../../modals/campaigns/campaign';
import { GridConfig, SelectionMode, GridPaginationEvent, GridColumn, EditableGridFieldTypes, Alignment } from '../../../components/sib-forms/grid/grid.config';
import { GridActionButtonConfig } from '../../../components/sib-forms/grid/grid-action-button.config';
import { FormGroup, FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import * as utils from '../../../helpers/utils';
import { CampaignStatusEnum } from '../../constants/campaign-status-enum';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { GridNameEnum } from '../../constants/grid-name.enum';
import { observable, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CampaignService } from '../../../services/shared/campaign.service';
import { AppUrls } from '../../../services/urls';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import { DateUtil } from '../../../helpers/date.util';
import { CalculationService } from '../../../services/shared/calculationService';

@Component({
    selector: 'sib-campaign-tasks',
    templateUrl: './campaign-tasks.component.html',
    styleUrls: ['./campaign-tasks.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CampaignTaskComponent implements OnInit, OnDestroy {

    @Output() eEmitTotalPendingCampaignRecords: EventEmitter<any> = new EventEmitter();

    @ViewChild(GridComponent)
    gridViewChildRef: GridComponent<Campaign>;

    isLoading = false;
    gridConfig: GridConfig<Campaign> = new GridConfig<Campaign>();
    buttonConfig: GridActionButtonConfig;
    value: { campaigns: Campaign[] };
    campaignTaskGroup: FormGroup;
    campaignTaskListStatusDropdown: SelectItem[] = [];
    totalRecords = 100;
    pendingCampaigns: Campaign[] = [];

    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2.7vw',
            editable: false,
            hidden: false,
            default: true,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'campaignId',
            name: 'campaignId',
            header: 'Id',
            required: true,
            editable: false,
            hidden: false,
            width: '5.3vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'employee.fullName',
            name: 'employee',
            header: 'Employee',
            required: true,
            editable: false,
            hidden: false,
            width: '7vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.employee) {
                    return data.employee.fullName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'employee.emailId',
            name: 'employee',
            header: 'EmailId',
            required: true,
            editable: false,
            hidden: true,
            width: '180px',
            default: false,
            styleClass: 'word-break',
            permanent: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.employee) {
                    return data.employee.emailId;
                } else {
                    return '-';
                }
            },
        },
        // {
        //     field: 'customer.company',
        //     name: 'customer',
        //     header: 'Company Name',
        //     required: true,
        //     editable: false,
        //     default: false,
        //     hidden: false,
        //     styleClass: 'word-break-wrap',
        //     width: '12vw',
        //     permanent: false,
        //     sortable: true,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        //     displayFn: (data) => {
        //         if (data.customer) {
        //             return data.customer.company;
        //         } else {
        //             return '-';
        //         }
        //     }
        // },
        {
            field: 'customer.billingName',
            name: 'customer.billingName',
            header: 'Billing Name',
            required: true,
            editable: false,
            default: true,
            hidden: false,
            styleClass: 'word-break-wrap',
            width: '12vw',
            permanent: true,
            sortable: true,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.customer) {
                    return data.customer.billingName;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'displayName',
            name: 'displayName',
            header: 'Display Name',
            required: true,
            editable: false,
            permanent: true,
            hidden: false,
            width: '230px',
            styleClass: 'word-break-wrap',
            default: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'totalSqFt',
            name: 'totalSqFt',
            header: 'Sq.Ft.',
            permanent: false,
            width: '5vw',
            default: false,
            hidden: false,
            sortable: true,
            required: true,
            editable: false,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.totalSqFt) {
                    return this.currencyHelperPipe.transform(data.totalSqFt);
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'summary.grandTotal',
            name: 'summary.grandTotal',
            header: 'Total Amount',
            permanent: true,
            width: '8.3vw',
            default: true,
            hidden: false,
            sortable: true,
            required: true,
            editable: false,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false
            // },
            displayFn: (data) => {
                if (data.summary.grandTotal) {
                    return this.currencyHelperPipe.transform(utils.parseValueToDecimalTwoPlaces(data.summary.grandTotal));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'summary.minimumAmt',
            name: 'summary.minimumAmt',
            header: 'LSP',
            permanent: false,
            width: '7vw',
            default: true,
            hidden: false,
            sortable: true,
            required: true,
            editable: false,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false
            // },
            displayFn: (data) => {
                if (data.summary.minimumAmt) {
                    return this.currencyHelperPipe.transform(utils.parseValueToDecimalTwoPlaces(data.summary.minimumAmt));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'summary.lspDiffAmt',
            name: 'summary.lspDiffAmt',
            header: 'LSP Diff',
            permanent: false,
            width: '9vw',
            default: true,
            hidden: false,
            sortable: true,
            required: true,
            editable: false,
            type: EditableGridFieldTypes.CUSTOM,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false
            // },
            displayFn: (data) => {
                if (data.summary.lspDiffAmt) {
                    return this.currencyHelperPipe.transform(utils.parseValueToDecimalTwoPlaces(data.summary.lspDiffAmt));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'price.pricePerSqFt',
            name: 'price.pricePerSqFt',
            header: 'Price/Sq.Ft.',
            width: '7vw',
            default: false,
            hidden: false,
            sortable: true,
            required: true,
            editable: false,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.price.pricePerSqFt) {
                    return this.currencyHelperPipe.transform(utils.parseValueToDecimalTwoPlaces(data.price.pricePerSqFt));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'summary.printingAmt',
            name: 'summary.printingAmt',
            header: 'Printing Charges',
            width: '7vw',
            default: false,
            sortable: true,
            required: true,
            hidden: false,
            editable: false,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.summary.printingAmt) {
                    return this.currencyHelperPipe.transform(utils.parseValueToDecimalTwoPlaces(data.summary.printingAmt));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'summary.mountingAmt',
            name: 'summary.mountingAmt',
            header: 'Mounting Charges',
            width: '7vw',
            default: false,
            sortable: true,
            hidden: false,
            required: true,
            editable: false,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.summary.mountingAmt) {
                    return this.currencyHelperPipe.transform(utils.parseValueToDecimalTwoPlaces(data.summary.mountingAmt));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'totalMountedItems',
            name: 'totalMountedItems',
            header: 'Total Mounted Items ',
            width: '6vw',
            default: false,
            sortable: true,
            hidden: false,
            required: true,
            editable: false,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.totalMountedItems >= 0) {
                    const mountedCount = data.totalMountedItems;
                    const mountCount = (data.totalMountItems > 0) ? data.totalMountItems : 0;
                    if (mountCount > 0) {
                        const pctg = utils.getDecimalNum(mountedCount / mountCount * 100);
                        return (this.currencyHelperPipe.transform(mountedCount) + '(' + this.currencyHelperPipe.transform(pctg) + '%)');
                    } else {
                        return this.currencyHelperPipe.transform(data.totalMountedItems) + '(N/A)';
                    }
                } else {
                    return 0;
                }
            }
        },
        {
            field: 'totalMountItems',
            name: 'totalMountItems',
            header: 'Total Mount Items ',
            width: '6vw',
            default: false,
            sortable: true,
            hidden: false,
            required: true,
            editable: false,
            filter: {
                matchMode: 'relational',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.totalMountItems > 0) {
                    return this.currencyHelperPipe.transform(data.totalMountItems);
                } else {
                    return 0;
                }
            }
        },
        {
            field: 'campaignStartDate',
            name: 'campaignStartDate',
            header: 'Campaign Start',
            permanent: true,
            required: true,
            editable: false,
            width: '6vw',
            hidden: false,
            sortable: true,
            displayFn: (data) => {
                if (data.campaignStartDate) {
                    return DateUtil.dategridFormatter(data.campaignStartDate);
                } else {
                    return '-';
                }
            },
        },

        {
            field: 'campaignEndDate',
            name: 'campaignEndDate',
            header: 'Campaign End',
            required: true,
            width: '6vw',
            permanent: true,
            hidden: false,
            editable: false,
            sortable: true,
            displayFn: (data) => {
                if (data.campaignStartDate) {
                    return DateUtil.dategridFormatter(data.campaignEndDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'days',
            name: 'days',
            header: 'Days',
            required: true,
            editable: false,
            hidden: false,
            default: false,
            permanent: false,
            width: '4vw',
            sortable: false,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data) {
                    return this.currencyHelperPipe.transform(this.calculationService.calculateDays(data.campaignStartDate, data.campaignEndDate));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'status',
            name: 'status',
            header: 'Status',
            required: true,
            permanent: true,
            hidden: false,
            editable: false,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'is',
                placeHolder: 'Search',
                custom: true
            },
        },
        {
            field: 'note',
            name: 'note',
            header: 'Note',
            permanent: true,
            sortable: true,
            hidden: false,
            required: true,
            width: '10vw',
            editable: false,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.note) {
                    return data.note;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'approveReject',
            name: 'approveReject',
            header: 'Action',
            permanent: true,
            sortable: false,
            hidden: false,
            required: true,
            width: '6vw',
            editable: false,
            type: EditableGridFieldTypes.CUSTOM,
            // align: Alignment.CENTER,
        },
    ];

    constructor(
        private notificationServcie: NotificatoinsService,
        private campaignTaskUIService: CampaignTaskUIService,
        private userGridConfigService: UserGridConfigService,
        private campaignService: CampaignService,
        private currencyHelperPipe: CurrencyHelperPipe,
        private calculationService: CalculationService
    ) { }

    ngOnInit() {
        this.setFormGroupObjects();
        this.setFilterDropdowns();
        this.setUserGridConfig();
        this.setGridConfigObject();
    }

    setFormGroupObjects() {
        this.campaignTaskGroup = new FormGroup({
            campaignTaskGridControl: new FormControl()
        });
    }

    setFilterDropdowns() {
        this.campaignTaskListStatusDropdown = utils.createDropdown(CampaignStatusEnum, true);
        this.campaignTaskListStatusDropdown = this.campaignTaskListStatusDropdown.filter(item => (item.label === 'All' || item.value.indexOf('PENDING') !== -1));
    }

    setGridConfigObject() {
        this.gridConfig.getDefaultModel = () => new Campaign();
        this.gridConfig.model = Campaign;
        this.gridConfig.editable = false;
        this.gridConfig.selectionMode = SelectionMode.None;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        this.gridConfig.scrollable = true;
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.rowExpandMode = 'single';
        this.gridConfig.lazy = true;
        this.gridConfig.enablePagination = true;
        this.gridConfig.showColorCode = false;

        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
            this.isLoading = true;
            // if (paginationEvent.filters && !paginationEvent.filters.status) {
            //     Object.assign(paginationEvent.filters, { "status": { value: 'PENDING', matchMode: 'contains' } });
            // }
            return this.campaignService.create(paginationEvent, paginationEvent, AppUrls.PENDING).pipe(
                map((response) => {
                    this.pendingCampaigns = response['data']['content'];
                    this.totalRecords = response['data'].totalElements;
                    this.eEmitTotalPendingCampaignRecords.emit(this.totalRecords);
                    this.isLoading = false;
                    return response['data']['content'];
                }), catchError((error) => {
                    this.notificationServcie.error(error.error.message, 'Campaign Error');
                    this.isLoading = false;
                    return Observable.empty();
                }));
        };
    }

    setUserGridConfig() {
        this.userGridConfigService.get(null, '/' + GridNameEnum['CAMPAIGNS'])
            .subscribe((campaignListGridConfig) => {
                this.columns = utils.getUserConfiguredColumns(campaignListGridConfig, this.columns);
                this.gridViewChildRef.setAllColumns();
            });
    }

    getCampaignStatusValue(status) {
        return CampaignStatusEnum[status];
    }

    approveCampaign(campaign) {
        this.isLoading = true;
        this.campaignTaskUIService.approveCampaign(campaign).subscribe(
            (response) => {
                if (response) {
                    this.notificationServcie.success('Campaign Approved Successfully', 'Campaign Approval');
                    this.refreshGrid();
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.refreshGrid();
                this.isLoading = false;
            }
        );
    }

    rejectCampaign(campaign) {
        this.isLoading = true;
        this.campaignTaskUIService.rejectCampaign(campaign).subscribe(
            (response) => {
                if (response) {
                    this.notificationServcie.success('Campaign Rejected Successfully', 'Campaign Rejection');
                    this.refreshGrid();
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.refreshGrid();
                this.isLoading = false;
            }
        );
    }

    updatingUserGridConfig(columnArray) {
        let campaignListGridConfig: Map<string, string> = new Map();
        campaignListGridConfig = utils.getGridConfigMap(columnArray, this.columns);
        this.userGridConfigService.patch(utils.buildObject(campaignListGridConfig), null, GridNameEnum['CAMPAIGNS'])
            .subscribe((result) => {
            });
    }

    getIntegerRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    getAbsoluteValue(value) {
        return Math.abs(value);
    }

    refreshGrid() {
        if (this.gridConfig.dataLoadFunction) {
            this.gridViewChildRef.refresh();
        }
    }

    ngOnDestroy() { }

}

