import { PaginationData } from "../paginationdata";
import { LazyLoadEvent } from "primeng/components/common/lazyloadevent";
import { EventData } from "../eventdata";

export class AreaQueryParams {
    countryId?:string;
    countryCode?: string;
    stateId?:string;
    districtId?:string;
    cityId?:string;
    projection?: string;
    criteria?: string;
    name?: string;
    pageData?: PaginationData;
    lazyData?: LazyLoadEvent;
    eventData?: EventData;
}