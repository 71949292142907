
import { map } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PlanNameService extends ApiService<any, any, any> {
    urlPath: string = 'plans?projection=planWithNameProjection';

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getPlanById(id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/' + id + '?projection=planProjection').pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    getAllPlans() {
        // return this.Http.post(this.baseUrl + 'plans/search/all', null).pipe(map((response: Response) => {
        //     return response;
        // }));
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/idDisplayName').pipe(map((response: Response) => {
                return response;
            }));
        }

    }


}