import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Area } from "../../modals/Area/area";

@Injectable()
export class AreaPostService extends ApiService<any, any, any> {
    urlPath: string = 'areas';
    // urlPath: string = 'areas/save';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }
}