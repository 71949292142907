import { Injectable } from "@angular/core";

/**
 * AppConfig class
 * Service class to be used for providing app level configurations.
 * Loads the initial set of configuration from the environment properties.
 * 
 * @export
 * @class AppConfig
 */
@Injectable()
export class AppConfig {
    
    static factory(initialConfig: Object) {
        return new AppConfig(initialConfig);
    }

    /**
     * Configuration object.
     * This object stores the configuration properties
     * 
     * @private
     * @type {Object}
     * @memberOf AppConfig
     */
    private config: Object = null;

    /**
     * Creates an instance of AppConfig.
     * 
     * @param {Http} http
     * 
     * @memberOf AppConfig
     */
    constructor(initialConfig: any) {
        // set the initial configuration as the values passed by environment variable from ng-cli/webpack
        // environment property values depend on the build environment and hence, this values depend on the build environment variable
        this.config = initialConfig;
    }

    /**
     * Get a value from configuration 
     * 
     * Returns undefined if the key is not undefined
     * Allows access to nested properties using string notations
     * Eg: for config property urls having the following value
     * urls : {
     *      api : 'http://a.b.com',
     *      ws: 'ws://a.b.com'
     *  }
     * 
     * one can access api property using 'urls.api'. For array properties indexes can be used with bracket notations like 'urls.api[0]' 
     * 
     * @param {string} key
     * @returns {*}
     * 
     * @memberOf AppConfig
     */
    public get(key: string) : any {
        return this.getByString(key);
    }

    /**
     * Return a config value from config object using string key
     * 
     * @private
     * @param {string} key
     * @returns
     * 
     * @memberOf AppConfig
     */
    private getByString(key: string) {
        key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        key = key.replace(/^\./, '');           // strip a leading dot
        var a = key.split('.');
        var o = this.config;
        try {
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
        } catch (e) {
            console.error(e);
            return undefined;

        }

        return o;
    }

    /**
     * Set a value in configuration for a key 
     * 
     * This overwrites a previous value including the ones coming from environment variables, hence HANDLE WITH CARE.
     * 
     * 
     * @param {string} key
     * @param {*} value
     * 
     * @memberOf AppConfig
     */
    public set(key: string, value: any) {
        this.config[key] = value;
    }
}