import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class HoardingMasterColService {
    selectedColumns: Subject<any[]> = new Subject();
    defaultColumns: Subject<any[]> = new Subject();
    siteFromDate: Subject<Date> = new Subject();
    statuses: Subject<any[]> = new Subject();
    globalFilter: Subject<any> = new Subject();
    monthlyRateValues: Subject<any> = new Subject();
}
