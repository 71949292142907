import { TransactionTypeEnum } from '../../shared/constants/transaction-type-enum';
import { TransactionEventEnum } from '../../shared/constants/transaction-event-enum';
import { SibModuleEnum } from '../../shared/constants/sib-module-enum';

export class Transaction {
    date: Date;
    description: string;
    amount: number;
    availableBalance: number;
    customId: string;
    transactionType: TransactionTypeEnum;
    transactionEvent: TransactionEventEnum;
    module: SibModuleEnum;
    moduleId: string;
}
