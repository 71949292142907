export class Correspondence {
    id: string;
    to: string[] = [];
    from: string;
    cc: string[] = [];
    bcc: string[] = [];
    message: string;
    subject: string;
    nameToAttachments: Map<string, ArrayBuffer[]>;
    attachImages = false;
    attachPPT = false;
    attachXLS = false;
    attachInvoice = false;

    setCorrespondence(item): Correspondence {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const loggedInEmail = userInfo[0].email;
        this.id = item.id;
        if (!item.campaign) {
            if (item.customer && item.customer.pocs) {
                item.customer.pocs.forEach((poc) => {
                    if (poc.emailVerified) {
                        this.to.push(poc.emailId);
                    }
                });
            }
            this.subject = item.displayName;
        } else {
            if (item.campaign.customer && item.campaign.customer.pocs) {
                item.campaign.customer.pocs.forEach((poc) => {
                    if (poc.emailVerified) {
                        this.to.push(poc.emailId);
                    }
                });
            }
            this.subject = item.campaign.displayName;
        }
        this.from = loggedInEmail;
        if (loggedInEmail !== item.employee.emailId) {
            this.cc.push(item.employee.emailId);
        }
        return this;
    }
}
