import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CreditNote } from '../../modals/billings/credit-note';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CreditNotesService } from '../../services/shared/credit-note.service';
import { AppUrls } from '../../services/urls';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { BillingUIService } from '../services/billingUI.service';
import { InvoiceCharEnum } from '../../modals/billings/invoice-char-enum';
import { MenuItem, OverlayPanel } from 'primeng/primeng';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DateUtil } from '../../helpers/date.util';
import * as utils from '../../helpers/utils';
import * as _ from "lodash";
import { InvoiceStatus } from '../../shared/constants/invoice-status-enum';
import { PrintConfig } from '../../modals/billings/print-config';
import * as $ from 'jquery';

@Component({
    selector: 'sib-cn-view',
    templateUrl: './cn-view.component.html',
    styleUrls: ['./cn-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CNViewComponent implements OnInit, OnDestroy {

    @ViewChild('printMenu')
    printOverlay: OverlayPanel;

    isLoading = false;
    creditNote: CreditNote = new CreditNote();
    creditNoteId = null;
    subscription: Subscription[] = [];
    isTallyEntered: InvoiceCharEnum;
    isPrinted: InvoiceCharEnum;
    isTallyEnteredBoolean = false;
    isPrintedBoolean = false;
    isDuplicate = false;
    isWithoutLetterHead = false;
    printMenu: MenuItem[] = [];
    safePdfUrl: SafeResourceUrl;
    safePdfName: string;
    currentDate = new Date();
    defaultTallyId: string;
    preview = false; // for printing

    constructor(
        private route: ActivatedRoute,
        private creditNoteService: CreditNotesService,
        private notificationServcie: NotificatoinsService,
        private billingUIService: BillingUIService,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit() {
        this.currentDate = new Date();
        this.getRouteId();
    }

    getRouteId() {
        this.subscription.push(this.route.params.subscribe((params: { id: string }) => {
            this.creditNoteId = params.id;
            this.getCreditNote();
        }));
    }

    getCreditNote() {
        this.isLoading = true;
        this.subscription.push(this.creditNoteService.get({ 'id': this.creditNoteId }, AppUrls.BY_ID).subscribe(
            (response) => {
                if (response) {
                    this.creditNote = response['data'];
                    this.creditNote = JSON.parse(JSON.stringify(this.creditNote));
                    this.setTallyAndPrintData();
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        ));
    }

    setTallyAndPrintData() {
        this.isTallyEntered = this.creditNote.tallyEntered;
        this.isPrinted = this.creditNote.printed;
        this.setInvoicePrintAndTallyBooleanValues();
    }

    setInvoicePrintAndTallyBooleanValues() {
        if (InvoiceCharEnum[this.isPrinted] !== InvoiceCharEnum.N) {
            this.isPrintedBoolean = (InvoiceCharEnum[this.isPrinted] !== InvoiceCharEnum.F) ? true : false;
        }
        if (InvoiceCharEnum[this.isTallyEntered] !== InvoiceCharEnum.N) {
            this.isTallyEnteredBoolean = (InvoiceCharEnum[this.isTallyEntered] !== InvoiceCharEnum.F) ? true : false;
        }
    }

    checkIsPrinted() {
        if (InvoiceCharEnum[this.isPrinted] === InvoiceCharEnum.N) {
            return "N";
        } else if (InvoiceCharEnum[this.isPrinted] === InvoiceCharEnum.F) {
            return "F";
        } else if (InvoiceCharEnum[this.isPrinted] === InvoiceCharEnum.T) {
            return "T";
        }
    }

    onPrintedChange(event) {
        this.isPrintedBoolean = event;
        this.isPrinted = event ? InvoiceCharEnum['T'] : InvoiceCharEnum['F'];
        this.creditNote.printed = utils.getKey(InvoiceCharEnum, (event) ? InvoiceCharEnum.T : InvoiceCharEnum.F);
        this.creditNoteService.markCNAsPrinted((event) ? 'T' : 'F', this.creditNote.id).subscribe((response) => {
            this.isPrintedBoolean ? this.notificationServcie.success("Credit Note Marked As Printed", "Credit Note Marked As Printed Successfully") : this.notificationServcie.success("Credit Note Unmarked As Printed", "Credit Note Unmarked As Printed Successfully");
        });
    }

    checkIsTallyEntered() {
        if (InvoiceCharEnum[this.isTallyEntered] === InvoiceCharEnum.N) {
            return "N";
        } else if (InvoiceCharEnum[this.isTallyEntered] === InvoiceCharEnum.F) {
            return "F";
        } else if (InvoiceCharEnum[this.isTallyEntered] === InvoiceCharEnum.T) {
            return "T";
        }
    }

    onTallyEnteredChange(event) {
        this.isTallyEnteredBoolean = event;
        this.creditNote.tallyEntered = utils.getKey(InvoiceCharEnum, (event) ? InvoiceCharEnum.T : InvoiceCharEnum.F);
        this.isTallyEntered = event ? InvoiceCharEnum['T'] : InvoiceCharEnum['F'];
        // if (event) {
        // changes for removing tally invoice id for vendor - chitra
        this.defaultTallyId = this.creditNote.id;
        // this.showTallyDialog();
        // }
        this.creditNoteService.markCNAsTallyEntered((event) ? 'T' : 'F', this.creditNote.id, this.defaultTallyId).subscribe((response) => {
            if (!event) {
                this.notificationServcie.success("Credit Note Unmarked As Tally Entered", "Credit Note Unmarked As Tally Entered Successfully");
            }
            // changes for removing tally Credit Note id for vendor - chitra
            else {
                this.notificationServcie.success("Credit Note Marked As Tally Entered", "Credit Note Marked As Tally Entered Successfully");
            }
        });
    }

    checkPermission(permission) {
        return this.billingUIService.checkPermission(permission);
    }

    onPrintTypeChange(event) {
        this.creditNote.printConfiguration.original = event;
        this.creditNote = JSON.parse(JSON.stringify(this.creditNote));
    }

    onPrintLetterHeadChange(event) {
        this.creditNote.printConfiguration.withLetterHead = event;
        this.creditNote = JSON.parse(JSON.stringify(this.creditNote));
    }

    previewPrintItems(printConfig: PrintConfig) {
        this.preview = true;
        this.printCn(printConfig);
    }

    printItems(printConfig: PrintConfig) {
        this.preview = false;
        this.printCn(printConfig);
    }

    printCn(printConfig: PrintConfig) {
        this.checkForAddBlock();
        this.creditNoteService.printCn(utils.createPrintWrapperObject(printConfig, this.creditNote.invoiceFormat, this.preview, null), this.creditNote.id)
            .subscribe(
                (response) => {
                    if (response) {
                        console.log("response", response);
                        const blob = new Blob([response], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        this.safePdfName = (this.creditNote.id ? this.creditNote.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
                        this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        this.isLoading = false;
                        window.open(url, "_blank").focus(); // need to disable adblock for this
                    }
                },
                (error) => {
                    this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                    this.isLoading = false;
                }
            );
    }

    checkForAddBlock() {
        if ($("#bottomAd").height() > 0) {
            console.log("ad block inctive", $("#bottomAd").height());
        } else {
            console.log("ad block active", $("#bottomAd").height());
            this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
        }
    }

    printingItem(isDuplicate, isWithoutLetterHead) {
        this.printOverlay.hide();
        if (isWithoutLetterHead) {
            this.printCNWithoutLetterHead(isDuplicate);
        } else {
            this.printCNWithletterHead(isDuplicate);
        }
    }

    printCNWithoutLetterHead(isDuplicate) {
        this.isLoading = true;
        this.creditNoteService.printCNWithoutLetterHead(this.creditNote.id, isDuplicate).subscribe(
            (response) => {
                if (response) {
                    console.log("response", response);
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    this.safePdfName = (this.creditNote.id ? this.creditNote.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
                    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.isLoading = false;
                    window.open(url, "_blank").focus(); // need to disable adblock for this
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    printCNWithletterHead(isDuplicate) {
        this.isLoading = true;
        this.creditNoteService.printCNWithLetterHead(this.creditNote.id, isDuplicate).subscribe(
            (response) => {
                if (response) {
                    console.log("response", response);
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    this.safePdfName = (this.creditNote.id ? this.creditNote.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
                    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.isLoading = false;
                    window.open(url, "_blank").focus(); // need to disable adblock for this
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    getStatus(status) {
        return InvoiceStatus[status];
    }

    getClass(status) {
        if (InvoiceStatus[status]) {
            if (InvoiceStatus[status].includes("Pending") || InvoiceStatus[status].includes("progress")) {
                return "color-pending";
            } else if (InvoiceStatus[status].includes("Rejected")) {
                return "color-rejected";
            } else {
                return "color-approved";
            }
        }
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

}
