import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { SIBConstants } from '../../constants/SIBConstant';
import { Title } from '@angular/platform-browser';
import { TasksUIService } from './services/task-ui.service';
import { Subscription } from 'rxjs';
import { InvoiceTaskCard } from '../../../modals/billings/invoice-task-card';
import { InvoiceStatus } from '../../constants/invoice-status-enum';
import { InvoiceCharEnum } from '../../../modals/billings/invoice-char-enum';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { TasksService } from '../../../services/shared/tasks.service';
import { PendingTaskCard } from '../../../modals/tasks/pending-task-cards';
import { AppUrls } from '../../../services/urls';
import * as _ from "lodash";
import { CreditNotesTaskCardsService } from '../../../services/shared/credit-notes-task-cards.service';
import { PendingUnbilledUIService } from '../pending-unbilled-tasks/services/pending-unbilled-ui.service';
import { InvoiceChartService } from '../../../services/shared/invoice-chart.service';
import { CampaignService } from '../../../services/shared/campaign.service';
import { GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { InvoiceService } from '../../../services/shared/invoice.service';
import { HoardingStatusService } from '../../../hoardings-operation/services/hoardings-status.service';
import * as utils from '../../../helpers/utils';

@Component({
    selector: 'sib-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TaskComponent implements OnInit, OnDestroy {

    activeIndex = 0;
    isLoading = false;
    subscription: Subscription[] = [];
    invoiceTaskCards: InvoiceTaskCard[] = [];
    srTaskCards: InvoiceTaskCard[] = [];
    creditNotesTaskCards: InvoiceTaskCard[] = [];
    tasksCards: PendingTaskCard[] = [];
    customFilter: any;
    showEmployee = false;
    tabPermissions: any[] = [];
    totalPendingUnbilledRecords = 0;
    totalPendingInvoiceRecords = 0;
    totalPendingCampaignRecords = 0;
    totalPendingSrRecords = 0;
    moduleName: string;
    totalPendingCreditNotesRecords = 0;
    totalPendingTasks: number = 0;
    totalSitesExpirationTaskRecords: number = 0;
    totalRatePendingTasksRecords: number = 0;
    searchEvent: GridPaginationEvent = {
        filters: {},
        globalFilter: '',
        sortField: '',
        sortOrder: 1,
        multiSortMetas: []
    };
    daysSearchEvent: GridPaginationEvent = {
        filters: {},
        globalFilter: '',
        sortField: '',
        sortOrder: 1,
        multiSortMetas: []
    };
    permissions: any[] = [];

    constructor(
        private pageTitle: Title,
        private tasksUIService: TasksUIService,
        private notificationServcie: NotificatoinsService,
        private tasksService: TasksService,
        private pendingUnbilledUIService: PendingUnbilledUIService,
        private invoiceChartService: InvoiceChartService,
        private campaignService: CampaignService,
        private creditNotesTaskCardsService: CreditNotesTaskCardsService,
        private invoiceService: InvoiceService,
        private campaignItemsService: HoardingStatusService
    ) { }

    ngOnInit() {
        let appTitle = utils.getAppTitle();
        !appTitle ? this.pageTitle.setTitle(SIBConstants.TASKS_MENU) : this.pageTitle.setTitle(SIBConstants.TASKS_MENU + ' - ' + appTitle);
        this.getTaskCount();
        this.setTabPermissions();
        this.getLastActiveTabIndex();
        this.setShowEmployee();
        this.getInvoiceTaskCards();
        this.getTaskCards();
        this.getCreditNotesTaskCards();
        if (this.checkPermission("read:sr-tasks")) {
            this.getSrTaskCards();
        }
    }

    setTabPermissions() {
        this.tabPermissions = [
            'read:pending-unbilled-tasks',
            'read:invoice-tasks',
            'read:campaign-tasks',
            // 'read:sr-tasks',
            'read:cn-tasks',
            'read:pending-tasks',
            'read:sites-expiring-tasks',
            'read:rate-approval-tasks'
        ];

    }

    checkTasksActiveIndex(permission) {
        this.permissions = _.cloneDeep(this.tabPermissions)
        let count = 0;
        let index = this.tabPermissions.findIndex((item) => item === permission);
        const filteredPermissions = this.permissions.splice(0, index);
        filteredPermissions.forEach((item) => {
            if (!this.checkPermission(item)) {
                count += 1;
            }
        });

        return (index - count);

    }


    setInvoiceTaskCardsRole() {
        this.invoiceTaskCards.forEach((card) => {
            // if (this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ADMIN_GEN_PENDING || this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ADMIN_EDIT_PENDING || this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ACCOUNTANT_INVOICE_CANCEL || this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION) {
            // if (this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_GEN_PENDING || this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_EDIT_PENDING || this.getInvoiceStatus(card.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL || this.getInvoiceStatus(card.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION) {
            if (this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_GEN_PENDING || this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_EDIT_PENDING || this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_CANCEL_PENDING) {
                card.role = "Admin";
            }
            if (this.getInvoiceStatus(card.status) === InvoiceStatus.EDIT_PENDING) {
                card.role = "Sales Rep";
            }
            if (!card.role) {
                if (InvoiceCharEnum[card.printed] === InvoiceCharEnum.F) {
                    card.role = "Bill Handler";
                } else if (InvoiceCharEnum[card.tallyEntered] === InvoiceCharEnum.F) {
                    card.role = "Accountant";
                }
            }
        });
        this.invoiceTaskCards = JSON.parse(JSON.stringify(this.invoiceTaskCards));
    }

    setShowEmployee() {
        if (this.checkPermission('readAll') || this.checkPermission('view:employee-column')) {
            this.showEmployee = true;
        } else {
            this.showEmployee = false;
        }
    }

    checkPermission(permission) {
        return this.tasksUIService.checkPermission(permission);
    }

    getInvoiceStatus(status) {
        // if (InvoiceStatus[status]) {
        //     return InvoiceStatus[status];
        // }
        // if (InvoiceStatus.ADMIN_PENDING[status]) {
        //     return InvoiceStatus.ADMIN_PENDING[status];
        // }
        return InvoiceStatus[status];
    }

    getInvoiceTaskCards() {
        this.isLoading = true;
        this.tasksUIService.getInvoiceTaskCards().subscribe(
            (response) => {
                if (response && response.length) {
                    this.invoiceTaskCards = response;
                    this.setInvoiceTaskCardsRole();
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }

    getSrTaskCards() {
        // this.isLoading = true;
        // this.tasksUIService.getInvoiceTaskCards().subscribe(
        //     (response) => {
        //         if (response && response.length) {
        //             this.srTaskCards = response;
        //             this.setInvoiceTaskCardsRole();
        //             this.isLoading = false;
        //         } else {
        //             this.isLoading = false;
        //         }
        //     },
        //     (error) => {
        //         this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
        //         this.isLoading = false;
        //     }
        // );
    }

    getTaskCards() {
        this.isLoading = true;
        this.tasksService.get(null, AppUrls.PENDING_TASKS).subscribe(
            (response) => {
                if (response && response.length) {
                    this.tasksCards = response;
                    this.isLoading = false;
                }
                else {
                    this.tasksCards = [];
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );

    }

    setCreditNotesTaskCardsRole() {
        this.creditNotesTaskCards.forEach((card) => {
            if (this.getInvoiceStatus(card.status) === InvoiceStatus.ADMIN_FULL_CN_PENDING) {
                card.role = "Admin";
            }
            if (!card.role) {
                if (InvoiceCharEnum[card.printed] === InvoiceCharEnum.F) {
                    card.role = "Bill Handler";
                } else if (InvoiceCharEnum[card.tallyEntered] === InvoiceCharEnum.F) {
                    card.role = "Accountant";
                }
            }
        });
        this.creditNotesTaskCards = JSON.parse(JSON.stringify(this.creditNotesTaskCards));
    }

    getCreditNotesTaskCards() {
        this.isLoading = true;
        this.creditNotesTaskCardsService.get(null, AppUrls.TASK_CARDS).subscribe(
            (response) => {
                if (response) {
                    this.creditNotesTaskCards = response;
                    this.setCreditNotesTaskCardsRole();
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!')
                this.isLoading = false;
            }
        );
    }

    getPendingTasks(event) {
        this.totalPendingTasks = event;
    }

    isActionPerformed(event) {
        if (event) {
            this.getTaskCards();
        }
    }

    setCustomFilter(filters) {
        this.customFilter = filters;
        this.customFilter = JSON.parse(JSON.stringify(this.customFilter));
    }

    setTask(task) {

    }

    getModuleName(moduleName) {
        this.moduleName = moduleName;
    }

    onTabChange(index) {
        this.activeIndex = index;
        this.tasksUIService.setTasksTabIndex(index);
    }

    getLastActiveTabIndex() {
        const index = Number(this.tasksUIService.getTasksTabIndex());
        const count = this.getAvailableTabPermissions();
        if (count !== -1) {
            if (count >= index) {
                this.onTabChange(index);
            } else {
                this.onTabChange(0);
            }
        } else {
            // do nothing
        }
    }

    getAvailableTabPermissions() {
        let count = -1;
        this.tabPermissions.forEach((perm) => {
            count = this.checkPermission(perm) ? ++count : count;
        });
        return count;
    }

    setTotalPendingUnbilledRecords(event) {
        this.totalPendingUnbilledRecords = event;
    }

    setTotalPendingInvoiceRecords(event) {
        this.totalPendingInvoiceRecords = event;
    }

    setTotalPendingCampaignRecords(event) {
        this.totalPendingCampaignRecords = event;
    }

    setTotalPendingSrRecords(event) {
        this.totalPendingSrRecords = event;
    }

    setTotalPendingCreditNotesRecords(event) {
        this.totalPendingCreditNotesRecords = event;
    }

    setTotalPendingSiteExpiration(event) {
        this.totalSitesExpirationTaskRecords = event
    }

    setTotalRatePendingRecords(event) {
        this.totalRatePendingTasksRecords = event;
    }

    isActionPerformedSuccess(event) {
        if (event) {
            this.getCreditNotesTaskCards();
        }
    }

    getTaskCount() {
        this.getPendingUnbilledTaskCount();
        this.getInvoiceTaskCount();
        this.getCampaignTaskCount();
        this.getSrTaskCount();
        this.getCnTaskCount();
        this.getPendingTaskCount();
        this.getSiteExpirationTaskCount();
        this.getRateApprovalTasksCount()
    }

    getPendingUnbilledTaskCount() {

        this.pendingUnbilledUIService.getPendingUnbilledTaskCount(this.searchEvent).subscribe((response) => {
            if (response) {
                this.totalPendingUnbilledRecords = response['data'];
                // console.log(this.totalPendingUnbilledRecords, "Total pending unbilled task count");
            }
        })
        // (error) => {
        //     this.notificationServcie.error(error, "Error!!");
        // }
    }

    getInvoiceTaskCount() {
        const paginationData = {
            sortField: 'days',
            sortOrder: 1
        }

        this.daysSearchEvent = Object.assign(this.daysSearchEvent, paginationData)
        var filter = { days: { value: [0, 30], matchMode: "between" } }
        Object.assign(this.daysSearchEvent.filters, filter);
        this.invoiceChartService.getInvoiceTaskCount(this.daysSearchEvent).subscribe((response) => {
            if (response) {
                this.totalPendingInvoiceRecords = response['data'];
                // console.log(this.totalPendingInvoiceRecords, "Total Invoice Task count");
            }
        });
    }

    getCampaignTaskCount() {
        this.campaignService.getCampaignTaskCount(this.searchEvent).subscribe((response) => {
            if (response) {
                this.totalPendingCampaignRecords = response['data'];
                // console.log(this.totalPendingCampaignRecords, "Total campaign task count");
            }
        })
    }

    getSrTaskCount() {
        this.invoiceService.getSrTaskCount(this.searchEvent).subscribe((response) => {
            if (response) {
                this.totalPendingSrRecords = response['data'];
                // console.log(this.totalPendingSrRecords, "Total Sr task count");
            }
        })
    }

    getCnTaskCount() {
        this.creditNotesTaskCardsService.getCnTaskCount(this.searchEvent).subscribe((response) => {
            if (response) {
                this.totalPendingCreditNotesRecords = response['data'];
                // console.log(this.totalPendingCreditNotesRecords, "Total CN task count");
            }
        })
        // (error) => {
        //     this.notificationServcie.error("Some Technical Issue", "Error!!");
        // }
    }

    getPendingTaskCount() {
        this.tasksService.getPendingTaskCount(this.searchEvent).subscribe((response) => {
            if (response) {
                this.totalPendingTasks = response['data'];
                // console.log(this.totalPendingTasks, "Pending conflict tasks");
            }
        })
    }

    getSiteExpirationTaskCount() {
        const paginationData = {
            sortField: 'days',
            sortOrder: 1
        }

        this.daysSearchEvent = Object.assign(this.daysSearchEvent, paginationData)
        var filter = { days: { value: [0, 30], matchMode: "between" } }
        Object.assign(this.daysSearchEvent.filters, filter);
        this.campaignItemsService.create(this.daysSearchEvent, null, AppUrls.EXPIRED_IN + AppUrls.COUNT).subscribe((response) => {
            if (response) {
                this.totalSitesExpirationTaskRecords = response['data'];
            }
        })
    }

    getRateApprovalTasksCount() {
        this.campaignItemsService.get(this.searchEvent, AppUrls.PENDING_RATE + AppUrls.COUNT).subscribe((response) => {
            if (response) {
                this.totalRatePendingTasksRecords = response['data']
            }
        })
    }

    ngOnDestroy() { }

}
