import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { GooglePieChartService } from '../services/shared/pieChart.services';
import { GanttChartConfig } from '../modals/charts/ganttChart.config';
import { PieChartConfig } from '../modals/charts/pieChart.config';
import { GanttChartService } from '../services/shared/ganttChart.services';
import { StackedBarChartConfig } from '../modals/charts/stackedBarChart.config';
import { GoogleStackedBarChartService } from '../services/stackedBarChart.service';
import { TimelineChartConfig } from '../modals/charts/timelineChart.config';
import { GoogleTimelineChartService } from '../services/shared/timeline-chart.service';
import { GoogleHistogramChartService } from '../services/shared/histogramChart.service';
import { HistogramChartConfig } from '../modals/charts/histogramChart.config';
import { GoogleTableChartService } from '../services/shared/table-chart.service';
import { TableChartConfig } from '../modals/charts/tableChart.config';
import { LineChartConfig } from '../modals/charts/lineChart.config';
import { GoogleLineChartService } from '../services/shared/lineChart.services';
import { ColumnChartConfig } from '../modals/charts/columnChart.config';
import { GoogleColumnChartService } from '../services/shared/column-chart.service';
import { GoogleAreaChartService } from '../services/shared/area-chart.service';
import { AreaChartConfig } from '../modals/charts/areaChart.config';

@Component({
  selector: 'sib-chart',
  templateUrl: './sib-chart.component.html',
  styleUrls: ['./sib-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SibChartComponent implements OnInit, AfterViewInit {
  // @Input() data: any[];
  _data: any[];
  @Input() set data(value) {
    this._data = value;
    this.showChart();
  }
  get data(): any[] {
    return this._data;
  }
  _chartType: string;
  @Input() set chartType(chartType) {
    this._chartType = chartType;
  }
  @Input() pieConfig: PieChartConfig;
  @Input() stackedBarConfig: StackedBarChartConfig;
  @Input() elementId: string;
  @Input() timelineConfig: TimelineChartConfig;
  @Input() histogramConfig: HistogramChartConfig;
  @Input() tableChartConfig: TableChartConfig;
  @Input() lineConfig: LineChartConfig;
  @Input() columnConfig: ColumnChartConfig;
  _areaChartConfig: AreaChartConfig;
  @Input() set areaChartConfig(areaChartConfig) {
    this._areaChartConfig = areaChartConfig;
    this.showChart();
  }
  get areaChartConfig(): any {
    return this._areaChartConfig;
  }

  @Output() eEmitPieChartLoaded: EventEmitter<any> = new EventEmitter();
  @Output() eEmitStackedBarChartLoaded: EventEmitter<any> = new EventEmitter();
  @Output() eEmitAreaChartLoaded: EventEmitter<any> = new EventEmitter();
  @Output() eEmitColumnChartLoaded: EventEmitter<any> = new EventEmitter();

  // isLoading = false;

  constructor(
    private pieChartService: GooglePieChartService,
    private stackedBarChartService: GoogleStackedBarChartService,
    private timelineChartService: GoogleTimelineChartService,
    private histogramChartService: GoogleHistogramChartService,
    private tableChartService: GoogleTableChartService,
    private lineChartService: GoogleLineChartService,
    private columnChartService: GoogleColumnChartService,
    private areaChartService: GoogleAreaChartService
  ) {

  }
  ngOnInit() {
    this.pieChartService.chartLoaded.subscribe((value) => {
      if (value) {
        // this.isLoading = false;
        this.eEmitPieChartLoaded.emit(true);
      }
    });
    this.stackedBarChartService.chartLoaded.subscribe((value) => {
      if (value) {
        // this.isLoading = false;
        this.eEmitStackedBarChartLoaded.emit(true);
      }
    });
    this.timelineChartService.chartLoaded.subscribe((value) => {
      if (value) {
        // this.isLoading = false;
      }
    });
    this.histogramChartService.chartLoaded.subscribe((value) => {
      if (value) {
        // this.isLoading = false;
      }
    });
    this.tableChartService.chartLoaded.subscribe((value) => {
      if (value) {
        // this.isLoading = false;
      }
    });
    this.lineChartService.chartLoaded.subscribe((value) => {
      if (value) {
        // this.isLoading = false;
      }
    });
    this.areaChartService.chartLoaded.subscribe((value) => {
      if (value) {
        this.eEmitAreaChartLoaded.emit(false);
      }
    });
    this.columnChartService.chartLoaded.subscribe((value) => {
      if (value) {
        this.eEmitColumnChartLoaded.emit(false);
      }
    })
  }

  ngAfterViewInit() {
    this.showChart();
  }

  showChart() {
    switch (this._chartType) {
      case 'pieChart': {
        this.showPieChart();
        break;
      }

      case 'stackedBarChart': {
        this.showStackedBarChart();
        break;
      }

      case 'timelinechart': {
        this.showTimelineChart();
        break;
      }

      case 'histogram': {
        this.showHistogramChart();
        break;
      }

      case 'table': {
        this.showTableChart();
        break;
      }

      case 'linechart': {
        this.showLineChart()
        break;
      }

      case 'columnChart': {
        this.showColumnChart()
        break;
      }

      case 'areaChart': {
        this.showAreaChart();
        break;
      }
    }
  }

  showPieChart() {
    // this.isLoading = true;
    this.pieChartService.buildPieChart(this.elementId, this.data, this.pieConfig);
  }

  showStackedBarChart() {
    // this.isLoading = true;
    this.stackedBarChartService.buildStackedBarChart(this.elementId, this.data, this.stackedBarConfig);
  }

  showTimelineChart() {
    // this.isLoading = true;
    this.timelineChartService.buildTimelineChart(this.elementId, this.data, this.timelineConfig);
  }

  cShowTimelineChart(timelineConfig?: any, data?: any) {
    this.timelineChartService.buildTimelineChart(this.elementId, this.data, timelineConfig)
    // this.isLoading = true;
  }

  showHistogramChart() {
    // this.isLoading = true;
    this.histogramChartService.buildHistogramChart(this.elementId, this.data, this.histogramConfig);
  }

  showTableChart() {
    this.tableChartService.buildTableChart(this.elementId, this.data, this.tableChartConfig)

  }

  showAreaChart() {
    this.areaChartService.buildAreaChart(this.elementId, this.data, this.areaChartConfig)
  }

  cShowTableChart(tableChartConfig?: any) {
    // this.isLoading = true;
    this.tableChartService.buildTableChart(this.elementId, this.data, tableChartConfig);
  }

  showLineChart() {
    // this.isLoading = true;
    this.lineChartService.buildLineChart(this.elementId, this.data, this.lineConfig);
  }

  cshowLineChart(lineChartConfig) {
    // this.isLoading = true;
    this.lineChartService.buildLineChart(this.elementId, this.data, lineChartConfig);
  }

  showColumnChart() {
    var googleChart = this.columnChartService.buildColumnChart(this.elementId, this.data, this.columnConfig);
  }

  cshowColumnChart(config) {
    this.columnChartService.buildColumnChart(this.elementId, this.data, config)
  }

  cshowStackedBarChart(config) {
    this.stackedBarChartService.buildStackedBarChart(this.elementId, this.data, config);
  }

}
