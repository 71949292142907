export class ROIItem {
    amount: number;
    billingName: string;
    customId: string;
    displayName: string;
    invoiceId: string;
    location: string;
    ranges: any[];
    rentItemCategory: string;
    size: string;
}