import { GridColumn, EditableGridFieldTypes, Alignment } from '../../components/sib-forms/grid/grid.config';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { DateUtil } from '../../helpers/date.util';
import { KioskTypeEnum } from '../../shared/constants/kiosk-type.enum';
import * as utils from '../../helpers/utils';
import * as mapUtils from '../../helpers/map.utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { CalculationService } from '../../services/shared/calculationService';
import { InvoiceStatus } from '../../shared/constants/invoice-status-enum';

export const SalesChartHoardingColumns: Array<GridColumn> = [
    {
        field: 'srNo',
        name: 'srNo',
        header: 'Sr ',
        required: true,
        width: '3vw',
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        styleClass: 'sr-number',
        type: EditableGridFieldTypes.CUSTOM,
    },
    {
        field: 'rentItems.customId',
        name: 'rentItems.customId',
        header: 'HID',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '7vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
        displayFn: (data) => {
            if (data && data.rentItems && data.rentItems.customId) {
                return data.rentItems.customId;
            } else {
                return '-';
            }
        }
    },
    {
        field: 'invoiceId',
        name: 'invoiceId',
        header: 'Invoice',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '9vw',
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
        // displayFn: (data) => {
        //     if (data && data.invoiceId) {
        //         return data.invoiceId;
        //     } else {
        //         return '-';
        //     }
        // }
    },
    {
        field: 'rentItems.city',
        name: 'rentItems.city',
        header: 'City',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '8vw',
        sortable: false,
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //     matchMode: 'contains',
        //     placeHolder: 'Search',
        //     custom: false
        // },
        displayFn: (data) => {
            if (data && data.rentItems && data.rentItems.city) {
                return data.rentItems.city;
            } else {
                return '-';
            }
        }
    },
    {
        field: 'rentItems.location',
        name: 'rentItems.location',
        header: 'Location',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '20vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.rentItems && data.rentItems.location) {
                return data.rentItems.location;
            } else {
                return '-';
            }
        }
    },
    {
        field: 'rentItems.size',
        name: 'rentItems.size',
        header: 'Size',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '5vw',
        sortable: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.rentItems && data.rentItems.size) {
                return (data.rentItems.size);
            } else {
                return '-';
            }
        },
    },
    {
        field: 'rentItems.squareFeet',
        name: 'rentItems.squareFeet',
        header: 'Sq.Ft',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '5vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'relational',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.rentItems && data.rentItems.squareFeet) {
                return (data.rentItems.squareFeet);
            } else {
                return '-';
            }
        },
    },
    {
        field: 'rentItems.lightType',
        name: 'rentItems.lightType',
        header: 'Light',
        required: true,
        editable: false,
        width: '4.5vw',
        hidden: false,
        default: true,
        sortable: true,
        permanent: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: true,
        },
        displayFn: (data) => {
            if (data && data.rentItems && data.rentItems.lightType) {
                return HoardingLightEnum[data.rentItems.lightType];
            } else {
                return '-';
            }
        },
    },
    {
        field: 'campaign.displayName',
        name: 'campaign.displayName',
        header: 'Display Name',
        required: true,
        editable: false,
        width: '12vw',
        hidden: false,
        default: true,
        sortable: true,
        permanent: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
        // displayFn: (data) => {
        //     if (data && data.campaign && data.campaign.displayName) {
        //         return data.campaign.displayName;
        //     } else {
        //         return '-';
        //     }
        // },
    },
    // {
    //     field: 'rentItems.quantity',
    //     name: 'rentItems.quantity',
    //     header: 'Qty.',
    //     required: true,
    //     editable: false,
    //     // hidden: false,
    //     default: true,
    //     permanent: true,
    //     width: '5vw',
    //     sortable: true,
    //     type: EditableGridFieldTypes.TEXT,
    //     filter: {
    //         matchMode: 'equals',
    //         placeHolder: 'Search',
    //         custom: false
    //     },
    //     displayFn: (data) => {
    //         if (data.rentItems.quantity) {
    //             return new CurrencyHelperPipe().transform(data.rentItems.quantity);
    //         } else {
    //             return '-';
    //         }
    //     }
    // },
    // {
    //     field: 'rentItems.kioskType',
    //     name: 'rentItems.kioskType',
    //     header: 'Kiosk Type',
    //     required: true,
    //     editable: false,
    //     // hidden: false,
    //     default: true,
    //     permanent: true,
    //     width: '8vw',
    //     sortable: true,
    //     type: EditableGridFieldTypes.TEXT,
    //     filter: {
    //         matchMode: 'equals',
    //         placeHolder: 'Search',
    //         custom: true
    //     },
    //     displayFn: (data) => {
    //         if (data.rentItems.kioskType) {
    //             return KioskTypeEnum[data.rentItems.kioskType];
    //         } else {
    //             return '-';
    //         }
    //     }
    // },
    {
        field: 'rentItems.itemStartDate',  
        name: 'rentItems.itemStartDate',
        header: 'Item St. Date',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '8vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
            if (data && data.rentItems && data.rentItems.itemStartDate) {
                return DateUtil.dategridFormatter(new Date(data.rentItems.itemStartDate));
            } else {
                return '-';
            }
        }
    },
    {
        field: 'rentItems.itemEndDate',
        name: 'rentItems.itemEndDate',
        header: 'Item End Date',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '8vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
            if (data &&data.rentItems && data.rentItems.itemEndDate) {
                return DateUtil.dategridFormatter(new Date(data.rentItems.itemEndDate));
            } else {
                return '-';
            }
        }
    },
    // {
    //     field: 'billStartDate',  
    //     name: 'billStartDate',
    //     header: 'Bill St. Date',
    //     required: true,
    //     editable: false,
    //     hidden: false,
    //     permanent: false,
    //     width: '8vw',
    //     sortable: true,
    //     type: EditableGridFieldTypes.TEXT,
    //     displayFn: (data) => {
    //         if (data && data.billStartDate) {
    //             return DateUtil.dategridFormatter(new Date(data.billStartDate));
    //         } else {
    //             return '-';
    //         }
    //     }
    // },
    // {
    //     field: 'billEndDate',
    //     name: 'billEndDate',
    //     header: 'Bill End Date',
    //     required: true,
    //     editable: false,
    //     hidden: false,
    //     permanent: false,
    //     width: '8vw',
    //     sortable: true,
    //     type: EditableGridFieldTypes.TEXT,
    //     displayFn: (data) => {
    //         if (data && data.billEndDate) {
    //             return DateUtil.dategridFormatter(new Date(data.billEndDate));
    //         } else {
    //             return '-';
    //         }
    //     }
    // },
    {
        field: 'status',
        name: 'status',
        header: 'Status',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '12vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'is',
            placeHolder: 'Search',
            custom: true,
        },
        displayFn: (data) => {
            if (data && data.status) {
                return InvoiceStatus[data.status];
            } else {
                return '-';
            }
        }
    },
    {
        field: 'campaign.employee.fullName',
        name: 'campaign.employee.fullName',
        header: 'Employee',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '8vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
        displayFn: (data) => {
            if (data && data.campaign.employee) {
                return data.campaign.employee.fullName;
            } else {
                return '-';
            }
        }
    },
    // {
    //     field: 'days',
    //     name: 'days',
    //     header: 'Days',
    //     required: true,
    //     editable: false,
    //     hidden: false,
    //     default: false,
    //     permanent: false,
    //     width: '3.5vw',
    //     sortable: false,
    //     type: EditableGridFieldTypes.TEXT,
    //     displayFn: (data) => {
    //         if (data) {
    //             return new CurrencyHelperPipe().transform(new CalculationService().calculateDays(data.itemStartDate, data.itemEndDate));
    //         } else {
    //             return '-';
    //         }
    //     },
    //     align: Alignment.RIGHT
    // },
    
  {
    field: 'rentItems.rate',
    name: 'rentItems.rate',
    header: 'Monthly Rate',
    required: true,
    editable: false,
    hidden: false,
    default: true,
    permanent: false,
    width: '8vw',
    sortable: true,
    type: EditableGridFieldTypes.NUMBER,
    filter: {
      matchMode: 'relational',
      placeHolder: 'Search',
      custom: false
    },
    displayFn: (data) => {
      if (data && data.rentItems && data.rentItems.rate) {
        return new CurrencyHelperPipe().transform(utils.parseValueToDecimalTwoPlaces(data.rentItems.rate));
      } else {
        return 0;
      }
    },
  },
  {
    field: 'rentItems.cost',
    name: 'rentItems.cost',
    header: 'Cost',
    required: true,
    editable: false,
    hidden: false,
    width: '7vw',
    permanent: false,
    default: true,
    sortable: true,
    type: EditableGridFieldTypes.NUMBER,
    filter: {
      matchMode: 'relational',
      placeHolder: 'Search',
      custom: false
    },
    displayFn: (data) => {
      if (data && data.rentItems && data.rentItems.cost) {
        return new CurrencyHelperPipe().transform(utils.parseValueToDecimalTwoPlaces(data.rentItems.cost));
      } else {
        return 0;
      }
    },
  },
//   {
//     field: 'rentItems.printPrice',
//     name: 'rentItems.printPrice',
//     header: 'Printing Price/SqFt',
//     required: true,
//     editable: false,
//     hidden: false,
//     default: true,
//     permanent: false,
//     width: '6.5vw',
//     sortable: true,
//     type: EditableGridFieldTypes.NUMBER,
//     // filter: {  // need to set the field first - rnd
//     //   matchMode: 'equals',
//     //   placeHolder: 'Search',
//     //   custom: false
//     // },
//     displayFn: (data) => {
//       if (data && data.rentItems && data.rentItems.printPrice) {
//         return new CurrencyHelperPipe().transform(utils.parseValueToDecimalTwoPlaces(data.rentItems.printPrice));
//       } else {
//         return 0;
//       }
//     }
//   },
//   {
//     field: 'rentItems.mountPrice',
//     name: 'rentItems.mountingPrice',
//     header: 'Mounting Price/SqFt',
//     required: true,
//     editable: false,
//     hidden: false,
//     default: true,
//     permanent: false,
//     width: '7vw',
//     sortable: true,
//     type: EditableGridFieldTypes.NUMBER,
//     // filter: {  // need to set the field first - rnd
//     //   matchMode: 'equals',
//     //   placeHolder: 'Search',
//     //   custom: false
//     // },
//     displayFn: (data) => {
//       if (data && data.rentItems && data.rentItems.mountPrice) {
//         return new CurrencyHelperPipe().transform(utils.parseValueToDecimalTwoPlaces(data.rentItems.mountPrice));
//       } else {
//         return 0;
//       }
//     }
//   },
];

export const SalesMap = (() => {
    return mapUtils.createMap(SalesChartHoardingColumns);
})();
