import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { EditableGridFieldTypes, GridColumn, GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { GridNameEnum } from '../../constants/grid-name.enum';
import * as utils from '../../../helpers/utils';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { FormGroup, FormControl } from '@angular/forms';
import { SiteExpirationTask } from '../../../modals/tasks/site-expiration-task';
import { HoardingStatusService } from '../../../hoardings-operation/services/hoardings-status.service';
import { AppUrls } from '../../../services/urls';
import { map, catchError } from 'rxjs/operators';
import { DateUtil } from '../../../helpers/date.util';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { CalculationService } from '../../../services/shared/calculationService';
import { Observable, of as observableOf } from 'rxjs';


@Component({
  selector: 'sib-site-expiration-tasks',
  templateUrl: './site-expiration-tasks.component.html',
  styleUrls: ['./site-expiration-tasks.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteExpirationTasksComponent implements OnInit {

  // isLoading = false;
  @ViewChild(GridComponent) gridViewChildRef: GridComponent<SiteExpirationTask>;
  gridConfig: GridConfig<SiteExpirationTask> = new GridConfig<SiteExpirationTask>();
  hiddenGridConfig: GridConfig<SiteExpirationTask> = new GridConfig<SiteExpirationTask>();

  _showEmployee: boolean;
  @Input() set showEmployee(showEmployee) {
    this._showEmployee = showEmployee;
    this.setColumns();
  }

  get showEmployee() {
    return this._showEmployee;
  }

  @Output() eEmitTotalPendingExpirationSites: EventEmitter<any> = new EventEmitter();

  expiringSites: SiteExpirationTask[] = [];
  totalRecords: number;
  tasksGroup: FormGroup;
  rowData: SiteExpirationTask;
  displayExtendDateDialog: boolean = false;
  days: string;
  showHiddenItems: boolean = false;
  paginationEvent: GridPaginationEvent;

  hideSites: boolean = false;



  taskColumns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr',
      required: true,
      width: '2vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },
    {
      field: 'customId',
      name: 'customId',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      width: '5vw',
      default: true,
      styleClass: 'word-break-wrap',
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.customId) {
          return (data.customId);
        }
      },
    },
    {
      field: 'district',
      name: 'district',
      header: 'District',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: false,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.district) {
          return (data.district);
        }
      },
    },
    {
      field: 'city',
      name: 'city',
      header: 'City',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.city) {
          return (data.city);
        }
      },
    },
    {
      field: 'areaName',
      name: 'areaName',
      header: 'Area',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      sortable: true,
      permanent: false,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.areaName) {
          return (data.areaName);
        }
      },
    },
    {
      field: 'location',
      name: 'location',
      header: 'Location',
      required: true,
      editable: false,
      width: '12vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.location) {
          return (data.location);
        }
      },
    },
    {
      field: 'size',
      name: 'size',
      header: 'Size',
      required: true,
      editable: false,
      width: '4vw',
      hidden: false,
      sortable: true,
      permanent: true,
      default: false,
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.size) {
          return (data.size);
        }
      },
    },

    // {
    //   field: 'companyName',
    //   name: 'companyName',
    //   header: 'Company Name',
    //   required: true,
    //   editable: false,
    //   width: '7vw',
    //   hidden: false,
    //   default: false,
    //   sortable: true,
    //   permanent: false,
    //   type: EditableGridFieldTypes.TEXT,
    //   filter: {
    //     matchMode: 'contains',
    //     placeHolder: 'Search',
    //     custom: false
    //   },
    //   displayFn: (data) => {
    //     if (data.companyName) {
    //       return (data.companyName);
    //     } else {
    //       return '-';
    //     }
    //   },
    // },


    {
      field: 'displayName',
      name: 'displayName',
      header: 'Display Name',
      required: true,
      editable: false,
      width: '7vw',
      hidden: false,
      default: false,
      sortable: true,
      permanent: true,
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
    },
    {
      field: 'employeeName',
      name: 'employeeName',
      header: 'Employee',
      required: true,
      width: '7vw',
      editable: false,
      default: false,
      // hidden: !this.showEmployee,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data && data.employeeName) {
          return data.employeeName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'itemStartDate',
      name: 'itemStartDate',
      header: 'Site Start Date',
      required: true,
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      width: '5vw',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemStartDate) {
          return DateUtil.dategridFormatter(data.itemStartDate);
        }
      }
    },
    {
      field: 'itemEndDate',
      name: 'itemEndDate',
      header: 'Site End Date',
      required: true,
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      width: '5vw',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemEndDate) {
          return DateUtil.dategridFormatter(data.itemEndDate);
        }
      }
    },

    {
      field: 'days',
      name: 'days',
      header: 'Expiring In Days',
      required: true,
      editable: false,
      default: false,
      hidden: false,
      permanent: true,
      sortable: true,
      width: '5vw',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        // if (data.days) {
        return data.days;
        // }
      }
    },

    {
      field: 'extendDays',
      name: 'extendDays',
      header: '',
      permanent: true,
      sortable: false,
      hidden: false,
      required: true,
      width: '5vw',
      editable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },

    {
      field: 'hiddenItem',
      name: 'hiddenItem',
      header: '',
      permanent: true,
      sortable: false,
      hidden: false,
      required: true,
      width: '3vw',
      editable: false,
      type: EditableGridFieldTypes.CUSTOM,
    }
  ]


  constructor(
    private userGridConfigService: UserGridConfigService,
    private campaignItemsService: HoardingStatusService,
    private notificationService: NotificatoinsService,
    private calculationService: CalculationService,

  ) { }

  ngOnInit() {
    this.getUserGridConfig();
    this.setEmployeeColumnVisibility(); // sets the visibility of the employee column
    this.setFormGroupObjects();
    this.setGridConfigObject();
    // this.refreshGrid();
    // this.getExpiringSites();

  }

  /**
   * gets user grid config
   *
   * @memberof SiteExpirationTasksComponent
   */
  getUserGridConfig() {
    this.userGridConfigService.get(null, '/' + GridNameEnum['SITES_EXPIRATION'])
      .subscribe((tasksGridConfig) => {
        this.taskColumns = utils.getUserConfiguredColumns(tasksGridConfig, this.taskColumns);
        this.gridViewChildRef.setAllColumns();
      });
  }

  /**
   * sets form group object
   *
   * @memberof SiteExpirationTasksComponent
   */
  setFormGroupObjects() {
    this.tasksGroup = new FormGroup({
      tasksControl: new FormControl()
    });
  }

  /**
   * Sets grid configuration object
   *
   * @memberof SiteExpirationTasksComponent
   */
  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new SiteExpirationTask();
    this.gridConfig.model = SiteExpirationTask;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;

    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
      // this.paginationEvent = paginationEvent
      const paginationData = {
        sortField: 'days',
        sortOrder: 1
      }
      if (!paginationEvent.sortField || (paginationEvent.sortField && paginationEvent.sortField === 'updatedDate')) {
        paginationEvent = Object.assign(paginationEvent, paginationData)
        var filter = { days: { value: [0, 30], matchMode: "between" } }
        Object.assign(paginationEvent.filters, filter);


      }
      var hiddenFilter = { hidden: { value: this.showHiddenItems ? true : false, matchMode: "is" } }
      Object.assign(paginationEvent.filters, hiddenFilter);

      return this.campaignItemsService.create(paginationEvent, paginationEvent, AppUrls.SEARCH + AppUrls.EXPIRED_IN).pipe(
        map((response) => {

          this.expiringSites = response['data']['content']
          this.totalRecords = response['data'].totalElements;
          this.eEmitTotalPendingExpirationSites.emit(this.totalRecords);
          return response['data']['content'];
        }), catchError((error) => {
          this.showErrorNotification(error);
          return Observable.empty();
        }));
      // }



    };
  }


  setHiddenGridConfig() {
    this.hiddenGridConfig.getDefaultModel = () => new SiteExpirationTask();
    this.hiddenGridConfig.model = SiteExpirationTask;
    this.hiddenGridConfig.selectionMode = SelectionMode.Multi;
    this.hiddenGridConfig.editable = true;
    this.hiddenGridConfig.expandableRows = false;
    this.hiddenGridConfig.checkboxSelection = false;
    this.hiddenGridConfig.resizableColumns = false;
    this.hiddenGridConfig.shouldAddRowOnDelete = false;
    this.hiddenGridConfig.lazy = true;
    this.hiddenGridConfig.enablePagination = true;
    this.hiddenGridConfig.scrollable = true;

    this.hiddenGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
      // this.paginationEvent = paginationEvent
      const paginationData = {
        sortField: 'days',
        sortOrder: 1
      }
      if (!paginationEvent.sortField || (paginationEvent.sortField && paginationEvent.sortField === 'updatedDate')) {
        paginationEvent = Object.assign(paginationEvent, paginationData)
        var filter = { days: { value: [0, 30], matchMode: "between" } }
        Object.assign(paginationEvent.filters, filter);


      }
      var hiddenFilter = { hidden: { value: this.showHiddenItems ? true : false, matchMode: "is" } }
      Object.assign(paginationEvent.filters, hiddenFilter);


      return this.campaignItemsService.create(paginationEvent, paginationEvent, AppUrls.SEARCH + AppUrls.EXPIRED_IN).pipe(
        map((response) => {
          this.expiringSites = response['data']['content']
          this.totalRecords = response['data'].totalElements;
          this.eEmitTotalPendingExpirationSites.emit(this.totalRecords);
          return response['data']['content'];
        }), catchError((error) => {
          this.showErrorNotification(error);
          return Observable.empty();
        }));



    };
  }


  /**
   * get expiring sites
   *
   * @memberof SiteExpirationTasksComponent
   */
  getExpiringSites() {
    const paginationData = {
      sortField: 'days',
      sortOrder: 1
    }
    if (!this.paginationEvent.sortField || (this.paginationEvent.sortField && this.paginationEvent.sortField === 'updatedDate')) {
      this.paginationEvent = Object.assign(this.paginationEvent, paginationData)
      var filter = { days: { value: [0, 30], matchMode: "between" } }
      Object.assign(this.paginationEvent.filters, filter);
    }

    var hiddenFilter = { hidden: { value: this.showHiddenItems ? true : false, matchMode: "is" } }
    Object.assign(this.paginationEvent.filters, hiddenFilter);
    this.campaignItemsService.create(this.paginationEvent, this.paginationEvent, AppUrls.SEARCH + AppUrls.EXPIRED_IN).subscribe((response) => {
      this.expiringSites = response['data']['content']
      this.totalRecords = response['data'].totalElements;
      this.eEmitTotalPendingExpirationSites.emit(this.totalRecords);
      this.gridViewChildRef.refresh();
    },
      (error) => {
        this.showErrorNotification(error);
      }
    )

  }

  /**
   * Updates the user grid configuration
   *
   * @param {*} columnArray
   * @memberof SiteExpirationTasksComponent
   */
  updatingUserGridConfig(columnArray) {
    var userGridConfigService: Map<string, string> = new Map();
    userGridConfigService = utils.getGridConfigMap(columnArray, this.taskColumns);
    this.userGridConfigService.patch(utils.buildObject(userGridConfigService), null, GridNameEnum['SITES_EXPIRATION'])
      .subscribe((result) => {
      })
  }

  /**
   * Refreshes the grid
   *
   * @memberof SiteExpirationTasksComponent
   */
  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }

  /** 
   * Displays Extend Date Dialog
   *
   * @param {*} rowData
   * @memberof SiteExpirationTasksComponent
   */
  openExtendDateDialog(rowData) {
    this.days = '';
    this.rowData = rowData;
    this.displayExtendDateDialog = true;

  }


  /** 
   * Hides Extend Date Dialog
   *
   * @memberof SiteExpirationTasksComponent
   */
  hideExtendDateDialog() {
    this.displayExtendDateDialog = false;
  }


  /**
   * extend days of a site in a campaign
   *
   * @memberof SiteExpirationTasksComponent
   */
  extendDays() {

    this.displayExtendDateDialog = false;

    this.rowData.itemEndDate = new Date(this.calculationService.extendDate(this.rowData.itemEndDate, Number(this.days)));
    const requestBody = {
      id: this.rowData._id,
      itemStartDate: new Date(this.rowData.itemStartDate),
      itemEndDate: new Date(this.rowData.itemEndDate),
      campaignId: this.rowData.campaignId
    }
    this.campaignItemsService.create(requestBody, null, AppUrls.UPDATE).subscribe(
      (response) => {
        if (response) {
          this.notificationService.success("Site Updated", "Site Updated sucessfully");
          this.refreshGrid();
        }
      },
      (error) => {
        this.notificationService.error(error.error.message ? error.error.message : 'Some technical issue', 'Please Refresh');
        this.refreshGrid();
      }

    )
  }



  /**
   * Shows error notification in grid
   *
   * @param {*} error
   * @memberof SiteExpirationTasksComponent
   */
  showErrorNotification(error) {
    this.notificationService.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
  }


  /**
   * checks if 0 is present in the input field
   *
   * @returns
   * @memberof SiteExpirationTasksComponent
   */
  validate() {
    if (Number(this.days)) { //if a number other than 0
      return false;
    } else {                //if a number is 0 or field is empty
      return true;
    }
  }

  checkPermission(permission) {
    return utils.checkPermission(permission);
  }

  setEmployeeColumnVisibility() {
    if (this.checkPermission('readAll') || this.checkPermission('view:employee-column')) { // should be visible if the user has readAll or view:employee-column permissions
      this.showEmployee = true;
    } else { // else employee column should not be visible.
      this.showEmployee = false;
    }
  }

  setColumns() {
    this.taskColumns.filter((col) => {
      switch (col.field) {
        case 'employeeName': {
          col.permanent = !this.showEmployee;
          col.hidden = !this.showEmployee;
          break;
        }
        case 'hiddenItem': {
          col.hidden = this.showHiddenItems;
        }
      }
      return col;
    });
    if (this.gridViewChildRef) {
      this.gridViewChildRef.setAllColumns();
    }
  }



  /**
   * hides item from the list
   *
   * @param {*} rowData
   * @memberof SiteExpirationTasksComponent
   */
  hideItem(rowData) {

    this.campaignItemsService.get(null, AppUrls.MARK_HIDDEN + AppUrls.SLASH + rowData._id).subscribe(() => {

      this.refreshGrid();
      this.notificationService.success("Item Hidden Successfully", "Item Hidden");
    },
      (error) => {
        this.showErrorNotification(error);
      }
    )
  }


  onToggle(event) {
    this.showHiddenItems = event.checked;
    this.getUserGridConfig();
    if (this.showHiddenItems) {
      this.setHiddenGridConfig();
    }
    this.setColumns();
    this.refreshGrid();
  }

  ngOnDestroy() { }
}




