import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { CreditNote } from '../../modals/billings/credit-note';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import * as utils from '../../helpers/utils';
import { CampaignService } from '../../services/shared/campaign.service';
import { CalculationService } from '../../services/shared/calculationService';
import { TaxConfigurationService } from '../../services/shared/tax-configuration.service';
import { Subscription } from 'rxjs';
import { Campaign } from '../../modals/campaigns/campaign';
import { CampaignItem } from '../../modals/campaigns/campaignItem';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';

@Component({
    selector: 'sib-cn-summary',
    templateUrl: './cn-summary.component.html',
    styleUrls: ['./cn-summary.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class CNSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

    _creditNote: CreditNote = new CreditNote();
    @Input() set creditNote(creditNote) {
        this._creditNote = creditNote;
        this._creditNote = JSON.parse(JSON.stringify(this._creditNote));
    }
    get creditNote() {
        return this._creditNote;
    }


    isLoading = false;
    subscription: Subscription[] = [];
    campaign: Campaign = new Campaign();
    campaignItems: CampaignItem[] = [];
    campaignDifference = 0;
    campaignDifferencePctg = 0;
    lspDifference = 0;
    lspDifferencePctg = 0;
    rentAmount = 0;
    printAmount = 0;
    mountAmount = 0;
    minAmount = 0;
    totalWithoutTax = 0;
    igstTax = 0;
    cgstTax = 0;
    sgstTax = 0;
    totalWithTax = 0;

    igstParameter: number;
    gstParameter: number;
    cgstParameter: number;
    sgstParameter: number;

    isIgst = false;

    constructor(
        private campaignService: CampaignService,
        private calculationService: CalculationService,
        private taxConfigurationService: TaxConfigurationService,
        private currencyPipe: CurrencyHelperPipe,
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.getTaxConfiguration();
    }

    ngAfterViewInit() { }

    getTaxConfiguration() {
        this.taxConfigurationService.getTaxConfiguration();
        this.subscription.push(
            this.taxConfigurationService.gst
                .subscribe((gst) => {
                    this.gstParameter = gst;
                }));

        this.subscription.push(
            this.taxConfigurationService.cgst
                .subscribe((cgst) => {
                    this.cgstParameter = cgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.sgst
                .subscribe((sgst) => {
                    this.sgstParameter = sgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.igst
                .subscribe((igst) => {
                    this.igstParameter = igst;
                    this.getCampaignItems();
                }));
    }

    getCampaignItems() {
        this.subscription.push(this.campaignService.getHoardings(this.creditNote.campaign.id).subscribe((response: any) => {
            this.campaignItems = response;
            this.calculateCNSummary();
            this.isLoading = false;
        }));
    }

    calculateCNSummary() {
        let rentDifference = 0;
        let printDifference = 0;
        let mountDifference = 0;
        // setting to 0 because tax is subscribed 2 times - need to resolve
        this.rentAmount = 0;
        this.printAmount = 0;
        this.mountAmount = 0;
        this.minAmount = 0;
        const compareObject = this.createMountingItemObjectToCompare();
        this.campaignItems.forEach((ci) => {
            this.creditNote.rentItems.forEach((item) => {
                if (item.customId === ci.customId) {
                    const days = this.calculationService.calculateDays(item.itemStartDate, item.itemEndDate);
                    const cost = Number(this.calculationService.calculateCostOfHoarding(item.rate, days));

                    const minCost = this.calculationService.calculateCostOfHoarding(ci.minimumPrice, days);
                    const difference = item.rate - ci.rate;
                    rentDifference += this.calculationService.calculateCostOfHoarding(difference, days);
                    this.rentAmount += cost;
                    this.minAmount += minCost;
                }
            });
        });
        compareObject.forEach((obj) => {
            this.creditNote.printingItems.forEach((item) => {
                if (item.customId === obj.customId && new Date(this.calculationService.setToBeginning(item.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(obj.serviceDate)).valueOf() && item.creative === obj.creative) {
                    const difference = Number(item.amount - obj.printCharge);
                    printDifference += Number(difference);
                    this.printAmount += Number(this.getDecimalValue(Number(item.amount), 2));
                }
            });
            this.creditNote.mountingItems.forEach((item) => {
                if (item.customId === obj.customId && new Date(this.calculationService.setToBeginning(item.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(obj.serviceDate)).valueOf() && item.creative === obj.creative) {
                    const difference = Number(item.amount - obj.mountCharge);
                    mountDifference += Number(difference);
                    this.mountAmount += Number(this.getDecimalValue(Number(item.amount), 2));
                }
            });
        });
        this.lspDifference = this.calculationService.calculateLSPDifference(this.rentAmount, this.minAmount);
        if (this.minAmount !== 0) {
            this.lspDifferencePctg = this.calculationService.calculateLSPDifferencePercentage(this.rentAmount, this.minAmount);
        } else {
            this.lspDifferencePctg = 0;
        }
        this.totalWithoutTax = Number(this.getDecimalValue(Number(this.rentAmount + this.printAmount + this.mountAmount), 2));
        this.campaignDifference = Number(this.getIntegerRoundedOffValue(this.getDecimalValue(Number(rentDifference + printDifference + mountDifference), 2)));
        if (this.totalWithoutTax !== 0) {
            this.campaignDifferencePctg = this.calculationService.calculatePctg(this.campaignDifference, this.totalWithoutTax);
        } else {
            this.campaignDifferencePctg = 0;
        }
        this.igstTax = Number(this.calculateTax(this.totalWithoutTax, this.igstParameter));
        this.cgstTax = Number(this.calculateTax(this.totalWithoutTax, this.cgstParameter));
        this.sgstTax = Number(this.calculateTax(this.totalWithoutTax, this.sgstParameter));
        this.totalWithTax = Number(this.getIntegerRoundedOffValue(this.totalWithoutTax + this.cgstTax + this.sgstTax));
        this.isIgst = false;
        // if (this.creditNote.invoiceGstDetail.branchAddress.state.name && (this.creditNote.invoiceGstDetail.branchAddress.state.name !== 'Gujarat' && this.creditNote.invoiceGstDetail.branchAddress.state.name !== '')) {
        //     this.totalWithTax = this.totalWithoutTax + this.igstTax;
        // }
        if (this.creditNote) {
            if (this.creditNote.invoiceGstDetail && this.creditNote.invoiceGstDetail.gstNo) {
                if (this.creditNote.supplier && this.creditNote.supplier.supplierGstDetail && this.creditNote.supplier.supplierGstDetail.gstNo) {
                    if (this.creditNote.invoiceGstDetail.branchAddress && this.creditNote.invoiceGstDetail.branchAddress.state && this.creditNote.invoiceGstDetail.branchAddress.state.name && this.creditNote.supplier.supplierGstDetail.branchAddress && this.creditNote.supplier.supplierGstDetail.branchAddress.state && this.creditNote.supplier.supplierGstDetail.branchAddress.state.name && this.creditNote.invoiceGstDetail.branchAddress.state.name !== this.creditNote.supplier.supplierGstDetail.branchAddress.state.name) {
                        this.totalWithTax = this.totalWithoutTax + this.igstTax;
                        this.isIgst = true;
                    }
                } else {
                    if (this.creditNote.supplier && this.creditNote.supplier.address && this.creditNote.supplier.address.state && this.creditNote.supplier.address.state.name) {
                        if (this.creditNote.invoiceGstDetail.branchAddress && this.creditNote.invoiceGstDetail.branchAddress.state && this.creditNote.invoiceGstDetail.branchAddress.state.name && this.creditNote.invoiceGstDetail.branchAddress.state.name !== this.creditNote.supplier.address.state.name) {
                            this.totalWithTax = this.totalWithoutTax + this.igstTax;
                            this.isIgst = true;
                        }
                    }
                }
            } else {
                if (this.creditNote && this.creditNote.campaign && this.creditNote.campaign.customer && this.creditNote.campaign.customer.state && this.creditNote.campaign.customer.state.name) {
                    if (this.creditNote.supplier && this.creditNote.supplier.supplierGstDetail && this.creditNote.supplier.supplierGstDetail.gstNo) {
                        if (this.creditNote.supplier.supplierGstDetail.branchAddress && this.creditNote.supplier.supplierGstDetail.branchAddress.state && this.creditNote.supplier.supplierGstDetail.branchAddress.state.name && this.creditNote.campaign.customer.state.name !== this.creditNote.supplier.supplierGstDetail.branchAddress.state.name) {
                            this.totalWithTax = this.totalWithoutTax + this.igstTax;
                            this.isIgst = true;
                        }
                    } else {
                        if (this.creditNote.supplier && this.creditNote.supplier.address && this.creditNote.supplier.address.state && this.creditNote.supplier.address.state.name) {
                            if (this.creditNote.campaign.customer.state.name !== this.creditNote.supplier.address.state.name) {
                                this.totalWithTax = this.totalWithoutTax + this.igstTax;
                                this.isIgst = true;
                            }
                        }
                    }
                }
            }
        }
    }

    createMountingItemObjectToCompare() {
        const obj: {
            customId: string,
            creative: string,
            serviceDate: Date,
            printCharge: number,
            mountCharge: number
        }[] = [];
        this.campaignItems.forEach((ci) => {
            ci.mountingItems.forEach((mi) => {
                obj.push({ customId: ci.customId, creative: mi.creativeName, serviceDate: new Date(mi.date), printCharge: (HoardingLightEnum[ci.lightType] === HoardingLightEnum.F_L) || (HoardingLightEnum[ci.lightType] === HoardingLightEnum.N_L) ? (mi.creativeName === "Default") ? ci.printingPrice.flNlPrice * ci.squareFeet : mi.printingPrice.flNlPrice * ci.squareFeet : (HoardingLightEnum[ci.lightType] === HoardingLightEnum.B_L) ? (mi.creativeName === "Default") ? ci.printingPrice.blPrice * ci.squareFeet : mi.printingPrice.blPrice * ci.squareFeet : 0, mountCharge: (mi.creativeName === "Default") ? ci.mountingPrice.pricePerSqFt * ci.squareFeet : mi.mountingPrice.pricePerSqFt * ci.squareFeet });
            });
        });
        return obj;
    }

    calculateTax(value, taxValue) {
        return Number(Math.fround(value * taxValue / 100).toFixed(2));
    }

    getDecimalValue(value, fractionPlaces) {
        return Number(Math.fround(value).toFixed(fractionPlaces));
    }

    parseValueToDecimalTwoPlaces(value) {
        return JSON.parse(JSON.stringify(utils.parseValueToDecimalTwoPlaces(value)));
    }

    getIntegerRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    getCurrencyFormat(value) {
        return this.currencyPipe.transform(value);
    }

    getAbsoluteValue(value) {
        return Number(Math.abs(Number(value)));
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

}
