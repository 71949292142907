import { NgModule, ModuleWithProviders } from "@angular/core";
import { AppConfig } from "./app-config.service";
import { environment } from "../../environments/environment";


@NgModule({

})

export class ServiceModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ServiceModule,
            providers: [
                { provide: AppConfig, useFactory: configFactory },
            ]
        }
    }
}

// Using factory for proving dependency
export function configFactory() {
    return new AppConfig(environment);
}