/* 
* @file Upload image service.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/

import { Injectable, Injector } from "@angular/core";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class UploadImageService extends ApiService<any, any, any> {
    urlPath: string = 'campaignItems/upload-image/monitoring-item';

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }
}
