import { Injectable, Injector } from "../../../../node_modules/@angular/core";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "../../../../node_modules/@angular/common/http";
import { NotificatoinsService } from "../notifications/notifications.service";

@Injectable()
export class ActivityLoogService extends ApiService<any, any, any> {
    // urlPath: string = 'plans?projection=planProjection';
    urlPath: string = 'activityLogs/search';
    baseUrl: string;


    constructor(private Http: HttpClient,
        private Injector: Injector,
        private notificationServcie: NotificatoinsService, ) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }
}
