import { Http, Response, Headers } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient } from "@angular/common/http";
import { AuthAPIService } from "../base-service/auth.service";



@Injectable()
export class AuthUserAPIService extends AuthAPIService<any, any, any> {
    urlPath: string = 'users';


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
    }

}