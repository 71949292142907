/**
* @file area-dialog.component
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/



import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from "@angular/router";

@Component({
    selector: 'sib-back',
    templateUrl: './sib-back.component.html',
    styleUrls: ['./sib-back.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SibBackComponent implements OnInit {

    @Input() showBack = true;
    @Input() isHistory: boolean = true;
    @Output() eEmitIsHistory: EventEmitter<boolean> = new EventEmitter();
    constructor() { }

    ngOnInit() { }

    goBack() {
        if (this.isHistory) {
            window.history.back();
        } else {
            this.eEmitIsHistory.emit(false);
        }

    }

}
