import { SystemService } from "./system.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class CampaignConfigurationService {
    lsp: Subject<number> = new Subject();
    blockLimit: Subject<number> = new Subject();
    blockPeriod: Subject<number> = new Subject();

    constructor(
        private systemService: SystemService
    ) { }

    getCampaignConfiguration() {
        this.systemService.get({ module: "CAMPAIGN" }, '/search/by-module')
            .subscribe((configuration) => {
                const configurations = configuration;
                // ['_embedded']['systemProperties']
                console.log("campaign configuration", configurations)
            })
    }


    setLsp(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'LSP') {
                this.lsp.next(configurations[i]['value']);
            }
        };
    }

    setBlockLimit(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'BLOCK_LIMIT') {
                this.blockLimit.next(configurations[i]['value']);
            }
        };
    }

    setBlockPeriod(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'BLOCK_PERIOD') {
                this.blockPeriod.next(configurations[i]['value']);
            }
        };
    }
}