export class ListBox {
    label:string;
    value: any;

    public setLabel(label: string){
        this.label = label;
    }

    public setValue(value: any){
        this.value = value;
    }
}