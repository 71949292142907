import { SMTPConfiguration } from "./smtpConfiguration";
import { EmailProviderEnum } from "../../shared/constants/email-provider-enum";



export class EmailConfig {
    mailProvider: EmailProviderEnum;
    username: string;
    password: string;
}
