import { Injectable } from "@angular/core";
import { GoogleChartsBaseService } from "./chart.base.service";
import { TimelineChartConfig } from "../../modals/charts/timelineChart.config";



declare var google: any;
@Injectable()
export class GoogleTimelineChartService extends GoogleChartsBaseService {

  constructor() { super(); }

  public buildTimelineChart(elementId: String, data: any[], config: TimelineChartConfig): void {
    const chartFunc = () => new google.visualization.Timeline(document.getElementById(elementId + ''));
    /* const options = {
    
        height: config.height,
        width: config.width,
        timeline: config.timeline
    }; */
    this.buildChart(data, chartFunc, config);
  }
}