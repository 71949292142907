
import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { ContractHoardings } from '../../modals/contracts/contract-hoarding';
import { GridColumn, EditableGridFieldTypes, Alignment, GridConfig, SelectionMode, GridPaginationEvent, GridActionButtonTypes } from '../../components/sib-forms/grid/grid.config';
import { FormGroup, FormControl } from '@angular/forms';
import { Contracts } from '../../modals/contracts/contracts';
import { Observable, Subscription } from 'rxjs';
import { ContractService } from '../services/contract.services';
import { AreaQueryParams } from '../../modals/queryparams/area-queryparams';
import { Area } from '../../modals/Area/area';
import { ActivatedRoute, Router } from '@angular/router';
import { GridActionButtonConfig } from '../../components/sib-forms/grid/grid-action-button.config';
import { NodeService } from '../../demo/service/nodeservice';
import { ContractsActionDispatcher } from '../action-dispatcher/action-dispatcher';
import { HoardingGroup } from '../../modals/hoardings/hoardings-group';
import { ActionDispatcher } from '../../app state/action-dispatcher/action-dispatcher';
import { AreaListGetService } from '../../services/shared/areaListGet.service';
import { CreateHoardingService } from '../../services/shared/createHoarding.service';
import { AreaListService } from '../../services/shared/areaList.service';
import { HoardingListComponent } from '../../shared/components/hoarding-list/hoarding-list.component';
import { ListHoardingsComponent } from '../../shared/components/list-hoardings/list-hoardings.component';
import { FirebaseService } from '../../services/shared/firebase.service';
import { NewContractComponent } from '../new-contract/new-contract.component';
import { ContractQueryParams } from '../../modals/queryparams/contract-queryparams';
import { HoardingService } from '../services/hoardings.service';
import { AppUrls } from '../../services/urls';
import { PhotoDialogComponent } from '../../shared/components/photo-dialog/photo-dialog.component';
import { HoardingDialogComponent } from '../../shared/components/hoarding-dialog/hoarding-dialog.component';
import { HoardingStatusEnum } from '../../shared/constants/hoarding-status-enum';
import * as _ from "lodash";
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { ImageDataDisplay } from '../../modals/image/image-data';
import { ContractStatusEnum } from '../../shared/constants/contract-status-enum';
import { ImportHoardingConfig } from '../../modals/import-hoarding/import-hoarding-config';
import { Kiosk } from '../../modals/hoardings/kiosk';
import { KioskService } from '../../services/shared/kiosk.service';
import { KiosksComponent } from '../../shared/components/kiosks/kiosks.component';
import { HoardingGroupsComponent } from '../../hoarding-groups/hoarding-groups.component';
import { SelectItem, MenuItem } from 'primeng/primeng';
import { HoardingMasterColService } from '../../services/shared/hoarding-master-column.service';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { ContractUiService } from '../../contracts/services/contract-ui.services';
import { Title } from '@angular/platform-browser';
import * as utils from '../../helpers/utils';
import { ImageFolderConfig } from '../../modals/image/image-folder-config';
import { UploadDocumentService } from '../../services/shared/upload-document.service';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import { NotificationsMessages } from '../../services/shared/notifications-messages';
import { ErrorUtil } from '../../helpers/error.utils';
import { SIBConstants } from '../../shared/constants/SIBConstant';

export type GridDataRequestEvent = any;

export type GridDataResponse<T> = any;

export class TreeNode {
  label?: string;
  data?: any;
  icon?: any;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: TreeNode[] = [];
  leaf?: boolean;
  expanded?: boolean;
  type?: string;
  parent?: TreeNode;
  partialSelected?: boolean;
  styleClass?: string;
  draggable?: boolean;
  droppable?: boolean;
  selectable?: boolean;
}

@Component({
  selector: 'sib-contract-hoarding',
  templateUrl: './contract-hoarding.component.html',
  styleUrls: ['./contract-hoarding.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContractHoardingComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() displayCreateHoarding: boolean = false;
  @Input() editMode: boolean = false;
  @Input() hoardingData: any;
  @Input() displayPhotoDialog: boolean = false;

  @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
  @Output() editReset: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<ContractHoardings>;

  @ViewChild(ListHoardingsComponent)
  hoardingListChildRef: ListHoardingsComponent;

  @ViewChild(KiosksComponent)
  kioskChildRef: KiosksComponent


  /**
   * Creating a object of childComponent to access its function
   * 
   * @type {NewContractComponent}
   * @memberof ContractsListComponent
   */
  @ViewChild(NewContractComponent)
  contractForm: NewContractComponent;

  @ViewChild(PhotoDialogComponent)
  photoDialogComponent: PhotoDialogComponent;

  @ViewChild(HoardingDialogComponent)
  hoardingDialogComponent: HoardingDialogComponent;

  @ViewChild(HoardingGroupsComponent)
  hoardingGroupChildRef: HoardingGroupsComponent;

  // contractHoardingGroup: FormGroup;
  contract: Contracts = new Contracts();
  hoardingGroup: HoardingGroup = new HoardingGroup();
  newHoarding: ContractHoardings = new ContractHoardings();
  displayHoardingSearch: boolean = false;
  display: boolean = false;
  displayClubHoarding: boolean = false;
  isGroupSaved: boolean = false;
  treeTableCols: any[] = [];
  hoardingGroups: HoardingGroup[] = [];
  treeData: TreeNode[] = [];
  hoardingListColumns: any[] = [];
  imageData: any;
  gridConfig: GridConfig<ContractHoardings> = new GridConfig<ContractHoardings>();
  displayContractForm: boolean = false;
  title: string;
  loading: boolean = false;
  contractId: any;
  numberOfHoardings: any;
  editHoardingMode = false;
  editHoardingData: ContractHoardings = new ContractHoardings();
  rowData: any;
  displayStatusQueueDialog = false; // status queue dialog
  statusStyle: any; //style object for contract status
  importHoardingConfig: ImportHoardingConfig = new ImportHoardingConfig();
  activeIndex: number = 0;
  kioskGridConfig: GridConfig<Kiosk> = new GridConfig<Kiosk>();
  kioskListColumns: any[] = [];
  mediaType: string;
  columns: SelectItem[] = [];
  selectedColumns: any[] = [];
  subscription: Subscription[] = [];
  totalHoardings: number;
  totalGroups: number;
  numberOfGroups: number;
  totalKiosks: number;
  numberOfKiosks: number;
  importTypes: MenuItem[] = [];
  displayImportFromCsv: boolean = false;
  csvFile: File;
  hoardingTemplateUrl: string;
  hoardingGroupColumns: any[] = [];
  selectedStatus: any[] = [];
  status: SelectItem[] = []; // status drop down
  createHoardingMode: boolean = false;
  displayUploadDocDialog: boolean = false;
  imageFolderConfig: ImageFolderConfig = new ImageFolderConfig();

  isFileDownloading = false;
  searchEvent: GridPaginationEvent;
  hoardingSearchEvent: GridPaginationEvent;
  hoaridngGroupSearchEvent: GridPaginationEvent;
  kioskSearchEvent: GridPaginationEvent;
  xlsLoader: PPTLoader = new PPTLoader();
  isExpired: boolean = false;
  expiredInventory: number = 0;
  hoardings: any[] = [];
  kiosks: any[] = [];

  displaySetupIncompleteDialog: boolean = false;
  importInformation: boolean = false;

  constructor(
    public actionDispatcher: ContractsActionDispatcher,
    private appActionDispatcher: ActionDispatcher,
    public contractService: ContractService,
    private _route: ActivatedRoute,
    private areaListGetService: AreaListGetService,
    private createHoardingService: CreateHoardingService,
    private router: Router,
    private areaListService: AreaListService,
    private firebaseService: FirebaseService,
    private element: ElementRef,
    private hoardingService: HoardingService,
    private cd: ChangeDetectorRef,
    private currencyHelperPipe: CurrencyHelperPipe,
    private kioskService: KioskService,
    private hmColService: HoardingMasterColService,
    private notificationServcie: NotificatoinsService,
    private contractUiService: ContractUiService,
    private pageTitle: Title,
    private uploadDocumentService: UploadDocumentService,
    private loaderSubjects: LoaderSubjects,
  ) {
    // this.actionDispatcher.resetContract();
  }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.CONTRACT_DETAILS) : this.pageTitle.setTitle(SIBConstants.CONTRACT_DETAILS + ' - ' + appTitle);
    this.setFilterDropdowns();
    this.hoardingListColumns = [
      'customId',
      // 'district',
      'city',
      'location',
      'size',
      'height',
      'width',
      'size',
      'startDate',
      'endDate',
      'minimumPrice',
      'grossPrice',
      'image',
      'statusQueues',
      'statusQueues.displayName',
      'statusQueues.employee',
      'areaName',
      'lightType',
      'id',
      'documents',
      'upload',
      'authorityTax'
    ];


    this.gridConfig.getDefaultModel = () => new ContractHoardings();
    this.gridConfig.model = ContractHoardings;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = true;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.rowExpandMode = 'single';
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showColorCode = true;
    // this.gridConfig.rowsPerPage = 10;    // remove for bug - 94240
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      var filter = { contractId: { value: this.contractId, matchMode: "contains" } }
      Object.assign(paginationEvent.filters, filter);
      if (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0)) {
        Object.assign(paginationEvent.filters, { 'status': { value: ['AVAILABLE', 'NOT_AVAILABLE', 'BLOCKED'], matchMode: 'in' } });
      }

      this.hoardingSearchEvent = paginationEvent;
      return this.hoardingService.create(paginationEvent, paginationEvent, '/search').pipe(
        map((response) => {
          this.loading = false; // added after hoarding tree is loaded
          this.hoardings = response['content'];
          this.totalHoardings = response.totalElements;
          this.numberOfHoardings = response.numberOfElements;
          this.getExpiredStatus();
          return response['content'];
        }));
    };

    this.hoardingGroupColumns = [
      'customId',
      'contract.id',
      'geoCoordinate.latitude',
      'geoCoordinate.longitude',
      'district',
      'city',
      'areaName',
      'location',
      'width',
      'height',
      'size',
      'squareFeet',
      'category',
      'lightType',
      'grossPrice',
      'minimumPrice',
      'startDate',
      'endDate',
      'campaignStartDate',
      'campaignEndDate',
      'statusQueues',
      'statusQueues.displayName',
      'statusQueues.employee',
      'image',
      'id'
    ];

    this.kioskListColumns = [
      'customId',
      // 'district',
      'city',
      'location',
      'startDate',
      'endDate',
      'width',
      'height',
      'size',
      'quantity',
      'kioskType',
      'minimumPrice',
      'grossPrice',
      'image',
      'statusQueues',
      'statusQueues.displayName',
      'statusQueues.employee',
      'areaName',
      'lightType',
      'note',
      'id',
      'documents',
      'upload',
      'authorityTax'
    ];

    this.loading = true;
    this.kioskGridConfig.getDefaultModel = () => new Kiosk();
    this.kioskGridConfig.model = Kiosk;
    this.kioskGridConfig.selectionMode = SelectionMode.None;
    this.kioskGridConfig.editable = true;
    this.kioskGridConfig.expandableRows = false;
    this.kioskGridConfig.checkboxSelection = true;
    this.kioskGridConfig.resizableColumns = false;
    this.kioskGridConfig.rowExpandMode = 'single';
    this.kioskGridConfig.shouldAddRowOnDelete = false;
    this.kioskGridConfig.lazy = true;
    this.kioskGridConfig.enablePagination = true;
    this.kioskGridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.kioskGridConfig.scrollable = true;
    // this.gridConfig.rowsPerPage = 10;    // remove for bug - 94240
    this.kioskGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      var filter = { contractId: { value: this.contractId, matchMode: "contains" } }
      Object.assign(paginationEvent.filters, filter);
      if (!paginationEvent.filters.status || (paginationEvent.filters.status && paginationEvent.filters.status.value.length === 0)) {
        Object.assign(paginationEvent.filters, { 'status': { value: ['AVAILABLE', 'NOT_AVAILABLE', 'BLOCKED'], matchMode: 'in' } });
      }
      this.kioskSearchEvent = paginationEvent;
      return this.kioskService.create(paginationEvent, paginationEvent, '/search').pipe(
        map((response) => {
          this.loading = false; // added after hoarding tree is loaded
          this.kiosks = response['content'];
          this.numberOfKiosks = response.numberOfElements;
          this.totalKiosks = response.totalElements;
          this.getExpiredStatus();
          return response['content'];
        }));
    };


    this.subscription.push(this.actionDispatcher.contractById.subscribe((contract) => {
      this.contract = contract;

      // this.cd.detectChanges();
      if (this.contractId) {
        this.actionDispatcher.getGroupsByContractId(this.contractId);
        this.contractExpiryCheck();
      }
    }));


    this.subscription.push(this._route.params.subscribe((params: { contractId: string }) => {
      this.contractId = params.contractId;
      if (!this.contractId) {
        this.router.navigate(['/contracts'])
      } else {
        this.changeHoardingGroupGridConfig();
        this.getUserColumnConfig();
        this.actionDispatcher.getContractById(this.contractId);
      }

    }));

    this.subscription.push(this.actionDispatcher.isHoardingsAddedToContract.subscribe((isHoardingsAddedToContract) => {
      if (isHoardingsAddedToContract) {
        this.displayHoardingSearch = false;
        this.hoardingListChildRef.refreshGrid();
        this.kioskChildRef.refreshGrid();
        this.actionDispatcher.resetHoardingAddedToContract();
      }
    }));

    this.subscription.push(this.actionDispatcher.getContractByIdFailedFlag.subscribe((contractByIdFailedFlag) => {
      if (contractByIdFailedFlag === true) {
        this.loading = false;
        this.router.navigate(['/contracts'])
      }
    }));

    this.subscription.push(this.actionDispatcher.hoardingGroups.subscribe((hoardingGroups) => {
      this.hoardingGroups = hoardingGroups;
      /* if(hoardingGroups.length > 0) {
        this.cd.detectChanges();
      } */
      if (this.hoardingGroups.length > 0) {
        this.hoardingGroups.forEach((hoardingGroup, i) => {
          this.treeData[i] = new TreeNode();
          this.treeData[i].data = hoardingGroup;
          if (hoardingGroup.hoardings && hoardingGroup.hoardings.length > 0) {
            this.treeData[i].children = [];
            hoardingGroup.hoardings.forEach((hoarding, index) => {
              this.treeData[i].children[index] = new TreeNode();
              this.treeData[i].children[index].data = hoarding;
            });
            // this.loading = false; 
          }
        });
      }
    }));

    this.treeTableCols = [
      { field: 'id', header: 'GID' },
      { field: 'location', header: 'Locations' },
      { field: 'width', header: 'Size' },
      { field: 'minimumPrice', header: 'Rate' },
      { field: 'status', header: 'Status' }
    ];

    this.setImportMenu();


  }

  ngAfterViewInit() {
    if (this.hoardingGroupChildRef) {
      this.changeHoardingGroupGridConfig()
    }

    this.getUserColumnConfig();

    this.subscription.push(this.hmColService.defaultColumns.subscribe((defaultColumns) => {

      defaultColumns.forEach((column) => {
        if (column['default'] === true && column['permanent'] === false) {
          if (this.selectedColumns.indexOf(column['header']) < 0) {
            this.selectedColumns.push(column['header'])
          }
        }
      })

      if (this.selectedColumns.length > 0) {
        this.hmColService.selectedColumns.next(this.selectedColumns);
      }

    }))
  }

  setFilterDropdowns() {
    this.status = utils.createDropdown(HoardingStatusEnumLocal, false);
  }

  setImportMenu() {
    this.importTypes = [
      {
        label: 'From Contract',
        command: (onclick) => {
          this.importHoardings()
        }
      },
      {
        label: 'From Excel',
        command: (onclick) => {
          this.importFromCSV();
        }
      }
    ]
  }
  contractExpiryCheck() {
    if (this.contract.status === 'EXPIRED') {
      this.hoardingListColumns.pop();
      this.hoardingListColumns = JSON.parse((JSON.stringify(this.hoardingListColumns)))
      // this.cd.detectChanges();
      //To do : sanchit
      // this.buttonConfig.add.disabled = () => {
      //   return true;
      // }
    }
  }


  createHoarding() {
    this.mediaType = 'HOARDING'
    this.createHoardingMode = true;
    this.displayCreateHoarding = true;
    this.editMode = false;
  }

  createKiosk() {
    this.mediaType = 'KIOSK'
    this.createHoardingMode = true;
    this.displayCreateHoarding = true;
    this.editMode = false;
  }

  onClubHoardingClick() {
    this.displayClubHoarding = true;
  }

  saveSelectedHoardings(event: ContractHoardings[]) {
    event.forEach(element => {
      element.contract = this.contract;
    });
    this.actionDispatcher.addHoardingsToContract(event);
  }

  toggleDisplayFlag(event) {
    this.displayHoardingSearch = false;
  }

  toggleGroupDialogDisplayFlag(event) {
    this.displayClubHoarding = false;
    this.isGroupSaved = false;
    this.hoardingGroup = new HoardingGroup();
    this.actionDispatcher.resetHoardingGroupFromContract()
    this.hoardingGroupChildRef.refreshGrid();
    this.actionDispatcher.getGroupsByContractId(this.contract.id);
  }

  editGroup(rowData, col) {
    const newImage: any[] = [];
    this.hoardingGroup = rowData.data;
    this.displayClubHoarding = true;
    this.isGroupSaved = true;
  }

  changeCreateHoardingDisplay(event) {
    this.hoardingListChildRef.refreshGrid();
    this.kioskChildRef.refreshGrid();
    this.hoardingGroupChildRef.refreshGrid();
    this.displayCreateHoarding = false;
  }


  editContract() {
    // this.router.navigate(['contracts/edit/' + this.contract.id]);
    this.displayContractForm = true;

  }

  getSitesStatus(status) {
    for (let i = 0; i < this.status.length; i++) {
      if (this.status[i].value === status) {
        return this.status[i].label;
      }
    }
  }

  emitSelectedStatus() {
    this.hmColService.statuses.next(this.selectedStatus);
  }

  removeStatus(i) {
    this.selectedStatus.splice(i, 1);
    this.emitSelectedStatus();
  }

  viewImages(event) {
    // this.displayPhotoDialog = true;
    this.imageData = event;
    this.loading = true;
    this.photoDialogComponent.imageData = this.getImageData(event);
    this.photoDialogComponent.showImages();
  }

  getImageData(data) {
    var imageData = [];
    for (let i = 0; i < data.images.length; i++) {
      imageData.push(new ImageDataDisplay().getImageData(data, data.images[i]))
    }
    return imageData;
  }

  viewDetail(rowData) {
    // rowData.statusQueues.forEach((item) => {
    //   item.customId = rowData.customId;
    // });
    this.rowData = rowData;
    this.displayStatusQueueDialog = true;
  }

  hideStatusQueueDialog() {
    this.displayStatusQueueDialog = false;
  }

  changePhotoDisplay(event) {
    this.displayPhotoDialog = false;
  }

  openContractForm() {
    this.title = 'Edit Contract (' + this.contract.id + ')';
    this.contractForm.handleDialogOpen(true);
  }

  backToContractList() {
    this.actionDispatcher.resetContract();
    this.actionDispatcher.resetHoardingGroups()
    this.router.navigate(['/contracts/']);
  }

  toggleLoader(event) {
    this.loading = false;
  }

  editHoarding(rowData) {
    this.editMode = true;
    this.hoardingData = _.cloneDeep(rowData);
    this.createHoardingMode = false;
    this.displayCreateHoarding = true;
  }

  changeDisplay($event) {
    this.displayCreateHoarding = false;
    this.hoardingListChildRef.refreshGrid();
    this.hoardingGroupChildRef.refreshGrid();
    this.kioskChildRef.refreshGrid();
    this.actionDispatcher.getContractById(this.contract.id);
  }

  changeEditMode() {
    this.hoardingListChildRef.refreshGrid();
    this.kioskChildRef.refreshGrid();
    this.hoardingGroupChildRef.refreshGrid();
    this.editMode = false;
  }

  updateHoarding(editedHoarding) {
    // this.actionDispatcher.updateHoarding(editedHoarding);
    this.subscription.push(
      this.actionDispatcher.isHoardingUpdated.subscribe((isHoardingUpdated) => {
        if (isHoardingUpdated) {
          this.hoardingDialogComponent.loading = false;
          this.displayCreateHoarding = false;
          this.hoardingDialogComponent.editContractHoardingMode = false;
          this.actionDispatcher.resetIsHoardingUpdated();
          this.changeEditMode();
        }
      })
    );
    this.subscription.push(
      this.actionDispatcher.isError.subscribe((isError) => {
        if (isError) {
          this.hoardingDialogComponent.loading = false;
          this.displayCreateHoarding = false;
          this.hoardingDialogComponent.editContractHoardingMode = false;
          this.newHoarding = new ContractHoardings();
          this.actionDispatcher.resetError();
          this.changeEditMode();
        }
      })
    );
  }

  getValue(status) {
    return HoardingStatusEnum[status];
  }

  hoardingCreated(event) {
    if (event) {
      this.actionDispatcher.getContractById(this.contract.id);
    } else {
      this.importInformation = false;
      this.displaySetupIncompleteDialog = true;
    }
  }

  onDialogClose() {
    this.actionDispatcher.getContractById(this.contract.id);
  }

  getLightType(key) {
    return HoardingLightEnum[key];
  }

  hideContractEditDialog(event) {
    this.displayContractForm = false;
    this.hoardingListChildRef.refreshGrid();
    this.hoardingGroupChildRef.refreshGrid();
    this.kioskChildRef.refreshGrid();
  }

  getStatus() {
    if (this.contract) {
      this.setStatusStyle();
      return ContractStatusEnum[this.contract.status]
    } else {
      return '-'
    }

  }

  setStatusStyle() {
    this.statusStyle = {
      'color': this.contract.status === 'ACTIVE' ? 'green' : this.contract.status === 'INACTIVE' ? 'red' : 'black',
      'font-weight': 'bold'
    }
  }

  importHoardings() {
    this.importHoardingConfig.showImportHoarding = true;
    this.importHoardingConfig.contract = this.contract;
  }

  closeImportHoardingDialog(event) {
    this.importHoardingConfig.showImportHoarding = event;
    this.hoardingListChildRef.refreshGrid();
    this.hoardingGroupChildRef.refreshGrid();
    this.actionDispatcher.getGroupsByContractId(this.contract.id);
    this.actionDispatcher.getContractById(this.contract.id);
  }

  onTabChange(event) {
    this.activeIndex = event.index
  }

  editKiosk(kiosk) {
    this.editMode = true;
    this.hoardingData = _.cloneDeep(kiosk);
    this.createHoardingMode = false;
    this.displayCreateHoarding = true;
  }

  changeHoardingGroupGridConfig() {
    if (this.hoardingGroupChildRef) {
      this.hoardingGroupChildRef.groupGridConfig.selectionMode = SelectionMode.None;
      this.hoardingGroupChildRef.groupGridConfig.checkboxSelection = false;
    }

  }

  getUserColumnConfig() {
    if (this.hoardingListChildRef) {
      this.columns = this.hoardingListChildRef.getModifiedColumns();
    }
  }

  emitSetColumns() {
    this.hmColService.selectedColumns.next(this.selectedColumns);
  }

  updatingUserGridConfig() {
    this.hoardingListChildRef.updatingUserGridConfig(this.selectedColumns);
    this.hoardingGroupChildRef.updatingUserGridConfig(this.selectedColumns);
    this.kioskChildRef.updateUserGridConfig(this.selectedColumns)
  }

  setNumberOfGroups($event) {
    this.numberOfGroups = $event;
  }

  setTotalGroupRecords($event) {
    this.totalGroups = $event;
  }

  importFromCSV() {
    this.contractUiService.getHoardingTemplateUrl()
      .subscribe((templateUrl) => {
        if (templateUrl) {
          this.hoardingTemplateUrl = templateUrl['data'];
          console.log("download url is", this.hoardingTemplateUrl)
        }
      }, (error) => {

        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          if (errorObject.message === 'Tenant Setup is incomplete please contact ShowItBig Support!') {
            this.closeUploadCSVDialog();
            this.importInformation = true;
            this.displaySetupIncompleteDialog = true;
          } else {
            this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
          }
        } else {
          this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
        }
      })
    this.displayImportFromCsv = true;
  }

  onImportFromCsvOpen() { }

  onCsvFileSelection(event) {
    this.csvFile = event.files[0];
  }

  uploadCsvFile() {
    this.loading = true;
    let formData: FormData = new FormData();
    formData.append('file', this.csvFile, this.csvFile.name);
    this.hoardingService.create(formData, { fileName: this.csvFile.name, file: this.csvFile }, `/import/${this.contract.id}`, { 'enctype': 'multipart/form-data' }, 'arraybuffer')
      .subscribe((response) => {
        this.loading = false;
        this.notificationServcie.success('File Uploaded Succesfully', 'File Upload.');
        this.closeUploadCSVDialog();
        this.hoardingListChildRef.refreshGrid();
        this.actionDispatcher.getContractById(this.contractId);
      }, (error) => {
        this.loading = false;
        this.csvFile = new File([""], '');

        const errorObject = ErrorUtil.getErrorObject(error);
        if (errorObject.code === 417 || errorObject.code === 412) {
          if (errorObject.message === 'Tenant Setup is incomplete please contact ShowItBig Support!') {
            this.closeUploadCSVDialog();
            this.importInformation = true;
            this.displaySetupIncompleteDialog = true;
          } else {
            this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
          }
        } else {
          this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
        }
      })
  }

  onImportFromCsvClose() { }

  closeUploadCSVDialog() {
    this.csvFile = new File([""], '');
    this.displayImportFromCsv = false;
  }

  uploadDoc() {
    this.displayUploadDocDialog = true;
    this.setImageFolderConfig();
  }

  setImageFolderConfig() {
    this.imageFolderConfig.itemId = this.contract.id;
    this.imageFolderConfig.moduleName = 'contracts';
  }

  showUploadDocDialog() {
    this.displayUploadDocDialog = true;
  }

  hideUploadDocDialog() {
    this.displayUploadDocDialog = false;
    this.gridViewChildRef.refresh();
  }

  setDocument(event) {
    console.log(event)
    this.contract.documents = [...this.contract.documents, ...event];
    this.uploadDocumentService.uploadDocument(this.contract.documents, this.imageFolderConfig.itemId).subscribe((response) => {
      this.notificationServcie.success('Document Uploaded Successfully', 'Document Upload');
      this.displayUploadDocDialog = false;
    });
  }

  setHoardingGroupSearchEvent(event) {
    this.hoaridngGroupSearchEvent = event;
  }

  exportXLSX() {
    switch (this.activeIndex) {
      case 0: {
        this.isFileDownloading = true;
        this.searchEvent = this.hoardingSearchEvent;
        this.createLoaderObject("XLS", this.xlsLoader);
        this.loaderSubjects.hoardingXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
        break;
      }
      case 1: {
        this.isFileDownloading = true;
        this.searchEvent = this.hoaridngGroupSearchEvent;
        this.createLoaderObject("XLS", this.xlsLoader);
        this.loaderSubjects.hoardingGroupXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
        break;
      }
      case 2: {
        this.isFileDownloading = true;
        this.searchEvent = this.kioskSearchEvent;
        this.createLoaderObject("XLS", this.xlsLoader);
        this.loaderSubjects.kioskXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
        break;
      }
    }
  }

  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
    object.source = this.contract.name;
  }

  getExpiredStatus() {
    this.isExpired = false;
    this.expiredInventory = 0;
    switch (this.activeIndex) {
      case 0: {
        this.hoardings.forEach(hoarding => {
          if (hoarding.status && HoardingStatusEnum[hoarding.status] === HoardingStatusEnum.EXPIRED) {
            this.expiredInventory += 1;
          }
        });
        if (this.expiredInventory === this.numberOfHoardings && this.expiredInventory > 0) {
          this.isExpired = true;
        } else {
          this.isExpired = false;
        }
        this.hoardingListChildRef.setColumns();
        break;
      }
      case 2: {
        this.kiosks.forEach(kiosk => {
          if (kiosk.status && HoardingStatusEnum[kiosk.status] === HoardingStatusEnum.EXPIRED) {
            this.expiredInventory += 1;
          }
        });
        if (this.expiredInventory === this.numberOfKiosks && this.expiredInventory > 0) {
          this.isExpired = true;
        } else {
          this.isExpired = false;
        }
        this.kioskChildRef.setColumns();
        break;
      }
    }

  }

  closeInformationDialog() {
    this.displaySetupIncompleteDialog = false;
  }

  openInformationDialog() {
    this.importInformation = false;
    this.displaySetupIncompleteDialog = true;
  }

  ngOnDestroy() {
    this.actionDispatcher.resetHoardingGroups()
    this.actionDispatcher.resetContract();
    this.actionDispatcher.resetContractIdFailedFlag();
    this.contract = new Contracts();
    this.contractId = undefined;
    this.hmColService.defaultColumns.next([])
    this.subscription.forEach((s) => s.unsubscribe());
  }
}

export enum HoardingStatusEnumLocal {
  AVAILABLE = "Available",
  BLOCKED = "Blocked",
  INACTIVE = "Inactive",
  NOT_AVAILABLE = "Not-Available",
  EXPIRED = "Expired"
}
