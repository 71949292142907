<!-- <div class="cn-preview">
    <div class="ui-g">
        <div class="ui-g-8">

            <div class="ui-g billing-heading">
                <div class="ui-g-12">
                    {{creditNote?.campaign?.customer?.billingName}}
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-6 padding-10">
                    <div *ngIf="!creditNote?.invoiceGstDetail?.gstNo">
                        <div class="ui-g padding-0">
                            {{creditNote?.campaign?.customer?.address1}}
                        </div>
                        <div class="ui-g padding-0">
                            {{creditNote?.campaign?.customer?.address2}}
                        </div>
                        <div class="ui-g padding-0" *ngIf="creditNote?.campaign?.customer?.postalCode">
                            {{creditNote?.campaign?.customer?.city?.name}} -
                            ({{creditNote?.campaign?.customer?.postalCode}}),
                            {{creditNote?.campaign?.customer?.state?.name}}
                        </div>
                        <div class="ui-g padding-0" *ngIf="!creditNote?.campaign?.customer?.postalCode">
                            {{creditNote?.campaign?.customer?.city?.name}},
                            {{creditNote?.campaign?.customer?.state?.name}}
                        </div>
                    </div>
                    <div *ngIf="creditNote?.invoiceGstDetail?.gstNo">
                        <div class="ui-g padding-0">
                            {{creditNote?.invoiceGstDetail?.branchAddress?.address1}}
                        </div>
                        <div class="ui-g padding-0">
                            {{creditNote?.invoiceGstDetail?.branchAddress?.address2}}
                        </div>
                        <div class="ui-g padding-0" *ngIf="creditNote?.campaign?.customer?.postalCode">
                            {{creditNote?.invoiceGstDetail?.branchAddress?.city?.name}} -
                            ({{creditNote?.invoiceGstDetail?.branchAddress?.postalCode}}),
                            {{creditNote?.invoiceGstDetail?.branchAddress?.state?.name}}
                        </div>
                        <div class="ui-g padding-0" *ngIf="!creditNote?.campaign?.customer?.postalCode">
                            {{creditNote?.invoiceGstDetail?.branchAddress?.city?.name}},
                            {{creditNote?.invoiceGstDetail?.branchAddress?.state?.name}}
                        </div>
                    </div>

                    <div class="ui-g padding-bottom-10 padding-top-20 ">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Place of Supply', value:creditNote.placeOfSupply  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'State', value: (creditNote?.invoiceGstDetail?.gstNo !== null && creditNote?.invoiceGstDetail?.gstNo !== undefined) ? creditNote?.invoiceGstDetail?.branchAddress?.state?.name : (creditNote?.campaign?.customer?.state?.name) ? creditNote?.campaign?.customer?.state?.name : 'N/A' }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'State Code', value: (creditNote?.invoiceGstDetail?.gstNo !== null && creditNote?.invoiceGstDetail?.gstNo !== undefined) ? creditNote?.invoiceGstDetail?.branchAddress?.state?.key : (creditNote?.campaign?.customer?.state?.key) ? creditNote?.campaign?.customer?.state?.key : 'N/A' }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'GST Number', value: (creditNote?.invoiceGstDetail?.gstNo !== null && creditNote?.invoiceGstDetail?.gstNo !== undefined) ? creditNote?.invoiceGstDetail?.gstNo : 'N/A'  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'PAN Number', value: (creditNote?.invoiceGstDetail?.panNo !== null && creditNote?.invoiceGstDetail?.panNo !== undefined) ? creditNote?.invoiceGstDetail?.panNo : (creditNote?.campaign && creditNote?.campaign?.customer && creditNote?.campaign?.customer?.pan !== null && creditNote?.campaign?.customer?.pan !== undefined) ? creditNote?.campaign?.customer?.pan : 'N/A'  }">
                        </ng-container>
                    </div>
                </div>

                <div class="ui-g-6 padding-10">

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Display Name', value: creditNote?.campaign?.displayName  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Credit Note No', value: (creditNote?.invoiceId) ? creditNote?.invoiceId : 'N/A' }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Credit Note Date', value: (creditNote?.createdDate) ? getFormattedDate(creditNote?.createdDate) : 'N/A'  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Invoice No', value: (creditNote?.invoiceReferenceId) ? creditNote?.invoiceReferenceId : 'N/A' }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Invoice Date', value: (creditNote?.billGeneratedDate) ? getFormattedDate(creditNote?.billGeneratedDate) : 'N/A'  }">
                        </ng-container>
                    </div>

                    
                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Remark', value: (creditNote?.remark) ? creditNote?.remark : 'N/A'  }">
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>

        <div class="ui-g-4">
            <sib-cn-summary *ngIf="creditNote" [creditNote]="creditNote"> </sib-cn-summary>
        </div>

        <div class="ui-g">
            <div class="ui-g-12 ctext-center padding-0">
                <b>CREDIT NOTE</b>
            </div>
            <div class="ui-g-12 padding-0">
                <sib-cn-summary-grid [creditNote]="creditNote"></sib-cn-summary-grid>
            </div>
        </div>

    </div>
</div>

<ng-template #contentTemplate let-key='key' let-value='value'>
    <div class="ui-g-4 padding-0">
        <div class="valuefont">{{key}}</div>
    </div>
    <div class="ui-g-8 padding-0">
        <div *ngIf="key === 'Display Name'" class="ui-g-12 padding-0"> <a target="_blank"
                [routerLink]="['/billings/view/',creditNote.campaign.id]">{{value}}</a></div>
        <div *ngIf="key !== 'Display Name'" class="ui-g-12 padding-0"> {{value}}</div>
    </div>
</ng-template> -->


<div class="cn-preview">
    <div class="ui-g">
        <div class="ui-g-8 padding-bottom-0">
            <div class="ui-g">
                <div class="ui-g-6 padding-10">
                    <!-- vendor name -->
                    <div class="ui-g-12 billing-name-heading padding-0">
                        <ng-container
                            *ngTemplateOutlet="addressContentTemplate; context: {value: creditNote?.supplier?.name }">
                        </ng-container>
                    </div>
                    <!-- vendor address -->
                    <div class="ui-g-12 padding-0">
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getAddressComparisonObject()?.invAdd?.address1  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getAddressComparisonObject()?.invAdd2  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: 'GSTIN / UIN: ' + (getAddressComparisonObject()?.invGstNo ? getAddressComparisonObject()?.invGstNo : 'N/A')  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getAddressComparisonObject()?.invStateWithCode  }">
                            </ng-container>
                        </div>
                    </div>
                    <div class="ui-g-12 padding-left-0 padding-right-0 padding-bottom-0 padding-top-10">
                        Buyer,
                    </div>
                    <!-- customer name -->
                    <div class="ui-g-12 padding-0 billing-name-heading">
                        <ng-container
                            *ngTemplateOutlet="addressContentTemplate; context: {value: creditNote?.campaign?.customer?.billingName }">
                        </ng-container>
                    </div>
                    <!-- customer address -->
                    <div class="ui-g-12 padding-0">
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getCustomerAddComparisionObj()?.invAdd?.address1  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getCustomerAddComparisionObj()?.invAdd2  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: 'GSTIN / UIN: ' + getCustomerAddComparisionObj()?.invGstNo  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: 'Place Of Supply: ' + creditNote?.placeOfSupply  }">
                            </ng-container>
                        </div>
                        <div class="ui-g-12 padding-0">
                            <ng-container
                                *ngTemplateOutlet="addressContentTemplate; context: {value: getCustomerAddComparisionObj()?.invStateWithCode  }">
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="ui-g-6 padding-10">
                    <div class="ui-g padding-bottom-5">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Credit Note No', value: (creditNote?.invoiceId) ? creditNote?.invoiceId : 'N/A' }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-5">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Credit Note Date', value: (creditNote?.createdDate) ? getFormattedDate(creditNote?.createdDate) : 'N/A'  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-5">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Invoice Ref No', value: (creditNote?.invoiceReferenceId) ? creditNote?.invoiceReferenceId : 'N/A' }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Invoice Date', value: (creditNote?.billGeneratedDate) ? getFormattedDate(creditNote?.billGeneratedDate) : 'N/A'  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-5">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Display Name', value: creditNote?.campaign?.displayName  }">
                        </ng-container>
                    </div>

                    <div class="ui-g padding-bottom-10">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Remark', value: (creditNote?.remark) ? creditNote?.remark : 'N/A'  }">
                        </ng-container>
                    </div>

                    <div class="ui-g">
                        <ng-container
                            *ngTemplateOutlet="contentTemplate; context: {key: 'Business By', value: creditNote?.campaign?.employee?.fullName  }">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui-g-4">
            <sib-cn-summary *ngIf="creditNote" [creditNote]="creditNote"> </sib-cn-summary>
        </div>

        <div class="ui-g">
            <div class="ui-g-12 ctext-center padding-0">
                <b>CREDIT NOTE</b>
            </div>
            <div class="ui-g-12 padding-0">
                <sib-cn-summary-grid [creditNote]="creditNote"></sib-cn-summary-grid>
            </div>
        </div>

    </div>
</div>

<ng-template #contentTemplate let-key='key' let-value='value'>
    <div class="ui-g-4 padding-0">
        <div class="valuefont">{{key}}</div>
    </div>
    <div class="ui-g-8 padding-0">
        <div *ngIf="key === 'Display Name'" class="ui-g-12 padding-0"> <a target="_blank"
                [routerLink]="['/billings/view/',creditNote.campaign.id]">{{value}}</a></div>
        <div *ngIf="key !== 'Display Name'" class="ui-g-12 padding-0"> {{value}}</div>
    </div>
</ng-template>

<ng-template #addressContentTemplate let-value='value' let-class='class'>
    <div class="ui-g-12 padding-0">
        {{value}}
    </div>
</ng-template>