import { SystemService } from "./system.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class TaxConfigurationService {
    gst: Subject<number> = new Subject();
    cgst: Subject<number> = new Subject();
    sgst: Subject<number> = new Subject();
    igst: Subject<number> = new Subject();
    
    constructor(
        private systemService: SystemService
    ) { }

    getTaxConfiguration() {
        this.systemService.get({ module: "TAX" }, '/search/by-module')
            .subscribe((configuration) => {
                const configurations = configuration;
                // ['_embedded']['systemProperties']
                this.setGst(configurations);
                this.setCgst(configurations);
                this.setSgst(configurations);
                this.setIgst(configurations);
            })
    }


    setGst(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'GST') {
                this.gst.next(configurations[i]['value']);
            }
        };
    }

    setCgst(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'CGST') {
                this.cgst.next(configurations[i]['value']);
            }
        };
    }

    setSgst(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'SGST') {
                this.sgst.next(configurations[i]['value']);
            }
        };
    }

    setIgst(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'IGST') {
                this.igst.next(configurations[i]['value']);
            }
        };
    }
}