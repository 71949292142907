import { Injectable, Injector } from "@angular/core";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Http, Response } from "@angular/http";

@Injectable()
export class PendingInvoiceService extends ApiService<any, any, any> {
    urlPath: string = 'invoices/pending-invoices';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }
}
