import { InjectionToken, Injector } from "@angular/core";
import { ApiService } from "./api.service";
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IQueryParams } from "./rest-interfaces";
import { Observable, Observer } from "rxjs";

export const HELP_URL = new InjectionToken<string>('HELP_URL');

export abstract class HelpService<TGet, TPost, TFilter> extends ApiService<TGet, TPost, TFilter> {

    /**
     * Abstract variable which would be given a value in the derived class.
     * 
     * @abstract
     * @type {string}
     * @memberOf ApiService
     */
    public urlPath: string;

    /**
     * Help url 
     * 
     * @type {string}
     * @memberOf ApiService
     */
    public helpUrl: string = '';

    /**
     * Creates an instance of ApiService.
     * 
     * @param {Http} http
     * @param {Injector} injector
     * 
     * @memberOf ApiService
     */
    constructor(public httpClient: HttpClient, public injector: Injector) {
        super(httpClient, injector);
        this.urlPath = this.getUrl();
        // this.addInterceptor();
    }

    /**
     * Gets the url on which http request has to be made
     * 
     * @returns {string}
     * 
     * @memberOf ApiService
     */
    getUrl(): string {
        //let config: AppConfig = this.iSnjector.get(AppConfig)
        //let baseUrl = 
        this.helpUrl = this.injector.get(HELP_URL);
        return this.helpUrl + this.urlPath;
    }


}