<div class="reserve-cn-details">
    <p-dialog *ngIf="displayReserveCNDetailDialog" header="Add CN Details" [modal]="true" showEffect="fade"
        blockScroll="true" [(visible)]="displayReserveCNDetailDialog" [width]="400" (onShow)="showCNDetailsDialog()"
        (onHide)="hideCNDetailsDialog()" [autoAlign]="false">
        <div *ngIf="isLoading">
            <p-progressSpinner class="loading"></p-progressSpinner>
        </div>
        <form #cnDetailsForm="ngForm" autocomplete="off" class="cnDetail">
            <div class="ui-g">
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Sales Rep
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5 reservedCn-srDropdown">
                        <p-dropdown name="salesRep" [options]="salesReps" [autoWidth]="false" [style]="{'width':'100%'}"
                            placeholder="Select a Sales Rep" filter="true" (onChange)="onSRChange($event)"
                            [panelStyleClass]="'srDropdown'" [(ngModel)]="selectedSR" [autoDisplayFirst]="false"
                            required>
                        </p-dropdown>
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Ref Inv Id
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input #refInvId="ngModel" style="width: 100%" id="refInvId" type="text" pInputText
                            placeholder="Enter a Reserve Invoice Id" name="referrenceInvoiceId"
                            [(ngModel)]="rowData.invoiceReferenceId" required
                            (blur)="(rowData.invoiceReferenceId) ? setGstDetail(rowData.invoiceReferenceId): ''" />
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <!-- <span>Company</span> -->
                        <span>Billing Name</span>
                    </div>
                    <div class="ui-g-8 margin-top--5 ">
                        <p-dropdown #companyDropdown name="companies" [options]="companies" [autoWidth]="false"
                            [style]="{'width':'100%'}" placeholder="Select a Billing Name" filter="true"
                            (onChange)="onCompanyChange($event)" [(ngModel)]="selectedCompany"
                            [autoDisplayFirst]="false">
                        </p-dropdown>
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <span>Address</span>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input style="width: 100%" id="address" type="text" pInputText placeholder="Enter the Address"
                            name="address" [(ngModel)]="address" />
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <span>Branch</span>
                    </div>
                    <div class="ui-g-8 margin-top--5 customDropdown">
                        <p-dropdown #groupedDropdown name="branches" [options]="branches" [autoWidth]="false"
                            [style]="{'width':'100%'}" placeholder="Select a Branch" [group]="true"
                            [panelStyleClass]="'customDropdown'" (onChange)="onBranchChange($event)"
                            [(ngModel)]="selectedBranch" [autoDisplayFirst]="false">
                            <ng-template let-group pTemplate="group">
                                {{group.label}} - {{group.value.branchAddress[0]?.state?.name}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Display Name
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input #displayName="ngModel" style="width: 100%" id="displayName" type="text" pInputText
                            name="displayName" [(ngModel)]="rowData.displayName" />
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Gen Date
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <p-calendar #CNGenDate="ngModel" shortYearCutoff="100" [inputStyle]="{'width':'100%'}"
                            name="CNGenDate" [styleClass]="'customCalendar'" [utc]="true" appendTo="body"
                            [(ngModel)]="rowData.tallyGeneratedDate" showIcon="true" dateFormat="ddMy"
                            pTooltip="{{CNGenDate.hasError('required')? 'Ex - 01Jan18' :''}}" trim="blur" required>
                        </p-calendar>
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Reason
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <textarea #textarea rows="5" cols="30" pInputTextarea name="reason" [(ngModel)]="rowData.reason"
                            required style="width : 100%"></textarea>
                    </div>
                </div>
            </div>
        </form>
        <div class="ctext-center">
            <button type="button" pButton class="mtab-save margin-hori-5"
                label="{{rowData.utilized ? 'UPDATE' : 'SAVE'}}" [disabled]="!cnDetailsForm.form.valid"
                (click)="saveCNDetails()"></button>
            <button type="button" pButton class="mtab-secondary margin-hori-5" label="CANCEL"
                (click)="cancelCNDetails()"></button>
            <button *ngIf="rowData.utilized" type="button" pButton class="mtab-danger margin-hori-5" label="UNUTILIZE"
                (click)="unutilizedCN()"></button>
        </div>
    </p-dialog>
</div>