import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";

@Injectable()
export class CreateHoardingService extends ApiService<any, any, any> {
    urlPath: string = 'hoardings'

    constructor(Http: HttpClient, Injector: Injector){
        super(Http, Injector)
    }    
}
