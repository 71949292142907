export class AbstractSummary {
    totalRent: number;
    totalGST: number;
    grandTotal: number;
    lspDiffPctg: number;
    lspDiffAmt: number;
    discountAmt: number;
    printingAmt: number;
    mountingAmt: number;
    minimumAmt: number;
}
