import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, empty, throwError } from "rxjs";
import { AuthService } from "../../login-service/auth.service";
import { EncrDecrService } from '../shared/encr-decr.service';
import { BASE_URL } from '../base-service/api.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {


    private urlMap: Map<String, String> = new Map();

    constructor(public auth: AuthService,
        public injector: Injector,
        private encrDecrService: EncrDecrService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        /* if ( req.url.indexOf('api/v2/') == -1) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        } */

        if (req.url.indexOf('vendorConfigs/search/vendor-domain') > 0) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getMasterToken()}`
                }
            });
        } else if (req.url.indexOf('public/') >= 0 || req.url.indexOf('public-') >= 0) {
            req = req.clone({
                setHeaders: {
                    // Authorization: `Bearer ${this.auth.getToken()}`,
                }
            })
        } else {

            if (req.url.indexOf('api/v2/') == -1 && req.url.indexOf('/oauth/token') == -1) {
                req = req.clone({
                    // url: this.getEncodedData(req),
                    setHeaders: {
                        Authorization: `Bearer ${this.auth.getToken()}`,
                        x_tenant: `${localStorage.getItem('id')}`
                    }
                });
            }

            if (req.url.indexOf('api/v2/') >= 0) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.auth.getAuthApiToken()}`
                    }
                });
            }

            if (req.url.indexOf('.webtask') >= 0) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.auth.getUsermanagementToken()}`
                    }
                });
            }
        }
        return next.handle(req).map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.status === 401) {
                    this.auth.logout();
                }
                // event.url = this.encrDecrService.set("12345", event.url);
                return event;
            }
        });
    }

    getEncodedData(req) {
        console.log("req url", req.url);
        if (this.isEncoded(req.url)) {
            console.log("duplicate 1 url", req.url);
            return req.url;
        } else {
            const baseUrl = this.injector.get(BASE_URL);
            const serverUrl = baseUrl.split("api/")[0];
            const partUrl = req.url.split(baseUrl)[1];
            // const duplicate = req.clone({ url: serverUrl + "api/" + this.encrDecrService.set("12345", partUrl), body: this.encrDecrService.set("12345", req.body) });
            const duplicate = serverUrl + "api/" + "12345abcde" + this.encrDecrService.set("12345", partUrl);
            console.log("duplicate 3 url", duplicate);
            return duplicate;
        }
    }

    isUrlPresent(url) {
        if (this.urlMap.has(url)) {
            return true;
        } else {
            return false;
        }
    }

    isEncoded(url) {
        if (url.indexOf("12345abcde") === -1) {
            return false;
        } else {
            return true;
        }
    }
}

