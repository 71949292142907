import { Component, Input, ContentChild, TemplateRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'sib-grid-column',
  templateUrl: './grid-column.component.html',
  styleUrls: ['./grid-column.component.css']
})
export class GridColumnComponent implements AfterViewInit  {
  
  @Input('field')
  field: string;

  @ContentChild('bodyTemplate')
  bodyTemplate: TemplateRef<any>;

  @ContentChild('editorTemplate')
  editorTemplate: TemplateRef<any>;

  @ContentChild('filterTemplate')
  filterTemplate: TemplateRef<any>
  
  constructor() { }

  ngAfterViewInit(): void {
    this.bodyTemplate;
  }

  

}
