<div id="bottomAd" style="display: none">&nbsp;</div>
<div class="credit-notes-tasks">
  <div *ngIf="isLoading">
    <div class="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>

  <form [formGroup]="creditNoteTaskGroup">

    <sib-grid [showFilter]="true" name="creditNoteTaskGrid" #creditNoteTaskGrid [columns]="columns"
      [showXlsExport]="false" [buttons]="buttonConfig" [config]="gridConfig" [totalRecords]="totalRecords"
      formControlName="creditNoteTaskSiteGridControl" (eEmitColumnSelection)="updatingUserGridConfig($event)"
      ngDefaultControl>

      <sib-grid-column field="srNo">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <span>
            {{rowIndex+1}}
          </span>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="invoiceId">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <span *ngIf="rowData.status === 'ADMIN_FULL_CN_PENDING'">
            <a class="link-1  after" target="_blank"
              [routerLink]="['/billings/bills/view/'+ getEncodedURI(rowData.id)]">
              <span
                *ngIf="rowData.status === 'INVOICE_CANCEL' || rowData.status === 'INVOICE_CANCEL_WITH_RETENTION'; else defaultTemplate">
                <del>{{rowData.invoiceId}}</del>
              </span>
              <ng-template #defaultTemplate>
                {{rowData.invoiceId}}
              </ng-template>
            </a>
          </span>
          <span *ngIf="rowData.status === 'FULL_CN_APPROVED' || rowData.status === 'PARTIAL_CN_APPROVED'">
            <a class="link-1  after" target="_blank" [routerLink]="['/billings/cn/view/'+ getEncodedURI(rowData.id)]">
              <span
                *ngIf="rowData.status === 'INVOICE_CANCEL' || rowData.status === 'INVOICE_CANCEL_WITH_RETENTION'; else defaultTemplate">
                <del>{{rowData.invoiceId}}</del>
              </span>
              <ng-template #defaultTemplate>
                {{rowData.invoiceId}}
              </ng-template>
            </a>
          </span>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="campaign.displayName">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <div class="link-wrapper" *ngIf="admin">
            <a class="link-1  after" [routerLink]="rowData.campaign.displayName">{{rowData?.campaign?.displayName}}</a>
          </div>
          <div class="link-wrapper" *ngIf="!admin">
            {{rowData?.campaign?.displayName}}
          </div>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="campaignDiff">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <div *ngIf="rowData.campaignDiff < 0" class="negDiff">
            {{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiff)) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiffPctg))
                  | CurrencyConverter}}%)
          </div>
          <div *ngIf="rowData.campaignDiff >= 0" class="posDiff">
            {{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiff)) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiffPctg))
                  | CurrencyConverter}}%)
          </div>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="lspDiff">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <div *ngIf="rowData.lspDiff < 0" class="negDiff">
            {{getAbsoluteValue(parseValueToDecimal(rowData.lspDiff)) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.lspDiffPctg))
                  | CurrencyConverter}}%)
          </div>
          <div *ngIf="rowData.lspDiff >= 0" class="posDiff">
            {{parseValueToDecimal(rowData.lspDiff) | CurrencyConverter}} ({{getAbsoluteValue(parseValueToDecimal(rowData.lspDiffPctg))
                  | CurrencyConverter}}%)
          </div>
        </ng-template>
      </sib-grid-column>

      <!-- <sib-grid-column field="status">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
          let-column="column">
            <div class="padding-0 c-inline">
                <span class="padding-0 float-left">
                    <div class="square-text-small background-{{getClassForCharacter(rowIndex % rowsPerPage)}}">
                        <span title="{{charTitleAt[rowIndex % rowsPerPage]}}"
                            class="text-style-small pointer {{getClassForCharacter(rowIndex % rowsPerPage)}}">
                            {{charAt[rowIndex % rowsPerPage]}}
                        </span>
                    </div>
                </span>
                <span class="padding-0">
                    <sib-status-visualization [nodes]="statusVisualizationNodes"
                        [selected]="pendingAt[rowIndex % rowsPerPage]"></sib-status-visualization>
                </span>
            </div>
          </ng-template>
        </sib-grid-column> -->
      <sib-grid-column field="status">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <div class="col-xs-0 padding-0 c-inline">
            <span class="padding-0 float-left">
              <div class="square-text-small background-{{getClassForCharacter(rowIndex % rowsPerPage)}}">
                <span title="{{charTitleAt[rowIndex % rowsPerPage]}}"
                  class="text-style-small pointer {{getClassForCharacter(rowIndex % rowsPerPage)}}">
                  {{charAt[rowIndex % rowsPerPage]}}
                </span>
              </div>
            </span>
            <span class="padding-0">
              <sib-status-visualization [nodes]="statusVisualizationNodes"
                [selected]="pendingAt[rowIndex % rowsPerPage]"></sib-status-visualization>
            </span>
          </div>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="printed">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <i *ngIf="rowData?.printed === 'T'" class="fa fa-check-circle custom-icon true-color"
            title="{{rowData?.printed==='T' ? 'True' : 'False'}}"></i>
          <i *ngIf="rowData?.printed !== 'T'" class="fa fa-times-circle custom-icon false-color"
            title="{{rowData?.printed==='T' ? 'True' : 'False'}}"></i>
        </ng-template>
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown [options]="printDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
            placeholder="Select" (onChange)="onPrintedFilterChange($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="tallyEntered">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <i *ngIf="rowData?.tallyEntered === 'T'" class="fa fa-check-circle custom-icon true-color"
            title="{{rowData?.tallyEntered==='T' ? 'True' : 'False'}}"></i>
          <i *ngIf="rowData?.tallyEntered !== 'T'" class="fa fa-times-circle custom-icon false-color"
            title="{{rowData?.tallyEntered==='T' ? 'True' : 'False'}}"></i>
        </ng-template>
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown [options]="tallyEntryDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
            placeholder="Select" (onChange)="onTallyEnteredFilterChange($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="markAsPrinted">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <p-checkbox binary="true" name="checkPrint{{rowIndex}}"
            [disabled]="rowData?.printed === 'N' || !checkPrintedStatus(rowData.status)"
            [ngModel]="invoicePrinted.get(rowData?.id)" [ngModelOptions]="{standalone: true}"
            (onChange)="onMarkAsPrintedChange(rowData, $event)">
          </p-checkbox>
        </ng-template>
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown [options]="printDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
            placeholder="Select" (onChange)="onPrintedFilterChange($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="printInvoice">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <div class="print-icon">
            <i *ngIf="rowData?.printed !== 'N'" class="icon-printer custom-icon true-color" title="Print Invoice"
              (click)="togglePrintMenu($event, rowData, rowIndex)"></i>
            <i *ngIf="rowData?.printed === 'N'" class="icon-printer custom-icon not-available-color"></i>
          </div>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="markAsTallyEntered">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <p-checkbox binary="true" name="checkTallyEntry{{rowIndex}}"
            [disabled]="rowData?.tallyEntered === 'N' || !checkTallyEnteredStatus(rowData.status)"
            [ngModel]="invoiceTallyEntered.get(rowData.id)" [ngModelOptions]="{standalone: true}"
            (onChange)="onMarkAsTallyEnteredChange(rowData, $event)"></p-checkbox>
        </ng-template>
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown [options]="tallyEntryDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
            placeholder="Select" (onChange)="onTallyEnteredFilterChange($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="approveReject">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <div class="font-size-20">
            <span class="padding-right-5">
              <i class="fa fa-check-circle custom-icon true-color" title="Approve" (click)="approveFullCn(rowData)"></i>
            </span>
            <span>
              <i class="fa fa-times-circle custom-icon false-color" title="Reject" (click)="rejectFullCn(rowData)"></i>
            </span>
          </div>
        </ng-template>
      </sib-grid-column>

      <sib-grid-column field="purchaseOrders">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <i *ngIf="rowData?.purchaseOrders?.length > 0" class="custom-grid-icon icon-contract"
            (click)="showOverlay($event, rowData, rowIndex)" title="PO"></i>
          <div *ngIf="rowData?.purchaseOrders?.length === 0">-</div>
        </ng-template>
      </sib-grid-column>

    </sib-grid>

  </form>
  <p-overlayPanel #op [style]="{'width':'170px'}">
    <ol class="padding-left-10">
      <div class="ctext-center" style="font-weight: bold">Download PO</div>
      <br>
      <li *ngFor="let po of rowData?.purchaseOrders; let i = index" class="word-break-wrap">
        <a style="cursor: pointer;" class="link-1  after" href="{{po.url}}" target="_blank">{{po.name}}</a>
      </li>
    </ol>
  </p-overlayPanel>

  <p-overlayPanel #printMenu>
    <ng-container *ngIf="rowData?.printConfiguration">
      <sib-print-pannel-layout [printConfig]="rowData?.printConfiguration" [selectedInvoiceFormat]="'FULL_DETAIL'"
        [disableInvoiceFormatDropdown]="true" [showSave]="false" (eEmitChangedPrintType)="onPrintTypeChange($event)"
        (eEmitChangedPrintLetterHead)="onPrintLetterHeadChange($event)"
        (eEmitPreviewPrintItems)="previewPrintItems($event)" (eEmitPrintItems)="printItems($event)">
      </sib-print-pannel-layout>
    </ng-container>
  </p-overlayPanel>
</div>