import { CustomLoader } from "../../modals/loader-subjects/loader";

export class PPTLoader extends CustomLoader {
    data?: any;
    fileType?: any;
    searchEvent?: any;
    customIds?: string[] = [];
    subModule?: any;
    source?: string;
    pptData?: any;
    billed?: any;
    invoiceType?: any;
    ids?: string[] = [];
}