export class ImageFolderConfig {
    moduleName: string;
    hid?: string;
    campaignId?: string;
    moduleSubType?: string;
    imageType?: string;
    itemId?: string;
    planId?: string;
    subModuleName?: string;
    year?: number;
    month?: string;
}