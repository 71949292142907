import { Component, OnInit, ViewEncapsulation, AfterContentInit, Input, ContentChildren, QueryList, ViewChild, TemplateRef, Output, EventEmitter, ChangeDetectorRef, ElementRef, AfterViewInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { GridColumn, GridConfig, GridPaginationEvent, GridActionButtonTypes, SelectionMode } from './grid.config';
import { LazyLoadEvent, DataTable, SelectItem, Message, MenuItem } from 'primeng/primeng';
import { PaginationData } from '../../../modals/paginationdata';
import { Observable, Subject, BehaviorSubject, of, from, Subscription, empty, interval } from 'rxjs';
import * as _ from "lodash";
import { GridColumnComponent } from './grid-column.component';
import { GridTemplateDirective } from './grid-template.directive';
import { GridActionButton } from './grid-action-button';
import { GridActionButtonConfig } from './grid-action-button.config';
import { NgModel, NgForm } from '@angular/forms';
import { IQueryParams } from '../../../services/base-service/rest-interfaces';
import { CustomerService } from '../../../customers/services/customer.services';
import { CalendarModule } from 'primeng/primeng';
import { ContractHoardings } from '../../../modals/contracts/contract-hoarding';
import * as utils from '../../../helpers/utils';
// import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";
import { FileItem } from '../../../modals/files/file-item';
import { CalculationService } from '../../../services/shared/calculationService';
import { LoaderSubjects } from '../../../modals/loader-subjects/loader-subjects';
import { GridLoader } from '../../../modals/loader-subjects/grid-loader';
import { DomHandler } from "primeng/components/dom/domhandler";
import { debounceTime, distinctUntilChanged, switchMap, last, catchError, throttle, map } from 'rxjs/operators';

export type GridLazyLoadEvent = LazyLoadEvent;



@Component({
  selector: "sib-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DomHandler],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent<T> implements OnInit, AfterContentInit, OnDestroy {

  /**
   * Template reference for rowExpansion
   * 
   * @protected
   * @type {TemplateRef<any>}
   * @memberof GridComponent
   */
  protected rowExpansionTemplate: TemplateRef<any>;

  protected lazyLoadData: GridLazyLoadEvent;
  protected paginationData: PaginationData;


  status: SelectItem[];

  selectedStatus: any[] = [];

  toggle: boolean = false;

  queryParams: IQueryParams;

  msgs: Message[] = [];

  filteredValue = [];  // value on filter


  /* status: [
    {label:'Active', value:'A'},
    {label:'Inactive', value:'I'},
    {label:'Blacklisted', value:'B'}
  ] */

  @ViewChild(NgModel) model: NgModel;

  @ViewChild(NgForm) private internalForm: NgForm;

  @ViewChild('dt') dataTable: DataTable;

  @ContentChildren(GridColumnComponent)
  gridColumns: QueryList<GridColumnComponent>;

  @ContentChildren(GridTemplateDirective)
  gridTemplates: QueryList<GridTemplateDirective>;


  value: any[] = [];
  // values: any[] = [];  

  @Input() totalRecord: number = 100;
  public initialValue: Array<T> = new Array<T>();

  @Input() title: string;
  // @Input() hId: any;
  // @Input() presetFilter: any;

  @Input() disableMaualDateEntry = false;

  @Input() comparisionId: string = null;

  @Input() globalFilterFields: any[];
  _actionButtons: Array<GridActionButton>;

  @Output() eRowExpand: EventEmitter<any> = new EventEmitter();

  @Output() eFilters: EventEmitter<any> = new EventEmitter();

  @Output() eSort: EventEmitter<any> = new EventEmitter();

  @Output() eDefaultSort: EventEmitter<any> = new EventEmitter();

  @Output() eFilteredValue: EventEmitter<any> = new EventEmitter();

  @Output() eFilterObject: EventEmitter<any> = new EventEmitter();

  @Output() eRowClick: EventEmitter<T> = new EventEmitter();

  @Output() eSelectionChange: EventEmitter<Array<any>> = new EventEmitter();

  @Output() eRowEdit: EventEmitter<Array<any>> = new EventEmitter();

  @Output() eRowEditWithIndex: EventEmitter<any> = new EventEmitter();

  @Output() eRowEditWithCol: EventEmitter<any> = new EventEmitter();

  @Output() eRowEditGridChange: EventEmitter<any> = new EventEmitter();

  @Output() eRowEditNumberWithCol: EventEmitter<any> = new EventEmitter();

  @Output() eRowEditNumberWithIndex: EventEmitter<any> = new EventEmitter();

  @Output() eRowEditNumberWithIndexAndCol: EventEmitter<any> = new EventEmitter();

  @Output() eRowEditNumberGridChange: EventEmitter<any> = new EventEmitter();

  @Output() eCalenderChange: EventEmitter<any> = new EventEmitter();

  @Output() eCalenderGridChange: EventEmitter<any> = new EventEmitter();

  @Output() eSitesAvailableFrom: EventEmitter<any> = new EventEmitter();

  @Output() eEmitColumnSelection: EventEmitter<any> = new EventEmitter();

  @Output() eEmitDataTableValue: EventEmitter<any> = new EventEmitter();

  @Output() eEmitDeselectedRow: EventEmitter<any> = new EventEmitter();

  @Output() eEmitExportXlsx: EventEmitter<boolean> = new EventEmitter();

  @Output() eEmitImportXlsx: EventEmitter<boolean> = new EventEmitter();

  @Output() eEmitExportJson: EventEmitter<boolean> = new EventEmitter();

  @Output() eEmitExportXml: EventEmitter<boolean> = new EventEmitter();

  @Output() eEmitExportTallyXls: EventEmitter<boolean> = new EventEmitter();

  @Output() eResetDropdown: EventEmitter<any> = new EventEmitter();

  @Output() eEmitToggleEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  set buttons(actionButtonConfig: GridActionButtonConfig) {
    this._actionButtons = this.getActionButtonsFromConfig(actionButtonConfig);
  }

  gridColumnMap: { [field: string]: GridColumnComponent };


  @Input() config: GridConfig<T>;
  // private _config: GridConfig<T>;
  // @Input()
  // public get config(): GridConfig<T> {
  //   return this._config;
  // }

  // public set config(value: GridConfig<T>) {
  //   this._config = value;
  //   if (!this._config.showLoader) {
  //     this.loading = false;

  //   }
  // }
  @Input() columns: Array<GridColumn> = [];
  @Input() loading: boolean = true;
  @Input() showSearch: boolean = true;
  @Input() sitesAvailableFrom = false;
  @Input() showColorLegends = false;
  @Input() showDefaultSort = false;
  @Input() colorLegendsStatus: SelectItem[] = [];
  @Input() showFilter: boolean = false;
  @Input() showXlsExport: boolean = false;
  @Input() showJsonExport: boolean = false;
  @Input() showTallyXlsExport: boolean = false;
  @Input() showXmlExport: boolean = false;
  @Input() showMoreMenu: boolean = false;
  @Input() showXslxImport: boolean = false;
  @Input() showToggleSwitch: boolean = false;
  @Input() toggleSwitchLabel: string;
  @Input() source: string;
  private _menuItem: MenuItem[] = [];
  @Input()
  public get menuItem(): MenuItem[] {
    return this._menuItem;
  }
  public set menuItem(value: MenuItem[]) {
    this._menuItem = value;
  }
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() datesDisabled = false;
  @Input() setMinDate = false; // used in plan view component for setting the min value of calendar in plan item for end date
  @Input() setBothDates = false; // used in plan view component to set the min and max value of item dates
  @Input() currentDateCheck = true; // for billing to disable the current date check
  @Output() filterSelectedColumn: EventEmitter<any> = new EventEmitter();
  _totalRecords: number = 10;

  _selectedColumn: any[] = [];
  @Input()
  set selectedColumn(columns: any[]) {
    this._selectedColumn = columns;
    // this.changeDetectorRef.detectChanges();
  }
  get selectedColumn(): any[] {
    // this.changeDetectorRef.detectChanges();
    return this._selectedColumn;
  }
  modifiedColumn: SelectItem[] = [];
  filterValue: any[] = [];
  totalColumns: any[] = [];

  presentDate: Date = new Date();  // for today's date
  currentDate: Date = new Date();

  disabledDates: Date[] = [];

  rowMap = new Map<number, any>(); // for mapping the row which has changed the date for disable dates

  decimalNumbers: RegExp = /^(\d*\.)?\d+$/;  // for decimal number fields
  twoPlaceDecimalNumbers: RegExp = /^(?:\d*\.\d{1,2}|\d+)$/; // for decimal number fields upto 2 places
  patternMatch = false; // if the pattern matches
  zero: RegExp = /^[0]*$/;  // to test for value 0

  previousSeleceted: Array<T> = [];


  @Input()
  set totalRecords(value: number) {
    this._totalRecords = value;
  }

  /**
   * Getter for total records (in case of pagination and lazy loading)
   * 
   * @readonly
   * @memberof GridComponent
   */
  get totalRecords() {
    return this._totalRecords;
  }

  /**
   * Page number to load in case of paginated grid
   * 
   * @type {number}
   * @memberof GridComponent
   */
  _page: number = 0;

  /**
   * Setter for _page 
   * 
   * @memberof GridComponent
   */
  @Input()
  set page(value: number) {
    this._page = value;
  }

  /**
   * Getter for page
   * 
   * @readonly
   * @memberof GridComponent
   */
  get page() {
    return this._page;
  }

  /**
 * Number of rows to show per page
 * 
 * @type {number}
 * @memberof GridComponent
 */
  _rows: number = 50;
  /**
   * Number of rows to show per page
   * 
   * @type {number}
   * @memberof GridComponent
   */
  @Input()
  set rows(value: number) {
    this._rows = value;
  }

  get rows() {
    return this._rows;
  }

  @Input() selected: Array<T> = [];

  @Input() campaignBlurEvent = false; // to deitermine if the blur event is from campaign - temp sol

  /**
   * Actual columns which will be dislayed
   * 
   * @type {Array<GridColumn>}
   * @memberof GridComponent
   */
  columnsToDisplay: Array<GridColumn> = [];

  filterMap: Map<string, any> = new Map<string, any>();
  keyValues: any[];

  public panelStyle: any = {
    minWidth: '24em',
  };

  /**
   * Programmer: Sanchit Mirg
   * Passing DataKey to set the Items in the Grid
   * 
   * @type {*}
   * @memberof GridComponent
   */
  dataKey: any

  @ViewChild('gb') globalFilter: ElementRef;

  gridLoader: GridLoader = new GridLoader();

  globalFilterData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  subscription: Subscription = new Subscription();
  checked: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private calculationService: CalculationService,
    private loaderSubjects: LoaderSubjects,
    private domHandler: DomHandler,
  ) {
    this.status = [];
    this.status.push({ label: 'Select Status', value: null });
    this.status.push({ label: 'Active', value: 'A' });
    this.status.push({ label: 'Inactive', value: 'I' });
    this.status.push({ label: 'BlackListed', value: 'B' });
    this.changeDetectorRef.detach();
    setInterval(() => this.detectChanges(), 100);
  }

  ngOnInit() {
    this.setAllColumns();

    this.globalFilterData.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(data => {
        if (data || data === '') {
          return this.createLazyLoadMetaData(data)
        }

      })
    ).subscribe((data) => {
      // if(data)
      this.lazyLoadHandler(data);
      this.globalFilterData.next(undefined)
    })
  }

  setAllColumns() {
    this.presentDate.setDate(this.presentDate.getDate() + 1);  // for getting tomorrow's date
    this.modifiedColumn = [];
    this.totalColumns = [];
    this.selectedColumn = [];

    for (let i = 0; i < this.columns.length; i++) {
      // if ((this.columns[i].hidden === false || !this.columns[i].hidden) && (this.columns[i].permanent === false || !this.columns[i].permanent)) {
      if (this.columns[i].permanent === false || !this.columns[i].permanent) {
        this.modifiedColumn.push({ label: this.columns[i].header, value: this.columns[i].header });
        this.totalColumns.push(this.columns[i]);
        if ((this.columns[i].default === true) || (!this.columns[i].default)) {
          if (this.columns[i].default !== false) {
            this.selectedColumn.push(this.columns[i].header);
          }
        }
      }
    }
    this.setColumnsToDisplay();
  }

  ngAfterContentInit(): void {

    this.processTemplates();

    this.gridColumnMap = {};
    this.gridColumns.forEach((oGridColumn: GridColumnComponent) => {
      this.gridColumnMap[oGridColumn.field] = oGridColumn;
    });
  }

  /**
   * Iterate over templates passed for various options and attach them to the properties
   * 
   * @memberof GridComponent
   */
  processTemplates() {
    this.gridTemplates.forEach(item => {
      switch (item.type) {
        case "rowExpansion":
          this.rowExpansionTemplate = item.templateRef;
          this.dataTable.rowExpansionTemplate = item.templateRef;
          break;
      }
    });
  }


  paginationHandler($event) {
    this.paginationData = $event;
  }

  /**
   * Handler for lazy load event from p-datatable
   * 
   * @param {LazyLoadEvent} $event 
   * @memberof GridComponent
   */
  lazyLoadHandler($event: LazyLoadEvent) {
    this.lazyLoadData = $event;

    this.paginationData = {
      page: this.calculatePageNumber($event),
      size: $event.rows,
      sortField: $event.sortField ? $event.sortField : "updatedDate",
      sortOrder: $event.sortOrder ? $event.sortOrder : 1,
      filters: $event.filters ? $event.filters : {},
      globalFilter: this.globalFilter ? this.globalFilter.nativeElement.value : ''
    };


    this.loadData(this.paginationData, this.lazyLoadData);

    //  $event.filters.s;
  }

  loadData(
    paginationEvent: GridPaginationEvent = null,
    lazyLoadEvent: GridLazyLoadEvent = null
  ) {

    if (!paginationEvent) {
      if (this.paginationData) {
        paginationEvent = this.paginationData;
      } else {
        paginationEvent = {
          page: this.page,
          size: this.rows
        };
      }
    }

    if (this.config) {
      this.config.showLoader ? this.loading = true : this.loading = false;
    }
    // this.loading = true;
    if (this.config && !this.config.showLoader) {
      this.createLoaderObject(true);
      setTimeout(() => this.loaderSubjects.gridLoader.next(this.gridLoader), 0)
    }

    if (this.config && this.config.dataLoadFunction) {
      //Sanchit Mirg 06-05-2019 now calling to normalize to observable function because of search issue
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      // this.config = this.config;
      console.log(this.config.dataLoadFunction(paginationEvent));
      this.subscription = this.normalizeToObservable(this.config.dataLoadFunction(paginationEvent)).pipe()
        .subscribe(data => {
          this.createLoaderObject(false);
          setTimeout(() => this.loaderSubjects.gridLoader.next(this.gridLoader), 0)
          // setTimeout(this.loaderSubjects.gridLoader.next(this.gridLoader);
          this.loading = false;
          const lazyLoadedItems = data;
          this.value = this.config.dataTransform ? this.transformData(lazyLoadedItems, this.config.dataTransform) : lazyLoadedItems;
          //uncommented- because it affected the hoarding group.
          // this.detectChanges(); // removed by Pulkit as it was taking 2 secs
          this.initialValue = _.cloneDeep(this.value);
          this.eEmitDataTableValue.emit(this.getDataTableInstance());

        });

    } else {
      this.initialValue = _.cloneDeep(this.value);
      this.eEmitDataTableValue.emit(this.value);
    }
    // this.changeDetectorRef.detectChanges();
  }

  createLoaderObject(isCreate: boolean) {
    this.gridLoader.isLoading = isCreate;
    this.gridLoader.text = 'Fetching data...';
    // this.loaderSubjects.gridLoader.next(this.gridLoader);
  }



  /**
   * Filter data for a column
   * 
   * @param {*} filterValue 
   * @param {string} field 
   * @param {string} matchMode 
   * @memberof GridComponent
   */
  filter(filterValue: any, field: string, matchMode: string) {
    if (filterValue === "ALL" && !this.config.lazy) {
      this.dataTable.reset();
    } else {
      if (filterValue.value) {
        this.dataTable.filter(filterValue.value, field, matchMode);
      } else {
        this.dataTable.filter(filterValue, field, matchMode);
      }
    }
  }

  onValueChange($event) {
    // console.log("value change", $event);
  }

  // customFilter(filterValue: any, field: string, matchMode: string) {
  //   console.log("values", this.value, filterValue, field, matchMode);
  //   if (this.filteredValue.length > 0) {
  //     console.log("values filter", this.filteredValue);
  //   } else {
  //     this.value.forEach((value) => {
  //       if (value.status === filterValue) {
  //         this.filteredValue.push(value);
  //       }
  //     });
  //   }
  // }

  export() {
    this.dataTable.exportCSV();
  }

  onDefaultSort() {
    this.eDefaultSort.emit(true);
  }

  // exportToXLSX() {
  //   let valueToExport: any[] = [];
  //   if (this.filteredValue.length !== 0) {
  //     valueToExport = _.cloneDeep(this.filteredValue);
  //   } else {
  //     valueToExport = _.cloneDeep(this.value);
  //   }
  //   valueToExport = this.setValueToExport(valueToExport);
  //   this.convertJSONToXLSX(valueToExport);
  // }

  // convertJSONToXLSX(valueToExport) {
  //   const ws_name = 'SomeSheet';
  //   const wb: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
  //   const ws: any = XLSX.utils.json_to_sheet(valueToExport);
  //   wb.SheetNames.push(ws_name);
  //   wb.Sheets[ws_name] = ws;
  //   const wbout = XLSX.write(wb, {
  //     bookType: 'xlsx', bookSST: true, type:
  //       'binary'
  //   });
  //   FileSaver.saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), this.config.exportFilename + '.xlsx');
  // }

  // s2ab(s) {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i !== s.length; ++i) {
  //     // tslint:disable-next-line:no-bitwise
  //     view[i] = s.charCodeAt(i) & 0xFF;
  //   }
  //   let blob: any;
  //   blob = new Blob([buf], { type: 'application/octet-stream' });
  //   // const file: FileItem[] = [];
  //   // file.push(blob);
  //   // console.log("array buffer", blob, file, buf);
  //   return buf;
  // }

  // setValueToExport(valueToExport) {
  //   const newVal: any[] = [];
  //   let value: any = [];
  //   valueToExport.forEach((val) => {
  //     value = [];
  //     this.columnsToDisplay.forEach((col) => {
  //       if (val[col.field] !== undefined) {
  //         value[col.header] = val[col.field];
  //       }
  //     });
  //     newVal.push(value);
  //   });
  //   return newVal;
  // }


  /**
   * filter for color legends
   * 
   * @param {any} dt 
   * @param {string} value 
   * @memberof GridComponent
   */
  filterByStatus(value) {
    if (value) {
      Object.assign(this.lazyLoadData.filters, { status: { value: value, matchMode: 'is' } });
      // if (!this.paginationData.page) {
      this.paginationData.page = 0;
      // }
      // Object.assign(this.paginationData.filters, { status: { value: value, matchMode: 'is' } });
      // this.paginationData.filters = { status: { value: value, matchMode: 'is' } };
    } else {
      delete this.lazyLoadData.filters.status;  // to delete the status key in filters
      // delete this.paginationData.filters.status;  // to delete the status key in filters
    }
    this.loadData(this.paginationData, this.lazyLoadData);
  }

  onFilter(event) {
    this.filteredValue = event.filteredValue;
    this.eFilters.emit(event.filters);
    this.eFilteredValue.emit(event.filteredValue);
    this.eFilterObject.emit(event);
    // console.log("on filter", event);
    // this.keyValues = Object.keys(event.filters);
    // for (let i = 0; i < this.keyValues.length; i++) {
    //   this.filterMap.set(this.keyValues[i], event.filters[this.keyValues[i]]);
  }

  onSort(event) {
    // console.log("on sort", event);
    this.eSort.emit(event);
  }



  transformData(lazyLoadedData: Array<any>, transformFunction: (any) => T) {
    return lazyLoadedData.map((row) => transformFunction(row));
  }

  extractData(data: any) {
    return data.result;
  }

  calculatePageNumber($event: LazyLoadEvent): number {
    return (($event.first + $event.rows) / $event.rows) - 1;
  }

  normalizeToPromise(asyncValue: any | Promise<any> | Observable<any>): Promise<any> {
    // check if value is observable
    if (asyncValue instanceof Observable) {
      return asyncValue.toPromise();
    }

    if (asyncValue instanceof Promise) {
      return asyncValue;
    }
    return Promise.resolve(asyncValue);
  }

  normalizeToObservable(asyncValue: any | Promise<any> | Observable<any>): Observable<any> {
    // check if value is observable
    // console.log("async value", typeof asyncValue, asyncValue);
    if (asyncValue instanceof Observable) {
      return asyncValue;
    }

    if (asyncValue instanceof Promise) {
      return from(asyncValue);
    }

    return from(asyncValue);
    // return Promise.resolve(asyncValue);
  }

  /**
     * Get FieldName for inputs
     * 
     * @param {any} col 
     * @param {any} rowData 
     * @returns 
     * @memberof GridComponent
     */
  getFieldName(col, rowData) {
    return col.field + "-" + rowData["id"];
  }

  /**
   * Return grid action buttons based on config
   * 
   * @param {GridActionButtonConfig} actionButtonConfig 
   * @returns 
   * @memberof GridComponent
   */
  getActionButtonsFromConfig(
    actionButtonConfig: GridActionButtonConfig
  ): Array<GridActionButton> {
    // let editableButtons = this.getEditableActionButtons(actionButtonConfig);
    // let selectionButtons = this.getSelectionActionButtons(actionButtonConfig);
    let otherButtons = this.getOtherActionButtons(actionButtonConfig);

    let actionButtons = [
      // ...editableButtons,
      // ...selectionButtons,
      ...otherButtons
    ];

    // sort buttons
    // actionButtons = this.sortButtons(actionButtons);

    return actionButtons;
  }

  /**
   * Returns Other Action buttons; apart from add, delete, selectall and deselectAll, provided in config
   * 
   * @param {GridActionButtonConfig} actionButtonConfig 
   * @returns 
   * @memberof GridComponent
   */
  getOtherActionButtons(actionButtonConfig: GridActionButtonConfig) {
    let otherButtons = [];
    // push other buttons provided in config
    for (var key in actionButtonConfig) {
      // if (["add", "delete", "selectAll", "deselectAll"].indexOf(key) === -1) {
      let tempButton = actionButtonConfig[key];
      // tempButton.children = this.sortButtons(tempButton.children);
      otherButtons.push(tempButton);
      // }
    }

    return otherButtons;
  }

  dropDownChange() {
    this.loadData(this.paginationData, this.lazyLoadData);
  }

  /**
   * Handler for rowExpand Event of datatable
   * 
   * @param {any} $event 
   * @memberof GridComponent
   */
  rowExpandHandler($event) {
    this.eRowExpand.emit($event);
  }

  rowClickHandler($event) {
    this.eRowClick.emit($event);
  }

  refresh() {
    this.loadData();
  }

  refreshFormTable() {
    if (this.config === undefined) {

    }
    else {
      if (this.config.showGlobalFilter) {
        this.globalFilter.nativeElement.value = '';
      }
      else {

      }
    }
    this.resetAndReloadTable();
    this.eResetDropdown.emit();
  }

  findRowByKey(uniqueKey: string, uniqueKeyValue: any) {
    return _.find(this.value, oItem => {
      return oItem[uniqueKey] === uniqueKeyValue;
    });
  }

  /**
   * Calling this function from the Parent Component
   * to default select the already selected items.
   * 
   * @param {*} items 
   * @param {string} [uniqueKey=null] 
   * @memberof GridComponent
   */
  addToSelected(items: any, uniqueKey: string = null) {

    this.dataKey = uniqueKey;

    if (this.config.selectionMode === 0) {
      this.selected = [...this.selected, items];
    }
  }

  /**
   * Remove the item from the selected array if present
   * 
   * @param {*} item 
   * 
   * @memberof GridComponent
   */
  removeFromSelected(item: any, uniqueKey: string = null) {
    if (this.config.selectionMode === SelectionMode.Multi) {
      /* this.selected.forEach((selectedItem, index) => {
        if (selectedItem['id'] === item['id']) {
          this.selected.splice(index, 1);
        }
      }); */

      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i]['id'] === item['id']) {
          this.selected.splice(i, 1);
        }
      }
    }
  }

  /**
   * Handler for rowSelect Event
   * 
   * @param {any} $event 
   * @memberof GridComponent
   */
  rowSelectHandler($event) {
    this.addToSelected($event.data);
    this.eSelectionChange.emit(this.selected);
    // this.changeDetectorRef.detectChanges();
    // this.detectChanges();
  }

  /**
   * Handler for RowUnselect event
   * 
   * @param {any} $event 
   * @memberof GridComponent
   */
  rowUnselectHandler($event) {
    // this.removeFromSelected($event.data);
    this.eEmitDeselectedRow.emit($event.data)
    this.eSelectionChange.emit(this.selected);
    // this.changeDetectorRef.detectChanges();
    // this.detectChanges();
  }


  setColumns() {
    // this.detectChanges();
    for (let i = 0; i < this.totalColumns.length; i++) {
      this.filterValue = [];
      for (let j = 0; j < this.selectedColumn.length; j++) {
        if (this.totalColumns[i].header === this.selectedColumn[j]) {
          this.filterValue.push(i);
        }
      }
      if (this.filterValue.length === 0) {
        this.totalColumns[i].hidden = true;
      } else {
        this.totalColumns[i].hidden = false;
      }
    }

    this.columnsToDisplay = this.columns.filter((col) => {
      if (col.hidden === false) {
        return col;
      }
    });

    if (this.gridColumnMap) {
      // this.detectChanges();
    }

  }

  setColumnsToDisplay() {
    this.setColumns();
  }



  showWarn() {
    this.msgs = [];
    this.msgs.push({ severity: 'info', summary: 'Warn Message', detail: 'This hoarding is selected via group. Unselect it via group' });
  }

  /**
    * Handler for header checkbox toggle
    * Selects all rows if the checkbox is checked, else deselects all rows
    * 
    * @param {any} $event 
    * @memberof GridComponent
    */
  headerCheckboxToggleHandler($event) {
    // add all rows to selection
    if ($event.checked === true) {
      // this.selectAllRows();
      this.selectAllRowsOnPage();
    } else {
      // this.deselectAllRows();
      this.deselectAllRowsOnPage();
    }
  }


  selectAllRowsOnPage() {
    this.selected.forEach((sel) => {
      let found = false;
      this.previousSeleceted.forEach((pre) => {
        let _sel = _.cloneDeep(sel);
        let _pre = _.cloneDeep(pre);
        if (this.comparisionId) {
          let splitArray = this.comparisionId.split('.');
          for (let i = 0; i < splitArray.length; i++) {
            _pre = _pre[splitArray[i]];
            _sel = _sel[splitArray[i]];
          }
          if (_pre === _sel) {
            found = true;
          }
        } else {
          if (pre["id"] === sel["id"]) {
            found = true;
          }
        }
      });
      if (!found) {
        this.previousSeleceted.push(sel);
      }
    });
    if (this.previousSeleceted.length !== this.selected.length) {
      this.selected = _.cloneDeep(this.previousSeleceted);
    }
    // this.changeDetectorRef.detectChanges();
    // this.detectChanges();
    this.eSelectionChange.emit(this.selected);
  }

  deselectAllRowsOnPage() {
    const foundIds = [];
    this.previousSeleceted.forEach((sel) => {
      let found = false;
      this.value.forEach((val) => {
        if (sel["id"] === val.id) {
          found = true;
        }
      });
      if (found) {
        foundIds.push(sel["id"]);
      }
    });
    foundIds.forEach((id) => {
      this.previousSeleceted = this.previousSeleceted.filter((sel) => {
        if (id !== sel["id"]) {
          return sel;
        }
      });
    });
    this.selected = _.cloneDeep(this.previousSeleceted);
    // this.changeDetectorRef.detectChanges();
    // this.detectChanges();
    this.eSelectionChange.emit(this.selected);
  }


  // selectAllRowsOnPage() {
  //   this.selected.forEach((sel) => {
  //     let found = false;
  //     this.previousSeleceted.forEach((pre) => {
  //       let _sel = _.cloneDeep(sel);
  //       let _pre = _.cloneDeep(pre);
  //       if (this.comparisionId) {
  //         let splitArray = this.comparisionId.split('.');
  //         for (let i = 0; i < splitArray.length; i++) {
  //           _pre = _pre[splitArray[i]];
  //           _sel = _sel[splitArray[i]];
  //         }
  //         if (_pre === _sel) {
  //           found = true;
  //         }
  //       } else {
  //         if (pre["id"] === sel["id"]) {
  //           found = true;
  //         }
  //       }
  //     });
  //     if (!found) {
  //       this.previousSeleceted.push(sel);
  //     }
  //   });
  //   if (this.previousSeleceted.length !== this.selected.length) {
  //     this.selected = _.cloneDeep(this.previousSeleceted);
  //   }
  //   this.changeDetectorRef.detectChanges();
  //   this.eSelectionChange.emit(this.selected);
  // }

  // deselectAllRowsOnPage() {
  //   const foundIds = [];
  //   this.previousSeleceted.forEach((sel) => {
  //     let found = false;
  //     this.value.forEach((val) => {
  //       if (sel["id"] === val.id) {
  //         found = true;
  //       }
  //     });
  //     if (found) {
  //       foundIds.push(sel["id"]);
  //     }
  //   });
  //   foundIds.forEach((id) => {
  //     this.previousSeleceted = this.previousSeleceted.filter((sel) => {
  //       if (id !== sel["id"]) {
  //         return sel;
  //       }
  //     });
  //   });
  //   this.selected = _.cloneDeep(this.previousSeleceted);
  //   this.changeDetectorRef.detectChanges();
  //   this.eSelectionChange.emit(this.selected);
  // }

  /**
   * Select all rows 
   * 
   * @memberof GridComponent
   */
  selectAllRows() {
    this.selected = this.value;
    // this.changeDetectorRef.detectChanges();
    // this.detectChanges();
    this.eSelectionChange.emit(this.selected);
  }

  /**
   * Deselect all rows
   * 
   * @memberof GridComponent
   */
  deselectAllRows() {
    this.selected = [];
    this.previousSeleceted = [];
    // this.changeDetectorRef.detectChanges();
    // this.detectChanges();
    this.eSelectionChange.emit(this.selected);
  }

  /**
 * Returns true if single select grid
 * 
 * @returns {boolean} 
 * @memberof GridComponent
 */
  isSingleSelection(): boolean {
    return this.config.selectionMode === SelectionMode.Single;
  }

  /**
   * Returns true if multi select grid
   * 
   * @returns {boolean} 
   * @memberof GridComponent
   */
  isMultiSelection(): boolean {
    return this.config.selectionMode === SelectionMode.Multi;
  }

  rowDoubleClickHandler(event) {

  }

  rowCollapseHandler(event) {

  }

  editInitHandler(event) {

  }

  editHandler(event) {

  }
  editCompleteHandler(event) {

  }

  onEditChange($event, rowData, rowIndex, col, column) {
    // if (!isNaN($event.target.value)) {
    this.eRowEdit.emit(rowData);
    this.eRowEditWithIndex.emit({ rowData, rowIndex });
    this.eRowEditWithCol.emit({ $event, rowData, column });
    this.eRowEditGridChange.emit({ rowData, rowIndex, col, column });
    // } else {
    // $event.preventDefault();
    // return false;
    // }

  }

  onEditNumberChange($event, rowData, rowIndex, col, column) {
    let patternMatch = false;
    // let zeroMatch = false;
    if (this.decimalNumbers.test($event.target.value)) {
      this.patternMatch = true;
      if (!this.twoPlaceDecimalNumbers.test($event.target.value)) {
        const parsedInputArray = $event.target.value.split('.');
        let fractionalPart = parsedInputArray[1];
        fractionalPart = String(fractionalPart).substr(0, 2);
        parsedInputArray[1] = fractionalPart;
        $event.target.value = parsedInputArray[0] + '.' + parsedInputArray[1];
      }
    } else {
      this.patternMatch = false;
    }
    // if (column.field === "rate" || column.field === "cost") {
    //   zeroMatch = this.zero.test($event.target.value);
    //   if (this.patternMatch && zeroMatch) {
    //     this.patternMatch = false;
    //   }
    // }
    patternMatch = this.patternMatch;
    this.eRowEditNumberWithCol.emit({ rowData, column, patternMatch });
    this.eRowEditNumberWithIndex.emit({ rowData, rowIndex });
    this.eRowEditNumberWithIndexAndCol.emit({ rowData, rowIndex, column });
    this.eRowEditNumberGridChange.emit({ rowData, rowIndex, col, column });
  }


  onCalenderChange($event, rowData, rowIndex, col?, column?) {
    console.log("event is", $event, $event.type);
    this.eCalenderChange.emit({ rowData, rowIndex });
    this.eCalenderGridChange.emit({ rowData, rowIndex, col, column });
  }

  blur($event, rowData, rowIndex, col, column) {
    if (!this.campaignBlurEvent) {
      this.onCalenderChange($event, rowData, rowIndex, col, column);
    }
  }

  selectSitesAvailableFrom(event) {
    if (event) {
      Object.assign(this.lazyLoadData.filters, { 'campaignEndDate': { value: event, matchMode: 'availableFrom' } });
      Object.assign(this.paginationData.filters, { 'campaignEndDate': { value: event, matchMode: 'availableFrom' } });
    } else {
      utils.removeByKeyInObject(this.lazyLoadData.filters, 'campaignEndDate');
      utils.removeByKeyInObject(this.paginationData.filters, 'campaignEndDate')
    }

    this.loadData(this.paginationData, this.lazyLoadData);
    // this.eSitesAvailableFrom.emit(event);
  }

  /* inputSitesAvailableFrom(event) {
    if (!event || event.data === null) {
      Object.assign(this.lazyLoadData.filters, { 'campaignEndDate': { value: null, matchMode: 'availableFrom' } });
      Object.assign(this.paginationData.filters, { 'campaignEndDate': { value: null, matchMode: 'availableFrom' } });
      this.loadData(this.paginationData, this.lazyLoadData);
    }
  } */

  dataTableOnBlur(event: any, col: any, rowData: any, dt: any) {
    let evt: any = {
      keyCode: 13, target: event.target
    }
    alert('Hello');
    // dt.onCellEditorKeydown(evt, col, rowData, rowIndex);
  }

  colorCode(status) {
    if (status === 'AVAILABLE') {
      return 'green'
    } else {
      return 'black'
    }
  }

  //called to filter data from array based on the comparator
  filterData(totalRecords, values, comparator) {
    this.value = this.filterRecords(totalRecords, values, comparator);
    // this.detectChanges();
  }

  filterRecords(data, values, comparator) {
    let response: any[] = [];
    let push: boolean = false;
    // for (let i = 0; i < data.length; i++) {
    //   for (let j = 0; j < values.length; j++) {
    //     if (data[i][comparator] === values[j][comparator]) {
    //       push = false;
    //       break;
    //     } else {
    //       push = true;
    //     }
    //   }
    //   if (push === true) {
    //     response.push(data[i]);
    //   }
    // }
    // return response;
    return data.filter((datum) => values.findIndex((value) => value[comparator] === datum[comparator]) === -1);
  }

  pushData(data) {
    this.value = data;
  }

  resetDataTable() {
    this.dataTable._sortField = null;
    this.dataTable._sortOrder = 1;

    this.dataTable.filteredValue = null;
    this.dataTable.filters = {};
    // this.dataTable.reset();
  }

  onClickCalendar(event, rowData, rowIndex) {
    this.disabledDates = [];
    if (!this.rowMap.get(rowIndex)) {
      let row: any;
      row = _.cloneDeep(rowData);
      this.rowMap.set(rowIndex, row);
    }
    this.setDisabledDates(this.rowMap.get(rowIndex));
  }

  setDisabledDates(row) {
    for (let d = new Date(this.minDate); d < new Date(row.itemStartDate); d.setDate(d.getDate() + 1)) {
      this.disabledDates.push(new Date(d));
    }
    for (let d = new Date(this.maxDate); d > new Date(row.itemEndDate); d.setDate(d.getDate() - 1)) {
      this.disabledDates.push(new Date(d));
    }
    this.disabledDates = _.cloneDeep(this.disabledDates);
  }

  setMinDateOnClickCalendar(event, rowData, rowIndex, column) {
    if (column.field === "itemEndDate") {
      this.minDate = _.cloneDeep(new Date(rowData.itemStartDate));
    } else if (column.field === "itemStartDate") {
      this.minDate = new Date(rowData.campaignStartDate) <= new Date(this.currentDate) ? new Date(rowData.campaignStartDate) : new Date(this.currentDate);
    } else {
      this.minDate = null;
    }
  }

  setBothDatesOnClick(event, rowData, rowIndex, column) {
    // if (this.currentDateCheck !== false) {
    //   this.disabledDates = [];
    //   if (column.field === "itemEndDate") {
    //     this.minDate = new Date(rowData.itemStartDate) < new Date(this.currentDate) ? new Date(this.currentDate) : new Date(rowData.itemStartDate);
    //     this.maxDate = new Date(rowData.endDate);
    //     if (rowData.itemStatus === "MOUNTED") {
    //       this.minDate = new Date(this.currentDate) < new Date(rowData.endDate) ? new Date(this.currentDate) : new Date(rowData.endDate);
    //     }
    //   } else if (column.field === "itemStartDate") {
    //     this.minDate = new Date(rowData.startDate) > new Date(this.currentDate) ? new Date(rowData.startDate) : new Date(this.currentDate);
    //     this.maxDate = new Date(rowData.endDate);
    //     if (rowData.itemStatus === "MOUNTED") {  // date validation based on status
    //       this.disabledDates = [];
    //       for (const date = new Date(this.minDate); date <= new Date(this.maxDate); date.setDate(date.getDate() + 1)) {
    //         this.disabledDates.push(new Date(date));
    //       }
    //     }
    //   } else {
    //     this.minDate = null;
    //     this.maxDate = null;
    //   }
    // } else { // used in billings
    //   this.minDate = new Date(rowData.validationItemStartDate);
    //   this.maxDate = new Date(rowData.validationItemEndDate);
    // }

    if (this.currentDateCheck !== false) {
      this.disabledDates = [];
      if (column.field === "itemEndDate") {
        this.minDate = this.calculationService.setToBeginning(new Date(rowData.startDate)).valueOf() <= this.calculationService.setToBeginning(new Date(this.currentDate)).valueOf() ? new Date(this.currentDate) : new Date(rowData.startDate);
        this.maxDate = new Date(rowData.endDate);
        if (rowData.itemStatus === "MOUNTED") {
          this.minDate = new Date(this.calculationService.setToBeginning(this.currentDate)).valueOf() < new Date(this.calculationService.setToBeginning(rowData.endDate)).valueOf() ? new Date(this.currentDate) : new Date(rowData.endDate);
        }
      } else if (column.field === "itemStartDate") {
        this.minDate = this.calculationService.setToBeginning(new Date(rowData.startDate)).valueOf() >= this.calculationService.setToBeginning(new Date(this.currentDate)).valueOf() ? new Date(rowData.startDate) : new Date(this.currentDate);
        this.maxDate = new Date(rowData.endDate);
        if (rowData.itemStatus === "MOUNTED") {  // date validation based on status
          this.disabledDates = [];
          for (const date = new Date(this.minDate); date <= new Date(this.maxDate); date.setDate(date.getDate() + 1)) {
            this.disabledDates.push(new Date(date));
          }
        }
      } else {
        this.minDate = null;
        this.maxDate = null;
      }
      // handle the expiry case
      if (rowData.itemStatus === 'EXPIRED') {
        this.disabledDates = [];
        this.minDate = new Date(rowData.itemStartDate);
        this.maxDate = new Date(rowData.itemEndDate);
        for (const date = new Date(this.minDate); date <= new Date(this.maxDate); date.setDate(date.getDate() + 1)) {
          this.disabledDates.push(new Date(date));
        }
      } else {
        // do nothing
      }
    } else { // used in billings
      this.minDate = new Date(rowData.validationItemStartDate);
      this.maxDate = new Date(rowData.validationItemEndDate);
    }
  }

  onHideColumnFilter() {
    this.eEmitColumnSelection.emit(this.selectedColumn);
  }

  globalFiltering(data) {
    /* setTimeout(() => {
      this.lazyLoadHandler(this.createLazyLoadMetaData())
    }, 300); */
    this.globalFilterData.next(data);
  }

  createLazyLoadMetaData(data): Observable<any> {
    return of({
      first: 0,
      rows: 50,
      sortField: undefined,
      sortOrder: 1,
      globalFilter: data
    })
  }

  getModifiedColumns() {
    return this.modifiedColumn;
  }

  getStatus(status) {
    for (let i = 0; i < this.colorLegendsStatus.length; i++) {
      if (this.colorLegendsStatus[i].value === status) {
        return this.colorLegendsStatus[i].label;
      }
    }
  }

  removeStatus(i) {
    this.selectedStatus.splice(i, 1)
  }

  exportXls() {
    this.eEmitExportXlsx.emit(true);
  }


  exportJson() {
    this.eEmitExportJson.emit(true);
  }

  exportXml() {
    this.eEmitExportXml.emit(true);
  }

  exportTallyXls() {
    this.eEmitExportTallyXls.emit(true);
  }

  importXlsx() {
    this.eEmitImportXlsx.emit(true);
  }

  filterByStatuses(value) {
    if (value) {
      if (this.lazyLoadData) {
        Object.assign(this.lazyLoadData.filters, { status: { value: value, matchMode: 'in' } });
        Object.assign(this.paginationData.filters, { status: { value: value, matchMode: 'in' } });
        this.paginationData.page = 0;
        // this.loadData(this.paginationData, this.lazyLoadData);
      }
    } else {
      if (this.lazyLoadData) {
        delete this.lazyLoadData.filters.status;  // to delete the status key in filters
        // delete this.paginationData.filters.status;  // to delete the status key in filters
      }
    }
    this.loadData(this.paginationData, this.lazyLoadData);
  }

  lazyLoadedFilter(filterValue: any, field: string, matchMode: string) {
    this.dataTable.filter(filterValue, field, matchMode);
  }

  addToExistingSelected(data) {
    this.selected = [...this.selected, ...data]
  }

  globalFilterHoardingMaster(data) {
    var paginationData = {
      first: 0,
      // rows: 50,
      size: 50,
      sortField: undefined,
      sortOrder: 1,
      filters: {},
      globalFilter: data
    }

    var lazyLoadData = paginationData;

    this.paginationData = {
      page: this.calculatePageNumber(paginationData),
      size: paginationData.size,
      sortField: paginationData.sortField ? paginationData.sortField : "updatedDate",
      sortOrder: paginationData.sortOrder ? paginationData.sortOrder : 1,
      filters: paginationData.filters ? paginationData.filters : {},
      globalFilter: paginationData.globalFilter
    };


    this.loadData(paginationData, lazyLoadData);
  }

  setDateFormat(date) {
    return new Date(date);
  }

  getDataTableInstance() {
    return this.dataTable;
  }

  detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
      // this.changeDetectorRef.markForCheck();
    }
  }

  resetAndReloadTable() {
    this.dataTable.reset();
  }

  getDatatableWidth() {
    // const element = document.getElementById("data-table");
    // const style = element.style;
    // const width = style.width;
    // return width;
    const element = this.domHandler.findSingle(document.getElementById("data-table"), 'tr');
    const style = element.style;
    const width = style.width;
    return width;
  }

  getCommaSeparatedFields() {
    var fields;
    if (this.globalFilterFields && this.globalFilterFields.length > 0) {
      for (let i = 0; i < this.globalFilterFields.length; i++) {
        var fieldName = this.globalFilterFields[i]
        if (i === 0) {
          fields = fieldName
        } else {
          fields = fields + ', ' + fieldName;
        }
      }
    } else {
      fields = 'No Fields';
    }
    return fields;
  }

  onToggle(event) {
    this.eEmitToggleEvent.emit(event);
  }

  ngOnDestroy(): void {
    this.globalFilterData.unsubscribe();
    this.subscription.unsubscribe();
  }
}
