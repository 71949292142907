import { Injectable, Injector } from "@angular/core";
import * as utils from '../../../../helpers/utils';
import { Subject } from 'rxjs';
import { BASE_URL } from '../../../../services/base-service/api.service';
import { AppUrls } from '../../../../services/urls';
import { InvoiceChartService } from '../../../../services/shared/invoice-chart.service';

@Injectable()
export class TasksUIService {
    baseUrl: string;

    constructor(
        private injector: Injector,
        private invoiceChartService: InvoiceChartService
    ) {
        this.baseUrl = this.injector.get(BASE_URL);
    }

    setTasksTabIndex(tabIndex) {
        localStorage.setItem('tasksTabIndex', tabIndex);
    }

    getTasksTabIndex() {
        return localStorage.getItem('tasksTabIndex');
    }

    checkPermission(permission) {
        return utils.checkPermission(permission);
    }

    getInvoiceTaskCards() {
        return this.invoiceChartService.get(null, AppUrls.TASK_CARDS);
    }
}
