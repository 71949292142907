import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as utils from '../helpers/utils';
import { DeviceCategoryEnum } from '../shared/constants/device-category.enum';
import { SelectItem } from 'primeng/primeng';
import { ModulesEnum } from '../shared/constants/support-modules-enum';
import { SuggestionService } from './suggestion.service';
import { NotificatoinsService } from '../services/notifications/notifications.service';
import { Suggestion } from '../modals/master/suggestion';
@Component({
  selector: 'sib-suggestion-dialog',
  templateUrl: './suggestion-dialog.component.html',
  styleUrls: ['./suggestion-dialog.component.css']
})
export class SuggestionDialogComponent implements OnInit {

  @Input() showSuggestionDialog: boolean;
  @Output() emitCloseSuggestionDialog: EventEmitter<any> = new EventEmitter();

  suggestion: Suggestion = new Suggestion();
  moduleDropDown: SelectItem[] = [];
  deviceDropDown: SelectItem[] = [];


  constructor(private suggestionService: SuggestionService,
    private notificationServcie: NotificatoinsService,
  ) {

  }

  ngOnInit() {
    this.setDropdowns();
  }

  setDropdowns() {
    this.setModuleDropdown();
    this.setDeviceTypeDropdown();
  }

  setModuleDropdown() {
    this.moduleDropDown = utils.createDropdown(ModulesEnum, false);

  }

  setDeviceTypeDropdown() {
    this.deviceDropDown = utils.createDropdown(DeviceCategoryEnum, false);
  }

  onShow() {
    this.suggestion = new Suggestion();
  }

  onHide() {
    this.cancelRequest();
  }

  sendRequest() {
    this.suggestionService.createSuggestion(this.suggestion).subscribe(
      (response) => {
        if (response) {
          this.notificationServcie.success("Suggestion Send Successfully", "Suggestion Request");
          this.cancelRequest();
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');

      }
    );
  }

  cancelRequest() {
    this.showSuggestionDialog = false;
    this.emitCloseSuggestionDialog.emit();
  }
}
