import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, ElementRef } from "@angular/core";
import { Correspondence } from "../../../modals/correspondence/conrrespondence";
import { SelectItem, AutoComplete } from "primeng/primeng";
import * as utils from '../../../helpers/utils';
import { UserGridConfigService } from "../../../services/shared/user-grid-config.service";
import { GridNameEnum } from "../../../shared/constants/grid-name.enum";
import { PointOfContact } from '../../../modals/KYC/pointofcontact';
import { Tenant } from '../../../modals/master/tenant';
import { VendorService } from '../../../services/shared/vendor.service';

@Component({
  selector: 'sib-email',
  templateUrl: './sib-email.component.html',
  styleUrls: ['./sib-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SibEmailComponent implements OnInit {

  @Input() display = false;
  @Input() source: string = null;
  @Input() correspondence: Correspondence = new Correspondence();
  @Input() columns: any[] = [];
  @Input() plan: any;
  @Input() selectedBydefault: any[] = [];
  @Input() kioskPresent: boolean;
  @Output() displayReset: EventEmitter<boolean> = new EventEmitter();


  totalconfigItems: any[] = [];
  pocs: string[] = [];
  selectedTo: any[] = [];
  verifiedPocs: PointOfContact[] = [];
  filterpoc: PointOfContact[] = [];
  @ViewChild('emailForm')
  form;

  cc = false;
  bcc = false;
  invalid = false; // true if email id is incorrect
  email: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  selectedColumns: any[] = [];
  modifiedColumns: SelectItem[] = [];
  changeColumn: any[] = [];
  excludeColumns: any[] = ['pendingReason', 'image', 'minimumPrice', 'status', 'mountPrice', 'printPrice', 'statusQueues.employee'];
  tenantId: string;
  vendorName: string;
  isLoading: boolean = false;
  constructor(
    private userGridConfigService: UserGridConfigService,
    private vendorService: VendorService

  ) { }

  ngOnInit() {

    this.setMailSubject();
    if (this.correspondence.cc.length > 0) {
      this.cc = true;
    }
    if (this.correspondence.bcc.length > 0) {
      this.bcc = true;
    }
    this.userGridConfigService.get(null, '/' + GridNameEnum['PLAN_ITEMS'])
      .subscribe((xlsColumnConfig) => {
        if (xlsColumnConfig) {
          this.selectedColumns = utils.getXlsColumnConfig(xlsColumnConfig, this.selectedBydefault);
          this.setColumns();
          this.updatingUserGridConfig();

        } else {
          this.setDefaultColumns()
        }

      });
    this.correspondence.to = [];

    this.getVerifiedPocs();



  }

  //get the company configuration detail and set the name of the company as the  mail subject
  setMailSubject() {
    this.isLoading = true;
    this.tenantId = localStorage.getItem('id');
    this.vendorService.get(null, this.tenantId).subscribe((response) => {
      if (response) {
        this.vendorName = response['name'];
        this.correspondence.subject = this.vendorName + ' - ' + this.correspondence.subject;
        this.isLoading = false;
      }
    }),
      (error) => {
        this.correspondence.subject = 'CHITRA' + ' - ' + this.correspondence.subject;
        this.isLoading = false;
      }

  }
  //get the entered email id in the send TO field
  getIdMailTo(event: KeyboardEvent) {
    if (event.key == "Enter") {
      let tokenInput = event.srcElement as any;
      if (tokenInput.value) {
        if (this.email.test(tokenInput.value)) {
          if (!this.correspondence.to.includes(tokenInput.value)) {
            this.correspondence.to.push(tokenInput.value);
          } else {
            // do nothing
          }
          this.invalid = false;
        } else {

          this.invalid = true;
        }

        tokenInput.value = "";
      }
    }
  }
  //add the email id on blur and on pressing the tab button
  onBlur(event) {
    if (event.target.value) {
      if (this.email.test(event.target.value)) {
        if (!this.correspondence.to.includes(event.target.value)) {
          this.correspondence.to.push(event.target.value);
        } else {
          // do nothing
        }

        this.invalid = false;
      } else {

        this.invalid = true;
      }
      event.target.value = "";
    }

  }

  //get the list of pocs with verified emails 
  getVerifiedPocs() {
    var poclist = this.plan.customer.pocs;
    for (var i = 0; i < poclist.length; i++) {
      if (poclist[i].emailVerified === true) {
        this.verifiedPocs.push(poclist[i]);
      }
    }
    if (this.verifiedPocs.length === 1) {
      this.correspondence.to.push(this.verifiedPocs[0].emailId);
    }

  }

  //to filter the poc to send the mail 
  filterToSend(event) {
    this.pocs = [];
    // this.pocs = utils.filterDataMulti(this.verifiedPocs, ['fullName', 'emailId'], event);
    this.filterpoc = utils.filterDataMulti(this.verifiedPocs, ['fullName', 'emailId'], event);
    this.filterpoc.forEach((item, index) => {
      this.pocs.push(item.emailId);
    })


  }

  toggleCc() {
    this.cc = !this.cc;
  }

  toggleBcc() {
    this.bcc = !this.bcc;
  }

  /**
   *to validate the email address
   *
   * @param {*} name
   * @memberof SibEmailComponent
   */
  validate(name) {
    this.correspondence[name].forEach((item, index) => {
      if (!this.email.test(item)) {
        this.correspondence[name].splice(index, 1);
        this.invalid = true;
      } else {
        this.invalid = false;
      }
    });
  }

  //to set the correspondence for the To field in email 
  setCorrespondence() {
    this.correspondence.to = [];
    this.selectedTo.forEach((item, index) => {
      this.correspondence.to[index] = item.emailId;

    });

  }

  /**
  *to check if form is valid
  *
  * @memberof SibEmailComponent
  */
  isValid() {
    if (this.form.valid && this.correspondence.to.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *to set timer for displaying the invalid message
   *
   * @memberof SibEmailComponent
   */
  setTimer() {
    if (this.invalid) {
      setTimeout(() => {
        this.invalid = false;
      }, 3000);
    }
  }

  updatingUserGridConfig() {
    var planXlsConfig: Map<string, string> = new Map();

    planXlsConfig = utils.getXlsConfigMap(this.selectedColumns, this.changeColumn);
    this.userGridConfigService.patch(utils.buildObject(planXlsConfig), null, GridNameEnum['PLAN_XLSX_EMAIL'])
      .subscribe((result) => {
      })
  }

  setDefaultColumns() {
    this.modifiedColumns = [];
    for (let i = 0; i < this.columns.length; i++) {
      this.modifiedColumns.push({ label: this.columns[i].header, value: this.columns[i].header });
      if (this.columns[i].permanent || this.columns[i].default) {
        this.selectedColumns.push(this.columns[i].header)
      }
    }
  }

  setColumns() {
    console.log(this.kioskPresent, "present")
    this.modifiedColumns = [];
    this.changeColumn = [];
    if (this.kioskPresent) {

      this.excludeColumns.push('kioskType', 'quantity');
      console.log(this.excludeColumns, "columns to exclude");
    }
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].header && this.excludeColumns.indexOf(this.columns[i].field) < 0) {
        this.changeColumn.push(this.columns[i]);
        this.modifiedColumns.push({ label: this.columns[i].header, value: this.columns[i].header })
      }

    }
  }

}
