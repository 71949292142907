import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Area } from "../../modals/Area/area";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { map } from 'rxjs/operators';
// import { map } from 'rxjs-compat/operator/map';

@Injectable()
export class MigratedInvoiceService extends ApiService<any, any, any> {
    urlPath: string = 'migrated/invoices';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getMigratedInvoice(id) {
        // return this.Http.get(this.baseUrl + 'invoices/' + id + '?projection=invoiceProjection');
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'migrated/invoices/byId', { params: { id: decodeURIComponent(id) } }).pipe(
                map((response: any) => response ? response.data : response)
            );
        }

    }
}