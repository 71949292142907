import { UserWrapper } from '../user/user-wrapper';
import { SibBaseObject } from '../BaseObject/SibBaseObject';
import { DeviceCategoryEnum } from '../../shared/constants/device-category.enum';
import { ModulesEnum } from '../../shared/constants/support-modules-enum';


export  class Suggestion extends SibBaseObject{
     module : ModulesEnum;
     suggestion :string;
     suggestedBy : UserWrapper = new UserWrapper() ;
     deviceType: DeviceCategoryEnum;
}