
import { map, concat, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { empty as observableEmpty } from 'rxjs';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { GridConfig, GridColumn, EditableGridFieldTypes, SelectionMode, GridPaginationEvent } from '../../components/sib-forms/grid/grid.config';
import { CampaignItem } from '../../modals/campaigns/campaignItem';
import { FormGroup, FormControl } from '@angular/forms';
import { PublicPageService } from '../../services/shared/publicpage.service';
import { ActivatedRoute, Params } from '@angular/router';
import { DateUtil } from '../../helpers/date.util';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { of as observableOf, Observable, Subscription, Subject } from 'rxjs';
import * as _ from "lodash";
import * as utils from '../../helpers/utils';
import { CampaignService } from '../../services/shared/campaign.service';
import { PaginationData } from '../../modals/paginationdata';

@Component({
  selector: 'sib-totalsites',
  templateUrl: './totalsites.component.html',
  styleUrls: ['./totalsites.component.scss'],
  encapsulation: ViewEncapsulation.None
  // providers: [PublicPageService]
})
export class TotalsitesComponent implements OnInit {
  @ViewChild('sibGrid')
  gridViewChildRef: GridComponent<CampaignItem>;

  filteredItems: CampaignItem[] = [];
  lightTypeDropdown: any[] = [];
  subscription: Subscription;
  filteredCampaignItems: CampaignItem[] = [];

  _campaignItems: CampaignItem[] = [];
  @Input() set campaignItems(campaignItems) {
    this._campaignItems = campaignItems;
    this.filteredItems = _.cloneDeep(this._campaignItems);


    this.gridViewChildRef.refresh();
  }

  get campaignItems() {
    return this._campaignItems;
  }

  gridConfig: GridConfig<CampaignItem> = new GridConfig<CampaignItem>();

  totalRecords: number;
  totalItemsGroup: FormGroup;
  campaignId: string;
  x_tenant: string;
  @Output() eEmitTotalCampaignItems: EventEmitter<number> = new EventEmitter();
  @Output() eEmitFilteredCampaignItems: EventEmitter<CampaignItem[]> = new EventEmitter();
  @Output() eEmitImages: EventEmitter<any> = new EventEmitter();

  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    // sortField: '',
    // sortOrder: 1,
    multiSortMetas: []
  };

  columns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr ',
      required: true,
      width: '2vw',
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },

    {
      field: 'customId',
      name: 'customId',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '4vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'city',
      name: 'city',
      header: 'City',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'location',
      name: 'locatoin',
      header: 'Location',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '14vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'size',
      name: 'size',
      header: 'Size',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '4vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'lightType',
      name: 'lightType',
      header: 'Light',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '3vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },
      displayFn: (data) => {
        if (data.lightType) {
          return HoardingLightEnum[data.lightType]
        } else {
          return '-';
        }
      }

    },
    {
      field: 'itemStartDate',
      name: 'itemStartDate',
      header: 'Start Date',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemStartDate) {
          return DateUtil.dategridFormatter(data.itemStartDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'itemEndDate',
      name: 'itemEndDate',
      header: 'End Date',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.itemEndDate) {
          return DateUtil.dategridFormatter(data.itemEndDate);
        } else {
          return '-';
        }
      },
    },
    // {
    //   field: 'image',
    //   name: 'image',
    //   header: 'Photos',
    //   required: true,
    //   editable: false,
    //   hidden: false,
    //   permanent: false,
    //   width: '5vw',
    //   sortable: true,
    //   type: EditableGridFieldTypes.CUSTOM,
    // },
  ]


  constructor(
    private _route: ActivatedRoute,
    private campaignService: CampaignService
  ) {
    this.subscription = this.campaignService.$search
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.filteredItems = [];
        this.filteredCampaignItems = [];
        if (searchTerm) {
          searchTerm = searchTerm.toLowerCase();
          this.campaignItems.forEach((item) => {
            if (item.customId.toLowerCase().includes(searchTerm) || item.city.toLowerCase().includes(searchTerm) || item.location.toLowerCase().includes(searchTerm)) {
              this.filteredItems.push(item);
            }
          })
        } else {
          this.filteredItems = _.cloneDeep(this.campaignItems);
        }

        this.filteredCampaignItems = _.cloneDeep(this.filteredItems);
        this.eEmitTotalCampaignItems.emit(this.filteredItems.length);
        this.gridViewChildRef.refresh();

      })
  }

  ngOnInit() {
    this.totalItemsGroup = new FormGroup({
      gridControl: new FormControl()
    });
    this.setLightDropdown();
    this.setGridConfigObject();

  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new CampaignItem();
    this.gridConfig.model = CampaignItem;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = true;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = false;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
    this.gridConfig.sortMode = "multiple";
    this.gridConfig.multiSortMeta = this.createMetaSortData();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      this.totalRecords = this.filteredItems.length;
      return observableOf(this.filteredItems);
    }
  }

  onFilter(event) {
    this.filteredCampaignItems = event;
    this.eEmitFilteredCampaignItems.emit(event);
  }

  getCustomIds() {
    this.campaignItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignItems);
    const customIds: string[] = [];
    if (this.filteredCampaignItems.length) {
      this.filteredCampaignItems.forEach((item) => {
        customIds.push(item.customId);
      });
    } else {
      this.campaignItems.forEach((item) => {
        customIds.push(item.customId);
      });
    }
    return customIds;
  }


  /**
   * to get the ids of items
   *
   * @returns
   * @memberof TotalsitesComponent
   */
  getIds() {
    this.campaignItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignItems);
    const ids: string[] = [];
    if (this.filteredCampaignItems.length && this.filteredCampaignItems.length !== this.campaignItems.length) {
      this.filteredCampaignItems.forEach((item) => {
        ids.push(item.id);
      });
    } else {
      this.campaignItems.forEach((item) => {
        ids.push(item.id);
      });
    }
    return ids;
  }

  setLightDropdown() {
    this.lightTypeDropdown = utils.createDropdown(HoardingLightEnum, true);
  }

  createMetaSortData() {
    return [{ field: 'district', order: 1 }, { field: 'city', order: 1 }, { field: 'area.name', order: 1 }];
  }

  onSort(event) {
    if (this.filteredCampaignItems && this.filteredCampaignItems.length) {
      this.campaignItems = _.cloneDeep(this.filteredCampaignItems);
    } else {
      this.campaignItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    }
    // this.eEmitFilteredCampaignItems.emit(this.campaignItems);
    this.searchEvent.multiSortMetas = Object.assign(this.searchEvent.multiSortMetas, event['multisortmeta']);
  }

  getCampaignSites() {
    this.campaignItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    if (this.filteredCampaignItems.length && this.filteredCampaignItems.length !== this.campaignItems.length) {
      return this.filteredCampaignItems;
    } else {
      return this.campaignItems;
    }
  }


  /**
 * emites images to public page component
 *
 * @param {*} rowData
 * @memberof MountedsitesComponent
 */
  viewImages(rowData) {
    this.eEmitImages.emit(rowData);
  }
}
