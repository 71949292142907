import { ViewEncapsulation, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'sib-adjust-superbill',
    templateUrl: './adjust-superbill.component.html',
    styleUrls: ['./adjust-superbill.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AdjustSuperbillComponent implements OnInit, OnDestroy {

    @Input() displayDialog = false;

    @Output() displayReset: EventEmitter<boolean> = new EventEmitter();

    ngOnInit() { }

    ngOnDestroy() { }
}
