<!-- <p-dataScroller [value]="cars" [rows]="5" [inline]="true" [lazy]="true" [styleClass]="'dataScrollerx'" (onLazyLoad)="loadData($event)"
  scrollHeight="500px">
  <p-header>
    Pending Hoardings
  </p-header>
</p-dataScroller> -->

<!-- 
<div class="ui-g datascroller-container">

  <div class="ui-md-3 ui-lg-3 datascroller">
    <p-dataScroller [value]="cars" [rows]="5" [inline]="true" [lazy]="false" [styleClass]="'dataScrollerx'" (onLazyLoad)="loadData($event)"
      scrollHeight="500px">
      <p-header>
        <ul class="scroller-header">
          <li class="scroller-header-content">
            Printing
          </li>
          <li class="search-item">
            <span class="md-inputfield">
              <input pinputtext type="text" class="ui-inputtext ui-corner-all ui-state-default ui-widget">
              <label>Search</label>
              <i class="topbar-icon material-icons">search</i>
            </span>
          </li>
        </ul>
      </p-header>
      <ng-template let-car pTemplate="item" let-i="index">
        <div class="hoarding-card-container">
          <div class="hoarding-card left-border-blue">
            <div class="ui-g row">
              <div class="ui-g-10 card-header">
                <span class="header-content">Parth Plan- PL</span>
              </div>
              <div class="ui-g-2">
                <a href="#">
                  <i class="material-icons tasksprite ellipsis-icon">more_vert</i>
                </a>
              </div>
            </div>
            <div class="hoarding-description">
              <span class="hoarding-description-content">AHM-141 Bodakdev CG road panchvati junction 1 (15X15)</span>
            </div>
            <div class="date">
              <span class=date-content>
                    <i class="material-icons">access_time</i>
                  <span>16 feb 18</span>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataScroller>
  </div>
  <div class="ui-md-3 ui-lg-3 datascroller">
    <p-dataScroller [value]="cars" [rows]="5" [inline]="true" [lazy]="true" [styleClass]="'dataScrollerx'" (onLazyLoad)="loadData($event)"
      scrollHeight="500px">
      <p-header>
        <ul class="scroller-header">
          <li class="scroller-header-content">
            Flex
          </li>
          <li class="search-item">
            <span class="md-inputfield">
                  <input pinputtext type="text" class="ui-inputtext ui-corner-all ui-state-default ui-widget">
                  <label>Search</label>
                  <i class="topbar-icon material-icons">search</i>
                </span>
          </li>
        </ul>
      </p-header>
      <ng-template let-car pTemplate="item">
        <div class="hoarding-card-container">
          <div class="hoarding-card left-border-orange">
            <div class="ui-g row">
              <div class="ui-g-10 card-header">
                <span class="header-content">Parth Plan- PL</span>
              </div>
              <div class="ui-g-2">
                <a href="#">
                  <i class="material-icons tasksprite ellipsis-icon">more_vert</i>
                </a>
              </div>
            </div>
            <div class="hoarding-description">
              <span class="hoarding-description-content">AHM-141 Bodakdev CG road panchvati junction 1 (15X15)</span>
            </div>
            <div class="date">
              <span class=date-content>
                    <i class="material-icons">access_time</i>
                  <span>16 feb 18</span>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataScroller>
  </div>
  <div class="ui-md-3 ui-lg-3 datascroller">
    <p-dataScroller [value]="cars" [rows]="5" [inline]="true" [lazy]="true" [styleClass]="'dataScrollerx'" (onLazyLoad)="loadData($event)"
      scrollHeight="500px">
      <p-header>
        <ul class="scroller-header">
          <li class="scroller-header-content">
            Mounting
          </li>
          <li class="search-item">
            <span class="md-inputfield">
                  <input pinputtext type="text" class="ui-inputtext ui-corner-all ui-state-default ui-widget">
                  <label>Search</label>
                  <i class="topbar-icon material-icons">search</i>
                </span>
          </li>
        </ul>
      </p-header>
      <ng-template let-car pTemplate="item">
        <div class="hoarding-card-container">
          <div class="hoarding-card left-border-yellow">
            <div class="ui-g row">
              <div class="ui-g-10 card-header">
                <span class="header-content">Parth Plan- PL</span>
              </div>
              <div class="ui-g-2">
                <a href="#">
                  <i class="material-icons tasksprite ellipsis-icon">more_vert</i>
                </a>
              </div>
            </div>
            <div class="hoarding-description">
              <span class="hoarding-description-content">AHM-141 Bodakdev CG road panchvati junction 1 (15X15)</span>
            </div>
            <div class="date">
              <span class=date-content>
                    <i class="material-icons">access_time</i>
                  <span>16 feb 18</span>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataScroller>
  </div>
  <div class="ui-md-3 ui-lg-3 datascroller">
    <p-dataScroller [value]="cars" [rows]="5" [inline]="true" [lazy]="true" [styleClass]="'dataScrollerx'" (onLazyLoad)="loadData($event)"
      scrollHeight="500px">
      <p-header>
        <ul class="scroller-header">
          <li class="scroller-header-content">
            Unmounted
          </li>
          <li class="search-item">
            <span class="md-inputfield">
                  <input pinputtext type="text" class="ui-inputtext ui-corner-all ui-state-default ui-widget">
                  <label>Search</label>
                  <i class="topbar-icon material-icons">search</i>
                </span>
          </li>
        </ul>
      </p-header>
      <ng-template let-car pTemplate="item">
        <div class="hoarding-card-container">
          <div class="hoarding-card left-border-green">
            <div class="ui-g row">
              <div class="ui-g-10 card-header">
                <span class="header-content">Parth Plan- PL</span>
              </div>
              <div class="ui-g-2">
                <a href="#">
                  <i class="material-icons tasksprite ellipsis-icon">more_vert</i>
                </a>
              </div>
            </div>
            <div class="hoarding-description">
              <span class="hoarding-description-content">AHM-141 Bodakdev CG road panchvati junction 1 (15X15)</span>
            </div>
            <div class="date">
              <span class=date-content>
                Date: 16 feb 18
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataScroller>
  </div>
</div> -->

<!-- <div class="card-container">
  <div class="card" (click)="cardClick()">
    <div class="front">
      A
    </div>
    <div class="back">
      B
    </div>
  </div>
</div> -->

<!-- <div class="card-container">
  <div class="card" (click)="cardClick(1)">
    <div class="front">
      A
    </div>
    <div class="back">
      B
    </div>
  </div>
</div> -->

<!-- <div class="card-containers">
  <div class="cards" (click)="cardClick(1)">
    <div>
      <div class="ui-g">
        <span class="id">HID: AHM-141</span>
        <span>
          <a href="#">
              <i class="material-icons">more_vert</i>
            </a>
      </span>
      </div>
      <div class="ui-g">
        <img src="http://www.adfilmmakers.com/wp-content/uploads/2016/12/hoarding-designing-companies-in-hyderabad-ap.jpg" height="100px"
          width="200px">
      </div>
      <div class="ui-g location">
        <span>Location: Near SG Highway, Ahmedabad</span>
      </div>
      <div class="ui-g location">
        <span>Size: 15X15</span>
      </div>
      <div class="ui-g location">
        <span>Delivery Date: 30 Mar 18</span>
      </div>
    </div>
    <div class="back">
      B
    </div>
  </div>
</div>

<div class="hoarding-card-container">
  <div class="hoarding-card left-border-blue">
    <div class="ui-g row">
      <div class="ui-g-10 card-header">
        <span class="header-content">Parth Plan- PL</span>
      </div>
      <div class="ui-g-2">
        <a href="#">
          <i class="material-icons tasksprite ellipsis-icon">more_vert</i>
        </a>
      </div>
    </div>
    <div class="hoarding-description">
      <span class="hoarding-description-content">AHM-141 Bodakdev CG road panchvati junction 1 (15X15)</span>
    </div>
    <div class="date">
      <span class=date-content>
          <i class="material-icons">access_time</i>
        <span>16 feb 18</span>
      </span>
    </div>
  </div>
</div> -->

<sib-map [options]="options"></sib-map>

<!-- <p-gmap [options]="options" [style]="{'width':'100%','height':'500px'}" ></p-gmap>  -->