import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, GalleriaModule, ProgressSpinnerModule, OrderListModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { RowsRearrangeComponent } from './rows-rearrange.component';



@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        GalleriaModule,
        ProgressSpinnerModule,
        OrderListModule
    ],

    declarations: [
        RowsRearrangeComponent
    ],

    exports: [
        RowsRearrangeComponent
    ],
    providers: []
})

export class RowsRearrangeModule { }
