import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, TooltipModule, ProgressSpinnerModule, SpinnerModule } from "primeng/primeng";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";

import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { PreviewComponent } from './preview.component';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ReactiveFormsModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        SpinnerModule,
        SibGridModule,
    ],

    declarations: [
        PreviewComponent
    ],

    exports: [
        PreviewComponent
    ]
})

export class PreviewModule { }
