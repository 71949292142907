import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { AppUrls } from '../urls';
import { PrintWrapper } from '../../modals/billings/print-wrapper';
import { Observable } from 'rxjs';

@Injectable()
export class CreditNotesService extends ApiService<any, any, any> {
    urlPath: string = 'creditNotes';
    // baseUrl: string;
    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        // this.baseUrl = this.Injector.get(BASE_URL);
    }

    markCNAsPrinted(value, id) {
        return this.get({ 'id': id, 'printed': value }, AppUrls.MARK_PRINTED);
    }

    markCNAsTallyEntered(value, id, tallyId) {
        return this.get({ 'id': id, 'tallyEntered': value, 'tallyId': tallyId }, AppUrls.MARK_TALLY_ENTERED);
    }

    printCNWithLetterHead(id, isDuplicate) {
        return this.get({ 'id': id, 'type': isDuplicate ? 'DUPLICATE' : 'ORIGINAL' }, AppUrls.GENERATE_PDF + AppUrls.WITH_LETTERHEAD, "arraybuffer");
    }

    printCNWithoutLetterHead(id, isDuplicate) {
        return this.get({ 'id': id, 'type': isDuplicate ? 'DUPLICATE' : 'ORIGINAL' }, AppUrls.GENERATE_PDF + AppUrls.WITHOUT_LETTERHEAD, "arraybuffer");
    }

    printCn(printWrapper: PrintWrapper, id: string): Observable<any> {
        return this.create(printWrapper, { id: id }, AppUrls.GENERATE_PDF, null, "arraybuffer");
    }
}
