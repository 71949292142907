<div class="pendingtasks">
  <div>
    <form [formGroup]="actionTaskGroup">
      <sib-grid name="taskGrid" #sibGrid [buttons]="buttonConfig" [showFilter]="true" [showColorLegends]="false"
        [totalRecords]="totalRecords" (eEmitColumnSelection)="updatingUserGridConfig($event)" [columns]="taskColumns"
        [config]="gridConfig" formControlName="taskGridControl" ngDefaultControl>

        <sib-grid-column field="srNo">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <span>
              {{rowIndex+1}}
            </span>
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="moduleData.displayKey">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <a class="link-1  after" target="_blank"
              [routerLink]="['/campaigns/view/', rowData.moduleData.primaryKey]">{{rowData.moduleData.displayKey}}</a>
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="data.campaignDisplayName">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <a class="link-1  after" target="_blank" *ngIf="rowData.data.campaignDisplayName; else defaultTemplate"
              [routerLink]="['/campaigns/view/', rowData.data.itemCampaignId]">{{rowData.data.campaignDisplayName}}</a>
            <ng-template #defaultTemplate>
              -
            </ng-template>
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="data.statusQueues">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div *ngIf="rowData?.data?.statusQueues?.length > 1; else falsyTemplate">
              <a class="link-1  after" (click)="viewDetail(rowData)">View Detail</a>
            </div>
            <ng-template #falsyTemplate>
              {{rowData.data.statusQueues[0].status}}
            </ng-template>
          </ng-template>

        </sib-grid-column>

        <sib-grid-column field="approveReject">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div>
              <span class="padding-right-5">
                <!-- <i class="fa fa-circle custom-icon true-color cursor-pointer " title="Adjust"
                  (click)="adjustCampaignItem(rowData)"><span class="adjust-remove-icon right-14">A</span></i> -->
                <button type="submit" pButton class="mtab-save" label="Adjust Dates"
                  (click)="adjustCampaignItem(rowData)" style="padding: 3px 8px"></button>
              </span>
              <span>
                <!-- <i class="fa fa-circle custom-icon false-color cursor-pointer" title="Remove"
                  (click)="removeCampaignItem(rowData)"><span class="adjust-remove-icon  right-13">R</span></i> -->
                <button type="button" pButton class="mtab-danger" label="Drop Site"
                  (click)="removeCampaignItem(rowData)"></button>
              </span>
            </div>
          </ng-template>
        </sib-grid-column>
        <sib-grid-column field="data.lightType">
          <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
            <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
              appendTo="body" placeholder="Light"
              (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
            </p-dropdown>
          </ng-template>
        </sib-grid-column>

      </sib-grid>
    </form>
  </div>
  <sib-status-queue-dialog *ngIf="displayStatusQueueDialog" [displayStatusQueueDialog]="displayStatusQueueDialog"
    [rowData]="rowData" [values]="rowData?.data?.statusQueues"
    (eEmitChangeStatusQueueDialogDisplay)="hideStatusQueueDialog()"></sib-status-queue-dialog>

  <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd key="dropConflictItem">
    <p-footer>
      <button type="button" pButton class="mtab-save margin-hori-5" label="YES" (click)="cd.accept()"></button>
      <button type="button" pButton class="mtab-secondary" label="CANCEL" (click)="cd.reject()"></button>
    </p-footer>
  </p-confirmDialog>
</div>