<div class="photo-interface-container">
  <div class="ui-g">
    <ul class="c-global-list">
      <li class="float-left">
        <div class="header-text">
          Photo Interface
        </div>
      </li>
      <li class="float-right margin-top-10 margin-right-14">
        <div class="ui-widget-header global-search-container">
          <input #globalSearch type="text" pInputText size="50" placeholder="Global Filter"
            (keyup)="globalFiltering(globalSearch.value)" class="global-search-textbox"
            pTooltip="{{getCommaSeparatedFields()}}" tooltipPosition="left">
          <span class="search-wrapper">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </li>
    </ul>
  </div>

  <div>
    <ul class="c-tab-list">
      <li class="float-right tab-menu">
        <div>
          <p-tieredMenu #showMenu popup="popup" [model]="menu"></p-tieredMenu>
          <i class="fa fa-ellipsis-v more-icon more-icon-wrapper icon" (click)="showMenu.toggle($event)"
            aria-hidden="true" title="Menu"></i>
        </div>
      </li>
      <li class="float-right tab-menu padding-left-10">
        <div>
          <p-multiSelect [options]="filterOptions" defaultLabel="" [style]="{'width':'0px'}" maxSelectedLabels="0"
            name="name" title="Filters" [(ngModel)]="selectedFilters" (onChange)="onFilterChange()"
            [dropdownIcon]="'fa fa-filter'" appendTo="body" [displaySelectedLabel]="false"
            [styleClass]="'filter-multi-select'" [panelStyleClass]="'photo-multi-select-panel'">
          </p-multiSelect>
        </div>
      </li>
      <li class="float-right tab-menu" *ngFor="let filter of selectedFilters; let i = index">
        <div class="filter-background">
          <span>
            {{getFilterLable(filter)}}
          </span>
          <span>
            <i class="fa fa-times" (click)="removeFilter(i)"></i>
          </span>
        </div>
      </li>
      <li class="float-right tab-menu-download">
        <label class="download-label">Download</label>
        <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-15 font-button" title="Download"
          [disabled]="disableMenuDownload" (click)="downloadSelectedImages()" icon="fa fa-download"></button>
      </li>
      <li class="float-right tab-menu-selected-items">
        No. of images selected: {{getCurrentTabObject()?.selectedItemCount}}
      </li>
    </ul>
  </div>
  <p-tabView (onChange)="onTabChange($event.index)" [activeIndex]="activeIndex">
    <!-- <p-tabPanel header="All">
      <sib-all-images #allImages (eEmitSelectedAllImages)="setSelectedAllImages($event)" [activeIndex]="activeIndex"
        (eEmitImagesAndIndex)="onImageClick($event)"></sib-all-images>
    </p-tabPanel> -->
    <p-tabPanel header="Mounted">
      <sib-mounted-image #mountingImage (eEmitSelectedMountImages)="setSelectedMountImages($event)"
        [activeIndex]="activeIndex" (eEmitImagesAndIndex)="onImageClick($event)">
      </sib-mounted-image>
    </p-tabPanel>
    <p-tabPanel header="Monitoring">
      <sib-monitoring-image #monitoringImage (eEmitSelectedMonitoringImages)="setSelectedMonitoringImages($event)"
        [activeIndex]="activeIndex" (eEmitImagesAndIndex)="onImageClick($event)"></sib-monitoring-image>
    </p-tabPanel>
    <p-tabPanel header="Unmounted">
      <sib-unmount-image #unmountingImage (eEmitSelectedUnmountingImages)="setSelectedUnmountingImages($event)"
        [activeIndex]="activeIndex" (eEmitImagesAndIndex)="onImageClick($event)"></sib-unmount-image>
    </p-tabPanel>
    <!-- <p-tabPanel header="Inventory">
      <sib-inventory-image #inventoryImage (eEmitSelectedInventoryImages)="setSelectedInventoryImages($event)"
        [activeIndex]="activeIndex" (eEmitImagesAndIndex)="onImageClick($event)"></sib-inventory-image>
    </p-tabPanel> -->
  </p-tabView>

  <sib-photo-dialog #photoDialog (displayReset)="resetPhotoDialogDisplay()"></sib-photo-dialog>
</div>