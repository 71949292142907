
import { map } from 'rxjs/operators';
import { ApiService, BASE_URL } from "../base-service/api.service";
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions, Headers } from "@angular/http";

@Injectable()

export class HoardingService extends ApiService<any, any, any> {
    // urlPath: string = 'hoardings?projection=hoardingProjection';
    urlPath: string = 'hoardings/search';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    addHoardingsToContract(hoardings) {

        if (navigator.onLine) {
            let body = JSON.stringify(hoardings);
            let headers = {
                headers: new HttpHeaders()
            };
            return this.Http.post(this.baseUrl + 'hoardings/saveAll', body, headers);

        }
    }

    getHoardingsNotInContractId(contractId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'hoardings/search/byContractIdNotIn?contractId=' + contractId).pipe(
                map((res: Response) => { console.log("get hoardings not in contractid", res); return res; }));
        }

    }

    /**
     * to get the status for hoardings
     * 
     * @returns 
     * @memberof HoardingService
     */
    getStatus() {

        if (navigator.onLine) {
            const headers = {
                headers: new HttpHeaders()
            };
            return this.Http.get(this.baseUrl + 'hoardings/status');

        }
    }

    getHoardings() {

    }
}
