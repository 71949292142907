import { Http, Response, Headers } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { PaginationData } from "../../modals/paginationdata";


@Injectable()
export class UserGridConfigService extends ApiService<any, any, any> {
    // ?projection=customerListProjection
    urlPath: string = 'userProperties/gridConfig';
    baseUrl: string;


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
    }
}