import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { GridConfig, GridColumn, EditableGridFieldTypes, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { PendingUnbilled } from '../../../modals/billings/pending-unbilled';
import { GridActionButtonConfig } from '../../../components/sib-forms/grid/grid-action-button.config';
import { FormGroup, FormControl } from '@angular/forms';
import { DateUtil } from '../../../helpers/date.util';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import { GridNameEnum } from '../../constants/grid-name.enum';
import * as utils from '../../../helpers/utils';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { AppUrls } from '../../../services/urls';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BillingGridService } from '../../../billings/services/billing-grid.service';
import { LoaderSubjects } from '../../../modals/loader-subjects/loader-subjects';
import { PPTLoader } from '../../../modals/loader-subjects/pptloader';
import { ActivatedRoute } from '@angular/router';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';

@Component({
    selector: 'sib-pending-unbilled-tasks',
    templateUrl: './pending-unbilled-tasks.component.html',
    styleUrls: ['./pending-unbilled-tasks.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PendingUnbilledTaskComponent implements OnInit, OnDestroy {

    @ViewChild(GridComponent)
    gridViewChildRef: GridComponent<PendingUnbilled>;

    @Output() eEmitTotalPendingUnbilledRecords: EventEmitter<any> = new EventEmitter();

    isLoading = false;
    gridConfig: GridConfig<PendingUnbilled> = new GridConfig<PendingUnbilled>();
    buttonConfig: GridActionButtonConfig;
    pendingUnbilledTaskGroup: FormGroup;
    totalRecords = 100;
    pendingUnbilledTasks: PendingUnbilled[] = [];
    totalUnbilledAmount: number;
    filterCards: any[] = [];
    urldata: any;
    index: number;
    selectedFilter: {
        filters: any,
    };
    paginationFilter: any;
    gridPaginationFilterCopy: {
        filters: any;
        sortField: string;
        sortOrder: number;
    };
    xlsLoader: PPTLoader = new PPTLoader();
    pendingUnbilledTaskPaginationEvent: GridPaginationEvent;
    showEmployee: boolean = false;

    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2.7vw',
            editable: false,
            hidden: false,
            default: true,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'campaignCustomId',
            name: 'campaignCustomId',
            header: 'Campaign Id',
            required: true,
            editable: false,
            // hidden: false,
            width: '5vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.campaignCustomId) {
                    return data.campaignCustomId;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'employee',
            name: 'employee',
            header: 'Employee',
            required: true,
            editable: false,
            // hidden: false,
            width: '7vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: false,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.employee) {
                    return data.employee;
                } else {
                    return '-';
                }
            },
        },
        // {
        //     field: 'company',
        //     name: 'company',
        //     header: 'Company Name',
        //     required: true,
        //     editable: false,
        //     default: false,
        //     hidden: false,
        //     styleClass: 'word-break-wrap',
        //     width: '8vw',
        //     permanent: false,
        //     sortable: true,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        //     displayFn: (data) => {
        //         if (data.company) {
        //             return data.company;
        //         } else {
        //             return '-';
        //         }
        //     }
        // },
        {
            field: 'billingName',
            name: 'billingName',
            header: 'Billing Name',
            required: true,
            editable: false,
            default: true,
            hidden: false,
            styleClass: 'word-break-wrap',
            width: '8vw',
            permanent: true,
            sortable: true,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.billingName) {
                    return data.billingName;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'displayName',
            name: 'displayName',
            header: 'Display Name',
            required: true,
            editable: false,
            permanent: true,
            hidden: false,
            width: '12vw',
            styleClass: 'word-break-wrap',
            default: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'unbilledAmount',
            name: 'unbilledAmount',
            header: 'Unbilled Amount',
            required: true,
            editable: false,
            permanent: true,
            hidden: false,
            width: '6vw',
            styleClass: 'word-break-wrap',
            default: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.unbilledAmount !== undefined && data.unbilledAmount !== null) {
                    return this.currencyHelperPipe.transform(utils.getIntegerRoundedOffValue(data.unbilledAmount));
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'minDate',
            name: 'minDate',
            header: 'From',
            permanent: true,
            required: true,
            editable: false,
            width: '4vw',
            hidden: false,
            sortable: true,
            displayFn: (data) => {
                if (data.minDate) {
                    return DateUtil.dategridFormatter(data.minDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'maxDate',
            name: 'maxDate',
            header: 'To',
            required: true,
            width: '4vw',
            permanent: true,
            hidden: false,
            editable: false,
            sortable: true,
            displayFn: (data) => {
                if (data.maxDate) {
                    return DateUtil.dategridFormatter(data.maxDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'delayedDays',
            name: 'delayedDays',
            header: 'Delayed Days',
            required: true,
            editable: false,
            hidden: false,
            default: true,
            permanent: true,
            width: '4vw',
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data) {
                    return this.currencyHelperPipe.transform(data.delayedDays);
                } else {
                    return '-';
                }
            },
        },
    ];

    constructor(
        private route: ActivatedRoute,
        private notificationServcie: NotificatoinsService,
        private userGridConfigService: UserGridConfigService,
        private currencyHelperPipe: CurrencyHelperPipe,
        private billingGridService: BillingGridService,
        private loaderSubjects: LoaderSubjects,
        private hmColService: HoardingMasterColService,
    ) { }

    ngOnInit() {
        this.getParamsFromRoute();
        this.setEmployeeColumnVisibility(); // sets the visibility of the employee column
        this.setFilterCards();
        this.setFormGroupObjects();
        this.setUserGridConfig();
        this.setGridConfigObject();
        this.setColumns();
    }
    getParamsFromRoute() {
        this.route.params.subscribe((params => {
            if (params) {
                this.urldata = params['showmoreunbilled'];
            }
        }));
    }

    setFormGroupObjects() {
        this.pendingUnbilledTaskGroup = new FormGroup({
            pendingUnbilledTaskGridControl: new FormControl()
        });
    }

    setUserGridConfig() {
        this.userGridConfigService.get(null, '/' + GridNameEnum['PENDING_UNBILLED_TASKS'])
            .subscribe((pendingUnbilledGridConfig) => {
                this.columns = utils.getUserConfiguredColumns(pendingUnbilledGridConfig, this.columns);
                // this.gridViewChildRef.setAllColumns();
                // this.hmColService.defaultColumns.next(this.columns)
                this.gridViewChildRef.ngOnInit();
            });
    }

    setFilterCards() {
        this.filterCards = [
            { label: 'ALL', value: 'All' },
            { label: '30', value: '> 30 days' },
            { label: '60', value: '> 60 days' },
            { label: '90', value: '> 90 days' },
        ];
    }

    setGridConfigObject() {
        this.gridConfig.getDefaultModel = () => new PendingUnbilled();
        this.gridConfig.model = PendingUnbilled;
        this.gridConfig.editable = false;
        this.gridConfig.selectionMode = SelectionMode.None;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        this.gridConfig.scrollable = true;
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.rowExpandMode = 'single';
        this.gridConfig.lazy = true;
        this.gridConfig.enablePagination = true;
        this.gridConfig.showColorCode = false;

        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
            this.isLoading = true;
            this.setPaginationEvent(paginationEvent);
            return this.billingGridService.create(paginationEvent, paginationEvent, AppUrls.PENDING + AppUrls.UNBILLED).pipe(
                map((response) => {
                    this.pendingUnbilledTaskPaginationEvent = paginationEvent;
                    this.totalRecords = response['pendingTask']['totalElements'];
                    this.eEmitTotalPendingUnbilledRecords.emit(this.totalRecords);
                    this.pendingUnbilledTasks = response["pendingTask"]["content"];
                    this.totalUnbilledAmount = response['totalUnbilled'];
                    this.isLoading = false;
                    return this.pendingUnbilledTasks;
                }), catchError((error) => {
                    this.showErrorNotification(error);
                    this.isLoading = false;
                    return Observable.empty();
                }));
        };
    }

    setColumns() {
        this.columns.filter((col) => {
            switch (col.field) {
                case 'employee': {
                    col.permanent = !this.showEmployee;
                    col.hidden = !this.showEmployee;
                    break;
                }
            }
            return col;
        });
        if (this.gridViewChildRef) {
            this.gridViewChildRef.setAllColumns();
        }
    }

    checkPermission(permission) {
        return utils.checkPermission(permission);
    }

    check(card, index) {
        let result = false;
        if (this.index !== undefined && this.index === index) {
            result = true;
        }
        return result;
    }

    filterData(card, index) {
        this.index = index;
        this.selectedFilter = { filters: [] };
        this.selectedFilter.filters = card.label;
        this.setPaginationFilter();
    }

    setPaginationFilter() {
        this.paginationFilter = {};
        this.paginationFilter = { 'delayedDays': { value: this.selectedFilter.filters, matchMode: 'is' } };
        this.refreshGrid();
    }

    setPaginationEvent(paginationEvent) {
        if (paginationEvent.filters === undefined) {
            paginationEvent.filters = {};
        }
        if (this.urldata) {
            var filter = { 'company': { value: this.urldata, matchMode: "contains" } };
            Object.assign(paginationEvent.filters, filter);
        }

        delete paginationEvent.filters.delayedDays;
        if (paginationEvent.sortField === "updatedDate") {
            paginationEvent.sortField = 'minDate'; // to set the default sort field
            paginationEvent.sortOrder = -1; // to set the default sort order --> acs
        }
        if (this.paginationFilter !== undefined) {
            Object.assign(paginationEvent.filters, this.paginationFilter);
        } else { // setting the default filter - the first filter card
            this.index = 0;
            this.selectedFilter = { filters: [] };
            this.selectedFilter.filters = this.filterCards[0].label;
            Object.assign(paginationEvent.filters, { 'delayedDays': { value: this.filterCards[0].label, matchMode: 'is' } });
            this.gridPaginationFilterCopy = {
                filters: paginationEvent.filters,
                sortField: paginationEvent.sortField,
                sortOrder: paginationEvent.sortOrder
            };
        }
    }

    downloadXLSX() {
        this.createLoaderObject('XLSX', this.xlsLoader);
        this.loaderSubjects.pendingUnbilledXlsLoader.next(this.xlsLoader);
    }

    createLoaderObject(fileType, object) {
        object.isLoading = true;
        object.text = 'Downloading XLS...';
        object.data = this.pendingUnbilledTaskPaginationEvent;
        object.fileType = fileType;
        object.customIds = [];
    }

    showErrorNotification(error) {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
    }

    showSuccessNotification(message, header) {
        this.notificationServcie.success(message, header);
    }

    updatingUserGridConfig(columnArray) {
        let pendingUnbilledGridConfig: Map<string, string> = new Map();
        pendingUnbilledGridConfig = utils.getGridConfigMap(columnArray, this.columns);
        this.userGridConfigService.patch(utils.buildObject(pendingUnbilledGridConfig), null, GridNameEnum['PENDING_UNBILLED_TASKS'])
            .subscribe((result) => {
            });
    }

    refreshGrid() {
        if (this.gridConfig.dataLoadFunction !== undefined) {
            this.gridViewChildRef.refresh();
        }
    }

    parseValueToTwoDecimalPlaces(value) {
        return utils.parseValueToDecimalTwoPlaces(value);
    }

    getIntegerRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    /**
     * @description set employee column visibility
     * @author Divya Sachan
     * @date 2019-10-22
     * @memberof PendingUnbilledTaskComponent
     */
    setEmployeeColumnVisibility() {
        if (this.checkPermission('readAll') || this.checkPermission('view:employee-column')) { // should be visible if the user has readAll or view:employee-column permissions
            this.showEmployee = true;
        } else { // else employee column should not be visible.
            this.showEmployee = false;
        }
    }


    ngOnDestroy() { }

}
