import { Injectable, Injector } from "@angular/core";
import * as utils from '../../helpers/utils';
import { Subject } from 'rxjs';
import { BASE_URL } from '../../services/base-service/api.service';
import { InvoiceChartService } from '../../services/shared/invoice-chart.service';
import { AppUrls } from '../../services/urls';
import { InvoiceWrapper } from '../../modals/billings/invoice-wrapper';
import { Invoice } from '../../modals/billings/invoice';
import { CreditNotesService } from '../../services/shared/credit-note.service';
import { CnRequestWrapper } from '../../modals/billings/cnRequestWrapper';
import { MigratedInvoiceService } from '../services/migrated-invoice.service';

@Injectable()
export class BillingUIService {
    baseUrl: string;
    activeTabIndex: Subject<number> = new Subject();

    billingTabIndex: string;
    // migratedInvoice: boolean;

    constructor(
        private injector: Injector,
        private invoiceChartService: InvoiceChartService,
        private creditNotesService: CreditNotesService,
        private migratedInvoiceService: MigratedInvoiceService,
    ) {
        this.baseUrl = this.injector.get(BASE_URL);
    }

    modifyFilterValue(value: any, actualValue: any) {
        return value;
    }

    checkPermission(permission) {
        return utils.checkPermission(permission);
    }

    filterInvoiceStatus(gridRef: any, filterValue: any, field: string, matchMode: string) {
        matchMode = (filterValue instanceof Array) ? 'in' : 'is';
        gridRef.filter(filterValue, field, matchMode);
    }

    getInvoiceVersions(invoiceId) {
        return this.invoiceChartService.get({ invoiceId: invoiceId }, AppUrls.DISTINCT_VERSIONS);
    }

    getInvoiceWithVersion(invoiceId, version) {
        return this.invoiceChartService.get({ invoiceId: invoiceId }, AppUrls.VERSION + AppUrls.SLASH + version);
    }

    revertToPreviousVersion(invoiceId, version) {
        return this.invoiceChartService.create(null, { invoiceId: invoiceId, version: version }, AppUrls.REVERT_TO_PREVIOUS);
    }

    updateInvoice(invoiceWrapper: InvoiceWrapper, migratedInvoice) {
        if(!migratedInvoice) {
            return this.invoiceChartService.create(invoiceWrapper, null, AppUrls.UPDATE + AppUrls.DETAILS);
        }
        else {
            return this.migratedInvoiceService.create(invoiceWrapper, null, AppUrls.UPDATE + AppUrls.DETAILS);
        }
    }

    createApplyPartialCN(cnRequestWrapper: CnRequestWrapper) {
        return this.creditNotesService.create(cnRequestWrapper, null, AppUrls.CREATE_PARTIAL_AMOUNT);
    }

    getSumPartialCN(invoiceId) {
        return this.creditNotesService.get({ invoiceReferenceId: invoiceId }, AppUrls.GET_SUM_PARTIAL_AMOUNT);
    }

    getCreditNotes() {
        return this.creditNotesService.get();
    }
    setBillingTabIndex(tabIndex) {
        localStorage.setItem('billingTabIndex', tabIndex);
        // this.billingTabIndex = tabIndex;
    }

    getBillingTabIndex() {
        return localStorage.getItem('billingTabIndex');
        // return this.billingTabIndex;
    }

    sendCNRequest(invoice: Invoice) {
        return this.creditNotesService.create(invoice, null, AppUrls.FULL_CN + AppUrls.REQUEST);
    }

    approveCNRequest(invoice: Invoice) {
        return this.creditNotesService.create(invoice, null, AppUrls.FULL_CN + AppUrls.APPROVE);
    }

    rejectCNRequest(invoice: Invoice) {
        return this.creditNotesService.create(invoice, null, AppUrls.FULL_CN + AppUrls.REJECT);
    }
}
