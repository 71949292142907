import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { DashboardUiService } from '../../dashboard/service/dashboard-ui.service';
import { DateUtil } from '../../helpers/date.util';
import { ColumnChartConfig, ChartArea } from '../../modals/charts/columnChart.config';
import { ViewChild } from '@angular/core';
import { SibChartComponent } from '../../sib-chart/sib-chart.component';
import * as utils from '../../helpers/utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { BillingSearch } from '../../modals/billings/billingSearch';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { SelectItem, OverlayPanel } from 'primeng/primeng';

@Component({
  selector: 'sib-superbill-chart',
  templateUrl: './superbill-chart.component.html',
  styleUrls: ['./superbill-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuperbillChartComponent implements OnInit {

  @ViewChild('overlay') overlay: OverlayPanel;

  @Input() showChartTitle: boolean = false;
  @Input() isSalesSearchEvent: boolean;
  @Input() billingSearch: BillingSearch;
  @Input() fiscalYear: string;
  @Input() userEmailId: string;

  @Output() eEmitChartClickedData: EventEmitter<any> = new EventEmitter<any>();
  @Output() eEmitPerformance: EventEmitter<any> = new EventEmitter<any>();
  @Output() eEmitFinancialYearChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eEmitSelectedFiscalYear: EventEmitter<any> = new EventEmitter<any>();

  selectedFiscalYear: any;
  currentFiscalYears: any[] = [];
  columnChartConfig: ColumnChartConfig = new ColumnChartConfig();
  columnChartData: any[] = [];
  superbillChartData: any[] = [];
  lineElementId: string

  billingSearchEvent: BillingSearch = new BillingSearch();

  @ViewChild(SibChartComponent)
  sibChartRef: SibChartComponent;

  chartArea: ChartArea = new ChartArea();
  fullName: string;
  email: string;
  totalSuperbillAmount: any;
  totalSuperbillAmountWithTax: any;
  totalInvoiceAmount: any;
  performance: any;
  columnChartLoader: boolean = true;
  fiscalYears: SelectItem[] = [];

  constructor(
    private service: DashboardUiService,
    private currencyPipeService: CurrencyHelperPipe,
  ) {
    this.columnChartConfig.onSelect = this.onChartSelection.bind(this);
  }

  ngOnInit() {
    this.fullName = utils.getLoggedInUserFullName(); // set logged in user fullname
    if (this.userEmailId) {
      this.email = this.userEmailId;
    } else if (this.checkPermission('readAll') || this.checkPermission('update:tally-invoice')) {
      this.email = "ALL";

    } else if (!this.checkPermission('readAll')) {
      this.email = utils.getLoggedInUserEmail();
    }
    this.getCurrentFiscalYear();
    this.getSuperbillChartData();
    // this.getFiscalYears();
  }

  /**
   * @description get column chart data
   * @author Divya Sachan
   * @date 2019-10-16
   * @memberof SuperbillChartComponent
   */
  getSuperbillChartData() {
    this.columnChartLoader = true;
    if (this.isSalesSearchEvent) { // if source of search event is sales
      this.billingSearchEvent = this.billingSearch;
    } else { // otherwise
      var startDate = new Date(Date.UTC(Number(this.currentFiscalYears[0]), 3, 1)); // set fiscal year start date
      var endDate = new Date(Date.UTC(Number(this.currentFiscalYears[1]), 2, 31)); // set fiscal year end date
      this.billingSearchEvent = {
        itemEndDate: endDate,
        itemStartDate: startDate,
        userEmailId: this.email,
      }
    }

    if (this.billingSearchEvent.userEmailId !== undefined) { // if user exists then get column chart data
      this.service.getBillingCardData(this.billingSearchEvent)
        .subscribe((response) => {
          if (response && response['data']) {
            this.columnChartData = [];
            this.columnChartData = response['data'];
            this.setColumnChart();
          } else {
            this.columnChartLoader = false;
          }
        })
    } else { // otherwise
      this.columnChartLoader = false;
    }

  }

  /**
   * @description set column chart data and properties
   * @author Divya Sachan
   * @date 2019-10-16
   * @returns
   * @memberof SuperbillChartComponent
   */
  setColumnChart() {
    if (this.columnChartData) {
      this.lineElementId = SIBConstants.SUPERBILL_CHART_ID;
      this.superbillChartData = [];
      let totalSuperbill: number = 0;
      let totalInvoice: number = 0;
      if (this.columnChartData.length === 0) return;
      this.superbillChartData.push([SIBConstants.MONTH, SIBConstants.INVOICE, SIBConstants.TOOLTIP, SIBConstants.CAMPAIGN_MENU, SIBConstants.TOOLTIP]);
      this.columnChartData.forEach((data) => {
        if (data) {
          let invoicePercentage = utils.getDecimalNum((data.invoiceAmount * 100) / data.campaignAmount) + "%";
          let effectiveSuperbillAmount = data.campaignAmount - data.invoiceAmount;
          let tooltip = this.getTooltip(this.currencyPipeService.transform(utils.getDecimalNumFixedToTwoPlaces(effectiveSuperbillAmount)), this.currencyPipeService.transform(utils.getDecimalNumFixedToTwoPlaces(data.campaignAmount)), invoicePercentage);
          this.superbillChartData.push([data.month, data.invoiceAmount, tooltip, effectiveSuperbillAmount, tooltip]);
          totalSuperbill = totalSuperbill + data.campaignAmount;
          totalInvoice = totalInvoice + data.invoiceAmount;
        }

      });

      if (this.showChartTitle) {
        this.performance = { superbill: this.currencyPipeService.transform(utils.getDecimalNum(totalSuperbill)), invoice: this.currencyPipeService.transform(utils.getDecimalNum(totalInvoice)) };
        this.eEmitPerformance.emit(this.performance);
        this.columnChartConfig.title = this.setChartTitle();
        this.columnChartConfig.titleTextStyle = { color: "#333333", bold: true, fontSize: 16 };
        this.columnChartConfig.height = 260;
      } else {
        this.totalSuperbillAmount = this.currencyPipeService.transform(utils.getDecimalNum(totalSuperbill));
        this.totalSuperbillAmountWithTax = this.currencyPipeService.transform(utils.getDecimalNum(totalSuperbill * 1.18))
      }
      this.columnChartConfig.isStacked = true;
      this.columnChartConfig.hAxis.direction = 1;
      this.columnChartConfig.legend.position = 'top';
      this.columnChartConfig.tooltip = { isHtml: true };
      this.columnChartConfig.vAxis.gridlines.count = 0;
      this.columnChartConfig.series = {
        0: { color: color.DARK_BLUE }, // dark blue
        1: { color: color.LIGHT_BLUE }, // light blue
      };
      this.columnChartLoader = false;
    } else {
      this.columnChartLoader = false;
    }
  }

  getCurrentFiscalYear() {
    this.selectedFiscalYear = String(DateUtil.getCurrentFiscalYear())
    this.splitYear();
  }

  splitYear() {
    this.currentFiscalYears = this.selectedFiscalYear.split("-");
  }

  // getTooltip(month, columnName, value) {
  //   return '<div style ="padding: 10px 10px 10px 10px;width:180px;"><span style="font-size: 14px;">' +
  //     '<b>' + month + '</b></span><br><span  style="font-size: 14px;">' + columnName +
  //     ': <b>' + value + '</b></span></div>';
  // }

  getTooltip(effectiveSuperbillAmount, campaignAmount, invoicePercentage) {
    return '<div style ="padding: 10px 10px 10px 10px;width:170px;"><span  style="font-size: 14px;">'
      + SIBConstants.TOTAL + ' : <b>' + campaignAmount + '</b></span><br><span  style="font-size: 14px;">' + SIBConstants.INVOICE +
      ' : <b>' + invoicePercentage + '</b></span><br></div>';
    // <span style="font-size: 14px;">' + 'Unbilled' + ' : <b>' + effectiveSuperbillAmount + '</b></span><br>
  }

  /**
   * @description show overlay on total superbill amount in top right corner
   * @author Divya Sachan
   * @date 2019-11-28
   * @param {*} event
   * @memberof SuperbillChartComponent
   */
  showOverlay(event) {
    this.overlay.show(event);
  }

  setChartTitle() {
    return 'Sales For Financial Year ' + this.fiscalYear;
  }

  setChartArea() {
    this.chartArea.height = "70%";
    this.chartArea.width = "70%";
    this.chartArea.left = "9%";
    this.chartArea.top = "15%";
  }

  refreshChart(height, width) {
    this.columnChartConfig.height = height;
    this.columnChartConfig.width = width;
    this.sibChartRef.cshowColumnChart(this.columnChartConfig);
  }

  onChartSelection(event) {
    this.eEmitChartClickedData.emit(event);
  }

  checkPermission(permission) {
    return utils.checkPermission(permission);
  }

  // Please do not remove this commented code
  // onFiscalYearSelection() {
  //   this.splitYear();
  //   this.getSuperbillChartData();
  //   this.eEmitSelectedFiscalYear.emit(this.currentFiscalYears);
  //   this.eEmitFinancialYearChanged.emit(true);
  // }

  // getFiscalYears() {
  //   var years = DateUtil.fiscalYears(new Date(2018, 1, 1), new Date());
  //   years.forEach((year) => {
  //     this.fiscalYears.push({ label: year, value: year })
  //   });
  // }
}

export enum color {
  DARK_BLUE = '#3366cc',
  // DARK_BLUE_1 = '#005580',
  // LIGHT_BLUE_1 = '#0099e6',
  LIGHT_BLUE = '#7094db',
  DARK_RED = '#dc3912',
  LIGHT_RED = '#f2785a',
  LIGHT_RED_1 = "#f7b2a1",
  BLACK = '#222222',
  TRANSPARENT = 'transparent',
  LIGHT_GREEN = "#79d279",
}