import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, InputTextModule, SharedModule, TooltipModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { MapDialogComponent } from "./map-dialog.component";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { GMapModule } from "primeng/components/gmap/gmap";

// import { } from 'googlemaps';
@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        InputTrimModule,
        GMapModule
    ],

    declarations: [
        MapDialogComponent
    ],

    exports: [
        MapDialogComponent
    ]
})

export class MapDialogModule { }