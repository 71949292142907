/**
* @file BillingActions
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { Injectable } from "@angular/core";
import { ActionExtended } from "../../shared/actionextended";
import 'rxjs';
import { InvoiceTaskCard } from "../../modals/billings/invoice-task-card";

@Injectable()
export class BillingActions {
    static GET_RENT_ITEMS = 'GET_RENT_ITEMS';
    static GET_RENT_ITEMS_SUCCESS = 'GET_RENT_ITEMS_SUCCESS';
    static GET_RENT_ITEMS_FAILURE = 'GET_RENT_ITEMS_FAILURE';

    static GET_PRINTING_ITEMS = 'GET_PRINTING_ITEMS';
    static GET_PRINTING_ITEMS_SUCCESS = 'GET_PRINTING_ITEMS_SUCCESS';
    static GET_PRINTING_ITEMS_FAILURE = 'GET_PRINTING_ITEMS_FAILURE';

    static GET_MOUNTING_ITEMS = 'GET_MOUNTING_ITEMS';
    static GET_MOUNTING_ITEMS_SUCCESS = 'GET_MOUNTING_ITEMS_SUCCESS';
    static GET_MOUNTING_ITEMS_FAILURE = 'GET_MOUNTING_ITEMS_FAILURE';

    static GET_PENDING_RENT_ITEMS = 'GET_PENDING_RENT_ITEMS';
    static GET_PENDING_RENT_ITEMS_SUCCESS = 'GET_PENDING_RENT_ITEMS_SUCCESS';
    static GET_PENDING_RENT_ITEMS_FAILURE = 'GET_PENDING_RENT_ITEMS_FAILURE';
    static GET_PENDING_PRINTING_ITEMS = 'GET_PENDING_PRINTING_ITEMS';
    static GET_PENDING_PRINTING_ITEMS_SUCCESS = 'GET_PENDING_PRINTING_ITEMS_SUCCESS';
    static GET_PENDING_PRINTING_ITEMS_FAILURE = 'GET_PENDING_PRINTING_ITEMS_FAILURE';
    static GET_PENDING_MOUNTING_ITEMS = 'GET_PENDING_MOUNTING_ITEMS';
    static GET_PENDING_MOUNTING_ITEMS_SUCCESS = 'GET_PENDING_MOUNTING_ITEMS_SUCCESS';
    static GET_PENDING_MOUNTING_ITEMS_FAILURE = 'GET_PENDING_MOUNTING_ITEMS_FAILURE';

    static RESET_ERROR = 'RESET_ERROR';
    static RESET_GOT_RENT_ITEMS = 'RESET_GOT_RENT_ITEMS';
    static RESET_GOT_PRINTING_ITEMS = 'RESET_GOT_PRINTING_ITEMS';
    static RESET_GOT_MOUNTING_ITEMS = 'RESET_GOT_MOUNTING_ITEMS';
    static RESET_IS_BILL_GENERATED = 'RESET_IS_BILL_GENERATED';
    static RESET_IS_MIGRATED_BILL_GENERATED = 'RESET_IS_MIGRATED_BILL_GENERATED';
    static RESET_INVOICES_RECEIVED = 'RESET_INVOICES_RECEIVED';
    static RESET_MIGRATED_INVOICE_RECEIVED = "RESET_MIGRATED_INVOICE_RECEIVED";
    static RESET_RENT_ITEMS = 'RESET_RENT_ITEMS';
    static RESET_PRINTING_ITEMS = 'RESET_PRINTING_ITEMS';
    static RESET_MOUNTING_ITEMS = 'RESET_MOUNTING_ITEMS';
    static RESET_PENDING_RENT_ITEMS = 'RESET_PENDING_RENT_ITEMS';
    static RESET_PENDING_PRINTING_ITEMS = 'RESET_PENDING_PRINTING_ITEMS';
    static RESET_PENDING_MOUNTING_ITEMS = 'RESET_PENDING_MOUNTING_ITEMS';
    // static RESET_IS_ACCOUNTANT_REQUEST_REJECTED = 'RESET_IS_ACCOUNTANL_REQUEST_REJECTED';
    // static RESET_IS_ADMIN_REQUEST_REJECTED = 'RESET_IS_ADMIN_REQUEST_REJECTED';
    // static RESET_IS_ACCOUNTANT_REQUEST_APPROVED = 'RESET_IS_ACCOUNTANL_REQUEST_APPROVED';
    // static RESET_IS_ADMIN_REQUEST_APPROVED = 'RESET_IS_ADMIN_REQUEST_APPROVED';
    // static RESET_IS_ACCOUNTANT_EDIT_REQUEST_REJECTED = 'RESET_IS_ACCOUNTANT_EDIT_REQUEST_REJECTED';
    // static RESET_IS_ACCOUNTANT_CANCEL_REQUEST_REJECTED = 'RESET_IS_ACCOUNTANT_CANCEL_REQUEST_REJECTED';
    // static RESET_IS_ACCOUNTANT_EDIT_REQUEST_APPROVED = 'RESET_IS_ACCOUNTANT_EDIT_REQUEST_APPROVED';
    // static RESET_IS_ACCOUNTANT_CANCEL_REQUEST_APPROVED = 'RESET_IS_ACCOUNTANT_CANCEL_REQUEST_APPROVED';
    static RESET_TEMP_INVOICE = 'RESET_TEMP_INVOICE';
    static RESET_IS_BILL_SUBMITTED = 'RESET_IS_BILL_SUBMITTED';
    static RESET_IS_MIGRATED_BILL_SUBMITTED = "RESET_IS_MIGRATED_BILL_SUBMITTED";

    static GENERATE_BILL = 'GENERATE_BILL';
    static GENERATE_BILL_SUCCESS = 'GENERATE_BILL_SUCCESS';
    static GENERATE_BILL_FAILURE = 'GENERATE_BILL_FAILURE';

    static GENERATE_MIGRATED_BILL = 'GENERATE_MIGRATED_BILL';
    static GENERATE_MIGRATED_BILL_SUCCESS = 'GENERATE_MIGRATED_BILL_SUCCESS';
    static GENERATE_MIGRATED_BILL_FAILURE = 'GENERATE_MIGRATED_BILL_FAILURE';

    static GET_INVOICES = 'GET_INVOICES';
    static GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
    static GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

    static GET_MIGRATED_INVOICES = 'GET_MIGRATED_INVOICES';
    static GET_MIGRATED_INVOICES_SUCCESS = 'GET_MIGRATED_INVOICES_SUCCESS';
    static GET_MIGRATED_INVOICES_FAILURE = 'GET_MIGRATED_INVOICES_FAILURE';

    // static EDIT_REQUEST = 'EDIT_REQUEST';
    // static EDIT_REQUEST_SUCCESS = 'EDIT_REQUEST_SUCCESS';
    // static EDIT_REQUEST_FAILURE = 'EDIT_REQUEST_FAILURE';

    // static CANCEL_REQUEST = 'CANCEL_REQUEST';
    // static CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS';
    // static CANCEL_REQUEST_FAILURE = 'CANCEL_REQUEST_FAILURE';

    // static APPROVE_REQUEST_ACCOUNTANT = 'APPROVE_REQUEST_ACCOUNTANT';
    // static APPROVE_REQUEST_ACCOUNTANT_SUCCESS = 'APPROVE_REQUEST_ACCOUNTANT_SUCCESS';
    // static APPROVE_REQUEST_ACCOUNTANT_FAILURE = 'APPROVE_REQUEST_ACCOUNTANT_FAILURE';

    // static REJECT_REQUEST_ACCOUNTANT = 'REJECT_REQUEST_ACCOUNTANT';
    // static REJECT_REQUEST_ACCOUNTANT_SUCCESS = 'REJECT_REQUEST_ACCOUNTANT_SUCCESS';
    // static REJECT_REQUEST_ACCOUNTANT_FAILURE = 'REJECT_REQUEST_ACCOUNTANT_FAILURE';

    // static APPROVE_REQUEST_ADMIN = 'APPROVE_REQUEST_ADMIN';
    // static APPROVE_REQUEST_ADMIN_SUCCESS = 'APPROVE_REQUEST_ADMIN_SUCCESS';
    // static APPROVE_REQUEST_ADMIN_FAILURE = 'APPROVE_REQUEST_ADMIN_FAILURE';

    // static REJECT_REQUEST_ADMIN = 'REJECT_REQUEST_ADMIN';
    // static REJECT_REQUEST_ADMIN_SUCCESS = 'REJECT_REQUEST_ADMIN_SUCCESS';
    // static REJECT_REQUEST_ADMIN_FAILURE = 'REJECT_REQUEST_ADMIN_FAILURE';

    // static REJECT_EDIT_REQUEST_ACCOUNTANT = 'REJECT_EDIT_REQUEST_ACCOUNTANT';
    // static REJECT_EDIT_REQUEST_ACCOUNTANT_SUCCESS = 'REJECT_EDIT_REQUEST_ACCOUNTANT_SUCCESS';
    // static REJECT_EDIT_REQUEST_ACCOUNTANT_FAILURE = 'REJECT_EDIT_REQUEST_ACCOUNTANT_FAILURE';

    // static REJECT_CANCEL_REQUEST_ACCOUNTANT = 'REJECT_CANCEL_REQUEST_ACCOUNTANT';
    // static REJECT_CANCEL_REQUEST_ACCOUNTANT_SUCCESS = 'REJECT_CANCEL_REQUEST_ACCOUNTANT_SUCCESS';
    // static REJECT_CANCEL_REQUEST_ACCOUNTANT_FAILURE = 'REJECT_CANCEL_REQUEST_ACCOUNTANT_FAILURE';

    // static APPROVE_EDIT_REQUEST_ACCOUNTANT = 'APPROVE_EDIT_REQUEST_ACCOUNTANT';
    // static APPROVE_EDIT_REQUEST_ACCOUNTANT_SUCCESS = 'APPROVE_EDIT_REQUEST_ACCOUNTANT_SUCCESS';
    // static APPROVE_EDIT_REQUEST_ACCOUNTANT_FAILURE = 'APPROVE_EDIT_REQUEST_ACCOUNTANT_FAILURE';

    // static APPROVE_CANCEL_REQUEST_ACCOUNTANT = 'APPROVE_CANCEL_REQUEST_ACCOUNTANT';
    // static APPROVE_CANCEL_REQUEST_ACCOUNTANT_SUCCESS = 'APPROVE_CANCEL_REQUEST_ACCOUNTANT_SUCCESS';
    // static APPROVE_CANCEL_REQUEST_ACCOUNTANT_FAILURE = 'APPROVE_CANCEL_REQUEST_ACCOUNTANT_FAILURE';

    static GET_TEMPORARY_INVOICE = 'GET_TEMPORARY_INVOICE';
    static GET_TEMPORARY_INVOICE_SUCCESS = 'GET_TEMPORARY_INVOICE_SUCCESS';
    static GET_TEMPORARY_INVOICE_FAILURE = 'GET_TEMPORARY_INVOICE_FAILURE';

    static SUBMIT_BILL = 'SUBMIT_BILL';
    static SUBMIT_BILL_SUCCESS = 'SUBMIT_BILL_SUCCESS';
    static SUBMIT_BILL_FAILURE = 'SUBMIT_BILL_FAILURE';

    static SUBMIT_MIGRATED_BILL = "SUBMIT_MIGRATED_BILL";
    static SUBMIT_MIGRATED_BILL_SUCCESS = "SUBMIT_MIGRATED_BILL_SUCCESS";
    static SUBMIT_MIGRATED_BILL_FAILURE = "SUBMIT_MIGRATED_BILL_FAILURE";

    static UPLOAD_INVOICE_PO = "UPLOAD_INVOICE_PO";
    static UPLOAD_PO_SUCCESS = "UPLOAD_PO_SUCCESS";
    static UPLOAD_PO_FAILURE = "UPLOAD_PO_FAILURE";
    static RESET_IS_PO_UPLOADED = "RESET_IS_PO_UPLOADED";

    // =====================================================================================================

    static EDIT_REQUEST = 'EDIT_REQUEST';
    static EDIT_REQUEST_SUCCESS = 'EDIT_REQUEST_SUCCESS';
    static EDIT_REQUEST_FAILURE = 'EDIT_REQUEST_FAILURE';
    static RESET_IS_EDIT_REQUEST_SUCCESS = "RESET_IS_EDIT_REQUEST_SUCCESS";

    static EDIT_DISCARD_REQUEST = 'EDIT_DISCARD_REQUEST';
    static EDIT_DISCARD_REQUEST_SUCCESS = 'EDIT_DISCARD_REQUEST_SUCCESS';
    static EDIT_DISCARD_REQUEST_FAILURE = 'EDIT_DISCARD_REQUEST_FAILURE';
    static RESET_IS_EDIT_DISCARD_REQUEST_SUCCESS = "RESET_IS_EDIT_DISCARD_REQUEST_SUCCESS";

    static CANCEL_REQUEST = 'CANCEL_REQUEST';
    static CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS';
    static CANCEL_REQUEST_FAILURE = 'CANCEL_REQUEST_FAILURE';
    static RESET_IS_CANCEL_REQUEST_SUCCESS = "RESET_IS_CANCEL_REQUEST_SUCCESS";

    static EDIT_MIGRATED_REQUEST = "EDIT_MIGRATED_REQUEST";
    static EDIT_MIGRATED_REQUEST_SUCCESS = "EDIT_MIGRATED_REQUEST_SUCCESS";
    static EDIT_MIGRATED_REQUEST_FAILURE = "EDIT_MIGRATED_REQUEST_FAILURE";
    static RESET_IS_EDIT_MIGRATED_REQUEST_SUCCESS = "RESET_IS_EDIT_MIGRATED_REQUEST_SUCCESS";

    static EDIT_DISCARD_MIGRATED_REQUEST = 'EDIT_DISCARD_MIGRATED_REQUEST';
    static EDIT_DISCARD_MIGRATED_REQUEST_SUCCESS = 'EDIT_DISCARD_MIGRATED_REQUEST_SUCCESS';
    static EDIT_DISCARD_MIGRATED_REQUEST_FAILURE = 'EDIT_DISCARD_MIGRATED_REQUEST_FAILURE';
    static RESET_IS_EDIT_DISCARD_MIGRATED_REQUEST_SUCCESS = "RESET_IS_EDIT_DISCARD_MIGRATED_REQUEST_SUCCESS";

    static CANCEL_MIGRATED_REQUEST = 'CANCEL_MIGRATED_REQUEST';
    static CANCEL_MIGRATED_REQUEST_SUCCESS = 'CANCEL_MIGRATED_REQUEST_SUCCESS';
    static CANCEL_MIGRATED_REQUEST_FAILURE = 'CANCEL_MIGRATED_REQUEST_FAILURE';
    static RESET_IS_CANCEL_MIGRATED_REQUEST_SUCCESS = "RESET_IS_CANCEL_MIGRATED_REQUEST_SUCCESS";

    static APPROVE_GEN_ADMIN = "APPROVE_GEN_ADMIN";
    static APPROVE_GEN_ADMIN_SUCCESS = "APPROVE_GEN_ADMIN_SUCCESS";
    static APPROVE_GEN_ADMIN_FAILURE = "APPROVE_GEN_ADMIN_FAILURE";
    static RESET_IS_GENERATE_REQUEST_APPROVED_ADMIN = "RESET_IS_GENERATE_REQUEST_APPROVED_ADMIN";

    static APPROVE_GEN_ACCOUNTANT = "APPROVE_GEN_ACCOUNTANT";
    static APPROVE_GEN_ACCOUNTANT_SUCCESS = "APPROVE_GEN_ACCOUNTANT_SUCCESS";
    static APPROVE_GEN_ACCOUNTANT_FAILURE = "APPROVE_GEN_ACCOUNTANT_FAILURE";
    static RESET_IS_GENERATE_REQUEST_APPROVED_ACCOUNTANT = "RESET_IS_GENERATE_REQUEST_APPROVED_ACCOUNTANT";

    static REJECT_GEN_ADMIN = "REJECT_GEN_ADMIN";
    static REJECT_GEN_ADMIN_SUCCESS = "REJECT_GEN_ADMIN_SUCCESS";
    static REJECT_GEN_ADMIN_FAILURE = "REJECT_GEN_ADMIN_FAILURE";
    static RESET_IS_GENERATE_REQUEST_REJECTED_ADMIN = "RESET_IS_GENERATE_REQUEST_REJECTED_ADMIN";

    static REJECT_GEN_ACCOUNTANT = "REJECT_GEN_ACCOUNTANT";
    static REJECT_GEN_ACCOUNTANT_SUCCESS = "REJECT_GEN_ACCOUNTANT_SUCCESS";
    static REJECT_GEN_ACCOUNTANT_FAILURE = "REJECT_GEN_ACCOUNTANT_FAILURE";
    static RESET_IS_GENERATE_REQUEST_REJECTED_ACCOUNTANT = "RESET_IS_GENERATE_REQUEST_REJECTED_ACCOUNTANT";

    static APPROVE_EDIT_ADMIN = "APPROVE_EDIT_ADMIN";
    static APPROVE_EDIT_ADMIN_SUCCESS = "APPROVE_EDIT_ADMIN_SUCCESS";
    static APPROVE_EDIT_ADMIN_FAILURE = "APPROVE_EDIT_ADMIN_FAILURE";
    static RESET_IS_EDIT_REQUEST_APPROVED_ADMIN = "RESET_IS_EDIT_REQUEST_APPROVED_ADMIN";

    static APPROVE_EDIT_ACCOUNTANT = "APPROVE_EDIT_ACCOUNTANT";
    static APPROVE_EDIT_ACCOUNTANT_SUCCESS = "APPROVE_EDIT_ACCOUNTANT_SUCCESS";
    static APPROVE_EDIT_ACCOUNTANT_FAILURE = "APPROVE_EDIT_ACCOUNTANT_FAILURE";
    static RESET_IS_EDIT_REQUEST_APPROVED_ACCOUNTANT = "RESET_IS_EDIT_REQUEST_APPROVED_ACCOUNTANT";

    static REJECT_EDIT_ADMIN = "REJECT_EDIT_ADMIN";
    static REJECT_EDIT_ADMIN_SUCCESS = "REJECT_EDIT_ADMIN_SUCCESS";
    static REJECT_EDIT_ADMIN_FAILURE = "REJECT_EDIT_ADMIN_FAILURE";
    static RESET_IS_EDIT_REQUEST_REJECTED_ADMIN = "RESET_IS_EDIT_REQUEST_REJECTED_ADMIN";

    static REJECT_EDIT_ACCOUNTANT = "REJECT_EDIT_ACCOUNTANT";
    static REJECT_EDIT_ACCOUNTANT_SUCCESS = "REJECT_EDIT_ACCOUNTANT_SUCCESS";
    static REJECT_EDIT_ACCOUNTANT_FAILURE = "REJECT_EDIT_ACCOUNTANT_FAILURE";
    static RESET_IS_EDIT_REQUEST_REJECTED_ACCOUNTANT = "RESET_IS_EDIT_REQUEST_REJECTED_ACCOUNTANT";

    static APPROVE_CANCEL_ADMIN = "APPROVE_CANCEL_ADMIN";
    static APPROVE_CANCEL_ADMIN_SUCCESS = "APPROVE_CANCEL_ADMIN_SUCCESS";
    static APPROVE_CANCEL_ADMIN_FAILURE = "APPROVE_CANCEL_ADMIN_FAILURE";
    static RESET_IS_CANCEL_REQUEST_APPROVED_ADMIN = "RESET_IS_CANCEL_REQUEST_APPROVED_ADMIN";

    static APPROVE_CANCEL_ACCOUNTANT = "APPROVE_CANCEL_ACCOUNTANT";
    static APPROVE_CANCEL_ACCOUNTANT_SUCCESS = "APPROVE_CANCEL_ACCOUNTANT_SUCCESS";
    static APPROVE_CANCEL_ACCOUNTANT_FAILURE = "APPROVE_CANCEL_ACCOUNTANT_FAILURE";
    static RESET_IS_CANCEL_REQUEST_APPROVED_ACCOUNTANT = "RESET_IS_CANCEL_REQUEST_APPROVED_ACCOUNTANT";

    static REJECT_CANCEL_ADMIN = "REJECT_CANCEL_ADMIN";
    static REJECT_CANCEL_ADMIN_SUCCESS = "REJECT_CANCEL_ADMIN_SUCCESS";
    static REJECT_CANCEL_ADMIN_FAILURE = "REJECT_CANCEL_ADMIN_FAILURE";
    static RESET_IS_CANCEL_REQUEST_REJECTED_ADMIN = "RESET_IS_CANCEL_REQUEST_REJECTED_ADMIN";

    static REJECT_CANCEL_ACCOUNTANT = "REJECT_CANCEL_ACCOUNTANT";
    static REJECT_CANCEL_ACCOUNTANT_SUCCESS = "REJECT_CANCEL_ACCOUNTANT_SUCCESS";
    static REJECT_CANCEL_ACCOUNTANT_FAILURE = "REJECT_CANCEL_ACCOUNTANT_FAILURE";
    static RESET_IS_CANCEL_REQUEST_REJECTED_ACCOUNTANT = "RESET_IS_CANCEL_REQUEST_REJECTED_ACCOUNTANT";

    static GET_INVOICE_TASK_CARDS = "GET_INVOICE_TASK_CARDS";
    static GET_INVOICE_TASK_CARDS_SUCCESS = "GET_INVOICE_TASK_CARDS_SUCCESS";
    static GET_INVOICE_TASK_CARDS_FAILURE = "GET_INVOICE_TASK_CARDS_FAILURE";
    static RESET_INVOICE_TASK_CARDS = "RESET_INVOICE_TASK_CARDS";



    getRentItems(billingGrid): ActionExtended {
        return {
            type: BillingActions.GET_RENT_ITEMS,
            payload: billingGrid
        }
    }

    getRentItemsSuccess(rentItems): ActionExtended {
        return {
            type: BillingActions.GET_RENT_ITEMS_SUCCESS,
            payload: rentItems
        }
    }

    getRentItemsFailure(): ActionExtended {
        return {
            type: BillingActions.GET_RENT_ITEMS_FAILURE
        }
    }

    getPrintingItems(billingGrid): ActionExtended {
        return {
            type: BillingActions.GET_PRINTING_ITEMS,
            payload: billingGrid
        }
    }

    getPrintingItemsSuccess(printingItems): ActionExtended {
        return {
            type: BillingActions.GET_PRINTING_ITEMS_SUCCESS,
            payload: printingItems
        }
    }

    getPrintingItemsFailure(): ActionExtended {
        return {
            type: BillingActions.GET_PRINTING_ITEMS_FAILURE
        }
    }

    getMountingItems(billingGrid): ActionExtended {
        return {
            type: BillingActions.GET_MOUNTING_ITEMS,
            payload: billingGrid
        }
    }

    getMountingItemsSuccess(mountingItems): ActionExtended {
        return {
            type: BillingActions.GET_MOUNTING_ITEMS_SUCCESS,
            payload: mountingItems
        }
    }

    getMountingItemsFailure(): ActionExtended {
        return {
            type: BillingActions.GET_MOUNTING_ITEMS_FAILURE
        }
    }

    resetError(): ActionExtended {
        return {
            type: BillingActions.RESET_ERROR
        };
    }

    generateBill(invoice): ActionExtended {
        return {
            type: BillingActions.GENERATE_BILL,
            payload: invoice
        }
    }

    generateBillSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.GENERATE_BILL_SUCCESS,
            payload: invoice
        }
    }

    generateBillFailure(): ActionExtended {
        return {
            type: BillingActions.GENERATE_BILL_FAILURE
        }
    }

    resetIsBillGenerated(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_BILL_GENERATED
        }
    }

    generateMigratedBill(invoice): ActionExtended {
        return {
            type: BillingActions.GENERATE_MIGRATED_BILL,
            payload: invoice
        }
    }

    generateMigratedBillSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.GENERATE_MIGRATED_BILL_SUCCESS,
            payload: invoice
        }
    }

    generateMigratedBillFailure(): ActionExtended {
        return {
            type: BillingActions.GENERATE_MIGRATED_BILL_FAILURE
        }
    }

    resetIsMigratedBillGenerated(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_MIGRATED_BILL_GENERATED
        }
    }


    getInvoices(campaignId): ActionExtended {
        return {
            type: BillingActions.GET_INVOICES,
            payload: campaignId
        }
    }

    getInvoicesSuccess(campaignId): ActionExtended {
        return {
            type: BillingActions.GET_INVOICES_SUCCESS,
            payload: campaignId
        }
    }

    getInvoicesFailure(): ActionExtended {
        return {
            type: BillingActions.GET_INVOICES_FAILURE
        }
    }


    getMigratedInvoices(campaignId): ActionExtended {
        return {
            type: BillingActions.GET_MIGRATED_INVOICES,
            payload: campaignId
        }
    }

    getMigratedInvoicesSuccess(campaignId): ActionExtended {
        return {
            type: BillingActions.GET_MIGRATED_INVOICES_SUCCESS,
            payload: campaignId
        }
    }

    getMigratedInvoicesFailure(): ActionExtended {
        return {
            type: BillingActions.GET_MIGRATED_INVOICES_FAILURE
        }
    }

    resetInvoicesReceived(): ActionExtended {
        return {
            type: BillingActions.RESET_INVOICES_RECEIVED
        }
    }

    resetGotRentItems(): ActionExtended {
        return {
            type: BillingActions.RESET_GOT_RENT_ITEMS
        };
    }

    resetGotPrintingItems(): ActionExtended {
        return {
            type: BillingActions.RESET_GOT_PRINTING_ITEMS
        };
    }

    resetGotMountingItems(): ActionExtended {
        return {
            type: BillingActions.RESET_GOT_MOUNTING_ITEMS
        };
    }

    resetRentItems(): ActionExtended {
        return {
            type: BillingActions.RESET_RENT_ITEMS
        };
    }

    resetPrintingItems(): ActionExtended {
        return {
            type: BillingActions.RESET_PRINTING_ITEMS
        };
    }

    resetMountingItems(): ActionExtended {
        return {
            type: BillingActions.RESET_MOUNTING_ITEMS
        };
    }

    resetPendingRentItems(): ActionExtended {
        return {
            type: BillingActions.RESET_PENDING_RENT_ITEMS
        };
    }

    resetPendingPrintingItems(): ActionExtended {
        return {
            type: BillingActions.RESET_PENDING_PRINTING_ITEMS
        };
    }

    resetPendingMountingItems(): ActionExtended {
        return {
            type: BillingActions.RESET_PENDING_MOUNTING_ITEMS
        };
    }

    getPendingRentItems(billingGrid): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_RENT_ITEMS,
            payload: billingGrid
        }
    }

    getPendingRentItemsSuccess(rentItems): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_RENT_ITEMS_SUCCESS,
            payload: rentItems
        }
    }

    getPendingRentItemsFailure(): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_RENT_ITEMS_FAILURE
        }
    }

    getPendingPrintingItems(billingGrid): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_PRINTING_ITEMS,
            payload: billingGrid
        }
    }

    getPendingPrintingItemsSuccess(printingItems): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_PRINTING_ITEMS_SUCCESS,
            payload: printingItems
        }
    }

    getPendingPrintingItemsFailure(): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_PRINTING_ITEMS_FAILURE
        }
    }

    getPendingMountingItems(billingGrid): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_MOUNTING_ITEMS,
            payload: billingGrid
        }
    }

    getPendingMountingItemsSuccess(mountingItems): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_MOUNTING_ITEMS_SUCCESS,
            payload: mountingItems
        }
    }

    getPendingMountingItemsFailure(): ActionExtended {
        return {
            type: BillingActions.GET_PENDING_MOUNTING_ITEMS_FAILURE
        }
    }

    // editRequest(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.EDIT_REQUEST,
    //         payload: invoice
    //     }
    // }

    // editRequestSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.EDIT_REQUEST_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // editRequestFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.EDIT_REQUEST_FAILURE
    //     }
    // }

    // resetIsEditRequestSuccess(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_EDIT_REQUEST_SUCCESS
    //     }
    // }

    // cancelRequest(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.CANCEL_REQUEST,
    //         payload: invoice
    //     }
    // }

    // cancelRequestSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.CANCEL_REQUEST_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // cancelRequestFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.CANCEL_REQUEST_FAILURE
    //     }
    // }

    // approveRequestAccountant(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_REQUEST_ACCOUNTANT,
    //         payload: invoice
    //     }
    // }

    // approveRequestAccountantSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_REQUEST_ACCOUNTANT_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // approveRequestAccountantFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_REQUEST_ACCOUNTANT_FAILURE
    //     }
    // }

    // rejectRequestAccountant(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_REQUEST_ACCOUNTANT,
    //         payload: invoice
    //     }
    // }

    // rejectRequestAccountantSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_REQUEST_ACCOUNTANT_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // rejectRequestAccountantFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_REQUEST_ACCOUNTANT_FAILURE
    //     }
    // }

    // approveRequestAdmin(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_REQUEST_ADMIN,
    //         payload: invoice
    //     }
    // }

    // approveRequestAdminSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_REQUEST_ADMIN_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // approveRequestAdminFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_REQUEST_ADMIN_FAILURE
    //     }
    // }

    // rejectRequestAdmin(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_REQUEST_ADMIN,
    //         payload: invoice
    //     }
    // }

    // rejectRequestAdminSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_REQUEST_ADMIN_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // rejectRequestAdminFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_REQUEST_ADMIN_FAILURE
    //     }
    // }

    // resetIsAccountantRequestRejected(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ACCOUNTANT_REQUEST_REJECTED
    //     };
    // }

    // resetIsAdminRequestRejected(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ADMIN_REQUEST_REJECTED
    //     };
    // }

    // resetIsAccountantRequestApproved(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ACCOUNTANT_REQUEST_APPROVED
    //     };
    // }

    // resetIsAdminRequestApproved(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ADMIN_REQUEST_APPROVED
    //     };
    // }

    // rejectEditRequestAccountant(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_EDIT_REQUEST_ACCOUNTANT,
    //         payload: invoice
    //     }
    // }

    // rejectEditRequestAccountantSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_EDIT_REQUEST_ACCOUNTANT_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // rejectEditRequestAccountantFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_EDIT_REQUEST_ACCOUNTANT_FAILURE
    //     }
    // }

    // rejectCancelRequestAccountant(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_CANCEL_REQUEST_ACCOUNTANT,
    //         payload: invoice
    //     }
    // }

    // rejectCancelRequestAccountantSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_CANCEL_REQUEST_ACCOUNTANT_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // rejectCancelRequestAccountantFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.REJECT_CANCEL_REQUEST_ACCOUNTANT_FAILURE
    //     }
    // }

    // resetIsAccountantEditRequestRejected(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ACCOUNTANT_EDIT_REQUEST_REJECTED
    //     };
    // }

    // resetIsAccountantCancelRequestRejected(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ACCOUNTANT_CANCEL_REQUEST_REJECTED
    //     };
    // }

    // approveEditRequestAccountant(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_EDIT_REQUEST_ACCOUNTANT,
    //         payload: invoice
    //     }
    // }

    // approveEditRequestAccountantSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_EDIT_REQUEST_ACCOUNTANT_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // approveEditRequestAccountantFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_EDIT_REQUEST_ACCOUNTANT_FAILURE
    //     }
    // }

    // approveCancelRequestAccountant(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_CANCEL_REQUEST_ACCOUNTANT,
    //         payload: invoice
    //     }
    // }

    // approveCancelRequestAccountantSuccess(invoice): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_CANCEL_REQUEST_ACCOUNTANT_SUCCESS,
    //         payload: invoice
    //     }
    // }

    // approveCancelRequestAccountantFailure(): ActionExtended {
    //     return {
    //         type: BillingActions.APPROVE_CANCEL_REQUEST_ACCOUNTANT_FAILURE
    //     }
    // }

    // resetIsAccountantEditRequestApproved(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ACCOUNTANT_EDIT_REQUEST_APPROVED
    //     };
    // }

    // resetIsAccountantCancelRequestApproved(): ActionExtended {
    //     return {
    //         type: BillingActions.RESET_IS_ACCOUNTANT_CANCEL_REQUEST_APPROVED
    //     };
    // }

    getTemporaryInvoice(invoiceId): ActionExtended {
        return {
            type: BillingActions.GET_TEMPORARY_INVOICE,
            payload: invoiceId
        }
    }

    getTemporaryInvoiceSuccess(invoiceId): ActionExtended {
        return {
            type: BillingActions.GET_TEMPORARY_INVOICE_SUCCESS,
            payload: invoiceId
        }
    }

    getTemporaryInvoiceFailure(): ActionExtended {
        return {
            type: BillingActions.GET_TEMPORARY_INVOICE_FAILURE
        }
    }

    resetTempInvoice(): ActionExtended {
        return {
            type: BillingActions.RESET_TEMP_INVOICE
        }
    }

    submitBill(invoiceId): ActionExtended {
        return {
            type: BillingActions.SUBMIT_BILL,
            payload: invoiceId
        }
    }

    submitBillSuccess(invoiceId): ActionExtended {
        return {
            type: BillingActions.SUBMIT_BILL_SUCCESS,
            payload: invoiceId
        }
    }

    submitBillFailure(): ActionExtended {
        return {
            type: BillingActions.SUBMIT_BILL_FAILURE
        }
    }

    resetIsBillSubmitted(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_BILL_SUBMITTED
        };
    }



    submitMigratedBill(invoiceId): ActionExtended {
        return {
            type: BillingActions.SUBMIT_MIGRATED_BILL,
            payload: invoiceId
        }
    }

    submitMigratedBillSuccess(invoiceId): ActionExtended {
        return {
            type: BillingActions.SUBMIT_MIGRATED_BILL_SUCCESS,
            payload: invoiceId
        }
    }

    submitMigratedBillFailure(): ActionExtended {
        return {
            type: BillingActions.SUBMIT_MIGRATED_BILL_FAILURE
        }
    }
    resetIsMigratedBillSubmitted(): ActionExtended {

        return {
            type: BillingActions.RESET_IS_MIGRATED_BILL_SUBMITTED
        };
    }

    uploadInvoicePO(campaignQueryParams): ActionExtended {
        return {
            type: BillingActions.UPLOAD_INVOICE_PO,
            payload: campaignQueryParams
        };
    }

    uploadPOSuccess(purchseOrders): ActionExtended {
        return {
            type: BillingActions.UPLOAD_PO_SUCCESS,
            payload: purchseOrders
        };
    }

    uploadPOFailure(): ActionExtended {
        return {
            type: BillingActions.UPLOAD_PO_FAILURE
        };
    }

    resetIsPOUploaded(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_PO_UPLOADED
        };
    }

    // ========================================================

    editRequest(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_REQUEST,
            payload: invoice
        };
    }

    editRequestSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_REQUEST_SUCCESS,
            payload: invoice
        };
    }

    editRequestFailure(): ActionExtended {
        return {
            type: BillingActions.EDIT_REQUEST_FAILURE
        };
    }

    resetIsEditRequestSuccess(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_REQUEST_SUCCESS
        };
    }


    editDiscardRequest(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_DISCARD_REQUEST,
            payload: invoice
        };
    }

    editDiscardRequestSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_DISCARD_REQUEST_SUCCESS,
            payload: invoice
        };
    }

    editDiscardRequestFailure(): ActionExtended {
        return {
            type: BillingActions.EDIT_DISCARD_REQUEST_FAILURE
        };
    }

    resetIsEditDiscardRequestSuccess(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_DISCARD_REQUEST_SUCCESS
        };
    }

    cancelRequest(invoice): ActionExtended {
        return {
            type: BillingActions.CANCEL_REQUEST,
            payload: invoice
        };
    }

    cancelRequestSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.CANCEL_REQUEST_SUCCESS,
            payload: invoice
        };
    }

    cancelRequestFailure(): ActionExtended {
        return {
            type: BillingActions.CANCEL_REQUEST_FAILURE
        };
    }

    resetIsCancelRequestSuccess(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_CANCEL_REQUEST_SUCCESS
        };
    }



    // ==============================================================================================================
    editMigratedRequest(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_MIGRATED_REQUEST,
            payload: invoice
        };
    }

    editMigratedRequestSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_MIGRATED_REQUEST_SUCCESS,
            payload: invoice
        };
    }


    editMigratedRequestFailure(): ActionExtended {
        return {
            type: BillingActions.EDIT_MIGRATED_REQUEST_FAILURE,
        };
    }

    resetIsEditMigratedRequestSuccess(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_MIGRATED_REQUEST_SUCCESS
        };
    }


    editMigratedDiscardRequest(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_DISCARD_MIGRATED_REQUEST,
            payload: invoice
        };
    }

    editDiscardMigratedRequestSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.EDIT_DISCARD_MIGRATED_REQUEST_SUCCESS,
            payload: invoice
        };
    }

    editDiscardMigratedRequestFailure(): ActionExtended {
        return {
            type: BillingActions.EDIT_DISCARD_MIGRATED_REQUEST_FAILURE
        };
    }

    resetIsEditDiscardMigratedRequestSuccess(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_DISCARD_MIGRATED_REQUEST_SUCCESS
        };
    }

    cancelMigratedRequest(invoice): ActionExtended {
        return {
            type: BillingActions.CANCEL_MIGRATED_REQUEST,
            payload: invoice
        };
    }

    cancelMigratedRequestSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.CANCEL_MIGRATED_REQUEST_SUCCESS,
            payload: invoice
        };
    }

    cancelMigratedRequestFailure(): ActionExtended {
        return {
            type: BillingActions.CANCEL_MIGRATED_REQUEST_FAILURE
        };
    }

    resetIsCancelMigratedRequestSuccess(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_CANCEL_MIGRATED_REQUEST_SUCCESS
        };
    }
    //==============================================================================================================

    approveGenerateRequestAdmin(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_GEN_ADMIN,
            payload: invoice
        };
    }

    approveGenerateRequestAdminSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_GEN_ADMIN_SUCCESS,
            payload: invoice
        };
    }

    approveGenerateRequestAdminFailure(): ActionExtended {
        return {
            type: BillingActions.APPROVE_GEN_ADMIN_FAILURE,
        };
    }

    resetIsGenerateRequestApprovedAdmin(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_GENERATE_REQUEST_APPROVED_ADMIN
        };
    }

    approveGenerateRequestAccountant(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_GEN_ACCOUNTANT,
            payload: invoice
        };
    }

    approveGenerateRequestAccountantSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_GEN_ACCOUNTANT_SUCCESS,
            payload: invoice
        };
    }

    approveGenerateRequestAccountantFailure(): ActionExtended {
        return {
            type: BillingActions.APPROVE_GEN_ACCOUNTANT_FAILURE,
        };
    }

    resetIsGenerateRequestApprovedAccountant(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_GENERATE_REQUEST_APPROVED_ACCOUNTANT
        };
    }

    approveEditRequestAdmin(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_EDIT_ADMIN,
            payload: invoice
        };
    }

    approveEditRequestAdminSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_EDIT_ADMIN_SUCCESS,
            payload: invoice
        };
    }

    approveEditRequestAdminFailure(): ActionExtended {
        return {
            type: BillingActions.APPROVE_EDIT_ADMIN_FAILURE,
        };
    }

    resetIsEditRequestApprovedAdmin(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_REQUEST_APPROVED_ADMIN
        };
    }

    approveEditRequestAccountant(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_EDIT_ACCOUNTANT,
            payload: invoice
        };
    }

    approveEditRequestAccountantSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_EDIT_ACCOUNTANT_SUCCESS,
            payload: invoice
        };
    }

    approveEditRequestAccountantFailure(): ActionExtended {
        return {
            type: BillingActions.APPROVE_EDIT_ACCOUNTANT_FAILURE,
        };
    }

    resetIsEditRequestApprovedAccountant(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_REQUEST_APPROVED_ACCOUNTANT
        };
    }

    approveCancelRequestAdmin(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_CANCEL_ADMIN,
            payload: invoice
        };
    }

    approveCancelRequestAdminSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_CANCEL_ADMIN_SUCCESS,
            payload: invoice
        };
    }

    approveCancelRequestAdminFailure(): ActionExtended {
        return {
            type: BillingActions.APPROVE_CANCEL_ADMIN_FAILURE,
        };
    }

    resetIsCancelRequestApprovedAdmin(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_CANCEL_REQUEST_APPROVED_ADMIN
        };
    }

    approveCancelRequestAccountant(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_CANCEL_ACCOUNTANT,
            payload: invoice
        };
    }

    approveCancelRequestAccountantSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.APPROVE_CANCEL_ACCOUNTANT_SUCCESS,
            payload: invoice
        };
    }

    approveCancelRequestAccountantFailure(): ActionExtended {
        return {
            type: BillingActions.APPROVE_CANCEL_ACCOUNTANT_FAILURE,
        };
    }

    resetIsCancelRequestApprovedAccountant(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_CANCEL_REQUEST_APPROVED_ACCOUNTANT
        };
    }

    rejectGenerateRequestAdmin(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_GEN_ADMIN,
            payload: invoice
        };
    }

    rejectGenerateRequestAdminSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_GEN_ADMIN_SUCCESS,
            payload: invoice
        };
    }

    rejectGenerateRequestAdminFailure(): ActionExtended {
        return {
            type: BillingActions.REJECT_GEN_ADMIN_FAILURE,
        };
    }

    resetIsGenerateRequestRejectedAdmin(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_GENERATE_REQUEST_REJECTED_ADMIN
        };
    }

    rejectGenerateRequestAccountant(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_GEN_ACCOUNTANT,
            payload: invoice
        };
    }

    rejectGenerateRequestAccountantSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_GEN_ACCOUNTANT_SUCCESS,
            payload: invoice
        };
    }

    rejectGenerateRequestAccountantFailure(): ActionExtended {
        return {
            type: BillingActions.REJECT_GEN_ACCOUNTANT_FAILURE,
        };
    }

    resetIsGenerateRequestRejectedAccountant(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_GENERATE_REQUEST_REJECTED_ACCOUNTANT
        };
    }

    rejectEditRequestAdmin(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_EDIT_ADMIN,
            payload: invoice
        };
    }

    rejectEditRequestAdminSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_EDIT_ADMIN_SUCCESS,
            payload: invoice
        };
    }

    rejectEditRequestAdminFailure(): ActionExtended {
        return {
            type: BillingActions.REJECT_EDIT_ADMIN_FAILURE,
        };
    }

    resetIsEditRequestRejectedAdmin(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_REQUEST_REJECTED_ADMIN
        };
    }

    rejectEditRequestAccountant(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_EDIT_ACCOUNTANT,
            payload: invoice
        };
    }

    rejectEditRequestAccountantSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_EDIT_ACCOUNTANT_SUCCESS,
            payload: invoice
        };
    }

    rejectEditRequestAccountantFailure(): ActionExtended {
        return {
            type: BillingActions.REJECT_EDIT_ACCOUNTANT_FAILURE,
        };
    }

    resetIsEditRequestRejectedAccountant(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_EDIT_REQUEST_REJECTED_ACCOUNTANT
        };
    }

    rejectCancelRequestAdmin(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_CANCEL_ADMIN,
            payload: invoice
        };
    }

    rejectCancelRequestAdminSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_CANCEL_ADMIN_SUCCESS,
            payload: invoice
        };
    }

    rejectCancelRequestAdminFailure(): ActionExtended {
        return {
            type: BillingActions.REJECT_CANCEL_ADMIN_FAILURE,
        };
    }

    resetIsCancelRequestRejectedAdmin(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_CANCEL_REQUEST_REJECTED_ADMIN
        };
    }

    rejectCancelRequestAccountant(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_CANCEL_ACCOUNTANT,
            payload: invoice
        };
    }

    rejectCancelRequestAccountantSuccess(invoice): ActionExtended {
        return {
            type: BillingActions.REJECT_CANCEL_ACCOUNTANT_SUCCESS,
            payload: invoice
        };
    }

    rejectCancelRequestAccountantFailure(): ActionExtended {
        return {
            type: BillingActions.REJECT_CANCEL_ACCOUNTANT_FAILURE,
        };
    }

    resetIsCancelRequestRejectedAccountant(): ActionExtended {
        return {
            type: BillingActions.RESET_IS_CANCEL_REQUEST_REJECTED_ACCOUNTANT
        };
    }

    getInvoiceTaskCards(): ActionExtended {
        return {
            type: BillingActions.GET_INVOICE_TASK_CARDS
        };
    }

    getInvoiceTaskCardsSuccess(invoiceTaskCards): ActionExtended {
        return {
            type: BillingActions.GET_INVOICE_TASK_CARDS_SUCCESS,
            payload: invoiceTaskCards,
        };
    }

    getInvoiceTaskCardsFailure(): ActionExtended {
        return {
            type: BillingActions.GET_INVOICE_TASK_CARDS_FAILURE
        };
    }

    resetInvoiceTaskCards(): ActionExtended {
        return {
            type: BillingActions.RESET_INVOICE_TASK_CARDS
        };
    }

}
