import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { AdjustSuperbillComponent } from './adjust-superbill.component';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule
    ],

    declarations: [
        AdjustSuperbillComponent
    ],

    exports: [
        AdjustSuperbillComponent
    ]
})

export class AdjsutSuperbillModule { }
