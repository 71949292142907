import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { Contracts } from '../../../modals/contracts/contracts';
import * as untappedRoiDataColumnConfig from '../../../modals/column-configurations/untapped-roi-data-column-config';
import { DashboardService } from '../../../dashboard/service/dashboard.service';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { LoaderSubjects } from '../../../modals/loader-subjects/loader-subjects';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { GridNameEnum } from '../../../shared/constants/grid-name.enum';
import * as utils from '../../../helpers/utils';
import { AppUrls } from '../../../services/urls';
import { catchError, map } from 'rxjs/operators';
import { empty as observableEmpty } from 'rxjs';
import { PPTLoader } from '../../../modals/loader-subjects/pptloader';
import { SelectItem } from 'primeng/primeng';

@Component({
  selector: 'sib-untapped-roi',
  templateUrl: './untapped-roi.component.html',
  styleUrls: ['./untapped-roi.component.scss']
})
export class UntappedRoiComponent implements OnInit {

  @ViewChild(GridComponent) gridViewChildRef: GridComponent<Contracts>;
  @ViewChild('chequeDetail') chequeDetailComponent;

  _startDate: Date;
  @Input() set startDate(startDate) {
    this._startDate = startDate;
    this.gridViewChildRef.refresh();
  }
  get startDate() {
    return this._startDate;
  }

  _endDate: Date;
  @Input() set endDate(endDate) {
    this._endDate = endDate;
    this.gridViewChildRef.refresh();
  }
  get endDate() {
    return this._endDate;
  }

  isLoading: boolean = false;

  gridConfig: GridConfig<Contracts> = new GridConfig<Contracts>();
  untappedRoiDataColumns = untappedRoiDataColumnConfig.untappedRoiDataColumns;
  totalRecords: number;
  showXlsExport = true;
  isFileDownloading = false;
  xlsLoader: PPTLoader = new PPTLoader(); // for Xls
  searchEvent: GridPaginationEvent;
  modifiedColumns: SelectItem[] = [];
  selectedColumns: any[] = [];
  chequeDetails: any[] = [];

  constructor(
    private dashboardService: DashboardService,
    private notificationServcie: NotificatoinsService,
    private loaderSubjects: LoaderSubjects,
    private hmColService: HoardingMasterColService,
    private userGridConfigService: UserGridConfigService,
  ) { }

  ngOnInit() {
    this.setUserGridConfig(); // set grid column config
    this.setGridConfigObject(); // set grid config
  }

  /**
   * @description set grid column configuration
   * @author Divya Sachan
   * @date 2019-11-15
   * @memberof UntappedRoiComponent
   */
  setUserGridConfig() {
    this.userGridConfigService.get(null, AppUrls.SLASH + GridNameEnum['UNTAPPED_ROI']).subscribe((untappedRoiDataGridConfig) => {
      this.untappedRoiDataColumns = utils.getUserConfiguredColumns(untappedRoiDataGridConfig, this.untappedRoiDataColumns);
      // this.hmColService.defaultColumns.next(this.untappedRoiDataColumns);
      this.gridViewChildRef.ngOnInit();
    });
  }

  ngAfterViewInit() {
    this.modifiedColumns = this.gridViewChildRef.getModifiedColumns();
    this.hmColService.defaultColumns.subscribe((defaultColumns) => {
      this.selectedColumns = [];
      defaultColumns.forEach((column) => {
        if (column['default'] === true && column['permanent'] === false) {
          this.selectedColumns.push(column['header']);
        }
      });
      if (this.selectedColumns.length > 0) {
        this.hmColService.selectedColumns.next(this.selectedColumns);
      }
    });
  }

  /**
   * @description set grid config properties
   * @author Divya Sachan
   * @date 2019-11-15
   * @memberof UntappedRoiComponent
   */
  setGridConfig() {
    this.gridConfig.getDefaultModel = () => new Contracts();
    this.gridConfig.model = Contracts;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = false;
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
  }

  /**
   * @description set grid configuration and data
   * @author Divya Sachan
   * @date 2019-11-15
   * @memberof UntappedRoiComponent
   */
  setGridConfigObject() {
    this.setGridConfig();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen;

      this.isLoading = true;

      this.setPaginationEvent(paginationEvent);
      return this.dashboardService.create(paginationEvent, paginationEvent, AppUrls.UNTAPPED_ROI_DATA).pipe(map(response => {
        if (response) {
          this.searchEvent = paginationEvent;
          this.totalRecords = response['data']['totalElements'];
          return response['data']['content'];
        }
      }), catchError(error => {

        if (error.error.code === 422) {
          this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
          this.gridViewChildRef.resetAndReloadTable();
        } else {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
          this.isLoading = false;
          return observableEmpty();
        }
      }));

    };
  }

  /**
   * @description set pagination Event
   * @author Divya Sachan
   * @date 2019-11-15
   * @param {*} paginationEvent
   * @memberof UntappedRoiComponent
   */
  setPaginationEvent(paginationEvent) {
    let filter = {
      "serviceDate": {
        value: [new Date(this.startDate), new Date(this.endDate)],
        matchMode: "dateBetween"
      }
    };
    Object.assign(paginationEvent.filters, filter);
  }

  /**
   * @description overlay for cheque name 
   * @author Divya Sachan
   * @date 2019-11-15
   * @param {*} rowData
   * @memberof UntappedRoiComponent
   */
  openChequeDetails(rowData) {
    if (rowData.cheques && rowData.cheques.length > 1) {
      this.chequeDetails = [];
      for (let i = 1; i < rowData.cheques.length; i++) {
        this.chequeDetails.push(rowData.cheques[i]);
      }
    }
  }

  /**
   * @description update grid configuration
   * @author Divya Sachan
   * @date 2019-11-15
   * @param {*} event
   * @memberof UntappedRoiComponent
   */
  updatingUserGridConfig(event) {
    let untappedRoiDataGridConfig: Map<string, string> = new Map();
    untappedRoiDataGridConfig = utils.getGridConfigMap(event, this.untappedRoiDataColumns);
    this.userGridConfigService.patch(utils.buildObject(untappedRoiDataGridConfig), null, GridNameEnum['UNTAPPED_ROI'])
      .subscribe((result) => {
      });
  }

  /**
   * @description export XLS
   * @author Divya Sachan
   * @date 2019-11-15
   * @memberof UntappedRoiComponent
   */
  exportXlsx() {
    this.isFileDownloading = true;
    this.createLoaderObject("XLS", this.xlsLoader)
    this.loaderSubjects.untappedRoiDataXlsLoader.next(this.xlsLoader);
    this.isFileDownloading = false;
  }

  /**
   * @description create object for XLS
   * @author Divya Sachan
   * @date 2019-11-15
   * @param {*} fileType
   * @param {*} object
   * @memberof UntappedRoiComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
  }
}
