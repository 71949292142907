
import {of as observableOf,  Subscription ,  Observable ,  BehaviorSubject ,  Subject } from 'rxjs';

import {take, filter, map, mergeMap, refCount, multicast, switchMap} from 'rxjs/operators';
// import { Injectable, Output, EventEmitter } from "@angular/core";

// @Injectable()
// export class TitleService{

    // @Output() emitTitle: EventEmitter<string> = new EventEmitter();

    // setTitle(title:string){
    //     console.log("The title under service is", title)
    //     this.emitTitle.emit(title);
    // }

    // getTitle(){

    // }


// }

import { Injectable } from '@angular/core';
import { Title as NgTitleService } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppConfig } from "./app-config.service";


/**
 * Application title service to set the application title in browser window
 * and broadcast the title as an observable.
 * 
 * This works in two modes: ConnectedWithRouter and DisconnectedWithRoute.
 * When ConnectedWithRoute:  This service listens on NavigationEnd events and then takes the title property from the activated route on navigation end (if present).
 * When not ConnectedWithRoute: This service emit title when title is change using title property
 * 
 * @export
 * @class AppTitleService
 */
@Injectable()
export class TitleService {


    /**
     * Title for the applcation
     * 
     * @type {string}
     * @memberof TitleService
     */
    protected _title: string;


    /**
     * Title observable. Emits when title changes
     * 
     * @type {Observable<string>}
     * @memberof TitleService
     */
    title$: Observable<string>;


    /**
     * A subject which emits when title changes throush service and not by router
     * 
     * @type {Subject<string>}
     * @memberof TitleService
     */
    titleChangeSubject$: Subject<string> = new Subject();


    /**
     * Subjet for multicasting title changes. Used for creating title$ Observable
     * 
     * @type {BehaviorSubject<string>}
     * @memberof TitleService
     */
    titleMulticastSubject$: BehaviorSubject<string> = new BehaviorSubject('');



    /**
     * Flag is set when title changes are emitted because of router events
     * 
     * @protected
     * @type {boolean}
     * @memberof TitleService
     */
    protected isConnectedWithRouter: boolean = true;


    /**
     * Observable which emits changes in isConnectedWithRouter. Used for switching between router events and changes in title thorough service
     * 
     * @protected
     * @type {BehaviorSubject<boolean>}
     * @memberof TitleService
     */
    protected connectedWithRouter$: BehaviorSubject<boolean> = new BehaviorSubject(true);


    /**
     * Returns true if service is connected to router
     * 
     * @readonly
     * @memberof TitleService
     */
    get isConnectedToRouter() {
        return this.isConnectedWithRouter;
    }


    /**
     * Returns title
     * 
     * @memberof TitleService
     */
    get title() {
        return this._title;
    }


    /**
     * Sets title if not connected to router 
     * 
     * @memberof TitleService
     */
    set title(val: string) {
        // allow to set title only when not connected with router
        if (!this.isConnectedToRouter) {
            this._title = val;
            // emit titleChangeSubject
            this.titleChangeSubject$.next(this.title);
            this.setWindowTitle(this._title);
        } else {
            console.warn('Title service: Cant change title because service connected with router');
        }
    }


    /**
     * Creates an instance of AppTitleService.
     * @param {NgTitleService} ngTitleService 
     * @param {Router} router 
     * @param {ActivatedRoute} activatedRoute 
     * @param {TranslateService} translate 
     * @param {AppConfig} config 
     * @memberof TitleService
     */
    constructor(private ngTitleService: NgTitleService, private router: Router, private activatedRoute: ActivatedRoute, private translate: TranslateService, private config: AppConfig) {
        this.connectWithRouter();
        this.initializeTitleObservable();

    }


    /**
     * Function set the title$ Observable from navigatinoEnd Events and TitleChangeSubject
     * 
     * @memberof AppTitleService
     */
    initializeTitleObservable() {
        let titleFromNavigation$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            // .filter((route) => route.outlet === 'primary')
            mergeMap((route) => route.data),
            map((routeData) => {
                if (routeData && routeData.title) {
                    this._title = routeData.title;
                } else {
                    this._title = '';
                }
                this.setWindowTitle(this._title);
                return this.title;
            }),);


        let title$ = this.connectedWithRouter$.pipe(switchMap((isConnectedWithRouter: boolean) => {
            return isConnectedWithRouter ? titleFromNavigation$ : this.titleChangeSubject$.asObservable()
        }))

        let translated$ = title$.pipe(switchMap((title) => {
            return title ? this.translate.stream(title) : observableOf(title)
        }));
        
        this.title$ = translated$.pipe(multicast(this.titleMulticastSubject$),refCount(),);
    }


    /**
     * Connect the title service with router.
     * Run this when title needs to be chagned on router navigation
     * 
     * @memberof AppTitleService
     */
    connectWithRouter() {
        this.isConnectedWithRouter = true;
        this.connectedWithRouter$.next(true);
    }


    /**
     * Disconnect the title service with router.
     * Run this when title needs to be changed manually. This will stop title changes on router navigation.
     * 
     * @memberof AppTitleService
     */
    disconnectFromRouter() {
        this.isConnectedWithRouter = false;
        this.connectedWithRouter$.next(false);
    }


    /**
     * Method sets the window title based on title
     * 
     * @param {string} title 
     * @memberof AppTitleService
     */
    setWindowTitle(title: string) {
        if(!title) {
            title = this.config.get('appDefaultTitle');
        }
        this.translate.stream(title).pipe(take(1)).subscribe((translatedTitle) => {
            this.ngTitleService.setTitle(translatedTitle)
        });
    }

}


// import { Title } from '@angular/platform-browser';
// import { Router } from '@angular/router';

// export class Title {
//     /**
//      * Get the title of the current HTML document.
//      * @returns {string}
//      */
//     getTitle(): string { return getDOM().getTitle(); }
  
//     /**
//      * Set the title of the current HTML document.
//      * @param newTitle
//      */
//     setTitle(newTitle: string) { getDOM().setTitle(newTitle); }
//   }