export enum ActivityLogModuleEnum {
    AREA = "Area",
    CITY = "City",
    CONTRACT_TYPE = "Contract Type",
    // COUNTRY = "Country",
    DISTRICT = "District",
    GROUP = "Group",
    // INDUSTRY = "Industry",
    // STATE = "State",
    CONTRACT = "Contract",
    CUSTOMER = "Customer",
    HOARDING = "Hoarding",
    HOARDING_GROUP = "Hoarding Group",
    POINT_OF_CONTACT = "Point Of Contact",
    // USER_TERRITORY = "User Territory",
    // COUNTER = "Counter",
    // USER = "Sib User",
    // TENANT = "Tenant",
    // ENVIRONMENT = "Environment",
    // SYSTEM_PROPERTY = "System Property",
    // USER_PROPERTY = "User Property",
    PLAN = "Plan",
    PLAN_ITEM = "Plan Item",
    CAMPAIGN = "Campaign",
    CAMPAIGN_ITEM = "Campaign Item",
    // PRINTER = "Printer",
    // SIB_MESSAGE = "Sib Message",
    // CRON_LOG = "Cron Log",
    // SALES_JOURNAL = "Sales Journal",
    INVOICE = "Invoice",
    MIGRATED_INVOICE = "Migrated Invoice",
    // ACCOUNT = "Account",
    // ACCOUNT_BOOK = "Account Book",
    // TEMP_INVOICE = "Temp Invoice",
    // SIB_CACHE = "Sib Cache",
    // FIELDS = "Field",
    // ROLE_MASTER = "Role Master",
    // ACTIVITY_LOG = "Activity Log",
    // PLAN_LOG = "Plan Log",
    // NOTIFICATION = "Notification",
    // CONTACT_PERSON = "Contact Person",
    KIOSK = "Kiosk",
    PURCHASE_ORDER = "Purchase Order",
    SUGGESTION = "suggestion",
    CAMPAIGN_ITEM_CONFLICT = "Campaign Item Conflict"
    // CI_FLEX = "Campaign Item Flex",
    // CI_MONITORING = "Campaign Item Monitoring",
    // CI_MOUNTING = "Campaign Item Mounting",
    // CI_UNMOUNTING = "Campaign Item Unmounting"
}
