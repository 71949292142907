/**
 * Programmer: Pulkit Bnasal
 * 
 * Description: HoardingModuleState will contain all the state for HoardingModule
 * And reducers will contain all the reducers that can change the state.
 * 'reducers' are registed in customerModule in StoreModule
 * This is done to keep all the states and reducers at one place.
 */
import { ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { HoardingState, hoardingReducer } from "./reducers/hoarding.reducers";
import { AppState } from "../app state/reducers/app.reducers";
import { AppModuleState } from "../app state/app-module.reducers";


export interface HoardingModuleState {
    hoardings: HoardingState;
}

export const reducers: ActionReducerMap<HoardingModuleState> = {
    hoardings: hoardingReducer
};

export interface HoardingModuleStateExtended extends AppModuleState {
    hoardings: HoardingModuleState;
}



