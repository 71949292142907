import { InputTrimDirective } from './input-trim.directive';
import { NgModule } from '@angular/core';

@NgModule( {
  imports     : [],
  exports     : [InputTrimDirective],
  declarations: [InputTrimDirective],
  providers   : [],
} )
export class InputTrimModule {
}
