export enum HelpModulesEnum {
    ALL = "All",
    CUSTOMER = 'Customer',
    CONTRACT = 'Contract',
    INVENTORY = 'Inventory',
    PLAN = 'Plan',
    CAMPAIGN = 'Campaign',
    OPERATION = 'Operation',
    BILLING = 'Billing',
    USER = 'User',
    MASTERS = 'Masters',
    PHOTO_INTERFACE = 'Photo Interface',
    ACTIVITY = 'Activity',
    DOCUMENT = 'Document'
}