/**
* @file date.util.component file
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
export class DateUtil {


  static month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  /*
  
  
  /**
    * This method is used for converting date into short formate
    *i.e 02 Jan,2018 to 02-Jan, 18
    * 
    * @param  {} date
   */

  static dategridFormatter(date) {

    var d = new Date(date);
    var month = '' + DateUtil.month_names_short[(d.getMonth())];
    var day = '' + d.getDate();
    var year = '' + d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    year = year.substring(2);

    // return day + month + ',' + year;
    return day + month + year;

  }

  static dateComparator(date1, date2) {
    if (date1 > date2) {
      return true;
    } else {
      return false;
    }
  }

  static dateComparatorgte(date1, date2) {
    if (this.convertDateTimeToDate(date1) >= this.convertDateTimeToDate(date2)) {
      return true;
    } else {
      return false;
    }
  }

  static convertDateTimeToDate(date) {
    var datePart = date.getDate();
    var month = date.getMonth();
    var year = date.getFullYear();

    return new Date(year, month, datePart);
  }

  static getCurrentFiscalYear(): any {
    //get current date
    var today = new Date();

    //get current month
    var curMonth = today.getMonth();

    var fiscalYr = "";
    if (curMonth > 2) { // changed value from 3 to 2 - Pulkit
      var nextYr1 = (today.getFullYear() + 1).toString();
      // .charAt(2) + nextYr1.charAt(3)
      fiscalYr = today.getFullYear().toString() + "-" + nextYr1;
    } else {
      var nextYr2 = today.getFullYear().toString();
      // .charAt(2) + nextYr2.charAt(3)
      fiscalYr = (today.getFullYear() - 1).toString() + "-" + nextYr2;
    }

    return fiscalYr;

  }

  static fiscalYears(startDate, endDate) {
    var startYear = (startDate.getFullYear());
    var endYear = (endDate.getFullYear() + 1);

    var yearDiff = endYear - startYear;
    var fiscalYears = [];
    for (let i = 0; i < yearDiff; i++) {
      // var a = startYear;
      fiscalYears.push(startYear + i + '-' + (startYear + i + 1));
    }

    return fiscalYears;
  }

  /**
   * @description get month start and end date according to selected year
   * @author Divya Sachan
   * @date 2020-01-15
   * @static
   * @param {*} month
   * @param {*} [selectedYear]
   * @param {boolean} [currentYear=false]
   * @returns
   * @memberof DateUtil
   */
  static getMonthStartAndEndDate(month, selectedYear?, currentYear = false) {
    var dates = []
    if (month >= 0 && month <= 2 && !currentYear) {
      var nextYear = new Date().getFullYear();
      if (selectedYear) {
        var startDate = new Date(selectedYear.split('-')[1], month, 1, new Date().getHours());
        var endDate = new Date(selectedYear.split('-')[1], month + 1, 0, new Date().getHours());
      } else {
        var startDate = new Date(nextYear, month, 1, new Date().getHours());
        var endDate = new Date(nextYear, month + 1, 0, new Date().getHours());
      }
    } else {
      var today = new Date();
      if (currentYear) {
        var startDate = new Date(today.getFullYear(), month, 1, today.getHours());
        var endDate = new Date(today.getFullYear(), month + 1, 0, today.getHours());
      } else {
        if (selectedYear) {
          var startDate = new Date(selectedYear.split('-')[0], month, 1, new Date().getHours());
          var endDate = new Date(selectedYear.split('-')[0], month + 1, 0, new Date().getHours());
        } else {
          var startDate = new Date(today.getFullYear() - 1, month, 1, today.getHours());
          var endDate = new Date(today.getFullYear() - 1, month + 1, 0, today.getHours());
        }
      }
    }
    dates.push(startDate);
    dates.push(endDate);
    return dates;
  }

  static getTimeFromDate(date) {
    let hours = String(new Date(date).getHours());
    let mins = String(new Date(date).getMinutes());
    let secs = String(new Date(date).getSeconds());
    const ampm = Number(hours) >= 12 ? "pm" : "am";
    hours = String(Number(hours) % 12);
    hours = Number(hours) ? hours : '12';
    hours = Number(hours) < 10 ? String('0' + String(hours)) : hours;
    mins = Number(mins) < 10 ? String('0' + String(mins)) : mins;
    secs = Number(secs) < 10 ? String('0' + String(secs)) : secs;
    return hours + ":" + mins + " " + ampm;
  }

  static formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }

  static formatDateDDMMMYY(date) {
    var d = new Date(date),
      month = '' + (d.getMonth()),
      day = '' + d.getDate(),
      year = d.getFullYear();

    return "" + day + " " + this.month_names_short[month] + ", " + year;
  }

  static formatDateDDMMYY(date) {
    var d = new Date(date),
      month = '' + (d.getMonth()),
      day = '' + d.getDate(),
      year = d.getFullYear();

    return "" + day + "" + this.month_names_short[month] + "" + year;
  }

  static getDaysInMonth(month, year) {

    return new Date(year, month + 1, 0).getDate();
  }

  /**
   * set month name according to the month index
   *
   * @static
   * @param {*} monthIndex
   * @returns
   * @memberof DateUtil
   */
  static setMonthName(monthIndex) {
    return monthIndex === 0 ? 'Jan' : (monthIndex === 1 ? 'Feb' : (monthIndex === 2 ? 'Mar' : (monthIndex === 3 ? 'Apr' : (monthIndex === 4 ? 'May' : (monthIndex === 5 ? 'Jun' : (monthIndex === 6 ? 'Jul' : (monthIndex === 7 ? 'Aug' : (monthIndex === 8 ? 'Sep' : (monthIndex === 9 ? 'Oct' : (monthIndex === 10 ? 'Nov' : 'Dec'))))))))))
  }

  /**
   * @description get last N (numberofmonthsprior) start date and end date
   * @author Divya Sachan
   * @date 2019-11-07
   * @static
   * @param {*} month
   * @param {*} [N]
   * @returns
   * @memberof DateUtil
   */
  static getLastNMonthStartDateEndDate(month, N?) {
    let startYear: any;
    let endYear: any;
    let startDate: Date;
    let endDate: Date;
    let dates = [];
    let monthNMonthsPrior = month - N; //get the month N months prior
    if (monthNMonthsPrior > 0) {
      startYear = new Date().getFullYear(); // set current year
      endYear = new Date().getFullYear(); //set current year
    } else {
      startYear = new Date().getFullYear() - 1; // set previous year
      endYear = new Date().getFullYear(); // set current year
      monthNMonthsPrior = monthNMonthsPrior + 12; // get corresponding positive value 
    }
    // setting date = 1 to the month gives start date of the corresponding month
    startDate = new Date(startYear, monthNMonthsPrior, 1, new Date().getHours());
    // setting date = 0 to the current month gives previous month's end date
    endDate = new Date(endYear, month, 0, new Date().getHours());
    dates.push(startDate);
    dates.push(endDate);
    return dates;
  }

}
