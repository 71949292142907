import { Component, OnInit } from '@angular/core';
import { Notifications, AlertType } from '../modals/notifications';
import { NotificatoinsService } from '../services/notifications/notifications.service';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'sib-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications: Notifications[] = [];
  life: number = 5000;
  constructor(
    private notificationsService: NotificatoinsService
  ) { }

  ngOnInit() {
    this.notificationsService.getAlert().subscribe((notification: Notifications) => {
      if (!notification) {
        this.notifications = [];
        return;
      }
      this.notifications.push(notification);
      /* Bug Id: 93928 Now showing notifications for 3 seconds */
      if (notification.severity === AlertType['0']) {
        setTimeout(() => {
          this.notificationsService.clear();
        }, 3000)
      } else {
        setTimeout(() => {
          this.notificationsService.clear();
        }, 10000)
      }

    });
  }

  ngOnDestroy() {
    this.notificationsService.clear();
  }

  onClose() {
    this.notifications = [];
  }
}
