import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputSwitchModule, DropdownModule } from 'primeng/primeng';
import { PrintPannelLayoutComponent } from './print-pannel-layout.component';
import { FormsModule } from '@angular/forms';
import { DirectiveModules } from '../../../modules/directive.modules';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule,
        ProgressSpinnerModule,
        InputSwitchModule,
        DropdownModule,
        FormsModule,
        DirectiveModules
    ],

    declarations: [
        PrintPannelLayoutComponent
    ],

    exports: [
        PrintPannelLayoutComponent
    ]
})

export class PrintPannelLayoutModule { }
