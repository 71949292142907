import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";

@Injectable()
export class HoardingLightService extends ApiService<any, any, any> {
    urlPath: string = 'hoardings/lights'

    constructor(Http: HttpClient, Injector: Injector){
        super(Http, Injector)
    }

    // createH(hoarding: ContractHoardings){
    //     return this.httpClient.post('http://172.16.5.149:8000/hoardings/save', hoarding);
    // }
}