export class TableChartConfig {

    allowHtml: boolean;

    alternatingRowStyle: boolean;

    /**
     * Example: var cssClassNames = {headerRow: 'bigAndBoldClass',
     *   hoverTableRow: 'highlightClass'};
     * 
     * @type {*}
     * @memberof TableChartConfig
     */
    cssClassNames: any;

    firstRowNumber: number;

    frozenColumns: number;

    height: string;

    page: string;

    pageSize: number;

    pagingButtons: string | number;

    showRowNumber: boolean;

    sort: string;

    sortAscending: boolean;

    sortColumn: number;

    startPage: number;

    width: string;

    constructor(
        alternatingRowStyle,
        cssClassNames,
        firstRowNumber,
        frozenColumns,
        height,
        showRowNumber,
        sort,
        sortAscending,
        sortColumn,
        width) {

            this.alternatingRowStyle = alternatingRowStyle;
            this.cssClassNames = cssClassNames;
            this.firstRowNumber = firstRowNumber;
            this.frozenColumns = frozenColumns;
            this.height = height;
            this.showRowNumber = showRowNumber;
            this.sort = sort;
            this.sortAscending = sortAscending;
            this.sortColumn = sortColumn;
            this.width = width;

    }
}