
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule } from "primeng/primeng";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { PendingTasksCardsComponent } from './pending-tasks-cards.component';
@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules
    ],

    declarations: [
        PendingTasksCardsComponent,
    ],

    exports: [
        PendingTasksCardsComponent
    ],

    providers: []
})

export class PendingTasksCardsModule { }
