import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { CreditNote } from '../../modals/billings/credit-note';
import { DateUtil } from '../../helpers/date.util';
import { AddressComparision } from '../../modals/billings/address-comparision';
import { Address } from '../../modals/contracts/address';
import { GstBranchAddress } from '../../modals/KYC/gst-branch-address';

@Component({
    selector: 'sib-cn-preview',
    templateUrl: './cn-preview.component.html',
    styleUrls: ['./cn-preview.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class CNPreviewComponent implements OnInit, AfterViewInit, OnDestroy {

    _creditNote: CreditNote = new CreditNote();
    @Input() set creditNote(creditNote) {
        this._creditNote = creditNote;
        this._creditNote = JSON.parse(JSON.stringify(this._creditNote));
    }
    get creditNote() {
        return this._creditNote;
    }

    addComparision: AddressComparision = new AddressComparision();
    customerAddComparision: AddressComparision = new AddressComparision();

    ngOnInit() { }

    ngAfterViewInit() { }

    getFormattedDate(date) {
        return DateUtil.dategridFormatter(date);
    }

    ngOnDestroy() { }

    getAddressComparisonObject() {
        if (this.creditNote) {
            if (this.creditNote.supplier && this.creditNote.supplier.supplierGstDetail) {
                if (this.creditNote.supplier.supplierGstDetail.gstNo) {
                    this.addComparision.invGstNo = this.creditNote.supplier.supplierGstDetail.gstNo;
                    this.createComparisionObject(this.addComparision.invAdd, this.creditNote.supplier.supplierGstDetail.branchAddress);
                } else {
                    this.addComparision.invGstNo = 'N/A';
                    this.createComparisionObject(this.addComparision.invAdd, this.creditNote.supplier.address);
                }
                this.addComparision.invAdd2 = this.createComparisionObjectAddress(this.addComparision.invAdd);
                this.addComparision.invStateWithCode = this.createComparisionObjectStateWithCode(this.addComparision.invAdd);
            }
        }
        return this.addComparision;
    }

    getCustomerAddComparisionObj() {
        if (this.creditNote) {
            if (this.creditNote.invoiceGstDetail) {
                if (this.creditNote.invoiceGstDetail.gstNo) {
                    this.customerAddComparision.invGstNo = this.creditNote.invoiceGstDetail.gstNo;
                    this.createComparisionObject(this.customerAddComparision.invAdd, this.creditNote.invoiceGstDetail.branchAddress);
                } else {
                    this.customerAddComparision.invGstNo = "N/A";
                    this.createComparisionObject(this.customerAddComparision.invAdd, this.creditNote.campaign.customer);
                }
                this.customerAddComparision.invAdd2 = this.createComparisionObjectAddress(this.customerAddComparision.invAdd);
                this.customerAddComparision.invStateWithCode = this.createComparisionObjectStateWithCode(this.customerAddComparision.invAdd);
            }
        }
        return this.customerAddComparision;
    }

    createComparisionObject(targetObj: Address, sourceObj: Address | GstBranchAddress) {
        targetObj.address1 = sourceObj.address1;
        targetObj.address2 = sourceObj.address2;
        targetObj.city = sourceObj.city;
        targetObj.district = sourceObj.district;
        targetObj.state = sourceObj.state;
        targetObj.postalCode = sourceObj.postalCode;
    }

    createComparisionObjectAddress(sourceObj: Address) {
        let targetObj = "";
        if (sourceObj.address2) {
            if (sourceObj.city && sourceObj.city.name) {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.address2 + ', ' + sourceObj.city.name + ' - ' + sourceObj.postalCode;
                } else {
                    targetObj = sourceObj.address2 + ', ' + sourceObj.city.name;
                }
            } else {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.address2 + ' - ' + sourceObj.postalCode;
                } else {
                    targetObj = sourceObj.address2;
                }
            }
        } else {
            if (sourceObj.city && sourceObj.city.name) {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.city.name + ' - ' + sourceObj.postalCode;
                } else {
                    targetObj = sourceObj.city.name;
                }
            } else {
                if (sourceObj.postalCode) {
                    targetObj = sourceObj.postalCode;
                } else {
                    targetObj = '-';
                }
            }
        }
        return targetObj;
    }

    createComparisionObjectStateWithCode(sourceObj: Address) {
        let targetObj = "";
        if (sourceObj.state && sourceObj.state.name) {
            if (sourceObj.state.code) {
                targetObj = "State Name: " + sourceObj.state.name + ', ' + 'Code: ' + sourceObj.state.code;
            } else {
                targetObj = "State Name: " + sourceObj.state.name + ', ' + 'Code: ' + 'N/A';
            }
        } else {
            if (sourceObj.state && sourceObj.state.code) {
                targetObj = "State Name: " + 'N/A' + ', ' + 'Code: ' + sourceObj.state.code;
            } else {
                targetObj = "State Name: " + 'N/A' + ', ' + 'Code: ' + 'N/A';
            }
        }
        return targetObj;
    }

}
