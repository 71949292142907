<div>
  <sib-grid name="hoardingGrid" #sibGrid [columns]="hoardingGridColumnConfig" [config]="gridConfig"
    [selectedColumn]="hmSelectedColumns" [totalRecords]="totalRecords" (eEmitColumnSelection)="updatingUserGridConfig($event)"
    (eSelectionChange)="rowSelect($event)">
    <sib-grid-column field="image">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <a *ngIf="rowData?.images?.length > 0" class="link-1  after" (click)="viewImages(rowData)">View</a>
        <div *ngIf="rowData?.images?.length === 0">-</div>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="customId">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        {{rowData.customId}}
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="statusQueues.displayName">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div *ngIf="rowData.statusQueues?.length === 1; else dashTemplate">
          <div *ngIf="email === rowData?.statusQueues[0]?.employeeEmail || authorized">

            <a class="link-1  after" target="_blank"
              [routerLink]="(rowData.statusQueues[0].module === 'Campaign') ? ['/campaigns/view/', rowData.statusQueues[0].id] : ['/plans/view/', rowData.statusQueues[0].id]">{{rowData.statusQueues[0].displayName}}</a>
          </div>
          <div *ngIf="email !== rowData?.statusQueues[0]?.employeeEmail && !authorized">
            {{rowData.statusQueues[0].displayName}}
          </div>
        </div>
        <ng-template #dashTemplate>
          -
        </ng-template>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="statusQueues.employee">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div *ngIf="rowData.statusQueues?.length === 1; else dashTemplate">
          <div>
            {{rowData.statusQueues[0].employee}}
          </div>
        </div>
        <ng-template #dashTemplate>
          -
        </ng-template>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="statusQueues">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div *ngIf="rowData?.statusQueues?.length > 1; else falsyTemplate">
          <div
            *ngIf="(rowData?.status === 'INACTIVE' || rowData?.status === 'MIGRATED' || rowData?.status === 'EXPIRED'); else detailTemplate">
            {{getValue(rowData.status)}}
          </div>

        </div>
        <ng-template #detailTemplate>
          <!--  -->
          <a class="link-1  after" (click)="viewStatusDetail(rowData)">View Detail</a>
        </ng-template>
        <ng-template #falsyTemplate>
          <div *ngIf="rowData?.statusQueues?.length === 1; else defaultTemplate">
            <!-- Sanchit Mirg 11-05-2019 Changes for status for single status -->
            <!-- && (rowData.status === 'INACTIVE' || rowData.status === 'MIGRATED' || rowData.status === 'EXPIRED') -->
            <!-- ;  else singleTemplate -->
            <div *ngIf="rowData?.status !== 'BLOCKED'; else singleTemplate">
              {{getValue(rowData.status)}}
            </div>
          </div>
          <ng-template #singleTemplate>
            {{rowData.statusQueues[0].status}}
          </ng-template>
          <ng-template #defaultTemplate>
            {{getValue(rowData.status)}}
          </ng-template>
        </ng-template>
      </ng-template>
    </sib-grid-column>

    <!-- <sib-grid-column field="move">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <i class="fa fa-arrow-right" aria-hidden="true" (click)="checkHoarding(rowData, rowIndex)"></i>
      </ng-template>
    </sib-grid-column> -->

    <!-- <sib-grid-column field="tradedAsset">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown #tradedassetdd [options]="tradedAssetDropdown" [style]="{'width':'100%','margin-top':'8px'}"
          appendTo="body" placeholder="Select" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
        </p-dropdown>
      </ng-template>
    </sib-grid-column> -->


    <sib-grid-column field="lightType">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown #lighttypedd [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
          appendTo="body" placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
        </p-dropdown>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="rating">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown #ratingdd [options]="ratingDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
          placeholder="Select" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)"></p-dropdown>
      </ng-template>
    </sib-grid-column>

  </sib-grid>
</div>