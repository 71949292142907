import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Car } from '../modals/car';


@Injectable()
export class DemoCarService  {

  baseUrl:string;

  constructor(private http: Http) { 
    this.baseUrl = 'http://localhost:3000/';
  }

  getCars() {
    return this.http.get('assets/data/cars-small.json')
      .toPromise()
      .then(res => <Car[]>res.json().data)
      .then(data => { console.log("the data from service is", data);return data; });

      /* return this.http.get(this.baseUrl + 'data')
      .map((res:Response)=>{
        return res.json();
      }) */
  }
}
