import { InjectionToken, Injector } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { IQueryParams } from "./rest-interfaces";
import { Observable, Observer } from "rxjs";


export const AUTH_PERMISSION_URL = new InjectionToken<string>('AUTH_PERMISSION_URL');

export abstract class AuthPermissionsService<TGet, TPost, TFilter> extends BaseService<TGet, TPost, TFilter> {

    [x: string]: any;

    static SUCCESS_STATUS = 'success';
    static FAIL_STATUS = 'fail';

    /**
     * Abstract variable which would be given a value in the derived class.
     * 
     * @abstract
     * @type {string}
     * @memberOf ApiService
     */
    public urlPath: string;

    /**
     * Base url 
     * 
     * @type {string}
     * @memberOf ApiService
     */
    public baseUrl: string = '';

    /**
     * Creates an instance of ApiService.
     * 
     * @param {Http} http
     * @param {Injector} injector
     * 
     * @memberOf ApiService
     */
    constructor(public httpClient: HttpClient, public injector: Injector) {
        super(httpClient);
        this.urlPath = this.getUrl();
        // this.addInterceptor();
    }

    /**
     * Gets the url on which http request has to be made
     * 
     * @returns {string}
     * 
     * @memberOf ApiService
     */
    getUrl(): string {
        //let config: AppConfig = this.iSnjector.get(AppConfig)
        //let baseUrl = 
        this.baseUrl = this.injector.get(AUTH_PERMISSION_URL);
        return this.baseUrl + this.urlPath;
    }

    /**
     * Executes http GET method and returns an observable which returns the collection of data on subscription.
     * 
     * @param {IQueryParams} [queryParams]
     * @param {BaseServiceGetOpt} [options]
     * @returns
     * 
     * @memberOf ApiService
     */
    get(queryParams?: IQueryParams, subpath?: string): Observable<any> {
        return Observable.create((observer: Observer<TGet>) => {
            this._get(queryParams, subpath).subscribe((response: any) => {

                //const finalResult: any = this.handleFullResponse(response, this.dataModel.ListGet || this.dataModel.Get, options);
                observer.next(response);
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            })
        })

    }

    /**
     * Executes http POST method and returns an observable which returns the response on subscription.
     * 
     * @param {*} item
     * @param {IQueryParams} [queryParams]
     * @returns
     * 
     * @memberOf ApiService
     */
    create(item: any, queryParams?: IQueryParams, subPath?: string) {
        return Observable.create((observer: Observer<any>) => {
            this._post(item, queryParams, subPath).subscribe((response: any) => {
                observer.next(response)
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            })
        })
    }

    /**
     * Delete an item based on Id
     * 
     * @memberOf ApiService
     */
    delete(id?: number, postModel?: any, queryParams?: IQueryParams, subPath?: string) {
        return Observable.create((observer: Observer<TGet>) => {
            this._delete(id, postModel, queryParams, subPath).subscribe((response: any) => {
                observer.next(response);
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            })
        })
    }

    /**
     * Executes http PUT method and returns an observable which returns the response on subscription.
     * 
     * @param {*} item
     * @param {IQueryParams} [queryParams]
     * @returns
     * 
     * @memberOf ApiService
     */
    update(item: any, queryParams?: IQueryParams, subPath?: string) {
        return Observable.create((observer: Observer<any>) => {
            this._put(item, queryParams, subPath).subscribe((response: any) => {
                observer.next(response);
                // this.createMessage(response, 'update');
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            })
        })
    }

    patch(item: any, queryParams?: IQueryParams, subPath?: string) {
        return Observable.create((observer: Observer<any>) => {
            this._patch(item, queryParams, subPath).subscribe((response: any) => {
                observer.next(response);
                // this.createMessage(response, 'update');
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            });
        });
    }

    /**
     * 
     * API request to URL passed
     * @param  {} url
     * @returns Observable
     */
    getByUrl(url: string, queryParams?: IQueryParams): Observable<TGet> {
        return Observable.create((observer: Observer<TGet>) => {
            this._getByUrl(url, queryParams).subscribe((response: any) => {
                observer.next(response);
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            })
        })
    }

    getById(id: any, queryParams?: IQueryParams, subpath?: string, projection?: string): Observable<TGet> {
        return Observable.create((observer: Observer<TGet>) => {
            this._getById(id, queryParams, subpath, projection).subscribe((response: any) => {
                observer.next(response);
            }, (err) => {
                observer.error(err);
            }, () => {
                observer.complete();
            })
        })
    }
}