<div class="newEmail">
        <div *ngIf="isLoading" class="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
    <form #emailForm="ngForm">
        {{setTimer()}}
        <div *ngIf="invalid" class="validationMessage padding-0">Enter a valid email id</div>
        <!-- <div class="ui-g padding-0">
            <div class="ui-g-6 padding-0">
                From Sanchitm@meditab.com
            </div>
            <div class="ui-g-6 padding-0">
                <span class="control-label pointer padding-0" (click)="toggleCc()">Cc</span>
                <span class="control-label pointer padding-0" (click)="toggleBcc()">Bcc</span>
            </div>
            <div class="ui-g-12 padding-0">
                <div class="to">To</div>
                <div class="md-inputfield to-value">
                    <p-chips [allowDuplicate]="false" [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="correspondence.to" name="to" (onAdd)="validate('to')"
                        [ngClass]="{ 'validColor' : correspondence?.to?.length > 0, 'invalidColor' : correspondence?.to?.length === 0 }"
                        required></p-chips>
                </div>

            </div>
            <div *ngIf="cc" class="ui-g-12 padding-0 margin-top-12">
                <div class="to">Cc</div>
                <div class="md-inputfield to-value">
                    <p-chips [allowDuplicate]="false" [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="correspondence.cc" name="cc" (onAdd)="validate('cc')"></p-chips>
                </div>
            </div>
            <div *ngIf="bcc" class="ui-g-12 padding-0 margin-top-12">
                <div class="to">Bcc</div>
                <div class="md-inputfield to-value">
                    <p-chips [allowDuplicate]="false" [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="correspondence.bcc" name="bcc" (onAdd)="validate('bcc')"></p-chips>
                </div>
            </div>
            <div class="ui-g-12 padding-0 margin-top-12">
                <div class="to">Subject
                </div>
                <div class="md-inputfield subject-value">
                    <input type="text" pInputText placeholder="" id="subject" [(ngModel)]="correspondence.subject" id="subject" name="subject">
                </div>
                <div *ngIf="correspondence?.subject === '' || correspondence?.subject === null" class="validationMessage padding-0">Mail will be sent without a subject</div>
            </div>
            <div class="ui-g-12 padding-0 margin-top-12">
                <div class="to">Attach</div>
                <div class="md-inputfield">
                    <div>
                        <p-checkbox name="group1" label="PPT" binary="true" [(ngModel)]="correspondence.attachPPT" inputId="ppt"></p-checkbox>
                    </div>
                    <div>
                        <p-checkbox [styleClass]="'xlsxCBox'" name="group2" binary="true" label="XLSX" [(ngModel)]="correspondence.attachXLS" inputId="xlsx"></p-checkbox>
                        <p-multiSelect *ngIf="correspondence.attachXLS" defaultLabel="" [(ngModel)]="selectedColumns" [options]="modifiedColumns"
                            maxSelectedLabels="0" name="name" [dropdownIcon]="'fa fa-cog'" appendTo="body" (onPanelHide)="updatingUserGridConfig()"
                            [displaySelectedLabel]="false" [panelStyleClass]="'hm-showcol-drop-down'" [styleClass]="'select-column-multi-email'">
                        </p-multiSelect>
                    </div>
                    <div>
                        <p-checkbox name="group3" binary="true" label="PHOTOS" [(ngModel)]="correspondence.attachImages" inputId="pic"></p-checkbox>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="ui-g">
            <div class="ui-g-1 ">
                <label class="control-label">To</label>
            </div>
            <!-- to provide suggestion of the pocs of  the specific customer to send mail to  -->
            <div [ngClass]="{'ui-g-10': !cc && !bcc, 'ui-g-11': cc || bcc}">
                <p-autoComplete [(ngModel)]="correspondence.to" name="to" [suggestions]="pocs" 
                    (completeMethod)="filterToSend($event)" [multiple]="true" 
                   (onKeyUp)="getIdMailTo($event)" (onBlur)="onBlur($event)" >

                    <!-- <ng-template let-contact pTemplate="filterpoc">
                        <div class="ui-helper-clearfix">
                            <div>{{contact.fullName}}({{contact.emailId}})</div>
                        </div>
                    </ng-template> -->
                </p-autoComplete>

            </div>
            

            <!-- commented because there should be suggestions for the entered email -->
            <!-- <span class="md-inputfield">
                    <p-chips [allowDuplicate]="false" [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="correspondence.to"
                        name="to" (onAdd)="validate('to')" [ngClass]="{ 'validColor' : correspondence?.to?.length > 0, 'invalidColor' : correspondence?.to?.length === 0 }"
                        required></p-chips>
                </span> -->

            <div *ngIf="!cc && !bcc" [ngClass]="{'ui-g-1': !cc && !bcc, '': cc || bcc}">
                <span class="control-label pointer" (click)="toggleCc()">Cc</span>
                <span class="control-label pointer" (click)="toggleBcc()">Bcc</span>
            </div>
        </div>
        <div *ngIf="cc" class="ui-g">
            <div class="ui-g-1 ">
                <label class="control-label">Cc</label>
            </div>
            <div [ngClass]="{'ui-g-10': !bcc, 'ui-g-11': bcc}">
                <p-chips [allowDuplicate]="false" [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="correspondence.cc"
                    name="cc" (onAdd)="validate('cc')"></p-chips>
            </div>
            <div *ngIf="!bcc" [ngClass]="{'ui-g-1': !bcc, '': bcc}">
                <span class="control-label pointer" (click)="toggleBcc()">Bcc</span>
            </div>
        </div>
        <div *ngIf="bcc" class="ui-g">
            <div class="ui-g-1 ">
                <label class="control-label">Bcc</label>
            </div>
            <div [ngClass]="{'ui-g-10': !cc, 'ui-g-11': cc}">
                <p-chips [allowDuplicate]="false" [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="correspondence.bcc"
                    name="bcc" (onAdd)="validate('bcc')"></p-chips>
            </div>
            <div *ngIf="!cc" [ngClass]="{'ui-g-1': !cc, '': cc}">
                <span class="control-label pointer" (click)="toggleCc()">Cc</span>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-1 margin-top--3">
                <label class="control-label">Subject</label>
            </div>
            <div class="ui-g-11 margin-top-5">
                <input type="text" pInputText placeholder="" id="subject" [(ngModel)]="correspondence.subject"
                    id="subject" name="subject">
            </div>
        </div>
        <div *ngIf="correspondence?.subject === '' || correspondence?.subject === null"
            class="validationMessage padding-0">Mail will be sent without a subject</div>
        <div class="ui-g">
            <div class="ui-g-1 ">
                <label class="control-label">Attach</label>
            </div>
            <div *ngIf="!source" class="ui-g-11">
                <div class="ui-g-3 padding-9 control-label">
                    <p-checkbox name="group1" label="PPT" binary="true" [(ngModel)]="correspondence.attachPPT" 
                        inputId="ppt" ></p-checkbox>
                </div>
                <div class="ui-g-3 padding-9 control-label">
                    <p-checkbox [styleClass]="'xlsxCBox'" name="group2" binary="true" label="XLSX"
                        [(ngModel)]="correspondence.attachXLS" inputId="xlsx"></p-checkbox>
                    <p-multiSelect *ngIf="correspondence.attachXLS" defaultLabel="" [(ngModel)]="selectedColumns"
                        [options]="modifiedColumns" maxSelectedLabels="0" name="name" [dropdownIcon]="'fa fa-cog'"
                        appendTo="body" (onPanelHide)="updatingUserGridConfig()" [displaySelectedLabel]="false"
                        [panelStyleClass]="'hm-showcol-drop-down'" [styleClass]="'select-column-multi-email'">
                    </p-multiSelect>
                </div>
                <div class="ui-g-3 padding-9 control-label">
                    <p-checkbox name="group3" binary="true" label="PHOTOS" [(ngModel)]="correspondence.attachImages" 
                        inputId="pic" ></p-checkbox>
                </div>
            </div>
            <div *ngIf="source" class="ui-g-11">
                <div class="ui-g-3 padding-9 control-label">
                    <p-checkbox name="group1" label="INVOICE" binary="true" [(ngModel)]="correspondence.attachInvoice" 
                        inputId="invoice"></p-checkbox>
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-1 ">
                <label class="control-label">Message</label>
            </div>
            <div class="ui-g-11">
                <textarea name="message" cols="30" rows="11" class="form-control1" [(ngModel)]="correspondence.message"
                    placeholder="Type your text here" required></textarea>
            </div>
        </div>
    </form>
</div>