import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule, DropdownModule, OverlayPanelModule } from "primeng/primeng";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';
import { SrTaskComponent } from './sr-tasks.component';
import { StatusVisualizationModule } from '../status-visualization/status-visualization.module';
import { InvoiceService } from '../../../services/shared/invoice.service';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        ReactiveFormsModule,
        FormsModule,
        CurrencyMainPipe,
        RouterModule,
        DropdownModule,
        StatusVisualizationModule,
        OverlayPanelModule
    ],

    declarations: [
        SrTaskComponent,
    ],

    exports: [
        SrTaskComponent
    ],

    providers: [
        InvoiceService
    ]
})

export class SrTaskModule { }
