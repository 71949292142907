
import { distinctUntilChanged } from 'rxjs/operators';
/**
* @file hoarding-dialog.component
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/


import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ViewEncapsulation } from "@angular/core";
import { AreaQueryParams } from "../../../modals/queryparams/area-queryparams";
import { Area } from "../../../modals/Area/area";
import { ContractHoardings } from "../../../modals/contracts/contract-hoarding";
import { ActionDispatcher } from "../../../app state/action-dispatcher/action-dispatcher";
import { ContractsActionDispatcher } from "../../../contracts/action-dispatcher/action-dispatcher";
import { Contracts } from "../../../modals/contracts/contracts";
import { HoardingDialogAreaService } from "./hoarding-area.serivce";
import { CreateHoardingService } from "../../../services/shared/createHoarding.service";
import * as utils from '../../../helpers/utils';
import { HoardingCategoryService } from "../../../services/shared/hoardingCategory.service";
import { HoardingLightService } from "../../../services/shared/hoardingLight.service";
import { HoardingActionDispatcher } from "../../../hoardings/action-dispatcher/action-dispatcher";
import { DashboardDemo } from "../../../demo/view/dashboarddemo";
import { FileItem } from "../../../modals/files/file-item";
import { FirebaseService } from "../../../services/shared/firebase.service";
import { Ng2PicaService } from "ng2-pica/dist/src/ng2-pica.service";
import { CustomerUiService } from "../../../customers/services/customer-ui.service";
import { FileUpload, SelectItem } from "primeng/primeng";
import { getLocaleDateFormat } from "@angular/common/src/i18n/locale_data_api";
import { GeoCoordinate } from "../../../modals/hoardings/geo-coordinate";
import * as _ from "lodash";
import { Subject, Subscription } from "rxjs";
import { CurrencyPipe } from "@angular/common";
import { CurrencyHelperPipe } from "../../helpers/currency.pipe/currencyHelperPipe";
import { HoardingCategoryEnum } from "../../constants/hoarding-category-enum";
import { HoardingLightEnum } from "../../constants/hoarding-light-enum";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";
import { MapMarker } from "../../../modals/map/MapMarker";


import * as JSZip from 'jszip';
import { ImageService } from "../../../services/shared/image.service";
import { MultiMediaTypeEnum } from "../../constants/multi-media-type-enum";
import { ImageCategoryEnum } from "../../constants/image-category-enum";
import { HoardingConfigurationService } from "../../../services/shared/hoarding-configuration.service";
import { KioskTypeEnum } from "../../constants/kiosk-type.enum";
import { MediaTypeEnum } from "../../constants/media-type-enum";
import { KioskActionDispatcher } from "../../../contracts/action-dispatcher/kiosk-action-dispatcher";
import { MediaSubCategoryEnum } from "../../constants/media-sub-category-enum";
import { ImageDataDisplay } from '../../../modals/image/image-data';
import { ContractUiService } from '../../../contracts/services/contract-ui.services';
import { ChangesService } from '../../../services/shared/changes.service';
import { ActivityLogModuleEnum } from '../../constants/activity-log-module-enum';
import { AppUrls } from '../../../services/urls';
import { InventoryElevation } from '../../constants/inventory-elevation';
import { HoardingStatusEnum } from '../../constants/hoarding-status-enum';
import { CalculationService } from '../../../services/shared/calculationService';
import { CreateMediaService } from '../../../services/shared/create-media.service';
import { NotificationsMessages } from '../../../services/shared/notifications-messages';
import { ErrorUtil } from '../../../helpers/error.utils';

@Component({
  selector: 'sib-hoarding-dialog',
  templateUrl: './hoarding-dialog.component.html',
  styleUrls: ['./hoarding-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HoardingDialogComponent implements OnInit, OnDestroy {

  @Input() displayCreateHoarding = false;
  @Input() editMode = false;
  @Input() editContractHoardingMode = false;
  @Input() createHoardingMode = false;
  @Input() contract: Contracts = new Contracts();
  // @Input() hoardingData: ContractHoardings;
  _hoardingData: ContractHoardings
  @Input()
  set hoardingData(value: ContractHoardings) {
    this._hoardingData = value;
    if (this._hoardingData.kioskType) {
      this.newHoarding.kioskType = KioskTypeEnum[value.kioskType];
    }
  }

  get hoardingData(): ContractHoardings {
    return this._hoardingData;
  }
  _mediaType: string
  @Input() get mediaType(): string {
    return this._mediaType;
  }
  set mediaType(val: string) {
    this._mediaType = val;
    this.newHoarding.mediaType = val;
  }

  @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
  @Output() editReset: EventEmitter<boolean> = new EventEmitter();
  // @Output() eEmitUpdateContractHoarding: EventEmitter<any> = new EventEmitter();
  @Output() eEmitHoardingCreated: EventEmitter<any> = new EventEmitter();

  @ViewChild('hoardingForm') hoardingForm;
  @ViewChild('mapComponent') mapComponent;
  @ViewChild(FileUpload) fileUploadComponent: FileUpload;

  loading = false;
  isTradedAssets = false;
  CID = '';
  image: any[] = []; // to set the images of new hoarding
  editable = true;
  hoardingCategory: any[] = [];
  lightTypes: any[] = [];
  elevationOptions: any[] = [];
  pumpCategoryOptions: any[] = [];
  hoardingStatus: any[] = [];
  // createdHoarding: ContractHoardings = new ContractHoardings();
  dHeader: string;
  mapDialogHeader: string;
  dFooter: string;
  areaQueryParams: AreaQueryParams = new AreaQueryParams();
  areaList: Area[] = [];
  filteredAreaList: Area[] = [];
  area: Area[] = [];
  newHoarding: ContractHoardings | any = new ContractHoardings();
  msg: string;
  display = false;
  files: FileItem[] = [];
  filesUrl = [];
  // url2: any;  // to store the url of image
  imageObject: { source: any, title: string, width?: string, height?: string, featuredMedia?: boolean, multiMediaType: any, imageType?: any }[] = [];
  // selectedImage: string;
  // decimalNumbers: RegExp = /^(\d*\.)?\d+$/;  // for decimal number fields
  currency: RegExp = /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?$/;
  valueError = false;
  showMapDialog: boolean = false;
  options: any;
  overlays: any[] = [];
  query = "";
  count = 0;
  // zip = new JSZip();
  mapMarker: MapMarker = new MapMarker();
  initialImage = false;
  areaFieldColor: boolean = false;
  decimalNumbers: RegExp = /^(\d*\.)?\d+$/;  // for decimal number fields
  minDate: Date = new Date();
  maxDate: Date = new Date();
  /**
   * default % discount on gross price
   * this is set in Hoarding settings
   * 
   * @type {number}
   * @memberof HoardingDialogComponent
   */

  minPricePercentage: number;
  kioskTypes: any[] = [];
  categoryToActionDispatcher: Map<string, any> = new Map();
  private searchTerm = new Subject<any>();
  imageData: ImageDataDisplay = new ImageDataDisplay();

  startDateMin: Date;
  startDateMax: Date;

  endDateMin: Date;
  endDateMax: Date;
  minHoardingEndDate: Date;

  subscription: Subscription[] = [];
  updateHoardingUnsub: Subject<boolean> = new Subject<boolean>();
  oldHoarding: boolean = false;

  constructor(
    private appActionDispatcher: ActionDispatcher,
    private actionDispatcher: ContractsActionDispatcher,
    private hoardingActionDispatcher: HoardingActionDispatcher,
    private hoardingDialogAreaService: HoardingDialogAreaService,
    private createHoardingService: CreateHoardingService,
    private hoardingCategoryService: HoardingCategoryService,
    private hoardingLightService: HoardingLightService,
    private ng2PicaService: Ng2PicaService,
    public firebaseService: FirebaseService,
    private customerUiService: CustomerUiService,
    private cd: ChangeDetectorRef,
    private currencyHelperPipe: CurrencyHelperPipe,
    private imageService: ImageService,
    private hoardingConfigurationService: HoardingConfigurationService,
    private kioskActionDispatcher: KioskActionDispatcher,
    private contractUiService: ContractUiService,
    private changesService: ChangesService,
    private notificationServcie: NotificatoinsService,
    private calculationService: CalculationService,
    private createMediaService: CreateMediaService,
  ) {

    this.hoardingCategory = utils.createDropdown(HoardingCategoryEnum, false);
    this.lightTypes = utils.createDropdown(HoardingLightEnum, false);
    this.elevationOptions = utils.createDropdown(InventoryElevation, false);
    this.pumpCategoryOptions = utils.createDropdown(PumpCategoryEnumLocal, false);
    this.categoryToActionDispatcher.set('HOARDING', this.actionDispatcher);
    this.categoryToActionDispatcher.set('KIOSK', this.kioskActionDispatcher);
  }

  ngOnInit() {
    this.subscription.push(this.searchTerm.pipe(
      // .debounceTime(300)
      distinctUntilChanged())
      .subscribe((searchObject) => {
        this.appActionDispatcher.getAreaByCriteria(this.areaQueryParams);
        /* this.subscription.push(this.appActionDispatcher.areaList.subscribe((areaList) => {
          if (areaList !== undefined && areaList.length !== 0) {
            this.areaList = areaList;
            this.filteredAreaList = utils.filterData(this.areaList, 'name', searchObject.event);
            // this.cd.detectChanges();
          }
        })); */
      }));

    this.subscription.push(this.appActionDispatcher.areaList.subscribe((areaList) => {
      if (areaList !== undefined && areaList.length !== 0) {
        this.areaList = areaList;
        this.filteredAreaList = this.areaList;
        // this.filteredAreaList = utils.filterData(this.areaList, 'name', searchObject.event);
        // this.cd.detectChanges();
      }
    }));

    this.subscription.push(this.appActionDispatcher.isError.subscribe((isError) => {
      if (isError) {
        // this.resetHoardingData();
        this.appActionDispatcher.resetError();
      }
    }));

    this.subscription.push(this.firebaseService.filteItems$.subscribe((fileItems) => {
      if (fileItems && fileItems.length > 0) {

        this.setUrlToHoardingImagesObejct(fileItems);
        this.firebaseService.resetFileItems();
        //not sure why we are using this.
        this.initialImage = true;
        if (this.editMode) {
          this.hoardingActionDispatcher.updateHoarding(this.newHoarding);
        } else {
          this.updateHoardingAfterCreation(this.newHoarding);
        }
      }
    }));

    this.subscription.push(this.actionDispatcher.isHoardingUpdated.takeUntil(this.updateHoardingUnsub).subscribe((isHoardingUpdated) => {
      if (isHoardingUpdated) {
        this.updateHoardingUnsub.next(true);
        this.loading = false;
        this.setActivityLog();
        this.actionDispatcher.resetIsHoardingUpdated();
        this.initialImage = false;
        this.displayCreateHoarding = false;
        this.files = [];
        this.displayReset.emit(false);
      }
    }));

    this.subscription.push(this.actionDispatcher.hoardingWithImageUpdated.subscribe((isUpdated) => {
      if (isUpdated) {
        this.imageService.setFirebaseImageFolder(this.files, ImageCategoryEnum[this.newHoarding.mediaType], this.newHoarding.customId);
        this.imageService.setDatabaseImageFolder(this.newHoarding.images, ImageCategoryEnum[this.newHoarding.mediaType], this.newHoarding.customId)
        this.imageService.setDatatbaseImageName(this.newHoarding.images, this.imageData.getImageNameData(this.newHoarding))
        this.imageService.setFirebaseImageNames(this.files, this.newHoarding.images)
        this.firebaseService.uploadImagesToFirebase(this.files);
        this.setActivityLog();
        this.actionDispatcher.resetIsHoardingUpdated();
        this.actionDispatcher.resetUpdateHoardingWithImage();
      }
    }));

    this.subscription.push(this.actionDispatcher.isError.subscribe((isError) => {
      if (isError) {
        this.loading = false;
        this.actionDispatcher.resetError();
      }
    }));

    this.subscription.push(
      this.actionDispatcher.errorCreatingContractHoarding.subscribe((error) => {
        if (error) {
          this.loading = false;
          this.actionDispatcher.resetError();
        }
      })
    )

    this.subscription.push(this.hoardingActionDispatcher.isError.subscribe((isError) => {
      if (isError) {
        this.loading = false;
        this.display = false;
        this.newHoarding = new ContractHoardings();
        this.hoardingActionDispatcher.resetError();
      }
    }));

    this.subscription.push(this.hoardingActionDispatcher.isHoardingCreated.subscribe((isHoardingCreated) => {
      if (isHoardingCreated) {
        this.loading = false;
        this.displayCreateHoarding = false;
        this.setActivityLog();
        this.hoardingActionDispatcher.resetHoarding();
      }
    }));

    this.subscription.push(
      this.hoardingActionDispatcher.hoardingWithImageUpdatedHM.subscribe((isUpdated) => {
        if (isUpdated) {
          this.imageService.setFirebaseImageFolder(this.files, ImageCategoryEnum[this.newHoarding.mediaType], this.newHoarding.customId);
          this.imageService.setDatabaseImageFolder(this.newHoarding.images, ImageCategoryEnum[this.newHoarding.mediaType], this.newHoarding.customId)
          this.imageService.setDatatbaseImageName(this.newHoarding.images, this.imageData.getImageNameData(this.newHoarding))
          this.imageService.setFirebaseImageNames(this.files, this.newHoarding.images)
          this.firebaseService.uploadImagesToFirebase(this.files);
          this.setActivityLog();
          this.hoardingActionDispatcher.resetUpdateHoardingWithImageHM();
        }

      })
    )


    this.getMinPriceConfiguration()
    this.hoardingStatus = utils.createDropdown(LocalHoardingStatusEnum, false)
    // this.kioskTypes = utils.createDropdown(KioskTypeEnum, false)
    this.kioskTypes = utils.createDropdown(KioskTypeEnum, false);
    if (this.hoardingData && this.hoardingData.kioskType) {
      this.setSelectedKioskType();
    }
    // this.kioskTypes.push({ label: '', value: '' })
    // Object.keys(KioskTypeEnum).forEach((key) => {
    //   this.kioskTypes.push({ label: KioskTypeEnum[key], value: key });
    // });

    this.setHoardingMinMaxDate()
  }

  setSelectedKioskType() {
    this.newHoarding.kioskType = this.kioskTypes.filter(type => type.value === this.hoardingData.kioskType);
  }

  getMinPriceConfiguration() {
    this.hoardingConfigurationService.getHoardingConfiguration();

    this.subscription.push(
      this.hoardingConfigurationService.minPricePercentage
        .subscribe((minPricePercentage) => {
          this.minPricePercentage = minPricePercentage;
        })
    )
  }

  setHoardingMinMaxDate() {
    /* this.newHoarding.startDate = new Date(this.contract.startDate);
    this.newHoarding.endDate = new Date(this.contract.endDate);
    this.maxDate = new Date(this.contract.endDate);
    this.minDate = new Date(this.contract.startDate); */

    if (!this.editContractHoardingMode && !this.editMode) {
      if (new Date() > new Date(this.contract.startDate)) {
        this.newHoarding.startDate = new Date();
      } else {
        this.newHoarding.startDate = new Date(this.contract.startDate)
      }

      this.startDateMin = this.newHoarding.startDate;
      this.startDateMax = new Date(this.contract.endDate);

      this.newHoarding.endDate = new Date(this.contract.endDate);

      this.endDateMin = this.newHoarding.startDate;
      this.endDateMax = new Date(this.contract.endDate);
      this.newHoarding.campaignEndDate = new Date(this.newHoarding.campaignEndDate);
    } else if (this.editContractHoardingMode || this.editMode) {
      // this.newHoarding.endDate = new Date(this.contract.endDate);
      // this.startDateMin = this.hoardingData.status && (HoardingStatusEnum[this.hoardingData.status] === HoardingStatusEnum["AVAILABLE"] || HoardingStatusEnum[this.hoardingData.status] === HoardingStatusEnum["BLOCKED"]) ? new Date() : new Date(this.newHoarding.startDate);


      if (HoardingStatusEnum[this.hoardingData.status] === HoardingStatusEnum['NOT_AVAILABLE']) {
        this.startDateMin = new Date(this.newHoarding.startDate)
        this.startDateMax = new Date(this.newHoarding.startDate);
        //Raveena:  user should be able to reduce the end date of the hoarding if it more than the campaign end date it is in
        this.endDateMin = new Date(this.hoardingData.campaignEndDate);
        // this.startDateMax = new Date(this.hoardingData.startDate)


        // this.newHoarding.endDate = new Date(this.newHoarding.endDate);
      } else if (HoardingStatusEnum[this.hoardingData.status] === HoardingStatusEnum['BLOCKED'] || HoardingStatusEnum[this.hoardingData.status] === HoardingStatusEnum['AVAILABLE'] || HoardingStatusEnum[this.hoardingData.status] === HoardingStatusEnum['INACTIVE']) {
        this.newHoarding.startDate = new Date(this.hoardingData.startDate);
        var currentDate = new Date();

        if (this.calculationService.setToBeginning(new Date(currentDate)).valueOf() === this.calculationService.setToBeginning(new Date(this.hoardingData.startDate)).valueOf()) {
          this.startDateMin = new Date(this.hoardingData.startDate);
          this.startDateMax = new Date(this.contract.endDate);
          this.endDateMin = new Date(this.newHoarding.startDate);

        } else if (this.calculationService.setToBeginning(new Date(currentDate)).valueOf() < this.calculationService.setToBeginning(new Date(this.hoardingData.startDate)).valueOf()) {
          this.startDateMin = new Date(this.contract.startDate) > new Date() ? new Date(this.contract.startDate) : new Date();
          this.startDateMax = new Date(this.contract.endDate);
          this.endDateMin = new Date(this.newHoarding.startDate);

        } else if (this.calculationService.setToBeginning(new Date(currentDate)).valueOf() > this.calculationService.setToBeginning(new Date(this.hoardingData.startDate)).valueOf()) {
          this.oldHoarding = true;
          this.endDateMin = new Date(currentDate);


        }
      }
      // else if (HoardingStatusEnum[this.hoardingData.status] === HoardingStatusEnum['INACTIVE']) {
      //   this.newHoarding.startDate = new Date(this.hoardingData.startDate);
      //   var currentDate = new Date();
      //   if (this.calculationService.setToBeginning(new Date(currentDate)).valueOf() >= this.calculationService.setToBeginning(new Date(this.hoardingData.startDate)).valueOf()) {
      //     this.endDateMin = new Date();
      //   } else {
      //     this.endDateMin = new Date(this.newHoarding.startDate)
      //   }

      // }
      this.minHoardingEndDate = new Date(this.hoardingData.endDate);
      // this.endDateMin = this.hoardingData.campaignEndDate ? new Date(this.hoardingData.campaignEndDate) : new Date(this.newHoarding.startDate);

      this.endDateMax = new Date(this.contract.endDate);
    }
  }

  /**
   *to reset the value of vendor name when  
   * checkbox is deselected
   * @memberof HoardingDialogComponent
   */
  tradedAssetCheckboxChange() {
    if (!this.isTradedAssets) {
      this.newHoarding.vendorName = null;
    }
  }


  /**
   * called when create hoarding 
   * dialog is to be displayed
   * @memberof HoardingDialogComponent
   */
  displayHoardingForm() {
    this.editMode = false;
    this.dHeader = `Create ${MediaSubCategoryEnum[this.newHoarding.mediaType]}`;
    this.dFooter = "CREATE";
    this.newHoarding.city = '';
    this.setInitialValueForChanges();
  }


  toggleIsTradedAssets() {
    this.isTradedAssets = !(this.isTradedAssets);
  }


  /**
   * setting rating msg 
   * 
   * @param {any} event 
   * @memberof HoardingDialogComponent
   */
  handleRating(event) { // rating + comment
    this.msg = "You have rated " + event.value;
  }


  /**
   * setting cancek rating msg
   * 
   * @param {any} event 
   * @memberof HoardingDialogComponent
   */
  handleCancelRating(event) {
    this.msg = "Rating Cancelled";
  }


  filterAreas(event) {
    this.count = 0;
    const query = event.query;
    if (query !== "") {
      this.query = query;
      this.areaQueryParams.name = query;
      this.searchTerm.next({ searchTerm: query, event: event });
    } else {
      this.query = query;
      this.newHoarding.city = "";
      this.newHoarding.district = "";
      this.newHoarding.state = "";
      this.resetLatLng();
      this.mapComponent.resetMarker();
    }
  }

  onDropdownClick(event) {
    if (this.query === "" && event.query === "") {
      this.query = "a";
      this.areaQueryParams.name = "a";
      this.searchTerm.next({ searchTerm: this.query, event: event });
    } else if (event.query !== "") {
      this.areaQueryParams.name = event.query;
      this.searchTerm.next({ searchTerm: event.query, event: event });
    }
  }

  /**
   * on area selection setting the city, district and state
   * @memberof HoardingDialogComponent
   */
  selectArea() {
    // Raveena | 16-05-2019 POJO changes for optimization
    this.newHoarding.city = this.newHoarding.area.city.name;
    this.newHoarding.district = this.newHoarding.area.city.district.name;
    this.newHoarding.state = this.newHoarding.area.city.district.state.name;

    this.actionDispatcher.resetAreasList();
    //Sanchit | 07-12-2017 Resetting the geocordinate on change of area
    this.resetLatLng();

    //Raveena | 16-05-2019 Removed because of optimisation change
    /* if (this.newHoarding.area.geoCoordinate.latitude && this.newHoarding.area.geoCoordinate.longitude) {
      this.options = {
        center: { lat: this.newHoarding.area.geoCoordinate.latitude, lng: this.newHoarding.area.geoCoordinate.longitude },
        zoom: 15
      };
    } else { */
    this.options = {
      center: { lat: 22.258652, lng: 71.1923805 },
      zoom: 15
      // }
    }


  }

  /**
   * when an image file 
   * is selected
   * @param {any} event 
   * @memberof HoardingDialogComponent
   */
  onFileSelection(event) {
    // this.files.push(new FileItem(event.files[0], 'images'));
    // this.files = [...this.imageService.getFirebaseImageObjects(event.files)];
    this.imageService.getFirebaseImageObjects(event.files).forEach((object) => {
      this.files.push(object);

    });
    this.imageService.getDatabaseImageObjects(event.files, MultiMediaTypeEnum['PICTURE']);
    this.subscription.push(this.imageService.databaseImageObjects$.subscribe((databaseImageObjects) => {
      if (databaseImageObjects && databaseImageObjects.length > 0) {
        // this.newHoarding.images = [];
        this.newHoarding.images = this.newHoarding.images || [];
        databaseImageObjects.forEach((object) => {
          this.newHoarding.images.push(object);
          this.filesUrl.push(object.url);
        });
      }

    }));

    // this.uploadImagesToFirebase(this.files)
  }


  /**
   * setting the minimum price
   * based on gross price
   * @param {*} event 
   * @memberof HoardingDialogComponent
   */
  keyUp(event: any) {
    this.newHoarding.minimumPrice = Math.ceil((this.minPricePercentage / 100) * (event.target.value));
  }


  /**
   * to check the values of min cost and gross price
   * 
   * @memberof HoardingDialogComponent
   */
  checkValues() {
    if (Number(this.newHoarding.minimumPrice) > Number(this.newHoarding.grossPrice)) {
      this.newHoarding.minimumPrice = null;
      this.valueError = true;
    } else {
      this.valueError = false;
    }
  }



  /**
   * called when creating or
   * editing the hoarding
   * @memberof HoardingDialogComponent
   */
  createHoardings() {
    if (!this.validateDates) return;
    if (this.editMode || this.editContractHoardingMode) {
      this.updateHoardings();
    } else {
      this.loading = true;
      this.newHoarding.tradedAsset = this.isTradedAssets;
      //Raveena | 16-05-2019 Getting Contract Wrapper, changes for optimisation
      this.newHoarding.contract = this.contractUiService.getContractWrapper(this.contract);
      this.createMediaService.create(this.newHoarding, null, ImageCategoryEnum[this.newHoarding.mediaType] + AppUrls.SAVE).subscribe((response) => {
        this.eEmitHoardingCreated.emit(true)
        // this.subscription.push(this.actionDispatcher.hoarding.subscribe((hoarding) => {
        this.cd.detectChanges();
        if (response.id !== undefined && response.id !== null) {
          if (response.customId !== null && response.customId !== undefined) {
            this.imageService.setFirebaseImageFolder(this.files, ImageCategoryEnum[response.mediaType], response.customId);
            // this.imageService.setFirebaseImageName(this.files, this.imageData.getImageNameData(response))
            this.imageService.setDatabaseImageFolder(response.images, ImageCategoryEnum[response.mediaType], response.customId);
            this.imageService.setDatatbaseImageName(response.images, this.imageData.getImageNameData(response))
            this.imageService.setFirebaseImageNames(this.files, response.images)
            this.firebaseService.uploadImagesToFirebase(this.files);
            response.startDate = new Date(response.startDate);
            response.endDate = new Date(response.endDate);
            this.newHoarding = _.cloneDeep(response);
            for (let i = 0; i < this.filesUrl.length; i++) {
              this.newHoarding.images[i].url = this.filesUrl[i];
            }
            this.actionDispatcher.resetHoarding();
            this.actionDispatcher.resetIsHoardingCreated();
            this.cd.detectChanges();

            if (this.newHoarding.images.length === 0) {
              this.loading = false;
              this.displayCreateHoarding = false;
            }

          }
        }
        // }));
      },
        (error) => {

          const errorObject = ErrorUtil.getErrorObject(error);
          if (errorObject.code === 417 || errorObject.code === 412) {
            if (errorObject.message === 'Tenant Setup is incomplete please contact ShowItBig Support!') {
              this.displayCreateHoarding = false;
              this.eEmitHoardingCreated.emit(false);
              // this.displaySetupIncompleteDialog = true;
            } else {
              this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);

            }
          } else {
            this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
          }
        });

      // this.categoryToActionDispatcher.get(this.newHoarding.mediaType).createHoardingFromContract(this.newHoarding);
      // this.actionDispatcher.createHoardingFromContract(this.newHoarding);
      // this.subscription.push(this.actionDispatcher.isHoardingFromContractCreated.subscribe((isHoardingCreated) => {
      //   this.cd.detectChanges();
      //   if (isHoardingCreated !== undefined && isHoardingCreated === true) {
      //     this.eEmitHoardingCreated.emit(true)
      //     this.subscription.push(this.actionDispatcher.hoarding.subscribe((hoarding) => {
      //       this.cd.detectChanges();
      //       if (hoarding.id !== undefined && hoarding.id !== null) {
      //         if (hoarding.customId !== null && hoarding.customId !== undefined) {
      //           this.imageService.setFirebaseImageFolder(this.files, ImageCategoryEnum[hoarding.mediaType], hoarding.customId);
      //           // this.imageService.setFirebaseImageName(this.files, this.imageData.getImageNameData(hoarding))
      //           this.imageService.setDatabaseImageFolder(hoarding.images, ImageCategoryEnum[hoarding.mediaType], hoarding.customId);
      //           this.imageService.setDatatbaseImageName(hoarding.images, this.imageData.getImageNameData(hoarding))
      //           this.imageService.setFirebaseImageNames(this.files, hoarding.images)
      //           this.firebaseService.uploadImagesToFirebase(this.files);
      //           hoarding.startDate = new Date(hoarding.startDate);
      //           hoarding.endDate = new Date(hoarding.endDate);
      //           this.newHoarding = _.cloneDeep(hoarding);
      //           for (let i = 0; i < this.filesUrl.length; i++) {
      //             this.newHoarding.images[i].url = this.filesUrl[i];
      //           }
      //           this.actionDispatcher.resetHoarding();
      //           this.actionDispatcher.resetIsHoardingCreated();
      //           this.cd.detectChanges();

      //           if (this.newHoarding.images.length === 0) {
      //             this.loading = false;
      //             this.displayCreateHoarding = false;
      //           }

      //         }
      //       }
      //     }));
      //   }
      // }));

    }
  }


  /**
   *updating the image folder after it has been saved 
   * 
   * @param {any} hoarding 
   * @memberof HoardingDialogComponent
   */
  updateHoardingAfterCreation(hoarding) {
    this.actionDispatcher.updateHoardingAfterCreation(hoarding);
    this.cd.detectChanges();
  }

  /**
   * update hoardings
   * 
   * @memberof HoardingDialogComponent 
   */
  updateHoardings() {
    this.loading = true;
    this.newHoarding.tradedAsset = this.isTradedAssets;
    //if the screen is opened from contract-hoarding Module then this block will be executed
    if (this.editContractHoardingMode) {
      this.updateHoardingFromContractModule()
    } else {
      // this is called when edited from hoarding Master module
      this.updateHoardingFromHoardingModule()
    }

    /* if (this.files.length > 0) {
      this.actionDispatcher.updateHoardingWithImage(this.newHoarding);
    } else {
      this.hoardingActionDispatcher.resetHoarding();
      this.hoardingActionDispatcher.updateHoarding(this.newHoarding);
      // this.editReset.emit(false);
    } */
  }

  updateHoardingFromContractModule() {
    if (this.files.length > 0) {
      this.actionDispatcher.updateHoardingWithImage(this.newHoarding);
    } else {
      this.actionDispatcher.updateHoardingFromContractModule(this.newHoarding)
    }
  }

  updateHoardingFromHoardingModule() {
    if (this.files.length > 0) {
      //HM stands for Hoarding Module
      this.hoardingActionDispatcher.updateHoardingWithImageHM(this.newHoarding)
    } else {
      this.hoardingActionDispatcher.resetHoarding();
      this.hoardingActionDispatcher.updateHoarding(this.newHoarding);
    }
  }


  /**
   * called when the user 
   * clicks on cancel
   * @param {any} hoardingForm 
   * @memberof HoardingDialogComponent
   */
  cancelHoardings() {
    this.loading = true;
    this.resetHoardingData();
    this.loading = false;
    this.displayCreateHoarding = false;
  }


  /**
   * setting the values of 
   * hoarding form when opened 
   * in edit mode
   * @param {any} hoardingData 
   * @memberof HoardingDialogComponent
   */
  editHoardingForm(hoardingData) {
    this.loading = true;
    if (hoardingData.status === 'NOT_AVAILABLE') {
      this.editable = false;
    } else {
      this.editable = true;
    }

    this.dHeader = `Edit ${MediaSubCategoryEnum[hoardingData.mediaType]} (  ${hoardingData.customId} )`;
    this.mapDialogHeader = `Hoarding Location (  ${hoardingData.customId} -  ${hoardingData.city}, ${hoardingData.location} -  ${hoardingData.size} ` + (hoardingData.statusQueues.length && hoardingData.statusQueues[0].displayName ? ` - ${hoardingData.statusQueues[0].displayName} )` : ')');
    this.dFooter = "SAVE";

    this.newHoarding = hoardingData;

    this.setInitialValueForChanges();

    this.isTradedAssets = this.newHoarding.tradedAsset;
    this.newHoarding.startDate = new Date(hoardingData.startDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
    this.newHoarding.endDate = new Date(hoardingData.endDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
    this.CID = hoardingData.contract.id;
    if (this.newHoarding.rating) {
      this.msg = "You have rated " + this.newHoarding.rating;
    } else {
      this.msg = "";
    }


    /* if (this.newHoarding.geoCoordinate.latitude && this.newHoarding.geoCoordinate.longitude) {

      this.options = {
        center: { lat: this.newHoarding.geoCoordinate.latitude, lng: this.newHoarding.geoCoordinate.longitude },
        zoom: 12
      };

      this.mapMarker.latitude = this.newHoarding.geoCoordinate.latitude;
      this.mapMarker.longitude = this.newHoarding.geoCoordinate.longitude;
      this.mapMarker.location = this.newHoarding.customId + ' ' + this.newHoarding.location + '(' + this.newHoarding.width + 'X' + this.newHoarding.height + ')'
      this.overlays.push(this.mapMarker)
      this.overlays = JSON.parse(JSON.stringify(this.overlays))
    } */
    this.loading = false;
  }

  setInitialValueForChanges() {
    if (MediaSubCategoryEnum[this.newHoarding.mediaSubCategory] === MediaSubCategoryEnum.HOARDING) {
      this.changesService.setInitialHoarding(_.cloneDeep(this.newHoarding));
    } else if (MediaSubCategoryEnum[this.newHoarding.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) {
      this.changesService.setInitialKiosk(_.cloneDeep(this.newHoarding));
    } else {
      // do nothing
    }
  }

  /**
   * called when hoarding dialog
   * is opened
   * @param {any} hoardingForm 
   * @memberof HoardingDialogComponent
   */
  showHoardingDialog() {
    this.actionDispatcher.resetAreasList();
    this.filteredAreaList = [];
    if (this.contract && this.contract.id) {
      this.CID = this.contract.id;
      this.newHoarding.contract = this.contract;
    }
    if (this.editMode || this.editContractHoardingMode) {
      setTimeout(() => {
        this.editHoardingForm(this.hoardingData);
      }, 500);
    } else {
      this.image = [];
      this.imageObject = [];
      this.CID = this.contract.id;
      this.displayHoardingForm();
    }
  }


  /**
   * removing any selected image 
   * @param {any} index 
   * @memberof HoardingDialogComponent
   */
  removeImage(index) {
    this.loading = true;
    this.files.splice(index, 1);
    this.newHoarding.images.splice(index, 1);
    this.image.splice(index, 1);
    this.loading = false;
  }


  /**
   * resetting the hoardig data to null
   * 
   * @memberof HoardingDialogComponent
   */
  resetHoardingData() {
    this.newHoarding.geoCoordinate = new GeoCoordinate();
    this.newHoarding = new ContractHoardings();
    this.hoardingData = new ContractHoardings();
    this.image = [];
    this.imageObject = [];
    this.filteredAreaList = [];
    this.areaList = [];
    this.msg = "";
    this.isTradedAssets = false;
    if (this.editMode || this.editContractHoardingMode) {
      this.editMode = false;
      this.editContractHoardingMode = false;
      this.editReset.emit(false);
    }
    this.displayReset.emit(false);
    this.cd.detach();
  }
  setZero() {
    if (this.newHoarding.elevation === 'GROUND_SITE') {
      this.newHoarding.elevationHeight = 0;
      this.newHoarding.numberOfStories = 0;
      this.newHoarding.storiesStartFrom = 0;
      this.newHoarding.storiesEndAt = 0;
    }
  }

  showMap() {

    if (this.newHoarding.geoCoordinate.latitude && this.newHoarding.geoCoordinate.longitude && this.newHoarding.geoCoordinate.longitude != 0 && this.newHoarding.geoCoordinate.latitude != 0) {

      this.options = {
        center: { lat: typeof (this.newHoarding.geoCoordinate.latitude) === 'string' ? parseFloat(this.newHoarding.geoCoordinate.latitude) : this.newHoarding.geoCoordinate.latitude, lng: typeof (this.newHoarding.geoCoordinate.longitude) === 'string' ? parseFloat(this.newHoarding.geoCoordinate.longitude) : this.newHoarding.geoCoordinate.longitude },
        zoom: 12
      };

      this.mapMarker.latitude = typeof (this.newHoarding.geoCoordinate.latitude) === 'string' ? parseFloat(this.newHoarding.geoCoordinate.latitude) : this.newHoarding.geoCoordinate.latitude;
      this.mapMarker.longitude = typeof (this.newHoarding.geoCoordinate.longitude) === 'string' ? parseFloat(this.newHoarding.geoCoordinate.longitude) : this.newHoarding.geoCoordinate.longitude
      this.overlays.push(this.mapMarker)
    } else {
      this.options = {
        center: { lat: 23.02579, lng: 72.58727 },
        zoom: 12
      }
    }
    this.showMapDialog = true;
  }

  setLatLng(event) {
    this.newHoarding.geoCoordinate.latitude = event.latitude;
    this.newHoarding.geoCoordinate.longitude = event.longitude;
    this.showMapDialog = false;
  }

  resetLatLng() {
    this.newHoarding.geoCoordinate.latitude = null;
    this.newHoarding.geoCoordinate.longitude = null;
  }

  onStartDateChange() {
    this.endDateMin = this.newHoarding.startDate;
    if (this.editContractHoardingMode || this.editMode) {
      this.validateDates();
    }
    if (this.newHoarding.endDate !== null && !this.editContractHoardingMode && !this.editMode) {
      if (this.newHoarding.endDate < this.newHoarding.startDate) {
        this.newHoarding.endDate = null;
      }
    }
  }

  getSeperators(value) {
    return this.currencyHelperPipe.transform(value);
  }

  onHideMap() {
    this.mapComponent.resetMarker();
    this.overlays = [];
  }

  calculateKioskSize() {
    if (this.newHoarding.kioskType === 'DOUBLE_SIDE') {
      this.newHoarding.squareFeet = this.getKioskSize(2)
    } else if (this.newHoarding.kioskType === 'SINGLE_SIDE') {
      this.newHoarding.squareFeet = this.getKioskSize(1)
    } else if (this.newHoarding.kioskType === 'CUBICLE') {
      this.newHoarding.squareFeet = this.getKioskSize(4)
    }
  }

  getKioskSize(sides) {
    return this.newHoarding.width * this.newHoarding.height * this.newHoarding.quantity * sides
  }

  validateDates() {
    if (this.calculationService.setToBeginning(new Date(this.newHoarding.startDate)).valueOf() > this.calculationService.setToBeginning(new Date(this.newHoarding.endDate)).valueOf()) {
      this.newHoarding.endDate = new Date(this.newHoarding.startDate);
      this.endDateMin = new Date(this.newHoarding.endDate);
      this.endDateMax = new Date(this.contract.endDate);

    }
    var hoardingStartDate = this.newHoarding.startDate;
    var hoardingEndDate = this.newHoarding.endDate;
    // var contractStartDate = this.contract.startDate;
    // var contractEndDate = this.contract.endDate;
    if (hoardingStartDate < this.startDateMin || hoardingStartDate > this.startDateMax || hoardingStartDate > hoardingEndDate) {
      this.newHoarding.startDate = new Date(this.startDateMin);
      this.newHoarding.endDate = new Date(this.startDateMax);
      return false;
    }

    if (hoardingEndDate < this.endDateMin || hoardingEndDate > this.endDateMax || hoardingEndDate < hoardingStartDate) {
      this.newHoarding.endDate = this.endDateMax;
      return false;
    }

    return true;
  }

  getChangedType() {
    if (MediaSubCategoryEnum[this.newHoarding.mediaSubCategory] === MediaSubCategoryEnum.HOARDING) {
      return ActivityLogModuleEnum.HOARDING;
    } else if (MediaSubCategoryEnum[this.newHoarding.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) {
      return ActivityLogModuleEnum.KIOSK;
    } else {
      return null;
    }
  }


  setActivityLog() {
    this.changesService.create(this.changesService.getActivityLogs(), null, AppUrls.SAVE).subscribe(
      (response) => {
        console.log("response", response);
        this.changesService.resetData();
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
      }
    );
  }

  ngOnDestroy() {
    this.overlays = [];
    this.cd.detach();

    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
    this.updateHoardingUnsub.unsubscribe();

  }

  getDate(date) {
    return new Date(date)
  }

  setUrlToHoardingImagesObejct(fileItems) {
    if (fileItems) {
      this.newHoarding.images.forEach((image, index) => {
        fileItems.forEach((fileItem) => {
          if (image.name === fileItem.file.name) {
            this.newHoarding.images[index].url = fileItem.url;

          }
        })
      })
    }
  }

  uploadImagesToFirebase(files) {
    for (let i = 0; i < files.length; i++) {
      if (this.files[i].isQueued === false) {
        this.imageService.setFirebaseImageFolder(this.files, ImageCategoryEnum[this.newHoarding.mediaType], this.newHoarding.customId);
        this.firebaseService.uploadImageToFirebase(this.files[i]);
        this.files[i].isQueued = true;
      }
    }
  }

  setDateFormat(date) {
    date = new Date(date);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    // date.setDate(date.getDate());
    // date.setMonth(date.getMonth());
    // date.setFullYear(date.getFullYear());
    return date;
  }
}


export enum LocalHoardingStatusEnum {
  AVAILABLE = "Active",
  INACTIVE = "Inactive",
}

export enum PumpCategoryEnumLocal {
  NATIONAL_HIGHWAY = "National Highway", //for active
  URBAN_Z = "Urban - Z", //urban-z
  RURAL = "Rural", //rural
  HIGHWAY = "Highway", // Highway
}
