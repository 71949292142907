<div id="bottomAd" style="display: none">&nbsp;</div>
<div class="cn-view">

    <div *ngIf="isLoading" class="loading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div class="ui-g ctext-right">

        <ul class="c-list-inline">

            <li class="float-left">
                <sib-back></sib-back>
            </li>

            <ng-container
                *ngIf="creditNote.status === 'FULL_CN_APPROVED' || creditNote.status === 'PARTIAL_CN_APPROVED'">

                <ng-container *permission="'update:tally-invoice'">
                    <li *ngIf="checkIsTallyEntered() !== 'N'">
                        <label class="font-12 font-weight-500 label-vCenter margin-right-5">Entered in Tally</label>
                        <p-checkbox name="tallyEntered" binary="true" inputId="tallyEntered"
                            [(ngModel)]="isTallyEnteredBoolean" (onChange)="onTallyEnteredChange($event)"></p-checkbox>
                    </li>
                </ng-container>

                <ng-container *permission="'update:invoice-print'">
                    <li *ngIf="checkIsPrinted() !== 'N'">
                        <label class="font-12 font-weight-500 label-vCenter margin-right-5">Mark as Printed</label>
                        <p-checkbox name="printed" binary="true" inputId="printed" [(ngModel)]="isPrintedBoolean"
                            (onChange)="onPrintedChange($event)"></p-checkbox>
                    </li>
                </ng-container>

                <ng-container *permission="'print:invoice'">
                    <li class="ctext-left printMenuClass" *ngIf="checkIsPrinted() !== 'N'">
                        <label class="font-12 font-weight-500 label-vCenter margin-right-5">CN Printing Options</label>
                        <button pButton class="mtab-secondary c-edit-btn border-rad-2 font-button"
                            title="CN Printing Options" icon="fa fa-print" (click)="printMenu.toggle($event)"></button>
                    </li>
                </ng-container>

            </ng-container>

        </ul>

        <div>

        </div>

    </div>

    <p-overlayPanel #printMenu>
        <!-- <span class="printing-header">Printing Options</span>
        <div class="detail-options">
            <span class="print-original"> Original</span>
            <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'" [(ngModel)]="isDuplicate">
            </p-inputSwitch>
            <span class="print-options">Duplicate</span>
        </div>
        <div *permission="'print:invoice-letterhead-option'" class="detail-options">
            <span class="print-options">With LetterHead</span>
            <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'"
                [(ngModel)]="isWithoutLetterHead">
            </p-inputSwitch>
            <span class="print-options">Without LetterHead</span>
        </div>
        <div class="print-button">
            <button class="mtab-primary" pButton label="PRINT"
                (click)="printingItem(isDuplicate, isWithoutLetterHead)"></button>
        </div> -->
        <!-- creditNote?.invoiceFormat -->
        <sib-print-pannel-layout [printConfig]="creditNote?.printConfiguration" [selectedInvoiceFormat]="'FULL_DETAIL'"
            [showSave]="false" [disableInvoiceFormatDropdown]="true"
            (eEmitPreviewPrintItems)="previewPrintItems($event)" (eEmitPrintItems)="printItems($event)"
            (eEmitChangedPrintType)="onPrintTypeChange($event)"
            (eEmitChangedPrintLetterHead)="onPrintLetterHeadChange($event)">
        </sib-print-pannel-layout>
    </p-overlayPanel>

    <div class="ui-g card padding-0">

        <div class="ui-g-4">
            <table>
                <tr>
                    <th>Status:</th>
                    <td class="{{getClass(creditNote?.status)}}">{{getStatus(creditNote?.status)}}</td>
                </tr>
            </table>
        </div>

        <div class="ui-g-4">
            <table>
                <tr>
                    <th>Raised by:</th>
                    <td>{{creditNote?.employee?.fullName}}</td>
                </tr>
            </table>
        </div>

        <div class="ui-g-4">
            <table>
                <tr>
                    <th>Note:</th>
                    <td *ngIf="creditNote?.billingNotes?.length > 0"><a class="note-link"
                            (click)="displayBillingNotes = true">{{creditNote?.billingNotes[creditNote.billingNotes.length
                                    - 1]?.message}}</a></td>
                    <td *ngIf="creditNote?.billingNotes?.length === 0">N/A</td>
                </tr>
            </table>
        </div>

    </div>

    <div class="ui-g card padding-0">
        <div class="ui-g-12">
            <sib-cn-preview *ngIf="creditNote.id" [creditNote]="creditNote"></sib-cn-preview>
        </div>
    </div>

</div>