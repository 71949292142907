export class NotificationsMessages {

    // all
    public static TECHNICAL_ISSUE = 'Some technical issue';
    public static REFRESH = 'Please Refresh';
    public static DOWNLOADING_PROGRESS = 'Downloading is in progress';
    public static DOWNLOAD = 'Download';
    public static ERROR = "Error!!";
    public static SERVER_ERROR = "Server Error";
    public static FILE_UPLOAD = "File Upload.";
    public static FILE_UPLOAD_SUCESSFULL = "File Uploaded Succesfully";
    public static TRY_AGAIN = "Please Try Again";
    public static INFORMATION = "Information"

    // plan
    public static MARK_ROTATIONAL = "Plan Marked As Rotational";
    public static ROTATIONAL_MARKED_SUCCESSFULL = "Plan Marked successfully";
    public static UNMARK_ROTATIONAL = "Plan Unmarked As Rotational";
    public static ROTATIONAL_UNMARKED_SUCCESSFULL = "Plan Unmarked successfully";
    public static CLONE_PLAN = "Plan Clone";
    public static CLONE_PLAN_SUCCESSFULL = "Plan Cloned sucessfully";


    //campaign
    public static APPROVE_ITEM_CHANGES = "Item Changes Approved";
    public static CHANGES_APPROVED = "Changes Approved";
    public static REJECT_ITEM_CHANGES = "Item Changes Rejected";
    public static CHANGES_REJECTED = "Changes Rejected";
    public static RATE_UPDATED = "Rate Updated";
    public static UPDATION_REQUEST = "Updation Request Sent Successfully";

    //contract    
    public static TEMPLATE_DOWNLOAD_ERROR = "Error downloading tempalate Url.";
    public static TEMPLATE_DOWNLOAD = "Template Download Url";
    public static ERROR_LOADING_FILE = "Error Loading File";
    public static NULL_SOURCE = "Source must not be null";
    public static UPLOAD_FILE_AGAIN = "Please Upload File Again";
    public static COLUMN_DATA_MISSING = "Column data is missing";

    public static DUPLICATE_INV_ID = "Duplicate Invoice Id"
}