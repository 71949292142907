<!-- ------------------------------  Please do not remove any commented code in this file-------------------------------- -->
<div class="superbill-list">
    <div class="ConfiguationContainer">

        <p-tabView (onChange)="onTabChange($event.index)" [activeIndex]="activeIndex">
            <p-tabPanel header="Super Bill" *permission="'read:superbills'">
                <sib-superbill-campaigns *ngIf="activeIndex === 0" [showEmployee]="showEmployee"
                    [userEmailId]="userEmailId">
                </sib-superbill-campaigns>
            </p-tabPanel>

            <!-- <p-tabPanel header="Temp Invoice" *permission="'read:invoices'">
                <div class="tempInvoice-grid-scroller">
                    <sib-invoice-list
                        *ngIf="(checkPermission('read:superbills') && activeIndex === 1) || (!checkPermission('read:superbills') && activeIndex === 0)"
                        [showEmployee]="showEmployee"></sib-invoice-list>
                </div>
            </p-tabPanel> -->
            <div *ngIf="!userEmailId">
                <p-tabPanel header="Invoice" *permission="'read:invoices'">
                    <sib-superbill-invoices-list
                        *ngIf="(checkPermission('read:superbills') && activeIndex === 1) || (!checkPermission('read:superbills') && activeIndex === 0)">
                        <!-- *ngIf="(checkPermission('read:superbills') && checkPermission('read:invoices') && activeIndex === 2)
                    || (!checkPermission('read:superbills') && checkPermission('read:invoices') && activeIndex === 1)" -->
                    </sib-superbill-invoices-list>
                </p-tabPanel>
            </div>
            <div *ngIf="!userEmailId">
                <p-tabPanel header="Migrated Invoice" *permission="'read:migrated-invoices'">
                    <div class="migratedInvoice-grid-scroller">
                        <sib-migrated-invoices
                            *ngIf="(checkPermission('read:superbills') && checkPermission('read:invoices') && activeIndex === 2) || ((!checkPermission('read:superbills') && checkPermission('read:invoices')) || (checkPermission('read:superbills') && !checkPermission('read:invoices')) && activeIndex === 1) || (!checkPermission('read:superbills') && !checkPermission('read:invoices') && activeIndex === 0)"
                            [showEmployee]="showEmployee"></sib-migrated-invoices>
                        <!-- *ngIf="(checkPermission('read:superbills') && checkPermission('read:invoices') && activeIndex === 3) || (!checkPermission('read:superbills') && checkPermission('read:invoices') && activeIndex === 2)" -->
                    </div>
                </p-tabPanel>
            </div>

            <div *ngIf="!userEmailId">
                <p-tabPanel header="Credit Note" *permission="'read:invoices'">
                    <div class="creditnote-grid-scoller">
                        <sib-credit-notes
                            *ngIf="(checkPermission('read:superbills') && checkPermission('read:invoices') && checkPermission('read:migrated-invoices') && activeIndex === 3) || (((!checkPermission('read:superbills') && checkPermission('read:invoices') && checkPermission('read:migrated-invoices')) || (checkPermission('read:superbills') && !checkPermission('read:invoices') && checkPermission('read:migrated-invoices')) || (checkPermission('read:superbills') && checkPermission('read:invoices') && !checkPermission('read:migrated-invoices'))) && activeIndex === 2) || (((!checkPermission('read:superbills') && !checkPermission('read:invoices') && checkPermission('read:migrated-invoices')) || (!checkPermission('read:superbills') && checkPermission('read:invoices') && !checkPermission('read:migrated-invoices')) || (checkPermission('read:superbills') && !checkPermission('read:invoices') && !checkPermission('read:migrated-invoices'))) && activeIndex === 1) || (!checkPermission('read:superbills') && !checkPermission('read:invoices') && !checkPermission('read:migrated-invoices') && activeIndex === 0)"
                            [showEmployee]="showEmployee" [showXlsExport]="true" [showTab]="showTab">
                            <!-- *ngIf="(checkPermission('read:superbills') && checkPermission('read:invoices') && checkPermission('read:migrated-invoices') && activeIndex === 4) || (!checkPermission('read:superbills') && checkPermission('read:invoices') && checkPermission('read:migrated-invoices') && activeIndex === 3) || (checkPermission('read:superbills') && checkPermission('read:invoices') && !checkPermission('read:migrated-invoices') && activeIndex === 3) || (!checkPermission('read:superbills') && checkPermission('read:invoices') && !checkPermission('read:migrated-invoices') && activeIndex === 2)" -->
                        </sib-credit-notes>
                    </div>
                </p-tabPanel>
            </div>

            <!-- <p-tabPanel header="Tasks" *permission="'read:invoice-tasks'">
                <div class="ui-g-12" *ngIf="activeIndex === 2">
                    <div class="ui-g-10">
                        <sib-task-cards *ngIf="invoiceTaskCards?.length > 0" [invoiceTaskCards]="invoiceTaskCards"
                            (eEmitFilterSelected)="setCustomFilter($event)"></sib-task-cards>
                    </div>
                </div>
                <div class="ui-g-12 margin-top--70" *ngIf="activeIndex === 2">
                    <sib-superbill-tasks *ngIf="customFilter" [showEmployee]="showEmployee" [customFilter]="customFilter"></sib-superbill-tasks>
                </div>
            </p-tabPanel> -->
        </p-tabView>
    </div>
    <ng-container
        *ngIf="!(checkPermission('read:superbills')) && !(checkPermission('read:invoices')) && !(checkPermission('read:migrated-invoices'))">
        <div class="no-tab">
            No tab permission assigned to the user
        </div>
    </ng-container>
</div>