import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { LazyLoadEvent } from "primeng/primeng";
import { AreaQueryParams } from "../../modals/queryparams/area-queryparams";
import { Industry } from "../../modals/industry/industry";
import { Group } from "../../modals/group/group";
import { ContractType } from "../../modals/contract-type/contract-type";
import { AppModuleState } from "../app-module.reducers";
import { AppActions } from "../actions/app-actions";
import { State } from "../../modals/Area/state";
import { District } from "../../modals/Area/district";
import { City } from "../../modals/Area/city";
import { Area } from "../../modals/Area/area";
import { ConfigMaster } from "../../modals/master/config.master";
import { MonitoringMaster } from "../../modals/monitoring/monitoring-master";
import { Tenant } from "../../modals/master/tenant";
import { HoardingModuleStateExtended } from "../../hoardings/hoarding-module.reducers";
import { GroupWrapper } from "../../modals/group/group-wrapper";
import { DistrictQueryParams } from '../../modals/queryparams/district-queryparams';


@Injectable()

export class ActionDispatcher {
    pageNumber: Number;
    industries: Observable<Industry[]>;
    groups: Observable<Group[]>;
    contractTypes: Observable<ContractType[]>;
    monitoringMaster: Observable<MonitoringMaster[]>;
    isMonitoringUpdated: Observable<boolean>;
    isMonitoringRemoved: Observable<boolean>;

    states: Observable<State[]>;
    districts: Observable<District[]>;
    cities: Observable<City[]>;
    areas: Observable<Area[]>;
    areaList: Observable<Area[]>;

    hoardingFilters: Observable<any[]>;


    // Global Configurations and Company Global Data
    configMaster: Observable<ConfigMaster>;

    isError: Observable<boolean>;
    isIndustryCreated: Observable<boolean>;
    isGroupCreated: Observable<boolean>;
    isGroupUpdated: Observable<boolean>;
    isContractTypeCreated: Observable<boolean>;
    isContractTypeUpdated: Observable<boolean>;
    isAreaCreated: Observable<boolean>;
    isAreaUpdated: Observable<boolean>;
    isDistrictCreated: Observable<boolean>;
    isDistrictUpdated: Observable<boolean>;
    isImageUploaded: Observable<boolean>;

    systemModules: Observable<any[]>;
    configurations: Observable<any[]>;
    vendorDetails: Observable<Tenant>;

    enablePassbook: Observable<boolean>;
    planConfigurations: Observable<any[]>;
    campaignConfigurations: Observable<any[]>;

    constructor(
        private store: Store<AppModuleState>,
        private appStore: Store<HoardingModuleStateExtended>,
        private appActions: AppActions
    ) {
        this.industries = store.select((state) => {
            return state.appList.industries;
        });

        this.groups = store.select((state) => {
            return state.appList.groups;
        });

        this.contractTypes = store.select((state) => {
            return state.appList.contractTypes;
        });


        this.states = store.select((state) => {
            return state.appList.states;
        });

        this.districts = store.select((state) => {
            return state.appList.districts;
        });

        this.cities = store.select((state) => {
            return state.appList.cities;
        });

        this.areas = store.select((state) => {
            return state.appList.areas;
        });

        this.areaList = store.select((state) => {
            return state.appList.areaList;
        });

        this.hoardingFilters = store.select((state) => {
            if (state.appList) {
                return state.appList.hoardingFilters;
            }
        });

        this.isContractTypeCreated = store.select((state) => {
            return state.appList.isContractTypeCreated;
        });

        this.isContractTypeUpdated = store.select((state) => {
            return state.appList.isContractTypeUpdated;
        })

        this.isGroupCreated = store.select((state) => {
            return state.appList.isGroupCreated;
        });

        this.isGroupUpdated = store.select((state) => {
            return state.appList.isGroupUpdated;
        });

        this.isIndustryCreated = store.select((state) => {
            return state.appList.isIndustryCreated;
        });

        this.isAreaCreated = store.select((state) => {
            return state.appList.isAreaCreated;
        });

        this.isAreaUpdated = store.select((state) => {
            return state.appList.isAreaUpdated;
        })

        this.isDistrictCreated = store.select((state) => {
            return state.appList.isDistrictCreated;
        });

        this.isDistrictUpdated = store.select((state) => {
            return state.appList.isDistrictUpdated;
        });

        this.isError = store.select((state) => {
            if (state.appList) {
                return state.appList.isError;
            }
        });

        this.industries = store.select((state) => {
            return state.appList.industries;
        });

        this.configMaster = store.select((state) => {
            return state.appList.configMaster;
        });

        this.isImageUploaded = store.select((state) => {
            if (state.appList) {
                return state.appList.isImageUploaded;
            }
        });

        this.monitoringMaster = store.select((state) => {
            return state.appList.monitoringMaster;
        });

        this.isMonitoringRemoved = store.select((state => {
            if (state.appList) {
                return state.appList.isMonitoringRemoved;
            }
        }));

        this.isMonitoringUpdated = store.select((state => {
            if (state.appList) {
                return state.appList.isMonitoringUpdated;
            }
        }));

        this.systemModules = store.select((state => {
            if (state.appList) {
                return state.appList.systemModules;
            }
        }))

        this.configurations = store.select((state => {
            if (state.appList) {
                return state.appList.configurations;
            }
        }))

        this.vendorDetails = store.select((state => {
            if (state.appList.vendorDetails) {
                return state.appList.vendorDetails;
            }
        }));

        this.enablePassbook = store.select((state => {
            if (state.appList.enablePassbook) {
                return state.appList.enablePassbook;
            }
        }));

        this.planConfigurations = store.select((state) => {
            if (state.appList.planConfigurations) {
                return state.appList.planConfigurations
            }
        });

        this.campaignConfigurations = store.select((state) => {
            if (state.appList.campaignConfigurations) {
                return state.appList.campaignConfigurations
            }
        });
    }



    calculatePageNumber($event: LazyLoadEvent): number {
        return ($event.first + $event.rows) / $event.rows;
    }

    getIndustries() {
        this.store.dispatch(this.appActions.getIndustry());
    }

    getGroups() {
        this.store.dispatch(this.appActions.getGroup());
    }

    getContractTypes() {
        this.store.dispatch(this.appActions.getContractType());
    }

    createIndustry(industry: Industry) {
        this.store.dispatch(this.appActions.createIndustry(industry));
    }

    createGroup(group: GroupWrapper) {
        this.store.dispatch(this.appActions.createGroup(group));
    }

    createContractType(contractType: ContractType) {
        this.store.dispatch(this.appActions.createContractType(contractType));
    }

    updateContractType(contractType: ContractType) {
        this.store.dispatch(this.appActions.updateContractType(contractType))
    }


    getStates(areaQueryParams: AreaQueryParams) {
        this.store.dispatch(this.appActions.getStates(areaQueryParams));
    }

    getDistricts(areaQueryParams) {
        this.store.dispatch(this.appActions.getDistricts(areaQueryParams));
    }

    createDistrict(district: District) {
        this.store.dispatch(this.appActions.createDistrict(district));
    }

    updateDistrict(district: District) {
        this.store.dispatch(this.appActions.updateDistrict(district))
    }

    resetDistrictList() {
        this.store.dispatch(this.appActions.resetDistrictsList());
    }

    resetIsDistrictCreated() {
        this.store.dispatch(this.appActions.resetIsDistrictCreated());
    }

    resetIsDistrictUpdated() {
        this.store.dispatch(this.appActions.resetIsDistrictUpdated())
    }

    getCities(areaQueryParams) {
        this.store.dispatch(this.appActions.getCities(areaQueryParams));
    }

    resetCitiesList() {
        this.store.dispatch(this.appActions.resetCitiesList());
    }

    resetAreasList() {
        this.store.dispatch(this.appActions.resetAreasList());
    }

    getAreas(areaQueryParams) {
        this.store.dispatch(this.appActions.getAreas(areaQueryParams));
    }

    createArea(area: Area) {
        this.store.dispatch(this.appActions.createArea(area));
    }
    updateArea(area: Area) {
        this.store.dispatch(this.appActions.updateArea(area))
    }

    addArea(area: Area) {
        this.store.dispatch(this.appActions.addArea(area));
    }

    saveArea(area) {
        this.store.dispatch(this.appActions.saveArea(area));
    }

    getAreaList(areaQueryParams) {
        this.store.dispatch(this.appActions.getAreaList(areaQueryParams));
    }

    getAreaListFirst() {
        this.store.dispatch(this.appActions.getAreaListFirst());
    }

    getAreaByCriteria(areaQueryParams) {
        this.store.dispatch(this.appActions.getAreaByCriteria(areaQueryParams));
    }


    getStatesList(districtQueryParams: DistrictQueryParams) {
        this.store.dispatch(this.appActions.getStatesList(districtQueryParams));
    }

    getDistrictsList(districtQueryParams) {
        this.store.dispatch(this.appActions.getDistrictsList(districtQueryParams));
    }

    resetError() {
        this.store.dispatch(this.appActions.resetError());
    }

    resetIsGroupCreated() {
        this.store.dispatch(this.appActions.resetIsGroupCreated());
    }

    resetIsContractTypeCreated() {
        this.store.dispatch(this.appActions.resetIsContractTypeCreated());
    }

    resetIsContractTypeUpdated() {
        this.store.dispatch(this.appActions.resetIsContractTypeUpdated());
    }

    resetIsAreaCreated() {
        this.store.dispatch(this.appActions.resetIsAreaCreated());
    }
    resetIsAreaUpdated() {
        this.store.dispatch(this.appActions.resetIsAreaUpdated())
    }

    getConfiguration() {
        this.store.dispatch(this.appActions.getConfiguration());
    }

    saveConfiguration(configs) {
        this.store.dispatch(this.appActions.saveConfiguration(configs));
    }

    uploadImage(selectedRow: any) {
        this.store.dispatch(this.appActions.uploadImage(selectedRow));
    }

    resetIsImageUploaded() {
        this.store.dispatch(this.appActions.resetIsImageUploaded());
    }

    resetIsIndustryCreate() {
        this.store.dispatch(this.appActions.resetIsIndustryCreated())
    }

    updateMonitoring(rowData) {
        this.store.dispatch(this.appActions.updateMonitoring(rowData));
    }

    removeMonitoringItem(rowData: any) {     // for removing a monitoring item
        this.store.dispatch(this.appActions.removeMonitoringItem(rowData));
    }

    resetIsMonitoringRemoved() {
        this.store.dispatch(this.appActions.resetIsMonitoringRemoved());
    }

    resetIsMonitoringUpdated() {
        this.store.dispatch(this.appActions.resetIsMonitoringUpdated());
    }

    saveVendorDetails(vendorDetails) {
        this.store.dispatch(this.appActions.saveVendorDetails(vendorDetails))
    }

    getSystemModules() {
        this.store.dispatch(this.appActions.getSystemModules())
    }

    getConfigurationByModule(moduleName) {
        this.store.dispatch(this.appActions.getConfigurationByModule(moduleName))
    }

    saveParameters(parameters) {
        this.store.dispatch(this.appActions.saveParameters(parameters))
    }

    savePassbookParameters(parameters) {
        this.store.dispatch(this.appActions.savePassbookParameters(parameters))
    }

    getVendorDetails(tenantId) {
        this.store.dispatch(this.appActions.getVendorDetails(tenantId))
    }

    getHoardingFilters() {
        this.store.dispatch(this.appActions.getHoardingFilters());
    }

    setHoardingFilters(filters: any) {
        this.store.dispatch(this.appActions.setHoardingFilters(filters));
    }

    updateGroup(groupWrapper) {
        this.store.dispatch(this.appActions.updateGroup(groupWrapper));
    }

    resetIsGroupUpdated() {
        this.store.dispatch(this.appActions.resetIsGroupUpdated());
    }

    getSystemConfiguations() {
        this.store.dispatch(this.appActions.getSystemConfiguations())
    }

    setEnablePassbook(value) {
        this.store.dispatch(this.appActions.setEnablePassbook(value));
    }
}
