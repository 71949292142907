import { Component, ViewEncapsulation, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { CustomerProfilePageService } from "./services/customer-profile-page.service";

@Component({
    selector: 'sib-customer-profile-page',
    templateUrl: './customer-profile-page.component.html',
    styleUrls: ['./customer-profile-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CustomerProfilePageService]
})
export class CustomerProfilePageComponent implements OnInit, OnDestroy {

    subscription: Subscription[] = [];

    constructor() { }

    ngOnInit() { }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

}
