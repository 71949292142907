import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, GalleriaModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { PhotoDialogComponent } from "./photo-dialog.component";
import { FirebaseService } from "../../../services/shared/firebase.service";



@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        GalleriaModule,
        ProgressSpinnerModule
    ],

    declarations: [
        PhotoDialogComponent
    ],

    exports: [
        PhotoDialogComponent,
    ],
    providers: [FirebaseService]
})

export class PhotoDialogModule { }
