import { GridColumn, EditableGridFieldTypes, Alignment } from '../../components/sib-forms/grid/grid.config';
import * as utils from '../../helpers/utils';
import * as mapUtils from '../../helpers/map.utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';

export const ROIContractDataColumns: Array<GridColumn> = [
  {
    field: 'srNo',
    name: 'srNo',
    header: 'Sr',
    required: true,
    width: '2vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: false,
    type: EditableGridFieldTypes.CUSTOM,
  },
  {
    field: 'contract.id',
    name: 'contract.id',
    header: 'CID',
    required: true,
    width: '4vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.contract && data.contract.id) {
        return data.contract.id;
      } else {
        return '-';
      }
    },
  },
  // {
  //   field: 'id',
  //   name: 'id',
  //   header: 'Contract Id',
  //   required: true,
  //   width: '4vw',
  //   editable: false,
  //   hidden: false,
  //   default: true,
  //   permanent: true,
  //   sortable: true,
  //   type: EditableGridFieldTypes.TEXT,
  //   filter: {
  //     matchMode: 'contains',
  //     placeHolder: 'Search',
  //     custom: false,
  //   },
  //   displayFn: (data) => {
  //     if (data && data.id) {
  //       return data.id;
  //     } else {
  //       return '-';
  //     }
  //   },
  // },
  {
    field: 'contract.name',
    name: 'contract.name',
    header: 'Contract',
    required: true,
    width: '7.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.CUSTOM,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
  },
  // {
  //   field: 'name',
  //   name: 'name',
  //   header: 'Contract Name',
  //   required: true,
  //   width: '8vw',
  //   editable: false,
  //   hidden: false,
  //   default: true,
  //   permanent: true,
  //   sortable: true,
  //   type: EditableGridFieldTypes.TEXT,
  //   filter: {
  //     matchMode: 'contains',
  //     placeHolder: 'Search',
  //     custom: false,
  //   },
  //   displayFn: (data) => {
  //     if (data && data.name) {
  //       return data.name;
  //     } else {
  //       return '-';
  //     }
  //   },
  // },
  {
    field: 'contract.cheques.chequeName',
    name: 'contract.cheques.chequeName',
    header: 'Cheque Name',
    required: true,
    width: '8vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: false,
    sortable: true,
    type: EditableGridFieldTypes.CUSTOM,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
  },
  // {
  //   field: 'cheques.chequeName',
  //   name: 'cheques.chequeName',
  //   header: 'Cheque',
  //   required: true,
  //   width: '8vw',
  //   editable: false,
  //   hidden: false,
  //   default: true,
  //   permanent: false,
  //   sortable: true,
  //   type: EditableGridFieldTypes.CUSTOM,
  //   filter: {
  //     matchMode: 'contains',
  //     placeHolder: 'Search',
  //     custom: false,
  //   },
  // },
  {
    field: 'invoiceSum',
    name: 'invoiceSum',
    header: 'Billed Amount',
    required: true,
    width: '4.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: false,
    sortable: true,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    type: EditableGridFieldTypes.NUMBER,
    displayFn: (data) => {
      if (data && data.invoiceSum >= 0) {
        return new CurrencyHelperPipe().transform(utils.getDecimalNumFixedToTwoPlaces(data.invoiceSum));
      } else {
        return '-';
      }
    },
  },
  {
    field: 'migratedInvoiceSum',
    name: 'migratedInvoiceSum',
    header: 'Migrated Amount',
    required: true,
    width: '5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: false,
    sortable: true,
    type: EditableGridFieldTypes.NUMBER,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    displayFn: (data) => {
      if (data && data.migratedInvoiceSum >= 0) {
        return new CurrencyHelperPipe().transform(utils.getDecimalNumFixedToTwoPlaces(data.migratedInvoiceSum));
      } else {
        return '-';
      }
    },
  },
  {
    field: 'unbilledSum',
    name: 'unbilledSum',
    header: 'Unbilled Amount',
    required: true,
    width: '5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: false,
    sortable: true,
    type: EditableGridFieldTypes.NUMBER,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    displayFn: (data) => {
      if (data && data.unbilledSum >= 0) {
        return new CurrencyHelperPipe().transform(utils.getDecimalNumFixedToTwoPlaces(data.unbilledSum));
      } else {
        return '-';
      }
    },
  },
  {
    field: 'totalEstimate',
    name: 'totalEstimate',
    header: 'Total Estimate',
    required: true,
    width: '4.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    type: EditableGridFieldTypes.NUMBER,
    displayFn: (data) => {
      if (data && data.totalEstimate >= 0) {
        return new CurrencyHelperPipe().transform(utils.getDecimalNumFixedToTwoPlaces(data.totalEstimate));
      } else {
        return '-';
      }
    },
  },
  {
    field: 'breakevens',
    name: 'breakevens',
    header: 'Break Even Amount',
    required: true,
    width: '5.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    type: EditableGridFieldTypes.NUMBER,
    displayFn: (data) => {
      if (data && data.breakevens >= 0) {
        return new CurrencyHelperPipe().transform(utils.getDecimalNumFixedToTwoPlaces(data.breakevens));
      } else {
        return '-';
      }
    },
  },
  {
    field: 'differenceAmount',
    name: 'differenceAmount',
    header: 'Difference',
    required: true,
    width: '4.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    type: EditableGridFieldTypes.CUSTOM,
  },
  {
    field: 'differencePercentage',
    name: 'differencePercentage',
    header: 'Difference (%)',
    required: true,
    width: '4.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    type: EditableGridFieldTypes.CUSTOM,
  },
];

export const ROIContractDataMap = (() => {
  return mapUtils.createMap(ROIContractDataColumns);
})();
