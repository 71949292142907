import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, DropdownModule, CalendarModule, TooltipModule, DialogModule, DataTableModule,  } from "primeng/primeng";
import { InputTrimModule } from "../../../directives/input-trim";
import { StatusQueueDialogComponent } from "./status-queue-dialog.component";
import { CommonDataTableModule } from "../dataTable/dataTable.module";



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        InputTrimModule,
        DialogModule,
        CommonDataTableModule,
        DataTableModule
    ],

    declarations: [
        StatusQueueDialogComponent
    ],

    exports: [
        StatusQueueDialogComponent
    ],
    providers: []
})

export class StatusQueueDialogModule { }
