import { ActionReducer, Action } from "@ngrx/store";
import { ActionExtended } from "../../shared/actionextended";
import { Industry } from "../../modals/industry/industry";
import { Group } from "../../modals/group/group";
import { ContractType } from "../../modals/contract-type/contract-type";
import { AppActions } from "../actions/app-actions";
import { MenuItem } from "primeng/primeng";
import { State } from "../../modals/Area/state";
import { District } from "../../modals/Area/district";
import { City } from "../../modals/Area/city";
import { Area } from "../../modals/Area/area";
import * as _ from 'lodash';
import { ConfigMaster } from "../../modals/master/config.master";
import { MonitoringMaster } from "../../modals/monitoring/monitoring-master";


export class AppState {
    industries: Industry[] = [];
    groups: Group[] = [];
    contractTypes: ContractType[] = [];
    monitoringMaster: MonitoringMaster[] = [];


    states: State[] = [];
    districts: District[] = [];
    districtsList: District[] = [];
    cities: City[] = [];
    areas: Area[] = [];
    areaList: Area[] = [];

    hoardingFilters: any[] = [];

    isError: boolean = false;
    isIndustryCreated: boolean = false;
    isGroupCreated: boolean = false;
    isContractTypeCreated: boolean = false;
    isContractTypeUpdated: boolean = false;
    isAreaCreated: boolean = false;
    isAreaUpdated: boolean = false;
    isDistrictCreated: boolean = false;
    isDistrictUpdated: boolean = false;
    isImageUploaded = false;
    isMonitoringUpdated = false;
    isMonitoringRemoved = false;
    isGroupUpdated = false;


    //Global Configurations and Company Global Data
    configMaster: ConfigMaster;

    vendorDetails: any;
    systemModules: any[] = [];
    configurations: any[] = [];
    systemConfigurations: any[] = [];

    customerConfigurations: any[] = [];
    planConfigurations: any[] = [];
    monitoringConfigurations: any[] = [];
    campaignConfigurations: any[] = [];
    otherConfigurations: any[] = [];

    enablePassbook: boolean = false;

}

export type MenuState = MenuItem[];
const initialMenuState: MenuState = [];
const initialState: AppState = new AppState();


export function appReducer(state = initialState, action: ActionExtended) {
    switch (action.type) {

        case AppActions.GET_INDUSTRY_SUCCESS: {
            return Object.assign({}, state, { industries: action.payload });
        }

        case AppActions.GET_GROUP_SUCCESS: {
            return Object.assign({}, state, { groups: action.payload });
        }

        case AppActions.GET_CONTRACT_TYPE_SUCCESS: {
            return Object.assign({}, state, { contractTypes: action.payload });
        }



        case AppActions.CREATE_INDUSTRY_SUCCESS: {
            let industries;
            if (action.payload.isEdit) {
                let index: number = _.findIndex(state.industries, { id: action.payload.data.id });
                if (index >= 0) {
                    industries = [...state.industries.slice(0, index), action.payload.data, ...state.industries.slice(index + 1)];
                } else {
                    industries = [...state.industries, action.payload.data];
                }
            } else {
                industries = [...state.industries, action.payload.data];
            }
            return Object.assign({}, state, { industries: industries, isIndustryCreated: true });
        }



        case AppActions.CREATE_GROUP_SUCCESS: {
            // let groups;
            // if (action.payload.isEdit) {
            //     let index: number = _.findIndex(state.groups, { id: action.payload.data.id });
            //     if (index >= 0) {
            //         groups = [...state.groups.slice(0, index), action.payload.data, ...state.groups.slice(index + 1)];
            //     } else {
            // groups = [...state.groups, action.payload.data];
            //     }
            // } else {
            // groups = [...state.groups, action.payload.data];
            // }
            return Object.assign({}, state, { groups: [...state.groups, action.payload.data], isGroupCreated: true });
        }

        case AppActions.CREATE_GROUP_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }



        case AppActions.CREATE_CONTRACT_TYPE_SUCCESS: {
            let contractTypes;
            if (action.payload.isEdit) {
                let index: number = _.findIndex(state.contractTypes, { id: action.payload.data.id });
                if (index >= 0) {
                    contractTypes = [...state.contractTypes.slice(0, index), action.payload.data, ...state.contractTypes.slice(index + 1)];
                } else {
                    contractTypes = [...state.contractTypes, action.payload.data];
                }
            } else {
                contractTypes = [...state.contractTypes, action.payload.data];
            }
            return Object.assign({}, state, { contractTypes: contractTypes, isContractTypeCreated: true });
        }

        case AppActions.CREATE_CONTRACT_TYPE_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.UPDATE_CONTRACT_TYPE_SUCCESS: {
            return Object.assign({}, state, { isContractTypeUpdated: true });
        }

        case AppActions.UPDATE_CONTRACT_TYPE_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.GET_STATES_SUCCESS: {
            return Object.assign({}, state, { states: action.payload });
        }

        case AppActions.GET_STATES_LIST_SUCCESS: {
            return Object.assign({}, state, { states: action.payload });
        }

        case AppActions.GET_DISTRICTS_SUCCESS: {
            return Object.assign({}, state, { districts: [...action.payload] });
        }

        case AppActions.CREATE_DISTRICT_SUCCESS: {
            return Object.assign({}, state, { districts: [...state.districts, action.payload.data], isDistrictCreated: true });
        }

        case AppActions.CREATE_DISTRICT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.UPDATE_DISTRICT_SUCCESS: {
            return Object.assign({}, state, { isDistrictUpdated: true });
        }

        case AppActions.UPDATE_DISTRICT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.GET_DISTRICT_LIST_SUCCESS: {
            return Object.assign({}, state, { districts: action.payload });
        }

        case AppActions.RESET_DISTRICT_LIST: {
            // return Object.assign({}, state, { districtList: [] });
            return Object.assign({}, state, { districtsList: [], districts: [] });
        }

        case AppActions.RESET_IS_DISTRICT_CREATED: {
            return Object.assign({}, state, { district: new District(), isDistrictCreated: false });
        }

        case AppActions.RESET_IS_DISTRICT_UPDATED: {
            return Object.assign({}, state, { district: new District(), isDistrictUpdated: false });
        }

        case AppActions.GET_CITIES_SUCCESS: {
            return Object.assign({}, state, { cities: [...action.payload] });
        }


        case AppActions.RESET_CITIES_LIST: {
            return Object.assign({}, state, { cities: [] });
        }

        case AppActions.RESET_AREAS_LIST: {
            return Object.assign({}, state, { areaList: [] });
        }

        case AppActions.GET_AREAS_SUCCESS: {
            return Object.assign({}, state, { areas: action.payload });
        }

        case AppActions.CREATE_AREA_SUCCESS: {
            let areas;
            if (action.payload.isEdit) {
                let index: number = _.findIndex(state.areas, { id: action.payload.data.id });
                if (index >= 0) {
                    areas = [...state.areas.slice(0, index), action.payload.data, ...state.areas.slice(index + 1)];
                } else {
                    areas = [...state.areas, action.payload.data];
                }
            } else {
                areas = [...state.areas, action.payload.data];
            }
            return Object.assign({}, state, { areas: areas, isAreaCreated: true });
        }

        case AppActions.CREATE_AREA_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }


        case AppActions.UPDATE_AREA_SUCCESS: {
            return Object.assign({}, state, { isAreaUpdated: true });
        }

        case AppActions.UPDATE_DISTRICT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.GET_AREA_LIST_SUCCESS: {
            return Object.assign({}, state, { areas: action.payload });
        }

        case AppActions.GET_AREA_LIST_FIRST_SUCCESS: {
            return Object.assign({}, state, { areas: action.payload });
        }

        case AppActions.GET_AREA_BY_CRITERIA_SUCCESS: {
            return Object.assign({}, state, { areaList: action.payload });
        }

        case AppActions.GET_AREA_BY_CRITERIA_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.RESET_ERROR: {
            return Object.assign({}, state, { isError: false });
        }

        case AppActions.RESET_IS_GROUP_CREATED: {
            return Object.assign({}, state, { group: new Group(), isGroupCreated: false });
        }

        case AppActions.RESET_IS_CONTRACT_TYPE_CREATED: {
            return Object.assign({}, state, { contractType: new ContractType(), isContractTypeCreated: false });
        }

        case AppActions.RESET_IS_CONTRACT_TYPE_UPDATED: {
            return Object.assign({}, state, { contractType: new ContractType(), isContractTypeUpdated: false });
        }
        case AppActions.RESET_IS_AREA_CREATED: {
            return Object.assign({}, state, { area: new Area(), isAreaCreated: false });
        }

        case AppActions.RESET_IS_AREA_UPDATED: {
            return Object.assign({}, state, { area: new Area(), isAreaCreated: false });
        }


        case AppActions.GET_CONFIG_SUCCESS: {
            return Object.assign({}, state, { states: action.payload });
        }

        case AppActions.UPLOAD_IMAGE_SUCCESS: {
            return Object.assign({}, state, { isImageUploaded: true });
        }

        case AppActions.UPLOAD_IMAGE_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.RESET_IS_IMAGE_UPLOADED: {
            return Object.assign({}, state, { isImageUploaded: false });
        }

        case AppActions.RESET_IS_INDUSTRY_CREATED: {
            return Object.assign({}, state, { isIndustryCreated: false });
        }

        case AppActions.RESET_IS_MONITORING_UPDATED: {
            return Object.assign({}, state, { isMonitoringUpdated: false });
        }

        case AppActions.RESET_IS_MONITORING_REMOVED: {
            return Object.assign({}, state, { isMonitoringRemoved: false });
        }

        case AppActions.UPDATE_MONITORING_SUCCESS: {
            return Object.assign({}, state, { isMonitoringUpdated: true });
        }

        case AppActions.UPDATE_MONITORING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.REMOVE_MONITORING_SUCCESS: {
            return Object.assign({}, state, { isMonitoringRemoved: true });
        }

        case AppActions.REMOVE_MONITORING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.SAVE_VENDOR_DETAILS_SUCCESS: {
            return Object.assign({}, state, { vendorDetails: action.payload })
        }

        case AppActions.GET_SYSTEM_MODULES_SUCCESS: {
            return Object.assign({}, state, { systemModules: action.payload })
        }

        case AppActions.GET_CONFIGURATION_BY_MODULE_SUCCESS: {
            return Object.assign({}, state, { configurations: action.payload })
        }

        case AppActions.SAVE_CONFIGURATIONS_SUCCESS: {
            return Object.assign({}, state, { configurations: action.payload })
        }

        case AppActions.SAVE_CONFIGURATIONS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case AppActions.GET_VENDOR_DETAILS_SUCCESS: {
            return Object.assign({}, state, { vendorDetails: action.payload })
        }

        case AppActions.SET_HOARDING_FILTERS: {
            return Object.assign({}, state, { hoardingFilters: action.payload });
        }

        case AppActions.GET_HOARDING_FILTERS: {
            return Object.assign({}, state, { hoardingFilters: action.payload });
        }

        case AppActions.UPDATE_GROUP_SUCCESS: {
            return Object.assign({}, state, { isGroupUpdated: true });
        }

        case AppActions.RESET_IS_GROUP_UPDATED: {
            return Object.assign({}, state, { isGroupUpdated: false });
        }
        case AppActions.GET_SYSTEM_CONFIGURATIONS_SUCCESS: {

            return Object.assign({}, state, {
                systemConfigurations: action.payload,
                customerConfigurations: action.payload.filter((data) => data.module === 'CUSTOMER'),
                planConfigurations: action.payload.filter((data) => data.module === 'PLAN'),
                monitoringConfigurations: action.payload.filter((data) => data.module === 'MONITORING'),
                campaignConfigurations: action.payload.filter((data) => data.module === 'CAMPAIGN'),
                otherConfigurations: action.payload.filter((data) => data.module === 'OTHERS')
            });
        }

        case AppActions.SET_ENABLE_PASSBOOK: {
            return Object.assign({}, state, { enablePassbook: action.payload });
        }

        default: {
            return state;
        }

    }
}

export function menuReducer(state: MenuState = initialMenuState, action: any): MenuState {
    switch (action.type) {
        case AppActions.LOAD_MENUS_SUCCESS:
            return Object.assign([], action.payload);
        default:
            return state;
    }
}





