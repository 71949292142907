/**
* @file  EventConfig
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Injectable } from "@angular/core";

@Injectable()
export class EventConfig extends SibBaseObject {
    public Server = 'https://api.meetup.com/find/upcoming_events?key=48576327545e47b335fe4f784e5769&group_urlname=ny-tech&sign=true&photo-host=public&page=20';
    public ApiUrl = '';
    public ServerWithApiUrl = this.Server + this.ApiUrl;
}