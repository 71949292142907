export class FileItem {
    
  public file?:File;
  public url:string = '';
  public isUploading:boolean = false;
  public progress:number = 0;
  public imagesFolder: string;
  public isQueued: boolean = false;

  public constructor(file: File, imagesFolder: string) {
    this.file = file;
    this.imagesFolder = imagesFolder;
  }

}
