
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule, OverlayPanelModule, DropdownModule, CheckboxModule, InputSwitchModule } from "primeng/primeng";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusVisualizationModule } from '../status-visualization/status-visualization.module';
import { RouterModule } from '@angular/router';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';
import { BillingGridService } from '../../../billings/services/billing-grid.service';
import { CreditNotesComponent } from './credit-notes.component';
import { CreditNotesService } from '../../../services/shared/credit-note.service';
import { ReserveCreditNotesModule } from '../../../billings/reserve-credit-notes/reserve-credit-notes.module';
import { PrintPannelLayoutModule } from '../print-pannel-layout/print-pannel-layout.module';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        ReactiveFormsModule,
        FormsModule,
        StatusVisualizationModule,
        OverlayPanelModule,
        RouterModule,
        CurrencyMainPipe,
        DropdownModule,
        CheckboxModule,
        InputSwitchModule,
        ReserveCreditNotesModule,
        PrintPannelLayoutModule
    ],

    declarations: [
        CreditNotesComponent,
    ],

    exports: [
        CreditNotesComponent
    ],

    providers: [
        BillingGridService,
        CreditNotesService
    ]
})

export class CreditNotesModule { }
