<div class="pendingtasks">
  <!-- <div *ngIf="isLoading">
    <div class="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div> -->

  <div>
    <form [formGroup]="tasksGroup">
      <sib-grid name="taskGrid" #sibGrid [showFilter]="true" [showColorLegends]="false" [totalRecords]="totalRecords"
        (eEmitColumnSelection)="updatingUserGridConfig($event)" [columns]="taskColumns" [config]="gridConfig"
        formControlName="tasksControl" ngDefaultControl>

        <sib-grid-column field="srNo">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <span>
              {{rowIndex+1}}
            </span>
          </ng-template>
        </sib-grid-column>
        <sib-grid-column field="displayName">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <a class="link-1  after" target="_blank" *ngIf="rowData.displayName; else defaultTemplate"
              [routerLink]="['/campaigns/view/', rowData.campaignId]">{{rowData.displayName}}</a>
            <ng-template #defaultTemplate>
              -
            </ng-template>
          </ng-template>
        </sib-grid-column>

        <sib-grid-column field="action">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div>
              <span class="padding-right-5">
                <button type="submit" pButton class="mtab-save" label="ACTION"
                  (click)="showSummaryOverlay($event,rowData)" style="padding: 3px 8px"></button>
              </span>
            </div>
          </ng-template>
        </sib-grid-column>
      </sib-grid>

    </form>
  </div>
</div>


<p-overlayPanel #campaignSummaryOverlay class="campaign-item-summary-overlay">
  <div>
    <div class="ui-g-12 overlay-description">
      {{getItemDescription()}}

    </div>
    <div class="ui-g-12 overlay-description">
      <span> (<b> Site Old Rate:</b> {{getDecimalNum(rowItem.oldCost)|CurrencyConverter}} - <b>Site New
          Rate:</b>
        {{getDecimalNum(rowItem.cost)|CurrencyConverter}} )</span>

    </div>

    <!-- [summary]="rowItem.campaign.summary" [summaryTitle]="'Old Summary'" -->
    <!-- [summary]="rowItem.campaign.newSummary" [summaryTitle]="'New Summary'" -->
    <div class="ui-g-6 ">
      <sib-campaign-summary-template [summary]="oldSummary" [summaryTitle]="'Old Summary'"
        [gstParameter]="gstParameter">
      </sib-campaign-summary-template>
    </div>
  </div>
  <div class="ui-g-6 ">
    <sib-campaign-summary-template [summary]="newSummary" [summaryTitle]="'New Summary'" [gstParameter]="gstParameter">
    </sib-campaign-summary-template>
  </div>

  <div style="text-align:center;margin-bottom:4px;">
    <button class="mtab-save margin-hori-5" type="submit" label="APPROVE" (click)="approveRateChanges()"
      pButton></button>
    <button class="mtab-danger" type="button" label="REJECT" (click)="rejectRateChanges()" pButton></button>
  </div>
</p-overlayPanel>