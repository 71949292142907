
import { map } from 'rxjs/operators';
import { Response } from "@angular/http";
import { Injectable, Injector } from "@angular/core";
import 'rxjs/Rx';
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";


@Injectable()
export class CustomerService extends ApiService<any, any, any> {
    urlPath: string = 'customers/search';
    baseUrl: string;


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }
    getCustomerById(id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'customers/' + id).pipe(
                map((res: Response) => { console.log("get customer by Id", res); return res }))
        }


    }

    getCustomerStatusList() {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'customers/status').pipe(
                map((res: Response) => res));
        }

    }

    getAllCustomers() {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'customers/find-all', { "sortField": "updatedDate", "sortOrder": 1, "filters": { "group._id": { "value": null, "matchMode": "is" } } }
            ).pipe(
                map((res: Response) => res));
        }

    }
}
