import { Injectable, OnInit } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import * as firebase from 'firebase';
import * as utils from '../../helpers/utils';
import { AuthService } from '../../login-service/auth.service';

@Injectable()
export class ChatBaseService {


  unreadMsgCnt: Subject<number> = new Subject();
  loggedInEmail: string = null;
  tenantId: string;

  constructor(private auth: AuthService) {
    this.getUnreadCount();
  }

  getUnreadCount() {
    this.auth.authSet.subscribe((result) => {
      if (result) {           // when result is true
        this.getCountFromFirebase();
      }
    });
  }

  // getting unread count of loggedin user 
  getCountFromFirebase() {
    let env = 'prod/';
    const val = localStorage.getItem('authApiUrl');
    if (val) {          // setting environment
      if (val.includes('vendor1') || val.includes('dev')) {
        env = 'dev/';
      } else if (val.includes('qa')) {
        env = 'qa/';
      } else if (val.includes('staging')) {
        env = 'staging/';
      } else {
        env = 'prod/';
      }
    }
    const email = utils.getLoggedInUserEmail();
    if (email) {        // if email id is available
      const emailId = email.split('.').join();
      this.loggedInEmail = emailId;
      this.tenantId = localStorage.getItem('id');
      const unreadRef = firebase.database().ref('/chat/' + env + this.tenantId + '/' + emailId);
      unreadRef.on('value', (snapshot: any) => {
        if (snapshot && snapshot.val() && snapshot.val().totalUnreadMsg !== undefined) {
          this.unreadMsgCnt.next(snapshot.val().totalUnreadMsg);
        } else {
          this.unreadMsgCnt.next(0);
        }
      });
    }
  }

}

