/**
* @file BillingComponent
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sib-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  initmap(){
    alert('Init Map Called');
  }
}
