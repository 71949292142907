<div class="count-card">
    <div class="header">
        <h3 class="heading-tertiary">
            Inventories
        </h3>
    </div>
    <hr>

    <div class="counts">
        <div class="total-counts">
            <div class="total-count-child ">
                   <a [routerLink]="['/hoardings']" title="Go to Inventory">
                        {{inventoryCount?.total}}
                    </a>
            </div>
        </div>
        <div class="individual-count">
            <table>
                <tr>
                    <td class="detail-text">Hoardings</td>
                    <td class="detail-count">{{inventoryCount?.hoarding}}</td>
                </tr>
                <tr>
                    <td class="detail-text">Groups</td>
                    <td class="detail-count">{{inventoryCount?.group}}</td>
                </tr>
                <tr>
                    <td class="detail-text">Kiosks</td>
                    <td class="detail-count">{{inventoryCount?.kiosk}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>