<div class="roi-inventory-container">
  <div class="float-left margin-top-8">
      <sib-back [isHistory]="false" (eEmitIsHistory)="getHistory($event)"></sib-back>
  </div>
  <div class='contract-info' >{{contractId}} - {{contractName}}</div>
  
  <div class="roi-inventory-grid-scroller">
    <sib-grid name="roiInventoryLevelGrid" #sibGrid [totalRecords]="totalInventoryRecords"
      [columns]="inventoryDataColumns" [config]="inventoryGridConfig" [showFilter]="true" [showXlsExport]="showXlsExport"
      (eEmitExportXlsx)="exportXlsx()" (eEmitColumnSelection)="updatingUserGridConfig($event)">
      <sib-grid-column field="srNo">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <span>
            {{rowIndex+1}}
          </span>
        </ng-template>
      </sib-grid-column>
      <sib-grid-column field="invoiceId">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <div *ngIf="rowData.invoiceId; else dashTemplate">
            <div>
              <a class="link-1  after" target="_blank" (click)="getInvoice(rowData)">{{rowData.invoiceId}}</a>
            </div>
          </div>
          <ng-template #dashTemplate>
            -
          </ng-template>
        </ng-template>
      </sib-grid-column>
      <sib-grid-column field="rentItemCategory">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown #rentItemCategorydd [options]="rentItemCategoryDropdown"
            [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Type"
            (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>
    </sib-grid>

  </div>
</div>