
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule, DialogModule, OverlayPanelModule, DropdownModule, CheckboxModule, InputSwitchModule } from "primeng/primeng";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusVisualizationModule } from '../status-visualization/status-visualization.module';
import { RouterModule } from '@angular/router';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';
import { SiteExpirationTasksComponent } from './site-expiration-tasks.component';
import { HoardingStatusService } from '../../../hoardings-operation/services/hoardings-status.service';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        ReactiveFormsModule,
        FormsModule,
        StatusVisualizationModule,
        OverlayPanelModule,
        RouterModule,
        CurrencyMainPipe,
        DropdownModule,
        CheckboxModule,
        InputSwitchModule,
        DialogModule
    ],

    declarations: [
        SiteExpirationTasksComponent,
    ],

    exports: [
        SiteExpirationTasksComponent
    ],

    providers: [
        HoardingStatusService
    ]
})

export class SiteExpirationTasksModule { }
