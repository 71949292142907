/* 
* @file Convert into Campaign service.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2017
*/

import { Injectable } from "@angular/core";
import { ValueTransformer } from "@angular/compiler/src/util";

@Injectable()
export class CalculationService {

    decimal = 2;

    calculateDays(startDate: Date, endDate: Date, includeFirst?: boolean) {
        const date1 = new Date(this.setToBeginning(startDate));
        const date2 = new Date(this.setToBeginning(endDate));
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        let diffDays: number;
        if (includeFirst === false) {
            diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        } else {
            diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
        }
        return Number(diffDays);
    }

    /**
     * @description extend date with number of days
     * @param {*} date
     * @param {*} days
     * @returns
     * @memberof CalculationService
     */
    extendDate(date, days) {
        let extendedDate = new Date();
        extendedDate = new Date(new Date(date).getTime() + (days * 86400000));
        return extendedDate;
    }

    setToBeginning(date) {
        const newDate = new Date(date);
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        return newDate;
    }

    calculateOriginalAmountFromDiscount(rate, discountPctg) {
        if (Number(discountPctg) !== 100) {
            return Number((rate * 100) / (100 - discountPctg)).toFixed(2);
        } else {
            return Number((rate * 100) / (100 - 10)).toFixed(2); // last value on which discount was calculated would be 10
        }
    }

    getMilSecFromDays(days) {
        return (days * 24 * 60 * 60 * 1000);
    }

    getDateFromMillSec(milSec) {
        return new Date(milSec);
    }

    getMillSecFromDate(date) {
        return new Date(date).getMilliseconds();
    }

    calculateAmountWithoutTax(amount, taxPctg) {
        return Number(((amount * 100) / (100 + taxPctg)));
    }

    calculateReverseTax(amount, taxPctg) {
        return Number(((amount * taxPctg) / (100 + taxPctg)));
    }

    calculateTax(amount, taxPctg) {
        return Number((amount * taxPctg) / 100);
    }

    calculateTotalRent(cost: number) {  // total cost of all hoardings
        return Number(cost.toFixed(this.decimal));
        // return Number(Math.ceil(cost));
    }


    calculateCostOfHoarding(monthlyRate: number, days: number) {
        return Number(Math.fround((monthlyRate / 30) * days).toFixed(this.decimal));
        // return Number(Math.ceil((monthlyRate / 30) * days));
    }

    calculateRateOfHoarding(cost: number, days: number) {
        return Number(Math.fround(((cost * 30) / days)).toFixed(this.decimal));
        // return Number(Math.ceil((cost * 30) / days));
    }

    calculateGST(totalRent: number, printingAmount: number, mountingAmount: number, GSTRate: number) {
        return Number(Math.fround((Number(totalRent) + Number(printingAmount) + Number(mountingAmount)) * GSTRate / 100).toFixed(this.decimal));
        // return Number(Math.ceil((Number(totalRent) + Number(printingAmount) + Number(mountingAmount)) * GSTRate / 100));
    }

    calculateGSTOnTotal(totalWOTax: number, GSTRate: number) {
        return Number(Math.fround((totalWOTax) * GSTRate / 100).toFixed(this.decimal));
        // return Number(Math.ceil((totalWOTax) * GSTRate / 100));
    }

    calculateGrandTotalWOTax(totalRent: number, printingAmount: number, mountingAmount: number) {
        return Number(Math.fround((totalRent + printingAmount + mountingAmount)).toFixed(this.decimal));
        // return Number(Math.ceil((totalRent + printingAmount + mountingAmount)));
    }

    calculateGrandTotal(totalRent: number, printingAmount: number, mountingAmount: number, gst: number) {
        return Number(Math.fround((Number(totalRent) + Number(printingAmount) + Number(mountingAmount) + Number(gst))).toFixed(this.decimal));
        // return Number(Math.ceil((Number(totalRent) + Number(printingAmount) + Number(mountingAmount) + Number(gst))));
    }

    calculateLSPDifference(totalRent: number, minimumAmount: number) {
        return Number(Math.fround((Number(totalRent) - Number(minimumAmount))).toFixed(this.decimal));
        // return Number(Math.ceil((Number(totalRent) - Number(minimumAmount))));
    }

    calculateLSPDifferencePercentage(totalRent: number, minimumAmount: number) {
        return Number(Math.fround((Number(totalRent) - Number(minimumAmount)) / Number(minimumAmount) * 100).toFixed(this.decimal));
        // return Number(Math.ceil((Number(totalRent) - Number(minimumAmount)) / Number(minimumAmount) * 100));
    }

    calculatePctg(difference, actual) {
        return Number(Math.fround(Number(difference) / Number(actual) * 100).toFixed(this.decimal));
    }

    calculateDiscountAmount(originalRate: number, discountPctg: number) {
        return Number(Math.fround((originalRate * discountPctg) / 100).toFixed(this.decimal));
        // return Number(Math.ceil((originalRate * discountPctg) / 100));
    }


    calculateGSTWithAdditionalPrice(totalRent: number, printingAmount: number, mountingAmount: number, additionalPrintingPrice: number, additionalMountingPrice: number, GSTRate: number) {
        return Number(Math.fround((totalRent + printingAmount + mountingAmount + additionalPrintingPrice + additionalMountingPrice) * GSTRate / 100).toFixed(this.decimal));
        // return Number(Math.ceil((totalRent + printingAmount + mountingAmount + additionalPrintingPrice + additionalMountingPrice) * GSTRate / 100));
    }

    calculateGrandTotalWithAdditionalPrice(totalRent: number, printingAmount: number, mountingAmount: number, additionalPrintingPrice: number, additionalMountingPrice: number, gst: number) {
        return Number((totalRent + printingAmount + mountingAmount + additionalPrintingPrice + additionalMountingPrice + gst).toFixed(this.decimal));
        // return Number(Math.ceil(totalRent + printingAmount + mountingAmount + additionalPrintingPrice + additionalMountingPrice + gst));
    }

    calculateCostFromPricePerSqFt(price, days, sqFt) {
        return Number((price * sqFt * days) / 30);
        // return Number(Math.ceil((price * sqFt * days) / 30));
    }

    calculateMinimumAmt(minPrice, days) {
        return Number((minPrice * days) / 30);
        // return Number(Math.ceil((minPrice * days) / 30));
    }

    calculateRentPctChange(actualRent, changedRent) {
        const diff = changedRent - actualRent;
        const diffPctg = diff / actualRent * 100;
        return diffPctg;
    }

    calculateExtendedDays(startDate: Date, endDate: Date) {
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return Number(diffDays);
    }

}

