import { Directive, ElementRef, AfterViewInit, Renderer2, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[dialog-append]',
})

export class DialogAppendDirective implements AfterViewInit {

    // tslint:disable-next-line: no-input-rename
    @Input('dialog-append') panelStyleClass = "";

    constructor(
        private el: ElementRef,
        private rend: Renderer2
    ) { }

    ngAfterViewInit() {
        const elements = document.getElementsByClassName(this.panelStyleClass);
        if (elements && elements.length) {
            this.rend.setStyle(
                elements[0], 'width', this.el.nativeElement.parentElement.clientWidth + 'px', 1
            );
            this.rend.removeStyle(
                elements[0], 'min-width'
            );
            this.rend.addClass(elements[0], "directiveDropdownClass");
        }
    }

}
