
import { Directive, ElementRef, Input, Renderer2, OnInit, ViewChild, AfterViewInit, TemplateRef, ViewContainerRef, ComponentRef } from '@angular/core';
import { AuthService } from '../login-service/auth.service';

// create a Directive to decorate. To prevent confusion let's call this myHighlight to
// illustrate the fact this this is a custom appearance
@Directive({
    selector: '[permission]',

})
export class SecuredDirective implements OnInit, AfterViewInit {

    // @Input('permission') permission: string;
    // @ViewChild('secured') securedElem: ViewChild;
    isVisible: boolean = false;
    permissions: string = '';
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private el: ElementRef,
        private renderer: Renderer2,
        private auth: AuthService) {
        this.permissions = this.auth.getPermissions();
    }

    @Input('permission') set myPermission(permission: string) {
        let index = this.permissions.indexOf(permission);
        // to find the value at the next index for full comparision of the permission and not part of it
        let value = this.permissions.substr(index + permission.length, 1);
        // if (this.permissions.indexOf(permission) !== -1 && (this.permissions.substring(this.permissions.indexOf(permission) + 1, 0)) === ' ' || this.permissions.substring(this.permissions.indexOf(permission) + 1, 0) === '"') { 
        if (index !== -1 && (value === ' ' || value === '"')) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }


    ngAfterViewInit() {

    }

    ngOnInit() {
        /* let permissions: string[] = this.auth.getUserProfile().permissions;

        permissions.forEach(element => {
            if (element === this.permission) {
                this.isVisible = true;
            }
            console.log(element);
        });
        if (!this.isVisible) {

            this.componentRef.destroy();
            // this.el.nativeElement.
            // this.el.nativeElement.style.display = "none";


        } */
    }

}