import { Component, OnInit } from '@angular/core';
import { DashboardUiService } from '../../service/dashboard-ui.service';
import { PlanCount } from '../../../modals/dashboard/plan-count';
import { PlanStatusEnum } from '../../../shared/constants/plan-status-enum';

@Component({
  selector: 'sib-plan-count-card',
  templateUrl: './plan-count-card.component.html',
  styleUrls: ['./plan-count-card.component.scss']
})
export class PlanCountCardComponent implements OnInit {
  planCounts: PlanCount[];
  total: number;
  constructor(
    private dashboardUiService: DashboardUiService
  ) { }

  ngOnInit() {
    this.dashboardUiService.getPlanCount()
      .subscribe((response) => {
        this.planCounts = response.data;
        this.total = 0;
        for(var i=0; i<this.planCounts.length; i++){
          this.planCounts[i].status = PlanStatusEnum[this.planCounts[i].status]
          this.total = this.total + this.planCounts[i].count;
        }
      },
      (error) => {

      }
      )
  }

}
