import { Component, OnInit } from '@angular/core';
import { DashboardUiService } from '../../service/dashboard-ui.service';
import { InventoryCount } from '../../../modals/dashboard/inventory-count';
import { CampaignCount } from '../../../modals/dashboard/campaign-count';

@Component({
  selector: 'sib-campaign-count-card',
  templateUrl: './campaign-count-card.component.html',
  styleUrls: ['./campaign-count-card.component.scss']
})
export class CampaignCountCardComponent implements OnInit {
  campaignCount: CampaignStatusCount = new CampaignStatusCount;
  total: number;

  constructor(
    private dashboardUiService: DashboardUiService
  ) { }

  ngOnInit() {
    this.dashboardUiService.getCampaignCount()
      .subscribe((response) => {
        if(response) {
          this.campaignCount = response.data;
          this.total = this.campaignCount.approvedCount + this.campaignCount.pendingCount;
        }
      },
      (error) => {

      }
      )
  }

}

export class CampaignStatusCount {

  approvedCount: number;
  pendingCount: number;
}
