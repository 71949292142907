import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../../../../services/base-service/api.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Http } from "@angular/http";

@Injectable()
export class PlaceDetailService {

    urlPath: string = 'https://maps.googleapis.com/maps/api/place/details/json';

    constructor(
        private http: HttpClient,
        private Injector: Injector) {
    }

    getPlaceDetails(params) {
        if (navigator.onLine) {
            const httpOptions = {
                params: this.buildHttpParams(params)
            }
            return this.http.get(this.urlPath, httpOptions)
        }

    }

    buildHttpParams(paramObject: Object): HttpParams {
        paramObject = this.buildQueryParams(paramObject);
        let params: HttpParams = new HttpParams();
        for (let key in paramObject) {

            if (paramObject.hasOwnProperty(key) && paramObject[key]) {
                params = params.set(key, paramObject[key].toString());
            }
        }
        return params;
    }

    buildQueryParams(queryParams) {
        return queryParams = Object.assign({}, this.getDefaultQueryParams(), queryParams);
    }

    getDefaultQueryParams() {
        return {};
    }
} 