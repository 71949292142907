import { map, catchError } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions, Headers } from "@angular/http";
import { Observable, Observer } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ApiService, BASE_URL } from '../../../../services/base-service/api.service';
import { NotificatoinsService } from '../../../../services/notifications/notifications.service';

@Injectable({
    providedIn: 'root'
})
export class PendingUnbilledUIService extends ApiService<any, any, any> {
    // urlPath: string = 'campaigns?projection=campaignProjection';
    urlPath: string = 'campaigns';
    baseUrl: string;

    constructor(private Http: HttpClient,
        private Injector: Injector,
        private route: ActivatedRoute,
        private notificationServcie: NotificatoinsService,
    ) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    downloadFile(fileType, data) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLSX') {
                const fileUrl = this.baseUrl + 'salesJournals/pending/unbilled/xlsx';
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                })
                    // , catchError((err) => {
                    //     this.notificationServcie.error("XLSX Download Error", "Please refresh and retry");
                    //     return err;
                    // })
                );
            }
        }


    }

    getPendingUnbilledTaskCount(searchEvent) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'salesJournals/pending/unbilled/count', searchEvent)

        }
    }
}
