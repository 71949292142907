import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DropdownModule, DialogModule, ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, TooltipModule, CalendarModule, RadioButtonModule, CheckboxModule, ProgressSpinnerModule, OverlayPanelModule } from "primeng/primeng";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { CommonDataTableModule } from "../dataTable/dataTable.module";
import { AdditionalPriceDialogComponent } from "./additional-prices-dialog.component";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { InputTrimModule } from "../../../directives/input-trim";
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        TooltipModule,
        CommonDataTableModule,
        SibGridModule,
        CalendarModule,
        RadioButtonModule,
        ProgressSpinnerModule,
        InputTrimModule,
        CheckboxModule,
        CurrencyMainPipe,
        OverlayPanelModule,
        DropdownModule
    ],

    declarations: [
        AdditionalPriceDialogComponent
    ],

    exports: [
        AdditionalPriceDialogComponent
    ]
})

export class AdditionalPriceDialogModule { }
