import { Contracts } from '../contracts/contracts';

export class Roi {
    breakevens: number;
    contract: Contracts = new Contracts();
    differencePercentage: number;
    invoiceSum: number;
    migratedInvoiceSum: number;
    totalEstimate: number;
    unbilledSum: number;
}