<div class="reserve-invoice-details">
    <p-dialog *ngIf="displayReserveInvoiceDetailDialog" header="Add Invoice Details" [modal]="true" showEffect="fade"
        blockScroll="true" [(visible)]="displayReserveInvoiceDetailDialog" [width]="400"
        (onShow)="showInvoiceDetailsDialog()" (onHide)="hideInvoiceDetailsDialog()" [autoAlign]="false">
        <div *ngIf="isLoading">
            <p-progressSpinner class="loading"></p-progressSpinner>
        </div>
        <form #invoiceDetailsForm="ngForm" autocomplete="off" class="invoiceDetail">
            <div class="ui-g">
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Sales Rep
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5 srDropdown">
                        <p-dropdown name="salesRep" [options]="salesReps" [autoWidth]="false" [style]="{'width':'100%'}"
                            placeholder="Select a Sales Rep" filter="true" (onChange)="onSRChange($event)"
                            [panelStyleClass]="'srDropdown'" [(ngModel)]="selectedSR" [autoDisplayFirst]="false"
                            required>
                        </p-dropdown>

                        <!-- <p-autoComplete [inputStyle]="{'width':'100%'}" [(ngModel)]="selectedSR"
                            (ngModelChange)="selectedSR=$event" [suggestions]="filteredEmployees" field="fullName"
                            (completeMethod)="filterEmployees($event)" name="selectedSR" [minLength]="0"
                            [multiple]="false" [dropdown]="true" scrollHeight="160px" styleClass="wid20"
                            (onSelect)="setCompanyForEmployee()" forceSelection="true" [style]="{'width':'300px'}">
                        </p-autoComplete> -->
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Company
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5 companyDropdown">
                        <p-dropdown name="companies" [options]="companies" [autoWidth]="false"
                            [panelStyleClass]="'companyDropdown'" [style]="{'width':'100%'}"
                            placeholder="Select a Company" filter="true" (onChange)="setBranches()"
                            [(ngModel)]="selectedCompany" [autoDisplayFirst]="false" required></p-dropdown>
                        <!-- <p-autoComplete [inputStyle]="{'width':'100%'}" [ngModel]="selectedCompany"
                            (ngModelChange)="selectedCompany=$event" [suggestions]="filteredCompany"
                            (completeMethod)="filterCompany($event)" field="company" name="companyName" [minLength]="0"
                            [multiple]="false" [dropdown]="true" scrollHeight="160px" styleClass="wid20"
                            forceSelection="true" [style]="{'width':'300px'}">
                        </p-autoComplete> -->
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Branch
                            <span *ngIf="branchDropdown" class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5 customDropdown">
                        <p-dropdown #groupedDropdown name="branches" [options]="branches" [autoWidth]="false"
                            [style]="{'width':'100%'}" placeholder="Select a Branch" [group]="true"
                            [panelStyleClass]="'customDropdown'" (onChange)="onBranchChange($event)"
                            [(ngModel)]="selectedBranch" [autoDisplayFirst]="false" [required]="branchDropdown">
                            <ng-template let-group pTemplate="group">
                                {{group.label}} - {{group.value.branchAddress[0]?.state?.name}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Display Name
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input #displayName="ngModel" style="width: 100%" id="displayName" type="text" pInputText
                            name="displayName" [(ngModel)]="rowData.displayName" />
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Amount
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input #amt="ngModel" style="width: 100%" id="amount" type="text" pInputText
                            [pattern]="decimalNumbers" [min]="0" name="amount" [defaultValue]="0"
                            [(ngModel)]="rowData.totalAmount" (keyup)="validate($event, decimalNumbers)"
                            pTooltip="{{amt.hasError('pattern')? 'Positive Numbers Only' :''}}" />
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Gen Date
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <p-calendar #billGenDt="ngModel" shortYearCutoff="100" [inputStyle]="{'width':'100%'}"
                            name="billGenDate" [styleClass]="'customCalendar'" [utc]="true" appendTo="body"
                            [(ngModel)]="rowData.billGeneratedDate" showIcon="true" dateFormat="ddMy"
                            pTooltip="{{billGenDt.hasError('required')? 'Ex - 01Jan18' :''}}" trim="blur" required>
                        </p-calendar>
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>PO No
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input style="width: 100%" id="poNumber" type="text" pInputText name="poNumber"
                            [(ngModel)]="rowData.poNumber" />
                    </div>
                </div>
            </div>
        </form>
        <div class="ctext-center">
            <button type="button" pButton class="mtab-save margin-hori-5"
                label="{{rowData.utilized ? 'UPDATE' : 'SAVE'}}" [disabled]="!invoiceDetailsForm.form.valid"
                (click)="saveInvoiceDetails()"></button>
            <button type="button" pButton class="mtab-secondary margin-hori-5" label="CANCEL"
                (click)="cancelInvoiceDetails()"></button>
            <button *ngIf="rowData.utilized" type="button" pButton class="mtab-danger margin-hori-5" label="UNUTILIZE"
                (click)="unutilizedInvoice()"></button>
        </div>
    </p-dialog>
</div>