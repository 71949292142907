import { SharedModules } from "./modules/shared.modules";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { MainComponent } from "./main/main.component";
import { AppRoutingModule, appRoutingProviders } from "./app.routes";
import { AuthService } from "./login-service/auth.service";
import { AuthGuardService } from "./login-service/auth-guard.service";
import { environment } from "../environments/environment";
import { BASE_URL } from "./services/base-service/api.service";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TitleService } from "./services/title.service";
import { HoardingListComponent } from "./shared/components/hoarding-list/hoarding-list.component";
import { SibGridModule } from "./components/sib-forms/grid/grid.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";


import { StoreModule } from "@ngrx/store";
import { appReducers } from "./app state/app-module.reducers";
import { AppEffects } from "./app state/effects/app.effects";
import { EffectsModule } from "@ngrx/effects";

import { IndustryService } from "./services/shared/industry.service";
import { AppActions } from "./app state/actions/app-actions";
import { GroupService } from "./services/shared/group.service";
import { ContractTypeService } from "./services/shared/contract-type.service";


import { ActionDispatcher } from "./app state/action-dispatcher/action-dispatcher";
import { appReducer } from "./app state/reducers/app.reducers";
import { AuthConfigService } from "./services/authconfig.service";
import { SecuredDirective } from "./directives/permission.directive";
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IndustryDialogComponent } from "./shared/components/industry-dialog/industry-dialog.component";
import { IndustryModule } from "./shared/components/industry-dialog/industry.module";
import { GroupDialogComponent } from "./shared/components/group-dialog/group-dialog.component";
import { GroupDialogModule } from "./shared/components/group-dialog/group-dialog.module";
// import { AreaActionDispatcher } from "./masters/area/action-dispatcher/action-dispatcher";
import { StateService } from "./services/shared/states.service";
import { DistrictService } from "./services/shared/district.service";
import { CityService } from "./services/shared/cities.service";
import { AreaService } from "./services/shared/area.service";
import { AreaListGetService } from "./services/shared/areaListGet.service";
import { AreaListService } from "./services/shared/areaList.service";
import { DistrictListGetService } from "./services/shared/districtListGet.service"
import { AreaPostService } from "./services/shared/areaPost.service";
import { DistrictPostService } from "./services/shared/districtPost.service"
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { AppConfig } from "./services/app-config.service";
import { ServiceModule, configFactory } from "./services/services.module";
import { AreaDialogModule } from "./shared/components/area-dialog/area-dialog.module";
import { DistrictDialogModule } from "./shared/components/district-dialog/district-dialog.module";
import { ContractTypeDialogModule } from "./shared/components/contract-type-dialog/contract-type-dialog.module";
import { HoardingService } from "./services/shared/hoardings.service";
import { ContractHoardingService } from "./services/shared/contract-hoarding.service";
import { HoardingsComponent } from './hoardings/hoardings.component';
import { HoardingDialogModule } from "./shared/components/hoarding-dialog/hoarding-dialog.module";
import { HoardingDialogAreaService } from "./shared/components/hoarding-dialog/hoarding-area.serivce";
import { AngularFireModule } from 'angularfire2';
import { FirebaseService } from "./services/shared/firebase.service";
import * as firebase from 'firebase';
import { ListHoardingsComponent } from "./shared/components/list-hoardings/list-hoardings.component";
import { FilterService } from "./hoardings/services/filter.service";
import { BrowserModule } from "@angular/platform-browser";
import { AutoCompleteModule, ButtonModule, DataTableModule, PanelModule, LightboxModule, TabMenuModule, OverlayPanelModule, TreeTableModule, GalleriaModule, TabViewModule, InputTextModule, DropdownModule, InputTextareaModule, SidebarModule, CalendarModule, PickListModule, SliderModule, DialogModule, DataListModule, ChartModule, ProgressSpinnerModule, MenuModule, MenuItem, GMapModule, ConfirmationService, CheckboxModule, EditorModule, TooltipModule } from "primeng/primeng";
// import { AuthConfigService } from "./services/authconfig.service";
// import { Title } from '@angular/platform-browser';
// import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng2PicaModule, Ng2PicaService } from 'ng2-pica';
// import { PlanModule } from "./plans/plan.module";
import { EventComponent } from "./events/event.component";
import { EventConfig } from "./modals/events/event-config";
// import { CampaignModule } from "./campaigns/campaign.module";
import { AreaCategoryService } from "./services/shared/area-category.service";
import { TokenInterceptor } from "./services/tokeninterceptor/tokeninterceptor.service";
import { AuthApiService } from "./services/shared/auth-api.service";
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { InputTrimModule } from "./directives/input-trim/input-trim.module";
import { PhotoDialogModule } from "./shared/components/photo-dialog/photo-dialog.module";
import { NotificatoinsService } from "./services/notifications/notifications.service";
import { CurrencyMainPipe } from "./shared/helpers/currency.pipe/currency.main.pipe";
import { CommonDataTableModule } from "./shared/components/dataTable/dataTable.module";
import { AssignToPlanDialogModule } from "./shared/components/assignToPlanDialog/assign-to-plan-dialog.module";
import { HoardingGroupDialogModule } from "./shared/components/hoarding-group-dialog/hoarding-group-dialog.module";
import { GroupHoardingDialogModule } from "./shared/components/group-hoarding-dialog/group-hoarding-dialog.module";
import { CommonTreeTableModule } from "./shared/components/treeTable/tree-table.module";
import { UserDetailsComponent } from './login-service/user-details/user-details.component';
import { UserService } from "./services/shared/user.service";
import { SettingService } from "./masters/setting/services/setting.service";
import { AddHoardingDialogModule } from "./shared/components/add-hoarding-dialog/add-hoarding-dialog.module";
import { HotkeyModule, HotkeysService, HotkeyOptions } from 'angular2-hotkeys';
import { StatusQueueDialogModule } from "./shared/components/status-queue-dialog/status-queue-dialog.module";
import { HoardingGroupsModule } from "./hoarding-groups/hoarding-groups.module";
import { AddMonitoringDialogModule } from "./shared/components/add-monitoring-dialog/add-monitoring-dialog.module";
import { PlaygroundComponent } from './playground/playground.component';
import { DataScrollerModule } from "primeng/components/datascroller/datascroller";
import { PreviewSendDialogModule } from "./shared/components/preview-send-dialogue/preview-send-dialog.module";
import { SibPPTPreviewModule } from "./shared/components/sib-ppt-preview/sib-ppt-preview.module";
import { SibEmailModule } from "./shared/components/sib-email/sib-email.module";
// import { CampaignMonitoringTabModule } from "./shared/components/campaign-monitoring-tab/campaign-monitoring-tab.module";
// import { CampaignMonitoringValuesModule } from "./shared/components/campaign-monitoring-values/campaign-monitoring-values.module";
import { UploadImageDialogModule } from "./shared/components/upload-image-dialog/upload-image-dialog.module";
import { PlayGroundService } from "./services/shared/playGround.service.";
import { MapModule } from "./shared/components/map/map.module";
import { RemoveMonitoringService } from "./services/shared/update-monitoring.service";
import { VendorService } from "./services/shared/vendor.service";
import { SystemService } from "./services/shared/system.service";
import { SibMasterService, MASTER_URL } from "./services/sib-master.service";
import { AdditionalPriceDialogModule } from "./shared/components/additional-prices-dialog/additional-prices-dialog.module";
import { HoardingGroupEditDialogModule } from "./shared/components/hoarding-group-edit-dialog/hoarding-group-edit-dialog.module";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { BlockConvertPlanModule } from "./shared/components/block-convert-plan/block-convert-plan.module";
import { NotificationsModule } from "./notifications/notifications.module";
import { NotificationsComponent } from "./notifications/notifications.component";
import { MounterService } from "./services/shared/mounter.service";
import { DUMMY_URL } from "./services/base-service/dummy.service";
import { AUTH_API_URL } from "./services/base-service/auth.service";
import { AUTH_PERMISSION_URL } from "./services/base-service/auth-permission.service";
import { PublicPageComponent } from './public-page/public-page.component';
import { CompareBillsModule } from "./shared/components/compareBills/compareBills.module";
import { PublicPageService } from "./services/shared/publicpage.service";
import { SibBackModule } from "./shared/components/sib-back/sib-back.module";
import { BillingNotesModule } from "./shared/components/billing-notes/billing-notes.module";
// import { RestrictBlockConvertPlanModule } from "./shared/components/restrict-block-convert-plan/restrict-block-convert-plan.module";
import { PhotoInterfaceService, PhotoInterfaceAllImagesService } from "./services/shared/photo-interface.service";
// import { CampaignEditDialogModule } from "./shared/components/campaign-edit-dialog/campaign-edit-dialog.module";
import { AuthUserAPIService } from "./services/shared/auth-user.service";
import { ImageService } from "./services/shared/image.service";
import { PlanListValidationDialogModule } from "./shared/components/plan-list-validation-dialog/plan-list-validation-dialog.module";
import { DateDeleteMessageDialogModule } from "./shared/components/date-delete-message-dialog/date-delete-message-dialog.module";
import { ActivityLogModule } from "./shared/components/activity-log/activity-log.module";
import { CustomerProfilePageModule } from "./shared/components/customer-profile-page/customer-profile-page.module";
import { ActivityLoogService } from "./services/shared/activity-logs.service";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SibChartComponent } from "./sib-chart/sib-chart.component";
import { HoardingMasterColService } from "./services/shared/hoarding-master-column.service";
import { UiConfigurationService } from "./masters/configurations/services/ui-configuration.service";
import { HttpConfigurationService } from "./masters/configurations/services/http-configuration.service";
import { EmailConfigService } from "./services/shared/email-config.service";
import { GooglePieChartService } from "./services/shared/pieChart.services";
import { GoogleStackedBarChartService } from "./services/stackedBarChart.service";
import { GoogleTimelineChartService } from "./services/shared/timeline-chart.service";
import { HoardingConfigurationService } from "./services/shared/hoarding-configuration.service";
import { CustomerConfigurationService } from "./services/shared/customer-configuration.service";
import { PlanConfigurationService } from "./services/shared/plan-configuration.service";
import { CampaignConfigurationService } from "./services/shared/campaign-configuration.service";
import { MonitoringConfigurationService } from "./services/shared/monitoring-configuration.service";
import { TaxConfigurationService } from "./services/shared/tax-configuration.service";
import { BillingConfigurationService } from "./services/shared/billing-configuration.service";
import { DocumentModule } from "./shared/components/documents/document.module";
import { GoogleHistogramChartService } from "./services/shared/histogramChart.service";
import { GoogleTableChartService } from "./services/shared/table-chart.service";
import { GoogleLineChartService } from "./services/shared/lineChart.services";
import { ChatBaseService } from "./services/shared/chat.base.services";
import { GridService } from './services/shared/grid-service';
import { GridUiService } from './services/shared/grid-ui-service';
import { GoogleColumnChartService } from "./services/shared/column-chart.service";
import { CityDialogComponent } from './shared/components/city-dialog/city-dialog.component';
import { TaskModule } from './shared/components/tasks/tasks.module';
import { PassbookModule } from './shared/components/passbook/passbook.module';
import { QuillModule } from 'ngx-quill'
import { ArticlesService } from './services/shared/articles.service';
import { ArticlesGetService } from './services/shared/articles-get.service';
import { HELP_URL } from './services/base-service/help.service';
import { CanActivatePassbookGuardService } from './shared/components/passbook/services/canActivatePassbookGuard.service';
import { HoardingMapComponent } from './shared/components/hoarding-map/hoarding-map.component';
import { ChangesService } from './services/shared/changes.service';
import { HoardingStatusService } from './hoardings-operation/services/hoardings-status.service';
import { SuperBillsService } from './billings/services/superbill.service'
import { BillingModule } from './billings/billing.module';
import { SuggestionModule } from './suggestion-view/suggestion.module';
import { GoogleAreaChartService } from './services/shared/area-chart.service';
import { HoardingMapViewComponent } from './shared/components/hoarding-map-view/hoarding-map-view.component';
import { HoardingMapViewModule } from './shared/components/hoarding-map-view/hoarding-map-view.module';
// import { HoardingMapViewComponent } from './hoardings/hoarding-map-view/hoarding-map-view.component';
import { AnalyticsModule } from './analytics/analytics.module';
import { HoardingGroupService } from './contracts/services/hoarding-group.service';
import { EncrDecrService } from './services/shared/encr-decr.service';
import { CampaignSummaryTemplateModule } from './shared/components/campaign-summary-template/campaign-summary-template.module';
// import { CaseListModule } from './case-list/case-list.module';
// import { CaseListService } from './services/shared/case-list.service';
// import { CASE_URL } from './services/base-service/case.service';
import { PreviewModule } from './shared/components/preview/preview.module';
import { CreateMediaService } from './services/shared/create-media.service';

export function HttpLoaderFactory(http: HttpClient) {
}

export const firebaseConfig = {
    apiKey: "AIzaSyA-jzJQfisHc6UrC5hlseKeH9AsszMS8Ns",
    authDomain: "showitbig-411c5.firebaseapp.com",
    databaseURL: "https://showitbig-411c5.firebaseio.com",
    projectId: "showitbig-411c5",
    storageBucket: "showitbig-411c5.appspot.com",
    messagingSenderId: "963545086691"
};
firebase.initializeApp(firebaseConfig);

@NgModule({
    imports: [
        SharedModules,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SibGridModule,
        IndustryModule,
        GroupDialogModule,
        HoardingDialogModule,
        ContractTypeDialogModule,
        DataListModule,
        AreaDialogModule,
        // DistrictDialogModule,
        // CityDialogComponent,
        ReactiveFormsModule,
        FormsModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        ButtonModule,
        DataTableModule,
        PanelModule,
        LightboxModule,
        TabMenuModule,
        OverlayPanelModule,
        CheckboxModule,
        TreeTableModule,
        GalleriaModule,
        TabViewModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        CalendarModule,
        SidebarModule,
        PickListModule,
        SliderModule,
        ChartModule,
        DialogModule,
        StoreModule.forRoot({ appList: appReducer }),
        StoreDevtoolsModule.instrument({
            maxAge: 40,
        }),
        StoreModule.forRoot(appReducers),
        // StoreDevtoolsModule.instrument(),
        // StoreModule.forRoot([appReducer]),
        // declare using object not array
        EffectsModule.forRoot([AppEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 10,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceModule,
        AngularFireModule.initializeApp(firebaseConfig),
        Ng2PicaModule,
        InputTrimModule,
        PhotoDialogModule,
        CurrencyMainPipe,
        CommonDataTableModule,
        AssignToPlanDialogModule,
        HoardingGroupsModule,
        StatusQueueDialogModule,
        HoardingGroupDialogModule,
        GroupHoardingDialogModule,
        AddHoardingDialogModule,
        CommonTreeTableModule,
        ProgressSpinnerModule,
        AddMonitoringDialogModule,
        DataScrollerModule,
        MenuModule,
        PreviewSendDialogModule,
        SibPPTPreviewModule,
        UploadImageDialogModule,
        AdditionalPriceDialogModule,
        MapModule,
        GMapModule,
        HoardingGroupEditDialogModule,
        BillingNotesModule,
        CompareBillsModule,
        SibBackModule,
        DocumentModule,
        TaskModule,
        PassbookModule,
        DateDeleteMessageDialogModule,
        PlanListValidationDialogModule,
        SibEmailModule,
        ActivityLogModule,
        CustomerProfilePageModule,
        QuillModule,
        EditorModule,
        TooltipModule,
        // NotificationsModule,
        // NotificationServiceModule.forRoot(),
        BillingModule,
        SuggestionModule,
        HoardingMapViewModule,
        // CapacityChartModule,
        AnalyticsModule,
        CampaignSummaryTemplateModule,
        // CaseListModule,
        PreviewModule,

    ],
    bootstrap: [AppComponent],

    providers: [
        appRoutingProviders,
        AuthService,
        AuthGuardService,
        { provide: BASE_URL, useValue: environment.baseUrl },
        { provide: DUMMY_URL, useValue: environment.dummyUrl },
        { provide: MASTER_URL, useValue: environment.masterUrl },
        { provide: HELP_URL, useValue: environment.helpUrl },
        // { provide: CASE_URL, useValue: environment.caseUrl },
        { provide: AUTH_API_URL, useValue: localStorage.getItem('authApiUrl') },
        { provide: AUTH_PERMISSION_URL, useValue: localStorage.getItem('auth_permission_url') },
        { provide: AppConfig, useFactory: configFactory },
        TitleService,
        TranslateService,
        IndustryService, GroupService, ContractTypeService,
        AppActions,
        ActionDispatcher,
        AuthConfigService,
        StateService,
        DistrictService,
        DistrictListGetService,
        CityService,
        AreaService,
        AreaListGetService,
        AreaListService,
        AreaPostService,
        DistrictPostService,
        AuthConfigService,
        HoardingService,
        ContractHoardingService,
        HoardingDialogAreaService,   // remove after testing
        AreaCategoryService,
        FirebaseService,
        FilterService,
        Ng2PicaService,
        EventConfig,
        NotificatoinsService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuthApiService,
        SettingService,
        UserService,
        PlayGroundService,
        RemoveMonitoringService,
        VendorService,
        SystemService,
        SibMasterService,
        MounterService,
        PublicPageService,
        PhotoInterfaceService,
        PhotoInterfaceAllImagesService,
        AuthUserAPIService,
        ImageService,
        ActivityLoogService,
        HoardingMasterColService,
        UiConfigurationService,
        HttpConfigurationService,
        EmailConfigService,
        GooglePieChartService,
        GoogleStackedBarChartService,
        GoogleAreaChartService,
        GoogleTimelineChartService,
        HoardingConfigurationService,
        CustomerConfigurationService,
        PlanConfigurationService,
        MonitoringConfigurationService,
        TaxConfigurationService,
        BillingConfigurationService,
        GoogleHistogramChartService,
        GoogleTableChartService,
        GoogleLineChartService,
        ChatBaseService,
        GridService,
        GridUiService,
        GoogleColumnChartService,
        ConfirmationService,
        ArticlesService,
        ArticlesGetService,
        CanActivatePassbookGuardService,
        ChangesService,
        HoardingStatusService,
        SuperBillsService,
        CampaignConfigurationService,
        HoardingGroupService,
        EncrDecrService,
        CreateMediaService,
        // CaseListService,
        NotificatoinsService,

    ],

    declarations: [
        EventComponent,
        PlaygroundComponent,
        HoardingMapComponent,

        // HoardingMapViewComponent
        // HoardingMapViewComponent
        // OutboundCallComponent,
        // DashboardComponent
        // SibChartComponent
        // PublicPageComponent
    ],


})
export class AppModule { }
