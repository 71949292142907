import { GstBranchAddress } from "../KYC/gst-branch-address";
import { GSTLevelEnum } from "../../shared/constants/GSTLevel-enum";
import { State } from "../Area/state";

export class InvoiceGstDetail {
    gstNo: string;
    panNo: string;
    branchAddress: GstBranchAddress = new GstBranchAddress();
    salesType: GSTLevelEnum;
    // suppliableState: State = new State();

    setInvoiceGstDetail(item: any): InvoiceGstDetail {
        if (item && item !== "") {
            if (item.gst === undefined) {
                this.gstNo = item.gstNo;
                this.panNo = item.panNo;
                this.branchAddress = item.branchAddress[0];
                this.salesType = item.salesType;
            } else {
                this.gstNo = item.gst.gstNo;
                this.panNo = item.gst.panNo;
                this.branchAddress = item.gst.branchAddress[0];
                this.salesType = item.gst.salesType;
            }
        }
        return this;
    }
}
