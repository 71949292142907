<!-- <div *ngIf="isLoading">
  <p-progressSpinner class="loading"></p-progressSpinner>
</div> -->
<p-confirmDialog header="Information" icon="fa fa-info-circle" width="425" acceptLabel="OK" [rejectVisible]="false">
</p-confirmDialog>
<!-- Displaying the user Details Dialog when user logins for the first time -->
<sib-user-details *ngIf="displayUserDetailDialog" [user]="user" [display]="displayUserDetailDialog"
  (eSave)="saveUser($event)" (eEmit)="closeDialog()"></sib-user-details>


<router-outlet></router-outlet>

<div *ngIf="!(online$ | async)" class="connection-message">
  There is no internet connection.
  <br>
  Connect with your internet and try again.
</div>

<p-dialog header="Information" *ngIf="showRefreshScreenDialog" [(visible)]="showRefreshScreenDialog" modal="true"
  [closable]="false" [blockScroll]="true" [autoAlign]="false">
  Connection established. Please refresh.
</p-dialog>