/**
* @file area-dialog.component
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewEncapsulation, HostListener, ChangeDetectorRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActionDispatcher } from "../../../app state/action-dispatcher/action-dispatcher";
import * as utils from '../../../helpers/utils';
import { Subscription } from "rxjs";
import { SelectItem, SelectItemGroup, Dropdown } from 'primeng/primeng';
import { Invoice } from '../../../modals/billings/invoice';
import { AppUrls } from '../../../services/urls';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { CreateCustomerService } from '../../../customers/services/createCustomerService';
import { InvoiceGstDetail } from '../../../modals/billings/invoice-gst-detail';
import { CampaignService } from '../../../services/shared/campaign.service';
import * as _ from 'lodash';
import { FirebaseService } from '../../../services/shared/firebase.service';
import { InvoiceWrapper } from '../../../modals/billings/invoice-wrapper';
import { ActivityLogModuleEnum } from '../../constants/activity-log-module-enum';
import { ImageService } from '../../../services/shared/image.service';
import { FileItem } from '../../../modals/files/file-item';
import { PurchaseOrderWrapper } from '../../../modals/campaigns/purchaseOrderWrapper';
import { MultiMediaTypeEnum } from '../../constants/multi-media-type-enum';
import * as fileUtils from '../../../helpers/file.util';
import { PurchaseOrder } from '../../../modals/campaigns/purchaseOrder';


@Component({
    selector: 'sib-update-invoice-dialog',
    templateUrl: './update-invoice-dialog.component.html',
    styleUrls: ['./update-invoice-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UpdateInvoiceDialogComponent implements OnInit, OnDestroy {

    @ViewChild("groupedDropdown") groupedDropdown: Dropdown;

    @Input() displayUpdateInvoiceDialog = false;
    _invoice: Invoice = new Invoice();
    @Input() set invoice(invoice) {
        this._invoice = invoice;
    }
    get invoice() {
        return this._invoice;
    }

    _migratedInvoice = false;
    @Input() set migratedInvoice(migratedInvoice) {
        this._migratedInvoice = migratedInvoice;
        this._migratedInvoice = JSON.parse(JSON.stringify(this._migratedInvoice));
    }

    get migratedInvoice() {
        return this._migratedInvoice;
    }

    @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
    @Output() updatedInvoice: EventEmitter<any> = new EventEmitter();

    isLoading = false;
    companies: SelectItem[] = [];
    selectedCompany: any;
    branches: SelectItemGroup[] = [];
    branchDropdown = false;
    invoiceGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    selectedBranch: any;
    selectedBranchObject: any = null;
    purchaseOrdersOptions: SelectItem[] = [];
    selectedPO: any;
    invoiceWrapper: InvoiceWrapper = new InvoiceWrapper();
    displayName: string;
    selectedCompanyGstNo: string;
    poNumber: string;
    poDate: string;

    files: FileItem[] = [];  // for image upload
    purchaseOrdersWrapper: PurchaseOrderWrapper[] = [];

    isFileDropped: boolean = false;
    firebaseUrlSaved: boolean = false;
    purchaseOrders: PurchaseOrder[] = [];
    selectedCompanyValue: any;
    isPoEdited: boolean = false;

    constructor(
        private createCustomerService: CreateCustomerService,
        private notificationServcie: NotificatoinsService,
        private campaignService: CampaignService,
        private firebaseService: FirebaseService,
        private imageService: ImageService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.getCompanies();
        this.getPOList();
        this.setDisplayName();

        this.firebaseService.resetFileItems();
        // set url to files after uploading it to firebase
        this.firebaseService.fileItems$.subscribe((fileItems) => {
            if (fileItems && fileItems.length > 0) {
                this.setUrlToFiles(fileItems);  //set url to files
                this.firebaseUrlSaved = true;

                this.getFileObjectFromWrapper(); // update the file name
                this.purchaseOrders.forEach((po) => {
                    po.folder = this.files[0].imagesFolder; // update the folder
                });

                this.uploadCampaignPO(); // upload po in campaign
                this.detectChanges();
            }
        });
    }

    /**
     * @description get companies and set selected company
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    getCompanies() {
        this.isLoading = true;
        this.companies = [];
        this.createCustomerService.get({ customerId: this.invoice.campaign.customer.id }, AppUrls.SEARCH + AppUrls.BY_CUSTOMER_ID).subscribe(
            (response) => {
                if (response) {
                    response['data'].forEach((customer) => {
                        if (this.invoice.campaign.customer &&
                            this.invoice.campaign.customer.company &&
                            customer.company === this.invoice.campaign.customer.company) { // set company which is present in invoice
                            this.selectedCompany = this.invoice.campaign.customer.billingName;
                            this.selectedCompanyValue = customer;
                            this.setBranches();
                        } else {
                            // Do Nothing
                        }

                    });
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    /**
     * @description get po list from campaign
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    getPOList() {
        this.isLoading = true;
        this.purchaseOrdersOptions = [];
        this.campaignService.get({ campaignId: this.invoice.campaign.id }, AppUrls.GET_PO_LIST).subscribe(
            (response) => {
                if (response) {
                    response['data'].forEach((po) => {
                        this.purchaseOrdersOptions.push({ label: po.name, value: po });
                    });
                    this.selectedPO = this.invoice.purchaseOrders;
                    this.isLoading = false;
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    /**
     * @description set display name
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    setDisplayName() {
        this.displayName = this.invoice.campaign.displayName;
    }

    /**
     * @description set branches
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    setBranches() {
        this.branches = [];
        this.selectedBranchObject = null;
        this.selectedBranch = null;
        this.groupedDropdown.value = '';
        if (this.selectedCompany && this.selectedCompanyValue && this.selectedCompanyValue.gstDetails) {
            this.selectedCompanyValue.gstDetails.forEach((gst) => {
                this.setBranchDropdown(gst);
            });
        }
        if (this.branches.length > 0) {
            this.branchDropdown = true;
            this.setSelectedBranch();
        } else {
            this.selectedCompanyGstNo = "";
            this.branchDropdown = false;
        }
    }

    /**
     * @description set branch dropdown according to the selected gst
     * @author Divya Sachan
     * @date 2019-11-06
     * @param {*} gst
     * @memberof UpdateInvoiceDialogComponent
     */
    setBranchDropdown(gst) {
        const items: SelectItem[] = [];
        gst.branchAddress.forEach((add) => {
            const value = { address: add, gst: gst };
            //branches of selected gst in invoice should only be pushed
            if (this.invoice.invoiceGstDetail.gstNo && this.invoice.invoiceGstDetail.gstNo === value.gst.gstNo) {
                items.push({ label: add.branchName + '-' + add.city.name, value: value });
            } else {
                // Do Nothing
            }
        });
        // selected gst should only be pushed
        if (this.invoice.invoiceGstDetail.gstNo && this.invoice.invoiceGstDetail.gstNo === gst.gstNo) {
            this.branches.push({ label: gst.gstNo, value: gst, items: items });
            this.selectedCompanyGstNo = gst.gstNo;
        } else {
            // Do Nothing
        }
    }

    /**
     * @description set selected branch
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    setSelectedBranch() {
        let branchNotMatchedCount: number = 0;
        this.branches.forEach((branch) => {
            branchNotMatchedCount = 0;
            branch.items.forEach((item) => {
                if (this.checkEqualityForBranch(item.value, this.invoice.invoiceGstDetail)) {
                    this.selectedBranch = item.value;
                    this.groupedDropdown.value = item.value;
                } else {
                    branchNotMatchedCount += 1;
                }
            });
            if (branchNotMatchedCount === this.branches[0].items.length) {
                this.selectedBranch = this.branches[0].items[0].value;
            }
        });

        this.isLoading = false;
    }

    /**
     * @description check whether the branch in invoice matches 
     * with the options in branch dropdown to show selected value
     * @author Divya Sachan
     * @date 2019-11-06
     * @param {*} item
     * @param {*} gstDetail
     * @returns
     * @memberof UpdateInvoiceDialogComponent
     */
    checkEqualityForBranch(item, gstDetail) {
        let result = false;
        if (item.gst.gstNo === gstDetail.gstNo && item.gst.panNo === gstDetail.panNo &&
            item.address.address1 === gstDetail.branchAddress.address1 &&
            item.address.branchName === gstDetail.branchAddress.branchName &&
            item.address.postalCode === gstDetail.branchAddress.postalCode) {
            result = true;
        }
        return result;
    }

    /**
     * @description on branch selection set the branch object
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    onBranchChange() {
        this.selectedBranchObject = this.createBranchItemObject(this.selectedBranch);
        this.createInvoiceGSTDetailObj();
        this.selectedPO = null;
    }

    createBranchItemObject(branch) {
        const branchClone = _.cloneDeep(branch); // to prevent the actual branch in dropdown from getting changed
        let index;
        branchClone.gst.branchAddress.filter((add, ind) => {
            if (JSON.stringify(branchClone.address) === JSON.stringify(branchClone.gst.branchAddress[ind])) {
                index = ind;
            }
        });
        if (index !== undefined) {
            branchClone.gst.branchAddress = branchClone.gst.branchAddress.filter((add, ind) => {
                if (ind === index) {
                    return add;
                }
            });
            return branchClone.gst;
        }
    }

    createInvoiceGSTDetailObj() {
        this.invoiceGstDetail = new InvoiceGstDetail().setInvoiceGstDetail(this.selectedBranchObject);
    }

    onPOChange(event) {

    }

    /**
     * @description view po from dropdown
     * @author Divya Sachan
     * @date 2019-11-06
     * @param {*} po
     * @memberof UpdateInvoiceDialogComponent
     */
    viewPO(po) {
        this.firebaseService.downloadImage(po.value.name, po.value.folder).then((response) => {
            window.open(response);
        });
    }

    /**
     * @description hides the quick invoice update dialog
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    onUpdateInvoiceDialogHide() {
        this.displayUpdateInvoiceDialog = false;
        this.displayReset.emit(false);
    }

    /**
     * @description update invoice
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    updateInvoice() {
        this.invoiceWrapper.id = this.invoice.id;
        this.invoiceWrapper.customer = this.invoice.campaign.customer;
        this.invoiceWrapper.customer.billingName = this.selectedCompany;
        this.invoiceWrapper.invoiceGstDetail = (this.selectedBranch) ? new InvoiceGstDetail().setInvoiceGstDetail(this.createBranchItemObject(this.selectedBranch)) : new InvoiceGstDetail();
        // this.invoiceWrapper.invoiceGstDetail = this.selectedBranch;
        this.setSelectedPO();
        this.invoiceWrapper.purchaseOrders = this.selectedPO;
        this.invoiceWrapper.displayName = this.displayName;
        this.invoiceWrapper.invoiceId = this.invoice.invoiceId;
        this.invoiceWrapper.isPoEdited = this.isPoEdited;
        this.updatedInvoice.emit(this.invoiceWrapper);
        this.onUpdateInvoiceDialogHide();
    }

    /**
     * @description cancel the invoice update
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    cancelUpdate() {
        this.onUpdateInvoiceDialogHide();
    }

    /**
     * @description set selected po
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    setSelectedPO() {
        if (this.selectedPO) {
            if (this.poNumber) { // set po number if edited.
                this.selectedPO[0].poNumber = this.poNumber;
                this.isPoEdited = true;
            } else {
                this.isPoEdited = false;
            }
            if (this.poDate) { // set po date if edited.
                this.selectedPO[0].poDate = this.poDate;
                this.isPoEdited = true;
            } else {
                this.isPoEdited = false;
            }
        } else { // otherwise
            this.isPoEdited = false;
        }
    }

    getChangedType() {
        if (!this.migratedInvoice) {
            return ActivityLogModuleEnum.INVOICE;
        } else {
            return ActivityLogModuleEnum.MIGRATED_INVOICE;
        }
    }

    /**
     * @description create object on file selection for firebase upload
     * @author Divya Sachan
     * @date 2019-11-06
     * @param {*} event
     * @memberof UpdateInvoiceDialogComponent
     */
    onFileSelection(event) {
        this.files = [];
        this.imageService.getFirebasePoObjects(event.files).forEach((object) => {
            this.files.push(object);
        });
        this.imageService.getDatabasePoObjects(event.files, MultiMediaTypeEnum['FILE']);
        this.imageService.databasePoObjects$.subscribe((databasePoObjects) => {
            if (databasePoObjects && databasePoObjects.length > 0) {
                databasePoObjects.forEach((object) => {
                    const purchaseOrderWrapper: PurchaseOrderWrapper = new PurchaseOrderWrapper();
                    purchaseOrderWrapper.purchaseOrder = object;
                    this.purchaseOrdersWrapper.push(purchaseOrderWrapper);
                });
                this.imageService.databasePoObjects$.next([]);
            }
        });

    }

    @HostListener('drop', ['$event'])
    onFileDrop(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        if (event.dataTransfer.files.length === 1) {
            this.onFileSelection(event.dataTransfer);
        } else if (event.dataTransfer.files.length > 1) {
            this.notificationServcie.info("One file can be uploaded at a time.", "PO Upload");
        } else {
            this.notificationServcie.info("Please Drag a File", "PO Upload");
        }
    }

    /**
     * @description set firebase folder and upload 
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    uploadFiles() {
        this.renameFileNames();
        this.imageService.setFirebaseImageFolder(this.files, 'campaigns', this.invoice.campaign.id, 'purchaseOrder');
        this.firebaseService.uploadImageToFirebase(this.files[0]);
    }

    /**
     * @description  get file object from wrapper and update the name 
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    getFileObjectFromWrapper() {
        this.purchaseOrders = [];
        this.purchaseOrdersWrapper.forEach((file) => {
            if (file.newName && file.newName !== "") {
                file.purchaseOrder.name = file.newName;
            }
            this.purchaseOrders.push(file.purchaseOrder);
        });
    }

    /**
     * @description update file names 
     * @author Divya Sachan
     * @date 2019-11-06
     * @param {*} finalFileList
     * @memberof UpdateInvoiceDialogComponent
     */
    updateNamesFromFiles(finalFileList) {
        finalFileList.forEach((file, index) => {
            const finalFile = new File([this.files[index].file], finalFileList[index].name, { type: this.files[index].file.type });
            this.files[index].file = finalFile;
        });
    }

    /**
     * @description rename file names and update the files object
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    renameFileNames() {
        this.getFileObjectFromWrapper();
        let files: any[] = [];
        let finalFileList: any[] = [...files, ...this.purchaseOrders];
        finalFileList = fileUtils.renameDuplicateNames(finalFileList, 'name');
        finalFileList = finalFileList.slice(files.length, finalFileList.length);
        finalFileList.forEach((file, index) => {
            this.purchaseOrdersWrapper.forEach((doc, ind) => {
                if (index === ind) {
                    if (doc.newName === "" || doc.newName === undefined) {
                        doc.newName = file.name;
                    }
                    else {
                        if (!fileUtils.getFileExtension(doc.newName)) {
                            doc.newName = doc.newName + "." + file.extension;
                            file.name = doc.newName;
                        }
                    }
                }
            });
        });
        this.updateNamesFromFiles(finalFileList);
    }

    /**
     * @description set url to files
     * @author Divya Sachan
     * @date 2019-11-06
     * @param {*} fileItems
     * @memberof UpdateInvoiceDialogComponent
     */
    setUrlToFiles(fileItems) {
        if (fileItems && fileItems.length === this.purchaseOrdersWrapper.length) {
            this.purchaseOrdersWrapper.forEach((file, index) => {
                fileItems.forEach((fileItem) => {
                    if (file.newName && file.newName !== "") {
                        if (file.newName === fileItem.file.name) {
                            this.purchaseOrdersWrapper[index].purchaseOrder.url = fileItem.url;
                        }
                    } else {
                        if (file.purchaseOrder.name === fileItem.file.name) {
                            this.purchaseOrdersWrapper[index].purchaseOrder.url = fileItem.url;
                        }
                    }
                });
            });
        }
    }

    /**
     * @description remove file from firebase and files object
     * @author Divya Sachan
     * @date 2019-11-06
     * @param {*} index
     * @param {boolean} [removeFromFiles=true]
     * @memberof UpdateInvoiceDialogComponent
     */
    removeFile(index, removeFromFiles = true) {
        if (this.files[index].isQueued) {
            this.firebaseService.deleteFile(this.files[index].file.name, this.files[index].imagesFolder);
            this.firebaseService.removeItemFromFileItem(this.files[index].file.name, this.files[index].imagesFolder);
        }
        if (removeFromFiles) {
            this.files.splice(index, 1);
            this.purchaseOrdersWrapper.splice(index, 1);
        }
    }

    /**
     * @description upload po in campaign
     * @author Divya Sachan
     * @date 2019-11-06
     * @memberof UpdateInvoiceDialogComponent
     */
    uploadCampaignPO() {
        this.isLoading = true;
        this.selectedPO = null;
        let purchaseOrder: any[] = [];
        if (this.purchaseOrdersWrapper && this.purchaseOrdersWrapper.length > 0) {
            purchaseOrder.push(this.purchaseOrdersWrapper[0].purchaseOrder);
            this.campaignService.create(purchaseOrder, null, AppUrls.UPLOAD_PO + '/' + this.invoice.campaign.id).subscribe((response) => {
                this.notificationServcie.success('PO Uploaded Successfully', 'PO Upload');
                this.getPOList(); // get po list after uploading it to campaign.
            }, (error) => {
                this.notificationServcie.error('PO Upload Unsuccessfull', 'PO Upload');
                this.isLoading = false;
            });
        }
    }

    detectChanges() {
        if (!this.changeDetectorRef["destroyed"]) {
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy() { }

}
