import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Area } from "../../modals/Area/area";

@Injectable()
export class AreaListGetService extends ApiService<any, any, any> {
    urlPath: string = 'areas/search';  // remove page number and size   ?projection=areaProjection
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getAreaListFirst() {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'areas');

        }
    }
}
