import { Injectable } from "@angular/core";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";
import { ContractsActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { ContractService } from "./contract.services";
import { Subject } from "rxjs";
import { ExpireConflictData } from "../../modals/contracts/expire-conflict-data";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import { HttpConfigurationService } from "../../masters/configurations/services/http-configuration.service";
import { Contracts } from '../../modals/contracts/contracts';
import { InventoryContract } from '../../modals/contracts/inventoryContract';
/**
 * Containing all the functionality of the components
 * 
 * Sanchit | 01-15-2018
 * 
 * @export
 * @class ContractUiService
 */
@Injectable()
export class ContractUiService {

    campaignItem: Subject<ExpireConflictData[]> = new Subject();

    constructor(
        private actionDispatcher: ContractsActionDispatcher,
        private contractService: ContractService,
        private notificationServcie: NotificatoinsService,
        private configuationService: HttpConfigurationService
    ) {
    }

    /**
     * fetching the list of areas from hoardings
     * 
     * @param {ContractHoardings[]} hoardings 
     * @returns {any[]} 
     * @memberof ContractUiService
     */
    getAreas(hoardings: ContractHoardings[]): any[] {
        var areas: any[] = [];

        hoardings.forEach(data => {

            if (!this.checkDuplicate(areas, data, 'id', 'area')) {
                areas.push(data.area);
            }

        });
        return areas;
    }

    /**
     * Creating a function to check duplicate in the list
     * 
     * @param {any[]} searchedList 
     * @param {*} data 
     * @param {*} [nestedObject] 
     * @param {string} [element] 
     * @returns {boolean} 
     * @memberof ContractUiService
     */
    checkDuplicate(searchedList: any[], data: any, element?: string, nestedObject?: any): boolean {

        if (searchedList.length > 0) {
            for (var i = 0; i < searchedList.length; i++) {
                if (searchedList[i][element] === data[nestedObject][element] && nestedObject) {
                    return true;
                } else if (searchedList[i] === data[element] && nestedObject === undefined) {
                    return true
                }
            }
        }
        return false;

    }

    getHoardingsByAreaAndCategory(hoardingList, areaId, category?: any, lightType?: any) {
        var hoardings = hoardingList;


        if (areaId) {
            hoardings = Object.assign([], this.filterHoardingsByArea(hoardingList, areaId));
        }

        if (category) {
            hoardings = Object.assign([], this.filterHoardingsByCategory(hoardings, category));
        }

        if (lightType) {
            hoardings = this.filterHoardingsByLightType(hoardings, lightType);
        }

        return hoardings;
    }

    filterHoardingsByArea(hoardingList, areaId) {
        return hoardingList.filter((data) => data.area.id === areaId)
    }

    filterHoardingsByCategory(hoardingList, category) {
        return hoardingList.filter((data) => data.category === category)
    }

    filterHoardingsByLightType(hoardingList, lightType) {
        return hoardingList.filter((data) => data.lightType === lightType)
    }

    filterCategoryByHoardings(hoardings) {
        var categories: any[] = [];

        hoardings.forEach(data => {
            if (categories.indexOf(data.category) < 0) {
                categories.push(data.category);
            }
        });
        return categories;
    }

    contractExpiryCheck(contract) {
        if (contract.status === 'EXPIRED') {
            this.contractService.get({ id: contract.id }, '/expire')
                .subscribe((response) => {
                    // this.campaignItem.next();
                    this.campaignItem.next(response)
                }, (error) => {
                    this.notificationServcie.info(error['error']['message'], 'Contract Update Info');
                })
        }
    }

    destroySubject() {
        this.campaignItem.observers = [];
    }

    getHoardingTemplateUrl() {
        return this.configuationService.get(null, `/hoardingTemplateUrl`)
    }

    getContractTemplateUrl() {
        return this.configuationService.get(null, `/contractTemplateUrl`);
    }

    //Raveena | 16-05-2019 Function for getting contract wrapper - Optimisation change

    getContractWrapper(contract: Contracts) {
        var contractWrapper: InventoryContract = new InventoryContract();
        contractWrapper.endDate = contract.endDate;
        contractWrapper.id = contract.id;
        contractWrapper.name = contract.name;
        contractWrapper.startDate = contract.startDate;
        return contractWrapper;
    }
}