import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { HelpService, HELP_URL } from "../base-service/help.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ArticlesService extends HelpService<any, any, any> {
    urlPath: string = 'helps';
    helpUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.helpUrl = this.Injector.get(HELP_URL);
    }

}
