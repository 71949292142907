import { Injectable, Injector } from "@angular/core";
import * as utils from '../../../../helpers/utils';
import { BASE_URL } from '../../../../services/base-service/api.service';
import { AppUrls } from '../../../../services/urls';
import { CampaignService } from '../../../../services/shared/campaign.service';
import { InvoiceChartService } from '../../../../services/shared/invoice-chart.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InvoiceTaskUIService {
    baseUrl: string;

    constructor(
        private injector: Injector,
        private invoiceChartService: InvoiceChartService,
        private campaignService: CampaignService,
        private http: HttpClient
    ) {
        this.baseUrl = this.injector.get(BASE_URL);
    }

    markInvoiceAsPrinted(value, id) {
        return this.invoiceChartService.get({ 'invoiceId': id, 'printed': value }, AppUrls.MARK_PRINTED);
    }

    markInvoiceAsTallyEntered(value, id, tallyId) {
        return this.invoiceChartService.get({ 'invoiceId': id, 'tallyEntered': value, 'tallyId': tallyId }, AppUrls.MARK_TALLY_ENTERED);
    }

    printInvoiceWithDetailsWithLetterhead(invoiceId, isDuplicate) {
        if (navigator.onLine) {
            return this.http.get(this.baseUrl + AppUrls.INVOICES + AppUrls.GENERATE_PDF + AppUrls.WITH_DETAIL, { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } });

        }
        // return this.invoiceChartService.get({ responseType: "arraybuffer", 'invoiceId': invoiceId, 'type': isDuplicate ? 'DUPLICATE' : 'ORIGINAL' }, AppUrls.GENERATE_PDF + AppUrls.WITH_DETAIL);
    }

    printInvoiceWithDetailsWithoutLetterhead(invoiceId, isDuplicate) {
        if (navigator.onLine) {
            return this.http.get(this.baseUrl + AppUrls.INVOICES + AppUrls.GENERATE_PDF + AppUrls.WITHOUT_LETTERHEAD + AppUrls.WITH_DETAIL, { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } });

        }
    }

    printInvoiceWithoutDetailsWithLetterhead(invoiceId, isDuplicate) {
        if (navigator.onLine) {
            return this.http.get(this.baseUrl + AppUrls.INVOICES + AppUrls.GENERATE_PDF + AppUrls.WITHOUT_DETAIL, { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } });

        }
    }

    printInvoiceWithoutDetailsWithoutLetterhead(invoiceId, isDuplicate) {
        if (navigator.onLine) {
            return this.http.get(this.baseUrl + AppUrls.INVOICES + AppUrls.GENERATE_PDF + AppUrls.WITHOUT_LETTERHEAD + AppUrls.WITHOUT_DETAIL, { responseType: "arraybuffer", params: { invoiceId: invoiceId, type: isDuplicate ? 'DUPLICATE' : 'ORIGINAL' } });

        }
    }

}
