import { Component, OnInit } from '@angular/core';
import { DashboardUiService } from '../../service/dashboard-ui.service';
import { CustomerCount } from '../../../modals/dashboard/customer-count';
import { CustomerStatusEnum } from '../../../shared/constants/customer-status-enum';

@Component({
  selector: 'sib-customer-count-card',
  templateUrl: './customer-count-card.component.html',
  styleUrls: ['./customer-count-card.component.scss']
})
export class CustomerCountCardComponent implements OnInit {
customerCounts: CustomerCount[];
total: number;

  constructor(
    private dashboardUiService: DashboardUiService
  ) { }

  ngOnInit() {
    this.dashboardUiService.getCustomerCount()
    .subscribe((response) => {
      this.customerCounts = response.data;
      this.total = 0;
      for(var i=0; i<this.customerCounts.length; i++){
        this.customerCounts[i].status = CustomerStatusEnum[this.customerCounts[i].status]
        this.total = this.total + this.customerCounts[i].count;
      }
    },
    (error) => {

    }
    )
  }

}
