import { ChartArea } from "./chart-area/chart-area";
import { ChartLegend } from "./chart-legend/chart-legend";
import { HAxis } from './hAxis/hAxis';
import { VAxis } from './vAxis/vAxis';
import { Annotation } from './annotation/annotation';

export class AreaChartConfig {
    title: string;
    height?: number;
    width?: number;
    chartArea?: ChartArea = new ChartArea();
    chartLegend?: ChartLegend = new ChartLegend();
    // colors?: string[];
    backgroundColor?: string;
    hAxis?: HAxis = new HAxis();
    vAxis?: VAxis = new VAxis();
    tooltip?: any;
    pointSize?: number;
    annotations?: Annotation = new Annotation();
    onSelect: Function;

    // constructor(title: string, height?: number, width?: number, chartArea?: ChartArea, chartLegend?: ChartLegend, hAxis?: HAxis, vAxis?: VAxis, backgroundColor?: string, tooltip?: any, pointSize?: number, annotations?: Annotation) {
    //     this.title = title;
    //     this.height = height;
    //     this.width = width;
    //     this.chartArea = chartArea;
    //     this.chartLegend = chartLegend;
    //     this.hAxis = hAxis;
    //     this.vAxis = vAxis;
    //     // this.colors = colors;
    //     this.backgroundColor = backgroundColor;
    //     this.tooltip = tooltip;
    //     this.pointSize = pointSize;
    //     this.annotations = annotations;
    // }
}
