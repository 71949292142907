import { Injectable, Injector } from "@angular/core";
import * as utils from '../../../../helpers/utils';
import { BASE_URL } from '../../../../services/base-service/api.service';
import { PassbookService } from './passbook.service';
import { AppUrls } from '../../../../services/urls';
import { GridPaginationEvent } from '../../../../components/sib-forms/grid/grid.config';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PassbookUIService {
    baseUrl: string;

    constructor(
        private injector: Injector,
        private passbookService: PassbookService,
        private Http: HttpClient
    ) {
        this.baseUrl = this.injector.get(BASE_URL);
    }

    getLoggedInUserEmail() {
        return utils.getLoggedInUserEmail();
    }

    getLoggedInUserFullName() {
        return utils.getLoggedInUserFullName();
    }

    getUsers() {
        return this.passbookService.get(null, AppUrls.GET_USERS);
    }

    getTransactions(searchObject, paginationObject?) {
        return this.passbookService.create(searchObject, paginationObject, AppUrls.TRANSACTIONS);
    }

    // getPassbook(searchObject, paginationObject) {
    //     return this.passbookService.create(searchObject, paginationObject, AppUrls.SEARCH);
    // }

    // 'passbook/getAll'
    getPassbook(transactionSearch, paginationObject?) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + AppUrls.PASSBOOK + AppUrls.GET_ALL, transactionSearch, paginationObject);

        }
    }

}
