import { Injectable } from '@angular/core';
import * as PptxGenJS from 'pptxgenjs'
import { Observable, Observer } from 'rxjs';
import Compressor from 'compressorjs';
import { DateUtil } from '../../helpers/date.util';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { AppUrls } from '../urls';

@Injectable({
  providedIn: 'root'
})
export class PptGeneratorService {

  pptx: any;
  imageName: string = 'assets/layout/images/customer-image.png';
  operatedDateText: string;
  tabIndex: number;
  imageObject = {
    width: 0,
    height: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/dev%2FSIB0001%2Fhoardings%2FOOH-0135%2FOOH-0135%20-%20Ahmedabad%2C%20kalasagar1%20-%2020X20.jpg?alt=media&token=9318e1e5-c32e-4f5d-805b-49a9060bb201'
  }
  logoUrl: string;
  pptText: string;
  pptRedirectUrl: string;
  portalBaseUrl: string;

  constructor(
    private currencyPipe: CurrencyHelperPipe,
  ) {
    // this.instantiatePpt();
    // this.setSlideMaster();
    // this.itemSlideMaster();
    // this.setLayout();

  }

  instantiatePpt() {
    this.pptx = new PptxGenJS();
  }

  setSlideMaster() {
    this.pptx.defineSlideMaster({
      title: 'MASTER_SLIDE',
      bkgd: 'FFFFFF',
      objects: [
        { 'line': { x: 0, y: 0, w: 0, line: '0088CC', lineSize: 12, h: '100%', align: 'left' } },
        { 'image': { x: 1, y: 1, w: '50%', h: '18%', sizing: { type: 'contain' }, path: this.logoUrl } },
        { 'line': { x: 1, y: 2.5, w: 8, h: 0, line: '000000', lineSize: 1 } },
        { 'text': { text: 'Test User', options: { x: 1.0, y: 2.7, w: 5.5, h: 0, fontSize: 14, margin: 5, bold: true } } },
        { 'text': { text: '1234567890', options: { x: 1.0, y: 3.2, w: 5.5, h: 0, fontSize: 14, margin: 5 } } },
        { 'text': { text: 'testuser@gmail.com', options: { x: 1, y: 3.7, w: 5.5, h: 0, fontSize: 14, margin: 5 } } },
        { 'text': { text: '08 Apr, 2019', options: { x: 7.8, y: 2.7, w: 5.5, h: 0, fontSize: 14, margin: 5, bold: true } } },
      ],
    })
  }

  itemSlideMaster(item) {
    this.pptx.defineSlideMaster({
      title: 'ITEM_SLIDE',
      bkgd: 'FFFFFF',
      objects: [
        { 'image': { x: 1, y: '5%', w: 8, h: 5.5, sizing: { type: 'contain', w: 8, h: 5 }, path: item.images[0].url } },
      ],
    })

  }

  setLayout() {
    this.pptx.setLayout('LAYOUT_4x3');
  }

  setFirstPageLayout(data) {
    // var slide = this.pptx.addNewSlide('MASTER_SLIDE');
    var slide = this.pptx.addNewSlide();
    var xDistance;
    slide.addShape(this.pptx.shapes.LINE, { x: 0, y: 0, w: 0, line: '0088CC', lineSize: 6, h: '100%', align: 'left' })
    slide.addShape(this.pptx.shapes.LINE, { x: 1, y: 3, w: 8, h: 0, line: '000000', lineSize: 1 })
    slide.addImage({ x: 1, y: 1, w: '50%', h: '20%', sizing: { type: 'contain' }, path: this.logoUrl })
    slide.addText(data.employee.fullName, { x: 1.0, y: 3.2, w: 5.5, h: 0, fontSize: 14, margin: 5, bold: true })
    slide.addText(data.employee.number, { x: 1.0, y: 3.7, w: 5.5, h: 0, fontSize: 14, margin: 5 })
    slide.addText(data.employee.emailId, { x: 1, y: 4.2, w: 5.5, h: 0, fontSize: 14, margin: 5 })
    slide.addText(DateUtil.formatDateDDMMMYY(new Date()), { x: 7.8, y: 3.2, w: 5.5, h: 0, fontSize: 14, margin: 5, bold: true });
    if (this.pptText) {
      if (this.pptText.length <= 10) {
        xDistance = 8;
      } else if (this.pptText.length > 11 && this.pptText.length <= 20) {
        xDistance = 7.5;
      } else if (this.pptText.length > 20 && this.pptText.length <= 30) {
        xDistance = 6.5;
      } else if (this.pptText.length > 30 && this.pptText.length <= 40) {
        xDistance = 6;
      } else if (this.pptText.length > 40) {
        xDistance = 5;
      }
    }
    slide.addText(
      [
        { text: this.pptText, options: { hyperlink: { url: this.pptRedirectUrl, tooltip: 'Visit Homepage' } } },
      ],
      { x: xDistance, y: 7, w: 5.5, h: 0, fontSize: 14, margin: 5, bold: true }
    );
  };

  // set item slide for plan item
  setItemSlide(item) {
    var slide = this.pptx.addNewSlide();
    var imageMeasurement = this.calculateImageHeightAndWidth(item);

    slide.addImage({ x: imageMeasurement.imageX, y: '4%', w: imageMeasurement.imageWidth, h: imageMeasurement.imageHeight, sizing: { type: 'contain', w: imageMeasurement.imageWidth, h: imageMeasurement.imageHeight }, data: item.data });
    var tabOpts1 = { x: 0.5, y: 6, w: '90%', h: 1.25, fill: 'FFFFFF', color: '3D3D3D', fontSize: 14, align: 'l', valign: 'm', autoPage: false, border: { pt: '0' } };
    var arrTabRows1 = [
      [{ text: item.header, options: { colspan: 2, fill: 'E4E9EB', margin: [0, 0, 0, 10], bold: true, align: 'c' } }],
      [{ text: 'Size : ' + item.size, options: { margin: [0, 0, 0, 10] } }, { text: 'Lights : ' + item.lightType, options: { margin: [0, 0, 0, 10] } }],
      [{ text: 'Rate for (' + item.numberOfDays + ' days) : ' + this.currencyPipe.transform(Math.round(item.rate)) + '/-', options: { margin: [0, 0, 0, 10] } }, { text: 'Availability : ' + item.availabilityDate, options: { margin: [0, 0, 0, 10] } }]
    ];
    slide.addTable(arrTabRows1, tabOpts1);

    // Do not remove this commented code

    /* imageContainerHeight = this.convertEmuToInch(this.pptx.getLayout().height) - 1.5
    imageContainerWidth = this.convertEmuToInch(this.pptx.getLayout().width) */

    // if (imageWidth >= imageContainerWidth) {
    // ratio = imageContainerWidth / imageWidth;
    // imageHeight = imageHeight * ratio;
    // imageWidth = imageWidth * ratio;
    //   console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", imageX, "imageX", "if1")
    //   imageX = this.setImageToCenter(imageWidth) + 0.75;
    //   imageWidth = imageContainerWidth - 0.5;
    //   console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", imageX, "imageX", "if1")
    //   // imageX = 0.5
    //   // imageWidth = imageContainerWidth;
    // } else if (imageWidth < imageContainerWidth) {
    //   imageX = this.setImageToCenter(imageWidth);
    //   console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", "else1")

    // }

    // if (imageHeight >= imageContainerHeight) {
    //   ratio = imageContainerHeight / imageHeight
    //   imageWidth = imageWidth * ratio;
    //   imageHeight = imageHeight * ratio;
    //   console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", imageX, "imageX", "if2")
    //   imageX = this.setImageToCenter(imageWidth) + 0.25;
    //   // imageHeight = imageContainerHeight;
    //   console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", imageX, "imageX", "if2")
    // } else {

    // }
    // x: imageX
    // border: { pt: 1, color: '000000' }, 
  }

  // get text for dates tab wise
  getOperatedDateText(tabIndex) {
    switch (tabIndex) {
      case 0: { // for site tab
        this.operatedDateText = 'Start Date';
        break;
      }
      case 1: { // for mounting tab
        this.operatedDateText = 'Mounting Date';
        break;
      }
      case 2: { // for monitoring tab
        this.operatedDateText = 'Monitoring Date';
        break;
      }
      case 3: { // for unmounting tab
        this.operatedDateText = 'Unmounting Date';
        break;
      }
    }
    return this.operatedDateText;
  }

  // setting slide for campaign item
  setItemSlideForCampaign(item, tabIndex) {
    this.tabIndex = tabIndex;
    var slide = this.pptx.addNewSlide();
    var imageMeasurement = this.calculateImageHeightAndWidth(item);

    slide.addImage({ x: imageMeasurement.imageX, y: '4%', w: imageMeasurement.imageWidth, h: imageMeasurement.imageHeight, sizing: { type: 'contain', w: imageMeasurement.imageWidth, h: imageMeasurement.imageHeight }, data: item.data });
    var tabOpts1 = { x: 0.5, y: 6, w: '90%', h: 1.25, fill: 'FFFFFF', color: '3D3D3D', fontSize: 14, align: 'l', valign: 'm', autoPage: false, border: { pt: '0' } };
    var arrTabRows1 = [
      [{ text: item.header, options: { colspan: 2, fill: 'E4E9EB', margin: [0, 0, 0, 10], bold: true, align: 'c' } }],
      [{ text: 'Size : ' + item.size, options: { margin: [0, 0, 0, 10] } }, { text: 'Lights : ' + item.lightType, options: { margin: [0, 0, 0, 10] } }],
      [{ text: this.getOperatedDateText(tabIndex) + ' : ' + DateUtil.dategridFormatter(item.operatedDate) + ' ' + item.mode, options: { margin: [0, 0, 0, 10] } }, { text: 'Site End Date : ' + DateUtil.dategridFormatter(item.availabilityDate), options: { margin: [0, 0, 0, 10] } }]
    ];
    slide.addTable(arrTabRows1, tabOpts1);
  }

  // calculate image height and width to be displayed in slide
  calculateImageHeightAndWidth(item) {
    var imageContainerHeight, imageContainerWidth, imageHeight = 0, imageWidth = 0, imageX, ratio = 0, widthRatio = 0, heightRatio = 0;
    imageContainerHeight = 5.5;
    imageContainerWidth = 9;

    imageHeight = this.convertEmuToInch(this.convertPixelToEmu(item.imageHeight));
    imageWidth = this.convertEmuToInch(this.convertPixelToEmu(item.imageWidth));
    // widthRatio = imageContainerWidth / imageWidth;
    // heightRatio = imageContainerHeight / imageHeight;

    while (imageWidth > imageContainerWidth) {
      ratio = imageContainerWidth / imageWidth;
      imageHeight = imageHeight * ratio;
      imageWidth = imageWidth * ratio;
      imageX = this.setImageToCenter(imageWidth);
      // console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", imageX, "imageX", "if1");
    }

    if (imageWidth <= imageContainerWidth) {
      imageX = this.setImageToCenter(imageWidth);
      // console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", imageX, "imageX", "else1");
    }

    while (imageHeight > imageContainerHeight) {
      ratio = imageContainerHeight / imageHeight
      imageWidth = imageWidth * ratio;
      imageHeight = imageHeight * ratio;
      imageX = this.setImageToCenter(imageWidth);
      // console.log(imageHeight, "imageHeight>>>>", imageWidth, "imageWidth>>>>", imageX, "imageX", "if2")
    }
    return { imageWidth: imageWidth, imageHeight: imageHeight, imageX: imageX };
  }

  setImageToCenter(imageWidth) {
    var slideWidth = (this.convertEmuToInch(this.pptx.getLayout().width));
    var slideCenter = (slideWidth / 2);
    var imageCenter = (imageWidth / 2);
    return (slideCenter - imageCenter);
  }

  addTermAndConditionSlide(termAndConditions, employeeData, campaignId?, isCampaign = false) {
    var slide = this.pptx.addNewSlide();
    var tenantId = localStorage.getItem('id');
    var campaignRedirectUrl = this.portalBaseUrl + SIBConstants.PUBLIC_PAGE + campaignId + AppUrls.SLASH + tenantId;
    //  "http://vendor1.showitbig.com:4200/public-page/5da9479047d6c700012c848a/SIB0001";
    slide.addShape(this.pptx.shapes.LINE, { x: 0, y: 0, w: 0, line: '0088CC', lineSize: 6, h: '100%', align: 'left' })
    slide.addText('TERMS & CONDITIONS:-', { x: 0.5, y: 0.5, w: 5.5, h: 0, fontSize: 18, margin: 5, bold: true })
    slide.addText(termAndConditions, { x: 0.5, y: 1.5, w: 9, h: 2, fontSize: 16, bold: false });
    slide.addShape(this.pptx.shapes.LINE, { x: 0.5, y: 5.5, w: 9, h: 0, line: '000000', lineSize: 1 })
    slide.addText(employeeData.fullName, { x: 7.5, y: 5.75, w: 5.5, h: 0, fontSize: 16, margin: 5, bold: false })
    slide.addText(employeeData.number, { x: 7.5, y: 6.10, w: 5.5, h: 0, fontSize: 16, margin: 5 });
    isCampaign ? slide.addText(
      [
        { text: 'Campaign Tracker Link', options: { hyperlink: { url: campaignRedirectUrl, tooltip: 'Campaign Tracker' } } },
      ],
      { x: 0.5, y: 6.10, w: 4.5, h: 0, fontSize: 14, margin: 5, bold: false }
    ) : '';
  }

  savePpt(name) {
    switch (this.tabIndex) {
      case 0: {
        this.pptx.save(name + '-Sites' + '-' + DateUtil.formatDateDDMMYY(new Date()));
        break;
      }
      case 1: {
        this.pptx.save(name + '-Mount' + '-' + DateUtil.formatDateDDMMYY(new Date()));
        break;
      }
      case 2: {
        this.pptx.save(name + '-Monitored' + '-' + DateUtil.formatDateDDMMYY(new Date()));
        break;
      }
      case 3: {
        this.pptx.save(name + '-Unmount' + '-' + DateUtil.formatDateDDMMYY(new Date()));
        break;
      }
      default: {
        this.pptx.save(name + '-' + DateUtil.formatDateDDMMYY(new Date()));
      }
    }
    this.tabIndex = undefined;
  }

  getBase64ImageFromURL(url: string, pptData) {
    return Observable.create((observer: Observer<any>) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          // observer.next({ base64Code: this.getBase64Image(img), image: img });
          pptData.data = this.getBase64Image(img);
          pptData.imageHeight = img.height;
          pptData.imageWidth = img.width;
          observer.next(pptData);
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        // observer.next(this.getBase64Image(img));
        pptData.data = this.getBase64Image(img);
        pptData.imageHeight = img.height;
        pptData.imageWidth = img.width;
        observer.next(pptData);
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png", 1);
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  dataURItoBLob(dataURI, pptData) {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/jpeg' });
      pptData.data = blob;
      // return blob;
      observer.next(pptData);
      observer.complete();
    });
  }

  blobToFile(blob, pptData) {
    return Observable.create(observer => {
      const imageFile = new File([blob], '', { type: 'image/jpeg' });
      pptData.data = imageFile
      observer.next(pptData);
    })
  }

  compressFile(file, pptData) {
    return Observable.create(observer => {
      new Compressor(file, {
        quality: 0.4,
        success: (result) => {
          if (result) {
            pptData.data = result
            observer.next(pptData);
          }

        }
      })
    })
  }

  getBase64FromFile(file, pptData) {
    var reader = new FileReader();
    reader.readAsDataURL(file)
    return Observable.create(observer => {
      reader.onload = (e) => {
        pptData.data = e.target['result'];
        observer.next(pptData)

      }
    })
  }

  convertPixelToEmu(data) {
    //1 pixel is equal to 9525emu
    return (data * 9525)
  }

  convertEmuToInch(data) {
    return Math.round(data / 914400)
  }

  setVendorInformation(url, pptText, pptRedirectUrl, portalBaseUrl?) {
    this.logoUrl = url;
    this.pptText = pptText;
    this.pptRedirectUrl = pptRedirectUrl;
    this.portalBaseUrl = portalBaseUrl;
    // console.log(this.logoUrl);
  }

}