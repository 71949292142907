import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { District } from "../Area/district";
import { Area } from "../Area/area";
import { Contracts } from "./contracts";
import { HoardingGroup } from "../hoardings/hoardings-group";
import { NumberFormatStyle } from "@angular/common";
import { GeoCoordinate } from "../hoardings/geo-coordinate";
import { Picture } from "../hoardings/picture";
import { AbstractItem } from "../hoardings/abstract-hoarding";

export class ContractHoardings extends AbstractItem {
    tradedAsset: boolean;
    clubbed = false;
    vendorName: string;
    rating = 0;
}
