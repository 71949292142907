import { State } from "../Area/state";
import { District } from "../Area/district";
import { City } from "../Area/city";

export class Address {
    address1: string;
    address2: string;
    postalCode: string;
    state: State = new State();
    district: District = new District();
    city: City = new City();
}