
import { Injectable } from '@angular/core';

import { GoogleChartsBaseService } from './chart.base.service';
import { PieChartConfig } from '../../modals/charts/pieChart.config';


declare var google: any;

@Injectable()
export class GooglePieChartService extends GoogleChartsBaseService {
  constructor() { super(); }


  public buildPieChart(elementId: String, data: any[], config: PieChartConfig): void {
    const chartFunc = () => new google.visualization.PieChart(document.getElementById(elementId + ''));
    const options = {
      title: config.title,
      pieHole: config.pieHole,
      chartArea: config.chartArea,
      legend: config.chartLegend,
      colors: config.colors,
      backgroundColor: config.backgroundColor,
      height: config.height,
      width: config.width
    };

    this.buildChart(data, chartFunc, options);
  }
}
