import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { DashboardService } from '../../dashboard/service/dashboard.service';
import { CapacityChart } from '../../shared/constants/capacity-chart';
import { SelectItem } from 'primeng/primeng';
import { CityService } from '../../masters/city/service/city.service';
import { AreaChartConfig } from '../../modals/charts/areaChart.config';
import { ChartArea } from '../../modals/charts/chart-area/chart-area';
import { ChartLegend } from '../../modals/charts/chart-legend/chart-legend';
import { HAxis } from '../../modals/charts/hAxis/hAxis';
import { VAxis } from '../../modals/charts/vAxis/vAxis';
import * as utils from '../../helpers/utils';
import { Annotation } from '../../modals/charts/annotation/annotation';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { UserPropertiesService } from '../../services/shared/user-properties.service';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { CapacityChartDrilldownComponent } from './capacity-chart-drilldown/capacity-chart-drilldown.component';
import { CalculationService } from '../../services/shared/calculationService';

@Component({
  selector: 'sib-capacity-chart',
  templateUrl: './capacity-chart.component.html',
  styleUrls: ['./capacity-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CapacityChartComponent implements OnInit {

  @ViewChild('capacityChartDrilldown') CapacityChartDrilldownComponent: CapacityChartDrilldownComponent;

  @Input() showTitle: boolean = true;
  @Input() showAction: boolean = true;

  @Output() eEmitChartClickedData: EventEmitter<any> = new EventEmitter<any>();
  title: string = "Capacity Chart";
  itemStartDate: Date;
  itemEndDate: Date;
  cities: any[];
  data: CapacityChart[] = [];
  areaChartData: any[] = [];
  currentSetDate: Date;
  monthName: string;
  selectedCities: any[];
  citiesOptions: any[] = [];
  entries: any[] = [];
  areaChartConfig: AreaChartConfig = new AreaChartConfig();
  areaChartArea: ChartArea = new ChartArea();
  areaChartLegend: ChartLegend = new ChartLegend();
  areaElementId: string;
  hAxis: HAxis = new HAxis();
  vAxis: VAxis = new VAxis();
  areaChartLoader: boolean = true;
  citiesSelected: string = "";
  annotations: Annotation = new Annotation();
  date: number;
  month: string;
  viewCapacityChartDrilldown: boolean = false;
  isCapacityChartClicked: boolean = false;

  chartClickedData: any[] = [];
  totalCities: any[] = [];

  constructor(
    private dashboardsService: DashboardService,
    private cityService: CityService,
    private cd: ChangeDetectorRef,
    private currencyPipe: CurrencyHelperPipe,
    private userPropertiesService: UserPropertiesService,
    private pageTitle: Title,
    private calculationService: CalculationService,
  ) {
    this.areaChartConfig.onSelect = this.onChartSelection.bind(this);
  }

  ngOnInit() {
    if (this.showTitle) {
      let appTitle = utils.getAppTitle();
      !appTitle ? this.pageTitle.setTitle(SIBConstants.CAPACITY_CHART_SUBMENU) : this.pageTitle.setTitle(SIBConstants.CAPACITY_CHART_SUBMENU + ' - ' + appTitle);
    }

    // this.setCitiesOptions();
    this.getSelectedCities();
    // this.setRequestData();
  }

  setCitiesOptions() {
    this.cityService.get(null, '/find/inventoryCities').subscribe((response) => {
      if (response) {
        this.totalCities = response['data'];
        this.filterCities(this.selectedCities);
        // this.handleSelectedCities(this.selectedCities);
        this.setRequestData();

      }
    })
  }

  filterCities(selectedCities) {
    this.citiesOptions = [];
    selectedCities.forEach((selectedCity) => {
      this.citiesOptions.push({ label: selectedCity.name, value: selectedCity });
    })

    this.totalCities.forEach((city) => {
      let isCityNotSelected = true;
      selectedCities.forEach((selectedCity) => {
        if (selectedCity.id === city.id) {
          isCityNotSelected = false;
        }
      })
      if (isCityNotSelected) {
        this.citiesOptions.push({ label: city.name, value: city });
      }

      // this.selectedCities = response['value'];

    })
  }

  // { module: 'DASHBOARD', key: 'CITY_CONFIGURATION' }
  getSelectedCities() {
    this.selectedCities = [];
    this.userPropertiesService.get(null, '/byModuleAndKey/DASHBOARD,CITY_CONFIGURATION').subscribe(
      (response) => {
        if (response) {
          // this.selectedCities = response
          this.selectedCities = response['value'];
          this.setCitiesOptions();
          // this.filterSelectedCities();
        }
      }
    );
  }

  setRequestData() {
    this.itemStartDate = new Date();
    this.itemEndDate = this.calculationService.extendDate(this.itemStartDate, 15);
    this.cities = this.selectedCities;
    this.createCapacityChart();
  }

  createCapacityChart() {
    this.areaChartLoader = true;
    this.dashboardsService.create({ itemStartDate: this.itemStartDate, itemEndDate: this.itemEndDate }, null, '/capacity').subscribe(
      (response) => {
        if (response) {
          this.data = response['data'];
          this.entries = Object.entries(this.data);
        }
        this.setAreaChart();
      }
    );
  }

  setAreaChart() {
    this.areaChartData = [['Dates', 'Utilized %', 'tooltip', 'annotation']];

    this.currentSetDate = this.itemStartDate;
    this.buildAreaChart();
    let title = '';
    this.itemStartDate = new Date();
    title = this.setGraphTitle();
    this.setAreaChartArea();
    this.setAreaChartLegend();
    this.setHAxis();
    this.setVAxis();
    this.setAnnotation();

    this.areaChartConfig.title = title;
    this.areaChartConfig.height = 300;
    this.areaChartConfig.width = innerWidth - 130;
    this.areaChartConfig.chartArea = this.areaChartArea;
    this.areaChartConfig.chartLegend = this.areaChartLegend;
    this.areaChartConfig.hAxis = this.hAxis;
    this.areaChartConfig.vAxis = this.vAxis;
    this.areaChartConfig.backgroundColor = null;
    this.areaChartConfig.tooltip = { isHtml: true };
    this.areaChartConfig.pointSize = 5;
    this.areaChartConfig.annotations = this.annotations;
    // this.areaChartConfig = new AreaChartConfig(title, 300, 1220, this.areaChartArea, this.areaChartLegend, this.hAxis, this.vAxis, null, { isHtml: true }, 5, this.annotations);
    this.areaElementId = 'myAreaChart';
    this.areaChartLoader = false;
  }

  buildAreaChart() {
    for (let i = 0; i < this.entries.length; i++) {
      if ((new Date(this.entries[i][0]).getDate() === new Date(this.currentSetDate).getDate()) && (new Date(this.entries[i][0]).getMonth() === new Date(this.currentSetDate).getMonth()) && (new Date(this.entries[i][0]).getFullYear() === new Date(this.currentSetDate).getFullYear())) {
        let date = this.currentSetDate.getDate();
        this.setMonthName(this.currentSetDate.getMonth())
        let month = this.monthName;
        // this.entries[i][1].totalTappedPercentage]

        // let tooltip = 'Current Sales: ' + this.setThousandSeperators(this.getIntegerRoundedOffValue(this.entries[i][1].totalsoldItemsAmount)) + ' (' + this.getIntegerFloorValue(this.entries[i][1].totalTappedPercentage) + '%)' + '\n' + 'Untapped Potential: ' + this.setThousandSeperators(this.getIntegerRoundedOffValue(this.entries[i][1].totalUnsoldItemsAmount)) + ' (' + this.getIntegerFloorValue(this.entries[i][1].totalUntappedPercentage) + '%)' + '\n' + '\n' + 'Total LSP of center: ' + this.setThousandSeperators(this.getIntegerRoundedOffValue(this.entries[i][1].totalInventoryAmount))
        let tooltip = this.createCustomTooltip(this.currencyPipe.transform(this.getIntegerRoundedOffValue(this.entries[i][1].totalsoldItemsAmount)), this.getIntegerRoundedOffValue(this.entries[i][1].totalTappedPercentage), this.currencyPipe.transform(this.getIntegerRoundedOffValue(this.entries[i][1].totalUnsoldItemsAmount)), this.getIntegerRoundedOffValue(this.entries[i][1].totalUntappedPercentage), this.currencyPipe.transform(this.getIntegerRoundedOffValue(this.entries[i][1].totalInventoryAmount)));
        this.areaChartData.push([date + ' ' + month, this.getIntegerRoundedOffValue(this.entries[i][1].totalTappedPercentage), tooltip, this.getIntegerRoundedOffValue(this.entries[i][1].totalTappedPercentage) + '%']);
        this.incrementDate();
        this.buildAreaChart();
      }
    }
  }



  createCustomTooltip(currentSale, tappedPercentage, unsoldItems, untappedPercentage, totalLsp) {
    return '<div style ="padding: 10px 10px 10px 10px;"><span style="font-size: 14px;">' +
      'Untapped LSP: <b>' + unsoldItems + '</b> (' + untappedPercentage + '%)</span><br><br><span  style="font-size: 14px;">' +
      'Sold LSP: <b>' + currentSale + '</b> (' + tappedPercentage + '%)</span><br><br><span  style="font-size: 14px;">' +
      'Total LSP: <b>' + totalLsp + '</b></span>' + '</div>';
  }

  setGraphTitle() {
    this.setMonthName(this.itemStartDate.getMonth())
    let startDateMonth = this.monthName;
    this.setMonthName(this.itemEndDate.getMonth());
    let endDateMonth = this.monthName;
    if (this.citiesOptions.length === this.selectedCities.length) {
      this.citiesSelected = 'for all cities';
    } else if (this.selectedCities.length === 0) {
      this.citiesSelected = "";
    } else if (this.selectedCities.length === 1) {
      this.citiesSelected = 'for ' + this.selectedCities[0].name;
    } else if (this.selectedCities.length === 2) {
      this.citiesSelected = 'for ' + this.selectedCities[0].name + ' and ' + this.selectedCities[1].name;
    } else if (this.selectedCities.length > 2 && this.selectedCities.length < this.citiesOptions.length) {
      this.citiesSelected = 'for ' + this.selectedCities[0].name + ', ' + this.selectedCities[1].name + ' and more';
    }

    return ('Hoarding Capacity ' + this.citiesSelected);

  }

  // + this.itemStartDate.getDate() + '-' + startDateMonth + '-' + this.itemStartDate.getFullYear() + ' to ' + this.itemEndDate.getDate() + '-' + endDateMonth + '-' + this.itemEndDate.getFullYear()

  setAreaChartArea() {
    this.areaChartArea.height = "70%";
    this.areaChartArea.width = "80%";
    this.areaChartArea.left = "9%";
    this.areaChartArea.top = "15%";
  }

  setAreaChartLegend() {
    this.areaChartLegend.position = 'left';
    this.areaChartLegend.alinment = 'center';
    this.areaChartLegend.textStyle.bold = false;
    this.areaChartLegend.textStyle.italic = true;
    this.areaChartLegend.textStyle.fontSize = '12';
  }

  setHAxis() {
    this.hAxis.title = "";
  }

  setVAxis() {
    this.vAxis.title = "Utilized %";
    this.vAxis.minValue = "0";
    this.vAxis.maxValue = "100";
  }

  setAnnotation() {
    this.annotations.textStyle = {
      color: '#000',
      bold: true
    }
    this.annotations.stemColor = 'none';
  }

  // setThousandSeperators(value) {
  //   return utils.setThousandSeperators(value);
  // }



  incrementDate() {
    this.currentSetDate.setDate(this.currentSetDate.getDate() + 1);

    this.currentSetDate = new Date(this.currentSetDate);
  }

  setMonthName(month) {
    this.monthName = month === 0 ? 'Jan' : (month === 1 ? 'Feb' : (month === 2 ? 'Mar' : (month === 3 ? 'Apr' : (month === 4 ? 'May' : (month === 5 ? 'Jun' : (month === 6 ? 'Jul' : (month === 7 ? 'Aug' : (month === 8 ? 'Sep' : (month === 9 ? 'Oct' : (month === 10 ? 'Nov' : 'Dec'))))))))))
  }

  getParsedValueToDecimalTwoPlaces(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  getIntegerRoundedOffValue(value) {
    return utils.getIntegerRoundedOffValue(value);
  }

  getIntegerFloorValue(value) {
    return utils.getIntegerFloorValue(value);
  }


  handleSelectedCities(cities) {
    this.cities = cities;
    this.userPropertiesService.create(this.cities, null, '/saveCityConfig').subscribe(
      (response) => {
        if (response) {
          this.setRequestData();
          if (this.viewCapacityChartDrilldown && this.selectedCities.length > 0 && this.isCapacityChartClicked) {
            setTimeout(() => {
              this.CapacityChartDrilldownComponent.ChartDrilldownHoardingsComponent.gridViewChildRef.refresh();
            }, 500);
            setTimeout(() => {
              this.CapacityChartDrilldownComponent.ChartDrilldownKiosksComponent.gridViewChildRef.refresh();
            }, 500);
          } else {
            this.viewCapacityChartDrilldown = false
          }
          // this.viewCapacityChartDrilldown = true;
        }
      }
    );


  }

  getIsCapacityChartClicked(event) {
    this.isCapacityChartClicked = event;
  }

  onChartSelection(data) {
    this.chartClickedData = [];
    let dt: number;
    let month: number;
    let monthName: string;
    this.entries.forEach((date) => {
      dt = (new Date(date[0])).getDate();
      month = (new Date(date[0])).getMonth();
      this.setMonthName(month);
      monthName = this.monthName;
      let dateMonth = String(dt + " " + monthName);
      // console.log(dateMonth);
      if (dateMonth === data[0]) {
        this.chartClickedData.push(date);
      }
    })
    this.viewCapacityChartDrilldown = true;

    if (this.isCapacityChartClicked) {
      setTimeout(() => {
        this.CapacityChartDrilldownComponent.resetRows();
        this.CapacityChartDrilldownComponent.ChartDrilldownHoardingsComponent.gridViewChildRef.refresh();
      }, 1000);
      setTimeout(() => {
        this.CapacityChartDrilldownComponent.ChartDrilldownKiosksComponent.gridViewChildRef.refresh();
      }, 1000);
    }
  }
}
