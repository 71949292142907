import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, InputTextModule, SharedModule, CheckboxModule, RatingModule, DragDropModule, FileUploadModule, AutoCompleteModule, DropdownModule, CalendarModule, LightboxModule, TooltipModule, RadioButtonModule, SpinnerModule, ProgressSpinnerModule, KeyFilterModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { CurrencyMainPipe } from "../../helpers/currency.pipe/currency.main.pipe";
import { ViewInventoryComponent } from "./view-inventory.component";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        CheckboxModule,
        RatingModule,
        DragDropModule,
        FileUploadModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        RadioButtonModule,
        LightboxModule,
        TooltipModule,
        InputTrimModule,
        SpinnerModule,
        CurrencyMainPipe,
        ProgressSpinnerModule,
        SibGridModule
    ],

    declarations: [
        ViewInventoryComponent
    ],

    exports: [
        ViewInventoryComponent
    ],

    providers: []
})

export class ViewInventoryModule { }
