<!-- [width]="400" -->
<!-- header="User Details" -->
<!-- [height]="800" -->
<p-dialog [width]="500" [styleClass]="'user-detail-dialog'" [modal]="true" [(visible)]="display" (onShow)="onShowDialog()"
  (onHide)="onHideDialog()" [closable]="false" [autoAlign]="false">

  <p-header>
    <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-10 user-info-logout" (click)="logout()" icon="fa fa-sign-out"
      title="Signout">
    </button>
  </p-header>

  <div *ngIf="isloading">
    <p-progressSpinner class="loading"></p-progressSpinner>
  </div>

  <!-- <div class="stepper-container"> -->
  <p-steps [model]="stepperItems" [(activeIndex)]="activeIndex" styleClass="steps-custom1" [readonly]="true" [(activeIndex)]="activeIndex"></p-steps>
  <!-- </div> -->
  <form #userDetails="ngForm">
    <!-- <div class="header-container">
      <h2 class="heading">Complete Your SIB Profile</h2>
      <span>Set up the password and verify your personal information</span>
    </div> -->

    <div class="information-form ui-g">
      <!-- <div class="ui-g-4" style="margin-left:18%;"> -->
      <div class="information-form-container">
        <div *ngIf="activeIndex === 0" class="slide-right">
          <header class="header">
            <span class="header-primary">
                  <h3>Welcome</h3>
                </span>
            <span class="header-secondary">
                  {{user?.firstName}} {{user?.lastName}}
                </span>
          </header>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
                <input id="firstname" type="text" minlength="0" maxlength="100" pInputText  [(ngModel)]="user.firstName" name="firstname" required/>
                <label for="firstname">First Name <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
                <input id="lastname" type="text" minlength="0" maxlength="100" pInputText  [(ngModel)]="user.lastName" name="lastname" required/>
                <label for="lastname">Last Name <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
                <input id="number" type="text" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.number" name="number" required/>
                <label for="number">Phone <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
              <input disabled id="email" type="text" minlength="0" maxlength="100" pInputText  [(ngModel)]="user.emailId" name="email" />
              <label for="email">Email</label>
            </span>
          </div>
        </div>

        <div *ngIf="activeIndex === 2" class="slide-left">
          <header class="header-1">
            <span class="header-primary-1">
              <h3>Configure Email</h3>
            </span>
            <span class="header-secondary-1">
              Please configure your email for client communication.
            </span>
          </header>
          <!-- <div class="personal-email-container ui-g-12 ui-fluid inputfield">
            <span>Personal Email</span>
            <p-radioButton name="emailgroup" [styleClass]="'uf-radiobutton'" value="userEmail" label="{{user.emailId}}" [(ngModel)]="emailSelected"></p-radioButton>
            <div *ngIf="emailSelected === 'userEmail'" class="ui-g-12 ui-fluid inputfield">
              <span class="md-inputfield">
                <input  #emailPassword id="passsword"  type="password" minlength="0" maxlength="15" pInputText  [(ngModel)]="emailConfigPassword" name="password" />
                <label for="passsword">Password</label>
              </span>
            </div>
            <div *ngIf="emailSelected === 'userEmail'" class="ui-g-12 ui-fluid inputfield">
              <span class="md-inputfield">
                  <input  #emailConfirmPassword id="confirmpasssword"  type="password" minlength="0" maxlength="15" pInputText [(ngModel)]="confirmPasswordValue"  (keyup)="verifyUserPassword(emailConfirmPassword.value)" name="confirmpassword" />
                  <label for="confirmpasssword">Confirm Password </label>
                </span>
            </div>

          </div> -->

          <div class="personal-email-container ui-g-12 ui-fluid inputfield">
            <span>Organization Email</span>
            <p-radioButton name="emailgroup" [styleClass]="'uf-radiobutton'" value="orgEmail" label="{{organizationEmail}}" [(ngModel)]="emailSelected"></p-radioButton>
          </div>
        </div>

        <div *ngIf="activeIndex === 1" class="slide-left">
          <header class="header-1">
            <span class="header-primary-1">
                <h3>ShowItBig System Credentials</h3>
              </span>
            <span class="header-secondary-1">
                Please update your ShowItBig System password here.
              </span>
          </header>
          <div class="password-info-container ui-g-12 ui-fluid inputfield">
            <span class="username-label">User Name</span>
            <span class="username-value">{{user.emailId}}</span>

            <div class="password-value ui-g-12 ui-fluid inputfield">
              <span>
                  Set Password
                </span>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                        <!-- [disabled]='(organizationmailchckbox.checked===true?true:false)' -->
                      <input   id="passsword"  type="password" minlength="0" maxlength="15" pInputText  [(ngModel)]="sibPassword" name="password" />
                      <label for="passsword">Password <span class="required">*</span></label>
                </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                          <!-- [disabled]='(organizationmailchckbox.checked===true?true:false)' -->
                        <input   id="confirmpasssword"  type="password" minlength="0" maxlength="15" pInputText  (keyup)="verifySibPassword()" [(ngModel)]="sibConfirmPassword" name="confirmpassword" />
                        <label for="confirmpasssword">Confirm Password <span class="required">*</span></label>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- saveUser(user) -->
    <div class="uf-button-container">

      <button *ngIf="activeIndex === 0" class="mtab-primary" [disabled]="!userDetails.form.valid" type="submit" label="NEXT" (click)="onNextClick()"
        pButton></button>

      <button *ngIf="activeIndex === 1" class="mtab-primary next-button" [disabled]="!sibPasswordVerified" type="submit" label="NEXT"
        (click)="onNextClick()" pButton></button>
      <button *ngIf="activeIndex === 1 || activeIndex === 2" class="mtab-primary prev-button" type="submit" label="PREVIOUS" (click)="onPreviousClick()"
        pButton></button>
      <button *ngIf="activeIndex === 2" class="mtab-primary" [disabled]="(!emailSelected || (emailSelected==='userEmail' && !isUserEmailVerified))"
        type="submit" label="SAVE" (click)="saveUser(user)" pButton></button>
    </div>
  </form>
</p-dialog>

<!-- <div class="ui-g-12 ui-fluid inputfield">
          <h4>Password</h4>
          <span class="md-inputfield">
               [disabled]='(organizationmailchckbox.checked===true?true:false)' 
              <input  #emailPassword id="passsword"  type="password" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.emailConfig.password" name="password" />
              <label for="passsword">Password</label>
            </span>
        </div> -->
<!-- </div> -->

<!-- <div class="ui-g-4">
        <h3 style="margin-bottom: 5px;">Additional Information</h3>
        <div class="user-image-container ui-g-12 ui-fluid inputfield">
          <div class="ui-g-8">
            <img class="user-image" src="{{imageName}}" />
            <p-fileUpload #fileRef mode="basic" [chooseLabel]="'UPLOAD'" [styleClass]="'upload-btn mtab-primary'" [maxFileSize]="1000000"
              name="demo[]" accept="image/*" [auto]="true" (onSelect)=" onFileSelection($event)">
            </p-fileUpload>
          </div>
        </div>
        <div class="ui-g-12 ui-fluid inputfield">
          <span class="ui-float-label">
                <p-dropdown id="genders" [options]="genders" [style]="{'width':'100%'}" [(ngModel)]="selectedCity" name="gender" optionLabel="name"></p-dropdown>
                <label for="genders">Gender
                    <span class="required">*</span>
          </label>
          </span>
        </div>
        <div class="ui-g-12 ui-fluid inputfield">
          <span class="ui-float-label">
              <p-calendar id="dob" [(ngModel)]="date3" name="dateofbirth" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"></p-calendar>
              <label for="dob">Date Of Birth <span class="required">*</span></label>
          </span>

        </div>
        <div class="ui-g-12 ui-fluid inputfield">
          <span class="md-inputfield">
                <input disabled id="address" type="text" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.emailId" name="address" />
                <label for="address">Address</label>
              </span>
        </div>
      </div> -->


<!-- <div class="ui-g-12 ui-fluid inputfield">
        <p-checkbox #organizationmailchckbox name="orgEmail" (onChange)="(organizationmailchckbox.checked? user.emailConfig.password = '': user.emailConfig.password)"
          (onChange)="(organizationmailchckbox.checked? user.emailConfig.mailProvider = '' : user.emailConfig.mailProvider)"
          label="Use Organization Mail Account" value="user.organizationEmail" binary="true" [(ngModel)]="user.organizationEmail"></p-checkbox>

      </div>
      <div class="ui-g-12 ui-fluid inputfield">
        <span class="md-inputfield">
          <input  #emailPassword id="passsword" [disabled]='(organizationmailchckbox.checked===true?true:false)' type="password" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.emailConfig.password" name="password" />
          <label for="passsword">Password</label>
        </span>
      </div>


      <div class="ui-g ui-fluid inputfield">
        <p-radioButton [disabled]='(organizationmailchckbox.checked===true?true:false)' class="margin-right-5" name="provider" value="Google"
          label="Google" [(ngModel)]="user.emailConfig.mailProvider"></p-radioButton>
        <p-radioButton [disabled]='(organizationmailchckbox.checked===true?true:false)' class="margin-right-5" name="provider" value="Yahoo"
          label="Yahoo" [(ngModel)]="user.emailConfig.mailProvider" inputId="Yahoo"></p-radioButton>
        <p-radioButton [disabled]='(organizationmailchckbox.checked===true?true:false)' class="margin-right-5" name="provider" value="Microsoft"
          label="Microsoft" [(ngModel)]="user.emailConfig.mailProvider" inputId="Microsoft"></p-radioButton>
      </div> -->