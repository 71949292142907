import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, TooltipModule, TreeTableModule, CalendarModule, } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { PriceDialogComponent } from "./price-dialog.component";
import { CommonDataTableModule } from "../dataTable/dataTable.module";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { HoardingActionDispatcher } from "../../../hoardings/action-dispatcher/action-dispatcher";
import { HoardingActions } from "../../../hoardings/actions/hoarding-actions";
import { CalculationService } from "../../../services/shared/calculationService";
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';


@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        TreeTableModule,
        TooltipModule,
        CommonDataTableModule,
        SibGridModule,
        CalendarModule,
        CurrencyMainPipe
    ],

    declarations: [
        PriceDialogComponent
    ],

    exports: [
        PriceDialogComponent
    ],

    providers: [
        HoardingActionDispatcher,
        HoardingActions,
        CalculationService
    ],
})

export class PriceDialogModule { }
