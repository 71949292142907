export enum SibModuleEnum {
    CUSTOMER = "Customer",
    POC = "POC",
    PLAN = "Plan",
    CAMPAIGN = "Campaign",
    MONITORING = "Monitoring",
    OTHERS = "Others",
    EMAIL = "Email",
    NOTIFICATION = "Notification",
    HOARDING = "Hoarding",
    HOARDING_GROUP = "HoardingGroup",
    TAX = "Tax",
    BILLING = "Billing",
    KISOK = "Kiosk",
    CONTRACT = "Contract",
    CAMPAIGN_ITEM = "CampaignItem",
    INTERESTED_CITIES = "Interested Cities",
    PASSBOOK = "Passbook",
    PHOTOINTERFACE = "Photo-interface",
    ACTIVITY = "Activity",
    DOCUMENTS = "Documents",
    MASTERS = "Masters",
    TASKS = "Tasks",
    USER = "User",
    INVENTORY = "Inventory",
    OPERATION = "Operation",
    DASHBOARD = "Dashboard",
    LOGO = "Logo",
    ALL = "All"
}
