import { SystemService } from "./system.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class MonitoringConfigurationService {
    monitoringPeriod: Subject<number> = new Subject();
    

    constructor(
        private systemService: SystemService
    ) { }

    getMonitoringConfiguration() {
        this.systemService.get({ module: "MONITORING" }, '/search/by-module')
            .subscribe((configuration) => {
                const configurations = configuration;
                // ['_embedded']['systemProperties']
                this.setMonitoringPeriod(configurations)
            })
    }


    setMonitoringPeriod(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'MONITORING_WINDOW') {
                this.monitoringPeriod.next(configurations[i]['value']);
            }
        };
    }

}