import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule, DropdownModule } from "primeng/primeng";
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CampaignTaskComponent } from './campaign-tasks.component';
import { CampaignTaskUIService } from './services/campaign-task-ui.service';
import { CampaignService } from '../../../services/shared/campaign.service';
import { RouterModule } from '@angular/router';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        ReactiveFormsModule,
        FormsModule,
        CurrencyMainPipe,
        RouterModule,
        DropdownModule
    ],

    declarations: [
        CampaignTaskComponent,
    ],

    exports: [
        CampaignTaskComponent
    ],

    providers: [
        CampaignTaskUIService,
        CampaignService
    ]
})

export class CampaignTaskModule { }
