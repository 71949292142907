import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../services/base-service/api.service";


@Injectable()
export class CreateCustomerService extends ApiService<any, any, any> {
   
    urlPath: string = 'customers';

    constructor(Http: HttpClient, Injector: Injector) {
        super(Http, Injector);
    }
}