export enum GridNameEnum {
    HOARDINGS = "hoardings",
    PLANS = "plans",
    PLAN_ITEMS = "planItems",
    CAMPAIGNS = "campaigns",
    MOUNTING_ITEMS = "mountingItems",
    HOARDING_GROUPS = "hoardingGroups",
    MONITORING_ITEMS = "monitoringItems",
    CAMPAIGN_ITEMS = "campaignItems",
    CUSTOMERS = "customers",
    CONTRACTS = "contracts",
    MOUNTING_ITEMS_CAMP = "mountingItemsCamp",
    MONITORING_ITEM_CAMP = "monitoringItemsCamp",
    UNMOUNTING_ITEMS_CAMP = "unmountingItemsCamp",
    SUPERBILL = "superBill",
    INVOICE = "invoice",
    KIOSK = "kiosk",
    PLAN_XLSX_EMAIL = "planXlsxEmail",
    FLEX_ITEMS = "flexItems",
    UNMOUNTING_ITEMS = "unmountingItems",
    SUPERBILL_INVOICE = "superBillInvoice",
    SUPERBILL_MIGRATED = "superBillMigrated",
    ACTIVITY_GRID = "activityGrid",
    UNUTILIZED_CAMPAIGN_ITEMS = "unutilizedItems",
    PENDING_UNBILLED_TASKS = "pendingUnbilledTasks",
    TASKS = "tasks",
    PASSBOOK = "passbook",
    CHART_HOARDINGS = "chartHoardings",
    CHART_KIOSKS = "chartKiosks",
    RESERVED_INVOICES = "reservedInvoices",
    RESERVED_CREDIT_NOTES = "reservedCreditNotes",
    RENT_ITEMS = "rentItems",
    PRINT_ITEMS = "printItems",
    MOUNT_ITEMS = "mountItems",
    POINT_OF_CONTACTS = "pointOfContacts",
    UNBILLED_ITEMS = "unbilledItems",
    MIGRATED_ITEMS = "migratedItems",
    GROUPS = "groups",
    SITES_EXPIRATION = "sitesExpiration",
    RATE_APPROVAL = "rateApproval",
    ROI = "roi",
    ROI_ITEM = "roiItem",
    UNTAPPED_ROI = "untappedRoi",
    CONTRACT_HOARDINGS = "contractHoardings",
    CONTRACT_HOARDING_GROUPS = "contractHoardingGroups",
    CONTRACT_KIOSK = "contractKiosk",
}
