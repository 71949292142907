<div class="suggesionGrid">
  <sib-grid [title]="'Suggestion'" name="suggesionGrid" #sibGrid [totalRecords]="totalRecords" [columns]="columns"
    [config]="gridConfig" ngDefaultControl [buttons]="buttonConfig" [globalFilterFields]="globalFilterFields">

    <sib-grid-column field="srNo">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <span>
          {{rowIndex+1}}
        </span>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="module">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown [options]="moduleDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
          (onChange)="sibGrid.filter($event.value,'module',col.filterMatchMode)" placeholder="Module"></p-dropdown>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="deviceType">
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown [options]="deviceDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
          (onChange)="sibGrid.filter($event.value,'deviceType',col.filterMatchMode)" placeholder="Device"></p-dropdown>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="delSuggestion">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div>
          <i class="fa fa-trash-o info-red size-16" title="Delete the Suggestion" *permission="'readAll'"
            (click)="deleteSuggestion(rowData)" aria-hidden="true"></i>
        </div>
      </ng-template>
    </sib-grid-column>

  </sib-grid>
  <p-confirmDialog header="Confirmation" key="suggestion" icon="fa fa-question-circle" width="425" #cd>
    <p-footer>
      <button type="button" pButton class="mtab-save margin-hori-5" label="YES" (click)="cd.accept()"></button>
      <button type="button" pButton class="mtab-secondary" label="NO" (click)="cd.reject()"></button>
    </p-footer>
  </p-confirmDialog>

</div>

<div *ngIf="displaySuggestionDialog">
  <sib-suggestion-dialog [showSuggestionDialog]="displaySuggestionDialog"
    (emitCloseSuggestionDialog)="closeSuggestionDialog()"></sib-suggestion-dialog>
</div>