import { Directive, Input, ElementRef, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ChangesService } from '../services/shared/changes.service';
import { ActivityLogModuleEnum } from '../shared/constants/activity-log-module-enum';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[changes]',
})

export class ChangesDirective implements OnChanges {

    @Input() changedType: string = null;
    @Input() changedValue: any = null;
    @Input() changedField: string = null;
    @Input() changedFieldDisplayName: string = null;
    @Input() formatterType: string = null;
    // tslint:disable-next-line: no-inferrable-types
    @Input() changeDescription: string = '-';
    // tslint:disable-next-line: no-inferrable-types
    @Input() changeAdditionalDescription: string = '-';
    @Input() changedFieldArrayVariable: string = null;

    constructor(
        private el: ElementRef,
        private changesService: ChangesService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnChanges() {
        // if (this.changedType === ActivityLogModuleEnum.PLAN || this.changedType === ActivityLogModuleEnum.PLAN_ITEM) {
        //     if (this.checkDirty()) {
        //         this.changesService.updateObjectMap(this.changedType, this.changedField, this.changedFieldDisplayName, this.changedValue, this.formatterType, this.changeDescription, this.changeAdditionalDescription, this.changedFieldArrayVariable);
        //     }
        // } else {
        setTimeout(() => {
            if (this.checkDirty()) {
                this.changesService.updateObjectMap(this.changedType, this.changedField, this.changedFieldDisplayName, this.changedValue, this.formatterType, this.changeDescription, this.changeAdditionalDescription, this.changedFieldArrayVariable);
            }
        }, 1000);
        // }
    }

    checkDirty() {
        if (this.el.nativeElement.classList && this.el.nativeElement.classList.length) {
            const list = this.el.nativeElement.classList;
            let dirty = false;

            // added for company branch dropdown in billing
            let uiInputwrapperFilledProp = false;
            let ngPristineProp = false;

            list.forEach((element) => {
                if (element === 'ng-dirty') {
                    dirty = true;
                } else if (element === 'ui-inputwrapper-filled') {
                    uiInputwrapperFilledProp = true;
                } else if (element === 'ng-pristine') {
                    ngPristineProp = true;
                } else {
                    // do nothing
                }
            });
            if (dirty) {
                return true;
            } else {
                if (uiInputwrapperFilledProp && !ngPristineProp) {
                    return true;
                }
                return false;
            }
        } else {
            return false;
        }
    }

}
