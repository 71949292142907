import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { InvoiceTaskCard } from '../../modals/billings/invoice-task-card';
import { BillingActionDispatcher } from '../action-dispatcher/action-dispatcher';
import { Subscription, Subject } from 'rxjs';
import { InvoiceStatus } from '../../shared/constants/invoice-status-enum';
import { InvoiceCharEnum } from '../../modals/billings/invoice-char-enum';
import { InvoiceListComponent } from '../../billings/invoice-list/invoice-list.component';
import { ViewChild } from '@angular/core';
import { BillingUIService } from '../services/billingUI.service';
import { TabIndexService } from '../../services/shared/tab-index.service';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { ActivatedRoute } from '@angular/router';
import * as utils from '../../helpers/utils';

@Component({
  selector: 'sib-superbill-list',
  templateUrl: './superbill-list.component.html',
  styleUrls: ['./superbill-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuperbillListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(InvoiceListComponent) invoiceListChildRef: InvoiceListComponent;

  showEmployee = false;
  customFilter: any;
  invoiceTaskCards: InvoiceTaskCard[] = [];
  activeIndex: number;
  subscription: Subscription[] = [];
  lastTabIndex: Subject<number> = new Subject();
  tabPermissions: any[] = [];
  showTab: boolean = true;
  userEmailId: string;

  constructor(
    private billingActionDispatcher: BillingActionDispatcher,
    private billingUIService: BillingUIService,
    private pageTitle: Title,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.BILLING_MENU) : this.pageTitle.setTitle(SIBConstants.BILLING_MENU + ' - ' + appTitle);
    this.setTabPermissions();
    this.getLastActiveTabIndex();
    this.getParamsFromRoute()

    if (this.checkPermission('readAll') || this.checkPermission('view:employee-column')) {
      this.showEmployee = true;
    } else {
      this.showEmployee = false;
    }
    // this.billingActionDispatcher.getInvoiceTaskCards();
    // this.subscription.push(this.billingActionDispatcher.invoiceTaskCards.subscribe((response: any) => {
    //   if (response && response.length) {
    //     this.invoiceTaskCards = response;
    //     this.setInvoiceTaskCardsRole();
    //     this.billingActionDispatcher.resetInvoiceTaskCards();
    //   }
    // }));
    this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
      if (isError) {
        console.log("error", isError);
        this.billingActionDispatcher.resetError();
      }
    }));
  }

  getParamsFromRoute() {
    this.route.params.subscribe((params => {
      if (params['userId']) {
        this.userEmailId = params['userId'];
        this.activeIndex = 0;
      }
    }));
  }

  ngAfterViewInit() { }

  setTabPermissions() {
    this.tabPermissions = [
      'read:superbills',
      'read:invoices',
      'read:invoices',
      'read:migrated-invoices',
      'read:invoices',
    ];
  }

  getLastActiveTabIndex() {
    // this.onTabChange(Number(this.billingUIService.getBillingTabIndex()));
    const index = Number(this.billingUIService.getBillingTabIndex());
    const count = this.getAvailableTabPermissions();
    if (count !== -1) {
      if (count >= index) {
        this.onTabChange(index);
      } else {
        this.onTabChange(0);
      }
    } else {
      // do nothing
    }
  }

  getAvailableTabPermissions() {
    let count = -1;
    this.tabPermissions.forEach((perm) => {
      count = this.checkPermission(perm) ? ++count : count;
    });
    return count;
  }

  setCustomFilter(filters) {
    this.customFilter = filters;
    this.customFilter = JSON.parse(JSON.stringify(this.customFilter));
  }

  // setInvoiceTaskCardsRole() {
  //   this.invoiceTaskCards.forEach((card) => {
  //     // if (this.getStatus(card.status) === InvoiceStatus.ADMIN_GEN_PENDING || this.getStatus(card.status) === InvoiceStatus.ADMIN_EDIT_PENDING || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION) {
  //     if (this.getStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ADMIN_GEN_PENDING || this.getStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ADMIN_EDIT_PENDING || this.getStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ACCOUNTANT_INVOICE_CANCEL || this.getStatus(card.status) === InvoiceStatus.ADMIN_PENDING.ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION) {
  //       card.role = "Admin";
  //     }
  //     // if (this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_GEN_PENDING || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_EDIT_PENDING || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_CANCEL_PENDING) {
  //     // if (this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_PENDING.ACCOUNTANT_GEN_PENDING || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_PENDING.ACCOUNTANT_EDIT_PENDING || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_PENDING.ACCOUNTANT_CANCEL_PENDING) {
  //     //   card.role = "Accountant";
  //     // }
  //     // if (this.getStatus(card.status) === InvoiceStatus.ADMIN_GEN_REJECT || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_GEN_REJECT || this.getStatus(card.status) === InvoiceStatus.EDIT_PENDING || this.getStatus(card.status) === InvoiceStatus.ADMIN_EDIT_REJECT || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_EDIT_REJECT || this.getStatus(card.status) === InvoiceStatus.ADMIN_CANCEL_REJECT || this.getStatus(card.status) === InvoiceStatus.ACCOUNTANT_CANCEL_REJECT) {
  //     if (this.getStatus(card.status) === InvoiceStatus.EDIT_PENDING) {
  //       card.role = "Sales Rep";
  //     }
  //     if (!card.role) {
  //       // card.role = "Accountant";
  //       if (InvoiceCharEnum[card.printed] === InvoiceCharEnum.F) {
  //         card.role = "Bill Handler";
  //       } else if (InvoiceCharEnum[card.tallyEntered] === InvoiceCharEnum.F) {
  //         card.role = "Accountant";
  //       }
  //     }
  //   });
  //   this.invoiceTaskCards = JSON.parse(JSON.stringify(this.invoiceTaskCards));
  // }

  getStatus(status) {
    // if (InvoiceStatus[status]) {
    //   return InvoiceStatus[status];
    // }
    // if (InvoiceStatus.ADMIN_PENDING[status]) {
    //   return InvoiceStatus.ADMIN_PENDING[status];
    // }
    // // if (InvoiceStatus.ACCOUNTANT_PENDING[status]) {
    // //   return InvoiceStatus.ACCOUNTANT_PENDING[status];
    // // }
    return InvoiceStatus[status];
  }

  onTabChange(index) {
    this.activeIndex = index;
    this.activeIndex = JSON.parse(JSON.stringify(this.activeIndex));
    this.billingUIService.setBillingTabIndex(index);
  }

  checkPermission(permission) {
    return this.billingUIService.checkPermission(permission);
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    });
  }

}

