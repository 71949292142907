import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { CreditNote } from '../../modals/billings/credit-note';
import { GridColumn, EditableGridFieldTypes, GridConfig, SelectionMode, GridPaginationEvent } from '../../components/sib-forms/grid/grid.config';
import { FormGroup, FormControl } from '@angular/forms';
import { GridActionButtonConfig } from '../../components/sib-forms/grid/grid-action-button.config';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { TaxConfigurationService } from '../../services/shared/tax-configuration.service';
import { Subscription, of as observableOf } from 'rxjs';
import * as utils from "../../helpers/utils";

@Component({
    selector: 'sib-cn-summary-grid',
    templateUrl: './cn-summary-grid.component.html',
    styleUrls: ['./cn-summary-grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class CNSummaryGridComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('cnSummaryGrid')
    cnSummaryGridRef;

    _creditNote: CreditNote = new CreditNote();
    @Input() set creditNote(creditNote) {
        this._creditNote = creditNote;
        this._creditNote = JSON.parse(JSON.stringify(this._creditNote));
    }
    get creditNote() {
        return this._creditNote;
    }

    creditNotes: CreditNote[] = [];

    columns: Array<GridColumn> = [
        {
            field: 'particulars',
            name: 'particulars',
            header: 'Particulars ',
            required: true,
            width: '50vw',
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            sortable: false,
            styleClass: 'word-break-wrap',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'amount',
            name: 'amount',
            header: 'Amount',
            permanent: true,
            sortable: true,
            hidden: false,
            required: true,
            editable: false,
            width: '40vw',
            type: EditableGridFieldTypes.CUSTOM,
            styleClass: 'invoice-grid',
        },
    ];

    totalRecords: number;
    cNSummaryGroup: FormGroup;
    gridConfig: GridConfig<any> = new GridConfig<any>();
    buttonConfig: GridActionButtonConfig;
    subscription: Subscription[] = [];
    igstParameter: number;
    gstParameter: number;
    cgstParameter: number;
    sgstParameter: number;

    constructor(
        private currencyPipe: CurrencyHelperPipe,
        private taxConfigurationService: TaxConfigurationService
    ) { }

    ngOnInit() {
        this.getTaxConfiguration();
        this.setFormGroupObjects();
        this.setGridConfigObject();
    }

    ngAfterViewInit() {
        this.refreshGrid();
    }

    setFormGroupObjects() {
        this.cNSummaryGroup = new FormGroup({
            cnSummaryGridControl: new FormControl()
        });
    }

    setGridConfigObject() {
        this.gridConfig.getDefaultModel = () => new CreditNote();
        this.gridConfig.model = CreditNote;
        this.gridConfig.selectionMode = SelectionMode.Multi;
        this.gridConfig.editable = true;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        // this.gridConfig.rowExpandMode = 'single';
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.showColorCode = false;
        this.gridConfig.lazy = false;
        this.gridConfig.enablePagination = false;
        this.gridConfig.paginatorConfig.alwaysShowPaginator = false;
        this.gridConfig.scrollable = true;


        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            return observableOf(this.creditNotes);
        };
    }

    getTaxConfiguration() {
        this.taxConfigurationService.getTaxConfiguration();

        this.subscription.push(
            this.taxConfigurationService.gst
                .subscribe((gst) => {
                    this.gstParameter = gst;
                }));

        this.subscription.push(
            this.taxConfigurationService.cgst
                .subscribe((cgst) => {
                    this.cgstParameter = cgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.sgst
                .subscribe((sgst) => {
                    this.sgstParameter = sgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.igst
                .subscribe((igst) => {
                    this.igstParameter = igst;
                    this.setItems();
                }));
    }

    setItems() {
        this.creditNotes = [];
        this.creditNotes.push(this.creditNote);
        this.refreshGrid();
    }

    getRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    refreshGrid() {
        if (this.gridConfig.dataLoadFunction !== undefined) {
            this.cnSummaryGridRef.refresh();
        }
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }

}