import { ApiService, BASE_URL } from '../base-service/api.service';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { empty as observableEmpty, of as observableOf, Observable } from 'rxjs';
import { HttpParamsOptions } from '@angular/common/http/src/params';

@Injectable()
export class GridService extends ApiService<any, any, any> {
    urlPath = "";
    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    exportGridXlsx(basePath, urlSubPath) {
        this.urlPath = basePath;
        return this.create(null, null, urlSubPath, { "Content-Type": "application/json" }, "arraybuffer");
    }
}