<div class="ui-g">

    <div class="ui-g-3 customer-photo-container">
        <div class="profile" class="customer-image-container">
            <img class="customer-image" src="{{customer?.image?.url}}" />
            <div class="loader image-loader">
                    <!-- *ngIf="imageLoader" -->
                <p-progressSpinner [style]="{width: '25px', height: '25px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    </div>

    <div class="ui-g-3 padding-30">
        <b>{{customer?.company}}</b>
    </div>

</div>