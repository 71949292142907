import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'CurrencyConverter'
})
export class CurrencyHelperPipe implements PipeTransform {
  transform(inputNumer: string | number): string {
    if (inputNumer) {
      if (inputNumer !== '-') {
        const absNumber = Math.abs(Number(inputNumer));
        const inputArray = Number(absNumber).toString().split('.');
        let input = inputArray[0];
        input = input.toString();
        let lastThree = input.substring(input.length - 3);
        const otherNumbers = input.substring(0, input.length - 3);
        if (otherNumbers !== '') {
          lastThree = ',' + lastThree;
        }
        let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (inputArray.length > 1) {
          res = res + '.' + inputArray[inputArray.length - 1];
        }
        if (inputNumer < 0) {
          return '-' + res;
        } else {
          return res;
        }
      } else {
        return "-";
      }
    } else {
      return '0';
    }
  }

}
