import { ChartArea } from "./chart-area/chart-area";
import { ChartLegend } from "./chart-legend/chart-legend";

export class PieChartConfig {
    title: string;
    pieHole?: number;
    is3D?: boolean;
    height?: number;
    width?: number;
    chartArea?: ChartArea = new ChartArea();
    chartLegend?: ChartLegend = new ChartLegend();
    colors?: string[];
    backgroundColor?:string;

    constructor(title: string, pieHole?: number, is3D?: boolean,  height?: number,  width?: number, chartArea?: ChartArea, chartLegend?: ChartLegend, colors?: string[],backgroundColor?:string) {
        this.title = title;
        this.pieHole = pieHole;
        this.is3D = is3D;
        this.height = height;
        this.width = width;
        this.chartArea = chartArea;
        this.chartLegend = chartLegend;
        this.colors = colors;
        this.backgroundColor = backgroundColor;
    }
}
