import { PurchaseOrder } from "../campaigns/purchaseOrder";

export class CampaignQueryParams {
    itemType?: any;
    itemId?: any;
    campaignId?: any;
    CAMPAIGN_ID?: any;
    ITEM_ID?: any;
    projection?: any;
    purchseOrders?: PurchaseOrder[];
}
