import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import { Correspondence } from "../../../modals/correspondence/conrrespondence";

@Component({
  selector: 'sib-preview-send-dialog',
  templateUrl: './preview-send-dialog.component.html',
  styleUrls: ['./preview-send-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PreviewSendDialogComponent implements OnInit {

  @Input() display = false;
  @Input() source: string = null;
  @Input() correspondence: Correspondence = new Correspondence();
  @Input() bytePPT: any;
  @Input() subView: String = 'EMAIL';
  @Input() columns: any[] = [];
  @Input() selectedColumns: any[] = [];
  @Input() kioskPresent: boolean;
  @Input() plan: any;
  @Output() displayReset: EventEmitter<boolean> = new EventEmitter();
  @Output() eEmitSendMail: EventEmitter<Correspondence> = new EventEmitter();


  lastSubView: any;
  inValid = false; // to check the form validity


  constructor() { }

  ngOnInit() {

    console.log(this.selectedColumns, 'selected columns');
    // this.resetDialog();
  }

  hide() {
    this.display = false;
    this.correspondence = new Correspondence();
    this.displayReset.emit(false);
  }

  changeView() {
    if (this.subView !== 'EMAIL') {
      this.lastSubView = _.cloneDeep(this.subView);
      this.subView = 'EMAIL';
    } else {
      this.subView = this.lastSubView;
    }
  }

  checkFormValidity(email) {
    if (email.isValid()) {
      this.inValid = false;
    } else {
      this.inValid = true;
    }
  }

  sendMail() {
    this.eEmitSendMail.emit(this.correspondence);
    this.hide();
  }

 

}

