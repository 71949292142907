import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { AreaDialogComponent } from "./area-dialog.component";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { DirectiveModules } from '../../../modules/directive.modules';
@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        DirectiveModules
    ],

    declarations: [
        AreaDialogComponent
    ],

    exports: [
        AreaDialogComponent
    ]
})

export class AreaDialogModule {}
