import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { FilterObject } from "../photo-interface.component";

@Injectable()
export class PhotoInterfaceGlobalSrevice {
    globalFilter: Subject<any> = new Subject();
    filterObject: Subject<FilterObject> = new Subject();

    setPhotoInterfaceTabIndex(tabIndex) {
        localStorage.setItem('photoInterfaceTabIndex', tabIndex);
    }

    getPhotoInterfaceTabIndex() {
        return localStorage.getItem('photoInterfaceTabIndex');
    }
}
