import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService } from "../base-service/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Area } from "../../modals/Area/area";

@Injectable()
export class AreaCategoryService extends ApiService<any, any, any> {
    urlPath: string = 'areas/categories';

    constructor(private Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }

    getCategories() {
        if (navigator.onLine) {
            return this.Http.get("http://172.16.5.149:9000/api/areas/categories")
                .subscribe((response) => {
                    console.log("inside service response", response);
                })
        }

    }

}