export enum CampaignStatusEnum {
    PENDING = "Pending",
    APPROVED = "Approved",
    APPROVED_PENDING = "Approved Pending",
    RUNNING = "Running",
    APPROVED_RUNNING = "Approved Running",
    PENDING_RUNNING = "Pending Running",
    EXPIRED = "Expired",
    REJECTED = "Rejected",
    PENDING_APPROVED_RUNNING = "A_P_R"
}
