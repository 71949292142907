import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, DropdownModule, DialogModule, CalendarModule, TooltipModule } from 'primeng/primeng';
import { SibGridModule } from '../../components/sib-forms/grid/grid.module';
import { RouterModule } from '@angular/router';
import { CreditNotesService } from '../../services/shared/credit-note.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReserveCNDetailDialogComponent } from './reserve-credit-note-detail-dialog.component';
import { UserService } from '../../services/shared/user.service';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        DialogModule,
        RouterModule,
        CalendarModule,
        TooltipModule
    ],

    declarations: [
        ReserveCNDetailDialogComponent
    ],

    exports: [
        ReserveCNDetailDialogComponent
    ],

    providers: [
        UserService
    ]
})

export class ReserveCreditNoteDialogModule { }
