/**
* @file BillingRoutingModule
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { SuperbillListComponent } from "./superbill-list/superbill-list.component";
import { BillingComponent } from "./billing.component";
import { SuperbillViewComponent } from "./superbill-view/superbill-view.component";
import { SuperbillHoardingsComponent } from "./superbill-hoardings/superbill-hoardings.component";
import { BillViewComponent } from "./bill-view/bill-view.component";
import { GenerateBillComponent } from "./generate-bill/generate-bill.component";
import { CanDeactivateGenerateInvoiceGuardService } from "./services/canDeactivateGenerateInvoiceGuard.service";
import { CanDeactivateSuperBillViewGuardService } from "./services/canDeactivateSuperBillViewGuard.service";
// import { CanActivateSuperBillListGuardService } from "./services/canActivateSuperBillListGuard.service";
import { CanActivateSuperBillViewGuardService } from "./services/canActivateSuperBillViewGuard.service";
import { CanActivateGenerateBillGuardService } from "./services/canActivateGenerateBillGuard.service";
import { CanActivateBillViewGuardService } from "./services/canActivateBillViewGuard.service";
import { ReserveInvoicesComponent } from './reserve-invoices/reserve-invoices.component';
import { CanActivateReserveInvoiceGuardService } from './services/canActivateReserveInvoiceGuard.service';
import { CNViewComponent } from './cn-view/cn-view.component';
import { CanActivateCNViewGuardService } from './services/CanActivateCNlViewGuard.service';
import { CanActivateReserveCreditNoteGuardService } from './services/canActivateReserveCreditNoteGuard.service';
import { ReserveCreditNotesComponent } from './reserve-credit-notes/reserve-credit-notes.component';
// import { MigratedInvoiceComponent } from './generate-bill/migrated-invoice/migrated-invoice.component';


const routes: Routes = [
    {
        path: '', component: BillingComponent, children: [
            {
                path: '', component: SuperbillListComponent, data: { title: "Billings" },
                // canActivate: [CanActivateSuperBillListGuardService]
            },
            {
                path: 'view/:id', component: SuperbillViewComponent, data: { title: "Superbill" }, canDeactivate: [CanDeactivateSuperBillViewGuardService], canActivate: [CanActivateSuperBillViewGuardService]
            },
            {
                path: 'generateBill/:id', component: GenerateBillComponent, data: { title: "Generate Invoice" }, canDeactivate: [CanDeactivateGenerateInvoiceGuardService], canActivate: [CanActivateGenerateBillGuardService]
            },
            {
                path: 'bills/view/:id', component: BillViewComponent, data: { title: "Invoice" }, canActivate: [CanActivateBillViewGuardService]
            },
            {
                path: 'cn/view/:id', component: CNViewComponent, data: { title: "Credit Note" }, canActivate: [CanActivateCNViewGuardService]
            },
            {
                path: 'reserve-invoices', component: ReserveInvoicesComponent, data: { title: "Reserve Invoices" }, canActivate: [CanActivateReserveInvoiceGuardService]
            },
            {
                path: 'reserve-credit-notes', component: ReserveCreditNotesComponent, data: { title: "Reserve Credit Notes" }, canActivate: [CanActivateReserveCreditNoteGuardService]
            },
            // {
            //     path: 'generateMigratedBill/:id', component: MigratedInvoiceComponent, data: { title: "Migrated Invoice" }
            // }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class BillingRoutingModule { }
