import { AbstractItem } from "../hoardings/abstract-hoarding";
import { Campaign } from "./campaign";
import { Printer } from "./printer";
import { User } from "./user";
import { MonitoringItem } from "./monitoringItem";
import { AdditionalPrice } from "./additionalPrice";
import { MountingItem } from "./mountingItem";
import { PrintingPrice } from "../plans/printingPrice";
import { MountingPrice } from "../plans/mountingPrice";
import { CampaignItemStatusEnum } from "../../shared/constants/campaign-item-status-enum";
import { KioskTypeEnum } from '../../shared/constants/kiosk-type.enum';



export class CampaignItem extends AbstractItem {
    campaignId: string;
    campaign: Campaign = new Campaign();
    rate: number;
    cost: number;
    rateType: string;
    itemStartDate: Date;
    itemEndDate: Date;
    pending: boolean;
    pendingReason: string;
    printer: Printer;
    monitoringItems: MonitoringItem[] = [];
    nonBillableDays: number;
    itemStatus: CampaignItemStatusEnum;
    hoardingId: string;
    mountingItems: MountingItem[] = [];
    // unmountingItem: MountingItem = new MountingItem();
    unmountingItems: MountingItem[] = [];
    printingPrice: PrintingPrice = new PrintingPrice();
    mountingPrice: MountingPrice = new MountingPrice();
    printPrice: number = 0;
    mountPrice: number = 0;
    printCharge: number;
    mountCharge: number;
    quantity: number;
    kioskType: KioskTypeEnum;
    employeeName: string;
    //Sanchit Flex changes
    flexItems: MountingItem[] = [];
    tradedAsset: boolean = false;
    vendorName: string;
    rateApprovalPending: boolean = false; //if true then rate approval is pending
    oldRate: number;
    oldCost: number;

    setPrintPrices(price: PrintingPrice): number {
        if (price.flNlPrice !== 0) {
            this.printPrice = price.flNlPrice;
        }
        if (price.blPrice !== 0) {
            this.printPrice = price.blPrice;
        }
        return this.printPrice;
    }

    setMountPrices(price: MountingPrice): number {
        if (price.pricePerSqFt !== 0) {
            this.mountPrice = price.pricePerSqFt;
        }
        if (price.pricePerUnit !== 0) {
            this.printPrice = price.pricePerUnit;
        }
        return this.mountPrice;
    }
}
