import { ActivityLogModuleEnum } from '../shared/constants/activity-log-module-enum';

export function createMap(columnsConfig) {
    const map = new Map<String, String>();
    columnsConfig.forEach((col) => {
        map.set(col.field, col.header);
    });
    return map;
}

// export function createModuleToActivityActionEventEnumMap(module) {
//     const map = new Map<ActivityLogModuleEnum, any>();
//     switch (module) {
//         // case ActivityLogModuleEnum[module] === "All" : {
//         //     break;
//         // }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.AREA: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CITY: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CONTRACT_TYPE: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.DISTRICT: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.GROUP: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CONTRACT: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CUSTOMER: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.HOARDING: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.HOARDING_GROUP: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.POINT_OF_CONTACT: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.PLAN: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CAMPAIGN: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CAMPAIGN_ITEM: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.INVOICE: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.KIOSK: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CI_FLEX: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CI_MONITORING: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CI_MOUNTING: {
//             break;
//         }
//         case ActivityLogModuleEnum[module] === ActivityLogModuleEnum.CI_UNMOUNTING: {
//             break;
//         }
//         default: {
//             break;
//         }
//     }
//     return map;
// }
