import { SibBaseObject } from '../BaseObject/SibBaseObject';
import { CampaingWrapper } from './campaign-wrapper';
import { RentItem } from './rent-item';
import { AdditionalItem } from './additional-item';
import { BillingNote } from './billingNote';
import { PurchaseOrder } from '../campaigns/purchaseOrder';
import { User } from '../campaigns/user';
import { InvoiceGstDetail } from './invoice-gst-detail';
import { InvoiceCharEnum } from './invoice-char-enum';
import { PrintConfig } from './print-config';
import { InvoiceFormatEnum } from '../../shared/constants/invoice-format-enum';
import { SupplierWrapper } from './supplier-wrapper';

export class AbstractInvoice extends SibBaseObject {
    billGeneratedDate: Date;
    billStartDate: Date;
    billEndDate: Date;
    campaign: CampaingWrapper = new CampaingWrapper();
    // status: InvoiceStatus;
    status: string; // type changed from enum to const
    rentItems: RentItem[] = [];
    printingItems: AdditionalItem[] = [];
    mountingItems: AdditionalItem[] = [];
    rentAmount: number;
    printAmount: number;
    mountAmount: number;
    totalAmount: number;
    actualAmount: number;
    lspDiff: number;
    lspDiffPctg: number;
    campaignDiff: number;
    campaignDiffPctg: number;
    isEdited = false;
    actualBillPath: string;
    // notes: Note[] = [];
    billingNotes: BillingNote[] = [];
    purchaseOrders: PurchaseOrder[] = [];
    taxAmount: number;
    tallyInvoiceId: string;
    interState: boolean;
    updateGeneratedDate: boolean;
    employee: User = new User();
    invoiceGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    placeOfSupply: string;
    printed: InvoiceCharEnum;
    tallyEntered: InvoiceCharEnum;
    customerReceived: InvoiceCharEnum;
    cnRequest = false;
    grouped = false;
    internalNotes: string;
    invoiceId: string;
    invoiceReferenceId: string;
    remark: string;
    printConfiguration: PrintConfig = new PrintConfig();
    invoiceFormat: InvoiceFormatEnum;
    supplier: SupplierWrapper = new SupplierWrapper();
}
