import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";
import { PrintingPrice } from "../plans/printingPrice";
import { MountingPrice } from "../plans/mountingPrice";
import { MediaSubCategoryEnum } from '../../shared/constants/media-sub-category-enum';

export class CampaignItemWrapper {
    id: string;
    customId: string;
    rate: number;
    cost: number;
    size: string;
    squareFeet: number;
    location: string;
    city: string;
    lightType: HoardingLightEnum;
    printPrice: PrintingPrice = new PrintingPrice();
    mountPrice: MountingPrice = new MountingPrice();
    mediaSubCategory: MediaSubCategoryEnum;
}
