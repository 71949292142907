/**
* @file  Campaign
* @author pulkitb@meditab.com
* @copyright Meditab Software 2017
*/

import { Campaign } from "../campaigns/campaign";
import { MonitoringItem } from "../campaigns/monitoringItem";
import { Area } from "../Area/area";
import { Picture } from "../hoardings/picture";
import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";

export class MonitoringMaster {
    id: string;
    campaign: Campaign = new Campaign();
    monitoringItems: MonitoringItem = new MonitoringItem();
    customId: string;
    district: string;
    city: string;
    area: Area = new Area();
    location: string;
    size: number;
    itemStatus: string;
    lightType: HoardingLightEnum;
    images: Picture[] = [];
    employeeName: string;
}
