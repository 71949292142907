export class PptGenerationData {
    data: string;
    srno: number;
    imageHeight: number;
    imageWidth: number;
    header: string;
    size: string;
    lightType: string;
    rate: number;
    numberOfDays: number;
    customId: string;
    availabilityDate: string;
    operatedDate: string;
    imageType: string;
    mode: string;
}