<div class="roi-container">
  <div class="flex-action-container margin-top-14" *ngIf="!viewContractInventory">
    <div class="flex-action-item">
      <!-- toggle for viewing untapped contracts -->
      <label class="font-bold label-vCenter padding-right-5 contract-toggle-label">Show NPC</label>
      <p-inputSwitch [styleClass]="'contract-toggle'" [(ngModel)]="checked" (onChange)="showUntappedContracts($event)">
      </p-inputSwitch>
    </div>
    <div class="flex-action-item">
      <span class="ui-float-label">
        <p-calendar #sDate [inputStyle]="{'width':'100px'}" id="stDate" name="startDate" [utc]="true"
          [(ngModel)]="startDate" showIcon="true" dateFormat="ddMy" shortYearCutoff="100" monthNavigator="true"
          yearNavigator="true" [yearRange]="'2017:2050'" [utc]="true" (onSelect)="onStartDateSelection(sDate)"
          appendTo="body" trim="blur">
        </p-calendar>
        <label for="stDate">Start Date
        </label>
      </span>
    </div>
    <div class="flex-action-item">
      <span class="ui-float-label">
        <p-calendar #eDate [inputStyle]="{'width':'100px'}" id="endDate" name="endDate" [utc]="true"
          [(ngModel)]="endDate" [minDate]="endDateMin" [defaultDate]="endDateMin" [disabled]="!sDate.value"
          showIcon="true" dateFormat="ddMy" shortYearCutoff="100" monthNavigator="true" yearNavigator="true"
          [yearRange]="'2017:2050'" [utc]="true" (onFocus)="setEndDateMin()" (onSelect)="onEndDateSelection(eDate)"
          appendTo="body" trim="blur">
        </p-calendar>
        <label for="endDate">End Date
        </label>
      </span>
    </div>
  </div>

  <div *ngIf="!viewContractInventory" class="roi-grid-scroller">
    <div *ngIf="!checked">
      <sib-grid [title]="'ROI'" name="roiContractLevelGrid" #sibGrid [totalRecords]="totalRecords"
        [columns]="contractDataColumns" [config]="gridConfig" [showFilter]="true" [showXlsExport]="showXlsExport"
        (eEmitExportXlsx)="exportXlsx()" (eEmitColumnSelection)="updatingUserGridConfig($event)">
        <sib-grid-column field="srNo">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <span>
              {{rowIndex+1}}
            </span>
          </ng-template>
        </sib-grid-column>
        <sib-grid-column field="contract.name">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div *ngIf="rowData.contract && rowData.contract.name; else dashTemplate" pTooltip="View Contract">
              <a (click)="viewInventory(rowData)">{{rowData?.contract?.name}}</a>
            </div>
          </ng-template>
          <ng-template #dashTemplate>
            -
          </ng-template>
        </sib-grid-column>
        <sib-grid-column field="contract.cheques.chequeName">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            {{(rowData?.contract?.cheques?.length> 0 && rowData?.contract?.cheques[0]?.chequeName !== null)? rowData?.contract?.cheques[0]?.chequeName:"-"}}
            <a class="cheque-detail-button" (mouseleave)="chequeDetail.toggle($event)"
              (mouseenter)="openChequeDetails(rowData); chequeDetail.toggle($event)"
              (click)="openChequeDetails(rowData); chequeDetail.toggle($event)"
              *ngIf="rowData?.contract?.cheques?.length> 1">+{{rowData?.contract?.cheques?.length - 1 }} more</a>
          </ng-template>
        </sib-grid-column>
        <sib-grid-column field="differenceAmount">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div *ngIf="rowData.differenceAmount >= 0" class="posDiff">
              {{getValueParsedToTwoDecimalPlaces(rowData.differenceAmount) | CurrencyConverter}}
            </div>
            <div *ngIf="rowData.differenceAmount < 0" class="negDiff">
              {{getAbsoluteValue(getValueParsedToTwoDecimalPlaces(rowData.differenceAmount)) | CurrencyConverter}}
            </div>
          </ng-template>
        </sib-grid-column>
        <sib-grid-column field="differencePercentage">
          <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
            <div *ngIf="rowData.differencePercentage >= 0" class="posDiff">
              {{getIntegerRoundedOffValue(rowData.differencePercentage) | CurrencyConverter}}%
            </div>
            <div *ngIf="rowData.differencePercentage < 0" class="negDiff">
              {{getAbsoluteValue(getIntegerRoundedOffValue(rowData.differencePercentage)) | CurrencyConverter}}%
            </div>
          </ng-template>
        </sib-grid-column>
      </sib-grid>
    </div>
    <div *ngIf="checked">
      <sib-untapped-roi [startDate]="startDate" [endDate]="endDate"></sib-untapped-roi>
    </div>
  </div>

  <div *ngIf="viewContractInventory">
    <sib-view-roi-contract-inventory #contractInventory [startDate]="startDate" [endDate]="endDate"
      [contractId]="contractId" [contractName]="contractName" (viewContractInventory)="viewContract($event)">
    </sib-view-roi-contract-inventory>
  </div>

  <p-overlayPanel [dismissable]="true" [styleClass]="'cheque-detail'" #chequeDetail>
    <h3>Cheque Details</h3>
    <ng-template let-cheque let-last="last" ngFor [ngForOf]="chequeDetails" let-i="index">
      <div class="cheque-row">{{i+1}}. {{cheque.chequeName}}</div>
      <!-- <hr> -->
    </ng-template>
  </p-overlayPanel>
</div>