import { PointOfContact } from "./pointofcontact";
import { SalesPerson } from "./salesperson";
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Industry } from "../industry/industry";
import { Group } from "../group/group";
import { User } from "../user/user";
import { GSTDetail } from "./GSTDetails";
import { State } from "../Area/state";
import { UserWrapper } from '../user/user-wrapper';
import { Picture } from '../hoardings/picture';

export class Customer extends SibBaseObject {
    //Push trigger
    gst: string;
    pan: string;
    company: string;
    billingName: string;
    industry: Industry = new Industry();
    group: Group = new Group();
    address1: string;
    address2: string;
    city: any;
    district: any;
    state: State = new State();
    postalCode: string;
    contactNumber: string;
    faxNumber: string;
    website: string;
    emailId: string;
    facebookUrl: string;
    linkedinUrl: string;
    pocs: PointOfContact[] = [];
    managers: UserWrapper[] = [];
    image?: any;
    mobileNumber: string;
    status: any;
    gstDetails: GSTDetail[] = [];
    dndFlag: boolean = false;

    profileImage: Picture = new Picture();
    // profileImage: Picture[] = [];
    customerId: string;
}
