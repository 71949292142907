/**
 * @description enum for invoice formats
 * @export
 * @enum {number}
 */
export enum InvoiceFormatEnum {
    FULL_DETAIL = "Full Detail",
    LESS_DETAIL = "Less Detail",
    PACKAGE_RATE_INCLUSIVE = "Package Rate inclusive of Printing/Mounting",
    PACKAGE_RATE_EXCLUSIVE = "Package Rate with separate Printing/Mounting",
    AS_PER_RO_INCLUSIVE = "As Per Ro inclusive of Printing/Mounting",
    AS_PER_RO_EXCLUSIVE = "As Per Ro with separate Printing/Mounting",
}
