import { Component, OnInit } from '@angular/core';
import { GanttChartConfig } from '../modals/charts/ganttChart.config';
import { TimelineChartConfig, Timeline } from '../modals/charts/timelineChart.config';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../shared/constants/SIBConstant';
import * as utils from '../helpers/utils';

@Component({
  selector: 'sib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  constructor(
    private pageTitle: Title,
  ) { }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.APP_TITLE) : this.pageTitle.setTitle(appTitle);
  }
}
