import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ButtonModule, SharedModule, InputTextModule, TooltipModule, GMapModule, DialogModule, CheckboxModule, AutoCompleteModule, OverlayPanelModule } from 'primeng/primeng';
import { InputTrimModule } from '../../../directives/input-trim';
import { PlaceAutoComplete } from './services/place-autocomplete.service';
import { PlaceDetailService } from './services/place-detail.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    ButtonModule,
    SharedModule,
    InputTextModule,
    TooltipModule,
    InputTrimModule,
    DialogModule,
    CheckboxModule,
    GMapModule,
    AutoCompleteModule,
    OverlayPanelModule
  ],
  declarations: [MapComponent],

  providers: [
    PlaceAutoComplete,
    PlaceDetailService
  ],

  exports: [
    MapComponent
  ]
})
export class MapModule { }
