import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class StateService extends ApiService<any, any, any> {
    urlPath: string = 'states/search/byCountryCode';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }

   
}