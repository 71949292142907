<div class="cn-summary">
    <div class="table-heading">
        Summary
    </div>
    <table class="c-tbl">
        <!-- <tr>
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Campaign Difference', value: getCurrencyFormat(getAbsoluteValue(parseValueToDecimalTwoPlaces(campaignDifference))) , class: (campaignDifference < 0) ? 'negDiff' : 'posDiff' }">
            </ng-container>
        </tr>
        <tr>
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'LSP Differnce', value: getCurrencyFormat(getAbsoluteValue(getIntegerRoundedOffValue(lspDifference))) + '(' + getCurrencyFormat(getAbsoluteValue(getIntegerRoundedOffValue(lspDifferencePctg))) + '%)' , class: (lspDifference < 0) ? 'negDiff' : 'posDiff' }">
            </ng-container>
        </tr> -->
        <tr *ngIf="creditNote?.rentItems?.length > 0">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Rent Amount', value: getCurrencyFormat(parseValueToDecimalTwoPlaces(rentAmount)) }">
            </ng-container>
        </tr>
        <tr *ngIf="creditNote?.printingItems?.length > 0">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Printing Amount', value: getCurrencyFormat(parseValueToDecimalTwoPlaces(printAmount)) }">
            </ng-container>
        </tr>
        <tr *ngIf="creditNote?.mountingItems?.length > 0">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Mounting Amount', value: getCurrencyFormat(parseValueToDecimalTwoPlaces(mountAmount)) }">
            </ng-container>
        </tr>
        <!-- <tr
            *ngIf="!creditNote?.invoiceGstDetail?.branchAddress?.state.name || (creditNote?.invoiceGstDetail?.branchAddress?.state.name === 'Gujarat' || creditNote?.invoiceGstDetail?.branchAddress?.state.name === '' || !creditNote?.invoiceGstDetail)">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'CGST(' + cgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(cgstTax)) }">
            </ng-container>
        </tr>
        <tr
            *ngIf="!creditNote?.invoiceGstDetail?.branchAddress?.state.name || (creditNote?.invoiceGstDetail?.branchAddress?.state.name === 'Gujarat' || creditNote?.invoiceGstDetail?.branchAddress?.state.name === '' || !creditNote?.invoiceGstDetail)">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'SGST(' + sgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(sgstTax)) }">
            </ng-container>
        </tr>
        <tr
            *ngIf="creditNote?.invoiceGstDetail?.branchAddress?.state.name && (creditNote?.invoiceGstDetail?.branchAddress?.state.name !== 'Gujarat' && creditNote?.invoiceGstDetail?.branchAddress?.state.name !== '')">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'IGST(' + igstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(igstTax)) }">
            </ng-container>
        </tr> -->
        <tr *ngIf="!isIgst">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'CGST(' + cgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(cgstTax)) }">
            </ng-container>
        </tr>
        <tr *ngIf="!isIgst">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'SGST(' + sgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(sgstTax)) }">
            </ng-container>
        </tr>
        <tr *ngIf="isIgst">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'IGST(' + igstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(igstTax)) }">
            </ng-container>
        </tr>
        <tr>
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Total' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(totalWithTax))  }">
            </ng-container>
        </tr>
    </table>


    <ng-template #summaryTemplate let-key='key' let-value='value' let-class='class'>
        <th>
            <div class="valuefont">{{key}}</div>
        </th>
        <td>
            <div class={{class}}>{{value}}</div>
        </td>
    </ng-template>
</div>