import { Injectable } from "@angular/core";
import { GoogleChartsBaseService } from "./chart.base.service";
import { HistogramChartConfig } from "../../modals/charts/histogramChart.config";

declare var google: any;

@Injectable()
export class GoogleHistogramChartService extends GoogleChartsBaseService {

  constructor() { super(); }

  public buildHistogramChart(elementId: String, data: any[], config: HistogramChartConfig): void {
    const chartFunc = () => new google.visualization.Histogram(document.getElementById(elementId + ''));
    const options = {
      title: config.title,
      isStacked: config.isStacked,
      height: config.height,
    //   bars: config.bars,
    //   height: config.height,
    //   width: config.width,
    //   chartArea: config.chartArea,
      legend: config.legend,
      colors: config.colors,
    };
    // alert('histogram chart service')
    this.buildChart(data, chartFunc, options);
  }
}
