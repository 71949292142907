import { Campaign } from '../campaigns/campaign';
import { User } from '../campaigns/user';
import { InvoiceStatus } from '../../shared/constants/invoice-status-enum';
import { RentItem } from '../billings/rent-item';
import { CampaignItem } from '../campaigns/campaignItem';

export class UnbilledItems {
    amount: number;
    campaign: Campaign = new Campaign();
    campaignItem: CampaignItem = new CampaignItem();
    category: string;
    creative: string;
    ranges: any[];
}