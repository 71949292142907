<div class="count-card">
    <div class="header">
        <h3 class="heading-tertiary">
            Users
        </h3>
    </div>
    <hr>
    <div class="counts">
        <div class="total-counts">
                <div class="total-count-child">
                    {{userCount?.total}}
                </div>
        </div>
        <div class="individual-count">
            <table>
                <tr>
                    <td class="detail-text">Active</td>
                    <td class="detail-count">{{userCount?.active}}</td>
                </tr>
                <tr>
                    <td class="detail-text">Inactive</td>
                    <td class="detail-count">{{userCount?.inActive}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>