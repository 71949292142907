<div class="pendingtasks">
  <!-- <div *ngIf="isLoading" class="loading">
    <p-progressSpinner></p-progressSpinner>
  </div> -->
  <div>
    <div class="toggle-container">
      <label class="font-bold label-vCenter padding-right-5 contract-toggle-label">Show Hidden Sites</label>
      <p-inputSwitch [styleClass]="'contract-toggle'" (onChange)="onToggle($event)">
      </p-inputSwitch>
    </div>
    <form [formGroup]="tasksGroup">
      <div *ngIf="!showHiddenItems">
        <sib-grid name="taskGrid" #sibGrid [showFilter]="true" [showColorLegends]="false" [totalRecords]="totalRecords"
          (eEmitColumnSelection)="updatingUserGridConfig($event)" [columns]="taskColumns" [config]="gridConfig"
          formControlName="tasksControl" ngDefaultControl>

          <sib-grid-column field="srNo">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <span>
                {{rowIndex+1}}
              </span>
            </ng-template>
          </sib-grid-column>
          <sib-grid-column field="displayName">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <a class="link-1  after" target="_blank" *ngIf="rowData.displayName; else defaultTemplate"
                [routerLink]="['/campaigns/view/', rowData.campaignId]">{{rowData.displayName}}</a>
              <ng-template #defaultTemplate>
                -
              </ng-template>
            </ng-template>
          </sib-grid-column>

          <sib-grid-column field="extendDays">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <div>
                <span class="padding-right-5">
                  <button type="submit" pButton class="mtab-save" label="Extend" (click)="openExtendDateDialog(rowData)"
                    style="padding: 3px 8px"></button>
                </span>
              </div>
            </ng-template>
          </sib-grid-column>
          <sib-grid-column field="hiddenItem">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <div>
                <span class="padding-right-5">
                  <i class="fa fa-eye-slash" style="font-size: 18px;" title="Hide Site" *ngIf="!showHiddenItems"
                    (click)="hideItem(rowData)"></i>
                </span>
              </div>
            </ng-template>
          </sib-grid-column>
        </sib-grid>
      </div>
      <div *ngIf="showHiddenItems">
        <sib-grid name="taskGrid" #sibGrid [showFilter]="true" [showColorLegends]="false" [totalRecords]="totalRecords"
          (eEmitColumnSelection)="updatingUserGridConfig($event)" [columns]="taskColumns" [config]="hiddenGridConfig"
          formControlName="tasksControl" ngDefaultControl>

          <sib-grid-column field="srNo">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <span>
                {{rowIndex+1}}
              </span>
            </ng-template>
          </sib-grid-column>
          <sib-grid-column field="displayName">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <a class="link-1  after" target="_blank" *ngIf="rowData.displayName; else defaultTemplate"
                [routerLink]="['/campaigns/view/', rowData.campaignId]">{{rowData.displayName}}</a>
              <ng-template #defaultTemplate>
                -
              </ng-template>
            </ng-template>
          </sib-grid-column>

          <sib-grid-column field="extendDays">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <div>
                <span class="padding-right-5">
                  <button type="submit" pButton class="mtab-save" label="Extend" (click)="openExtendDateDialog(rowData)"
                    style="padding: 3px 8px"></button>
                </span>
              </div>
            </ng-template>
          </sib-grid-column>
          <sib-grid-column field="hiddenItem">
            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
              <div>
                <span class="padding-right-5">
                  <i class=" fa fa-times-circle-o info-red" style="font-size: 18px;" title="Hide Site"
                    *ngIf="!showHiddenItems" (click)="hideItem(rowData)"></i>
                </span>
              </div>
            </ng-template>
          </sib-grid-column>
        </sib-grid>
      </div>

    </form>
  </div>
</div>


<p-dialog *ngIf="displayExtendDateDialog" header="Extend Site" [(visible)]="displayExtendDateDialog" [modal]="true"
  showEffect="fade" [width]="330" (onHide)="hideExtendDateDialog()" [closeOnEscape]="true" blockScroll="true"
  [autoAlign]="false">

  <div class="ui-g-12 ui-fluid " style="padding: 3px; margin-bottom: 18px;">
    <div class="ui-g-8 inputfield">
      <span>No of Days to be extended :</span>
    </div>
    <div class="ui-g-4 inputfield">
      <span class="md-inputfield">
        <input id="days" type="text" pInputText name="days" [(ngModel)]="days" required />
      </span>
    </div>
  </div>
  <div style="text-align:center">
    <br>
    <button type="button" pButton label="UPDATE" class="mtab-save margin-hori-5" (click)="extendDays()"
      [disabled]="!days || validate()"></button>

    <button type="button" pButton label="CANCEL" class="mtab-secondary" (click)="hideExtendDateDialog()"></button>
  </div>
</p-dialog>