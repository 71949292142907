import { Component, ViewEncapsulation, OnInit, AfterViewInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { GridConfig, GridColumn, EditableGridFieldTypes, SelectionMode, GridPaginationEvent, Alignment } from '../../../components/sib-forms/grid/grid.config';
import { Invoice } from '../../../modals/billings/invoice';
import { GridActionButtonConfig } from '../../../components/sib-forms/grid/grid-action-button.config';
import { StatusVisualizationNode } from '../../../modals/billings/status-visualization-node';
import { DateUtil } from '../../../helpers/date.util';
import { InvoiceStatus } from '../../constants/invoice-status-enum';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { OverlayPanel, SelectItem } from 'primeng/primeng';
import { PendingInvoiceService } from '../../../services/shared/pending-invoice.service';
import { FirebaseService } from '../../../services/shared/firebase.service';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { Router } from '@angular/router';
import { BillingUIService } from '../../../billings/services/billingUI.service';
import { GridNameEnum } from '../../constants/grid-name.enum';
import * as utils from '../../../helpers/utils';
import { map } from 'rxjs/operators';
import { SIBConstants } from '../../constants/SIBConstant';
import { InvoiceCharEnum } from '../../../modals/billings/invoice-char-enum';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { InvoiceTaskUIService } from './services/invoice-task-UI.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { PaginationData } from '../../../modals/paginationdata';
import * as fileUtils from '../../../helpers/file.util';
import { LoaderSubjects } from '../../../modals/loader-subjects/loader-subjects';
import { PPTLoader } from '../../../modals/loader-subjects/pptloader';
import { CommonService } from '../../../services/shared/common.service';
import { CustomerUiService } from '../../../customers/services/customer-ui.service';
import { InvoiceService } from '../../../services/shared/invoice.service';
import * as $ from 'jquery';
import { PrintConfig } from '../../../modals/billings/print-config';

@Component({
    selector: 'sib-superbill-tasks',
    templateUrl: './superbill-tasks.component.html',
    styleUrls: ['./superbill-tasks.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SuperbillTasksComponent implements OnInit, AfterViewInit, OnDestroy {

    _showEmployee: boolean;
    @Input() set showEmployee(showEmployee) {
        this._showEmployee = showEmployee;
        this.setColumns();
    }

    get showEmployee() {
        return this._showEmployee;
    }

    _customFilter: any;
    @Input() set customFilter(customFilter) {
        if (customFilter !== undefined && customFilter !== null) {
            // this.showJsonExport = (customFilter.tallyEntered === "F") ? true : false;
            this._customFilter = customFilter;
            this.customGridFilter = {}; // reset custom grid filter when task card is clicked
            this.setPaginationFilter();
        }
    }

    get customFilter() {
        return this._customFilter;
    }

    @Output() eEmitTotalPendingInvoiceRecords: EventEmitter<any> = new EventEmitter();

    @ViewChild('taskSiteGrid')
    taskGridViewChildRef: GridComponent<Invoice>;

    @ViewChild('printMenu')
    printOverlay: OverlayPanel;

    isLoading = false;
    rowData: any;
    rowIndex: number;
    taskTotalRecords = 0;
    taskGroup: FormGroup;
    taskGridConfig: GridConfig<Invoice> = new GridConfig<Invoice>();
    taskButtonConfig: GridActionButtonConfig;
    invoiceStatusDropdown: any[] = [];
    taskInvoices: Invoice[] = [];
    paginationFilter: any;
    // to check with whom the invoice is pending
    srPendingStatus = [];
    adminPendingStatus = [];
    accountantPendingStatus = [];
    flowCompleteWithApprovalStatus = [];
    flowCompleteWithRejectionStatus = [];
    // to check in which flow the invoice is present
    generateFlowStatus = [];
    editFlowStatus = [];
    cancelFlowStatus = [];
    // to set the values of visualization chars and titles
    pendingAt: any[] = [];
    charAt: any[] = [];
    charTitleAt: any[] = [];
    rowsPerPage: number;
    statusVisualizationNodes: StatusVisualizationNode[] = [];
    printDropdown: SelectItem[] = [];
    tallyEntryDropdown: SelectItem[] = [];
    customGridFilterApplied = false; // for dropdown filters
    customGridFilter: any;
    invoicePrinted: any[] = [];
    invoicePrintedCheckboxVisibleStatus: any[] = [];
    invoiceTallyEntered: any[] = [];
    invoiceTallyEnteredCheckboxVisibleStatus: any[] = [];
    safePdfUrl: SafeResourceUrl;
    safePdfName: string;
    currentDate = new Date();
    isDuplicate = false;
    isWithoutDetail = false; // for with/without detail switch
    isWithoutLetterHead = false; // for with/without letterhead switch
    showJsonExport = false;
    showXmlExport = false;
    showXlsExport = false;
    showTallyXlsExport = false;
    isFileDownloading: boolean = false;
    xlsLoader: PPTLoader = new PPTLoader();
    searchEvent: GridPaginationEvent;

    selectedInvoiceFormat: any;
    preview = false; // for printing
    userRole: string;

    taskColumns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        /* {
            field: 'id',
            name: 'id',
            header: 'Invoice',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        }, */

        {
            field: 'campaign.campaignId',
            name: 'campaign.campaignId',
            header: 'Campaign Id',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign && data.campaign.campaignId) {
                    return data.campaign.campaignId;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'invoiceId',
            name: 'invoiceId',
            header: 'Invoice',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        // {
        //     field: 'campaign.customer.company',
        //     name: 'campaign.customer.company',
        //     header: 'Company',
        //     required: true,
        //     width: '7vw',
        //     editable: false,
        //     default: false,
        //     hidden: false,
        //     permanent: false,
        //     sortable: true,
        //     styleClass: 'word-break-wrap ',
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false,
        //     },
        //     displayFn: (data) => {
        //         if (data.campaign.customer.company) {
        //             return data.campaign.customer.company;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        {
            field: 'campaign.customer.billingName',
            name: 'campaign.customer.billingName',
            header: 'Billing Name',
            required: true,
            width: '7vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign.customer.billingName) {
                    return data.campaign.customer.billingName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaign.displayName',
            name: 'campaign.displayName',
            header: 'Display Name',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign.displayName) {
                    return data.campaign.displayName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaign.employee.fullName',
            name: 'employee.fullName',
            header: 'Employee',
            required: true,
            width: '7vw',
            editable: false,
            default: false,
            // hidden: !this.showEmployee,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.campaign.employee) {
                    return data.campaign.employee.fullName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billStartDate',
            name: 'billStartDate',
            header: 'Bill Start Date',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.billStartDate) {
                    return DateUtil.dategridFormatter(data.billStartDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billEndDate',
            name: 'billEndDate',
            header: 'Bill End Date',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.billEndDate) {
                    return DateUtil.dategridFormatter(data.billEndDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'billGeneratedDate',
            name: 'billGeneratedDate',
            header: 'Bill Generated Date',
            required: true,
            width: '8vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data.billGeneratedDate) {
                    return DateUtil.dategridFormatter(data.billGeneratedDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'status',
            name: 'status',
            header: 'Status',
            required: true,
            width: '12vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            displayFn: (data) => {
                if (data.status) {
                    return InvoiceStatus[data.status];
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'rentAmount',
            name: 'rentAmount',
            header: 'Rent Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.rentAmount !== null) {
                    return this.currencyPipe.transform(this.getDecimalNum(data.rentAmount, 2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'printAmount',
            name: 'printAmount',
            header: 'Print Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.printAmount !== null) {
                    return this.currencyPipe.transform(this.getDecimalNum(data.printAmount, 2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'mountAmount',
            name: 'mountAmount',
            header: 'Mount Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.mountAmount !== null) {
                    return this.currencyPipe.transform(this.getDecimalNum(data.mountAmount, 2));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'totalAmount',
            name: 'totalAmount',
            header: 'Bill Amount',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.totalAmount !== null) {
                    return this.currencyPipe.transform(utils.getIntegerRoundedOffValue(data.totalAmount));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'lspDiff',
            name: 'lspDiff',
            header: 'LSP Diff',
            required: true,
            width: '6vw',
            editable: false,
            default: false,
            hidden: false,
            permanent: false,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.lspDiff !== null) {
                    return this.currencyPipe.transform(data.lspDiff);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'campaignDiff',
            name: 'campaignDiff',
            header: 'Difference',
            required: true,
            width: '7vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            // filter: {
            //     matchMode: 'relational',
            //     placeHolder: 'Search',
            //     custom: false,
            // },
            displayFn: (data) => {
                if (data.campaignDiff !== null) {
                    return this.currencyPipe.transform(data.campaignDiff);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'printed',
            name: 'printed',
            header: 'Printed',
            required: true,
            width: '5vw',
            editable: false,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
            displayFn: (data) => {
                if (data.printed !== null && data.printed !== undefined) {
                    return data.printed;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'markAsPrinted',
            name: 'markAsPrinted',
            header: 'Mark Print',
            required: true,
            width: '5vw',
            editable: false,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
        },
        {
            field: 'printInvoice',
            name: 'printInvoice',
            header: 'Print',
            required: true,
            width: '4vw',
            editable: false,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
        },
        {
            field: 'tallyEntered',
            name: 'tallyEntered',
            header: 'Tally Entry',
            required: true,
            width: '5vw',
            editable: false,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
            displayFn: (data) => {
                if (data.tallyEntered !== null && data.tallyEntered !== undefined) {
                    return data.tallyEntered;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'markAsTallyEntered',
            name: 'markAsTallyEntered',
            header: 'Mark Tally',
            required: true,
            width: '5vw',
            editable: false,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true,
            },
        },
        {
            field: 'purchaseOrders',
            name: 'purchaseOrders',
            header: 'PO',
            required: true,
            width: '4vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM
        },
    ];

    @ViewChild('taskSiteGrid')
    taskSiteGridViewChildRef: GridComponent<Invoice>;

    @ViewChild('op')
    overlay: OverlayPanel;

    constructor(
        private pendingInvoiceService: PendingInvoiceService,
        private firebaseService: FirebaseService,
        private currencyPipe: CurrencyHelperPipe,
        private userGridConfigService: UserGridConfigService,
        private router: Router,
        private billingUIService: BillingUIService,
        private notificationServcie: NotificatoinsService,
        private invoiceTaskUIService: InvoiceTaskUIService,
        private sanitizer: DomSanitizer,
        private loaderSubjects: LoaderSubjects,
        private commonService: CommonService,
        private customerUiService: CustomerUiService,
        private invoiceService: InvoiceService,
    ) { }

    ngOnInit() {
        this.currentDate = new Date();
        this.userRole = this.customerUiService.getUserRole();
        this.getUserGridConfig();
        this.setFilterDropdowns();
        this.setFlows();
        this.setFormGroupObjects();
        this.setGridConfigObject();
        this.setRoles();
    }

    ngAfterViewInit() {
        // this.setPaginationFilter();
    }

    setFormGroupObjects() {
        this.taskGroup = new FormGroup({
            taskGridControl: new FormControl()
        });
    }

    setGridConfigObject() {
        this.taskGridConfig.getDefaultModel = () => new Invoice();
        this.taskGridConfig.model = Invoice;
        this.taskGridConfig.selectionMode = SelectionMode.Multi;
        this.taskGridConfig.editable = true;
        this.taskGridConfig.expandableRows = false;
        this.taskGridConfig.checkboxSelection = false;
        this.taskGridConfig.resizableColumns = false;
        // this.taskGridConfig.rowExpandMode = 'single';
        this.taskGridConfig.shouldAddRowOnDelete = false;
        this.taskGridConfig.showColorCode = true;
        this.taskGridConfig.lazy = true;
        this.taskGridConfig.enablePagination = true;
        this.taskGridConfig.scrollable = true;
        this.taskGridConfig.showColorCode = true;

        this.taskGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            const paginationData: PaginationData = {
                sortField: 'billGeneratedDate',
                sortOrder: -1
            }
            this.setPaginationEvent(paginationEvent, paginationData);
            return this.pendingInvoiceService.create(paginationEvent, paginationEvent).pipe(
                map((response) => {
                    this.searchEvent = paginationEvent;
                    this.taskInvoices = response['data']['content'];
                    this.setInvoice();
                    // this.setInvoicePrinted();
                    // this.setInvoiceTallyEntered();
                    // this.setPendingAt();
                    // this.setCharAt();
                    this.setStatusVisualizationNode();
                    this.taskTotalRecords = response['data'].totalElements;
                    this.eEmitTotalPendingInvoiceRecords.emit(this.taskTotalRecords);
                    this.rowsPerPage = response["data"].size;
                    this.showJsonExport = (this.customFilter.tallyEntered === "F") ? true : false;
                    this.showXmlExport = (this.customFilter.tallyEntered === "F") ? true : false;
                    this.showXlsExport = (this.customFilter.tallyEntered === "F") ? true : false;
                    this.showTallyXlsExport = (this.customFilter.tallyEntered === "F") ? true : false;
                    return response['data']['content'];
                }));
        };
    }

    setInvoice() {
        this.invoicePrinted = [];
        this.invoiceTallyEntered = [];
        this.pendingAt = [];
        this.charAt = [];
        this.charTitleAt = [];
        this.taskInvoices.forEach((inv, index) => {
            this.setInvoicePrinted(inv);
            this.setInvoiceTallyEntered(inv);
            this.setPendingAt(inv, index);
            this.setCharAt(inv, index);
        });
    }

    getUserGridConfig() {
        this.userGridConfigService.get(null, '/' + GridNameEnum['INVOICE'])
            .subscribe((invoiceListGridConfig) => {
                this.taskColumns = utils.getUserConfiguredColumns(invoiceListGridConfig, this.taskColumns);
                this.taskSiteGridViewChildRef.setAllColumns();
            });
    }

    setInvoicePrinted(inv) {
        // this.invoicePrinted = [];
        // this.taskInvoices.forEach((inv) => {
        (InvoiceCharEnum[inv.printed] === InvoiceCharEnum.F || InvoiceCharEnum[inv.printed] === InvoiceCharEnum.N) ? this.invoicePrinted.push(false) : this.invoicePrinted.push(true);
        // });
    }

    setInvoiceTallyEntered(inv) {
        // this.invoiceTallyEntered = [];
        // this.taskInvoices.forEach((inv) => {
        (InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.F || InvoiceCharEnum[inv.tallyEntered] === InvoiceCharEnum.N) ? this.invoiceTallyEntered.push(false) : this.invoiceTallyEntered.push(true);
        // });
    }

    setFilterDropdowns() {
        this.setPrintDropdown();
        this.setTallyEntryDropdown();
        this.setInvoiceStatusDropdown();
    }

    setPrintDropdown() {
        this.printDropdown = [
            { label: "All", value: "ALL" },
            { label: 'True', value: true },
            { label: 'False', value: false },
        ];
    }

    onPrintedFilterChange(value, field, matchMode) {
        this.setFilterValues(value, field, matchMode);
    }

    setTallyEntryDropdown() {
        this.tallyEntryDropdown = [
            { label: "All", value: "ALL" },
            { label: 'True', value: true },
            { label: 'False', value: false },
        ];
    }

    onTallyEnteredFilterChange(value, field, matchMode) {
        this.setFilterValues(value, field, matchMode);
    }

    setFilterValues(value, field, matchMode) {
        this.customGridFilterApplied = true;
        switch (value) {
            case 'ALL': {
                value = ["ALL"];
                matchMode = 'in';
                break;
            }
            case true: {
                value = ['T'];
                matchMode = 'in';
                break;
            }
            case false: {
                value = ['N', 'F'];
                matchMode = 'in';
                break;
            }
        }
        this.setCustomGridFilter(value, field, matchMode);
    }

    setCustomGridFilter(value, field, matchMode) {
        switch (field) {
            case "printed": {
                this.customGridFilter = { "printed": { value: value, matchMode: matchMode } };
                break;
            }
            case "tallyEntered": {
                this.customGridFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
                break;
            }
            case "markAsPrinted": {
                this.customGridFilter = { "printed": { value: value, matchMode: matchMode } };
                break;
            }
            case "markAsTallyEntered": {
                this.customGridFilter = { "tallyEntered": { value: value, matchMode: matchMode } };
            }
        }
        // this.taskGridViewChildRef.refresh();
        this.setPaginationFilter();
    }

    setInvoiceStatusDropdown() {
        this.invoiceStatusDropdown = utils.createDropdown(InvoiceStatus, true);
        // this.invoiceStatusDropdown = utils.createDropdown(InvoiceStatusEnum, true);
    }

    setFlows() {
        this.generateFlowStatus = ['ADMIN_GEN_PENDING', 'ACCOUNTANT_GEN_PENDING', 'ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_GEN_APPROVE'];
        this.editFlowStatus = ['EDIT_PENDING', 'ADMIN_EDIT_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_EDIT_APPROVE'];
        this.cancelFlowStatus = ['ADMIN_CANCEL_PENDING', 'ACCOUNTANT_CANCEL_PENDING', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'ADMIN_FULL_CN_PENDING', 'PARTIAL_CN_APPLIED', 'FULL_CN_REJECTED', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPROVED'];
        this.srPendingStatus = ['EDIT_PENDING'];
        this.adminPendingStatus = ['ADMIN_GEN_PENDING', 'ADMIN_EDIT_PENDING', 'ADMIN_CANCEL_PENDING', 'ADMIN_FULL_CN_PENDING'];
        this.accountantPendingStatus = ['ACCOUNTANT_GEN_PENDING', 'ACCOUNTANT_EDIT_PENDING', 'ACCOUNTANT_CANCEL_PENDING'];
        this.flowCompleteWithApprovalStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_EDIT_APPROVE', 'BILL_GENERATED', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION', 'FULL_CN_APPROVED', 'PARTIAL_CN_APPLIED', 'PARTIAL_CN_APPROVED'];
        this.flowCompleteWithRejectionStatus = ['ADMIN_GEN_REJECT', 'ACCOUNTANT_GEN_REJECT', 'ADMIN_EDIT_REJECT', 'ACCOUNTANT_EDIT_REJECT', 'ADMIN_CANCEL_REJECT', 'ACCOUNTANT_CANCEL_REJECT', 'FULL_CN_REJECTED'];
        this.invoicePrintedCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
        this.invoiceTallyEnteredCheckboxVisibleStatus = ['ADMIN_GEN_APPROVE', 'ADMIN_GEN_REJECT', 'ADMIN_EDIT_APPROVE', 'ADMIN_EDIT_REJECT', 'BILL_GENERATED', 'ADMIN_CANCEL_REJECT', 'INVOICE_CANCEL', 'INVOICE_CANCEL_WITH_RETENTION'];
    }

    setRoles() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const roles = userInfo[0].app_metadata.authorization.roles;
        const permissions = userInfo[0].app_metadata.authorization.permissions;
    }

    setColumns() {
        this.taskColumns.filter((col) => {
            switch (col.field) {
                case 'campaign.employee.fullName': {
                    col.permanent = !this.showEmployee;
                    col.hidden = !this.showEmployee;
                    break;
                }
                case 'printed':
                case 'markAsPrinted':
                case 'printInvoice': {
                    col.hidden = (this.customFilter && this.customFilter.printed === "F") ? false : true;
                    col.default = true;
                    col.permanent = (col.hidden) ? true : false;
                    break;
                }
                case 'tallyEntered':
                case 'markAsTallyEntered': {
                    col.hidden = (this.customFilter && this.customFilter.tallyEntered === "F") ? false : true;
                    col.default = true;
                    col.permanent = (col.hidden) ? true : false;
                    break;
                }
            }
            return col;
        });
        this.taskGridViewChildRef.setAllColumns();
    }

    setPaginationFilter() {
        this.setColumns();
        this.paginationFilter = {};
        if (this.customFilter.filters.length > 0) {
            this.paginationFilter = { 'status': { value: this._customFilter.filters, matchMode: 'in' } };
        } else if (this.customFilter.printed === "F") {
            this.paginationFilter = { "printed": { value: [this._customFilter.printed], matchMode: 'in' } };
        } else if (this.customFilter.tallyEntered === "F") {
            this.paginationFilter = { 'tallyEntered': { value: [this._customFilter.tallyEntered], matchMode: 'in' } };
        }
        if (this.customGridFilterApplied) {
            Object.assign(this.paginationFilter, this.customGridFilter);
        }
        this.refreshGrid();
    }

    setPaginationEvent(paginationEvent, paginationData) {
        if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
            paginationEvent = Object.assign(paginationEvent, paginationData)
        }

        if (paginationEvent.filters === undefined) {
            paginationEvent.filters = {};
        }
        delete paginationEvent.filters.status;
        delete paginationEvent.filters.tallyEntered;
        delete paginationEvent.filters.printed;
        if (this.customFilter !== undefined) {
            Object.assign(paginationEvent.filters, this.paginationFilter);
        } else { }
    }

    viewCampaign(rowData) {
        window.open('/campaigns/view/' + rowData.campaign.id);
    }

    setPendingAt(invoice, index) {
        // this.pendingAt = [];
        // this.taskInvoices.forEach((invoice, index) => {
        this.setBackground(invoice.status, index);
        // });
    }

    setBackground(status, index) {
        if (this.srPendingStatus.includes(status)) {
            this.pendingAt[index] = "SR";
        }
        if (this.adminPendingStatus.includes(status)) {
            this.pendingAt[index] = "ADM";
        }
        if (this.accountantPendingStatus.includes(status)) {
            this.pendingAt[index] = "ACC";
        }
        if (this.flowCompleteWithApprovalStatus.includes(status)) {
            this.pendingAt[index] = "APP";
        }
        if (this.flowCompleteWithRejectionStatus.includes(status)) {
            this.pendingAt[index] = "REJ";
        }
    }

    setCharAt(invoice, index) {
        // this.charAt = [];
        // this.charTitleAt = [];
        // this.taskInvoices.forEach((invoice, index) => {
        this.setChar(invoice.status, index);
        // });
    }

    setChar(status, index) {
        if (this.generateFlowStatus.includes(status)) {
            this.charAt[index] = "G";
            this.charTitleAt[index] = SIBConstants.STATUS_GEN_ICON_TOOLTIP;
        }
        if (this.editFlowStatus.includes(status)) {
            this.charAt[index] = "E";
            this.charTitleAt[index] = SIBConstants.STATUS_MOD_ICON_TOOLTIP;
        }
        if (this.cancelFlowStatus.includes(status)) {
            this.charAt[index] = "C";
            this.charTitleAt[index] = SIBConstants.STATUS_CAN_ICON_TOOLTIP;
        }
    }

    getClassForCharacter(index) {
        switch (this.charAt[index]) {
            case 'G': {
                return "generate";
            }
            case 'E': {
                return "edit";
            }
            case 'C': {
                return "cancel";
            }
        }
    }

    setStatusVisualizationNode() {
        this.statusVisualizationNodes = [
            { header: "SR", title: "Sales Representative" },
            { header: "ADM", title: "Admin" },
            { header: "CMP", title: " " }, // for APP or REJ
        ];
    }

    checkPrintedStatus(status) {
        if (this.invoicePrintedCheckboxVisibleStatus.includes(status)) {
            return true;
        } else {
            return false;
        }
    }

    checkTallyEnteredStatus(status) {
        if (this.invoiceTallyEnteredCheckboxVisibleStatus.includes(status)) {
            return true;
        } else {
            return false;
        }
    }

    onMarkAsPrintedChange(row, event) {
        this.isLoading = true;
        this.invoiceTaskUIService.markInvoiceAsPrinted((event) ? 'T' : 'F', row.id).subscribe(
            (response) => {
                if (response) {
                    event ? this.notificationServcie.success("Invoice Marked As Printed", "Invoice Marked As Printed Successfully") : this.notificationServcie.success("Invoice Unmarked As Printed", "Invoice Unmarked As Printed Successfully");
                    this.isLoading = false;
                    this.refreshGrid();
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }

    onMarkAsTallyEnteredChange(row, event) {
        this.isLoading = true;
        this.invoiceTaskUIService.markInvoiceAsTallyEntered((event) ? 'T' : 'F', row.id, row.id).subscribe(
            (response) => {
                if (response) {
                    event ? this.notificationServcie.success("Invoice Marked As Tally Entered", "Invoice Marked As Tally Entered Successfully") : this.notificationServcie.success("Invoice Unmarked As Tally Entered", "Invoice Unmarked As Tally Entered Successfully");
                    this.isLoading = false;
                    this.refreshGrid();
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                this.isLoading = false;
            }
        );
    }

    togglePrintMenu(event, rowData, rowIndex) {
        this.rowData = rowData;
        this.selectedInvoiceFormat = rowData.invoiceFormat;
        this.rowIndex = rowIndex;
        this.printOverlay.toggle(event);
    }

    setSelectedInvoiceFormat(event) {
        this.selectedInvoiceFormat = event;
        this.rowData.invoiceFormat = event;
    }

    onPrintTypeChange(event) {
        this.rowData.printConfiguration.original = event;
    }

    onPrintLetterHeadChange(event) {
        this.rowData.printConfiguration.withLetterHead = event;
    }

    savePrintingInstructions(printConfig: PrintConfig) {
        this.rowData.printConfiguration = printConfig;
        this.printOverlay.hide();
        this.commonService.saveBillingPrintConfig(this.rowData.printConfiguration, this.selectedInvoiceFormat, this.rowData.id, SIBConstants.INVOICE_PARAM)
            .subscribe(
                (response) => {
                    if (response) {
                        this.notificationServcie.success('Configuration Saved Successfully', 'Configuration Saved');
                    }
                },
                (error) => {
                    this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
                }
            );
    }

    previewPrintItems(printConfig: PrintConfig) {
        this.preview = true;
        this.printInvoice(printConfig);
    }

    printItems(printConfig: PrintConfig) {
        this.preview = false;
        this.printInvoice(printConfig);
    }

    printInvoice(printConfig: PrintConfig) {
        this.checkForAddBlock();
        if (this.userRole === 'Sales Rep') {
            printConfig.withLetterHead = true;
        }
        this.invoiceService.printInvoice(utils.createPrintWrapperObject(printConfig, this.selectedInvoiceFormat, this.preview, this.rowData.rowsOrder), this.rowData.id)
            .subscribe(
                (response) => {
                    if (response) {
                        const blob = new Blob([response], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        this.isLoading = false;
                        window.open(url, "_blank").focus(); // need to disable adblock for this
                    }
                },
                (error) => {
                    this.notificationServcie.error(error.message ? error.message : "Please refresh and retry", "Print Error");
                }
            );
    }

    checkForAddBlock() {
        if ($("#bottomAd").height() > 0) {
            console.log("ad block inctive");
        } else {
            console.log("ad block active");
            this.notificationServcie.info("Remove Adblocker to print", "Remove Adblocker");
        }
    }

    viewInvoiceDetails(rowData, event) {
        if (event.ctrlKey) {
            // do nothing
        } else {
            const id = rowData.id;
            const encId = encodeURIComponent(id);
            this.router.navigate(['billings/bills/view/' + encId]);
        }
    }

    getEncodedURI(id) {
        return encodeURIComponent(id);
    }

    viewPO(PO) {
        this.firebaseService.downloadImage(PO.name, PO.folder).then((response) => {
            window.open(response);
        });
    }

    showOverlay($event, rowData, rowIndex) {
        this.overlay.show($event);
        this.rowData = rowData;
        this.rowIndex = rowIndex;
    }

    printingItem(isDuplicate, isWithoutDetail, isWithoutLetterHead) {
        this.printOverlay.hide();
        if (!isWithoutDetail && !isWithoutLetterHead) {
            this.printBillsWithDetailsWithLetterhead(isDuplicate);
        } else if (!isWithoutDetail && isWithoutLetterHead) {
            this.printBillsWithDetailsWithoutLetterhead(isDuplicate);
        } else if (isWithoutDetail && !isWithoutLetterHead) {
            this.printBillsWithoutDetailsWithLetterhead(isDuplicate);
        } else {
            this.printBillsWithoutDetailsWithoutLetterhead(isDuplicate);
        }
    }

    printBillsWithDetailsWithLetterhead(isDuplicate) {
        this.isLoading = true;
        this.invoiceTaskUIService.printInvoiceWithDetailsWithLetterhead(this.rowData.id, isDuplicate).subscribe(
            (response) => {
                if (response) {
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
                    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.isLoading = false;
                    window.open(url, "_blank").focus(); // need to disable adblock for this
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    printBillsWithDetailsWithoutLetterhead(isDuplicate) {
        this.isLoading = true;
        this.invoiceTaskUIService.printInvoiceWithDetailsWithoutLetterhead(this.rowData.id, isDuplicate).subscribe(
            (response) => {
                if (response) {
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
                    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.isLoading = false;
                    window.open(url, "_blank").focus(); // need to disable adblock for this
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    printBillsWithoutDetailsWithLetterhead(isDuplicate) {
        this.isLoading = true;
        this.invoiceTaskUIService.printInvoiceWithoutDetailsWithLetterhead(this.rowData.id, isDuplicate).subscribe(
            (response) => {
                if (response) {
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
                    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.isLoading = false;
                    window.open(url, "_blank").focus(); // need to disable adblock for this
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    printBillsWithoutDetailsWithoutLetterhead(isDuplicate) {
        this.isLoading = true;
        this.invoiceTaskUIService.printInvoiceWithoutDetailsWithoutLetterhead(this.rowData.id, isDuplicate).subscribe(
            (response) => {
                if (response) {
                    const blob = new Blob([response], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    this.safePdfName = (this.rowData.id ? this.rowData.id : '') + '-' + DateUtil.dategridFormatter(new Date(this.currentDate)) + '.pdf';
                    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.isLoading = false;
                    window.open(url, "_blank").focus(); // need to disable adblock for this
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
                this.isLoading = false;
            }
        );
    }

    exportJson() {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.taskSiteGridViewChildRef.getDataTableInstance()["_value"]));
        const dlAnchorElem = document.createElement("a");
        dlAnchorElem.setAttribute("href", dataStr);
        dlAnchorElem.setAttribute("download", "tally_" + DateUtil.dategridFormatter(new Date()) + "_" + DateUtil.getTimeFromDate(new Date()) + ".json");
        dlAnchorElem.click();
    }

    exportXml() {
        const values = this.taskSiteGridViewChildRef.getDataTableInstance()["_value"];
        fileUtils.createZipFile(values, "tally", "invoiceId");
    }

    exportXlsx() {

    }

    exportTallyXls() {
        this.isFileDownloading = true;
        this.createLoaderObject("XLS", this.xlsLoader)
        this.loaderSubjects.invoiceTallyXlsLoader.next(this.xlsLoader);
        this.isFileDownloading = false;
    }

    createLoaderObject(fileType, object) {
        object.isLoading = true;
        object.text = 'Downloading XLS...';
        object.fileType = fileType;
        object.searchEvent = this.searchEvent;
    }

    updatingUserGridConfig(columnArray) {
        let invoiceListGridConfig: Map<string, string> = new Map();
        invoiceListGridConfig = utils.getGridConfigMap(columnArray, this.taskColumns);
        this.userGridConfigService.patch(utils.buildObject(invoiceListGridConfig), null, GridNameEnum['INVOICE'])
            .subscribe((result) => {
            });
    }

    /* Getting decimal point in Number datatype
   *  @param  {} source
   */
    getDecimalNum(target, decimal) {
        // return Number(target.toFixed(decimal));

        return Number(Math.fround(target).toFixed(decimal));
    }

    parseValueToDecimal(value) {
        return utils.parseValueToDecimalTwoPlaces(value);
    }

    getAbsoluteValue(value) {
        return Number(Math.abs(Number(value)));
    }

    checkPermission(permission) {
        return this.billingUIService.checkPermission(permission);
    }

    refreshGrid() {
        if (this.taskGridConfig.dataLoadFunction !== undefined) {
            this.taskSiteGridViewChildRef.refresh();
        }
    }

    ngOnDestroy() { }
}
