import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Params } from '@angular/router';
import { HoardingMapService } from '../../../services/shared/hoarding-map.service';
import { MapMarker } from '../../../modals/map/MapMarker';
import { MapMetadata } from '../../../modals/map/map-metadata';
import { HoardingLightEnum } from '../../../shared/constants/hoarding-light-enum';
import { HoardingStatusEnum } from '../../../shared/constants/hoarding-status-enum';
import { DateUtil } from '../../../helpers/date.util';
import { CampaignItem } from '../../../modals/campaigns/campaignItem';
import { CampaignService } from '../../../services/shared/campaign.service';
import { PublicPageService } from '../../../services/shared/publicpage.service';
import { CampaignItemStatusEnum } from '../../constants/campaign-item-status-enum';
import { MountingMaster } from '../../../modals/mounting/mounting-master';
import { MonitoringMaster } from '../../../modals/monitoring/monitoring-master';
import { UnmountingMaster } from '../../../modals/unmounting/unmounting-master';

@Component({
  selector: 'sib-hoarding-map',
  templateUrl: './hoarding-map.component.html',
  styleUrls: ['./hoarding-map.component.css']
})
export class HoardingMapComponent implements OnInit {

  id: string;
  tenantId: string;
  items: any[] = [];
  mapMarker: MapMarker = new MapMarker();
  overlays: any[] = [];
  options: any;
  showMap: boolean = false;
  mapMetadata: MapMetadata = new MapMetadata()
  mapFromHoarding: boolean = false;
  showNotification: boolean = true;
  campaignTrackerMap: boolean = false;
  campaignItems: CampaignItem[] = [];
  campaignMountingItems: MountingMaster[] = [];
  campaignMonitoringItems: MonitoringMaster[] = [];
  campaignUnmountingItems: UnmountingMaster[] = [];


  constructor(
    private route: ActivatedRoute,
    private hoardingMapService: HoardingMapService,
    private publicPageService: PublicPageService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: Params) => {
      if (params) {
        this.id = params.get('id');
        this.tenantId = params.get('tenantId')
        if (params.get('campaignTracker')) {
          this.campaignTrackerMap = true;

        }
        this.getItems()
      }
    })
  }

  getItems() {
    this.hoardingMapService.get({ x_tenant: this.tenantId }, `/public/map/${this.id}`)
      .subscribe((response) => {
        if (response.length > 0 && !this.campaignTrackerMap) {
          this.items = response;
          this.setMapMarker()
        } else {
          this.getCampaignItems();
        }
      })
  }

  getCampaignItems() {
    this.publicPageService.get(null, '/public/byCampaign/' + this.id + '?x_tenant=' + this.tenantId).subscribe((response) => {
      this.campaignItems = response['data']['campaignItems'];
      this.campaignMountingItems = response['data']['mountingItems'];
      this.campaignMonitoringItems = response['data']['monitoringItems'];
      this.campaignUnmountingItems = response['data']['unmountingItems'];
      this.setCampaignTrackerMapMarker();

    })
  }
  setMapMarker() {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].geoCoordinate.latitude && this.items[i].geoCoordinate.longitude) {
        this.mapMarker = new MapMarker()
        this.mapMarker.latitude = typeof (this.items[i].geoCoordinate.latitude) === 'string' ? parseFloat(this.items[i].geoCoordinate.latitude) : this.items[i].geoCoordinate.latitude;
        this.mapMarker.longitude = typeof (this.items[i].geoCoordinate.longitude) === 'string' ? parseFloat(this.items[i].geoCoordinate.longitude) : this.items[i].geoCoordinate.longitude;
        this.mapMarker.location = ''
        this.mapMarker.metadata = this.setMapMetadata(this.items[i]);
        this.overlays.push(this.mapMarker);
        this.options = {
          center: { lat: typeof (this.overlays[0].latitude) === 'string' ? parseFloat(this.overlays[0].latitude) : this.overlays[0].latitude, lng: typeof (this.overlays[0].longitude) === 'string' ? parseFloat(this.overlays[0].longitude) : this.overlays[0].longitude },
          zoom: 8
        };
      }
    }
    this.showMap = true;
  }

  setCampaignTrackerMapMarker() {
    this.campaignItems.forEach((item) => {
      if (item.geoCoordinate.latitude && item.geoCoordinate.longitude) {
        this.mapMarker = new MapMarker()
        this.mapMarker.latitude = typeof (item.geoCoordinate.latitude) === 'string' ? parseFloat(item.geoCoordinate.latitude) : item.geoCoordinate.latitude;
        this.mapMarker.longitude = typeof (item.geoCoordinate.longitude) === 'string' ? parseFloat(item.geoCoordinate.longitude) : item.geoCoordinate.longitude;
        this.mapMarker.location = ''
        this.mapMarker.metadata = this.setTrackerMapMetadata(item);
        this.overlays.push(this.mapMarker);
        this.options = {
          center: { lat: typeof (this.overlays[0].latitude) === 'string' ? parseFloat(this.overlays[0].latitude) : this.overlays[0].latitude, lng: typeof (this.overlays[0].longitude) === 'string' ? parseFloat(this.overlays[0].longitude) : this.overlays[0].longitude },
          zoom: 8
        };
      }
    })
    this.showMap = true;
  }

  setMapMetadata(item) {
    this.mapMetadata = new MapMetadata();
    this.mapMetadata.city = item.city;
    this.mapMetadata.customId = item.customId;
    this.mapMetadata.location = item.location;
    this.mapMetadata.lightType = HoardingLightEnum[item.lightType];
    this.mapMetadata.rate = item.rate;
    this.mapMetadata.size = item.size;
    this.mapMetadata.status = item.status;
    this.mapMetadata.availableFrom = item.campaignEndDate ? DateUtil.formatDateDDMMYY(new Date(item.campaignEndDate)) : null;
    this.mapMetadata.image = item.image ? item.image : null;
    return this.mapMetadata;
  }

  setTrackerMapMetadata(item) {
    this.mapMetadata = new MapMetadata();
    this.mapMetadata.customId = item.customId;
    this.mapMetadata.city = item.city;
    this.mapMetadata.location = item.location;
    this.mapMetadata.lightType = HoardingLightEnum[item.lightType];
    this.mapMetadata.size = item.size;
    this.mapMetadata.startDate = DateUtil.dategridFormatter(new Date(item.itemStartDate));
    this.mapMetadata.endDate = DateUtil.dategridFormatter(new Date(item.itemEndDate));
    this.mapMetadata.status = item.itemStatus;
    this.mapMetadata.mountingImage = [];
    this.mapMetadata.unmountingImage = []
    this.mapMetadata.monitoringImage = [];
    this.campaignMountingItems.forEach((site) => {
      if (site.id === item.id) {
        this.mapMetadata.mountingImage.push.apply(this.mapMetadata.mountingImage, site.mountingItems.images);
      }
    })


    this.campaignMonitoringItems.forEach((site) => {
      if (site.id === item.id) {
        this.mapMetadata.monitoringImage.push.apply(this.mapMetadata.monitoringImage, site.monitoringItems.images);
      }
    })
    this.campaignUnmountingItems.forEach((site) => {
      if (site.id === item.id) {
        this.mapMetadata.unmountingImage.push.apply(this.mapMetadata.unmountingImage, site.unmountingItems.images);
      }
    })

    this.mapMetadata.mountingImage = this.mapMetadata.mountingImage.length ? this.mapMetadata.mountingImage[0] : null;
    this.mapMetadata.monitoringImage = this.mapMetadata.monitoringImage.length ? this.mapMetadata.monitoringImage[0] : null;
    this.mapMetadata.unmountingImage = this.mapMetadata.unmountingImage.length ? this.mapMetadata.unmountingImage[0] : null;
    // this.mapMetadata.availableFrom = item.campaignEndDate ? DateUtil.formatDateDDMMYY(new Date(item.campaignEndDate)) : null;
    // this.mapMetadata.image = item.image ? item.image : null;
    return this.mapMetadata;
  }


}
