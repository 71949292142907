
import { map } from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit, Input, ViewChild, AfterViewInit } from "@angular/core";
import { GridColumn, GridConfig, EditableGridFieldTypes, SelectionMode, GridPaginationEvent } from "../../../../components/sib-forms/grid/grid.config";
import { ActivityLog } from "../../../../modals/activity/activity-log";
import { GridActionButtonConfig } from "../../../../components/sib-forms/grid/grid-action-button.config";
import { DateUtil } from "../../../../helpers/date.util";
import { GridComponent } from "../../../../components/sib-forms/grid/grid.component";
import { ActivityLoogService } from "../../../../services/shared/activity-logs.service";
import { TagEnum } from "../../../constants/tag-enum";
import { ModuleEnum } from "../../../constants/module-enum";
import * as utils from '../../../../helpers/utils';
import { ActivityLogModuleEnum } from '../../../constants/activity-log-module-enum';
import { ActionEventEnum } from '../../../constants/action-event-enum';
import * as activityActionEvent from '../../../constants/action-event-enum';
import { Change } from '../../../../modals/change/change';
import { UserGridConfigService } from '../../../../services/shared/user-grid-config.service';
import { GridNameEnum } from '../../../../shared/constants/grid-name.enum';

@Component({
    selector: 'sib-activity-grid',
    templateUrl: './activity-grid.component.html',
    styleUrls: ['./activity-grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ActivityGridComponent implements OnInit, AfterViewInit {


    selectedMod: any;
    moduleDropdown: any[] = [];
    actionEventDropdown: any[] = [];
    selectedAction: any;
    changes: Change[] = [];
    displayKey: string;

    _startDate: Date;
    @Input() set startDate(startDate) {
        this._startDate = startDate;
        this.ngAfterViewInit();
    }

    get startDate() {
        return new Date(this._startDate);
    }

    _endDate: Date;
    @Input() set endDate(endDate) {
        this._endDate = endDate;
        this.ngAfterViewInit();
    }

    get endDate() {
        return new Date(this._endDate);
    }

    @ViewChild(GridComponent)
    gridViewChildRef: GridComponent<ActivityLog>;

    globalFilterFields:string[] = ['Employee', 'Action', 'Module', 'Field Name', 'Old Value', 'New Value', 'Description',];

    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2vw',
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'user.fullName',
            name: 'user',
            header: 'Employee',
            required: true,
            editable: false,
            hidden: false,
            width: '3vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.user && data.user.fullName) {
                    return data.user.fullName;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'module',
            name: 'module',
            header: 'Module',
            required: true,
            editable: false,
            hidden: false,
            width: '3vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true
            },
            displayFn: (data) => {
                // if (data.collectionName) {
                //     return data.collectionName;
                if (data.module) {
                    return ActivityLogModuleEnum[data.module];
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'actionEvent',
            name: 'actionEvent',
            header: 'Action',
            required: true,
            editable: false,
            hidden: false,
            width: '15vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true
            },
            displayFn: (data) => {
                if (data.actionEvent) {
                    if (data.displayKey) {
                        return ActionEventEnum[data.actionEvent] + " - " + data.displayKey;
                    }
                    return ActionEventEnum[data.actionEvent];
                } else {
                    return '-';
                }
            },
        },
        // {
        //     field: 'displayKey',
        //     name: 'displayKey',
        //     header: 'Description',
        //     required: true,
        //     editable: false,
        //     hidden: false,
        //     width: '6vw',
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     permanent: true,
        //     sortable: true,
        //     type: EditableGridFieldTypes.CUSTOM,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        // },
        // {
        //     field: 'changes.fieldName',
        //     name: 'property',
        //     header: 'Property',
        //     required: true,
        //     editable: false,
        //     hidden: false,
        //     width: '6vw',
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     permanent: true,
        //     sortable: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        //     displayFn: (data) => {
        //         if (data && data.changes && data.changes.fieldName) {
        //             return data.changes.fieldName;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        // {
        //     field: 'changes.oldValue',
        //     name: 'oldValue',
        //     header: 'Old Value',
        //     required: true,
        //     editable: false,
        //     hidden: false,
        //     width: '6vw',
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     permanent: true,
        //     sortable: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        //     displayFn: (data) => {
        //         if (data && data.changes && data.changes.oldValue) {
        //             if (data.changes.fieldName.indexOf("date") !== -1 || data.changes.fieldName.indexOf("Date") !== -1) {
        //                 return DateUtil.dategridFormatter(data.changes.oldValue);
        //             } else {
        //                 return data.changes.oldValue;
        //             }
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        // {
        //     field: 'changes.newValue',
        //     name: 'newValue',
        //     header: 'New Value',
        //     required: true,
        //     editable: false,
        //     hidden: false,
        //     width: '6vw',
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     permanent: true,
        //     sortable: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        //     displayFn: (data) => {
        //         if (data && data.changes && data.changes.newValue) {
        //             if (data.changes.fieldName.indexOf("date") !== -1 || data.changes.fieldName.indexOf("Date") !== -1) {
        //                 return DateUtil.dategridFormatter(data.changes.newValue);
        //             } else {
        //                 return data.changes.newValue;
        //             }
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        // {
        //     field: 'user.emailId',
        //     name: 'user',
        //     header: 'EmailId',
        //     required: true,
        //     editable: false,
        //     hidden: false,
        //     width: '6vw',
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     permanent: false,
        //     sortable: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        //     displayFn: (data) => {
        //         if (data.user) {
        //             return data.user.emailId;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        // {
        //     field: 'changes.description',
        //     name: 'description',
        //     header: 'Activity',
        //     required: true,
        //     editable: false,
        //     hidden: false,
        //     width: '18vw',
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     permanent: false,
        //     sortable: true,
        //     type: EditableGridFieldTypes.CUSTOM,
        //     filter: {
        //         matchMode: 'contains',
        //         placeHolder: 'Search',
        //         custom: false
        //     },
        //     // displayFn: (data) => {
        //     //     if (data.changes) {
        //     //         return data.changes.description;
        //     //     } else {
        //     //         return '-';
        //     //     }
        //     // },
        // },
        {
            field: 'updatedDate',
            name: 'updatedDate',
            header: 'Date',
            required: true,
            editable: false,
            sortable: true,
            width: '2vw',
            displayFn: (data) => {
                if (data.updatedDate) {
                    return DateUtil.dategridFormatter(data.updatedDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'updatedTime',
            name: 'updatedTime',
            header: 'Time',
            required: true,
            editable: false,
            sortable: true,
            width: '2vw',
            displayFn: (data) => {
                if (data.updatedDate) {
                    return DateUtil.getTimeFromDate(data.updatedDate);
                } else {
                    return '-';
                }
            },
        },
    ];
    gridConfig: GridConfig<ActivityLog> = new GridConfig<ActivityLog>();
    buttonConfig: GridActionButtonConfig;
    activityLogs: ActivityLog[] = [];
    paginationFilter: any;
    totalRecords = 0;
    tag = "a";

    constructor(
        private activityLoogService: ActivityLoogService,
        private userGridConfigService: UserGridConfigService,
    ) { }

    ngOnInit() {
        this.userGridConfigService.get(null, '/' + GridNameEnum['ACTIVITY_GRID'])
            .subscribe((activityListGridConfig) => {
                this.columns = utils.getUserConfiguredColumns(activityListGridConfig, this.columns);
                this.gridViewChildRef.ngOnInit();
            });

        this.gridConfig.getDefaultModel = () => new ActivityLog();
        this.gridConfig.model = ActivityLog;
        this.gridConfig.editable = false;
        this.gridConfig.selectionMode = SelectionMode.None;
        this.gridConfig.expandableRows = true;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.rowExpandMode = 'single';
        this.gridConfig.lazy = true;
        this.gridConfig.enablePagination = true;
        this.gridConfig.showColorCode = false;
        this.gridConfig.showGlobalFilter = true;
        this.gridConfig.scrollable = true;
        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
            this.setPaginationEvent(paginationEvent);
            return this.activityLoogService.create(paginationEvent, paginationEvent).pipe(
                map((response) => {
                    this.activityLogs = response['content'];
                    this.totalRecords = response.totalElements;
                    return response['content'];
                }));
        };
        this.moduleDropdown = utils.createDropdown(ActivityLogModuleEnum, true);
        // this.actionEventDropdown = utils.createDropdown(ActionEventEnum, true);
        this.setDefaultModuleAndAction();
    }

    setDefaultModuleAndAction() {
        this.selectedMod = this.moduleDropdown[0].value;
        this.actionEventDropdown = activityActionEvent.createModuleToActivityEnumDropdown(this.selectedMod);
        this.selectedAction = this.actionEventDropdown[0].value;
        this.ngAfterViewInit();
    }

    ngAfterViewInit() {
        this.paginationFilter = { 'createdDate': { value: [new Date(this._startDate), new Date(this._endDate)], matchMode: 'dateBetween' }, 'module': { value: this.selectedMod, matchMode: 'is' }, 'actionEvent': { value: this.selectedAction, matchMode: 'is' } };
        if (this.gridConfig.dataLoadFunction) {
            this.gridViewChildRef.refresh();
        }
    }

    onModuleChange() {
        // this.ngAfterViewInit();
        this.actionEventDropdown = activityActionEvent.createModuleToActivityEnumDropdown(this.selectedMod);
        this.selectedAction = this.actionEventDropdown[0].value;
        this.ngAfterViewInit();
    }

    onActionEventChange() {
        this.ngAfterViewInit();
    }

    setPaginationEvent(paginationEvent) {
        if (paginationEvent.filters === undefined) {
            paginationEvent.filters = {};
        }
        Object.assign(paginationEvent.filters, this.paginationFilter);
    }

    onlinkclick() {
        window.alert('link clicked');
    }

    getTagValue(tag) {
        return TagEnum[tag];
    }

    refreshGrid() {
        this.gridViewChildRef.refresh();
    }

    openDescription(rowData) {
        // console.log("change", change);
        // const rowData = this.activityLogs[index];
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.CAMPAIGN_ITEM) {
            window.open('/campaigns/view/' + rowData.primaryKey);
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.CAMPAIGN) {
            window.open('/campaigns/view/' + rowData.primaryKey);
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.PLAN) {
            window.open('/plans/view/' + rowData.primaryKey);
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.HOARDING || ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.HOARDING_GROUP) {
            window.open('/hoardings');
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.CUSTOMER) {
            window.open('/customers');
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.GROUP) {
            window.open('/customers/customergroups');
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.POINT_OF_CONTACT) {
            window.open('/customers/pointofcontacts/');
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.CONTRACT) {
            window.open('/contracts/' + rowData.primaryKey);
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.AREA) {
            window.open('/masters/area');
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.INVOICE) {
            window.open('/billings/bills/view/' + encodeURIComponent(rowData.primaryKey));
        }
        if (ActivityLogModuleEnum[rowData.module] === ActivityLogModuleEnum.PURCHASE_ORDER) {
            window.open('/campaigns/view/' + rowData.primaryKey);
        }
    }

    onRowExpand(event) {
        this.changes = event.data.changes;
        this.displayKey = event.data.displayKey;
    }

    getActionEvent(value) {
        return ActionEventEnum[value];
    }

    formatValueBasedOnType(value, formatterType) {
        return utils.formatValueBasedOnType(value, formatterType);
    }

    updatingUserGridConfig(columnArray) {
        let activityListGridConfig: Map<string, string> = new Map();
        activityListGridConfig = utils.getGridConfigMap(columnArray, this.columns);
        this.userGridConfigService.patch(utils.buildObject(activityListGridConfig), null, GridNameEnum['ACTIVITY_GRID'])
            .subscribe((result) => {
            });
    }

    getDescriptionForPlanUpdate(rowData, change) {
        if (rowData.module === 'PLAN' && rowData.actionEvent === 'UPDATE') {
            return change.description
        } else {
            return ''
        }
    }

    getAdditionalDescription(rowData) {
        if (rowData.actionEvent !== 'UPDATE' && rowData.additoinalDesc) {
            return ` - ${rowData.additoinalDesc}`;
        }
    }
}
