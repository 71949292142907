<div class="activityLog">
    <div class="card padding-0">
        <div class="padding-0">
            <div class="list-container padding-top-5">
                <ul class="cinline-block list-margin">
                    <li class="padding-right-15">
                        <p-calendar #end [styleClass]="'calendar-button'" name="endDate" styleClass="calWidth" [disabled]="startDate === null" (onSelect)="onStartDateChange($event)"
                            [(ngModel)]="endDate" [utc]="true" showIcon="true" id="endDate" dateFormat="ddMy" appendTo="body"
                            required [maxDate]="currentDate" [minDate]="startDate"></p-calendar>
                    </li>
                    <li class="calendar-text">
                        <div>End Date</div>
                    </li>
                    <li>
                        <p-calendar #st [styleClass]="'calendar-button'" name="stDate" styleClass="calWidth" (onSelect)="onStartDateChange($event)"
                            [(ngModel)]="startDate" [utc]="true" showIcon="true" id="stDate" dateFormat="ddMy" appendTo="body"
                            required [maxDate]="currentDate"></p-calendar>
                    </li>
                    <li class="calendar-text">
                        <div>Start Date</div>
                    </li>
                    <!-- <li class="list-left margin-top-4 margin-right--5">
                        <i class="fa fa-time" aria-hidden="true"></i>
                        <i class="material-icons">access_time</i>
                    </li> -->
                    <!-- <li class="list-left margin-top-6 ">
                        <span>{{minutes}} :</span>
                        <span>{{seconds}}</span>
                    </li> -->
                    <li class="list-left">
                        <i class="fa fa-repeat refresh-button" (click)="refreshGrid()" pTooltip="Refresh"></i>
                    </li>
                </ul>
            </div>
        </div>
        <div class="padding-0 margin-top--46">
            <!-- [selectedModule]="selectedModule" -->
            <sib-activity-grid #activityGrid *ngIf="showGrid" [startDate]="startDate" [endDate]="endDate"></sib-activity-grid>
        </div>
    </div>
</div>