import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { State } from "./state";

export class District extends SibBaseObject {
    name: string;
    code: string;
    key: string;
    category: string = 'CUSTOM';
    state: State = new State();
}
