import { Component, OnInit, Input } from '@angular/core';
import * as utils from '../../../helpers/utils';
import { CampaignSummary } from '../../../modals/campaigns/campaignSummary';

@Component({
  selector: 'sib-campaign-summary-template',
  templateUrl: './campaign-summary-template.component.html',
  styleUrls: ['./campaign-summary-template.component.css']
})
export class CampaignSummaryTemplateComponent implements OnInit {

  @Input() summary: CampaignSummary = new CampaignSummary();
  @Input() summaryTitle: string;
  @Input() gstParameter: number;


  constructor() { }

  ngOnInit() {
  }

  getIntegerRoundedOffValue(value) {
    return utils.getIntegerRoundedOffValue(value);
  }

  getAbsoluteValue(value) {
    return Math.abs(value);
  }

  getDecimalNum(target, decimal) {
    return Number(Math.fround(target).toFixed(decimal));
  }




}
