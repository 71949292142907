import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { EditableGridFieldTypes, GridConfig, GridColumn, SelectionMode, GridPaginationEvent } from '../../components/sib-forms/grid/grid.config';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { UnmountingMaster } from '../../modals/unmounting/unmounting-master';
import { FormGroup, FormControl } from '@angular/forms';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { DateUtil } from '../../helpers/date.util';
import { of as observableOf, Subscription } from 'rxjs';
import * as _ from "lodash";
import * as utils from '../../helpers/utils';
import { CampaignService } from '../../services/shared/campaign.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PaginationData } from '../../modals/paginationdata';

@Component({
  selector: 'sib-unmountingsites',
  templateUrl: './unmountingsites.component.html',
  styleUrls: ['./unmountingsites.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnmountingsitesComponent implements OnInit {

  @ViewChild('sibGrid')
  gridViewChildRef: GridComponent<UnmountingMaster>;
  unmountingSitesGroup: FormGroup;
  gridConfig: GridConfig<UnmountingMaster> = new GridConfig<UnmountingMaster>();
  totalRecords: number;
  filteredItems: UnmountingMaster[] = [];
  filteredUnmountingItems: UnmountingMaster[] = [];
  _campaignUnmountingItems: UnmountingMaster[] = [];

  lightTypeDropdown: any[] = [];
  subscription: Subscription;

  @Input() set campaignUnmountingItems(campaignUnmountingItems) {
    this._campaignUnmountingItems = campaignUnmountingItems;
    this.filteredItems = _.cloneDeep(this._campaignUnmountingItems);
    this.gridViewChildRef.refresh();

  }

  get campaignUnmountingItems() {
    return this._campaignUnmountingItems;
  }

  @Output() eEmitTotalUnmountingItems: EventEmitter<any> = new EventEmitter();
  @Output() eEmitFilteredUnmountingItems: EventEmitter<UnmountingMaster[]> = new EventEmitter();
  @Output() eEmitImages: EventEmitter<any> = new EventEmitter();

  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    // sortField: '',
    // sortOrder: 1,
    multiSortMetas: []
  };

  columns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr ',
      required: true,
      width: '2vw',
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },

    {
      field: 'customId',
      name: 'HID',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'city',
      name: 'city',
      header: 'City',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '7vw',
      default: true,
      sortable: true,
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },

    {
      field: 'location',
      name: 'location',
      header: 'Location',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '15vw',
      default: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'size',
      name: 'size',
      header: 'Size',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'lightType',
      name: 'lightType',
      header: 'Light',
      required: true,
      editable: false,
      sortable: true,
      width: '3vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },

      displayFn: (data) => {
        if (data.lightType) {
          return HoardingLightEnum[data.lightType]
        } else {
          return '-';
        }
      }
    },
    {
      field: 'unmountingItems.creativeName',
      name: 'creativeName',
      header: 'Creative Name',
      required: true,
      editable: false,
      sortable: true,
      width: '9vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.unmountingItems.creativeName && data.unmountingItems.creativeName !== "Default") {
          return data.unmountingItems.creativeName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'itemEndDate',
      name: 'itemEndDate',
      header: 'Item End Date',
      required: true,
      editable: false,
      sortable: true,
      width: '6vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data) {
          if (new Date(data.unmountingItems.date).getTime() > new Date(data.itemEndDate).getTime()) {
            return DateUtil.dategridFormatter(data.itemEndDate);
          } else {
            return DateUtil.dategridFormatter(data.unmountingItems.date);
          }
        } else {
          return '-';
        }
      },
    },
    {
      field: 'unmountingItems.date',
      name: 'unmountingItems.date',
      header: 'Unmounting Date',
      required: true,
      editable: false,
      sortable: true,
      width: '7vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.unmountingItems.date && (data.unmountingItems.imageStatus === 'DONE' || data.unmountingItems.imageStatus === 'WITHOUT_IMAGE')) {
          return DateUtil.dategridFormatter(data.unmountingItems.date);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'unmountingItems.images',
      name: 'image',
      header: 'Photos',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '4vw',
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },

  ];

  constructor(private campaignService: CampaignService) {
    this.subscription = this.campaignService.$search
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.filteredItems = [];
        if (searchTerm) {
          searchTerm = searchTerm.toLowerCase();
          this.campaignUnmountingItems.forEach((item) => {
            if (item.customId.toLowerCase().includes(searchTerm) || item.district.toLowerCase().includes(searchTerm) || item.city.toLowerCase().includes(searchTerm) || item.location.toLowerCase().includes(searchTerm)) {
              this.filteredItems.push(item);
            }
          })
        } else {
          this.filteredItems = _.cloneDeep(this.campaignUnmountingItems);
        }
        this.filteredUnmountingItems = _.cloneDeep(this.filteredItems);
        this.eEmitTotalUnmountingItems.emit(this.filteredItems)
        this.gridViewChildRef.refresh();

      })
  }

  ngOnInit() {
    this.unmountingSitesGroup = new FormGroup({
      gridControl: new FormControl()
    });
    this.setLightDropdown();
    this.setGridConfigObject();
    this.gridViewChildRef.refresh();
  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new UnmountingMaster();
    this.gridConfig.model = UnmountingMaster;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    // this.gridConfig.rowExpandMode = 'single';
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = false;
    this.gridConfig.lazy = false;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
    this.gridConfig.sortMode = "multiple";
    this.gridConfig.multiSortMeta = this.createMetaSortData();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      this.totalRecords = this.filteredItems.length;

      return observableOf(this.filteredItems);
    }

  }

  onFilter(event) {
    this.filteredUnmountingItems = event;
    this.eEmitFilteredUnmountingItems.emit(event);
  }

  getCustomIds() {
    this.campaignUnmountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignUnmountingItems);
    const customIds: string[] = [];
    if (this.filteredUnmountingItems.length) {
      this.filteredUnmountingItems.forEach((item) => {
        customIds.push(item.customId);
      });
    } else {
      this.campaignUnmountingItems.forEach((item) => {
        customIds.push(item.customId);
      });
    }
    // console.log("cusotm ids", customIds);
    return customIds;
  }


  /**
   * gets the ids for unmounting items
   *
   * @returns
   * @memberof UnmountingsitesComponent
   */
  getIds() {
    this.campaignUnmountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignUnmountingItems);
    const ids: string[] = [];
    if (this.filteredUnmountingItems.length && this.filteredUnmountingItems.length !== this.campaignUnmountingItems.length) {
      this.filteredUnmountingItems.forEach((item) => {
        if (item.unmountingItems && item.unmountingItems.id) {
          ids.push(item.unmountingItems.id);
        }
      });
    } else {
      this.campaignUnmountingItems.forEach((item) => {
        if (item.unmountingItems && item.unmountingItems.id) {
          ids.push(item.unmountingItems.id);
        }
      });
    }
    return ids;
  }


  setLightDropdown() {
    this.lightTypeDropdown = utils.createDropdown(HoardingLightEnum, true);
  }

  createMetaSortData() {
    return [{ field: 'city', order: 1 }, { field: 'areaName', order: 1 }];
  }

  onSort(event) {
    if (this.filteredUnmountingItems && this.filteredUnmountingItems.length) {
      this.campaignUnmountingItems = _.cloneDeep(this.filteredUnmountingItems);
    } else {
      this.campaignUnmountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    }
    this.filteredUnmountingItems = this.campaignUnmountingItems;
    this.searchEvent.multiSortMetas = Object.assign(this.searchEvent.multiSortMetas, event['multisortmeta']);
  }

  getUnmountingItems() {
    this.campaignUnmountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    if (this.filteredUnmountingItems.length && this.filteredUnmountingItems.length !== this.campaignUnmountingItems.length) {
      return this.filteredUnmountingItems;
    } else {
      return this.campaignUnmountingItems;
    }
  }


  /**
   * emites images to public page component
   *
   * @param {*} rowData
   * @memberof MountedsitesComponent
   */
  viewImages(rowData) {
    this.eEmitImages.emit(rowData);
  }
}
