import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule, SharedModule, GalleriaModule, ProgressSpinnerModule, TooltipModule,  InputTextModule } from "primeng/primeng";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { DirectiveModules } from "../../../modules/directive.modules";
import { StatusVisualizationComponent } from "./status-visualization.component";



@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule,
        SibGridModule,
        GalleriaModule,
        InputTextModule,
        ProgressSpinnerModule,
        TooltipModule,
        DirectiveModules,
    ],

    declarations: [
        StatusVisualizationComponent
    ],

    exports: [
        StatusVisualizationComponent,
    ],
    providers: []
})

export class StatusVisualizationModule { }
