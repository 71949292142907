import { SystemService } from "./system.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class CustomerConfigurationService {
    emailVerfication: Subject<boolean> = new Subject();
    numberVerification: Subject<boolean> = new Subject();

    constructor(
        private systemService: SystemService
    ) { }

    getCustomerConfiguration() {
        this.systemService.get({ module: "CUSTOMER" }, '/search/by-module')
            .subscribe((configuration) => {
                const configurations = configuration;
                // ['_embedded']['systemProperties']
                this.setEmailConfigurationParameter(configurations);
                this.setNumberConfigurationParameter(configurations);
            })
    }


    setEmailConfigurationParameter(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'EMAIL_VERIFICATION') {
                this.emailVerfication.next(configurations[i]['value']);
            }
        };
    }

    setNumberConfigurationParameter(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'NUMBER_VERIFICATION') {
                this.numberVerification.next(configurations[i]['value']);
            }
        };
    }

}