import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { GridService } from './grid-service';
import { DateUtil } from '../../helpers/date.util';
import { map, catchError } from 'rxjs/operators';
import { empty as observableEmpty, of as observableOf, Observable } from 'rxjs';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from './notifications-messages';
import { NotificatoinsService } from '../notifications/notifications.service';

@Injectable()
export class GridUiService {

    moreMenu: MenuItem[] = [];
    progressValue = 0; // for progress bar
    subscription: Subscription[] = [];

    constructor(
        private gridService: GridService,
        private notificationServcie: NotificatoinsService
    ) {

    }

    exportGridXlsx(basePath, subPath, initialFileName) {

        const interval = setInterval(() => {
            this.progressValue = this.progressValue + Math.floor(Math.random() * 10) + 1;
            if (this.progressValue >= 100) {
                this.progressValue = 100;
                clearInterval(interval);
            }
        }, 290);

        this.subscription.push(this.gridService.exportGridXlsx(basePath, subPath)
            .subscribe((response: any) => {
                let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                const url = URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.setAttribute('style', 'display:none;');
                anchor.setAttribute('style', 'display:none;');
                document.body.appendChild(anchor);
                anchor.download = initialFileName + '-' + DateUtil.dategridFormatter(new Date()) + ".xlsx";
                anchor.href = url;
                anchor.click();
            },
                (error) => {

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                }));

    }
}