import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutoCompleteModule, ButtonModule, DataTableModule, InputTextModule, DropdownModule, DialogModule, ConfirmDialogModule } from "primeng/primeng";
import { FormsModule } from '@angular/forms';
import { SuggestionViewComponent } from './suggestion-view.component';
import { SuggestionService } from '../suggestion-dialog/suggestion.service';
import { GridComponent } from '../components/sib-forms/grid/grid.component';
import { GridColumnComponent } from '../components/sib-forms/grid/grid-column.component';
import { SibGridModule } from '../components/sib-forms/grid/grid.module';
import { DirectiveModules } from '../modules/directive.modules';
import { SuggestionDialogModule } from '../suggestion-dialog/suggestion-dialog.module';

@NgModule({
  declarations: [
    SuggestionViewComponent,
  ],

  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    InputTextModule,
    SibGridModule,
    ConfirmDialogModule,
    DirectiveModules,
    SuggestionDialogModule
  ],
  exports: [SuggestionViewComponent],

  providers: [SuggestionService],
})

export class SuggestionModule { }
