import { Injectable, Injector } from "@angular/core";
import * as utils from '../../helpers/utils';
import { BASE_URL } from '../../services/base-service/api.service';
import { AppUrls } from '../../services/urls';
import { HttpClient } from "@angular/common/http";
import { UserRoleEnum } from '../../shared/constants/user-role-enum';
import { UserService } from './user.service';
import { DashboardService } from '../../dashboard/service/dashboard.service';
import { catchError, map } from 'rxjs/operators';
import { NotificatoinsService } from '../notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class SalesChartService {

  baseUrl: string;

  constructor(
    private injector: Injector,
    private Http: HttpClient,
    private userService: UserService,
    private dashboardsService: DashboardService,
    private notificationServcie: NotificatoinsService,
  ) {
    this.baseUrl = this.injector.get(BASE_URL);
  }

  /**
   * @description get Sales data
   * @author Divya Sachan
   * @date 2019-10-10
   * @param {*} billingSearch
   * @returns
   * @memberof SalesChartService
   */
  getSalesChartData(billingSearch) {
    return this.dashboardsService.create(billingSearch, null, AppUrls.SALES_CHART);
  }

  /**
   * @description get Sales Data City wise
   * @author Divya Sachan
   * @date 2019-10-10
   * @param {*} billingSearch
   * @returns
   * @memberof SalesChartService
   */
  getCitySalesChartData(billingSearch) {
    return this.dashboardsService.create(billingSearch, null, AppUrls.CITY_SALES_CHART);
  }

  /**
   * @description get Rent Items and unbilled Items Total Count
   * @author Divya Sachan
   * @date 2019-10-10
   * @param {*} paginationEvent
   * @returns
   * @memberof SalesChartService
   */
  getItemCount(paginationEvent) {
    if (navigator.onLine) {
      return this.Http.post(this.baseUrl + AppUrls.DASHBOARDS + AppUrls.SALES_CHART + AppUrls.GET_ITEM_COUNT, paginationEvent).pipe(map((response: any) => {
        return response;
      }), catchError((err) => {
        this.notificationServcie.error("Error Fetching Items Count", "Error");
        return err;
      }));
    }

  }

  /**
   * @description Sales XLS export
   * @author Divya Sachan
   * @date 2019-10-10
   * @param {*} fileType
   * @param {*} data
   * @returns
   * @memberof SalesChartService
   */
  downloadSalesChartXls(fileType, data) {

    if (navigator.onLine) {
      let options = {};
      let fileUrl, invoiceType;
      if (fileType === 'XLSX') {
        if (data.billed === true && data.invoiceType) {

          invoiceType = data.invoiceType;
          fileUrl = this.baseUrl + AppUrls.DASHBOARDS + AppUrls.EXPORT_GRID + AppUrls.BILLED_ITEMS_XLS + '?invoiceType=' + invoiceType;
        } else if (data.billed === false) {
          fileUrl = this.baseUrl + AppUrls.DASHBOARDS + AppUrls.EXPORT_GRID + AppUrls.UNBILLED_ITEMS_XLS;
        }
        // 'pointOfContacts/exportGrid/XLSX'
        options = { responseType: "arraybuffer" };
        return this.Http.post(fileUrl, data.searchEvent, options).pipe(map((response: any) => {
          return response;
        })
          // , catchError((err) => {
          // if (err.status === 412 || err.error.code === 412) {
          //   this.notificationServcie.info(err.error.message ? err.error.message : "Please save columns from show columns and try again.", "XLSX Download Error");
          // } else {
          //   this.notificationServcie.error(err.error.message ? err.error.message : "XLSX Download Error", "Please refresh and retry");
          // }
          //   return err;
          // })
        );
      }
    }
  }
}
