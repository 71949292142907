<p-dialog header="Invoice Notes" [(visible)]="displayBillingNotes" modal="modal" showEffect="fade" width="600" (onHide)="hideDialog()"
    (onShow)="showDialog()" [styleClass]="'billing-notes-dialog'">

    <div *ngIf="isloading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div>

    <div *ngIf="invoice.billingNotes.length === 0">
        <h3 class="no-notes">No Notes present yet</h3>
    </div>

    <section class="timeline">
        <div class="container">
            <ng-template ngFor let-note [ngForOf]='invoice.billingNotes' let-i='index' let-even='even' let-odd='odd'>
                <ng-template [ngIf]="even">
                    <div class="timeline-item">
                        <div class="timeline-img"></div>

                        <div class="timeline-content js--fadeInLeft word-break-wrap">
                            <h2>{{note.name}}</h2>
                            <div class="date">{{note.date | date:'ddMMMyy'}}</div>
                            <p>
                                <i>{{note.message}}</i>
                            </p>
                            <p class="bnt-more" href="javascript:void(0)">{{note.emailId}}</p>
                        </div>

                    </div>
                </ng-template>



                <ng-template [ngIf]="odd">
                    <div class="timeline-item">
                        <div class="timeline-img"></div>

                        <div class="timeline-content timeline-card js--fadeInRight word-break-wrap">
                            <div class="timeline-img-header">
                                <h2>{{note.name}}</h2>
                            </div>
                            <div class="date">{{note.date | date:'ddMMMyy'}}</div>
                            <p>{{note.message}}</p>
                            <p class="bnt-more" href="javascript:void(0)">{{note.emailId}}</p>
                        </div>

                    </div>
                </ng-template>

            </ng-template>

        </div>


    </section>

</p-dialog>