<div class="invoice-summary">
    <div class="table-heading">
        Summary
    </div>
    <table class="c-tbl">
        <tr>
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Campaign Difference', value: invoice.campaignDiff !== null && invoice.campaignDiff !== undefined ? getCurrencyFormat(getAbsoluteValue(parseValueToDecimalTwoPlaces(invoice.campaignDiff))) : getCurrencyFormat(getAbsoluteValue(parseValueToDecimalTwoPlaces(campaignDifference))) , class: (campaignDifference < 0) ? 'negDiff' : 'posDiff', previousValue: getCurrencyFormat(getAbsoluteValue(parseValueToDecimalTwoPlaces(tempInvoice?.campaignDiff))), prevClass: (tempInvoice?.campaignDiff < 0) ? 'negDiff' : 'posDiff' }">
            </ng-container>
        </tr>
        <tr>
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'LSP Difference', value: getCurrencyFormat(getAbsoluteValue(getIntegerRoundedOffValue(lspDifference))) + '(' + getCurrencyFormat(getAbsoluteValue(getIntegerRoundedOffValue(lspDifferencePctg))) + '%)' , class: (lspDifference < 0) ? 'negDiff' : 'posDiff', previousValue: getCurrencyFormat(getAbsoluteValue(getIntegerRoundedOffValue(tempInvoice?.lspDiff))) + '(' + getCurrencyFormat(getAbsoluteValue(getIntegerRoundedOffValue(tempInvoiceLSPDifferencePctg))) + '%)', prevClass: (tempInvoice?.lspDiff < 0) ? 'negDiff' : 'posDiff' }">
            </ng-container>
        </tr>
        <tr *ngIf="invoice?.rentItems?.length > 0">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Rent Amount', value: getCurrencyFormat(parseValueToDecimalTwoPlaces(rentAmount)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(tempInvoice?.rentAmount)) }">
            </ng-container>
        </tr>
        <tr *ngIf="invoice?.printingItems?.length > 0">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Printing Amount', value: getCurrencyFormat(parseValueToDecimalTwoPlaces(printAmount)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(tempInvoice?.printAmount)) }">
            </ng-container>
        </tr>
        <tr *ngIf="invoice?.mountingItems?.length > 0">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Mounting Amount', value: getCurrencyFormat(parseValueToDecimalTwoPlaces(mountAmount)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(tempInvoice?.mountAmount)) }">
            </ng-container>
        </tr>
        <tr *ngIf="(invoice?.printingItems?.length > 0 || invoice?.mountingItems?.length > 0)">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'SubTotal' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(totalWithoutTax)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(tempInvoice?.totalAmount - tempInvoice?.taxAmount))  }">
            </ng-container>
        </tr>
        <!-- <tr
            *ngIf="!invoice?.invoiceGstDetail?.branchAddress?.state.name ? (invoice?.campaign?.customer?.state?.name ? invoice?.campaign?.customer?.state?.name === 'Gujarat' : true) : invoice?.invoiceGstDetail?.branchAddress?.state.name && (invoice?.invoiceGstDetail?.branchAddress?.state.name === 'Gujarat' || invoice?.invoiceGstDetail?.branchAddress?.state.name === '' || !invoice?.invoiceGstDetail)">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'CGST(' + cgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(cgstTax)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(getTaxValue(tempInvoice?.taxAmount, 'CGST'))) }">
            </ng-container>
        </tr>
        <tr
            *ngIf="!invoice?.invoiceGstDetail?.branchAddress?.state?.name ? (invoice?.campaign?.customer?.state?.name ? invoice?.campaign?.customer?.state?.name === 'Gujarat' : true) : invoice?.invoiceGstDetail?.branchAddress?.state.name && (invoice?.invoiceGstDetail?.branchAddress?.state.name === 'Gujarat' || invoice?.invoiceGstDetail?.branchAddress?.state.name === '')">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'SGST(' + sgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(sgstTax)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(getTaxValue(tempInvoice?.taxAmount, 'SGST'))) }">
            </ng-container>
        </tr>
        <tr
            *ngIf="!invoice?.invoiceGstDetail?.branchAddress?.state.name ? (invoice?.campaign?.customer?.state?.name && invoice?.campaign?.customer?.state?.name !== 'Gujarat') : invoice?.invoiceGstDetail?.branchAddress?.state.name && (invoice?.invoiceGstDetail?.branchAddress?.state.name !== 'Gujarat' && invoice?.invoiceGstDetail?.branchAddress?.state.name !== '')">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'IGST(' + igstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(igstTax)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(getTaxValue(tempInvoice?.taxAmount, 'IGST'))) }">
            </ng-container>
        </tr> -->
        <tr *ngIf="!isIgst">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'CGST(' + cgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(cgstTax)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(getTaxValue(tempInvoice?.taxAmount, 'CGST'))) }">
            </ng-container>
        </tr>
        <tr *ngIf="!isIgst">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'SGST(' + sgstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(sgstTax)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(getTaxValue(tempInvoice?.taxAmount, 'SGST'))) }">
            </ng-container>
        </tr>
        <tr *ngIf="isIgst">
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'IGST(' + igstParameter + '%)' , value: getCurrencyFormat(parseValueToDecimalTwoPlaces(igstTax)), previousValue: getCurrencyFormat(parseValueToDecimalTwoPlaces(getTaxValue(tempInvoice?.taxAmount, 'IGST'))) }">
            </ng-container>
        </tr>
        <tr>
            <ng-container
                *ngTemplateOutlet="summaryTemplate; context: {key: 'Total' , value: getCurrencyFormat(getIntegerRoundedOffValue(totalWithTax)), previousValue: getCurrencyFormat(getIntegerRoundedOffValue(tempInvoice?.totalAmount))  }">
            </ng-container>
        </tr>
    </table>


    <ng-template #summaryTemplate let-key='key' let-value='value' let-class='class' let-previousValue='previousValue'
        let-prevClass='prevClass'>
        <th>
            <div class="valuefont">{{key}}</div>
        </th>
        <td>
            <div class={{class}}>{{value}}</div>
            <div *ngIf="showChanges && value !== previousValue" class="change-data {{prevClass}}">{{previousValue}}
            </div>
        </td>
    </ng-template>
</div>