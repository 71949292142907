import { Injectable } from "@angular/core";

/**
 * Creating a static constant class for storing URLs
 * 
 * @export
 * @class AppUrls
 */
export class AppUrls {

    //--------------URLS---------------------------

    /**
     * Contract Module URLs
     * 
     * @static
     * @memberof AppUrls
     */
    static HOARDING_GROUP = '/hoardingGroups';
    static SAVE_HOARDING_GROUP = '/save';
    static UPDATE_HOARDING_GROUP = '/update';
    static GET_HOARDINGS_BY_CONTRACT_ID = '/search/byContractId';

    static SAVE_CONTRACT = '/save';
    static UPDATE_CONTRACT = '/update';

    static GET_HOARDING_STATUS_LIST = '/status';
    static GET_CAMPAIGN_HOARDINGS_BY_STATUS = '/search/itemStatus';
    static UPDATE_CAMPAIGN_HOARDING_STATUS = '/update/itemStatus';

    static GET_CAMPAIGN_BY_STATUS = '/search/status';
    static GET_CAMPAIGN_HOARDINS_BY_STATUS_CAMPAIGNID = '/search/campaignId-itemStatus'
    static SAVE_CUSTOMER = '/save';
    static UPDATE_CUSTOMER = '/update';

    static ASSIGN_MOUNTER = '/webAssign/mounter';
    static ASSIGN_UNMOUNTER = '/assign/unmounter';
    static PERFORM_MOUTING = '/update/mounting-item';
    // static UPDATE_MOUNTING_IMAGE = '/update/mounting-item';
    static UPDATE_MOUNTING_IMAGE = '/webPerform/mounting';

    // static UPLOAD_MONITORING_IMAGE = '/upload-image/monitoring-item';
    static UPLOAD_MONITORING_IMAGE = '/webPerform/monitoring';
    static REMOVE_MONITORING_ITEM = '/remove/monitoring-item';
    static UPDATE_MONITORING = '/update/monitoring-item ';
    // static UPDATE_UNMOUNTING_ITEM = '/update/unmounting-item';
    static UPDATE_UNMOUNTING_ITEM = '/webPerform/unmounting';
    static GET_RENT_ITEMS = '/rent-items';
    static GET_PRINTING_ITEMS = '/printing-items';
    static GET_MOUNTING_ITEMS = '/mounting-items';
    static GENERATE_INVOICE = '/generate-invoice';
    static GET_INVOICES = '/invoices/byCampaignId';

    static DOWNLOAD_XLSX = '/generateXLS';
    static DOWNLOAD_PPT = '/generatePPT';
    static CAMPAIGN_ITEMS = '/campaignItems';

    // Documents

    static GET_ALL_DOCS = '/search/images/purchaseOrders';

    // all

    static SEARCH = '/search';
    static ACTIVITY = '/activity';
    static SLASH = '/';
    static REMOVE = '/remove';
    static PUBLIC = 'public';
    static LOGO_DETAIL = '/getLogoDetails';

    // billing

    static ADJUSTMENT = '/adjustment';
    static GENERATE_ENABLED = '/generateEnabled';
    static DISTINCT_VERSIONS = '/distinctVersions';
    static VERSION = '/version';
    static REVERT_TO_PREVIOUS = '/revertToPrevious';
    static MARK = '/mark';
    static GROUPED = '/grouped';
    static GET_LAST_INVOICE_ID = '/getLastInvoiceId';
    static GET_LAST_CN_ID = '/getLastCNId';
    static FETCH = '/fetch';
    static RESERVED_INVOICE_METADATA = '/reservedInvoiceMetadata';
    static RESERVED_CN_METADATA = '/reservedCnMetadata';
    static RESERVE_INVOICE_IDS = '/reserveInvoiceIds';
    static RESERVE_CN_IDS = '/reserveCNIds';
    static MARK_UTILIZED_OR_NOT = '/markUtilizedOrNot';
    static FIND_ALL = '/find-all';
    static BY_ROLE = '/byRole';
    static PENDING_TO_GEN = '/pendingToGen';
    static UNBILLED = '/unbilled';
    static BY_CUSTOMER_ID = '/byCustomerId';
    static GET_PO_LIST = '/getPoList';
    static UPDATE = '/update';
    static DETAILS = '/details';
    static GET_DISPLAY_NAME = '/getDisplayName';
    static CREATE_PARTIAL_AMOUNT = '/partialCn/approve';
    static GET_SUM_PARTIAL_AMOUNT = '/getTotalCnAmount';
    static GET_CREDIT_NOTES = '/creditNotes';
    static REQUEST = '/request';
    static FULL_CN = '/fullCn';
    static BY_CAMPAIGN_ID = '/byCampaignId';
    static FIND_CUSTOMER_BY_INVOICE_ID = '/findCustomerByInvoiceId';
    static VALIDATE_INV_ID = '/validateInvoiceId';

    // cn

    static BY_ID = '/byId';

    // photo-interface

    static ALL = "/all";
    static ALL_WITHOUT_SLASH = "all";
    static MOUNT_ITEM = "/mountItem";
    static UNMOUNT_ITEM = "/unmountItem";
    static MONITORING_ITEM = "/monitoringItem";
    static IMAGES = "/images";
    static MOUNTING_ITEMS = "/mounting-items";
    static MONITORING_ITEMS = "/monitoring-items";
    static UNMOUNTING_ITEMS = "/unmounting-items";

    // user properties

    static MODULES = "/modules";
    static BY_MODULE = "/by-module";
    static BYMODULE = "/byModule";

    // sr territory

    static ASSIGN_SR_TERRITORY = '/assign/interestedTerritory';
    static FETCH_SR_TERRITORY = '/fetch/interestedTerritory';
    static REMOVE_SR_TERRITORY = '/remove/interestedTerritory';
    static REMOVE_ALL_SR_TERRITORY = '/removeAll/interestedTerritory';

    // tasks

    static TASK_CARDS = '/task-cards';
    static ADMIN_PENDING = '/adminPending';
    static PENDING = '/pending';
    static APPROVE = '/approve';
    static REJECT = '/reject';
    static MARK_PRINTED = '/mark/printed';
    static MARK_TALLY_ENTERED = '/mark/tallyEntered';
    static GENERATE_PDF = '/generatePDF';
    static WITH_DETAIL = '/withDetail';
    static WITHOUT_DETAIL = '/withoutDetail';
    static WITHOUT_LETTERHEAD = '/withoutLetterHead';
    static WITH_LETTERHEAD = '/withLetterHead';
    static INVOICES = 'invoices';
    static XLSX = '/xlsx';
    static SR = '/sr';
    static CI_EXTEND = '/perform/ci-extend';
    static PENDING_TASKS = '/pending-taskCards';
    static CREDIT_NOTES_TASKS = '/search/task';
    static EXPIRED_IN = '/expiredIn';
    static COUNT = '/count';
    static PENDING_RATE = '/pendingRate/approvalTask';
    static MARK_HIDDEN = '/markHidden';

    // passbook

    static GET_USERS = '/getUsers';
    static PASSBOOK = 'passbook'
    static TRANSACTIONS = '/transactions';
    static GET_ALL = '/getAll'

    // activity log

    static SAVE = '/save';

    // suggestions
    static REMOVE_SUGGESTION = "/removeSuggestion";

    // sales chart
    static SALES_CHART = "/salesChart";
    static CITY_SALES_CHART = "/citySalesChart";
    static RENT_ITEMS = "/rentItems";
    static PRINT_ITEMS = "/printingItems";
    static MOUNT_ITEMS = "/mountingItems";
    static UNBILLED_ITEMS = "/unBilledItems";
    static GET_BILLED_ITEM_SUMMARY = '/getBilledItemSummary';
    static GET_ITEM_COUNT = '/getItemCount';

    // XLS
    static EXPORT_GRID = "/exportGrid";
    static BILLED_ITEMS_XLS = '/billedItems';
    static UNBILLED_ITEMS_XLS = '/unbilledItems';
    static XLS = "/XLSX";

    static DASHBOARDS = "dashboards";

    //upload Document
    static UPLOAD_DOCUMENT = "/uploadDocument";
    static EDIT_DOCUMENT = "/editDocument";
    static REMOVE_DOCUMENT = "/removeDocument";

    //invoice chart
    static SUMMARY_FINANCIAL_YEAR = "/summary/byFinancialYear";

    //miscellaneous
    static RESERVED = '/reserved';
    static RESERVE = 'reserve';
    static CREDIT_NOTES = 'creditNotes';
    static GET_GST_DETAIL = "/getGstDetail";
    // static BILLINGS = "/billings";
    static VIEW = "/view";
    static CAMPAIGNS = "/campaigns";
    static BILLS = "/bills";

    static SAVE_ROWS_ORDER = "/saveRowsOrder";

    // common
    static BILLINGS = '/billings';
    static PRINT_CONFIGURATION = '/printConfiguration';

    //roi
    static GETROIDATA = "/getRoiData";
    static GETROIITEMDATA = "/getRoiItemData";
    static GET_UNIQUE_ID = "/getUniqId";
    static ROI_DATA = "/ROIData";
    static ROI_ITEM_DATA = "/ROIItemData";
    static UNTAPPED_ROI_DATA = "/untappedROIData";

    static RESERVED_INVOICE = "reservedInvoice";
    static RESERVED_CN = "reservedcn"
    static DELETE = "/delete"


    //campaigns
    static NEW_SUMMARY = "/getNewSummary";
    static RATE = '/rate';

    static UPLOAD_PO = "/uploadPO";

    //case list
    static AUTH = '/oauth/token/client';
    static CASE_LIST = '/api/v1/cases';


    //plan
    static ROLLBACK_TO_PLAN = '/rollBack-to-plan';


}
