import { Component, OnInit, Injectable, Input } from '@angular/core';
import { DemoCarService } from '../services/demo-car.service';
import { Car } from '../modals/car';
import { DataListHook } from '../modals/hooks/data-list-hook';
import { PaginatorHook } from '../modals/hooks/paginator-hook';

@Component({
  selector: 'sib-list-pagination-search',
  templateUrl: './list-pagination-search.component.html',
  styleUrls: ['./list-pagination-search.component.css']
})

@Injectable()
export class ListPaginationSearchComponent implements OnInit {

  data: any[]=[];
  cols: any[]=[];

  paginator: boolean;
  rows: number;
  totalRecords: number;
  
  @Input() dataListHook: DataListHook ;
  @Input() paginatorHook: PaginatorHook;

  constructor(private carService:DemoCarService) { }

  ngOnInit() {
    /* this.carService.getCars().then(cars => this.data = cars);
    this.cols = [
      {field: 'vin', header: 'Vin'},
      {field: 'year', header: 'Year'},
      {field: 'brand', header: 'Brand'},
      {field: 'color', header: 'Color'}
    ] */

    this.cols = this.dataListHook.columns;
    this.data = this.dataListHook.data;
    
    this.paginator = this.paginatorHook.paginator;
    this.rows = this.paginatorHook.rows;
    this.totalRecords = this.paginatorHook.totalRecords;
  }

  loadCarsLazy($event){
    
  }

}
