import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { NotificatoinsService } from '../notifications/notifications.service';

@Injectable()
export class InvoiceChartService extends ApiService<any, any, any> {
    urlPath: string = 'invoices';
    // baseUrl: string;
    constructor(private Http: HttpClient, private Injector: Injector,
        private notificationServcie: NotificatoinsService,
    ) {
        super(Http, Injector);
        // this.baseUrl = this.Injector.get(BASE_URL);
    }

    downloadFile(fileType, data, type: string = null) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLSX') {
                let fileUrl: string;
                switch (type) {
                    case "reservedInvoice": {
                        fileUrl = this.baseUrl + 'invoices/reservedInvoiceMetadata/exportGrid';
                        break;
                    }
                    case "migratedInvoice": {
                        fileUrl = this.baseUrl + 'migrated/invoices/exportGrid';
                        break;
                    }
                    case "creditNote": {
                        fileUrl = this.baseUrl + 'creditNotes/exportGrid';
                        break;
                    }
                    case "reservedCN": {
                        fileUrl = this.baseUrl + 'creditNotes/reservedCreditNoteMetadata/exportGrid';
                        break;
                    }
                    case "tally": {
                        fileUrl = this.baseUrl + 'invoices/tally/exportGrid';
                        break;
                    }
                    default: {
                        fileUrl = this.baseUrl + 'invoices/generateXLS';
                    }
                }
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                    // }), catchError((err) => {

                    //     this.notificationServcie.error("XLSX Download Error", "Please refresh and retry");
                    //     return err;
                    // })
                }));
            }
        }

    }

    getInvoiceTaskCount(searchEvent) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/task-cards/count', searchEvent)

        }
    }
}
