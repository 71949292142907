export enum InvoiceStatus {
    ADMIN_GEN_PENDING = "Admin Gen Pending",  // Admin Task
    ADMIN_GEN_APPROVE = "Admin Gen Approved",
    ADMIN_GEN_REJECT = "Admin Gen Rejected",
    EDIT_PENDING = "Invoice edit is in progress", // SR task
    ADMIN_EDIT_PENDING = "Admin Edit Pending",  // Admin Task
    ADMIN_EDIT_APPROVE = "Admin Edit Approved",
    ADMIN_EDIT_REJECT = "Admin Edit Rejected",
    ADMIN_CANCEL_PENDING = "Admin Cancel Pending",
    ADMIN_CANCEL_REJECT = "Admin Cancel Rejected",
    INVOICE_CANCEL = "Cancelled",
    INVOICE_CANCEL_WITH_RETENTION = "CN Applied",
    ADMIN_FULL_CN_PENDING = "Admin Full CN Pending",
    FULL_CN_APPROVED = "Full CN Approved",
    FULL_CN_REJECTED = "Full CN Rejected",
    PARTIAL_CN_APPLIED = "Partial CN Applied",
    PARTIAL_CN_APPROVED = "Partial CN Approved"
}

