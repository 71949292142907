
import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule } from "primeng/primeng";
import { TaskComponent } from './tasks.component';
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { TasksUIService } from './services/task-ui.service';
import { TaskCardModule } from '../task-cards/task-cards.module';
import { InvoiceChartService } from '../../../services/shared/invoice-chart.service';
import { SuperbillTasksModule } from '../superbill-tasks/superbill-tasks.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CampaignTaskModule } from '../campaign-tasks/campaign-tasks.module';
import { PendingUnbilledTasksModule } from '../pending-unbilled-tasks/pending-unbilled-tasks.module';
import { SrTaskModule } from '../sr-tasks/sr-tasks.module';
import { TasksService } from '../../../services/shared/tasks.service';
import { PendingTasksCardsModule } from '../pending-tasks-cards/pending-tasks-cards.module';
import { PendingTasksModule } from '../pending-tasks/pending-tasks.module';
import { CreditNotesTasksModule } from '../credit-notes-tasks/credit-notes-tasks.module'
import { CreditNotesTaskCardModule } from '../credit-notes-tasks-cards/credit-notes-task-cards.module';
import { CreditNotesTaskCardsService } from '../../../services/shared/credit-notes-task-cards.service';
import { SiteExpirationTasksModule } from '../site-expiration-tasks/site-expiration-tasks.module';
import { RateApprovalTasksModule } from '../rate-approval-tasks/rate-approval-tasks.module';
import { SharedModules } from '../../../modules/shared.modules';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        TaskCardModule,
        SuperbillTasksModule,
        ReactiveFormsModule,
        FormsModule,
        CampaignTaskModule,
        PendingUnbilledTasksModule,
        SrTaskModule,
        PendingTasksCardsModule,
        PendingTasksModule,
        CreditNotesTasksModule,
        CreditNotesTaskCardModule,
        SiteExpirationTasksModule,
        RateApprovalTasksModule,
        SharedModules
    ],

    declarations: [
        TaskComponent,
    ],

    exports: [
        TaskComponent
    ],

    providers: [
        TasksUIService,
        InvoiceChartService,
        TasksService,
        CreditNotesTaskCardsService
    ]
})

export class TaskModule { }
