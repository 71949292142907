/* 
* @file Plan service.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/

import { ApiService, BASE_URL } from "../base-service/api.service";
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions, Headers } from "@angular/http";
import { Observable } from "rxjs";
import { Plan } from "../../modals/plans/plan";
import { ResponseContentType } from '@angular/http';
@Injectable()
export class PlayGroundService extends ApiService<any, any, any> {
    urlPath: string = 'tests/search';
    baseUrl: string;
    plan: Plan;


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }
    /**
     * API call for gettign all the plans 
     */
    test1() {
        let headers = {
            headers: new HttpHeaders()
        };
        return this.Http.get(this.baseUrl + 'tests/test1');
    }
   

}
