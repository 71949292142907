<!-- margin-top-42 padding-20 -->
<!-- <div class="task-cards">
    <ul class="cinline-block">
        <li *ngFor="let card of invoiceTaskCards; let i = index" [ngClass]="{'selected':check(card, i),'':!check(card, i)}"
            class="card-dim card card-color" (click)="filterData(card, i)">
            <div class="ui-g padding-top-5">
                <div class="card-count ui-g-2">
                    {{card.count}}
                </div>
                <div class="card-role ui-g-10">
                    {{card.role ? "("+card.role+")" :""}}
                </div>
            </div>
            <div class="card-status">
                {{getStatus(card)}}
            </div>
        </li>
    </ul>
</div> -->


<div class="task-cards">
    <ul class="cinline-block">
        <li *ngFor="let card of invoiceTaskCards; let i = index" [ngClass]="{'selected':check(card, i),'':!check(card, i)}"
            class="card-dim card card-color" (click)="filterData(card, i)">
            <!-- <div class="ui-g padding-top-5"> -->
            <div class="ui-g">
                <div class="card-count ui-g-12">
                    {{card.count}}
                </div>
                <!-- <div class="card-role ui-g-10">
                    {{card.role ? "("+card.role+")" :""}}
                </div> -->
                <div class="card-status ui-g-12">
                    {{getStatus(card)}}
                </div>
            </div>
            <!-- <div class="card-status">
                {{getStatus(card)}}
            </div> -->
        </li>
    </ul>
</div>