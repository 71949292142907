import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, InputTextModule, SharedModule, TooltipModule, MultiSelectModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { GroupDialogComponent } from "./group-dialog.component";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { CustomerService } from "../../../customers/services/customer.services";
import { DirectiveModules } from '../../../modules/directive.modules';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        InputTrimModule,
        MultiSelectModule,
        ProgressSpinnerModule,
        DirectiveModules
    ],

    declarations: [
        GroupDialogComponent
    ],

    exports: [
        GroupDialogComponent
    ],
    providers: [
        CustomerService
    ]
})

export class GroupDialogModule { }