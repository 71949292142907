import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Area } from "../Area/area";


export class User extends SibBaseObject {
    authId: string;
    emailId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    number: number;
    roles: string[] = [];
    areas: Area[] = [];
}
