import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, DropdownModule, CalendarModule, SliderModule, OverlayPanelModule, DialogModule, TooltipModule } from "primeng/primeng";
import { ListHoardingsComponent } from "./list-hoardings.component";
import { BrowserModule } from "@angular/platform-browser";
import { HoardingLightService } from "../../../services/shared/hoardingLight.service";
import { HoardingCategoryService } from "../../../services/shared/hoardingCategory.service";
import { UserGridConfigService } from "../../../services/shared/user-grid-config.service";
import { DirectiveModules } from "../../../modules/directive.modules";
import { RouterModule } from '@angular/router';
import { UploadDocDialogModule } from "../../../shared/components/upload-doc-dialog/upload-doc-dialog.module";
import { KeyToValuePipe } from '../../helpers/key-value-pipe/KeyToValuePipe';
import { ValueToKeyPipe } from '../../helpers/key-value-pipe/ValueToKeyPipe';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SibGridModule,
        HttpModule,
        ButtonModule,
        DropdownModule,
        CalendarModule,
        SliderModule,
        SharedModule,
        DirectiveModules,
        RouterModule,
        UploadDocDialogModule,
        OverlayPanelModule,
        DialogModule,
        TooltipModule
    ],

    declarations: [ListHoardingsComponent,
        KeyToValuePipe,
        ValueToKeyPipe
    ],

    exports: [
        ListHoardingsComponent,
    ],
    providers: [HoardingCategoryService, HoardingLightService, UserGridConfigService]
})

export class ListHoardingsModule { }
