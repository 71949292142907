import { GridColumn, EditableGridFieldTypes, Alignment } from '../../components/sib-forms/grid/grid.config';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { DateUtil } from '../../helpers/date.util';
import { KioskTypeEnum } from '../../shared/constants/kiosk-type.enum';
import * as utils from '../../helpers/utils';
import * as mapUtils from '../../helpers/map.utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { CalculationService } from '../../services/shared/calculationService';
import { InvoiceStatus } from '../../shared/constants/invoice-status-enum';

export const UnbilledHoardingColumns: Array<GridColumn> = [
    {
        field: 'srNo',
        name: 'srNo',
        header: 'Sr ',
        required: true,
        width: '3vw',
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        styleClass: 'sr-number',
        type: EditableGridFieldTypes.CUSTOM,
    },
    {
        field: 'campaignItem.customId',
        name: 'campaignItem.customId',
        header: 'HID',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '7vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
        displayFn: (data) => {
            if (data && data.campaignItem && data.campaignItem.customId) {
                return data.campaignItem.customId;
            } else {
                return '-';
            }
        }
    },
    // {
    //     field: 'invoiceId',
    //     name: 'invoiceId',
    //     header: 'Invoice',
    //     required: true,
    //     editable: false,
    //     hidden: false,
    //     permanent: true,
    //     width: '9vw',
    //     sortable: true,
    //     type: EditableGridFieldTypes.TEXT,
    //     filter: {
    //         matchMode: 'contains',
    //         placeHolder: 'Search',
    //         custom: false,
    //     },
    //     displayFn: (data) => {
    //         if (data && data.invoiceId) {
    //             return data.invoiceId;
    //         } else {
    //             return '-';
    //         }
    //     }
    // },
    {
        field: 'campaignItem.city',
        name: 'campaignItem.city',
        header: 'City',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '8vw',
        sortable: false,
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //     matchMode: 'contains',
        //     placeHolder: 'Search',
        //     custom: false
        // },
        displayFn: (data) => {
            if (data && data.campaignItem && data.campaignItem.city) {
                return data.campaignItem.city;
            } else {
                return '-';
            }
        }
    },
    {
        field: 'campaignItem.location',
        name: 'campaignItem.location',
        header: 'Location',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '20vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.campaignItem && data.campaignItem.location) {
                return data.campaignItem.location;
            } else {
                return '-';
            }
        }
    },
    {
        field: 'campaignItem.size',
        name: 'campaignItem.size',
        header: 'Size',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '5vw',
        sortable: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.campaignItem && data.campaignItem.size) {
                return (data.campaignItem.size);
            } else {
                return '-';
            }
        },
    },
    {
        field: 'campaignItem.squareFeet',
        name: 'campaignItem.squareFeet',
        header: 'Sq.Ft',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '5vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'relational',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.campaignItem && data.campaignItem.squareFeet) {
                return (data.campaignItem.squareFeet);
            } else {
                return '-';
            }
        },
    },
    {
        field: 'campaignItem.lightType',
        name: 'campaignItem.lightType',
        header: 'Light',
        required: true,
        editable: false,
        width: '4.5vw',
        hidden: false,
        default: true,
        sortable: true,
        permanent: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: true,
        },
        displayFn: (data) => {
            if (data && data.campaignItem && data.campaignItem.lightType) {
                return HoardingLightEnum[data.campaignItem.lightType];
            } else {
                return '-';
            }
        },
    },
    {
        field: 'campaign.displayName',
        name: 'campaign.displayName',
        header: 'Display Name',
        required: true,
        editable: false,
        width: '12vw',
        hidden: false,
        default: true,
        sortable: true,
        permanent: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
        // displayFn: (data) => {
        //     if (data && data.campaign && data.campaign.displayName) {
        //         return data.campaign.displayName;
        //     } else {
        //         return '-';
        //     }
        // },
    },
    {
        field: 'ranges[0]',
        name: 'ranges[0]',
        header: 'Item St. Date',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '8vw',
        sortable: false,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
            if (data && data.ranges && data.ranges.length > 0) {
                return DateUtil.dategridFormatter(new Date(data.ranges[0]));
            } else {
                return '-';
            }
        }
    },
    {
        field: 'ranges[1]',
        name: 'ranges[1]',
        header: 'Item End Date',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '8vw',
        sortable: false,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
            if (data && data.ranges && data.ranges.length > 0) {
                return DateUtil.dategridFormatter(new Date(data.ranges[1]));
            } else {
                return '-';
            }
        }
    },
    {
        field: 'campaign.employee.fullName',
        name: 'campaign.employee.fullName',
        header: 'Employee',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        width: '8vw',
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
            matchMode: 'contains',
            placeHolder: 'Search',
            custom: false,
        },
        displayFn: (data) => {
            if (data && data.campaign.employee) {
                return data.campaign.employee.fullName;
            } else {
                return '-';
            }
        }
    },
    {
        field: 'campaignItem.rate',
        name: 'campaignItem.rate',
        header: 'Monthly Rate',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: false,
        width: '8vw',
        sortable: true,
        type: EditableGridFieldTypes.NUMBER,
        filter: {
            matchMode: 'relational',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.campaignItem && data.campaignItem.rate) {
                return new CurrencyHelperPipe().transform(utils.parseValueToDecimalTwoPlaces(data.campaignItem.rate));
            } else {
                return 0;
            }
        },
    },
    // {
    //     field: 'campaignItem.cost',
    //     name: 'campaignItem.cost',
    //     header: 'Cost',
    //     required: true,
    //     editable: false,
    //     hidden: false,
    //     width: '7vw',
    //     permanent: false,
    //     default: true,
    //     sortable: true,
    //     type: EditableGridFieldTypes.NUMBER,
    //     filter: {
    //         matchMode: 'relational',
    //         placeHolder: 'Search',
    //         custom: false
    //     },
    //     displayFn: (data) => {
    //         if (data && data.campaignItem && data.campaignItem.cost) {
    //             return new CurrencyHelperPipe().transform(utils.parseValueToDecimalTwoPlaces(data.campaignItem.cost));
    //         } else {
    //             return 0;
    //         }
    //     },
    // },
    {
        field: 'amount',
        name: 'amount',
        header: 'Cost',
        required: true,
        editable: false,
        hidden: false,
        width: '7vw',
        permanent: false,
        default: true,
        sortable: true,
        type: EditableGridFieldTypes.NUMBER,
        filter: {
            matchMode: 'relational',
            placeHolder: 'Search',
            custom: false
        },
        displayFn: (data) => {
            if (data && data.campaignItem && data.amount) {
                return new CurrencyHelperPipe().transform(utils.parseValueToDecimalTwoPlaces(data.amount));
            } else {
                return 0;
            }
        },
    },
];

export const UnbilledHoardingsMap = (() => {
    return mapUtils.createMap(UnbilledHoardingColumns);
})();
