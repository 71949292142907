import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Contracts } from "../contracts/contracts";
import { Picture } from "./picture";
import { GeoCoordinate } from "./geo-coordinate";
import { Area } from "../Area/area";
import { MediaSubCategoryEnum } from "../../shared/constants/media-sub-category-enum";

export class Media extends SibBaseObject {
   customId: string;
   mediaType: string;
   mediaSubCategory: MediaSubCategoryEnum;
}
