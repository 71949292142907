import { Component, Input, Output, OnInit, ViewChild, EventEmitter, ViewEncapsulation } from '@angular/core';
import { GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { ROIItem } from '../../../modals/analytics/roi-item';
import * as roiInventoryDataColumnConfig from '../../../modals/column-configurations/roi-inventory-data-column-config';
import { DashboardService } from '../../../dashboard/service/dashboard.service';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { PaginationData } from '../../../modals/paginationdata';
import { AppUrls } from '../../../services/urls';
import { map, catchError } from 'rxjs/operators';
import { InvoiceChartService } from '../../../services/shared/invoice-chart.service';
import { Dropdown } from 'primeng/primeng';
import * as utils from '../../../helpers/utils';
import { RentItemCategory } from '../../../modals/analytics/rent-item-category-enum';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { empty as observableEmpty } from 'rxjs';
import { PPTLoader } from '../../../modals/loader-subjects/pptloader';
import { LoaderSubjects } from '../../../modals/loader-subjects/loader-subjects';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import { SelectItem } from 'primeng/primeng';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { GridNameEnum } from '../../../shared/constants/grid-name.enum';
import { SIBConstants } from '../../../shared/constants/SIBConstant';

@Component({
  selector: 'sib-view-roi-contract-inventory',
  templateUrl: './view-roi-contract-inventory.component.html',
  styleUrls: ['./view-roi-contract-inventory.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewRoiContractInventoryComponent implements OnInit {

  @ViewChild(GridComponent) gridViewChildRef: GridComponent<ROIItem>;
  @ViewChild('rentItemCategorydd') rentItemCategoryDd: Dropdown;

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() contractId: string;
  @Input() contractName: string;

  @Output() viewContractInventory: EventEmitter<boolean> = new EventEmitter();
  isLoading: boolean = false;
  inventoryGridConfig: GridConfig<ROIItem> = new GridConfig<ROIItem>();
  inventoryDataColumns = roiInventoryDataColumnConfig.ROIInventoryDataColumns;
  totalInventoryRecords: number;
  rentItemCategoryDropdown: any[] = [];

  showXlsExport = true;
  isFileDownloading = false;
  xlsLoader: PPTLoader = new PPTLoader();
  searchEvent: GridPaginationEvent;
  modifiedColumns: SelectItem[] = [];
  selectedColumns: any[] = [];
  previewColumns: any;

  constructor(
    private dashboardService: DashboardService,
    private notificationServcie: NotificatoinsService,
    private invoiceService: InvoiceChartService,
    private loaderSubjects: LoaderSubjects,
    private hmColService: HoardingMasterColService,
    private userGridConfigService: UserGridConfigService,
  ) { }

  ngOnInit() {
    this.setUserGridConfig();
    this.setRentItemCategoryDropdown(); // set filter dropdown
    this.setInventoryGridConfigObject();
  }

  /**
   * @description set column configuration
   * @author Divya Sachan
   * @date 2019-11-13
   * @memberof ViewRoiContractInventoryComponent
   */
  setUserGridConfig() {
    this.userGridConfigService.get(null, AppUrls.SLASH + GridNameEnum['ROI_ITEM']).subscribe((hoardingGridConfig) => {
      let columns = [];
      this.inventoryDataColumns = utils.getUserConfiguredColumns(hoardingGridConfig, this.inventoryDataColumns);
      // this.hmColService.defaultColumns.next(this.inventoryDataColumns);
      this.gridViewChildRef.ngOnInit();
      this.inventoryDataColumns.forEach(column => {
        if (column.permanent) {
          columns.push(column.header);
        } else {
          if (column.field === hoardingGridConfig[column.header]) {
            columns.push(column.header);
          }
        }
      });
      this.updatingUserGridConfig(columns);
    });
  }

  ngAfterViewInit() {
    this.modifiedColumns = this.gridViewChildRef.getModifiedColumns();
    this.hmColService.defaultColumns.subscribe((defaultColumns) => {
      this.selectedColumns = [];
      defaultColumns.forEach((column) => {
        if (column['default'] === true && column['permanent'] === false) {
          this.selectedColumns.push(column['header']);
        }
      });

      if (this.selectedColumns.length > 0) {
        this.hmColService.selectedColumns.next(this.selectedColumns);
      }

    });
  }

  /**
   * @description set rent category filter dropdown
   * @author Divya Sachan
   * @date 2019-11-11
   * @memberof ViewRoiContractInventoryComponent
   */
  setRentItemCategoryDropdown() {
    this.rentItemCategoryDropdown = utils.createDropdown(RentItemCategory, true);
  }

  /**
   * @description set inventory grid properties
   * @author Divya Sachan
   * @date 2019-11-08
   * @memberof RoiComponent
   */
  setInventoryGridConfig() {
    this.inventoryGridConfig.getDefaultModel = () => new ROIItem();
    this.inventoryGridConfig.model = ROIItem;
    this.inventoryGridConfig.selectionMode = SelectionMode.None;
    this.inventoryGridConfig.editable = true;
    this.inventoryGridConfig.expandableRows = false;
    this.inventoryGridConfig.checkboxSelection = false;
    this.inventoryGridConfig.resizableColumns = false;
    this.inventoryGridConfig.shouldAddRowOnDelete = false;
    this.inventoryGridConfig.showColorCode = false;
    this.inventoryGridConfig.lazy = true;
    this.inventoryGridConfig.enablePagination = true;
    this.inventoryGridConfig.scrollable = true;
  }

  /**
   * @description set inventory grid for contract
   * @author Divya Sachan
   * @date 2019-11-08
   * @memberof RoiComponent
   */
  setInventoryGridConfigObject() {
    this.setInventoryGridConfig();
    this.inventoryGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen;

      this.isLoading = true;

      this.setInventoryPaginationEvent(paginationEvent);
      return this.dashboardService.create(paginationEvent, paginationEvent, AppUrls.GETROIITEMDATA).pipe(map(response => {
        if (response) {
          this.searchEvent = paginationEvent;
          this.totalInventoryRecords = response['data']['totalElements'];
          return response['data']['content'];
        }
      }), catchError(error => {
        if (error.error.code === 422) {
          this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
          this.gridViewChildRef.resetAndReloadTable();
        } else {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
          return observableEmpty();
        }
      }));
    };
  }

  /**
   * @description set pagination event
   * @author Divya Sachan
   * @date 2019-11-11
   * @param {PaginationData} paginationEvent
   * @memberof ViewRoiContractInventoryComponent
   */
  setInventoryPaginationEvent(paginationEvent: PaginationData) {
    let filter = {
      "serviceDate": {
        value: [new Date(this.startDate), new Date(this.endDate)],
        matchMode: "dateBetween"
      },
      "campaignItem.contractId": {
        value: this.contractId,
        matchMode: "is"
      }
    };
    // if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
    //   paginationEvent.sortField = 'ranges[0]';
    //   paginationEvent.sortOrder = -1;
    // }
    Object.assign(paginationEvent.filters, filter);
  }

  /**
   * @description traverse back to ROI contract grid
   * @author Divya Sachan
   * @date 2019-11-11
   * @memberof ViewRoiContractInventoryComponent
   */
  getHistory(event) {
    this.viewContractInventory.emit(event);
  }

  /**
   * @description get temp id of invoice and open it in new tab
   * @author Divya Sachan
   * @date 2019-11-11
   * @param {*} rowData
   * @memberof ViewRoiContractInventoryComponent
   */
  getInvoice(rowData) {
    if (rowData.invoiceId.includes(SIBConstants.TEMP) || rowData.invoiceId.includes(SIBConstants.MIG)) { // for temp invoice 
      window.open(AppUrls.BILLINGS + AppUrls.BILLS + AppUrls.VIEW + AppUrls.SLASH + rowData.invoiceId, "_blank");
    } else { // otherwise
      this.invoiceService.get({ 'invoiceId': rowData.invoiceId }, AppUrls.GET_UNIQUE_ID).subscribe(response => {
        if (response && response['data']) {
          window.open(AppUrls.BILLINGS + AppUrls.BILLS + AppUrls.VIEW + AppUrls.SLASH + response['data'], "_blank");
        } else {
          this.notificationServcie.error('Error Fetching Invoice.', 'Error!!');
        }
      }, (error) => {
        this.notificationServcie.error(error.error.message ? error.error.message : 'Error Fetching Invoice.', 'Error!!');
      });
    }
  }

  /**
   * @description save column configuration
   * @author Divya Sachan
   * @date 2019-11-13
   * @param {*} event
   * @memberof ViewRoiContractInventoryComponent
   */
  updatingUserGridConfig(event) {
    let roiContractInventoryGridConfig: Map<string, string> = new Map();
    roiContractInventoryGridConfig = utils.getGridConfigMap(event, this.inventoryDataColumns);
    this.userGridConfigService.patch(utils.buildObject(roiContractInventoryGridConfig), null, GridNameEnum['ROI_ITEM'])
      .subscribe((result) => {
      });
  }

  /**
   * @description export XLS
   * @author Divya Sachan
   * @date 2019-11-13
   * @memberof ViewRoiContractInventoryComponent
   */
  exportXlsx() {
    this.isFileDownloading = true;
    this.createLoaderObject("XLS", this.xlsLoader)
    this.loaderSubjects.roiContractInventoryDataXlsLoader.next(this.xlsLoader);
    this.isFileDownloading = false;
  }

  /**
   * @description create loader object for XLS
   * @author Divya Sachan
   * @date 2019-11-13
   * @param {*} fileType
   * @param {*} object
   * @memberof ViewRoiContractInventoryComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = this.searchEvent;
    object.data = this.contractName;
  }

}
