import { AbstractReservedMetadata } from './abstract-reserved-metadata';
import { PurchaseOrder } from '../campaigns/purchaseOrder';
import { InvoiceGstDetail } from './invoice-gst-detail';


export class ReservedCreditNoteMetadata extends AbstractReservedMetadata {
    invoiceReferenceId: string;
    reason: string;
    tallyGeneratedDate: Date; // CN generated date
    documents: PurchaseOrder[] = [];
    invoiceGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    displayName: string;
}
