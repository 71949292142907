import { map } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class DistrictPostService extends ApiService<any, any, any> {
    urlPath: string = 'districts';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }
}


