import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { District } from "./district";
import { City } from "./city";
import { State } from "./state";
import { Country } from "./country";
import { GeoCoordinate } from "../hoardings/geo-coordinate";

export class Area extends SibBaseObject {
    name: string;
    code: string;
    city: City = new City();
    postalCode: string;
    category: string = 'CUSTOM';
    stateName: string;
    stateCode: string;
    districtName: string;
    districtCode: string;
    cityName: string;
    cityCode: string;
    // latitude?: string;
    // longitude?: string;
    geoCoordinate: GeoCoordinate = new GeoCoordinate();
}