export const environment = {
    production: false,
    VERSION: require('../../package.json').version,
    name: 'staging',
    masterUrl: 'https://stagingapi.showitbig.com/master-api',
    baseUrl: 'https://stagingapi.showitbig.com/api/',
    dummyUrl: 'http://localhost:3000/',
    // helpUrl: 'http://172.16.5.181:9005/article-api/',
    helpUrl: 'https://chat.showitbig.com:9002/article-api/',
  
    // chatBaseURL: 'https://182.156.206.197:9009',   // Chat Server URL
    chatBaseURL: 'https://chat.showitbig.com:9001'
  
  };
  