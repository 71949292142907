import { PurchaseOrder } from "../campaigns/purchaseOrder";

export class PurchaseOrderMaster {
    id: string;
    campaignId: string;
    planId: string;
    displayName: string;
    purchaseOrders: PurchaseOrder = new PurchaseOrder();

    setPurchaseOrderMaster(moduleData, purchaseOrder, moduleName?: string): PurchaseOrderMaster {
        this.id = moduleData.id;
        if (moduleName === 'plans') {
            this.planId = moduleData.planId;
        } else if (moduleName === 'campaigns') {
            this.campaignId = moduleData.campaignId;
        }
        this.displayName = moduleData.displayName;
        this.purchaseOrders = purchaseOrder;
        return this;
    }
}
