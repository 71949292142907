import { NgModule } from "@angular/core";
import { IndustryDialogComponent } from "./industry-dialog.component";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, TooltipModule, ProgressSpinnerModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        ProgressSpinnerModule
    ],

    declarations: [
        IndustryDialogComponent
    ],

    exports: [
        IndustryDialogComponent
    ]
})

export class IndustryModule { }