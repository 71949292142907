/* 
* @file Event service.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/

import { Http, Response, Headers } from "@angular/http";
import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from "@angular/common/http";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { Observable } from "rxjs";
import { EventConfig } from '../../modals/events/event-config';

@Injectable()
export class EventService extends ApiService<any, any, any> {

    urlPath: string = 'events?projection=eventListProjection';
    baseUrl: string;
    private actionUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector,  private _eventConfig : EventConfig) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
         this.actionUrl = _eventConfig.ServerWithApiUrl ; 
    }
   
    /**
     * Call to API for getting T
     * @returns Observable
     */
    public getAll<T>(): Observable<T> {
        let headers = {
                    headers: new HttpHeaders()
                };
        // alert('action url is --- '+this.actionUrl);
        return this.Http.get<T>(this.actionUrl);
    }

}

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        console.log(JSON.stringify(req.headers));
        return next.handle(req);
    }
}