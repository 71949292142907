import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataTableModule, MenubarModule, SplitButtonModule, ButtonModule, SharedModule, DropdownModule, GrowlModule, AutoCompleteModule, MultiSelectModule, CalendarModule, TooltipModule, TieredMenuModule, DomHandler, InputSwitchModule } from "primeng/primeng";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GridComponent } from "./grid.component";
import { GridColumnComponent } from "./grid-column.component";
import { GridTemplateDirective } from "./grid-template.directive";
import { AreaDialogModule } from "../../../shared/components/area-dialog/area-dialog.module";
import { DistrictDialogModule } from "../../../shared/components/district-dialog/district-dialog.module";
import { DirectiveModules } from "../../../modules/directive.modules";


@NgModule({
    imports: [
        CommonModule,
        DataTableModule,
        MenubarModule,
        SplitButtonModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        DropdownModule,
        MultiSelectModule,
        AutoCompleteModule,
        AreaDialogModule,
        DistrictDialogModule,
        TooltipModule,
        GrowlModule,
        CalendarModule,
        DirectiveModules,
        TieredMenuModule,
        InputSwitchModule,
    ],
    declarations: [
        GridComponent,
        GridColumnComponent,
        GridTemplateDirective
    ],

    exports: [
        GridComponent,
        GridColumnComponent,
        GridTemplateDirective
    ]
})

export class SibGridModule { }