<div class="credit-notes-task-cards">
  <ul class="cinline-block">
      <li *ngFor="let card of creditNotesTaskCards; let i = index" [ngClass]="{'selected':check(card, i),'':!check(card, i)}"
          class="card-dim card card-color" (click)="filterData(card, i)">
          <div class="ui-g">
              <div class="card-count ui-g-12">
                  {{card.count}}
              </div>
              <div class="card-status ui-g-12">
                  {{getStatus(card)}}
              </div>
          </div>
      </li>
  </ul>
</div>