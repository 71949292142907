import { ContractHoardings } from '../contracts/contract-hoarding';
import { HoardingGroup } from '../hoardings/hoardings-group';
import { Kiosk } from '../hoardings/kiosk';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { HoardingStatusEnum } from '../../shared/constants/hoarding-status-enum';
import { DateUtil } from '../../helpers/date.util';

export class MapMetadata {
    customId: string;
    city: string;
    location: string;
    size: string | number;
    lightType: string;
    rate: any;
    status: any;
    availableFrom: any;
    image: any;
    startDate: any;
    endDate: any;
    mountingImage: any;
    unmountingImage: any;
    monitoringImage: any;

    setMapMetadataFromInventory(inventory: any): MapMetadata {
        this.city = inventory.city;
        this.customId = inventory.customId;
        this.location = inventory.location;
        this.lightType = HoardingLightEnum[inventory.lightType];
        this.rate = inventory.grossPrice;
        this.size = inventory.size;
        this.status = HoardingStatusEnum[inventory.status];
        this.availableFrom = inventory.campaignEndDate ? DateUtil.formatDateDDMMYY(new Date(inventory.campaignEndDate)) : null;
        this.image = inventory.images ? inventory.images[0] : null;
        this.startDate = DateUtil.formatDateDDMMYY(new Date(inventory.itemStartDate));
        this.endDate = DateUtil.formatDateDDMMMYY(new Date(inventory.itemEndDate));
        this.mountingImage = inventory.mountingImage ? inventory.mountingImage[0] : null;
        this.unmountingImage = inventory.unmountingImage ? inventory.unmountingImage[0] : null;
        this.monitoringImage = inventory.monitoringImage ? inventory.monitoringImage[0] : null;
        return this;
    }

}
