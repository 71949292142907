/**
 * Programmer: Sanchit Mirg
 * 
 * Description: ContractModuleState will contain all the state for ContractModule
 * And reducers will contain all the reducers that can change the state.
 * 'reducers' are registed in customerModule in StoreModule
 * This is done to keep all the states and reducers at one place.
 */
import { ActionReducerMap } from "@ngrx/store";
import { ContractState, contractReducer } from "./reducers/contract.reducers";
import { AppModuleState } from "../app state/app-module.reducers";

export interface ContractModuleState {
    contracts: ContractState;
}

export interface contractModuleWithApp extends AppModuleState {
    contracts: ContractModuleState
}

export const reducers: ActionReducerMap<ContractModuleState> = {
    contracts: contractReducer
};

