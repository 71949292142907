export enum MediaSubCategoryEnum {
    HOARDING = "Hoarding",
    HOARDING_GROUP = "Hoarding Group",
    KIOSK = "Kiosk",
    // BUS_SHELTER = "Bus Shelter",
    // BUS = "Bus",
    // RAILWAY_STATION = "Railway Station",
    // SKYWALK = "SkyWalk",
    // GANTRY = "Gantry",
    // TRAFFIC_BOOTH = "TRAFFIC_BOOTH"
}
