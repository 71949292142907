<!-- <p-dialog header="View Images" [(visible)]="displayPhotoDialog" [modal]="true" showEffect="fade" [width]="430" [height]="475"
    (onHide)="hidePhotoDialog()" (onShow)="showPhotoDialog()">
    <div *ngIf="images.length === 0">
        <h4> No Photos to display </h4>
    </div>
    <p-galleria *ngIf="displayPhotoDialog" [images]="images" panelWidth="400" panelHeight="300" transitionInterval="1000"></p-galleria>
</p-dialog> -->
<!-- panelWidth="500" panelHeight="500" -->
<div #galleryModal id="myModal" class="modal">
    <!-- <span class="close">&times;</span> -->
    <!-- *ngIf="!noImage; else noImageTemplate" -->
    <div class="loader"></div>
    <div #galleryModalContent class="modal-content">
        <!-- <div *ngIf="!noImage; else noImageTemplate"> -->

        <div class="close-button-container">
            <span class="close" (click)="closeDisplay()">&times;</span>
        </div>
        <!-- modified by Pulkit to add showGallery property to instantiate the galleria component only after all the images are set -->
        <!-- *ngIf="showGallery" -->
        <p-galleria #gallery *ngIf="showGallery" class="galleryImages" [images]="images" transitionInterval="0"
            effectDuration="0" [autoPlay]="false" [activeIndex]="activeIndex" (onImageChange)="onImageChange($event.index)"
            [panelWidth]="panelWidth" [panelHeight]="panelHeight"></p-galleria>
        <!-- </div> -->
    </div>
</div>


<ng-template #noImageTemplate>
    <div>
        <p-dialog header="View Images" [(visible)]="noImage" width="200" [responsive]="true" (onHide)="hideDialog()">
            <h3 style="text-align:center">No Image Available.</h3>
        </p-dialog>
    </div>

</ng-template>