/**
* @file  Plan.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/

import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Customer } from "../KYC/customer";
import { PlanHoarding } from "./planHoarding";
import { PrintingPrice } from "./printingPrice";
import { MountingPrice } from "./mountingPrice";
import { Price } from "./price";
import { PlanSummary } from "./planSummary";
import { Tax } from "./tax";
import { PlanItem } from "./planItem";
import { ContractHoardings } from "../contracts/contract-hoarding";
import { HoardingGroup } from "../hoardings/hoardings-group";
import { User } from "../campaigns/user";
import { PurchaseOrder } from '../campaigns/purchaseOrder';

export class Plan extends SibBaseObject {
    displayName: string;
    customer: Customer = new Customer();
    // Raveena | 16-05-2019 Optimization change
    // hoardingItems: PlanItem[] = [];
    // hoardingGroupItems: PlanItem[] = [];
    campaignStartDate: Date;
    campaignEndDate: Date;
    totalItems: number;
    totalSqFt: number;
    price: Price = new Price();
    printingPrice: PrintingPrice = new PrintingPrice();
    mountingPrice: MountingPrice = new MountingPrice();
    tax: Tax = new Tax();
    discount: number;
    discountedPrice: number;
    summary: PlanSummary = new PlanSummary();
    status: any;
    pending: boolean;
    blockedDate: Date;
    employee: User = new User();
    lspPctDiff: boolean;
    blockedCount: number;
    rotational: boolean;
    // Raveena | 16-05-2019 Optimization change
    // kioskItems: PlanItem[] = [];
    kioskPrintingPrice: PrintingPrice = new PrintingPrice();
    kioskMountingPrice: MountingPrice = new MountingPrice();

    purchaseOrders: PurchaseOrder[] = [];
    // calculateOnMonthlyBasis = true;
}
