import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, GalleriaModule, ProgressSpinnerModule, OverlayPanelModule, TooltipModule, CalendarModule, CheckboxModule, InputTextModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { EditPoComponent } from "./edit-po.component";
import { EditPOService } from './services/edit-po.service';
import { DirectiveModules } from '../../../modules/directive.modules';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        SibGridModule,
        GalleriaModule,
        ProgressSpinnerModule,
        OverlayPanelModule,
        TooltipModule,
        CalendarModule,
        CheckboxModule,
        InputTextModule,
        DirectiveModules

    ],

    declarations: [
        EditPoComponent
    ],

    exports: [
        EditPoComponent,
    ],
    providers: [
        EditPOService
    ]
})

export class EditPoModule { }
