import { ContractHoardings } from "../../modals/contracts/contract-hoarding";
import { ActionExtended } from "../../shared/actionextended";
import { HoardingActions } from "../actions/hoarding-actions";
import * as _ from 'lodash';
import { Plan } from "../../modals/plans/plan";


export class HoardingState {
    hoardings: ContractHoardings[] = [];
    plan: Plan[] = [];
    singlePlan: any = null;
    singleCampaign: any;
    isHoardingCreated = false;
    isPlanCreated = false;
    createdPlanId = '';
    isHoardingGroupUpdated = false;
    isCampaignUpdated = false;
    isError = false;
    hoardingWithImageUpdatedHM: boolean = false;
    updateGroupWithImageHM: boolean = false;
    errorUpdatingHoardingGroupError: boolean = false;
    hoardingFilters: any[] = [];
}

const initialState: HoardingState = new HoardingState();

export function hoardingReducer(state = initialState, action: ActionExtended) {
    switch (action.type) {

        case HoardingActions.UPDATE_HOARDING_SUCCESS: {
            // let hoarding;
            // const index: number = _.findIndex(state.hoarding, { id: action.payload.id });
            // if (index >= 0) {
            //     hoarding = [...state.hoarding.slice(0, index), action.payload, ...state.hoarding.slice(index + 1)];
            //     console.log("hoarding reducer", hoarding);
            // } else {
            //     hoarding = [...state.hoarding, action.payload];
            // }
            return Object.assign({}, state, { isHoardingCreated: true });
        }

        case HoardingActions.UPDATE_HOARDING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case HoardingActions.RESET_HOARDING: {
            return Object.assign({}, state, { hoarding: new ContractHoardings(), isHoardingCreated: false });
        }

        case HoardingActions.RESET_ERROR: {
            return Object.assign({}, state, { isError: false });
        }

        case HoardingActions.CREATE_PLAN_SUCCESS: {
            // return Object.assign({}, state, { plan: [...state.plan, action.payload], createdPlanId: action.payload.id });
            return Object.assign({}, state, { plan: [action.payload], createdPlanId: action.payload.plan.id });
        }

        case HoardingActions.CREATE_PLAN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case HoardingActions.GET_PLAN_SUCCESS: {
            return Object.assign({}, state, { singlePlan: action.payload });
        }

        case HoardingActions.GET_PLAN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case HoardingActions.GET_CAMPAIGN_SUCCESS: {
            return Object.assign({}, state, { singleCampaign: action.payload });
        }

        case HoardingActions.GET_CAMPAIGN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case HoardingActions.RESET_IS_PLAN_CREATED: {
            return Object.assign({}, state, { plan: new Plan(), isPlanCreated: false });
        }

        case HoardingActions.RESET_CREATED_PLAN_ID: {
            return Object.assign({}, state, { plan: new Plan(), createdPlanId: '' });
        }

        case HoardingActions.HOARDING_UPDATE_PLAN: {
            return Object.assign({}, state, { isPlanCreated: false });
        }

        case HoardingActions.UPDATE_PLAN_SUCCESS: {
            // let plan;
            // const index: number = _.findIndex(state.plan, { id: action.payload.id });
            // if (index >= 0) {
            //     plan = [...state.plan.slice(0, index), action.payload, ...state.plan.slice(index + 1)];
            //     console.log("plan reducer", plan);
            // } else {
            //     plan = [...state.plan, action.payload];
            // }
            return Object.assign({}, state, { plan: action.payload, isPlanCreated: true });
        }

        case HoardingActions.UPDATE_PLAN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case HoardingActions.UPDATE_HOARDING_GROUP_SUCCESS_FROM_HOARDING: {
            return Object.assign({}, state, { isHoardingGroupUpdated: true });
        }

        case HoardingActions.UPDATE_HOARDING_GROUP_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case HoardingActions.RESET_IS_HOARDING_GROUP_UPDATED_FROM_HOARDING: {
            return Object.assign({}, state, { isHoardingGroupUpdated: false });
        }

        case HoardingActions.UPDATE_CAMPAIGN_SUCCESS: {
            return Object.assign({}, state, { isCampaignUpdated: true });
        }

        case HoardingActions.UPDATE_CAMPAIGN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case HoardingActions.RESET_IS_CAMPAIGN_UPDATED: {
            return Object.assign({}, state, { isCampaignUpdated: false });
        }

        case HoardingActions.RESET_SINGLE_PLAN: {
            return Object.assign({}, state, { singlePlan: null });
        }

        case HoardingActions.UPDATE_HOARDING_WITH_IMAGE_HM_SUCCESS: {
            return Object.assign({}, state, { hoardingWithImageUpdatedHM: true });
        }

        case HoardingActions.RESET_UPDATE_HOARDING_WITH_IMAGE_HM: {
            return Object.assign({}, state, { hoardingWithImageUpdatedHM: false });
        }

        // case HoardingActions.SET_HOARDING_FILTERS: {
        //     console.log("inside set hoarding reducers ....");
        //     return Object.assign({}, state, { hoardingFilters: action.payload });
        // }

        case HoardingActions.UPDATE_GROUP_WITH_IMAGE_HM: {
            return Object.assign({}, state, { updateHoardingGroupWithImageHM: true })
        }

        case HoardingActions.UPDATE_GROUP_WITH_IMAGE_HM_SUCCESS: {
            return Object.assign({}, state, { updateGroupWithImageHM: true })
        }

        case HoardingActions.RESET_UPDATE_GROUP_WITH_IMAGE_HM: {
            return Object.assign({}, state, { updateGroupWithImageHM: false })
        }

        case HoardingActions.UPDATE_GROUP_WITH_IMAGE_HM_ERROR: {
            return Object.assign({}, state, { errorUpdatingHoardingGroupError: true })
        }

        case HoardingActions.RESET_UPDATE_GROUP_WITH_IMAGE_HM_ERROR: {
            return Object.assign({}, state, { errorUpdatingHoardingGroupError: false })
        }

        default:
            return state;

        // case HoardingActions.SET_HOARDING_FILTERS: {
        //     console.log("inside set hoarding reducers ....");
        //     return Object.assign({}, state, {hoardingFilters: action.payload});
        // }
    }
}
