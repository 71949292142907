/**
* @file status-queue-dialog.component
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/


import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";


@Component({
    selector: 'sib-status-queue-dialog',
    templateUrl: './status-queue-dialog.component.html',
    styleUrls: ['./status-queue-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StatusQueueDialogComponent implements OnInit {
    @Input() displayStatusQueueDialog = false;
    @Input() rowData = null;

    @Output() eEmitChangeStatusQueueDialogDisplay: EventEmitter<any> = new EventEmitter();

    @Input() values: any[] = [];
    columns: any[] = [];
    loading = true; // for data table loading icon

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        this.columns = [  // passing value of datatable columns
            { name: 'employee', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Employee', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
            { name: 'customId', style: { 'width': '4vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Id', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: false },
            { name: 'companyName', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Billing Name', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
            // {
            //     name: 'billingName',
            //     style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' },
            //     header: 'Billing Name',
            //     filter: false,
            //     filterMatchMode: 'contains',
            //     source: 'statusQueue',
            //     type: 'text', hidden: false, permanent: true, sortable: true
            // },
            { name: 'displayName', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Display Name', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
            // { name: 'campaignStartDate', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Campaign Start', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
            // { name: 'campaignEndDate', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Campaign End', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
            { name: 'campaignStartDate', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Site Start Date', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
            { name: 'campaignEndDate', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Site End Date', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
            { name: 'status', style: { 'width': '8vw', 'text-align': 'left', 'word-break': 'normal', 'word-wrap': 'break-word' }, header: 'Status', filter: false, filterMatchMode: 'contains', source: 'statusQueue', type: 'text', hidden: false, permanent: true, sortable: true },
        ];
    }

    hideStatusQueueDialog() {
        this.displayStatusQueueDialog = false;
        this.eEmitChangeStatusQueueDialogDisplay.emit(false);
    }

    showStatusQueueDialog() {
        // this.values = this.rowData.statusQueues;
        this.values.forEach((value) => {
            //as now customId is provided by the backend
            // value.customId = this.rowData.customId;
            if (!(value.companyName)) {
                value.companyName = '-';
            }
        });
        this.loading = false;
    }


    /**
     * navigate to the link to view the details
     * 
     * @param {any} rowData 
     * @memberof StatusQueueDialogComponent
     */
    viewItemDetail(rowData) {
        if (rowData.module === 'Plan') {
            // this.router.navigate(['/plans/view/' + rowData.id]);
            window.open("/plans/view/" + rowData.id);
        } else {
            // this.router.navigate(['/campaigns/view/' + rowData.id]);
            window.open("/campaigns/view/" + rowData.id);
        }
    }
}
