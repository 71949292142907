import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";
import { HoardingActions } from "../actions/hoarding-actions";
import { HoardingModuleState, HoardingModuleStateExtended } from "../hoarding-module.reducers";
import { Store } from "@ngrx/store";
import { Plan } from "../../modals/plans/plan";
import { CampaignItem } from "../../modals/campaigns/campaignItem";


@Injectable()
export class HoardingActionDispatcher {
    hoarding: Observable<ContractHoardings[]>;
    plan: Observable<Plan[]>;
    singlePlan: Observable<any>;
    singleCampaign: Observable<any>;
    isHoardingCreated: Observable<boolean>;
    isPlanCreated: Observable<boolean>;
    isCampaignUpdated: Observable<boolean>;
    createdPlanId: Observable<string>;
    isHoardingGroupUpdated: Observable<boolean>;
    isError: Observable<boolean>;
    hoardingFilters: Observable<any[]>;
    hoardingWithImageUpdatedHM: Observable<boolean>;
    updateGroupWithImageHM: Observable<boolean>;
    errorUpdatingHoardingGroupError: Observable<boolean>;

    constructor(
        private store: Store<HoardingModuleStateExtended>,
        private hoardingActions: HoardingActions
    ) {
        this.hoarding = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.hoardings;
            }
        });

        this.plan = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.plan;
            }
        });

        this.singlePlan = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.singlePlan;
            }
        });

        this.singleCampaign = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.singleCampaign;
            }
        });

        this.isHoardingCreated = store.select(state => {
            if (state.hoardings) {
                return state.hoardings.hoardings.isHoardingCreated;
            }
        });

        this.isError = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.isError;
            }
        });

        this.isPlanCreated = store.select(state => {
            if (state.hoardings) {
                return state.hoardings.hoardings.isPlanCreated;
            }
        });

        this.createdPlanId = store.select(state => {
            if (state.hoardings) {
                return state.hoardings.hoardings.createdPlanId;
            }
        });

        this.isHoardingGroupUpdated = store.select(state => {
            if (state.hoardings) {
                return state.hoardings.hoardings.isHoardingGroupUpdated;
            }
        });

        this.isCampaignUpdated = store.select(state => {
            if (state.hoardings) {
                return state.hoardings.hoardings.isCampaignUpdated;
            }
        });

        this.hoardingFilters = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.hoardingFilters;
            }
        });

        this.hoardingWithImageUpdatedHM = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.hoardingWithImageUpdatedHM;
            }
        });

        this.updateGroupWithImageHM = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.updateGroupWithImageHM;
            }
        })

        this.errorUpdatingHoardingGroupError = store.select((state) => {
            if (state.hoardings) {
                return state.hoardings.hoardings.errorUpdatingHoardingGroupError;
            }
        })
    }

    updateHoarding(hoarding: ContractHoardings) {
        this.store.dispatch(this.hoardingActions.updateHoarding(hoarding));
    }

    resetHoarding() {
        this.store.dispatch(this.hoardingActions.resetHoarding());
    }

    resetError() {
        this.store.dispatch(this.hoardingActions.resetError());
    }

    getPlan(planId: any) {
        this.store.dispatch(this.hoardingActions.getPlan(planId));
    }

    getCampaign(campaignId: any) {
        this.store.dispatch(this.hoardingActions.getCampaign(campaignId));
    }

    createPlan(plan: any) {
        this.store.dispatch(this.hoardingActions.createPlan(plan));
    }

    updatePlan(plan: any) {
        this.store.dispatch(this.hoardingActions.hoardingUpdatePlan(plan));
    }

    resetIsPlanCreated() {
        this.store.dispatch(this.hoardingActions.resetIsPlanCreated());
    }

    resetCreatedPlanId() {
        this.store.dispatch(this.hoardingActions.resetCreatedPlanId());
    }

    updateHoardingGroup(hoardingGroup: any) {
        this.store.dispatch(this.hoardingActions.updateHoardingGroup(hoardingGroup));
    }

    resetIsHoardingGroupUpdated() {
        this.store.dispatch(this.hoardingActions.resetIsHoardingGroupUpdated());
    }

    updateHoardingCampaign(campaignItems: CampaignItem[]) {
        this.store.dispatch(this.hoardingActions.updateHoardingCampaign(campaignItems));
    }

    resetIsCampaignUpdated() {
        this.store.dispatch(this.hoardingActions.resetIsCampaignUpdated());
    }

    setHoardingFilters(filters: any) {
        this.store.dispatch(this.hoardingActions.setHoardingFilters(filters));
    }

    resetSinglePlan() {
        this.store.dispatch(this.hoardingActions.resetSinglePlan());
    }

    updateHoardingWithImageHM(hoarding) {
        this.store.dispatch(this.hoardingActions.updateHoardingWithImageHM(hoarding));
    }

    resetUpdateHoardingWithImageHM() {
        this.store.dispatch(this.hoardingActions.resetUpdateHoardingWithImageHM());
    }

    updateGroupWithImage(hoardingGroup) {
        this.store.dispatch(this.hoardingActions.updateGroupWithImage(hoardingGroup))
    }

    resetUpdateGroupWithImageHM() {
        this.store.dispatch(this.hoardingActions.resetUpdateGroupWithImageHM())
    }

    resetUpdateGroupWithImageError() {
        this.store.dispatch(this.hoardingActions.resetUpdateGroupWithImageError())
    }
}
