/**
* @file billingReducer.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/

import { ActionExtended } from "../../shared/actionextended";
import { Plan } from "../../modals/plans/plan";
import { BillingActions } from "../actions/billing-actions";
import * as _ from 'lodash';
import { Observable } from "rxjs";
import { InvoiceTaskCard } from "../../modals/billings/invoice-task-card";


export class BillingState {

    isError = false;
    gotRentItems = false;
    gotPrintingItems = false;
    gotMountingItems = false;
    rentItems = null;
    printingItems = null;
    mountingItems = null;
    pendingRentItems = null;
    pendingPrintingItems = null;
    pendingMountingItems = null;
    isBillGenerated = false;
    isMigratedBillGenerated = false;
    invoices = [];
    invoicesRecieved = false;
    // isAccountantRequestRejected = false;
    // isAdminRequestRejected = false;
    // isAccountantRequestApproved = false;
    // isAdminRequestApproved = false;
    // isEditRequestRejected = false;
    // isEditRequestApproved = false;
    // isCancelRequestRejected = false;
    // isCancelRequestApproved = false;
    tempInvoice = null;
    isBillSubmitted = false;
    isMigratedBillSubmitted = false;
    isPOUploaded = false;

    // ===============================================

    isEditRequestSuccess = false;
    isEditDiscardRequestSuccess = false;
    isCancelRequestSuccess = false;

    isEditMigratedRequestSuccess = false;
    isEditDiscardMigratedRequestSuccess = false;
    isCancelMigratedRequestSuccess = false;

    invoiceTaskCards: InvoiceTaskCard[] = [];

    isGenerateRequestApprovedAdmin = false;
    isGenerateRequestApprovedAccountant = false;
    isGenerateRequestRejectedAdmin = false;
    isGenerateRequestRejectedAccountant = false;

    isEditRequestApprovedAdmin = false;
    isEditRequestApprovedAccountant = false;
    isEditRequestRejectedAdmin = false;
    isEditRequestRejectedAccountant = false;

    isCancelRequestApprovedAdmin = false;
    isCancelRequestApprovedAccountant = false;
    isCancelRequestRejectedAdmin = false;
    isCancelRequestRejectedAccountant = false;
}

const initialState: BillingState = new BillingState();

export function billingReducer(state = initialState, action: ActionExtended) {

    switch (action.type) {
        case BillingActions.GET_RENT_ITEMS: {
            return Object.assign({}, state, { rentItems: null });
        }

        case BillingActions.GET_PRINTING_ITEMS: {
            return Object.assign({}, state, { printingItems: null });
        }

        case BillingActions.GET_MOUNTING_ITEMS: {
            return Object.assign({}, state, { mountingItems: null });
        }

        case BillingActions.GET_PENDING_RENT_ITEMS: {
            return Object.assign({}, state, { pendingRentItems: null });
        }

        case BillingActions.GET_PENDING_PRINTING_ITEMS: {
            return Object.assign({}, state, { pendingPrintingItems: null });
        }

        case BillingActions.GET_PENDING_MOUNTING_ITEMS: {
            return Object.assign({}, state, { pendingMountingItems: null });
        }

        case BillingActions.GET_RENT_ITEMS_SUCCESS: {
            return Object.assign({}, state, { rentItems: action.payload, gotRentItems: true });
        }

        case BillingActions.GET_RENT_ITEMS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.GET_PRINTING_ITEMS_SUCCESS: {
            return Object.assign({}, state, { printingItems: action.payload, gotPrintingItems: true });
        }

        case BillingActions.GET_PRINTING_ITEMS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.GET_MOUNTING_ITEMS_SUCCESS: {
            return Object.assign({}, state, { mountingItems: action.payload, gotMountingItems: true });
        }

        case BillingActions.GET_MOUNTING_ITEMS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_ERROR: {
            return Object.assign({}, state, { isError: false });
        }

        case BillingActions.RESET_GOT_RENT_ITEMS: {
            return Object.assign({}, state, { gotRentItems: false });
        }

        case BillingActions.RESET_GOT_PRINTING_ITEMS: {
            return Object.assign({}, state, { gotPrintingItems: false });
        }

        case BillingActions.RESET_GOT_MOUNTING_ITEMS: {
            return Object.assign({}, state, { gotMountingItems: false });
        }

        case BillingActions.GENERATE_BILL_SUCCESS: {
            return Object.assign({}, state, { isBillGenerated: true })
        }

        case BillingActions.GENERATE_BILL_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_BILL_GENERATED: {
            return Object.assign({}, state, { isBillGenerated: false });
        }

        case BillingActions.GENERATE_MIGRATED_BILL_SUCCESS: {
            return Object.assign({}, state, { isMigratedBillGenerated: true });
        }

        case BillingActions.GENERATE_MIGRATED_BILL_FAILURE: {
            return Object.assign({}, state, { isError: true }); ``
        }

        case BillingActions.RESET_IS_MIGRATED_BILL_GENERATED: {
            return Object.assign({}, state, { isMigratedBillGenerated: false });
        }

        case BillingActions.GET_INVOICES: {
            return Object.assign({}, state, { invoices: [] });
        }

        case BillingActions.GET_INVOICES_SUCCESS: {
            return Object.assign({}, state, { invoices: action.payload, invoicesRecieved: true });
        }

        case BillingActions.GET_INVOICES_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_INVOICES_RECEIVED: {
            return Object.assign({}, state, { invoicesRecieved: false });
        }


        case BillingActions.GET_MIGRATED_INVOICES: {
            return Object.assign({}, state, { invoices: [] });
        }

        case BillingActions.GET_MIGRATED_INVOICES_SUCCESS: {
            return Object.assign({}, state, { invoices: action.payload, invoicesRecieved: true });
        }

        case BillingActions.GET_MIGRATED_INVOICES_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        // case BillingActions.RESET_MIGRATED_INVOICES_RECEIVED: {
        //     return Object.assign({}, state, { invoicesRecieved: false });
        // }

        case BillingActions.RESET_RENT_ITEMS: {
            return Object.assign({}, state, { rentItems: null });
        }

        case BillingActions.RESET_PRINTING_ITEMS: {
            return Object.assign({}, state, { printngItems: null });
        }

        case BillingActions.RESET_MOUNTING_ITEMS: {
            return Object.assign({}, state, { mountingItems: null });
        }

        case BillingActions.RESET_PENDING_RENT_ITEMS: {
            return Object.assign({}, state, { pendingRentItems: null });
        }

        case BillingActions.RESET_PENDING_PRINTING_ITEMS: {
            return Object.assign({}, state, { pendingPrintngItems: null });
        }

        case BillingActions.RESET_PENDING_MOUNTING_ITEMS: {
            return Object.assign({}, state, { pendingMountingItems: null });
        }

        case BillingActions.GET_PENDING_RENT_ITEMS: {
            return Object.assign({}, state, { pendingRentItems: null });
        }

        case BillingActions.GET_PENDING_RENT_ITEMS_SUCCESS: {
            return Object.assign({}, state, { pendingRentItems: action.payload });
        }

        case BillingActions.GET_PENDING_RENT_ITEMS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.GET_PENDING_PRINTING_ITEMS: {
            return Object.assign({}, state, { pendingPrintingItems: null });
        }

        case BillingActions.GET_PENDING_PRINTING_ITEMS_SUCCESS: {
            return Object.assign({}, state, { pendingPrintingItems: action.payload });
        }

        case BillingActions.GET_PENDING_PRINTING_ITEMS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.GET_PENDING_MOUNTING_ITEMS: {
            return Object.assign({}, state, { pendingMountingItems: null });
        }

        case BillingActions.GET_PENDING_MOUNTING_ITEMS_SUCCESS: {
            return Object.assign({}, state, { pendingMountingItems: action.payload });
        }

        case BillingActions.GET_PENDING_MOUNTING_ITEMS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        // case BillingActions.EDIT_REQUEST_SUCCESS: {
        //     return Object.assign({}, state, { isEditRequestSuccess: true });
        // }

        // case BillingActions.EDIT_REQUEST_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.CANCEL_REQUEST_SUCCESS: {
        //     return Object.assign({}, state, { isCancelRequestSuccess: true });
        // }

        // case BillingActions.CANCEL_REQUEST_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.APPROVE_REQUEST_ACCOUNTANT_SUCCESS: {
        //     return Object.assign({}, state, { isAccountantRequestApproved: true });
        // }

        // case BillingActions.APPROVE_REQUEST_ACCOUNTANT_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.REJECT_REQUEST_ACCOUNTANT_SUCCESS: {
        //     return Object.assign({}, state, { isAccountantRequestRejected: true });
        // }

        // case BillingActions.REJECT_REQUEST_ACCOUNTANT_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.APPROVE_REQUEST_ADMIN_SUCCESS: {
        //     return Object.assign({}, state, { isAdminRequestApproved: true });
        // }

        // case BillingActions.APPROVE_REQUEST_ADMIN_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.REJECT_REQUEST_ADMIN_SUCCESS: {
        //     return Object.assign({}, state, { isAdminRequestRejected: true });
        // }

        // case BillingActions.REJECT_REQUEST_ADMIN_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.RESET_IS_ACCOUNTANT_REQUEST_REJECTED: {
        //     return Object.assign({}, state, { isAccountantRequestRejected: false });
        // }

        // case BillingActions.RESET_IS_ADMIN_REQUEST_REJECTED: {
        //     return Object.assign({}, state, { isAdminRequestRejected: false });
        // }

        // case BillingActions.RESET_IS_ACCOUNTANT_REQUEST_APPROVED: {
        //     return Object.assign({}, state, { isAccountantRequestApproved: false });
        // }

        // case BillingActions.RESET_IS_ADMIN_REQUEST_APPROVED: {
        //     return Object.assign({}, state, { isAdminRequestApproved: false });
        // }

        // case BillingActions.REJECT_EDIT_REQUEST_ACCOUNTANT_SUCCESS: {
        //     return Object.assign({}, state, { isEditRequestRejected: true });
        // }

        // case BillingActions.REJECT_EDIT_REQUEST_ACCOUNTANT_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.REJECT_CANCEL_REQUEST_ACCOUNTANT_SUCCESS: {
        //     return Object.assign({}, state, { isCancelRequestRejected: true });
        // }

        // case BillingActions.REJECT_CANCEL_REQUEST_ACCOUNTANT_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.RESET_IS_ACCOUNTANT_EDIT_REQUEST_REJECTED: {
        //     return Object.assign({}, state, { isEditRequestRejected: false });
        // }

        // case BillingActions.RESET_IS_ACCOUNTANT_CANCEL_REQUEST_REJECTED: {
        //     return Object.assign({}, state, { isCancelRequestRejected: false });
        // }

        // case BillingActions.APPROVE_EDIT_REQUEST_ACCOUNTANT_SUCCESS: {
        //     return Object.assign({}, state, { isEditRequestApproved: true });
        // }

        // case BillingActions.APPROVE_EDIT_REQUEST_ACCOUNTANT_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.APPROVE_CANCEL_REQUEST_ACCOUNTANT_SUCCESS: {
        //     return Object.assign({}, state, { isCancelRequestApproved: true });
        // }

        // case BillingActions.APPROVE_CANCEL_REQUEST_ACCOUNTANT_FAILURE: {
        //     return Object.assign({}, state, { isError: true });
        // }

        // case BillingActions.RESET_IS_ACCOUNTANT_EDIT_REQUEST_APPROVED: {
        //     return Object.assign({}, state, { isEditRequestApproved: false });
        // }

        // case BillingActions.RESET_IS_ACCOUNTANT_CANCEL_REQUEST_APPROVED: {
        //     return Object.assign({}, state, { isCancelRequestApproved: false });
        // }

        case BillingActions.GET_TEMPORARY_INVOICE_SUCCESS: {
            return Object.assign({}, state, { tempInvoice: action.payload });
        }

        case BillingActions.GET_TEMPORARY_INVOICE_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_TEMP_INVOICE: {
            return Object.assign({}, state, { tempInvoice: null });
        }

        case BillingActions.SUBMIT_BILL_SUCCESS: {
            return Object.assign({}, state, { isBillSubmitted: true });
        }

        case BillingActions.SUBMIT_BILL_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_BILL_SUBMITTED: {
            return Object.assign({}, state, { isBillSubmitted: false });
        }

        case BillingActions.SUBMIT_MIGRATED_BILL_SUCCESS: {
            return Object.assign({}, state, { isMigratedBillSubmitted: true });
        }

        case BillingActions.SUBMIT_MIGRATED_BILL_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_MIGRATED_BILL_SUBMITTED: {
            return Object.assign({}, state, { isMigratedBillSubmitted: false });
        }





        case BillingActions.UPLOAD_PO_SUCCESS: {
            return Object.assign({}, state, { isPOUploaded: true });
        }

        case BillingActions.UPLOAD_PO_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_PO_UPLOADED: {
            return Object.assign({}, state, { isPOUploaded: false });
        }

        // ==============================================================================

        case BillingActions.EDIT_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditRequestSuccess: true });
        }

        case BillingActions.EDIT_REQUEST_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditRequestSuccess: false });
        }

        case BillingActions.EDIT_DISCARD_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditDiscardRequestSuccess: true });
        }

        case BillingActions.EDIT_DISCARD_REQUEST_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_DISCARD_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditDiscardRequestSuccess: false });
        }

        case BillingActions.CANCEL_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestSuccess: true });
        }

        case BillingActions.CANCEL_REQUEST_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_CANCEL_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestSuccess: false });
        }



        case BillingActions.EDIT_MIGRATED_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditMigratedRequestSuccess: true });
        }

        case BillingActions.EDIT_MIGRATED_REQUEST_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_MIGRATED_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditMigratedRequestSuccess: false });
        }

        case BillingActions.EDIT_DISCARD_MIGRATED_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditDiscardRequestSuccess: true });
        }

        case BillingActions.EDIT_DISCARD_REQUEST_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_DISCARD_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isEditDiscardRequestSuccess: false });
        }

        case BillingActions.CANCEL_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestSuccess: true });
        }

        case BillingActions.CANCEL_REQUEST_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_CANCEL_REQUEST_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestSuccess: false });
        }




        case BillingActions.APPROVE_GEN_ADMIN_SUCCESS: {
            return Object.assign({}, state, { isGenerateRequestApprovedAdmin: true });
        }

        case BillingActions.APPROVE_GEN_ADMIN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_GENERATE_REQUEST_APPROVED_ADMIN: {
            return Object.assign({}, state, { isGenerateRequestApprovedAdmin: false });
        }

        case BillingActions.APPROVE_GEN_ACCOUNTANT_SUCCESS: {
            return Object.assign({}, state, { isGenerateRequestApprovedAccountant: true });
        }

        case BillingActions.APPROVE_GEN_ACCOUNTANT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_GENERATE_REQUEST_APPROVED_ACCOUNTANT: {
            return Object.assign({}, state, { isGenerateRequestApprovedAccountant: false });
        }

        case BillingActions.APPROVE_EDIT_ADMIN_SUCCESS: {
            return Object.assign({}, state, { isEditRequestApprovedAdmin: true });
        }

        case BillingActions.APPROVE_EDIT_ADMIN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_REQUEST_APPROVED_ADMIN: {
            return Object.assign({}, state, { isEditRequestApprovedAdmin: false });
        }

        case BillingActions.APPROVE_EDIT_ACCOUNTANT_SUCCESS: {
            return Object.assign({}, state, { isEditRequestApprovedAccountant: true });
        }

        case BillingActions.APPROVE_EDIT_ACCOUNTANT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_REQUEST_APPROVED_ACCOUNTANT: {
            return Object.assign({}, state, { isEditRequestApprovedAccountant: false });
        }

        case BillingActions.APPROVE_CANCEL_ADMIN_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestApprovedAdmin: true });
        }

        case BillingActions.APPROVE_CANCEL_ADMIN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_CANCEL_REQUEST_APPROVED_ADMIN: {
            return Object.assign({}, state, { isCancelRequestApprovedAdmin: false });
        }

        case BillingActions.APPROVE_CANCEL_ACCOUNTANT_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestApprovedAccountant: true });
        }

        case BillingActions.APPROVE_CANCEL_ACCOUNTANT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_CANCEL_REQUEST_APPROVED_ACCOUNTANT: {
            return Object.assign({}, state, { isCancelRequestApprovedAccountant: false });
        }

        case BillingActions.REJECT_GEN_ADMIN_SUCCESS: {
            return Object.assign({}, state, { isGenerateRequestRejectedAdmin: true });
        }

        case BillingActions.REJECT_GEN_ADMIN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_GENERATE_REQUEST_REJECTED_ADMIN: {
            return Object.assign({}, state, { isGenerateRequestRejectedAdmin: false });
        }

        case BillingActions.REJECT_GEN_ACCOUNTANT_SUCCESS: {
            return Object.assign({}, state, { isGenerateRequestRejectedAccountant: true });
        }

        case BillingActions.REJECT_GEN_ACCOUNTANT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_GENERATE_REQUEST_REJECTED_ACCOUNTANT: {
            return Object.assign({}, state, { isGenerateRequestRejectedAccountant: false });
        }

        case BillingActions.REJECT_EDIT_ADMIN_SUCCESS: {
            return Object.assign({}, state, { isEditRequestRejectedAdmin: true });
        }

        case BillingActions.REJECT_EDIT_ADMIN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_REQUEST_REJECTED_ADMIN: {
            return Object.assign({}, state, { isEditRequestRejectedAdmin: false });
        }

        case BillingActions.REJECT_EDIT_ACCOUNTANT_SUCCESS: {
            return Object.assign({}, state, { isEditRequestRejectedAccountant: true });
        }

        case BillingActions.REJECT_EDIT_ACCOUNTANT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_EDIT_REQUEST_REJECTED_ACCOUNTANT: {
            return Object.assign({}, state, { isEditRequestRejectedAccountant: false });
        }

        case BillingActions.REJECT_CANCEL_ADMIN_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestRejectedAdmin: true });
        }

        case BillingActions.REJECT_CANCEL_ADMIN_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_CANCEL_REQUEST_REJECTED_ADMIN: {
            return Object.assign({}, state, { isCancelRequestRejectedAdmin: false });
        }

        case BillingActions.REJECT_CANCEL_ACCOUNTANT_SUCCESS: {
            return Object.assign({}, state, { isCancelRequestRejectedAccountant: true });
        }

        case BillingActions.REJECT_CANCEL_ACCOUNTANT_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_IS_CANCEL_REQUEST_REJECTED_ACCOUNTANT: {
            return Object.assign({}, state, { isCancelRequestRejectedAccountant: false });
        }

        case BillingActions.GET_INVOICE_TASK_CARDS_SUCCESS: {
            return Object.assign({}, state, { invoiceTaskCards: action.payload });
        }

        case BillingActions.GET_INVOICE_TASK_CARDS_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case BillingActions.RESET_INVOICE_TASK_CARDS: {
            return Object.assign({}, state, { invoiceTaskCards: [] });
        }

        default: {
            return state;
        }
    }

}

