<div *ngIf="isloading">
  <p-progressSpinner class="loading"></p-progressSpinner>
</div>
<div class="profile ui-g-12 card">
  <form #userProfileForm="ngForm">
    <div class="ui-g-12 profile-container">
      <div class="name-container ui-g-12">
        <h2>
          {{firstName}} {{lastName}}
        </h2>
      </div>
      <div class="ui-g-12 padding-left-5">
        <!-- <div class="photo-container ui-g-2">
          <div class="profile" class="customer-image-container">
            <img class="customer-image" src="{{imageName}}" />
          </div>

          <div class="customer-upload-btn-containers">
            <p-fileUpload #fileRef mode="basic" [chooseLabel]="'UPLOAD'" [styleClass]="'upload-btn mtab-primary'" [maxFileSize]="1000000"
              name="demo[]" accept="image/*" [auto]="true" (onSelect)=" onFileSelection($event)">
            </p-fileUpload>
          </div>
        </div> -->
        <div class="ui-g-3">
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
                  <input id="firstname" type="text" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.firstName" name="firstname" required/>
                  <label for="firstname">First Name <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
                  <input id="lastname" type="text" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.lastName" name="lastname" required/>
                  <label for="lastname">Last Name <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
                <input id="number" type="text" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.number" name="number" required/>
                <label for="number">Phone <span class="required">*</span></label>
            </span>
          </div>
          <div class="ui-g-12 ui-fluid inputfield">
            <span class="md-inputfield">
                <input disabled id="email" type="text" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.emailId" name="email" />
                <label for="email">Email</label>
              </span>
          </div>
        </div>
        <div class="additional-info-container ui-g-7">
          <div class="info ui-g-12">
            <h3>Additional Information</h3>
          </div>
          <div class="ui-g-12">
            <div class="ui-g-3">
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                      <p-dropdown id="gender" [autoWidth]="false" [(ngModel)]="user.gender" [autoDisplayFirst]="false" [options]="genders" name="gender"></p-dropdown>
                        <label for="gender">Gender
                        </label>
                  </span>
              </div>

              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                        <p-calendar #dob  id="dob" appendTo="body" name="dateofbirth" dateFormat="ddMy"
                          [(ngModel)]="user.dateOfBirth" monthNavigator="true" yearNavigator="true" [yearRange]="'1950:2018'" [utc]="true"
                          [showIcon]="true"></p-calendar>
                        <label for="dob">DOB</label>
                  </span>
              </div>

              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                    <p-dropdown id="blood-Group" [autoWidth]="false" [(ngModel)]="user.bloodGroup" [autoDisplayFirst]="false" [options]="bloodGroups" name="bloodGroup"></p-dropdown>
                      <label for="blood-Group">Blood Group
                      </label>
                    </span>
              </div>

            </div>
            <div class="ui-g-3">
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                      <!-- (completeMethod)="filterStateList($event)" -->
                    <p-autoComplete  [(ngModel)]="user.state" [suggestions]="filteredStateList" (completeMethod)="filterStateList($event)"
                        (onSelect)="onStateSelect()" styleClass="wid10" [minLength]="0" field="name"
                        [multiple]="false" [dropdown]="true" name="states" forceSelection="true">
                    </p-autoComplete>
                    <label>State</label>
                  </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                    <p-autoComplete  [suggestions]="filteredDistrictList" (completeMethod)="filterDistrictList($event)"
                      [(ngModel)]="user.district" (onSelect)="onSelectDistrict()" styleClass="wid10" [minLength]="0" 
                        field="name" [multiple]="false" [dropdown]="true" name="districts" forceSelection="true">
                    </p-autoComplete>
                    <label>District</label>
                  </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="ui-float-label">
                      <p-autoComplete  [suggestions]="filteredCitiesList" (completeMethod)="filterCityList($event)"
                      [(ngModel)]="user.city" styleClass="wid10" [minLength]="0" field="name" [multiple]="false" [dropdown]="true"
                          name="cities" forceSelection="true">
                      </p-autoComplete>
                      <label>City</label>
                    </span>
              </div>
            </div>
            <div class="ui-g-3">
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                      <input id="postalcode" type="text" minlength="6" maxlength="6" pInputText  [(ngModel)]="user.postalCode" name="postalcode"/>
                      <label for="postalcode">Postal Code</label>
                  </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                        <input id="emergencynumber" type="text" minlength="0" maxlength="10" pInputText  [(ngModel)]="user.emergencyContactNumber" name="enumber"/>
                        <label for="emergencynumber">Emergency Number</label>
                    </span>
              </div>
              <div class="ui-g-12 ui-fluid inputfield">
                <span class="md-inputfield">
                      <input id="emergencypersonname" type="text" pInputText  [(ngModel)]="user.emergencyContactPersonName" name="econtactperson"/>
                      <label for="emergencypersonname">Contact Person</label>
                  </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="email-password-setup ui-g-12 padding-left-5">
      <div class="sib-credentials-container ui-g-4">
        <div class="ui-g-12">
          <h2 class="heading">
            ShowItBig Credentials
          </h2>
          <span class="heading-secondary">These Credentials are used for logging into ShowItbig system.</span>
        </div>
        <div class="user-name ui-g-12">
          <span>User Name</span>
          <div>{{user.emailId}}</div>
        </div>
        <div class="ui-g-12 password">
          <h2 class="heading">
            Change Password
          </h2>
        </div>
        <div style="width:80%;" class="ui-g-12 ui-fluid inputfield">
          <span class="md-inputfield">
          <input   #sibpassword id="password" [(ngModel)]="sibPassword" type="password" minlength="0" maxlength="15" pInputText name="password" />
          <label for="password">New Password</label>
        </span>
        </div>
        <div style="width:80%;" class="ui-g-12 ui-fluid inputfield">
          <span class="md-inputfield">
            <input   id="confirmpassword"  [class.ng-invalid]="sibPassword && !sibPasswordVerified" [(ngModel)]="sibConfirmPassword" (keyup)="verifySibPassword()" type="password" minlength="0" maxlength="15" pInputText name="confirmpassword"/>
            <label for="confirmpassword">Confirm Password</label>
          </span>
          <span *ngIf="sibPassword && !sibPasswordVerified" class="required">Required field.</span>
        </div>
      </div>
      <div class="sib-email-setup ui-g-3">
        <div class="ui-g-12">
          <h2 class="heading">
            ShowItBig Email Setup
          </h2>
          <span class="heading-secondary">Setup Email for Client Communication.</span>
        </div>

        <!-- <div class="personal-email-container ui-g-12 ui-fluid inputfield">
          <span>Personal Email</span>
          <p-radioButton name="emailgroup" value="userEmail" label="{{user.emailId}}" [(ngModel)]="emailSelected"></p-radioButton>
          <div class="ui-g-12">
            <div *ngIf="emailSelected === 'userEmail'" class="ui-g-12 ui-fluid inputfield">
              <span class="md-inputfield">
              <input  #emailPassword id="passsword"  type="password" minlength="0" maxlength="15" pInputText  [(ngModel)]="user.emailConfig.password" name="useremailpassword" />
              <label for="passsword">Password</label>
            </span>
            </div>
            <div *ngIf="emailSelected === 'userEmail'" class="ui-g-12 ui-fluid inputfield">
              <span class="md-inputfield">
              <input  #emailConfirmPassword id="confirmpasssword" [class.ng-invalid]="emailSelected==='userEmail' && !isUserEmailVerified"  type="password" minlength="0" maxlength="15" pInputText [(ngModel)]="confirmPasswordValue"  (keyup)="verifyUserPassword(emailConfirmPassword.value)" name="useremailconfirmpassword" />
              <label for="confirmpasssword">Confirm Password</label>
            </span>
            <span *ngIf="emailSelected==='userEmail' && !isUserEmailVerified" class="required">Required field.</span>
            </div>
          </div>
        </div> -->

        <div class="personal-email-container ui-g-12 ui-fluid inputfield">
          <span>Organization Email</span>
          <p-radioButton name="emailgroup" value="orgEmail" label="{{organizationEmail}}" [(ngModel)]="emailSelected"></p-radioButton>
        </div>


      </div>
    </div>
  </form>
</div>
<div style="text-align: center;">
  <!-- <button class="mtab-primary" type="submit" label="RESET" pButton></button> -->
  <button [disabled]="!userProfileForm.form.valid || (sibPassword && !sibPasswordVerified) || (!emailSelected || ((emailSelected==='userEmail' && !isUserEmailVerified) || (emailSelected==='userEmail' && !user.emailConfig.password)))"
    (click)="updateUser()" class="mtab-save" type="button" label="UPDATE" pButton></button>
</div>