import { Injectable } from "@angular/core";
import { SibMasterService } from "./sib-master.service";

@Injectable()
export class AuthConfigService {

    config: any = {};


    private vendor1 = {
        authApiUrl: 'https://vendor1.auth0.com/api/v2/',
        apiAccessTokenUrl: 'https://vendor1.auth0.com/oauth/token',
        apiConfigCredentials: {
            "grant_type": "client_credentials",
            "client_id": "rEBP8b12yPBEZmkDMnA9l8D9nffKkApv",
            "client_secret": "HpkkP3ul6vY5E7PLQ1f3Fdy6nzHRNJlsLwtr-vmE_kSLAWCAi_BKoQmYlLH6Lc1h",
            "audience": "https://vendor1.auth0.com/api/v2/"
        },

        authUserManagementConfig: {
            "client_id": "h8Du5Nhzibif3tICsEG96ItAuZ92pqUU",
            "client_secret": "7AeLbTZQepMKgYAoyXQ5E_XN6nWNM0Msp3NcgnBdKR5ViEnCC01fluudEi4y_6q7",
            "audience": "urn:auth0-authz-api",
            "grant_type": "client_credentials"
        },

        authConfig: {
            clientID: 'QawsariygVDDUJ6FvZ0nkPV9iO0EVi6e',
            // clientId : 'rEBP8b12yPBEZmkDMnA9l8D9nffKkApv',
            domain: 'vendor1.auth0.com',
            responseType: 'token id_token',
            audience: 'sib-api',
            redirectUri: 'http://vendor1.showitbig.com:4200',
            scope: 'openid'
        }
    }

    private vendor2 = {
        authApiUrl: 'https://showitbig-dev.auth0.com/api/v2/',
        apiAccessTokenUrl: 'https://showitbig-dev.auth0.com/oauth/token',
        apiConfigCredentials: {
            grant_type: 'client_credentials',
            client_id: '6TWrLXVCcGCv0M3H4n7LTdGlzJe0o2Ra',
            client_secret: 'yFTAyTaiPbXn3C6GOAx2_6okCCtsXvTRJ84eBR_XnKxkT5UPImmGB6Ilfk8CFm0A',
            audience: 'https://showitbig-dev.auth0.com/api/v2/'
        },

        authConfig: {
            clientID: 'cBnoQRrK7cxy5B1AfEZGnX7AGPDoXHqd',
            domain: 'showitbig-dev.auth0.com',
            responseType: 'token id_token',
            audience: 'sib-dev-api',
            redirectUri: 'http://vendor2.showitbig.com:4200',
            scope: 'openid'
        }
        // authConfig: {
        //     clientID: 'SWy7qD7WEn0xxvg6y2iXiamUP3iz7aG1',
        //     domain: 'vendor2.auth0.com',
        //     responseType: 'token id_token',
        //     audience: 'https://vendor2.auth0.com/userinfo',
        //     redirectUri: 'http://vendor2.showitbig.com:4200',      
        //     scope: 'openid'
        // }
    }

    private localhost = {
        authConfig: {
            clientID: 'SWy7qD7WEn0xxvg6y2iXiamUP3iz7aG1',
            domain: 'vendor2.auth0.com',
            responseType: 'token id_token',
            audience: 'https://vendor2.auth0.com/userinfo',
            redirectUri: 'http://vendor2.showitbig.com:4200',
            scope: 'openid'
        }
    }

    private dev = {

        authApiUrl: 'https://showitbig.auth0.com/api/v2/',
        apiAccessTokenUrl: 'https://showitbig.auth0.com/oauth/token',
        apiConfigCredentials: {
            "grant_type": "client_credentials",
            "client_id": "6Vwz0GkODsFzFfvFKqjrt9ftgRol8EcP",
            "client_secret": "8SeEYf6dQWQk1TUWcUJjQ_V11mxISuNGKxFFCiVlMJM7jNx5aMoZr2I3fDAxGXNY",
            "audience": "https://showitbig.auth0.com/api/v2/"
        },

        authConfig: {
            clientID: 'K2kfjw2yjpOc7R2waoJgUUf6zMQRYcXY',
            domain: 'showitbig.auth0.com',
            responseType: 'token id_token',
            audience: 'sib-api',
            redirectUri: 'https://showitbig.medpharmservices.com',
            scope: 'openid'
        }
    }

    private devEnvironment = {

        authApiUrl: 'https://showitbig-dev.auth0.com/api/v2/',
        apiAccessTokenUrl: 'https://showitbig-dev.auth0.com/oauth/token',
        apiConfigCredentials: {
            grant_type: 'client_credentials',
            client_id: '6TWrLXVCcGCv0M3H4n7LTdGlzJe0o2Ra',
            client_secret: 'yFTAyTaiPbXn3C6GOAx2_6okCCtsXvTRJ84eBR_XnKxkT5UPImmGB6Ilfk8CFm0A',
            audience: 'https://showitbig-dev.auth0.com/api/v2/'
        },

        authConfig: {
            clientID: 'cBnoQRrK7cxy5B1AfEZGnX7AGPDoXHqd',
            domain: 'showitbig-dev.auth0.com',
            responseType: 'token id_token',
            audience: 'sib-dev-api',
            redirectUri: 'https://dev.showitbig.com',
            scope: 'openid'
        }

    }
    //https://showitbig-qa.auth0.com/api/v2/
    private qaEnvironment = {

        authApiUrl: 'https://showitbig-qa.auth0.com/api/v2/',
        apiAccessTokenUrl: 'https://showitbig-qa.auth0.com/oauth/token',
        apiConfigCredentials: {
            grant_type: "client_credentials",
            client_id: "InxCER9549Aex3Sd1k1H1gxKPgZwqjL1",
            client_secret: "NF8A5KzuaT9T9Ia_Qrw6hNRC9d8qoO26LKDtkxrmGgJDer3mHiIRB2dwn5A6cok-",
            audience: "https://showitbig-qa.auth0.com/api/v2/"
        },

        authConfig: {
            clientID: '8H462rT9GbGzN9Nz1o4k4rMCZW2HQV34',
            domain: 'showitbig-qa.auth0.com',
            responseType: 'token id_token',
            audience: 'sib-qa-api',
            redirectUri: 'https://qa.showitbig.com',
            scope: 'openid'
        }
    }

    private stagingEnvironment = {

        authApiUrl: 'https://showitbig-staging.auth0.com/api/v2/',
        apiAccessTokenUrl: 'https://showitbig-staging.auth0.com/oauth/token',
        apiConfigCredentials: {
            grant_type: "client_credentials",
            client_id: "AWvJWfJPdeiDv7TM030XoSDdf9asuQcd",
            client_secret: "xnCW0cd1F0bdrGEtDreEuhnAWVSxiiNXMbtgUeVzIQqEnsbj_XwjnnII4MFOYsdc",
            audience: "https://staging.auth0.com/api/v2/"
        },

        authConfig: {
            clientID: 'kR6DiFdf9bHKsuJ66CtVh7iJtIqFZfmK',
            domain: 'staging.auth0.com',
            responseType: 'token id_token',
            audience: 'sib-staging-api',
            redirectUri: 'https://staging.showitbig.com',
            scope: 'openid'
        }
    }

    private prodEnvironment = {

        authApiUrl: 'https://staging-chitra.auth0.com/api/v2/',
        apiAccessTokenUrl: 'https://staging-chitra.auth0.com/oauth/token',
        apiConfigCredentials: {
            grant_type: "client_credentials",
            client_id: "uenyVHyp26cH2PBnEJoYbC2O06vfdD33",
            client_secret: "W3iJLEbBeooThra_HyPfKNRZjP5rsscgpdAx-bz6daAYYdxKIrCtqp9Ijwmyisz6",
            audience: "https://showitbig-chitra.auth0.com/api/v2/"
        },

        authConfig: {
            clientID: 'wHdKv5JT0d6o8NS1ZN04Bqq2d8l66X1N',
            domain: 'showitbig-chitra.auth0.com',
            responseType: 'token id_token',
            audience: 'sib-chitra-api',
            redirectUri: 'https://chitra.showitbig.com',
            scope: 'openid'
        }
    }
    constructor(
        // private sibMasterService: SibMasterService
    ) {
        if (document.location.href.indexOf('vendor1') > -1) {
            this.config = this.vendor1
        } else if (document.location.href.indexOf('vendor2') > -1) {
            this.config = this.vendor2
        } else  if (document.location.href.indexOf('localhost') > -1) {
            this.config = this.vendor1
        } else if (document.location.href.indexOf('35.226.247.247') > -1) {
            this.config = this.vendor1
        } else if (document.location.href.indexOf('medpharmservices') > -1) {
            this.config = this.dev
        } else if (document.location.href.indexOf('dev.showitbig.com') > -1) {
            this.config = this.devEnvironment;
        } else if (document.location.href.indexOf('qa.showitbig.com') > -1) {
            this.config = this.qaEnvironment;
        } else if (document.location.href.indexOf('chitra.showitbig.com') > -1) {
            this.config = this.prodEnvironment;
        } else if (document.location.href.indexOf('staging.showitbig.com') > -1) {
            this.config = this.stagingEnvironment;
        } 
        // else if (document.location.href.indexOf('staging-chitra.showitbig.com') > -1) {
        //     this.config = this.stagingEnvironment;
        // }


    }
}