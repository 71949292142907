import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, DropdownModule, CalendarModule, TooltipModule, DialogModule, ProgressSpinnerModule } from "primeng/primeng";
import { AssignToPlanDialogComponent } from "./assign-to-plan-dialog.component";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { DirectiveModules } from '../../../modules/directive.modules';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        InputTrimModule,
        ProgressSpinnerModule,
        DialogModule,
        DirectiveModules,
    ],

    declarations: [
        AssignToPlanDialogComponent
    ],

    exports: [
        AssignToPlanDialogComponent
    ],

    entryComponents: [
        AssignToPlanDialogComponent
    ],

    providers: []
})

export class AssignToPlanDialogModule { }
