import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule, DataTableModule, TooltipModule, ProgressSpinnerModule, DropdownModule } from "primeng/primeng";
import { SibGridModule } from "../components/sib-forms/grid/grid.module";
import { PhotoDialogModule } from "../shared/components/photo-dialog/photo-dialog.module";
import { HoardingGroupsComponent } from "./hoarding-groups.component";
import { HoardingFunctionalService } from "../hoardings/services/hoarding-functional.service";
import { FindHoardingInGroupService } from "../services/shared/findHoardingInGroup.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "../services/tokeninterceptor/tokeninterceptor.service";
import { HoardingGroupService } from "../services/shared/hoardingGroup.service";
import { StatusQueueDialogModule } from "../shared/components/status-queue-dialog/status-queue-dialog.module";
import { HoardingGroupEditDialogModule } from "../shared/components/hoarding-group-edit-dialog/hoarding-group-edit-dialog.module";
import { ActionDispatcher } from "../app state/action-dispatcher/action-dispatcher";
import { UserGridConfigService } from "../services/shared/user-grid-config.service";
import { CommonHoardingGroupModule } from "../shared/components/common-hoarding-group/common-hoarding-group.module";
import { DirectiveModules } from "../modules/directive.modules";
import { RouterModule } from '@angular/router';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        SibGridModule,
        DropdownModule,
        DataTableModule,
        TooltipModule,
        PhotoDialogModule,
        StatusQueueDialogModule,
        HoardingGroupEditDialogModule,
        ProgressSpinnerModule,
        CommonHoardingGroupModule,
        DirectiveModules,
        RouterModule
    ],

    declarations: [
       HoardingGroupsComponent
    ],

    exports: [ HoardingGroupsComponent ],

    providers: [
        HoardingFunctionalService,
        HoardingGroupService,
        FindHoardingInGroupService,
        ActionDispatcher,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        UserGridConfigService
    ]
})

export class HoardingGroupsModule { }
