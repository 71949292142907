import { Component, ViewEncapsulation, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "../../../../../../node_modules/rxjs/Rx";
import { Customer } from "../../../../modals/KYC/customer";
import { CustomerProfilePageService } from "../services/customer-profile-page.service";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
    selector: 'sib-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CustomerProfilePageService]
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {

    subscription: Subscription[] = [];
    customerId: string;
    customer: Customer = new Customer();

    constructor(
        private _route: ActivatedRoute,
        private customerProfilePageService: CustomerProfilePageService
    ) {
        this.subscription.push(this._route.params.subscribe((params: Params) => {
            if (params) {
                this.customerId = params['customerId'];
            }
        }));
    }

    ngOnInit() {
        this.subscription.push(this.customerProfilePageService.getCustomerById(this.customerId).subscribe((res: any) => {
            this.customer = res;
        }));
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }
}

