import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, DropdownModule, CalendarModule, TooltipModule, TreeTableModule } from "primeng/primeng";
import { InputTrimModule } from "../../../directives/input-trim";
import { CommonTreeTableComponent } from "./tree-table.component";





@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        InputTrimModule,
        TreeTableModule
    ],

    declarations: [
        CommonTreeTableComponent
    ],

    exports: [
        CommonTreeTableComponent
    ],
    providers: []
})

export class CommonTreeTableModule { }