import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, TooltipModule, FileUploadModule, DropdownModule, RadioButtonModule, CalendarModule, ProgressBarModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { UploadImageDialogComponent } from "./upload-image-dialog.component";
import { UploadImageService } from "../../../services/shared/upload-images.service";
import { CampaignActionDispatcher } from "../../../campaigns/action-dispatcher/action-dispatcher";
import { CampaignActions } from "../../../campaigns/actions/campaign-actions";
// import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        FileUploadModule,
        DropdownModule,
        RadioButtonModule,
        CalendarModule,
        ProgressBarModule
        // NgxDropzoneModule
    ],

    declarations: [
        UploadImageDialogComponent
    ],

    exports: [
        UploadImageDialogComponent
    ],

    providers: [UploadImageService,
        CampaignActions,
        CampaignActionDispatcher]
})

export class UploadImageDialogModule { }
