import { Component, Injectable, ViewChild, ElementRef, ViewEncapsulation, OnInit, Input, Inject } from '@angular/core';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';

import { TitleService } from './services/title.service';
import { Subscription, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './login-service/auth.service';
import { UserService } from './services/shared/user.service';
import { PlanService } from './services/shared/plan.service';
import { DateUtil } from './helpers/date.util';
import { LoaderSubjects } from './modals/loader-subjects/loader-subjects';
import { PPTLoader } from './modals/loader-subjects/pptloader';
import { GridLoader } from './modals/loader-subjects/grid-loader';
import { CampaignService } from './services/shared/campaign.service';
import { PhotoLoader } from './modals/loader-subjects/photo-loader';
import * as fileUtils from './helpers/file.util'
import { ActionDispatcher } from './app state/action-dispatcher/action-dispatcher';
import { PptGeneratorService } from './services/shared/ppt-generator.service';
import { PptGenerationData } from './modals/ppt/pptgenerationdata';
import { switchMap } from 'rxjs/operators';
import { HoardingLightEnum } from './shared/constants/hoarding-light-enum';
import { CalculationService } from './services/shared/calculationService';
import { NotificatoinsService } from './services/notifications/notifications.service';
import { AppUrls } from './services/urls';
import { map, catchError } from 'rxjs/operators';
import { PendingUnbilledUIService } from './shared/components/pending-unbilled-tasks/services/pending-unbilled-ui.service';
import { InvoiceChartService } from './services/shared/invoice-chart.service';
import { HoardingStatusService } from './hoardings-operation/services/hoardings-status.service';
import { SuperBillsService } from './billings/services/superbill.service';
import { MenuItem } from 'primeng/primeng';
import { Picture } from './modals/hoardings/picture';
import { SystemService } from './services/shared/system.service';
import { VendorService } from './services/shared/vendor.service';
import { SIBConstants } from './shared/constants/SIBConstant';
import { PointOfContactService } from './customers/services/pointofcontact.services';
import { MediaSubCategoryEnum } from './shared/constants/media-sub-category-enum';
import { HoardingService } from './contracts/services/hoardings.service';
import { HoardingGroupService } from './contracts/services/hoarding-group.service';
import { KioskService } from './services/shared/kiosk.service';
import { ContractService } from './contracts/services/contract.services';
import { SalesChartService } from './services/shared/sales-chart.service';
import { ImageTypeEnum } from './shared/constants/image-type-enum';
import { NotificationsMessages } from './services/shared/notifications-messages';
import { DashboardService } from './dashboard/service/dashboard.service';
import { DOCUMENT, Title } from '@angular/platform-browser';
import { PlanTemplateService } from './services/shared/plan-template.service';
import { ErrorUtil } from './helpers/error.utils';
import * as utils from './helpers/utils';

@Component({
    selector: 'app-topbar',
    templateUrl: "./topbar.component.html",
    styleUrls: ["./topbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [PointOfContactService]
})
@Injectable()
export class AppTopBar implements OnInit {

    showPassBook = false;

    userProfile: any;
    userCompleteProfile: any;
    title: string;
    appTitleSubscription: Subscription;
    showUsers: boolean = false;
    image: string;
    userData: any;
    profileInitials: any;
    display: boolean = false;
    displaySuggestionDialog: boolean = false;
    helpItems: MenuItem[];
    //---------------------------------------------PPT download variables------------------------------------

    pptloader: PPTLoader = new PPTLoader()
    resetPptloader: PPTLoader = new PPTLoader();
    //---------------------------------------------------------------------------------------------------------

    //-----------------------------------------------XLS download variables------------------------------------

    xlsLoader: PPTLoader = new PPTLoader();
    resetXlsLoader: PPTLoader = new PPTLoader();

    //----------------------------------------------------GridLoader--------------------------------------------
    gridLoader: GridLoader = new GridLoader();

    //----------------------------------------------------PhotoLoader---------------------------------
    photoLoader: PhotoLoader = new PhotoLoader();
    mountingPhotoLoader: PhotoLoader = new PhotoLoader();
    monitoringPhotoLoader: PhotoLoader = new PhotoLoader();
    resetPhotoLoader: PhotoLoader = new PhotoLoader();
    pptGenerationObjects: PptGenerationData[] = [];
    planTermAndCondtions: any[] = [];
    campaignTermAndConditions: any[] = [];
    // campaignItemTypeImages: CampaignItemType = new CampaignItemType();
    displayCallDialog: boolean = false;

    logoUrl: string;
    pptEmailUrl: string;
    tenantId: string;
    isLoading: boolean;

    pptText: string;
    pptRedirectUrl: string;
    defaultLogoUrl: string;
    applicationTitle: string;
    portalBaseUrl: string;

    rollbackToPlan: any;
    resetrollbackToPlanloader = {};


    constructor(
        public app: MainComponent,
        public appTitle: TitleService,
        private router: Router,
        private auth: AuthService,
        private userService: UserService,
        private loaderSubjects: LoaderSubjects,
        private planService: PlanService,
        private campaignService: CampaignService,
        private pendingUnbilledUIService: PendingUnbilledUIService,
        private invoiceChartService: InvoiceChartService,
        private actionDispatcher: ActionDispatcher,
        private pptGeneratorService: PptGeneratorService,
        private calculationService: CalculationService,
        private notificationServcie: NotificatoinsService,
        private hoardingOperationService: HoardingStatusService,
        private superBillsService: SuperBillsService,
        private systemService: SystemService,
        private vendorService: VendorService,
        private pointOfContactService: PointOfContactService,
        private hoardingService: HoardingService,
        private hoardingGroupService: HoardingGroupService,
        private kioskService: KioskService,
        private contractService: ContractService,
        private salesChartService: SalesChartService,
        private dashboardService: DashboardService,
        private planTemplateService: PlanTemplateService,
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private pageTitle: Title,

    ) {
        // this.titleService.emitTitle.subscribe((data) => { console.log("the data catched in topbar is", data); this.title = data })
        this.setTitleSubscription();
        // this.userProfile = this.auth.getUserProfile();
        this.userProfile = this.auth.getUserInfo()
        // this.getVendorLogoUrl();
        // this.userCompleteProfile = JSON.parse(localStorage.getItem('userInfo'));
    }

    ngOnInit() {

        this.getVendorInformation();
        this.actionDispatcher.enablePassbook.subscribe((value) => {
            if (value) {
                this.showPassBook = value;
            } else {
                this.showPassBook = false;
            }
        });

        // this.actionDispatcher.planConfigurations.subscribe((planConfigurations) => {
        //     if (planConfigurations) {
        //         this.planTermAndCondtions = planConfigurations.filter(config => config.key === 'TERM_AND_CONDITION')
        //     }
        // })

        // this.actionDispatcher.campaignConfigurations.subscribe((campaignConfigurations) => {
        //     if (campaignConfigurations) {
        //         this.campaignTermAndConditions = campaignConfigurations.filter(config => config.key === 'TERM_AND_CONDITION')
        //     }
        // })
        this.systemService.get({ module: 'PLAN', key: 'TERM_AND_CONDITION' }, '/byMoudule/key').subscribe(
            (response) => {
                if (response) {
                    this.planTermAndCondtions = response['value'];
                    // console.log(this.planTermAndCondtions);
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
            }
        );

        this.systemService.get({ module: 'CAMPAIGN', key: 'TERM_AND_CONDITION' }, '/byMoudule/key').subscribe(
            (response) => {
                if (response) {
                    this.campaignTermAndConditions = response['value'];
                    // console.log(this.campaignTermAndConditions);
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
            }
        );
        this.userCompleteProfile = JSON.parse(localStorage.getItem('userInfo'));

        if (this.userProfile) {
            this.image = this.userProfile.picture;

        }
        this.auth.emitProifle.subscribe((profile) => {
            this.image = profile.picture;
            this.userProfile = profile;
        })

        this.userService.user.subscribe((user) => {
            if (user) {
                this.userProfile = user[0];
                // this.userData = user[0];
                // console.log("user data is", this.userData)
                let localuser = JSON.parse(localStorage.getItem("userInfo"))[0].user_metadata;
                this.profileInitials = localuser.firstname.charAt(0).toUpperCase() + localuser.lastname.charAt(0).toUpperCase();


            }
        })

        if (localStorage.getItem("userInfo")) {
            let localuser = JSON.parse(localStorage.getItem("userInfo"))[0].user_metadata;
            this.profileInitials = localuser.firstname.charAt(0).toUpperCase() + localuser.lastname.charAt(0).toUpperCase();
        }

        // this.getVendorLogoUrl();

        this.loaderSubjects.pptLoader.subscribe((data) => {
            if (data) {
                this.pptloader = data;
                if (this.pptloader.fileType === 'PPT') {
                    this.downloadPPT();
                } else if (this.pptloader.fileType === 'PPT_BETA') {
                    this.downloadPPTBeta();
                }

                this.loaderSubjects.pptLoader.next(undefined);
            }
        })

        this.loaderSubjects.xlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        })

        this.loaderSubjects.gridLoader.subscribe((data) => {
            this.gridLoader = data;
        })

        this.loaderSubjects.campaignXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadCampaignXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }

        });
        this.loaderSubjects.campaignTrackerXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadCampaignTrackerXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }

        });
        this.loaderSubjects.campaignPptLoader.subscribe((data) => {
            // .selectedImageType && data.pptData.selectedImageType.length > 0
            if (data.fileType === 'PPT') {
                this.pptloader = data;
                this.downloadCampaignPpt();
                this.loaderSubjects.pptLoader.next(undefined);
            } else if (data.fileType === 'PPT_BETA') {
                this.pptloader = data;
                this.downloadCampaignPptBeta();
                this.loaderSubjects.pptLoader.next(undefined);
            }
            //  else {
            //     this.notificationServcie.info('Please select the image type', 'PPT Download');
            // }
        });

        this.loaderSubjects.campaignTrackerPptLoader.subscribe((data) => {
            if (data) {
                this.pptloader = data;
                this.downloadCampaignTrackerPpt();
                this.loaderSubjects.pptLoader.next(undefined);
            }

        });

        this.loaderSubjects.superbillListXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadSuperbillListXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.invoiceXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadInvoiceXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.invoiceTallyXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadInvoiceTallyXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.migratedInvoiceXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadMigratedInvoiceXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.creditNoteXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadCreditNoteXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.reservedInvoiceXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadReservedInvoiceXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.reservedCreditNoteXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadReservedCNXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.pendingUnbilledXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadPendingUnbilledXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }

        });

        this.loaderSubjects.flexItemsXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadFlexItemsXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.mountingItemsXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadMountingItemsXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.monitoringItemsXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadMonitoringItemsXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.unmountingItemsXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadUnmountingItemsXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.photoLoader.subscribe((data) => {
            if (data) {
                this.photoLoader = data;
                if (this.photoLoader.items && this.photoLoader.items.length > 0) {
                    this.downloadImages(this.photoLoader)
                }

                // this.loaderSubjects.photoLoader.next(undefined)
            }
        });

        this.loaderSubjects.pocXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadPocXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.hoardingXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                if (this.xlsLoader.source) {
                    this.downloadHoardingXls(this.xlsLoader.source);
                } else {
                    this.downloadHoardingXls();
                }
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.hoardingGroupXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                if (this.xlsLoader.source) {
                    this.downloadHoardingGroupXls(this.xlsLoader.source);
                } else {
                    this.downloadHoardingGroupXls();
                }
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.kioskXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                if (this.xlsLoader.source) {
                    this.downloadKioskXls(this.xlsLoader.source);
                } else {
                    this.downloadKioskXls();
                }
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.contractXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadContractXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.salesChartXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                // this.downloadContractXls();
                this.downloadSalesChartXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.roiContractDataXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadRoiContractDataXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.roiContractInventoryDataXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadRoiContractInventoryDataXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.untappedRoiDataXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadUntappedRoiDataXls();
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });


        this.loaderSubjects.templateHoardingXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadTemplateXlsx('hoardings');
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.templateHoardingGroupXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadTemplateXlsx('hoardingGroups');
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });

        this.loaderSubjects.templateKioskXlsLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.downloadTemplateXlsx('kiosks');
                this.loaderSubjects.xlsLoader.next(undefined);
            }
        });


        this.loaderSubjects.campaignRollbackLoader.subscribe((data) => {
            if (data) {
                this.xlsLoader = data;
                this.campaignRollback(data.data);
                this.loaderSubjects.xlsLoader.next(undefined)
            }
        })

        // this.loaderSubjects.mountingPhotoLoader.subscribe((data) => {
        //     if (data) {
        //         this.mountingPhotoLoader = data;
        //         if (this.mountingPhotoLoader.items && this.mountingPhotoLoader.items.length > 0) {
        //             this.downloadImages(this.mountingPhotoLoader)
        //         }

        //         // this.loaderSubjects.photoLoader.next(undefined)
        //     }
        // })

        // this.loaderSubjects.monitoringPhotoLoader.subscribe((data) => {
        //     if (data) {
        //         this.monitoringPhotoLoader = data;
        //         if (this.monitoringPhotoLoader.items && this.monitoringPhotoLoader.items.length > 0) {
        //             this.downloadImages(this.monitoringPhotoLoader)
        //         }

        //         // this.loaderSubjects.photoLoader.next(undefined)
        //     }
        // })

        fileUtils.isFileDownloaded.subscribe((data) => {
            if (data) {
                this.createPhotoLoaderResetObject(this.resetPhotoLoader)
                this.loaderSubjects.photoLoader.next(this.resetPhotoLoader)
                fileUtils.isFileDownloaded.next(false)
            }
        })

        this.helpItems = [
            { label: 'Help & Support', command: (event) => { this.showHelpDialog(); } },
            { label: 'Suggestion', command: (event) => { this.showSuggestionDialog(); } }
        ];
    }

    downloadPPT() {
        if (this.pptloader.pptData) {
            if (this.pptloader.customIds.length === 0) {
                this.notificationServcie.error("PPT Download Error", "Please Try Again!")
            } else {
                this.planService.downloadFile(this.pptloader.pptData.id, this.pptloader.fileType, this.pptloader.customIds)
                    .subscribe((response: any) => {
                        let blob;
                        const anchor = document.createElement("a");
                        blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
                        anchor.download = this.pptloader.pptData.displayName + '-' + DateUtil.dategridFormatter(new Date()) + ".pptx";
                        const url = URL.createObjectURL(blob);

                        anchor.href = url;
                        anchor.click();
                        this.createLoaderResetObject(this.resetPptloader)
                        this.loaderSubjects.pptLoader.next(this.resetPptloader)
                    })
            }

        }

    }

    downloadPPTBeta() {
        if (this.pptloader.pptData && this.pptloader.pptData.items && this.pptloader.pptData.items.length > 0) {
            this.pptGenerationObjects = [];
            this.pptGeneratorService.instantiatePpt();
            // this.pptGeneratorService.setVendorInformation(this.logoUrl, this.pptText, this.pptRedirectUrl);
            this.pptGeneratorService.setVendorInformation(this.pptEmailUrl, this.pptText, this.pptRedirectUrl);
            this.pptGeneratorService.setSlideMaster();
            this.pptGeneratorService.setFirstPageLayout(this.pptloader.pptData.plan);
            this.pptGeneratorService.setLayout();
            // this.pptGeneratorService.setVendorInformation(this.logoUrl);
            this.pptloader.pptData.items.forEach((element, index) => {
                var pptGenerationData = new PptGenerationData();
                of(element).pipe(
                    switchMap((element) => {
                        if (element.images && element.images.length > 0) {
                            return this.pptGeneratorService.getBase64ImageFromURL(element.images[0].url, pptGenerationData)
                        } else {
                            var noImageUrl = 'https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89'
                            return this.pptGeneratorService.getBase64ImageFromURL(noImageUrl, pptGenerationData)
                        }

                    }), switchMap((pptData) => {
                        return this.pptGeneratorService.dataURItoBLob(pptData['data'], pptData);
                    }), switchMap((pptData) => {
                        return this.pptGeneratorService.blobToFile(pptData['data'], pptData);
                    }), switchMap((pptData) => {
                        return this.pptGeneratorService.compressFile(pptData['data'], pptData);
                    }), switchMap((pptData) => {
                        return this.pptGeneratorService.getBase64FromFile(pptData['data'], pptData);
                    })
                ).subscribe((result) => {
                    // if (result) {
                    pptGenerationData.srno = index + 1;
                    // pptGenerationData.data = String(result);

                    pptGenerationData.size = element.size;
                    pptGenerationData.lightType = HoardingLightEnum[element.lightType];
                    var numberOfDays = this.calculationService.calculateDays(element.itemStartDate, element.itemEndDate, true)
                    pptGenerationData.rate = this.calculationService.calculateCostOfHoarding(element.rate, numberOfDays);
                    pptGenerationData.numberOfDays = numberOfDays
                    pptGenerationData.availabilityDate = this.getAvailableDate(element);
                    pptGenerationData.header = this.getDescription(element, pptGenerationData);
                    pptGenerationData.customId = element.customId;
                    this.pptGenerationObjects.push(pptGenerationData)
                    this.savePpt(this.pptloader.pptData.items)
                }, (error) => {
                    this.notificationServcie.error('PPT Download Error', 'Please Refresh.');
                    // return observableEmpty();
                })
            });
        }
    }

    savePpt(items) {
        if (items.length === this.pptGenerationObjects.length) {
            var pptDataArray = [];
            items.forEach((item) => {
                pptDataArray.push(...this.pptGenerationObjects.filter(data => data.customId === item.customId))
            })
            pptDataArray.forEach((data) => {
                this.pptGeneratorService.setItemSlide(data);
            });
            this.pptGeneratorService.addTermAndConditionSlide(this.planTermAndCondtions, this.pptloader.pptData.plan.employee);
            this.pptGeneratorService.savePpt(this.pptloader.pptData.plan.displayName)
            this.createLoaderResetObject(this.resetPptloader)
            this.loaderSubjects.pptLoader.next(this.resetPptloader)
        }
    }

    getDescription(data, pptData) {
        const hid = data.customId ? data.customId : '';
        const city = data.city ? data.city : '';
        const location = data.location ? data.location : '';
        return pptData.srno + ') ' + hid + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '');
    }

    getAvailableDate(item) {
        switch (item.status) {
            case 'AVAILABLE': {
                return 'Available Now'
            }

            case 'BLOCKED': {
                // var obj = this.findMaxDateObject(item.statusQueues, 'blockedEndDate');
                // if (this.pptloader.pptData.plan.id === obj.id) {
                //     return 'Available Now'
                // } else {
                //     return obj.status;
                // }
                let remainingObj = [];
                if (item.statusQueues && item.statusQueues.length > 0) {
                    if (item.statusQueues.length === 1 && item.statusQueues[0].id === this.pptloader.pptData.plan.id) {
                        return 'Available Now';
                    } else if (item.statusQueues.length === 1 && item.statusQueues[0].id != this.pptloader.pptData.plan.id) {
                        return item.statusQueues[0].status;
                    } else {
                        item.statusQueues.forEach((statusQueue) => {
                            if (statusQueue.id != this.pptloader.pptData.plan.id) {
                                remainingObj.push(statusQueue);
                            }
                        });
                        var obj = this.findMaxDateObject(remainingObj, 'blockedEndDate');
                        // if(obj && obj.length > 0) {
                        return obj.status;
                        // } else {
                        //     return '';
                        // }
                        // return obj.status;
                    }
                } else {
                    return '';
                }
            }

            case 'NOT_AVAILABLE': {
                // var planBlocked = item.statusQueues.filter(item => item.module === 'Plan');
                var inCampaign = item.statusQueues.filter(item => item.module === 'Campaign');

                // var inPlanMaxBlockDateObj = this.findMaxDateObject(planBlocked, 'blockedEndDate');
                var inCampaignMaxDateObj = this.findMaxDateObject(inCampaign, 'campaignEndDate');

                var availableStatus = String(DateUtil.formatDate(new Date(new Date(inCampaignMaxDateObj.campaignEndDate).getTime() + 86400000))) + this.getPlanStatus(item.statusQueues)
                return availableStatus;
            }
        }
    }

    getPlanStatus(statusQueues) {
        // if (obj) {
        //     if (this.pptloader.pptData.plan.id === obj.id) {
        //         return ''
        //     } else {
        //         return ' / ' + obj.status;
        //     }
        // } else {
        //     return '';
        // }
        var planBlocked = statusQueues.filter(item => item.module === 'Plan');
        let remainingObj = [];
        if (planBlocked && planBlocked.length > 0) {
            if (planBlocked[0].id === this.pptloader.pptData.plan.id && planBlocked.length === 1) {
                return '';
            } else if (planBlocked[0].id !== this.pptloader.pptData.plan.id && planBlocked.length === 1) {
                return ' / ' + planBlocked[0].status;
            } else if (planBlocked.length > 1) {
                planBlocked.forEach((statusQueue) => {
                    if (statusQueue.id != this.pptloader.pptData.plan.id) {
                        remainingObj.push(statusQueue);
                    }
                });
                let obj = this.findMaxDateObject(remainingObj, 'blockEndDate');
                return ' / ' + obj.status;
            }
        } else {
            return '';
        }


    }

    findMaxDateObject(items, field) {
        return items.reduce((acc, obj) => {
            var maxDate = acc[field];
            var toCompareDate = obj[field];
            if (toCompareDate > maxDate) {
                acc = obj
            }
            return acc;
        }, items[0])
    }

    downloadXls() {
        if (this.xlsLoader.data) {
            this.planService.downloadFile(this.xlsLoader.data.id, this.xlsLoader.fileType, this.xlsLoader.customIds)
                .subscribe((response: any) => {
                    let blob;
                    const anchor = document.createElement("a");
                    blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    anchor.download = this.xlsLoader.data.displayName + '-' + DateUtil.dategridFormatter(new Date()) + ".xlsx";
                    const url = URL.createObjectURL(blob);
                    anchor.href = url;
                    anchor.click();
                    this.createLoaderResetObject(this.resetXlsLoader)
                    this.loaderSubjects.xlsLoader.next(this.resetXlsLoader)
                })
        }
    }

    downloadCampaignXls() {
        if (this.xlsLoader.data) {
            this.campaignService.downloadFile(this.xlsLoader.data.id, this.xlsLoader.fileType, this.xlsLoader.subModule, this.xlsLoader.ids)
                .subscribe((response) => {
                    let blob;
                    let url;
                    let anchor;
                    blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    url = URL.createObjectURL(blob);
                    anchor = document.createElement("a");
                    anchor.download = this.xlsLoader.data.displayName + ".xlsx";
                    anchor.href = url;
                    anchor.click();
                    this.createLoaderResetObject(this.resetXlsLoader)
                    this.loaderSubjects.xlsLoader.next(this.resetXlsLoader)
                })
        }
    }

    downloadCampaignTrackerXls() {
        if (this.xlsLoader.data) {
            this.campaignService.downloadCampaignTrackerFile(this.xlsLoader.data.id, this.xlsLoader.fileType, this.tenantId, this.xlsLoader.subModule, this.xlsLoader.customIds)
                .subscribe((response) => {
                    let blob;
                    let url;
                    let anchor;
                    blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    url = URL.createObjectURL(blob);
                    anchor = document.createElement("a");
                    anchor.download = this.xlsLoader.data.displayName + ".xlsx";
                    anchor.href = url;
                    anchor.click();
                    this.createLoaderResetObject(this.resetXlsLoader)
                    this.loaderSubjects.xlsLoader.next(this.resetXlsLoader)
                }
                )
        }
    }

    downloadPendingUnbilledXls() {
        if (this.xlsLoader.data) {
            this.pendingUnbilledUIService.downloadFile("XLSX", this.xlsLoader.data).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Pending-Unbilled.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader)
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader)
            }, (error) => {
                this.xlsLoader.isLoading = false;
                this.xlsLoader.text = null;

                const errorObject = ErrorUtil.getErrorObject(error);
                if (errorObject.code === 417 || errorObject.code === 412) {
                    this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                } else {
                    this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                }
            });
        }
    }

    downloadSuperbillListXls() {
        if (this.xlsLoader.searchEvent) {
            this.superBillsService.downloadSuperbillListXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "SuperbillList.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadInvoiceXls() {
        if (this.xlsLoader.searchEvent) {
            this.invoiceChartService.downloadFile("XLSX", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Invoices.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            },
                (error) => {
                    this.xlsLoader.isLoading = false;
                    this.xlsLoader.text = null;

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                });
        }
    }

    downloadInvoiceTallyXls() {
        if (this.xlsLoader.searchEvent) {
            this.invoiceChartService.downloadFile("XLSX", this.xlsLoader.searchEvent, "tally").subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "TallyInvoices.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            },
                (error) => {
                    this.xlsLoader.isLoading = false;
                    this.xlsLoader.text = null;

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                });
        }
    }

    downloadMigratedInvoiceXls() {
        if (this.xlsLoader.searchEvent) {
            this.invoiceChartService.downloadFile("XLSX", this.xlsLoader.searchEvent, "migratedInvoice").subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Migrated_Invoice.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            },
                (error) => {
                    this.xlsLoader.isLoading = false;
                    this.xlsLoader.text = null;

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                });
        }
    }

    downloadCreditNoteXls() {
        if (this.xlsLoader.searchEvent) {
            this.invoiceChartService.downloadFile("XLSX", this.xlsLoader.searchEvent, "creditNote").subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Credit_Note.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            },
                (error) => {
                    this.xlsLoader.isLoading = false;
                    this.xlsLoader.text = null;

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                });
        }
    }

    downloadReservedInvoiceXls() {
        if (this.xlsLoader.searchEvent) {
            this.invoiceChartService.downloadFile("XLSX", this.xlsLoader.searchEvent, "reservedInvoice").subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Reserved_Invoices.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            },
                (error) => {
                    this.xlsLoader.isLoading = false;
                    this.xlsLoader.text = null;

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                });
        }
    }

    downloadReservedCNXls() {
        if (this.xlsLoader.searchEvent) {
            this.invoiceChartService.downloadFile("XLSX", this.xlsLoader.searchEvent, "reservedCN").subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Reserved_Credit_Note.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            },
                (error) => {
                    this.xlsLoader.isLoading = false;
                    this.xlsLoader.text = null;

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                });
        }
    }

    downloadFlexItemsXls() {
        if (this.xlsLoader.searchEvent) {
            this.hoardingOperationService.downloadFlexXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "FlexItems.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.flexItemsXlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadMountingItemsXls() {
        if (this.xlsLoader.searchEvent) {
            this.hoardingOperationService.downloadMountingXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "MountItems.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.mountingItemsXlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadMonitoringItemsXls() {
        if (this.xlsLoader.searchEvent) {
            this.hoardingOperationService.downloadMonitoringXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "MonitoringItems.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.monitoringItemsXlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadUnmountingItemsXls() {
        if (this.xlsLoader.searchEvent) {
            this.hoardingOperationService.downloadUnmountingXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "UnmountItems.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.unmountingItemsXlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadImages(object) {
        // if (this.photoLoader.items.length > 0) {
        //     fileUtils.createFileBlobs(this.photoLoader.items, this.photoLoader.name);
        // }
        if (object.items.length > 0) {
            fileUtils.createFileBlobs(object.items, object.name);
        }
    }

    createLoaderResetObject(object) {
        object.fileType = null;
        object.text = null;
        object.isLoading = false;
        object.data = null;
        object.pptData = null;
    }

    createPhotoLoaderResetObject(object) {
        object.items = null;
        object.isLoading = false;
        object.text = null;
        object.name = null
        // object.isNoImage = false;
    }


    createResetObject(object) {
        object.text = null;
        object.data = null;
    }


    setTitleSubscription() {
        this.appTitleSubscription = this.appTitle.title$.subscribe(appTitle => {
            this.title = appTitle;
        });
    }

    moveToHomepage() {
        this.router.navigate(['/'])
    }

    showUserList() {

    }

    logout() {

        this.image = null;
        this.auth.logout();

    }

    viewProfile() {
        this.router.navigate(['/profile'])
    }

    getRoles() {
        if (this.userProfile && this.userProfile.app_metadata) {
            for (let i = 0; i < this.userProfile.app_metadata.authorization.roles.length; i++) {
                this.userProfile.app_metadata.authorization.roles[i] = this.userProfile.app_metadata.authorization.roles[i].charAt(0).toUpperCase() + this.userProfile.app_metadata.authorization.roles[i].slice(1);
            }
            return this.userProfile.app_metadata.authorization.roles.join(', ');
        }

    }

    showHelpDialog() {
        this.display = true;
    }

    showSuggestionDialog() {
        this.displaySuggestionDialog = true;
    }
    closeSuggestionDialog() {
        this.displaySuggestionDialog = false;
    }

    /**
     * @description download campaign ppt from frontend
     * @author Divya Sachan
     * @date 2019-10-18
     * @memberof AppTopBar
     */
    downloadCampaignPpt() {
        if (this.pptloader.pptData) {
            // console.log(this.pptloader.pptData, "campaign ppt");
            const noImageUrl = 'https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/sib-assets%2Fno_image_available.png?alt=media&token=74c70498-65b2-4ced-920a-a068677f0f89';
            let type: any;
            if (this.pptloader.pptData && this.pptloader.pptData.items && this.pptloader.pptData.items.length > 0) {
                this.pptGenerationObjects = [];
                this.pptGeneratorService.instantiatePpt();
                // this.pptGeneratorService.setVendorInformation(this.logoUrl, this.pptText, this.pptRedirectUrl);
                this.pptGeneratorService.setVendorInformation(this.pptEmailUrl, this.pptText, this.pptRedirectUrl, this.portalBaseUrl);
                this.pptGeneratorService.setSlideMaster();
                this.pptGeneratorService.setFirstPageLayout(this.pptloader.pptData.campaign);
                this.pptGeneratorService.setLayout();
                this.pptloader.pptData.items.forEach((element, index) => {
                    // console.log(element, "ELEMENT");
                    var pptGenerationData = new PptGenerationData();
                    of(element).pipe(
                        switchMap((element) => {
                            if (this.pptloader.pptData.tabIndex === 0) { // for site tab
                                if (element && element.images && element.images.length > 0) {
                                    return this.pptGeneratorService.getBase64ImageFromURL(element.images[0].url, pptGenerationData)
                                } else {
                                    return this.pptGeneratorService.getBase64ImageFromURL(noImageUrl, pptGenerationData)
                                }
                            } else if (this.pptloader.pptData.tabIndex === 1) { // for mounting tab
                                if (element && element.mountingItems.images && element.mountingItems.images.length > 0) {
                                    return this.pptGeneratorService.getBase64ImageFromURL(element.mountingItems.images[0].url, pptGenerationData)
                                }
                            } else if (this.pptloader.pptData.tabIndex === 2) { // for monitoring tab
                                if (element && element.monitoringItems.images && element.monitoringItems.images.length > 0) {
                                    return this.pptGeneratorService.getBase64ImageFromURL(element.monitoringItems.images[0].url, pptGenerationData)
                                }
                            } else if (this.pptloader.pptData.tabIndex === 3) { // for unmounting tab
                                if (element && element.unmountingItems.images && element.unmountingItems.images.length > 0) {
                                    return this.pptGeneratorService.getBase64ImageFromURL(element.unmountingItems.images[0].url, pptGenerationData)
                                }
                            }

                        }), switchMap((pptData) => {
                            return this.pptGeneratorService.dataURItoBLob(pptData['data'], pptData);
                        }), switchMap((pptData) => {
                            return this.pptGeneratorService.blobToFile(pptData['data'], pptData);
                        }), switchMap((pptData) => {
                            return this.pptGeneratorService.compressFile(pptData['data'], pptData);
                        }), switchMap((pptData) => {
                            return this.pptGeneratorService.getBase64FromFile(pptData['data'], pptData);
                        })
                    ).subscribe((result) => {
                        pptGenerationData.srno = index + 1;
                        pptGenerationData.size = this.pptloader.pptData.items[index].size;
                        pptGenerationData.lightType = HoardingLightEnum[this.pptloader.pptData.items[index].lightType];
                        pptGenerationData.availabilityDate = this.pptloader.pptData.items[index].itemEndDate;
                        pptGenerationData.operatedDate = this.getOperatedDate(this.pptloader.pptData.items[index], this.pptloader.pptData.tabIndex);
                        pptGenerationData.customId = this.pptloader.pptData.items[index].customId;
                        // pptGenerationData.imageType = elementImages[index].imageType;
                        type = this.getImageType(this.pptloader.pptData.items[index], this.pptloader.pptData.tabIndex)
                        pptGenerationData.imageType = type.imageType;
                        pptGenerationData.mode = type.mode;
                        pptGenerationData.header = this.getDescriptionheaderforCampaign(this.pptloader.pptData.items[index], pptGenerationData, this.pptloader.pptData.tabIndex);
                        this.pptGenerationObjects.push(pptGenerationData)
                        this.saveCampaignPpt(this.pptloader.pptData.items, this.pptloader.pptData.tabIndex)
                    }, (error) => {
                        this.pptloader.text = null;
                        this.pptloader.isLoading = false;
                        this.notificationServcie.error('Error', 'Please Refresh.');
                    })
                });
            }
            else {
                this.pptloader.isLoading = false;
                this.pptloader.text = null;
                let notification = this.setNotificationTabWise();
                this.notificationServcie.info(notification, "PPT Download");
            }
        }
    }

    // Get image type for campaign item
    getImageType(data, tabIndex) {
        let imageType = '';
        let mode = '';
        switch (tabIndex) {
            case 0: { // site tab
                imageType = '';
                mode = '';
                break;
            }
            case 1: { // mounting tab
                imageType = (data.mountingItems.images && data.mountingItems.images.length > 0) ? ('(' + ImageTypeEnum[data.mountingItems.images[0].imageType] + ')') : '';
                mode = (data.mountingItems.creativeName !== 'Default') ? ('(' + data.mountingItems.creativeName + ')') : '';
                break;
            }
            case 2: { // monitoring tab
                imageType = (data.monitoringItems.images && data.monitoringItems.images.length > 0) ? ('(' + ImageTypeEnum[data.monitoringItems.images[0].imageType] + ')') : '';
                mode = (data.monitoringItems.mode !== undefined) ? ('(' + data.monitoringItems.mode + ')') : '';
                break;
            }
            case 3: { // unmounting tab
                imageType = (data.unmountingItems.images && data.unmountingItems.images.length > 0) ? ('(' + ImageTypeEnum[data.unmountingItems.images[0].imageType] + ')') : '';
                mode = (data.unmountingItems.creativeName !== 'Default') ? ('(' + data.unmountingItems.creativeName + ')') : '';
                break;
            }
        }
        return { imageType: imageType, mode: mode };
    }

    //get Description header for campaign item
    getDescriptionheaderforCampaign(data, pptData, tabIndex) {
        const hid = data.customId ? data.customId : '';
        const city = data.city ? data.city : '';
        const location = data.location ? data.location : '';
        let imageType = this.getImageType(data, tabIndex).imageType;

        return pptData.srno + ') ' + hid + (city && city !== '' && city !== null ? ' - ' + city : '') + (location && location !== '' && location !== null ? ', ' + location : '') + imageType;
    }

    // get startdate for sites tab, mounted date for mounted item, monitoring date for monitored item and unmounting date for unmounted item.
    getOperatedDate(element, tabIndex) {
        let operatedDate;
        switch (tabIndex) {
            case 0: { //site tab
                operatedDate = element.itemStartDate;
                break;
            }
            case 1: { // mounting tab
                operatedDate = element.mountingItems.date;
                break;
            }
            case 2: { // monitoring tab
                operatedDate = element.monitoringItems.monitoringDate;
                break;
            }
            case 3: { // unmounting tab
                operatedDate = element.unmountingItems.date;
                break;
            }
        }
        return operatedDate;
    }

    // saving ppt for campaign items
    saveCampaignPpt(items, tabIndex) {
        if (items.length === this.pptGenerationObjects.length) {
            var pptDataArray = [];
            items.forEach((item, index) => {
                // pptDataArray.push(...this.pptGenerationObjects.filter(data => data.))
                pptDataArray.push(...this.pptGenerationObjects.filter(data => data.srno === (index + 1))) // filtering data to make the items the come in order in ppt
            })
            pptDataArray.forEach((data) => {
                this.pptGeneratorService.setItemSlideForCampaign(data, tabIndex); // creating slide individual campaign item
            });
            this.pptGeneratorService.addTermAndConditionSlide(this.campaignTermAndConditions, this.pptloader.pptData.campaign.employee, this.pptloader.pptData.campaign.id, true); // adding term and condition slide
            this.pptGeneratorService.savePpt(this.pptloader.pptData.campaign.displayName);
            this.createLoaderResetObject(this.resetPptloader)
            this.loaderSubjects.pptLoader.next(this.resetPptloader)
        }
    }

    setNotificationTabWise() {
        let notificationTabWise = '';
        if (this.pptloader.pptData.tabIndex === 1) {
            notificationTabWise = this.setNotificationAccordingToItemType('mounted');
        } else if (this.pptloader.pptData.tabIndex === 2) {
            notificationTabWise = this.setNotificationAccordingToItemType('monitored');
        } else if (this.pptloader.pptData.tabIndex === 3) {
            notificationTabWise = this.setNotificationAccordingToItemType('unmounted');
        } else {
            notificationTabWise = this.setNotificationAccordingToItemType('');
        }
        return notificationTabWise;
    }

    setNotificationAccordingToItemType(itemType) {
        let notification = '';
        if (this.pptloader.pptData.itemCount === 0) {
            notification = "There are no " + itemType + " items to be exported.";
        } else if (this.pptloader.pptData.itemCount > 0 && this.pptloader.pptData.items.length === 0) {
            notification = "There are no " + itemType + " items with images to download PPT.";
        }
        return notification;
    }

    setTabwiseNotification() {
        if (this.pptloader.pptData.tabIndex === 1) {
            return 'mounted';
        } else if (this.pptloader.pptData.tabIndex === 2) {
            return 'monitored';
        } else if (this.pptloader.pptData.tabIndex === 3) {
            return 'unmounted';
        }
    }

    getVendorInformation() {
        this.isLoading = true;
        this.tenantId = localStorage.getItem('id');
        this.vendorService.get(null, this.tenantId).subscribe((response) => {
            if (response) {
                localStorage.setItem('appTitle', response['name']);
                !utils.getAppTitle() ? (this.pageTitle.getTitle().includes('-') ? this.pageTitle.setTitle(this.pageTitle.getTitle().split(' -')[0]) : this.pageTitle.setTitle(this.pageTitle.getTitle())) : (this.pageTitle.getTitle().includes('-') ? this.pageTitle.setTitle(this.pageTitle.getTitle().split(' -')[0] + ' - ' + utils.getAppTitle()) : this.pageTitle.setTitle(utils.getAppTitle()));
                this.portalBaseUrl = response['portalBaseUrl']
                this.defaultLogoUrl = response['defaultLogo'].url;
                // this.logoUrl = response['logoDetail']['url'];
                if (response['name'] && response['website']) {
                    this.pptText = response['name'];
                    this.pptRedirectUrl = response['website'];
                } else if (response['name'] && !response['website']) {
                    this.pptText = response['name'];
                    this.pptRedirectUrl = "";
                } else if (!response['name'] && response['website']) {
                    this.pptText = SIBConstants.APP_TITLE;
                    this.pptRedirectUrl = response['website'];
                } else {
                    this.pptText = SIBConstants.APP_TITLE;
                    this.pptRedirectUrl = "";
                }

                this.getLogosUrls();
                // this.pptText = response['name'];
                // this.pptRedirectUrl = response['website'];
                // console.log(this.logoUrl)
                this.isLoading = false;
            }
        }),
            (error) => {
                this.isLoading = false;
            }
    }

    downloadPocXls() {
        if (this.xlsLoader.searchEvent) {
            this.pointOfContactService.downloadPocXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Poc.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.flexItemsXlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadHoardingXls(name?: string) {
        if (this.xlsLoader.searchEvent) {
            this.hoardingService.downloadFile("XLSX", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = name ? name + "-" + "Hoardings.xlsx" : "Hoardings.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadHoardingGroupXls(name?: string) {
        if (this.xlsLoader.searchEvent) {
            this.hoardingGroupService.downloadFile("XLSX", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = name ? name + "-" + "Hoarding-Groups.xlsx" : "Hoarding-Groups.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadKioskXls(name?: string) {
        if (this.xlsLoader.searchEvent) {
            this.kioskService.downloadFile("XLSX", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = name ? name + "-" + "Kiosks.xlsx" : "Kiosks.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadContractXls() {
        if (this.xlsLoader.searchEvent) {
            this.contractService.downloadFile("XLSX", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "Contracts.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            });
        }
    }

    downloadSalesChartXls() {
        if (this.xlsLoader.searchEvent) {
            this.salesChartService.downloadSalesChartXls("XLSX", this.xlsLoader).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                if (this.xlsLoader.billed) {
                    if (this.xlsLoader.invoiceType === 'invoice') {
                        anchor.download = "Billed-Items.xlsx";
                    } else if (this.xlsLoader.invoiceType === 'migratedInvoice') {
                        anchor.download = "Migrated-Billed-Items.xlsx";
                    }
                    // anchor.download = "Billed-Items.xlsx";
                } else if (!this.xlsLoader.billed) {
                    anchor.download = "Unbilled-Items.xlsx";
                }
                // anchor.download = "sales.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            },
                (error) => {
                    this.xlsLoader.isLoading = false;
                    this.xlsLoader.text = null;

                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                });
        }
    }

    /**
     * @description Campaign ppt download from backend
     * @author Divya Sachan
     * @date 2019-10-14
     * @memberof AppTopBar
     */
    downloadCampaignPptBeta() {
        if (this.pptloader.data) {
            if (!this.pptloader.pptData) {
                this.notificationServcie.error("PPT Download Error", "Please refresh and retry");
            } else {
                this.campaignService.downloadFile(this.pptloader.data.id, this.pptloader.fileType, null, null, this.pptloader.pptData)
                    .subscribe((response: any) => {
                        let blob;
                        const anchor = document.createElement("a");
                        blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
                        anchor.download = this.setCampaignPptName(this.pptloader.pptData.tabIndex);
                        const url = URL.createObjectURL(blob);
                        anchor.href = url;
                        anchor.click();
                        this.pptloader.isLoading = false;
                        this.pptloader.text = null;
                        this.createLoaderResetObject(this.resetPptloader)
                        this.loaderSubjects.campaignPptLoader.next(this.resetPptloader)
                    },
                        (error) => {
                            this.pptloader.isLoading = false;
                            this.pptloader.text = null;

                            const errorObject = ErrorUtil.getErrorObject(error);
                            if (errorObject.code === 417 || errorObject.code === 412) {
                                this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                            } else {
                                this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                            }
                        }
                    )
            }

        }
    }

    downloadCampaignTrackerPpt() {
        if (this.pptloader.data) {
            this.campaignService.downloadCampaignTrackerFile(this.pptloader.data.id, this.pptloader.fileType, this.tenantId, null, this.pptloader.pptData)
                .subscribe((response: any) => {
                    let blob;
                    const anchor = document.createElement("a");
                    blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
                    anchor.download = this.setCampaignPptName(this.pptloader.pptData.tabIndex);
                    const url = URL.createObjectURL(blob);
                    anchor.href = url;
                    anchor.click();
                    this.pptloader.isLoading = false;
                    this.pptloader.text = null;
                    this.createLoaderResetObject(this.resetPptloader)
                    this.loaderSubjects.campaignPptLoader.next(this.resetPptloader)
                }
                )
        }
    }


    /**
     * download plan template hoardings
     *
     * @memberof AppTopBar
     */
    downloadTemplateXlsx(media) {
        if (this.xlsLoader.searchEvent) {
            this.planTemplateService.downloadFile("XLSX", this.xlsLoader.searchEvent, media).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = media === 'hoardings' ? this.xlsLoader.searchEvent.name + "-Hoardings.xlsx" : (media === 'hoardingGroups') ? this.xlsLoader.searchEvent.name + "-Groups.xlsx" : this.xlsLoader.searchEvent.name + "-Kiosks.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.xlsLoader.next(this.resetXlsLoader);
            });
        }
    }



    /**
     * rollbacks the campaign to plan
     *
     * @memberof AppTopBar
     */
    campaignRollback(campaignId) {
        if (campaignId) {
            this.loaderSubjects.loadingSubject.next(true);
            this.campaignService.get(null, AppUrls.ROLLBACK_TO_PLAN + AppUrls.SLASH + campaignId).subscribe(() => {
                this.notificationServcie.success('Campaign Rollbacked Successfully', 'Campaign Rollback');
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.campaignRollbackLoader.next(this.resetXlsLoader);
                this.loaderSubjects.loadingSubject.next(false);
                this.router.navigate(['/plans']);
            },
                (error) => {
                    // this.ro.isLoading = false;
                    // this.pptloader.text = null;
                    this.loaderSubjects.campaignRollbackLoader.next(this.resetXlsLoader);
                    this.loaderSubjects.loadingSubject.next(false);
                    const errorObject = ErrorUtil.getErrorObject(error);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                })
        }

    }


    /**
     * @description set name of ppt according to itemType
     * @author Divya Sachan
     * @date 2019-10-18
     * @returns
     * @memberof AppTopBar
     */
    setCampaignPptName(tabIndex) {
        let itemType = '';
        switch (tabIndex) {
            case 0: {
                itemType = "Sites";
                break;
            }
            case 1: {
                itemType = "Mount";
                break;
            }
            case 2: {
                itemType = "Monitored";
                break;
            }
            case 3: {
                itemType = "Unmount";
                break;
            }
        }
        return this.pptloader.data.displayName + '-' + itemType + '-' + DateUtil.dategridFormatter(new Date()) + ".pptx";
    }


    /**
     * gets logos url
     *
     * @memberof AppTopBar
     */
    getLogosUrls() {
        this.vendorService.get(null, 'getLogo').subscribe((response) => {
            if (response['data'].favIcon && response['data'].favIcon.url) {
                this._document.querySelector("link[rel*='icon']").setAttribute('href', response['data'].favIcon.url);
            } else {
                this._document.querySelector("link[rel*='icon']").setAttribute('href', "assets/layout/images/sib-favicon.ico")
            }
            if (response['data'].portalLogo && response['data'].portalLogo.url) {
                this.logoUrl = response['data'].portalLogo.url;         //for portal logo
            } else {
                this.logoUrl = this.defaultLogoUrl
            }
            if (response['data'].logoDetail && response['data'].logoDetail.url) {
                this.pptEmailUrl = response['data'].logoDetail.url;     //for ppt email logo
            } else {
                this.pptEmailUrl = this.defaultLogoUrl;
            }

        },
            (error) => {
                this.notificationServcie.error(error.error.message ? error.error.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.REFRESH)
            })

    }
    /**
     * @description download ROI XLS
     * @author Divya Sachan
     * @date 2019-11-13
     * @memberof AppTopBar
     */
    downloadRoiContractDataXls() {
        if (this.xlsLoader.searchEvent) {
            this.dashboardService.downloadRoiContractDataXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "ROI.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.roiContractDataXlsLoader.next(this.resetXlsLoader);
            }, (error) => {
                this.xlsLoader.isLoading = false;
                this.xlsLoader.text = null;
                const errorObject = ErrorUtil.getErrorObject(error);
                if (errorObject.code === 417 || errorObject.code === 412) {
                    this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                } else {
                    this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                }
            });
        }

    }

    /**
     * @description download ROI inventory XLS
     * @author Divya Sachan
     * @date 2019-11-13
     * @memberof AppTopBar
     */
    downloadRoiContractInventoryDataXls() {
        if (this.xlsLoader.searchEvent) {
            this.dashboardService.downloadRoiContractInventoryDataXls("XLS", this.xlsLoader).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = this.xlsLoader.data + "-ROI Item.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.roiContractInventoryDataXlsLoader.next(this.resetXlsLoader);
            }, (error) => {
                this.xlsLoader.isLoading = false;
                this.xlsLoader.text = null;
                const errorObject = ErrorUtil.getErrorObject(error);
                if (errorObject.code === 417 || errorObject.code === 412) {
                    this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                } else {
                    this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                }
            });
        }
    }

    /**
     * @description download Untapped ROI XLS
     * @author Divya Sachan
     * @date 2019-11-15
     * @memberof AppTopBar
     */
    downloadUntappedRoiDataXls() {
        if (this.xlsLoader.searchEvent) {
            this.dashboardService.downloadUntappedRoiDataXls("XLS", this.xlsLoader.searchEvent).subscribe((response) => {
                let blob;
                let url;
                let anchor;
                blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                url = URL.createObjectURL(blob);
                anchor = document.createElement("a");
                anchor.download = "NPC.xlsx";
                anchor.href = url;
                anchor.click();
                this.createLoaderResetObject(this.resetXlsLoader);
                this.loaderSubjects.untappedRoiDataXlsLoader.next(this.resetXlsLoader);
            }, (error) => {
                this.xlsLoader.isLoading = false;
                this.xlsLoader.text = null;
                const errorObject = ErrorUtil.getErrorObject(error);
                if (errorObject.code === 417 || errorObject.code === 412) {
                    this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                } else {
                    this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                }
            });
        }
    }
}

