import { PrintConfig } from './print-config';
import { InvoiceFormatEnum } from '../../shared/constants/invoice-format-enum';
import { InvoiceTypeEnum } from '../../shared/constants/invoice-type-enum';

/**
 * @description for printing of invoice, creditnote
 * @author Pulkit Bansal
 * @date 2019-10-21
 * @export
 * @class PrintWrapper
 */
export class PrintWrapper {
    printConfig: PrintConfig = new PrintConfig();
    printFormat: InvoiceFormatEnum;
    preview: boolean;
    itemIds: string[] = [];
}
