import { Component, OnInit, ViewChild, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { InvoiceChartComponent } from '../invoice-chart/invoice-chart.component';
import { InvoiceListComponent } from '../invoice-list/invoice-list.component';
import { BillingUIService } from '../services/billingUI.service';
import { ReserveInvoicesComponent } from '../reserve-invoices/reserve-invoices.component';

@Component({
  selector: 'sib-superbill-invoices-list',
  templateUrl: './superbill-invoices-list.component.html',
  styleUrls: ['./superbill-invoices-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuperbillInvoicesListComponent implements OnInit {

  @ViewChild('invoiceCardRef') invoiceCardRef: ElementRef;
  @ViewChild(InvoiceListComponent) invoiceList: InvoiceListComponent;
  @ViewChild(ReserveInvoicesComponent) reserveInvoiceList: ReserveInvoicesComponent;
  @ViewChild(InvoiceChartComponent) invoiceChartChildRef: InvoiceChartComponent;

  showEmployee = false;
  invoiceChartHeight: any;
  invoiceChartWidth: any;
  activeIndex: number = 0;
  isInvoiceChartFilter: boolean = false;
  invoiceChartEvent: any;
  reserveInvoiceChartEvent: any;
  isReserveInvoiceChartFilter: boolean = false;
  isLoading: boolean = false;
  showTitle: boolean;
  isFinancialYearChanged: boolean = false;
  financialYear: any[] = [];

  constructor(
    private billingUIService: BillingUIService,
  ) { }

  ngOnInit() {
    // this.chart(event);
    this.showTitle = false;
    if (this.checkPermission('readAll') || this.checkPermission('view:employee-column')) {
      this.showEmployee = true;
    } else {
      this.showEmployee = false;
    }
    this.setInvoiceChartCard();
    // this.invoiceList.billGridViewChildRef.refresh();
    // this.reserveInvoiceList.gridViewChildRef.refresh();
  }

  onTabChange(event) {
    this.activeIndex = event.index;
  }

  getHeight(obj, val: number) {
    return (obj.nativeElement.clientHeight - val);
  }

  getWidth(obj, val: number) {
    return (obj.nativeElement.clientWidth - val);
    // return (window.innerWidth - val)
  }

  setInvoiceChartCard() {
    this.invoiceChartHeight = this.getHeight(this.invoiceCardRef, 50);
    this.invoiceChartWidth = this.getWidth(this.invoiceCardRef, 50)
  }

  refreshChart(event) {
    if (event) {
      this.invoiceChartChildRef.refreshChart(this.getHeight(this.invoiceCardRef, 0), this.getWidth(this.invoiceCardRef, 60))
    }
  }

  chart(event) {
    // console.log(event)
    // this.isLoading = true;
    // this.activeIndex = 0;
    if (this.activeIndex === 0) {
      this.isInvoiceChartFilter = true;
      this.isReserveInvoiceChartFilter = false;
      this.invoiceChartEvent = event;
      setTimeout(() => {
        this.invoiceList.billGridViewChildRef.refresh();
        this.isInvoiceChartFilter = false;
      }, 500);
    } else if (this.activeIndex === 1) {
      this.isReserveInvoiceChartFilter = true;
      this.isInvoiceChartFilter = false;
      this.reserveInvoiceChartEvent = event;
      setTimeout(() => {
        this.reserveInvoiceList.gridViewChildRef.refresh();
        this.isReserveInvoiceChartFilter = false;
      }, 500);
    }
    // if (event[5] === "Invoice") {
    //   this.activeIndex = 0;
    //   this.isInvoiceChartFilter = true;
    //   this.isReserveInvoiceChartFilter = false;
    //   this.invoiceChartEvent = event;
    //   setTimeout(() => {
    //     // this.invoiceList.setGridConfigObject();
    //     this.invoiceList.billGridViewChildRef.refresh();
    //   }, 500);
    //   // this.invoiceList.billGridViewChildRef.refresh();
    // } else if (event[5] === "Reserved Invoice") {
    //   this.activeIndex = 1;
    //   this.isReserveInvoiceChartFilter = true;
    //   this.isInvoiceChartFilter = false;
    //   this.reserveInvoiceChartEvent = event;
    //   setTimeout(() => {
    //     // this.reserveInvoiceList.setGridConfigObject();
    //     this.reserveInvoiceList.gridViewChildRef.refresh();
    //   }, 500);

    //   // this.reserveInvoiceList.gridViewChildRef.refresh();
    // }
    // this.isInvoiceChartFilter = false;
    // this.isReserveInvoiceChartFilter = false;
  }

  financialYearChanged(event) {
    this.isFinancialYearChanged = event;
  }

  setFinancialYear(event) {
    this.financialYear = event;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.invoiceChartChildRef.refreshChart(this.getHeight(this.invoiceCardRef, 0), this.getWidth(this.invoiceCardRef, 60));
  }

  checkPermission(permission) {
    return this.billingUIService.checkPermission(permission);
  }

  getIsLoading(event) {
    this.invoiceChartChildRef.getInvoiceCardData();
    this.isLoading = event;
  }
}
