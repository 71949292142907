export class HistogramChartConfig {

    /**
     * Where to place the axis titles, compared to the chart area. Supported values:

     *   in - Draw the axis titles inside the chart area.
     *   out - Draw the axis titles outside the chart area.
     *   none - Omit the axis titles.
     *   Type: string
     *   Default: 'out'
     * 
     * @type {string}
     * @memberof HistogramChartConfig
     */
    axisTitlesPosition?: string

    /**
     * The background color for the main area of the chart. Can be either a simple HTML color string, for example: 'red' or '#00cc00', or an object with the following properties.

     *   Type: string or object
     *   Default: 'white'
     * 
     * @type {(string | any)}
     * @memberof HistogramChartConfig
     */
    backgroundColor?: string | BackgroundColor;

    chartArea?: ChartArea;

    dataOpacity?: number;

    colors: string[] = [];

    enableInteractivity?: boolean;

    focusTarget?: string;

    fontSize?: number;

    fontName?: string;

    forceIFrame?: boolean;

    hAxis?: HAxis;

    histogram?: Histogram;

    height?: number;

    interpolateNulls?: boolean;

    isStacked?: boolean;

    legend?: Legend;

    title?: string;

    titlePosition?: string;

    titleTextStyle?: string;

    tooltip?: ToolTip;

    constructor( title, legend, colors, interpolateNulls) {
        // this.height = height;
        this.title = title;
        this.legend = legend;
        this.colors = colors;
        this.interpolateNulls = interpolateNulls;
    }

}

export class Animation {

    /**
     * The duration of the animation, in milliseconds
     * 
     * @type {number}
     * @memberof Animation
     */
    duration: number;

    /**
     * The easing function applied to the animation. The following options are available:

     *  'linear' - Constant speed.
     *   'in' - Ease in - Start slow and speed up.
     *   'out' - Ease out - Start fast and slow down.
     *   'inAndOut' - Ease in and out - Start slow, speed up, then slow down.
     * 
     * @type {string}
     * @memberof Animation
     */
    easing: string;

    /**
     * Determines if the chart will animate on the initial draw. 
     * If true, the chart will start at the baseline and animate to its final state.
     * 
     * @type {boolean}
     * @memberof Animation
     */
    startup: boolean;


}

export class BackgroundColor {
    /**
     * The color of the chart border, as an HTML color string.

     *   Type: string
     *   Default: '#666'
     * 
     * @type {string}
     * @memberof BackgroundColor
     */
    stroke: string;

    /**
     * The border width, in pixels.

     *   Type: number
     *   Default: 0
     * 
     * @type {number}
     * @memberof BackgroundColor
     */
    strokeWidth: number;

    /**
     * The chart fill color, as an HTML color string. 
     * 
     * @type {string}
     * @memberof BackgroundColor
     */
    fill: string;
}

export class Bar {

    /**
     * The width of a group of bars, specified in either of these formats:
     *   Pixels (e.g. 50).
     *   Percentage of the available width for each group (e.g. '20%'), where '100%' means that groups have no space between them.
     * 
     * @type {(number | string)}
     * @memberof Bar
     */
    groupWidth: number | string;
}

export class ChartArea {

    backgroundColor: string | BackgroundColor;

    left: number | string;

    top: number | string;

    width: number | string;

    height: number | string;

}

export class HAxis {

    /**
     * An object with members to configure the gridlines on the horizontal axis. To specify properties of this object, you can use object literal notation, as shown here:

     *    {color: '#333', count: 4}
     * 
     * @type {*}
     * @memberof HAxis
     */
    gridlines: GridLines;

}

export class GridLines {

    color: string;

    count: number;

    units: number;
}

export class Histogram {

    bucketSize?: number;

    hideBucketItems?: boolean;

    lastBucketPercentile?: number;

    minValue?: number;

    maxValue?: number;
}

export class Legend {
    alignment?: string;

    maxLines?: number;

    position?: string;

    textStyle?: any;

    constructor(position, maxLines) {
        this.position = position;
        this.maxLines = maxLines;
    }
}

export class ToolTip {

    isHtml: boolean;

    showColorCode: boolean;

    textStyle: any;

    trigger: string;
}