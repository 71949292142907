import { Campaign } from "./campaign";
import { Area } from "../Area/area";
import { HoardingLightEnum } from "../../shared/constants/hoarding-light-enum";
import { Picture } from "../hoardings/picture";
import { MediaSubCategoryEnum } from '../../shared/constants/media-sub-category-enum';

export class AbstractMounting {
    id: string;
    campaign: Campaign = new Campaign();
    customId: string;
    district: string;
    city: string;
    area: Area = new Area();
    location: string;
    size: string;
    lightType: HoardingLightEnum;
    squareFeet: string;
    itemStartDate: Date;
    itemEndDate: Date;
    employee: string;
    width: number;
    height: number;
    status: string; // hoarding / group status
    itemStatus: string;
    areaName: string;
    images: Picture[] = [];
    mediaSubCategory: MediaSubCategoryEnum;
    employeeName: string
}
