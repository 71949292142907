import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ButtonModule, SharedModule, InputTextModule, TooltipModule, FileUploadModule, DropdownModule, CalendarModule, AutoCompleteModule, RadioButtonModule, ProgressSpinnerModule } from 'primeng/primeng';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ButtonModule,
    SharedModule,
    InputTextModule,
    TooltipModule,
    FileUploadModule,
    DropdownModule,
    CalendarModule,
    AutoCompleteModule,
    RadioButtonModule,
    ProgressSpinnerModule
  ],
  declarations: [
    UserProfileComponent
  ],
  exports: [
    UserProfileComponent
  ]
})
export class UserProfileModule { }
