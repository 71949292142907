import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHoardingGroupComponent } from './common-hoarding-group/common-hoarding-group.component';
import { DialogModule, ProgressSpinnerModule, KeyFilterModule, SpinnerModule, TooltipModule, CalendarModule, DropdownModule, AutoCompleteModule, FileUploadModule, InputTextModule, SharedModule, ButtonModule, StepsModule } from 'primeng/primeng';
import { FormsModule } from '@angular/forms';
import { PhotoDialogModule } from '../photo-dialog/photo-dialog.module';
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';
import { ListHoardingsModule } from '../list-hoardings/list-hoardings.module';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';
import { InputTrimModule } from '../../../directives/input-trim/input-trim.module';
import { HttpModule } from '@angular/http';
import { ContractService } from '../../../contracts/services/contract.services';
import { ContractUiService } from '../../../contracts/services/contract-ui.services';
import { HoardingCategoryService } from '../../../services/shared/hoardingCategory.service';
import { HoardingService } from '../../../contracts/services/hoardings.service';
import { ContractsActionDispatcher } from '../../../contracts/action-dispatcher/action-dispatcher';
import { ContractActions } from '../../../contracts/actions/contract-actions';
import { StatusQueueDialogModule } from '../status-queue-dialog/status-queue-dialog.module';
import { DirectiveModules } from '../../../modules/directive.modules';
import { MapModule } from "../map/map.module";
@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    HttpModule,
    ButtonModule,
    SharedModule,
    InputTextModule,
    FileUploadModule,
    AutoCompleteModule,
    DropdownModule,
    CalendarModule,
    TooltipModule,
    InputTrimModule,
    SpinnerModule,
    CurrencyMainPipe,
    ProgressSpinnerModule,
    KeyFilterModule,
    ListHoardingsModule,
    SibGridModule,
    PhotoDialogModule,
    StepsModule,
    StatusQueueDialogModule,
    DirectiveModules,
    MapModule
  ],
  declarations: [CommonHoardingGroupComponent],

  exports: [
    CommonHoardingGroupComponent
  ],
  providers: [
    ContractService, 
    ContractUiService, 
    HoardingCategoryService, 
    HoardingService, 
    ContractsActionDispatcher, 
    ContractActions, 
  ]
})
export class CommonHoardingGroupModule { }
