import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Http, Response } from "@angular/http";

@Injectable()
export class CityService extends ApiService<any, any, any> {
    urlPath: string = 'cities/search/byDistrictId';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }

    createCity(city) {
        this.urlPath = 'cities';
        return this.create(city);
    }

    updateCity(city) {
        this.urlPath = 'cities';
        return this.patch(city);
    }

}