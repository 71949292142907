export class TimelineChartConfig {
    height?: number;
    width?: number;
    backgroundColor?: string;
    colors?: string[];
    timeline?: Timeline;
    avoidOverlappingGridLines?: boolean;
    fontName?: string;
    fontSize?: number;
    forceIFrame: boolean;


    constructor(
        height?: number,
        width?: number,
        backgroundColor?: string,
        colors?: string[],
        timeline?: Timeline,
        avoidOverlappingGridLines?: boolean,
        fontName?: string,
        fontSize?: number,
        forceIFrame?: boolean,
    ) {

        this.height = height;
        this.width = width;
        this.backgroundColor = backgroundColor;
        this.colors = colors;
        this.timeline = timeline;
        this.avoidOverlappingGridLines = avoidOverlappingGridLines;
        this.fontName = fontName;
        this.fontSize = fontSize;
        this.forceIFrame = forceIFrame;
    }
}

export class Timeline {
    groupByRowLabel?: boolean;
    colorByRowLabel?: boolean;
    barLabelStyle?: BarFontObject;
    rowLabelStyle?: RowFontObject;
    showBarLabels?: boolean;
    showRowLabels?: boolean;
    singleColor?: string;

    constructor(
        groupByRowLabel,
        colorByRowLabel,
        barLabelStyle?: BarFontObject,
        rowLabelStyle?: RowFontObject,
        showBarLabels?: boolean,
        showRowLabels?: boolean,
        singleColor?: string,
    ) {
        this.groupByRowLabel = groupByRowLabel;
        this.colorByRowLabel = colorByRowLabel;
        this.barLabelStyle = barLabelStyle;
        this.rowLabelStyle = rowLabelStyle;
        this.showBarLabels = showBarLabels;
        this.showRowLabels = showRowLabels;
        this.singleColor = singleColor;
    }
}

export class BarFontObject {
    fontName?: string;
    fontSize?: string;
}

export class RowFontObject {
    color: string;
    fontName?: string;
    fontSize?: string;
}