import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Country } from "./country";
import { District } from "./district";
import { GeoCoordinate } from "../hoardings/geo-coordinate";

export class City extends SibBaseObject {
    name: string;
    code: string;
    key: string;
    // latitude: string;
    // longitude: string;
    geoCoordinate: GeoCoordinate = new GeoCoordinate();
    district: District = new District();
    category: string = 'CUSTOM';
}