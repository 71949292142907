import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";

@Injectable()
export class UpdateHoardingService extends ApiService<any, any, any> {
    urlPath = 'hoardings/update';

    constructor(Http: HttpClient, Injector: Injector) {
        super(Http, Injector)
    }
}
