import { Injectable } from "@angular/core";
import { ActionExtended } from "../../shared/actionextended";
import 'rxjs/Rx';

@Injectable()
export class HoardingActions {
    static UPDATE_HOARDING_HOARDING = 'UPDATE_HOARDING_HOARDING';
    static UPDATE_HOARDING_SUCCESS = '[HoardingActions] UPDATE_HOARDING_SUCCESS';
    static UPDATE_HOARDING_FAILURE = '[HoardingActions] UPDATE_HOARDING_FAILURE';
    static UPDATE_HOARDING_GROUP_FROM_HOARDING = 'UPDATE_HOARDING_GROUP_FROM_HOARDING';
    static UPDATE_HOARDING_GROUP_SUCCESS_FROM_HOARDING = 'UPDATE_HOARDING_GROUP_SUCCESS_FROM_HOARDING';
    static UPDATE_HOARDING_GROUP_FAILURE = 'UPDATE_HOARDING_GROUP_FAILURE';
    static RESET_HOARDING = 'RESET_HOARDING';
    static RESET_ERROR = 'RESET_ERROR';
    static GET_PLAN = 'GET_PLAN';
    static GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
    static GET_PLAN_FAILURE = 'GET_PLAN_FAILURE';
    static GET_CAMPAIGN = 'GET_CAMPAIGN';
    static GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
    static GET_CAMPAIGN_FAILURE = 'GET_CAMPAIGN_FAILURE';
    static CREATE_PLAN = 'CREATE_PLAN';
    static CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
    static CREATE_PLAN_FAILURE = 'CREATE_PLAN_FAILURE';
    static HOARDING_UPDATE_PLAN = 'HOARDING_UPDATE_PLAN';
    static UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
    static UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE';
    static RESET_IS_PLAN_CREATED = 'RESET_IS_PLAN_CREATED';
    static RESET_CREATED_PLAN_ID = 'RESET_CREATED_PLAN_ID';
    static RESET_IS_HOARDING_GROUP_UPDATED_FROM_HOARDING = 'RESET_IS_HOARDING_GROUP_UPDATED_FROM_HOARDING';
    static UPDATE_HOARDING_CAMPAIGN = 'UPDATE_HOARDING_CAMPAIGN';
    static UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
    static UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE';
    static RESET_IS_CAMPAIGN_UPDATED = 'RESET_IS_CAMPAIGN_UPDATED';

    static SET_HOARDING_FILTERS = 'SET_HOARDING_FILTERS';
    static RESET_SINGLE_PLAN = 'RESET_SINGLE_PLAN';

    static UPDATE_HOARDING_WITH_IMAGE_HM = 'UPDATE_HOARDING_WITH_IMAGE_HM';
    static UPDATE_HOARDING_WITH_IMAGE_HM_SUCCESS = 'UPDATE_HOARDING_WITH_IMAGE_HM_SUCCESS';
    static RESET_UPDATE_HOARDING_WITH_IMAGE_HM = 'RESET_UPDATE_HOARDING_WITH_IMAGE_HM';
    static UPDATE_GROUP_WITH_IMAGE_HM = 'UPDATE_GROUP_WITH_IMAGE_HM';
    static UPDATE_GROUP_WITH_IMAGE_HM_SUCCESS = 'UPDATE_GROUP_WITH_IMAGE_HM_SUCCESS';
    static UPDATE_GROUP_WITH_IMAGE_HM_ERROR = 'UPDATE_GROUP_WITH_IMAGE_HM_ERROR';
    static RESET_UPDATE_GROUP_WITH_IMAGE_HM = 'RESET_UPDATE_GROUP_WITH_IMAGE_HM';
    static RESET_UPDATE_GROUP_WITH_IMAGE_HM_ERROR = 'RESET_UPDATE_GROUP_WITH_IMAGE_HM_ERROR'
    updateHoarding(hoarding): ActionExtended {
        return {
            type: HoardingActions.UPDATE_HOARDING_HOARDING,
            payload: hoarding
        };
    }

    updateHoardingSuccess(hoarding): ActionExtended {
        return {
            type: HoardingActions.UPDATE_HOARDING_SUCCESS,
            payload: hoarding
        };
    }

    updateHoardingFailure(): ActionExtended {
        return {
            type: HoardingActions.UPDATE_HOARDING_FAILURE
        };
    }

    resetHoarding(): ActionExtended {
        return {
            type: HoardingActions.RESET_HOARDING,
        };
    }

    resetError(): ActionExtended {
        return {
            type: HoardingActions.RESET_ERROR
        };
    }

    getCampaign(campaignId): ActionExtended {
        return {
            type: HoardingActions.GET_CAMPAIGN,
            payload: campaignId
        };
    }

    getCampaignSuccess(campaign): ActionExtended {
        return {
            type: HoardingActions.GET_CAMPAIGN_SUCCESS,
            payload: campaign
        };
    }

    getCampaignFailure(): ActionExtended {
        return {
            type: HoardingActions.GET_CAMPAIGN_FAILURE
        };
    }


    getPlan(planId): ActionExtended {
        return {
            type: HoardingActions.GET_PLAN,
            payload: planId
        };
    }

    getPlanSuccess(plan): ActionExtended {
        return {
            type: HoardingActions.GET_PLAN_SUCCESS,
            payload: plan
        };
    }

    getPlanFailure(): ActionExtended {
        return {
            type: HoardingActions.GET_PLAN_FAILURE
        };
    }


    createPlan(plan): ActionExtended {
        return {
            type: HoardingActions.CREATE_PLAN,
            payload: plan
        };
    }

    createPlanSuccess(response): ActionExtended {
        return {
            type: HoardingActions.CREATE_PLAN_SUCCESS,
            payload: response
        };
    }

    createPlanFailure(): ActionExtended {
        return {
            type: HoardingActions.CREATE_PLAN_FAILURE
        };
    }

    hoardingUpdatePlan(plan): ActionExtended {
        return {
            type: HoardingActions.HOARDING_UPDATE_PLAN,
            payload: plan
        };
    }

    updatePlanSuccess(plan): ActionExtended {
        return {
            type: HoardingActions.UPDATE_PLAN_SUCCESS,
            payload: plan
        };
    }

    updatePlanFailure(): ActionExtended {
        return {
            type: HoardingActions.UPDATE_PLAN_FAILURE
        };
    }

    resetIsPlanCreated(): ActionExtended {
        return {
            type: HoardingActions.RESET_IS_PLAN_CREATED
        };
    }

    resetCreatedPlanId(): ActionExtended {
        return {
            type: HoardingActions.RESET_CREATED_PLAN_ID
        };
    }

    updateHoardingGroup(hoardingGroup): ActionExtended {
        return {
            type: HoardingActions.UPDATE_HOARDING_GROUP_FROM_HOARDING,
            payload: hoardingGroup
        };
    }

    updateHoardingGroupSuccess(response): ActionExtended {
        return {
            type: HoardingActions.UPDATE_HOARDING_GROUP_SUCCESS_FROM_HOARDING,
            payload: response
        };
    }

    updateHoardingGroupFailure(): ActionExtended {
        return {
            type: HoardingActions.UPDATE_HOARDING_GROUP_FAILURE
        };
    }

    resetIsHoardingGroupUpdated(): ActionExtended {
        return {
            type: HoardingActions.RESET_IS_HOARDING_GROUP_UPDATED_FROM_HOARDING
        };
    }

    updateHoardingCampaign(campaignItems): ActionExtended {
        return {
            type: HoardingActions.UPDATE_HOARDING_CAMPAIGN,
            payload: campaignItems
        };
    }

    updateCampaignSuccess(campaignItems): ActionExtended {
        return {
            type: HoardingActions.UPDATE_CAMPAIGN_SUCCESS,
            payload: campaignItems
        };
    }

    updateCampaignFailure(): ActionExtended {
        return {
            type: HoardingActions.UPDATE_CAMPAIGN_FAILURE
        };
    }

    resetIsCampaignUpdated(): ActionExtended {
        return {
            type: HoardingActions.RESET_IS_CAMPAIGN_UPDATED
        };
    }

    setHoardingFilters(filters): ActionExtended {
        return {
            type: HoardingActions.SET_HOARDING_FILTERS,
            payload: filters
        };
    }

    resetSinglePlan(): ActionExtended {
        return {
            type: HoardingActions.RESET_SINGLE_PLAN
        };
    }

    updateHoardingWithImageHM(hoarding) {
        return {
            type: HoardingActions.UPDATE_HOARDING_WITH_IMAGE_HM,
            payload: hoarding
        }
    }

    updateHoardingWithImageHMSuccess(hoarding) {
        return {
            type: HoardingActions.UPDATE_HOARDING_WITH_IMAGE_HM_SUCCESS
        }
    }

    resetUpdateHoardingWithImageHM() {
        return {
            type: HoardingActions.RESET_UPDATE_HOARDING_WITH_IMAGE_HM
        }
    }

    updateGroupWithImage(hoardingGroup) {
        return {
            type: HoardingActions.UPDATE_GROUP_WITH_IMAGE_HM,
            payload: hoardingGroup
        }
    }

    updateGroupWithImageSuccess(hoardingGroup) {
        return {
            type: HoardingActions.UPDATE_GROUP_WITH_IMAGE_HM_SUCCESS
        }
    }

    updateGroupWithImageError() {
        return {
            type: HoardingActions.UPDATE_GROUP_WITH_IMAGE_HM_ERROR
        }
    }

    resetUpdateGroupWithImageError() {
        return {
            type: HoardingActions.RESET_UPDATE_GROUP_WITH_IMAGE_HM_ERROR
        }
    }

    resetUpdateGroupWithImageHM() {
        return {
            type: HoardingActions.RESET_UPDATE_GROUP_WITH_IMAGE_HM
        }
    }
}
