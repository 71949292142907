import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { User } from "./user";
import { Picture } from "../hoardings/picture";
import { MountImageStatus } from "../../shared/constants/mount-image-status-enum";
import { PrintingPrice } from "../plans/printingPrice";
import { MountingPrice } from "../plans/mountingPrice";
import { Note } from "../billings/note";

export class MountingItem {
    id: string;
    mounter: User = new User();
    images: Picture[] = [];
    imageStatus: MountImageStatus;
    creativeName: string;
    printingPrice: PrintingPrice = new PrintingPrice();
    mountingPrice: MountingPrice = new MountingPrice();
    totalPrice: number;
    // action:string;
    date: Date;
    note: Note;
}