<div class="capacityChartDrilldown" *ngIf="viewCapacityChartDrilldown">
    <div *ngIf="isloading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div>
    <div class="flex-action-container float-right">
        <div class="flex-action-item margin-top-8">
            <button pButton label="MOVE TO PLAN ({{totalSelectedInventory()}})" class="mtab-primary float-right"
                (click)="moveSelectedInventory()"
                [disabled]="selectedKiosks.length === 0 && selectedHoardingRows.length === 0"></button>
        </div>
        <div class="flex-action-item margin-top-8">
            <button pButton label="CLEAR" class="mtab-primary float-right" (click)="resetRows()"
                [disabled]="selectedKiosks.length === 0 && selectedHoardingRows.length === 0"></button>
        </div>
        <div class="flex-action-item margin-top-12">
            <p-multiSelect [options]="modifiedColumns" defaultLabel="Show Columns" [style]="{'width':'132px'}"
                maxSelectedLabels="0" name="name" [(ngModel)]="selectedColumns" (onChange)="emitSetColumns()"
                [dropdownIcon]="'fa fa-cog'" appendTo="body" (onPanelHide)="updatingUserGridConfig()"
                [displaySelectedLabel]="false" [panelStyleClass]="'hm-showcol-drop-down'"
                [styleClass]="'show-column-multi-hoarding'">
            </p-multiSelect>
        </div>
        <div class="flex-action-item margin-top-15-4">
            <span><b><span style="color: #564c50">Hoardings on date: </span></b>{{getFormattedDate()}}</span>
        </div>
    </div>

    <div class="content-pane">
        <p-tabView (onChange)="onTabChange($event)" [style]="{'margin-top': '-40px', 'padding': '0px'}">
            <p-tabPanel header="Hoardings ({{totalHoardingRecords}})">
                <sib-chart-drilldown-hoardings [gridConfig]="hoardingGridConfig" (eEmitViewImage)="viewImages($event)"
                    (eEmitViewDetail)="viewDetail($event)" (eRowSelection)="onHoardingRowSelection($event)"
                    [totalRecords]="totalHoardingRecords">
                </sib-chart-drilldown-hoardings>
            </p-tabPanel>
            <p-tabPanel header="Kiosks ({{totalKioskRecords}})">
                <sib-chart-drilldown-kiosks [gridConfig]="kioskGridConfig" (eEmitViewImage)="viewImages($event)"
                    (eEmitViewDetail)="viewDetail($event)" (eRowSelection)="onKioskRowSelection($event)"
                    [totalRecords]="totalKioskRecords">
                </sib-chart-drilldown-kiosks>
            </p-tabPanel>
        </p-tabView>
    </div>


    <p-dialog header="Create Plan" *ngIf="createPlan" [(visible)]="createPlan" [autoAlign]="true"
        [width]=" (activeIndex === 1) ? 370 : 1200" [modal]="true" (onHide)="onHideStepperDialog()" blockScroll="true">
        <div class="ui-g margin-top--7">
            <div class="ui-g-4 ctext-left">
                <ul class="c-list-inline">
                    <li *ngIf="activeIndex !== 0">
                        <button class="mtab-primary step-btn" (click)="previousClick()" pButton
                            icon="fa fa-angle-double-left"></button>
                    </li>
                </ul>
            </div>
            <div class="ctext-center" [ngClass]="activeIndex === 1 ? 'ui-g-8 margin-left--70' : 'ui-g-4'">
                <ul class="c-list-inline">
                    <li>
                        <p-steps class="c-stepper" [model]="planStepItems" [(activeIndex)]="activeIndex"
                            [readonly]="true" (activeIndexChange)="onActiveIndexChange(activeIndex)">
                            <!-- (activeIndexChange)="onActiveIndexChange()" -->
                        </p-steps>
                    </li>
                </ul>
            </div>

            <div class="ui-g-4 ctext-right" *ngIf="activeIndex !== 1">
                <ul class="c-list-inline">
                    <li>
                        <ul class="c-list-inline">
                            <li *ngIf="activeIndex !== 1" class="margin-bottom-5">
                                <button class="mtab-primary margin-right-20 step-btn" (click)="nextClick()" pButton
                                    icon="fa fa-angle-double-right"
                                    [disabled]="selectedInventoryValues.length === 0"></button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="createPlan && activeIndex === 0">
            <div class="ui-g gridScroller">
                <sib-view-inventory [columns]="dataTableColumns" [gridConfig]="dataTableGridConfig"
                    (eEmitRemoveHoarding)="removeHoarding($event)">
                </sib-view-inventory>
            </div>
        </div>

        <div *ngIf="createPlan && activeIndex === 1">
            <sib-assign-to-plan-dialog #assignToPlan [planEdit]="planEdit" [displayPlanDialog]="displayPlanDialog"
                (eEmitChangeDisplay)="hidePlanDialog()" (eEmitCreatePlan)="creatPlan($event)"
                (eEmitSavePlan)="creatPlan($event)">
            </sib-assign-to-plan-dialog>
        </div>
    </p-dialog>

    <sib-status-queue-dialog *ngIf="displayStatusQueueDialog" [displayStatusQueueDialog]="displayStatusQueueDialog"
        [rowData]="rowData" [values]="rowData?.statusQueues"
        (eEmitChangeStatusQueueDialogDisplay)="hideStatusQueueDialog()"></sib-status-queue-dialog>

    <sib-photo-dialog (displayReset)="changePhotoDisplay($event)"></sib-photo-dialog>
</div>