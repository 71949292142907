import { Injectable } from "@angular/core";
import { GSTDetail } from "../../modals/KYC/GSTDetails";
import { GstBranchAddress } from "../../modals/KYC/gst-branch-address";
import { Subject } from "rxjs";

@Injectable()
export class CustomerUiService {

    public $copyMainAddress = new Subject<any>();
    public $address = new Subject<any>();
    // public userInfo = JSON.parse(localStorage.getItem('userInfo'))[0];
    public userInfo = (localStorage.getItem('userInfo') && localStorage.getItem('userInfo')[0]) ? JSON.parse(localStorage.getItem('userInfo'))[0] : null;

    constructor() {

    }

    modifyFileName(file: File, name: string) {
        const re: RegExp = /(?:\.([^.]+))?$/;
        console.log("filename", file.name);
        const extension: string = re.exec(file.name)[1];
        const date = new Date().getTime();
        const updatedName: string = name + date.toString() + '.' + extension;
        const blob: any = file.slice(0, -1, file.type);
        const newfile: File = new File([blob], updatedName, { type: file.type });
        return newfile;
    }

    modifyFileNameMultiple(file: File, name: string) {
        const re: RegExp = /(?:\.([^.]+))?$/;
        const extension: string = re.exec(file.name)[2];
        const date = new Date().getTime();
        const updatedName: string = name + date.toString() + '.' + extension;
        const blob: any = file.slice(0, -1, file.type);
        const newfile: File = new File([blob], updatedName, { type: file.type });
        return newfile;
    }

    /**
     * checking if data already exist in dataList based on field passed. 
     * 
     * @param {any} data 
     * @param {any} dataList 
     * @param {any} field 
     * @returns {boolean} 
     * @memberof CustomerUiService
     */
    isAlreadyExist(data, dataList, field, compareType): boolean {


        for (let i = 0; i < dataList.length; i++) {

            if (dataList[i][field] !== '' && dataList[i][field] !== null && dataList[i][field] === data[field] && compareType === 'match') {
                return true
            }

            if (dataList[i][field] !== '' && dataList[i][field] !== null && dataList[i][field].indexOf(data) > -1 && compareType === 'contains') {
                return true
            }

        };
        return false;
    }

    validateGstDetails(gstDetails: GSTDetail[] = []): boolean {
        var validateGstDetail: boolean = false;
        for (let i = 0; i < gstDetails.length; i++) {
            validateGstDetail = this.validateGstDetail(gstDetails[i])
            var validGstAddress: boolean;
            for (let j = 0; j < gstDetails[i].branchAddress.length; j++) {
                validGstAddress = this.validateBranchAddress(gstDetails[i].branchAddress[j])
                if (!validGstAddress) {
                    break;
                }
            }
            if (!validateGstDetail || !validGstAddress) {
                return false;
            } else if (i === gstDetails.length - 1) {
                return true;
            }
        }
    }

    validateGstDetail(gstDetail: GSTDetail) {
        if (gstDetail.gstNo &&
            gstDetail.panNo
        ) {
            return true;
        } else {
            return false;
        }
    }

    validateBranchAddresses(branchAddresses: GstBranchAddress[] = []) {
        var validateBranchAddress: boolean = false;
        for (let i = 0; i < branchAddresses.length; i++) {
            validateBranchAddress = this.validateBranchAddress(branchAddresses[i])
            if (!validateBranchAddress) {
                return false;
            } else if (i === branchAddresses.length - 1) {
                return true;
            }
        }
    }

    validateBranchAddress(branchAddress: GstBranchAddress): boolean {
        if (branchAddress.branchName &&
            branchAddress.address1
            && branchAddress.city && branchAddress.city.id
            && branchAddress.district && branchAddress.district.id
            && branchAddress.state && branchAddress.state.id
            && branchAddress.postalCode
        ) {
            return true;
        } else {
            return false;
        }
    }

    getPanNumber(gstNo) {
        return gstNo.substring(2, 12)
    }

    getState(gstNo, stateList) {
        return stateList.filter((state) =>
            (state.key === gstNo.substring(0, 2))
        )
    }

    getUserEmail() {
        return this.userInfo ? this.userInfo.email : null;
    }

    getUserRole(): string {
        if (this.userInfo && this.userInfo['app_metadata']['authorization']['permissions'].indexOf('readAll') > -1) {
            return LocalUserRoleEnum['ADMIN'];
        } else if (this.userInfo && this.userInfo['app_metadata']['authorization']['permissions'].indexOf('create:customers') > -1) {
            return LocalUserRoleEnum['SALES_REPRESENTATIVE'];
        }
    }


    /** Raveena
     * Checks whether the gst no and state are matching or not.
     *
     * @param {*} gstNo
     * @param {*} stateList
     * @param {*} currentState
     * @returns
     * @memberof CustomerUiService
     */
    validateGstAndMainAddress(gstNo, stateList, mainAddressState, index) {

        var state = this.getState(gstNo, stateList)[0];
        // var state = this.getState(gstNo, stateList)[0];
        if (state && state.id && state.id !== mainAddressState) {
            return false;
        } else {
            return true;
        }

    }
}

export enum LocalUserRoleEnum {
    SALES_REPRESENTATIVE = "Sales Rep",
    ADMIN = "Admin",
}