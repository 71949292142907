import { MountingItem } from "../campaigns/mountingItem";
import { AbstractMounting } from "../campaigns/abstractMounting";

// export class MountingMaster {
//     id: string;
//     mountingItems: MountingItem = new MountingItem();
//     customId: string;
//     district: string;
//     city: string;
//     area: Area;
//     location: string;
//     size: string;
//     campaign: Campaign;
//     lightType: HoardingLightEnum;
//     squareFeet: string;
//     images: Picture[] = [];
// }
export class MountingMaster extends AbstractMounting {
    mountingItems: MountingItem = new MountingItem();
    rate: number;
}


