import { Contracts } from "../../modals/contracts/contracts";
import { ActionExtended } from "../../shared/actionextended";
import { ContractActions } from "../actions/contract-actions";
import { HoardingGroup } from "../../modals/hoardings/hoardings-group";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";

export class ContractState {
    contracts: Contracts[];
    contract: Contracts;
    hoarding: ContractHoardings;
    isHoardingFromContractCreated = false;
    contractCreated = false;
    isHoardingUpdated = false;
    statesList: any[] = [];
    districtsList: any[] = [];
    citiesList: any[] = [];
    areasList: any[] = [];
    isHoardingAdded = false;
    isHoardingGroupCreated = false;
    // isHoardingGroupUpdated = false;
    hoardingGroups: HoardingGroup[] = [];
    isHoardingAddedToGroup = false;
    hoardingGroup: HoardingGroup = new HoardingGroup();
    isHoardingsAddedToContract = false;
    isError = false;
    getContractByIdFailedFlag: boolean = false;
    isHoardingGroupUpdated: boolean = false;
    updateHoardingGroupError: boolean = false;
    isContractUpdated: boolean = false;
    updatedContract: Contracts;
    contractById: Contracts;
    hoardingGroupWithImageUpdated: boolean = false;
    hoardingWithImageUpdated: boolean = false;
    errorCreatingContract: boolean = false;
    errorCreatingContractHoarding: boolean = false;
    updateContractFailed: boolean = false;
}

const initialState: ContractState = new ContractState();

export function contractReducer(state = initialState, action: ActionExtended) {

    switch (action.type) {

        case ContractActions.CREATE_CONTRACT_SUCCESS: {
            return Object.assign({}, state, { contract: action.payload, contractCreated: true });
        }

        case ContractActions.GET_STATES_SUCCESS_CONTRACT: {
            return Object.assign({}, state, { statesList: action.payload });
        }

        case ContractActions.GET_DISTRICTS_SUCCESS_CONTRACT: {
            return Object.assign({}, state, { districtsList: [...state.districtsList, ...action.payload] });
        }

        case ContractActions.RESET_DISTRICT_LIST_CONTRACT: {
            return Object.assign({}, state, { districtsList: [] });
        }

        case ContractActions.GET_CITIES_SUCCESS_CONTRACT: {
            return Object.assign({}, state, { citiesList: [...state.citiesList, ...action.payload] });
        }

        case ContractActions.GET_AREAS_SUCCESS_CONTRACT: {
            return Object.assign({}, state, { areasList: [...state.areasList, ...action.payload] });
        }

        case ContractActions.SET_CONTRACT: {
            return Object.assign({}, state, { contract: action.payload });
        }

        case ContractActions.RESET_CITIES_LIST_CONTRACT: {
            return Object.assign({}, state, { citiesList: [] });
        }

        case ContractActions.RESET_AREAS_LIST_CONTRACT: {
            return Object.assign({}, state, { areasList: [] });
        }

        case ContractActions.GET_CONTRACT_BY_ID_SUCCESS: {
            return Object.assign({}, state, { contractById: action.payload });
        }

        /* case ContractActions.ADD_HOARDINGS_CONTRACT_SUCCESS: {
            return Object.assign({}, state, { isHoardingAdded: true});
        } */

        case ContractActions.CREATE_HOARDING_GROUP_SUCCESS: {
            return Object.assign({}, state, { isHoardingGroupCreated: true, hoardingGroup: action.payload });
        }

        case ContractActions.GET_GROUPS_BY_CONTRACTID_SUCCESS: {
            return Object.assign({}, state, { hoardingGroups: action.payload });
        }

        case ContractActions.CREATE_HOARDING_FROM_CONTRACT_SUCCESS: {
            return Object.assign({}, state, { hoarding: action.payload, isHoardingFromContractCreated: true });
        }

        case ContractActions.RESET_CONTRACT: {
            return Object.assign({}, state, { contract: new Contracts() });
        }

        case ContractActions.RESET_HOARDING: {
            return Object.assign({}, state, { hoarding: new ContractHoardings()});
        }

        case ContractActions.SAVE_GROUP_HOARDINGS_SUCCESS: {
            return Object.assign({}, state, { isHoardingAddedToGroup: true });
        }

        case ContractActions.GET_GROUPS: {
            return Object.assign({}, state, { hoardingGroups: action.payload });
        }

        case ContractActions.GET_GROUPS_SUCCESS: {
            return Object.assign({}, state, { hoardingGroups: action.payload });
        }

        case ContractActions.ADD_HOARDINGS_CONTRACT_SUCCESS: {
            return Object.assign({}, state, { isHoardingsAddedToContract: true });
        }

        case ContractActions.RESET_HOARDING_ADDED_TO_GROUP: {
            return Object.assign({}, state, { isHoardingAddedToGroup: false });
        }

        case ContractActions.RESET_HOARDING_ADDED_TO_CONTRACT: {
            return Object.assign({}, state, { isHoardingsAddedToContract: false });
        }

        case ContractActions.CREATE_HOARDING_FAILURE: {
            return Object.assign({}, state, { errorCreatingContractHoarding: true });
        }

        case ContractActions.RESET_ERROR: {
            return Object.assign({}, state, { isError: false, errorCreatingContractHoarding: false });
        }

        case ContractActions.GROUP_CREATION_FAILED: {
            return Object.assign({}, state, { isError: true });
        }

        case ContractActions.GET_GROUP_BY_ID_SUCCESS: {
            return Object.assign({}, state, { hoardingGroup: action.payload });
        }

        case ContractActions.RESET_CREATE_CONTRACT_FLAG: {
            return Object.assign({}, state, { contractCreated: false });
        }

        case ContractActions.UPDATE_HOARDING_AFTER_CREATION: {
            return Object.assign({}, state, { isHoardingUpdated: false });
        }

        case ContractActions.UPDATE_HOARDING: {
            return Object.assign({}, state, { isHoardingUpdated: false });
        }

        case ContractActions.UPDATE_CONTRACT_HOARDING_SUCCESS: {
            return Object.assign({}, state, { isHoardingUpdated: true });
        }

        case ContractActions.UPDATE_HOARDING_FAILURE: {
            return Object.assign({}, state, { isError: true });
        }

        case ContractActions.RESET_IS_HOARDING_UPDATED: {
            return Object.assign({}, state, { isHoardingUpdated: false });
        }

        case ContractActions.RESET_IS_HOARDING_CREATED: {
            return Object.assign({}, state, { isHoardingFromContractCreated: false });
        }

        case ContractActions.RESET_IS_HOARDING_GROUP_CREATED: {
            return Object.assign({}, state, { isHoardingGroupCreated: false });
        }

        case ContractActions.RESET_HOARDING_GROUPS: {
            return Object.assign({}, state,{hoardingGroups:[]})
        }

        case ContractActions.GET_CONTRACT_BY_ID_FAILED: {
            return Object.assign({}, state, {getContractByIdFailedFlag: true})
        }

        case ContractActions.RESET_CONTRACT_BY_ID_FAILED_FLAG: {
            return Object.assign({}, state, {getContractByIdFailedFlag: false})
        }

        case ContractActions.UPDATE_HOARDING_GROUP_SUCCESS: {
            return Object.assign({}, state, {isHoardingGroupUpdated: true})
        }
        
        case ContractActions.RESET_UPDATE_HOARDING_GROUP: {
            return Object.assign({}, state, {isHoardingGroupUpdated: false})
        }

        case ContractActions.UPDATE_HOARDING_GROUP_ERROR: {
            return Object.assign({}, state, {updateHoardingGroupError: true})
        }

        case ContractActions.RESET_UPDATE_HOARDING_GROUP_ERROR: {
            return Object.assign({}, state, {updateHoardingGroupError: false})
        }

        case ContractActions.UPDATE_CONTRACT_SUCCESS: {
            return Object.assign({}, state, {isContractUpdated: true, updatedContract: action.payload})
        }

        case ContractActions.RESET_IS_CONTRACT_UPDATED: {
            return Object.assign({}, state, {isContractUpdated: false, updatedContract: {}})
        }

        case ContractActions.UPDATE_HOARDING_GROUP_WITH_IMAGE_SUCCESS: {
            return Object.assign({}, state, {hoardingGroupWithImageUpdated: true})
        }

        case ContractActions.RESET_UPDATE_HOARDING_GROUP_WITH_IMAGE: {
            return Object.assign({}, state, {hoardingGroupWithImageUpdated: false})
        }

        case ContractActions.UPDATE_HOARDING_WITH_IMAGE_SUCCESS: {
            return Object.assign({}, state, {hoardingWithImageUpdated: true})
        }

        case ContractActions.RESET_UPDATE_HOARDING_WITH_IMAGE: {
            return Object.assign({}, state, {hoardingWithImageUpdated: false})
        }

        case ContractActions.RESET_HOARDING_GROUP_FROM_CONTRACT: {
            return Object.assign({}, state, {hoardingGroup: {}})
        }

        case ContractActions.CONTRACT_CREATION_ERROR: {
            return Object.assign({}, state, {errorCreatingContract: true})
        }

        case ContractActions.RESET_CONTRACT_CREATION_ERROR: {
            return Object.assign({}, state, {errorCreatingContract: false})
        }

        case ContractActions.UPDATE_CONTRACT_FAILED: {
            return Object.assign({}, state, {updateContractFailed: true})
        }

        case ContractActions.RESET_UPDATE_CONTRACT_FAILED: {
            return Object.assign({}, state, {updateContractFailed: false})
        }
        
        default: {
            return state;
        }
    }
}
