import { Component, OnInit, ViewChild, Input, AfterViewInit, EventEmitter, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GridComponent } from '../../../components/sib-forms/grid/grid.component';
import { Kiosk } from '../../../modals/hoardings/kiosk';
import { Subscription } from 'rxjs';
import { GridColumn, EditableGridFieldTypes, Alignment, GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { FormGroup, FormControl } from '@angular/forms';
import { HoardingLightEnum } from '../../constants/hoarding-light-enum';
import { DateUtil } from '../../../helpers/date.util';
import { CurrencyHelperPipe } from '../../helpers/currency.pipe/currencyHelperPipe';
import { KioskService } from '../../../services/shared/kiosk.service';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import { HoardingStatusEnum } from '../../constants/hoarding-status-enum';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import * as utils from '../../../helpers/utils';
import { GridNameEnum } from '../../constants/grid-name.enum';
import { ChangeDetectionStrategy } from '@angular/core';
import { SelectItem, Dropdown, OverlayPanel } from 'primeng/primeng';
import { KioskTypeEnum } from '../../../shared/constants/kiosk-type.enum';
import { InventoryElevation } from '../../constants/inventory-elevation';
import { ImageFolderConfig } from '../../../modals/image/image-folder-config';
import { UploadDocumentService } from '../../../services/shared/upload-document.service';
import * as _ from 'lodash';
import { AuthorityTax } from '../../../modals/hoardings/authority-tax';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';

@Component({
  selector: 'sib-kiosks',
  templateUrl: './kiosks.component.html',
  styleUrls: ['./kiosks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KiosksComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<Kiosk>;

  @ViewChild('doc') overlay: OverlayPanel;
  @ViewChild('ratingdd') ratingDd: Dropdown;
  @ViewChild('lighttypedd') lightTypeDd: Dropdown;
  @ViewChild('kiosktypedd') kioskTypeDd: Dropdown;
  @ViewChild('tradedassetdd') tradedAssetDd: Dropdown;

  @Input() columns: any[] = [];
  @Input() gridConfig: GridConfig<Kiosk> = new GridConfig<Kiosk>();
  @Input() totalRecords = 0;

  _src: string = null;
  @Input() set source(source) {
    this._src = source;
    this.getGridColumnConfig();
  }
  get source() {
    return this._src;
  }

  _isExpired: boolean;
  @Input() set isExpired(isExpired) {
    this._isExpired = isExpired;
    this.ngOnInit();
  }
  get isExpired() {
    return this._isExpired;
  }

  @Output() eEmitEditKiosk: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewImage: EventEmitter<any> = new EventEmitter();
  @Output() eRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() eEmitViewDetail: EventEmitter<any> = new EventEmitter();
  @Output() eEmitCheckKiosk: EventEmitter<any> = new EventEmitter();

  gridColumnConfig: Array<GridColumn>;
  kioskGroup: FormGroup;
  kioskGridConfig: GridConfig<Kiosk> = new GridConfig<Kiosk>();
  kioskSelectedColumns: any[] = [];
  subscription: Subscription[] = [];
  lightTypeDropdown: any[] = [];
  kioskType: any[] = [];
  email: string; // to get the logged in user email
  authorized = false; // to check if the logged in user is authorized for all - currently admin and accountant
  ratingDropdown: SelectItem[] = [];
  tradedAssetDropdown: SelectItem[] = [];
  displayAuthorityTaxDialog: boolean = false;
  authorityTax: AuthorityTax = new AuthorityTax();
  selectedKioskId: string = '';
  changes: boolean = false;
  editable: boolean = false;
  customId: string = '';

  rowData: any;
  rowIndex: number;
  displayUploadDocDialog: boolean = false;
  imageFolderConfig: ImageFolderConfig = new ImageFolderConfig();
  clonedRowData: any;

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private kioskService: KioskService,
    private userGridConfigService: UserGridConfigService,
    private hmColService: HoardingMasterColService,
    private cd: ChangeDetectorRef,
    private uploadDocumentService: UploadDocumentService,
    private notificationServcie: NotificatoinsService,
  ) {
  }

  ngOnInit() {
    this.showUploadDocDialog();
    this.hideUploadDocDialog();
    this.gridColumnConfig = this.getGridColumnConfig();
    this.kioskGroup = new FormGroup({
      gridControl: new FormControl()
    });

    this.subscription.push(this.hmColService.selectedColumns.subscribe((kioskSelectedColumns) => {
      this.kioskSelectedColumns = JSON.parse(JSON.stringify(kioskSelectedColumns));
      this.detectChanges();
      setTimeout(() => {
        this.gridViewChildRef.setColumns();
      }, 100);
    }));

    this.subscription.push(this.hmColService.siteFromDate.subscribe((siteFromDate) => {
      /* if (!siteFromDate) {
        this.gridViewChildRef.inputSitesAvailableFrom(siteFromDate)
      } else { */
      this.gridViewChildRef.selectSitesAvailableFrom(siteFromDate);
      // }

    }));

    this.subscription.push(this.hmColService.statuses.subscribe((status) => {
      if (status) {
        this.gridViewChildRef.filterByStatuses(status)
      }

    }));

    this.subscription.push(this.hmColService.globalFilter.subscribe((globalFilterData) => {
      // if(globalFilterData) {
      this.gridViewChildRef.globalFilterHoardingMaster(globalFilterData)
      // }
    }))

    let gridNameEnum = this.setGridNameEnum();
    this.subscription.push(this.userGridConfigService.get(null, '/' + gridNameEnum)
      .subscribe((hoardingGridConfig) => {
        this.gridColumnConfig = utils.getUserConfiguredColumns(hoardingGridConfig, this.gridColumnConfig);
        this.hmColService.defaultColumns.next(this.gridColumnConfig)
        this.gridViewChildRef.ngOnInit();
      }));

    this.gridConfig.showColorCode = true; //side color code --> Raveena

    //added the light type dropdown --> Raveena
    this.lightTypeDropdown.push({ label: "All", value: "ALL" });
    Object.keys(HoardingLightEnum).forEach((light) => {
      this.lightTypeDropdown.push({ label: HoardingLightEnum[light], value: light });
    });

    this.kioskType.push({ label: "All", value: "ALL" })
    Object.keys(KioskTypeEnum).forEach((type) => {
      this.kioskType.push({ label: KioskTypeEnum[type], value: type });
    });
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const roles = userInfo[0].app_metadata.authorization.roles;
    this.email = userInfo[0].email;

    roles.forEach((role) => {
      if (role === "admin" || role === "accountant") {
        this.authorized = true;
      }
    })

    this.ratingDropdown = [
      { label: 'All', value: 'ALL' },
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
    ];

    this.tradedAssetDropdown = [
      { label: 'All', value: 'ALL' },
      { label: 'True', value: true },
      { label: 'False', value: false },
    ];

    this.setColumns();
  }

  ngAfterViewInit() {

  }

  getGridColumnConfig() {
    const columns: Array<GridColumn> = [
      {
        field: 'move',
        name: '',
        header: '',
        required: true,
        editable: false,
        hidden: false,
        width: '2vw',
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
        styleClass: 'padding-0'
      },
      {
        field: 'customId',
        name: 'customId',
        header: 'HID',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: '6vw',
        sortable: true,
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'contract.id',
        name: 'contract',
        header: 'CID',
        width: '6vw',
        required: true,
        editable: false,
        permanent: false,
        default: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.contract) {
            return data.contract.id;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'geoCoordinate.latitude',
        name: 'geoCoordinate.latitude',
        header: 'Latitude',
        required: true,
        editable: false,
        hidden: false,
        permanent: false,
        sortable: true,
        default: false,
        width: '7vw',
        styleClass: 'word-break-wrap',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.latitude;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'geoCoordinate.longitude',
        name: 'geoCoordinate.longitude',
        header: 'Longitude',
        required: true,
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        width: '7vw',
        styleClass: 'word-break-wrap',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.geoCoordinate) {
            return data.geoCoordinate.longitude;
          } else {
            return 'N/A';
          }
        },
      },
      {
        field: 'district',
        name: 'district',
        header: 'District',
        required: true,
        editable: false,
        width: '7vw',
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'city',
        name: 'city',
        header: 'City',
        required: true,
        editable: false,
        hidden: false,
        width: '7vw',
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'areaName',
        name: 'area',
        header: 'Area',
        required: true,
        width: '9vw',
        editable: false,
        hidden: false,
        sortable: true,
        permanent: false,
        default: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'location',
        name: 'location',
        header: 'Location',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '20vw',
        default: true,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'width',
        name: 'width',
        header: 'W',
        width: '50px',
        required: true,
        permanent: false,
        default: false,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.width) {
            return data.width;
          } else {
            return '-';
          }
        },

      },
      {
        field: 'height',
        name: 'height',
        header: 'H',
        width: '50px',
        required: true,
        permanent: false,
        default: false,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.height) {
            return data.height;
          } else {
            return '-';
          }
        },

      },
      {
        field: 'size',
        name: 'size',
        header: 'Size',
        width: '6vw',
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'quantity',
        name: 'quantity',
        header: 'Qty',
        width: '5vw',    // changed qty width from  4vw to 7vw -->Raveena
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.quantity) {
            return data.quantity;
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'kioskType',
        name: 'kioskType',
        header: 'Type',
        width: '6vw',    // changed qty width from  4vw to 7vw -->Raveena
        required: true,
        permanent: true,
        editable: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.kioskType) {
            return KioskTypeEnum[data.kioskType];
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: true
        },
      },
      {
        field: 'squareFeet',
        name: 'squareFeet',
        header: 'Total SqFt',
        width: '5.5vw',
        required: true,
        permanent: false,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        styleClass: 'text-right',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.squareFeet) {
            return this.currencyPipe.transform(Math.ceil(data.squareFeet));
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'lightType',
        name: 'lightType',
        header: 'Light',
        required: true,
        editable: false,
        width: '4.5vw',
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        //uncommented for light type filter--> Raveena
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return '-';
          }
        },
      },
      {
        field: 'image',
        name: '',
        header: 'Photos',
        required: true,
        editable: false,
        permanent: true,
        width: '4vw',
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.LEFT
      },
      {
        field: 'grossPrice',
        name: 'grossPrice',
        header: 'Gross Price',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.grossPrice) {
            return this.currencyPipe.transform(data.grossPrice);
          } else {
            return '-';
          }
        },
      },
      {
        field: 'minimumPrice',
        name: 'minimumPrice',
        header: 'LSP',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        width: '5.2vw', //width: '7vw',
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'relational',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.minimumPrice) {
            return this.currencyPipe.transform(data.minimumPrice);
          } else {
            return '-';
          }
        },
      },
      {
        field: 'startDate',
        name: 'startDate',
        header: 'Start Date',
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: true,
        sortable: true,
        width: '7vw',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.startDate) {
            return DateUtil.dategridFormatter(data.startDate);
          }
        },
      },
      {
        field: 'endDate',
        name: 'endDate',
        header: 'End Date',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: true,
        width: '7vw',
        displayFn: (data) => {
          if (data.endDate) {
            return DateUtil.dategridFormatter(data.endDate);
          }
        },
        type: EditableGridFieldTypes.TEXT,

      },
      {
        field: 'campaignStartDate',
        name: 'campaignStartDate',
        header: 'Campaign Start Date',
        required: true,
        editable: false,
        default: false,
        hidden: false,
        permanent: false,
        sortable: true,
        width: '7vw',
        type: EditableGridFieldTypes.TEXT,

        displayFn: (data) => {
          if (data.campaignStartDate) {
            return DateUtil.dategridFormatter(data.campaignStartDate)
          } else {
            return '-';
          }
        }
      },
      {
        field: 'campaignEndDate',
        name: 'campaignEndDate',
        header: 'Campaign End Date',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: '7vw',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.campaignEndDate) {
            return DateUtil.dategridFormatter(data.campaignEndDate)
          } else {
            return '-';
          }
        }
      },
      {
        field: 'statusQueues',
        name: 'statusQueues',
        header: 'Status',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: '7.5vw',
        type: EditableGridFieldTypes.CUSTOM,
      },
      {
        field: 'statusQueues.displayName',
        name: 'displayName',
        header: 'Display Name',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: '9vw',
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'statusQueues.employee',
        name: 'employeeName',
        header: 'Employee',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        permanent: false,
        sortable: true,
        width: '9vw',
        type: EditableGridFieldTypes.CUSTOM,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'rating',
        name: 'rating',
        header: 'Rating',
        required: true,
        editable: false,
        default: false,
        width: '5vw',
        permanent: false,
        hidden: false,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'is',
          placeHolder: 'Search',
          custom: true
        },
        displayFn: (data) => {
          if (data.rating) {
            return data.rating;
          } else {
            return '-';
          }
        },
      },
      {
        field: 'tradedAsset',
        name: 'tradedAsset',
        header: 'Traded Asset',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        width: '5.5vw',
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        // filter: {
        //   matchMode: 'binary',
        //   placeHolder: 'Search',
        //   custom: true
        // },
        displayFn: (data) => {
          if (data.tradedAsset) {
            return 'True';
          } else {
            return 'False';
          }
        },
      },
      {
        field: 'vendorName',
        name: 'vendorName',
        header: 'Vendor Name',
        required: true,
        editable: false,
        hidden: false,
        default: false,
        sortable: true,
        permanent: false,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
        displayFn: (data) => {
          if (data.vendorName) {
            return data.vendorName;
          } else {
            return '-';
          }
        }
      },
      // {
      //   field: 'gpsAzimuth',
      //   name: 'gpsAzimuth',
      //   header: 'GPS Azimuth',
      //   width: '7vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.gpsAzimuth) {
      //       return data.gpsAzimuth;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },

      // {
      //   field: 'eMeterNo',
      //   name: 'eMeterNo',
      //   header: 'Electricty Meter No.',
      //   width: '7vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.eMeterNo) {
      //       return data.eMeterNo;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },


      // {
      //   field: 'elevation',
      //   name: 'elevation',
      //   header: 'Elevation',
      //   width: '7vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.elevation) {
      //       return InventoryElevation[data.elevation];
      //     } else {
      //       return '-';
      //     }
      //   },
      // },


      // {
      //   field: 'elevationHeight',
      //   name: 'elevationHeight',
      //   header: 'Elevation Height',
      //   width: '6vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.elevationHeight) {
      //       return data.elevationHeight;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },


      // {
      //   field: 'numberOfStories',
      //   name: 'numberOfStories',
      //   header: 'Total Storey',
      //   width: '4vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.numberOfStories) {
      //       return data.numberOfStories;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },

      // {
      //   field: 'storiesStartFrom',
      //   name: 'storiesStartFrom',
      //   header: 'Storey Start',
      //   width: '5vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.storiesStartFrom) {
      //       return data.storiesStartFrom;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },

      // {
      //   field: 'storiesEndAt',
      //   name: 'storiesEndAt',
      //   header: 'Storey End',
      //   width: '5vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.storiesEndAt) {
      //       return data.storiesEndAt;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },

      // {
      //   field: 'contractor.name',
      //   name: 'contractor.name',
      //   header: 'Contractor Name',
      //   width: '10vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.contractor) {
      //       return data.contractor.name;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },

      // {
      //   field: 'contractor.contactNumber1',
      //   name: 'contractor.contactNumber1',
      //   header: 'Contractor No.',
      //   width: '8vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.contractor) {
      //       return data.contractor.contactNumber1;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },

      // {
      //   field: 'siteEngineer.name',
      //   name: 'siteEngineer.name',
      //   header: 'Site Engineer Name',
      //   width: '10vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.siteEngineer) {
      //       return data.siteEngineer.name;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },

      // {
      //   field: 'siteEngineer.contactNumber1',
      //   name: 'siteEngineer.contactNumber1',
      //   header: 'Site Engineer No.',
      //   width: '8vw',
      //   required: true,
      //   editable: false,
      //   permanent: false,
      //   default: false,
      //   hidden: false,
      //   sortable: true,
      //   type: EditableGridFieldTypes.TEXT,
      //   // filter: {
      //   //   matchMode: 'contains',
      //   //   placeHolder: 'Search',
      //   //   custom: false
      //   // },
      //   displayFn: (data) => {
      //     if (data.siteEngineer) {
      //       return data.siteEngineer.contactNumber1;
      //     } else {
      //       return '-';
      //     }
      //   },
      // },
      {
        field: 'note',
        name: 'note',
        header: 'Note',
        required: true,
        editable: false,
        permanent: false,
        width: '8vw',
        default: false,
        styleClass: 'word-break-wrap',
        // default: true,
        hidden: false,
        type: EditableGridFieldTypes.TEXT,
        align: Alignment.LEFT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'documents',
        name: 'documents',
        header: 'Doc',
        required: true,
        width: '4vw',
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: 'upload',
        name: '',
        header: '',
        required: true,
        width: '4vw',
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
      },
      {
        field: 'id',
        name: '',
        header: '',
        required: true,
        permanent: true,
        editable: false,
        width: '4vw',
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,
        /* filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: true
        }, */
      },

      {
        field: 'authorityTax',
        name: '',
        header: '',
        required: true,
        permanent: true,
        editable: false,
        width: '3vw',
        default: true,
        hidden: false,
        type: EditableGridFieldTypes.CUSTOM,
        align: Alignment.CENTER,

      },

    ];

    const filteredColumns = this.filterColumns(columns);

    return filteredColumns;
  }

  filterColumns(columns: any[]) {
    let updatedColumns: any[] = [];
    columns.forEach((column) => {
      if (this.columns.includes(column.field)) {
        column.hidden = false;
      } else {
        column.hidden = true;
      }
    });
    if (this.source !== 'contracts') {
      updatedColumns = columns.filter(col => col.field !== 'startDate' && col.field !== 'endDate');
      columns = updatedColumns;
    } else {
      columns = columns.filter(col=> col.field !== 'contract.id')
    }
    return columns;
  }

  refreshGrid() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.refresh();
    }
  }

  refresh() {
    if (this.gridConfig.dataLoadFunction) {
      this.gridViewChildRef.loadData();
    }
  }

  resetAndReloadTable() {
    this.gridViewChildRef.resetAndReloadTable();
    this.resetDropDownFilters();
  }

  editKiosk(rowData) {
    this.eEmitEditKiosk.emit(rowData)
  }

  viewImages(rowData) {
    this.eEmitViewImage.emit(rowData);
  }

  rowSelect(kiosks) {
    this.eRowSelection.emit(kiosks);
  }

  filterData(totalRecords, values, comparator) {
    this.gridViewChildRef.filterData(totalRecords, values, comparator);
  }

  addBackToKioskList(kiosks, kiosk) {
    kiosks.push(kiosk);
    this.gridViewChildRef.pushData(kiosks);
    this.detectChanges();
  }

  addMultipleBackToKioskList(kiosks, values) {
    kiosks.push(...values);
    this.gridViewChildRef.pushData(kiosks);
    this.detectChanges();
  }

  getValue(status) {
    return HoardingStatusEnum[status];
  }

  viewStatusDetail(rowData) {
    this.eEmitViewDetail.emit(rowData);
  }

  checkKiosk(rowData, rowIndex) {
    this.eEmitCheckKiosk.emit({ rowData, rowIndex });
  }

  updateUserGridConfig(event) {
    let hoardingGridConfig: Map<string, string> = new Map();
    let gridNameEnum = this.setGridNameEnum();
    hoardingGridConfig = utils.getGridConfigMap(event, this.gridColumnConfig);
    this.userGridConfigService.patch(utils.buildObject(hoardingGridConfig), null, gridNameEnum)
      .subscribe((result) => {
      });
  }

  resetDataTable() {
    this.resetDropDownFilters();
    this.gridViewChildRef.resetDataTable();
  }

  resetDropDownFilters() {
    this.ratingDd ? this.ratingDd.selectedOption = null : '';
    this.lightTypeDd ? this.lightTypeDd.selectedOption = null : '';
    this.kioskTypeDd ? this.kioskTypeDd.selectedOption = null : '';
    this.tradedAssetDd ? this.tradedAssetDd.selectedOption = null : '';
  }

  showOverlay($event, rowData, rowIndex) {
    this.clonedRowData = _.cloneDeep(rowData);
    this.rowData = rowData;
    this.rowIndex = rowIndex;
    this.overlay.show($event);
  }

  uploadDoc(rowData) {
    this.clonedRowData = _.cloneDeep(rowData)
    this.displayUploadDocDialog = true;
    this.setImageFolderConfig();
  }

  setImageFolderConfig() {
    this.imageFolderConfig.itemId = this.clonedRowData.id;
    this.imageFolderConfig.moduleName = 'kiosks';
  }

  showUploadDocDialog() {
    this.displayUploadDocDialog = true;
  }

  hideUploadDocDialog() {
    this.displayUploadDocDialog = false;
  }

  setDocument(event) {
    console.log(event)
    this.clonedRowData.documents = [...this.clonedRowData.documents, ...event];
    this.uploadDocumentService.uploadKiosksDocument(this.clonedRowData.documents, this.imageFolderConfig.itemId).subscribe((response) => {
      this.notificationServcie.success('Document Uploaded Successfully', 'Document Upload');
      this.displayUploadDocDialog = false;
      this.gridViewChildRef.refresh();
    });
  }

  detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  addAuthorityTax(rowData) {
    this.authorityTax = new AuthorityTax();
    this.customId = rowData.customId;
    this.selectedKioskId = rowData.id;
    this.displayAuthorityTaxDialog = true;
    if (rowData.authorityTax.licenseNo !== null) {
      this.authorityTax.licenseNo = rowData.authorityTax.licenseNo;
      this.authorityTax.startDate = new Date(rowData.authorityTax.startDate);
      this.authorityTax.endDate = new Date(rowData.authorityTax.endDate);
      this.authorityTax.taxAmount = rowData.authorityTax.taxAmount
      this.editable = true;
    }

    this.changes = false;
    this.cd.detectChanges();
  }

  saveAuthorityTax() {
    this.kioskService.create(this.authorityTax, null, '/updateAuthorityTax/' + this.selectedKioskId).subscribe(
      (response) => {

        if (!this.editable) {
          this.notificationServcie.success("Tax Saved sucessfully", "Tax Saved");
        } else {
          this.notificationServcie.success("Tax Updated sucessfully", "Tax Updated");
        }
        this.hideDialog();
        // this.refreshGrid()
        this.gridViewChildRef.refresh();

      }


    );
  }

  handleChange() {
    this.changes = true;
  }
  resetData() {
    this.authorityTax = new AuthorityTax();
  }

  hideDialog() {
    this.displayAuthorityTaxDialog = false;
    this.editable = false;
    this.cd.detectChanges();
  }

  setColumns() {
    this.gridColumnConfig.filter(col => {
      switch (col.field) {
        case 'id': {
          col.hidden = this.isExpired;
          break;
        }
      }
      return col;
    });
    if (this.gridViewChildRef) {
      this.gridViewChildRef.setAllColumns();
    }
  }

  /**
   * @description set grid name enum according to the source
   * @author Divya Sachan
   * @date 2020-01-16
   * @returns
   * @memberof KiosksComponent
   */
  setGridNameEnum() {
    if (this.source === 'contracts') {
      return GridNameEnum['CONTRACT_KIOSK'];
    } else {
      return GridNameEnum['KIOSK']
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    })

    this.hmColService.defaultColumns.next([])
  }
}
