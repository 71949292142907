import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ChatBaseService } from '../services/shared/chat.base.services';
import { AuthService } from '../login-service/auth.service';

@Component({
  selector: 'sib-app-chat',
  templateUrl: './sib-chat.component.html',
  styleUrls: ['./sib-chat.component.css']
})
export class SibChatComponent implements OnInit, AfterViewInit {

  loggedInUser: string;
  tenantId: string;
  chatBaseURL: string;
  chatURL: string;
  urlSafe: SafeResourceUrl;
  unreadCount: number;
  userDetails: any;
  constructor(public sanitizer: DomSanitizer, private chatService: ChatBaseService, private auth: AuthService) {
  }

  ngOnInit() {
    this.chatService.unreadMsgCnt.subscribe((value: number) => {  // getting unread count 
      if (value !== undefined && value !== null) {
        this.unreadCount = value;
      }
    });
    this.auth.authSet.subscribe((result) => {
      if (result) {           // when result is true
        this.updateUserDetails();
      }
    });
  }

  ngAfterViewInit() {
    if (this.auth && this.auth.getUserInfo()) {       // when loggedin user info is available
      this.updateUserDetails();
    }
  }

  // for logged in user email and tenant id
  updateUserDetails() {
    this.userDetails = this.auth.getUserInfo();
    this.loggedInUser = (this.userDetails && this.userDetails.email) ? this.userDetails.email : null;
    this.tenantId = localStorage.getItem('id');
    if (this.loggedInUser) {
      this.getChatData();
    }
  }

  // open chat iframe 
  showChatBox() {
    $("#chat-button").slideToggle(100, function () {
      $("#chat-box").slideToggle();
    });
  }

  // calling api for chat
  getChatData() {
    this.chatURL = environment.chatBaseURL + "/?emailId=" + this.loggedInUser + "&tenantId=" + this.tenantId;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.chatURL);
  }
}
