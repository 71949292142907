import { AfterViewInit, OnDestroy, Component, ViewEncapsulation, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { InvoiceTaskCard } from "../../../modals/billings/invoice-task-card";
import { TaskCardStatusEnum } from "../../../shared/constants/task-card-status.enum";
import { InvoiceCharEnum } from "../../../modals/billings/invoice-char-enum";

@Component({
  selector: 'sib-credit-notes-tasks-cards',
  templateUrl: './credit-notes-tasks-cards.component.html',
  styleUrls: ['./credit-notes-tasks-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class CreditNotesTasksCardsComponent implements OnInit, AfterViewInit, OnDestroy {

  _creditNotesTaskCards: InvoiceTaskCard[] = [];
  @Input() set creditNotesTaskCards(creditNotesTaskCards) {
      this._creditNotesTaskCards = creditNotesTaskCards;
      if (this.creditNotesTaskCards && this.creditNotesTaskCards.length > 0) {
          if (!this.selectedFilters) {
              this.filterData(this.creditNotesTaskCards[0], 0);
          }
      }
  }

  get creditNotesTaskCards() {
      return this._creditNotesTaskCards;
  }

  @Output() eEmitFilterSelected: EventEmitter<any> = new EventEmitter();

  index: number;

  selectedFilters: {
      filters: any,
      printed: any,
      tallyEntered: any
  };

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() { }

  filterData(card, index) {
      this.index = index;
      const status = card.status;
      this.selectedFilters = { filters: [], printed: "N", tallyEntered: "N" };
      if (TaskCardStatusEnum[card.status]) {
          this.selectedFilters.filters.push(status);
      } else {
          if (InvoiceCharEnum[card.printed] === InvoiceCharEnum.F) {
              this.selectedFilters.printed = "F";
          } else if (InvoiceCharEnum[card.tallyEntered] === InvoiceCharEnum.F) {
              this.selectedFilters.tallyEntered = "F";
          }
      }
      this.eEmitFilterSelected.emit(this.selectedFilters);
  }

  getStatus(card) {
      const status = card.status;
      if (TaskCardStatusEnum[status]) {
          return TaskCardStatusEnum[status];
      } else if (InvoiceCharEnum[card.printed] === InvoiceCharEnum.F) {
          return "Printing";
      } else if (InvoiceCharEnum[card.tallyEntered] === InvoiceCharEnum.F) {
          return "Tally Entry";
      }
  }

  check(card, index) {
      let result = false;
      if (this.index !== undefined && this.index === index) {
          result = true;
      }
      return result;
  }

  ngOnDestroy() { }
}
