import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class SavePlanService extends ApiService<any, any, any> {
    urlPath: string = 'plans/save';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    updatePlan(plan) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'plans/update', plan);

        }
    }

    getPlan(planId): any {
        console.log("inside service", planId);
        // return this.Http.get(this.baseUrl + 'plans/items/' + planId);
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/' + planId);

        }
    }

    getCampaign(campaignId): any {
        console.log("inside service", campaignId);
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'campaignItems/by-campaign/' + campaignId);

        }
    }
}
