import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, InputTextModule, SharedModule, CheckboxModule, RatingModule, DragDropModule, FileUploadModule, AutoCompleteModule, DropdownModule, CalendarModule, LightboxModule, TooltipModule, RadioButtonModule, SpinnerModule, ProgressSpinnerModule, KeyFilterModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { AreaDialogComponent } from "../area-dialog/area-dialog.component";
import { ContractsActionDispatcher } from "../../../contracts/action-dispatcher/action-dispatcher";
import { ContractActions } from "../../../contracts/actions/contract-actions";
import { AreaDialogModule } from "../area-dialog/area-dialog.module";
import { CreateHoardingService } from "../../../services/shared/createHoarding.service";
import { HoardingCategoryService } from "../../../services/shared/hoardingCategory.service";
import { HoardingLightService } from "../../../services/shared/hoardingLight.service";
import { UpdateHoardingService } from "../../../services/shared/updateHoarding.service";
import { CustomerUiService } from "../../../customers/services/customer-ui.service";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { CurrencyMainPipe } from "../../helpers/currency.pipe/currency.main.pipe";
import { MapModule } from "../map/map.module";
import { CurrencyHelperPipe } from "../../helpers/currency.pipe/currencyHelperPipe";
import { CampaignActionDispatcher } from "../../../campaigns/action-dispatcher/action-dispatcher";
import { CampaignActions } from "../../../campaigns/actions/campaign-actions";
import { UploadDocDialogComponent } from './upload-doc-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        CheckboxModule,
        RatingModule,
        DragDropModule,
        FileUploadModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        RadioButtonModule,
        AreaDialogModule,
        LightboxModule,
        TooltipModule,
        InputTrimModule,
        SpinnerModule,
        CurrencyMainPipe,
        ProgressSpinnerModule,
        KeyFilterModule,
        MapModule
    ],

    declarations: [
        UploadDocDialogComponent
    ],

    exports: [
        UploadDocDialogComponent
    ],

    providers: [CampaignActionDispatcher,
        CampaignActions]
})

export class UploadDocDialogModule { }