import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { catchError, map } from "rxjs/operators";
import { empty as observableEmpty, Observable } from "rxjs";
import { SelectItem, Dropdown, Menu } from 'primeng/primeng';
import { HelpModulesEnum } from '../shared/constants/help-modules-enum';
import { ArticlesService } from "../services/shared/articles.service";
import { ArticlesGetService } from '../services/shared/articles-get.service';
import { NotificatoinsService } from "../../app/services/notifications/notifications.service";
import { ArticleQueryParams } from '../modals/queryparams/article.queryparams';
import { ArticleSearchQueryParams } from '../modals/queryparams/articleSearch-queryparams';
import * as utils from '../helpers/utils';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import Rx from 'rxjs/Rx'
import { HelpSupport } from '../modals/master/help';
import { MenuItem, MenubarModule, LISTBOX_VALUE_ACCESSOR } from 'primeng/primeng';

@Component({
  selector: 'sib-help-support',
  templateUrl: './help-support.component.html',
  styleUrls: ['./help-support.component.scss'],
  providers: [ArticleQueryParams, ArticleSearchQueryParams],
  encapsulation: ViewEncapsulation.None
})
export class HelpSupportComponent implements OnInit {

  @Input() display: boolean = false;
  @Output() emitCloseHelpDialog: EventEmitter<any> = new EventEmitter();
  displayArticle: boolean = false;
  // modules: any[] = [];
  selectedModule: string;
  selectedModuleArticles: any[] = [];
  articleQueryParams: ArticleQueryParams = new ArticleQueryParams();
  articlesSearchQueryParams: ArticleSearchQueryParams = new ArticleSearchQueryParams();
  articles: any[] = [];
  modulesMap: Map<string, any[]> = new Map();
  totalRecords: number;
  modulesArticlesMap = [{ 'ALL': [], 'CUSTOMER': [], 'CONTRACT': [], 'INVENTORY': [], 'PLAN': [], 'CAMPAIGN': [], 'OPERATION': [], 'BILLING': [], 'USER': [], 'MASTERS': [], 'PHOTO_INTERFACE': [], 'ACTIVITY': [], 'DOCUMENT': [] }];
  // modulesArticlesMap: any[] = [];
  selectedDevice: string;
  webArticles: any[] = [];
  mobileArticles: any[] = [];
  currentModule: string;
  @ViewChild('helpSearchInput') helpSearchInput: ElementRef;
  searchedArticles: string[] = [];
  keyUp = new Subject<string>();
  filteredArticles: string[] = [];
  showFilterContainer: boolean = false;
  selectedArticle: HelpSupport = new HelpSupport();
  helpSearch: string = '';
  articleIsSelected: boolean = false;
  modules: MenuItem[] = [];
  moduleSelected: string = 'Help';
  // searchedText: string = null;
  viewPlaylist: boolean = false;

  @ViewChild('modulesMenu') modulesMenu: Menu;

  constructor(
    private articlesService: ArticlesService,
    private articlesGetService: ArticlesGetService,
    private notificationsService: NotificatoinsService,
    private router: Router
  ) {
    // this.modules = utils.createDropdown(HelpModulesEnum, false);
    const observable = this.keyUp
      .map(value => (<HTMLInputElement>event.target).value)
      .debounceTime(300)
      .distinctUntilChanged()
      .flatMap((search) => {
        this.showFilterContainer = true;
        this.getFilteredArticles(search);
        return Observable.of(search).delay(300);
      })
      .subscribe((data) => {

      })
  }
  ngOnInit() {

    this.getArticles();
    this.createModulesMenu();

  }

  getArticles() {
    this.articlesGetService
      .getArticlesList(this.articleQueryParams)
      .pipe(
        map((response) => {
          this.totalRecords = response.data;
          this.articles = response["data"];
          this.getArticlesList('ALL');
          this.modulesToArticlesMapping();
          return response["data"];

        }),
        catchError(error => {
          this.notificationsService.error(
            error.error ? error.error : "Some technical issue",
            "Error!!"
          );
          return observableEmpty();
        })
      ).subscribe((response) => {
      });


  }

  createModulesMenu() {

    for (let i = 0; i < Object.values(HelpModulesEnum).length; i++) {
      this.modules.push(
        {
          label: Object.values(HelpModulesEnum)[i],
          command: (onclick) => {
            this.showFilterContainer = false;
            this.helpSearch = '';
            this.articleIsSelected = false;
            this.selectedModule = Object.keys(HelpModulesEnum)[i];
            this.moduleSelected = Object.values(HelpModulesEnum)[i] + ' Articles';
            this.getArticlesList(this.selectedModule);
          }
        }
      )
    }
  }

  capitizeLabel(name) {
    return (name.charAt(0).toUpperCase() + name.substr(1).toLowerCase());
  }



  hideHelpDialog() {
    this.display = false;
    this.emitCloseHelpDialog.emit(true);
  }

  getFilteredArticles(search) {
    if (this.selectedModule) {
      this.articlesSearchQueryParams.module = this.selectedModule;
    } else {
      this.articlesSearchQueryParams.module = 'ALL';
    }

    if (search !== '') {
      this.articlesSearchQueryParams.words = search;
    }
    else {
      this.articlesSearchQueryParams.words = ' ';
    }

    this.articlesGetService
      .getFilteredArticles(this.articlesSearchQueryParams)
      .pipe(
        map((response) => {
          this.totalRecords = response.data;
          this.filteredArticles = [];
          this.filteredArticles = response["data"];
          return response["data"];
        }),
        catchError(error => {
          this.notificationsService.error(
            error.error ? error.error : "Some technical issue",
            "Error!!"
          );
          return observableEmpty();
        })
      ).subscribe((response) => {
      });
  }



  filteredArticle(article) {
    this.articleIsSelected = true;
    this.helpSearch = '';
    this.showFilterContainer = false;
    this.selectedArticleToShow(article);
  }

  selectedArticleToShow(article) {
    this.selectedArticle = new HelpSupport();
    this.selectedArticle = article;
  }



  modulesToArticlesMapping() {
    for (let i = 0; i < this.articles.length; i++) {
      let item = this.articles[i].module
      switch (item) {
        case 'CUSTOMER':
          this.modulesArticlesMap[0].CUSTOMER.push(this.articles[i]);
          break;
        case 'CONTRACT':
          this.modulesArticlesMap[0].CONTRACT.push(this.articles[i]);
          break;
        case 'INVENTORY':
          this.modulesArticlesMap[0].INVENTORY.push(this.articles[i]);
          break;
        case 'PLAN':
          this.modulesArticlesMap[0].PLAN.push(this.articles[i]);
          break;
        case 'CAMPAIGN':
          this.modulesArticlesMap[0].CAMPAIGN.push(this.articles[i]);
          break;
        case 'OPERATION':
          this.modulesArticlesMap[0].OPERATION.push(this.articles[i]);
          break;
        case 'BILLING':
          this.modulesArticlesMap[0].BILLING.push(this.articles[i]);
          break;
        case 'USER':
          this.modulesArticlesMap[0].USER.push(this.articles[i]);
          break;
        case 'MASTERS':
          this.modulesArticlesMap[0].MASTERS.push(this.articles[i]);
          break;
        case 'PHOTO_INTERFACE':
          this.modulesArticlesMap[0]["PHOTO_INTERFACE"].push(this.articles[i]);
          break;
        case 'ACTIVITY':
          this.modulesArticlesMap[0].ACTIVITY.push(this.articles[i]);
          break;
        case 'DOCUMENT':
          this.modulesArticlesMap[0].DOCUMENT.push(this.articles[i]);
          break;

      }
    }
  }

  getArticlesList(selectedModule) {
    console.log("Selected module....", selectedModule);
    this.displayArticle = true
    this.selectedModuleArticles = [];
    this.webArticles = [];
    this.mobileArticles = [];
    if (selectedModule === 'ALL') {
      this.selectedModuleArticles = this.articles
    } else {
      this.selectedModuleArticles = this.modulesArticlesMap[0][selectedModule];
    }

    for (let i = 0; i < this.selectedModuleArticles.length; i++) {
      if (this.selectedModuleArticles[i].deviceCategory === 'WEB') {
        this.webArticles.push(this.selectedModuleArticles[i])
      }
      else {
        this.mobileArticles.push(this.selectedModuleArticles[i]);
      }
    }
    console.log("Array of selected module...", this.selectedModuleArticles);
  }



  selectedDeviceCategory(event) {
    switch (event.index) {
      case 0: {
        this.selectedDevice = "WEB";
        break;
      }
      case 1: {
        this.selectedDevice = "MOBILE";
        break;
      }
      case 2: {
        this.selectedDevice = "BOTH";
        break;
      }

    }
  }

  /**
   * @description show playlist dialog
   * @author Divya Sachan
   * @date 2019-11-28
   * @param {*} event
   * @memberof HelpSupportComponent
   */
  showPlaylistDialog(event) {
    this.viewPlaylist = true;
  }

  /**
   * @description hide playlist dialog
   * @author Divya Sachan
   * @date 2019-11-28
   * @param {*} event
   * @memberof HelpSupportComponent
   */
  hidePlaylistDialog(event) {
    this.viewPlaylist = false;
  }

}
