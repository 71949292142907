import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService } from "../../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Area } from "../../../modals/Area/area";

@Injectable()
export class HoardingDialogAreaService extends ApiService<any, any, any> {
    urlPath: string = 'areas/search/byCriteria?criteria=panch';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }

}