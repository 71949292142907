/**
* @file  campaignReducer 
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
* Description: 
* This is done to keep all the states and reducers at one place.
*/
import { ActionReducerMap } from "@ngrx/store";
import { CampaignState, campaignReducer } from "./reducers/campaign.reducers";
import { AppModuleState } from "../app state/app-module.reducers";

export interface CampaignModuleState {
    campaigns: CampaignState;
}
export const reducers: ActionReducerMap<CampaignModuleState> = {
    campaigns: campaignReducer
};
export interface CampaignModuleStateExtended extends AppModuleState {
    campaigns: CampaignModuleState;
}

