export class Notifications {
    severity: AlertType;
    summary: string;
    detail: string;
}

export enum AlertType {
    success,
    info,
    warn,
    error
}