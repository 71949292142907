
import {map} from 'rxjs/operators';
/**
* @file  EventComponent 
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { Customer } from '../modals/KYC/customer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/primeng';
import { GridComponent } from '../components/sib-forms/grid/grid.component';
import { GridColumn, EditableGridFieldTypes, GridConfig, SelectionMode, GridPaginationEvent, Alignment, GridActionButtonTypes } from '../components/sib-forms/grid/grid.config';
import { EventService } from '../services/shared/event.service';
import { GridActionButtonConfig } from '../components/sib-forms/grid/grid-action-button.config';
import { PointOfContact } from '../modals/KYC/pointofcontact';
import { SalesPerson } from '../modals/KYC/salesperson';
import { CustomerQueryParams } from '../modals/queryparams/customer-queryparams';
import { Event } from '../modals/events/event';



@Component({
  selector: 'sib-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [EventService]
})
export class EventComponent implements OnInit {

  customers: Observable<{ customer: Customer[] }>
  totalRecords: number = 100;
  display: boolean = false;
  public values: any[];
  eventGroup: FormGroup;
  events: Event[] = [];
  columns: Array<GridColumn> = [
    {
      field: 'name',
      name: 'name',
      header: 'Event Name',
      required: true,
      editable: false,
      permanent: true,
      sortable: true,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
    },
    {
      field: 'time',
      name: 'time',
      header: 'Event Time',
      required: true,
      editable: false,
      permanent: true,
      sortable: true,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.time) {
          var date = new Date(data.time);
          return this.getFormattedDate(date);
        } else {
          return '-';
        }
      }

    },
    {
      field: 'venueName',
      name: 'venueName',
      header: 'Venue Name',
      required: true,
      editable: false,
      permanent: true,
      sortable: true,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.venue) {

          return data.venue.name;
        } else {
          return '-';
        }
      }

    },
    {
      field: 'venueAdd',
      name: 'venueAdd',
      header: 'Venue Address',
      required: true,
      editable: false,
      permanent: true,
      sortable: true,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.venue) {

          return data.venue.address_1 + '  ' + data.venue.address_2;
        } else {
          return '-';
        }
      }

    },

  ];

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<Event>;
  gridConfig: GridConfig<Event> = new GridConfig<Event>();
  buttonConfig: GridActionButtonConfig;
  value: { events: Event[] };

  constructor(
    private eventService: EventService,
    private router: Router,
  ) {

  }


  ngOnInit() {

    this.eventGroup = new FormGroup({
      gridControl: new FormControl()
    });


    this.gridConfig.getDefaultModel = () => new Event();
    this.gridConfig.model = Event;
    this.gridConfig.editable = false;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.rowExpandMode = 'single';
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;

    this.gridConfig.dataLoadFunction = () => {
      return this.eventService.getAll().pipe(
        map((response) => {
          console.log("The response from dataLoadFunction is", response)
          console.log("The response from server for plan search", response);
          this.events = response['events'];
          console.log("total plans are", this.events);
          return this.events;
        }))
    }


  }
  /**
   * Formates the date in dd/mm/yyyy format
   * @param  {} date
   */
  getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return day + '/' + month + '/' + year;
  }
}
