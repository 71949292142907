import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, DropdownModule, CalendarModule, SharedModule, DataScrollerModule, CheckboxModule, InputTextModule, TabViewModule ,TooltipModule} from 'primeng/primeng';
import { PhotoDialogModule } from '../photo-dialog/photo-dialog.module';
import { ActivityLogComponent } from './activity-log.component';
import { SetGridService } from './services/setGrid.service';
import { ActivityGridComponent } from './activity-grid/activity-grid.component';
import { SibGridModule } from '../../../components/sib-forms/grid/grid.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        DropdownModule,
        CalendarModule,
        SharedModule,
        DataScrollerModule,
        CheckboxModule,
        SibGridModule,
        InputTextModule,
        TabViewModule,
        PhotoDialogModule,
        TooltipModule
    ],
    declarations: [
        ActivityLogComponent,
        ActivityGridComponent
    ],

    exports: [
        ActivityLogComponent,
        ActivityGridComponent
    ],
    providers: [
        SetGridService
    ]
})
export class ActivityLogModule { }
