import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, DropdownModule, DialogModule, TooltipModule, ConfirmDialogModule } from 'primeng/primeng';
import { SibGridModule } from '../../components/sib-forms/grid/grid.module';
import { ReserveCreditNotesComponent } from './reserve-credit-notes.component';
import { RouterModule } from '@angular/router';
import { CreditNotesService } from '../../services/shared/credit-note.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReserveCreditNoteDialogModule } from '../reserve-credit-note-detail-dialog/reserve-credit-note-dialog.module';
import { UploadDocumentModule } from '../../shared/components/upload-document/upload-document.module';
import { DocumentModule } from '../../shared/components/documents/document.module';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SibGridModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        RouterModule,
        DialogModule,
        TooltipModule,
        ConfirmDialogModule,
        ReserveCreditNoteDialogModule,
        UploadDocumentModule,
        DocumentModule
    ],

    declarations: [
        ReserveCreditNotesComponent
    ],

    exports: [
        ReserveCreditNotesComponent
    ],

    providers: [
        CreditNotesService
    ]
})

export class ReserveCreditNotesModule { }
