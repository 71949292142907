export enum HoardingCategoryEnum {
    AIRPORT = "Airport",
    BUS_STAND = "Bus Stand",
    COMMERCIAL = "Commercial",
    EDUCATIONAL = "Educational",
    ENTRY_POINT = "Entry Point",
    EXPRESSWAY = "Expressway",
    HOS = "HOS",
    HOSPITAL = "Hospital",
    INDUSTRIAL = "Industrial",
    JUNCTION = "Junction",
    MEDICAL = "Medical",
    RECIDENTIAL = "Residential",
    ST = "ST",
    TRANSIT = "Transit"
}
