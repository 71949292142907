import { Component, AfterViewInit, ElementRef, Renderer2, ViewChild, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../login-service/auth.service';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MenuItem } from 'primeng/primeng';
import { SIBConstants } from '../shared/constants/SIBConstant';
import * as $ from 'jquery';
import { SystemService } from '../services/shared/system.service';
import { NotificatoinsService } from '../services/notifications/notifications.service';
import { ActionDispatcher } from '../app state/action-dispatcher/action-dispatcher';
enum MenuOrientation {
    STATIC,
    OVERLAY,
    SLIM,
    HORIZONTAL
}

declare var jQuery: any;
//  let jQuery: any;

@Component({
    selector: 'sib-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {


    layoutCompact: boolean = true;

    layoutMode: MenuOrientation = MenuOrientation.STATIC;

    darkMenu: boolean = false;

    profileMode: string = 'top';

    rotateMenuButton: boolean;

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    rightPanelActive: boolean;

    rightPanelClick: boolean;

    layoutContainer: HTMLDivElement;

    layoutMenuScroller: HTMLDivElement;

    menuClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    resetMenu: boolean;

    menuHoverActive: boolean;

    @ViewChild('layoutContainer') layourContainerViewChild: ElementRef;

    @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ElementRef;

    model: MenuItem[] = [];

    mainMenuComponent: MainMenuComponent = new MainMenuComponent();

    template: string = `<img src="http://pa1.narvii.com/5722/2c617cd9674417d272084884b61e4bb7dd5f0b15_hq.gif">`

    constructor(

        private auth: AuthService,
        private systemService: SystemService,
        private notificationServcie: NotificatoinsService,
        private actionDispatcher: ActionDispatcher,
    ) {

    }

    ngOnInit() {

        this.getPassbookEnableProperty();

        this.layoutMode = MenuOrientation.SLIM;
        var permission = this.auth.getPermissions();

        // Setup
        var myMenuItem = new Object();
        // var mySubMenuItem = new Object();

        if (permission.includes('menu:tasks')) {
            this.model.push({ label: SIBConstants.TASKS_MENU, icon: 'icon-task', routerLink: ['/tasks'] });
        }

        if (permission.includes('menu:customers')) {
            myMenuItem = new Object();
            myMenuItem['label'] = SIBConstants.CUSTOMER_MENU;
            myMenuItem['icon'] = 'icon-customer';
            myMenuItem['items'] = [];
            if (permission.includes('read:customers')) {
                myMenuItem['items'].push({ label: SIBConstants.CUSTOMER_MENU, icon: 'icon-customer', routerLink: ['/customers'] })
            }
            /* if (permission.includes('create:customers')) {
                myMenuItem['items'].push({ label: 'New Customer', icon: 'icon-newcustomer', routerLink: ['/customers/addcustomer'] })
            } */
            if (permission.includes('menu:customer-groups')) {
                myMenuItem['items'].push({ label: SIBConstants.CUSTOMER_GROUPS_MENU, icon: 'icon-customer-groups', routerLink: ['/customers/customergroups'] })
            }
            if (permission.includes('menu:pocs')) {
                myMenuItem['items'].push({ label: SIBConstants.CUSTOMER_POC_MENU, icon: 'icon-poc', routerLink: ['/customers/pointofcontacts'] })
            }
            this.model.push(myMenuItem);

        }

        if (permission.includes('menu:contracts')) {
            this.model.push({ label: SIBConstants.CONTRACT_MENU, icon: 'icon-contract-type', routerLink: ['/contracts'] });
        }
        if (permission.includes('menu:hoardings')) {
            this.model.push({ label: SIBConstants.INVENTORY_MENU, icon: 'icon-hoarding', routerLink: ['/hoardings'] });
        }

        if (permission.includes('menu:plans')) {
            this.model.push({ label: SIBConstants.PLAN_MENU, icon: 'icon-plans', routerLink: ['/plans'] });
        }

        if (permission.includes('menu:campaigns')) {
            this.model.push({ label: SIBConstants.CAMPAIGN_MENU, icon: 'icon-campaign-4', routerLink: ['/campaigns'] });
        }
        if (permission.includes('menu:operations')) {

            myMenuItem = new Object();
            myMenuItem['label'] = SIBConstants.OPERATIONS_MENU;
            myMenuItem['icon'] = 'icon-operations';
            myMenuItem['items'] = [];
            if (permission.includes('menu:operations-hoardings')) {
                myMenuItem['items'].push({ label: SIBConstants.OP_KANBAN_MENU, icon: 'icon-hoarding-grid', routerLink: ['/operations/hoardings-operation'] })
            }
            if (permission.includes('menu:operations-campaigns')) {
                // myMenuItem['items'].push({ label: 'Campaign Operations', icon: 'assets/layout/images/kanban.svg', routerLink: ['/operations/campaign-operations'] })
            }

            myMenuItem['items'].push({ label: SIBConstants.OP_GRID_MENU, icon: 'icon-hoarding-operations', routerLink: ['/operations/hoardings'] })


            this.model.push(myMenuItem);
        }

        // if (permission.includes('menu:billings')) {
        //     this.model.push({ label: SIBConstants.BILLING_MENU, icon: 'icon-billings-2', routerLink: ['/billings'] });
        // }

        if (permission.includes('menu:billings')) {
            myMenuItem = new Object();
            myMenuItem['label'] = SIBConstants.BILLING_MENU;
            myMenuItem['icon'] = 'icon-billings-2';
            myMenuItem['items'] = [];
            if (permission.includes('menu:superbills')) {
                myMenuItem['items'].push({ label: SIBConstants.SUPERBILL_SUBMENU, icon: 'icon-billings-1', routerLink: ['/billings'] });
            }
            if (permission.includes('menu:reserve-invoices')) {
                myMenuItem['items'].push({ label: SIBConstants.RESERVE_INVOICES_MENU, icon: 'icon-tally', routerLink: ['/billings/reserve-invoices'] })
            }
            if (permission.includes('menu:reserve-credit-notes')) {
                myMenuItem['items'].push({ label: SIBConstants.RESERVE_CN_MENU, icon: 'icon-tally-1', routerLink: ['/billings/reserve-credit-notes'] });
            }
            this.model.push(myMenuItem);
        }

        if (permission.includes('menu:users')) {
            myMenuItem = new Object();
            myMenuItem['label'] = SIBConstants.USER_MENU;
            myMenuItem['icon'] = 'icon-users';
            myMenuItem['items'] = [];
            if (permission.includes('menu:territory')) {

                myMenuItem['items'].push({
                    label: 'Territory', icon: SIBConstants.USER_USERS_MENU, items: [
                        // { label: SIBConstants.USER_TERRITORY_BM_MENU, icon: 'icon-branch-manager', routerLink: ['/users/bmTerritory'] },
                        // { label: SIBConstants.USER_TERRITORY_PHOTOGRAPHER_MENU, icon: 'icon-photographer', routerLink: ['/users/bdTerritory'] },
                        { label: SIBConstants.USER_TERRITORY_WAREHOUSE_MENU, icon: 'icon-warehouse-keeper', routerLink: ['/users/WarehouseKeeperTerritory'] },
                        { label: SIBConstants.USER_TERRITORY_MOUNTER_MENU, icon: 'icon-mounter', routerLink: ['/users/MounterTerritory'] },
                        //warehouse submenu should come above mounter submenu
                        // { label: SIBConstants.USER_TERRITORY_WAREHOUSE_MENU, icon: 'icon-warehouse-keeper', routerLink: ['/users/WarehouseKeeperTerritory'] },
                    ]
                })

                myMenuItem['items'].push({
                    label: SIBConstants.USER_USERS_MENU, icon: 'contacts', items: [
                        { label: SIBConstants.USER_USERS_MENU, icon: 'icon-users', routerLink: ['/users/employees'] },
                        // { label: 'Groups', routerLink: ['/users/groups'] },
                        // { label: SIBConstants.USER_USERS_ROLES_MENU, icon: 'icon-roles', routerLink: ['/users/roles'] },
                        { label: SIBConstants.USER_USERS_PERMISSIONS_MENU, icon: 'icon-permissions', routerLink: ['/users/permissions'] },
                    ]
                })
            }

            this.model.push(myMenuItem);
        }




        if (permission.includes('menu:masters')) {
            myMenuItem = new Object();
            myMenuItem['label'] = SIBConstants.MASTERS_MENU;
            myMenuItem['icon'] = 'icon-masters';
            myMenuItem['items'] = [];
            if (permission.includes('menu:contractTypes')) {
                myMenuItem['items'].push({ label: SIBConstants.CONTRACT_MENU, icon: 'icon-contract-type', command: () => this.layoutCompact = true, routerLink: ['/masters/contract-type'] })
            }
            if (permission.includes('menu:custom-area')) {
                myMenuItem['items'].push({ label: SIBConstants.MASTERS_CUSTOM_DISTRICTS_MENU, icon: 'icon-custom-district', command: () => this.layoutCompact = true, routerLink: ['/masters/district'] })
            }
            if (permission.includes('menu:custom-area')) {
                myMenuItem['items'].push({ label: SIBConstants.MASTERS_CUSTOM_CITY_MENU, icon: 'icon-custom-city', command: () => this.layoutCompact = true, routerLink: ['/masters/city'] })
            }
            if (permission.includes('menu:custom-area')) {
                myMenuItem['items'].push({ label: SIBConstants.MASTERS_CUSTOM_AREAS_MENU, icon: 'icon-custom-area', command: () => this.layoutCompact = true, routerLink: ['/masters/area'] })
            }
            if (permission.includes('menu:industry-segments')) {
                myMenuItem['items'].push({ label: SIBConstants.MASTERS_INDUSTRY_MENU, icon: 'icon-industry', command: () => this.layoutCompact = true, routerLink: ['/masters/industry'] })
            }

            // if (permission.includes('menu:customer-groups')) {
            //     myMenuItem['items'].push({ label: 'Group', icon: 'icon-group', command: () => this.layoutCompact = true, routerLink: ['/masters/group'] })
            // }
            // if (permission.includes('menu:settings')) {
            //     myMenuItem['items'].push({ label: SIBConstants.MASTERS_SETTINGS_MENU, icon: 'icon-settings', routerLink: ['/masters/settings'] })
            // }
            // if (permission.includes('menu:articles')) {
            //     myMenuItem['items'].push({ label: SIBConstants.MASTERS_ARTICLES_MENU, icon: 'icon-menu-articles', routerLink: ['/masters/articles'] })
            // }

            this.model.push(myMenuItem);
        }



        if (permission.includes('menu:photo-interface')) {
            this.model.push({ label: SIBConstants.PHOTO_INTERFACE_MENU, icon: 'icon-photo-interface', routerLink: ['/photo-interface'] });
        }

        // if (permission.includes('menu:activity')) {
        //     this.model.push({ label: SIBConstants.ACTIVITY_MENU, icon: 'icon-activity-log', routerLink: ['/activity'] });
        // }

        // if (permission.includes('menu:documents')) {
        //     this.model.push({ label: SIBConstants.DOCUMENT_MENU, icon: 'icon-documents', routerLink: ['/documents'] });
        // }
        // // Suggestion menu should be visible to all the users
        // // if (permission.includes('readAll')) {
        //     this.model.push({ label: SIBConstants.SUGGESTION, icon: 'fa  fa-thumbs-o-up', routerLink: ['/suggestion'] });
        // }
        if (permission.includes('menu:analytics')) {
            myMenuItem = new Object();
            myMenuItem['label'] = SIBConstants.ANALYTICS_MENU;
            myMenuItem['icon'] = 'icon-analytics';
            myMenuItem['items'] = [];
            if (permission.includes('view:capacity-chart')) {
                myMenuItem['items'].push({ label: SIBConstants.CAPACITY_CHART_SUBMENU, icon: 'icon-capacity-1', routerLink: ['/analytics/capacity-chart'] });
            }
            if (permission.includes('view:sales-chart')) {
                myMenuItem['items'].push({ label: SIBConstants.SALES_CHART_SUBMENU, icon: 'icon-sales', routerLink: ['/analytics/sales-chart'] });
            }
            if (permission.includes('view:roi')) {
                myMenuItem['items'].push({ label: SIBConstants.ROI_SUBMENU, icon: 'icon-roi', routerLink: ['/analytics/roi'] });
            }
            this.model.push(myMenuItem);
        }

        if (permission.includes('menu:others')) {
            myMenuItem = new Object();
            myMenuItem['label'] = SIBConstants.OTHERS;
            myMenuItem['icon'] = "icon-other";
            myMenuItem['items'] = [];
            // if (permission.includes('menu:users')) {
            //     mySubMenuItem = new Object();
            //     mySubMenuItem['label'] = SIBConstants.USER_MENU;
            //     mySubMenuItem['icon'] = 'icon-users';
            //     mySubMenuItem['items'] = [];
            //     if (permission.includes('menu:territory')) {

            //         mySubMenuItem['items'].push({
            //             label: 'Territory', icon: SIBConstants.USER_USERS_MENU, items: [
            //                 { label: SIBConstants.USER_TERRITORY_WAREHOUSE_MENU, icon: 'icon-warehouse-keeper', routerLink: ['/users/WarehouseKeeperTerritory'] },
            //                 { label: SIBConstants.USER_TERRITORY_MOUNTER_MENU, icon: 'icon-mounter', routerLink: ['/users/MounterTerritory'] },
            //             ]
            //         })
            //         mySubMenuItem['items'].push({
            //             label: SIBConstants.USER_USERS_MENU, icon: 'contacts', items: [
            //                 { label: SIBConstants.USER_USERS_MENU, icon: 'icon-users', routerLink: ['/users/employees'] },
            //                 { label: SIBConstants.USER_USERS_PERMISSIONS_MENU, icon: 'icon-permissions', routerLink: ['/users/permissions'] },
            //             ]
            //         })
            //     }
            //     myMenuItem['items'].push(mySubMenuItem);
            // }
            // if (permission.includes('menu:masters')) {
            //     mySubMenuItem = new Object();
            //     mySubMenuItem['label'] = SIBConstants.MASTERS_MENU;
            //     mySubMenuItem['icon'] = 'icon-masters';
            //     mySubMenuItem['items'] = [];
            //     if (permission.includes('menu:contractTypes')) {
            //         mySubMenuItem['items'].push({ label: SIBConstants.CONTRACT_MENU, icon: 'icon-contract-type', command: () => this.layoutCompact = true, routerLink: ['/masters/contract-type'] })
            //     }
            //     if (permission.includes('menu:custom-area')) {
            //         mySubMenuItem['items'].push({ label: SIBConstants.MASTERS_CUSTOM_DISTRICTS_MENU, icon: 'icon-custom-district', command: () => this.layoutCompact = true, routerLink: ['/masters/district'] })
            //     }
            //     if (permission.includes('menu:custom-area')) {
            //         mySubMenuItem['items'].push({ label: SIBConstants.MASTERS_CUSTOM_CITY_MENU, icon: 'icon-custom-city', command: () => this.layoutCompact = true, routerLink: ['/masters/city'] })
            //     }
            //     if (permission.includes('menu:custom-area')) {
            //         mySubMenuItem['items'].push({ label: SIBConstants.MASTERS_CUSTOM_AREAS_MENU, icon: 'icon-custom-area', command: () => this.layoutCompact = true, routerLink: ['/masters/area'] })
            //     }
            //     if (permission.includes('menu:industry-segments')) {
            //         mySubMenuItem['items'].push({ label: SIBConstants.MASTERS_INDUSTRY_MENU, icon: 'icon-industry', command: () => this.layoutCompact = true, routerLink: ['/masters/industry'] })
            //     }

            //     // if (permission.includes('menu:customer-groups')) {
            //     //     mySubMenuItem['items'].push({ label: 'Group', icon: 'icon-group', command: () => this.layoutCompact = true, routerLink: ['/masters/group'] })
            //     // }
            //     if (permission.includes('menu:settings')) {
            //         mySubMenuItem['items'].push({ label: SIBConstants.MASTERS_SETTINGS_MENU, icon: 'icon-settings', routerLink: ['/masters/settings'] })
            //     }
            //     // if (permission.includes('menu:articles')) {
            //     //     myMenuItem['items'].push({ label: SIBConstants.MASTERS_ARTICLES_MENU, icon: 'icon-menu-articles', routerLink: ['/masters/articles'] })
            //     // }

            //     myMenuItem['items'].push(mySubMenuItem);
            // }
            // if (permission.includes('menu:photo-interface')) {
            //     myMenuItem['items'].push({ label: SIBConstants.PHOTO_INTERFACE_MENU, icon: 'icon-photo-interface', command: () => this.layoutCompact = true, routerLink: ['/photo-interface'] });
            // }
            if (permission.includes('menu:activity')) {
                myMenuItem['items'].push({ label: SIBConstants.ACTIVITY_MENU, icon: 'icon-activity-log', command: () => this.layoutCompact = true, routerLink: ['/activity'] });
            }
            if (permission.includes('menu:documents')) {
                myMenuItem['items'].push({ label: SIBConstants.DOCUMENT_MENU, icon: 'icon-documents', command: () => this.layoutCompact = true, routerLink: ['/documents'] });
            }
            myMenuItem['items'].push({ label: SIBConstants.SUGGESTION, icon: 'fa  fa-thumbs-o-up', command: () => this.layoutCompact = true, routerLink: ['/suggestion'] });
            if (permission.includes('menu:articles')) {
                myMenuItem['items'].push({ label: SIBConstants.MASTERS_ARTICLES_MENU, icon: 'icon-menu-articles', command: () => this.layoutCompact = true, routerLink: ['/masters/articles'] })
            }
            this.model.push(myMenuItem);
        }

        if (permission.includes('menu:settings')) {
            this.model.push({ label: SIBConstants.MASTERS_SETTINGS_MENU, icon: 'icon-settings', routerLink: ['/masters/settings'] });
        }
        // if(permission.includes('menu:analytics')) {

        // }   
    }



    ngAfterViewInit() {
        this.layoutContainer = <HTMLDivElement>this.layourContainerViewChild.nativeElement;
        this.layoutMenuScroller = <HTMLDivElement>this.layoutMenuScrollerViewChild.nativeElement;

        setTimeout(() => {
            jQuery(this.layoutMenuScroller).nanoScroller({ flash: true });
        }, 10);
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }
    onMenuButtonClick(event) {
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        else {
            if (this.isDesktop())
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            else
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;

        if (!this.isHorizontal()) {
            setTimeout(() => {
                jQuery(this.layoutMenuScroller).nanoScroller();
            }, 500);
        }
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item)
            this.activeTopbarItem = null;
        else
            this.activeTopbarItem = item;

        event.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;
        event.preventDefault();
    }

    onRightPanelClick() {
        this.rightPanelClick = true;
    }

    hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }

    isSlim() {
        return this.layoutMode === MenuOrientation.SLIM;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    changeToHorizontalMenu() {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    }

    changeToSlimMenu() {
        this.layoutMode = MenuOrientation.SLIM;
    }

    getPassbookEnableProperty() {
        this.systemService.get({ module: 'PASSBOOK', key: 'BLOCK_CREDIT' }, '/byMoudule/key').subscribe(
            (response) => {
                if (response) {
                    this.actionDispatcher.setEnablePassbook(response['value']);
                }
            },
            (error) => {
                this.notificationServcie.error(error.error ? error.error.message ? error.error.message : 'Some technical issue' : error.message, 'Error!!');
            }
        );
    }

    ngOnDestroy() {
        jQuery(this.layoutMenuScroller).nanoScroller({ flash: true });
    }
    @HostListener('click', ['$event.target'])
    onClick(target) {

        if ($("#chat-box").is(":visible")) {
            $("#chat-box").slideToggle(function () {
                $("#chat-button").slideToggle();
            });
        }


    }
}
