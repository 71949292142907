/**
* @file  MountingPrice.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Customer } from "../KYC/customer";
import { ContractHoardings } from "../contracts/contract-hoarding";
import { PlanHoarding } from "./planHoarding";
import { Price } from "./price";

export class MountingPrice extends Price {
  
}