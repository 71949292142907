<!-- <div class="submenu"> -->
<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? _item : _item.items)">
  <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" class="subMenu"
    *ngIf="child.visible === false ? false : true">
    <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink"
      *ngIf="!child.routerLink" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
      <!-- <div class="custom-icon"><img src="{{child.icon}}" alt=""> <i class="icon-icons-roles" data-icon="a" aria-hidden="true"></i>   </div> -->
      <i class="{{child.icon}}" aria-hidden="true"
        [ngClass]=" child.label === 'Others' ? 'menu-icon menu-icon-others' : 'menu-icon'"> </i>
      <!--  menu-icon  -->
      <!--  -->
      <span class='badge' *ngIf="child.badge">{{child.badge}}</span>
      <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
    </a>



    <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="child.routerLink"
      [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
      [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
      <!-- <div class="custom-icon"><img src="{{child.icon}}" alt=""> </div> -->
      <i class="{{child.icon}}" aria-hidden="true"
        [ngClass]=" child.label === 'Others' ? 'menu-icon menu-icon-others' : 'menu-icon'"> </i>
      <span>{{child.label}}</span>
      <span class="menuitem-badge badge" *ngIf="child.badge">{{child.badge}}</span>
      <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">{{child.label}}</div>
    </div>
    <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
      [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ? 'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'">
      <!-- [ngClass]="{'submenu-others-scroller' : child.label === 'Others'}" -->
      <!-- [ngClass]="(child.label === 'Others')? 'submenu-others-scroller' : 'overflow-hidden'" -->
    </ul>
  </li>
  <div style="border-bottom: 1.5px solid #a9a9a9fc;" *ngIf="child.label === 'Tasks'"></div>
</ng-template>

<!-- </div> -->

<!-- *ngIf="child.visible === false ? false : true" -->

<!-- *permission="'read:customer'" -->