/*
* @file Document service.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/


import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable()
export class EditPOService {
    showLoader$: Subject<boolean> = new Subject();

    resetLoader() {
        this.showLoader$.next();
    }
}
