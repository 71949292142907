import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from '../../services/shared/notifications-messages';

@Injectable()
export class HoardingStatusService extends ApiService<any, any, any> {

    urlPath: string = 'campaignItems';

    constructor(private Http: HttpClient,
        private Injector: Injector,
        private notificationServcie: NotificatoinsService,
    ) {
        super(Http, Injector);
    }

    downloadFlexXls(fileType, data) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + 'campaignItems/exportGrid/XLSX/flex-items';
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }), catchError((err) => {

                    const errorObject = ErrorUtil.getErrorObject(err);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    return err;
                }));
            }
        }

    }

    downloadMountingXls(fileType, data) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + 'campaignItems/exportGrid/XLSX/mounting-items';
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }), catchError((err) => {
                    var errorObject;
                    var errorObject = ErrorUtil.getErrorObject(err);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    return err;
                }));
            }
        }

    }

    downloadMonitoringXls(fileType, data) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + 'campaignItems/exportGrid/XLSX/monitoring-items';
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }), catchError((err) => {
                    var errorObject;
                    var errorObject = ErrorUtil.getErrorObject(err);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    return err;
                }));
            }
        }

    }

    downloadUnmountingXls(fileType, data) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + 'campaignItems/exportGrid/XLSX/unmounting-items';
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }), catchError((err) => {
                    var errorObject;
                    var errorObject = ErrorUtil.getErrorObject(err);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    return err;
                }));
            }
        }

    }
}