import { CampaignItemWrapper } from "./campaign-item-wrapper";
import { PrintingPrice } from "../plans/printingPrice";
import { MountingPrice } from "../plans/mountingPrice";
import { AdditionalItemWrapper } from "./AdditionalItemWrapper";

export class AdditionalItem extends CampaignItemWrapper {
    printingPrice: PrintingPrice = new PrintingPrice();
    mountingPrice: MountingPrice = new MountingPrice();
    amount: number;
    serviceDate: Date;
    actualAmount: number;
    creative: string;
    isGlobalSet?: boolean;
    note: string;

    setItem(item: AdditionalItemWrapper): AdditionalItem {
        this.printingPrice = item.printingPrice;
        this.mountingPrice = item.mountingPrice;
        this.amount = item.amount;
        this.city = item.city;
        this.cost = item.cost;
        this.customId = item.customId;
        this.id = item.id;
        this.location = item.location;
        this.rate = item.rate;
        this.lightType = item.lightType;
        this.mediaSubCategory = item.mediaSubCategory;
        this.size = item.size;
        this.squareFeet = item.squareFeet;
        this.serviceDate = item.serviceDate;
        this.actualAmount = item.actualAmount;
        this.creative = item.creative;
        this.note = item.note;
        // if (item.printingPrice.flNlPrice !== 0) {
        //     this.printingCost = item.printingPrice.flNlPrice;
        // } else if (item.printingPrice.blPrice !== 0) {
        //     this.printingCost = item.printingPrice.blPrice;
        // }
        // if (item.mountingPrice.pricePerSqFt !== 0) {
        //     this.mountingCost = item.mountingPrice.pricePerSqFt;
        // } else if (item.mountingPrice.pricePerUnit !== 0) {
        //     this.mountingCost = item.mountingPrice.pricePerUnit;
        // }
        return this;
    }
}
