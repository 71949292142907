export class PendingUnbilled {
    employee: string;
    company: string;
    campaignId: string;
    displayName: string;
    unbilledAmount: number;
    minDate: Date;
    maxDate: Date;
    delayedDays: number;
}
