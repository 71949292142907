import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[mtabGridTemplate]'
})
export class GridTemplateDirective {

  @Input('mtabGridTemplate')
  type: string;

  constructor(public templateRef: TemplateRef<any>) {

  }

}