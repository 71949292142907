import { Component, OnInit, ViewChild, ViewEncapsulation, Input, ElementRef, HostListener } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import * as utils from '../../helpers/utils';
import { DateUtil } from '../../helpers/date.util';
import { UserService } from '../../services/shared/user.service';
import { AppUrls } from '../../services/urls';
import { SalesChartService } from '../../services/shared/sales-chart.service';
import { SibChartComponent } from '../../sib-chart/sib-chart.component';
import { DashboardService } from '../../dashboard/service/dashboard.service';
import { ColumnChartConfig, Bar, VAxis, HAxis, ChartArea } from '../../modals/charts/columnChart.config';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { CityService } from '../../masters/city/service/city.service';
import { StackedBarChartConfig } from '../../modals/charts/stackedBarChart.config';
import { ChartLegend } from '../../modals/charts/chart-legend/chart-legend';
import { Annotation } from '../../modals/charts/annotation/annotation';
import { MonthEnum } from '../../shared/constants/month.enum';
import { BillingSearch } from '../../modals/billings/billingSearch';
import { GridPaginationEvent, GridConfig, SelectionMode } from '../../components/sib-forms/grid/grid.config';
import * as salesChartColumnConfig from '../../modals/column-configurations/sales-chart-hoardings-column-config';
import * as unbilledHoardingsColumnConfig from '../../modals/column-configurations/unbilled-hoardings-column-config';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { map, catchError, concat, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SalesHoardings } from '../../modals/hoardings/sales-hoardings';
import { ViewInventoryComponent } from '../../shared/components/view-inventory/view-inventory.component';
import { Subscription, Subject } from 'rxjs';
import { HoardingMasterColService } from '../../services/shared/hoarding-master-column.service';
import { GridNameEnum } from '../../shared/constants/grid-name.enum';
import { SuperbillChartComponent } from '../../billings/superbill-chart/superbill-chart.component';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { UnbilledItems } from '../../modals/hoardings/unbilled-items';
import { LoaderSubjects } from '../../modals/loader-subjects/loader-subjects';
import { PPTLoader } from '../../modals/loader-subjects/pptloader';
import { empty as observableEmpty } from 'rxjs';
import { CalculationService } from '../../services/shared/calculationService';
import * as _ from 'lodash';

@Component({
  selector: 'sib-sales-chart',
  templateUrl: './sales-chart.component.html',
  styleUrls: ['./sales-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalesChartComponent implements OnInit {

  @ViewChild(SibChartComponent)
  sibChartRef: SibChartComponent;
  @ViewChild('superbillCardRef') superbillCardRef: ElementRef;
  @ViewChild('citySalesChartRef') citySalesChartRef: ElementRef;
  @ViewChild(SuperbillChartComponent) superbillChartChildRef: SuperbillChartComponent;
  @ViewChild('rentItems') rentItems: ViewInventoryComponent;
  @ViewChild('migratedRentItems') migratedRentItems: ViewInventoryComponent;
  @ViewChild('printItems') printingItems: ViewInventoryComponent;
  @ViewChild('mountItems') mountingItems: ViewInventoryComponent;
  @ViewChild('unbilledItems') unbilledItems: ViewInventoryComponent;
  @ViewChild('gb') globalFilter: ElementRef;

  @Input() showTitle: boolean = true;
  @Input() showAction: boolean = true;

  title = 'Sales';
  gridTitle = "Hoardings";
  salesReps: SelectItem[] = [];
  currentFiscalYears: any[] = [];
  fiscalYears: SelectItem[] = [];
  selectedFiscalYear: any;
  selectedUser: any;

  salesColumnChartConfig: ColumnChartConfig = new ColumnChartConfig();
  columnChartData: any[] = [];
  salesChartData: any[] = [];
  data: any[] = [];
  lineElementId: string;
  columnChartLoader: boolean = true;

  isLoading: boolean = false;
  width: number;
  height: number;
  salesChartArea: ChartArea = new ChartArea();
  email: string;
  fullName: string;

  citySalesStackedBarChartConfig: StackedBarChartConfig = new StackedBarChartConfig();
  barChartData: any[] = [];
  citySalesChartData: any[] = [];
  cityChartData: any[] = [];
  citySalesData: any[] = [];

  salesCityChartArea: ChartArea = new ChartArea();
  citySalesChartLegend: ChartLegend = new ChartLegend();
  citySalesChartHeight: number;
  chartElementId: string
  totalCities: any[] = [];
  citiesOptions: any[] = [];
  selectedCities: any[];
  stackedBarChartLoader: boolean = true;
  hAxis: HAxis = new HAxis();
  vAxis: VAxis = new VAxis();
  annotations: Annotation = new Annotation();
  bar: Bar = new Bar();
  citiesSelected: string;

  dates: any[] = [];
  startDate: Date;
  endDate: Date;
  billingSearch: BillingSearch = new BillingSearch();
  isSalesChartClicked: boolean = false;
  salesChartClickedData: any[];
  paginationEvent: GridPaginationEvent;
  unbilledItemsPaginationEvent: GridPaginationEvent;
  billedItemsPaginationEvent: GridPaginationEvent;

  itemsPaginationEvent: GridPaginationEvent = {
    page: 0,
    size: 50,
    filters: {},
    globalFilter: '',
    sortField: 'updatedDate',
    sortOrder: 1,
  };

  salesChartColumns: any[] = [];
  salesPrintingItemsColumns: any[] = [];
  salesMountingItemsColumns: any[] = [];

  rentItemsGridConfig: GridConfig<SalesHoardings> = new GridConfig<SalesHoardings>();
  migratedRentItemsGridConfig: GridConfig<SalesHoardings> = new GridConfig<SalesHoardings>();
  unbilledItemsGridConfig: GridConfig<UnbilledItems> = new GridConfig<UnbilledItems>();

  cityWiseRentItemsGridConfig: GridConfig<SalesHoardings> = new GridConfig<SalesHoardings>();
  cityWiseMigratedRentItemsGridConfig: GridConfig<SalesHoardings> = new GridConfig<SalesHoardings>();
  cityWiseUnbilledItemsGridConfig: GridConfig<UnbilledItems> = new GridConfig<UnbilledItems>();

  salesChartHoardings: number;
  totalPrintingItems: number;
  totalMountingItems: number;
  migratedItems: number;
  isCitySalesChartClicked: boolean = false;
  isChartClicked: boolean = false;

  modifiedColumns: SelectItem[] = [];
  selectedColumns: any[] = [];
  subscription: Subscription[] = [];
  gridNameEnum = GridNameEnum['RENT_ITEMS'];
  showEmployee: boolean = false;
  titleForUnsoldCities: string;
  soldHoardings: any;
  migratedHoardings: any;
  activeIndex: number;
  srList: any[] = [];
  filteredSalesRepresentative: any[] = [];
  performance: any;
  chartInformation: any;
  totalSuperbill: any;
  totalInvoice: any;

  unbilledHoardings: any;
  totalUnbilledHoardings: number;
  unbilledHoardingsColumns: any[] = [];
  // isUnbilledCitySalesChartClicked: boolean = false;
  // billedBarClicked: boolean = false;
  isSalesBarClicked: boolean = false;
  isCitySalesBarClicked: boolean = false;
  // isCityBilledBarClicked: boolean = false;
  // isCityUnbilledBarClicked: boolean = false;

  rentAmount: string;
  printingAmount: string;
  mountingAmount: string;

  isFileDownloading: boolean = false;
  xlsLoader: PPTLoader = new PPTLoader();
  invoiceType: string;

  paginationData: GridPaginationEvent;
  city: any[] = [];

  gridLoader: boolean;
  afterRentTabClicked: boolean = false;
  afterMigratedTabClicked: boolean = false;
  afterUnbilledTabClicked: boolean = false;
  // globalFilterFields: string[] = ['HID', 'Employee'];
  globalSearchData: Subject<any> = new Subject();
  globalFilterValue: any;

  searchEventMap: Map<string, GridPaginationEvent> = new Map();
  searchEvent: any;
  totalRentAmount: any;
  totalMigratedAmount: any;
  totalUnbilledAmount: any;
  showTotal: boolean = false;
  rentGlobalFilterFields: string[] = ['HID, Invoice, City, Location, Size, Display Name, Employee'];
  unbilledGlobalFilterFields: string[] = ['HID, City, Location, Size, Display Name, Employee'];
  splitSelectedFiscalYear: any[] = [];
  pageSize: number;
  page: number;

  constructor(
    private userService: UserService,
    private salesChartService: SalesChartService,
    private dashboardService: DashboardService,
    private pageTitle: Title,
    private cityService: CityService,
    private notificationServcie: NotificatoinsService,
    private hmColService: HoardingMasterColService,
    private currencyPipeService: CurrencyHelperPipe,
    private loaderSubjects: LoaderSubjects,
    private calculationService: CalculationService,
  ) {
    this.citySalesStackedBarChartConfig.onSelect = this.onCitySalesChartSelection.bind(this);
  }

  ngOnInit() {
    if (this.showTitle) { // to show the page title
      let appTitle = utils.getAppTitle();
      !appTitle ? this.pageTitle.setTitle(SIBConstants.SALES_CHART_SUBMENU) : this.pageTitle.setTitle(SIBConstants.SALES_CHART_SUBMENU + ' - ' + appTitle);
    }

    this.fullName = utils.getLoggedInUserFullName(); // stores the fullName of the user
    this.email = utils.getLoggedInUserEmail(); // stores the email Id of the user

    this.setEmployeeColumnVisibility(); // sets the visibility of the employee column
    this.setFiscalYearDropdown();
    // this.getCurrentFiscalYear(); // get the current fiscal year and set as selected fiscal year
    // this.getFiscalYears(); // get fiscal years for the fiscal year dropdown
    this.setColumns(); // set columns for the grids
    this.setCitiesOptions(); // set Cities List
    this.getUsers(); // get SR's
    this.setSalesChartGridConfig(); // set grid configuration

    this.globalSearchData
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchValue) => {
        this.setGlobalFilterValue(searchValue);
        if (searchValue || searchValue === '') {
          this.resetGridFilters();
          this.refreshGrid();
          this.getItemCount();
        }

        setTimeout(() => { this.hmColService.globalFilter.next(searchValue); }, 1000);
      });
  }

  /**
   * @description set columns for the grids
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setColumns() {
    this.setRentItemColumns();
    this.setUnbilledItemColumns();
  }

  /**
   * @description  set rent items and migrated rent items columns
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setRentItemColumns() {
    this.salesChartColumns = salesChartColumnConfig.SalesChartHoardingColumns;
  }

  /**
   * @description set unbilled items columns
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setUnbilledItemColumns() {
    this.unbilledHoardingsColumns = unbilledHoardingsColumnConfig.UnbilledHoardingColumns;
  }

  ngAfterViewInit() {
    this.hmColService.defaultColumns.subscribe((defaultColumns) => {
      this.selectedColumns = [];
      defaultColumns.forEach((column) => {
        if (column['default'] === true && column['permanent'] === false) {
          this.selectedColumns.push(column['header']);
        }
      });

      if (this.selectedColumns.length > 0) {
        this.hmColService.selectedColumns.next(this.selectedColumns);
      }

    });
  }

  /**
   * @description set Employee Column Visibility
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setEmployeeColumnVisibility() {
    if (this.checkPermission('readAll') || this.checkPermission('view:employee-column')) { // should be visible if the user has readAll or view:employee-column permissions
      this.showEmployee = true;
    } else { // else employee column should not be visible.
      this.showEmployee = false;
    }
  }

  /**
    * @description create Fiscal year dropdown
    * @author Divya Sachan
    * @date 2019-10-07
    * @memberof SalesChartComponent
    */
  setFiscalYearDropdown() {
    this.currentFiscalYears = String(DateUtil.getCurrentFiscalYear()).split("-"); // get current fiscal year
    var years = DateUtil.fiscalYears(new Date(this.currentFiscalYears[0] - 1, 0, 1), new Date()); //get the years to show in fiscal year dropdown
    this.fiscalYears = utils.createDropdownFromStringArray(years, false); // create label value pair 
    // this.selectedFiscalYear = this.fiscalYears[this.fiscalYears.length - 1].value; // set selected Fiscal year
    this.fiscalYears.forEach(year => {
      if (year.value === String(this.currentFiscalYears[0] + '-' + this.currentFiscalYears[1])) {
        this.selectedFiscalYear = year.value;// set selected Fiscal year
      }
    });
    this.splitSelectedFiscalYear = this.selectedFiscalYear.split("-");
  }

  /**
   * @description Setting Cities List
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setCitiesOptions() {
    this.cityService.get(null, AppUrls.GET_ALL).subscribe((response) => {
      if (response) {
        this.totalCities = response['data'];
        this.filterCities();
      }
    })
  }

  /**
   * @description Filtering Cities
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  filterCities() {
    this.citiesOptions = [];
    if (this.selectedCities && this.selectedCities.length > 0) {

      this.selectedCities.forEach((selectedCity) => {
        this.citiesOptions.push({ label: selectedCity.name, value: selectedCity });
      });

      this.totalCities.forEach((city) => {
        let isCityNotSelected = true;
        this.selectedCities.forEach((selectedCity) => {
          if (selectedCity.id === city.id) {
            isCityNotSelected = false;
          }
        });
        if (isCityNotSelected) {
          this.citiesOptions.push({ label: city.name, value: city });
        }
      })

    } else {
      this.totalCities.forEach((city) => {
        this.citiesOptions.push({ label: city.name, value: city });
      });
    }

  }

  /**
   * @description Getting SR List and creating dropdown
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  getUsers() {
    this.isLoading = true;

    this.userService.get({ role: SIBConstants.SALES_REPRESENTATIVE }, AppUrls.SEARCH + AppUrls.BY_ROLE).subscribe(
      (response) => {
        if (response) {
          const resp = response["_embedded"]["users"];

          this.srList.push({ fullName: 'All', emailId: 'ALL' });

          resp.forEach((user) => {
            this.srList.push(user);
          })
          this.setUserOnLoad(); // set user on load
          this.getSalesChartData(); // get column chart data -- sales
          this.getCitySalesChartData(); // get stacked bar chart data -- city sales
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  /**
   * @description setting user on load permission wise
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setUserOnLoad() {
    let user = null;
    if (this.checkPermission('readAll')) { // if the user has readAll permission then ALL option is set
      user = this.srList.find((user) => user.fullName === "All");
    } else if (!this.checkPermission('readAll')) { // otherwise the respective user is set
      user = this.srList.find((user) => user.fullName === this.fullName);
    }
    this.selectedUser = user;
  }

  /**
   * @description Filtering SR 
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} event
   * @memberof SalesChartComponent
   */
  filterSalesRepresentative(event) {
    this.filteredSalesRepresentative = [];
    this.filteredSalesRepresentative = utils.filterData(this.srList, 'fullName', event);
  }

  /**
   * @description Creating Column chart for Sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  getSalesChartData() {
    this.setFiscalYearStartAndEndDate(); // set month start and end date
    this.setSelectedUserEmail(); // set user email
    this.setSalesChartBillingSearch();
    setTimeout(() => {
      this.superbillChartChildRef.getSuperbillChartData();
    }, 1000);
    this.showTotal = true;
  }

  /**
   * @description Get Total Performance of the selected Fiscal Year.
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} event
   * @memberof SalesChartComponent
   */
  getPerformance(event) {
    this.performance = event;
    this.totalSuperbill = this.performance.superbill;
    this.totalInvoice = this.performance.invoice;
  }

  /**
   * @descriptionSetting Sales Chart Configuration
   * (not used as of now because we are using superbill chart to show in sales)
   * @author Divya Sachan
   * @date 2019-10-07
   * @returns
   * @memberof SalesChartComponent
   */
  setSalesChart() {
    if (this.columnChartData) {
      let minimum = -1;
      let maximum = 1;
      let vAxisMaxValue: number;
      let vAxisMinValue: number;

      this.lineElementId = SIBConstants.SALES_CHART_ID;
      this.salesChartData = [];

      if (this.columnChartData.length === 0) return;

      this.salesChartData.push([SIBConstants.MONTH, SIBConstants.BILLED_AMOUNT, SIBConstants.INVOICE_AMOUNT, SIBConstants.POS_CAMPAIGN_DIFFERENCE, SIBConstants.NEG_CAMPAIGN_DIFFERENCE, SIBConstants.UNBILLED_AMOUNT]);
      this.data = [];
      this.columnChartData.forEach((data) => {
        if (data) {
          let campaignDiff = data.billedAmount + data.unbilledAmount - data.superBillAmount;
          let invoiceAmount;
          let billedAmount = data.billedAmount + ((-1) * campaignDiff);
          if (campaignDiff < 0) {
            invoiceAmount = data.billedAmount;
            this.getChartData(billedAmount, invoiceAmount, ((-1) * campaignDiff), data.unbilledAmount);
            this.salesChartData.push([data.month, billedAmount, invoiceAmount, 0, ((-1) * campaignDiff), data.unbilledAmount])
          } else {
            invoiceAmount = billedAmount;
            this.getChartData(billedAmount, invoiceAmount, campaignDiff, data.unbilledAmount);
            this.salesChartData.push([data.month, billedAmount, invoiceAmount, campaignDiff, 0, data.unbilledAmount]);
          }
        }
      });
      this.setWidth();
      this.setHeight();
      this.setSalesChartArea();
      if (this.data && this.data.length > 0) {
        minimum = this.data.reduce((a, b) => Math.min(a, b));
        maximum = utils.getIntegerCeilValue(this.data.reduce((a, b) => Math.max(a, b)));
        vAxisMaxValue = Number(maximum + (0.3 * maximum));
        vAxisMinValue = Number(minimum + (0.3 * minimum));
      }

      if (vAxisMaxValue) {
        this.salesColumnChartConfig.vAxis.viewWindow = { min: 0, max: vAxisMaxValue };
      } else {
        this.salesColumnChartConfig.vAxis.viewWindow = { min: -1, max: 1 };
      }

      this.salesColumnChartConfig.title = this.setSalesChartTitle();
      this.salesColumnChartConfig.height = 280;
      this.salesColumnChartConfig.titleTextStyle = { color: color.BLACK, bold: true }
      this.salesColumnChartConfig.width = this.width;
      this.salesColumnChartConfig.chartArea = this.salesChartArea;
      this.salesColumnChartConfig.isStacked = true;
      this.salesColumnChartConfig.hAxis.textStyle = { color: color.BLACK, fontSize: 13.5 };
      this.salesColumnChartConfig.vAxis.format = 'decimal';
      this.salesColumnChartConfig.vAxes = {
        0: { textStyle: { color: color.BLACK, fontSize: 13.5 } },
        1: { gridlines: { color: color.TRANSPARENT }, textStyle: { color: color.TRANSPARENT } }
      };
      this.salesColumnChartConfig.series = {
        0: { targetAxisIndex: 0, color: color.DARK_BLUE },
        1: { targetAxisIndex: 1, color: color.LIGHT_BLUE },
        2: { targetAxisIndex: 1, color: color.DARK_RED },
        3: { targetAxisIndex: 1, color: color.LIGHT_GREEN },
        4: { targetAxisIndex: 0, color: color.LIGHT_RED },
      };

      this.columnChartLoader = false;
    } else {

      this.columnChartLoader = false;
    }
  }

  /**
   * @description get min and max from the chart data to set min and max value of the chart vAxis
   * not in use
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} billedAmount
   * @param {*} invoiceAmount
   * @param {*} campaignDiff
   * @param {*} unbilledAmount
   * @memberof SalesChartComponent
   */
  getChartData(billedAmount, invoiceAmount, campaignDiff, unbilledAmount) {
    if (unbilledAmount !== 0) {
      this.data.push(unbilledAmount);
    }
    if (billedAmount !== 0) {
      this.data.push(billedAmount)
    }
    if (campaignDiff !== 0) {
      this.data.push(campaignDiff);
    }
    if (invoiceAmount !== 0) {
      this.data.push(invoiceAmount);
    }
    if (billedAmount === 0 && unbilledAmount === 0) {

    } else {
      this.data.push(billedAmount + unbilledAmount);
    }
    if (campaignDiff === 0 && invoiceAmount === 0) {

    } else {
      this.data.push(campaignDiff + invoiceAmount);
    }
  }

  /**
   * @description Setting Stacked bar chart for city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  getCitySalesChartData() {
    // this.stackedBarChartLoader = true;
    // if (!this.isSalesChartClicked) {
    //   this.setFiscalYearStartAndEndDate();
    // } else {
    //   this.stackedBarChartLoader = true;
    //   this.citySalesChartData = [];
    //   this.setMonthStartAndEndDate();
    // }
    this.isLoading = true;
    this.setSelectedUserEmail(); // set selected user email
    this.setCitySalesChartBillingSearch(); // set billing search for city sales
    this.cityChartData = [];
    this.citySalesChartData = [];
    this.salesChartService.getCitySalesChartData(this.billingSearch).subscribe((response) => {
      if (response && response['data'] && response['data'].length > 0) { // if response exists then set city sales data
        this.barChartData = [];
        this.barChartData = response['data'];
        this.setCitySalesChart();
      } else { // otherwise show message
        this.barChartData = [];
        this.titleForUnsoldCities = this.setTitleForUnsoldCities();
        this.stackedBarChartLoader = false;
        this.isLoading = false;
      }
    })
  }

  /**
   * @description setting City sales chart data and properties
   * @author Divya Sachan
   * @date 2019-10-07
   * @returns
   * @memberof SalesChartComponent
   */
  setCitySalesChart() {
    if (this.barChartData) { //if city sales chart data exists
      this.chartElementId = SIBConstants.CITY_SALES_CHART_ID;

      if (this.barChartData.length === 0) return;

      this.setCitySalesData();
      this.setCitySalesConfig();
      this.showTotal = true;
      this.stackedBarChartLoader = false;
      this.isLoading = false;
    } else { // Otherwise
      this.isLoading = false;
      this.stackedBarChartLoader = false;
    }
  }

  /**
   * @description set City Sales Data
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setCitySalesData() {
    this.citySalesChartData = [];
    this.cityChartData = [];
    let totalRentAmount = 0;
    let totalMigratedAmount = 0;
    let totalUnbilledAmount = 0;
    this.citySalesChartData.push([SIBConstants.CITY, SIBConstants.BILLED_AMOUNT, SIBConstants.UNBILLED_AMOUNT, { role: 'annotation' }]);
    this.barChartData.forEach((data) => {
      if (data) {
        let unbilledAmount = data.unbilledAmount;
        let billedAmount = data.invoiceAmount + data.migratedInvoiceAmount;
        let amount = utils.getIntegerRoundedOffValue(data.amount);
        this.cityChartData.push([data.city, billedAmount, unbilledAmount, data.amount]);
        if (this.cityChartData && this.cityChartData.length <= 10) {
          this.citySalesChartData.push([data.city, billedAmount, unbilledAmount, amount]);
        }
        totalRentAmount += data.invoiceAmount;
        totalMigratedAmount += data.migratedInvoiceAmount;
        totalUnbilledAmount += data.unbilledAmount;
      }
    });

    this.totalRentAmount = this.currencyPipeService.transform(utils.parseValueToDecimalTwoPlaces(totalRentAmount));
    this.totalMigratedAmount = this.currencyPipeService.transform(utils.parseValueToDecimalTwoPlaces(totalMigratedAmount));
    this.totalUnbilledAmount = this.currencyPipeService.transform(utils.parseValueToDecimalTwoPlaces(totalUnbilledAmount));
  }


  /**
   * @description set Stacked bar chart configuration and properties
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setCitySalesConfig() {
    this.setCitySalesChartHeight(this.citySalesChartData.length)
    this.setCitySalesChartArea();
    this.setCitySalesChartAnnotation();
    this.setHAxis();
    this.setVAxis();
    this.setCitySalesChartLegend();
    this.width = innerWidth - 70;
    this.bar.groupWidth = '25';
    this.citySalesStackedBarChartConfig.title = this.setCitySalesChartTitle();
    this.citySalesStackedBarChartConfig.titleTextStyle = { color: color.BLACK, bold: true }
    this.citySalesStackedBarChartConfig.isStacked = true;
    this.citySalesStackedBarChartConfig.width = this.width;
    this.citySalesStackedBarChartConfig.chartArea = this.salesCityChartArea;
    this.citySalesStackedBarChartConfig.chartLegend = this.citySalesChartLegend;
    this.citySalesStackedBarChartConfig.hAxis = this.hAxis;
    this.citySalesStackedBarChartConfig.vAxis = this.vAxis;
    this.citySalesStackedBarChartConfig.bar = this.bar;
    this.citySalesStackedBarChartConfig.annotations = this.annotations;
    this.citySalesStackedBarChartConfig.series = {
      0: { color: color.DARK_BLUE }, // dark blue
      1: { color: color.LIGHT_BLUE }, // light blue
    };
  }
  /**
   * @description set Selected User Email 
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setSelectedUserEmail() {
    if (this.selectedUser === "All") { // if All is set then set email as ALL
      this.email = "ALL";
    } else { // Otherwise set respective user's email
      this.findSelectedUser(this.selectedUser);
    }

  }

  /**
   * @description Find selected User and set the user email
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} selectedUser
   * @memberof SalesChartComponent
   */
  findSelectedUser(selectedUser) {
    let user = this.srList.find((user) => user.fullName === selectedUser.fullName);
    this.email = user.emailId;
  }

  /**
   * @description Set Billing Search Object for Sales - Column Chart
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setSalesChartBillingSearch() {
    this.billingSearch = {
      itemStartDate: new Date(this.startDate),
      itemEndDate: new Date(this.endDate),
      userEmailId: this.email,
    }
  }

  /**
   * @description Set Billing Search Object for City Sales - Stacked Bar chart
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setCitySalesChartBillingSearch() {
    this.billingSearch = {
      itemStartDate: new Date(this.startDate),
      itemEndDate: new Date(this.endDate),
      userEmailId: this.email,
      cities: this.selectedCities,
    }
  }

  /**
   * @description Setting Fiscal Year Start Date and End Date
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setFiscalYearStartAndEndDate() {
    this.splitSelectedFiscalYear = this.selectedFiscalYear.split("-");
    this.startDate = new Date(Date.UTC(Number(this.splitSelectedFiscalYear[0]), 3, 1));
    this.endDate = new Date(Date.UTC(Number(this.splitSelectedFiscalYear[1]), 2, 31));
  }

  /**
   * @description Setting Month Start Date and End Date
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setMonthStartAndEndDate() {
    this.dates = DateUtil.getMonthStartAndEndDate(Number(MonthEnum[this.salesChartClickedData[0]]), this.selectedFiscalYear);
    let monthStartDate = new Date(this.dates[0]);
    let monthEndDate = new Date(this.dates[1]);
    this.startDate = new Date(Date.UTC(Number(monthStartDate.getFullYear()), monthStartDate.getMonth(), monthStartDate.getDate(), 0, 0, 0, 0));
    this.endDate = new Date(Date.UTC(Number(monthEndDate.getFullYear()), monthEndDate.getMonth(), monthEndDate.getDate(), 0, 0, 0, 0));
  }

  /**
   * @description load column chart and stacked bar chart on fiscal year selection
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  onFiscalYearSelection() {
    this.isChartClicked = false;
    this.isSalesChartClicked = false;
    this.isSalesBarClicked = false;
    this.isCitySalesChartClicked = false;
    this.isCitySalesBarClicked = false;
    // this.resetGlobalFilter();
    this.getSalesChartData();
    this.getCitySalesChartData();
  }

  /**
   * @description load column chart and stacked bar chart on user selection
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  onUserSelection() {
    this.selectedCities = [];
    this.isChartClicked = false;
    this.isSalesChartClicked = false;
    this.isCitySalesChartClicked = false;
    // this.resetGlobalFilter();
    this.getSalesChartData();
    this.getCitySalesChartData();
  }

  /**
   * @description load stacked column chart on city selection
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  onCitySelection() {
    this.isChartClicked = false;
    this.isSalesChartClicked = false;
    this.isCitySalesChartClicked = false;
    this.showTotal = false;
    // this.resetGlobalFilter();
    this.getCitySalesChartData();
  }

  /**
   * @description set width for the column chart (not in use as of now)
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setWidth() {
    this.width = window.innerWidth - 100;
  }

  /**
   * @description set height for the column chart (not in use as of now)
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setHeight() {
    this.height = window.innerHeight - 400;
  }

  /**
   * @description set horizontal Axis for the stacked bar chart - city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setHAxis() {
    this.hAxis.title = "Total Sales";
    this.hAxis.direction = 1;
  }

  /**
   * @description set vertical axis for the stacked bar chart - city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setVAxis() {
    this.vAxis.title = "";
  }

  /**
   * @description set chart area for the column chart -- sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setSalesChartArea() {
    this.salesChartArea.height = "70%";
    this.salesChartArea.width = "70%";
    this.salesChartArea.left = "15%";
    this.salesChartArea.top = "15%";
  }

  /**
   * @description set chart area for the stacked bar chart -- city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setCitySalesChartArea() {
    this.salesCityChartArea.height = "70%";
    this.salesCityChartArea.width = "70%";
    this.salesCityChartArea.left = "9%";
    this.salesCityChartArea.top = "15%";
  }

  /**
   * @description set annotation property for the stacked bar chart -- city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setCitySalesChartAnnotation() {
    this.annotations.textStyle = {
      color: '#000',
      bold: true
    }
    this.annotations.alwaysOutside = true;
    this.annotations.stemColor = 'none';
  }

  /**
   * @description set chart legend for the stacked bar chart -- city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setCitySalesChartLegend() {
    this.citySalesChartLegend.position = 'top';
  }

  /**
   * @description checks permission
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} permission
   * @returns
   * @memberof SalesChartComponent
   */
  checkPermission(permission) {
    return utils.checkPermission(permission);
  }

  /**
   * @description load grid Items on when sales bar is clicked
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} data
   * @memberof SalesChartComponent
   */
  onSalesChartSelection(data) {
    this.isChartClicked = true;
    this.isSalesChartClicked = true;
    this.isCitySalesChartClicked = false;
    this.salesChartClickedData = data;
    this.setMonthStartAndEndDate();
    if (this.globalFilterValue && this.globalFilter.nativeElement.value) {
      this.resetGlobalFilter();
    }
    this.getCitySalesChartData();

    if (this.activeIndex === undefined) {
      this.activeIndex = 0;
      this.afterRentTabClicked = true;
      this.afterMigratedTabClicked = false;
      this.afterUnbilledTabClicked = false;
    } else {

    }

    if (this.isSalesBarClicked && this.isSalesChartClicked && this.citySalesChartData && this.citySalesChartData.length > 0) {
      this.refreshGrid();
    }

    this.getItemCount();
  }

  /**
   * @description Setting Grid Configuration
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setSalesChartGridConfig() {
    this.setRentItems();
    this.setMigratedRentItems();
    this.setUnbilledItems();
    this.setCityWiseSalesGridConfig();
  }

  /**
   * @description setting grid configuration on selection of city sales
   * @author Divya Sachan
   * @date 2020-01-10
   * @memberof SalesChartComponent
   */
  setCityWiseSalesGridConfig() {
    this.setCityWiseRentItems();
    this.setCityWiseMigratedRentItems();
    this.setCityWiseUnbilledItems();
  }

  /**
   * @description action to be performed when stacked bar chart's bar is clicked -- city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} data
   * @memberof SalesChartComponent
   */
  onCitySalesChartSelection(data) {
    this.isChartClicked = true;
    this.isCitySalesChartClicked = true;
    this.isSalesChartClicked = false;
    this.citySalesData = data;
    // this.resetGlobalFilter();
    if (this.globalFilterValue && this.globalFilter.nativeElement.value) {
      this.resetGlobalFilter();
    }
    // this.getCitySalesChartData();
    if (this.activeIndex === undefined) {
      this.activeIndex = 0;
      this.afterRentTabClicked = true;
      this.afterMigratedTabClicked = false;
      this.afterUnbilledTabClicked = false;
    } else {

    }

    let selectedCity = this.barChartData.find(cityData => cityData.city === data[0]);
    this.totalRentAmount = this.currencyPipeService.transform(utils.parseValueToDecimalTwoPlaces(selectedCity.invoiceAmount));
    this.totalMigratedAmount = this.currencyPipeService.transform(utils.parseValueToDecimalTwoPlaces(selectedCity.migratedInvoiceAmount));
    this.totalUnbilledAmount = this.currencyPipeService.transform(utils.parseValueToDecimalTwoPlaces(selectedCity.unbilledAmount));

    if (this.isCitySalesBarClicked && this.isCitySalesChartClicked) {
      this.refreshGrid()
    }
    this.getItemCount();
  }

  /**
   * @description set height of the stacked bar chart -- city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} citySalesChartRecordsLength
   * @memberof SalesChartComponent
   */
  setCitySalesChartHeight(citySalesChartRecordsLength) {
    if (citySalesChartRecordsLength >= 1 && citySalesChartRecordsLength < 5) {
      this.citySalesStackedBarChartConfig.height = 400;
    } else if (citySalesChartRecordsLength >= 5 && citySalesChartRecordsLength <= 8) {
      this.citySalesStackedBarChartConfig.height = 450;
    } else if (citySalesChartRecordsLength >= 9 && citySalesChartRecordsLength <= 11) {
      this.citySalesStackedBarChartConfig.height = 500;
    } else {
      this.citySalesStackedBarChartConfig.height = 550;
    }
  }

  /**
   * @description set title for the column chart -- sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @returns
   * @memberof SalesChartComponent
   */
  setSalesChartTitle() {
    return 'Sales For Financial Year ' + this.selectedFiscalYear;
  }

  /**
   * @description set title for the stacked column chart -- city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @returns
   * @memberof SalesChartComponent
   */
  setCitySalesChartTitle() {
    if (this.selectedCities === undefined || this.selectedCities.length === 0) {
      this.citiesSelected = 'For City(s)';
    } else if (this.citiesOptions.length === this.selectedCities.length) {
      this.citiesSelected = 'For All Cities';
    } else if (this.selectedCities.length === 1) {
      this.citiesSelected = 'For ' + this.selectedCities[0].name;
    } else if (this.selectedCities.length === 2) {
      this.citiesSelected = 'For ' + this.selectedCities[0].name + ' and ' + this.selectedCities[1].name;
    } else if (this.selectedCities.length > 2 && this.selectedCities.length < this.citiesOptions.length) {
      this.citiesSelected = 'For ' + this.selectedCities[0].name + ', ' + this.selectedCities[1].name + ' and more';
    }
    return ('Sales ' + this.citiesSelected);
  }

  /**
   * @description set title for stacked bar chart when cities selected does not have any sale -- city sales
   * @author Divya Sachan
   * @date 2019-10-07
   * @returns
   * @memberof SalesChartComponent
   */
  setTitleForUnsoldCities() {
    if (this.selectedCities === undefined || this.selectedCities.length === 0) {
      this.citiesSelected = 'for any city';
    } else if (this.selectedCities.length === 1) {
      this.citiesSelected = 'for ' + this.selectedCities[0].name;
    } else if (this.selectedCities.length === 2) {
      this.citiesSelected = 'for ' + this.selectedCities[0].name + ' and ' + this.selectedCities[1].name;
    } else if (this.selectedCities.length > 2 && this.selectedCities.length < this.citiesOptions.length) {
      this.citiesSelected = 'for ' + this.selectedCities[0].name + ', ' + this.selectedCities[1].name + ' and more';
    } else if (this.citiesOptions.length === this.selectedCities.length) {
      this.citiesSelected = 'for all Selected cities';
    }
    return ('There is no sale ' + this.citiesSelected);
  }

  /**
   * @description emit set columns
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  emitSetColumns() {
    this.hmColService.selectedColumns.next(this.selectedColumns);
  }

  /**
   * @description updates grid columns configuration
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  updatingUserGridConfig() {
    switch (this.activeIndex) {
      case 0: {
        this.rentItems.updatingUserGridConfig(this.selectedColumns); // columns for rent Items
        break;
      }
      case 1: {
        this.migratedRentItems.updatingUserGridConfig(this.selectedColumns); // columns for migrated rent Items
        break;
      }
      case 2: {
        this.unbilledItems.updatingUserGridConfig(this.selectedColumns); // columns for unbilled Items.
        break;
      }
    }
    // this.rentItems.updatingUserGridConfig(this.selectedColumns); // columns for rent Items
    // this.migratedRentItems.updatingUserGridConfig(this.selectedColumns); // columns for migrated rent Items
    // this.unbilledItems.updatingUserGridConfig(this.selectedColumns); // columns for unbilled Items.
  }

  /**
   * @description get modified columns(temporary columns)
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} event
   * @memberof SalesChartComponent
   */
  getModifiedColumns(event) {
    this.modifiedColumns = event;
  }

  /**
   * @description invokes when tab is changed
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} event
   * @memberof SalesChartComponent
   */
  onTabChange(event) {
    this.activeIndex = event.index;
    switch (this.activeIndex) {
      case 0: { // for first Tab
        this.afterRentTabClicked = true;
        this.afterMigratedTabClicked = false;
        this.afterUnbilledTabClicked = false;
        this.rentItems.refresh();
        break;
      }
      case 1: { // for second tab
        this.afterMigratedTabClicked = true;
        this.afterRentTabClicked = false;
        this.afterUnbilledTabClicked = false;
        // setTimeout(() => {
        this.migratedRentItems.refresh();
        // }, 500);
        break;
      }
      case 2: { // for third tab
        this.afterUnbilledTabClicked = true;
        this.afterRentTabClicked = false;
        this.afterMigratedTabClicked = false;
        this.unbilledItems.refresh();
        break;
      }
    }
  }

  /**
   * @description Set Rent Items Grid Configuration
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setRentItemsGridConfig() {
    this.rentItemsGridConfig.getDefaultModel = () => new SalesHoardings();
    this.rentItemsGridConfig.model = SalesHoardings;
    this.rentItemsGridConfig.selectionMode = SelectionMode.None;
    this.rentItemsGridConfig.editable = true;
    this.rentItemsGridConfig.expandableRows = false;
    this.rentItemsGridConfig.checkboxSelection = false;
    this.rentItemsGridConfig.resizableColumns = false;
    this.rentItemsGridConfig.rowExpandMode = 'single';
    this.rentItemsGridConfig.shouldAddRowOnDelete = false;
    this.rentItemsGridConfig.showLoader = true;
    this.rentItemsGridConfig.lazy = true;
    this.rentItemsGridConfig.enablePagination = true;
    this.rentItemsGridConfig.scrollable = true;
  }

  /**
   * @description Set Rent Items
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setRentItems() {
    this.setRentItemsGridConfig(); // set rent items grid configuration
    this.rentItemsGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      this.setRentItemsPaginationEvent(paginationEvent);
      return this.dashboardService.create(paginationEvent, { invoiceType: 'invoice', page: paginationEvent.page === 0 ? '0' : paginationEvent.page, size: paginationEvent.size, sortField: paginationEvent.sortField, sortOrder: paginationEvent.sortOrder, filters: paginationEvent.filters }, AppUrls.CITY_SALES_CHART + AppUrls.RENT_ITEMS).pipe(map((response) => {
        if (response) {
          this.paginationEvent = paginationEvent;
          this.billedItemsPaginationEvent = this.paginationEvent;
          this.soldHoardings = response['data']['content'];
          this.salesChartHoardings = response['data']['totalElements'];
          // this.billedBarClicked = true;
          // this.isSalesChartClicked ? this.isSalesBarClicked = true : this.isSalesBarClicked = false;
          // this.isCitySalesChartClicked ? this.isCitySalesBarClicked = true : this.isCitySalesBarClicked = false;
          this.isSalesBarClicked = true;
          this.isCitySalesBarClicked = true;
          return response['data']['content'];
        }
      }), catchError(error => {
        // this.showErrorNotification(error);
        if (error.error.code === 422) {
          this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
          this.rentItems.gridViewChildRef.resetAndReloadTable();
        } else {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
          return observableEmpty();
        }
      }));
    }
    this.isChartClicked = false;
    this.afterRentTabClicked = false;
    this.afterMigratedTabClicked = false;
    this.afterUnbilledTabClicked = false;
  }

  /**
   * @description set migrated rent Items grid configuration
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setMigratedRentItemsGridConfig() {
    this.migratedRentItemsGridConfig.getDefaultModel = () => new SalesHoardings();
    this.migratedRentItemsGridConfig.model = SalesHoardings;
    this.migratedRentItemsGridConfig.selectionMode = SelectionMode.None;
    this.migratedRentItemsGridConfig.editable = true;
    this.migratedRentItemsGridConfig.expandableRows = false;
    this.migratedRentItemsGridConfig.checkboxSelection = false;
    this.migratedRentItemsGridConfig.resizableColumns = false;
    this.migratedRentItemsGridConfig.rowExpandMode = 'single';
    this.migratedRentItemsGridConfig.shouldAddRowOnDelete = false;
    this.migratedRentItemsGridConfig.showLoader = true;
    this.migratedRentItemsGridConfig.lazy = true;
    this.migratedRentItemsGridConfig.enablePagination = true;
    this.migratedRentItemsGridConfig.scrollable = true;
  }

  /**
   * @description set migrated rent items
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setMigratedRentItems() {
    this.setMigratedRentItemsGridConfig();
    this.migratedRentItemsGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      this.setRentItemsPaginationEvent(paginationEvent);
      return this.dashboardService.create(paginationEvent, { invoiceType: 'migratedInvoice', page: paginationEvent.page === 0 ? '0' : paginationEvent.page, size: paginationEvent.size, sortField: paginationEvent.sortField, sortOrder: paginationEvent.sortOrder, filters: paginationEvent.filters }, AppUrls.CITY_SALES_CHART + AppUrls.RENT_ITEMS).pipe(map((response) => {
        if (response && response['data']) {
          this.paginationEvent = paginationEvent;
          this.billedItemsPaginationEvent = this.paginationEvent;
          this.migratedItems = response['data']['content'];
          this.migratedHoardings = response['data']['totalElements'];
          // this.billedBarClicked = true;
          this.isSalesBarClicked = true;
          this.isCitySalesBarClicked = true;
          // this.isSalesChartClicked ? this.isSalesBarClicked = true : this.isSalesBarClicked = false;
          // this.isCitySalesChartClicked ? this.isCitySalesBarClicked = true : this.isCitySalesBarClicked = false;
          return response['data']['content'];
        } else {
          this.migratedHoardings = 0;

        }
      }), catchError(error => {
        if (error.error.code === 422) {
          this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
          this.migratedRentItems.gridViewChildRef.resetAndReloadTable();
        } else {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
          return observableEmpty();
        }
      }));
    }

    this.isChartClicked = false;
    this.afterRentTabClicked = false;
    this.afterMigratedTabClicked = false;
    this.afterUnbilledTabClicked = false;
  }

  /**
   * @description set unbilled items grid configuration
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setUnbilledItemsGridConfig() {
    this.unbilledItemsGridConfig.getDefaultModel = () => new UnbilledItems();
    this.unbilledItemsGridConfig.model = UnbilledItems;
    this.unbilledItemsGridConfig.selectionMode = SelectionMode.None;
    this.unbilledItemsGridConfig.editable = true;
    this.unbilledItemsGridConfig.expandableRows = false;
    this.unbilledItemsGridConfig.checkboxSelection = false;
    this.unbilledItemsGridConfig.resizableColumns = false;
    this.unbilledItemsGridConfig.rowExpandMode = 'single';
    this.unbilledItemsGridConfig.shouldAddRowOnDelete = false;
    this.unbilledItemsGridConfig.showLoader = true;
    this.unbilledItemsGridConfig.lazy = true;
    this.unbilledItemsGridConfig.enablePagination = true;
    this.unbilledItemsGridConfig.scrollable = true;
  }

  /**
   * @description set unbilled items
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  setUnbilledItems() {
    this.setUnbilledItemsGridConfig();
    this.unbilledItemsGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
        paginationEvent.sortField = "campaignItem.customId";
      }
      this.setUnbilledItemsPaginationEvent(paginationEvent); // set pagination event
      return this.dashboardService.create(paginationEvent, paginationEvent, AppUrls.CITY_SALES_CHART + AppUrls.UNBILLED_ITEMS).pipe(
        map((response) => {
          if (response && response['data']) {
            this.paginationEvent = paginationEvent;
            this.unbilledItemsPaginationEvent = this.paginationEvent;
            this.unbilledHoardings = response['data']['content'];
            this.totalUnbilledHoardings = response['data']['totalElements'];
            this.isCitySalesBarClicked = true;
            this.isSalesBarClicked = true;
            // this.isSalesChartClicked ? this.isSalesBarClicked = true : this.isSalesBarClicked = false;
            // this.isCitySalesChartClicked ? this.isCitySalesBarClicked = true : this.isCitySalesBarClicked = false;
            return response['data']['content'];
          }
        }), catchError(error => {
          if (error.error.code === 422) {
            this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
            this.unbilledItems.gridViewChildRef.resetAndReloadTable();
          } else {
            this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
            return observableEmpty();
          }
        }));
    }
    this.isChartClicked = false;
    this.afterRentTabClicked = false;
    this.afterMigratedTabClicked = false;
    this.afterUnbilledTabClicked = false;

  }

  /**
   * @description set rent items grid config for city sales chart
   * @author Divya Sachan
   * @date 2020-01-10
   * @memberof SalesChartComponent
   */
  setCityWiseRentItemsGridConfig() {
    this.cityWiseRentItemsGridConfig.getDefaultModel = () => new SalesHoardings();
    this.cityWiseRentItemsGridConfig.model = SalesHoardings;
    this.cityWiseRentItemsGridConfig.selectionMode = SelectionMode.None;
    this.cityWiseRentItemsGridConfig.editable = true;
    this.cityWiseRentItemsGridConfig.expandableRows = false;
    this.cityWiseRentItemsGridConfig.checkboxSelection = false;
    this.cityWiseRentItemsGridConfig.resizableColumns = false;
    this.cityWiseRentItemsGridConfig.rowExpandMode = 'single';
    this.cityWiseRentItemsGridConfig.shouldAddRowOnDelete = false;
    this.cityWiseRentItemsGridConfig.showLoader = true;
    this.cityWiseRentItemsGridConfig.lazy = true;
    this.cityWiseRentItemsGridConfig.enablePagination = true;
    this.cityWiseRentItemsGridConfig.scrollable = true;
  }

  /**
   * @description set city wise rent items
   * @author Divya Sachan
   * @date 2020-01-10
   * @memberof SalesChartComponent
   */
  setCityWiseRentItems() {
    this.setCityWiseRentItemsGridConfig(); // set rent items grid configuration
    this.cityWiseRentItemsGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      this.setRentItemsPaginationEvent(paginationEvent);
      return this.dashboardService.create(paginationEvent, { invoiceType: 'invoice', page: paginationEvent.page === 0 ? '0' : paginationEvent.page, size: paginationEvent.size, sortField: paginationEvent.sortField, sortOrder: paginationEvent.sortOrder, filters: paginationEvent.filters }, AppUrls.CITY_SALES_CHART + AppUrls.RENT_ITEMS).pipe(map((response) => {
        if (response) {
          this.paginationEvent = paginationEvent;
          this.billedItemsPaginationEvent = this.paginationEvent;
          this.soldHoardings = response['data']['content'];
          this.salesChartHoardings = response['data']['totalElements'];
          // this.billedBarClicked = true;
          // this.isSalesChartClicked ? this.isSalesBarClicked = true : this.isSalesBarClicked = false;
          // this.isCitySalesChartClicked ? this.isCitySalesBarClicked = true : this.isCitySalesBarClicked = false;
          this.isSalesBarClicked = true;
          this.isCitySalesBarClicked = true;
          return response['data']['content'];
        }
      }), catchError(error => {
        // this.showErrorNotification(error);
        if (error.error.code === 422) {
          this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
          this.rentItems.gridViewChildRef.resetAndReloadTable();
        } else {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
          return observableEmpty();
        }
      }));
    }
    this.isChartClicked = false;
    this.afterRentTabClicked = false;
    this.afterMigratedTabClicked = false;
    this.afterUnbilledTabClicked = false;
  }

  /**
   * @description set city wise migrated rent items grid configuration
   * @author Divya Sachan
   * @date 2020-01-10
   * @memberof SalesChartComponent
   */
  setCityWiseMigratedRentItemsGridConfig() {
    this.cityWiseMigratedRentItemsGridConfig.getDefaultModel = () => new SalesHoardings();
    this.cityWiseMigratedRentItemsGridConfig.model = SalesHoardings;
    this.cityWiseMigratedRentItemsGridConfig.selectionMode = SelectionMode.None;
    this.cityWiseMigratedRentItemsGridConfig.editable = true;
    this.cityWiseMigratedRentItemsGridConfig.expandableRows = false;
    this.cityWiseMigratedRentItemsGridConfig.checkboxSelection = false;
    this.cityWiseMigratedRentItemsGridConfig.resizableColumns = false;
    this.cityWiseMigratedRentItemsGridConfig.rowExpandMode = 'single';
    this.cityWiseMigratedRentItemsGridConfig.shouldAddRowOnDelete = false;
    this.cityWiseMigratedRentItemsGridConfig.showLoader = true;
    this.cityWiseMigratedRentItemsGridConfig.lazy = true;
    this.cityWiseMigratedRentItemsGridConfig.enablePagination = true;
    this.cityWiseMigratedRentItemsGridConfig.scrollable = true;
  }

  /**
   * @description set city wise migrated rent items
   * @author Divya Sachan
   * @date 2020-01-10
   * @memberof SalesChartComponent
   */
  setCityWiseMigratedRentItems() {
    this.setCityWiseMigratedRentItemsGridConfig();
    this.cityWiseMigratedRentItemsGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      this.setRentItemsPaginationEvent(paginationEvent);
      return this.dashboardService.create(paginationEvent, { invoiceType: 'migratedInvoice', page: paginationEvent.page === 0 ? '0' : paginationEvent.page, size: paginationEvent.size, sortField: paginationEvent.sortField, sortOrder: paginationEvent.sortOrder, filters: paginationEvent.filters }, AppUrls.CITY_SALES_CHART + AppUrls.RENT_ITEMS).pipe(map((response) => {
        if (response && response['data']) {
          this.paginationEvent = paginationEvent;
          this.billedItemsPaginationEvent = this.paginationEvent;
          this.migratedItems = response['data']['content'];
          this.migratedHoardings = response['data']['totalElements'];
          // this.billedBarClicked = true;
          this.isSalesBarClicked = true;
          this.isCitySalesBarClicked = true;
          // this.isSalesChartClicked ? this.isSalesBarClicked = true : this.isSalesBarClicked = false;
          // this.isCitySalesChartClicked ? this.isCitySalesBarClicked = true : this.isCitySalesBarClicked = false;
          return response['data']['content'];
        } else {
          this.migratedHoardings = 0;

        }
      }), catchError(error => {
        if (error.error.code === 422) {
          this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
          this.migratedRentItems.gridViewChildRef.resetAndReloadTable();
        } else {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
          return observableEmpty();
        }
      }));
    }

    this.isChartClicked = false;
    this.afterRentTabClicked = false;
    this.afterMigratedTabClicked = false;
    this.afterUnbilledTabClicked = false;
  }

  /**
   * @description set city wise unbilled items grid configuration
   * @author Divya Sachan
   * @date 2020-01-10
   * @memberof SalesChartComponent
   */
  setCityWiseUnbilledItemsGridConfig() {
    this.cityWiseUnbilledItemsGridConfig.getDefaultModel = () => new UnbilledItems();
    this.cityWiseUnbilledItemsGridConfig.model = UnbilledItems;
    this.cityWiseUnbilledItemsGridConfig.selectionMode = SelectionMode.None;
    this.cityWiseUnbilledItemsGridConfig.editable = true;
    this.cityWiseUnbilledItemsGridConfig.expandableRows = false;
    this.cityWiseUnbilledItemsGridConfig.checkboxSelection = false;
    this.cityWiseUnbilledItemsGridConfig.resizableColumns = false;
    this.cityWiseUnbilledItemsGridConfig.rowExpandMode = 'single';
    this.cityWiseUnbilledItemsGridConfig.shouldAddRowOnDelete = false;
    this.cityWiseUnbilledItemsGridConfig.showLoader = true;
    this.cityWiseUnbilledItemsGridConfig.lazy = true;
    this.cityWiseUnbilledItemsGridConfig.enablePagination = true;
    this.cityWiseUnbilledItemsGridConfig.scrollable = true;
  }

  /**
   * @description set city wise unbilled items
   * @author Divya Sachan
   * @date 2020-01-10
   * @memberof SalesChartComponent
   */
  setCityWiseUnbilledItems() {
    this.setCityWiseUnbilledItemsGridConfig();
    this.cityWiseUnbilledItemsGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
        paginationEvent.sortField = "campaignItem.customId";
      }
      this.setUnbilledItemsPaginationEvent(paginationEvent); // set pagination event
      return this.dashboardService.create(paginationEvent, paginationEvent, AppUrls.CITY_SALES_CHART + AppUrls.UNBILLED_ITEMS).pipe(
        map((response) => {
          if (response && response['data']) {
            this.paginationEvent = paginationEvent;
            this.unbilledItemsPaginationEvent = this.paginationEvent;
            this.unbilledHoardings = response['data']['content'];
            this.totalUnbilledHoardings = response['data']['totalElements'];
            this.isCitySalesBarClicked = true;
            this.isSalesBarClicked = true;
            // this.isSalesChartClicked ? this.isSalesBarClicked = true : this.isSalesBarClicked = false;
            // this.isCitySalesChartClicked ? this.isCitySalesBarClicked = true : this.isCitySalesBarClicked = false;
            return response['data']['content'];
          }
        }), catchError(error => {
          if (error.error.code === 422) {
            this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
            this.unbilledItems.gridViewChildRef.resetAndReloadTable();
          } else {
            this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
            return observableEmpty();
          }
        }));
    }
    this.isChartClicked = false;
    this.afterRentTabClicked = false;
    this.afterMigratedTabClicked = false;
    this.afterUnbilledTabClicked = false;

  }

  /**
   * @description set rent items pagination event
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} paginationEvent
   * @memberof SalesChartComponent
   */
  setRentItemsPaginationEvent(paginationEvent: GridPaginationEvent) {
    if (paginationEvent) {

    } else {
      paginationEvent = this.paginationData;
    }
    if (this.isCitySalesChartClicked) {
      Object.assign(paginationEvent.filters, {
        "rentItems.city": { value: this.citySalesData[0], matchMode: "is" },
      });
    } else {
      Object.assign(paginationEvent.filters, {
        "rentItems.city": { value: 'ALL', matchMode: "is" },
      });
    }

    Object.assign(paginationEvent.filters, {
      "billStartDate": { value: new Date(this.startDate), matchMode: "after" },
      "billEndDate": { value: new Date(this.endDate), matchMode: "before" },
      "campaign.employee.emailId": { value: this.email, matchMode: "is" },
    });

    if (this.globalFilterValue || this.globalFilterValue === '') {
      Object.assign(paginationEvent, this.setGlobalFilterInPagination(paginationEvent));
    }
  }

  /**
   * @description set unbilled items pagination event
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} paginationEvent
   * @memberof SalesChartComponent
   */
  setUnbilledItemsPaginationEvent(paginationEvent: GridPaginationEvent) {
    if (paginationEvent) {

    } else {
      paginationEvent = this.paginationData;
    }
    if (this.isCitySalesChartClicked) {
      Object.assign(paginationEvent.filters, {
        "campaignItem.city": { value: this.citySalesData[0], matchMode: "is" },
      });
    } else {
      Object.assign(paginationEvent.filters, {
        "campaignItem.city": { value: 'ALL', matchMode: "is" },
      });
    }

    Object.assign(paginationEvent.filters, {
      "serviceDate": { value: [this.startDate, this.endDate], matchMode: "dateBetween" },
      "campaign.employee.emailId": { value: this.email, matchMode: "is" },
      "billed": { value: false, matchMode: "is" },
      "eligibleToGenBill": { value: true, matchMode: "is" },
      "category": { value: "RENT", matchMode: "is" },
    });

    if (this.globalFilterValue || this.globalFilterValue === '') {
      Object.assign(paginationEvent, this.setGlobalFilterInPagination(paginationEvent));
    }
  }

  // getBilledItemSummary() {
  //   this.city = [];
  //   if (this.isCityBilledBarClicked && this.isCitySalesChartClicked) {
  //     this.city = [{ id: '', name: this.citySalesData[0] }];
  //   }

  //   this.billingSearch = {
  //     itemStartDate: new Date(this.startDate),
  //     itemEndDate: new Date(this.endDate),
  //     userEmailId: this.email,
  //     cities: this.city,
  //   }
  //   this.dashboardService.create(this.billingSearch, this.billingSearch, AppUrls.SALES_CHART + AppUrls.GET_BILLED_ITEM_SUMMARY).subscribe((response) => {
  //     this.billingSearch = new BillingSearch();
  //     if (response && response['data']) {
  //       this.rentAmount = this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(response['data']['rentItems']));
  //       this.printingAmount = this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(response['data']['printingItems']));
  //       this.mountingAmount = this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(response['data']['mountingItems']));
  //     }
  //   })
  // }

  /**
   * @description parse value to two decimal places
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} value
   * @returns
   * @memberof SalesChartComponent
   */
  parseValueToDecimal(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  /**
   * @description Refresh Stacked Bar Chart (City Sales chart)
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} height
   * @param {*} width
   * @memberof SalesChartComponent
   */
  refreshCitySalesChart(height, width) {
    this.citySalesStackedBarChartConfig.height = height;
    this.citySalesStackedBarChartConfig.width = width;
    this.sibChartRef.cshowStackedBarChart(this.citySalesStackedBarChartConfig);
  }

  /**
   * @description Create Loader Object for billed and unbilled Items
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} fileType
   * @param {*} object
   * @memberof SalesChartComponent
   */
  createLoaderObject(fileType, object) {
    object.isLoading = true;
    object.text = 'Downloading XLS...';
    object.fileType = fileType;
    object.searchEvent = this.paginationEvent;
    if (this.isSalesChartClicked || this.isCitySalesChartClicked) {
      if (this.activeIndex === 0) {
        this.invoiceType = 'invoice';
        object.billed = true;
      } else if (this.activeIndex === 1) {
        this.invoiceType = 'migratedInvoice';
        object.billed = true;
      } else if (this.activeIndex === 2) {
        this.invoiceType = undefined;
        object.billed = false;
      }
      object.invoiceType = this.invoiceType;
    }
  }

  /**
   * @description sales Xls export
   * @author Divya Sachan
   * @date 2019-10-07
   * @memberof SalesChartComponent
   */
  exportXls() {
    let totalElements = 0;
    switch (this.activeIndex) {
      case 0: {
        totalElements = this.salesChartHoardings;
        break;
      }
      case 1: {
        totalElements = this.migratedHoardings;
        break;
      }
      case 2: {
        totalElements = this.totalUnbilledHoardings;
        break;
      }
    }
    if (totalElements && totalElements > 0) {
      this.isFileDownloading = true;
      this.createLoaderObject("XLSX", this.xlsLoader)
      this.loaderSubjects.salesChartXlsLoader.next(this.xlsLoader);
      this.isFileDownloading = false;
    } else {
      this.notificationServcie.info('No Items Present', 'Cannot Download XLSX');
    }

  }

  /**
   * @description get height of the column chart
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} obj
   * @param {number} val
   * @returns
   * @memberof SalesChartComponent
   */
  getHeight(obj, val: number) {
    return (obj.nativeElement.clientHeight - val);
  }

  /**
   * @description get width of the column chart
   * @author Divya Sachan
   * @date 2019-10-07
   * @param {*} obj
   * @param {number} val
   * @returns
   * @memberof SalesChartComponent
   */
  getWidth(obj, val: number) {
    return (obj.nativeElement.clientWidth - val);
  }

  /**
   * @description show notification on error
   * @author Divya Sachan
   * @date 2019-10-10
   * @param {*} error
   * @returns
   * @memberof SalesChartComponent
   */
  showErrorNotification(error) {
    switch (error.error.code) {
      case 422: {
        this.notificationServcie.info('Only Numbers can be filtered in the requested Column Filter', 'Filter');
        this.rentItems.gridViewChildRef.resetAndReloadTable();
        break;
      }
      default: {
        this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
        return observableEmpty();
      }
    }
  }

  /**
   * @description get Total Item count
   * All the grids are not loaded at once so this gives the total count on each tab
   * @author Divya Sachan
   * @date 2019-10-10
   * @memberof SalesChartComponent
   */
  getItemCount() {
    this.billedItemsPaginationEvent = _.cloneDeep(this.itemsPaginationEvent);
    this.unbilledItemsPaginationEvent = _.cloneDeep(this.itemsPaginationEvent);

    this.setRentItemsPaginationEvent(this.billedItemsPaginationEvent);
    this.setUnbilledItemsPaginationEvent(this.unbilledItemsPaginationEvent);

    this.searchEvent = { "unbilledItems": this.unbilledItemsPaginationEvent, "billedItems": this.billedItemsPaginationEvent };
    this.salesChartService.getItemCount(this.searchEvent).subscribe((response) => {
      if (response) {
        this.salesChartHoardings = response['data'].invoice;
        this.migratedHoardings = response['data'].migratedInvoice;
        this.totalUnbilledHoardings = response['data'].unbilledItems;
      }
    }, catchError(error => {
      this.notificationServcie.error(error.error.message ? error.error.message : 'Some technical issue', 'Error!!');
      return observableEmpty();
    }));
  }

  /**
   * @description get Comma separated values from globalFilterFields array
   * @author Divya Sachan
   * @date 2019-10-10
   * @returns
   * @memberof SalesChartComponent
   */
  getCommaSeparatedFields() {
    var globalFilterFields: any = [];
    if (this.activeIndex === 0 || this.activeIndex === 1) {
      globalFilterFields = this.rentGlobalFilterFields;
    } else if (this.activeIndex === 2) {
      globalFilterFields = this.unbilledGlobalFilterFields;
    }
    return utils.getCommaSeparatedFields(globalFilterFields);
  }

  globalFiltering(data) {
    this.globalSearchData.next(data);
  }

  setGlobalFilterValue(value) {
    this.globalFilterValue = value;
  }

  setGlobalFilterInPagination(paginationEvent) {
    paginationEvent.page = this.globalFilterValue ? 0 : paginationEvent.page;
    paginationEvent.globalFilter = this.globalFilterValue ? this.globalFilterValue : '';
    return paginationEvent;
  }

  /**
   * @description reset Grid Filters
   * @author Divya Sachan
   * @date 2019-10-10
   * @memberof SalesChartComponent
   */
  resetGridFilters() {
    if (this.activeIndex === 0) {
      this.rentItems.gridViewChildRef.resetAndReloadTable();
    } else if (this.activeIndex === 1) {
      this.migratedRentItems.gridViewChildRef.resetAndReloadTable();
    } else if (this.activeIndex === 2) {
      this.unbilledItems.gridViewChildRef.resetAndReloadTable();
    }
    // this.rentItems.resetDataTable();
    // this.migratedRentItems.resetDataTable();
    // this.unbilledItems.resetDataTable();
  }

  /**
   * @description refresh Grid
   * @author Divya Sachan
   * @date 2019-10-10
   * @memberof SalesChartComponent
   */
  refreshGrid() {
    if (this.activeIndex === 0 && this.rentItems && this.rentItems.gridViewChildRef) {
      // setTimeout(() => {
      this.rentItems.gridViewChildRef.refresh();
      // }, 500);
    } else if (this.activeIndex === 1 && this.migratedRentItems && this.migratedRentItems.gridViewChildRef) {
      // setTimeout(() => {
      this.migratedRentItems.gridViewChildRef.refresh();
      // }, 500);
    } else if (this.activeIndex === 2 && this.unbilledItems && this.unbilledItems.gridViewChildRef) {
      // setTimeout(() => {
      this.unbilledItems.gridViewChildRef.refresh();
      // }, 500);
    }
  }

  resetGlobalFilter() {
    this.globalFiltering(null);
    this.globalFilterValue = '';
    this.globalFilter.nativeElement.value = '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.superbillChartChildRef.refreshChart(this.getHeight(this.superbillCardRef, 0), this.getWidth(this.superbillCardRef, 60));
    // this.refreshCitySalesChart(this.getHeight(this.citySalesChartRef, 0), this.getWidth(this.citySalesChartRef, 100));
  }
}

export enum color {
  DARK_BLUE = '#3366cc',
  // DARK_BLUE_1 = '#005580',
  // LIGHT_BLUE_1 = '#0099e6',
  LIGHT_BLUE = '#7094db',
  DARK_RED = '#dc3912',
  LIGHT_RED = '#f2785a',
  LIGHT_RED_1 = "#f7b2a1",
  BLACK = '#222222',
  TRANSPARENT = 'transparent',
  LIGHT_GREEN = "#79d279",
}