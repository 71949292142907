
import { map, catchError } from 'rxjs/operators';
/* 
* @file Document service.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/


import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { BASE_URL, ApiService } from "../../../../services/base-service/api.service";
import { NotificatoinsService } from "../../../../services/notifications/notifications.service";
import { AppUrls } from '../../../../services/urls';

@Injectable()
export class DocumentService extends ApiService<any, any, any> {
    // urlPath: string = 'plans?projection=planProjection';
    // urlPath: string = 'documents/search';
    // urlPath: string = '';
    urlPath: string = "campaigns";
    baseUrl: string;


    constructor(private Http: HttpClient,
        private Injector: Injector,
        private notificationServcie: NotificatoinsService, ) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getDocuments() {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'campaigns/images/purchaseOrders').pipe(map((response) => {
                return response;
            }));
        }

    }

    deleteDocument(campaignId, name) {
        if (navigator.onLine) {
            return this.Http.delete(this.baseUrl + 'campaigns/removePO/' + campaignId, { params: { 'poName': name } }).pipe(map((response: any) => {
                this.notificationServcie.success('PO Deleted Successfully', 'PO Deleted');
                return response;
            }), catchError((error: any) => {
                this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                return error;
            }));
        }

        // return this.Http.delete(this.baseUrl + 'campaigns/removePO/' + campaignId, { params: { 'poName': name } });
    }

    savePurchaseOrder(campaignId, po) {
        if (navigator.onLine) {
            return this.Http.patch(this.baseUrl + 'campaigns/editPO/' + campaignId, po).pipe(map((response: any) => {
                console.log("response is", response);
                this.notificationServcie.success('PO Edited Successfully', 'PO Editing');
                return response;
            }), catchError((error) => {
                this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                return error;
            }));
        }

    }

    savePlanPurchaseOrder(planId, po) {
        if (navigator.onLine) {
            return this.Http.patch(this.baseUrl + 'plans/editPO/' + planId, po).pipe(map((response: any) => {
                console.log("response is", response);
                this.notificationServcie.success('PO Edited Successfully', 'PO Editing');
                return response;
            }), catchError((error) => {
                this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                return error;
            }));
        }

    }

    deletePlanDocument(planId, name) {
        if (navigator.onLine) {
            return this.Http.delete(this.baseUrl + 'plans/removePO/' + planId, { params: { 'poName': name } }).pipe(map((response: any) => {
                this.notificationServcie.success('PO Deleted Successfully', 'PO Deleted');
                return response;
            }), catchError((error: any) => {
                this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                return error;
            }));
        }

    }

    deleteReserveInvoiceDocument(rowDataId, documentId) {
        if (navigator.onLine) {
            return this.Http.delete(this.baseUrl + AppUrls.INVOICES + '/' + AppUrls.RESERVE + AppUrls.REMOVE_DOCUMENT + '/' + rowDataId, { params: { 'documentId': documentId } }).pipe(map((response: any) => {
                this.notificationServcie.success('File Deleted Successfully', 'File Deleted');
                return response;
            }), catchError((error: any) => {
                this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                return error;
            }));
        }

    }

    deleteReserveCNDocument(rowDataId, documentId) {
        if (navigator.onLine) {
            return this.Http.delete(this.baseUrl + AppUrls.CREDIT_NOTES + '/' + AppUrls.RESERVE + AppUrls.REMOVE_DOCUMENT + '/' + rowDataId, { params: { 'documentId': documentId } }).pipe(map((response: any) => {
                this.notificationServcie.success('File Deleted Successfully', 'File Deleted');
                return response;
            }), catchError((error: any) => {
                this.notificationServcie.error(error.error ? error.error.message : 'Some technical Error', 'Error');
                return error;
            }));
        }

    }
}
