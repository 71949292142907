import { SibBaseObject } from '../BaseObject/SibBaseObject';
import { UserWrapper } from '../user/user-wrapper';
import { User } from '../campaigns/user';
import { Customer } from '../KYC/customer';

export class AbstractReservedMetadata extends SibBaseObject {
    invoiceId: string;
    utilized: boolean;
    customer: Customer = new Customer();
    reservedBy: UserWrapper = new UserWrapper();
    utilizedBy: UserWrapper = new UserWrapper();
    reservedDate: Date;
    utilizedDate: Date;
    employee: User = new User();
}
