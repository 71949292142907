
import { map } from 'rxjs/operators';
import { Http, Response } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import 'rxjs/Rx';
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { Observable } from "rxjs";
import { Group } from "../../modals/group/group";

@Injectable()

export class GroupService extends ApiService<any, any, any> {
    urlPath: string = 'groups';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getGroups() {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'groups');
        }
    }

    createGroup(group) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'groups/save', group).pipe(
                map((res: Response) => res));
        }

    }

    updateGroup(group) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'groups/update', group).pipe(
                map((res: Response) => res));
        }

    }

    findCustomersInGroup(groupId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'groups/find/customers?groupId=' + groupId).pipe(
                map((res: Response) => res));
        }

    }

}
