import { Injectable } from "@angular/core";
import { GoogleChartsBaseService } from "./chart.base.service";
import { ColumnChartConfig } from "../../modals/charts/columnChart.config";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { SIBConstants } from '../../shared/constants/SIBConstant';

declare var google: any;

@Injectable()
export class GoogleColumnChartService extends GoogleChartsBaseService {

    chartFunc: any;
    // super();
    constructor() {
        super();
    }

    public buildColumnChart(elementId: String, data: any[], config: ColumnChartConfig): void {
        if (elementId === SIBConstants.SALES_CHART_ID) {
            this.chartFunc = () => new google.charts.Bar(document.getElementById(elementId + ''));
        } else {
            this.chartFunc = () => new google.visualization.ColumnChart(document.getElementById(elementId + ''));
        }
        const options = {
            title: config.title,
            hAxis: config.hAxis,
            // vAxis: config.vAxis,
            height: config.height,
            width: config.width,
            axisTitlesPosition: config.axisTitlesPosition,
            // chartArea: config.chartArea,
            legend: config.legend,
            // chartArea: { left: 150 }
            vAxis: config.vAxis,
            isStacked: config.isStacked,
            series: config.series,
            annotations: config.annotations,
            bars: config.bars,
            tooltip: config.tooltip,
            vAxes: config.vAxes,
            titleTextStyle: config.titleTextStyle,
        };
        this.buildChart(data, this.chartFunc, options, config);

    }
}