/**
* @file  Event
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { SibBaseObject } from "../BaseObject/SibBaseObject";

export class Event extends SibBaseObject {
    name: string;
    startDate: Date;
    endDate: Date;
    city: string;
    venue: string;
    time: Date;
    venueName: string;
    venueAdd: string;
}