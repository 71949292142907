import { SibBaseObject } from '../BaseObject/SibBaseObject';
import { ProductEnum } from '../../../app/shared/constants/product-enum';
import { DeviceCategoryEnum } from '../../../app/shared/constants/device-category.enum';

export class HelpSupport extends SibBaseObject {
    product: ProductEnum;
    deviceCategory: DeviceCategoryEnum;
    module: string;
    description: string;
    title: string;

}