import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import * as utils from '../../../helpers/utils';
import { InvoiceFormatEnum } from '../../constants/invoice-format-enum';
import { CustomerUiService } from '../../../customers/services/customer-ui.service';
import { PrintConfig } from '../../../modals/billings/print-config';
import { print } from 'util';

/**
 * @description for print pannel
 * @author Pulkit Bansal
 * @date 2019-10-19
 * @export
 * @class PrintPannelLayout
 */
@Component({
    selector: 'sib-print-pannel-layout',
    templateUrl: './print-pannel-layout.component.html',
    styleUrls: ['./print-pannel-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PrintPannelLayoutComponent implements OnInit {

    @Input() showSave = true;
    @Input() showPreview = true;
    @Input() showPrint = true;
    @Input() showInvoiceFormat = true;
    @Input() disableInvoiceFormatDropdown = false;

    _printConfig: PrintConfig = new PrintConfig();
    @Input() set printConfig(printConfig) {
        if (printConfig) {
            if (printConfig.original !== undefined && printConfig.original !== null) {
                this._printConfig.original = printConfig.original;
            } else {
                this._printConfig.original = false;
            }
            if (printConfig.withLetterHead !== undefined && printConfig.withLetterHead !== null) {
                this._printConfig.withLetterHead = printConfig.withLetterHead;
            } else {
                this._printConfig.withLetterHead = false;
            }
        } else {
            this._printConfig.original = false;
            this._printConfig.withLetterHead = false;
        }
        // this._printConfig = printConfig;
    }

    get printConfig() {
        return this._printConfig;
    }

    _selectedInvoiceFormat: any;
    @Input() set selectedInvoiceFormat(selectedInvoiceFormat) {
        this._selectedInvoiceFormat = selectedInvoiceFormat;
    }

    get selectedInvoiceFormat() {
        return this._selectedInvoiceFormat;
    }

    @Output() eEmitSelectedInvoiceFormat: EventEmitter<any> = new EventEmitter();

    @Output() eEmitSavePrintInstructions: EventEmitter<any> = new EventEmitter();

    @Output() eEmitPreviewPrintItems: EventEmitter<any> = new EventEmitter();

    @Output() eEmitPrintItems: EventEmitter<any> = new EventEmitter();

    @Output() eEmitChangedPrintType: EventEmitter<any> = new EventEmitter();

    @Output() eEmitChangedPrintLetterHead: EventEmitter<any> = new EventEmitter();

    invoiceFormats: SelectItem[] = [];
    userRole: string;

    constructor(
        private customerUiService: CustomerUiService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.setUserRole();
        this.setInvoiceFormatDropdown();
    }

    setUserRole() {
        this.userRole = this.customerUiService.getUserRole();
    }

    setInvoiceFormatDropdown() {
        this.invoiceFormats = utils.createDropdown(InvoiceFormatEnum, false);
    }

    onInvoiceFormatChange() {
        this.eEmitSelectedInvoiceFormat.emit(this.selectedInvoiceFormat);
    }

    onTypeChange(event) {
        this.printConfig.original = event.checked;
        this.printConfig = JSON.parse(JSON.stringify(this.printConfig));
        this.eEmitChangedPrintType.emit(event.checked);
        this.detectChanges();
    }

    onLetterHeadChange(event) {
        this.printConfig.withLetterHead = event.checked;
        this.printConfig = JSON.parse(JSON.stringify(this.printConfig));
        this.eEmitChangedPrintLetterHead.emit(event.checked);
        this.detectChanges();
    }

    savePrintingInstructions() {
        this.eEmitSavePrintInstructions.emit(this.printConfig);
    }

    previewItems() {
        this.eEmitPreviewPrintItems.emit(this.printConfig);
    }

    printItems() {
        this.eEmitPrintItems.emit(this.printConfig);
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

}
