<section class="rowsRearrange">
    <section class="table-header">
        <section class="header-columns" *ngFor="let col of columns" [style.width]=col.width>
            {{col?.header}}
        </section>
    </section>
    <section class="order-list">
        <p-orderList [value]="rows" [responsive]="true" dragdrop="true" [styleClass]="'reorder'">
            <ng-template let-row let-i="index" pTemplate="item">
                <ng-container *ngFor="let col of columns">
                    <span *ngIf="col?.field === 'srNo'" [style.width]=col.width class="inline-block">
                        {{i + 1}}
                    </span>
                    <span *ngIf="col?.field !== 'srNo'" [style.width]=col.width class="inline-block">
                        <ng-container
                            *ngTemplateOutlet="orderListTemplate ; context: { key: col?.field, value: getValueForACol(row, col?.field) }">
                        </ng-container>
                    </span>
                </ng-container>
            </ng-template>
        </p-orderList>
    </section>
    <section class="action-buttons">
        <button class="mtab-save area-d-save margin-hori-5" pButton (click)="saveRowsConfig()" label=" SAVE "></button>
        <button class="mtab-secondary" pButton (click)="cancelRowsConfig()" label="CANCEL"></button>
    </section>

    <ng-template #orderListTemplate let-key='key' let-value='value'>
        <section [innerHtml]='value' class="inline-block">
        </section>
    </ng-template>
</section>