export abstract class SibBaseObject {
    id: string;
    // createdDate: any = undefined;
    createdDate: Date;
    createdBy: string;
    // updatedDate: any = undefined;
    updatedDate: Date;
    updatedBy: string;
    isActive: boolean;
    note: string;
}
