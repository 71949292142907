
import { map } from 'rxjs/operators';
/* 
* @file AddMonitoring service.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/


import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { isEqual, differenceWith } from 'lodash';


@Injectable()
export class BillingGridService extends ApiService<any, any, any> {
    urlPath: string = 'salesJournals';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getInvoices(payload): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/search/byCampaignId?campaignId=' + payload).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    getMigratedInvoices(payload): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'migrated/invoices/search/byCampaignId=' + payload).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    getCustomerGroups(groupId): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'customers/search/byGroup?groupId=' + groupId).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    generateBill(payload): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generate', payload).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    generateMigratedBill(payload): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'migrated/invoices/generate', payload).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    getChanges(orginalCollection: any, changedCollection: any): any {
        return differenceWith(changedCollection, orginalCollection, isEqual);
    }

    // editRequest(invoice): any {
    //     return this.Http.post(this.baseUrl + 'invoices/edit/request', invoice).map((response: Response) => {
    //         return response;
    //     });
    // }

    cancelRequest(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/cancel/request', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }


    // cancelMigratedRequest(invoice): any {
    //     return this.Http.get
    // }

    approveRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/account/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/account/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    approveRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    // rejectEditRequestAccountant(invoice): any {
    //     return this.Http.post(this.baseUrl + 'invoices/edit/reject', invoice).map((response: Response) => {
    //         return response;
    //     });
    // }

    // rejectCancelRequestAccountant(invoice): any {
    //     return this.Http.post(this.baseUrl + 'invoices/cancel/reject', invoice).map((response: Response) => {
    //         return response;
    //     });
    // }

    // approveEditRequestAccountant(invoice): any {
    //     return this.Http.post(this.baseUrl + 'invoices/edit/approve', invoice).map((response: Response) => {
    //         return response;
    //     });
    // }

    // approveCancelRequestAccountant(invoice): any {
    //     return this.Http.post(this.baseUrl + 'invoices/cancel/approve', invoice).map((response: Response) => {
    //         return response;
    //     });
    // }

    // getTempInvoice(id): any {
    //     return this.Http.get(this.baseUrl + 'tempInvoices/' + id + '?projection=tempInvoiceProjection').pipe(map((response: Response) => {
    //         return response;
    //     }));
    // }

    getTempInvoice(id, projection): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'tempInvoices/byId', { params: { 'id': id, 'projection': projection } }).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    submitBill(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/update', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    submitMigratedBill(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'migrated/invoices/update', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    // ============================================================================================================

    editRequest(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/edit/request', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    editDiscardRequest(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/edit/discard', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    // editMigratedRequest(invoice): any {
    //     return this.Http.post(this.baseUrl + 'migrated/invoices/edit/request', invoice).pipe(map((response: Response) => {
    //         return response;
    //     }));
    // }

    approveGenerateRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generate/admin/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    approveGenerateRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generate/account/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    approveEditRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/edit/admin/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    approveEditRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/edit/account/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    approveCancelRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/cancel/admin/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    approveCancelRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/cancel/account/approve', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectGenerateRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generate/admin/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectGenerateRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/generate/account/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectEditRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/edit/admin/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectEditRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/edit/account/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectCancelRequestAdmin(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/cancel/admin/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    rejectCancelRequestAccountant(invoice): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'invoices/cancel/account/reject', invoice).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

    getInvoiceTaskCards(): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'invoices/task-cards').pipe(map((response: Response) => {
                return response;
            }));
        }

    }

}


