/**
* @file  monitoringItem
* @author pulkitb@meditab.com
* @copyright Meditab Software 2017
*/

import { User } from "./user";
import { Picture } from "../hoardings/picture";

export class MonitoringItem {
    id: string;
    monitoringDate: Date;
    mode: string;
    images: Picture[] = [];
    photographer: User = new User();
    status: string = "PENDING_MONITORING";
}

