import { MultiMediaType } from "./multiMediaType";
import { MultiMediaTypeEnum } from "../../shared/constants/multi-media-type-enum";

export class MultiMedia {
    id: string;
    name: string;
    url: string | ArrayBuffer;
    folder: string;
    extension: string;
    featuredMedia = false;
    multiMediaType: MultiMediaTypeEnum;
    thumbUrl: String;
}


// imageObject: { source: any, title: string, width?: string, height?: string, featuredMedia?: boolean, multiMediaType: string, imageType?: any }[] = [];