import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ReservedInvoiceMetadata } from '../../../modals/billings/reservedInvoiceMetadata';
import { SelectItem, SelectItemGroup, Dropdown } from 'primeng/primeng';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { CreateCustomerService } from '../../../customers/services/createCustomerService';
import { AppUrls } from '../../../services/urls';
import * as utils from '../../../helpers/utils';
import { InvoiceGstDetail } from '../../../modals/billings/invoice-gst-detail';
import { Customer } from '../../../modals/KYC/customer';
import { UserService } from '../../../services/shared/user.service';
import * as _ from "lodash";
import { User } from '../../../modals/campaigns/user';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../../services/shared/company.service';

@Component({
    selector: 'sib-reserve-invoice-detail-dialog',
    templateUrl: './reserve-invoice-detail-dialog.component.html',
    styleUrls: ['./reserve-invoice-detail-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReserveInvoiceDetailDialogComponent implements OnInit, OnDestroy {

    @ViewChild("groupedDropdown") groupedDropdown: Dropdown;

    @Input() displayReserveInvoiceDetailDialog = false;

    _rowData: ReservedInvoiceMetadata = new ReservedInvoiceMetadata(); // to store the row marked/unmarked as utilized
    @Input() set rowData(rowData) {
        if (rowData && rowData.billGeneratedDate) {
            rowData.billGeneratedDate = new Date(rowData.billGeneratedDate);
        }
        this._rowData = rowData;
    }

    get rowData() {
        return this._rowData;
    }

    @Output() eEmitDisplayReset: EventEmitter<boolean> = new EventEmitter();

    @Output() eEmitsaveInvoiceDetails: EventEmitter<any> = new EventEmitter();

    @Output() eEmitUnutilizeInvoice: EventEmitter<any> = new EventEmitter();
    subscription: Subscription[] = [];
    isLoading = false;
    companies: SelectItem[] = [];
    selectedCompany: any = null;
    selectedBranch: any = null;
    branchDropdown = false;
    branches: SelectItemGroup[] = [];
    salesReps: SelectItem[] = [];
    selectedSR: any = null;
    amount = 0;
    decimalNumbers: RegExp = /^(\d*\.)?\d+$/;  // for decimal number fields
    twoPlaceDecimalNumbers: RegExp = /^(?:\d*\.\d{1,2}|\d+)$/; // for decimal number fields upto 2 places

    constructor(
        private notificationServcie: NotificatoinsService,
        private createCustomerService: CreateCustomerService,
        private userService: UserService,
        private companyService: CompanyService,
    ) { }

    ngOnInit() { }

    showInvoiceDetailsDialog() {
        this.isLoading = true;
        this.getAllSalesReps();
        if (this.rowData.utilized) {
            //do nothing  
        }
        else {
            this.getAllCompanies();
        }
    }

    getAllCompanies() {
        this.isLoading = true;
        this.companies = [];
        this.selectedCompany = null;
        this.createCustomerService.create(null, null, AppUrls.FIND_ALL).subscribe(
            (response) => {
                if (response) {
                    response.forEach((res) => {
                        this.companies.push({ label: res.company, value: res });
                    });
                    // if (this.rowData.utilized) {
                    //     this.setSelectedCompany();
                    // }
                    this.isLoading = false;
                }
            },
            (error) => {
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    getAllSalesReps() {
        this.isLoading = true;
        this.salesReps = [];
        this.selectedSR = null;
        this.userService.get({ role: 'SALES_REPRESENTATIVE' }, AppUrls.SEARCH + AppUrls.BY_ROLE).subscribe(
            (response) => {
                if (response) {
                    const resp = response["_embedded"]["users"];
                    resp.forEach((res) => {
                        this.salesReps.push({ label: res.fullName, value: res });
                    });
                    if (this.rowData.utilized) {
                        this.setSelectedSR();
                    } else {
                        this.isLoading = false;
                    }
                }
            },
            (error) => {
                this.showErrorNotification(error);
                this.isLoading = false;
            }
        );
    }

    setSelectedCompany() {
        this.companies.filter((company) => {
            if (company.value.id === this.rowData.customer.id) { // filters and sets the customer 
                this.selectedCompany = company.value;
            }
        });
        if (this.selectedCompany) {
            this.setBranches();
        } else {
            this.isLoading = false;
        }
    }

    setBranches() {
        this.branches = [];
        this.selectedBranch = null;
        this.groupedDropdown.value = '';
        if (this.selectedCompany !== undefined && this.selectedCompany !== null && this.selectedCompany.gstDetails !== undefined && this.selectedCompany.gstDetails !== null) {
            this.selectedCompany.gstDetails.forEach((gst) => {
                const items: SelectItem[] = [];
                gst.branchAddress.forEach((add) => {
                    const value = { address: add, gst: gst };
                    items.push({ label: add.branchName + '-' + add.city.name, value: value });
                });
                this.branches.push({ label: gst.gstNo, value: gst, items: items });
            });
            if (this.branches.length > 0) {
                this.branchDropdown = true;
            } else {
                this.branchDropdown = false;
            }
            if (this.rowData.utilized && this.branches.length > 0) {
                this.setSelectedBranch();
            } else {
                this.isLoading = false;
            }
        } else {
            this.isLoading = false;
        }
    }

    setSelectedBranch() {
        this.branches.forEach((branch) => {
            branch.items.forEach((item) => {
                if (this.checkEquality(item.value, this.rowData.invoiceGstDetail)) {
                    this.selectedBranch = item.value;
                    this.groupedDropdown.value = item.value;
                }
            });
        });
        this.isLoading = false;
    }

    checkEquality(item, gstDetail) {
        let result = false;
        if (item.gst.gstNo === gstDetail.gstNo && item.gst.panNo === gstDetail.panNo &&
            item.address.address1 === gstDetail.branchAddress.address1 &&
            item.address.branchName === gstDetail.branchAddress.branchName &&
            item.address.postalCode === gstDetail.branchAddress.postalCode) {
            result = true;
        }
        return result;
    }

    onBranchChange(event) {
        this.selectedBranch = event.value;
    }

    validate(event, pattern) {
        const result = pattern.test(event.target.value);
        if (result) {
            const twoPlaceResult = this.twoPlaceDecimalNumbers.test(event.target.value); // to test for 2 decimal places
            if (twoPlaceResult) {
                this.rowData.totalAmount = utils.parseValueToDecimalTwoPlaces(this.rowData.totalAmount);
            } else {
                event.target.value = utils.parseValueToDecimalTwoPlaces(event.target.value);
                this.validate(event, pattern);
            }
        } else {
            // do nothing
        }
    }

    createBranchItemObject(branch) {
        const branchClone = _.cloneDeep(branch); // to prevent the actual branch in dropdown from getting changed
        let index;
        branchClone.gst.branchAddress.filter((add, ind) => {
            if (JSON.stringify(branchClone.address) === JSON.stringify(branchClone.gst.branchAddress[ind])) {
                index = ind;
            }
        });
        if (index !== undefined) {
            branchClone.gst.branchAddress = branchClone.gst.branchAddress.filter((add, ind) => {
                if (ind === index) {
                    return add;
                }
            });
            return branchClone.gst;
        }
    }

    setSelectedSR() {
        this.salesReps.filter((sr) => {
            if (sr.value.emailId === this.rowData.employee.emailId) {
                this.selectedSR = sr.value;
            }
        });
        if (this.selectedSR) {
            this.setCompanyForSR();
        } else {
            this.isLoading = false;
        }
    }

    onSRChange(event) {
        this.selectedSR = event.value;
        this.setCompanyForSR();
    }

    saveInvoiceDetails() {
        this.isLoading = true;
        if (!this.rowData.totalAmount) {
            this.rowData.totalAmount = 0;
        }
        this.rowData.utilized = true;
        this.rowData.customer = (this.selectedCompany) ? this.selectedCompany : new Customer();
        this.rowData.invoiceGstDetail = (this.selectedBranch) ? new InvoiceGstDetail().setInvoiceGstDetail(this.createBranchItemObject(this.selectedBranch)) : new InvoiceGstDetail();
        this.rowData.employee = (this.selectedSR) ? this.selectedSR : new User();
        this.eEmitsaveInvoiceDetails.emit(this.rowData);
        this.isLoading = false;
        this.displayReserveInvoiceDetailDialog = false;
    }

    cancelInvoiceDetails() {
        this.isLoading = true;
        this.hideInvoiceDetailsDialog();
    }

    unutilizedInvoice() {
        this.isLoading = true;
        this.rowData.utilized = false;
        this.rowData.invoiceGstDetail = new InvoiceGstDetail();
        this.rowData.totalAmount = 0;
        this.rowData.poNumber = null;
        this.rowData.customer = new Customer();
        this.rowData.utilizedDate = null;
        this.rowData.billGeneratedDate = null;
        this.rowData.employee = new User();
        this.rowData.displayName = null;
        this.eEmitUnutilizeInvoice.emit(this.rowData);
        this.isLoading = false;
        this.displayReserveInvoiceDetailDialog = false;
    }

    hideInvoiceDetailsDialog() {
        this.isLoading = false;
        this.displayReserveInvoiceDetailDialog = false;
        this.eEmitDisplayReset.emit(false);
    }

    showErrorNotification(error) {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
    }

    showSuccessNotification(message, header) {
        this.notificationServcie.success(message, header);
    }

    setCompanyForSR() {
        this.companies = [];
        this.subscription.push(this.companyService.getCompaniesWithSrEmail(this.selectedSR.emailId).subscribe((response: any) => {
            if (response) {
                response.forEach((res) => {
                    this.companies.push({ label: res.company, value: res });
                });
                if (this.rowData.utilized) {
                    this.setSelectedCompany();
                } else {
                    this.isLoading = false;
                }
            }
        }));

    }
    ngOnDestroy() { }

}
