import { Injectable } from "@angular/core";
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router, NavigationStart, RouterEvent } from "@angular/router";
import { Observable } from "rxjs";
import { ConfirmationService } from "primeng/primeng";
import { GenerateBillComponent } from "../generate-bill/generate-bill.component";

@Injectable()
export class CanDeactivateGenerateInvoiceGuardService implements CanDeactivate<GenerateBillComponent> {

    constructor(
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    canDeactivate(component: GenerateBillComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!component.editMode) {
            return true;
        }

        this.confirmationService.confirm({
            message: 'Do you wish to discard the invoice ?',
            accept: () => {
                component.discardEdit();
                component.editMode = false;
                component.router.navigate([nextState.url]);
            },
            reject: () => {
                // do nothing
            }
        });
    }
}
