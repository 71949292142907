import { Http, Response } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../base-service/api.service";
import { Observable } from "rxjs";


@Injectable()

export class CreateMediaService extends ApiService<any, any, any> {
    urlPath: string = '';

    constructor(Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }
}
