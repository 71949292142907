import { InvoiceGstDetail } from './invoice-gst-detail';
import { AbstractReservedMetadata } from './abstract-reserved-metadata';

export class ReservedInvoiceMetadata extends AbstractReservedMetadata {
    totalAmount: number;
    invoiceGstDetail: InvoiceGstDetail = new InvoiceGstDetail();
    billGeneratedDate: Date;
    poNumber: string;
    displayName: string;
}
