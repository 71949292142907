<div class="ui-g-12 ">
    <div *ngIf="showFilter" style="float: right;">
        <p-multiSelect [options]="modifiedColumn" defaultLabel="Column Filter" [style]="{'width':'160px'}"
            maxSelectedLabels="0" selectedItemsLabel="{0} columns selected" (onChange)="setColumnsToDisplay()"
            name="name" [(ngModel)]="selectedColumn">
        </p-multiSelect>
    </div>

    <div *ngIf="showColorLegends" style="float: right; padding-right: 100px">
        <a href="javascript:void(0)" (click)="filterByStatus(dt, 'Pending')">
            <span class="unassigned"></span>
            Pending
        </a>
        <a href="javascript:void(0)" (click)="filterByStatus(dt, 'Not Available')">
            <span class="inactive"></span>
            Not Available
        </a>
        <a href="javascript:void(0)" (click)="filterByStatus(dt, 'Available')">
            <span class="active"></span>
            Available
        </a>
    </div>

    <div *ngIf="showFilter">
        <br>
        <br>
    </div>


    <p-dataTable [value]="values" [style]="{'z-index': '9999 !important'}" [rows]="10" [paginator]="paginator"
        [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [selectionMode]="selectionMode === 'null' ? null : 'single'"
        [(selection)]="selected" (onRowSelect)="onRowSelect($event)" [loading]="loading" appendTo="body"
        [editable]="true" #dt>
        <div *ngFor="let column of columnsToDisplay;let i = index">
            <p-column field={{column.name}} header={{column.header}} [style]="column.style" [filter]="column.filter"
                filterPlaceholder="Search" filterMatchMode="column.filterMatchMode" [sortable]="column.sortable"
                [editable]="column.editable">
                <ng-template *ngIf="column.type === 'text'" pTemplate="filter" let-col>
                    <input id="input" type="text" size="8" pInputText
                        (keyup)="filter($event.target.value,column.name,column.filterMatchMode, dt, col)"
                        placeholder="Search">
                </ng-template>
                <ng-template *ngIf="column.header === ''" let-value="rowData" pTemplate="body" let-rowIndex="rowIndex">
                    <i class="fa fa-times" aria-hidden="true" (click)="removeHoarding(value, rowIndex)"
                        style="position: relative; cursor: pointer"></i>
                </ng-template>
                <ng-template *ngIf="column.header === '' && column.source === 'plan'" let-rowData="rowData"
                    pTemplate="body" let-rowIndex="rowIndex">
                    <i class="fa fa-times" aria-hidden="true" (click)="removePlanHoarding(rowData)"
                        style="position: relative; cursor: pointer;"></i>
                </ng-template>
                <ng-template *ngIf="column.header === 'Location' && column.source === 'hoardings'" let-rowData="rowData"
                    pTemplate="body" let-rowIndex="rowIndex">
                    {{rowData.location}} - {{rowData.size}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Location' && column.source === 'campaign'" let-rowData="rowData"
                    pTemplate="body" let-rowIndex="rowIndex">
                    {{rowData.location}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Size'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.height}} X {{rowData.width}}
                </ng-template>
                <ng-template *ngIf="column.header === 'CID'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.contract.id}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Average Cost'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.averageCost}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Photos'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <a class="link-1  after" (click)="viewPhotos(rowData)">View</a>
                </ng-template>
                <ng-template *ngIf="column.header === 'Uploaded Photos'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <a class="link-1  after" (click)="viewPhotos(rowData)">View</a>
                </ng-template>
                <ng-template *ngIf="column.header === 'Monitoring Date'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData?.monitoringDate | date:'ddMMMyy'}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Mode'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData?.mode}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Photographer'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData?.photographer?.firstName}}
                </ng-template>
                <ng-template *ngIf="column.name === 'displayName'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="email === rowData?.employeeEmail || authorized">
                        <a class="link-1  after" (click)="viewItemDetail(rowData)">{{rowData.displayName}}</a>
                    </div>
                    <div *ngIf="email !== rowData?.employeeEmail && !authorized">
                        {{rowData.displayName}}
                    </div>
                </ng-template>
                <ng-template *ngIf="column.header === 'Campaign Start'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>
                <ng-template *ngIf="column.editable" let-rowData="rowData" pTemplate="editor" let-rowIndex="rowIndex">
                    <p-calendar appendTo="body" [utc]="true"></p-calendar>
                </ng-template>
                <ng-template *ngIf="column.header === 'Campaign End'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>
                <ng-template *ngIf="column.name === 'delete'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <i class="fa fa-times" aria-hidden="true" (click)="removeMonitoringItem(rowData, rowIndex)"
                        style="position: relative; cursor: pointer"></i>
                </ng-template>
                <ng-template *ngIf="column.name === 'edit'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <i class="fa fa-pencil-square-o info-blue" (click)="editMonitoringItem(rowData, rowIndex)"
                        aria-hidden="true"></i>
                </ng-template>
                <ng-template *ngIf="column.header === 'status'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div class="{{rowData.status}}" style="display:none">{{rowData.status}}</div>
                </ng-template>
                <ng-template *ngIf="column.header === 'Company Name'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.companyName}}
                </ng-template>

                <ng-template *ngIf="column.header === 'Item Start'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

                <ng-template *ngIf="column.header === 'Item End'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

                <ng-template *ngIf="column.header === 'Site Start Date'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

                <ng-template *ngIf="column.header === 'Site End Date'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>


            </p-column>
        </div>
    </p-dataTable>
</div>

<sib-photo-dialog [displayPhotoDialog]="displayPhotoDialog" [imageData]="imageData"
    (displayReset)="changePhotoDisplay($event)"></sib-photo-dialog>

<p-dialog header="Status Detail" [(visible)]="displayStatusQueueDialog" [modal]="true" showEffect="fade" [width]="800"
    (onHide)="hideStatusQueueDialog()">
    <p-dataTable [value]="newValues">
        <div *ngFor="let column of newColumns;let i = index">
            <p-column field={{column.name}} header={{column.header}} [style]="column.style"
                [sortable]="column.sortable">
                <ng-template *ngIf="column.header === ''" let-value="rowData" pTemplate="body" let-rowIndex="rowIndex">
                    <i class="fa fa-times" aria-hidden="true" (click)="removeHoarding(value, rowIndex)"
                        style="position: relative; cursor: pointer"></i>
                </ng-template>
                <ng-template *ngIf="column.header === 'Location' && column.name === 'location'" let-rowData="rowData"
                    pTemplate="body" let-rowIndex="rowIndex">
                    {{rowData.location}} - {{rowDat.size}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Location' && column.name === 'locations'" let-rowData="rowData"
                    pTemplate="body" let-rowIndex="rowIndex">
                    {{rowData.location}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Size'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.height}} X {{rowData.width}}
                </ng-template>
                <ng-template *ngIf="column.header === 'CID'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.contract.id}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Average Cost'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.averageCost}}
                </ng-template>
                <ng-template *ngIf="column.header === 'Photos'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <a class="link-1  after" (click)="viewPhotos(rowData)">View</a>
                </ng-template>
                <ng-template *ngIf="column.header === 'Status'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div class="{{rowData.status}}">
                        {{rowData.status}}
                    </div>
                </ng-template>
                <ng-template *ngIf="column.header === 'Company Name'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    {{rowData.companyName}}
                </ng-template>
                <ng-template *ngIf="column.name === 'displayName'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="email === rowData?.employeeEmail || authorized">
                        <a class="link-1  after" (click)="viewNewItemDetail(rowData)">{{rowData.displayName}}</a>
                    </div>
                    <div *ngIf="email !== rowData?.employeeEmail && !authorized">
                        {{rowData.displayName}}
                    </div>
                </ng-template>
                <ng-template *ngIf="column.header === 'Campaign Start'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>
                <ng-template *ngIf="column.header === 'Campaign End'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

                <ng-template *ngIf="column.header === 'Item Start'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

                <ng-template *ngIf="column.header === 'Item End'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

                <ng-template *ngIf="column.header === 'Site Start Date'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignStartDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

                <ng-template *ngIf="column.header === 'Site End Date'" let-rowData="rowData" pTemplate="body"
                    let-rowIndex="rowIndex">
                    <div *ngIf="rowData.campaign">
                        {{rowData.campaign.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                    <div *ngIf="!rowData.campaign">
                        {{rowData.campaignEndDate | date:'ddMMMyy'}}
                    </div>
                </ng-template>

            </p-column>
        </div>
    </p-dataTable>
</p-dialog>