<div class="ui-g row bs-wizard" style="border-bottom:0;">

    <!-- <div class="col-xs-3 bs-wizard-step">
        <div class="text-center bs-wizard-stepnum">SR</div>
        <div class="progress">
        </div>
        <a class="bs-wizard-dot" title="Sales Representative"></a>
    </div>

    <div class="col-xs-3 bs-wizard-step">
        <div class="text-center bs-wizard-stepnum">ACC</div>
        <div class="progress">
        </div>
        <a class="bs-wizard-dot" title="Accountant"></a>
    </div>

    <div class="col-xs-3 bs-wizard-step">
        <div class="text-center bs-wizard-stepnum">ADM</div>
        <div class="progress last-bar-child">
        </div>
        <a class="bs-wizard-dot" title="Admin"></a>
    </div> -->

    <!-- <div class=" bs-wizard-step" *ngFor="let node of nodes; let i=index" style="width: 50px"> -->
    <div class="col-xs-3 bs-wizard-step" *ngFor="let node of nodes; let i=index">
        <div *ngIf="node.header !== 'CMP'" class="text-center bs-wizard-stepnum">{{node.header}}</div>
        <div *ngIf="node.header === 'CMP'" class="text-center bs-wizard-empty-stepnum"> {{node.header}}</div>
        <div class="progress" [ngClass]="{'last-bar-child': i===nodes.length-1}"></div>
        <a *ngIf="node.header !=='CMP'" class="bs-wizard-dot" [ngClass]="{'set-circle-background': selected===node.header}"
            title="{{node.title}}"></a>
        <!-- <a *ngIf="node.header === 'CMP'" class="bs-wizard-dot" [ngClass]="{'set-circle-background': selected==='APP' || selected==='REJ'}"
            title=" ">
            <div class="bs-wizard-dot-with-check">

            </div>
        </a> -->
        <!-- [ngClass]="{'set-circle-background': selected==='APP'}" -->
        <a *ngIf="node.header === 'CMP' && selected==='APP'" class="bs-wizard-dot-approve" title="Approved">
            <div class="bs-wizard-dot-with-check"> </div>
        </a>
        <!-- [ngClass]="{'set-circle-background': selected==='REJ'}" -->
        <a *ngIf="node.header === 'CMP' && selected==='REJ'" class="bs-wizard-dot-reject" title="Rejected">
            <div><i style="margin-left: 0.8px" class="fa fa-close bs-wizard-close"></i></div>
        </a>
        <a *ngIf="node.header === 'CMP' && selected !=='APP' && selected !=='REJ'" class="bs-wizard-dot" title=" "> </a>
    </div>

</div>