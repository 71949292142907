import { State } from '../Area/state';
import { GSTDetail } from '../KYC/GSTDetails';
import { InvoiceGstDetail } from './invoice-gst-detail';
import { Customer } from '../KYC/customer';
import { Tenant } from '../master/tenant';
import { Address } from '../contracts/address';

export class SupplierWrapper {
    name: string;
    address: Address = new Address();
    gstDetails: GSTDetail[] = [];
    supplierGstDetail: InvoiceGstDetail = new InvoiceGstDetail();

    setSupplierWrapper(tenant: Tenant, supplierGstDetail: InvoiceGstDetail): SupplierWrapper {
        this.name = tenant.name;
        this.address = tenant.address;
        this.gstDetails = tenant.gstDetails;
        this.supplierGstDetail = supplierGstDetail;
        return this;
    }
}
