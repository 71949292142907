<p-dialog header="Suggestion" [(visible)]="showSuggestionDialog" showEffect="fade" [width]="300" modal="true"
   (onShow)="onShow()" (onHide)="onHide()">
   <form #suggestionForm="ngForm">
      <div class="ui-g ui-float-label padding-top-10 padding-bottom-10">
         <p-dropdown name="dropdownModule" [options]="moduleDropDown" placeholder="Select module"
            [(ngModel)]="suggestion.module" required autoWidth="false" [style]="{'width':'100%'}">
         </p-dropdown>
      </div>

      <div class="ui-g ui-float-label padding-top-10 padding-bottom-10 ">
         <p-dropdown name="dropdownDevice" [options]="deviceDropDown" placeholder="Select device"
            [(ngModel)]="suggestion.deviceType" required autoWidth="false" [style]="{'width':'100%'}">
         </p-dropdown>
      </div>

      <div class="ui-g ui-float-label padding-top-10 padding-bottom-10">
         <textarea name="suggestionText" #suggestiontext="ngModel" rows="3" cols="30" pInputTextarea placeholder="Type your suggestion"
            [(ngModel)]="suggestion.suggestion" required style="width : 100%"></textarea>
         <!-- <label>Enter your suggestion for selected module</label> -->
      </div>
      <div class="ctext-center padding-10">
         <button type="button" pButton class="mtab-save margin-hori-5" label="SEND"
            [disabled]="!suggestionForm.form.valid" (click)="sendRequest()">
         </button>
         <button type="button" pButton class="mtab-secondary" label="CANCEL" (click)="cancelRequest()"></button>
      </div>
   </form>
</p-dialog>