import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../shared/constants/SIBConstant';
import { GridComponent } from '../components/sib-forms/grid/grid.component';
import { Suggestion } from '../modals/master/suggestion';
import { EditableGridFieldTypes, GridColumn, GridConfig, SelectionMode, GridPaginationEvent, Alignment, GridActionButtonTypes } from '../components/sib-forms/grid/grid.config';
import { ModulesEnum } from '../shared/constants/support-modules-enum';
import { DeviceCategoryEnum } from '../shared/constants/device-category.enum';
import * as utils from '../helpers/utils';
import { SuggestionService } from '../suggestion-dialog/suggestion.service';
import { map } from 'rxjs/operators';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { AppUrls } from '../services/urls';
import { NotificatoinsService } from '../services/notifications/notifications.service';
import { ActivityLogModuleEnum } from '../shared/constants/activity-log-module-enum';
import { ChangesService } from '../services/shared/changes.service';
import * as _ from 'lodash';
import { GridActionButtonConfig } from '../components/sib-forms/grid/grid-action-button.config';

@Component({
  selector: 'sib-suggestion-view',
  templateUrl: './suggestion-view.component.html',
  styleUrls: ['./suggestion-view.component.css']
})
export class SuggestionViewComponent implements OnInit {

  suggestions: Suggestion[] = [];
  paginationFilter: any;
  totalRecords = 0;
  moduleDropdown: SelectItem[] = [];
  deviceDropdown: SelectItem[] = [];
  rowData: Suggestion = new Suggestion();
  displaySuggestionDialog: boolean = false;
  globalFilterFields = ['Employee', 'Module', 'Suggestion', 'Device Type'];

  @ViewChild(GridComponent)
  gridViewChildRef: GridComponent<Suggestion>;

  buttonConfig: GridActionButtonConfig = {
    add: {
      type: GridActionButtonTypes.ADD,
      label: 'NEW',
      styleClass: 'mtab-primary',
      displayOrder: 3,
      hide: false,
      onClick: () => { this.showSuggestionDialog(); }
    },
  };

  columns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr ',
      required: true,
      width: '2vw',
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },
    {
      field: 'suggestedBy.fullName',
      name: 'employee',
      header: 'Employee',
      required: true,
      editable: false,
      hidden: false,
      width: '3vw',
      default: true,
      styleClass: 'word-break-wrap',
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      },
      displayFn: (data) => {
        if (data.suggestedBy) {
          return data.suggestedBy.fullName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'module',
      name: 'module',
      header: 'Module',
      required: true,
      editable: false,
      hidden: false,
      width: '4vw',
      default: true,
      styleClass: 'word-break-wrap',
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true
      },
      displayFn: (data) => {
        if (data.module) {
          return ModulesEnum[data.module];
        } else {
          return '-';
        }
      },
    },
    {
      field: 'suggestion',
      name: 'suggestion',
      header: 'Suggestion',
      required: true,
      editable: false,
      hidden: false,
      width: '8vw',
      default: true,
      styleClass: 'word-break-wrap',
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false
      }
    },
    {
      field: 'deviceType',
      name: 'deviceType',
      header: 'DeviceType',
      required: true,
      editable: false,
      hidden: false,
      width: '3vw',
      default: true,
      styleClass: 'word-break-wrap',
      permanent: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true
      },
      displayFn: (data) => {
        if (data.deviceType) {
          return DeviceCategoryEnum[data.deviceType];
        } else {
          return '-';
        }
      },
    },
    {
      field: 'delSuggestion',
      name: 'delSuggestion',
      header: '',
      required: true,
      editable: false,
      hidden: false,
      width: '2vw',
      default: true,
      // styleClass: 'suggestion-delete',
      permanent: true,
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
      align: Alignment.CENTER
    },
  ];

  gridConfig: GridConfig<Suggestion> = new GridConfig<Suggestion>();

  constructor(
    private pageTitle: Title,
    private suggestionService: SuggestionService,
    private confirmationService: ConfirmationService,
    private notificationServcie: NotificatoinsService,
    private changesService: ChangesService,
  ) { }

  ngOnInit() {
    let appTitle = utils.getAppTitle();
    !appTitle ? this.pageTitle.setTitle(SIBConstants.SUGGESTION) : this.pageTitle.setTitle(SIBConstants.SUGGESTION + ' - ' + appTitle);
    this.setGridConfigObject();
    this.setFilterDropdowns();

  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new Suggestion();
    this.gridConfig.model = Suggestion;
    this.gridConfig.editable = false;
    this.gridConfig.selectionMode = SelectionMode.None;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.rowExpandMode = 'single';
    this.gridConfig.lazy = true;
    this.gridConfig.enablePagination = true;
    this.gridConfig.showColorCode = false;
    this.gridConfig.showGlobalFilter = true;
    this.gridConfig.scrollable = true;

    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      return this.suggestionService.create(paginationEvent, paginationEvent, AppUrls.SEARCH).pipe(
        map((response) => {
          this.suggestions = response['data']['content'];
          this.totalRecords = response["data"].totalElements;
          return response['data']['content'];
        }));
    };
  }

  setFilterDropdowns() {
    this.setModuleDropdown();
    this.setDevicDropdown();
  }

  setModuleDropdown() {
    this.moduleDropdown = utils.createDropdown(ModulesEnum, true);
  }

  setDevicDropdown() {
    this.deviceDropdown = utils.createDropdown(DeviceCategoryEnum, false);
  }

  refreshGrid() {
    this.gridViewChildRef.refresh();
  }

  // to delete the suggestion
  deleteSuggestion(rowData) {
    this.rowData = rowData;
    this.confirmationService.confirm({
      message: 'Do you want to delete this Suggestion?', key: 'suggestion',
      accept: () => {
        this.removeSuggestion(this.rowData);
      },
      reject: () => {
        // do nothing
      }
    });
  }

  removeSuggestion(rowData) {
    this.suggestionService.get({ suggestionId: rowData.id }, AppUrls.REMOVE_SUGGESTION).subscribe(
      (response) => {
        if (response) {
          this.notificationServcie.success("Suggestion Deleted Successfully", "Suggestion Deletion");
          this.changesService.setInitialSuggestion(_.cloneDeep(rowData));
          this.updateActivityLog("suggestion", "Suggestion Removed", null, null, "-", "-", null);
          this.gridViewChildRef.refresh();
        }
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');

      }
    );
  }
  getChangedType() {
    return ActivityLogModuleEnum.SUGGESTION;
  }

  updateActivityLog(changedField: string, changedFieldDisplayName: string, changedValue: any, formatterType: string, changeDescription: string, changeAdditionalDescription: string, changedFieldArrayVariable: string) {
    this.changesService.updateObjectMap(this.getChangedType(), changedField, changedFieldDisplayName, changedValue, formatterType, changeDescription, changeAdditionalDescription, changedFieldArrayVariable);
    this.setActivityLogs();
  }
  setActivityLogs() {
    this.changesService.create(this.changesService.getActivityLogs(), null, AppUrls.SAVE).subscribe(
      (response) => {
        console.log("response", response);
        this.changesService.resetData();
      },
      (error) => {
        this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
      }
    );
  }

  showSuggestionDialog() {
    this.displaySuggestionDialog = true;
  }

  closeSuggestionDialog() {
    this.refreshGrid();
    this.displaySuggestionDialog = false;
  }
}
