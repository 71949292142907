
import { catchError, map } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { PaginationData } from '../../modals/paginationdata';


@Injectable()
export class CompanyService extends ApiService<any, any, any> {
    urlPath: string = 'customers/search/byStatus?status=ACTIVE&projection=customerCompanyProjection';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getCompaniesWithSrEmail(email) {
        // search/
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'customers/find/byManagerEmailId', { params: { 'emailId': email } }).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                // TODO: Error handling
                console.log(err);
                return err;
            }));
        }

    }
    //     let search: PaginationData = {
    //         filters: {},
    //         globalFilter: null,
    //         size: 500,
    //         page: 0,

    //     };
    //     return this.Http.post(this.baseUrl + 'customers/search', search).pipe(map((response) => {
    //         return response;
    //     }), catchError((err) => {
    //         // TODO: Error handling
    //         console.log(err);
    //         return err;
    //     }));
    // }
}
