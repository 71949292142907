export class SiteExpirationTask {
    _id: string;
    campaignId: string;
    district: string;
    city: string;
    areaName: string;
    location: string;
    size: string;
    companyName: string;
    displayName: string;
    customId: string;
    itemStartDate: Date = new Date();
    itemEndDate: Date = new Date();
    days: number;
}