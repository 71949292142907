import { Component, AfterViewInit, ElementRef, Renderer2, ViewChild, OnInit, Inject } from '@angular/core';
import { AuthService } from './login-service/auth.service';
import { IndustryService } from './services/shared/industry.service';
import { GroupService } from './services/shared/group.service';
import { ContractTypeService } from './services/shared/contract-type.service';
import { ActionDispatcher } from './app state/action-dispatcher/action-dispatcher';
import { Title, DomSanitizer, SafeResourceUrl, DOCUMENT } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HotkeyModule, HotkeysService, Hotkey, HotkeyOptions } from 'angular2-hotkeys';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { Car } from './demo/domain/car';
import { User } from './modals/user/user';
import { CarService } from './demo/service/carservice';
import { EventService } from './demo/service/eventservice';
import { AuthApiService } from './services/shared/auth-api.service';
import { UserService } from './services/shared/user.service';
import { NotificatoinsService } from './services/notifications/notifications.service';
import { AuthUserAPIService } from './services/shared/auth-user.service';
import { RemoteUser } from './modals/user/user-remote';
import { SibUserRoleEnum } from './shared/constants/sib-user-role-enum';
import * as $ from 'jquery';
import { ChatBaseService } from './services/shared/chat.base.services';
import { SystemService } from './services/shared/system.service';
import { AppUrls } from './services/urls';
import { VendorService } from './services/shared/vendor.service';
import { NotificationsMessages } from './services/shared/notifications-messages';
import { merge, of, fromEvent, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent implements OnInit {

  // @ViewChild('appFavicon') appFavicon:ElementRef;
  industryList: any[] = [];
  groupList: any[] = [];
  contractTypeList: any[] = [];
  areaList: any[] = [];

  rightPanelActive: any;

  cities: SelectItem[];

  cars: Car[];

  chartData: any;

  events: any[];

  selectedCity: any;

  /**
   * Boolean to toggle display of dialog for entering user Details
   * 
   * @type {boolean}
   * @memberof DashboardDemo
   */
  displayUserDetailDialog: boolean = false;

  /**
   * creating user Object to store user Information
   * 
   * @type {User}
   * @memberof DashboardDemo
   */
  user: User = new User();

  deadlineDate: any;
  nowDate: any;
  dateDiff: any;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isLoading: boolean = false;
  online$: Observable<boolean>;
  isConnected: boolean;
  showRefreshScreenDialog: boolean = false;
  refresh: boolean = false;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public renderer: Renderer2,
    private industryService: IndustryService,
    private groupService: GroupService,
    private contractTypeService: ContractTypeService,
    private actionDispatcher: ActionDispatcher,
    private titleService: Title,
    private router: Router,
    private aRoute: ActivatedRoute,
    private _hotkeysService: HotkeysService,
    private carService: CarService,
    private eventService: EventService,
    private systemService: SystemService,
    // Injecting auth Service --> Handles login
    private auth: AuthService,
    //Injecting authApi service that handles all the api functionality at auth end
    private authApiService: AuthApiService,
    //user service to handle all the api call related to user.
    private userService: UserService,
    private notificationServcie: NotificatoinsService,
    private authUserApiService: AuthUserAPIService,
    private confirmationService: ConfirmationService,
    private chatBaseService: ChatBaseService,
    private vendorService: VendorService
  ) {


    this.actionDispatcher.getIndustries();
    this.actionDispatcher.getGroups();
    this.actionDispatcher.getContractTypes();
    this.actionDispatcher.getAreaListFirst();
    this.actionDispatcher.getSystemConfiguations();


    this._hotkeysService.add(new Hotkey('ctrl+alt+w', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/']);
      return false; // Prevent bubbling
    }, undefined, 'Go to Dashboards'));
    this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/customers']);
      return false; // Prevent bubbling
    }, undefined, 'Go to Customers'));

    this._hotkeysService.add(new Hotkey('ctrl+alt+h', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/hoardings']);
      return false; // Prevent bubbling
    }, undefined, 'Go to Hoarding Master'));
    this._hotkeysService.add(new Hotkey('ctrl+alt+o', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/contracts']);
      return false; // Prevent bubbling
    }, undefined, 'Go to Contracts'));
    this._hotkeysService.add(new Hotkey('ctrl+alt+p', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/plans']);
      return false; // Prevent bubbling
    }, undefined, 'Go to Plans'));
    this._hotkeysService.add(new Hotkey('ctrl+alt+m', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/campaigns']);
      return false; // Prevent bubbling
    }, undefined, 'Go to Campaigns'));
    this._hotkeysService.add(new Hotkey('ctrl+alt+s', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/masters/settings']);
      return false; // Prevent bubbling
    }, undefined, 'Go to Settings'));

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

  }

  ngOnInit() {

    this.online$.subscribe((isConnected) => {
      if (isConnected && this.refresh) {            //when internet goes offline first
        this.showRefreshScreenDialog = true;
      } else {
        this.refresh = true;
      }
    });

    // this._document.getElementById('appFavicon').setAttribute('href', 'https://firebasestorage.googleapis.com/v0/b/showitbig-411c5.appspot.com/o/dev%2FSIB0001%2Fclient%2Ffavicon.png?alt=media&token=62e38583-54ae-4d9f-a5db-b5967f0041df');
    this.actionDispatcher.industries.subscribe((industryList) => {
      this.industryList = industryList;
    });

    this.actionDispatcher.groups.subscribe((groupList) => {
      this.groupList = groupList;
    });

    this.actionDispatcher.contractTypes.subscribe((contractTypeList) => {
      this.contractTypeList = contractTypeList;
    });

    this.actionDispatcher.areas.subscribe((areaList) => {
      this.areaList = areaList;
    });

    this.checkForUserInfo();

    /**
      * When the user logs in then Get API access token  
      * Programmer: Sanchit Mirg
      * 
      * @memberof DashboardDemo
      */

    //first when user gets the profile information after login from auth End.
    this.auth.emitProifle.subscribe((profile) => {

      //after getting the profile get api access token api is called to get the api access token
      if (profile) {

        this.authApiService.getUserManagmentApiAccessToken().subscribe((response) => {
          localStorage.setItem('usermanagement_access_token', response['access_token'])
          // localStorage.setItem("auth_permission_url", 'https://vendor1.us.webtask.io/adf6e2f2b84784b57522e3b19dfc9201/api')
        })

        this.authApiService.getApiAccessToken().subscribe((response) => {

          //storing the api_access_tokin in the local storage
          localStorage.setItem('api_access_token', response['access_token']);

          //after getting the api access token we are getting the userinfo based on email Id
          this.authApiService.getUserInfoByEmail(profile).subscribe((userInfo) => {

            if (userInfo) {
              //setting the user info in the local storage
              localStorage.setItem('userInfo', JSON.stringify(userInfo));

              // setting the auth service authSet subject to true so that we can enable chat
              this.auth.authSet.next(true);
              this.addCrossSiteEventListener();

              //validating the user if user is loggin for the first time 
              //or if there is no information for that user in the backend
              this.validateUserFirstLogin();
            }

          });
        });

      }
    })

    //calling the validate user - login independently if user refreshes the screen
    this.countDown();
    setInterval(() => {
      this.countDown();
    }, 1000)
    this.validateUserFirstLogin();

    this.addCrossSiteEventListener();
  }

  addCrossSiteEventListener() {
    // check for browser support
    if (window.addEventListener) {

      let chatLocal = this;

      // message handler
      window.addEventListener('message', function (e) {
        // check message origin
        if ((typeof e.data != 'undefined') && e.data.event_id != undefined && e.data.event_id == "sib-chat-frame") {

          $("#chat-box").slideToggle(function () {
            //Chat button toggle
            $("#chat-button").slideToggle(100);
          });


        } else if ((typeof e.data != 'undefined') && e.data.event_id != undefined && e.data.event_id == "sib-chat-count") {
          chatLocal.chatBaseService.unreadMsgCnt.next(e.data.chat_count);
        }

      }, false);
    }
  }

  checkForUserInfo() {
    if (localStorage.getItem('userInfo')) {
      this.auth.authSet.next(true);
    }
  }

  onRightPanelClick() { }


  countDown() {
    this.deadlineDate = new Date("Nov 30, 2018 00:00:00").getTime()
    var currentDate = new Date().getTime();

    var seconds_left = (this.deadlineDate - currentDate) / 1000;

    this.days = Math.floor(seconds_left / 86400);
    seconds_left = seconds_left % 86400;

    this.hours = Math.floor(seconds_left / 3600);
    seconds_left = seconds_left % 3600;

    this.minutes = Math.floor(seconds_left / 60);
    this.seconds = Math.floor(seconds_left % 60)
  }


  /**
   * validating if the user is logging in for the first.
   * Programmer: Sanchit Mirg.
   * 
   * @memberof DashboardDemo
   */
  validateUserFirstLogin() {

    this.isLoading = true;
    //getting the userDetails from local storage
    const userDetails = JSON.parse(localStorage.getItem('userInfo'));
    this.userService.user.next(userDetails);
    //calling the userService to check if the user by that email id already exist or not
    if (userDetails && userDetails[0]) {
      this.userService.get(null, '/search/byEmailId?emailId=' + userDetails[0].email)
        .subscribe((user) => {

          //checking if the user is logging in for the first or user does not exist in the backend.
          if (user && user.userRegistered === false) {

            //setting default userInfo
            /* this.user.emailId = userDetails[0].email
            this.user.authId = userDetails[0].user_id */
            this.user = user;
            this.user.roles = [...this.user.roles, ... this.mapUserRole(userDetails[0].app_metadata['authorization']['roles'])]

            //toggling the user Detail Dialog.
            this.displayUserDetailDialog = true;
            this.isLoading = false;
          } else if (user && user.userRegistered === true) {
            this.isLoading = false;
          }
        }, (error) => {
          // this.confirm();
          this.isLoading = false;
        });
    }

  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Please contact ShowItBig support.',
      accept: () => {
        this.validateUserFirstLogin();
      },
      reject: () => {
        this.validateUserFirstLogin();
      }
    });
  }


  mapUserRole(authRoles) {
    // alert("your role is "+authRoles);
    var userRole = [];

    for (let item in SibUserRoleEnum) {
      if (authRoles.indexOf(SibUserRoleEnum[item]) !== -1) {
        userRole.push(item);
      }
    }
    return userRole;
  }

  /**
   * Calling this function to save User
   * Programmer: Sanchit Mirg
   * 
   * @param {any} user 
   * @memberof DashboardDemo
   */
  saveUser(user) {
    user.user.userRegistered = true;

    const userDetails = new RemoteUser().toRemoteUpdate(user.user);
    userDetails['password'] = user.password;
    delete userDetails['email_verified'];
    delete userDetails['email'];
    this.authUserApiService.patch(userDetails, null, user.user.authId)
      .subscribe((Authuser) => {
        if (Authuser) {
          //calling the service to create user.
          this.userService.create(user.user, null, '/update')
            .subscribe(
              (user) => {
                this.notificationServcie.success("User saved Successfully", 'User Save')
                this.displayUserDetailDialog = false;
              }, (error) => {
                this.notificationServcie.success("User Creation Failed", 'User Creation')
              })
        }
      })



  }

  closeDialog() {
    this.displayUserDetailDialog = false;
    this.validateUserFirstLogin();
  }


  /**
   * gets favicon image
   *
   * @memberof AppComponent
   */
  getFavicon() {
    this.vendorService.get(null, 'getLogo').subscribe((response) => {
      if (response) {
        const element = this._document.getElementById('appFavicon');
        if (element) {
          element.setAttribute('href', response['data'].favIcon.url);
        }
      }
      // this._document.getElementById('appFavicon').setAttribute('href', response['data'].favIcon.url);
    });
  }


}
