import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ButtonModule, SharedModule, InputTextModule, AutoCompleteModule, DropdownModule, CalendarModule, TooltipModule, DataTableModule, DialogModule, MultiSelectModule } from "primeng/primeng";
import { InputTrimModule } from "../../../directives/input-trim";
import { CommonDataTableComponent } from "./dataTable.component";
import { PhotoDialogModule } from "../photo-dialog/photo-dialog.module";




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        InputTrimModule,
        DataTableModule,
        DialogModule,
        MultiSelectModule,
        PhotoDialogModule
    ],

    declarations: [
        CommonDataTableComponent
    ],

    exports: [
        CommonDataTableComponent
    ],
    providers: []
})

export class CommonDataTableModule { }
