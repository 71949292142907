/**
* @file PlanActionDispatcher
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BillingActions } from "../actions/billing-actions";
import { BillingModuleState, BillingModuleStateExtended } from "../billing-module.reducers";
import { Store } from "@ngrx/store";
import { BillingModule } from "../billing.module";
import { CampaignQueryParams } from "../../modals/queryparams/campaign-queryparams";



@Injectable()
export class BillingActionDispatcher {

    isError: Observable<boolean>;
    gotRentItems: Observable<boolean>;
    gotPrintingItems: Observable<boolean>;
    gotMountingItems: Observable<boolean>;
    rentItems: Observable<any[]>;
    printingItems: Observable<any[]>;
    mountingItems: Observable<any[]>;
    pendingRentItems: Observable<any[]>;
    pendingPrintingItems: Observable<any[]>;
    pendingMountingItems: Observable<any[]>;
    isBillGenerated: Observable<boolean>;
    isMigratedBillGenerated: Observable<boolean>;
    invoices: Observable<any[]>;
    invoicesRecieved: Observable<boolean>;
    // isAccountantRequestRejected: Observable<boolean>;
    // isAdminRequestRejected: Observable<boolean>;
    // isAccountantRequestApproved: Observable<boolean>;
    // isAdminRequestApproved: Observable<boolean>;
    // isEditRequestRejected: Observable<boolean>;
    // isEditRequestApproved: Observable<boolean>;
    // isCancelRequestRejected: Observable<boolean>;
    // isCancelRequestApproved: Observable<boolean>;
    tempInvoice: Observable<any>;
    isBillSubmitted: Observable<boolean>;
    isMigratedBillSubmitted: Observable<boolean>;
    isPOUploaded: Observable<boolean>;

    // ==========================================================

    isEditRequestSuccess: Observable<boolean>;
    isEditDiscardRequestSuccess: Observable<boolean>;
    isCancelRequestSuccess: Observable<boolean>;

    isEditMigratedRequestSuccess: Observable<boolean>;
    isEditDiscardMigratedRequestSuccess: Observable<boolean>;

    isCancelMigratedRequestSuccess: Observable<boolean>;

    invoiceTaskCards: Observable<any[]>;

    isGenerateRequestApprovedAdmin: Observable<boolean>;
    isGenerateRequestApprovedAccountant: Observable<boolean>;
    isGenerateRequestRejectedAdmin: Observable<boolean>;
    isGenerateRequestRejectedAccountant: Observable<boolean>;

    isEditRequestApprovedAdmin: Observable<boolean>;
    isEditRequestApprovedAccountant: Observable<boolean>;
    isEditRequestRejectedAdmin: Observable<boolean>;
    isEditRequestRejectedAccountant: Observable<boolean>;

    isCancelRequestApprovedAdmin: Observable<boolean>;
    isCancelRequestApprovedAccountant: Observable<boolean>;
    isCancelRequestRejectedAdmin: Observable<boolean>;
    isCancelRequestRejectedAccountant: Observable<boolean>;

    constructor(
        private store: Store<BillingModuleStateExtended>,
        private billingActions: BillingActions
    ) {
        this.isError = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isError;
            }
        });

        this.gotRentItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.gotRentItems;
            }
        });

        this.gotPrintingItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.gotPrintingItems;
            }
        });

        this.gotMountingItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.gotMountingItems;
            }
        });

        this.rentItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.rentItems;
            }
        });

        this.printingItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.printingItems;
            }
        });

        this.mountingItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.mountingItems;
            }
        });

        this.isBillGenerated = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isBillGenerated;
            }
        });

        this.isMigratedBillGenerated = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isMigratedBillGenerated
            }
        });
        this.invoices = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.invoices;
            }
        });

        this.invoicesRecieved = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.invoicesRecieved;
            }
        });

        this.pendingRentItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.pendingRentItems;
            }
        });

        this.pendingPrintingItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.pendingPrintingItems;
            }
        });

        this.pendingMountingItems = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.pendingMountingItems;
            }
        });

        // this.isAccountantRequestRejected = store.select((state) => {
        //     if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isAccountantRequestRejected;
        //     }
        // });

        // this.isAdminRequestRejected = store.select((state) => {
        //    if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isAdminRequestRejected;
        //     }
        // });

        // this.isAccountantRequestApproved = store.select((state) => {
        //    if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isAccountantRequestApproved;
        //     }
        // });

        // this.isAdminRequestApproved = store.select((state) => {
        //    if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isAdminRequestApproved;
        //     }
        // });

        // this.isEditRequestApproved = store.select((state) => {
        //     if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isEditRequestApproved;
        //     }
        // });

        // this.isEditRequestRejected = store.select((state) => {
        //    if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isEditRequestRejected;
        //     }
        // });

        // this.isCancelRequestApproved = store.select((state) => {
        //     if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isCancelRequestApproved;
        //     }
        // });

        // this.isCancelRequestRejected = store.select((state) => {
        //     if (state.billings && state.billings.billings) {
        //         return state.billings.billings.isCancelRequestRejected;
        //     }
        // });

        this.tempInvoice = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.tempInvoice;
            }
        });

        this.isBillSubmitted = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isBillSubmitted;
            }
        });

        this.isMigratedBillSubmitted = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isMigratedBillSubmitted;
            }
        });

        this.isPOUploaded = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isPOUploaded;
            }
        });

        // =========================================================================================

        this.isEditRequestSuccess = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditRequestSuccess;
            }
        });

        this.isEditDiscardRequestSuccess = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditDiscardRequestSuccess;
            }
        });

        this.isCancelRequestSuccess = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isCancelRequestSuccess;
            }
        });



        this.isEditMigratedRequestSuccess = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditMigratedRequestSuccess;
            }
        });

        this.isEditDiscardMigratedRequestSuccess = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditDiscardMigratedRequestSuccess;
            }
        });

        this.isCancelMigratedRequestSuccess = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isCancelMigratedRequestSuccess;
            }
        });

        this.isGenerateRequestApprovedAdmin = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isGenerateRequestApprovedAdmin;
            }
        });

        this.isGenerateRequestApprovedAccountant = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isGenerateRequestApprovedAccountant;
            }
        });

        this.isGenerateRequestRejectedAdmin = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isGenerateRequestRejectedAdmin;
            }
        });

        this.isGenerateRequestRejectedAccountant = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isGenerateRequestRejectedAccountant;
            }
        });

        this.isEditRequestApprovedAdmin = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditRequestApprovedAdmin;
            }
        });

        this.isEditRequestApprovedAccountant = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditRequestApprovedAccountant;
            }
        });

        this.isEditRequestRejectedAdmin = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditRequestRejectedAdmin;
            }
        });

        this.isEditRequestRejectedAccountant = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isEditRequestRejectedAccountant;
            }
        });

        this.isCancelRequestApprovedAdmin = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isCancelRequestApprovedAdmin;
            }
        });

        this.isCancelRequestApprovedAccountant = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isCancelRequestApprovedAccountant;
            }
        });

        this.isCancelRequestRejectedAdmin = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isCancelRequestRejectedAdmin;
            }
        });

        this.isCancelRequestRejectedAccountant = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.isCancelRequestRejectedAccountant;
            }
        });

        this.invoiceTaskCards = store.select((state) => {
            if (state.billings && state.billings.billings) {
                return state.billings.billings.invoiceTaskCards;
            }
        });
    }

    getRentItems(billingGrid) {
        this.store.dispatch(this.billingActions.getRentItems(billingGrid));
    }

    getPrintingItems(billingGrid) {
        this.store.dispatch(this.billingActions.getPrintingItems(billingGrid));
    }

    getMountingItems(billingGrid) {
        this.store.dispatch(this.billingActions.getMountingItems(billingGrid));
    }

    resetError() {
        this.store.dispatch(this.billingActions.resetError());
    }

    generateBill(invoice) {
        this.store.dispatch(this.billingActions.generateBill(invoice));
    }

    generateMigratedBill(invoice) {
        this.store.dispatch(this.billingActions.generateMigratedBill(invoice))
    }

    resetIsBillGenerated() {
        this.store.dispatch(this.billingActions.resetIsBillGenerated());
    }

    resetIsMigratedBillGenerated() {
        this.store.dispatch(this.billingActions.resetIsMigratedBillGenerated());
    }
    getInvoices(campaignId) {
        this.store.dispatch(this.billingActions.getInvoices(campaignId));
    }

    resetInvoicesReceived() {
        this.store.dispatch(this.billingActions.resetInvoicesReceived());
    }

    resetRentItems() {
        this.store.dispatch(this.billingActions.resetRentItems());
    }

    resetPrintingItems() {
        this.store.dispatch(this.billingActions.resetPrintingItems());
    }

    resetMountingItems() {
        this.store.dispatch(this.billingActions.resetMountingItems());
    }

    resetPendingRentItems() {
        this.store.dispatch(this.billingActions.resetPendingRentItems());
    }

    resetPendingPrintingItems() {
        this.store.dispatch(this.billingActions.resetPendingPrintingItems());
    }

    resetPendingMountingItems() {
        this.store.dispatch(this.billingActions.resetPendingMountingItems());
    }

    getPendingRentItems(billingGrid) {
        this.store.dispatch(this.billingActions.getPendingRentItems(billingGrid));
    }

    getPendingPrintingItems(billingGrid) {
        this.store.dispatch(this.billingActions.getPendingPrintingItems(billingGrid));
    }

    getPendingMountingItems(billingGrid) {
        this.store.dispatch(this.billingActions.getPendingMountingItems(billingGrid));
    }

    // editRequest(invoice) {
    //     this.store.dispatch(this.billingActions.editRequest(invoice));
    // }

    // cancelRequest(invoice) {
    //     this.store.dispatch(this.billingActions.cancelRequest(invoice));
    // }

    // approveRequestAccountant(invoice) {
    //     this.store.dispatch(this.billingActions.approveRequestAccountant(invoice))
    // }

    // approveEditRequestAccountant(invoice) {
    //     this.store.dispatch(this.billingActions.approveEditRequestAccountant(invoice))
    // }

    // approveCancelRequestAccountant(invoice) {
    //     this.store.dispatch(this.billingActions.approveCancelRequestAccountant(invoice))
    // }

    // rejectRequestAccountant(invoice) {
    //     this.store.dispatch(this.billingActions.rejectRequestAccountant(invoice))
    // }

    // rejectEditRequestAccountant(invoice) {
    //     this.store.dispatch(this.billingActions.rejectEditRequestAccountant(invoice))
    // }

    // rejectCancelRequestAccountant(invoice) {
    //     this.store.dispatch(this.billingActions.rejectCancelRequestAccountant(invoice))
    // }

    // approveRequestAdmin(invoice) {
    //     this.store.dispatch(this.billingActions.approveRequestAdmin(invoice))
    // }

    // rejectRequestAdmin(invoice) {
    //     this.store.dispatch(this.billingActions.rejectRequestAdmin(invoice))
    // }

    // resetIsAccountantRequestRejected() {
    //     this.store.dispatch(this.billingActions.resetIsAccountantRequestRejected());
    // }

    // resetIsAdminRequestRejected() {
    //     this.store.dispatch(this.billingActions.resetIsAdminRequestRejected());
    // }

    // resetIsAccountantRequestApproved() {
    //     this.store.dispatch(this.billingActions.resetIsAccountantRequestApproved());
    // }

    // resetIsAdminRequestApproved() {
    //     this.store.dispatch(this.billingActions.resetIsAdminRequestApproved());
    // }

    // resetIsAccountantEditRequestRejected() {
    //     this.store.dispatch(this.billingActions.resetIsAccountantEditRequestRejected());
    // }

    // resetIsAccountantCancelRequestRejected() {
    //     this.store.dispatch(this.billingActions.resetIsAccountantCancelRequestRejected());
    // }

    // resetIsAccountantEditRequestApproved() {
    //     this.store.dispatch(this.billingActions.resetIsAccountantEditRequestApproved());
    // }

    // resetIsAccountantCancelRequestApproved() {
    //     this.store.dispatch(this.billingActions.resetIsAccountantCancelRequestApproved());
    // }

    getTemporaryInvoice(invoiceId) {
        this.store.dispatch(this.billingActions.getTemporaryInvoice(invoiceId));
    }

    resetTempInvoice() {
        this.store.dispatch(this.billingActions.resetTempInvoice());
    }

    submitBill(invoice) {
        this.store.dispatch(this.billingActions.submitBill(invoice));
    }

    resetIsBillSubmitted() {
        this.store.dispatch(this.billingActions.resetIsBillSubmitted());
    }


    submitMigratedBill(invoice) {
        this.store.dispatch(this.billingActions.submitMigratedBill(invoice));
    }

    resetIsMigratedBillSubmitted() {
        this.store.dispatch(this.billingActions.resetIsMigratedBillSubmitted());
    }

    uploadInvoicePO(campaignQueryParams: CampaignQueryParams) {
        this.store.dispatch(this.billingActions.uploadInvoicePO(campaignQueryParams));
    }

    resetIsPOUploaded() {
        this.store.dispatch(this.billingActions.resetIsPOUploaded());
    }

    // ===============================================================================

    editRequest(invoice) {
        this.store.dispatch(this.billingActions.editRequest(invoice));
    }

    resetIsEditRequestSuccess() {
        this.store.dispatch(this.billingActions.resetIsEditRequestSuccess());
    }

    editMigratedRequest(invoice) {
        this.store.dispatch(this.billingActions.editMigratedRequest(invoice));
    }

    resetIsMigratedRequestSuccess() {
        this.store.dispatch(this.billingActions.resetIsEditMigratedRequestSuccess());
    }

    editDiscardRequest(invoice) {
        this.store.dispatch(this.billingActions.editDiscardRequest(invoice));
    }

    resetIsEditDiscardRequestSuccess() {
        this.store.dispatch(this.billingActions.resetIsEditDiscardRequestSuccess());
    }

    cancelRequest(invoice) {
        this.store.dispatch(this.billingActions.cancelRequest(invoice));
    }

    resetIsCancelRequestSuccess() {
        this.store.dispatch(this.billingActions.resetIsCancelRequestSuccess());
    }

    approveGenerateRequestAdmin(invoice) {
        this.store.dispatch(this.billingActions.approveGenerateRequestAdmin(invoice));
    }

    approveGenerateRequestAccountant(invoice) {
        this.store.dispatch(this.billingActions.approveGenerateRequestAccountant(invoice));
    }

    resetIsGenerateRequestApprovedAdmin() {
        this.store.dispatch(this.billingActions.resetIsGenerateRequestApprovedAdmin());
    }

    resetIsGenerateRequestApprovedAccountant() {
        this.store.dispatch(this.billingActions.resetIsGenerateRequestApprovedAccountant());
    }

    approveEditRequestAdmin(invoice) {
        this.store.dispatch(this.billingActions.approveEditRequestAdmin(invoice));
    }

    approveEditRequestAccountant(invoice) {
        this.store.dispatch(this.billingActions.approveEditRequestAccountant(invoice));
    }

    resetIsEditRequestApprovedAdmin() {
        this.store.dispatch(this.billingActions.resetIsEditRequestApprovedAdmin());
    }

    resetIsEditRequestApprovedAccountant() {
        this.store.dispatch(this.billingActions.resetIsEditRequestApprovedAccountant());
    }

    approveCancelRequestAdmin(invoice) {
        this.store.dispatch(this.billingActions.approveCancelRequestAdmin(invoice));
    }

    approveCancelRequestAccountant(invoice) {
        this.store.dispatch(this.billingActions.approveCancelRequestAccountant(invoice));
    }

    resetIsCancelRequestApprovedAdmin() {
        this.store.dispatch(this.billingActions.resetIsCancelRequestApprovedAdmin());
    }

    resetIsCancelRequestApprovedAccountant() {
        this.store.dispatch(this.billingActions.resetIsCancelRequestApprovedAccountant());
    }

    rejectGenerateRequestAdmin(invoice) {
        this.store.dispatch(this.billingActions.rejectGenerateRequestAdmin(invoice));
    }

    rejectGenerateRequestAccountant(invoice) {
        this.store.dispatch(this.billingActions.rejectGenerateRequestAccountant(invoice));
    }

    resetIsGenerateRequestRejectedAdmin() {
        this.store.dispatch(this.billingActions.resetIsGenerateRequestRejectedAdmin());
    }

    resetIsGenerateRequestRejectedAccountant() {
        this.store.dispatch(this.billingActions.resetIsGenerateRequestRejectedAccountant());
    }

    rejectEditRequestAdmin(invoice) {
        this.store.dispatch(this.billingActions.rejectEditRequestAdmin(invoice));
    }

    rejectEditRequestAccountant(invoice) {
        this.store.dispatch(this.billingActions.rejectEditRequestAccountant(invoice));
    }

    resetIsEditRequestRejectedAdmin() {
        this.store.dispatch(this.billingActions.resetIsEditRequestRejectedAdmin());
    }

    resetIsEditRequestRejectedAccountant() {
        this.store.dispatch(this.billingActions.resetIsEditRequestRejectedAccountant());
    }

    rejectCancelRequestAdmin(invoice) {
        this.store.dispatch(this.billingActions.rejectCancelRequestAdmin(invoice));
    }

    rejectCancelRequestAccountant(invoice) {
        this.store.dispatch(this.billingActions.rejectCancelRequestAccountant(invoice));
    }

    resetIsCancelRequestRejectedAdmin() {
        this.store.dispatch(this.billingActions.resetIsCancelRequestRejectedAdmin());
    }

    resetIsCancelRequestRejectedAccountant() {
        this.store.dispatch(this.billingActions.resetIsCancelRequestRejectedAccountant());
    }

    getInvoiceTaskCards() {
        this.store.dispatch(this.billingActions.getInvoiceTaskCards());
    }

    resetInvoiceTaskCards() {
        this.store.dispatch(this.billingActions.resetInvoiceTaskCards());
    }

}
