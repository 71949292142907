import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, DataTableModule, InputTextModule, InputTextareaModule, MultiSelectModule, DropdownModule, DialogModule} from "primeng/primeng";
import { FormsModule } from '@angular/forms';
import { SuggestionDialogComponent } from './suggestion-dialog.component';
import { SuggestionService } from './suggestion.service';

@NgModule({
  declarations: [SuggestionDialogComponent],

  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
  ],
  exports : [SuggestionDialogComponent],

  providers : [SuggestionService],
})

export class SuggestionDialogModule { }
