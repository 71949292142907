import { Injectable, Injector } from "@angular/core";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { Plan } from "../../modals/plans/plan";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UploadPOService extends ApiService<any, any, any> {
    // urlPath: string = 'plans?projection=planProjection';
    urlPath: string = 'campaigns/uploadPO/';
    baseUrl: string;
    plan: Plan;


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }
}