<div class="ui-g">
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>DataTable</h1>
            <p-dataTable [value]="cars1" selectionMode="single" [(selection)]="selectedCar" 
                [paginator]="true" [rows]="10" [responsive]="true">
                <p-header>List of Cars</p-header>
                <p-column field="vin" header="Vin" [sortable]="true"></p-column>
                <p-column field="year" header="Year" [sortable]="true"></p-column>
                <p-column field="brand" header="Brand" [sortable]="true"></p-column>
                <p-column field="color" header="Color" [sortable]="true"></p-column>
            </p-dataTable>
        </div>
    </div>
    <div class="ui-g-12">
        <!-- Left Side -->
        <div class="card card-w-title">
            <h1>DataGrid</h1>
            <p-dataGrid [value]="cars2" [paginator]="true" [rows]="9">
                <p-header>
                    Grid of Cars
                </p-header>
                <ng-template let-car pTemplate="item">
                    <div style="padding:3px" class="ui-g-12 ui-md-4">
                        <p-panel [header]="car.vin" [style]="{'text-align':'center'}">
                            <img src="assets/demo/images/car/{{car.brand}}.gif">
                            <div class="car-detail">{{car.year}} - {{car.color}}</div>
                        </p-panel>
                    </div>
                </ng-template>
            </p-dataGrid>
        </div>
    </div>
    
    <div class="ui-g-12 ui-md-8">
        <div class="card card-w-title">
            <h1>PickList</h1>
            <p-pickList [source]="sourceCars" [target]="targetCars" sourceHeader="Available" targetHeader="Selected" [responsive]="true"
                [sourceStyle]="{'height':'250px'}" [targetStyle]="{'height':'250px'}">
                <ng-template let-car pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <img src="assets/demo/images/car/{{car.brand}}.gif" style="display:inline-block;margin:2px 0 2px 2px"/>
                        <div style="font-size:16px;float:right;margin:15px 5px 0 0">{{car.brand}}</div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
    </div>
    
    <div class="ui-g-12 ui-md-4">
        <div class="card card-w-title">
            <h1>OrderList</h1>
            <p-orderList [value]="orderListCars" [listStyle]="{'height':'250px'}" [responsive]="true" header="OrderList">
                <ng-template let-car pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <img src="assets/demo/images/car/{{car.brand}}.gif" style="display:inline-block;margin:2px 0 2px 2px" />
                        <div style="font-size:16px;float:right;margin:15px 5px 0 0">{{car.brand}}</div>
                    </div>
                </ng-template>
            </p-orderList>
        </div>
    </div>
    
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Organization Chart</h1>
            <p-organizationChart [value]="data" selectionMode="single" [(selection)]="selectedNode1"></p-organizationChart>
        </div>
    </div>
                            
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>TreeTable</h1>
            <p-treeTable [value]="files1" selectionMode="single" [(selection)]="selectedNode">
                <p-header>Basic</p-header>
                <p-column field="name" header="Name"></p-column>
                <p-column field="size" header="Size"></p-column>
                <p-column field="type" header="Type"></p-column>
            </p-treeTable>
        </div>
    </div>
    
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>DataList - Paginator</h1>
            <p-dataList [value]="cars3" [paginator]="true" [rows]="5" styleClass="cars-datalist">
                <p-header>
                    List of Cars
                </p-header>
                <ng-template let-car pTemplate="item">
                    <div style="border-bottom: 1px solid #bdbdbd" class="clearfix car-item">
                        <img src="assets/demo/images/car/{{car.brand}}.gif" width="48" style="display:inline-block;margin:24px;vertical-align:middle"/>
                        <div class="car-details" style="display:inline-block;vertical-align:middle">
                            <p>{{car.brand}}</p>
                            <p style="color:#757575">{{car.year}} - {{car.color}}</p>
                        </div>
                        <button type="button" pButton icon="ui-icon-search" style="margin:24px 24px 0 0;float:right"></button>
                    </div>
                </ng-template>
            </p-dataList>
        </div>
    </div>
        
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Carousel</h1>
            <p-carousel headerText="Cars" [value]="carouselCars">
                <ng-template let-car pTemplate="item">
                    <div class="ui-g" style="text-align:center">
                        <div class="ui-g-12" style="text-align:Center">
                            <img src="assets/demo/images/car/{{car.brand}}.gif" />
                        </div>
                        <div class="ui-g-6">Vin: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.vin}}</div>
                        
                        <div class="ui-g-6">Year: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.year}}</div>
                        
                        <div class="ui-g-6">Brand: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.brand}}</div>
                        
                        <div class="ui-g-6">Color: </div>
                        <div class="ui-g-6" style="font-weight:bold">{{car.color}}</div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
        
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Tree</h1>
            <p-tree [value]="files2"></p-tree>
        </div>
    </div>
    <div class="ui-g-12">
        <div class="card card-w-title">
            <h1>Schedule</h1>
            <!-- <p-schedule [events]="events" defaultDate="2016-01-12" [header]="scheduleHeader"></p-schedule> -->
        </div>
    </div>
</div>