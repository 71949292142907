
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MonitoringMaster } from '../../modals/monitoring/monitoring-master';
import { GridConfig, GridColumn, EditableGridFieldTypes, Alignment, SelectionMode, GridPaginationEvent } from '../../components/sib-forms/grid/grid.config';
import { DateUtil } from '../../helpers/date.util';

import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { of as observableOf, Subscription } from 'rxjs';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import * as _ from "lodash";
import * as utils from '../../helpers/utils';
import { CampaignService } from '../../services/shared/campaign.service';
import { PaginationData } from '../../modals/paginationdata';

@Component({
  selector: 'sib-monitoringsites',
  templateUrl: './monitoringsites.component.html',
  styleUrls: ['./monitoringsites.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MonitoringsitesComponent implements OnInit {

  @ViewChild('sibGrid')
  gridViewChildRef: GridComponent<MonitoringMaster>;
  monitoringSitesGroup: FormGroup;
  gridConfig: GridConfig<MonitoringMaster> = new GridConfig<MonitoringMaster>();
  totalRecords: number;
  filteredItems: MonitoringMaster[] = [];
  lightTypeDropdown: any[] = [];
  subscription: Subscription;
  filteredMonitoringItems: MonitoringMaster[] = [];

  _campaignMonitoringItems: MonitoringMaster[] = [];
  @Input() set campaignMonitoringItems(campaignMonitoringItems) {
    this._campaignMonitoringItems = campaignMonitoringItems;
    this.filteredItems = _.cloneDeep(this._campaignMonitoringItems);
    this.gridViewChildRef.refresh();
  }

  get campaignMonitoringItems() {
    return this._campaignMonitoringItems;
  }

  @Output() eEmitTotalMonitoringItems: EventEmitter<any> = new EventEmitter();
  @Output() eEmitFilteredMonitoringItems: EventEmitter<MonitoringMaster[]> = new EventEmitter();
  @Output() eEmitImages: EventEmitter<any> = new EventEmitter();

  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    // sortField: '',
    // sortOrder: 1,
    multiSortMetas: []
  };

  columns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr ',
      required: true,
      width: '2vw',
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },


    {
      field: 'customId',
      name: 'HID',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'city',
      name: 'city',
      header: 'City',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '7vw',
      default: true,
      sortable: true,
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },

    {
      field: 'location',
      name: 'location',
      header: 'Location',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '15vw',
      default: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'size',
      name: 'size',
      header: 'Size',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'lightType',
      name: 'lightType',
      header: 'Light',
      required: true,
      editable: false,
      sortable: true,
      width: '3vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },

      displayFn: (data) => {
        if (data.lightType) {
          return HoardingLightEnum[data.lightType]
        } else {
          return '-';
        }
      }
    },
    {
      field: 'monitoringItems.monitoringDate',
      name: 'monitoringItems.monitoringDate',
      header: 'Monitoring Date',
      required: true,
      editable: false,
      sortable: true,
      width: '7vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.monitoringItems) {
          return DateUtil.dategridFormatter(data.monitoringItems.monitoringDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'monitoringItems.images',
      name: 'image',
      header: 'Photos',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '4vw',
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },
    // {
    //   field: 'creativeName',
    //   name: 'creativeName',
    //   header: 'Creative Name',
    //   required: true,
    //   editable: false,
    //   sortable: true,
    //   width: '9vw',
    //   styleClass: 'word-break-wrap',
    //   type: EditableGridFieldTypes.TEXT,
    //   displayFn: (data) => {
    //     if (data.monitoringItems) {
    //       return data.monitoringItems.creativeName
    //     } else {
    //       return '-';
    //     }
    //   },
    // },
  ];

  constructor(
    private campaignService: CampaignService
  ) {
    this.subscription = this.campaignService.$search
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.filteredItems = [];
        this.filteredMonitoringItems = [];
        if (searchTerm) {
          searchTerm = searchTerm.toLowerCase();
          this.campaignMonitoringItems.forEach((item) => {
            if (item.customId.toLowerCase().includes(searchTerm) || item.district.toLowerCase().includes(searchTerm) || item.city.toLowerCase().includes(searchTerm) || item.location.toLowerCase().includes(searchTerm)) {
              this.filteredItems.push(item);
            }
          })
        } else {
          this.filteredItems = _.cloneDeep(this.campaignMonitoringItems);
        }

        this.filteredMonitoringItems = _.cloneDeep(this.filteredItems)
        this.eEmitTotalMonitoringItems.emit(this.filteredItems);
        this.gridViewChildRef.refresh();

      })
  }

  ngOnInit() {
    this.monitoringSitesGroup = new FormGroup({
      gridControl: new FormControl()
    });
    this.setLightDropdown();
    this.setGridConfigObject();
    // this.gridViewChildRef.refresh();
  }

  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new MonitoringMaster();
    this.gridConfig.model = MonitoringMaster;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    // this.gridConfig.rowExpandMode = 'single';
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = false;
    this.gridConfig.lazy = false;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
    this.gridConfig.sortMode = "multiple";
    this.gridConfig.multiSortMeta = this.createMetaSortData();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen

      this.totalRecords = this.filteredItems.length;

      return observableOf(this.filteredItems);
    }
  }

  onFilter(event) {
    this.filteredMonitoringItems = event;
    this.eEmitFilteredMonitoringItems.emit(event);
  }


  getCustomIds() {
    this.campaignMonitoringItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignMonitoringItems);
    const customIds: string[] = [];
    if (this.filteredMonitoringItems.length) {
      this.filteredMonitoringItems.forEach((item) => {
        customIds.push(item.customId);
      });
    } else {
      this.campaignMonitoringItems.forEach((item) => {
        customIds.push(item.customId);
      });
    }
    return customIds;
  }


  /**
   * get the ids for monitoring sites
   *
   * @returns
   * @memberof MonitoringsitesComponent
   */
  getIds() {
    this.campaignMonitoringItems = this.gridViewChildRef.getDataTableInstance()['_value'];

    const ids: string[] = [];
    if (this.filteredMonitoringItems.length && this.filteredMonitoringItems.length !== this.campaignMonitoringItems.length) {
      this.filteredMonitoringItems.forEach((item) => {
        if (item.monitoringItems && item.monitoringItems.id) {
          ids.push(item.monitoringItems.id);
        }
      });
    } else {
      this.campaignMonitoringItems.forEach((item) => {
        if (item.monitoringItems && item.monitoringItems.id) {
          ids.push(item.monitoringItems.id);
        }
      });
    }
    return ids;
  }


  setLightDropdown() {
    this.lightTypeDropdown = utils.createDropdown(HoardingLightEnum, true);
  }

  createMetaSortData() {
    return [{ field: 'location', order: 1 }];
  }

  onSort(event) {
    if (this.filteredMonitoringItems && this.filteredMonitoringItems.length) {
      this.campaignMonitoringItems = _.cloneDeep(this.filteredMonitoringItems);
    } else {
      this.campaignMonitoringItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    }
    this.filteredMonitoringItems = this.campaignMonitoringItems;
    this.searchEvent.multiSortMetas = Object.assign(this.searchEvent.multiSortMetas, event['multisortmeta']);
  }

  getMonitoringItems() {
    this.campaignMonitoringItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    if (this.filteredMonitoringItems.length && this.filteredMonitoringItems.length !== this.campaignMonitoringItems.length) {
      return this.filteredMonitoringItems;
    } else {
      return this.campaignMonitoringItems;
    }
  }



  /**
   * emites images to public page component
   *
   * @param {*} rowData
   * @memberof MountedsitesComponent
   */
  viewImages(rowData) {
    this.eEmitImages.emit(rowData);
  }
}
