/* 
* @file RemoveMonitoring service.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2017
*/


import { Injectable, Injector } from "@angular/core";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';

@Injectable()
export class RemoveMonitoringService extends ApiService<any, any, any> {
    urlPath: string = 'campaignItems';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    removeMultipleMonitoringItem(monitoringItems) {
        var body = JSON.stringify(monitoringItems);

        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'campaignItems/remove/monitoringItems', body, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }).pipe(map((response: Response) => {
                return response;
            }));
        }

    }
}

