import { Injectable, Injector } from "@angular/core";
import * as utils from '../../../../helpers/utils';
import { BASE_URL } from '../../../../services/base-service/api.service';
import { AppUrls } from '../../../../services/urls';
import { CampaignService } from '../../../../services/shared/campaign.service';

@Injectable()
export class CampaignTaskUIService {
    baseUrl: string;

    constructor(
        private injector: Injector,
        private campaignService: CampaignService
    ) {
        this.baseUrl = this.injector.get(BASE_URL);
    }

    approveCampaign(campaign) {
        return this.campaignService.create(campaign, null, AppUrls.APPROVE);
    }

    rejectCampaign(campaign) {
        return this.campaignService.create(campaign, null, AppUrls.REJECT);
    }


}
