import { SystemService } from "./system.service";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class BillingConfigurationService {
    billMargin: Subject<number> = new Subject();
    amountOverDueDays: Subject<number> = new Subject();
    invoiceFreezeDays: Subject<number> = new Subject();
    termsAndConditions: Subject<number> = new Subject();
    invIdCreationStrategy: Subject<string> = new Subject();

    constructor(
        private systemService: SystemService
    ) { }

    getBillingConfiguration() {
        this.systemService.get({ module: "BILLING" }, '/search/by-module')
            .subscribe((configuration) => {
                // ['_embedded']['systemProperties']
                const configurations = configuration;
                this.setBillMargin(configurations);
                this.setAmountOverdueDays(configurations);
                this.setInvoiceFreezeDays(configurations);
                this.setTermsAndConditions(configurations);
                this.setInvoiceIdCreationStrategy(configurations);
            });
    }


    setBillMargin(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'BILL_MARGIN') {
                this.billMargin.next(configurations[i]['value']);
            }
        }
    }

    setAmountOverdueDays(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'AMOUNT_OVERDUE_DAYS') {
                this.amountOverDueDays.next(configurations[i]['value']);
            }
        }
    }

    setInvoiceFreezeDays(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'INVOICE_FREEZE_DAYS') {
                this.invoiceFreezeDays.next(configurations[i]['value']);
            }
        }
    }

    setTermsAndConditions(configurations) {
        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i]['key'] === 'TERM_AND_CONDITION') {
                this.termsAndConditions.next(configurations[i]['value']);
            }
        }
    }

    setInvoiceIdCreationStrategy(configurations) {
        this.invIdCreationStrategy.next(configurations.find(param => param.key === 'INV_ID_CREATION').value);
    }
}
