import { Injectable, Injector, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./base-service/api.service";
import { Http } from "@angular/http";
import { Subject } from "rxjs";

export const MASTER_URL = new InjectionToken<string>('MASTER_URL');

@Injectable()
export class SibMasterService {


    public static authWebConfig = new Subject<any>();
    public baseUrl: string;

    masterConfigCredentials = {
        masterTokenUrl: 'https://showitbig-master.auth0.com/oauth/token',
        config: {
            "grant_type": "client_credentials",
            "audience": "sib-master-api",
            "client_id": "wQ6Rnzq6lKaAffc4sNwXmAhmydj8nrSq",
            "client_secret": "XwoJbpaJezX2tN_eLEZUV9TZ_zVG4HKxx6w4TVjfMCXfQeVUqd2UGRxTNbdtI36k"
        }
    }

    constructor(
        private Http: HttpClient,
        public injector: Injector
    ) {
        this.baseUrl = this.injector.get(MASTER_URL)
    }

    getMasterAccessToken() {
        return this.Http.post(this.masterConfigCredentials.masterTokenUrl, this.masterConfigCredentials.config)
    }

    getAuthConfig(url) {
        return this.Http.get(this.baseUrl + '/vendorConfigs/search/vendor-domain?vendorDomain=' + url)
    }

    setAuthWebConfig(config) {
        SibMasterService.authWebConfig.next(config);
    }
}