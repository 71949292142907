export enum TaskCardStatusEnum {
    ADMIN_GEN_PENDING = "New",
    // ACCOUNTANT_GEN_PENDING = "New",
    ADMIN_GEN_REJECT = "New Reject",
    ACCOUNTANT_GEN_REJECT = "Accountant New Reject",
    EDIT_PENDING = "Edit Pending",
    ADMIN_EDIT_PENDING = "Edit",
    // ACCOUNTANT_EDIT_PENDING = "Edit",
    ADMIN_EDIT_REJECT = "Edit Reject",
    // ACCOUNTANT_EDIT_REJECT = "Accountant Edit Reject",
    // ACCOUNTANT_INVOICE_CANCEL = "Cancel",
    // ACCOUNTANT_INVOICE_CANCEL_WITH_RETENTION = "Cancel",
    ADMIN_CANCEL_PENDING = "Cancel",
    // ACCOUNTANT_CANCEL_PENDING = "Cancel",
    ADMIN_CANCEL_REJECT = "Cancel Reject",
    // ACCOUNTANT_CANCEL_REJECT = "Accountant Cancel Reject",
    ADMIN_FULL_CN_PENDING = "New", //for CN Task Card
}
