<div id="bottomAd" style="display: none">&nbsp;</div>
<div class="generateBill">

    <div *ngIf="isLoading">
        <div class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>


    <div class="ui-g margin-bottom--15">
        <div class="ui-g-4 ctext-left">
            <ul class="c-list-inline">
                <li *ngIf="activeIndex === 0">
                    <sib-back [showBack]="true"></sib-back>
                </li>
                <li *ngIf="activeIndex !== 0">
                    <button class="mtab-primary step-btn" (click)="previousClick()" pButton
                        icon="fa fa-angle-double-left"></button>
                </li>
            </ul>
        </div>
        <div class="ui-g-4 ctext-center">
            <ul class="c-list-inline">
                <li>
                    <p-steps class="c-stepper" [model]="invoiceStepItems" [(activeIndex)]="activeIndex"
                        [readonly]="true" (activeIndexChange)="onActiveIndexChange()"></p-steps>
                </li>
            </ul>
        </div>
        <div class="ui-g-4 ctext-right">
            <ul class="c-list-inline">
                <li>
                    <ul class="c-list-inline">
                        <!-- <li>
                            <button class="mtab-danger margin-right-20" (click)="discardEdit()" pButton label="DISCARD"></button>
                        </li> -->
                        <li *ngIf="activeIndex !== 2" class="margin-bottom-5">
                            <button class="mtab-primary margin-right-20 step-btn" (click)="nextClick()" pButton
                                icon="fa fa-angle-double-right"
                                [disabled]="activeIndex === 0 && rentRows.length === 0 && printRows.length === 0 && mountRows.length === 0"></button>
                        </li>
                        <!-- <li *ngIf="activeIndex === 2">
                            <button type="button" pButton label="RESET" class="mtab-secondary margin-right-20" (click)="resetInvoiceDetails()"></button>
                        </li> -->
                        <li *ngIf="activeIndex === 2">
                            <button type="button" pButton
                                label="{{ invoice.id === null || invoice.id === undefined  ? ( !migratedInvoice ? 'GENERATE': 'MIGRATE') : 'SUBMIT' }}"
                                class="mtab-save" (click)="createInvoice()"
                                [disabled]="disableButton || ((invoiceIdCreationStrategy === 'MANUAL' && !migratedInvoice) ? (manualGeneratedInvoiceId === undefined || manualGeneratedInvoiceId === null) : false)"></button>
                        </li>
                        <li class="ctext-left margin-bottom-7">
                            <p-tieredMenu #showMenu popup="popup" [model]="menu"></p-tieredMenu>
                            <i class="fa fa-ellipsis-v margin-top-6 more-icon   more-icon-wrapper margin-top-7"
                                (click)="showMenu.toggle($event)" aria-hidden="true" title="Menu"></i>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="activeIndex === 0">
        <sib-superbill-hoardings [showSelected]="false" [showBack]="false" [checkbox]="true" [editable]="false"
            [activeIndex]="activeIndex" (eEmitRentRowsSelected)="selectedRentItems($event)"
            [selectedRentRows]="rentRows" [selectedPrintingRows]="printRows" [selectedMountingRows]="mountRows"
            [migratedInvoice]="migratedInvoice" (eEmitPrintingRowsSelected)="selectedPrinitingItems($event)"
            (eEmitChangeSelectedRentItemsValidations)="changeSelectedRentRowsValidationDates($event)"
            (eEmitMountingRowsSelected)="selectedMountingItems($event)" (eEmitDates)="setStartAndEndDates($event)"
            (eEmitCampaign)="setCampaign($event)"></sib-superbill-hoardings>
    </div>

    <div *ngIf="activeIndex === 1">
        <sib-superbill-hoardings [showSelected]="true" [showBack]="false" [checkbox]="false" [editable]="true"
            [selectedRentRows]="rentRows" [selectedPrintingRows]="printRows" [selectedMountingRows]="mountRows"
            [migratedInvoice]="migratedInvoice" (eEmitDates)="setStartAndEndDates($event)"
            (eEmitEditedRentItems)=" setEditedRentRows($event)"
            (eEmitEditedPrintingItems)="setEditedPrintingRows($event)"
            (eEmitEditedMountingItems)="setEditedMountingRows($event)"
            (eRentRowEditGridChange)="onRentGridRowEdit($event)"
            (eRentRowEditNumberGridChange)="onRentGridNumberRowEdit($event)"
            (eRentCalenderGridChange)="onRentGridCalendarChange($event)"
            (ePrintRowEditGridChange)="onPrintGridRowEdit($event)"
            (ePrintRowEditNumberGridChange)="onPrintGridNumberRowEdit($event)"
            (eMountRowEditGridChange)="onMountGridRowEdit($event)"
            (eMountRowEditNumberGridChange)="onMountGridNumberRowEdit($event)" (eEmitNoOfDays)="setNoOfDays($event)"
            (eEmitInvoiceRentItems)="setInvoiceRentItems($event)" [days]="noOfDays"></sib-superbill-hoardings>
    </div>

    <div *ngIf="activeIndex === 2">
        <div class="card padding-0">
            <sib-add-invoice-details #invoiceDetails *ngIf="campaign?.id !== null && !migratedInvoice"
                [invoice]="invoice" (eEmitSelectedCompany)="setSelectedCompany($event)"
                [initialInvoiceId]="initialInvoiceId" (eEmitSelectedBranch)="setSelectedBranch($event)"
                (eEmitSupplier)="setSupplier($event)" (eEmitSelectedSupplierBranch)="setSelectedSupplierBranch($event)"
                (eEmitIsGrouped)="setGrouped($event)" (eEmitSelectedPO)="setSelectedPO($event)"
                (eEmitInvoiceNote)="setInvoiceNote($event)" (eEmitInternalNote)="setInternalNote($event)"
                (eEmitSelectedPlaceOfSupply)="setPlaceOfSupply($event)"
                (eEmitPrintConfiguration)="setPrintConfiguration($event)" (eEmitInvoiceId)="setManualInvoiceId($event)"
                (eEmitSelectedInvoiceFormat)="setSelectedInvioceFormat($event)" [migratedInvoice]="migratedInvoice"
                (eEmitChangedPrintType)="onPrintTypeChange($event)" (eEmitPreviewPrintItems)="previewPrintItems($event)"
                (eEmitChangedPrintLetterHead)="onPrintLetterHeadChange($event)"
                (eEmitInvoiceIdCreationStrategy)="getInvoiceIdCreationStrategy($event)">
            </sib-add-invoice-details>
            {{isFormValid(invoiceDetails)}}
        </div>
        <div class="card padding-0">
            <sib-add-invoice-details #invoiceDetails *ngIf="campaign?.id !== null && migratedInvoice"
                [invoice]="invoice" (eEmitSelectedCompany)="setSelectedCompany($event)"
                (eEmitSelectedBranch)="setSelectedBranch($event)" (eEmitSupplier)="setSupplier($event)"
                (eEmitSelectedSupplierBranch)="setSelectedSupplierBranch($event)" (eEmitIsGrouped)="setGrouped($event)"
                (eEmitSelectedInvoiceFormat)="setSelectedInvioceFormat($event)"
                (eEmitSelectedPO)="setSelectedPO($event)" (eEmitInvoiceNote)="setInvoiceNote($event)"
                (eEmitInternalNote)="setInternalNote($event)" (eEmitInvoiceRefId)="setInvoiceRefId($event)"
                (eEmitSelectedPlaceOfSupply)="setPlaceOfSupply($event)"
                (eEmitPrintConfiguration)="setPrintConfiguration($event)" [migratedInvoice]="migratedInvoice">
            </sib-add-invoice-details>
            {{isFormValid(invoiceDetails)}}
        </div>
        <div class="padding-0 margin-0">
            <sib-invoice-preview #invoicePreview [invoice]="invoice" [showSortRearrange]=true
                [invoiceFormat]="invoice?.invoiceFormat" (eEmitCampAndLspDiff)="setCampAndLspDiff($event)"
                (eEmitRowsOrder)="onRowsReorder($event)">
            </sib-invoice-preview>
        </div>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
        <p-footer>
            <button type="button" pButton class="mtab-save margin-hori-5" label="YES" (click)="cd.accept()"></button>
            <button type="button" pButton class="mtab-secondary" label="NO, CONTINUE" (click)="cd.reject()"></button>
        </p-footer>
    </p-confirmDialog>

</div>