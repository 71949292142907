import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, SharedModule, InputTextModule, FileUploadModule, AutoCompleteModule, DropdownModule, CalendarModule, TooltipModule, SpinnerModule, ProgressSpinnerModule, KeyFilterModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim";
import { CurrencyMainPipe } from "../../helpers/currency.pipe/currency.main.pipe";
import { MapModule } from "../map/map.module";
import { HoardingGroupEditDialogComponent } from "./hoarding-group-edit-dialog.component";
import { ListHoardingsModule } from "../list-hoardings/list-hoardings.module";
import { SibGridModule } from "../../../components/sib-forms/grid/grid.module";
import { ContractService } from "../../../contracts/services/contract.services";
import { ContractUiService } from "../../../contracts/services/contract-ui.services";
import { HoardingCategoryService } from "../../../services/shared/hoardingCategory.service";
import { HoardingService } from "../../../contracts/services/hoardings.service";
import { PhotoDialogModule } from "../photo-dialog/photo-dialog.module";
import { ContractsActionDispatcher } from "../../../contracts/action-dispatcher/action-dispatcher";
import { ContractActions } from "../../../contracts/actions/contract-actions";
import { NotificatoinsService } from "../../../services/notifications/notifications.service";


@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        FileUploadModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        TooltipModule,
        InputTrimModule,
        SpinnerModule,
        CurrencyMainPipe,
        ProgressSpinnerModule,
        KeyFilterModule,
        MapModule,
        ListHoardingsModule,
        SibGridModule,
        PhotoDialogModule,
    ],

    declarations: [
        HoardingGroupEditDialogComponent
    ],

    exports: [
        HoardingGroupEditDialogComponent,
    ],

    providers: [ContractService, ContractUiService, HoardingCategoryService, HoardingService, ContractsActionDispatcher, ContractActions, 
        // NotificatoinsService
    ]
})

export class HoardingGroupEditDialogModule { }
