
    <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
    <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="!child.routerLink" 
            [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
       
            <span>{{child.label}}</span>
            <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
            <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
        </a>

        <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="child.routerLink"
            [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
           <div style="display:inline">
           <i class="{{child.icon}} menu-icon" aria-hidden="true"> </i> 
            <span>{{child.label}}</span>
            <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
            <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
            </div>
        </a>
        <div class="layout-menu-tooltip">
            <div class="layout-menu-tooltip-arrow"></div>
            <div class="layout-menu-tooltip-text">{{child.label}}</div>
        </div>
        <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
            [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ? 'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
    </li>
</ng-template>
    