import { Injectable } from "@angular/core";
import { GoogleChartsBaseService } from "./shared/chart.base.service";
import { StackedBarChartConfig } from "../modals/charts/stackedBarChart.config";

declare var google: any;

@Injectable()
export class GoogleStackedBarChartService extends GoogleChartsBaseService {

  constructor() { super(); }

  public buildStackedBarChart(elementId: String, data: any[], config: StackedBarChartConfig): void {
    const chartFunc = () => new google.visualization.BarChart(document.getElementById(elementId + ''));
    const options = {
      hAxis: config.hAxis,
      vAxis: config.vAxis,
      title: config.title,
      titleTextStyle: config.titleTextStyle,
      isStacked: config.isStacked,
      bars: config.bars,
      height: config.height,
      width: config.width,
      chartArea: config.chartArea,
      legend: config.chartLegend,
      colors: config.colors,
      bar: config.bar,
      annotations: config.annotations,
      series: config.series,
    };

    this.buildChart(data, chartFunc, options, config);
  }
}
