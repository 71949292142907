import { NgModule, forwardRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { SharedModule, InputTextModule, ButtonModule, TooltipModule, TabViewModule, TieredMenuModule, DialogModule, MultiSelectModule, DropdownModule, ProgressSpinnerModule } from "primeng/primeng";
import { PublicPageComponent } from "./public-page.component";
import { SibGridModule } from "../components/sib-forms/grid/grid.module";
import { TotalsitesComponent } from './totalsites/totalsites.component';
import { MountedsitesComponent } from './mountedsites/mountedsites.component';
import { MonitoringsitesComponent } from './monitoringsites/monitoringsites.component';
import { UnmountingsitesComponent } from './unmountingsites/unmountingsites.component';
import { MapModule } from '../shared/components/map/map.module';
import { PhotoDialogModule } from '../shared/components/photo-dialog/photo-dialog.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        SibGridModule,
        TabViewModule,
        TieredMenuModule,
        DialogModule,
        MultiSelectModule,
        DropdownModule,
        ProgressSpinnerModule,
        MapModule,
        PhotoDialogModule
    ],

    declarations: [
        PublicPageComponent,
        TotalsitesComponent,
        MountedsitesComponent,
        MonitoringsitesComponent,
        UnmountingsitesComponent,
    ],

    providers: [
    ],

    exports: [
        PublicPageComponent
    ]
})
export class PublicPageModule { }