<div id="bottomAd" style="display: none">&nbsp;</div>
<div class="bill-view">

    <div *ngIf="isLoading" class="loading">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="ui-g ctext-right">
        <ul class="c-list-inline">
            <li class="float-left">
                <sib-back></sib-back>
            </li>

            <ng-container
                *ngIf="invoice.status === 'ADMIN_EDIT_APPROVE' || invoice.status === 'BILL_GENERATED' || invoice.status === 'ADMIN_EDIT_REJECT'">
                <ng-container *permission="'create:invoices'">
                    <li *ngIf="versions && versions?.length > 0">
                        <label class="font-12 font-weight-500 label-vCenter margin-right-5">Versions</label>
                        <button pButton class="mtab-secondary c-edit-btn border-rad-2 font-button" title="View Versions"
                            icon="fa fa-object-ungroup" (click)="versionMenu.toggle($event)"></button>
                    </li>
                </ng-container>
            </ng-container>

            <ng-container
                *ngIf="invoice.status === 'ADMIN_GEN_APPROVE' || invoice.status === 'ADMIN_EDIT_APPROVE' || invoice.status === 'BILL_GENERATED' || invoice.status === 'ADMIN_GEN_REJECT'|| invoice.status === 'ADMIN_EDIT_REJECT' || invoice.status === 'ADMIN_CANCEL_REJECT' || invoice.status === 'INVOICE_CANCEL' || invoice.status === 'INVOICE_CANCEL_WITH_RETENTION' || invoice.status === 'FULL_CN_APPROVED' || invoice.status === 'PARTIAL_CN_APPROVED'">

                <ng-container *permission="'update:tally-invoice'">
                    <li *ngIf="checkIsTallyEntered() !== 'N'">
                        <label class="font-12 font-weight-500 label-vCenter margin-right-5">Entered in Tally</label>
                        <p-checkbox name="tallyEntered" binary="true" inputId="tallyEntered"
                            [(ngModel)]="isTallyEnteredBoolean" (onChange)="onTallyEnteredChange($event)"></p-checkbox>
                    </li>
                </ng-container>

                <ng-container *permission="'update:invoice-print'">
                    <li *ngIf="checkIsPrinted() !== 'N'">
                        <label class="font-12 font-weight-500 label-vCenter margin-right-5">Mark as Printed</label>
                        <p-checkbox name="printed" binary="true" inputId="printed" [(ngModel)]="isPrintedBoolean"
                            (onChange)="onInvoicePrintedChange($event)"></p-checkbox>
                    </li>
                </ng-container>

                <ng-container *permission="'print:invoice'">
                    <li class="ctext-left printMenuClass" *ngIf="checkIsPrinted() !== 'N'">

                        <label class="font-12 font-weight-500 label-vCenter margin-right-5">Invoice Printing
                            Options</label>
                        <button pButton class="mtab-secondary c-edit-btn border-rad-2 font-button" title="Print"
                            icon="fa fa-print" (click)="togglePrintMenu($event)"></button>
                    </li>
                </ng-container>

            </ng-container>
            <ng-container>
                <li
                    *ngIf="((invoice?.printingItems && invoice?.printingItems?.length) || (invoice?.mountingItems && invoice?.mountingItems?.length)) && (!selectedInvoiceFormat || (selectedInvoiceFormat !== 'AS_PER_RO_INCLUSIVE' && selectedInvoiceFormat !== 'AS_PER_RO_EXCLUSIVE'))">

                    <label *ngIf="!invoice?.grouped" class="font-12 font-weight-500 label-vCenter"> Group
                        Printing/Mounting
                    </label>
                    <label *ngIf="invoice?.grouped" class="font-12 font-weight-500 label-vCenter"> Ungroup
                        Printing/Mounting
                    </label>
                    <p-checkbox name="grouped" binary="true" inputId="grouped" [(ngModel)]="grouped"
                        (onChange)="toggleView($event)"></p-checkbox>

                </li>
            </ng-container>
            <ng-container *permission="'compare:invoices'">

                <li *ngIf="invoice.status === 'ADMIN_EDIT_PENDING'">
                    <label *ngIf="!showChanges" class="font-12 font-bold label-vCenter"> VIEW CHANGES </label>
                    <label *ngIf="showChanges" class="font-12 font-bold label-vCenter"> HIDE CHANGES </label>

                    <button #viewChangesBtn class="mtab-secondary c-edit-btn border-rad-2" (click)="viewChanges()"
                        pButton title="{{showChanges ? 'Hide Changes' : 'View Changes'}}"
                        icon="{{showChanges ? 'fa fa-eye-slash' : 'fa fa-eye'}}">
                    </button>
                </li>
            </ng-container>
            <ng-container *permission="'compare:invoices'">

                <li *ngIf="invoice.purchaseOrders.length > 0">
                    <label class="font-12 font-weight-500 label-vCenter compare-label"> PO </label>

                    <button #compareBtn class="mtab-secondary c-edit-btn border-rad-2 compare-icon"
                        (click)="viewDocuments()" pButton icon="fa fa-file">

                    </button>

                </li>
                <li *ngIf="invoice.purchaseOrders.length === 0" class="margin-right-0"></li>
            </ng-container>
            <ng-container>
                <li *ngIf="checkPermission('approve:invoices') || checkPermission('approve:account-invoices')">
                    <button *ngIf="checkPermission('approve:invoices') && !checkPermission('approve:account-invoices')"
                        class="mtab-save" (click)="approveRequest()" pButton label="APPROVE"
                        [disabled]="invoice.status !== 'ADMIN_GEN_PENDING' && invoice.status !== 'ADMIN_EDIT_PENDING' && invoice.status !== 'ADMIN_CANCEL_PENDING' && invoice.status !== 'ADMIN_FULL_CN_PENDING'">
                    </button>
                    <button *ngIf="!checkPermission('approve:invoices') && checkPermission('approve:account-invoices')"
                        class="mtab-save" (click)="approveRequest()" pButton label="APPROVE"
                        [disabled]="invoice.status !== 'ACCOUNTANT_GEN_PENDING' && invoice.status !== 'ACCOUNTANT_EDIT_PENDING' && invoice.status !== 'ACCOUNTANT_CANCEL_PENDING'">
                    </button>
                    <button *ngIf="checkPermission('approve:invoices') && checkPermission('approve:account-invoices')"
                        class="mtab-save" (click)="approveRequest()" pButton label="APPROVE"
                        [disabled]="invoice.status !== 'ADMIN_GEN_PENDING' && invoice.status !== 'ACCOUNTANT_GEN_PENDING' && invoice.status !== 'ADMIN_EDIT_PENDING' && invoice.status !== 'ACCOUNTANT_EDIT_PENDING' && invoice.status !== 'ACCOUNTANT_CANCEL_PENDING' && invoice.status !== 'ADMIN_CANCEL_PENDING' && invoice.status !== 'ADMIN_FULL_CN_PENDING'">
                    </button>
                </li>
            </ng-container>
            <ng-container>
                <li *ngIf="checkPermission('approve:invoices') || checkPermission('approve:account-invoices')">
                    <button *ngIf="checkPermission('approve:invoices') && !checkPermission('approve:account-invoices')"
                        class="mtab-danger" (click)="rejectRequest()" pButton label="REJECT"
                        [disabled]="invoice.status !== 'ADMIN_GEN_PENDING' && invoice.status !== 'ADMIN_EDIT_PENDING' &&  invoice.status !== 'ADMIN_CANCEL_PENDING' && invoice.status !== 'ADMIN_FULL_CN_PENDING'">
                    </button>
                    <button *ngIf="!checkPermission('approve:invoices') && checkPermission('approve:account-invoices')"
                        class="mtab-danger" (click)="rejectRequest()" pButton label="REJECT"
                        [disabled]="invoice.status !== 'ACCOUNTANT_GEN_PENDING' && invoice.status !== 'ACCOUNTANT_EDIT_PENDING' && invoice.status !== 'ACCOUNTANT_CANCEL_PENDING'">
                    </button>
                    <button *ngIf="checkPermission('approve:invoices') && checkPermission('approve:account-invoices')"
                        class="mtab-danger" (click)="rejectRequest()" pButton label="REJECT"
                        [disabled]="invoice.status !== 'ADMIN_GEN_PENDING' && invoice.status !== 'ACCOUNTANT_GEN_PENDING' && invoice.status !== 'ADMIN_EDIT_PENDING' && invoice.status !== 'ACCOUNTANT_EDIT_PENDING' && invoice.status !== 'ACCOUNTANT_CANCEL_PENDING' && invoice.status !== 'ADMIN_CANCEL_PENDING' && invoice.status !== 'ADMIN_FULL_CN_PENDING'">
                    </button>
                </li>
            </ng-container>
            <ng-container>


                <li class="ctext-left"
                    *ngIf="(checkPermission('request:edit-invoices') || checkPermission('request:cancel-invoices') || checkPermission('edit:invoices'))">
                    <div
                        *ngIf="!editDisable ? !compareStatus(invoice.status, disablePendingCancelStatus) : (!compareStatus(invoice.status, disablePendingCancelStatus) && (checkPermission('readAll') || userRole === 'Sales Rep'))">
                        <!-- *ngIf="!editDisable ? !compareStatus(invoice.status, disablePendingCancelStatus) : (!compareStatus(invoice.status, disablePendingCancelStatus) && checkPermission('readAll'))" -->
                        <div class="applyWidth"
                            [ngClass]="{'applyWidthforMenuitem' : (userRole === 'Sales Rep' && invoice.status != 'ADMIN_EDIT_REJECT' && invoice.status != 'ADMIN_GEN_REJECT')}">
                            <!-- [ngClass]="{'applyWidthForCancelMenu' : (userRole === 'Sales Rep'), 'applyWidthforMenuitem' : (userRole !== 'Sales Rep')}"> -->
                            <!-- [ngClass]="{'applyWidthforReject' : (userRole === 'Sales Rep' && (invoice.status === 'ADMIN_EDIT_REJECT' || invoice.status === 'ADMIN_GEN_REJECT')), 'applyWidthforSrMenuitem' : (userRole === 'Sales Rep' && invoice.status != 'ADMIN_EDIT_REJECT' && invoice.status != 'ADMIN_GEN_REJECT')}" -->
                            <p-tieredMenu #showMenu popup="false" [model]="moreMenu"></p-tieredMenu>
                        </div>
                        <i class="fa fa-ellipsis-v more-icon   more-icon-wrapper  margin-top-2"
                            (click)="showMenu.toggle($event)" aria-hidden="true" title="Menu"></i>
                    </div>
                </li>
            </ng-container>
        </ul>
        <div>

        </div>

    </div>

    <!-- <sib-billing-notes *ngIf="displayBillingNotes" [displayBillingNotes]="displayBillingNotes" [invoice]="invoice"
        (displayReset)="hideBillingNotesComponent()"></sib-billing-notes> -->



    <div id="sidebar">
        <div id="split-bar"></div>
        <div class="ui-g card padding-0" *ngIf=migratedInvoice>
            <div class="ui-g-3">
                <table>
                    <tr>
                        <th>Status:</th>
                        <td class="{{getClass(invoice?.status)}}">{{getStatus(invoice?.status)}}</td>
                    </tr>
                </table>
            </div>
            <div class="ui-g-3">
                <table>
                    <tr>
                        <th>Employee:</th>
                        <td>{{invoice?.campaign?.employee?.fullName}}</td>
                    </tr>
                </table>
            </div>
            <div class="ui-g-3">
                <table>
                    <tr>
                        <th>Tally Reference Id:</th>
                        <td *ngIf="invoice?.refInvoiceId != null">{{invoice?.refInvoiceId}}</td>
                        <td *ngIf="invoice?.refInvoiceId === null"> N/A</td>
                    </tr>
                </table>
            </div>
            <div class="ui-g-3">
                <table>
                    <tr>
                        <th>Note:</th>
                        <td *ngIf="invoice?.billingNotes?.length > 0"><a class="note-link"
                                (click)="displayBillingNotes = true">{{invoice?.billingNotes[invoice.billingNotes.length
                                - 1]?.message}}</a></td>
                        <td *ngIf="invoice?.billingNotes?.length === 0">N/A</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="ui-g card padding-0" *ngIf=!migratedInvoice>
            <div class="ui-g-4">
                <table>
                    <tr>
                        <th>Status:</th>
                        <td class="{{getClass(invoice?.status)}}">{{getStatus(invoice?.status)}}</td>
                    </tr>
                </table>
            </div>
            <div class="ui-g-4">
                <table>
                    <tr>
                        <th>Employee:</th>
                        <td>{{invoice?.campaign?.employee?.fullName}}</td>
                    </tr>
                </table>
            </div>
            <div class="ui-g-4">
                <table>
                    <tr>
                        <th>Note:</th>
                        <td *ngIf="invoice?.billingNotes?.length > 0"><a class="note-link"
                                (click)="displayBillingNotes = true">{{invoice?.billingNotes[invoice.billingNotes.length
                                - 1]?.message}}</a></td>
                        <td *ngIf="invoice?.billingNotes?.length === 0">N/A</td>
                    </tr>
                </table>
            </div>
        </div>



        <div class="ui-g-12 padding-0 margin-0">
            <sib-invoice-preview #invoicePreview *ngIf="invoice.id" [invoice]="invoice" [tempInvoice]="tempInvoice"
                [isInvFormatFullDetail]="isInvFormatFullDetail" [invoiceFormat]="invoice?.invoiceFormat"
                (eEmitSelectedInvoiceFormat)="setSelectedInvoiceFormat($event)" [showChanges]="showChanges"
                [showSortRearrange]=true [revert]="revert" (eEmitRowsOrder)="onRowsReorder($event)"
                [migratedInvoice]="migratedInvoice">
            </sib-invoice-preview>
        </div>

        <p-dialog header="{{header}}" [(visible)]="displayConfirmationDialog" [width]="320" modal="true"
            [blockScroll]="true" [autoAlign]="false">
            <div *ngIf="CNApplyRequest">
                <div class="ui-g ui-float-label margin-top-5 cancel-reason">
                    <p-dropdown #reasons="ngModel" [options]="cnReasons" [autoWidth]="false" name="cnReasons"
                        appendTo="body" [(ngModel)]="invoice.remark" [autoDisplayFirst]="false">
                    </p-dropdown>
                    <label>{{remark}}</label>

                </div>
                <br>
                <div class="ui-g ui-float-label">
                    <textarea #textarea rows="2" cols="30" pInputTextarea [(ngModel)]="billingNote.message" required
                        style="width : 100%"></textarea>
                    <label>{{reason}}</label>
                </div>
            </div>

            <div class="ui-g ui-float-label margin-top-5 cancel-reason" *ngIf="!CNApplyRequest">
                <p-dropdown #cancellationReasons="ngModel" [options]="invoiceCancellationReasons" [autoWidth]="false"
                    name="cancellationReasons" [(ngModel)]="billingNote.message" [autoDisplayFirst]="false"
                    appendTo="body">
                </p-dropdown>
                <label>{{reason}}</label>

            </div>

            <br>
            <div class="ctext-center">
                <button type="button" pButton class="mtab-save margin-hori-5" label="SEND"
                    [disabled]="!billingNote.message" (click)="sendRequest()"></button>
                <button type="button" pButton class="mtab-secondary" label="CANCEL" (click)="cancelRequest()"></button>
            </div>
        </p-dialog>

        <!-- This dialog is for partial apply cn-->
        <p-dialog header="{{header}}" [(visible)]="displayPartialApplyCNDiaglog" [width]="300" modal="true"
            (onShow)="onPartialCNDialog()" (onHide)="cancelApplyCNRequest()" [autoAlign]="false">
            <div *ngIf="isLoading" class="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div>Invoice No : {{invoice?.invoiceId}}</div><br>
            <form #partialCNDialogForm="ngForm">
                <div class="ui-g ui-float-label margin-top-10 ">
                    <input #amountPartCN="ngModel" type="text" [pattern]="decimalNumbers" pInputText
                        [ngClass]="{ 'amount' : disableButton}" name="Amount"
                        pTooltip="{{amountPartCN.hasError('pattern')? 'Number with max two decimal and <= '+ differPartialCNTotamt :'Amount should be <= '+differPartialCNTotamt}}"
                        (input)="validamount($event)" [(ngModel)]="amount" style="width : 100%" title="" required>
                    <label>Enter amount (Without GST)</label>
                </div><br>
                <div class="ui-g ui-float-label margin-top-10">
                    <input type="text" [(ngModel)]="amountWithGST" [disabled]="true" pInputText name="AmonutWithGST"
                        style="width : 100%">
                    <label>Amount with GST </label>
                </div><br>
                <!-- <div class="ui-g ui-float-label margin-top-10">
                    <textarea #textarea rows="2" cols="30" pInputTextarea [(ngModel)]="invoice.remark" required
                        name="remark" style="width : 100%"></textarea>
                    <label>{{remark}}</label>
                </div> -->
                <div class="ui-g ui-float-label margin-top-7 cancel-cn-reason">
                    <p-dropdown #partialReasons="ngModel" [options]="cnReasons" [autoWidth]="false"
                        name="partialCnReasons" appendTo="body" [(ngModel)]="invoice.remark" [autoDisplayFirst]="false">
                    </p-dropdown>
                    <label>{{remark}}</label>

                </div>
                <br>
                <div class="ui-g ui-float-label margin-top-10">
                    <textarea #textarea rows="1" cols="30" pInputTextarea [(ngModel)]="billingNote.message" required
                        name="reason" style="width : 100%"></textarea>
                    <label>{{reason}}</label>
                </div><br>
                <div class="ctext-center">
                    <button type="button" pButton class="mtab-save margin-hori-5" label="SEND"
                        [disabled]="!partialCNDialogForm.form.valid || disableButton"
                        (click)="sendApplyCNRequest()"></button>
                    <button type="button" pButton class="mtab-secondary" label="CANCEL"
                        (click)="cancelApplyCNRequest()"></button>
                </div>
            </form>

        </p-dialog>

        <p-dialog header="{{appRejHeader}}" [(visible)]="displayApproveRejectDialog" [width]="270"
            (onHide)="cancelAppRejRequest()">
            <div *ngIf="showRejectionDropdown" class="ui-g">
                <p-dropdown [style]="{'width':'130px'}" [options]="dialogDropdownOptionsRejection"
                    placeholder="Select a Reason" [optionLabel]="label" [(ngModel)]="selectedReason"></p-dropdown>
                <br>
                <br>
            </div>
            <div *ngIf="showApprovalDropdown" class="ui-g">
                <p-dropdown [style]="{'width':'130px'}" [options]="dialogDropdownOptionsApproval"
                    placeholder="Select a Reason" [optionLabel]="label" [(ngModel)]="selectedReason"></p-dropdown>
                <br>
                <br>
            </div>
            <div *ngIf="!showRejectionDropdown && !showApprovalDropdown" class="ui-g">
                <textarea #appRejTextarea xcrows="3" cols="30" pInputTextarea [(ngModel)]="billingNote.message"
                    placeholder="{{appRejReason}}"></textarea>
            </div>
            <div *ngIf="(showRejectionDropdown || showApprovalDropdown) && selectedReason === 'Other'" class="ui-g">
                <textarea #appRejTextarea rows="3" cols="30" pInputTextarea [(ngModel)]="billingNote.message"
                    placeholder="{{appRejReason}}"></textarea>
            </div>
            <br>
            <button type="button" pButton class="mtab-save margin-hori-5" label="OK"
                [disabled]="!billingNote.message && !(!billingNote.message && selectedReason === 'CN')"
                (click)="performAppRejRequest()"></button>
            <button type="button" pButton class="mtab-secondary" label="CANCEL"
                (click)="cancelAppRejRequest()"></button>
        </p-dialog>


        <p-overlayPanel #printMenu>
            <sib-print-pannel-layout [printConfig]="invoice?.printConfiguration"
                [selectedInvoiceFormat]="selectedInvoiceFormat"
                (eEmitSelectedInvoiceFormat)="setSelectedInvoiceFormat($event)"
                (eEmitSavePrintInstructions)="savePrintingInstructions($event)"
                (eEmitPreviewPrintItems)="previewPrintItems($event)" (eEmitPrintItems)="printItems($event)"
                (eEmitChangedPrintType)="onPrintTypeChange($event)"
                (eEmitChangedPrintLetterHead)="onPrintLetterHeadChange($event)">
            </sib-print-pannel-layout>
        </p-overlayPanel>

        <p-overlayPanel #versionMenu class="menu-items">
            <ul class="version-list-inline">
                <li *ngFor="let version of versions; let index='index'">
                    <div *ngIf="index === 0">
                        <a (click)="showVersion(version, (invoice.status === 'ADMIN_EDIT_REJECT') ? true : false)"
                            title="view invoice">{{version}}</a>
                    </div>
                    <div *ngIf="index !== 0">
                        <a (click)=" showVersion(version, false)" title="view invoice">{{version}}</a>
                    </div>
                </li>
            </ul>
        </p-overlayPanel>
        <p-dialog *ngIf="displayDocumentComponent" header="View Documents" [closeOnEscape]="true"
            [(visible)]="displayDocumentComponent" [modal]="true" showEffect="fade" [width]="1000"
            (onHide)="hideDocumentDialog()" [baseZIndex]=10000 [autoZIndex]="true">
            <p-header>
                <span *ngIf="showLoader" class="process-loader">
                    <p-progressSpinner [style]="{width: '20px', height: '20px', top: '5px' }" strokeWidth="8"
                        fill="#EEEEEE" animationDuration="2s"></p-progressSpinner> Processing...
                </span>
            </p-header>
            <sib-document *ngIf="displayDocumentComponent" [campaign]="invoice"
                [viewDocumentsFromBilling]="viewDocumentsFromBilling"></sib-document>
        </p-dialog>

        <sib-billing-notes *ngIf="displayBillingNotes" [displayBillingNotes]="displayBillingNotes" [invoice]="invoice"
            (displayReset)="hideBillingNotesComponent()"></sib-billing-notes>

        <!-- <sib-compare-bills [displayCompareBillsDialog]="displayCompareBillsDialog" [editedInvoice]="invoice" [lastInvoice]="lastInvoice"
        (displayReset)="hideCompareBillsDialog()"></sib-compare-bills> -->


        <!-- <p-dialog class="customPdfDialog" header="View Invoice PDF" [(visible)]="displayPDFDialog" [modal]="true"
        showEffect="fade" (onHide)="displayPDFDialog = false" [styleClass]="'fullscreen-dialog'">
        <iframe *ngIf="safePdfUrl" [src]="safePdfUrl" [name]="safePdfName"></iframe>
    </p-dialog> -->

        <sib-preview-send-dialog *ngIf="displayPreviewSendDialog" [display]="displayPreviewSendDialog"
            [subView]="subView" [source]="invoice" [correspondence]="correspondence"
            (displayReset)="displayPreviewSendDialogReset($event)" (eEmitSendMail)="sendMail($event)">
        </sib-preview-send-dialog>



        <!-- changes for removing tally invoice id for vendor - chitra -->
        <!-- <p-dialog header="Tally Invoice Id" [(visible)]="displayTallyDialog" [modal]="true" showEffect="fade" (onHide)="hideTallyDialog()">
        <span class="md-inputfield">
            <input #tallyInvoiceId class="width100" id="tallyId" type="text" pInputText name="tallyId" [(ngModel)]="defaultTallyId"
                required />
            <label for="tallyId">Tally Id
                <span class="required">* </span>
            </label>
        </span>

        <div class="ctext-center padding-10 padding-top-20">
            <button class="mtab-save margin-hori-5" type="button" pButton label="SAVE" (click)="saveTallyInvoiceId()"
                [disabled]="!tallyInvoiceId.value || (tallyInvoiceId.value === invoice?.tallyInvoiceId)"></button>
            <button class="mtab-secondary" type="button" pButton label="CANCEL" (click)="cancelTallyInvoiceId()"></button>
        </div>
    </p-dialog> -->

        <p-dialog header="Revert Version" [modal]="true" [(visible)]="displayRevertVersionDialog"
            (onHide)="hideRevertVersionDialog()" [autoAlign]="false" showEffect="fade"
            [styleClass]="'fullscreen-dialog'">

            <div class="ui-g ctext-right" *ngIf="previousVersionInvoice && previousVersionInvoice?.id">
                <ul class="c-list-inline">
                    <ng-container *permission="'compare:invoices'">
                        <li>
                            <label *ngIf="!showChanges" class="font-12 font-bold label-vCenter"> VIEW CHANGES </label>
                            <label *ngIf="showChanges" class="font-12 font-bold label-vCenter"> HIDE CHANGES </label>
                            <button #viewChangesBtn class="mtab-secondary c-edit-btn border-rad-2"
                                (click)="viewChanges()" pButton
                                title="{{showChanges ? 'Hide Changes' : 'View Changes'}}"
                                icon="{{showChanges ? 'fa fa-eye-slash' : 'fa fa-eye'}}">
                            </button>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <sib-invoice-preview *ngIf="versionInvoice.id && displayRevertVersionDialog" [invoice]="versionInvoice"
                [tempInvoice]="previousVersionInvoice" [showChanges]="showChanges">
            </sib-invoice-preview>
            <div class="ctext-center padding-top-10" *ngIf="showRevert && displayRevertVersionDialog">
                <button type="button" pButton class="mtab-save margin-hori-5" label="ROLLBACK TO VERSION"
                    (click)="revertInvoiceVersion()"></button>
            </div>
        </p-dialog>

        <sib-update-invoice-dialog *ngIf="displayUpdateInvoiceDialog"
            [displayUpdateInvoiceDialog]="displayUpdateInvoiceDialog" (displayReset)="onUpdateInvoiceDialogHide()"
            (updatedInvoice)="onInvoiceUpdate($event)" [invoice]="invoice" [migratedInvoice]="migratedInvoice">
        </sib-update-invoice-dialog>

    </div>
</div>