
import { empty as observableEmpty, of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
/* 
* @file Plan service.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/

import { ApiService, BASE_URL } from "../base-service/api.service";
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { RequestOptions, Headers } from "@angular/http";
import { Plan } from "../../modals/plans/plan";
import { ResponseContentType } from '@angular/http';
import { NotificatoinsService } from "../notifications/notifications.service";
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from './notifications-messages';
@Injectable({
    providedIn: 'root'
})
export class PlanService extends ApiService<any, any, any> {
    // urlPath: string = 'plans?projection=planProjection';
    urlPath: string = 'plans/search';
    baseUrl: string;
    plan: Plan;


    constructor(private Http: HttpClient,
        private Injector: Injector,
        private notificationServcie: NotificatoinsService, ) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    planRotationalUpdate(planId: string, isRotational: any) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'plans/markRotational', null, { params: { 'planId': planId, 'isRotational': isRotational } }).pipe(map((response) => {
                return response;
            }), catchError((error) => {
                return error;
            }));
        }

    }
    /**
     * API call for gettign all the plans 
     */
    getAllPlans() {

        if (navigator.onLine) {
            let headers = {
                headers: new HttpHeaders()
            };
            return this.Http.get(this.baseUrl + 'plans?sort=updatedDate');
        }
    }
    /**
     * 
     * API request to URL passed
     * @param  {} url
     * @returns Observable
     */
    getByURL(url): Observable<any> {

        if (navigator.onLine) {
            let headers = {
                headers: new HttpHeaders()
            }
            return this.Http.get(url).pipe(map((res: Response) => {
                return res;
            }), catchError((err) => {
                // TODO: Error handling
                console.log(err);
                return err;
            }));
        }

    }

    /**
     * Settign Plan to this scope
     * @param  {} planData
     */
    setPlan(planData) {
        this.plan = planData;
    }

    /**
     * Fetching all the Hoardings based on Plan ID
     * @param  {} planId
     */
    getHoardings(planId) {

        if (navigator.onLine) {
            let headers = {
                headers: new HttpHeaders()
            }
            return this.Http.get(this.baseUrl + 'plans/items/' + planId).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                // TODO: Error handling
                console.log(err);
                return err;
            }));
        }

    }

    /**
     * Convert Plan to Campaign
     * @param  {} plan
     */
    convertPlan(planQueryParams) {
        if (navigator.onLine) {
            const body = JSON.stringify(planQueryParams);

            return this.Http.post('plans/convert-to-campaign/' + planQueryParams.planId, body, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }).pipe(map((response) => {
                return response;
            }));
        }

    }

    /**
     * Update Plan
     * @param  {} plan
     */
    udpatePlan(plan): any {
        if (navigator.onLine) {
            var body = JSON.stringify(plan);

            return this.Http.post(this.baseUrl + 'plans/update/', body, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }).pipe(map((response) => {
                return response;
            }));
        }


    }

    assignPlan(plan): any {
        if (navigator.onLine) {
            var body = JSON.stringify(plan);

            return this.Http.post(this.baseUrl + 'plans/assign', body, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }).pipe(map((response) => {
                return response;
            }));
        }


    }

    /**
    * Block Plan
    * @param  {} plan
    */
    blockPlan(planId): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/block/' + planId).pipe(map((response) => {
                return response;
            }), catchError((error) => {
                return error;
            }));
            //     return response;
            // })
        }

    }

    updateDeletePlan(planUpdateDeleteWrapper): any {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'plans/removeItem-onPlanUpdate', planUpdateDeleteWrapper).pipe(map((response) => {
                return response;
            }), catchError((error) => {
                return error;
            }));
        }

    }

    deletePlan(planId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/remove/' + planId).pipe(map((response) => {
                return response;
            }), catchError((error) => {
                console.log("error is ", error);
                return error;
            }));
        }

    }

    // deleteItem(planQueryParams) {
    //     console.log("delete object is", planQueryParams);
    //     return this.Http.delete(this.baseUrl + 'plans/removeItem/?itemType=' + planQueryParams.itemType + '&itemId=' + planQueryParams.itemId + '&planId=' + planQueryParams.planId).map((response) => {
    //         return response;
    //     }).catch((error) => {
    //         console.log("error is", error);
    //         return error;
    //     });
    // }

    getPlanById(planId) {

        // + '?projection=planProjection' Sanchit Mirg Removed the projection
        if (navigator.onLine) {
            const headers = {
                headers: new HttpHeaders()
            };
            return this.Http.get(this.baseUrl + 'plans/' + planId).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                // TODO: Error handling
                console.log(err);
                return err;
            }));
        }

    }

    getUsersByRole(role) {

        // + '?projection=planProjection'
        if (navigator.onLine) {
            const headers = {
                headers: new HttpHeaders()
            };
            return this.Http.get(this.baseUrl + 'users/byRole', { params: { 'role': role } }).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                // TODO: Error handling
                console.log(err);
                return err;
            }));
        }

    }

    /**
     * Unblock Plan
     * @param  {} plan
     */
    unBlockPlan(planId): any {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/unblock/' + planId).pipe(map((response) => {
                return response;
            }), catchError((err) => {
                // TODO: Error handling
                console.log(err);
                return err;
            }));
        }

    }




    // deleteItem(planQueryParams) {
    //     console.log("delete object is", planQueryParams);
    //     return this.Http.delete(this.baseUrl + 'plans/removeItem/?itemType=' + planQueryParams.itemType + '&itemId=' + planQueryParams.itemId + '&planId=' + planQueryParams.planId).map((response) => {
    //         return response;
    //     }).catch((error) => {
    //         console.log("error is", error);
    //         return error;
    //     });
    // }



    /** 
     * Download  Plan PPT
     * @param  {} plan
     */
    downloadFile1(planId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + "plans/generatePPT?planId=" + planId, { responseType: "arraybuffer" }).pipe(map((response) => {
                // return this.Http.get(this.baseUrl + 'plans/generatePPT?planId=' + planId).map((response) => {
                return response;
            }), catchError((err) => {
                // TODO: Error handling
                console.log(err);
                this.notificationServcie.error("PPT Download Error", "Please refresh and retry");
                return observableOf(err);
            }));
        }


    }
    downloadFile(planId, fileType, customIds, searchEvent?) {
        let fileUrl;
        if (fileType === 'PPT') {
            fileUrl = this.baseUrl + 'plans/generatePPT?planId=' + planId;
        } else if (fileType === 'XLS') {
            fileUrl = this.baseUrl + 'plans/generateXLS?planId=' + planId;
        }
        if (navigator.onLine) {
            // return this.Http.get(fileUrl, { responseType: "arraybuffer" }).pipe(map((response) => {
            return this.Http.post(fileUrl, customIds, { responseType: "arraybuffer" }).pipe(map((response) => {
                return response;
            }), catchError((error) => {


                const errorObject = ErrorUtil.getErrorObject(error);
                if (errorObject.code === 417 || errorObject.code === 412) {
                    this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                } else {
                    this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                }
                return observableOf(error);
            }));
        }


    }


    /**
     * to get the status for plans
     * 
     * @returns 
     * @memberof PlanService
     */
    getStatus() {
        const headers = {
            headers: new HttpHeaders()
        };
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/status');

        }
    }

    getPPTPreview(planId) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'plans/previewPPT?planId=' + planId, { responseType: "arraybuffer" }).pipe(map((response) => {
                console.log("response is", response);
                return response;
            }));
        }

    }

    sendMail(correspondence) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'plans/send/mail', correspondence).pipe(map((response) => {
                return response;
            }),
                catchError((err) => {
                    this.notificationServcie.error(err.error.message, 'Send Mail');
                    return observableEmpty();
                }));
        }

    }

    uploadPO(purchaseOrders, planId) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'plans/uploadPO/' + planId, purchaseOrders).pipe(map((response) => {
                return response;
            }),
                catchError((err) => {
                    this.notificationServcie.error(err.error.message, 'PO upload');
                    return observableEmpty();
                }));
        }

    }

    createPlan(planWrapper) {
        if (navigator.onLine) {
            return this.Http.post(this.baseUrl + 'plans/save', planWrapper);

        }
    }
}
