import { Address } from '../contracts/address';

export class AddressComparision {
    invAdd: Address = new Address();
    tempInvAdd: Address = new Address();
    invAdd2: string;
    tempInvAdd2: string;
    invGstNo: string;
    tempInvGstNo: string;
    invStateWithCode: string;
    tempInvStateWithCode: string;
}
