import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AnalyticsComponent } from './analytics.component';
import { SIBConstants } from '../shared/constants/SIBConstant';
import { SalesChartComponent } from './sales-chart/sales-chart.component';
import { CapacityChartComponent } from './capacity-chart/capacity-chart.component';
import { RoiComponent } from './roi/roi.component';
import { ViewRoiContractInventoryComponent } from './roi/view-roi-contract-inventory/view-roi-contract-inventory.component';


const routes: Routes = [
    {
        path: 'capacity-chart', component: CapacityChartComponent, data: { title: 'Capacity Chart' },
    },
    {
        path: 'sales-chart', component: SalesChartComponent, data: { title: 'Sales Chart' },
    },
    {
        path: 'roi', component: RoiComponent, data: { title: SIBConstants.ROI_SUBMENU },
    },
    // {
    //     path: 'roi/:contractId', component: ViewRoiContractInventoryComponent, data: { title: SIBConstants.ROI_SUBMENU },
    // },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AnalyticsRoutingModule { }
