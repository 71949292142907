import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FirebaseService } from '../../../services/shared/firebase.service';
import { ImageService } from '../../../services/shared/image.service';
import { FileItem } from '../../../modals/files/file-item';
import { MultiMediaTypeEnum } from '../../constants/multi-media-type-enum';
import { ImageFolderConfig } from '../../../modals/image/image-folder-config';
import { PurchaseOrderWrapper } from '../../../modals/campaigns/purchaseOrderWrapper';
import { PurchaseOrder } from '../../../modals/campaigns/purchaseOrder';
import { Subject } from 'rxjs';
import * as fileUtils from '../../../helpers/file.util';
import { SelectItem } from 'primeng/primeng';
import { DocumentTypeEnum } from '../../constants/document-type-enum';
import * as utils from '../../../helpers/utils';
@Component({
  selector: 'sib-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadDocumentComponent implements OnInit {

  @Input() displayUploadDocumentDialog: boolean = false;
  @Input() folderConfig: ImageFolderConfig = new ImageFolderConfig();

  @Output() displayUploadDocument: EventEmitter<boolean> = new EventEmitter();
  @Output() displayReset: EventEmitter<any> = new EventEmitter();
  @Output() eEmitFiles: EventEmitter<any> = new EventEmitter();

  files: FileItem[] = [];
  documentsWrapper: PurchaseOrderWrapper[] = [];
  purchaseOrdersWrapper: PurchaseOrderWrapper[] = [];
  isPO: boolean;
  combinedWrapper: PurchaseOrderWrapper[] = [];
  combinedWrapperFiles: PurchaseOrder[] = [];
  firebaseUrlSaved: boolean = false;
  isLoading: boolean = false;
  fileUnsub: Subject<boolean> = new Subject<boolean>();

  /**
   * @description store the document Type
   * @type {string}
   * @memberof UploadDocumentComponent
   */
  documentType: string;
  
  /**
   * @description document type dropdown
   * @type {SelectItem[]}
   * @memberof UploadDocumentComponent
   */
  documentTypeDropdown: SelectItem[] = [];
  filesUploaded: boolean = false;
  isDocumentTypeSelected: boolean = false;

  constructor(
    private firebaseService: FirebaseService,
    private changeDetectorRef: ChangeDetectorRef,
    private imageService: ImageService,
  ) { }

  ngOnInit() {
    // set Url to files after it has been uploaded.
    this.firebaseService.fileItems$.subscribe((fileItems) => {
      if (fileItems && fileItems.length > 0) {
        this.setUrlToFiles(fileItems);  //set url to files
        this.firebaseUrlSaved = true;
        this.detectChanges();
      }
    });
    this.setDocumentTypeDropdown(); // set document type dropdown
  }
  
  /**
   * @description set document type dropdown
   * @author Divya Sachan
   * @date 2019-10-09
   * @memberof UploadDocumentComponent
   */
  setDocumentTypeDropdown() {
    this.documentTypeDropdown = utils.createDropdown(DocumentTypeEnum, false);
  }
  
  /**
   * @description opens upload document dialog
   * @author Divya Sachan
   * @date 2019-10-09
   * @memberof UploadDocumentComponent
   */
  showUploadDocumentDialog() {
    this.firebaseService.resetFileItems();
    this.displayUploadDocumentDialog = true;
  }
  
  /**
   * @description hides upload document dialog
   * @author Divya Sachan
   * @date 2019-10-09
   * @memberof UploadDocumentComponent
   */
  hideUploadDocumentDialog() {
    if (!this.firebaseUrlSaved) { // if save button not pressed
      this.removeAllFiles(); // remove all the selected files from firebase and files array
      this.resetData(); // reset data
    } else { // otherwise
      this.displayUploadDocument.emit(false); 
      this.resetData(); // reset data
    }
  }

  /**
   * @description 
   * @author Divya Sachan
   * @date 2019-10-09
   * @param {*} event
   * @memberof UploadDocumentComponent
   */
  onFileSelection(event) {
    this.imageService.getFirebasePoObjects(event.files).forEach((object) => {
      this.files.push(object);
    });
    this.imageService.getDatabasePoObjects(event.files, MultiMediaTypeEnum['FILE']);
    this.imageService.databasePoObjects$.subscribe((databasePoObjects) => {
      if (databasePoObjects && databasePoObjects.length > 0) {
        databasePoObjects.forEach((object) => {
          const purchaseOrderWrapper: PurchaseOrderWrapper = new PurchaseOrderWrapper();
          purchaseOrderWrapper.purchaseOrder = object;
          if (this.documentType === "ISPO") {
            purchaseOrderWrapper.purchaseOrder.po = true;
            this.purchaseOrdersWrapper.push(purchaseOrderWrapper);
          } else {
            purchaseOrderWrapper.purchaseOrder.po = false;
            this.documentsWrapper.push(purchaseOrderWrapper);
          }
          this.combinedWrapper.push(purchaseOrderWrapper);
          this.detectChanges();
        });
        this.imageService.databasePoObjects$.next([]);

      }
    });
    this.firebaseUrlSaved = false;
  }

  uploadAllFiles() {
    this.uploadFiles();
  }

  uploadFiles() {
    this.renameFileNames();
    this.imageService.setFirebaseImageFolder(this.files, this.folderConfig.moduleName, this.folderConfig.itemId);
    for (let i = 0; i < this.files.length; i++) {
      if (!this.files[i].isQueued) {
        // this.disableSave = true;
        this.firebaseService.uploadImageToFirebase(this.files[i]);
        this.files[i].isQueued = true;
      }
    }
  }

  setUrlToFiles(fileItems) {
    if (fileItems && fileItems.length === this.combinedWrapper.length) {
      this.combinedWrapper.forEach((file, index) => {
        fileItems.forEach((fileItem) => {
          if (file.newName && file.newName !== "") {
            if (file.newName === fileItem.file.name) {
              this.combinedWrapper[index].purchaseOrder.url = fileItem.url;
            }
          } else {
            if (file.purchaseOrder.name === fileItem.file.name) {
              this.combinedWrapper[index].purchaseOrder.url = fileItem.url;
            }
          }
        });
      });
      // this.firebaseUrlSaved = true;
    }
  }

  getFileObjectFromWrapper() {
    this.combinedWrapperFiles = [];
    this.combinedWrapper.forEach((file) => {
      if (file.newName && file.newName !== "") {
        file.purchaseOrder.name = file.newName;
      }
      this.combinedWrapperFiles.push(file.purchaseOrder);
    });
  }

  setFiles() {
    if (this.firebaseUrlSaved) {
      this.getFileObjectFromWrapper();
      this.combinedWrapperFiles.forEach((po) => {
        po.folder = this.files[0].imagesFolder;
      });
      this.eEmitFiles.emit(this.combinedWrapperFiles);
      this.hideUploadDocumentDialog();
    }
  }

  saveUploadedFiles() {
    this.uploadFiles();
    this.setFiles();
  }

  renameFileNames() {
    this.getFileObjectFromWrapper();
    let files: any[] = [];
    let finalFileList: any[] = [...files, ...this.combinedWrapperFiles];
    finalFileList = fileUtils.renameDuplicateNames(finalFileList, 'name');
    finalFileList = finalFileList.slice(files.length, finalFileList.length);
    finalFileList.forEach((file, index) => {
      this.combinedWrapper.forEach((doc, ind) => {
        if (index === ind) {
          if (doc.newName === "" || doc.newName === undefined) {
            doc.newName = file.name;
          }
          else {
            if (!fileUtils.getFileExtension(doc.newName)) {
              doc.newName = doc.newName + "." + file.extension;
              file.name = doc.newName;
            }
          }
        }
      });
    });
    this.updateNamesFromFiles(finalFileList);
  }

  updateNamesFromFiles(finalFileList) {
    finalFileList.forEach((file, index) => {
      const finalFile = new File([this.files[index].file], finalFileList[index].name, { type: this.files[index].file.type });
      this.files[index].file = finalFile;
    });
  }

  resetData() {
    this.files = [];
    this.purchaseOrdersWrapper = [];
    this.documentsWrapper = [];
    this.combinedWrapper = [];
    this.combinedWrapperFiles = [];
    this.firebaseUrlSaved = false;
    this.displayReset.emit(false);
  }

  removeAllFiles() {
    this.files.forEach((file) => {
      this.removeFile(0);
    });
    this.setFiles();
  }

  removeFile(index, isPO?: boolean) {
    if (this.files[index].isQueued) {
      this.removeFileFromFirebase(index);
    }
    if (isPO) {
      this.removeFileFromFiles(this.purchaseOrdersWrapper, index)
      this.purchaseOrdersWrapper.splice(index, 1);
    } else if (!isPO) {
      this.removeFileFromFiles(this.documentsWrapper, index);
      this.documentsWrapper.splice(index, 1);
    } else {
      this.purchaseOrdersWrapper = [];
      this.documentsWrapper = [];
      this.files = [];
      this.combinedWrapper = [];
    }
  }

  removeFileFromFirebase(index) {
    this.firebaseService.deleteFile(this.files[index].file.name, this.files[index].imagesFolder);
    this.firebaseService.removeItemFromFileItem(this.files[index].file.name, this.files[index].imagesFolder);
  }

  removeFileFromFiles(fileWrapper, index) {
    if (fileWrapper && fileWrapper.length > 0) {
      this.files.forEach((file, fileIndex) => {
        if (file.file.name === fileWrapper[index].purchaseOrder.name) {
          this.files.splice(fileIndex, 1);
          this.combinedWrapper.splice(fileIndex, 1);
        }
      });
    } else {
      // Do nothing
    }
  }

  onDocumentTypeSelection(event) {
    this.documentType = event.value;
    this.isDocumentTypeSelected = true;
  }

  findFile(name) {
    let index = this.files.findIndex((item) => item.file.name === name);
    if (index !== undefined) {
      return this.files[index].progress;
    } else {
      return 0;
    }

  }

  detectChanges() {
    if (!this.changeDetectorRef["destroyed"]) {
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.fileUnsub.unsubscribe();
  }
}
