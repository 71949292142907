
import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { Contracts } from '../../modals/contracts/contracts';
import { HttpClient } from '@angular/common/http';
import { ListBox } from '../../modals/listbox';
import { Breakeven } from '../../modals/contracts/breakevenamount';
import { AreaQueryParams } from '../../modals/queryparams/area-queryparams';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractsActionDispatcher } from '../action-dispatcher/action-dispatcher';
import { ContractTypeService } from '../../services/shared/contract-type.service';
import { Location } from '@angular/common';
import * as utils from '../../helpers/utils';
import { ContractService } from '../services/contract.services';
import * as _ from 'lodash';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { ContractStatusEnum } from '../../shared/constants/contract-status-enum';
import { Subscription } from 'rxjs';
import { ChequeDetail } from '../../modals/contracts/chequedetail';
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { ContractUiService } from '../services/contract-ui.services';
import { ExpireConflictData } from '../../modals/contracts/expire-conflict-data';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { DateUtil } from '../../helpers/date.util';
import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { ChangesService } from '../../services/shared/changes.service';
import { ActivityLogModuleEnum } from '../../shared/constants/activity-log-module-enum';
import { AppUrls } from '../../services/urls';

@Component({
  selector: 'sib-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewContractComponent implements OnInit, OnDestroy, AfterViewInit {
  contractCustomId: string;

  @ViewChild('contractForm') contractForm;
  @ViewChild('cName') contractName: ElementRef;

  @Output() hideDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set contractId(id: string) {
    if (id) {
      this.contractCustomId = id;
      this.handleDialogOpen(true);
    }
  }

  get contractId() {
    return this.contractCustomId;
  }

  /**
   * New contract Object
   * 
   * @type {Contracts}
   * @memberof NewContractComponent
   */
  newContract: Contracts = new Contracts();

  /**
   * List of selected States, district, cities, areas
   * 
   * @type {any[]}
   * @memberof NewContractComponent
   */
  selectedStates: any;
  selectedDistricts: any;
  stateList: any[] = []
  filteredStateList: any[] = [];
  districtList: any[] = [];
  filteredDistrictList: any[] = [];
  selectedCities: any[] = [];
  selectedAreas: any[] = [];
  citiesList: any[] = [];
  filteredCitiesList: any[] = [];
  areasList: any[] = [];

  /**
   * Contract Type list
   * 
   * @type {any[]}
   * @memberof NewContractComponent
   */
  contractType: any[] = [];

  /**
   * Array to store breakeven amounts 
   * 
   * @type {Breakeven[]}
   * @memberof NewContractComponent
   */
  breakevenAmounts: Breakeven[] = [];


  /**
   * Variable to handle enable disbale addicon in breakeven
   * 
   * @type {boolean}
   * @memberof NewContractComponent
   */
  addIconDisable: boolean = true;

  /**
   * while fetching areas passing the object for queryParams
   * 
   * @type {AreaQueryParams}
   * @memberof NewContractComponent
   */
  areaQueryParams: AreaQueryParams = new AreaQueryParams();

  /**
   * When opening in edit mode then storing the contract object in this object 
   * 
   * @type {Contracts}
   * @memberof NewContractComponent
   */
  selectedContract: Contracts = new Contracts();

  /**
   * variable to check if contract is opened in edit mode 
   * 
   * @type {boolean}
   * @memberof NewContractComponent
   */
  isEditMode: boolean = false;

  /**
   * Labels on the screen
   * 
   * @type {string}
   * @memberof NewContractComponent
   */
  createButtonLabel: string;
  headerLabel: string;
  cancelButtonLabel: string;
  selectedContractType: any;
  states: any[] = [];
  districts: any[] = [];
  cities: any[] = [];
  loading: boolean = false;
  contractStatus: SelectItem[] = [];

  chequeDetails: ChequeDetail[] = [];

  displayConflictHoardings: boolean = false;
  expireConflicts: ExpireConflictData[] = [];
  endDateMin: Date;
  endDateMax: Date;
  subscription: Subscription[] = [];

  constructor(
    public actionDispatcher: ContractsActionDispatcher,
    public httpClient: HttpClient,
    private router: Router,
    private _route: ActivatedRoute,
    private contractTypeService: ContractTypeService,
    private _location: Location,
    private contractService: ContractService,
    private confirmationService: ConfirmationService,
    private notificationServcie: NotificatoinsService,
    private contractUiService: ContractUiService,
    private currencyPipe: CurrencyHelperPipe,
    private renderer: Renderer2,
    private changesService: ChangesService
  ) { }

  ngOnInit() {

    this.actionDispatcher.contractById.subscribe((contract) => {

      //if opening contract in edit mode
      if (contract && contract.id && this.isEditMode) {
        this.changesService.setInitialContract(contract);
        this.setContractData(contract);
      }


    });

    this.subscription.push(this.actionDispatcher.stateList.subscribe((stateList) => {
      this.stateList = stateList;
      this.filteredStateList = this.stateList;
    }));

    this.subscription.push(this.actionDispatcher.districtList.subscribe((districtList) => {
      this.districtList = districtList;
      this.filteredDistrictList = this.districtList;
    }));

    this.subscription.push(this.actionDispatcher.citiesList.subscribe((citiesList) => {
      this.citiesList = citiesList;
      this.filteredCitiesList = this.citiesList;
    }));



    //if breakeven amount is not there
    if (this.breakevenAmounts.length === 0) {
      this.breakevenAmounts.push(new Breakeven());
    }

    if (this.chequeDetails.length === 0) {
      this.addChequeDetail();
    }

    //calling function to fetch states
    this.fetchStates();

    //when creating contract subscribing if contract is saved successfully
    this.subscription.push(this.actionDispatcher.contractCreated.subscribe((contractCreated) => {
      if (contractCreated === true) {
        this.loading = false;

        this.actionDispatcher.contract.subscribe((contract) => {
          this.actionDispatcher.resetCreateContratFlag();
          if (contract.id) {
            this.actionDispatcher.resetContract();
            this.router.navigate(['/contracts/' + contract.id]);
          }
        });
      }
    }));

    this.subscription.push(this.actionDispatcher.errorCreatingContract.subscribe((error) => {
      if (error) {
        this.loading = false;
        this.actionDispatcher.resetContractCreationError()
      }
    }));

    this.subscription.push(this.actionDispatcher.isContractUpdated.subscribe((isContractUpdated) => {
      if (isContractUpdated) {
        this.loading = false;
        this.isEditMode = false;
        this.hideDialog.emit(false);
        this.changesService.create(this.changesService.getActivityLogs(), null, AppUrls.SAVE).subscribe(
          (response) => {
            console.log("response", response);
          },
          (error) => {
            this.notificationServcie.error(error.error ? error.error.message ? error.error.message : error.message : 'Some technical issue', 'Error!!');
          }
        );
        this.actionDispatcher.resetIsContractUpated()
      }
    }));


    this.fetchContractType();

    Object.keys(ContractStatusEnumLocal).forEach((key) => {
      this.contractStatus.push({
        label: ContractStatusEnumLocal[key],
        value: key
      });
    });

    this.subscription.push(this.contractUiService.campaignItem.subscribe((expireConflicts) => {
      if (expireConflicts && expireConflicts.length > 0) {
        this.displayConflictHoardings = true;
        this.expireConflicts = expireConflicts;
        // this.contractUiService.destroySubject();
      } else if (expireConflicts.length === 0) {
        this.loading = true;
        this.actionDispatcher.updateContract(this.newContract)
        // this.contractUiService.destroySubject();
      }
    }));

    this.subscription.push(this.actionDispatcher.updateContractFailed.subscribe((failed) => {
      if (failed) {
        this.loading = false;
        if (this.chequeDetails.length === 0) {
          this.addChequeDetail();
        }
        this.actionDispatcher.resetUpdateContractFailed();
      }
    }));
  }

  setContractData(contract) {
    this.newContract = JSON.parse(JSON.stringify(contract));

    this.newContract.startDate = new Date(this.newContract.startDate);
    this.newContract.endDate = new Date(this.newContract.endDate);
    //manipulating the Date for each Breakeven
    this.newContract.breakevens.forEach((breakeven, index) => {
      breakeven.startDate = new Date(breakeven.startDate);
      breakeven.endDate = new Date(breakeven.endDate);
    });

    this.chequeDetails = this.newContract.cheques ? this.newContract.cheques : [];
    if (this.chequeDetails.length === 0) {
      this.addChequeDetail();
    }
    this.breakevenAmounts = this.newContract.breakevens;
    /* for(let i = 0; i<this.breakevenAmounts.length; i++) {
      this.breakevenAmounts[i].amount = parseInt(this.currencyPipe.transform(this.breakevenAmounts[i].amount))
    } */
    this.loading = false;

    //mapping the contract type in edit mode
    if (this.contractType.length > 0 && this.newContract.type) {
      this.selectedContractType = utils.findInListMapper(this.contractType, this.newContract.type, 'code')
    }

  }

  ngAfterViewInit() {
    this.loading = false;
    // this.contractName.nativeElement.focus();
  }



  /**
   * Fetching states Passing parameters in areaquery params
   * 
   * @memberof NewContractComponent
   */
  fetchStates() {
    this.areaQueryParams.countryCode = "IN";
    this.actionDispatcher.getStates(this.areaQueryParams);
  }

  /**
   * Fetching districts
   * 
   * @memberof NewContractComponent
   */
  fetchDistricts() {
    this.newContract.address.district = null;
    this.newContract.address.city = null;

    this.actionDispatcher.resetDistrictList();
    this.actionDispatcher.resetCitiesList();
    this.actionDispatcher.resetAreasList();
    this.areaQueryParams = new AreaQueryParams();
    this.areaQueryParams.stateId = this.newContract.address.state.id;
    this.actionDispatcher.getDistricts(this.areaQueryParams);

  }

  fetchCities() {
    this.actionDispatcher.resetCitiesList();
    this.actionDispatcher.resetAreasList();

    // for (let i = 0; i < this.selectedDistricts.length; i++) {
    this.areaQueryParams = new AreaQueryParams();
    this.areaQueryParams.districtId = this.newContract.address.district.id;
    this.actionDispatcher.getCities(this.areaQueryParams);
    // }
  }

  fetchContractType() {
    this.contractTypeService.getContractTypes().pipe(
      map((response) => {
        return response['_embedded']['contractTypes']
      })).subscribe(response => {
        var contractType = utils.ListMapper(response, 'name', true, null, false);
        this.contractType = contractType;
        if (this.selectedContract && this.selectedContract.id !== undefined && this.contractType.length > 0 && this.selectedContract.type) {
          this.selectedContractType = utils.findValueInListMapper(this.contractType, this.selectedContract.type, 'value')
        }
      });
  }

  /**
   * Creating contract
   * 
   * @memberof NewContractComponent
   */
  createContract() {

    for (let i = 0; i < this.breakevenAmounts.length; i++) {
      isNaN(this.breakevenAmounts[i].amount) ? this.breakevenAmounts[i].amount = 0 : this.breakevenAmounts[i].amount;
      this.breakevenAmounts[i].amount = parseInt(String(this.breakevenAmounts[i].amount).split(',').join(''));
    }

    this.newContract.breakevens = this.breakevenAmounts;

    if (Object.keys(this.chequeDetails[this.chequeDetails.length - 1]).length === 0
      || this.chequeDetails[this.chequeDetails.length - 1].chequeName === '') {
      this.chequeDetails.pop();
    }

    if (this.chequeDetails.length > 0) {
      this.newContract.cheques = this.chequeDetails;
    }

    this.newContract.type = this.selectedContractType.code;

    if (this.validateDates()) {
      if (!this.newContract.id) {
        this.loading = true;
        this.actionDispatcher.createContract(this.newContract);
      } else {
        if (this.newContract.status === 'EXPIRED') {
          this.contractUiService.contractExpiryCheck(this.newContract);
        } else {
          this.loading = true;
          this.actionDispatcher.updateContract(this.newContract)
        }
      }
    }
  }

  clearContractForm() {

    if (this.isEditMode) {
      this.hideDialog.emit(true);
      // this._location.back();

    } else {

      this.newContract = new Contracts();
      this.breakevenAmounts = [];
      this.breakevenAmounts.push(new Breakeven());
      this.chequeDetails = [];
      // this.chequeDetails = ChequeDetails[] = []
      this.chequeDetails.push(new ChequeDetail())
      this.selectedContractType = null;
      this.selectedStates = [];
      this.selectedDistricts = [];
      this.selectedCities = [];
      this.selectedAreas = [];

    }
  }

  addBreakevenAmount() {
    this.addIconDisable = true;
    this.breakevenAmounts.push(new Breakeven());

    if (this.breakevenAmounts.length > 1) {
      const breakevenLength = this.breakevenAmounts.length;
      this.breakevenAmounts[breakevenLength - 1].startDate = this.startDateSelected(this.breakevenAmounts[breakevenLength - 2].endDate);
      this.breakevenAmounts[breakevenLength - 1].endDate = this.newContract.endDate;
      this.updateActivityLog("breakevens/" + (breakevenLength - 1) + ".startDate", "Breakeven Start Date", this.newContract.breakevens[breakevenLength - 1].startDate, "date", "BreakEven Added", "-", null);
      this.updateActivityLog("breakevens/" + (breakevenLength - 1) + ".endDate", "Breakeven End Date", this.newContract.breakevens[breakevenLength - 1].endDate, "date", "BreakEven Added", "-", null);
      this.updateActivityLog("breakevens/" + (breakevenLength - 1) + ".amount", "Breakeven Amount", this.newContract.breakevens[breakevenLength - 1].amount, "currency", "BreakEven Added", "-", null);
    }
  }

  startDateSelected(startDate) {
    return new Date(startDate.getTime() + 86400000);
  }

  onEndDateSelection() {
    this.addIconDisable = false;
  }

  /**
   * Deleting the breakeven Amount, if there is no row left then adding one row to it.
   * 
   * @param {any} i 
   * @memberof NewContractComponent
   */
  deleteBreakeven(i) {
    if (this.isEditMode) {
      this.updateActivityLog("breakevens/" + i + ".amount", "Breakeven Removed", null, "currency", String(this.newContract.breakevens[i].amount), "-", null);

    }

    this.breakevenAmounts.splice(i, 1);

    if (this.breakevenAmounts.length === 0) {
      this.breakevenAmounts.push(new Breakeven());
      //to get the start date of the breakeven amount acccording to the previous record end date
      this.breakevenAmounts[this.breakevenAmounts.length - 1].startDate = this.newContract.startDate;
    }

    this.breakevenAmounts[this.breakevenAmounts.length - 1].endDate = this.newContract.endDate;
    // this.breakevenAmounts[this.breakevenAmounts.length - 1].startDate = this.newContract.startDate;
  }

  Date() {
    return new Date();
  }

  contractStartDateSelected(contractStartDate) {
    this.setEndDateMinMax()
    this.breakevenAmounts = [];

    if (this.breakevenAmounts.length === 0) {
      this.breakevenAmounts.push(new Breakeven());
    }

    if (this.newContract.endDate && !DateUtil.dateComparator(contractStartDate.value, this.newContract.endDate)) {
      this.newContract.endDate = null;
    }

    this.breakevenAmounts[this.breakevenAmounts.length - 1].endDate = null

    if (this.breakevenAmounts.length === 1) {
      this.breakevenAmounts[0].startDate = contractStartDate.value;
    }
  }

  setEndDateMinMax() {
    if (new Date(this.newContract.startDate) > new Date()) {
      this.endDateMin = new Date(this.newContract.startDate);
    } else {
      this.endDateMin = new Date();
    }

  }

  contractEndDateSelected(contractEndDate) {
    // this.breakevenAmounts = [];
    if (this.breakevenAmounts.length === 0) {
      this.breakevenAmounts.push(new Breakeven());
    }
    if (!DateUtil.dateComparator(contractEndDate.value, this.newContract.startDate)) {
      this.newContract.endDate = null;
    } else {
      this.breakevenAmounts[this.breakevenAmounts.length - 1].endDate = contractEndDate.value;
    }

    if (this.breakevenAmounts.length > 1) {
      for (let i = 0; i < this.breakevenAmounts.length; i++) {
        if (this.breakevenAmounts[i].startDate < this.newContract.endDate && this.breakevenAmounts[i].endDate > this.newContract.endDate) {
          this.breakevenAmounts[i].endDate = this.newContract.endDate;
          this.breakevenAmounts.splice(i + 1, this.breakevenAmounts.length - i);
          break;
        }
      }
    }

  }

  handleDialogOpen(event) {
    // this.contractName.nativeElement.focus();
    const element = this.renderer.selectRootElement('#contractName');
    setTimeout(() => element.focus(), 0)
    this.selectedStates = [];
    this.selectedDistricts = [];
    this.selectedCities = [];
    this.selectedAreas = [];
    this.actionDispatcher.resetCreateContratFlag()
    if (event === false) {
      this.isEditMode = false;
      this.createButtonLabel = 'CREATE';
      this.headerLabel = 'Create';
      this.cancelButtonLabel = 'RESET';
      this.changesService.setInitialContract(this.newContract);
      this.clearContractForm();
    } else {

      this.loading = true;
      this.createButtonLabel = 'SAVE';
      this.headerLabel = 'Edit';
      this.cancelButtonLabel = 'CANCEL';
      //fetching the id from params and getting the contract
      this._route.params.subscribe((params: { contractId: string }) => {
        if (params.contractId) {
          this.isEditMode = true;
          this.actionDispatcher.getContractById(params.contractId);
        } else if (this.contractId) {
          this.isEditMode = true;
          this.actionDispatcher.getContractById(this.contractId);
        }
        else {
          this.actionDispatcher.resetContract();
        }

      });
    }

  }

  filterStateList(event) {
    this.filteredStateList = [];
    this.filteredStateList = utils.filterData(this.stateList, 'name', event);
  }

  filterDistrictList(event) {
    this.filteredDistrictList = [];
    this.filteredDistrictList = utils.filterData(this.districtList, 'name', event);
  }

  filterCityList(event) {
    this.filteredCitiesList = [];
    this.filteredCitiesList = utils.filterData(this.citiesList, 'name', event);
  }

  handleCloseDialog() {
    this.contractForm.reset();
    this.isEditMode = false;
    this.contractCustomId = undefined;
    this.clearContractForm();
  }

  addChequeDetail() {
    this.chequeDetails.push(new ChequeDetail());
  }

  removeChequeDetail(index) {
    if (this.isEditMode) {
      this.updateActivityLog("cheques/" + index + ".chequeName", "Cheque Removed", null, null, this.newContract.cheques[index].chequeName, "-", null);
    }

    this.chequeDetails.splice(index, 1)

    if (this.chequeDetails.length === 0) {
      this.addChequeDetail();
    }
  }

  validateBrkEvenChqAmount() {
    var breakevenAmounts = [];
    this.breakevenAmounts.forEach((breakeven) => {
      breakevenAmounts.push(breakeven.amount)
    })

    var breakEvenSum;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    breakEvenSum = breakevenAmounts.reduce(reducer)

    var chequeAmounts = [];
    this.chequeDetails.forEach((chequeDetail) => {
      chequeAmounts.push(chequeDetail.amount)
    })

    var chequeSum = 0;
    chequeSum = chequeAmounts.reduce(reducer);

    if (breakEvenSum > chequeSum) {
      return true;
    } else {
      return false;
    }
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Total Breakeven Amount is <= Total Cheques Amount.',
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  onStateChange() {
    if (!this.newContract.address.state) {
      this.newContract.address.district = null;
      this.newContract.address.city = null;
    }
  }

  validateDates(): boolean {
    if (DateUtil.dateComparator(this.newContract.startDate, this.newContract.endDate)) {
      this.newContract.endDate = null;
      this.breakevenAmounts = [];
      this.breakevenAmounts.push(new Breakeven())
      this.breakevenAmounts[0].startDate = this.newContract.startDate;
      return false
    }

    this.breakevenAmounts[this.breakevenAmounts.length - 1].endDate = this.newContract.endDate;
    return true;
  }

  getChangedType() {
    return ActivityLogModuleEnum.CONTRACT;
  }

  updateActivityLog(changedField: string, changedFieldDisplayName: string, changedValue: any, formatterType: string, changeDescription: string, changeAdditionalDescription: string, changedFieldArrayVariable: string) {
    this.changesService.updateObjectMap(this.getChangedType(), changedField, changedFieldDisplayName, changedValue, formatterType, changeDescription, changeAdditionalDescription, changedFieldArrayVariable);
  }

  ngOnDestroy() {
    this.subscription.forEach((s) => {
      s.unsubscribe();
    })
  }
}


export enum ContractStatusEnumLocal {
  ACTIVE = "Active",
  INACTIVE = 'Inactive'
}