<div class="sales-container">
  <div *ngIf="isLoading || stackedBarChartLoader">
    <p-progressSpinner class="loading"></p-progressSpinner>
  </div>
  <div class="ui-g header-text" *ngIf="showTitle">
    <h2 class="margin-0">{{title}}</h2>
  </div>
  <div class="action-container margin-top--40">
    <div class="flex-action-container float-right">
      <div class="flex-action-item margin-10" *ngIf="checkPermission('readAll')">
        <div class="ui-fluid">
          <span class="ui-float-label">
            <p-autoComplete [suggestions]="filteredSalesRepresentative" [(ngModel)]="selectedUser" placeholder="User"
              field="fullName" [multiple]="false" [dropdown]="true" (onSelect)="onUserSelection()"
              (completeMethod)="filterSalesRepresentative($event)"></p-autoComplete>
            <label>User</label>
          </span>
        </div>
      </div>
      <div class="flex-action-item margin-10">
        <span class="ui-float-label">
          <p-dropdown [options]="fiscalYears" [dropdownIcon]="'fa fa-calendar'" [(ngModel)]="selectedFiscalYear"
            [style]="{'width':'100px'}" placeholder="Financial Year" (onChange)="onFiscalYearSelection()">
          </p-dropdown>
          <label>Financial Year</label>
        </span>
      </div>
    </div>
  </div>

  <div #superbillCardRef class="salesChart">
    <div class="flex-action-container float-right margin-top-0" style="z-index: 1;" *ngIf="!stackedBarChartLoader">
      <div class="flex-action-item margin-10 margin-top-0">
        <div style="font-size: 1.1em;">
          Campaign: <strong>{{totalSuperbill}}</strong>
        </div>
      </div>
      <div class="flex-action-item margin-10 margin-top-0">
        <div style="font-size: 1.1em;">
          Invoice: <strong>{{totalInvoice}}</strong>
        </div>
      </div>
    </div>
    <sib-superbill-chart [showChartTitle]="true" [billingSearch]="billingSearch" [fiscalYear]="selectedFiscalYear"
      [isSalesSearchEvent]="true" (eEmitChartClickedData)="onSalesChartSelection($event)"
      (eEmitPerformance)="getPerformance($event)">
    </sib-superbill-chart>
  </div>

  <div>
    <div class="multiselectContainer" *ngIf="!stackedBarChartLoader">
      <span class="city-label"> Select City(s)</span>
      <div style="position: relative; top: 5px; ">
        <p-multiSelect [options]="citiesOptions" name="city" [(ngModel)]="selectedCities" [maxSelectedLabels]="1"
          (onPanelHide)="onCitySelection()" (onPanelShow)="filterCities()" selectedItemsLabel="{0} cities selected"
          defaultLabel="Choose" scrollHeight="150px">
        </p-multiSelect>
      </div>
    </div>
    <!-- <div class="summary" *ngIf="citySalesChartData.length > 0 && (isSalesChartClicked || isCitySalesChartClicked)">
      <table class="c-tbl">
        <tr>
          <th>Rent Amount</th>
          <td>{{totalRentAmount}}</td>
        </tr>
        <tr>
          <th>Migrated Amount</th>
          <td>{{totalMigratedAmount}}</td>
        </tr>
        <tr>
          <th>Unbilled Amount</th>
          <td>{{totalUnbilledAmount}}</td>
        </tr>
      </table>
    </div> -->
    <div *ngIf="barChartData.length === 0" class="information">
      <h2>{{titleForUnsoldCities}}</h2>
    </div>
    <sib-chart #citySalesChartRef class="cityStackedBarChart" *ngIf="citySalesChartData.length > 0"
      [data]=" citySalesChartData" [stackedBarConfig]="citySalesStackedBarChartConfig" [chartType]="'stackedBarChart'"
      [elementId]="chartElementId" (eEmitStackedBarChartLoaded)="stackedBarChartLoader = false">
    </sib-chart>
  </div>

  <div *ngIf="isChartClicked">
    <div class="ui-g-12 card" *ngIf="citySalesChartData.length > 0 && (isSalesChartClicked || isCitySalesChartClicked)">
      <ul class="cinline-block">
        <li>
          <table>
            <tr>
              <td>
                <div class="font-bold">Rent Amount</div>
              </td>
              <td>
                <div>₹ {{ totalRentAmount }}</div>
              </td>
            </tr>
          </table>
        </li>
        <li>
          <table>
            <tr>
              <td>
                <div class="font-bold">Migrated Amount</div>
              </td>
              <td>
                <div>₹ {{ totalMigratedAmount }}</div>
              </td>
            </tr>
          </table>
        </li>
        <li>
          <table>
            <tr>
              <td>
                <div class="font-bold">Unbilled Amount</div>
              </td>
              <td>
                <div>₹ {{ totalUnbilledAmount }}</div>
              </td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
    <div class="flex-action-container float-right"
      *ngIf="citySalesChartData.length > 0 && (isSalesChartClicked || isCitySalesChartClicked)">
      <div class="flex-action-item margin-left-0 margin-right-10 margin-top-8">
        <div class="ui-widget-header global-search-container">
          <input #gb type="text" pInputText size="50" placeholder="Global Filter" (keyup)="globalFiltering(gb.value)"
            class="global-search-textbox" pTooltip="{{getCommaSeparatedFields()}}" tooltipPosition="left">
          <span class="search-wrapper">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div class="flex-action-item margin-top-12 padding-left-5">
        <label class="font-12 font-bold label-vCenter padding-right-5">Export XLSX</label>
        <button pButton class="mtab-primary c-edit-btn border-rad-2 xlsBtn" (click)="exportXls()" title="Export Xlsx"
          icon="fa fa-file-excel-o">
        </button>
      </div>
      <div class="flex-action-item" style="margin-top: 7.4px;">
        <p-multiSelect [options]="modifiedColumns" defaultLabel="Show Columns" [style]="{'width':'132px'}"
          maxSelectedLabels="0" name="name" [(ngModel)]="selectedColumns" (onChange)="emitSetColumns()"
          [dropdownIcon]="'fa fa-cog'" appendTo="body" (onPanelHide)="updatingUserGridConfig()"
          [displaySelectedLabel]="false" [panelStyleClass]="'showcol-drop-down'" [styleClass]="'show-column-hoarding'">
        </p-multiSelect>
      </div>
    </div>
    <div class="content-pane-container" *ngIf="citySalesChartData.length > 0 && (isSalesChartClicked)">
      <p-tabView (onChange)="onTabChange($event)" [style]="{'margin-top': '-40px', 'padding': '0px'}"
        [activeIndex]="activeIndex">
        <p-tabPanel header="Invoice Rent Items ({{salesChartHoardings}})">
          <sib-view-inventory #rentItems *ngIf="afterRentTabClicked" [columns]="salesChartColumns"
            [gridConfig]="rentItemsGridConfig" [totalRecords]="salesChartHoardings" [gridNameEnum]="gridNameEnum"
            (eEmitModifiedColumns)="getModifiedColumns($event)" [showEmployee]="showEmployee">
          </sib-view-inventory>
        </p-tabPanel>
        <p-tabPanel header="Migrated Invoice Rent Items ({{migratedHoardings}})">
          <sib-view-inventory #migratedRentItems *ngIf="afterMigratedTabClicked" [columns]="salesChartColumns"
            [gridConfig]="migratedRentItemsGridConfig" [totalRecords]="migratedHoardings"
            [gridNameEnum]="'migratedItems'" (eEmitModifiedColumns)="getModifiedColumns($event)"
            [showEmployee]="showEmployee">
          </sib-view-inventory>
        </p-tabPanel>
        <p-tabPanel header="Unbilled Rent Items ({{totalUnbilledHoardings}})">
          <sib-view-inventory #unbilledItems *ngIf="afterUnbilledTabClicked" [columns]="unbilledHoardingsColumns"
            [gridConfig]="unbilledItemsGridConfig" [totalRecords]="totalUnbilledHoardings"
            [gridNameEnum]="'unbilledItems'" (eEmitModifiedColumns)="getModifiedColumns($event)"
            [showEmployee]="showEmployee">
          </sib-view-inventory>
        </p-tabPanel>
      </p-tabView>
    </div>

    <div class="content-pane-container" *ngIf="citySalesChartData.length > 0 && (isCitySalesChartClicked)">
      <p-tabView (onChange)="onTabChange($event)" [style]="{'margin-top': '-40px', 'padding': '0px'}"
        [activeIndex]="activeIndex">
        <p-tabPanel header="Invoice Rent Items ({{salesChartHoardings}})">
          <sib-view-inventory #rentItems *ngIf="afterRentTabClicked" [columns]="salesChartColumns"
            [gridConfig]="cityWiseRentItemsGridConfig" [totalRecords]="salesChartHoardings"
            [gridNameEnum]="gridNameEnum" (eEmitModifiedColumns)="getModifiedColumns($event)"
            [showEmployee]="showEmployee">
          </sib-view-inventory>
        </p-tabPanel>
        <p-tabPanel header="Migrated Invoice Rent Items ({{migratedHoardings}})">
          <sib-view-inventory #migratedRentItems *ngIf="afterMigratedTabClicked" [columns]="salesChartColumns"
            [gridConfig]="cityWiseMigratedRentItemsGridConfig" [totalRecords]="migratedHoardings"
            [gridNameEnum]="'migratedItems'" (eEmitModifiedColumns)="getModifiedColumns($event)"
            [showEmployee]="showEmployee">
          </sib-view-inventory>
        </p-tabPanel>
        <p-tabPanel header="Unbilled Rent Items ({{totalUnbilledHoardings}})">
          <sib-view-inventory #unbilledItems *ngIf="afterUnbilledTabClicked" [columns]="unbilledHoardingsColumns"
            [gridConfig]="cityWiseUnbilledItemsGridConfig" [totalRecords]="totalUnbilledHoardings"
            [gridNameEnum]="'unbilledItems'" (eEmitModifiedColumns)="getModifiedColumns($event)"
            [showEmployee]="showEmployee">
          </sib-view-inventory>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>