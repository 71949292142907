<div class="passbook">
    <div *ngIf="isLoading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div>
    <div class="ui-g header-list-container">
        <ul class="header-list-inline">
            <li *ngIf="title" class="float-left">
                <div *ngIf="title" class="padding-0 ui-g grid-header-div">
                    <h2 class="margin-0 grid-header">{{title}}</h2>
                </div>
            </li>
            <li *ngIf="transactions && transactions?.length && (checkPermission('read:passbook') && !checkPermission('readAll'))"
                class="float-right">
                <span class="placeholder-text">
                    <span>Available Balance: </span>
                    <span>₹ {{getParsedValueToDecimalTwoPlaces(availableBalance) | CurrencyConverter}}</span>
                </span>
            </li>
        </ul>
    </div>
    <div class="list-container">
        <form #passbookForm="ngForm" autocomplete="off">
            <ul class="c-list-inline">
                <li class="placeholder-text">
                    Month :
                </li>
                <li>
                    <p-dropdown name="month" [options]="monthDropdown" [style]="{'width':'100px'}"
                        [(ngModel)]="selectedMonth" (onChange)="onMonthChange()" [panelStyleClass]="'month-drop-down'"
                        [styleClass]="'show-month-drop-down'">
                    </p-dropdown>
                </li>
            </ul>
        </form>
    </div>
    <ng-container *ngIf="passbookForm.form.valid">
        <ng-container *ngIf="checkPermission('read:passbook') && checkPermission('readAll')">
            <div class="passbookGridScroller">
                <sib-grid [title]="'Transactions'" name="passbookGrid" #passbookGrid [columns]="columns"
                    [config]="gridConfig" [totalRecords]="totalRecords" [loading]="isLoading">
                    <sib-grid-column field="user.fullName">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <div>
                                <a
                                    (click)="showTransactionDialog(rowData)">{{(rowData && rowData.user) ? rowData.user.fullName : '-'}}</a>
                            </div>
                        </ng-template>
                    </sib-grid-column>
                    <sib-grid-column field="percentage">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <span class="progress-container">
                                <p-progressBar [showValue]="true" [value]="setUsedPercentageValue(rowData)">
                                </p-progressBar>
                            </span>
                        </ng-template>
                    </sib-grid-column>
                </sib-grid>
            </div>
        </ng-container>
        <ng-container *ngIf="checkPermission('read:passbook') && !checkPermission('readAll')">
            <div class="passbookGridScroller">
                <sib-grid [title]="'Transactions'" name="transactionGrid" #transactionGrid
                    [columns]="transactionColumns" [config]="transactionGridConfig"
                    [totalRecords]="totalTransactionRecords" [loading]="isLoading">
                    <sib-grid-column field="icon">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                            let-column="column">
                            <div *ngIf="rowData.transactions.transactionType === 'CREDIT'">
                                <i class="fa fa-plus-circle plus-sign"></i>
                            </div>
                            <div *ngIf="rowData.transactions.transactionType === 'DEBIT'">
                                <i class="fa fa-minus-circle minus-sign"></i>
                            </div>
                        </ng-template>
                    </sib-grid-column>
                    <sib-grid-column field="transactions.transactionType">
                        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                            <p-dropdown [options]="transactionTypeDropdown"
                                [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Type"
                                (onChange)="transactionGrid.filter($event.value,'transactions.transactionType',col.filterMatchMode)">
                            </p-dropdown>
                        </ng-template>
                    </sib-grid-column>
                    <sib-grid-column field="transactions.module">
                        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                            <p-dropdown [options]="moduleDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                                appendTo="body" placeholder="Module"
                                (onChange)="transactionGrid.filter($event.value,'transactions.module',col.filterMatchMode)">
                            </p-dropdown>
                        </ng-template>
                    </sib-grid-column>
                </sib-grid>
            </div>
        </ng-container>
    </ng-container>



    <p-dialog header="Employee's Transactions" *ngIf="displayTransactionDialog" [(visible)]="displayTransactionDialog"
        [width]="1000" [modal]="true" (onShow)="showTransactionDialog()">
        <div class="ui-g-12">
            <span class="placeholder-text  float-right">
                <span>Available Balance: </span>
                <span>₹ {{getParsedValueToDecimalTwoPlaces(availableBalance) | CurrencyConverter}}</span>
            </span>
        </div>
        <div class="transactionGridScroller" *ngIf="displayTransactionDialog">
            <sib-grid name="transactionGrid" #transactionGrid [columns]="transactionColumns"
                [config]="transactionGridConfig" [totalRecords]="totalTransactionRecords" [loading]="isLoading">
                <sib-grid-column field="icon">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <div *ngIf="rowData.transactions.transactionType === 'CREDIT'">
                            <i class="fa fa-plus-circle plus-sign"></i>
                        </div>
                        <div *ngIf="rowData.transactions.transactionType === 'DEBIT'">
                            <i class="fa fa-minus-circle minus-sign"></i>
                        </div>
                    </ng-template>
                </sib-grid-column>
                <sib-grid-column field="transactions.transactionType">
                    <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                        <p-dropdown [options]="transactionTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                            appendTo="body" placeholder="Transaction Type"
                            (onChange)="transactionGrid.filter($event.value,'transactions.transactionType',col.filterMatchMode)">
                        </p-dropdown>
                    </ng-template>
                </sib-grid-column>
                <sib-grid-column field="transactions.module">
                    <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                        <p-dropdown [options]="moduleDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                            appendTo="body" placeholder="Module"
                            (onChange)="transactionGrid.filter($event.value,'transactions.module',col.filterMatchMode)">
                        </p-dropdown>
                    </ng-template>
                </sib-grid-column>
            </sib-grid>
        </div>
    </p-dialog>
</div>