
import { map } from 'rxjs/operators';
import { Injectable, Injector } from "../../../../../../node_modules/@angular/core";
import { HttpClient } from "../../../../../../node_modules/@angular/common/http";
import { BASE_URL, ApiService } from "../../../../services/base-service/api.service";

@Injectable()
export class CustomerProfilePageService extends ApiService<any, any, any> {

    urlPath: string;
    baseUrl: string;


    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getCustomerById(id) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'customers/' + id + '?projection=customerProjection').pipe(
                map((res: Response) => { console.log("get customer by Id", res); return res; }));

        }

    }
}
