<div class="superbill-campaigns">
    <div *ngIf="isLoading">
        <div class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
    <!--  -->
    <div #superbillCardRef class="superbill-chart-container">
        <sib-superbill-chart [userEmailId]="userEmailId"></sib-superbill-chart>
        <!-- <sib-sales-chart [showTitle]="false" [showAction]="false"></sib-sales-chart> -->
    </div>
    <form [formGroup]="preBillGroup">
        <sib-grid [showFilter]="true" name="siteGrid" #sibGrid [columns]="columns" [buttons]="buttonConfig"
            [config]="gridConfig" (eEmitColumnSelection)="updatingUserGridConfig($event)" [totalRecords]="totalRecords"
            [showXlsExport]="showXlsExport" formControlName="siteGridControl" ngDefaultControl
            (eEmitExportXlsx)="exportXlsx()" (eResetDropdown)="resetStatus()">

            <sib-grid-column field="srNo">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span>
                        {{rowIndex+1}}
                    </span>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="billProgress">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span class="progress-container">
                        <p-progressBar [showValue]="true" [value]="getInvoiceProgressValue(rowData)">
                        </p-progressBar>
                    </span>
                </ng-template>
            </sib-grid-column>

            <!-- <sib-grid-column field="details">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
                    <img class="custom-grid-icon" src="assets/layout/images/next.svg" alt="" title="View Super BIll Details"
                        (click)="viewSuperBillDetails(rowData)">
                </ng-template>
            </sib-grid-column> -->

            <sib-grid-column field="displayName">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div class="link-wrapper">
                        <!-- (click)="viewSuperBillDetails(rowData, $event)" -->
                        <a *ngIf="rowData?.totalMountedItems > 0" class="link-1  after"
                            [routerLink]="['/billings/view/', rowData.id]">{{rowData.displayName}}</a>
                        <a *ngIf="rowData?.totalMountedItems === 0" class="link-1  after"
                            (click)="showMsg()">{{rowData.displayName}}</a>
                    </div>
                    <!-- <div class="link-wrapper" *ngIf="!admin">
                        {{rowData.displayName}}
                    </div> -->
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="summary.lspDiffAmt">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <div *ngIf="rowData.summary.lspDiffAmt >= 0" class="posDiff">
                        {{getAbsoluteValue(getIntegerRoundedOffValue(rowData.summary.lspDiffAmt)) | CurrencyConverter}}
                        ({{getAbsoluteValue(getIntegerRoundedOffValue(rowData.summary.lspDiffPctg)) |
                        CurrencyConverter}}%)
                    </div>
                    <div *ngIf="rowData.summary.lspDiffAmt < 0" class="negDiff">
                        {{getAbsoluteValue(getIntegerRoundedOffValue(rowData.summary.lspDiffAmt)) | CurrencyConverter}}
                        ({{getAbsoluteValue(getIntegerRoundedOffValue(rowData.summary.lspDiffPctg)) |
                        CurrencyConverter}}%)
                    </div>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="difference">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <!-- <div *ngIf="(rowData.summary.grandTotal - rowData.summary.totalGST) - rowData.billingAmount >= 0" class="negDiff">
                        {{parseValueToDecimal(rowData.summary.grandTotal - rowData.billingAmount - rowData.summary.totalGST) | CurrencyConverter}} -->
                    <div *ngIf="(rowData.summary.grandTotal) - rowData.billingAmount >= 0" class="negDiff">
                        {{parseValueToDecimal(rowData.summary.grandTotal - rowData.billingAmount) | CurrencyConverter}}
                    </div>
                    <!-- <div *ngIf="(rowData.summary.grandTotal - rowData.summary.totalGST) - rowData.billingAmount < 0"
                        class="posDiff">
                        {{parseValueToDecimal(rowData.billingAmount - rowData.summary.grandTotal +
                        rowData.summary.totalGST) | CurrencyConverter}} -->
                    <div *ngIf="(rowData.summary.grandTotal) - rowData.billingAmount < 0" class="posDiff">
                        {{parseValueToDecimal(rowData.billingAmount - rowData.summary.grandTotal) | CurrencyConverter}}
                    </div>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="status">
                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown #statusDropDown [options]="campaignStatusDropdown"
                        [style]="{'width':'90%','margin-top':'8px'}" appendTo="body" placeholder="Status"
                        (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)"></p-dropdown>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="superBillStatus">
                <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                    <p-dropdown #statusOpenClose [options]="superBillStatusDropdown"
                        [style]="{'width':'90%','margin-top':'8px'}" appendTo="body" placeholder="Status"
                        (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)"></p-dropdown>
                </ng-template>
            </sib-grid-column>

            <sib-grid-column field="delete">
                <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                    let-column="column">
                    <span *permission="'delete:superbills'">
                        <i class="fa fa-trash-o info-red size-16" title="Delete the superbill"
                            (click)="deleteSuperbill(rowData)" aria-hidden="true"></i>
                    </span>
                </ng-template>
            </sib-grid-column>

        </sib-grid>
    </form>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
        <p-footer>
            <button type="button" pButton class="mtab-save margin-hori-5" label="YES" (click)="cd.accept()"></button>
            <button type="button" pButton class="mtab-secondary" label="NO" (click)="cd.reject()"></button>
        </p-footer>
    </p-confirmDialog>

</div>