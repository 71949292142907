import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { Observable } from 'rxjs/Rx';
import { AppUrls } from '../urls';
import { PrintConfig } from '../../modals/billings/print-config';

@Injectable()
export class CommonService extends ApiService<any, any, any> {
    urlPath: string = 'common';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    /**
     * @description to save the rows config
     * @author Pulkit Bansal
     * @date 2019-10-19
     * @param {string[]} rowsOrder
     * @param {string} type --> invoice, migrated, reserved, creditnote
     * @param {string} id
     * @returns {Observable<any>}
     * @memberof CommonService
     */
    saveBillingRowsConfig(rowsOrder: string[], type: string, id: string): Observable<any> {
        return this.create(rowsOrder, { type: type, id: id }, AppUrls.BILLINGS + AppUrls.SAVE_ROWS_ORDER);
    }

    /**
     * @description to save the print config
     * @author Pulkit Bansal
     * @date 2019-10-19
     * @param {PrintConfig} printConfig
     * @param {string} id
     * @param {string} type --> invoice, migrated, reserved, creditnote
     * @returns {Observable<any>}
     * @memberof CommonService
     */
    saveBillingPrintConfig(printConfig: PrintConfig, format: string, id: string, type: string): Observable<any> {
        return this.create(printConfig, { type: type, format: format }, AppUrls.BILLINGS + AppUrls.SAVE + AppUrls.PRINT_CONFIGURATION + '/' + id);
    }
}
