<div class="update-invoice-dialog">
    <p-dialog header="Quick Update Invoice" [modal]="true" [(visible)]="displayUpdateInvoiceDialog"
        (onHide)="onUpdateInvoiceDialogHide()" [autoAlign]="false" showEffect="false" width="420" blockScroll="true">

        <div *ngIf="isLoading">
            <div class="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </div>

        <form #updateInvoiceDetailForm="ngForm" autocomplete="off">
            <div class="ui-g padding-0">
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Display Name
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input class="width100" id="displayName" type="text" pInputText name="displayName"
                            [(ngModel)]="displayName" required />
                    </div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Billing Name
                            <span class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <input class="width100" id="billingName" type="text" pInputText name="billingName"
                            [(ngModel)]="selectedCompany" required />
                    </div>
                    <div class="hint" *ngIf="selectedCompany && selectedCompanyGstNo" style="font-size: 10px;">
                        {{selectedCompany}} - {{selectedCompanyGstNo}}</div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>Branch
                            <span *ngIf="branchDropdown" class="required">*</span>
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <p-dropdown #groupedDropdown name="branches" [options]="branches" [autoWidth]="false"
                            [style]="{'width':'100%'}" placeholder="Select a Branch" [group]="true" appendTo="body"
                            dialog-append="updateInvoiceBranchDropdown" [disabled]="!selectedCompany"
                            [panelStyleClass]="'updateInvoiceCustomDropdown updateInvoiceBranchDropdown'"
                            (onChange)="onBranchChange()" [(ngModel)]="selectedBranch" [autoDisplayFirst]="false"
                            [required]="branchDropdown" changes [changedValue]="selectedBranch?.address?.branchName"
                            [changedType]="getChangedType()"
                            [changedField]="'invoiceGstDetail.branchAddress.branchName'"
                            [changedFieldDisplayName]="'Company Branch'">
                            <ng-template let-group pTemplate="group">
                                {{group.label}} - {{group.value.branchAddress[0]?.state?.name}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="hint" *ngIf="selectedBranch" style="font-size: 10px;">
                        {{selectedBranch.address.branchName}} - {{selectedBranch.address.address1}}</div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-4">
                        <div>PO
                        </div>
                    </div>
                    <div class="ui-g-8 margin-top--5">
                        <p-multiSelect #poDropdown="ngModel" [options]="purchaseOrdersOptions" name="po"
                            [(ngModel)]="selectedPO" appendTo="body" dialog-append="updateInvoicePODropdown"
                            [panelStyleClass]="'updateInvoiceMultiselectDropdown updateInvoicePODropdown'"
                            defaultLabel="Choose PO" [style]="{'width':'100%'}" maxSelectedLabels="0"
                            selectedItemsLabel="{0} PO selected" (onChange)="onPOChange($event)" changes
                            [changedValue]="selectedPO" [changedType]="getChangedType()"
                            [changedField]="'purchaseOrders'" [changedFieldDisplayName]="'Purchase Orders'"
                            [changedFieldArrayVariable]="'name'">
                            <ng-template let-po pTemplate="item">
                                <span *ngIf="po?.value?.poNumber"> {{po.label}} - {{po.value.poNumber}} - <a
                                        (click)="viewPO(po)">View</a></span>
                                <span *ngIf="!po?.value?.poNumber"> {{po.label}} - <a
                                        (click)="viewPO(po)">View</a></span>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>
                <div class="ui-g-12 padding-top-10">
                    <div class="ui-g-6 padding-left-0">
                        <span class="md-inputfield ui-float-label">
                            <input class="width100" id="poNumber" name="poNumber" trim="blur" type="text" pInputText
                                [(ngModel)]="poNumber"
                                [disabled]="!poDropdown.value || (selectedPO && selectedPO.length !== 1)" maxlength="50"
                                changes [changedValue]="poNumber" [changedType]="getChangedType()"
                                [changedField]="'poNumber'" [changeDescription]="'-'"
                                [changeAdditionalDescription]="'-'" [changedFieldDisplayName]="'PO Number'" />
                            <label for="poNumber">PO Number
                            </label>
                        </span>
                    </div>
                    <div class="ui-g-6">
                        <span class="md-inputfield ui-float-label">
                            <p-calendar id="poDate" name="poDate" dateFormat="ddMy" [showIcon]="true"
                                [style]="{'width':'91.5%'}" [autoZIndex]="true" [baseZIndex]="200000"
                                monthNavigator="true"
                                [disabled]="!poDropdown.value || (selectedPO && selectedPO.length !== 1)" [utc]="true"
                                yearNavigator="true" [yearRange]="'2017:2050'" appendTo="body" [(ngModel)]="poDate"
                                changes [changedValue]="poDate" [changedType]="getChangedType()"
                                [changedField]="'poDate'" [changeDescription]="'-'" [changeAdditionalDescription]="'-'"
                                [changedFieldDisplayName]="'PO Date'"></p-calendar>
                            <label for="poDate">PO Date
                            </label>
                            <span>
                                <i *ngIf="poDropdown.value && (poNumber || poDate)" class="fa fa-info"
                                    style="color: #194f8e"
                                    pTooltip="PO changes will be reflected in respective Invoice and Campaign."></i>
                            </span>
                        </span>

                    </div>

                </div>
                <div class="ui-g-12 text-align-center font-bold padding-top-0">OR</div>
                <div class="ui-g-12 margin-top--40" *ngIf="purchaseOrdersWrapper && purchaseOrdersWrapper.length">
                    <button pButton type="button" class="mtab-primary float-right" label="UPLOAD PO"
                        (click)="uploadFiles($event)"
                        [disabled]="(purchaseOrdersWrapper.length > 0 && (!purchaseOrdersWrapper[0].purchaseOrder.poNumber || !purchaseOrdersWrapper[0].purchaseOrder.poDate)) || firebaseUrlSaved"></button>
                </div>
                <div class="ui-g-12 card drop-file-container" pDroppable="event" pDroppableDisabled="!poDropdown.value"
                    *ngIf="purchaseOrdersWrapper.length === 0">
                    <div class="text-align-center" style="color: gray;">Drop Your Files Here</div>
                </div>
                <div class="ui-g-12 card bg-color" *ngIf="purchaseOrdersWrapper && purchaseOrdersWrapper.length">
                    <div class="ui-g-12 padding-bottom-5">
                        <div class="ui-g-11 padding-0">
                            {{purchaseOrdersWrapper[0].purchaseOrder.name}}
                        </div>
                        <div class="ui-g-1 padding-0" pTooltip="Remove the File">
                            <i class="fa fa-trash-o info-red" aria-hidden="true" (click)="removeFile(0)"></i>
                        </div>
                    </div>
                    <div class="ui-g-12 padding-0 padding-bottom-5">
                        <div class="ui-g-12">
                            <span class="md-inputfield">
                                <input #name="ngModel" id="name" type="text" trim="blur"
                                    [(ngModel)]="purchaseOrdersWrapper[0].newName" autocomplete="off" pInputText
                                    name="newName" title=" " [style]="{'width':'90%'}" />
                                <label for="newName">New Name</label>
                            </span>
                        </div>
                    </div>
                    <div class="ui-g-12 padding-top-10 padding-bottom-0">
                        <div class="ui-g-6 padding-left-0">
                            <span class="md-inputfield ui-float-label">
                                <input class="width100" id="purchaseOrderPoNumber" name="purchaseOrderPoNumber"
                                    trim="blur" type="text" pInputText
                                    [(ngModel)]="purchaseOrdersWrapper[0].purchaseOrder.poNumber" maxlength="50"
                                    required changes [changedValue]="poNumber" [changedType]="getChangedType()"
                                    [changedField]="'poNumber'" [changeDescription]="'-'"
                                    [changeAdditionalDescription]="'-'" [changedFieldDisplayName]="'PO Number'" />
                                <label for="purchaseOrderPoNumber">PO Number <span class="required">*</span>
                                </label>
                            </span>
                        </div>
                        <div class="ui-g-6">
                            <span class="md-inputfield ui-float-label">
                                <p-calendar id="purchaseOrderPoDate" name="purchaseOrderPoDate" dateFormat="ddMy"
                                    [showIcon]="true" [style]="{'width':'100%'}" [autoZIndex]="true"
                                    [baseZIndex]="200000" monthNavigator="true" [utc]="true" yearNavigator="true"
                                    [yearRange]="'2017:2050'" appendTo="body"
                                    [(ngModel)]="purchaseOrdersWrapper[0].purchaseOrder.poDate" required changes
                                    [changedValue]="poDate" [changedType]="getChangedType()" [changedField]="'poDate'"
                                    [changeDescription]="'-'" [changeAdditionalDescription]="'-'"
                                    [changedFieldDisplayName]="'PO Date'">
                                </p-calendar>
                                <label for="purchaseOrderPoDate">PO Date <span class="required">*</span>
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="ui-g-12  padding-0">
                        <p-progressBar [value]="files[0].progress"></p-progressBar>
                    </div>
                </div>
            </div>
        </form>

        <div class="ctext-center">
            <button type="submit" class="mtab-save margin-hori-5" pButton (click)="updateInvoice()"
                [disabled]="!updateInvoiceDetailForm.form.valid || (purchaseOrdersWrapper && purchaseOrdersWrapper.length > 0 ? !firebaseUrlSaved : false) || purchaseOrdersWrapper.length > 0 && (!purchaseOrdersWrapper[0].purchaseOrder.poNumber || !purchaseOrdersWrapper[0].purchaseOrder.poDate)"
                label="UPDATE"></button>
            <button type="button" class="mtab-secondary" pButton (click)="cancelUpdate()" label="CANCEL"></button>
        </div>

    </p-dialog>
</div>