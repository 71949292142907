
import { of as observableOf, Subscription, Observable } from 'rxjs';
import { Component, ViewEncapsulation, AfterViewInit, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MenuItem } from "primeng/primeng";
import { Campaign } from "../../modals/campaigns/campaign";
import { Invoice } from "../../modals/billings/invoice";
import { BillingActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { Router } from "@angular/router";
import { AdditionalItem } from "../../modals/billings/additional-item";
import { GridColumn, EditableGridFieldTypes, GridConfig, SelectionMode, GridPaginationEvent } from "../../components/sib-forms/grid/grid.config";
import { DateUtil } from "../../helpers/date.util";
import { FormGroup, FormControl } from "@angular/forms";
import { GridActionButtonConfig } from "../../components/sib-forms/grid/grid-action-button.config";
import { CombinedItem } from "../../modals/billings/combined-item";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { TaxConfigurationService } from "../../services/shared/tax-configuration.service";
import * as utils from "../../helpers/utils";
import * as _ from "lodash";
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { InvoiceFormatEnum } from '../../shared/constants/invoice-format-enum';

@Component({
    selector: 'sib-invoice-summary-grid',
    templateUrl: './invoice-summary-grid.component.html',
    styleUrls: ['./invoice-summary-grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class InvoiceSummaryGridComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('invoiceSummaryGrid')
    invoiceSummaryGridRef: GridComponent<CombinedItem>;

    _invoice: Invoice = new Invoice();
    items: CombinedItem[] = []; // to hold the current invoice items
    previousItems: CombinedItem[] = []; // to hold the previous invoice items
    comparisionItems: CombinedItem[] = []; // to merge the current and previous invoice items
    // to sort each type of item independently
    rentItems: CombinedItem[] = [];
    printingItems: CombinedItem[] = [];
    mountingItems: CombinedItem[] = [];
    previousInvoiceRentItems: CombinedItem[] = [];
    previousInvoicePrintingItems: CombinedItem[] = [];
    previousInvoiceMountingItems: CombinedItem[] = [];

    @Input() set invoice(invoice) {
        this._invoice = invoice;
        this._invoice = JSON.parse(JSON.stringify(this._invoice));
        // this.grouped = this._invoice.grouped;
    }

    get invoice() {
        return this._invoice;
    }

    _tempInvoice: Invoice = new Invoice();
    @Input() set tempInvoice(tempInvoice) {
        this._tempInvoice = tempInvoice;
        this._tempInvoice = JSON.parse(JSON.stringify(this._tempInvoice));
        this.setPreviousInvoiceItems();
    }

    get tempInvoice() {
        return this._tempInvoice;
    }

    _showChanges: boolean = false;
    @Input() set showChanges(showChanges) {
        this._showChanges = showChanges;
        this._showChanges = JSON.parse(JSON.stringify(this._showChanges));
        this.setItems();
        // this.setPreviousInvoiceItems();
        if (this.gridConfig.dataLoadFunction !== undefined) {
            this.invoiceSummaryGridRef.refresh();
        }
    }

    get showChanges() {
        return this._showChanges;
    }

    _revert = false;
    @Input() set revert(revert) {
        this._revert = revert;
        this._revert = JSON.parse(JSON.stringify(this._revert));
        if (this._revert) {
            this.setItems();
        }
    }

    get revert() {
        return this._revert;
    }

    _grouped = false;
    @Input() set grouped(grouped) {
        this._grouped = grouped;
        this.setItems();
        // this.setPreviousInvoiceItems();
        if (this.gridConfig.dataLoadFunction !== undefined) {
            this.invoiceSummaryGridRef.refresh();
        }
    }

    get grouped() {
        return this._grouped;
    }

    _invoiceFormat: InvoiceFormatEnum;
    @Input() set invoiceFormat(invoiceFormat) {
        if (invoiceFormat) {
            this._invoiceFormat = invoiceFormat;
            this._invoiceFormat = JSON.parse(JSON.stringify(this._invoiceFormat));
        }
        this.setColumns();
    }

    get invoiceFormat() {
        return this._invoiceFormat;
    }

    @Input() isInvFormatFullDetail: boolean;

    /**
     * @description to emit the rows for reorder
     * @type {EventEmitter<Array<any>>}
     * @memberof InvoiceSummaryGridComponent
     */
    @Output() eEmitRowsToRearrange: EventEmitter<Array<any>> = new EventEmitter();

    isLoading = false;
    subscription: Subscription[] = [];

    igstParameter: number;
    gstParameter: number;
    cgstParameter: number;
    sgstParameter: number;

    defaultSortInProgress = false;

    // for amount field in package rate
    firstRentalItem = true;
    firstPrintingMountingItem = true;
    firstPreviusRentalItem = true;
    firstPreviousPrintingMountingItem = true;


    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2vw',
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'inventory',
            name: 'inventory',
            header: 'Description',
            required: true,
            editable: false,
            hidden: false,
            width: '25vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: false,
            type: EditableGridFieldTypes.CUSTOM,
        },
        {
            field: 'itemStartDate',
            name: 'itemStartDate',
            header: 'Start Date',
            required: true,
            editable: false,
            hidden: false,
            width: '4vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
        },
        {
            field: 'itemEndDate',
            name: 'itemEndDate',
            header: 'End Date',
            required: true,
            editable: false,
            hidden: false,
            width: '4vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
        },
        {
            field: 'days',
            name: 'days',
            header: 'Days',
            required: true,
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            width: '2vw',
            sortable: true,
        },
        {
            field: 'rate',
            name: 'rate',
            header: 'Monthly Rate',
            required: true,
            editable: false,
            default: true,
            styleClass: 'invoice-grid',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            width: '4vw',
            sortable: true,
        },
        // {
        //     field: 'printRate',
        //     name: 'printRate',
        //     header: 'Print Rate',
        //     required: true,
        //     editable: false,
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     hidden: false,
        //     permanent: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     width: '4vw',
        //     sortable: true,
        //     displayFn: (data) => {
        //         if (data.type === "print") {
        //             return data.printRate;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        // {
        //     field: 'mountRate',
        //     name: 'mountRate',
        //     header: 'Mount Rate',
        //     required: true,
        //     editable: false,
        //     default: true,
        //     styleClass: 'word-break-wrap',
        //     hidden: false,
        //     permanent: true,
        //     type: EditableGridFieldTypes.TEXT,
        //     width: '4vw',
        //     sortable: true,
        //     displayFn: (data) => {
        //         if (data.type === "mount") {
        //             return data.mountRate;
        //         } else {
        //             return '-';
        //         }
        //     },
        // },
        {
            field: 'amount',
            name: 'amount',
            header: 'Cost',
            permanent: true,
            sortable: true,
            hidden: false,
            required: true,
            editable: false,
            width: '4vw',
            type: EditableGridFieldTypes.CUSTOM,
            styleClass: 'invoice-grid',
        },
    ];

    totalRecords: number;
    invoiceSummaryGroup: FormGroup;
    gridConfig: GridConfig<CombinedItem> = new GridConfig<CombinedItem>();
    buttonConfig: GridActionButtonConfig;

    total = 0;
    igstTax = 0;
    cgstTax = 0;
    sgstTax = 0;
    grandTotal = 0;

    bothInvoiceRentItems: CombinedItem[] = [];
    bothPreviousInvoiceRentItems: CombinedItem[] = [];
    bothInvoicePrintingItems: CombinedItem[] = [];
    bothPreviousInvoicePrintingItems: CombinedItem[] = [];
    bothInvoiceMountingItems: CombinedItem[] = [];
    bothPreviousInvoiceMountingItems: CombinedItem[] = [];
    // for comparision items for invoice formats including 'Ro'
    bothInvoiceRoItems: CombinedItem[] = [];
    bothPreviousInvoiceRoItems: CombinedItem[] = [];
    bothInvoiceRoRentItems: CombinedItem[] = [];
    bothPreviousInvoiceRoRentItems: CombinedItem[] = [];
    bothInvoiceRoPrintMountItems: CombinedItem[] = [];
    bothPreviousInvoiceRoPrintMountItems: CombinedItem[] = [];

    constructor(
        private currencyPipe: CurrencyHelperPipe,
        private taxConfigurationService: TaxConfigurationService
    ) { }

    ngOnInit() {
        this.getTaxConfiguration();
        this.setFormGroupObjects();
        this.setGridConfigObject();
    }

    ngAfterViewInit() {
        if (this.gridConfig.dataLoadFunction !== undefined) {
            this.invoiceSummaryGridRef.refresh();
        }
    }

    setFormGroupObjects() {
        this.invoiceSummaryGroup = new FormGroup({
            invoiceSummaryGridControl: new FormControl()
        });
    }

    setGridConfigObject() {
        this.gridConfig.getDefaultModel = () => new CombinedItem();
        this.gridConfig.model = CombinedItem;
        this.gridConfig.selectionMode = SelectionMode.Multi;
        this.gridConfig.editable = true;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        // this.gridConfig.rowExpandMode = 'single';
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.showColorCode = false;
        this.gridConfig.lazy = false;
        this.gridConfig.enablePagination = false;
        this.gridConfig.paginatorConfig.alwaysShowPaginator = false;
        this.gridConfig.scrollable = true;
        // this.gridConfig.sortMode = 'multiple';
        // this.gridConfig.multiSortMeta = this.createMetaSortData();


        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            if (this.showChanges) {
                return observableOf(this.comparisionItems);
            } else {
                return observableOf(this.items);
            }
        };
    }

    // createMetaSortData() {
    //     // return [{ field: 'mountingItems.imageStatus', order: -1 }, { field: 'mountingItems.date', order: -1 }];
    //     return [{ field: 'city', order: 1 }, { field: 'areaName', order: 1 }];
    //     // return null;
    // }

    getTaxConfiguration() {
        this.taxConfigurationService.getTaxConfiguration();

        this.subscription.push(
            this.taxConfigurationService.gst
                .subscribe((gst) => {
                    this.gstParameter = gst;
                }));

        this.subscription.push(
            this.taxConfigurationService.cgst
                .subscribe((cgst) => {
                    this.cgstParameter = cgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.sgst
                .subscribe((sgst) => {
                    this.sgstParameter = sgst;
                }));

        this.subscription.push(
            this.taxConfigurationService.igst
                .subscribe((igst) => {
                    this.igstParameter = igst;
                    if (this._invoice.rentItems.length > 0 || this._invoice.printingItems.length > 0 || this._invoice.mountingItems.length > 0) {
                        this.setItems();
                    }
                }));

    }

    setItems() {
        this.items = [];
        this.total = 0;
        this.igstTax = 0;
        this.cgstTax = 0;
        this.sgstTax = 0;
        this.grandTotal = 0;

        this.setItemsBasedOnInvoiceFormat();

        if (this.validateInvioceRowsOrder() && !this.defaultSortInProgress) {
            this.updateItemsOrder(this._invoice.rowsOrder, true);
        }

        // this.emitRowsToRearrange();

        if (this.gridConfig.dataLoadFunction !== undefined) {
            this.invoiceSummaryGridRef.refresh();
        }
        if (this.tempInvoice && this.tempInvoice.id) {
            // this.createComparisionItems();
            this.setPreviousInvoiceItems();
        }
    }

    setItemsBasedOnInvoiceFormat() {
        this.items = [];
        this.setRentItems();
        this.setPrintingItems();
        this.setMountingItems();
        this.sortItems();
        switch (this.isInvFormatFullDetail ? InvoiceFormatEnum[this._invoiceFormat] : InvoiceFormatEnum[this.invoice.invoiceFormat]) {
            case "Full Detail":
            case "Less Detail":
            case "Package Rate inclusive of Printing/Mounting":
            case "Package Rate with separate Printing/Mounting": {
                this.items = [...this.rentItems, ...this.printingItems, ...this.mountingItems];
                break;
            }
            case "As Per Ro inclusive of Printing/Mounting": {
                const item: CombinedItem = new CombinedItem();
                item.type = "RO";
                item.id = "As Per Ro";
                item.amount = this.rentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0) + this.printingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.mountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0);
                this.items = [item];
                break;
            }
            case "As Per Ro with separate Printing/Mounting": {
                const item1: CombinedItem = new CombinedItem();
                const item2: CombinedItem = new CombinedItem();
                item1.type = "RO_RENT";
                item1.amount = this.rentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0);
                item1.id = "As Per Ro - Rent";
                item2.type = "RO_PRINT_MOUNT";
                item2.amount = this.printingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.mountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0);
                item2.id = "As Per Ro - Printing/Mounting";
                this.items = [item1, item2];
                break;
            }
            default: {
                this.items = [...this.rentItems, ...this.printingItems, ...this.mountingItems];
            }
        }
        this.items = JSON.parse(JSON.stringify(this.items));
    }

    setRentItems() {
        this.rentItems = [];
        this._invoice.rentItems.forEach((item) => {
            this.rentItems.push(new CombinedItem().setRentItem(item, "Rent"));
        });
    }

    setPrintingItems() {
        this.printingItems = [];
        if (this.grouped) {
            this.getPrintingItemMapByPrintRate(this.invoice.printingItems).forEach((value, key) => {
                this.printingItems.push(new CombinedItem().setPrintingMountingItem('Printing', key, value));
            });
        } else {
            this._invoice.printingItems.forEach((item) => {
                if (item.amount > 0) {
                    this.printingItems.push(new CombinedItem().setAdditionalItem(item, "Printing"));
                }
            });
        }
    }

    sortItems() {
        this.sortRentItems();
        this.sortPrintingItems();
        this.sortMountingItems();
    }

    setMountingItems() {
        this.mountingItems = [];
        if (this.grouped) {
            this.getMountingItemMapByPricePerSqft(this.invoice.mountingItems).forEach((value, key) => {
                this.mountingItems.push(new CombinedItem().setPrintingMountingItem('Mounting', key, value));
            });
        } else {
            this._invoice.mountingItems.forEach((item) => {
                if (item.amount > 0) {
                    this.mountingItems.push(new CombinedItem().setAdditionalItem(item, "Mounting"));
                }
            });
        }
    }

    sortRentItems() {
        this.rentItems = utils.sortArray(this.rentItems, "city");
    }

    sortPrintingItems() {
        this.printingItems = utils.sortArray(this.printingItems, "city");
    }

    sortMountingItems() {
        this.mountingItems = utils.sortArray(this.mountingItems, "city");
    }

    setColumns(calledFromThisComponent = true) {
        this.columns.filter((column) => {
            switch (InvoiceFormatEnum[this._invoiceFormat]) {
                case "Full Detail": {
                    column.hidden = false;
                    switch (column.field) {
                        case 'srNo': {
                            // column.width = "2vw";
                            column.width = "2%";
                            break;
                        }
                        case 'inventory': {
                            // column.width = "25vw";
                            column.width = "38%";
                            break;
                        }
                        case 'itemStartDate': {
                            // column.width = "4vw";
                            column.width = "5%";
                            break;
                        }
                        case 'itemEndDate': {
                            // column.width = "4vw";
                            column.width = "5%";
                            break;
                        }
                        case 'days': {
                            // column.width = "2vw";
                            column.width = "3%";
                            break;
                        }
                        case 'rate': {
                            // column.width = "4vw";
                            column.width = "6%";
                            break;
                        }
                        case 'amount': {
                            column.header = "Cost";
                            // column.width = "4vw";
                            column.width = "6%";
                            break;
                        }
                    }
                    break;
                }
                case "Less Detail":
                case "As Per Ro inclusive of Printing/Mounting":
                case "As Per Ro with separate Printing/Mounting": {
                    switch (column.field) {
                        case "itemStartDate":
                        case "itemEndDate":
                        case "days":
                        case "rate": {
                            column.hidden = true;
                            break;
                        }
                        case 'srNo': {
                            // column.width = "2vw";
                            column.width = "2%";
                            break;
                        }
                        case 'inventory': {
                            // column.width = "30vw";
                            column.width = "48%";
                            break;
                        }
                        case "amount": {
                            column.header = "Cost";
                            // column.width = "4vw";
                            column.width = "5%";
                            break;
                        }
                        default: {
                            column.hidden = false;
                        }
                    }
                    break;
                }
                case "Package Rate inclusive of Printing/Mounting":
                case "Package Rate with separate Printing/Mounting": {
                    switch (column.field) {
                        case "itemStartDate":
                        case "itemEndDate":
                        case "days":
                        case "rate": {
                            column.hidden = true;
                            break;
                        }
                        case 'srNo': {
                            // column.width = "2vw";
                            column.width = "2%";
                            break;
                        }
                        case 'inventory': {
                            // column.width = "30vw";
                            column.width = "48%";
                            break;
                        }
                        case "amount": {
                            column.header = "Package Rate";
                            // column.width = "4vw";
                            column.width = "5%";
                            break;
                        }
                        default: {
                            column.hidden = false;
                        }
                    }
                    break;
                }
                default: {
                    column.hidden = false;
                    switch (column.field) {
                        case 'srNo': {
                            // column.width = "2vw";
                            column.width = "2%";
                            break;
                        }
                        case 'inventory': {
                            // column.width = "25vw";
                            column.width = "38%";
                            break;
                        }
                        case 'itemStartDate': {
                            // column.width = "4vw";
                            column.width = "5%";
                            break;
                        }
                        case 'itemEndDate': {
                            // column.width = "4vw";
                            column.width = "5%";
                            break;
                        }
                        case 'days': {
                            // column.width = "2vw";
                            column.width = "3%";
                            break;
                        }
                        case 'rate': {
                            // column.width = "4vw";
                            column.width = "6%";
                            break;
                        }
                        case 'amount': {
                            column.header = "Cost";
                            // column.width = "4vw";
                            column.width = "6%";
                            break;
                        }
                    }
                }
            }
            return column;
        });
        if (calledFromThisComponent) {
            this.setItems();
        }
        this.invoiceSummaryGridRef.setAllColumns();
    }

    setPreviousInvoiceItems() {
        this.previousItems = [];
        this.setPreviousInvoiceItemsBasedOnInvoiceFormat();
        if (this.validateInvioceRowsOrder() && !this.defaultSortInProgress) {
            this.updatePreviousItemsOrder(this._invoice.rowsOrder);
        }
        if (this.items) {
            this.createComparisionItems();
        }
    }

    setPreviousInvoiceItemsBasedOnInvoiceFormat() {
        this.setPreviousInvoiceRentItems();
        this.setPreviousInvoicePrintingItems();
        this.setPreviousInvoiceMountingItems();
        this.sortPreviousInvoiceItems();
        switch (InvoiceFormatEnum[this._invoiceFormat]) {
            case "Full Detail":
            case "Less Detail":
            case "Package Rate inclusive of Printing/Mounting":
            case "Package Rate with separate Printing/Mounting": {
                this.previousItems = [...this.previousInvoiceRentItems, ...this.previousInvoicePrintingItems, ...this.previousInvoiceMountingItems];
                break;
            }
            case "As Per Ro inclusive of Printing/Mounting": {
                const item: CombinedItem = new CombinedItem();
                item.type = "RO";
                item.id = "As Per Ro";
                item.amount = this.previousInvoiceRentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0) + this.previousInvoicePrintingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.previousInvoiceMountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0);
                this.previousItems = [item];
                break;
            }
            case "As Per Ro with separate Printing/Mounting": {
                const item1: CombinedItem = new CombinedItem();
                const item2: CombinedItem = new CombinedItem();
                item1.type = "RO_RENT";
                item1.amount = this.previousInvoiceRentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0);
                item1.id = "As Per Ro - Rent";
                item2.type = "RO_PRINT_MOUNT";
                item2.amount = this.previousInvoicePrintingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.previousInvoiceMountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0);
                item2.id = "As Per Ro - Printing/Mounting";
                this.previousItems = [item1, item2];
                break;
            }
            default: {
                this.previousItems = [...this.previousInvoiceRentItems, ...this.previousInvoicePrintingItems, ...this.previousInvoiceMountingItems];
            }
        }
        this.previousItems = JSON.parse(JSON.stringify(this.previousItems));
    }

    setPreviousInvoiceRentItems() {
        this.previousInvoiceRentItems = [];
        this.tempInvoice.rentItems.forEach((item) => {
            this.previousInvoiceRentItems.push(new CombinedItem().setRentItem(item, "Rent"));
        });
    }

    setPreviousInvoicePrintingItems() {
        this.previousInvoicePrintingItems = [];
        if (this.grouped) {
            this.getPrintingItemMapByPrintRate(this.tempInvoice.printingItems).forEach((value, key) => {
                this.previousInvoicePrintingItems.push(new CombinedItem().setPrintingMountingItem('Printing', key, value));
            });
        } else {
            this.tempInvoice.printingItems.forEach((item) => {
                if (item.amount > 0) {
                    this.previousInvoicePrintingItems.push(new CombinedItem().setAdditionalItem(item, "Printing"));
                }
            });
        }
    }

    setPreviousInvoiceMountingItems() {
        this.previousInvoiceMountingItems = [];
        if (this.grouped) {
            this.getMountingItemMapByPricePerSqft(this.tempInvoice.mountingItems).forEach((value, key) => {
                this.previousInvoiceMountingItems.push(new CombinedItem().setPrintingMountingItem('Mounting', key, value));
            });
        } else {
            this.tempInvoice.mountingItems.forEach((item) => {
                if (item.amount > 0) {
                    this.previousInvoiceMountingItems.push(new CombinedItem().setAdditionalItem(item, "Mounting"));
                }
            });
        }
    }

    sortPreviousInvoiceItems() {
        this.sortPreviousInvoiceRentItems();
        this.sortPreviousInvoicePrintingItems();
        this.sortPreviousInvoiceMountingItems();
    }

    sortPreviousInvoiceRentItems() {
        this.previousInvoiceRentItems = utils.sortArray(this.previousInvoiceRentItems, "city");
    }

    sortPreviousInvoicePrintingItems() {
        this.previousInvoicePrintingItems = utils.sortArray(this.previousInvoicePrintingItems, "city");
    }

    sortPreviousInvoiceMountingItems() {
        this.previousInvoiceMountingItems = utils.sortArray(this.previousInvoiceMountingItems, "city");
    }

    createComparisionItems() {
        switch (InvoiceFormatEnum[this._invoiceFormat]) {
            case "As Per Ro inclusive of Printing/Mounting": {
                this.bothInvoiceRoItems = this.items.filter(item => item !== undefined && item !== null && item.type === "RO");
                this.bothPreviousInvoiceRoItems = this.previousItems.filter(item => item !== undefined && item !== null && item.type === "RO");
                this.comparisionItems = [...this.bothInvoiceRoItems];
                break;
            }
            case "As Per Ro with separate Printing/Mounting": {
                this.bothInvoiceRoRentItems = this.items.filter(item => item !== undefined && item !== null && item.type === "RO_RENT");
                this.bothPreviousInvoiceRoRentItems = this.previousItems.filter(item => item !== undefined && item !== null && item.type === "RO_RENT");
                this.bothInvoiceRoPrintMountItems = this.items.filter(item => item !== undefined && item !== null && item.type === "RO_PRINT_MOUNT");
                this.bothPreviousInvoiceRoPrintMountItems = this.previousItems.filter(item => item !== undefined && item !== null && item.type === "RO_PRINT_MOUNT");
                this.comparisionItems = [...this.bothInvoiceRoRentItems, ...this.bothInvoiceRoPrintMountItems];
                break;
            }
            default: {
                this.bothInvoiceRentItems = [];
                this.bothPreviousInvoiceRentItems = [];
                this.bothInvoicePrintingItems = [];
                this.bothPreviousInvoicePrintingItems = [];
                this.bothInvoiceMountingItems = [];
                this.bothPreviousInvoiceMountingItems = [];
                const invoiceRentItems: CombinedItem[] = this.items.filter(item => item !== undefined && item.type === 'Rent');
                const invoicePrintingItems: CombinedItem[] = this.items.filter(item => item !== undefined && item.type === 'Printing');
                const invoiceMountingItems: CombinedItem[] = this.items.filter(item => item !== undefined && item.type === 'Mounting');
                const previousInvoiceRentItems: CombinedItem[] = this.previousItems.filter(item => item !== undefined && item.type === 'Rent');
                const previousInvoicePrintingItems: CombinedItem[] = this.previousItems.filter(item => item !== undefined && item.type === 'Printing');
                const previousInvoiceMountingItems: CombinedItem[] = this.previousItems.filter(item => item !== undefined && item.type === 'Mounting');
                const onlyPreviousInvoiceRentItems: CombinedItem[] = this.getItemsFromLists(previousInvoiceRentItems, invoiceRentItems, 'Rent', true);
                onlyPreviousInvoiceRentItems.forEach((item) => item.statusType = 'delete');
                const onlyInvoiceRentItems: CombinedItem[] = this.getItemsFromLists(invoiceRentItems, previousInvoiceRentItems, 'Rent', true);
                onlyInvoiceRentItems.forEach((item) => item.statusType = 'new');
                // const bothInvoiceRentItems: CombinedItem[] = this.getItemsFromLists(invoiceRentItems, previousInvoiceRentItems, 'Rent', false);
                // const bothPreviousInvoiceRentItems: CombinedItem[] = this.getItemsFromLists(previousInvoiceRentItems, invoiceRentItems, 'Rent', false);
                this.bothInvoiceRentItems = this.getItemsFromLists(invoiceRentItems, previousInvoiceRentItems, 'Rent', false);
                this.bothPreviousInvoiceRentItems = this.getItemsFromLists(previousInvoiceRentItems, invoiceRentItems, 'Rent', false);
                // bothInvoiceRentItems.forEach((item, index) => {
                //     item.statusType = (index % 2 === 0) ? 'old-both' : 'new-both';
                // });
                // bothInvoiceRentItems.forEach((item) => item.statusType = 'both');
                this.bothInvoiceRentItems.forEach((item) => item.statusType = 'both');
                const onlyPreviousInvoicePrintingItems: CombinedItem[] = this.getItemsFromLists(previousInvoicePrintingItems, invoicePrintingItems, 'Printing', true);
                onlyPreviousInvoicePrintingItems.forEach((item) => item.statusType = 'delete');
                const onlyInvoicePrintingItems: CombinedItem[] = this.getItemsFromLists(invoicePrintingItems, previousInvoicePrintingItems, 'Printing', true);
                onlyInvoicePrintingItems.forEach((item) => item.statusType = 'new');
                // const bothInvoicePrintingItems: CombinedItem[] = this.getItemsFromLists(invoicePrintingItems, previousInvoicePrintingItems, 'Printing', false);
                // const bothPreviousInvoicePrintingItems: CombinedItem[] = this.getItemsFromLists(previousInvoicePrintingItems, invoicePrintingItems, 'Printing', false);
                this.bothInvoicePrintingItems = (this.grouped) ? this.getItemsFromLists(invoicePrintingItems, previousInvoicePrintingItems, 'Printing', false) : this.getPrintMountItemsForUngrouped(invoicePrintingItems, previousInvoicePrintingItems, 'Printing', false);
                this.bothPreviousInvoicePrintingItems = (this.grouped) ? this.getItemsFromLists(previousInvoicePrintingItems, invoicePrintingItems, 'Printing', false) : this.getPrintMountItemsForUngrouped(previousInvoicePrintingItems, invoicePrintingItems, 'Printing', false);
                // bothInvoicePrintingItems.forEach((item, index) => {
                //     item.statusType = (index % 2 === 0) ? 'old-both' : 'new-both';
                // });
                // bothInvoicePrintingItems.forEach((item) => item.statusType = 'both');
                this.bothInvoicePrintingItems.forEach((item) => item.statusType = 'both');
                const onlyPreviousInvoiceMountingItems: CombinedItem[] = this.getItemsFromLists(previousInvoiceMountingItems, invoiceMountingItems, 'Mounting', true);
                onlyPreviousInvoiceMountingItems.forEach((item) => item.statusType = 'delete');
                const onlyInvoiceMountingItems: CombinedItem[] = this.getItemsFromLists(invoiceMountingItems, previousInvoiceMountingItems, 'Mounting', true);
                onlyInvoiceMountingItems.forEach((item) => item.statusType = 'new');
                // const bothInvoiceMountingItems: CombinedItem[] = this.getItemsFromLists(invoiceMountingItems, previousInvoiceMountingItems, 'Mounting', false);
                // const bothPreviousInvoiceMountingItems: CombinedItem[] = this.getItemsFromLists(previousInvoiceMountingItems, invoiceMountingItems, 'Mounting', false);
                this.bothInvoiceMountingItems = (this.grouped) ? this.getItemsFromLists(invoiceMountingItems, previousInvoiceMountingItems, 'Mounting', false) : this.getPrintMountItemsForUngrouped(invoiceMountingItems, previousInvoiceMountingItems, 'Mounting', false);
                this.bothPreviousInvoiceMountingItems = (this.grouped) ? this.getItemsFromLists(previousInvoiceMountingItems, invoiceMountingItems, 'Mounting', false) : this.getPrintMountItemsForUngrouped(previousInvoiceMountingItems, invoiceMountingItems, 'Mounting', false);
                // bothInvoiceMountingItems.forEach((item, index) => {
                //     item.statusType = (index % 2 === 0) ? 'old-both' : 'new-both';
                // });
                // bothInvoiceMountingItems.forEach((item) => item.statusType = 'both');
                this.bothInvoiceMountingItems.forEach((item) => item.statusType = 'both');
                // this.comparisionItems = [...onlyPreviousInvoiceRentItems, ...bothInvoiceRentItems, ...onlyInvoiceRentItems, ...onlyPreviousInvoicePrintingItems, ...bothInvoicePrintingItems, ...onlyInvoicePrintingItems, ...onlyPreviousInvoiceMountingItems, ...bothInvoiceMountingItems, ...onlyInvoiceMountingItems];
                this.comparisionItems = [...onlyPreviousInvoiceRentItems, ...this.bothInvoiceRentItems, ...onlyInvoiceRentItems, ...onlyPreviousInvoicePrintingItems, ...this.bothInvoicePrintingItems, ...onlyInvoicePrintingItems, ...onlyPreviousInvoiceMountingItems, ...this.bothInvoiceMountingItems, ...onlyInvoiceMountingItems];
            }
        }
        this.comparisionItems = JSON.parse(JSON.stringify(this.comparisionItems));
        if (this.invoice.status && this.gridConfig.dataLoadFunction !== undefined) {
            this.invoiceSummaryGridRef.refresh();
        }
    }

    getPreviousInvoiceRentItem(row) {
        const index = this.bothPreviousInvoiceRentItems.findIndex((item) => item.customId === row.customId);
        return this.bothPreviousInvoiceRentItems[index];
    }

    getPreviousInvoicePrintingItem(row) {
        const index = (this.grouped) ? this.bothPreviousInvoicePrintingItems.findIndex((item) => item.printRate === row.printRate && item.squareFeet === row.squareFeet) : this.bothPreviousInvoicePrintingItems.findIndex((item) => item.customId === row.customId);
        return this.bothPreviousInvoicePrintingItems[index];
    }

    getPreviousInvoiceMountingItem(row) {
        const index = (this.grouped) ? this.bothPreviousInvoiceMountingItems.findIndex((item) => item.mountRate === row.mountRate && item.squareFeet === row.squareFeet) : this.bothPreviousInvoiceMountingItems.findIndex((item) => item.customId === row.customId);
        return this.bothPreviousInvoiceMountingItems[index];
    }

    getPreviousInvoiceRoRentPrintMountItem(row) {
        if (row && row.type) {
            switch (row.type) {
                case "RO": {
                    return this.bothPreviousInvoiceRoItems[0];
                }
                case "RO_RENT": {
                    return this.bothPreviousInvoiceRoRentItems[0];
                }
                case "RO_PRINT_MOUNT": {
                    return this.bothPreviousInvoiceRoPrintMountItems[0];
                }
            }
        } else {
            return;
        }
    }

    getItemsFromLists(fromList, comparisionList, type, unique) {
        const list: CombinedItem[] = [];
        fromList.filter((item) => {
            let found = false;
            let index = -1;
            comparisionList.forEach((itm, ind) => {
                switch (type) {
                    case 'Rent': {
                        if (item.customId === itm.customId) {
                            // if (item.id === itm.id) {
                            found = true;
                            index = ind;
                        }
                        break;
                    }
                    case 'Printing': {
                        if (item.printRate === itm.printRate && item.squareFeet === itm.squareFeet) {
                            found = true;
                            index = ind;
                        }
                        break;
                    }
                    case 'Mounting': {
                        if (item.mountRate === itm.mountRate && item.squareFeet === itm.squareFeet) {
                            found = true;
                            index = ind;
                        }
                        break;
                    }
                }
            });
            if (unique) {
                if (!found) {
                    list.push(item);
                }
            } else {
                if (found) {
                    list.push(item);
                    // list.push(comparisionList[index]);
                }
            }
        });
        return list;
    }

    getPrintMountItemsForUngrouped(fromList, comparisionList, type, unique) {
        const list: CombinedItem[] = [];
        fromList.filter((item) => {
            let found = false;
            let index = -1;
            comparisionList.forEach((itm, ind) => {
                switch (type) {
                    case 'Printing': {
                        if (item.customId === itm.customId) {
                            // if (item.id === itm.id) {
                            found = true;
                            index = ind;
                        }
                        break;
                    }
                    case 'Mounting': {
                        // if (item.customId === itm.customId) {
                        if (item.id === itm.id) {
                            found = true;
                            index = ind;
                        }
                        break;
                    }
                }
            });
            if (unique) {
                if (!found) {
                    list.push(item);
                }
            } else {
                if (found) {
                    list.push(item);
                    // list.push(comparisionList[index]);
                }
            }
        });
        return list;
    }

    calculateTax(value, taxValue) {
        return Number(Math.round(value * taxValue / 100).toFixed(2));
    }

    onSort(event) {
        this.items = this.invoiceSummaryGridRef.getDataTableInstance()['_value'];
        this.emitRowsToRearrange();
    }

    getItemIds(isInvFormatFullDetail?) {
        this.isInvFormatFullDetail = isInvFormatFullDetail;
        this.setItems();
        return this.items.map(item => item.id);
    }


    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }


    /* Getting decimal point in Number datatype
    *  @param  {} source
    */
    getDecimalNum(target, decimal) {
        // return Number(target.toFixed(decimal));

        return Number(Math.fround(target).toFixed(decimal));
    }

    getRoundedOffValue(value) {
        return utils.getIntegerRoundedOffValue(value);
    }

    getPrintingItemMapByPrintRate(items) {
        // const printingItemMap = new Map<number, number>();
        // items.forEach((item) => {
        //     if (item.printingPrice.flNlPrice > 0 || item.printingPrice.blPrice > 0) {
        //         const key1 = item.printingPrice.flNlPrice;
        //         const key2 = item.printingPrice.blPrice;
        //         if (printingItemMap.has(key1 > 0 ? key1 : key2) || printingItemMap.has(key2 > 0 ? key2 : key1)) {
        //             printingItemMap.set(key1 > 0 ? key1 : key2, printingItemMap.get(key1 > 0 ? key1 : key2) + item.squareFeet);
        //         } else {
        //             printingItemMap.set(key1 > 0 ? key1 : key2, item.squareFeet);
        //         }
        //     }

        // });

        const printingItemMap = new Map<Number, any>();
        items.forEach((item) => {
            if (item.printingPrice.flNlPrice > 0 || item.printingPrice.blPrice > 0) {
                const key1 = item.printingPrice.flNlPrice;
                const key2 = item.printingPrice.blPrice;
                const obj: any = {};
                if (printingItemMap.has(key1 > 0 ? key1 : key2) || printingItemMap.has(key2 > 0 ? key2 : key1)) {
                    obj.squareFeet = Number(obj.squareFeet >= 0) ? Number(obj.squareFeet) + Number(item.squareFeet) : 0 + Number(item.squareFeet);
                    obj.amount = Number(obj.amount >= 0) ? Number(obj.amount) + Number(item.amount) : 0 + Number(item.amount);
                    const oldObj = printingItemMap.get(key1 > 0 ? key1 : key2);
                    obj.squareFeet = Number(obj.squareFeet) + Number(oldObj.squareFeet);
                    obj.amount = Number(obj.amount) + Number(oldObj.amount);
                    printingItemMap.set(key1 > 0 ? key1 : key2, obj);
                } else {
                    obj.squareFeet = Number(obj.squareFeet >= 0) ? Number(obj.squareFeet) + Number(item.squareFeet) : 0 + Number(item.squareFeet);
                    obj.amount = Number(obj.amount >= 0) ? Number(obj.amount) + Number(item.amount) : 0 + Number(item.amount);
                    printingItemMap.set(key1 > 0 ? key1 : key2, obj);
                }
            }
        });
        return printingItemMap;
    }

    getPrintingItemMapByAmount(items) {
        const printingItemMap = new Map<number, number>();
        items.forEach((item) => {
            if (item.amount > 0) {
                printingItemMap.set(item.amount, item.squareFeet);
            } else {
                // will not haapen
                // printingItemMap.set(0, item.squareFeet);
                // do nothing
            }

        });
        return printingItemMap;
    }

    getMountingItemMapByPricePerSqft(items) {
        // const mountingItemMap = new Map<number, number>();
        // items.forEach((item) => {
        //     if (item.mountingPrice.pricePerSqFt > 0) {
        //         const key = item.mountingPrice.pricePerSqFt;
        //         if (mountingItemMap.has(key)) {
        //             mountingItemMap.set(key, mountingItemMap.get(key) + item.squareFeet);
        //         } else {
        //             mountingItemMap.set(key, item.squareFeet);
        //         }
        //     }

        // });
        const mountingItemMap = new Map<Number, any>();
        items.forEach((item) => {
            if (item.mountingPrice.pricePerSqFt > 0) {
                const key = item.mountingPrice.pricePerSqFt;
                const obj: any = {};
                if (mountingItemMap.has(key)) {
                    obj.squareFeet = Number(obj.squareFeet >= 0) ? Number(obj.squareFeet) + Number(item.squareFeet) : 0 + Number(item.squareFeet);
                    obj.amount = Number(obj.amount >= 0) ? Number(obj.amount) + Number(item.amount) : 0 + Number(item.amount);
                    const oldObj = mountingItemMap.get(key);
                    obj.squareFeet = Number(obj.squareFeet) + Number(oldObj.squareFeet);
                    obj.amount = Number(obj.amount) + Number(oldObj.amount);
                    mountingItemMap.set(key, obj);
                } else {
                    obj.squareFeet = Number(obj.squareFeet >= 0) ? Number(obj.squareFeet) + Number(item.squareFeet) : 0 + Number(item.squareFeet);
                    obj.amount = Number(obj.amount >= 0) ? Number(obj.amount) + Number(item.amount) : 0 + Number(item.amount);
                    mountingItemMap.set(key, obj);
                }
            }
        });
        return mountingItemMap;
    }

    getMountingItemMapByAmount(items) {
        const mountingItemMap = new Map<number, number>();
        items.forEach((item) => {
            if (item.amount > 0) {
                mountingItemMap.set(item.amount, item.squareFeet);
            } else {
                // will not haapen
                // mountingItemMap.set(0, item.squareFeet);
                // do nothing
            }

        });
        return mountingItemMap;
    }

    getFormattedDate(date) {
        return DateUtil.dategridFormatter(date);
    }

    getParseValueToDecimalTwoPlaces(value) {
        return utils.parseValueToDecimalTwoPlaces(value);
    }

    getFormattedCurrency(value) {
        return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(value));
    }

    /**
     * @description emits the event for rows to rearrange
     * @author Pulkit Bansal
     * @date 2019-10-06
     * @memberof InvoiceSummaryGridComponent
     */
    emitRowsToRearrange() {
        this.eEmitRowsToRearrange.emit(this.items);
        this.defaultSortInProgress = false;
    }

    /**
     * @description to get the current row order of grid
     * @author Pulkit Bansal
     * @date 2019-10-09
     * @returns
     * @memberof InvoiceSummaryGridComponent
     */
    getCurrentInvoiceGridOrder() {
        return this.invoiceSummaryGridRef.getDataTableInstance()["_value"];
    }


    /**
     * @description to update the order of rows
     * also called from invoice preview component
     * @author Pulkit Bansal
     * @date 2019-10-09
     * @param {string[]} rowsOrder
     * @param {boolean} [calledFromThisComponent=false]
     * @memberof InvoiceSummaryGridComponent
     */
    updateItemsOrder(rowsOrder: string[], calledFromThisComponent = false) {
        const updatedItems: CombinedItem[] = [];
        let notFound = false;
        rowsOrder.forEach((id) => {
            const index = this.items.findIndex(item => item.id === id);
            if (index !== -1) {
                updatedItems.push(this.items[index]);
            } else {
                notFound = true;
            }
        });
        if (!notFound && rowsOrder.length === this.items.length) {
            this.items = _.cloneDeep(updatedItems);
        }
        if (!calledFromThisComponent) {
            if (this.gridConfig.dataLoadFunction !== undefined) {
                this.invoiceSummaryGridRef.refresh();
            }
            if (this.invoice.status) { // invoice is edited, not generated
                this.setPreviousInvoiceItems();
            }
        }
    }

    /**
     * @description to update the order of rows for previous items
     * @author Pulkit Bansal
     * @date 2019-10-31
     * @param {string[]} rowsOrder
     * @memberof InvoiceSummaryGridComponent
     */
    updatePreviousItemsOrder(rowsOrder: string[]) {
        const updatedItems: CombinedItem[] = [];
        let notFound = false;
        rowsOrder.forEach((id) => {
            const index = this.previousItems.findIndex(item => item.id === id);
            if (index !== -1) {
                updatedItems.push(this.previousItems[index]);
            } else {
                notFound = true;
            }
        });
        if (!notFound && rowsOrder.length === this.previousItems.length) {
            this.previousItems = _.cloneDeep(updatedItems);
        }
    }

    /**
     * @description to validate current rows order wrt grouped and ungrouped
     * @returns {boolean}
     * @memberof InvoiceSummaryGridComponent
     */
    validateInvioceRowsOrder(): boolean {
        if (InvoiceFormatEnum[this.invoiceFormat] === "As Per Ro inclusive of Printing/Mounting") {
            if (this.isInvoiceRowsOrderPresent()) {
                return !this.checkRowsOrderForNullUndefined() && this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro");
            } else {
                return false;
            }
        } else if (InvoiceFormatEnum[this.invoiceFormat] === "As Per Ro with separate Printing/Mounting") {
            if (this.isInvoiceRowsOrderPresent()) {
                return !this.checkRowsOrderForNullUndefined() && this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro - Rent") && this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro - Printing/Mounting");
            } else {
                return false;
            }
        } else {
            // if invoice contains rowOrder
            if (this.isInvoiceRowsOrderPresent()) {
                // if the invoice is grouped
                if (this.grouped) {
                    // if invoice contains print or mount items
                    if (this.containsPrintOrMountItems()) {
                        return !this.checkRowsOrderForNullUndefined() && this.rowsOrderContainsCustomIdForGroupedItems();
                    } else {
                        return !this.checkRowsOrderForNullUndefined() && !this.rowsOrderContainsCustomIdForGroupedItems();
                    }
                } else { // invoice is not grouped
                    return !this.checkRowsOrderForNullUndefined() && !this.rowsOrderContainsCustomIdForGroupedItems() && !this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro") && !this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro - Rent") && !this.rowsOrderContainsCustomIdForInvoiceFormats("As Per Ro - Printing/Mounting");
                }
            } else {
                return false;
            }
        }
    }

    /**
     * @description to check if rows order exists in invoice
     * @returns {boolean}
     * @memberof InvoiceSummaryGridComponent
     */
    isInvoiceRowsOrderPresent(): boolean {
        if (this._invoice.rowsOrder && this._invoice.rowsOrder.length) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @description to check if rows order contains null or undefined
     * @returns {boolean}
     * @memberof InvoiceSummaryGridComponent
     */
    checkRowsOrderForNullUndefined(): boolean {
        if (this._invoice.rowsOrder.findIndex(item => item !== undefined && item !== null) !== -1) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * @description to check if invioce contains print or mount items
     * @returns {boolean}
     * @memberof InvoiceSummaryGridComponent
     */
    containsPrintOrMountItems(): boolean {
        if ((this._invoice.printingItems && this._invoice.printingItems.length) || (this._invoice.mountingItems && this._invoice.mountingItems.length)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @description to check if the rows order in invoice contains custom ids -> for groupoed print and mount items
     * @returns {boolean}
     * @memberof InvoiceSummaryGridComponent
     */
    rowsOrderContainsCustomIdForGroupedItems(): boolean {
        if (this._invoice.rowsOrder.findIndex(item => item.includes("@")) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @description to check if rows order contains custom ids --> for ro types in invoice format enum
     * @param {string} checkValue
     * @returns {boolean}
     * @memberof InvoiceSummaryGridComponent
     */
    rowsOrderContainsCustomIdForInvoiceFormats(checkValue: string): boolean {
        if (this._invoice.rowsOrder.findIndex(item => item === checkValue) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @description sorts in default order --> by city
     * called from invoice preview component
     * @memberof InvoiceSummaryGridComponent
     */
    onDefaultSort() {
        this.defaultSortInProgress = true;
        this.setItems();
    }

    /**
     * @description to get the amount based on invoiceFormat
     * called from the html template
     * @param {CombinedItem} row
     * @returns {number}
     * @memberof InvoiceSummaryGridComponent
     */
    getAmountBasedOnInvoiceFormat(row: CombinedItem, index: number): number | string {
        switch (InvoiceFormatEnum[this._invoiceFormat]) {
            case "Package Rate inclusive of Printing/Mounting": {
                if (index === 0) {
                    return this.getRoundedOffValue(this.rentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0) + this.printingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.mountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0));
                } else {
                    return "-";
                }
                break;
            }
            case "Package Rate with separate Printing/Mounting": {
                // templating is starting from the beginning so reset these values
                if (index === 0) {
                    this.firstRentalItem = true;
                    this.firstPrintingMountingItem = true;
                } else {
                    // do nothing
                }
                switch (row.type) {
                    case "Rent": {
                        if (this.firstRentalItem) {
                            this.firstRentalItem = false;
                            return this.getRoundedOffValue(this.rentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0));
                        } else {
                            return "-";
                        }
                        break;
                    }
                    case "Printing":
                    case "Mounting": {
                        if (this.firstPrintingMountingItem) {
                            this.firstPrintingMountingItem = false;
                            return this.getRoundedOffValue(this.printingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.mountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0));
                        } else {
                            return "-";
                        }
                        break;
                    }
                }
            }
            case "Full Detail": {
                let rentItems = this.items.filter(item => item.id === row.id);
                if (rentItems && rentItems.length) {
                    return rentItems[0].amount;
                } else {
                    return "-";
                }
                break;
            }
            default: {
                let rentItems = this.items.filter(item => item.id === row.id);
                if (rentItems && rentItems.length) {
                    return rentItems[0].amount;
                } else {
                    return "-";
                }
                break;
            }
        }
    }

    /**
     * @description to get the previous amount based on invoice format
     * called from the html template
     * @param {CombinedItem} row
     * @param {number} index
     * @returns {(number | string)}
     * @memberof InvoiceSummaryGridComponent
     */
    getPreviousAmountBasedOnInvoiceFormat(row: CombinedItem, index: number): number | string {
        switch (InvoiceFormatEnum[this._invoiceFormat]) {
            case "Package Rate inclusive of Printing/Mounting": {
                if (index === 0) {
                    return this.getRoundedOffValue(this.previousInvoiceRentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0) + this.printingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.mountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0));
                } else {
                    return "-";
                }
                break;
            }
            case "Package Rate with separate Printing/Mounting": {
                // templating is starting from the beginning so reset these values
                if (index === 0) {
                    this.firstPreviusRentalItem = true;
                    this.firstPreviousPrintingMountingItem = true;
                } else {
                    // do nothing
                }
                switch (row.type) {
                    case "Rent": {
                        if (this.firstPreviusRentalItem) {
                            this.firstPreviusRentalItem = false;
                            return this.getRoundedOffValue(this.previousInvoiceRentItems.map(rentItem => rentItem.amount).reduce((sum, rentItem) => sum + rentItem, 0));
                        } else {
                            return "-";
                        }
                        break;
                    }
                    case "Printing":
                    case "Mounting": {
                        if (this.firstPreviousPrintingMountingItem) {
                            this.firstPreviousPrintingMountingItem = false;
                            return this.getRoundedOffValue(this.previousInvoicePrintingItems.map(printItem => printItem.amount).reduce((sum, printItem) => sum + printItem, 0) + this.previousInvoiceMountingItems.map(mountItem => mountItem.amount).reduce((sum, mountItem) => sum + mountItem, 0));
                        } else {
                            return "-";
                        }
                        break;
                    }
                }
            }
            case "Full Detail": {
                let previousRentItems = this.items.filter(item => item.id === row.id);
                if (previousRentItems && previousRentItems.length) {
                    return previousRentItems[0].amount;
                } else {
                    return "-";
                }
                break;
            }
            default: {
                let previousRentItems = this.items.filter(item => item.id === row.id);
                if (previousRentItems && previousRentItems.length) {
                    return previousRentItems[0].amount;
                } else {
                    return "-";
                }
                break;
            }
        }
    }

}
