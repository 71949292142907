import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LineChartConfig } from '../../modals/charts/lineChart.config';
import { InvoiceChartService } from '../../services/shared/invoice-chart.service';
import { HAxis } from '../../modals/charts/hAxis/hAxis';
import { VAxis } from '../../modals/charts/vAxis/vAxis';
import { ViewChild } from '@angular/core';
import { SibChartComponent } from '../../sib-chart/sib-chart.component';
import { Input } from '@angular/core';
import { ColumnChartConfig } from '../../modals/charts/columnChart.config';
import { GoogleColumnChartService } from '../../services/shared/column-chart.service';
import { EventEmitter } from '@angular/core';
import { Output, ChangeDetectorRef } from '@angular/core';
import { DateUtil } from '../../helpers/date.util';
import { SelectItem, OverlayPanel } from 'primeng/primeng';
import * as utils from '../../helpers/utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { SIBConstants } from '../../shared/constants/SIBConstant';
import { AppUrls } from '../../services/urls';

@Component({
  selector: 'sib-invoice-chart',
  templateUrl: './invoice-chart.component.html',
  styleUrls: ['./invoice-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [InvoiceChartService]
})
export class InvoiceChartComponent implements OnInit {

  @ViewChild(SibChartComponent)
  sibChartRef: SibChartComponent;
  @ViewChild('overlay') overlay: OverlayPanel;

  @Input() width: number;
  @Input() height: number;

  @Output() eEmitRefreshEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() eEmitChartClickedData: EventEmitter<any> = new EventEmitter<any>();
  @Output() eEmitFinancialYearChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eEmitSelectedFiscalYear: EventEmitter<any> = new EventEmitter<any>();

  lineChartConfig: LineChartConfig;
  invoiceChartData: any[] = [];
  lineElementId: string;
  lineChartData: any[] = [];
  hAxis: HAxis = new HAxis();
  vAxis: VAxis = new VAxis();
  CurrentFiscalYears: any[] = [];
  fiscalYears: SelectItem[] = [];
  selectedFiscalYear: any;
  columnChartConfig: ColumnChartConfig = new ColumnChartConfig();
  columnChartData: any[] = [];
  data: any;
  chartType: string; // specifying the type of chart
  totalInvoiceAmount: any; // store total invoice amount with tax
  totalInvoiceAmountWithoutTax: any;

  constructor(
    private service: InvoiceChartService,
    private columnChartService: GoogleColumnChartService,
    private cd: ChangeDetectorRef,
    private currencyPipeService: CurrencyHelperPipe
  ) {
    this.columnChartConfig.onSelect = this.onChartSelection.bind(this);
  }

  ngOnInit() {
    this.getCurrentFiscalYear();
    this.getInvoiceCardData();
    this.getFiscalYears();
  }

  /**
   * get invoice data for selected Financial Year
   *
   * @memberof InvoiceChartComponent
   */
  getInvoiceCardData() {
    this.service.get({ startYear: Number(this.CurrentFiscalYears[0]), endYear: Number(this.CurrentFiscalYears[1]) }, AppUrls.SUMMARY_FINANCIAL_YEAR)
      .subscribe((response) => {
        if (response && response['data']) {
          this.invoiceChartData = [];
          this.columnChartData = [];
          this.invoiceChartData = response['data'];
          this.setStackedColumnChart();
        }
        this.eEmitFinancialYearChanged.emit(false);
      })
  }

  /**
   * set the column chart data
   *
   * @memberof InvoiceChartComponent
   */
  setInvoiceChartData() {
    let totalInvoiceAmount = 0;
    this.columnChartData.push([SIBConstants.YEAR, SIBConstants.INVOICE, SIBConstants.TOOLTIP, SIBConstants.RESERVED_INVOICE, SIBConstants.TOOLTIP]);

    this.invoiceChartData.forEach(data => {
      let totalAmount = data.amount + data.reservedInvoiceAmount;
      let tooltip = this.createCustomTooltip(this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(totalAmount)), this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(data.amount)), this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(data.reservedInvoiceAmount)));
      this.columnChartData.push([data.month, data.amount, tooltip, data.reservedInvoiceAmount, tooltip]);
      totalInvoiceAmount = totalInvoiceAmount + totalAmount;
    })

    this.totalInvoiceAmount = this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(totalInvoiceAmount));
    this.totalInvoiceAmountWithoutTax = this.currencyPipeService.transform(utils.getIntegerRoundedOffValue(totalInvoiceAmount / 1.18));
  }

  /**
   * create the chart container.
   * sets the data and configuration  for the column chart.
   * @returns
   * @memberof InvoiceChartComponent
   */
  setStackedColumnChart() {
    if (this.invoiceChartData && this.invoiceChartData != null) {
      this.lineElementId = SIBConstants.INVOICE_CHART_ID; // sets the container id

      // this.lineChartData = [];
      if (this.invoiceChartData.length === 0) return;
      this.columnChartData = [];
      this.totalInvoiceAmount = 0;
      this.setInvoiceChartData();
      this.setColumnChartConfig();

      this.chartType = '';
      this.chartType = 'columnChart'; // specifying type of chart

    }
  }

  /**
   * set configuration for stacked column chart
   *
   * @memberof InvoiceChartComponent
   */
  setColumnChartConfig() {
    this.setHAxis();
    this.setVAxis();
    this.setWidth();
    this.columnChartConfig.hAxis.direction = 1;
    this.columnChartConfig.isStacked = true;
    this.columnChartConfig.tooltip = { isHtml: true };
    this.columnChartConfig.axisTitlesPosition = 'none';
    this.columnChartConfig.vAxis.gridlines.count = 0;
    this.columnChartConfig.legend.position = 'top';
  }

  /** 
   * set the width for the column chart
   *
   * @memberof InvoiceChartComponent
   */
  setWidth() {
    this.width = window.innerWidth - 160;
  }

  /**
   * set the height for the column chart
   *
   * @memberof InvoiceChartComponent
   */
  setHeight() {
    this.height = window.innerHeight - 300;
  }

  /**
   * set the horizontal axis
   *
   * @memberof InvoiceChartComponent
   */
  setHAxis() {
    this.hAxis.title = "Month";
  }

  /**
   * set the vertical axis
   *
   * @memberof InvoiceChartComponent
   */
  setVAxis() {
    this.vAxis.title = "Amount (in rupees)";
  }

  /**
   * set the current Fiscal Year as selected Fiscal Year
   *
   * @memberof InvoiceChartComponent
   */
  getCurrentFiscalYear() {
    this.selectedFiscalYear = String(DateUtil.getCurrentFiscalYear());
    this.splitYear();
  }

  /**
   * splits the selected fiscal year
   *
   * @memberof InvoiceChartComponent
   */
  splitYear() {
    this.CurrentFiscalYears = this.selectedFiscalYear.split("-");
    this.eEmitSelectedFiscalYear.emit(this.selectedFiscalYear);
  }

  /**
   * create Fiscal Year Dropdown
   *
   * @memberof InvoiceChartComponent
   */
  getFiscalYears() {
    var years = DateUtil.fiscalYears(new Date(2018, 1, 1), new Date());
    years.forEach((year) => {
      this.fiscalYears.push({ label: year, value: year })
    });
  }

  /**
   * action to be performed when fiscal year is selected
   *
   * @memberof InvoiceChartComponent
   */
  onFiscalYearSelection() {
    this.splitYear();
    this.getInvoiceCardData();
    this.eEmitSelectedFiscalYear.emit(this.selectedFiscalYear);
    this.eEmitFinancialYearChanged.emit(true);
  }

  /**
   * action to be performed when chart is clicked.
   *
   * @param {*} data
   * @memberof InvoiceChartComponent
   */
  onChartSelection(data) {
    this.eEmitSelectedFiscalYear.emit(this.selectedFiscalYear);
    this.eEmitChartClickedData.emit(data);
    this.eEmitFinancialYearChanged.emit(false);
  }

  /**
   * Refresh the chart
   *
   * @param {*} height
   * @param {*} width
   * @memberof InvoiceChartComponent
   */
  refreshChart(height, width) {
    this.columnChartConfig.height = height;
    this.columnChartConfig.width = width;
    this.sibChartRef.cshowColumnChart(this.columnChartConfig);
  }

  /**
   * creating custom tooltip for the column chart
   *
   * @param {*} month
   * @param {*} totalAmount
   * @param {*} invoiceAmount
   * @param {*} reservedInvoiceAmount
   * @returns
   * @memberof InvoiceChartComponent
   */
  createCustomTooltip(totalAmount, invoiceAmount, reservedInvoiceAmount) {
    return '<div style ="padding: 10px 10px 10px 10px; width: 220px;"><span  style="font-size: 14px;">' +
      'Invoice : <b>' + invoiceAmount + '</b></span><br><span  style="font-size: 14px;">' +
      'Reserved Invoice : <b>' + reservedInvoiceAmount + '</b></span><br><span  style="font-size: 14px;">' +
      '<hr>' + 'Total : <b>' + totalAmount + '</b></span><br></div>';
  }

  /**
   * @description show overlay on total invoice amount in top right corner
   * @author Divya Sachan
   * @date 2019-11-28
   * @param {*} event
   * @memberof InvoiceChartComponent
   */
  showOverlay(event) {
    this.overlay.show(event);
  }

}
