import { AfterViewInit, OnDestroy, Component, ViewEncapsulation, OnInit, Input } from "@angular/core";
import { StatusVisualizationNode } from "../../../modals/billings/status-visualization-node";

@Component({
    selector: 'sib-status-visualization',
    templateUrl: './status-visualization.component.html',
    styleUrls: ['./status-visualization.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StatusVisualizationComponent implements OnInit, AfterViewInit, OnDestroy {

    _nodes: StatusVisualizationNode[] = [];
    @Input() set nodes(nodes) {
        this._nodes = nodes;
    }

    get nodes() {
        return this._nodes;
    }

    _selected: string;
    @Input() set selected(selected) {
        this._selected = selected;
    }

    get selected() {
        return this._selected;
    }

    ngOnInit() { }

    ngAfterViewInit() { }

    ngOnDestroy() { }

}
