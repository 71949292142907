import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { EditableGridFieldTypes, GridColumn, GridConfig, SelectionMode, GridPaginationEvent } from '../../../components/sib-forms/grid/grid.config';
import { DateUtil } from '../../../helpers/date.util';
import { CurrencyHelperPipe } from '../../../shared/helpers/currency.pipe/currencyHelperPipe';
import { HoardingLightEnum } from '../../../shared/constants/hoarding-light-enum';
import { ContractHoardings } from '../../../modals/contracts/contract-hoarding';
import { SelectItem, MenuItem } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { HoardingMasterColService } from '../../../services/shared/hoarding-master-column.service';
import * as utils from '../../../helpers/utils';
import { UserGridConfigService } from '../../../services/shared/user-grid-config.service';
import { map } from 'rxjs/operators';
import { ChartDrilldownHoardingsComponent } from '../../../shared/components/chart-drilldown-hoardings/chart-drilldown-hoardings.component';
import { ChartDrilldownKiosksComponent } from '../../../shared/components/chart-drilldown-kiosks/chart-drilldown-kiosks.component';
import { Kiosk } from '../../../modals/hoardings/kiosk';
import { MenuConstants } from '../../../shared/constants/menu-constants';
import { Router } from '@angular/router';
import { DashboardService } from '../../../dashboard/service/dashboard.service';
import { PhotoDialogComponent } from '../../../shared/components/photo-dialog/photo-dialog.component';
import { ImageDataDisplay } from '../../../modals/image/image-data';
import { NotificatoinsService } from '../../../services/notifications/notifications.service';
import { HoardingFunctionalService } from '../../../hoardings/services/hoarding-functional.service';
import { PlanWrapper } from '../../../modals/plans/planWrapper';
import { Plan } from '../../../modals/plans/plan';
import { PlanService } from '../../../services/shared/plan.service';
import { of as observableOf } from 'rxjs';
import { ViewInventoryComponent } from '../../../shared/components/view-inventory/view-inventory.component';
import * as _ from "lodash";

@Component({
  selector: 'sib-capacity-chart-drilldown',
  templateUrl: './capacity-chart-drilldown.component.html',
  styleUrls: ['./capacity-chart-drilldown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CapacityChartDrilldownComponent implements OnInit {

  _viewCapacityChartDrilldown: boolean;
  @Input() set viewCapacityChartDrilldown(viewCapacityChartDrilldown) {
    this._viewCapacityChartDrilldown = viewCapacityChartDrilldown;

  }
  get viewCapacityChartDrilldown() {
    return this._viewCapacityChartDrilldown;
  }

  @ViewChild(ChartDrilldownHoardingsComponent) ChartDrilldownHoardingsComponent: ChartDrilldownHoardingsComponent;
  @ViewChild(ChartDrilldownKiosksComponent) ChartDrilldownKiosksComponent: ChartDrilldownKiosksComponent;
  @ViewChild(ViewInventoryComponent) inventoryViewChildRef: ViewInventoryComponent;
  @ViewChild(PhotoDialogComponent)
  photoDialogComponent: PhotoDialogComponent;

  _clickedData: any[];
  @Input() set clickedData(clickedData) {
    this._clickedData = clickedData;
    if (this.activeIndex === 0) {
      this.setHoardingGridConfigObject();
    } else if (this.activeIndex === 1) {
      this.setKioskGridConfigObject();
    }

  }
  get clickedData() {
    return this._clickedData;
  }

  @Input() isCapacityChartClicked: boolean;
  @Output() displayCapacityChartDrilldown: EventEmitter<boolean> = new EventEmitter();
  @Output() eEmitIsCapacityChartClicked: EventEmitter<boolean> = new EventEmitter();
  isloading: boolean = false;
  totalHoardingRecords: number;
  totalKioskRecords: number;
  rowData: any;
  subscription: Subscription[] = [];
  modifiedColumns: SelectItem[] = [];
  selectedColumns: any[] = [];
  activeIndex: number = 0;

  hoardingGridConfig: GridConfig<ContractHoardings> = new GridConfig<ContractHoardings>();
  kioskGridConfig: GridConfig<Kiosk> = new GridConfig<Kiosk>();
  dataTableGridConfig: GridConfig<any> = new GridConfig<any>();

  menu: MenuItem[] = [];
  stepperMenu: MenuItem[] = [];
  planStepItems: MenuItem[] = [];
  createPlan: boolean = false;
  stepIndex: number = 0;
  dataTableColumns: any[] = [];

  displayPlanDialog: boolean = false;
  displayStatusQueueDialog = false; // status queue dialog

  imageData: any[] = [];
  selectedHoardingRows: ContractHoardings[] = [];
  selectedKiosks: Kiosk[] = [];

  selectedInventoryValues: any[] = [];
  currentDate: Date = new Date();
  totalHoardings: ContractHoardings[] = [];
  totalKiosks: Kiosk[] = [];

  planWrapper: PlanWrapper = new PlanWrapper();
  displayPlan: boolean = false;
  planBo: any;

  planEdit: boolean = false;
  newPlan: Plan = new Plan();
  originalStartDateValue: Date;
  originalEndDateValue: Date;

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private hmColService: HoardingMasterColService,
    private userGridConfigService: UserGridConfigService,
    private dashboardService: DashboardService,
    public router: Router,
    private notificationServcie: NotificatoinsService,
    private hoardingFunctionalService: HoardingFunctionalService,
    private planService: PlanService
  ) { }

  ngOnInit() {
    this.createPlan = false;
    this.setHoardingGridConfigObject();
    this.setKioskGridConfigObject();
    this.dataTableColumns = this.setDataTableColumns();
    this.menu = [
      {
        label: MenuConstants.DISCARD_L,
        icon: MenuConstants.DISCARD_I,
        command: (event) => {
          this.discardDrilldown();
        }
      }
    ];

    this.planStepItems = [
      { label: "Customize" },
      { label: "Details" },
    ];

    this.stepperMenu = [
      {
        label: MenuConstants.DISCARD_L,
        icon: MenuConstants.DISCARD_I,
        command: (event) => {
          this.discardEdit();
        }
      }
    ];

  }

  refresh() {
    this.ngOnInit();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.modifiedColumns = this.ChartDrilldownHoardingsComponent.gridViewChildRef.getModifiedColumns();
    }, 1000)

    this.subscription.push(this.hmColService.defaultColumns.subscribe((defaultColumns) => {

      this.selectedColumns = [];
      defaultColumns.forEach((column) => {
        if (column['default'] === true && column['permanent'] === false) {
          this.selectedColumns.push(column['header']);
        }
      });

      if (this.selectedColumns.length > 0) {
        this.hmColService.selectedColumns.next(this.selectedColumns);
      }

    }));
  }

  onTabChange(event) {
    this.activeIndex = event.index;
  }

  setHoardingGridConfigObject() {
    this.hoardingGridConfig.getDefaultModel = () => new ContractHoardings();
    this.hoardingGridConfig.model = ContractHoardings;
    this.hoardingGridConfig.selectionMode = SelectionMode.Multi;
    this.hoardingGridConfig.editable = true;
    this.hoardingGridConfig.expandableRows = false;
    this.hoardingGridConfig.checkboxSelection = true;
    this.hoardingGridConfig.resizableColumns = false;
    this.hoardingGridConfig.rowExpandMode = 'single';
    this.hoardingGridConfig.shouldAddRowOnDelete = false;
    this.hoardingGridConfig.showColorCode = true;
    this.hoardingGridConfig.lazy = true;
    this.hoardingGridConfig.enablePagination = true;
    this.hoardingGridConfig.scrollable = true;
    this.hoardingGridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.hoardingGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      Object.assign(paginationEvent.filters, { date: { value: this.clickedData[0][1].date, matchMode: 'is' } });
      Object.assign(paginationEvent.filters, { 'tradedAsset': { value: false, matchMode: "binary" } });
      if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
        paginationEvent.sortField = 'minimumPrice'
      }
      return this.dashboardService.create(paginationEvent, { category: 'HOARDING', page: paginationEvent.page === 0 ? '0' : paginationEvent.page, size: paginationEvent.size, sortField: paginationEvent.sortField, sortOrder: paginationEvent.sortOrder, filters: paginationEvent.filters }, '/search/unsoldInventory').pipe(map((response) => {
        if (response) {
          this.totalHoardingRecords = response['data']['totalElements'];
          this.totalHoardings = response['data']['content'];
          this.isCapacityChartClicked = true;
          this.eEmitIsCapacityChartClicked.emit(true);
          return response['data']['content'];
        }
      },
        (error) => {
          this.notificationServcie.error(error, "Error");
        }));
    }
  }

  setKioskGridConfigObject() {
    this.kioskGridConfig.getDefaultModel = () => new Kiosk();
    this.kioskGridConfig.model = Kiosk;
    this.kioskGridConfig.selectionMode = SelectionMode.Multi;
    this.kioskGridConfig.editable = true;
    this.kioskGridConfig.expandableRows = false;
    this.kioskGridConfig.checkboxSelection = true;
    this.kioskGridConfig.resizableColumns = false;
    this.kioskGridConfig.rowExpandMode = 'single';
    this.kioskGridConfig.shouldAddRowOnDelete = false;
    this.kioskGridConfig.showColorCode = true;
    this.kioskGridConfig.lazy = true;
    this.kioskGridConfig.enablePagination = true;
    this.kioskGridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.kioskGridConfig.scrollable = true;
    this.kioskGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      Object.assign(paginationEvent.filters, { date: { value: this.clickedData[0][1].date, matchMode: 'is' } });
      Object.assign(paginationEvent.filters, { 'tradedAsset': { value: false, matchMode: "binary" } });
      if (!paginationEvent.sortField || paginationEvent.sortField === 'updatedDate') {
        paginationEvent.sortField = 'minimumPrice'
      }
      return this.dashboardService.create(paginationEvent, { category: 'KIOSK', page: paginationEvent.page === 0 ? '0' : paginationEvent.page, size: paginationEvent.size, sortField: paginationEvent.sortField, sortOrder: paginationEvent.sortOrder, filters: paginationEvent.filters }, '/search/unsoldInventory').pipe(map((response) => {
        if (response) {

          this.totalKioskRecords = response['data']['totalElements'];
          this.totalKiosks = response['data']['content'];
          return response['data']['content'];
        }
      },
        (error) => {
          this.notificationServcie.error(error, "Error");
        }));
    }
  }

  emitSetColumns() {
    this.hmColService.selectedColumns.next(this.selectedColumns);
  }

  updatingUserGridConfig() {
    this.ChartDrilldownHoardingsComponent.updatingUserGridConfig(this.selectedColumns);
    this.ChartDrilldownKiosksComponent.updatingUserGridConfig(this.selectedColumns);
  }

  discardEdit() {
    this.selectedInventoryValues = [];
    this.selectedInventoryValues = utils.setData(this.selectedInventoryValues);
    this.ChartDrilldownHoardingsComponent.gridViewChildRef.refresh();
    this.createPlan = false;
  }

  discardDrilldown() {
    this.viewCapacityChartDrilldown = false;
  }

  previousClick() {
    this.activeIndex = this.activeIndex - 1;
    setTimeout(() => {
      if (this.activeIndex === 0) {
        this.inventoryViewChildRef.gridViewChildRef.refresh();
      } else if (this.activeIndex === 1) {

      }

    }, 1000)
  }

  nextClick() {
    this.activeIndex = this.activeIndex + 1;
  }

  setDataTableConfigObject() {
    this.dataTableGridConfig.lazy = false;
    this.dataTableGridConfig.resizableColumns = false;
    this.dataTableGridConfig.enablePagination = true;
    this.dataTableGridConfig.paginatorConfig.alwaysShowPaginator = true;
    this.dataTableGridConfig.scrollable = true;
    this.dataTableGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
      this.selectedInventoryValues = utils.setData(this.selectedInventoryValues)
      return observableOf(this.selectedInventoryValues);
    }
  }
  setDataTableColumns() {
    const dataTableColumns: Array<GridColumn> = [
      {
        field: 'customId',
        name: 'customId',
        header: 'HID',
        required: true,
        editable: false,
        hidden: false,
        width: '3vw',
        permanent: true,
        default: true,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.customId) {
            return data.customId;
          } else {
            return '-';
          }
        }
      },

      {
        field: 'city',
        name: 'city',
        header: 'City',
        required: true,
        editable: false,
        hidden: false,
        width: '5vw',
        sortable: true,
        permanent: true,
        default: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'areaName',
        name: 'area',
        header: 'Area',
        required: true,
        width: '5vw',
        editable: false,
        hidden: false,
        sortable: true,
        permanent: true,
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'location',
        name: 'location',
        header: 'Location',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        width: '15vw',
        default: true,
        sortable: true,
        styleClass: 'text-right',
        type: EditableGridFieldTypes.TEXT,
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'size',
        name: 'size',
        header: 'Size',
        required: true,
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: true,
        width: '3vw',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.size) {
            return data.size;
          } else {
            return '-';
          }
        },
      },
      {
        field: 'lightType',
        name: 'lightType',
        header: 'Light',
        required: true,
        editable: false,
        width: '3vw',
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.lightType) {
            return HoardingLightEnum[data.lightType];
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: true
        },
      },
      {
        field: 'grossPrice',
        name: 'grossPrice',
        header: 'Gross Price',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: '4.8vw',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.grossPrice) {
            return this.currencyPipe.transform(data.grossPrice);
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'minimumPrice',
        name: 'minimumPrice',
        header: 'LSP',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: '4.5vw', //width:'7vw';
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.minimumPrice) {
            return this.currencyPipe.transform(data.minimumPrice);
          } else {
            return '-';
          }
        },
        filter: {
          matchMode: 'contains',
          placeHolder: 'Search',
          custom: false
        },
      },
      {
        field: 'campaignStartDate',
        name: 'campaignStartDate',
        header: 'Campaign Start',
        required: true,
        editable: false,
        default: true,
        hidden: false,
        permanent: true,
        sortable: true,
        width: '5.5vw',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.campaignStartDate) {
            return DateUtil.dategridFormatter(data.campaignStartDate)
          } else {
            return '-';
          }
        }
      },
      {
        field: 'campaignEndDate',
        name: 'campaignEndDate',
        header: 'Campaign End',
        required: true,
        editable: false,
        hidden: false,
        default: true,
        permanent: true,
        sortable: true,
        width: '5.5vw',
        type: EditableGridFieldTypes.TEXT,
        displayFn: (data) => {
          if (data.campaignEndDate) {
            return DateUtil.dategridFormatter(data.campaignEndDate)
          } else {
            return '-';
          }
        }
      },
      {
        field: 'remove',
        name: 'remove',
        header: '',
        required: true,
        editable: false,
        hidden: false,
        permanent: true,
        default: true,
        width: '2vw',
        type: EditableGridFieldTypes.CUSTOM,
      },
    ]
    return dataTableColumns;
  }

  viewPlanDialog() {
    this.displayPlanDialog = true;
  }

  hidePlanDialog() {
    this.displayPlanDialog = false;
    this.selectedInventoryValues = [];
    this.createPlan = false;
  }

  creatPlan(event: Plan) {

    var planItemBo = this.hoardingFunctionalService.getPlanItemBo(this.selectedInventoryValues);
    this.getPlanWrapper(event, planItemBo);
    if (event.id) {
      this.planService.assignPlan(this.planWrapper).subscribe((response) => {
        if (response) {
          this.planBo = response;
          this.router.navigate(['/plans/view/' + event.id]);
        }
      },
        (error) => {
          if (error.error.code === 412) {
            this.notificationServcie.error(error.error.message ? error.error.message : 'Items already Present', 'Plan Update');
            this.selectedInventoryValues = [];
            this.createPlan = false;
            this.displayPlanDialog = false;
          }
        });
    } else {
      this.getCreatedPlan();
    }
  }

  getCreatedPlan() {
    this.planService.createPlan(this.planWrapper).subscribe((response) => {
      if (response) {
        this.planBo = response;
        this.router.navigate(['/plans/view/' + this.planBo['plan'].id]);
      }
    });
  }

  getPlanWrapper(plan, planItemBo) {
    this.planWrapper = new PlanWrapper();
    this.planWrapper.plan = plan;
    this.planWrapper.items = planItemBo;
  }

  viewDetail(rowData) {
    this.rowData = rowData;
    this.displayStatusQueueDialog = true;
  }

  viewImages(imageData) {
    this.imageData = [];
    this.imageData = imageData;
    this.photoDialogComponent.imageData = this.getImageData(imageData);
    this.photoDialogComponent.showImages();
  }

  changePhotoDisplay(event) {
    this.photoDialogComponent.imageData = [];
  }

  getImageData(data) {
    const imageData = [];
    for (let i = 0; i < data.images.length; i++) {
      imageData.push(new ImageDataDisplay().getImageData(data, data.images[i]));
    }
    return imageData;
  }

  onHoardingRowSelection($event) {
    this.selectedHoardingRows = $event;
  }

  onKioskRowSelection(kiosks) {
    this.selectedKiosks = kiosks;
  }

  moveSelectedInventory() {
    this.isloading = true;
    this.setDataTableConfigObject();
    this.activeIndex = 0;
    this.selectedHoardingRows.forEach((row) => {
      this.selectedInventoryValues.push(row);
    });

    this.selectedKiosks.forEach((row) => {
      this.selectedInventoryValues.push(row);
    });
    setTimeout(() => {
      this.inventoryViewChildRef.gridViewChildRef.refresh();
    }, 500);
    this.createPlan = true;
    this.isloading = false;
  }

  resetRows() {
    this.ChartDrilldownHoardingsComponent.gridViewChildRef.selected = [];
    this.ChartDrilldownHoardingsComponent.gridViewChildRef.previousSeleceted = [];
    this.selectedHoardingRows = [];

    this.ChartDrilldownKiosksComponent.gridViewChildRef.selected = [];
    this.ChartDrilldownKiosksComponent.gridViewChildRef.previousSeleceted = [];
    this.selectedKiosks = [];

  }

  hideStatusQueueDialog() {
    this.displayStatusQueueDialog = false;
  }

  removeHoarding(rowIndex) {
    this.totalHoardings = utils.setData(this.totalHoardings);
    this.totalKiosks = utils.setData(this.totalKiosks);
    this.selectedInventoryValues.splice(rowIndex, 1);
    this.selectedInventoryValues = utils.setData(this.selectedInventoryValues);
    if (this.selectedInventoryValues.length === 0) {
      this.displayPlanDialog = false;
      this.selectedInventoryValues = [];
      this.createPlan = false;
    } else {
      this.inventoryViewChildRef.gridViewChildRef.refresh();
    }
  }

  onHideStepperDialog() {
    this.selectedInventoryValues = [];
    this.selectedInventoryValues = utils.setData(this.selectedInventoryValues);
    this.createPlan = false;
  }

  totalSelectedInventory() {
    return this.selectedHoardingRows.length + this.selectedKiosks.length;
  }

  getFormattedDate() {
    return DateUtil.formatDateDDMMMYY(this.clickedData[0][1].date);
  }
}
