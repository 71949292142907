<form [formGroup]="campaignMigratedInvoicesGroup">
  <sib-grid [showFilter]="true" name="campaignMigratedInvoices" #campaignMigratedInvoicesSibGrid
    [columns]="campaignMigratedInvoicesColumns" [buttons]="campaignMigratedInvoicesButtonConfig"
    [config]="campaignMigratedInvoicesGridConfig" [totalRecords]="campaignMigratedInvoicesTotalRecords"
    formControlName="campaignMigratedInvoicesGridControl" ngDefaultControl
    (eEmitColumnSelection)="updatingMigratedUserGridConfig($event)">



    <!-- 
    <sib-grid-column field="srNo">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <span>
          {{rowIndex+1}}
        </span>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="invoiceId">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
       
        <a class="link-1  after" [routerLink]="['/billings/bills/view/', getEncodedURI(rowData.id)]">
          <span>
            {{rowData.invoiceId}}
          </span>
        </a>
      </ng-template>
    </sib-grid-column>


    <sib-grid-column field="lspDiff">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div *ngIf="rowData.lspDiff < 0" class="negDiff">
          {{getAbsoluteValue(parseValueToDecimal(rowData.lspDiff)) | CurrencyConverter}}
        </div>
        <div *ngIf="rowData.lspDiff >= 0" class="posDiff">
          {{parseValueToDecimal(rowData.lspDiff) | CurrencyConverter}}
        </div>
      </ng-template>
    </sib-grid-column> -->


    <sib-grid-column field="srNo">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <span>
          {{rowIndex+1}}
        </span>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="invoiceId">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <!-- (click)="viewInvoiceDetails(rowData, $event)" -->
        <a class="link-1  after" [routerLink]="['/billings/bills/view/', getEncodedURI(rowData.id)]">
          <span
            *ngIf="rowData.status === 'INVOICE_CANCEL' || rowData.status === 'INVOICE_CANCEL_WITH_RETENTION'; else defaultTemplate">
            <!-- Sanchit Mirg 18-04-2019 Now showing invoiceId -->
            <!-- <del>{{rowData.id}}</del> -->
            <del>{{rowData.invoiceId}}</del>
          </span>
          <ng-template #defaultTemplate>
            <!-- {{rowData.id}} -->
            {{rowData.invoiceId}}
          </ng-template>
        </a>
      </ng-template>
    </sib-grid-column>

    <!-- <sib-grid-column field="campaign.displayName">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
                        <div class="link-wrapper" *ngIf="admin">
                            <a class="link-1  after" (click)="viewCampaign(rowData)">{{rowData?.campaign?.displayName}}</a>
                        </div>
                        <div class="link-wrapper" *ngIf="!admin">
                            {{rowData?.campaign?.displayName}}
                        </div>
                    </ng-template>
                </sib-grid-column> -->

    <sib-grid-column field="PO">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <img class="custom-grid-icon" src="assets/layout/images/details.svg" alt="" title=""
          (click)="showOverlay($event, rowdata, rowIndex)">
      </ng-template>
    </sib-grid-column>

    <!-- <sib-grid-column field="details">
                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
                                <img class="custom-grid-icon" src="assets/layout/images/next.svg" alt="" title="View Details" (click)="viewInvoiceDetails(rowData)">
                            </ng-template>
                        </sib-grid-column> -->

    <sib-grid-column field="status">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div class="col-xs-0 padding-0 c-inline">
          <span class="padding-0 float-left">
            <div class="square-text-small background-{{getClassForCharacter(rowIndex % rowsPerPage)}}">
              <span title="{{charTitleAt[rowIndex % rowsPerPage]}}"
                class="text-style-small pointer {{getClassForCharacter(rowIndex % rowsPerPage)}}">
                {{charAt[rowIndex % rowsPerPage]}}
              </span>
            </div>
          </span>
          <span class="padding-0">
            <sib-status-visualization [nodes]="statusVisualizationNodes" [selected]="pendingAt[rowIndex % rowsPerPage]">
            </sib-status-visualization>
          </span>
        </div>
      </ng-template>
      <!-- campaignInvoicesSibGrid.filter($event.value,col.field,col.filterMatchMode)" -->
      <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
        <p-dropdown [options]="invoiceStatusDropdown" [style]="{'width':'90%','margin-top':'8px'}" appendTo="body"
          placeholder="Status"
          (onChange)="billingUIService.filterInvoiceStatus(campaignInvoicesSibGrid, $event.value,col.field,col.filterMatchMode)">
        </p-dropdown>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="campaignDiff">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div *ngIf="rowData.campaignDiff < 0" class="negDiff">
          {{getAbsoluteValue(parseValueToDecimal(rowData.campaignDiff)) | CurrencyConverter}}
        </div>
        <div *ngIf="rowData.campaignDiff >= 0" class="posDiff">
          {{parseValueToDecimal(rowData.campaignDiff) | CurrencyConverter}}
        </div>
      </ng-template>
    </sib-grid-column>

    <sib-grid-column field="lspDiff">
      <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
        <div *ngIf="rowData.lspDiff < 0" class="negDiff">
          {{getAbsoluteValue(parseValueToDecimal(rowData.lspDiff)) | CurrencyConverter}}
        </div>
        <div *ngIf="rowData.lspDiff >= 0" class="posDiff">
          {{parseValueToDecimal(rowData.lspDiff) | CurrencyConverter}}
        </div>
      </ng-template>
    </sib-grid-column>
  </sib-grid>
</form>