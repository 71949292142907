<div class="superbillHoarding">
    <!-- <div *ngIf="isLoading">
        <div class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div> -->

    <div *ngIf="showBack" class="ui-g padding-bottom-15">
        <sib-back [showBack]="showBack"></sib-back>
    </div>

    <div class="ui-g-12 m-bottom">
        <p-panel [toggleable]="true" header="RENTAL ({{!showSelected ? rentItems.length : _selectedRentRows.length}})"
            expandIcon="fa fa-chevron-circle-down" collapseIcon="fa fa-chevron-circle-up">
            <div class="ui-g padding-0">
                <ul class="mount-list-inline " *ngIf="activeIndex===0">
                    <li class="global-total-text">
                        Mount Count : <span
                            [ngClass]="(campaign.defaultMountedItems<campaign.totalItems )?'class-red':''">{{campaign.defaultMountedItems}}</span>
                        / {{ campaign.totalItems }}
                    </li>
                </ul>
                <ul *ngIf="_editable && !_checkbox && _selectedRentRows.length > 0"
                    class="date-list-inline ctext-right">
                    <li class="global-total-text">
                        <div>Total Rate</div>
                    </li>
                    <li class="global-total-input">
                        <input id="gblTotal" #gblTotal="ngModel" name="globalTotal" [(ngModel)]="globalTotal"
                            style="position: relative" type="text" autocomplete="off" pKeyFilter="pnum"
                            [pattern]="decimalNumbers" pInputText (blur)="validateGlobalTotal($event, decimalNumbers)"
                            changes [changedValue]="globalTotal" [changedType]="getChangedType()"
                            [changedField]="'rentAmount'" [changedFieldDisplayName]="'Invoice Total Rate'"
                            [formatterType]="'currency'">
                    </li>
                    <!-- <li class="global-rate-text">
                        <div>Rate</div>
                    </li>
                    <li class="global-rate-input">
                        <input id="gblRate" #gblRate="ngModel" name="globalRate" [(ngModel)]="globalRate" type="text"
                            autocomplete="off" pKeyFilter="pnum" [pattern]="decimalNumbers" pInputText style="position: relative"
                            (input)="validateGlobalRate($event, decimalNumbers)">
                    </li> -->
                    <li class="global-calendar-text margin-left--15">
                        <div>Start Date</div>
                    </li>
                    <li>
                        <!-- onGlobalStartDateChange() -->
                        <p-calendar [styleClass]="'global-calendar-button'" [inputStyle]="{'width':'90%'}"
                            #stDate="ngModel" id="stDate" name="stDate" [utc]="true" [(ngModel)]="globalStartDate"
                            showIcon="true" dateFormat="ddMy" monthNavigator="true"
                            [minDate]="campaign?.campaignStartDate" [maxDate]="campaign?.campaignEndDate"
                            yearNavigator="true" [yearRange]="'2017:2050'" [utc]="true"
                            (onSelect)="validateGlobalDates()" (onBlur)="validateGlobalDates()" appendTo="body"
                            trim="blur" changes [changedValue]="globalStartDate" [changedType]="getChangedType()"
                            [changedField]="'billStartDate'" [changedFieldDisplayName]="'Invoice Start Date'"
                            [formatterType]="'date'"></p-calendar>
                    </li>
                    <li class="global-calendar-text">
                        <div>End Date</div>
                    </li>
                    <li>
                        <!-- onGlobalEndDateChange() -->
                        <p-calendar [styleClass]="'global-calendar-button'" [inputStyle]="{'width':'90%'}"
                            #edDate="ngModel" id="edDate" name="edDate"
                            [minDate]="stDate.value ? stDate.value : campaign?.campaignStartDate"
                            [maxDate]="campaign?.campaignEndDate" showIcon="true" [utc]="true"
                            [(ngModel)]="globalEndDate" dateFormat="ddMy" trim="blur" monthNavigator="true"
                            (onSelect)="validateGlobalDates()" (onBlur)="validateGlobalDates()" yearNavigator="true"
                            [yearRange]="'2017:2050'" [utc]="true" appendTo="body" changes
                            [changedValue]="globalEndDate" [changedType]="getChangedType()"
                            [changedField]="'billEndDate'" [changedFieldDisplayName]="'Invoice End Date'"
                            [formatterType]="'date'"></p-calendar>
                    </li>
                    <li class="input-text margin-left-20">
                        No of Days
                    </li>
                    <li class="input-field margin-right--14">
                        <input pInputText type="number" #noOfDays id="noOfDays" name="noOfDays" [min]="1"  pKeyFilter="pint" [(ngModel)]="days"
                        (keyup)="((noOfDays.value && noOfDays.value !== '0') ? noOfDays.value : noOfDays.value ='');extendDays(noOfDays.value)">
                        <!-- (keyup)="(noOfDays.value && noOfDays.value !== 0) ? noOfDays.value : noOfDays.value ='' "> -->
                    </li>
                    <!-- <li class="global-rate-text">
                        <div>Rate</div>
                    </li>
                    <li class="global-rate-input">
                        <input id="gblRate" #gblRate="ngModel" name="globalRate" [(ngModel)]="globalRate" type="text"
                            autocomplete="off" pKeyFilter="pnum" [pattern]="decimalNumbers" pInputText (input)="validateGlobalRate($event, decimalNumbers)">
                    </li> -->
                </ul>
                <!-- onRentCalenderChange($event) validateRentDates($event) -->
                <form [formGroup]="rentGroup" class="verticalDivisionMiddleScroller  padding-0">
                    <div *ngIf="isLoading" class="loading">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                    <sib-grid [showFilter]="false" name="rentGrid" #rentGrid [columns]="rentColumns"
                        [buttons]="rentButtonConfig" [config]="rentGridConfig" [totalRecords]="totalRentRecords"
                        formControlName="rentGridControl" (eSelectionChange)="onRentGridRowSelect($event)"
                        (eCalenderChange)="onRentCalenderChange($event)" [minDate]="rentMinDate" [maxDate]="rentMaxDate"
                        (eRowEditNumberWithIndexAndCol)="onRentRowEdit($event)"
                        (eRowEditWithCol)="onRentRowNoteChange($event)" [datesDisabled]="true" [setBothDates]="true"
                        [currentDateCheck]="false" ngDefaultControl (eRowEditGridChange)="onRentGridRowEdit($event)"
                        (eRowEditNumberGridChange)="onRentGridNumberRowEdit($event)"
                        (eCalenderGridChange)="onRentGridCalendarChange($event)">

                        <sib-grid-column field="srNo">
                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                                let-column="column">
                                <span>
                                    {{rowIndex+1}}
                                </span>
                            </ng-template>
                        </sib-grid-column>

                        <sib-grid-column field="customId">
                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                                let-column="column">
                                <a class="link-1  after" (click)="viewHoarding(rowData)">{{rowData.customId}}</a>
                            </ng-template>
                        </sib-grid-column>

                    </sib-grid>
                </form>
            </div>
        </p-panel>
    </div>

    <div class="ui-g-12 m-bottom">
        <p-panel [toggleable]="true"
            header="PRINTING ({{!showSelected ? printingItemsWrapper.length : _selectedPrintingRows.length}})"
            expandIcon="fa fa-chevron-circle-down" collapseIcon="fa fa-chevron-circle-up">
            <div class="ui-g padding-0">
                <ul *ngIf="_editable && !_checkbox && _selectedPrintingRows.length > 0"
                    class="printing-list-inline ctext-right">
                    <li class="global-printing-text">
                        <a (click)="printingOverlay.show($event)">Set Printing Rates</a>
                    </li>
                </ul>
                <form [formGroup]="printingGroup" class="verticalDivisionMiddleScroller  padding-0">
                    <div *ngIf="isLoading" class="loading">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                    <sib-grid [showFilter]="false" name="printingGrid" #printingGrid [columns]="printingColumns"
                        [buttons]="printingButtonConfig" [config]="printingGridConfig"
                        [totalRecords]="totalPrintingRecords" formControlName="printingGridControl"
                        (eSelectionChange)="onPrintingGridRowSelect($event)"
                        (eRowEditWithCol)="onPrintRowNoteChange($event)"
                        (eRowEditNumberWithIndexAndCol)="onPrintingRowEdit($event)" ngDefaultControl
                        (eRowEditGridChange)="onPrintGridRowEdit($event)"
                        (eRowEditNumberGridChange)="onPrintGridNumberRowEdit($event)">

                        <sib-grid-column field="srNo">
                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                                let-column="column">
                                <span>
                                    {{rowIndex+1}}
                                </span>
                            </ng-template>
                        </sib-grid-column>

                        <sib-grid-column field="customId">
                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                                let-column="column">
                                <a class="link-1  after" (click)="viewHoarding(rowData)">{{rowData.customId}}</a>
                            </ng-template>
                        </sib-grid-column>

                        <sib-grid-column field="lightType">
                            <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                                <p-dropdown [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                                    appendTo="body" placeholder="Light"
                                    (onChange)="printingGrid.filter($event.value,col.field,col.filterMatchMode)">
                                </p-dropdown>
                            </ng-template>
                        </sib-grid-column>

                    </sib-grid>
                </form>
            </div>
        </p-panel>
    </div>

    <div class="ui-g-12 m-bottom">
        <p-panel [toggleable]="true"
            header="MOUNTING ({{!showSelected ? mountingItemsWrapper.length : _selectedMountingRows.length}})"
            expandIcon="fa fa-chevron-circle-down" collapseIcon="fa fa-chevron-circle-up">
            <div class="ui-g padding-0">
                <ul *ngIf="_editable && !_checkbox && _selectedMountingRows.length > 0"
                    class="printing-list-inline ctext-right">
                    <li class="global-printing-text">
                        <a (click)="mountingOverlay.show($event)">Set Mounting Rates</a>
                    </li>
                </ul>
                <form [formGroup]="mountingGroup" class="verticalDivisionMiddleScroller  padding-0">
                    <div *ngIf="isLoading" class="loading">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                    <sib-grid [showFilter]="false" name="mountingGrid" #mountingGrid [columns]="mountingColumns"
                        [buttons]="mountingButtonConfig" (eSelectionChange)="onMountingGridRowSelect($event)"
                        [config]="mountingGridConfig" [totalRecords]="totalMountingRecords"
                        (eRowEditNumberWithIndexAndCol)="onMountingRowEdit($event)"
                        (eRowEditWithCol)="onMountRowNoteChange($event)" formControlName="mountingGridControl"
                        ngDefaultControl (eRowEditGridChange)="onMountGridRowEdit($event)"
                        (eRowEditNumberGridChange)="onMountGridNumberRowEdit($event)">

                        <sib-grid-column field="srNo">
                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                                let-column="column">
                                <span>
                                    {{rowIndex+1}}
                                </span>
                            </ng-template>
                        </sib-grid-column>

                        <sib-grid-column field="customId">
                            <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                                let-column="column">
                                <a class="link-1  after" (click)="viewHoarding(rowData)">{{rowData.customId}}</a>
                            </ng-template>
                        </sib-grid-column>

                        <sib-grid-column field="lightType">
                            <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                                <p-dropdown [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}"
                                    appendTo="body" placeholder="Light"
                                    (onChange)="mountingGrid.filter($event.value,col.field,col.filterMatchMode)">
                                </p-dropdown>
                            </ng-template>
                        </sib-grid-column>

                    </sib-grid>
                </form>
            </div>
        </p-panel>
    </div>

    <!-- [campaignId]="campaignId" -->
    <sib-upload-PO-dialog [displayUploadPODialog]="displayUploadPODialog" [campaign]="campaign"
        (displayReset)="hideUploadPODialog()"></sib-upload-PO-dialog>

    <p-overlayPanel #printingOverlay>
        <div class="printingOverlay">
            <div class="ui-g font-weight-500" *ngIf="printingHoardingPresent">
                Hoarding Price
            </div>
            <div class="ui-g" *ngIf="printingHoardingPresent">
                <table>
                    <tr>
                        <th>
                            FL/NL Price:
                        </th>
                        <td>
                            <!-- [pattern]="decimalNumbers"  -->
                            <input #hoardingFlnlPrice="ngModel" id="hoardingFlnlPrice" trim="blur" type="text"
                                pInputText (input)="validateGlobalPrintingMounting($event, decimalNumbers)"
                                name="hoardingFlnlPrice" autocomplete="off" pKeyFilter="pnum" title=" "
                                [(ngModel)]="globalHoardingFlNlPrice" changes [changedValue]="globalHoardingFlNlPrice"
                                [changedType]="getChangedType()" [changedField]="'globalHoardingFlNlPrice'"
                                [changedFieldDisplayName]="'Hoarding/Group Global FL/NL Print Price'"
                                [formatterType]="'currency'" />
                        </td>
                        <th>
                            BL Price:
                        </th>
                        <td>
                            <input #hoardingBlPrice="ngModel" id="hoardingBlPrice" trim="blur" type="text" pInputText
                                name="hoardingBlPrice" (input)="validateGlobalPrintingMounting($event, decimalNumbers)"
                                title=" " autocomplete="off" pKeyFilter="pnum" [(ngModel)]="globalHoardingBlPrice"
                                changes [changedValue]="globalHoardingBlPrice" [changedType]="getChangedType()"
                                [changedField]="'globalHoardingBlPrice'"
                                [changedFieldDisplayName]="'Hoarding/Group Global BL Print Price'"
                                [formatterType]="'currency'" />
                        </td>
                    </tr>
                </table>
            </div>
            <div class="ui-g font-weight-500" *ngIf="printingKioskPresent">
                Kiosk Price
            </div>
            <div class="ui-g" *ngIf="printingKioskPresent">
                <table>
                    <tr>
                        <th>
                            FL/NL Price:
                        </th>
                        <td>
                            <input #kioskFlnlPrice="ngModel" id="kioskFlnlPrice" trim="blur" type="text" pInputText
                                name="kioskFlnlPrice" (input)="validateGlobalPrintingMounting($event, decimalNumbers)"
                                autocomplete="off" pKeyFilter="pnum" title=" " [(ngModel)]="globalKioskFlNlPrice"
                                changes [changedValue]="globalKioskFlNlPrice" [changedType]="getChangedType()"
                                [changedField]="'globalKioskFlNlPrice'"
                                [changedFieldDisplayName]="'Kiosk Global FL/NL Print Price'"
                                [formatterType]="'currency'" />
                        </td>
                        <th>
                            BL Price:
                        </th>
                        <td>
                            <input #kioskBlPrice="ngModel" id="kioskBlPrice" trim="blur" type="text" pInputText
                                name="kioskBlPrice" (input)="validateGlobalPrintingMounting($event, decimalNumbers)"
                                title=" " autocomplete="off" pKeyFilter="pnum" [(ngModel)]="globalKioskBlPrice" changes
                                [changedValue]="globalKioskBlPrice" [changedType]="getChangedType()"
                                [changedField]="'globalKioskBlPrice'"
                                [changedFieldDisplayName]="'Kiosk Global BL Print Price'"
                                [formatterType]="'currency'" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </p-overlayPanel>

    <p-overlayPanel #mountingOverlay>
        <div class="mountingOverlay">
            <div class="ui-g font-weight-500" *ngIf="mountingHoardingPresent">
                Hoarding Price
            </div>
            <div class="ui-g" *ngIf="mountingHoardingPresent">
                <table>
                    <tr>
                        <th>
                            Mounting:
                        </th>
                        <td>
                            <!-- [pattern]="decimalNumbers"  -->
                            <input #hoardingMountingPrice="ngModel" id="hoardingMountingPrice" trim="blur" type="text"
                                pInputText (input)="validateGlobalPrintingMounting($event, decimalNumbers)"
                                name="hoardingMountingPrice" autocomplete="off" pKeyFilter="pnum" title=" "
                                [(ngModel)]="globalHoardingMountingPrice" changes
                                [changedValue]="globalHoardingMountingPrice" [changedType]="getChangedType()"
                                [changedField]="'globalHoardingMountingPrice'"
                                [changedFieldDisplayName]="'Hoarding/Group Global Mount Price'"
                                [formatterType]="'currency'" />
                        </td>
                    </tr>
                </table>
            </div>
            <div class="ui-g font-weight-500" *ngIf="mountingKioskPresent">
                Kiosk Price
            </div>
            <div class="ui-g" *ngIf="mountingKioskPresent">
                <table>
                    <tr>
                        <th>
                            Mounting:
                        </th>
                        <td>
                            <input #kioskMountingPrice="ngModel" id="kioskMountingPrice" trim="blur" type="text"
                                pInputText name="kioskMountingPrice"
                                (input)="validateGlobalPrintingMounting($event, decimalNumbers)" autocomplete="off"
                                pKeyFilter="pnum" title=" " [(ngModel)]="globalKioskMountingPrice" changes
                                [changedValue]="globalKioskMountingPrice" [changedType]="getChangedType()"
                                [changedField]="'globalKioskMountingPrice'"
                                [changedFieldDisplayName]="'Kiosk Global Mount Price'" [formatterType]="'currency'" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </p-overlayPanel>
</div>