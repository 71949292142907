import { PaginationData } from "../../../modals/paginationdata";
import { Column, SortMeta } from "primeng/primeng";
import { Observable } from "rxjs";
import { EventData } from "../../../modals/eventdata";
import { RequestData } from '../../../modals/user/request-data';

export interface HeaderColumn {
    header: string;
    rowspan?: number;
    colspan?: number;
    hidden?: (item?) => boolean;
    width?: any;
    frozen?: boolean;
}
export type HeaderColumnRow = HeaderColumn[];

export enum SelectionMode {
    None = 0,
    Single,
    Multi
}

export enum GridActionButtonTypes {
    ADD = 1,
    DELETE,
    SELECT_ALL,
    DESELECT_ALL,
    SAVE,
    OTHER
}

/**
 * Field Types that are supported in editable grid columns
 * 
 * @export
 * @enum {number}
 */
export enum EditableGridFieldTypes {
    TEXT = 1,
    DROPDOWN,
    CHECKBOX,
    DATE = 4,
    CUSTOM = 5,
    AUTOCOMPLETE,
    MULTISELECT,
    NUMBER,
}

export type GridPaginationEvent = PaginationData;

export type GridRequestData = RequestData;

export type GridEvent = EventData;

export enum Alignment {
    LEFT = 1,
    CENTER,
    RIGHT
}

/**
* Grid Column Definition
* 
* @export
* @interface GridColumn
*/
export interface GridColumn {

    /**
     * Field name
     * It should be unique for a grid.
     * It uniquely identifies the given column.
     * Also, data value from the object is bound by this key in the grid data.
     * 
     * @type {string}
     * @memberof GridColumn
     */
    field: string;


    /**
     * Name of the column
     * 
     * @type {string}
     * @memberof GridColumn
     */
    name?: string;


    /**
     * String to be shown in the header of the column
     * 
     * @type {string}
     * @memberof GridColumn
     */
    header: any;


    /**
     * Set this to true if the value in editable mode is required.
     * 
     * @type {boolean}
     * @memberof GridColumn
     */
    required: boolean;


    /**
     * Set this to true if the grid values are editable
     * 
     * @type {boolean}
     * @memberof GridColumn
     */
    editable: boolean;

    /**
     * Grid Column field type
     * 
     * @type {EditableGridFieldTypes}
     * @memberof GridColumn
     */
    type?: EditableGridFieldTypes;


    /**
     * configuration to be passed to the form control if the grid column is editable
     * Eg: this will be an instance of TextControlConfig if using text control
     * 
     * @type {*}
     * @memberof GridColumn
     */
    controlConfig?: any;


    /**
     * set this to true if the column is to be hidden
     * 
     * @type {boolean}
     * @memberof GridColumn
     */
    hidden?: boolean;


    /**
     * Alignment of display and control in the column
     * 
     * @type {Alignment}
     * @memberof GridColumn
     */
    align?: Alignment;


    /**
     * If this function is set, then result of this function is displayed in nonEditable mode
     * 
     * @memberof GridColumn
     */
    displayFn?: (rowData: any, column: GridColumn) => any;


    /**
     * Return value of this function is used as title for the column.
     * 
     * @memberof GridColumn
     */
    displayTitleFn?: (rowData: any, column: GridColumn) => string;


    /**
     * Whether the column is fixed in horizontal scrolling or not.
     * 
     * @type {boolean}
     * @memberof GridColumn
     */
    frozen?: boolean;

    /**
     * Function takes rowData as arument and returns true if the given cell should be editable else returns false
     * @memberof GridColumn
     */
    isCellEditable?: (rowData: any, column: Column) => boolean;

    filter?: GridColumnFilter;


    width?: any;

    sortable?: boolean;

    permanent?: boolean;

    default?: boolean;


    styleClass?: any;



}

/**
 * Inteface for Filter settins for grid colums
 * 
 * @export
 * @interface GridColumnFilter
 */
export interface GridColumnFilter {


    /**
     * Match mode of filter
     * 
     * @type {('equals' | 'contains' | 'in' | 'startsWith' | 'endsWith' | 'gte' | 'lte' | 'after' | 'before' | 'binary')}
     * @memberof GridColumnFilter
     */
    matchMode: 'equals' | 'contains' | 'in' | 'startsWith' | 'endsWith' | 'gte' | 'lte' | 'after' | 'before' | 'binary' | 'relational' | 'is';


    /**
     * Place holder to show for search component
     * 
     * @type {string}
     * @memberof GridColumnFilter
     */
    placeHolder: string;

    custom?: boolean;
}

export class GridConfig<T> {

    /**
   * Function that return a new instance of datamodel when a new value needs to be added to the grid
   * 
   * 
   * @memberof EditableGridConfig
   */
    getDefaultModel: () => T;

    model: any;

    /**
   * Number of rows to be show in grid when there are no records
   * 
   * @type {number}
   * @memberof EditableGridConfig
   */
    emptyRowCount?: number = 1;

    /**
   * 
   * 
   * @type {boolean}
   * @memberof EditableGridConfig
   */
    shouldAddRowOnTab: boolean = true;

    /**
	 * Whether to add a new row when last row is deleted
	 * @default {true}
	 */
    shouldAddRowOnDelete: boolean = false;

    /**
	 * If true, multiple rows can be selected.
	 */
    selectionMode: SelectionMode = SelectionMode.None;

    /**
	 * If true, only add a new row on tab only if the current row is valid | depends on isAddOnTab to be true
	 * 
	 * @type {boolean}
	 * @memberOf EditableGridConfig
	 */
    shouldAddRowOnlyIfValid: boolean = false;

    /**
    * Aruguments to pass to toRemove function call, which is exected on call of save method.
    */
    toRemoteArgs: any[] = [];

    /**
    * Key for identifying the rows uniqueyly
    * 
    * @type {string}
    * @memberof EditableGridConfig
    */
    trackByKey: string = '_$$uid';

    /**
    * Flag controls if the grid is editable or not 
    * 
    * @type {boolean}
    * @memberof GridConfig
    */
    editable: boolean = false;

    /**
    * Show checkbox in first column or not.
    * 
    * @type {boolean}
    * @memberof GridConfig
    */
    checkboxSelection: boolean = true;

    /**
	 * Flag To restrict select all functionalitiy in multiselect. So if checkBoxSelection is true and this flag is true, 
	 * then checkbox in header will not come.
	 * And if checkBoxSelection is false and this flag is true, then selectAll/deSelectAll buttons will not come.
     * 
     * @type {boolean}
     * @memberof GridConfig
     */
    restrictSelectAll: boolean = false;

    /**
   * Loading Icon to be shown in the grid when data is loading
   * 
   * @type {string}
   * @memberof GridConfig
   */
    loadingIcon: string = 'fa-circle-o-notch';

    /**
   * Empty message to be shown when no records are being displayed 
   * 
   * @type {string}
   * @memberof GridConfig
   */
    emptyMessage: string = 'No record found';

    /**
   * Number of rows to display per page
   * 
   * @type {number}
   * @memberof GridConfig
   */
    rowsPerPage: number = 50;

    /**
   * When specified as true, enables the pagination.
   * 
   * @type {boolean}
   * @memberof GridConfig
   */
    enablePagination: boolean = true;

    /**
   * Configuratoin for paginator if paginator is added
   * 
   * @type {PaginatorConfig}
   * @memberof GridConfig
   */
    paginatorConfig: PaginatorConfig = new PaginatorConfig();

    /**
   * Defines if the columns should be stacked in smaller screens.
   * 
   * @type {boolean}
   * @memberof GridConfig
   */
    responsive: boolean = false;

    /**
   * Activates expandable rows feature when true.
   * 
   * @type {boolean}
   * @memberof GridConfig
   */
    expandableRows: boolean = false;

    /**
   * Whether multiple rows can be expanded at any time. Valid values are "multiple" and "single".
   * 
   * @type {RowExpandMode}
   * @memberof GridConfig
   */
    rowExpandMode: RowExpandMode = 'multiple';

    /**
   * When enabled, columns can be resized using drag and drop.
   * 
   * @type {boolean}
   * @memberof GridConfig
   */
    resizableColumns: boolean = true;

    /**
   * Defines whether the overall table width should change on column resize, valid values are "fit" and "expand".
   * 
   * @type {ColumnResizeMode}
   * @memberof GridConfig
   */
    columnResizeMode: ColumnResizeMode = 'fit';

    /**
     * Determines if the data will be loaded lazily. When set, the grid will load data using dataLoadFunction provided in the configuration.
     * 
     * @type {boolean}
     * @memberof GridConfig
     */
    lazy: boolean = true;

    /**
   * If set to true, grid rows can be toggled into edit mode and saved individually.
   * 
   * 
   * @type {boolean}
   * @memberof GridConfig
   */
    editableRow: boolean = false;

    /**
   * When set this flag, makes the whole row editable whenever a cell in the particular row switchces to editable mode. 
   * Applicatble only when editableRow is true
   * 
   * @type {boolean}
   * @memberof GridConfig
   */
    makeRowEditableOnCellEdit: boolean = true;

    scrollable: boolean = false;

    scrollWidth: string = "1500";

    /**
   * Transformation function to transform data returned by the dataLoadFunction to appropriate  object
   * 
   * @memberof GridConfig
   */
    dataTransform?: (row: any) => T;

    dataLoadFunction: (event: GridDataRequestEvent) => Promise<GridDataResponse<T>> | Observable<GridDataResponse<T>>;

    headerColumnGroup?: HeaderColumnRow[];

    showColorCode?: boolean = false;

    // strikeRow?: boolean = false;

    // colorRow?: (rowData: any, column: GridColumn) => any;

    /**
     * the name of exported csv file
     * 
     * @type {string}
     * @memberof GridConfig
     */
    exportFilename?: string;

    showGlobalFilter?: boolean = false;

    sortMode?: any;

    sortField?: string;

    sortOrder?: number;

    multiSortMeta?: SortMeta[] = [];

    showLoader: boolean = true;

    showRefreshIcon?: boolean = false;
}

export type GridDataRequestEvent = any;

export type GridDataResponse<T> = any;

export class PaginatorConfig {
    pageLinks: number = 3;
    rowsPerPageOptions: Array<number> = [10, 20, 50, 100];
    alwaysShowPaginator: boolean = true;
}

export type RowExpandMode = 'single' | 'multiple';
export type ColumnResizeMode = 'fit' | 'expand';