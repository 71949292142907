import { Injectable, Injector } from '@angular/core';
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient } from "../../../../node_modules/@angular/common/http";
import { Contracts } from '../../modals/contracts/contracts';
import { ActivityLogModuleEnum } from '../../shared/constants/activity-log-module-enum';
import { Change } from '../../modals/change/change';
import { ActivityLog } from '../../modals/activity/activity-log';
import { ActionEventEnum } from '../../shared/constants/action-event-enum';
import * as utils from '../../helpers/utils';
import { ContractHoardings } from '../../modals/contracts/contract-hoarding';
import { Kiosk } from '../../modals/hoardings/kiosk';
import { Customer } from '../../modals/KYC/customer';
import { HoardingGroup } from '../../modals/hoardings/hoardings-group';
import { ContractType } from '../../modals/contract-type/contract-type';
import { District } from '../../modals/Area/district';
import { City } from '../../modals/Area/city';
import { PointOfContact } from '../../modals/KYC/pointofcontact';
import { GroupWrapper } from '../../modals/group/group-wrapper';
import { Area } from '../..//modals/Area/area';
import { Plan } from '../../modals/plans/plan';
import { Campaign } from '../../modals/campaigns/campaign';
import { CampaignItem } from '../../modals/campaigns/campaignItem';
import { Invoice } from '../../modals/billings/invoice';
import { PurchaseOrder } from '../../modals/campaigns/purchaseOrder';
import { Suggestion } from '../..//modals/master/suggestion';
import { ActivityPurchaseOrder } from '../../modals/campaigns/activityPurchaseOrder';

export class ValueObj {
    constructor(
        public initialValue: any,
        public finalValue: any,
        public displayFieldValue: string,
        public description: string,
        public additionalDescription: string,
        public formatterType: string
    ) { }
}

@Injectable()
export class ChangesService extends ApiService<any, any, any> {

    // tslint:disable-next-line: no-inferrable-types
    urlPath: string = 'activityLogs';
    baseUrl: string;

    private initialContract: Contracts = null;
    private initialHoarding: ContractHoardings = null;
    private initialHoardingGroup: HoardingGroup = null;
    private initialKiosk: Kiosk = null;
    private initialCustomer: Customer = null;
    private initialPoc: PointOfContact = null;
    private initialContractType: ContractType = null;
    private initialDistrict: District = null;
    private initialCity: City = null;
    private initialGroup: GroupWrapper = null;
    private initialArea: Area = null;
    private initialPlan: Plan = null;
    private initialPlanItems: any[] = null;
    private initialCampaign: Campaign = null;
    private initialCampaignItems: CampaignItem[] = null;
    private initialInvoice: Invoice = null;
    private initialMigratedInvoice: Invoice = null;
    private initialPurchaseOrder: ActivityPurchaseOrder = null;
    private initialSuggestion: Suggestion = null;

    private map = new Map<string, Map<string, ValueObj>>();
    private changes: Change[] = [];
    private activityLogs: ActivityLog[] = [];

    constructor(
        private Http: HttpClient,
        // tslint:disable-next-line: no-shadowed-variable
        private Injector: Injector
    ) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getActivityLogs() {
        return this.activityLogs;
    }

    setInitialContract(contract: Contracts) {
        this.initialContract = contract;
        if (this.map.has(ActivityLogModuleEnum.CONTRACT)) {
            this.map.delete(ActivityLogModuleEnum.CONTRACT);
        }
        this.resetData();
    }

    getInitialContract() {
        return this.initialContract;
    }

    setInitialHoarding(hoarding: ContractHoardings) {
        this.initialHoarding = hoarding;
        if (this.map.has(ActivityLogModuleEnum.HOARDING)) {
            this.map.delete(ActivityLogModuleEnum.HOARDING);
        }
        this.resetData();
    }

    getInitialHoarding() {
        return this.initialHoarding;
    }

    setInitialHoardingGroup(hoardingGroup: HoardingGroup) {
        this.initialHoardingGroup = hoardingGroup;
        if (this.map.has(ActivityLogModuleEnum.HOARDING_GROUP)) {
            this.map.delete(ActivityLogModuleEnum.HOARDING_GROUP);
        }
        this.resetData();
    }

    getInitialHoardingGroup() {
        return this.initialHoardingGroup;
    }

    setInitialKiosk(kiosk: Kiosk) {
        this.initialKiosk = kiosk;
        if (this.map.has(ActivityLogModuleEnum.KIOSK)) {
            this.map.delete(ActivityLogModuleEnum.KIOSK);
        }
        this.resetData();
    }

    getInitialKiosk() {
        return this.initialKiosk;
    }

    setInitialCustomer(customer: Customer) {
        this.initialCustomer = customer;
        if (this.map.has(ActivityLogModuleEnum.CUSTOMER)) {
            this.map.delete(ActivityLogModuleEnum.CUSTOMER);
        }
        this.resetData();
    }

    getInitialCustomer() {
        return this.initialCustomer;
    }

    setInitialPoc(poc: PointOfContact) {
        this.initialPoc = poc;
        if (this.map.has(ActivityLogModuleEnum.POINT_OF_CONTACT)) {
            this.map.delete(ActivityLogModuleEnum.POINT_OF_CONTACT);
        }
        this.resetData();
    }

    getInitialPoc() {
        return this.initialPoc;
    }

    setInitialContractType(contractType: ContractType) {
        this.initialContractType = contractType;
        if (this.map.has(ActivityLogModuleEnum.CONTRACT_TYPE)) {
            this.map.delete(ActivityLogModuleEnum.CONTRACT_TYPE);
        }
        this.resetData();
    }

    getInitialContractType() {
        return this.initialContractType;
    }

    setInitialDistrict(district: District) {
        this.initialDistrict = district;
        if (this.map.has(ActivityLogModuleEnum.DISTRICT)) {
            this.map.delete(ActivityLogModuleEnum.DISTRICT);
        }
        this.resetData();
    }

    getInitialDistrict() {
        return this.initialDistrict;
    }

    setInitialCity(city: City) {
        this.initialCity = city;
        if (this.map.has(ActivityLogModuleEnum.CITY)) {
            this.map.delete(ActivityLogModuleEnum.CITY);
        }
        this.resetData();
    }

    getInitialCity() {
        return this.initialCity;
    }


    setInitialGroup(group: GroupWrapper) {
        this.initialGroup = group;
        if (this.map.has(ActivityLogModuleEnum.GROUP)) {
            this.map.delete(ActivityLogModuleEnum.GROUP);
        }
        this.resetData();
    }

    getInitialGroup() {
        return this.initialGroup;
    }

    setInitialArea(area: Area) {
        this.initialArea = area;
        if (this.map.has(ActivityLogModuleEnum.AREA)) {
            this.map.delete(ActivityLogModuleEnum.AREA);
        }
        this.resetData();
    }

    getInitialArea() {
        return this.initialArea;
    }

    setInitialPlan(plan: Plan) {
        this.initialPlan = plan;
        if (this.map.has(ActivityLogModuleEnum.PLAN)) {
            this.map.delete(ActivityLogModuleEnum.PLAN);
        }
        this.resetData();
    }

    getInitialPlan() {
        return this.initialPlan;
    }

    setInitialPlanItems(planItems: any[]) {
        this.initialPlanItems = planItems;
        if (this.map.has(ActivityLogModuleEnum.PLAN_ITEM)) {
            this.map.delete(ActivityLogModuleEnum.PLAN_ITEM);
        }
        this.resetData();
    }

    getInitialPlanItems() {
        return this.initialPlanItems;
    }

    setInitialCampaign(campaign: Campaign) {
        this.initialCampaign = campaign;
        if (this.map.has(ActivityLogModuleEnum.CAMPAIGN)) {
            this.map.delete(ActivityLogModuleEnum.CAMPAIGN);
        }
        this.resetData();
    }

    getInitialCampaign() {
        return this.initialCampaign;
    }

    setInitialCampaignItems(campaignItems: CampaignItem[]) {
        this.initialCampaignItems = campaignItems;
        if (this.map.has(ActivityLogModuleEnum.CAMPAIGN_ITEM)) {
            this.map.delete(ActivityLogModuleEnum.CAMPAIGN_ITEM);
        }
        this.resetData();
    }

    getInitialCampaignItems() {
        return this.initialCampaignItems;
    }

    setInitialInvoice(invoice: Invoice) {
        this.initialInvoice = invoice;
        if (this.map.has(ActivityLogModuleEnum.INVOICE)) {
            this.map.delete(ActivityLogModuleEnum.INVOICE);
        }
        this.resetData();
    }

    getInitialInvoice() {
        return this.initialInvoice;
    }

    setInitialMigratedInvoice(invoice: Invoice) {
        this.initialMigratedInvoice = invoice;
        if (this.map.has(ActivityLogModuleEnum.MIGRATED_INVOICE)) {
            this.map.delete(ActivityLogModuleEnum.MIGRATED_INVOICE);
        }
        this.resetData();
    }

    getInitialMigratedInvoice() {
        return this.initialMigratedInvoice;
    }

    setInitialPurchaseOrder(purchaseOrder: ActivityPurchaseOrder) {
        this.initialPurchaseOrder = purchaseOrder;
        if (this.map.has(ActivityLogModuleEnum.PURCHASE_ORDER)) {
            this.map.delete(ActivityLogModuleEnum.PURCHASE_ORDER);
        }
        this.resetData();
    }

    getInitialPurchaseOrder() {
        return this.initialPurchaseOrder;
    }
    setInitialSuggestion(Suggestion: Suggestion) {
        this.initialSuggestion = Suggestion;
        if (this.map.has(ActivityLogModuleEnum.SUGGESTION)) {
            this.map.delete(ActivityLogModuleEnum.SUGGESTION);
        }
        this.resetData();
    }

    getInitialSuggestion() {
        return this.initialSuggestion;
    }


    /**
     * @description to create/update the map object based on changes
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {string} type
     * @param {string} field
     * @param {string} changedFieldDisplayName
     * @param {*} value
     * @param {string} formatterType
     * @param {string} changeDescription
     * @param {string} changeAdditionalDescription
     * @param {string} changedFieldArrayVariable
     * @memberof ChangesService
     */
    updateObjectMap(type: string, field: string, changedFieldDisplayName: string, value: any, formatterType: string, changeDescription: string, changeAdditionalDescription: string, changedFieldArrayVariable: string) {
        if (this.getActionEvent(type) === ActionEventEnum.CREATE) {
            this.changes = [];
            this.activityLogs = [];
        } else {
            let valueToCheck = this.getValueToCheck(this.getObject(type), field);
            if (changedFieldArrayVariable) {
                valueToCheck = this.getValueFromArrayObj(valueToCheck, changedFieldArrayVariable);
                value = this.getValueFromArrayObj(value, changedFieldArrayVariable);
            } else {
                // do nothing
            }
            if (formatterType) {
                switch (formatterType) {
                    case 'date': {
                        valueToCheck = new Date(valueToCheck);
                        value = new Date(value);
                        break;
                    }
                }
            } else {
                // do nothing
            }
            if (this.map.has(type)) {
                const innerMap = this.map.get(type);
                if (JSON.stringify(valueToCheck) !== JSON.stringify(value) && ((JSON.stringify(valueToCheck) !== '""' && JSON.stringify(valueToCheck) !== undefined) || (JSON.stringify(value) !== undefined && JSON.stringify(value) !== '""'))) {
                    innerMap.set(field, new ValueObj(valueToCheck, value, changedFieldDisplayName, changeDescription, changeAdditionalDescription, formatterType));
                    this.map.set(type, innerMap);
                } else {
                    innerMap.delete(field);
                    this.map.set(type, innerMap);
                }
            } else {
                if (JSON.stringify(valueToCheck) !== JSON.stringify(value) && ((JSON.stringify(valueToCheck) !== '""' && JSON.stringify(valueToCheck) !== undefined) || (JSON.stringify(value) !== undefined && JSON.stringify(value) !== '""'))) {
                    const innerMap = new Map<string, ValueObj>();
                    innerMap.set(field, new ValueObj(valueToCheck, value, changedFieldDisplayName, changeDescription, changeAdditionalDescription, formatterType));
                    this.map.set(type, innerMap);
                } else {
                    // do nothing
                }
            }
            console.log("map is", this.map);
            this.createChangesObject(type);
        }
    }

    /**
     * @description to get the object for value comparision
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @returns
     * @memberof ChangesService
     */
    getObject(type) {
        switch (type) {
            case ActivityLogModuleEnum.CONTRACT: {
                return this.getInitialContract();
            }
            case ActivityLogModuleEnum.HOARDING: {
                return this.getInitialHoarding();
            }
            case ActivityLogModuleEnum.HOARDING_GROUP: {
                return this.getInitialHoardingGroup();
            }
            case ActivityLogModuleEnum.KIOSK: {
                return this.getInitialKiosk();
            }
            case ActivityLogModuleEnum.CUSTOMER: {
                return this.getInitialCustomer();
            }
            case ActivityLogModuleEnum.POINT_OF_CONTACT: {
                return this.getInitialPoc();
            }
            case ActivityLogModuleEnum.CONTRACT_TYPE: {
                return this.getInitialContractType();
            }
            case ActivityLogModuleEnum.DISTRICT: {
                return this.getInitialDistrict();
            }
            case ActivityLogModuleEnum.CITY: {
                return this.getInitialCity();
            }
            case ActivityLogModuleEnum.GROUP: {
                return this.getInitialGroup();
            }
            case ActivityLogModuleEnum.AREA: {
                return this.getInitialArea();
            }
            case ActivityLogModuleEnum.PLAN: {
                return this.getInitialPlan();
            }
            case ActivityLogModuleEnum.PLAN_ITEM: {
                return this.getInitialPlanItems();
            }
            case ActivityLogModuleEnum.CAMPAIGN: {
                return this.getInitialCampaign();
            }
            case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
                return this.getInitialCampaignItems();
            }
            case ActivityLogModuleEnum.INVOICE: {
                return this.getInitialInvoice();
            }
            case ActivityLogModuleEnum.MIGRATED_INVOICE: {
                return this.getInitialMigratedInvoice();
            }
            case ActivityLogModuleEnum.PURCHASE_ORDER: {
                return this.getInitialPurchaseOrder();
            }
            case ActivityLogModuleEnum.SUGGESTION: {
                return this.getInitialSuggestion();
            }
        }
    }

    /**
     * @description to get the value from the object based on object pojo
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} object
     * @param {*} field
     * @returns
     * @memberof ChangesService
     */
    getValueToCheck(object, field) {
        const splitArray = field.split('.').join('#').split('/').join('#').split('#');
        splitArray.forEach((item) => {
            object = (object && object[item]) ? object[item] : undefined;
        });
        return object;
    }

    /**
     * @description to get the value of an array field in the object - used in groups for companies array
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} value
     * @param {*} changedFieldArrayVariable
     * @returns
     * @memberof ChangesService
     */
    getValueFromArrayObj(value, changedFieldArrayVariable) {
        value = value.map(item => item[changedFieldArrayVariable]);
        return value.slice().join(",");
    }

    /**
     * @description to create the changes object for the type specified
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @memberof ChangesService
     */
    createChangesObject(type) {
        this.changes = [];
        const changedValueMap = this.map.get(type);
        this.changes = (changedValueMap) ? this.createChagnesObjectFromMap(changedValueMap) : [];
        console.log("changes", this.changes);
        if (changedValueMap) {
            this.createActivityLogObject(type);
        }
    }

    /**
     * @description to create the changes object from the map of the specified type
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {Map<string, ValueObj>} map
     * @returns
     * @memberof ChangesService
     */
    createChagnesObjectFromMap(map: Map<string, ValueObj>) {
        const changes: Change[] = [];
        map.forEach((value, key) => {
            const change: Change = new Change();
            change.fieldName = value.displayFieldValue;
            change.oldValue = value.initialValue;
            change.newValue = value.finalValue;
            change.description = value.description;
            change.additionalDescription = value.additionalDescription;
            change.formatterType = value.formatterType;
            changes.push(change);
        });
        return changes;
    }

    /**
     * @description to create the activity log object for the specified type
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @memberof ChangesService
     */
    createActivityLogObject(type) {
        switch (type) {
            case ActivityLogModuleEnum.CONTRACT:
            case ActivityLogModuleEnum.HOARDING:
            case ActivityLogModuleEnum.HOARDING_GROUP:
            case ActivityLogModuleEnum.KIOSK:
            case ActivityLogModuleEnum.CUSTOMER:
            case ActivityLogModuleEnum.POINT_OF_CONTACT:
            case ActivityLogModuleEnum.CONTRACT_TYPE:
            case ActivityLogModuleEnum.DISTRICT:
            case ActivityLogModuleEnum.CITY:
            case ActivityLogModuleEnum.AREA:
            case ActivityLogModuleEnum.GROUP:
            case ActivityLogModuleEnum.INVOICE:
            case ActivityLogModuleEnum.MIGRATED_INVOICE:
            case ActivityLogModuleEnum.PURCHASE_ORDER:
            case ActivityLogModuleEnum.SUGGESTION: {
                this.activityLogs = [];
                this.setActivityLogObject(type);
                break;
            }
            case ActivityLogModuleEnum.PLAN: {
                if (this.map.has(ActivityLogModuleEnum.PLAN_ITEM)) {
                    this.setActivityLogObject(type, true);
                } else {
                    this.activityLogs = [];
                    this.setActivityLogObject(type);
                }
                break;
            }
            case ActivityLogModuleEnum.PLAN_ITEM: {
                if (this.map.has(ActivityLogModuleEnum.PLAN)) {
                    this.setActivityLogObject(type, true);
                } else {
                    this.activityLogs = [];
                    this.setActivityLogObject(type);
                }
                break;
            }
            case ActivityLogModuleEnum.CAMPAIGN: {
                if (this.map.has(ActivityLogModuleEnum.CAMPAIGN_ITEM)) {
                    this.setActivityLogObject(type, true);
                } else {
                    this.activityLogs = [];
                    this.setActivityLogObject(type);
                }
                break;
            }
            case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
                if (this.map.has(ActivityLogModuleEnum.CAMPAIGN)) {
                    this.setActivityLogObject(type, true);
                } else {
                    this.activityLogs = [];
                    this.setActivityLogObject(type);
                }
                break;
            }
        }
        console.log("activity logs", this.activityLogs);
    }

    /**
     * @description setting the activity log object for the specified type
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @param {boolean} [updateExisting=false]
     * @memberof ChangesService
     */
    setActivityLogObject(type, updateExisting = false) {
        if (this.activityLogs && this.activityLogs.length && updateExisting) {
            const index = this.activityLogs.findIndex(log => log.module === utils.getKey(ActivityLogModuleEnum, type));
            if (index !== -1) {
                this.activityLogs[index].changes = this.changes;
            } else {
                this.setActivityLogObject(type);
            }
        } else {
            const activityLog: ActivityLog = new ActivityLog();
            activityLog.module = utils.getKey(ActivityLogModuleEnum, type);
            activityLog.actionEvent = utils.getKey(ActionEventEnum, this.getActionEvent(type));
            activityLog.primaryKey = this.getPrimaryKey(type);
            activityLog.displayKey = this.getDisplayKey(type);
            activityLog.changes = this.changes;
            activityLog.responsiblePersonEmailId = this.getResponsibleUserEmail(type);
            activityLog.additoinalDesc = this.getAdditionalDescription(type);
            this.activityLogs.push(activityLog);
        }
    }

    /**
     * @description to get the action event - create or update - based on type specified
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @returns
     * @memberof ChangesService
     */
    getActionEvent(type) {
        switch (type) {
            case ActivityLogModuleEnum.CONTRACT: {
                if (!this.getInitialContract() || !this.getInitialContract().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.HOARDING: {
                if (!this.getInitialHoarding() || !this.getInitialHoarding().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.HOARDING_GROUP: {
                if (!this.getInitialHoardingGroup() || !this.getInitialHoardingGroup().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.KIOSK: {
                if (!this.getInitialKiosk() || !this.getInitialKiosk().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.CUSTOMER: {
                if (!this.getInitialCustomer() || !this.getInitialCustomer().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.POINT_OF_CONTACT: {
                if (!this.getInitialPoc() || !this.getInitialPoc().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.CONTRACT_TYPE: {
                if (!this.getInitialContractType() || !this.getInitialContractType().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.DISTRICT: {
                if (!this.getInitialDistrict() || !this.getInitialDistrict().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.CITY: {
                if (!this.getInitialCity() || !this.getInitialCity().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.GROUP: {
                if (!this.getInitialGroup() || !this.getInitialGroup().group.id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.AREA: {
                if (!this.getInitialArea() || !this.getInitialArea().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.PLAN: {
                if (!this.getInitialPlan() || !this.getInitialPlan().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.PLAN_ITEM: {
                if (!this.getInitialPlan() || !this.getInitialPlan().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.CAMPAIGN: {
                if (!this.getInitialCampaign() || !this.getInitialCampaign().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
                if (!this.getInitialCampaign() || !this.getInitialCampaign().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.INVOICE: {
                if (!this.getInitialInvoice() || !this.getInitialInvoice().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.MIGRATED_INVOICE: {
                if (!this.getInitialMigratedInvoice() || !this.getInitialMigratedInvoice().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.PURCHASE_ORDER: {
                if (!this.getInitialPurchaseOrder() || !this.getInitialPurchaseOrder().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
            case ActivityLogModuleEnum.SUGGESTION: {
                if (!this.getInitialSuggestion() || !this.getInitialSuggestion().id) {
                    return ActionEventEnum.CREATE;
                } else {
                    return ActionEventEnum.UPDATE;
                }
            }
        }
    }

    /**
     * @description to get the primary key for activity log object based on the type specified
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @returns
     * @memberof ChangesService
     */
    getPrimaryKey(type) {
        switch (type) {
            case ActivityLogModuleEnum.CONTRACT: {
                return this.getInitialContract().id;
            }
            case ActivityLogModuleEnum.HOARDING: {
                return this.getInitialHoarding().id;
            }
            case ActivityLogModuleEnum.HOARDING_GROUP: {
                return this.getInitialHoardingGroup().id;
            }
            case ActivityLogModuleEnum.KIOSK: {
                return this.getInitialKiosk().id;
            }
            case ActivityLogModuleEnum.CUSTOMER: {
                return this.getInitialCustomer().id;
            }
            case ActivityLogModuleEnum.POINT_OF_CONTACT: {
                return this.getInitialPoc().id;
            }
            case ActivityLogModuleEnum.CONTRACT_TYPE: {
                return this.getInitialContractType().id;
            }
            case ActivityLogModuleEnum.DISTRICT: {
                return this.getInitialDistrict().id;
            }
            case ActivityLogModuleEnum.CITY: {
                return this.getInitialCity().id;
            }
            case ActivityLogModuleEnum.GROUP: {
                return this.getInitialGroup().group.id;
            }
            case ActivityLogModuleEnum.AREA: {
                return this.getInitialArea().id;
            }
            case ActivityLogModuleEnum.PLAN: {
                return this.getInitialPlan().id;
            }
            case ActivityLogModuleEnum.PLAN_ITEM: {
                return this.getInitialPlan().id;
            }
            case ActivityLogModuleEnum.CAMPAIGN: {
                return this.getInitialCampaign().id;
            }
            case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
                return this.getInitialCampaign().id;
            }
            case ActivityLogModuleEnum.INVOICE: {
                return this.getInitialInvoice().id;
            }
            case ActivityLogModuleEnum.MIGRATED_INVOICE: {
                return this.getInitialMigratedInvoice().id;
            }
            case ActivityLogModuleEnum.PURCHASE_ORDER: {
                return this.getInitialPurchaseOrder().id;
            }
            case ActivityLogModuleEnum.SUGGESTION: {
                return this.getInitialSuggestion().id;
            }
        }
    }

    /**
     * @description to get the display key for activity log object based on the type specified
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @returns
     * @memberof ChangesService
     */
    getDisplayKey(type) {
        switch (type) {
            case ActivityLogModuleEnum.CONTRACT: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialContract().name;
            }
            case ActivityLogModuleEnum.HOARDING: {
                const modifiedValue = this.getModifiedValue(type, 'customId');
                return (modifiedValue) ? modifiedValue : this.getInitialHoarding().customId;
            }
            case ActivityLogModuleEnum.HOARDING_GROUP: {
                const modifiedValue = this.getModifiedValue(type, 'customId');
                return (modifiedValue) ? modifiedValue : this.getInitialHoardingGroup().customId;
            }
            case ActivityLogModuleEnum.KIOSK: {
                const modifiedValue = this.getModifiedValue(type, 'customId');
                return (modifiedValue) ? modifiedValue : this.getInitialKiosk().customId;
            }
            case ActivityLogModuleEnum.CUSTOMER: {
                const modifiedValue = this.getModifiedValue(type, 'billingName');
                return (modifiedValue) ? modifiedValue : this.getInitialCustomer().billingName;
            }
            case ActivityLogModuleEnum.POINT_OF_CONTACT: {
                const modifiedValue = this.getModifiedValue(type, 'firstName');
                return (modifiedValue) ? modifiedValue : this.getInitialPoc().firstName;
            }
            case ActivityLogModuleEnum.CONTRACT_TYPE: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialContractType().name;
            }
            case ActivityLogModuleEnum.DISTRICT: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialDistrict().name;
            }
            case ActivityLogModuleEnum.CITY: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialCity().name;
            }
            case ActivityLogModuleEnum.GROUP: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialGroup().group.name;
            }
            case ActivityLogModuleEnum.AREA: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialArea().name;
            }
            case ActivityLogModuleEnum.PLAN: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialPlan().displayName;
            }
            case ActivityLogModuleEnum.PLAN_ITEM: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialPlan().displayName;
            }
            case ActivityLogModuleEnum.CAMPAIGN: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialCampaign().displayName;
            }
            case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialCampaign().displayName;
            }
            case ActivityLogModuleEnum.INVOICE: {
                const modifiedValue = this.getModifiedValue(type, 'invoiceId');
                return (modifiedValue) ? modifiedValue : this.getInitialInvoice().invoiceId;
            }
            case ActivityLogModuleEnum.MIGRATED_INVOICE: {
                const modifiedValue = this.getModifiedValue(type, 'invoiceId');
                return (modifiedValue) ? modifiedValue : this.getInitialMigratedInvoice().invoiceId;
            }
            case ActivityLogModuleEnum.PURCHASE_ORDER: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialPurchaseOrder().displayName;
            }
            case ActivityLogModuleEnum.SUGGESTION: {
                const modifiedValue = this.getModifiedValue(type, 'suggestion');
                return (modifiedValue) ? modifiedValue : this.getInitialSuggestion().suggestion;
            }
        }
    }

    /**
     * @description to check if the value of the field is updated for use in activity log display key or activity log additional description
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @param {*} searchKey
     * @returns
     * @memberof ChangesService
     */
    getModifiedValue(type, searchKey) {
        const map = this.map.get(type);
        if (map && map.has(searchKey)) {
            return map.get(searchKey).finalValue;
        } else {
            return null;
        }
    }

    /**
     * @description to get the responsible person email based on type specified
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @returns
     * @memberof ChangesService
     */
    getResponsibleUserEmail(type) {
        switch (type) {
            case ActivityLogModuleEnum.CONTRACT: {
                return this.getInitialContract().updatedBy;
            }
            case ActivityLogModuleEnum.HOARDING: {
                return this.getInitialHoarding().updatedBy;
            }
            case ActivityLogModuleEnum.HOARDING_GROUP: {
                return this.getInitialHoardingGroup().updatedBy;
            }
            case ActivityLogModuleEnum.KIOSK: {
                return this.getInitialKiosk().updatedBy;
            }
            case ActivityLogModuleEnum.CUSTOMER: {
                return this.getInitialCustomer().updatedBy;
            }
            case ActivityLogModuleEnum.POINT_OF_CONTACT: {
                return this.getInitialPoc().updatedBy;
            }
            case ActivityLogModuleEnum.CONTRACT_TYPE: {
                return this.getInitialContractType().updatedBy;
            }
            case ActivityLogModuleEnum.DISTRICT: {
                return this.getInitialDistrict().updatedBy;
            }
            case ActivityLogModuleEnum.CITY: {
                return this.getInitialCity().updatedBy;
            }
            case ActivityLogModuleEnum.GROUP: {
                return this.getInitialGroup().group.updatedBy;
            }
            case ActivityLogModuleEnum.AREA: {
                return this.getInitialArea().updatedBy;
            }
            case ActivityLogModuleEnum.PLAN: {
                return this.getInitialPlan().updatedBy;
            }
            case ActivityLogModuleEnum.PLAN_ITEM: {
                return this.getInitialPlan().updatedBy;
            }
            case ActivityLogModuleEnum.CAMPAIGN: {
                return this.getInitialCampaign().updatedBy;
            }
            case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
                return this.getInitialCampaign().updatedBy;
            }
            case ActivityLogModuleEnum.INVOICE: {
                return this.getInitialInvoice().updatedBy;
            }
            case ActivityLogModuleEnum.MIGRATED_INVOICE: {
                return this.getInitialMigratedInvoice().updatedBy;
            }
            case ActivityLogModuleEnum.PURCHASE_ORDER: {
                return this.getInitialPurchaseOrder().updatedBy;
            }
            case ActivityLogModuleEnum.SUGGESTION: {
                return this.getInitialSuggestion().updatedBy;
            }
        }
    }

    /**
     * @description to get the additional description for activity log based on type specified
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {*} type
     * @returns
     * @memberof ChangesService
     */
    getAdditionalDescription(type) {
        switch (type) {
            case ActivityLogModuleEnum.CONTRACT: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialContract().name;
            }
            case ActivityLogModuleEnum.HOARDING: {
                const modifiedValue = this.getModifiedValue(type, 'customId');
                return (modifiedValue) ? modifiedValue : this.getInitialHoarding().customId;
            }
            case ActivityLogModuleEnum.HOARDING_GROUP: {
                const modifiedValue = this.getModifiedValue(type, 'customId');
                return (modifiedValue) ? modifiedValue : this.getInitialHoardingGroup().customId;
            }
            case ActivityLogModuleEnum.KIOSK: {
                const modifiedValue = this.getModifiedValue(type, 'customId');
                return (modifiedValue) ? modifiedValue : this.getInitialKiosk().customId;
            }
            case ActivityLogModuleEnum.CUSTOMER: {
                const modifiedValue = this.getModifiedValue(type, 'billingName');
                return (modifiedValue) ? modifiedValue : this.getInitialCustomer().billingName;
            }
            case ActivityLogModuleEnum.POINT_OF_CONTACT: {
                const modifiedValue = this.getModifiedValue(type, 'firstName');
                return (modifiedValue) ? modifiedValue : this.getInitialPoc().firstName;
            }
            case ActivityLogModuleEnum.CONTRACT_TYPE: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialContractType().name;
            }
            case ActivityLogModuleEnum.DISTRICT: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialDistrict().name;
            }
            case ActivityLogModuleEnum.CITY: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialCity().name;
            }
            case ActivityLogModuleEnum.GROUP: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialGroup().group.name;
            }
            case ActivityLogModuleEnum.AREA: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialArea().name;
            }
            case ActivityLogModuleEnum.PLAN: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialPlan().displayName;
            }
            case ActivityLogModuleEnum.PLAN_ITEM: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialPlan().displayName;
            }
            case ActivityLogModuleEnum.CAMPAIGN: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialCampaign().displayName;
            }
            case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
                const modifiedValue = this.getModifiedValue(type, 'displayName');
                return (modifiedValue) ? modifiedValue : this.getInitialCampaign().displayName;
            }
            case ActivityLogModuleEnum.INVOICE: {
                const modifiedValue = this.getModifiedValue(type, 'invoiceId');
                return (modifiedValue) ? modifiedValue : this.getInitialInvoice().invoiceId;
            }
            case ActivityLogModuleEnum.MIGRATED_INVOICE: {
                const modifiedValue = this.getModifiedValue(type, 'invoiceId');
                return (modifiedValue) ? modifiedValue : this.getInitialMigratedInvoice().invoiceId;
            }
            case ActivityLogModuleEnum.PURCHASE_ORDER: {
                const modifiedValue = this.getModifiedValue(type, 'name');
                return (modifiedValue) ? modifiedValue : this.getInitialPurchaseOrder().name;
            }
            case ActivityLogModuleEnum.SUGGESTION: {
                const modifiedValue = this.getModifiedValue(type, 'id');
                return (modifiedValue) ? modifiedValue : this.getInitialSuggestion().id;
            }
        }
    }

    /**
     * @description to create/update the activity log for no of hoardings changed in hoarding group edit
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {ContractHoardings[]} hoardings
     * @memberof ChangesService
     */
    createHoardingChangesObjectWhileGroupEdit(hoardings: ContractHoardings[]) {
        const hoardingsRemoved: string[] = this.getInitialHoardingGroup().hoardings.filter(item => hoardings.findIndex(itm => itm.customId === item.customId) === -1).map(it => it.customId);
        const hoardingsAdded: string[] = hoardings.filter(item => this.getInitialHoardingGroup().hoardings.findIndex(itm => itm.customId === item.customId) === -1).map(it => it.customId);
        if (hoardingsRemoved.length) {
            if (this.map.has(ActivityLogModuleEnum.HOARDING_GROUP)) {
                const innerMap = this.map.get(ActivityLogModuleEnum.HOARDING_GROUP);
                if (innerMap.has("Hoardings Removed")) {
                    // do nothing
                } else {
                    innerMap.set("Hoardings Removed", new ValueObj(hoardingsRemoved.slice().join(","), "-", "Hoardings Removed", hoardingsRemoved.slice().join(","), "-", null));
                }
                this.changes = this.createChagnesObjectFromMap(innerMap);
            } else {
                const innerMap = new Map<string, ValueObj>();
                innerMap.set("Hoardings Removed", new ValueObj(hoardingsRemoved.slice().join(","), "-", "Hoardings Removed", hoardingsRemoved.slice().join(","), "-", null));
                this.map.set(ActivityLogModuleEnum.HOARDING_GROUP, innerMap);
                this.changes = this.createChagnesObjectFromMap(innerMap);
            }
        } else {
            // do nothing
        }

        if (hoardingsAdded.length) {
            if (this.map.has(ActivityLogModuleEnum.HOARDING_GROUP)) {
                const innerMap = this.map.get(ActivityLogModuleEnum.HOARDING_GROUP);
                if (innerMap.has("Hoardings Added")) {
                    // do nothing
                } else {
                    innerMap.set("Hoardings Added", new ValueObj("-", hoardingsAdded.slice().join(","), "Hoardings Added", hoardingsAdded.slice().join(","), "-", null));
                }
                this.changes = this.createChagnesObjectFromMap(innerMap);
            } else {
                const innerMap = new Map<string, ValueObj>();
                innerMap.set("Hoardings Added", new ValueObj("-", hoardingsAdded.slice().join(","), "Hoardings Added", hoardingsAdded.slice().join(","), "-", null));
                this.map.set(ActivityLogModuleEnum.HOARDING_GROUP, innerMap);
                this.changes = this.createChagnesObjectFromMap(innerMap);
            }
        } else {
            // do nothing
        }

        if (!hoardingsRemoved.length && !hoardingsAdded.length) {
            if (this.map.has(ActivityLogModuleEnum.HOARDING_GROUP)) {
                const innerMap = this.map.get(ActivityLogModuleEnum.HOARDING_GROUP);
                if (innerMap.has("Hoardings Added")) {
                    innerMap.delete("Hoardings Added");
                } else {
                    // do nothing
                }
                if (innerMap.has("Hoardings Removed")) {
                    innerMap.delete("Hoardings Removed");
                } else {
                    // do nothing
                }
                this.changes = this.createChagnesObjectFromMap(innerMap);
            } else {
                // do noting
            }
        } else {
            // do nothing
        }

        if (this.changes && this.changes.length) {
            this.createActivityLogObject(ActivityLogModuleEnum.HOARDING_GROUP);
        } else {
            this.activityLogs = [];
        }
    }

    /**
     * @description to create/update the activity log for no of items changed in invoice edit based on item type
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {string} itemType - rentItems, printingItems or mountingItems - based on invoice pojo
     * @param {any[]} updatedRows
     * @param {boolean} migrated - to differentialte between normal and migrated invoices
     * @memberof ChangesService
     */
    createItemChangesObjectWhileInvoiceEdit(itemType: string, updatedRows: any[], migrated: boolean) {
        const invoiceType = (!migrated) ? ActivityLogModuleEnum.INVOICE : ActivityLogModuleEnum.MIGRATED_INVOICE;
        const itemsRemoved: string[] = (!migrated) ? this.getInitialInvoice()[itemType].filter(item => updatedRows.findIndex(itm => itm.customId === item.customId) === -1).map(it => it.customId) : this.getInitialMigratedInvoice()[itemType].filter(item => updatedRows.findIndex(itm => itm.customId === item.customId) === -1).map(it => it.customId);
        const itemsAdded: string[] = (!migrated) ? updatedRows.filter(item => this.getInitialInvoice()[itemType].findIndex(itm => itm.customId === item.customId) === -1).map(it => it.customId) : updatedRows.filter(item => this.getInitialMigratedInvoice()[itemType].findIndex(itm => itm.customId === item.customId) === -1).map(it => it.customId);
        if (itemsRemoved.length) {
            if (this.map.has(invoiceType)) {
                const innerMap = this.map.get(invoiceType);
                if (innerMap.has(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Removed")) {
                    // if (innerMap.has((itemType === "rentItems") ? "Rent Items Removed" : (itemType === "printingItems") ? "Printing Items Removed" : "Mounting Items Removed")) {
                    // do nothing
                } else {
                    innerMap.set(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Removed", new ValueObj(itemsRemoved.slice().join(","), "-", this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Removed", itemsRemoved.slice().join(","), "-", null));
                }
                this.changes = this.createChagnesObjectFromMap(innerMap);
            } else {
                const innerMap = new Map<string, ValueObj>();
                innerMap.set(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Removed", new ValueObj(itemsRemoved.slice().join(","), "-", this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Removed", itemsRemoved.slice().join(","), "-", null));
                this.map.set(invoiceType, innerMap);
                this.changes = this.createChagnesObjectFromMap(innerMap);
            }
        } else {
            // do nothing
        }

        if (itemsAdded.length) {
            if (this.map.has(invoiceType)) {
                const innerMap = this.map.get(invoiceType);
                if (innerMap.has(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Added")) {
                    // do nothing
                } else {
                    innerMap.set(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Added", new ValueObj("-", itemsAdded.slice().join(","), this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Added", itemsAdded.slice().join(","), "-", null));
                }
                this.changes = this.createChagnesObjectFromMap(innerMap);
            } else {
                const innerMap = new Map<string, ValueObj>();
                innerMap.set(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Added", new ValueObj("-", itemsAdded.slice().join(","), this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Added", itemsAdded.slice().join(","), "-", null));
                this.map.set(invoiceType, innerMap);
                this.changes = this.createChagnesObjectFromMap(innerMap);
            }
        } else {
            // do nothing
        }

        if (!itemsRemoved.length && !itemsAdded.length) {
            if (this.map.has(invoiceType)) {
                const innerMap = this.map.get(invoiceType);
                if (innerMap.has(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Added")) {
                    innerMap.delete(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Added");
                } else {
                    // do nothing
                }
                if (innerMap.has(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Removed")) {
                    innerMap.delete(this.getInvoiceInnerMapFieldBasedOnItemType(itemType) + " " + "Removed");
                } else {
                    // do nothing
                }
                this.changes = this.createChagnesObjectFromMap(innerMap);
            } else {
                // do noting
            }
        } else {
            // do nothing
        }

        if (this.changes && this.changes.length) {
            this.createActivityLogObject(invoiceType);
        } else {
            this.activityLogs = [];
        }
    }

    /**
     * @description to get the activity log display field name based on item type while editing invoice items - rentItems, printingItems or mountingItems
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @param {string} itemType
     * @returns
     * @memberof ChangesService
     */
    getInvoiceInnerMapFieldBasedOnItemType(itemType: string) {
        switch (itemType) {
            case "rentItems": {
                return "Rent Items";
            }
            case "printingItems": {
                return "Printing Items";
            }
            case "mountingItems": {
                return "Mounting Items";
            }
        }
    }

    /**
     * @description to reset the map based on the changes from plan and campaign edit dialogs
     * @author Pulkit Bansal
     * @date 2019-06-13
     * @param {string} type
     * @param {any[]} fields
     * @memberof ChangesService
     */
    resetMap(type: string, fields: any[]) {
        if (this.map.has(type)) {
            const innerMap = this.map.get(type);
            fields.forEach((field) => {
                if (innerMap.has(field)) {
                    innerMap.delete(field);
                }
            });
            this.createChangesObject(type);
        } else {
            // do nothing
        }
    }

    /**
     * @description to reset the map, changes and activity logs objects
     * @author Pulkit Bansal
     * @date 2019-06-15
     * @memberof ChangesService
     */
    resetData() {
        this.map = new Map<string, Map<string, ValueObj>>();
        this.changes = [];
        this.activityLogs = [];
    }

}


