<div class="campaignTasks">
    <!-- <div *ngIf="isLoading">
        <p-progressSpinner class="loading"></p-progressSpinner>
    </div> -->
    <div>
        <form [formGroup]="campaignTaskGroup">
            <sib-grid name="campaignTaskGrid" #sibGrid [buttons]="buttonConfig" [totalRecords]="totalRecords"
                [showFilter]="true" [showColorLegends]="false" (eEmitColumnSelection)="updatingUserGridConfig($event)"
                [colorLegendsStatus]="campaignTaskListStatusDropdown" [columns]="columns" [config]="gridConfig"
                formControlName="campaignTaskGridControl" ngDefaultControl>
                <sib-grid-column field="srNo">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span>
                            {{rowIndex+1}}
                        </span>
                    </ng-template>
                </sib-grid-column>
                <!-- <sib-grid-column field="delCampaign">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
                        <div *ngIf="rowData.status === 'PENDING' || rowData.status === 'APPROVED'">
                            <i class="fa fa-trash-o info-red size-16" title="Delete the campaign" (click)="deleteCampaign(rowData)"
                                aria-hidden="true"></i>
                        </div>
                    </ng-template>
                </sib-grid-column> -->
                <!-- <sib-grid-column field="viewCampaign">
                        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
                            <i class="fa fa-pencil-square-o info-blue size-16" (click)="viewCampaign(rowData)"  aria-hidden="true"></i>
                        </ng-template>
                    </sib-grid-column> -->

                <sib-grid-column field="summary.lspDiffAmt">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span *ngIf="rowData?.summary?.lspDiffAmt >= 0"
                            class="pos-diff">{{getIntegerRoundedOffValue(getAbsoluteValue(rowData.summary.lspDiffAmt))|CurrencyConverter}}
                            ({{getIntegerRoundedOffValue(getAbsoluteValue(rowData.summary.lspDiffPctg))}}%) </span>
                        <span *ngIf="rowData?.summary?.lspDiffAmt < 0"
                            class="neg-diff">{{getIntegerRoundedOffValue(getAbsoluteValue(rowData.summary.lspDiffAmt))|CurrencyConverter}}
                            ({{getIntegerRoundedOffValue(getAbsoluteValue(rowData.summary.lspDiffPctg))}}%) </span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="displayName">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <div class="link-wrapper">
                            <a class="link-1  after" target="_blank"
                                [routerLink]="['/campaigns/view/', rowData.id]">{{rowData.displayName}}</a>
                        </div>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="approveReject">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <div class="font-size-20">
                            <span class="padding-right-5">
                                <i class="fa fa-check-circle custom-icon true-color" title="Approve"
                                    (click)="approveCampaign(rowData)"></i>
                            </span>
                            <span>
                                <i *ngIf="rowData?.status === 'PENDING'"
                                    class="fa fa-times-circle custom-icon false-color" title="Reject"
                                    (click)="rejectCampaign(rowData)"></i>
                            </span>
                        </div>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="status">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <div class="{{rowData.status}}">
                            {{getCampaignStatusValue(rowData.status)}}
                        </div>
                    </ng-template>
                    <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
                        <p-dropdown [options]="campaignTaskListStatusDropdown"
                            [style]="{'width':'100%','margin-top':'8px'}" appendTo="body" placeholder="Status"
                            (onChange)="sibGrid.filter($event.value,'status',col.filterMatchMode)"></p-dropdown>
                    </ng-template>
                </sib-grid-column>

            </sib-grid>
        </form>
    </div>
</div>