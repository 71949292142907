import { Component, Input } from '@angular/core';
import { MainComponent } from '../main.component';

@Component({
  selector: 'sib-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent {

  @Input() reset: boolean;
  @Input() model: any[];

  constructor() { }

  changeTheme(theme) {
    let themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    let layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');

    themeLink.href = 'assets/theme/theme-' + theme + '.css';
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
  }
}
