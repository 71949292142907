import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContractModuleState, contractModuleWithApp } from "../contract-module.reducers";
import { Contracts } from "../../modals/contracts/contracts";
import { ContractActions } from "../actions/contract-actions";
import { AreaQueryParams } from "../../modals/queryparams/area-queryparams";
import { Observable } from "rxjs";
import { HoardingGroup } from "../../modals/hoardings/hoardings-group";
import { ContractHoardings } from "../../modals/contracts/contract-hoarding";

@Injectable()
export class ContractsActionDispatcher {

    stateList: Observable<any[]>;
    districtList: Observable<any[]>;
    citiesList: Observable<any[]>;
    areasList: Observable<any[]>;
    contractCreated: Observable<boolean>;
    contract: Observable<Contracts>;
    hoardingGroups: Observable<HoardingGroup[]>;
    hoarding: Observable<ContractHoardings>;
    isHoardingFromContractCreated: Observable<boolean>;
    isHoardingUpdated: Observable<boolean>;
    isError: Observable<boolean>;
    isHoardingGroupCreated: Observable<boolean>;
    // isHoardingGroupUpdated: Observable<boolean>;
    hoardingGroup: Observable<HoardingGroup>;
    isHoardingAddedToGroup: Observable<boolean>;
    isHoardingsAddedToContract: Observable<boolean>;
    getContractByIdFailedFlag: Observable<boolean>;
    isHoardingGroupUpdated: Observable<boolean>;
    updateHoardingGroupError: Observable<boolean>;
    isContractUpdated: Observable<boolean>;
    updatedContract: Observable<Contracts>;
    contractById: Observable<Contracts>;
    hoardingGroupWithImageUpdated: Observable<boolean>;
    hoardingWithImageUpdated: Observable<boolean>;
    errorCreatingContract: Observable<boolean>;
    errorCreatingContractHoarding: Observable<boolean>;
    updateContractFailed:Observable<boolean>;
    
    constructor(
        private store: Store<contractModuleWithApp>,
        private contractActions: ContractActions

    ) {
        this.stateList = store.select((state) => {
            return state.contracts.contracts.statesList;
        });

        this.districtList = store.select((state) => {
            return state.contracts.contracts.districtsList;
        });

        this.citiesList = store.select((state) => {
            return state.contracts.contracts.citiesList;
        });

        this.areasList = store.select((state) => {
            return state.contracts.contracts.areasList;
        });

        this.contractCreated = store.select(state => {
            return state.contracts.contracts.contractCreated;
        });

        this.contract = store.select(state => {
            return state.contracts.contracts.contract;
        });

        this.hoardingGroups = store.select((state) => {
            return state.contracts.contracts.hoardingGroups;
        });

        this.hoarding = store.select((state) => {
            return state.contracts.contracts.hoarding;
        });

        this.isHoardingFromContractCreated = store.select(state => {
            return state.contracts.contracts.isHoardingFromContractCreated;
        });

        this.isHoardingUpdated = store.select(state => {
            return state.contracts.contracts.isHoardingUpdated;
        });

        // this.isHoardingUpdated = store.select(state => {
        //     return state.contracts.contracts.isHoardingCreated;
        // });

        this.isHoardingGroupCreated = store.select(state => {
            return state.contracts.contracts.isHoardingGroupCreated;
        });

        // this.isHoardingGroupUpdated = store.select(state => {
        //     return state.contracts.contracts.isHoardingGroupUpdated;
        // });

        this.hoardingGroup = store.select(state => {
            return state.contracts.contracts.hoardingGroup;
        });

        this.isHoardingAddedToGroup = store.select(state => {
            return state.contracts.contracts.isHoardingAddedToGroup;
        });

        this.isHoardingsAddedToContract = store.select(state => {
            return state.contracts.contracts.isHoardingsAddedToContract;
        });

        this.isError = store.select(state => {
            if (state.contracts) {
                return state.contracts.contracts.isError;
            }
        });

        this.hoardingGroup = store.select(state => {
            return state.contracts.contracts.hoardingGroup;
        });

        this.getContractByIdFailedFlag = store.select(state => {
            return state.contracts.contracts.getContractByIdFailedFlag
        })

        this.isHoardingGroupUpdated = store.select(state => {
            return state.contracts.contracts.isHoardingGroupUpdated
        })

        this.updateHoardingGroupError = store.select(state => {
            return state.contracts.contracts.updateHoardingGroupError;
        })
        
        this.updatedContract = store.select(state => {
            return state.contracts.contracts.updatedContract;
        })

        this.isContractUpdated = store.select(state => {
            return state.contracts.contracts.isContractUpdated;
        })

        this.contractById = store.select(state => {
            return state.contracts.contracts.contractById;
        })

        this.hoardingGroupWithImageUpdated = store.select(state => {
            return state.contracts.contracts.hoardingGroupWithImageUpdated;
        })

        this.hoardingWithImageUpdated = store.select(state => {
            return state.contracts.contracts.hoardingWithImageUpdated;
        })

        this.errorCreatingContract = store.select(state => {
            return state.contracts.contracts.errorCreatingContract;
        })
        
        this.errorCreatingContractHoarding = store.select(state => {
            return state.contracts.contracts.errorCreatingContractHoarding;
        })

        this.updateContractFailed = store.select(state => {
            return state.contracts.contracts.updateContractFailed;
        })
    }

    createContract(contract: Contracts) {
        this.store.dispatch(this.contractActions.createContract(contract))
    }

    getStates(areaQueryParams: AreaQueryParams) {
        this.store.dispatch(this.contractActions.getStates(areaQueryParams))
    }

    getDistricts(areaQueryParams) {
        this.store.dispatch(this.contractActions.getDistricts(areaQueryParams))
    }

    resetDistrictList() {
        this.store.dispatch(this.contractActions.resetDistrictsList())
    }

    resetCitiesList() {
        this.store.dispatch(this.contractActions.resetCitiesList())
    }

    resetAreasList() {
        this.store.dispatch(this.contractActions.resetAreasList())
    }

    getCities(areaQueryParams) {
        this.store.dispatch(this.contractActions.getCities(areaQueryParams))
    }

    getAreas(areaQueryParams) {
        this.store.dispatch(this.contractActions.getAreas(areaQueryParams))
    }

    setContract(contractData) {
        this.store.dispatch(this.contractActions.setContract(contractData))
    }

    getContractById(id) {
        this.store.dispatch(this.contractActions.getContractById(id));
    }

    addHoardingsToContract(hoardings) {
        this.store.dispatch(this.contractActions.addHoardingsToContract(hoardings));
    }

    createHoardingGroup(hoardingGroup: any) {
        this.store.dispatch(this.contractActions.createHoardingGroup(hoardingGroup));
    }

    // updateHoardingGroup(hoardingGroup: any) {
    //     this.store.dispatch(this.contractActions.updateHoardingGroup(hoardingGroup));
    // }

    getGroupsByContractId(contractId) {
        this.store.dispatch(this.contractActions.getGroupsByContractId(contractId));
    }

    createHoardingFromContract(hoarding: ContractHoardings) {
        this.store.dispatch(this.contractActions.createHoardingFromContract(hoarding));
    }

    resetContract() {
        this.store.dispatch(this.contractActions.resetContract());
    }

    resetHoarding() {
        this.store.dispatch(this.contractActions.resetHoarding());
    }

    saveGroupHoardings(hoardingGroupSaveObject) {
        this.store.dispatch(this.contractActions.saveGroupHoardings(hoardingGroupSaveObject));
    }

    getGroups() {
        this.store.dispatch(this.contractActions.getGroups());
    }

    resetIsHoardingAddedToGroup() {
        this.store.dispatch(this.contractActions.resetIsHoardingAddedToGroup());
    }

    resetHoardingAddedToContract() {
        this.store.dispatch(this.contractActions.resetHoardingAddedToContract());
    }

    resetError() {
        this.store.dispatch(this.contractActions.resetError());
    }

    getGroupById(groupId) {
        this.store.dispatch(this.contractActions.getGroupById(groupId))
    }

    resetCreateContratFlag() {
        this.store.dispatch(this.contractActions.resetCreateContractFlag())
    }

    resetIsHoardingUpdated() {
        this.store.dispatch(this.contractActions.resetIsHoardingUpdated());
    }

    resetIsHoardingCreated() {
        this.store.dispatch(this.contractActions.resetIsHoardingCreated());
    }

    updateHoardingFromContractModule(hoarding) {
        this.store.dispatch(this.contractActions.updateHoardingFromContractModule(hoarding));
    }

    updateHoardingAfterCreation(hoarding) {
        this.store.dispatch(this.contractActions.updateHoardingAfterCreation(hoarding));
    }

    resetIsHoardingGroupCreated() {
        this.store.dispatch(this.contractActions.resetIsHoardingGroupCreated());
    }

    resetHoardingGroups() {
        this.store.dispatch(this.contractActions.resetHoardingGroups())
    }

    resetContractIdFailedFlag() {
        this.store.dispatch(this.contractActions.resetContractIdFailedFlag())
    }

    updateHoardingGroup(hoardingGroup){
        this.store.dispatch(this.contractActions.updateHoardingGroup(hoardingGroup))
    }

    updateContract(contract){
        this.store.dispatch(this.contractActions.updateContract(contract));
    }

    resetUpdateHoardingGroup() {
        this.store.dispatch(this.contractActions.resetUpdateHoardingGroup())
    }

    resetUpdateHoardingGroupFailure() {
        this.store.dispatch(this.contractActions.resetUpdateHoardingGroupFailure())
    }

    resetIsContractUpated() {
        this.store.dispatch(this.contractActions.resetIsContractUpated())
    }

    updateHoardingGroupWithImage(hoardingGroup) {
        this.store.dispatch(this.contractActions.updateHoardingGroupWithImage(hoardingGroup))
    }
    
    resetUpdateHoardingGroupWithImage() {
        this.store.dispatch(this.contractActions.resetUpdateHoardingGroupWithImage())
    }

    updateHoardingWithImage(hoarding) {
        this.store.dispatch(this.contractActions.updateHoardingWithImage(hoarding))
    }

    resetUpdateHoardingWithImage() {
        this.store.dispatch(this.contractActions.resetUpdateHoardingWithImage())
    }

    resetHoardingGroupFromContract() {
        this.store.dispatch(this.contractActions.resetHoardingGroupFromContract())
    }

    resetContractCreationError() {
        this.store.dispatch(this.contractActions.resetContractCreationError())
    }

    resetUpdateContractFailed() {
        this.store.dispatch(this.contractActions.resetUpdateContractFailed())
    }
}
