

export class ErrorUtil {



    /**
     *  get error object
     * 
     * @static
     * @memberof ErrorUtil
     */
    static getErrorObject(error) {
        var errorObject;
        if (error.error instanceof ArrayBuffer) {
            errorObject = this.checkArrayBufferType(error.error);
        } else if (error.error.message && error.error.code) {
            errorObject = error.error;
        } else {
            errorObject = error;
        }

        return errorObject;
    }

    /**
     * checks the type of array buffer
     *
     * @static
     * @param {*} arrayBuffer
     * @returns
     * @memberof ErrorUtil
     */
    static checkArrayBufferType(arrayBuffer) {
        if (new Int8Array(arrayBuffer).length > 0) {
            return this.extractObjectFromString(this.decodeArrayBufferToString(new Int8Array(arrayBuffer)))
        } else if (new Int16Array(arrayBuffer).length > 0) {
            return this.extractObjectFromString(this.decodeArrayBufferToString(new Int16Array(arrayBuffer)))
        } else if (new Uint8Array(arrayBuffer).length > 0) {
            return this.extractObjectFromString(this.decodeArrayBufferToString(new Uint8Array(arrayBuffer)))
        }
    }



    /**
     * convert array buffer to string
     *
     * @static
     * @param {*} array
     * @returns
     * @memberof ErrorUtil
     */
    static decodeArrayBufferToString(array) {
        return new TextDecoder("utf-8").decode(array);
    }



    /**
     * extracts object from string
     *
     * @static
     * @param {*} str
     * @returns
     * @memberof ErrorUtil
     */
    static extractObjectFromString(str) {
        return JSON.parse(str.substring(str.indexOf('{'), str.lastIndexOf('}') + 1))
    }

}
