import { ChartArea } from "./chart-area/chart-area";
import { ChartLegend } from "./chart-legend/chart-legend";
import { Annotation } from './annotation/annotation';
import { HAxis } from './hAxis/hAxis';
import { VAxis } from './vAxis/vAxis';
import { Bar } from './columnChart.config';
import { TextStyle } from './chart-legend/text-style';

export class StackedBarChartConfig {
    title: string;
    titleTextStyle: TextStyle = new TextStyle();
    isStacked?: boolean;
    bars?: string;
    height?: number | string;
    width?: number | string;
    chartArea?: ChartArea = new ChartArea();
    chartLegend?: ChartLegend = new ChartLegend();
    colors?: string[];
    hAxis: HAxis = new HAxis();
    vAxis: VAxis = new VAxis();
    annotations: Annotation = new Annotation();
    bar?: Bar = new Bar();
    onSelect?: Function;
    series: any;

    // constructor(title: string, isStacked?: boolean, bars?: string, height?: number | string, width?: number | string, chartArea?: ChartArea, chartLegend?: ChartLegend, colors?: string[], hAxis?: HAxis, vAxis?: VAxis, bar?: Bar, annotations?: Annotation) {
    //     this.title = title;
    //     this.isStacked = isStacked;
    //     this.bars = bars;
    //     this.height = height;
    //     this.width = width;
    //     this.chartArea = chartArea;
    //     this.chartLegend = chartLegend;
    //     this.colors = colors;
    //     this.hAxis = hAxis;
    //     this.vAxis = vAxis;
    //     this.bar = bar;
    //     this.annotations = annotations;
    // }
}
