import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { GoogleTimelineChartService } from '../services/shared/timeline-chart.service';
import { SibChartComponent } from '../sib-chart/sib-chart.component';
import { TopHoardingTimelineComponent } from './top-hoarding-timeline/top-hoarding-timeline.component';
import { TopSectionComponent } from './top-section/top-section.component';
import { InventoryCountCardComponent } from './top-section/inventory-count-card/inventory-count-card.component';
import { DashboardService } from './service/dashboard.service';
import { DashboardUiService } from './service/dashboard-ui.service';
import { CustomerCountCardComponent } from './top-section/customer-count-card/customer-count-card.component';
import { PlanCountCardComponent } from './top-section/plan-count-card/plan-count-card.component';
import { CampaignCountCardComponent } from './top-section/campaign-count-card/campaign-count-card.component';
import { UserCountCardComponent } from './top-section/user-count-card/user-count-card.component';
import { TopHoardingCardComponent } from './middle-section/top-hoarding-card/top-hoarding-card.component';
import { MiddleSectionComponent } from './middle-section/middle-section.component';
import { RouterModule } from '@angular/router';
import { SibChartModule } from '../sib-chart/sib-chart.module';
import { BillingChartComponent } from './middle-section/billing-chart/billing-chart.component';
import { BlockConvertCardComponent } from './middle-section/block-convert-card/block-convert-card.component';
import { AutoCompleteModule, MultiSelectModule, ProgressSpinnerModule } from 'primeng/primeng';
import { FormsModule } from '@angular/forms';
import { CityService } from '../masters/city/service/city.service';
import { DirectiveModules } from '../modules/directive.modules';
import { UserPropertiesService } from '../services/shared/user-properties.service';
import { AnalyticsModule } from '../analytics/analytics.module';
import { CapacityChartModule } from '../analytics/capacity-chart/capacity-chart.module';
import { CapacityChartComponent } from '../analytics/capacity-chart/capacity-chart.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SibChartModule,
    AutoCompleteModule,
    FormsModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    DirectiveModules,
    CapacityChartModule
  ],
  declarations: [
    DashboardComponent,
    // SibChartComponent,
    TopHoardingTimelineComponent,
    TopSectionComponent,
    // CapacityChartComponent,
    InventoryCountCardComponent,
    CustomerCountCardComponent,
    PlanCountCardComponent,
    CampaignCountCardComponent,
    UserCountCardComponent,
    MiddleSectionComponent,
    TopHoardingCardComponent,
    BillingChartComponent,
    BlockConvertCardComponent,
  ],
  exports: [
    DashboardComponent,
    TopHoardingTimelineComponent
  ],
  providers: [
    DashboardService,
    DashboardUiService,
    CityService,
    UserPropertiesService
  ]
})
export class DashboardModule { }
