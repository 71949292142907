<div class="top-db-sec-cont">

    <div class="card count-container">
        <sib-inventory-count-card></sib-inventory-count-card>
    </div>
    <div class="card count-container">
        <sib-customer-count-card></sib-customer-count-card>
    </div>
    <div class="card count-container">
        <sib-plan-count-card></sib-plan-count-card>
    </div>
    <div class="card count-container">
        <sib-campaign-count-card></sib-campaign-count-card>
    </div>
    <div class="card count-container">
        <sib-user-count-card></sib-user-count-card>
    </div>
</div>
<!-- Provided permission for capacity chart -->
<div class="capacity-chart" *permission="'view:capacity-chart'"> 
    <sib-capacity-chart [showTitle]="false" [showAction]="false"></sib-capacity-chart>
</div>