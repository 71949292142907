
import { map } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { ApiService, BASE_URL } from "../base-service/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Area } from "../../modals/Area/area";

@Injectable()
export class AreaService extends ApiService<any, any, any> {
    urlPath: string = 'areas/search/byCityId';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    // ?projection=
    // + criteria.projection

    //Raveena | 16-05-2019 Removed projection - Optimization change
    getAreaList(criteria: any) {
        console.log("inside area list get", criteria);
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'areas');

        }
    }

    getAreaByCriteria(criteria: any) {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'areas/search/byName?name=' + criteria.name).pipe(map((response: Response) => {
                return response;
            }));
        }

    }

}