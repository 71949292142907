<div class="card">
  <form [formGroup]="monitoringSitesGroup">
    <sib-grid name="totalCampaignItemgroup" #sibGrid [columns]="columns" [totalRecords]="totalRecords"
      [config]="gridConfig" formControlName="gridControl" (eFilteredValue)="onFilter($event)" (eSort)="onSort($event)"
      ngDefaultControl>
      <sib-grid-column field="srNo">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <span>
            {{rowIndex+1}}
          </span>
        </ng-template>
      </sib-grid-column>
      <sib-grid-column field="lightType">
        <ng-template #filterTemplate pTemplate="filter" let-col="col" let-column="column">
          <p-dropdown [options]="lightTypeDropdown" [style]="{'width':'100%','margin-top':'8px'}" appendTo="body"
            placeholder="Light" (onChange)="sibGrid.filter($event.value,col.field,col.filterMatchMode)">
          </p-dropdown>
        </ng-template>
      </sib-grid-column>
      <sib-grid-column field="monitoringItems.images">
        <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col" let-column="column">
          <a *ngIf="rowData?.monitoringItems?.images?.length > 0" class="link-1  after"
            (click)="viewImages(rowData)">View</a>
          <div *ngIf="rowData?.monitoringItems?.images?.length === 0">-</div>
        </ng-template>
      </sib-grid-column>
    </sib-grid>
  </form>
</div>