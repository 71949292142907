/**
* @file  PrintingPrice
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Customer } from "../KYC/customer";
import { ContractHoardings } from "../contracts/contract-hoarding";
import { PlanHoarding } from "./planHoarding";
import { Price } from "./price";

export class PrintingPrice {

    flNlPrice: number = 0;
    blPrice: number = 0;

}