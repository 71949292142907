import { ActivityLogModuleEnum } from './activity-log-module-enum';
import * as mapUtils from '../../helpers/map.utils';
import * as utils from '../../helpers/utils';

export enum ActionEventEnum {
    CREATE = "Create",
    UPDATE = "Update",
    DELETE = "Delete",
    EXPIRED = "Expired",
    CON_INACTIVE = "Contract Inactive",
    CON_ACTIVE = "Contract Active",
    INV_MIGRATED = "Migrated",
    PLAN_BLOCKED = "Blocked",
    PLAN_UNBLOCK = "Unblocked",
    PLAN_ITEM_REMOVED = "Item Removed",
    CAMP_CONVERT = "Convert to Campaign",
    CAMP_APPROVED = "Approved",
    CAMP_REJECTED = "Rejected",
    CAMP_ROLLBACK = "Rollback to Plan",
    CAMP_PO_UPLOADED = "PO uploaded",
    CAMP_CLONE = "Clone to Plan",
    CAMP_PO_DELETED = "PO deleted",
    CAMP_PO_UPDATED = "PO updated",
    PLAN_CREATED_ON_CLONE = "Plan Copied",
    PLAN_MARKED_ROTATIONAL = "Marked as rotational",
    PLAN_UNMARK_ROTATIONAL = "Unmarked as rotational",
    PLAN_ITEM_ADDED = "Item Added",
    PLAN_CREATED_ON_ROLLBACK = "Plan Created on Campaign RollBack",
    PLAN_CREATED_ON_SHARE = "Plan Shared",
    PLAN_CREATED_ON_CLONE_FROM_CAMPAIGN = "Copied Campaign Plan Created",

    // =====================================================================

    CAMP_SUMMARY_UPDATED_DUE_TO_MOUNTING_ADD = "Summary Updated due to mounting  addition",
    CAMP_SUMMARY_UPDATED_DUE_TO_MOUNTING_REMOVE = "Summary Updated due to mounting  remove",
    CAMP_SUMMARY_UPDATED_DUE_TO_MOUNTING_UPDATE = "Summary Updated due to mounting  update",
    MOB_CAMP_UPDATED = "Campaign Updated From Mobile",
    MOB_CAMP_REMOVED = "Campaign Removed From Mobile",
    MOB_CAMP_APPROVED = "Campaign Approved From Mobile",
    MOB_CAMP_REJECTED = "Rejected From Mobile",

    // =====================================================================

    CI_ADDED = "Item added",
    CI_REMOVED = "Item removed",
    CI_PRINTED = "Item Printed",
    CI_FLEX_RECEIVED = "Item Flex received",
    CI_MOUNTED = "Item Mounted",
    CI_UNMOUNTED = "Item unmounted",
    CI_ADDITIONAL_ADDED = "Additional creative added",
    CI_MONITORING_ADDED = "Monitoring added",
    CI_MONITORING_DONE = "Monitoring done",
    CI_MONITORING_REMOVE = "Monitoring removed",

    // ===================================================================================

    CI_MOUNTER_ASSIGNED = "Mounter Assigned",
    CI_UNMOUNTER_ASSIGNED = "Unmounter Assigned",
    CI_MOUNTING_REMOVED = "Additional Creative Removed",
    CI_MOUNTING_UPDATED = "Mounting updated",
    CI_ROLL_BACK_TO_RECEIVED_FLEX = "Roll back to received flex",
    CI_EXPIRED = "Campaign Site Expired",
    CI_ADJUSTED = "Item Adjusted",
    CI_RATE_APPROVED = "Item Rate Approved",
    CI_RATE_REJECTED = "Item Rate Rejected",
    CI_RATE_UPDATED = "Item Rate Updated",

    // ===================================================================================

    CUS_ACTIVE = "Customer Active",
    CUS_INACTIVE = "Customer Inactive",
    CUS_BLACKLIST = "Blacklisted",
    CUS_REMOVED_FROM_GROUP = "Customer removed from group",
    CUS_ASSIGNED_TO_GROUP = "Customer assigned to group",
    INV_EDIT_APPROVE = "Invoice Edit Pending for Approval",
    INV_GEN_APPROVE = "Approve Generated Invoice",
    INV_GEN_REJECT = "Reject Generated Invoice",
    INV_GEN_ACC_APPROVE = "Approve Generated Invoice by Accountant",
    INV_GEN_ACC_REJECT = "Approve Generated Invoice by Accountant",
    INV_CANCEL_REQUEST = "Cancel Request raised",
    INV_ADMIN_EDIT_APPROVE = "Invoice edit approved by Admin",
    INV_ADMIN_EDIT_REJECT = "Invoice edit rejected by Admin",
    INV_ACC_EDIT_REJECT = "Invoice edit rejected by accountant",
    INV_ACC_EDIT_APPROVE = "Invoice edit approved by accountant",
    INV_APPROVE_CANCEL = "Invoice cancel request approved",
    INV_REJECT_CANCEL = "Invoice cancel request rejected",
    INV_ACC_APPROVE_CANCEL = "Invoice cancel request approved by Accountant",
    INV_ACC_REJECT_CANCEL = "Invoice cancel request rejected by Accountant",
    INV_DISCARD_EDIT = "Invoice edit discarded.",
    INV_PRINT_MARKED = "Invoice marked as printed.",
    INV_TALLY_ENTERED = "Invoice tally ID entered.",
    INV_CUSTOMER_REVIEWED = "Invoice reviewed by customer.",
    INV_PRINT_PENDING_MARKED = "Invoice marked as print pending.",
}

export enum ActivityLogCommonActionEventEnum {
    CREATE = "Create",
    UPDATE = "Update",
    // DELETE = "Delete",
    // EXPIRED = "Expired",
}

export enum ActivityLogDeleteActionEventEnum {
    DELETE = "Delete",
}

export enum ActivityLogExpireActionEventEnum {
    EXPIRED = "Expired",
}

export enum ActivityLogContractActionEventEnum {
    CON_INACTIVE = "Contract Inactive",
    CON_ACTIVE = "Contract Active",
    // EXPIRED = "Expired",
}

export enum ActivityLogInventoryActionEventEnum {
    INV_MIGRATED = "Migrated",
    // EXPIRED = "Expired",
}

export enum ActivityLogPlanActionEventEnum {
    PLAN_BLOCKED = "Blocked",
    PLAN_UNBLOCK = "Unblocked",
    PLAN_ITEM_REMOVED = "Item Removed",
    PLAN_ITEM_ADDED = "Item Added",
    PLAN_CREATED_ON_ROLLBACK = "Plan Created on Campaign RollBack",
    PLAN_CREATED_ON_CLONE = "Cloned Campaign Plan Created",
    PLAN_MARKED_ROTATIONAL = "Marked as rotational",
    PLAN_UNMARK_ROTATIONAL = "Unmarked as rotational",
    // EXPIRED = "Expired",
    // DELETE = "Delete",
}

export enum ActivityLogCampaignActionEventEnum {
    CAMP_CONVERT = "Convert to Campaign",
    CAMP_APPROVED = "Approved",
    CAMP_REJECTED = "Rejected",
    CAMP_ROLLBACK = "Rollback to Plan",
    CAMP_CLONE = "Clone to Plan",
    CAMP_SUMMARY_UPDATED_DUE_TO_MOUNTING_ADD = "Summary Updated due to mounting  addition",
    CAMP_SUMMARY_UPDATED_DUE_TO_MOUNTING_REMOVE = "Summary Updated due to mounting  remove",
    CAMP_SUMMARY_UPDATED_DUE_TO_MOUNTING_UPDATE = "Summary Updated due to mounting  update",
    CAMP_PO_UPLOADED = "PO uploaded",
    CAMP_PO_DELETED = "PO deleted",
    CAMP_PO_UPDATED = "PO updated",

    // EXPIRED = "Expired",
    // DELETE = "Delete",
}

export enum ActivityLogCampaignItemActionEventEnum {
    CI_ADDED = "Item added",
    CI_REMOVED = "Item removed",
    // CI_PRINTED = "Item Printed", // will be used when printer module is integrated
    CI_FLEX_RECEIVED = "Item Flex received",
    CI_MOUNTED = "Item Mounted",
    CI_UNMOUNTED = "Item unmounted",
    CI_ADDITIONAL_ADDED = "Additional creative added",
    CI_MONITORING_ADDED = "Monitoring added",
    CI_MONITORING_DONE = "Monitoring done",
    CI_MONITORING_REMOVE = "Monitoring removed",
    CI_MOUNTER_ASSIGNED = "Mounter Assigned",
    CI_UNMOUNTER_ASSIGNED = "Unmounter Assigned",
    CI_MOUNTING_REMOVED = "Mounting Removed",
    CI_MOUNTING_UPDATED = "Mounting updated",
    CI_ROLL_BACK_TO_RECEIVED_FLEX = "Roll back to received flex",
    CI_EXPIRED = "Campaign Site Expired",
    CI_RATE_APPROVED = "Item Rate Approved",
    CI_RATE_REJECTED = "Item Rate Rejected",
    CI_RATE_UPDATED = "Item Rate Updated"
    // EXPIRED = "Expired",
    // DELETE = "Delete",
}

export enum ActivityLogCustomerActionEventEnum {
    CUS_ACTIVE = "Customer Active",
    CUS_INACTIVE = "Customer Inactive",
    CUS_BLACKLIST = "Blacklisted",
}

export enum ActivityLogCustomerGroupActionEventEnum {
    CUS_REMOVED_FROM_GROUP = "Customer removed from group",
    CUS_ASSIGNED_TO_GROUP = "Customer assigned to group",
}

export enum ActivityLogInvoiceActionEventEnum {
    INV_EDIT_APPROVE = "Invoice Edit Pending for Approval",
    INV_GEN_APPROVE = "Approve Generated Invoice",
    INV_GEN_REJECT = "Approve Generated Invoice",
    INV_GEN_ACC_APPROVE = "Approve Generated Invoice by Accountant",
    INV_GEN_ACC_REJECT = "Generated Invoice rejected by Accountant",
    INV_CANCEL_REQUEST = "Cancel Requst raised",
    INV_ADMIN_EDIT_APPROVE = "Invoice edit approved by Admin",
    INV_ADMIN_EDIT_REJECT = "Invoice edit rejected by Admin",
    INV_ACC_EDIT_REJECT = "Invoice edit rejected by accountant",
    INV_ACC_EDIT_APPROVE = "Invoice edit approved by accountant",
    INV_APPROVE_CANCEL = "Invoice cancel request approved",
    INV_REJECT_CANCEL = "Invoice cancel request rejected",
    INV_ACC_APPROVE_CANCEL = "Invoice cancel request approved by Accountant",
    INV_ACC_REJECT_CANCEL = "Invoice cancel request rejected by Accountant",
    INV_DISCARD_EDIT = "Invoice edit discarded.",
    INV_PRINT_MARKED = "Invoice marked as printed.",
    INV_TALLY_ENTERED = "Invoice tally ID entered.",
    INV_CUSTOMER_REVIEWED = "Invoice reviewed by customer.",
    INV_PRINT_PENDING_MARKED = "Invoice marked as print pending.",
    // DELETE = "Delete",
}

export enum ActivityLogConflictActionEventEnum {
    CI_REMOVED = "Item removed",
    CI_ADJUSTED = "Item Adjusted",
}

// export function createModuleToActivityEnumMap(module) {
//     return mapUtils.createModuleToActivityActionEventEnumMap(module);
// }

export function createModuleToActivityEnumDropdown(module) {
    let dropdown: any[] = [];
    switch (ActivityLogModuleEnum[module]) {
        case ActivityLogModuleEnum.AREA:
        case ActivityLogModuleEnum.CITY:
        case ActivityLogModuleEnum.CONTRACT_TYPE:
        case ActivityLogModuleEnum.DISTRICT:
        // case ActivityLogModuleEnum.GROUP:
        case ActivityLogModuleEnum.POINT_OF_CONTACT: {
            dropdown = utils.createDropdown(ActivityLogCommonActionEventEnum);
            break;
        }
        case ActivityLogModuleEnum.CONTRACT: {
            dropdown = [...utils.createDropdown(ActivityLogContractActionEventEnum), ...utils.createDropdown(ActivityLogExpireActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.CUSTOMER: {
            dropdown = [...utils.createDropdown(ActivityLogCommonActionEventEnum), ...utils.createDropdown(ActivityLogCustomerActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.HOARDING:
        case ActivityLogModuleEnum.HOARDING_GROUP:
        case ActivityLogModuleEnum.KIOSK: {
            dropdown = [...utils.createDropdown(ActivityLogCommonActionEventEnum), ...utils.createDropdown(ActivityLogInventoryActionEventEnum, false), ...utils.createDropdown(ActivityLogExpireActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.PLAN: {
            dropdown = [...utils.createDropdown(ActivityLogCommonActionEventEnum), ...utils.createDropdown(ActivityLogPlanActionEventEnum, false), ...utils.createDropdown(ActivityLogExpireActionEventEnum, false), ...utils.createDropdown(ActivityLogDeleteActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.CAMPAIGN: {
            dropdown = [...utils.createDropdown(ActivityLogCommonActionEventEnum), ...utils.createDropdown(ActivityLogCampaignActionEventEnum, false), ...utils.createDropdown(ActivityLogExpireActionEventEnum, false), ...utils.createDropdown(ActivityLogDeleteActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.CAMPAIGN_ITEM: {
            dropdown = [...utils.createDropdown(ActivityLogCommonActionEventEnum), ...utils.createDropdown(ActivityLogCampaignItemActionEventEnum, false), ...utils.createDropdown(ActivityLogDeleteActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.INVOICE: {
            dropdown = [...utils.createDropdown(ActivityLogCommonActionEventEnum), ...utils.createDropdown(ActivityLogInvoiceActionEventEnum, false), ...utils.createDropdown(ActivityLogDeleteActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.GROUP: {
            dropdown = [...utils.createDropdown(ActivityLogCommonActionEventEnum), ...utils.createDropdown(ActivityLogCustomerGroupActionEventEnum, false)];
            break;
        }
        case ActivityLogModuleEnum.CAMPAIGN_ITEM_CONFLICT: {
            dropdown = utils.createDropdown(ActivityLogConflictActionEventEnum);
            break;
        }
        // case ActivityLogModuleEnum.CI_FLEX: {
        //     break;
        // }
        // case ActivityLogModuleEnum.CI_MONITORING: {
        //     break;
        // }
        // case ActivityLogModuleEnum.CI_MOUNTING: {
        //     break;
        // }
        // case ActivityLogModuleEnum.CI_UNMOUNTING: {
        //     break;
        // }
        default: {
            dropdown = utils.createDropdown(ActionEventEnum);
            break;
        }
    }
    return dropdown;
}
