import { SibBaseObject } from '../BaseObject/SibBaseObject';
import { TaskTypeEnum } from '../../shared/constants/task-type-enum';
import { ModuleMetaData } from './module-meta-data';
import { TaskResponsiblePerson } from './task-responsible-person';
import { TaskStatus } from '../../shared/constants/task-status.enum';
import { TaskName } from '../../shared/constants/task-name-enum';

export class Task extends SibBaseObject {
    name: TaskName;
    description: string;
    data: any;
    taskType: TaskTypeEnum;
    moduleData: ModuleMetaData = new ModuleMetaData();
    valid: boolean;
    responsiblePerson: TaskResponsiblePerson = new TaskResponsiblePerson();
    useful: boolean;
    status: TaskStatus;
}