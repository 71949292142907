import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { User } from '../../modals/user/user';
import { MenuItem } from 'primeng/primeng';
import { EmailConfigService } from '../../services/shared/email-config.service';
import { AuthService } from '../../login-service/auth.service';

@Component({
  selector: 'sib-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit, AfterViewInit {

  /**
   * toggling display and hide of dialog 
   * 
   * @type {boolean}
   * @memberof UserDetailsComponent
   */
  @Input() display: boolean = false;

  /**
   * user object
   * 
   * @type {User}
   * @memberof UserDetailsComponent
   */
  @Input() user: User = new User();

  @Input() isloading: boolean = false;

  /**
   * Emitting the save Event
   * 
   * @type {EventEmitter<User>}
   * @memberof UserDetailsComponent
   */
  @Output() eSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() eEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  smtpEdit: boolean = false;
  stepperItems: MenuItem[];
  imageName: string = 'assets/layout/images/customer-image.png';
  activeIndex: number = 0;
  emailSelected: any;
  isUserEmailVerified: boolean = false;
  confirmPasswordValue: string;
  sibConfirmPassword: string;
  sibPassword: string;
  sibPasswordVerified: boolean = false;
  organizationEmail: string;
  emailConfigPassword: string;

  constructor(
    private emailConfigService: EmailConfigService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.stepperItems = [
      { label: "Verify Information" },
      { label: "System credentials" },
      { label: "Your Email Setup" }
    ]

    this.emailConfigService.get(null, "/search/emailConfig")
      .subscribe((emailConfig) => {
        this.organizationEmail = emailConfig['data']['username'];
      })
  }


  ngAfterViewInit() {
    if (this.user.organizationEmail) {
      this.emailSelected = 'orgEmail'
    } else {
      this.emailSelected = 'userEmail'
    }
  }

  saveUser(user) {
    if (this.emailSelected === 'orgEmail') {
      user.organizationEmail = true;
    } else {
      user.organizationEmail = false;
      user.emailConfig.username = this.user.emailId;
      user.emailConfig.password = this.emailConfigPassword
    }

    this.isloading = true;
    this.eSave.emit({ user: user, password: this.sibPassword })
  }

  onShowDialog() {
  }

  onHideDialog() {
    this.isloading = false;
    this.eEmit.emit(false);
  }

  onNextClick() {
    this.activeIndex = this.activeIndex + 1;
  }

  onPreviousClick() {
    this.activeIndex = this.activeIndex - 1;
  }

  verifyUserPassword(value) {
    if (value === this.emailConfigPassword) {
      this.isUserEmailVerified = true;
    } else {
      this.isUserEmailVerified = false;
    }
  }

  verifySibPassword() {
    if (this.sibPassword === this.sibConfirmPassword) {
      this.sibPasswordVerified = true;
    } else {
      this.sibPasswordVerified = false;
    }
  }

  logout() {
    this.auth.logout();
  }
}
