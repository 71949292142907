<section class="print-pannel-layout">
    <span class="printing-header">Printing Options</span>
    <section class="invoice-type-dropdown" *ngIf="showInvoiceFormat">
        <label class="font-12 print-invoice-type">Format
            <span class="required">* </span>
        </label>
        <p-dropdown [options]="invoiceFormats" [autoWidth]="false" [style]="{'width':'235px'}" name="invoiceFormat"
            [styleClass]="'print-pannel-dropdown'" placeholder="Select an Invoice Format" filter="true"
            (onChange)="onInvoiceFormatChange()" [(ngModel)]="selectedInvoiceFormat" [autoDisplayFirst]="false"
            [disabled]="disableInvoiceFormatDropdown" required>
        </p-dropdown>
    </section>
    <section class="detail-options">
        <span class="print-original"> Duplicate</span>
        <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'" [(ngModel)]="printConfig.original"
            (onChange)="onTypeChange($event)">
        </p-inputSwitch>
        <span class="print-options">Original</span>
    </section>
    <section *permission="'print:invoice-letterhead-option'" class="detail-options">
        <span class="print-options">W/O LetterHead</span>
        <p-inputSwitch name="printingOptions" [styleClass]="'print-options-switch'"
            [(ngModel)]="printConfig.withLetterHead" (onChange)="onLetterHeadChange($event)">
        </p-inputSwitch>
        <span class="print-options">With LetterHead</span>
    </section>
    <section class="print-button">
        <button *ngIf="((userRole === 'Sales Rep' || userRole === 'Admin') && showSave)"
            class="mtab-primary margin-hori-5" pButton label="SAVE" (click)="savePrintingInstructions()"
            [disabled]="!selectedInvoiceFormat"></button>
        <button *ngIf="showPreview" class="mtab-primary margin-hori-5" pButton label="PREVIEW" (click)="previewItems()"
            [disabled]="!selectedInvoiceFormat"></button>
        <button *ngIf="showPrint" class="mtab-primary" pButton label="PRINT" (click)="printItems()"
            [disabled]="!selectedInvoiceFormat"></button>
    </section>
</section>