import { Injectable } from "@angular/core";
import { ActionExtended } from "../../shared/actionextended";
import 'rxjs/Rx';

@Injectable()
export class ContractActions {

    static CREATE_CONTRACT = 'CREATE_CONTRACT';
    static CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS'
    static GET_STATES_CONTRACT = 'GET_STATES_CONTRACT';
    static GET_STATES_SUCCESS_CONTRACT = 'GET_STATES_SUCCESS_CONTRACT';
    static GET_DISTRICTS_CONTRACT = 'GET_DISTRICTS_CONTRACT';
    static GET_DISTRICTS_SUCCESS_CONTRACT = 'GET_DISTRICTS_SUCCESS_CONTRACT';
    static RESET_DISTRICT_LIST_CONTRACT = 'RESET_DISTRICT_LIST_CONTRACT';
    static RESET_CITIES_LIST_CONTRACT = 'RESET_CITIES_LIST_CONTRACT';
    static GET_CITIES_CONTRACT = 'GET_CITIES_CONTRACT';
    static GET_CITIES_SUCCESS_CONTRACT = 'GET_CITIES_SUCCESS_CONTRACT';
    static GET_AREAS_CONTRACT = 'GET_AREAS_CONTRACT';
    static GET_AREAS_SUCCESS_CONTRACT = 'GET_AREAS_SUCCESS_CONTRACT';
    static SET_CONTRACT = 'SET_CONTRACT';
    static RESET_AREAS_LIST_CONTRACT = 'RESET_AREAS_LIST_CONTRACT';
    static GET_CONTRACT_BY_ID = 'GET_CONTRACT_BY_ID';
    static GET_CONTRACT_BY_ID_SUCCESS = 'GET_CONTRACT_BY_ID_SUCCESS';
    static ADD_HOARDINGS_CONTRACT = 'ADD_HOARDINGS_CONTRACT';
    static ADD_HOARDINGS_CONTRACT_SUCCESS = 'ADD_HOARDINGS_CONTRACT_SUCCESS';
    static CREATE_HOARDING_GROUP = 'CREATE_HOARDING_GROUP';
    static CREATE_HOARDING_GROUP_SUCCESS = 'CREATE_HOARDING_GROUP_SUCCESS';
    static UPDATE_HOARDING_GROUP = 'UPDATE_HOARDING_GROUP';
    // static UPDATE_HOARDING_GROUP_SUCCESS = 'UPDATE_HOARDING_GROUP_SUCCESS';
    // static UPDATE_HOARDING_GROUP_FAILURE = 'UPDATE_HOARDING_GROUP_FAILURE';
    static GET_GROUPS_BY_CONTRACTID = 'GET_GROUPS_BY_CONTRACTID';
    static GET_GROUPS_BY_CONTRACTID_SUCCESS = 'GET_GROUPS_BY_CONTRACTID_SUCCESS';
    static CREATE_HOARDING_FROM_CONTRACT = 'CREATE_HOARDING_FROM_CONTRACT';
    static CREATE_HOARDING_FROM_CONTRACT_SUCCESS = 'CREATE_HOARDING_FROM_CONTRACT_SUCCESS';
    static CREATE_HOARDING_FAILURE = 'CREATE_HOARDING_FAILURE';
    static RESET_HOARDING = 'RESET_HOARDING';
    // static RESET_HOARDING_SUCCESS = 'RESET_HOARDING_SUCCESS';
    static RESET_CONTRACT = 'RESET_CONTRACT';
    static SAVE_GROUP_HOARDINGS = 'SAVE_GROUP_HOARDINGS';
    static SAVE_GROUP_HOARDINGS_SUCCESS = 'SAVE_GROUP_HOARDINGS_SUCCESS';
    static GET_GROUPS = 'GET_GROUPS';
    static GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
    static RESET_HOARDING_ADDED_TO_GROUP = 'RESET_HOARDING_ADDED_TO_GROUP';
    static RESET_HOARDING_ADDED_TO_CONTRACT = 'RESET_HOARDING_ADDED_TO_CONTRACT';
    static RESET_ERROR = 'RESET_ERROR';
    static GROUP_CREATION_FAILED = 'GROUP_CREATION_FAILED';
    static GET_GROUP_BY_ID = 'GET_GROUP_BY_ID';
    static GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS';
    static RESET_CREATE_CONTRACT_FLAG = 'RESET_CREATE_CONTRACT_FLAG';
    static UPDATE_HOARDING = 'UPDATE_HOARDING';
    static UPDATE_HOARDING_AFTER_CREATION = 'UPDATE_HOARDING_AFTER_CREATION';
    static UPDATE_CONTRACT_HOARDING_SUCCESS = '[ContractActions] UPDATE_CONTRACT_HOARDING_SUCCESS';
    static UPDATE_HOARDING_FAILURE = '[ContractActions] UPDATE_HOARDING_FAILURE';
    static RESET_IS_HOARDING_UPDATED = 'RESET_IS_HOARDING_UPDATED';
    static RESET_IS_HOARDING_CREATED = 'RESET_IS_HOARDING_CREATED';
    static RESET_IS_HOARDING_GROUP_CREATED = 'RESET_IS_HOARDING_GROUP_CREATED';
    static RESET_HOARDING_GROUPS = 'RESET_HOARDING_GROUPS';
    static GET_CONTRACT_BY_ID_FAILED = 'GET_CONTRACT_BY_ID_FAILED';
    static RESET_CONTRACT_BY_ID_FAILED_FLAG = 'RESET_CONTRACT_BY_ID_FAILED_FLAG';
    static UPDATE_CONTRACT = 'UPDATE_CONTRACT';
    static UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
    static UPDATE_HOARDING_GROUP_SUCCESS = 'UPDATE_HOARDING_GROUP_SUCCESS';
    static RESET_UPDATE_HOARDING_GROUP = 'RESET_UPDATE_HOARDING_GROUP';
    static RESET_UPDATE_HOARDING_GROUP_ERROR = 'RESET_UPDATE_HOARDING_GROUP_ERROR';
    static UPDATE_HOARDING_GROUP_ERROR = 'UPDATE_HOARDING_GROUP_ERROR';
    static RESET_IS_CONTRACT_UPDATED = 'RESET_IS_CONTRACT_UPDATED';
    static UPDATE_HOARDING_GROUP_WITH_IMAGE = 'UPDATE_HOARDING_GROUP_WITH_IMAGE';
    static UPDATE_HOARDING_GROUP_WITH_IMAGE_SUCCESS = 'UPDATE_HOARDING_GROUP_WITH_IMAGE_SUCCESS';
    static RESET_UPDATE_HOARDING_GROUP_WITH_IMAGE = 'RESET_UPDATE_HOARDING_GROUP_WITH_IMAGE';
    static UPDATE_HOARDING_WITH_IMAGE = 'UPDATE_HOARDING_WITH_IMAGE';
    static UPDATE_HOARDING_WITH_IMAGE_SUCCESS = 'UPDATE_HOARDING_WITH_IMAGE_SUCCESS';
    static RESET_UPDATE_HOARDING_WITH_IMAGE = 'RESET_UPDATE_HOARDING_WITH_IMAGE';
    static RESET_HOARDING_GROUP_FROM_CONTRACT = 'RESET_HOARDING_GROUP_FROM_CONTRACT';
    static CONTRACT_CREATION_ERROR = 'CONTRACT_CREATION_ERROR';
    static RESET_CONTRACT_CREATION_ERROR = 'RESET_CONTRACT_CREATION_ERROR';
    static UPDATE_CONTRACT_FAILED = 'UPDATE_CONTRACT_FAILED';
    static RESET_UPDATE_CONTRACT_FAILED = 'RESET_UPDATE_CONTRACT_FAILED';
    // static RESET_IS_HOARDING_GROUP_UPDATED = 'RESET_IS_HOARDING_GROUP_UPDATED';

    createContract(contract): ActionExtended {
        return {
            type: ContractActions.CREATE_CONTRACT,
            payload: contract
        };
    }

    createContractSuccess(contract): ActionExtended {
        return {
            type: ContractActions.CREATE_CONTRACT_SUCCESS,
            payload: contract
        };
    }

    getStates(areaQueryParams): ActionExtended{
        return {
            type: ContractActions.GET_STATES_CONTRACT,
            payload: areaQueryParams
        };
    }

    getStatesSuccess(states): ActionExtended{
        return {
            type: ContractActions.GET_STATES_SUCCESS_CONTRACT,
            payload: states
        };
    }

    getDistricts(areaQueryParams): ActionExtended {
        return {
            type: ContractActions.GET_DISTRICTS_CONTRACT,
            payload: areaQueryParams
        };
    }

    getDistrictsSuccess(districts): ActionExtended {
        return {
            type: ContractActions.GET_DISTRICTS_SUCCESS_CONTRACT,
            payload: districts
        };
    }

    resetDistrictsList(): ActionExtended {
        return {
            type: ContractActions.RESET_DISTRICT_LIST_CONTRACT
        };
    }

    resetCitiesList(): ActionExtended {
        return {
            type: ContractActions.RESET_CITIES_LIST_CONTRACT
        };
    }

    getCities(areaQueryParams): ActionExtended {
        return {
            type: ContractActions.GET_CITIES_CONTRACT,
            payload: areaQueryParams
        };
    }

    getCitiesSuccess(cities): ActionExtended {
        return {
            type: ContractActions.GET_CITIES_SUCCESS_CONTRACT,
            payload: cities
        };
    }

    getAreas(areaQueryParams) {
        return {
            type: ContractActions.GET_AREAS_CONTRACT,
            payload: areaQueryParams
        };
    }

    getAreasSuccess(areas) {
        return {
            type: ContractActions.GET_AREAS_SUCCESS_CONTRACT,
            payload: areas
        };
    }

    setContract(contractData) {
        return {
            type: ContractActions.SET_CONTRACT,
            payload: contractData
        };
    }

    resetAreasList() {
        return {
            type: ContractActions.RESET_AREAS_LIST_CONTRACT
        };
    }

    getContractById(id): ActionExtended {
        return {
            type: ContractActions.GET_CONTRACT_BY_ID,
            payload: id
        };
    }

    getContractByIdSuccess(contract): ActionExtended {
        return {
            type: ContractActions.GET_CONTRACT_BY_ID_SUCCESS,
            payload: contract
        };
    }

    addHoardingsToContract(hoardings): ActionExtended {
        return {
            type: ContractActions.ADD_HOARDINGS_CONTRACT,
            payload: hoardings
        };
    }

    addHoardingsToContractSuccess(): ActionExtended {
        return {
            type: ContractActions.ADD_HOARDINGS_CONTRACT_SUCCESS,
        };
    }

    createHoardingGroup(hoardingGroup): ActionExtended {
        return {
            type: ContractActions.CREATE_HOARDING_GROUP,
            payload: hoardingGroup
        };
    }

    createHoardingGroupSuccess(response): ActionExtended {
        return {
            type: ContractActions.CREATE_HOARDING_GROUP_SUCCESS,
            payload: response
        };
    }

    getGroupsByContractId(contractId): ActionExtended {
        return {
            type: ContractActions.GET_GROUPS_BY_CONTRACTID,
            payload: contractId
        };
    }

    getGroupsByContractIdSuccess(groups): ActionExtended {
        return {
            type: ContractActions.GET_GROUPS_BY_CONTRACTID_SUCCESS,
            payload: groups
        }
    }

    createHoardingFromContract(hoarding): ActionExtended {
        return {
            type: ContractActions.CREATE_HOARDING_FROM_CONTRACT,
            payload: hoarding
        };
    }

    createHoardingFromContractSuccess(hoarding): ActionExtended {
        return {
            type: ContractActions.CREATE_HOARDING_FROM_CONTRACT_SUCCESS,
            payload: hoarding
        };
    }

    createHoardingFailure(): ActionExtended {
        return {
            type: ContractActions.CREATE_HOARDING_FAILURE
        };
    }

    resetHoarding(): ActionExtended {
        return {
            type: ContractActions.RESET_HOARDING,
        };
    }

    resetContract(): ActionExtended {
        return {
            type: ContractActions.RESET_CONTRACT,
        };
    }

    saveGroupHoardings(hoardingGroupSaveObject) {
        return {
            type: ContractActions.SAVE_GROUP_HOARDINGS,
            payload: hoardingGroupSaveObject
        };
    }

    saveGroupHoardingsSuccess(response) {
        return {
            type: ContractActions.SAVE_GROUP_HOARDINGS_SUCCESS,
            payload: response
        };
    }
    
    getGroups(): ActionExtended {
        return {
            type: ContractActions.GET_GROUPS
            // payload: 'test'
        };
    }

    getGroupsSuccess(groups): ActionExtended {
        return {
            type: ContractActions.GET_GROUPS_SUCCESS,
            payload: groups
        };
    }

    resetIsHoardingAddedToGroup() {
        return {
            type: ContractActions.RESET_HOARDING_ADDED_TO_GROUP,
        };
    }

    resetHoardingAddedToContract() {
        return {
            type: ContractActions.RESET_HOARDING_ADDED_TO_CONTRACT,
        }
    }

    resetError(): ActionExtended {
        return {
            type: ContractActions.RESET_ERROR
        };
    }

    createGroupFailure(): ActionExtended {
        return {
            type: ContractActions.GROUP_CREATION_FAILED
        }
    }

    getGroupById(groupId): ActionExtended {
        return {
            type: ContractActions.GET_GROUP_BY_ID,
            payload: groupId
        }
    }

    getGroupByIdSuccess(group): ActionExtended {
        return {
            type: ContractActions.GET_GROUP_BY_ID_SUCCESS,
            payload: group
        }
    }

    resetCreateContractFlag(): ActionExtended {
        return {
            type: ContractActions.RESET_CREATE_CONTRACT_FLAG
        }
    }

    updateHoardingFromContractModule(hoarding): ActionExtended {
        return {
            type: ContractActions.UPDATE_HOARDING,
            payload: hoarding
        };
    }

    updateHoardingAfterCreation(hoarding): ActionExtended {
        return {
            type: ContractActions.UPDATE_HOARDING_AFTER_CREATION,
            payload: hoarding
        };
    }

    updateHoardingFromContractSuccess(hoarding): ActionExtended {
        return {
            type: ContractActions.UPDATE_CONTRACT_HOARDING_SUCCESS,
            payload: hoarding
        };
    }

    updateHoardingFailure(): ActionExtended {
        return {
            type: ContractActions.UPDATE_HOARDING_FAILURE
        };
    }

    resetIsHoardingUpdated(): ActionExtended {
        return {
            type: ContractActions.RESET_IS_HOARDING_UPDATED
        }
    }

    resetIsHoardingCreated(): ActionExtended {
        return {
            type: ContractActions.RESET_IS_HOARDING_CREATED
        }
    }

    resetIsHoardingGroupCreated(): ActionExtended {
        return {
            type: ContractActions.RESET_IS_HOARDING_GROUP_CREATED
        }
    }

    resetHoardingGroups():ActionExtended {
        return {
            type:ContractActions.RESET_HOARDING_GROUPS
        }
    }

    getContractByIdFailed() {
        return {
            type: ContractActions.GET_CONTRACT_BY_ID_FAILED
        }
    }

    resetContractIdFailedFlag() {
        return {
            type: ContractActions.RESET_CONTRACT_BY_ID_FAILED_FLAG
        }
    }

    updateHoardingGroup(hoardingGroup) {
        return {
            type: ContractActions.UPDATE_HOARDING_GROUP,
            payload: hoardingGroup
        }
    }

    updateHoardingGroupSuccess(hoardingGroup) {
        return {
            type:ContractActions.UPDATE_HOARDING_GROUP_SUCCESS,
            payload: hoardingGroup
        }
    }

    resetUpdateHoardingGroup() {
        return {
            type:ContractActions.RESET_UPDATE_HOARDING_GROUP
        }
    }

    updateContract(contract):ActionExtended {
        return {
            type:ContractActions.UPDATE_CONTRACT,
            payload:contract
        }
    }

    updateContractSuccess(contract) {
        return {
            type: ContractActions.UPDATE_CONTRACT_SUCCESS
        }
    }

    updateHoardingGroupFailure() {
        return {
            type: ContractActions.UPDATE_HOARDING_GROUP_ERROR
        }
    }

    resetUpdateHoardingGroupFailure() {
        return {
            type: ContractActions.RESET_UPDATE_HOARDING_GROUP_ERROR
        }
    }
    
    resetIsContractUpated() {
        return {
            type: ContractActions.RESET_IS_CONTRACT_UPDATED
        }
    }

    updateHoardingGroupWithImage(hoardingGroup) {
        return {
            type: ContractActions.UPDATE_HOARDING_GROUP_WITH_IMAGE,
            payload: hoardingGroup
        }
    }

    updateHoardingGroupWithImageSuccess(hoardingGroup) {
        return {
            type: ContractActions.UPDATE_HOARDING_GROUP_WITH_IMAGE_SUCCESS
        }
    }

    resetUpdateHoardingGroupWithImage() {
        return {
            type: ContractActions.RESET_UPDATE_HOARDING_GROUP_WITH_IMAGE
        }
    }

    updateHoardingWithImage(hoarding) {
        return {
            type: ContractActions.UPDATE_HOARDING_WITH_IMAGE,
            payload: hoarding
        }
    }

    updateHoardingWithImageSuccess(hoarding) {
        return {
            type: ContractActions.UPDATE_HOARDING_WITH_IMAGE_SUCCESS
        }
    }

    resetUpdateHoardingWithImage() {
        return {
            type: ContractActions.RESET_UPDATE_HOARDING_WITH_IMAGE
        }
    }

    resetHoardingGroupFromContract() {
        return {
            type: ContractActions.RESET_HOARDING_GROUP_FROM_CONTRACT
        }
    }

    contractCreationError() {
        return {
            type: ContractActions.CONTRACT_CREATION_ERROR
        }
    }

    resetContractCreationError() {
        return {
            type: ContractActions.RESET_CONTRACT_CREATION_ERROR
        }
    }

    updateContractFail() {
        return {
            type: ContractActions.UPDATE_CONTRACT_FAILED
        }
    }

    resetUpdateContractFailed() {
        return {
            type: ContractActions.RESET_UPDATE_CONTRACT_FAILED
        }
    }
    
}
