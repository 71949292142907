import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskTypeEnum } from '../../constants/task-type-enum';
import { TaskName } from '../../constants/task-name-enum';

@Component({
  selector: 'sib-pending-tasks-cards',
  templateUrl: './pending-tasks-cards.component.html',
  styleUrls: ['./pending-tasks-cards.component.scss']
})
export class PendingTasksCardsComponent implements OnInit {

  _tasksCards: any[] = [];
  @Input() set tasksCards(tasksCards) {
    this._tasksCards = tasksCards;
    if (this.tasksCards && this.tasksCards.length > 0) {
      // if (!this.selectedFilters) {
      this.filterData(this.tasksCards[0]);
      // }
    }
  }

  get tasksCards() {
    return this._tasksCards
  }

  @Output() eEmitSelectedTask: EventEmitter<any> = new EventEmitter();
  @Output() eEmitModuleName: EventEmitter<any> = new EventEmitter();
  taskType: TaskTypeEnum;

  constructor() { }

  ngOnInit() {
  }

  filterData(card) {
    this.eEmitSelectedTask.emit(card);
    this.eEmitModuleName.emit(card.moduleName);
  }

  setTaskType(task) {
    if (task.taskType === "FOLLOW_UP") {
      return "F";
    } else if (task.taskType === "MANDATORY") {
      return "M";
    } else if (task.taskType === "REMINDER") {
      return "R";
    } else {

    }
  }

  checkForTaskType(task) {
    if (task.taskType === "FOLLOW_UP") {
      this.taskType = TaskTypeEnum.FOLLOW_UP
    } else if (task.taskType === "MANDATORY") {
      this.taskType = TaskTypeEnum.MANDATORY
    } else if (task.taskType === "REMINDER") {
      this.taskType = TaskTypeEnum.REMINDER
    } else {

    }
  }

  setTaskName(taskName) {
    return TaskName[taskName];
  }

}
