import { Picture } from "../hoardings/picture";

export class ImageDataDisplay {
    image: Picture;
    city: any;
    location: any;
    size: any;
    displayName?: any;
    hid: any;
    imageType?: any;
    campaignId?: any;
    employeeEmail?: string;

    getImageData(data, image) {

        this.image = image;
        this.city = data.city ? data.city : data.area.city.name;
        this.location = data.location;
        this.hid = data.customId;
        this.size = data.size;
        if (window.location.href.includes('photo-interface')) {                 // when it gets url which includes photo-interface
            this.displayName = data.campaign ? data.campaign.displayName : '';
            this.campaignId = data.campaign ? data.campaign.id : '';
            this.employeeEmail = data.campaign ? data.campaign.employeeEmail : '';
        }
        return this;
    }

    getImageNameData(data, imageType?: any) {
        this.city = data.city ? data.city : data.area.city.name;
        this.location = data.location;
        this.hid = data.customId;
        this.size = data.size;
        this.imageType = imageType ? imageType : null;
        return this;
    }
}