import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { User } from "../user/user";
import { ActionEventEnum } from "../../shared/constants/action-event-enum";
import { Change } from "../change/change";
import { ActivityLogModuleEnum } from '../../shared/constants/activity-log-module-enum';

export class ActivityLog extends SibBaseObject {
    module: ActivityLogModuleEnum;
    actionEvent: ActionEventEnum;
    user: User;
    primaryKey: string;
    displayKey: string;
    changes: Change[] = [];
    // changes: Change = new Change();
    responsiblePersonEmailId: string;
    additoinalDesc: string;
}
