/**
* @file  CampaignActions 
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { Injectable } from "@angular/core";
import { ActionExtended } from "../../shared/actionextended";
import 'rxjs';

@Injectable()
export class CampaignActions {
    static CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
    static SET_CAMPAIGN = 'SET_CAMPAIGN';
    static GET_CAMPAIGN = 'GET_CAMPAIGN';
    static CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
    static CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';
    static UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
    static UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
    static UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE';
    static RESET_CAMPAIGN = 'RESET_CAMPAIGN';
    static GET_CAMPAIGN_BY_ID = 'GET_CAMPAIGN_BY_ID';
    static CONVERT_CAMPAIGN = 'CONVERT_CAMPAIGN';
    static REVERT_TO_PLAN = 'REVERT_TO_PLAN';
    static REVERT_TO_PLAN_SUCCESS = 'REVERT_TO_PLAN_SUCCESS';
    static REVERT_TO_PLAN_FAILURE = 'REVERT_TO_PLAN_FAILURE';
    static RESET_IS_CAMPAIGN_REVERTED = 'RESET_IS_CAMPAIGN_REVERTED';
    static RESET_IS_MONITORING_REMOVED = 'RESET_IS_MONITORING_REMOVED';
    static RESET_CREATED_PLAN_ID = 'RESET_CREATED_PLAN_ID';
    static REJECT_CAMPAIGN = 'REJECT_CAMPAIGN';
    static REJECT_CAMPAIGN_SUCCESS = 'REJECT_CAMPAIGN_SUCCESS';
    static REJECT_CAMPAIGN_FAILURE = 'REJECT_CAMPAIGN_FAILURE';
    static RESET_IS_CAMPAIGN_REJECTED = 'RESET_IS_CAMPAIGN_REJECTED';
    static SET_MONITORING = 'SET_MONITORING';
    static SET_MONITORING_SUCCESS = 'SET_MONITORING_SUCCESS';
    static SET_MONITORING_FAILURE = 'SET_MONITORING_FAILURE';
    static RESET_IS_MONITORING_SET = 'RESET_IS_MONITORING_SET';
    static REMOVE_CAMPAIGN_MONITORING = 'REMOVE_CAMPAIGN_MONITORING';
    static REMOVE_MONITORING_SUCCESS = 'REMOVE_MONITORING_SUCCESS';
    static REMOVE_MONITORING_FAILURE = 'REMOVE_MONITORING_FAILURE';
    static REMOVE_MOUNTING = 'REMOVE_MOUNTING';
    static REMOVE_MOUNTING_SUCCESS = 'REMOVE_MOUNTING_SUCCESS';
    static REMOVE_MOUNTING_FAILURE = 'REMOVE_MOUNTING_FAILURE';

    static REMOVE_UNMOUNTING = 'REMOVE_UNMOUNTING';
    static REMOVE_UNMOUNTING_SUCCESS = 'REMOVE_UNMOUNTING_SUCCESS';
    static REMOVE_UNMOUNTING_FAILURE = 'REMOVE_UNMOUNTING_FAILURE';
    static RESET_IS_UNMOUNTING_REMOVED = 'RESET_IS_UNMOUNTING_REMOVED';

    static RESET_IS_MOUNTING_REMOVED = 'RESET_IS_MOUNTING_REMOVED';
    static RESET_IS_CAMPAIGN_UPDATED = 'RESET_IS_CAMPAIGN_UPDATED';
    static RESET_IS_CAMPAIGN_CREATED = 'RESET_IS_CAMPAIGN_CREATED';
    static RESET_ERROR = 'RESET_ERROR';
    static APPROVE_CAMPAIGN = 'APPROVE_CAMPAIGN';
    static APPROVE_CAMPAIGN_SUCCESS = 'APPROVE_CAMPAIGN_SUCCESS';
    static APPROVE_CAMPAIGN_FAILURE = 'APPROVE_CAMPAIGN_FAILURE';
    static RESET_IS_CAMPAIGN_APPROVED = 'RESET_IS_CAMPAIGN_APPROVED';
    static DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
    static DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
    static DELETE_CAMPAIGN_FAILURE = 'DELETE_CAMPAIGN_FAILURE';
    static RESET_IS_CAMPAIGN_DELETED = 'RESET_IS_CAMPAIGN_DELETED';
    static DELETE_ITEM = 'DELETE_ITEM';
    static DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
    static DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
    static RESET_IS_ITEM_DELETED = 'RESET_IS_ITEM_DELETED';
    static UPDATE_CAMPAIGN_MONITORING = 'UPDATE_CAMPAIGN_MONITORING';
    static UPDATE_MONITORING_SUCCESS = 'UPDATE_MONITORING_SUCCESS';
    static UPDATE_MONITORING_FAILURE = 'UPDATE_MONITORING_FAILURE';
    static RESET_IS_MONITORING_UPDATED = 'RESET_IS_MONITORING_UPDATED';
    static SET_ADDITIONAL_PRICE = 'SET_ADDITIONAL_PRICE';
    static SET_ADDITIONAL_PRICE_SUCCESS = 'SET_ADDITIONAL_PRICE_SUCCESS';
    static SET_ADDITIONAL_PRICE_FAILURE = 'SET_ADDITIONAL_PRICE_FAILURE';
    static RESET_IS_ADDITIONAL_PRICE_ADDED = 'RESET_IS_ADDITIONAL_PRICE_ADDED';
    static GET_ADDITIONAL_PRICES_BY_CAMPAIGN_ID = 'GET_ADDITIONAL_PRICES_BY_CAMPAIGN_ID';
    static TEMP_SAVE_CAMPAIGN = 'TEMP_SAVE_CAMPAIGN';
    static RESET_TEMP_SAVE_CAMPAIGN = 'RESET_TEMP_SAVE_CAMPAIGN';
    static ADD_CAMPAIGN_ITEMS = 'ADD_CAMPAIGN_ITEMS';
    static ADD_CAMPAIGN_ITEMS_SUCCESS = 'ADD_CAMPAIGN_ITEMS_SUCCESS';
    static ADD_CAMPAIGN_ITEMS_FAILURE = 'ADD_CAMPAIGN_ITEMS_FAILURE';

    static UPLOAD_IMAGE = 'UPLOAD_IMAGE';
    static UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
    static UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';
    static RESET_IS_IMAGE_UPLOADED = 'RESET_IS_IMAGE_UPLOADED';

    static UPLOAD_MOUNTING_IMAGE = 'UPLOAD_MOUNTING_IMAGE';
    static UPLOAD_MOUNTING_IMAGE_SUCCESS = 'UPLOAD_MOUNTING_IMAGE_SUCCESS';
    static UPLOAD_MOUNTING_IMAGE_FAILURE = 'UPLOAD_MOUNTING_IMAGE_FAILURE';

    static UPLOAD_UNMOUNTING_IMAGE = 'UPLOAD_UNMOUNTING_IMAGE';
    static UPLOAD_UNMOUNTING_IMAGE_SUCCESS = 'UPLOAD_UNMOUNTING_IMAGE_SUCCESS';
    static UPLOAD_UNMOUNTING_IMAGE_FAILURE = 'UPLOAD_UNMOUNTING_IMAGE_FAILURE';

    static EDIT_MOUNTING_ITEM = 'EDIT_MOUNTING_ITEM';
    static EDIT_MOUNTING_ITEM_SUCCESS = 'EDIT_MOUNTING_ITEM_SUCCESS';
    static EDIT_MOUNTING_ITEM_FAILURE = 'EDIT_MOUNTING_ITEM_FAILURE';
    static RESET_IS_MOUNTING_EDITED = 'RESET_IS_MOUNTING_EDITED';

    static EDIT_UNMOUNTING_ITEM = 'EDIT_UNMOUNTING_ITEM';
    static EDIT_UNMOUNTING_ITEM_SUCCESS = 'EDIT_UNMOUNTING_ITEM_SUCCESS';
    static EDIT_UNMOUNTING_ITEM_FAILURE = 'EDIT_UNMOUNTING_ITEM_FAILURE';
    static RESET_IS_UNMOUNTING_EDITED = 'RESET_IS_UNMOUNTING_EDITED';

    static UPLOAD_PO = "UPLOAD_PO";
    static UPLOAD_PO_SUCCESS = "UPLOAD_PO_SUCCESS";
    static UPLOAD_PO_FAILURE = "UPLOAD_PO_FAILURE";
    static RESET_IS_PO_UPLOADED = "RESET_IS_PO_UPLOADED";

    static ROLLBACK_TO_PLAN = 'ROLLBACK_TO_PLAN';
    static ROLLBACK_TO_PLAN_SUCCESS = 'ROLLBACK_TO_PLAN_SUCCESS';
    static ROLLBACK_TO_PLAN_FAILURE = 'ROLLBACK_TO_PLAN_FAILURE';

    createCampaign(campaign): ActionExtended {
        console.log("inside create actoins", campaign);
        return {
            type: CampaignActions.CREATE_CAMPAIGN,
            payload: campaign
        };
    }
    convertCampaign(campaign): ActionExtended {
        console.log("inside create actoins parth ", campaign);
        return {
            type: CampaignActions.CONVERT_CAMPAIGN,
            payload: campaign
        };
    }


    createCampaignSuccess(campaign): ActionExtended {
        return {
            type: CampaignActions.CREATE_CAMPAIGN_SUCCESS,
            payload: campaign
        };
    }

    createCampaignFailure(): ActionExtended {
        return {
            type: CampaignActions.CREATE_CAMPAIGN_FAILURE
        };
    }

    updateCampaign(campaignBO): ActionExtended {
        console.log("inside update actoins", campaignBO);
        return {
            type: CampaignActions.UPDATE_CAMPAIGN,
            payload: campaignBO
        };
    }

    updateCampaignSuccess(campaignBO): ActionExtended {
        return {
            type: CampaignActions.UPDATE_CAMPAIGN_SUCCESS,
            payload: campaignBO
        };
    }

    updateCampaignFailure(): ActionExtended {
        return {
            type: CampaignActions.UPDATE_CAMPAIGN_FAILURE
        };
    }

    resetCampaign(): ActionExtended {
        return {
            type: CampaignActions.RESET_CAMPAIGN,
        };
    }
    setCampaign(campaign) {
        return {
            type: CampaignActions.SET_CAMPAIGN,
            payload: campaign
        };
    }
    getCampaign(): ActionExtended {
        return {
            type: CampaignActions.GET_CAMPAIGN
        };
    }
    getCampaignById(id): ActionExtended {
        return {
            type: CampaignActions.GET_CAMPAIGN_BY_ID,
            payload: id
        };
    }

    revertToPlan(campaignId): ActionExtended {
        return {
            type: CampaignActions.REVERT_TO_PLAN,
            payload: campaignId
        };
    }

    revertToPlanSuccess(campaignId): ActionExtended {
        return {
            type: CampaignActions.REVERT_TO_PLAN_SUCCESS,
            payload: campaignId
        };
    }

    revertToPlanFailure(): ActionExtended {
        return {
            type: CampaignActions.REVERT_TO_PLAN_FAILURE
        };
    }

    resetIsCampaignReverted() {
        return {
            type: CampaignActions.RESET_IS_CAMPAIGN_REVERTED
        };
    }

    resetCreatedPlanId(): ActionExtended {
        return {
            type: CampaignActions.RESET_CREATED_PLAN_ID
        };
    }

    resetIsCampaignRejected(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_CAMPAIGN_REJECTED
        };
    }

    resetIsMonitoringRemoved(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_MONITORING_REMOVED
        };
    }

    rejectCampaign(campaign) {
        return {
            type: CampaignActions.REJECT_CAMPAIGN,
            payload: campaign
        };
    }

    rejectCampaignSuccess(campaign) {
        return {
            type: CampaignActions.REJECT_CAMPAIGN_SUCCESS,
            payload: campaign
        };
    }

    rejectCampaignFailure() {
        return {
            type: CampaignActions.REJECT_CAMPAIGN_FAILURE
        };
    }

    setMonitoring(campaignItem): ActionExtended {
        return {
            type: CampaignActions.SET_MONITORING,
            payload: campaignItem
        };
    }

    setMonitoringSuccess(campaignItem) {
        return {
            type: CampaignActions.SET_MONITORING_SUCCESS,
            payload: campaignItem
        };
    }

    setMonitoringFailure() {
        return {
            type: CampaignActions.SET_MONITORING_FAILURE
        };
    }

    resetIsMonitoringSet(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_MONITORING_SET
        };
    }

    updateCampaignMonitoring(rowData): ActionExtended {
        return {
            type: CampaignActions.UPDATE_CAMPAIGN_MONITORING,
            payload: rowData
        };
    }

    updateMonitoringSuccess(rowData) {
        return {
            type: CampaignActions.UPDATE_MONITORING_SUCCESS,
            payload: rowData
        };
    }

    updateMonitoringFailure() {
        return {
            type: CampaignActions.UPDATE_MONITORING_FAILURE
        };
    }

    resetIsMonitoringUpdated(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_MONITORING_UPDATED
        };
    }

    removeCampaignMonitoringItem(rowData): ActionExtended {
        return {
            type: CampaignActions.REMOVE_CAMPAIGN_MONITORING,
            payload: rowData
        };
    }

    removeMonitoringItemSuccess(rowData) {
        return {
            type: CampaignActions.REMOVE_MONITORING_SUCCESS,
            payload: rowData
        };
    }

    removeMonitoringItemFailure() {
        return {
            type: CampaignActions.REMOVE_MONITORING_FAILURE
        };
    }

    removeMountingItem(rowData): ActionExtended {
        return {
            type: CampaignActions.REMOVE_MOUNTING,
            payload: rowData
        };
    }

    removeMountingItemSuccess(rowData) {
        return {
            type: CampaignActions.REMOVE_MOUNTING_SUCCESS,
            payload: rowData
        };
    }

    removeMountingItemFailure() {
        return {
            type: CampaignActions.REMOVE_MOUNTING_FAILURE
        };
    }

    removeUnmountingItem(rowData): ActionExtended {
        return {
            type: CampaignActions.REMOVE_UNMOUNTING,
            payload: rowData
        };
    }

    removeUnmountingSuccess(rowData): ActionExtended {
        return {
            type: CampaignActions.REMOVE_UNMOUNTING_SUCCESS,
            payload: rowData
        };
    }

    removeUnmountingFailure(rowData): ActionExtended {
        return {
            type: CampaignActions.REMOVE_UNMOUNTING_FAILURE
        };
    }

    resetIsMountingRemoved(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_MOUNTING_REMOVED
        };
    }

    resetIsUnmountingRemoved(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_UNMOUNTING_REMOVED
        };
    }

    resetIsCampaignCreated(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_CAMPAIGN_CREATED
        };
    }

    resetIsCampaignUpdated(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_CAMPAIGN_UPDATED
        };
    }

    resetError(): ActionExtended {
        return {
            type: CampaignActions.RESET_ERROR
        };
    }

    uploadImage(selectedRow): ActionExtended {
        console.log("inside upload image actoins", selectedRow);
        return {
            type: CampaignActions.UPLOAD_IMAGE,
            payload: selectedRow
        };
    }

    uploadImageSuccess(selectedRow) {
        console.log("inside upload image success actoins", selectedRow);
        return {
            type: CampaignActions.UPLOAD_IMAGE_SUCCESS,
            payload: selectedRow
        };
    }

    uploadImageFailure() {
        console.log("inside upload image failure actoins");
        return {
            type: CampaignActions.UPLOAD_IMAGE_FAILURE
        };
    }

    uploadMountingImage(selectedRow): ActionExtended {
        console.log("inside upload mounting image actoins", selectedRow);
        return {
            type: CampaignActions.UPLOAD_MOUNTING_IMAGE,
            payload: selectedRow
        };
    }

    uploadMountingImageSuccess(selectedRow) {
        console.log("inside upload mounting image success actoins", selectedRow);
        return {
            type: CampaignActions.UPLOAD_MOUNTING_IMAGE_SUCCESS,
            payload: selectedRow
        };
    }

    uploadMountingImageFailure() {
        console.log("inside upload mounting image failure actoins");
        return {
            type: CampaignActions.UPLOAD_MOUNTING_IMAGE_FAILURE
        };
    }

    uploadUnmountingImage(selectedRow): ActionExtended {
        return {
            type: CampaignActions.UPLOAD_UNMOUNTING_IMAGE,
            payload: selectedRow
        }
    }

    uploadUnmountingImageSuccess(selectedRow) {
        return {
            type: CampaignActions.UPLOAD_UNMOUNTING_IMAGE_SUCCESS,
            payload: selectedRow
        };
    }

    uploadUnmountingImageFailure() {
        return {
            type: CampaignActions.UPLOAD_UNMOUNTING_IMAGE_FAILURE,
        };
    }

    resetIsImageUploaded(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_IMAGE_UPLOADED
        };
    }

    editMountingItem(selectedRow): ActionExtended {
        console.log("inside edit mounting item actoins", selectedRow);
        return {
            type: CampaignActions.EDIT_MOUNTING_ITEM,
            payload: selectedRow
        };
    }

    editMountingItemSuccess(selectedRow) {
        console.log("inside edit mounting item success actoins", selectedRow);
        return {
            type: CampaignActions.EDIT_MOUNTING_ITEM_SUCCESS,
            payload: selectedRow
        };
    }

    editMountingItemFailure() {
        console.log("inside edit mounting item failure actoins");
        return {
            type: CampaignActions.EDIT_MOUNTING_ITEM_FAILURE
        };
    }

    resetIsMountingEdited(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_MOUNTING_EDITED
        };
    }

    editUnmountingItem(selectedRow): ActionExtended {
        return {
            type: CampaignActions.EDIT_UNMOUNTING_ITEM,
            payload: selectedRow
        };
    }

    editUnmountingItemSuccess(selectedRow) {
        return {
            type: CampaignActions.EDIT_UNMOUNTING_ITEM_SUCCESS,
            payload: selectedRow
        };
    }

    editUnmountingItemFailure() {
        return {
            type: CampaignActions.EDIT_UNMOUNTING_ITEM_FAILURE
        };
    }

    resetIsUnmountingEdited(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_UNMOUNTING_EDITED
        };
    }



    approveCampaign(campaign): ActionExtended {
        console.log("inside approve actoins", campaign);
        return {
            type: CampaignActions.APPROVE_CAMPAIGN,
            payload: campaign
        };
    }

    approveCampaignSuccess(campaign) {
        return {
            type: CampaignActions.APPROVE_CAMPAIGN_SUCCESS,
            payload: campaign
        };
    }

    approveCampaignFailure() {
        return {
            type: CampaignActions.APPROVE_CAMPAIGN_FAILURE
        };
    }

    resetIsCampaignApproved(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_CAMPAIGN_APPROVED
        };
    }

    deleteCampaign(campaignId): ActionExtended {
        return {
            type: CampaignActions.DELETE_CAMPAIGN,
            payload: campaignId
        };
    }

    deleteCampaignSuccess(campaignId): ActionExtended {
        return {
            type: CampaignActions.DELETE_CAMPAIGN_SUCCESS,
            payload: campaignId
        };
    }

    deleteCampaignFailure(): ActionExtended {
        return {
            type: CampaignActions.DELETE_CAMPAIGN_FAILURE
        };
    }

    deleteItem(campaignQueryParams): ActionExtended {
        return {
            type: CampaignActions.DELETE_ITEM,
            payload: campaignQueryParams
        };
    }

    deleteItemSuccess(campaignQueryParams): ActionExtended {
        return {
            type: CampaignActions.DELETE_ITEM_SUCCESS,
            payload: campaignQueryParams
        };
    }

    deleteItemFailure(): ActionExtended {
        return {
            type: CampaignActions.DELETE_ITEM_FAILURE
        };
    }

    resetIsCampaignDeleted(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_CAMPAIGN_DELETED
        };
    }

    resetIsItemDeleted(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_ITEM_DELETED
        };
    }

    setAdditionalPrice(campaignItem): ActionExtended {
        return {
            type: CampaignActions.SET_ADDITIONAL_PRICE,
            payload: campaignItem
        };
    }

    setAdditionalPriceSuccess(campaignItem) {
        return {
            type: CampaignActions.SET_ADDITIONAL_PRICE_SUCCESS,
            payload: campaignItem
        };
    }

    setAdditionalPriceFailure() {
        return {
            type: CampaignActions.SET_ADDITIONAL_PRICE_FAILURE
        };
    }

    resetIsAdditionalPriceAdded(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_ADDITIONAL_PRICE_ADDED
        };
    }

    getAdditionalPricesByCampaignId(id): ActionExtended {
        return {
            type: CampaignActions.GET_CAMPAIGN_BY_ID,
            payload: id
        };
    }

    tempSaveCampaign(campaignItems): ActionExtended {
        console.log("temp save actions", campaignItems);
        return {
            type: CampaignActions.TEMP_SAVE_CAMPAIGN,
            payload: campaignItems
        };
    }

    resetTempSaveCampaign(): ActionExtended {
        return {
            type: CampaignActions.RESET_TEMP_SAVE_CAMPAIGN
        };
    }

    addCampaignItems(campaignItems): ActionExtended {
        return {
            type: CampaignActions.ADD_CAMPAIGN_ITEMS,
            payload: campaignItems
        };
    }

    addCampaignItemsSuccess(campaignItems): ActionExtended {
        return {
            type: CampaignActions.ADD_CAMPAIGN_ITEMS_SUCCESS,
            payload: campaignItems
        };
    }

    addCampaignItemsFailure(): ActionExtended {
        return {
            type: CampaignActions.ADD_CAMPAIGN_ITEMS_FAILURE
        };
    }

    uploadPO(campaignQueryParams): ActionExtended {
        return {
            type: CampaignActions.UPLOAD_PO,
            payload: campaignQueryParams
        };
    }

    uploadPOSuccess(purchseOrders): ActionExtended {
        return {
            type: CampaignActions.UPLOAD_PO_SUCCESS,
            payload: purchseOrders
        };
    }

    uploadPOFailure(): ActionExtended {
        return {
            type: CampaignActions.UPLOAD_PO_FAILURE
        };
    }

    resetIsPOUploaded(): ActionExtended {
        return {
            type: CampaignActions.RESET_IS_PO_UPLOADED
        };
    }

    rollBackToPlan(campaignId): ActionExtended {
        return {
            type: CampaignActions.ROLLBACK_TO_PLAN,
            payload: campaignId
        };
    }

    rollbackToPlanSuccess(campaignId): ActionExtended {
        return {
            type: CampaignActions.ROLLBACK_TO_PLAN_SUCCESS,
            payload: campaignId
        };
    }

    rollbackToPlanFailure(): ActionExtended {
        return {
            type: CampaignActions.ROLLBACK_TO_PLAN_FAILURE
        };
    }

}
