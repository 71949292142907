/**
* @file  PlanHoarding.
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Customer } from "../KYC/customer";
import { Area } from "../Area/area";
import { Plan } from "./plan";
import { ContractHoardings } from "../contracts/contract-hoarding";
import { Contracts } from "../contracts/contracts";
import { GeoCoordinate } from "../hoardings/geo-coordinate";
import { Picture } from "../hoardings/picture";
import { AbstractItem } from "../hoardings/abstract-hoarding";
import { HoardingGroup } from "../hoardings/hoardings-group";
import { PrintingPrice } from "./printingPrice";
import { MountingPrice } from "./mountingPrice";
import { Kiosk } from "../hoardings/kiosk";

export class PlanItem extends SibBaseObject {
    //Raveena | 16-05-2019 
    planId: string;
    hoarding: ContractHoardings;
    hoardingGroup: HoardingGroup;
    kiosk: Kiosk;
    rate: number;
    cost: number;
    rateType: string;
    itemStartDate: Date;
    itemEndDate: Date;
    pending: boolean;
    pendingReason: string;
    printingPrice: PrintingPrice = new PrintingPrice();
    mountingPrice: MountingPrice = new MountingPrice();
    printPrice: number = 0;
    mountPrice: number = 0;
    printCharge: number;
    mountCharge: number;
    //Raveena | 16-05-2019 
    mediaSubCategory: string;
}
