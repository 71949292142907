import { GridColumn, EditableGridFieldTypes } from '../../components/sib-forms/grid/grid.config';
import * as utils from '../../helpers/utils';
import * as mapUtils from '../../helpers/map.utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';

export const untappedRoiDataColumns: Array<GridColumn> = [
  {
    field: 'srNo',
    name: 'srNo',
    header: 'Sr',
    required: true,
    width: '2vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: false,
    type: EditableGridFieldTypes.CUSTOM,
  },
  {
    field: 'id',
    name: 'id',
    header: 'CID',
    required: true,
    width: '4vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.id) {
        return data.id;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'name',
    name: 'name',
    header: 'Contract',
    required: true,
    width: '8vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.name) {
        return data.name;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'cheques.chequeName',
    name: 'cheques.chequeName',
    header: 'Cheque Name',
    required: true,
    width: '8vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: false,
    sortable: true,
    type: EditableGridFieldTypes.CUSTOM,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
  },
  {
    field: 'breakevens',
    name: 'breakevens',
    header: 'Break Even Amount',
    required: true,
    // width: '4vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    // filter: {
    //   matchMode: 'relational',
    //   placeHolder: 'Search',
    //   custom: false,
    // },
    type: EditableGridFieldTypes.NUMBER,
    displayFn: (data) => {
      if (data && data.breakevens >= 0) {
        return new CurrencyHelperPipe().transform(utils.getDecimalNumFixedToTwoPlaces(data.breakevens));
      } else {
        return '-';
      }
    },
  },
];

export const untappedRoiDataMap = (() => {
  return mapUtils.createMap(untappedRoiDataColumns);
})();
