/**
* @file  Price.
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { Customer } from "../KYC/customer";
import { ContractHoardings } from "../contracts/contract-hoarding";
import { PlanHoarding } from "./planHoarding";

export class Price {
  pricePerUnit: number = 0;
  pricePerSqFt: number = 0;

}