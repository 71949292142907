import { SibBaseObject } from "../BaseObject/SibBaseObject";
import { PlanHoarding } from "../plans/planHoarding";
import { CampaignHoarding } from "../campaigns/campaignHoarding";
import { ContractHoardings } from '../contracts/contract-hoarding';
import { HoardingGroup } from '../hoardings/hoardings-group';
import { Kiosk } from '../hoardings/kiosk';
import { MapMetadata } from './map-metadata';


export class MapMarker extends SibBaseObject {
    latitude: number;
    longitude: number;
    location: string;
    metadata: any;

    setMapMarkerFromInventory(inventory: ContractHoardings | HoardingGroup | Kiosk): MapMarker {
        this.latitude = typeof (inventory.geoCoordinate.latitude) === 'string' ? parseFloat(inventory.geoCoordinate.latitude) : inventory.geoCoordinate.latitude;
        this.longitude = typeof (inventory.geoCoordinate.longitude) === 'string' ? parseFloat(inventory.geoCoordinate.longitude) : inventory.geoCoordinate.longitude;
        this.location = '';
        this.metadata = new MapMetadata().setMapMetadataFromInventory(inventory);
        return this;
    }

}