/**
* @file  CampaignActionDispatcher 
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CampaignActions } from "../actions/campaign-actions";
import { CampaignModuleState, CampaignModuleStateExtended } from "../campaign-module.reducers";
import { Store } from "@ngrx/store";
import { Campaign } from "../../modals/campaigns/campaign";
import { CampaignModule } from "../campaign.module";
import { CampaignBO } from "../../modals/campaigns/campaignBO";
import { CampaignItem } from "../../modals/campaigns/campaignItem";
import { PurchaseOrder } from "../../modals/campaigns/purchaseOrder";
import { CampaignQueryParams } from "../../modals/queryparams/campaign-queryparams";


@Injectable()
export class CampaignActionDispatcher {

    isCampaignCreated: Observable<boolean>;
    isImageUploaded: Observable<boolean>;
    isCampaignUpdated: Observable<boolean>;
    isCampaignReverted: Observable<boolean>;
    isCampaignRejected: Observable<boolean>;
    isCampaignApproved: Observable<boolean>;
    isMonitoringRemoved: Observable<boolean>;
    isMountingRemoved: Observable<boolean>;
    isMountingEdited: Observable<boolean>;
    isUnmountingEdited: Observable<boolean>;
    isUnmountingRemoved: Observable<boolean>;
    isMonitoringSet: Observable<boolean>;
    isMonitoringUpdated: Observable<boolean>;
    isCampaignDeleted: Observable<boolean>;
    isItemDeleted: Observable<boolean>;
    isAdditionalPriceAdded: Observable<boolean>;
    campaign: Observable<Campaign>;
    campaignItems: Observable<CampaignItem[]>;
    createdPlanId: Observable<string>;
    isError: Observable<boolean>;
    isPOUploaded: Observable<boolean>;

    constructor(
        private store: Store<CampaignModuleStateExtended>,
        private campaignActions: CampaignActions

    ) {
        this.campaign = store.select(state => {
            if (state.campaigns && state.campaigns.campaigns) {

                return state.campaigns.campaigns.campaign;
            } else {
                return JSON.parse(localStorage.getItem('lastCampaign'));
            }

        });

        this.campaignItems = store.select(state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.campaignItems;
            }
        });

        this.isCampaignCreated = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isCampaignCreated;
            }
        }));

        this.isCampaignReverted = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isCampaignReverted;
            }
        }));

        this.isCampaignDeleted = store.select(state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isCampaignDeleted;
            }
        });

        this.isItemDeleted = store.select(state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isItemDeleted;
            }
        });

        this.createdPlanId = store.select(state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.createdPlanId;
            }
        });

        this.isCampaignRejected = store.select(state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isCampaignRejected;
            }
        });

        this.isCampaignUpdated = store.select(state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isCampaignUpdated;
            }
        });

        this.isImageUploaded = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isImageUploaded;
            }
        }));

        this.isCampaignApproved = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isCampaignApproved;
            }
        }));

        this.isMonitoringRemoved = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isMonitoringRemoved;
            }
        }));

        this.isMountingRemoved = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isMountingRemoved;
            }
        }));

        this.isMountingEdited = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isMountingEdited;
            }
        }));

        this.isUnmountingEdited = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isUnmountingEdited;
            }
        }));

        this.isUnmountingRemoved = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isUnmountingRemoved;
            }
        }))

        this.isMonitoringSet = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isMonitoringSet;
            }
        }));

        this.isMonitoringUpdated = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isMonitoringUpdated;
            }
        }));

        this.isAdditionalPriceAdded = store.select((state => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isAdditionalPriceAdded;
            }
        }));

        this.isError = store.select((state) => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isError;
            }
        });

        this.isPOUploaded = store.select((state) => {
            if (state.campaigns && state.campaigns.campaigns) {
                return state.campaigns.campaigns.isPOUploaded;
            }
        });
    }

    setCampaign(campaignData) {
        // localStorage.setItem('lastCampaign', JSON.stringify(campaignData));
        this.store.dispatch(this.campaignActions.setCampaign(campaignData))
    }
    getCampaignById(id) {
        this.store.dispatch(this.campaignActions.getCampaignById(id));
    }
    getCampaign() {
        this.store.dispatch(this.campaignActions.getCampaign());
    }

    convertCampaign(campaignData) {
        console.log('Inside Action dispatcher parth ' + campaignData)
        this.store.dispatch(this.campaignActions.convertCampaign(campaignData));
    }

    revertToPlan(campaignId) {
        console.log("inside action dispatcher revert to plan", campaignId);
        this.store.dispatch(this.campaignActions.revertToPlan(campaignId));
    }

    resetIsCampaignReverted() {
        this.store.dispatch(this.campaignActions.resetIsCampaignReverted());
    }

    resetCreatedPlanId() {
        this.store.dispatch(this.campaignActions.resetCreatedPlanId());
    }

    rejectCampaign(campaign) {
        this.store.dispatch(this.campaignActions.rejectCampaign(campaign));
    }

    resetIsCampaignRejected() {
        this.store.dispatch(this.campaignActions.resetIsCampaignRejected());
    }

    setMonitoring(campaignItem) {
        this.store.dispatch(this.campaignActions.setMonitoring(campaignItem));
    }

    updateCampaignMonitoring(rowData) {
        this.store.dispatch(this.campaignActions.updateCampaignMonitoring(rowData));
    }

    removeCampaignMonitoringItem(rowData: any) {     // for removing a monitoring item
        this.store.dispatch(this.campaignActions.removeCampaignMonitoringItem(rowData));
    }

    removeMountingItem(rowData: any) {     // for removing a mounting item
        this.store.dispatch(this.campaignActions.removeMountingItem(rowData));
    }

    removeUnmountingItem(rowData: any) {
        this.store.dispatch(this.campaignActions.removeUnmountingItem(rowData));
    }

    editMountingItem(rowData: any) {
        this.store.dispatch(this.campaignActions.editMountingItem(rowData));
    }

    resetIsMountingEdited() {
        this.store.dispatch(this.campaignActions.resetIsMountingEdited());
    }

    editUnmountingItem(rowData: any) {
        this.store.dispatch(this.campaignActions.editUnmountingItem(rowData));
    }

    resetIsUnmountingEdited() {
        this.store.dispatch(this.campaignActions.resetIsUnmountingEdited())
    }

    updateCampaign(campaignBO: CampaignBO) {
        this.store.dispatch(this.campaignActions.updateCampaign(campaignBO));
    }

    resetIsCampaignCreated() {
        this.store.dispatch(this.campaignActions.resetIsCampaignCreated());
    }

    resetIsCampaignUpdated() {
        this.store.dispatch(this.campaignActions.resetIsCampaignUpdated());
    }

    resetIsCampaignApproved() {
        this.store.dispatch(this.campaignActions.resetIsCampaignApproved());
    }

    resetIsCampaignDeleted() {
        this.store.dispatch(this.campaignActions.resetIsCampaignDeleted());
    }

    resetIsItemDeleted() {
        this.store.dispatch(this.campaignActions.resetIsItemDeleted());
    }

    approveCampaign(campaign) {
        this.store.dispatch(this.campaignActions.approveCampaign(campaign));
    }

    resetError() {
        this.store.dispatch(this.campaignActions.resetError());
    }

    uploadImage(selectedRow: any) {
        this.store.dispatch(this.campaignActions.uploadImage(selectedRow));
    }

    uploadMountingImage(selectedRow: any) {
        this.store.dispatch(this.campaignActions.uploadMountingImage(selectedRow));
    }

    uploadUnmountingImage(selectedRow: any) {
        this.store.dispatch(this.campaignActions.uploadUnmountingImage(selectedRow));
    }

    resetIsImageUploaded() {
        this.store.dispatch(this.campaignActions.resetIsImageUploaded());
    }


    deleteCampaign(campaignId) {
        console.log('Inside delete plan Action dispatcher parth ' + campaignId);
        this.store.dispatch(this.campaignActions.deleteCampaign(campaignId));
    }

    deleteItem(campaignQueryParams) {
        this.store.dispatch(this.campaignActions.deleteItem(campaignQueryParams));
    }

    resetIsMonitoringRemoved() {
        this.store.dispatch(this.campaignActions.resetIsMonitoringRemoved());
    }

    resetIsMountingRemoved() {
        this.store.dispatch(this.campaignActions.resetIsMountingRemoved());
    }

    resetIsUnmountingRemoved() {
        this.store.dispatch(this.campaignActions.resetIsUnmountingRemoved());
    }

    resetIsMonitoringSet() {
        this.store.dispatch(this.campaignActions.resetIsMonitoringSet());
    }

    resetIsMonitoringUpdated() {
        this.store.dispatch(this.campaignActions.resetIsMonitoringUpdated());
    }

    setAdditionalPrice(campaignItem: any) {
        this.store.dispatch(this.campaignActions.setAdditionalPrice(campaignItem));
    }

    resetIsAdditionalPriceAdded() {
        this.store.dispatch(this.campaignActions.resetIsAdditionalPriceAdded());
    }

    getAdditionalPricesByCampaignId(id) {
        this.store.dispatch(this.campaignActions.getAdditionalPricesByCampaignId(id));
    }

    tempSaveCampaign(campaignItems: CampaignItem[]) {
        console.log("temp save dispatcher", campaignItems);
        this.store.dispatch(this.campaignActions.tempSaveCampaign(campaignItems));
    }

    resetTempSaveCampaign() {
        this.store.dispatch(this.campaignActions.resetTempSaveCampaign());
    }

    addCampaignItems(campaignItems: CampaignItem[]) {
        this.store.dispatch(this.campaignActions.addCampaignItems(campaignItems));
    }

    uploadPO(campaignQueryParams: CampaignQueryParams) {
        this.store.dispatch(this.campaignActions.uploadPO(campaignQueryParams));
    }

    resetIsPOUploaded() {
        this.store.dispatch(this.campaignActions.resetIsPOUploaded());
    }

    rollBackToPlan(campaignId) {
        console.log("inside action dispatcher rollback to plan", campaignId);
        this.store.dispatch(this.campaignActions.rollBackToPlan(campaignId));
    }
}
