<div class="count-card">
    <div class="header">
        <h3 class="heading-tertiary">
            Campaigns
        </h3>
    </div>
    <hr>
    <div class="counts">
        <div class="total-counts">
            <div class="total-count-child">
                <a [routerLink]="['/campaigns']" title="Go to Campaigns">
                    {{total}}
                </a>
            </div>
        </div>
        <div class="individual-count">
            <div class="individual-count">
                <table>
                    <tr>
                        <td class="detail-text">Approved</td>
                        <td class="detail-count">{{campaignCount?.approvedCount}}</td>
                    </tr>
                    <tr>
                        <td class="detail-text">Pending</td>
                        <td class="detail-count">{{campaignCount?.pendingCount}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>