import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GrowlModule } from "primeng/primeng";
import { NotificationsComponent } from "./notifications.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GrowlModule
    ],

    declarations: [
        NotificationsComponent
    ],

    exports: [
        NotificationsComponent,
    ]
})

export class NotificationsModule {}
