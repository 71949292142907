
import { of as observableOf, Observable, Subscription, empty as observableEmpty } from 'rxjs';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { EditableGridFieldTypes, GridColumn, GridConfig, SelectionMode, GridPaginationEvent, GridActionButtonTypes } from "../../components/sib-forms/grid/grid.config";
import { InvoiceStatus } from "../../shared/constants/invoice-status-enum";
import { DateUtil } from "../../helpers/date.util";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import { BillingActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { Invoice } from "../../modals/billings/invoice";
import { GridActionButtonConfig } from "../../components/sib-forms/grid/grid-action-button.config";
import { FormGroup, FormControl } from "../../../../node_modules/@angular/forms";
import { InvoiceService } from "../../services/shared/invoice.service";
import { Router } from "@angular/router";
import { SelectItem, ConfirmationService } from "primeng/primeng";
import { NotificatoinsService } from "../../services/notifications/notifications.service";
import * as utils from '../../helpers/utils';
import { StatusVisualizationNode } from "../../modals/billings/status-visualization-node";
import { SIBConstants } from "../../shared/constants/SIBConstant";
import { UserGridConfigService } from "../../services/shared/user-grid-config.service";
import { GridNameEnum } from '../../shared/constants/grid-name.enum';
import { BillingUIService } from '../services/billingUI.service';
import { MigratedInvoice } from '../../modals/billings/migrated-invoice';
import { CustomerUiService } from '../../customers/services/customer-ui.service';
import { MigratedInvoiceService } from '../services/migrated-invoice.service';
// import { CampaignEditDialogComponent } from 'src/app/shared/components/campaign-edit-dialog/campaign-edit-dialog.component';
import { map, catchError } from 'rxjs/operators';


@Component({
  selector: 'sib-superbill-migrated-list',
  templateUrl: './superbill-migrated-list.component.html',
  styleUrls: ['./superbill-migrated-list.component.css']
})
export class SuperbillMigratedListComponent implements OnInit {


  @ViewChild('campaignMigratedInvoicesSibGrid')
  campaignMigratedInvoicesSibGrid;

  @Input() migratedInvoice: MigratedInvoice[] = [];
  @Input() campaignId;

  constructor(
    private currencyPipe: CurrencyHelperPipe,
    private billingActionDispatcher: BillingActionDispatcher,
    private userGridConfigService: UserGridConfigService,
    private router: Router,
    private invoiceService: InvoiceService,
    private notificationServcie: NotificatoinsService,
    private migratedInvoiceService: MigratedInvoiceService
  ) {
    this.campaignMigratedInvoicesGridConfig.getDefaultModel = () => new MigratedInvoice();
    this.campaignMigratedInvoicesGridConfig.model = MigratedInvoice;
    this.campaignMigratedInvoicesGridConfig.selectionMode = SelectionMode.Multi;
    this.campaignMigratedInvoicesGridConfig.editable = true;
    this.campaignMigratedInvoicesGridConfig.expandableRows = false;
    this.campaignMigratedInvoicesGridConfig.checkboxSelection = false;
    this.campaignMigratedInvoicesGridConfig.resizableColumns = false;
    // this.campaignInvoicesGridConfig.rowExpandMode = 'single';
    this.campaignMigratedInvoicesGridConfig.shouldAddRowOnDelete = false;
    this.campaignMigratedInvoicesGridConfig.showColorCode = true;
    this.campaignMigratedInvoicesGridConfig.lazy = true;
    this.campaignMigratedInvoicesGridConfig.enablePagination = true;
    this.campaignMigratedInvoicesGridConfig.scrollable = true;
    this.campaignMigratedInvoicesGridConfig.showColorCode = false;

    this.campaignMigratedInvoicesGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen

      return this.migratedInvoiceService.get({ campaignId: decodeURIComponent(this.campaignId) }, '/byCampaignId?').pipe(
        map((response) => {
          return response['data'];
        }), catchError(error => {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Server Error', 'Please Refresh.');
          return observableEmpty();
        }));
    }
  }

  ngOnInit() {
    this.setMigratedUserGridConfig();
    this.setMigratedFormGroupObjects();
    // this.setMigratedGridConfigObject();
  }





  rowsPerPage: number;


  // _migratedInvoices: MigratedInvoice[] = [];
  campaignMigratedInvoicesGroup: FormGroup;
  campaignMigratedInvoicesGridConfig: GridConfig<Invoice> = new GridConfig<Invoice>();
  campaignMigratedInvoicesButtonConfig: GridActionButtonConfig = {
  };
  campaignMigratedInvoicesTotalRecords: number;


  campaignMigratedInvoicesColumns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr',
      required: true,
      width: '2vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },
    {
      field: 'campaign.campaignId',
      name: 'campaign.campaignId',
      header: 'Campaign Id',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign && data.campaign.campaignId) {
          return data.campaign.campaignId;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'invoiceId',
      name: 'invoiceId',
      header: 'Invoice No',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },

    {
      field: 'refInvoiceId',
      name: 'refInvoiceId',
      header: 'Tally Reference Id',
      required: true,
      width: '5.5vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.refInvoiceId) {
          return data.refInvoiceId;
        } else {
          return '-';
        }
      },
    },

    {
      field: 'campaign.displayName',
      name: 'campaign.displayName',
      header: 'Display Name',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.campaign && data.campaign.displayName) {
          return data.campaign.displayName;
        } else {
          return '-';
        }
      },
    },
    //-----------------------------------------Sanchit Mirg 18-04-2019 End------------------------------------------------
    // {
    //   field: 'campaign.customer.company',
    //   name: 'campaign.customer.company',
    //   header: 'Company',
    //   required: true,
    //   width: '6vw',
    //   editable: false,
    //   default: true,
    //   hidden: false,
    //   permanent: true,
    //   sortable: true,
    //   styleClass: 'word-break-wrap ',
    //   type: EditableGridFieldTypes.TEXT,
    //   filter: {
    //     matchMode: 'contains',
    //     placeHolder: 'Search',
    //     custom: false,
    //   },
    //   displayFn: (data) => {
    //     if (data.campaign.customer.company) {
    //       return data.campaign.customer.company;
    //     } else {
    //       return '-';
    //     }
    //   },
    // },
    {
      field: 'billGeneratedDate',
      name: 'billGeneratedDate',
      header: 'Bill Generated Date',
      required: true,
      width: '5vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billGeneratedDate) {
          return DateUtil.dategridFormatter(data.billGeneratedDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billStartDate',
      name: 'billStartDate',
      header: 'Bill Start Date',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billStartDate) {
          return DateUtil.dategridFormatter(data.billStartDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'billEndDate',
      name: 'billEndDate',
      header: 'Bill End Date',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.billEndDate) {
          return DateUtil.dategridFormatter(data.billEndDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'rentAmount',
      name: 'rentAmount',
      header: 'Rent Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.rentAmount !== null) {
          return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.rentAmount));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'printAmount',
      name: 'printAmount',
      header: 'Printing Amount',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.printAmount !== null) {
          return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.printAmount));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'mountAmount',
      name: 'mountAmount',
      header: 'Mounting Amount',
      required: true,
      width: '5vw',
      editable: false,
      default: false,
      hidden: false,
      permanent: false,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.mountAmount !== null) {
          return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.mountAmount));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'totalAmount',
      name: 'totalAmount',
      header: 'Bill Amount',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.TEXT,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.totalAmount !== null) {
          return this.currencyPipe.transform(utils.getIntegerRoundedOffValue(data.totalAmount));
        } else {
          return '-';
        }
      },
    },
    {
      field: 'lspDiff',
      name: 'lspDiff',
      header: 'LSP Difference',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.lspDiff !== null) {
          return this.currencyPipe.transform(data.lspDiff);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'campaignDiff',
      name: 'campaignDiff',
      header: 'Campaign Difference',
      required: true,
      width: '6vw',
      editable: false,
      default: true,
      hidden: false,
      permanent: true,
      sortable: true,
      styleClass: 'word-break-wrap ',
      type: EditableGridFieldTypes.CUSTOM,
      // filter: {
      //     matchMode: 'equals',
      //     placeHolder: 'Search',
      //     custom: false,
      // },
      displayFn: (data) => {
        if (data.campaignDiff !== null) {
          return this.currencyPipe.transform(data.campaignDiff);
        } else {
          return '-';
        }
      },
    },


  ];



  getEncodedURI(id) {
    return encodeURIComponent(id);
  }

  setMigratedUserGridConfig() {
    this.userGridConfigService.get(null, '/' + GridNameEnum['SUPERBILL_MIGRATED'])
      .subscribe((migratedinvoiceListGridConfig) => {
        this.campaignMigratedInvoicesColumns = utils.getUserConfiguredColumns(migratedinvoiceListGridConfig, this.campaignMigratedInvoicesColumns);
        this.campaignMigratedInvoicesSibGrid.setAllColumns();
      });
  }

  setMigratedFormGroupObjects() {
    this.campaignMigratedInvoicesGroup = new FormGroup({
      campaignMigratedInvoicesGridControl: new FormControl()
    });
  }


  /* setMigratedGridConfigObject() {
    this.campaignMigratedInvoicesGridConfig.getDefaultModel = () => new MigratedInvoice();
    this.campaignMigratedInvoicesGridConfig.model = MigratedInvoice;
    this.campaignMigratedInvoicesGridConfig.selectionMode = SelectionMode.Multi;
    this.campaignMigratedInvoicesGridConfig.editable = true;
    this.campaignMigratedInvoicesGridConfig.expandableRows = false;
    this.campaignMigratedInvoicesGridConfig.checkboxSelection = false;
    this.campaignMigratedInvoicesGridConfig.resizableColumns = false;
    // this.campaignInvoicesGridConfig.rowExpandMode = 'single';
    this.campaignMigratedInvoicesGridConfig.shouldAddRowOnDelete = false;
    this.campaignMigratedInvoicesGridConfig.showColorCode = true;
    this.campaignMigratedInvoicesGridConfig.lazy = false;
    this.campaignMigratedInvoicesGridConfig.enablePagination = true;
    this.campaignMigratedInvoicesGridConfig.scrollable = true;
    this.campaignMigratedInvoicesGridConfig.showColorCode = false;

    this.campaignMigratedInvoicesGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen

      return this.migratedInvoiceService.get({ campaignId: decodeURIComponent(this.campaignId) }, '/byCampaignId?').pipe(
        map((response) => {
          return response['data'];
        }), catchError(error => {
          this.notificationServcie.error(error.error.message ? error.error.message : 'Server Error', 'Please Refresh.');
          return observableEmpty();
        }));
    }

  }; */




  updatingMigratedUserGridConfig(columnArray) {
    let migratedinvoiceListGridConfig: Map<string, string> = new Map();
    migratedinvoiceListGridConfig = utils.getGridConfigMap(columnArray, this.campaignMigratedInvoicesColumns);
    this.userGridConfigService.patch(utils.buildObject(migratedinvoiceListGridConfig), null, GridNameEnum['SUPERBILL_MIGRATED'])
      .subscribe((result) => {
      });
  }


  getDecimalNum(target, decimal) {
    // return Number(target.toFixed(decimal));
    return Number(Math.fround(target).toFixed(decimal));
  }

  parseValueToDecimal(value) {
    return utils.parseValueToDecimalTwoPlaces(value);
  }

  getAbsoluteValue(value) {
    return Number(Math.abs(Number(value)));
  }

  // getDecimalNum(target, decimal) {
  //   // return Number(target.toFixed(decimal));
  //   return Number(Math.fround(target).toFixed(decimal));
  // }



}
