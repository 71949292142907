import { Injectable } from "@angular/core";
import { ArticlesService } from "./articles.service";


@Injectable()
export class ArticlesGetService {

    constructor(
        private articlesservice: ArticlesService
    ) {

    }

    getArticlesList(articleQueryParam) {
        return this.articlesservice.get(articleQueryParam, '/byProductAndModule');
    }

    getFilteredArticles(articlesSearchQueryParams) {
        return this.articlesservice.get(articlesSearchQueryParams, '/search');
    }
}