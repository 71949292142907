import { NgModule } from "@angular/core";
import { SecuredDirective } from "../directives/permission.directive";
import { UppercaseDirective } from "../directives/uppercase.directive";
import { LowercaseDirective } from "../directives/lowercase.directive";
import { CurrencyConverterDirective } from "../directives/currency.directive";
import { NormalCaseDirective } from "../directives/normalcase.directive";
import { CounterDirective } from "../directives/counter.directive";
import { TrimCommasDirective } from '../directives/trimTraillingCommas.directive';
import { TitlecaseDirective } from "../directives/titlecase.directive";
import { ChangesDirective } from '../directives/changes.directive';
import { ValidationsDirective } from '../directives/validations.directive';

@NgModule({
    imports: [],

    declarations: [
        SecuredDirective,
        UppercaseDirective,
        LowercaseDirective,
        CurrencyConverterDirective,
        NormalCaseDirective,
        CounterDirective,
        TrimCommasDirective,
        TitlecaseDirective,
        ChangesDirective,
        ValidationsDirective
    ],

    providers: [],

    exports: [SecuredDirective,
        UppercaseDirective,
        LowercaseDirective,
        CurrencyConverterDirective,
        NormalCaseDirective,
        CounterDirective,
        TrimCommasDirective,
        TitlecaseDirective,
        ChangesDirective,
        ValidationsDirective
    ]

})

export class DirectiveModules { }
