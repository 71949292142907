import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router, CanActivate, CanActivateChild } from "@angular/router";
import { Observable } from "rxjs";
import { AuthGuardService } from "../../../../login-service/auth-guard.service";
import { AuthService } from "../../../../login-service/auth.service";
import { SIBConstants } from "../../../../shared/constants/SIBConstant";
import { NotificatoinsService } from '../../../../services/notifications/notifications.service';

@Injectable()
export class CanActivatePassbookGuardService implements CanActivate {

    constructor(
        private notificationServcie: NotificatoinsService,
        private ag: AuthGuardService,
        private as: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this.ag.canActivate(route, state)) {
            if (this.as.checkPermission(SIBConstants.READ_PASSBOOK)) {
                return true;
            } else {
                this.notificationServcie.info('Not Authorized', 'Permission not allowed');
            }
        }
    }
}
