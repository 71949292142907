<!-- <ul>
  <div class="wrapper" *ngFor="let task of _taskCards">
    <li class="ribbon"> </li>
  </div>
</ul> -->
<div class="wrapper" *ngFor="let task of tasksCards" (click)="filterData(task)">
  <!-- <span
    class="task-name">{{setTaskName(task.name)}}
  </span> -->
  <div class="ui-g">
    <div class="card-count ui-g-12 task-count">
      {{task.count}}
    </div>
    <div class="card-status ui-g-12 task-name">
      {{setTaskName(task.name)}}
    </div>
  </div>
  <div class="ribbon">
    <span class="task-type">{{setTaskType(task)}}</span>
  </div>
</div>