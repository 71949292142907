import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { contractModuleWithApp } from "../contract-module.reducers";
import { ContractActions } from "../actions/contract-actions";

@Injectable()
export class KioskActionDispatcher {


    constructor(
        private store: Store<contractModuleWithApp>,
        private contractActions: ContractActions
    ) {

    }

    createHoardingFromContract(kiosk: any) {
        this.store.dispatch(this.contractActions.createHoardingFromContract(kiosk))
    }

}