import { Http, Response } from "@angular/http";
// import { SibApiService } from "../../services/api/sib-api.service";
import { Injectable, Injector } from "@angular/core";
import 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { Observable } from "rxjs";
import { Industry } from "../../modals/industry/industry";

@Injectable()

export class IndustryService extends ApiService<any, any, any> {
    urlPath: string = 'industries';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    getIndustries() {
        if (navigator.onLine) {
            return this.Http.get(this.baseUrl + 'industries');

        }
    }
}
