import { Injectable } from "@angular/core";
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router, NavigationStart, RouterEvent } from "@angular/router";
import { Observable } from "rxjs";
import { ConfirmationService } from "primeng/primeng";
import { SuperbillViewComponent } from "../superbill-view/superbill-view.component";
import { PlatformLocation } from "@angular/common";


@Injectable()
export class CanDeactivateSuperBillViewGuardService implements CanDeactivate<SuperbillViewComponent> {

    constructor(
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private router: Router,
        private location: PlatformLocation
    ) { }

    canDeactivate(component: SuperbillViewComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (nextState.url.includes("generateBill") && !component.superbillInvoiceGrid.generatePressed) {
            this.router.navigate(['/billings']);
        } else {
            return true;
        }
    }
}

