import { CommonModule } from "@angular/common";
import { HttpModule } from "@angular/http";
import { ConfirmDialogModule, ConfirmationService, ButtonModule, SharedModule, ProgressSpinnerModule, InputTextModule, TabViewModule, DropdownModule } from "primeng/primeng";
import { NgModule } from '@angular/core';
import { DirectiveModules } from '../../../modules/directive.modules';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CampaignSummaryTemplateComponent } from './campaign-summary-template.component';
import { CurrencyMainPipe } from '../../helpers/currency.pipe/currency.main.pipe';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        ProgressSpinnerModule,
        TabViewModule,
        DirectiveModules,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        DropdownModule,
        ConfirmDialogModule,
        CurrencyMainPipe
    ],

    declarations: [
        CampaignSummaryTemplateComponent,
    ],

    exports: [
        CampaignSummaryTemplateComponent
    ],

    providers: [
        ConfirmationService,
    ]
})

export class CampaignSummaryTemplateModule { }
