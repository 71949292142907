import { ApiService } from "../base-service/api.service";
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ContractHoardingService extends ApiService<any, any, any> {
    urlPath: string = 'hoardings/saveAll';

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
    }
}
