import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService, BASE_URL } from "../base-service/api.service";
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentService extends ApiService<any, any, any> {

  baseUrl: string;


  constructor(private Http: HttpClient, private Injector: Injector) {
    super(Http, Injector);
    this.baseUrl = this.Injector.get(BASE_URL);
  }
  uploadDocument(files, id) {
    let body = JSON.stringify(files)
    if (navigator.onLine) {
      return this.Http.post(this.baseUrl + 'contracts/uploadDocument/' + id, body, { headers: new HttpHeaders().set('Content-Type', 'application/json') }).pipe(map((response) => {
        return response;
      }))
    }

  }

  uploadHoardingDocument(files, id) {
    let body = JSON.stringify(files)
    if (navigator.onLine) {
      return this.Http.post(this.baseUrl + 'hoardings/uploadDocument/' + id, body, { headers: new HttpHeaders().set('Content-Type', 'application/json') }).pipe(map((response) => {
        return response;
      }))
    }

  }

  uploadHoardingGroupsDocument(files, id) {
    let body = JSON.stringify(files)
    if (navigator.onLine) {
      return this.Http.post(this.baseUrl + 'hoardingGroups/uploadDocument/' + id, body, { headers: new HttpHeaders().set('Content-Type', 'application/json') }).pipe(map((response) => {
        return response;
      }))
    }

  }

  uploadKiosksDocument(files, id) {
    let body = JSON.stringify(files)
    if (navigator.onLine) {
      return this.Http.post(this.baseUrl + 'kiosks/uploadDocument/' + id, body, { headers: new HttpHeaders().set('Content-Type', 'application/json') }).pipe(map((response) => {
        return response;
      }))
    }

  }
}
