import { GridColumn, EditableGridFieldTypes, Alignment } from '../../components/sib-forms/grid/grid.config';
import * as utils from '../../helpers/utils';
import * as mapUtils from '../../helpers/map.utils';
import { CurrencyHelperPipe } from '../../shared/helpers/currency.pipe/currencyHelperPipe';
import { RentItemCategory } from '../analytics/rent-item-category-enum';

export const ROIInventoryDataColumns: Array<GridColumn> = [

  {
    field: 'srNo',
    name: 'srNo',
    header: 'Sr',
    required: true,
    width: '2vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: false,
    type: EditableGridFieldTypes.CUSTOM,
  },
  {
    field: 'customId',
    name: 'customId',
    header: 'HID',
    required: true,
    // width: '4vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.customId) {
        return data.customId;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'city',
    name: 'city',
    header: 'City',
    required: true,
    width: '7vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.city) {
        return data.city;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'location',
    name: 'location',
    header: 'Location',
    required: true,
    width: '20vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.location) {
        return data.location;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'size',
    name: 'size',
    header: 'Size',
    required: true,
    width: '4.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    type: EditableGridFieldTypes.TEXT,
    displayFn: (data) => {
      if (data && data.size) {
        return data.size;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'billingName',
    name: 'billingName',
    header: 'Billing Name',
    required: true,
    width: '7vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.billingName) {
        return data.billingName;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'displayName',
    name: 'displayName',
    header: 'Display Name',
    required: true,
    width: '10vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: false,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.displayName) {
        return data.displayName;
      } else {
        return '-';
      }
    },
  },
  {
    field: 'ranges[0]',
    name: 'ranges[0]',
    header: 'Start Date',
    required: true,
    width: '5.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.DATE,
    displayFn: (data) => {
      if (data && data.ranges && data.ranges.length) {
        return new Date(data.ranges[0]);
      } else {
        return '-';
      }
    },
  },
  {
    field: 'ranges[1]',
    name: 'ranges[1]',
    header: 'End Date',
    required: true,
    width: '5.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.DATE,
    displayFn: (data) => {
      if (data && data.ranges && data.ranges.length) {
        return new Date(data.ranges[1]);
      } else {
        return '-';
      }
    },
  },
  {
    field: 'amount',
    name: 'amount',
    header: 'RSD',
    required: true,
    width: '5.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.NUMBER,
    filter: {
      matchMode: 'relational',
      placeHolder: 'Search',
      custom: false,
    },
    displayFn: (data) => {
      if (data && data.amount >= 0) {
        return new CurrencyHelperPipe().transform(utils.getDecimalNumFixedToTwoPlaces(data.amount));
      } else {
        return '-';
      }
    },
  },
  {
    field: 'rentItemCategory',
    name: 'rentItemCategory',
    header: 'Type',
    required: true,
    width: '5.5vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.TEXT,
    filter: {
      matchMode: 'is',
      placeHolder: 'Search',
      custom: true,
    },
    displayFn: (data) => {
      if (data && data.rentItemCategory) {
        return RentItemCategory[data.rentItemCategory];
      } else {
        return '-';
      }
    },
  },
  {
    field: 'invoiceId',
    name: 'invoiceId',
    header: 'Invoice',
    required: true,
    width: '9vw',
    editable: false,
    hidden: false,
    default: true,
    permanent: true,
    sortable: true,
    type: EditableGridFieldTypes.CUSTOM,
    filter: {
      matchMode: 'contains',
      placeHolder: 'Search',
      custom: false,
    },
  },
];

export const ROIInventoryDataMap = (() => {
  return mapUtils.createMap(ROIInventoryDataColumns);
})();
