import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, ButtonModule, InputTextModule, SharedModule, TooltipModule } from "primeng/primeng";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { InputTrimModule } from "../../../directives/input-trim/input-trim.module";
import { GMapModule } from "primeng/components/gmap/gmap";
// import {} from 'googlemaps';
import { PreviewSendDialogComponent } from "./preview-send-dialog.component";
import { SibPPTPreviewModule } from "../sib-ppt-preview/sib-ppt-preview.module";
import { SibEmailModule } from "../sib-email/sib-email.module";


@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        HttpModule,
        ButtonModule,
        SharedModule,
        InputTextModule,
        TooltipModule,
        InputTrimModule,
        GMapModule,
        SibPPTPreviewModule,
        SibEmailModule

    ],

    declarations: [
        PreviewSendDialogComponent
    ],

    exports: [
        PreviewSendDialogComponent,
    ]
})

export class PreviewSendDialogModule { }