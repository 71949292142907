/**
* @file PlanModuleState
* @author ParthL@meditab.com
* @copyright Meditab Software 2017
*/
import { ActionReducerMap } from "@ngrx/store";
import { BillingState, billingReducer } from "./reducers/billing.reducers";
import { AppModuleState } from "../app state/app-module.reducers";


export interface BillingModuleState {
    billings: BillingState;
}

export const reducers: ActionReducerMap<BillingModuleState> = {
    billings: billingReducer
};

export interface BillingModuleStateExtended extends AppModuleState {
    billings: BillingModuleState;
}
