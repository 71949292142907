<div class="ui-g">
    <div class="ui-g-6">
        <div class="card">
            <sib-customer-details></sib-customer-details>
        </div>
    </div>
    <div class="ui-g-3">
        <div class="card">
            <sib-customer-plans></sib-customer-plans>
        </div>
    </div>
    <div class="ui-g-3">
        <div class="card">
            <sib-customer-campaigns></sib-customer-campaigns>
        </div>
    </div>
</div>