<div class="uploadDocument">
    <p-dialog header="Upload Document(s)" *ngIf="displayUploadDocumentDialog" [(visible)]="displayUploadDocumentDialog"
        [modal]="true" showEffect="fade" [width]="900" class="dialogImageUpload" (onShow)="showUploadDocumentDialog()"
        (onHide)="hideUploadDocumentDialog()">
        <div *ngIf="isLoading">
            <div class="loading">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </div>
        <div>
            <span>
                <p-fileUpload #fileRef mode="basic" [chooseLabel]="'ADD DOCUMENT'" [maxFileSize]="1000000" name="demo[]"
                    accept="*" (onSelect)="onFileSelection($event)" [auto]="true" [disabled]="!isDocumentTypeSelected"
                    pTooltip="{{!isDocumentTypeSelected ? 'Select Document Type First' : (files.length > 0 ? (files.length + ' file(s) selected') : 'No file Chosen')}}">
                </p-fileUpload>
            </span>
            <span class="ui-float-label float-right margin-top-15">
                <p-dropdown appendTo="body" [options]="documentTypeDropdown" [(ngModel)]="documentType"
                    placeholder="Document Type" [style]="{'width': '100%'}"
                    (onChange)="onDocumentTypeSelection($event)">
                </p-dropdown>
                <label style="color:#222222">Document Type</label>
            </span>

        </div>
        <div class="ui-g-12 padding-0">
            <div *ngIf="purchaseOrdersWrapper.length > 0" class="font-size-1-1 text-align-center margin-bottom-8">
                <strong class="border-bottom">Purchase Orders</strong>
            </div>
            <div class="card bg-color outer-card-vertical-scroller">
                <form #uploadPoForm="ngForm" autocomplete="off">
                    <div class="unorderedList ui-g-4 padding-0 padding-right-5 padding-left-5"
                        *ngFor="let po of purchaseOrdersWrapper; let i=index">
                        <div class="ui-g-12 card inner-card-vertical-scroller-po">
                            <div class="ui-g-12 padding-0">
                                <div class="ui-g-11 word-break-wrap padding-0 po-name">
                                    {{po.purchaseOrder.name}}
                                </div>
                                <div class="ui-g-1 padding-0" pTooltip="Remove the file">
                                    <i class="fa fa-trash-o info-red" aria-hidden="true"
                                        (click)="removeFile(i, true)"></i>
                                </div>
                            </div>
                            <div class="ui-g-12">
                                <div class="ui-g-12 setInput padding-left-0 padding-top-5">
                                    <span class="md-inputfield">
                                        <input #name="ngModel" id="name" type="text" trim="blur"
                                            [(ngModel)]="po.newName" autocomplete="off" pInputText name="newName{{i}}"
                                            title=" " [style]="{'width':'100%'}" />
                                        <label for="newName">New Name</label>
                                    </span>
                                </div>
                            </div>
                            <div class="ui-g-12 padding-bottom-0">
                                <div class="ui-g-6 setInput padding-left-0 padding-top-0">
                                    <span class="md-inputfield">
                                        <input #poNumber="ngModel" id="poNumber" type="text" trim="blur"
                                            [(ngModel)]="po.purchaseOrder.poNumber" pInputText name="poNumber{{i}}"
                                            maxlength="50" title=" " required />
                                        <label for="poNumber">PO Number
                                            <span class="required">*</span>
                                        </label>
                                    </span>
                                </div>
                                <div class="ui-g-6 padding-top-0">
                                    <span class="ui-float-label">
                                        <p-calendar [inputStyle]="{'width':'100%'}" #poDate="ngModel" id="poDate"
                                            name="poDate{{i}}" showIcon="true" [utc]="true"
                                            [(ngModel)]="po.purchaseOrder.poDate" dateFormat="ddMy" trim="blur"
                                            monthNavigator="true" yearNavigator="true" [yearRange]="'2017:2050'"
                                            required appendTo="body"></p-calendar>
                                        <label for="endDate">PO Date
                                            <span class="required">*</span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div class="ui-g-12 padding-0">
                                <div class="padding-left-0">
                                    <p-progressBar [value]="findFile(po.purchaseOrder.name)"></p-progressBar>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <div class="ui-g-12 padding-0">
            <div *ngIf="documentsWrapper.length > 0" class="font-size-1-1 text-align-center margin-bottom-8">
                <strong class="border-bottom">Documents</strong>
            </div>
            <div class="card bg-color outer-card-vertical-scroller">
                <form #uploadDocForm="ngForm" autocomplete="off">
                    <div class="unorderedList ui-g-4 padding-0 padding-right-5 padding-left-5"
                        *ngFor="let po of documentsWrapper; let i=index">
                        <div class="ui-g-12 card inner-card-vertical-scroller">
                            <div class="ui-g-12 padding-0">
                                <div class="ui-g-11 word-break-wrap padding-0 po-name">
                                    {{po.purchaseOrder.name}}
                                </div>
                                <div class="ui-g-1 padding-0" pTooltip="Remove the file">
                                    <i class="fa fa-trash-o info-red" aria-hidden="true"
                                        (click)="removeFile(i, false)"></i>
                                </div>
                            </div>
                            <div class="ui-g-12">
                                <div class="ui-g-12 setInput padding-left-0">
                                    <span class="md-inputfield">
                                        <input #name="ngModel" id="name" type="text" trim="blur"
                                            [(ngModel)]="po.newName" autocomplete="off" pInputText name="newName{{i}}"
                                            title=" " />
                                        <label for="newName">New Name</label>
                                    </span>
                                </div>
                            </div>
                            <div class="ui-g-12 padding-0">
                                <div class="padding-left-0">
                                    <p-progressBar [value]="findFile(po.purchaseOrder.name)"></p-progressBar>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <p-footer>
            <div class="ctext-center ui-g-12">
                <button class="mtab-primary margin-hori-5 " type="button" pButton label=" UPLOAD ALL "
                    (click)="uploadAllFiles()"
                    [disabled]="!uploadPoForm.form.valid || !uploadDocForm.form.valid || combinedWrapper.length === 0 || firebaseUrlSaved"></button>
                <button class="mtab-save" type="button" pButton label=" SAVE " (click)="saveUploadedFiles()"
                    [disabled]="!uploadPoForm.form.valid || !uploadDocForm.form.valid || combinedWrapper.length === 0 || !firebaseUrlSaved"></button>
            </div>
        </p-footer>

    </p-dialog>
</div>