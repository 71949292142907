import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { Http } from "@angular/http";

@Injectable()
export class SystemService extends ApiService<any, any, any> {
    urlPath: string = 'systemProperties';

    constructor(private Http: HttpClient, Injector: Injector, private http: Http) {
        super(Http, Injector);
    }
}