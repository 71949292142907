import { Injectable, Injector } from "@angular/core";
import { Http, Response } from "@angular/http";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Area } from "../../modals/Area/area";
import { ApiService, BASE_URL } from "../../services/base-service/api.service";
import { catchError, map } from 'rxjs/operators';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from '../../services/shared/notifications-messages';

@Injectable()
export class SuperBillsService extends ApiService<any, any, any> {
    urlPath: string = 'superBills';
    baseUrl: string;

    constructor(private Http: HttpClient, private Injector: Injector, private http: Http) {
        super(Http, Injector);
        this.baseUrl = this.Injector.get(BASE_URL);
    }

    downloadSuperbillListXls(fileType, data) {
        let options = {};
        if (fileType === 'XLS') {
            const fileUrl = this.baseUrl + 'superBills/exportGrid';
            options = { responseType: "arraybuffer" };
            return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                return response;
            }), catchError((err) => {

                const errorObject = ErrorUtil.getErrorObject(err);
                if (errorObject.code === 417 || errorObject.code === 412) {
                    this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                } else {
                    this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                }
                return err;
            }));
        }
    }
}