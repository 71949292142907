import { Component, ViewEncapsulation, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "../../../../../../node_modules/rxjs/Rx";
import { CustomerProfilePageService } from "../services/customer-profile-page.service";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
    selector: 'sib-customer-campaigns',
    templateUrl: './customer-campaigns.component.html',
    styleUrls: ['./customer-campaigns.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CustomerProfilePageService]
})
export class CustomerCampaignsComponent implements OnInit, OnDestroy {

    subscription: Subscription[] = [];
    customerId: string;

    constructor(
        private _route: ActivatedRoute,
        private customerProfilePageService: CustomerProfilePageService
    ) {
        this.subscription.push(this._route.params.subscribe((params: Params) => {
            if (params) {
                this.customerId = params['customerId'];
            }
        }));
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
    }
}
