import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { ErrorUtil } from '../../helpers/error.utils';
import { NotificationsMessages } from './notifications-messages';

@Injectable()
export class KioskService extends ApiService<any, any, any> {
    urlPath: string = 'kiosks';

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
    }

    downloadFile(fileType, data) {
        if (navigator.onLine) {
            let options = {};

            if (fileType === 'XLSX') {
                const fileUrl = this.baseUrl + 'kiosks/exportGrid/XLSX';
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }), catchError((err) => {

                    const errorObject = ErrorUtil.getErrorObject(err);
                    if (errorObject.code === 417 || errorObject.code === 412) {
                        this.notificationServcie.info(errorObject.message ? errorObject.message : NotificationsMessages.TRY_AGAIN, NotificationsMessages.INFORMATION);
                    } else {
                        this.notificationServcie.error(errorObject.message ? errorObject.message : NotificationsMessages.TECHNICAL_ISSUE, NotificationsMessages.ERROR)
                    }
                    return err;
                }));
            }
        }
    }
}    