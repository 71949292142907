import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { SelectItem } from '../../../../../node_modules/primeng/primeng';
import { SetGridService } from './services/setGrid.service';
import { ModuleEnum } from '../../constants/module-enum';
import * as utils from '../../../helpers/utils';
import { ActivityLogModuleEnum } from '../../constants/activity-log-module-enum';
import { ActionEventEnum } from '../../constants/action-event-enum';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../constants/SIBConstant';

@Component({
    selector: 'sib-activity-log',
    templateUrl: './activity-log.component.html',
    styleUrls: ['./activity-log.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ActivityLogComponent implements OnInit, OnDestroy {

    startDate = new Date();
    endDate = new Date();
    currentDate = new Date();
    modules: SelectItem[] = [];
    selectedModule: any;
    actions: SelectItem[] = [];
    selectedAction: any;
    // showGrid = false; // to show the grid
    showGrid = true; // to show the grid

    minutes: number;
    seconds: number;
    timerValue = 60;
    counterId; // to stop the timer when the user moves out of this module

    @ViewChild('activityForm')
    form;

    @ViewChild('activityGrid')
    gridViewRef;

    constructor(
        private setGridService: SetGridService,
        private pageTitle: Title
    ) { }

    ngOnInit() {
        let appTitle = utils.getAppTitle();
        !appTitle ? this.pageTitle.setTitle(SIBConstants.ACTIVITY_MENU) : this.pageTitle.setTitle(SIBConstants.ACTIVITY_MENU + ' - ' + appTitle);
        this.currentDate = new Date();
        this.startDate = new Date(this.startDate.setDate(this.currentDate.getDate() - 10));
        this.endDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
        // this.modules.push({ label: "ALL", value: "ALL" });
        // Object.keys(ModuleEnum).forEach((key) => {
        //     this.modules.push({ label: ModuleEnum[key], value: ModuleEnum[key] });
        // });
        this.modules = utils.createDropdown(ActivityLogModuleEnum, true);
        this.selectedModule = this.modules[0].value;
        this.actions = utils.createDropdown(ActionEventEnum, true);
        this.selectedAction = this.actions[0].value;
        // this.checkFormValidity();
        /* this.countDown();
        this.counterId = setInterval(() => {
            this.countDown();
        }, 1000); */
    }

    onStartDateChange(event) {
        if (new Date(this.startDate) > new Date(this.endDate)) {
            this.endDate = new Date(this.startDate);
        }
        // this.checkFormValidity();
    }

    onEndDateChange(event) {
        // this.checkFormValidity();
    }

    onDropDownChange(event) {
        // this.checkFormValidity();
    }

    checkFormValidity() {
        // if (this.form.valid && this.endDate !== null && this.endDate !== undefined) {
        // if (this.endDate !== null && this.startDate !== null) {
        //     this.showGrid = true;
        // } else {
        //     this.showGrid = false;
        // }
    }

    /* countDown() {
        this.minutes = Math.floor(this.timerValue / 60);
        this.seconds = Math.floor(this.timerValue % 60);
        this.timerValue -= 1;
        if (this.timerValue === -1) {
            this.refreshGrid();
        }
    } */

    resetTimer() {
        this.timerValue = 60;
    }

    refreshGrid() {
        this.gridViewRef.refreshGrid();
        this.resetTimer();
    }

    ngOnDestroy() {
        if (this.counterId) {
            clearInterval(this.counterId);
        }
    }

}
