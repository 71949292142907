import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../base-service/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class InvoicesPrintConfigurationService extends ApiService<any, any, any> {
    urlPath: string = 'invoices';

    constructor(private Http: HttpClient, private Injector: Injector) {
        super(Http, Injector);
    }
}    