
import { Injectable } from '@angular/core';
import { GoogleChartsBaseService } from './chart.base.service';
import { AreaChartConfig } from '../../modals/charts/areaChart.config';


declare var google: any;

@Injectable()
export class GoogleAreaChartService extends GoogleChartsBaseService {
    constructor() { super(); }


    public buildAreaChart(elementId: String, data: any[], config: AreaChartConfig): void {
        const chartFunc = () => new google.visualization.AreaChart(document.getElementById(elementId + ''));
        const options = {
            title: config.title,
            chartArea: config.chartArea,
            legend: config.chartLegend,
            backgroundColor: config.backgroundColor,
            height: config.height,
            width: config.width,
            hAxis: config.hAxis,
            vAxis: config.vAxis,
            tooltip: config.tooltip,
            pointSize: config.pointSize,
            annotations: config.annotations
        };

        this.buildChart(data, chartFunc, options, config);
    }
}
