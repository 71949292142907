
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { GridConfig, GridColumn, EditableGridFieldTypes, Alignment, SelectionMode, GridPaginationEvent } from '../../components/sib-forms/grid/grid.config';
import { MountingMaster } from '../../modals/mounting/mounting-master';
import { DateUtil } from '../../helpers/date.util';
import { CampaignItemStatusEnum } from '../../shared/constants/campaign-item-status-enum';
import { MountImageStatus } from '../../shared/constants/mount-image-status-enum';
import { PublicPageService } from '../../services/shared/publicpage.service';
import { ActivatedRoute, Params } from '@angular/router';
import { GridComponent } from '../../components/sib-forms/grid/grid.component';
import { of as observableOf, Observable, Subscription, Subject } from 'rxjs';
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import * as _ from "lodash";
import * as utils from '../../helpers/utils';
import { CampaignService } from '../../services/shared/campaign.service';
import { PaginationData } from '../../modals/paginationdata';
import { ImageDataDisplay } from '../../modals/image/image-data';
import { PhotoDialogComponent } from '../../shared/components/photo-dialog/photo-dialog.component';

@Component({
  selector: 'sib-mountedsites',
  templateUrl: './mountedsites.component.html',
  styleUrls: ['./mountedsites.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MountedsitesComponent implements OnInit {
  @ViewChild('sibGrid')
  gridViewChildRef: GridComponent<MountingMaster>;

  @ViewChild(PhotoDialogComponent)
  photoDialogComponent: PhotoDialogComponent;

  mountedSitesGroup: FormGroup;
  gridConfig: GridConfig<MountingMaster> = new GridConfig<MountingMaster>();
  campaignId: string;
  x_tenant: string;
  totalRecords: number;
  filteredItems: MountingMaster[] = [];
  lightTypeDropdown: any[] = [];
  subscription: Subscription;
  filteredMountingItems: MountingMaster[] = [];
  imageData: any = [];
  // @Input() campaignMountingItems: MountingMaster[];

  _campaignMountingItems: MountingMaster[] = [];
  @Input() set campaignMountingItems(campaignMountingItems) {
    this._campaignMountingItems = campaignMountingItems;
    this.filteredItems = _.cloneDeep(this._campaignMountingItems);
    this.gridViewChildRef.refresh();
  }

  get campaignMountingItems() {
    return this._campaignMountingItems;
  }

  @Output() eEmitTotalMountingItems: EventEmitter<any> = new EventEmitter();
  @Output() eEmitFilteredMountedItems: EventEmitter<MountingMaster[]> = new EventEmitter();
  @Output() eEmitImages: EventEmitter<any> = new EventEmitter();

  searchEvent: PaginationData = {
    filters: {},
    globalFilter: '',
    // sortField: '',
    // sortOrder: 1,
    multiSortMetas: []
  };

  columns: Array<GridColumn> = [
    {
      field: 'srNo',
      name: 'srNo',
      header: 'Sr ',
      required: true,
      width: '2vw',
      editable: false,
      hidden: false,
      default: true,
      permanent: true,
      sortable: false,
      styleClass: 'sr-number',
      type: EditableGridFieldTypes.CUSTOM
    },

    {
      field: 'customId',
      name: 'HID',
      header: 'HID',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'city',
      name: 'city',
      header: 'City',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '5vw',
      default: true,
      sortable: true,
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },

    {
      field: 'location',
      name: 'location',
      header: 'Location',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '15vw',
      default: true,
      sortable: true,
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'size',
      name: 'size',
      header: 'Size',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      default: true,
      sortable: true,
      width: '5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
    },
    {
      field: 'lightType',
      name: 'lightType',
      header: 'Light',
      required: true,
      editable: false,
      sortable: true,
      width: '3vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: true,
      },

      displayFn: (data) => {
        if (data.lightType) {
          return HoardingLightEnum[data.lightType]
        } else {
          return '-';
        }
      }
    },

    {
      field: 'mountingItems.creativeName',
      name: 'creativeName',
      header: 'Creative Name',
      required: true,
      editable: false,
      sortable: true,
      width: '9vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      filter: {
        matchMode: 'contains',
        placeHolder: 'Search',
        custom: false,
      },
      displayFn: (data) => {
        if (data.mountingItems.creativeName && data.mountingItems.creativeName !== "Default") {
          return data.mountingItems.creativeName;
        } else {
          return '-';
        }
      },
    },
    {
      field: 'itemStartDate',
      name: 'itemStartDate',
      header: 'Item Start Date',
      required: true,
      editable: false,
      sortable: true,
      width: '6vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.mountingItems.creativeName && data.mountingItems.creativeName !== "Default") {
          return DateUtil.dategridFormatter(data.mountingItems.date)
        }
        if (data.itemStartDate) {
          return DateUtil.dategridFormatter(data.itemStartDate);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'mountingItems.date',
      name: 'mountedDate',
      header: 'Mounted Date',
      required: true,
      editable: false,
      sortable: true,
      hidden: false,
      permanent: true,
      width: '5.5vw',
      styleClass: 'word-break-wrap',
      type: EditableGridFieldTypes.TEXT,
      displayFn: (data) => {
        if (data.mountingItems.date && (data.mountingItems.imageStatus === 'DONE' || data.mountingItems.imageStatus === 'WITHOUT_IMAGE')) {
          return DateUtil.dategridFormatter(data.mountingItems.date);
        } else {
          return '-';
        }
      },
    },
    {
      field: 'mountingItems.images',
      name: 'image',
      header: 'Photos',
      required: true,
      editable: false,
      hidden: false,
      permanent: true,
      width: '4vw',
      sortable: false,
      type: EditableGridFieldTypes.CUSTOM,
    },

  ]

  constructor(
    private publicPageService: PublicPageService,
    private _route: ActivatedRoute,
    private campaignService: CampaignService
  ) {
    this.subscription = this.campaignService.$search
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.filteredItems = [];
        this.filteredMountingItems = [];
        if (searchTerm) {
          searchTerm = searchTerm.toLowerCase();
          this.campaignMountingItems.forEach((item) => {
            if (item.customId.toLowerCase().includes(searchTerm) || item.district.toLowerCase().includes(searchTerm) || item.city.toLowerCase().includes(searchTerm) || item.location.toLowerCase().includes(searchTerm)) {
              this.filteredItems.push(item);
            }
          })
        } else {
          this.filteredItems = _.cloneDeep(this.campaignMountingItems);
        }

        this.filteredMountingItems = _.cloneDeep(this.filteredItems);
        this.eEmitTotalMountingItems.emit(this.filteredItems);

        this.gridViewChildRef.refresh();

      })
  }

  ngOnInit() {
    this.mountedSitesGroup = new FormGroup({
      gridControl: new FormControl()
    });
    this.setLightDropdown();
    this.setGridConfigObject();

    this.gridViewChildRef.refresh();

  }
  setGridConfigObject() {
    this.gridConfig.getDefaultModel = () => new MountingMaster();
    this.gridConfig.model = MountingMaster;
    this.gridConfig.selectionMode = SelectionMode.Multi;
    this.gridConfig.editable = true;
    this.gridConfig.expandableRows = false;
    this.gridConfig.checkboxSelection = false;
    this.gridConfig.resizableColumns = false;
    this.gridConfig.shouldAddRowOnDelete = false;
    this.gridConfig.showColorCode = true;
    this.gridConfig.lazy = false;
    this.gridConfig.enablePagination = true;
    this.gridConfig.scrollable = true;
    this.gridConfig.showLoader = false;
    this.gridConfig.sortMode = "multiple";
    this.gridConfig.multiSortMeta = this.createMetaSortData();
    this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // API call to get the data and render on the screen
      this.totalRecords = this.filteredItems.length;
      return observableOf(this.filteredItems);
    }
  }

  onFilter(event) {
    this.filteredMountingItems = event;
    this.eEmitFilteredMountedItems.emit(event);
  }

  getCustomIds() {
    this.campaignMountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignMountingItems);
    const customIds: string[] = [];
    if (this.filteredMountingItems && this.filteredMountingItems.length !== this.campaignMountingItems.length) {
      this.filteredMountingItems.forEach((item) => {
        customIds.push(item.customId);
      });
    } else {
      this.campaignMountingItems.forEach((item) => {
        customIds.push(item.customId);
      });
    }
    // console.log("cusotm ids", customIds);
    return customIds;
  }


  /**
   * get the ids for mounting items
   *
   * @returns
   * @memberof MountedsitesComponent
   */
  getIds() {
    this.campaignMountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    // this.filteredItems = _.cloneDeep(this.campaignMountingItems);
    const ids: string[] = [];
    if (this.filteredMountingItems.length && this.filteredMountingItems.length !== this.campaignMountingItems.length) {
      this.filteredMountingItems.forEach((item) => {
        if (item.mountingItems && item.mountingItems.id) {
          ids.push(item.mountingItems.id);
        }
      });
    } else {
      this.campaignMountingItems.forEach((item) => {
        // item.mountingItems.forEach((itm) => {
        //     ids.push(itm.id);
        // });
        if (item.mountingItems && item.mountingItems.id) {
          ids.push(item.mountingItems.id);
        }
      });
    }
    return ids;
  }


  setLightDropdown() {
    this.lightTypeDropdown = utils.createDropdown(HoardingLightEnum, true);
  }

  createMetaSortData() {
    return [{ field: 'city', order: 1 }, { field: 'areaName', order: 1 }];
  }

  onSort(event) {
    if (this.filteredMountingItems && this.filteredMountingItems.length) {
      this.campaignMountingItems = _.cloneDeep(this.filteredMountingItems);
    } else {
      this.campaignMountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    }
    this.filteredMountingItems = this.campaignMountingItems;
    this.eEmitFilteredMountedItems.emit(this.filteredMountingItems);
    this.searchEvent.multiSortMetas = Object.assign(this.searchEvent.multiSortMetas, event['multisortmeta']);
  }

  getMountingItems() {
    this.campaignMountingItems = this.gridViewChildRef.getDataTableInstance()['_value'];
    if (this.filteredMountingItems.length && this.filteredMountingItems.length !== this.campaignMountingItems.length) {
      return this.filteredMountingItems;
    } else {
      return this.campaignMountingItems;
    }
  }



  /**
   * emites images to public page component
   *
   * @param {*} rowData
   * @memberof MountedsitesComponent
   */
  viewImages(rowData) {
    this.eEmitImages.emit(rowData);
  }


}
