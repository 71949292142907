<div class="profile" [ngClass]="{'profile-expanded':active}">
    <a href="#" (click)="onClick($event)">
            <!-- userProfile?.picture -->
        <img class="profile-image" src="{{image}}" />
        <span class="profile-name">{{userProfile?.name}}</span>
        <i class="material-icons">keyboard_arrow_down</i>
    </a>
</div>

<ul class="ultima-menu profile-menu" [@menu]="active ? 'visible' : 'hidden'">
    <!-- <li role="menuitem">
        <a href="#" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
            <i class="material-icons">person</i>
            <span>Profile</span>
        </a>
    </li>
    <li role="menuitem">
        <a href="#" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
            <i class="material-icons">security</i>
            <span>Privacy</span>
        </a>
    </li>
    <li role="menuitem">
        <a href="#" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
            <i class="material-icons">settings_application</i>
            <span>Settings</span>
        </a>
    </li> -->
    <li role="menuitem">
        <a href="#" class="ripplelink" [attr.tabindex]="!active ? '-1' : null" (click)="logout()">
            <i class="material-icons">power_settings_new</i>
            <span>Logout</span>
        </a>
    </li>
</ul>