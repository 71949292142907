<div *ngIf="areaChartLoader" class="loading loader">
  <p-progressSpinner></p-progressSpinner>
</div>
<div class="ui-g header-text" *ngIf="showTitle">
  <h2 class="margin-0">{{title}}</h2>
</div>

<div class="multiselect-container">
  <span class="city-label"> Select City(s)</span>
  <div style="position: relative; top: 15px; ">
    <p-multiSelect [options]="citiesOptions" name="city" [(ngModel)]="selectedCities" [maxSelectedLabels]="1"
      (onPanelHide)="handleSelectedCities(selectedCities)" (onPanelShow)="filterCities(selectedCities)"
      selectedItemsLabel="{0} cities selected" defaultLabel="Choose">
    </p-multiSelect>
    <!-- <p-multiSelect [options]="citiesOptions" name="city" [(ngModel)]="selectedCities" [maxSelectedLabels]="1"
      (onChange)="handleSelectedCities($event)" selectedItemsLabel="{0} cities selected">
    </p-multiSelect> -->
  </div>
</div>

<sib-chart class="area-chart" #sibAreaChart *ngIf="areaChartData.length > 0 " [data]="areaChartData"
  [areaChartConfig]="areaChartConfig" [elementId]="areaElementId" [chartType]="'areaChart'"
  (eEmitAreaChartLoaded)="areaChartLoader = false"></sib-chart>

<div *ngIf="viewCapacityChartDrilldown && showAction">
  <sib-capacity-chart-drilldown #capacityChartDrilldown [viewCapacityChartDrilldown]="viewCapacityChartDrilldown"
    [clickedData]="chartClickedData" [isCapacityChartClicked]="isCapacityChartClicked" (eEmitIsCapacityChartClicked)="getIsCapacityChartClicked($event)">
  </sib-capacity-chart-drilldown>
</div>