
import { map } from 'rxjs/operators';

import { of as observableOf, Observable } from 'rxjs';
import { Component, ViewEncapsulation, AfterViewInit, OnDestroy, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, HostListener } from "@angular/core";
import { GridColumn, EditableGridFieldTypes, Alignment, GridConfig, SelectionMode, GridPaginationEvent } from "../../../components/sib-forms/grid/grid.config";
import { DateUtil } from "../../../helpers/date.util";
import { PurchaseOrderMaster } from "../../../modals/purchaseOrderMaster/purchaseOrderMaster";
import { GridComponent } from "../../../components/sib-forms/grid/grid.component";
import { GridActionButtonConfig } from "../../../components/sib-forms/grid/grid-action-button.config";
import { FirebaseService } from "../../../services/shared/firebase.service";
import { OverlayPanel, ConfirmationService } from "primeng/primeng";
import { DocumentService } from "./services/document.service";
import { FormGroup, FormControl } from "@angular/forms";
import { AppUrls } from "../../../services/urls";
import { EditPOService } from '../edit-po/services/edit-po.service';
import { Title } from '@angular/platform-browser';
import { SIBConstants } from '../../constants/SIBConstant';
import { ContractDocumentType } from '../../constants/contract-documen-type-enum';
import { InventoryDocumentType } from '../../constants/inventory-document-type-enum';
import * as utils from '../../../helpers/utils';

@Component({
    selector: 'sib-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ConfirmationService]
})

export class DocumentComponent implements OnInit, AfterViewInit, OnDestroy {

    isLoading = false;

    editMode = false;

    documentPage = true;

    documentGroup: FormGroup;

    totalRecords = 0;

    showCampaignCols = true;

    rowData: any;

    rowIndex: number;

    purchaseOrderMasters: PurchaseOrderMaster[] = [];

    showLoader = false; // for loader while editing document
    showPO: boolean = false;

    moduleName: string;
    @Input() set campaign(campaign) {
        this.moduleName = 'campaigns';
        this.setColumns();
        campaign.purchaseOrders.forEach((po) => {
            this.purchaseOrderMasters.push(new PurchaseOrderMaster().setPurchaseOrderMaster(campaign, po, this.moduleName));
        });
        this.totalRecords = this.purchaseOrderMasters.length;
        if (this.gridConfig.dataLoadFunction && this.documentPage) {
            this.gridViewChildRef.refresh();
        }
    }

    @Input() set plan(plan) {
        this.moduleName = 'plans';
        this.setColumns();
        plan.purchaseOrders.forEach((po) => {
            this.purchaseOrderMasters.push(new PurchaseOrderMaster().setPurchaseOrderMaster(plan, po, this.moduleName));
        });
        this.totalRecords = this.purchaseOrderMasters.length;
        if (this.gridConfig.dataLoadFunction && this.documentPage) {
            this.gridViewChildRef.refresh();
        }
    }

    @Input() set moduleData(moduleData) {
        this.setColumns();
        moduleData.documents.forEach((po) => {
            this.purchaseOrderMasters.push(new PurchaseOrderMaster().setPurchaseOrderMaster(moduleData, po));
        });
        this.totalRecords = this.purchaseOrderMasters.length;
        if (this.gridConfig.dataLoadFunction && this.documentPage) {
            this.gridViewChildRef.refresh();
        }
    }

    @Input() set moduleDocumentData(moduleDocumentData) {
        this.setColumns();
        moduleDocumentData.documents.forEach((doc) => {
            this.purchaseOrderMasters.push(doc);
        });
        this.totalRecords = this.purchaseOrderMasters.length;
        if (this.gridConfig.dataLoadFunction && this.documentPage) {
            this.gridViewChildRef.refresh();
        }
    }

    @Input() viewDocumentsFromBilling: boolean;
    @Input() viewDocumentsFromContract: boolean = false;
    @Output() eEmitToggleHeader: EventEmitter<any> = new EventEmitter();
    @Output() eEmitPurchaseOrdersToEdit: EventEmitter<any> = new EventEmitter();
    @Output() eEmitPurchaseOrdersToDelete: EventEmitter<any> = new EventEmitter();

    @ViewChild(GridComponent)
    gridViewChildRef: GridComponent<PurchaseOrderMaster>;

    @ViewChild('gridContainer')
    gridContainerRef: ElementRef;

    @ViewChild('editContainer')
    editContainerRef: ElementRef;

    gridConfig: GridConfig<PurchaseOrderMaster> = new GridConfig<PurchaseOrderMaster>();

    buttonConfig: GridActionButtonConfig;

    columns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2vw',
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'campaignId',
            name: 'campaignId',
            header: 'Campaign Id',
            required: true,
            editable: false,
            hidden: false,
            width: '3vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.campaignId) {
                    return data.campaignId;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'displayName',
            name: 'displayName',
            header: 'Display Name',
            required: true,
            editable: false,
            hidden: false,
            width: '5vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            // displayFn: (data) => {
            //     if (data.displayName) {
            //         return data.displayName;
            //     } else {
            //         return '-';
            //     }
            // },
        },
        // mayur-->creating the PO docname as the link to open the document 
        {
            field: 'purchaseOrders.name',
            name: 'purchaseOrders.name',
            header: 'Doc Name',
            required: true,
            editable: false,
            hidden: false,
            width: '4vw',
            default: true,
            styleClass: 'word-break',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.purchaseOrders && data.purchaseOrders.name) {
                    return data.purchaseOrders.name;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'purchaseOrders.po',
            name: 'purchaseOrders.po',
            header: 'Is PO',
            required: true,
            editable: false,
            hidden: false,
            width: '3vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.purchaseOrders) {
                    return data.purchaseOrders.po;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'purchaseOrders.poNumber',
            name: 'purchaseOrders.poNumber',
            header: 'PO Number',
            required: true,
            editable: false,
            hidden: false,
            width: '4vw',
            default: true,
            // styleClass: 'word-break-wrap',
            styleClass: 'word-break',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.purchaseOrders && data.purchaseOrders.poNumber) {
                    return data.purchaseOrders.poNumber;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'purchaseOrders.poDate',
            name: 'purchaseOrders.poDate',
            header: 'PO Date',
            required: true,
            editable: false,
            sortable: true,
            width: '2.3vw',
            displayFn: (data) => {
                if (data.purchaseOrders && data.purchaseOrders.poDate) {
                    return DateUtil.dategridFormatter(new Date(data.purchaseOrders.poDate));
                } else {
                    return '-';
                }
            },
        },
        // {
        //     field: 'viewPO',
        //     name: '',
        //     header: '',
        //     required: true,
        //     editable: false,
        //     width: '2vw',
        //     hidden: false,
        //     permanent: true,
        //     type: EditableGridFieldTypes.CUSTOM,
        //     align: Alignment.CENTER
        // },
        {
            field: 'editPO',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '2vw',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
        {
            field: 'delPO',
            name: '',
            header: '',
            required: true,
            editable: false,
            width: '2vw',
            hidden: false,
            permanent: true,
            type: EditableGridFieldTypes.CUSTOM,
            align: Alignment.CENTER
        },
    ];


    billingDocumentColumns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2vw',
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },

        {
            field: 'purchaseOrders.name',
            name: 'purchaseOrders.name',
            header: 'Doc Name',
            required: true,
            editable: false,
            hidden: false,
            width: '10vw',
            default: true,
            styleClass: 'word-break',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.purchaseOrders && data.purchaseOrders.name) {
                    return data.purchaseOrders.name;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'purchaseOrders.po',
            name: 'purchaseOrders.po',
            header: 'Is PO',
            required: true,
            editable: false,
            hidden: false,
            width: '3vw',
            default: true,
            styleClass: 'word-break-wrap',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.purchaseOrders) {
                    return data.purchaseOrders.po;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'purchaseOrders.poNumber',
            name: 'purchaseOrders.poNumber',
            header: 'PO Number',
            required: true,
            editable: false,
            hidden: false,
            width: '10vw',
            default: true,
            // styleClass: 'word-break-wrap',
            styleClass: 'word-break',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data.purchaseOrders && data.purchaseOrders.poNumber) {
                    return data.purchaseOrders.poNumber;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'purchaseOrders.poDate',
            name: 'purchaseOrders.poDate',
            header: 'PO Date',
            required: true,
            editable: false,
            sortable: true,
            width: '4vw',
            displayFn: (data) => {
                if (data.purchaseOrders && data.purchaseOrders.poDate) {
                    return DateUtil.dategridFormatter(new Date(data.purchaseOrders.poDate));
                } else {
                    return '-';
                }
            },
        },
        // {
        //     field: 'viewPO',
        //     name: '',
        //     header: '',
        //     required: true,
        //     editable: false,
        //     width: '4vw',
        //     hidden: false,
        //     permanent: true,
        //     type: EditableGridFieldTypes.CUSTOM,
        //     align: Alignment.CENTER
        // },
    ];

    contractDocumentColumns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr ',
            required: true,
            width: '2vw',
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'name',
            name: 'name',
            header: 'Doc Name',
            required: true,
            editable: false,
            hidden: false,
            default: true,
            styleClass: 'word-break',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data && data.name) {
                    return data.name;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'type',
            name: 'type',
            header: 'Doc Type',
            required: true,
            editable: false,
            hidden: false,
            default: true,
            styleClass: 'word-break',
            permanent: true,
            sortable: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
            displayFn: (data) => {
                if (data && data.type) {
                    return ContractDocumentType[data.type] || InventoryDocumentType[data.type];
                } else {
                    return '-';
                }
            },
        },
    ];

    constructor(
        private documentService: DocumentService,
        private firebaseService: FirebaseService,
        private confirmationService: ConfirmationService,
        private editPOService: EditPOService,
        private pageTitle: Title
    ) { }

    ngOnInit() {
        if (this.showCampaignCols) {
            let appTitle = utils.getAppTitle();
            !appTitle ? this.pageTitle.setTitle(SIBConstants.DOCUMENT_MENU) : this.pageTitle.setTitle(SIBConstants.DOCUMENT_MENU + ' - ' + appTitle);
        }
        this.isLoading = true;

        this.gridContainerRef.nativeElement.style.display = 'block';
        this.editContainerRef.nativeElement.style.display = 'none';

        this.documentGroup = new FormGroup({
            documentGridControl: new FormControl()
        });

        this.gridConfig.getDefaultModel = () => new PurchaseOrderMaster();
        this.gridConfig.model = PurchaseOrderMaster;
        this.gridConfig.editable = true;
        this.gridConfig.selectionMode = SelectionMode.None;
        this.gridConfig.expandableRows = false;
        this.gridConfig.checkboxSelection = false;
        this.gridConfig.resizableColumns = false;
        this.gridConfig.shouldAddRowOnDelete = false;
        this.gridConfig.scrollable = true;
        this.gridConfig.rowExpandMode = 'single';
        this.gridConfig.lazy = this.showCampaignCols;
        this.gridConfig.enablePagination = true;
        this.gridConfig.showColorCode = false;

        this.gridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {
            if (this.showCampaignCols) {
                // return this.documentService.getDocuments().map((response) => {
                // this.purchaseOrderMasters = response["data"];
                // this.isLoading = false;
                // this.totalRecords = this.purchaseOrderMasters.length;
                // // return response["data"];
                // return this.purchaseOrderMasters;
                // });
                return this.documentService.create(paginationEvent, paginationEvent, AppUrls.GET_ALL_DOCS).pipe(map((response) => {
                    this.purchaseOrderMasters = response["data"]['content'];
                    this.isLoading = false;
                    this.totalRecords = response['data'].totalElements;
                    return this.purchaseOrderMasters;
                }));
            } else {
                this.isLoading = false;
                return observableOf(this.purchaseOrderMasters);
            }
        };

        this.editPOService.showLoader$.subscribe((loader) => {
            if (loader !== undefined) {
                this.showLoader = loader;
            }
        });
    }

    ngAfterViewInit() {
        if (!this.showCampaignCols) {
            this.refreshGrid();
        }
    }

    setColumns() {
        this.showCampaignCols = false;
        this.columns.forEach((col) => {
            if (col.field === 'displayName' || col.field === 'campaignId') {
                col.hidden = !this.showCampaignCols;
            }
        });
        if (this.gridViewChildRef) {
            this.gridViewChildRef.setAllColumns();
        }
    }

    refreshGrid() {
        if (this.gridConfig.dataLoadFunction && this.documentPage) {
            this.gridViewChildRef.refresh();
        }
    }

    viewCampaign(row) {
        window.open('/campaigns/view/' + row.id);
    }

    viewPO(PO) {
        this.firebaseService.downloadImage(PO.name, PO.folder).then((response) => {
            window.open(response);
        });
    }

    editPO(row, index) {
        this.rowIndex = index;
        this.rowData = row;
        this.rowData = JSON.parse(JSON.stringify(row));
        this.showPO = true;
        if (!this.showCampaignCols) {
            this.gridContainerRef.nativeElement.style.display = 'none';
            this.editContainerRef.nativeElement.style.display = 'block';
            this.eEmitToggleHeader.emit(true);
        } else {
            this.editMode = true;
        }
    }

    setChangedRow(event) {
        // this.rowData.purchaseOrders = event.purchaseOrders;
        // this.rowData = JSON.parse(JSON.stringify(this.rowData));
        // this.rowData = event;
        this.purchaseOrderMasters[this.rowIndex] = event;
        this.purchaseOrderMasters = JSON.parse(JSON.stringify(this.purchaseOrderMasters));
        this.eEmitPurchaseOrdersToEdit.emit(event);
        if (this.moduleName === 'campaigns') {
            this.documentService.savePurchaseOrder(event.id, event.purchaseOrders).subscribe((response) => {
                this.refreshGrid();
            });
        } else if (this.moduleName === 'plans') {
            this.documentService.savePlanPurchaseOrder(event.id, event.purchaseOrders).subscribe((response) => {
                this.refreshGrid();
            });
        }

        this.cancelChanges();
    }

    cancelChanges() {
        if (this.editMode) {
            this.editMode = false;
        } else {
            this.gridContainerRef.nativeElement.style.display = 'block';
            this.editContainerRef.nativeElement.style.display = 'none';
            this.eEmitToggleHeader.emit(false);
        }
        this.showPO = false;
        this.refreshGrid();
    }

    delPO(row, index) {
        // api to delete the po
        // if (this.editMode) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this document?',
            accept: () => {
                // this.isLoading = true;
                this.deleteDocument(row, index);
            },
            reject: () => {
                // do nothing
            }
        });
        // } else {
        //     this.deleteDocument(row, index);
        // }
    }

    poDisplayName(rowData) {
        return rowData.purchaseOrders.name.split('.')[0];
    }

    deleteDocument(row, index) {
        let rowWrapper = { row: row, index: index };
        this.eEmitPurchaseOrdersToDelete.emit(rowWrapper);
        if (this.moduleName === undefined || this.moduleName === 'campaigns') {
            this.documentService.deleteDocument(row.id, row.purchaseOrders.name).subscribe((response: any) => {
                this.isLoading = false;
                this.purchaseOrderMasters.splice(index, 1);
                this.purchaseOrderMasters = JSON.parse(JSON.stringify(this.purchaseOrderMasters));
                this.refreshGrid();
            });
        } else if (this.moduleName === 'plans') {
            this.documentService.deletePlanDocument(row.id, row.purchaseOrders.name).subscribe((response: any) => {
                this.isLoading = false;
                this.purchaseOrderMasters.splice(index, 1);
                this.purchaseOrderMasters = JSON.parse(JSON.stringify(this.purchaseOrderMasters));
                this.refreshGrid();
            });
        } else {
            this.isLoading = false;
            this.purchaseOrderMasters.splice(index, 1);
            this.purchaseOrderMasters = JSON.parse(JSON.stringify(this.purchaseOrderMasters));
            this.refreshGrid();
        }



    }

    docDisplayName(rowData) {
        return rowData.name.split('.')[0];
    }

    ngOnDestroy() { }

}
