import { Component, OnInit } from '@angular/core';
import { DashboardUiService } from '../../service/dashboard-ui.service';
import { UserCount } from '../../../modals/dashboard/user-count';

@Component({
  selector: 'sib-user-count-card',
  templateUrl: './user-count-card.component.html',
  styleUrls: ['./user-count-card.component.scss']
})
export class UserCountCardComponent implements OnInit {
  userCount: UserCount;
  constructor(
    private dashboardUiService: DashboardUiService
  ) { }

  ngOnInit() {
    this.dashboardUiService.getUserCount()
      .subscribe((response) => {
        console.log("response is", response);
        this.userCount = response.data;
      },
      (error) => {

      }
      )
  }

}
