import { CampaignItemWrapper } from "./campaign-item-wrapper";
import { PrintingPrice } from "../plans/printingPrice";
import { MountingPrice } from "../plans/mountingPrice";
import { RentItem } from "./rent-item";
import { AdditionalItemWrapper } from "./AdditionalItemWrapper";
import { AdditionalItem } from "./additional-item";
import * as utils from '../../helpers/utils';

export class CombinedItem extends CampaignItemWrapper {
    itemStartDate: Date;
    itemEndDate: Date;
    days: number;
    type: string;
    amount: number;
    printingPrice?: PrintingPrice = new PrintingPrice();
    mountingPrice?: MountingPrice = new MountingPrice();
    printRate?: number;
    mountRate?: number;
    creative?: string;
    statusType?: string = null;
    note: string;

    setRentItem(item: RentItem, type: string): CombinedItem {
        this.type = type;
        this.itemStartDate = new Date(item.itemStartDate);
        this.itemEndDate = new Date(item.itemEndDate);
        this.days = item.days;
        this.amount = Number((item.cost).toFixed(2));
        this.id = item.id;
        this.customId = item.customId;
        this.rate = item.rate;
        this.cost = item.cost;
        this.size = item.size;
        this.squareFeet = item.squareFeet;
        this.location = item.location;
        this.city = item.city;
        this.lightType = item.lightType;
        this.printPrice = item.printPrice;
        this.mountPrice = item.mountPrice;
        this.note = item.note;
        return this;
    }

    setAdditionalItem(item: AdditionalItem, type: string): CombinedItem {
        this.type = type;
        if (type === 'Printing') {
            // this.printRate = item.printingPrice.flNlPrice > 0 ? item.printingPrice.flNlPrice : item.printingPrice.blPrice > 0 ? item.printingPrice.blPrice : 0;
            this.printRate = utils.divideDecimals(item.amount, item.squareFeet);
        }
        if (type === 'Mounting') {
            // this.mountRate = item.mountingPrice.pricePerSqFt;
            this.mountRate = utils.divideDecimals(item.amount, item.squareFeet);
        }
        this.printingPrice = item.printingPrice;
        this.mountingPrice = item.mountingPrice;
        this.creative = item.creative;
        this.amount = Number((item.amount).toFixed(2));
        this.id = item.id;
        this.customId = item.customId;
        this.rate = item.rate;
        this.cost = item.cost;
        this.size = item.size;
        this.squareFeet = item.squareFeet;
        this.location = item.location;
        this.city = item.city;
        this.lightType = item.lightType;
        this.printPrice = item.printPrice;
        this.mountPrice = item.mountPrice;
        this.note = item.note;
        return this;
    }

    // setPrintingMountingItem(type, rate, squareFeet) {
    //     this.type = type;
    //     type === 'Printing' ? this.printRate = rate : this.mountRate = rate;
    //     this.amount = rate * squareFeet
    //     this.squareFeet = squareFeet;
    //     return this;
    // }

    setPrintingMountingItem(type, rate, obj) {
        this.type = type;
        type === 'Printing' ? this.printRate = rate : this.mountRate = rate;
        this.id = type === "Printing" ? type + "@" + this.printRate : type + "@" + this.mountRate;
        // this.amount = rate * squareFeet
        // this.squareFeet = squareFeet;
        this.amount = obj.amount;
        this.squareFeet = obj.squareFeet;
        return this;
    }

    setPrintingMountingItemByAmount(type, amount, squareFeet) {
        this.type = type;
        this.amount = amount;
        type === 'Printing' ? this.printRate = Number(amount / squareFeet) : this.mountRate = Number(amount / squareFeet);
        this.squareFeet = squareFeet;
        return this;
    }
}


export class CombinePrintingItem {
    totalSqFt: number;
    printingPrice: number;
    type: string;
}