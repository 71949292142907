import { AfterViewInit, OnDestroy, Component, ViewEncapsulation, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { InvoiceTaskCard } from "../../../modals/billings/invoice-task-card";
import { TaskCardStatusEnum } from "../../../shared/constants/task-card-status.enum";
import { InvoiceCharEnum } from "../../../modals/billings/invoice-char-enum";

@Component({
    selector: 'sib-task-cards',
    templateUrl: './task-cards.component.html',
    styleUrls: ['./task-cards.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TaskCardsComponent implements OnInit, AfterViewInit, OnDestroy {

    _invoiceTaskCards: InvoiceTaskCard[] = [];
    @Input() set invoiceTaskCards(invoiceTaskCards) {
        this._invoiceTaskCards = invoiceTaskCards;
        if (this.invoiceTaskCards && this.invoiceTaskCards.length > 0) {
            if (!this.selectedFilters) {
                this.filterData(this.invoiceTaskCards[0], 0);
                // this.check(this.invoiceTaskCards[0], 0);
            }
        }
    }

    get invoiceTaskCards() {
        return this._invoiceTaskCards;
    }

    @Output() eEmitFilterSelected: EventEmitter<any> = new EventEmitter();

    index: number;

    selectedFilters: {
        filters: any,
        printed: any,
        tallyEntered: any
    };

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit() { }

    filterData(card, index) {
        this.index = index;
        const status = card.status;
        // let result = false;
        // let index = 0;
        this.selectedFilters = { filters: [], printed: "N", tallyEntered: "N" };
        if (TaskCardStatusEnum[card.status]) {
            // this.selectedFilters.forEach((filter, ind) => {
            //     if (filter === status) {
            //         result = true;
            //         index = ind;
            //     }
            // });
            this.selectedFilters.filters.push(status);
        } else {
            if (InvoiceCharEnum[card.printed] === InvoiceCharEnum.F) {
                this.selectedFilters.printed = "F";
            } else if (InvoiceCharEnum[card.tallyEntered] === InvoiceCharEnum.F) {
                this.selectedFilters.tallyEntered = "F";
            }
        }
        // if (!result) {
        //     this.selectedFilters.splice(index, 1); // removing multi select
        //     this.selectedFilters.push(status);
        // } else {
        //     this.selectedFilters.splice(index, 1);
        // }
        this.eEmitFilterSelected.emit(this.selectedFilters);
    }

    getStatus(card) {
        const status = card.status;
        if (TaskCardStatusEnum[status]) {
            return TaskCardStatusEnum[status];
        } else if (InvoiceCharEnum[card.printed] === InvoiceCharEnum.F) {
            return "Printing";
        } else if (InvoiceCharEnum[card.tallyEntered] === InvoiceCharEnum.F) {
            return "Tally Entry";
        }
    }

    check(card, index) {
        // const status = card.status;
        let result = false;
        if (this.index !== undefined && this.index === index) {
            result = true;
        }
        // if (this.selectedFilters && this.selectedFilters.filters) {
        //     this.selectedFilters.filters.forEach((filter) => {
        //         if (filter === status) {
        //             result = true;
        //         }
        //     });
        //     if (!result) {
        //         if (this.selectedFilters.printed === "F") {
        //             result = true;
        //         } else if (this.selectedFilters.tallyEntered === "F") {
        //             result = true;
        //         }
        //     }
        // }
        return result;
    }

    ngOnDestroy() { }

}
