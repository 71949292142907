import { Injectable, Injector } from "@angular/core";
import { ApiService } from "../../services/base-service/api.service";
import { HttpClient } from "@angular/common/http";
import { NotificatoinsService } from '../../services/notifications/notifications.service';
import { catchError, map } from 'rxjs/operators';
import { AppUrls } from '../../services/urls';

@Injectable()
export class DashboardService extends ApiService<any, any, any> {
    urlPath: string = 'dashboards';
    baseUrl: string;


    constructor(
        private Http: HttpClient,
        private Injector: Injector,
        private notificationServcie: NotificatoinsService, ) {
        super(Http, Injector);
    }


    /**
     * @description download ROI XLS
     * @author Divya Sachan
     * @date 2019-11-13
     * @param {*} fileType
     * @param {*} data
     * @returns
     * @memberof DashboardService
     */
    downloadRoiContractDataXls(fileType, data) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + this.urlPath + AppUrls.EXPORT_GRID + AppUrls.ROI_DATA;
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }));
            }
        }

    }

    /**
     * @description download ROI inventory XLS
     * @author Divya Sachan
     * @date 2019-11-13
     * @param {*} fileType
     * @param {*} data
     * @returns
     * @memberof DashboardService
     */
    downloadRoiContractInventoryDataXls(fileType, data) {
        if (navigator.onLine) {
            let options = {};
            if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + this.urlPath + AppUrls.EXPORT_GRID + AppUrls.ROI_ITEM_DATA + AppUrls.SLASH + data.data;
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data.searchEvent, options).pipe(map((response: any) => {
                    return response;
                }));
            }
        }

    }

    /**
     * @description download ROI unatapped contract XLS
     * @author Divya Sachan
     * @date 2019-11-15
     * @param {*} fileType
     * @param {*} data
     * @returns
     * @memberof DashboardService
     */
    downloadUntappedRoiDataXls(fileType, data) {
        if (navigator.onLine) {
            let options = {};

            if (fileType === 'XLS') {
                const fileUrl = this.baseUrl + this.urlPath + AppUrls.EXPORT_GRID + AppUrls.UNTAPPED_ROI_DATA;
                options = { responseType: "arraybuffer" };
                return this.Http.post(fileUrl, data, options).pipe(map((response: any) => {
                    return response;
                }));
            }
        }

    }

}