<div class="preview-send">
    <p-dialog header="{{subView === 'PPT'? 'PREVIEW' : 'SEND'}}" class="email-dialog" [(visible)]="display" [modal]="true" showEffect="fade"
        width="900" height="555" [resizable]="true" (onHide)="hide()">

        <div *ngIf="subView ==='PPT'">
            <!-- <sib-ppt-preview [bytePPT]="bytePPT"></sib-ppt-preview> -->
            {{changeView()}}
        </div>

        <div *ngIf="subView !== 'PPT' && subView !== 'EMAIL'">
            {{changeView()}}
        </div>

        <div *ngIf="subView === 'EMAIL'" class="email-div">
            <sib-email #email [correspondence]="correspondence" [columns]="columns" [selectedBydefault]="selectedColumns" [source]="source" [kioskPresent]="kioskPresent" [plan]="plan"></sib-email>
            {{checkFormValidity(email)}}
        </div>


        <p-footer>
            <span *ngIf="subView !== 'EMAIL'" class="ctext-right">
                <button type="button" pButton class="mtab-primary" (click)="changeView()" label="NEXT"></button>
            </span>
            <span *ngIf="subView === 'EMAIL' && lastSubView === 'PPT'" class="ctext-left">
                <!-- <button type="button" pButton class="mtab-primary" (click)="changeView()" label="PREVIOUS"></button> -->
            </span>
            <span *ngIf="subView === 'EMAIL'" class="ctext-right">
                <button class="send-email-button mtab-save" type="submit" label="SEND" pButton (click)="sendMail()" [disabled]="inValid"></button>

            </span>
        </p-footer>

    </p-dialog>
</div>