<!-- <div class="padding-0" style="width:100%; height:100%"> -->

<!-- style="width:100%; height:100%" -->
<div id="{{elementId}}"></div>
<!-- <div *ngIf="isLoading">
        <div class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div> -->

<!-- <div id="{{elementId}}"></div> -->

<!-- </div> -->