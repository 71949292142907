<div class="documentClass">
    <!-- <div *ngIf="isLoading">
        <div class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div> -->

    <div #gridContainer [ngClass]="{'gridVerticalScroller': !showCampaignCols, '': showCampaignCols}">
        <form [formGroup]="documentGroup">
            <sib-grid name="documentGrid" #sibGrid [buttons]="buttonConfig" [totalRecords]="totalRecords"
                [columns]="viewDocumentsFromBilling ? billingDocumentColumns : (viewDocumentsFromContract ? contractDocumentColumns : columns)"
                [config]="gridConfig" [showColorLegends]="false" formControlName="documentGridControl"
                [showFilter]="false" ngDefaultControl>
                <!-- [columns]="!viewDocumentsFromBilling ? columns: billingDocumentColumns" -->

                <sib-grid-column field="srNo">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <span>
                            {{rowIndex+1}}
                        </span>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="displayName">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <div class="link-wrapper">
                            <a class="link-1  after" (click)="viewCampaign(rowData)">{{rowData.displayName}}</a>
                        </div>
                    </ng-template>
                </sib-grid-column>
                <!-- Docname needed to be in the link form to open the document -->
                <sib-grid-column field="purchaseOrders.name">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <a class="link-1  after"
                            (click)="viewPO(rowData?.purchaseOrders)">{{poDisplayName(rowData)}}</a>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="name">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <a class="link-1  after" (click)="viewPO(rowData)">{{docDisplayName(rowData)}}</a>
                    </ng-template>
                </sib-grid-column>

                <sib-grid-column field="editPO">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <button pButton class="mtab-secondary c-edit-btn border-rad-2 margin-right-10"
                            (click)="editPO(rowData, rowIndex)" icon="fa fa-pencil">
                        </button>
                    </ng-template>
                </sib-grid-column>
                <!-- commented because the view PO is now provided in the docname itselft -->
                <!-- <sib-grid-column field="viewPO">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <a class="link-1  after" (click)="viewPO(rowData?.purchaseOrders)">View</a>
                    </ng-template>
                </sib-grid-column> -->

                <sib-grid-column field="delPO">
                    <ng-template #bodyTemplate let-rowData="rowData" let-rowIndex="rowIndex" let-col="col"
                        let-column="column">
                        <i class="fa fa-trash-o info-red size-16" title="Delete the PO"
                            (click)="delPO(rowData, rowIndex)" aria-hidden="true"></i>
                    </ng-template>
                </sib-grid-column>

            </sib-grid>
        </form>
    </div>

    <div #editContainer>
        <sib-edit-po *ngIf="rowData && !editMode && showPO" [row]="rowData" (eEmitRowChanged)="setChangedRow($event)"
            (eEmitCancelChanges)="cancelChanges()"></sib-edit-po>
    </div>

    <div *ngIf="editMode">
        <p-dialog #dialogRef header="Edit Document" [(visible)]="editMode" [modal]="true" showEffect="fade"
            [width]="1000" (onHide)="editMode = false">
            <!--  [positionTop]="10" -->
            <p-header>
                <span *ngIf="showLoader || true" class="process-loader">
                    <p-progressSpinner [style]="{width: '20px', height: '20px', top: '5px' }" strokeWidth="8"
                        fill="#EEEEEE" animationDuration="2s"></p-progressSpinner> Processing...
                </span>
            </p-header>
            <sib-edit-po *ngIf='editMode' [row]="rowData" (eEmitRowChanged)="setChangedRow($event)"
                (eEmitCancelChanges)="cancelChanges()"></sib-edit-po>
        </p-dialog>
    </div>

    <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd class="confirmationDialogClass">
        <p-footer>
            <button type="button" pButton class="mtab-save margin-hori-5" label="YES" (click)="cd.accept()"></button>
            <button type="button" pButton class="mtab-secondary" label="NO" (click)="cd.reject()"></button>
        </p-footer>
    </p-confirmDialog>
</div>