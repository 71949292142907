import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../services/base-service/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HoardingMapService extends ApiService<any, any, any> {

  urlPath: string = 'plans'
  constructor(
    Http: HttpClient,
    private Injector: Injector,) { 
    super(Http, Injector);
  }
}
