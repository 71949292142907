
import { of as observableOf, Observable, empty, Subscription, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy, AfterViewInit, ElementRef } from "@angular/core";
import { EditableGridFieldTypes, GridColumn, GridConfig, GridPaginationEvent, SelectionMode } from "../../components/sib-forms/grid/grid.config";
import { DateUtil } from "../../helpers/date.util";
import { FormGroup, FormControl } from "@angular/forms";
import { GridComponent } from "../../components/sib-forms/grid/grid.component";
import { GridActionButtonConfig } from "../../components/sib-forms/grid/grid-action-button.config";
import { RentItem } from "../../modals/billings/rent-item";
import { AdditionalItem } from "../../modals/billings/additional-item";
import { BillingGridService } from "../services/billing-grid.service";
import { BillingGrid } from "../../modals/billings/billing-grid";
import { BillingActionDispatcher } from "../action-dispatcher/action-dispatcher";
import { Campaign } from "../../modals/campaigns/campaign";
import { CampaignService } from "../../services/shared/campaign.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Invoice } from "../../modals/billings/invoice";
import { SelectItem, MenuItem } from "primeng/primeng";
import { AUTH_PERMISSION_URL } from "../../services/base-service/auth-permission.service";
import { CalculationService } from "../../services/shared/calculationService";
import { CampaignQueryParams } from "../../modals/queryparams/campaign-queryparams";
import { AdditionalItemWrapper } from "../../modals/billings/AdditionalItemWrapper";
import * as utils from '../../helpers/utils';
import { PurchaseOrder } from "../../modals/campaigns/purchaseOrder";
import { CurrencyHelperPipe } from "../../shared/helpers/currency.pipe/currencyHelperPipe";
import * as _ from "lodash";
import { HoardingLightEnum } from '../../shared/constants/hoarding-light-enum';
import { MediaSubCategoryEnum } from '../../shared/constants/media-sub-category-enum';
import { ActivityLogModuleEnum } from '../../shared/constants/activity-log-module-enum';
import { NotificatoinsService } from '../../services/notifications/notifications.service';

@Component({
    selector: 'sib-superbill-hoardings',
    templateUrl: './superbill-hoardings.component.html',
    styleUrls: ['./superbill-hoardings.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class SuperbillHoardingsComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() showBack = true;
    _checkbox = true;
    @Input() set checkbox(checkbox) {
        this._checkbox = checkbox;
        // this.ngOnInit();
        this.setAllGridConfigs();
    }
    _editable = true;
    @Input() set editable(editable) {
        this._editable = editable;
        this.setColums();
    }

    @Input() showSelected = false;

    @Input() activeIndex;

    _migratedInvoice = false;
    @Input() set migratedInvoice(migratedInvoice) {
        this._migratedInvoice = migratedInvoice;
        this._migratedInvoice = JSON.parse(JSON.stringify(this._migratedInvoice));
    }

    get migratedInvoice() {
        return this._migratedInvoice;
    }

    _days: number;
    @Input() set days(days) {
        this._days = days;
    }

    get days() {
        return this._days;
    }

    @Output() eEmitRentRowsSelected: EventEmitter<any> = new EventEmitter();
    @Output() eEmitPrintingRowsSelected: EventEmitter<any> = new EventEmitter();
    @Output() eEmitMountingRowsSelected: EventEmitter<any> = new EventEmitter();
    @Output() eEmitEditedRentItems: EventEmitter<any> = new EventEmitter();
    @Output() eEmitChangeSelectedRentItemsValidations: EventEmitter<any> = new EventEmitter();
    @Output() eEmitEditedPrintingItems: EventEmitter<any> = new EventEmitter();
    @Output() eEmitEditedMountingItems: EventEmitter<any> = new EventEmitter();
    @Output() eEmitDates: EventEmitter<any> = new EventEmitter();
    @Output() eEmitCampaign: EventEmitter<any> = new EventEmitter();

    @Output() eRentRowEditGridChange: EventEmitter<any> = new EventEmitter();
    @Output() eRentRowEditNumberGridChange: EventEmitter<any> = new EventEmitter();
    @Output() eRentCalenderGridChange: EventEmitter<any> = new EventEmitter();
    @Output() ePrintRowEditGridChange: EventEmitter<any> = new EventEmitter();
    @Output() ePrintRowEditNumberGridChange: EventEmitter<any> = new EventEmitter();
    @Output() eMountRowEditGridChange: EventEmitter<any> = new EventEmitter();
    @Output() eMountRowEditNumberGridChange: EventEmitter<any> = new EventEmitter();
    @Output() setSelectedRows: EventEmitter<any> = new EventEmitter();
    @Output() eEmitNoOfDays: EventEmitter<any> = new EventEmitter();
    @Output() eEmitInvoiceRentItems: EventEmitter<RentItem[]> = new EventEmitter();

    @ViewChild('noOfDays') noOfDays: ElementRef;

    isLoading = false;

    rowData: any;
    rowIndex: number;

    displayUploadPODialog = false;
    startDate = null;
    endDate = null;

    globalStartDate: Date;
    globalEndDate: Date;
    globalRate: number;
    globalTotal: number;
    globalHoardingFlNlPrice: number;
    globalHoardingBlPrice: number;
    globalKioskFlNlPrice: number;
    globalKioskBlPrice: number;
    globalHoardingMountingPrice: number;
    globalKioskMountingPrice: number;
    printingHoardingPresent = false;
    printingKioskPresent = false;
    mountingHoardingPresent = false;
    mountingKioskPresent = false;
    minGlobalStartDate: Date;
    maxGlobalEndDate: Date;
    decimalNumbers: RegExp = /^(\d*\.)?\d+$/;  // for decimal number fields
    twoPlaceDecimalNumbers: RegExp = /^(?:\d*\.\d{1,2}|\d+)$/; // for decimal number fields upto 2 places
    itemCountAfterValidationForExtendDays: number = 0;


    rentColumns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'customId',
            name: 'customId',
            header: 'HID',
            required: true,
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            width: '4vw',
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'city',
            name: 'city',
            header: 'City',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'location',
            name: 'location',
            header: 'Location',
            required: true,
            width: '10vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'size',
            name: 'size',
            header: 'Size',
            required: true,
            width: '3vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'rate',
            name: 'monthlyRate',
            header: 'Monthly Rate',
            required: true,
            width: '4vw',
            editable: this._editable,
            // editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.NUMBER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.rate) {
                    return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.rate));
                } else {
                    return 0;
                }
            },
        },
        {
            field: 'itemStartDate',
            name: 'itemStartDate',
            header: 'Start Date',
            required: true,
            width: '4.5vw',
            editable: this._editable,
            // editable: true,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.DATE,
            displayFn: (data) => {
                if (data.itemStartDate) {
                    return DateUtil.dategridFormatter(new Date(data.itemStartDate));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'itemEndDate',
            name: 'itemEndDate',
            header: 'End Date',
            required: true,
            width: '4.5vw',
            editable: this._editable,
            // editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.DATE,
            displayFn: (data) => {
                if (data.itemEndDate) {
                    return DateUtil.dategridFormatter(new Date(data.itemEndDate));
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'days',
            name: 'days',
            header: 'Days To Bill',
            required: true,
            width: '3.3vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'cost',
            name: 'cost',
            header: 'Rates for Selected Dates',
            required: true,
            width: '5.5vw',
            // editable: false,
            editable: this._editable,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.NUMBER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.cost) {
                    return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.cost));
                } else {
                    return 0;
                }
            },
        },
        {
            field: 'note',
            name: 'note',
            header: 'Notes',
            required: true,
            width: '6vw',
            editable: this._editable,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.note) {
                    return data.note;
                } else {
                    return "Enter notes";
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
    ];

    printingColumns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'customId',
            name: 'customId',
            header: 'HID',
            required: true,
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            width: '4vw',
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'city',
            name: 'city',
            header: 'City',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'location',
            name: 'location',
            header: 'Location',
            required: true,
            width: '10vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'size',
            name: 'size',
            header: 'Size',
            required: true,
            width: '3vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'lightType',
            name: 'lightType',
            header: 'Light',
            required: true,
            editable: false,
            width: '3vw',
            hidden: false,
            default: true,
            sortable: true,
            permanent: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true
            },
            displayFn: (data) => {
                if (data.lightType) {
                    return HoardingLightEnum[data.lightType];
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'creative',
            name: 'creative',
            header: 'Creative',
            required: true,
            width: '5vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.creative && data.creative !== "Default") {
                    return data.creative;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'serviceDate',
            name: 'serviceDate',
            header: 'Mounting Date',
            required: true,
            width: '3vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.serviceDate) {
                    return DateUtil.dategridFormatter(data.serviceDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'printingCost',
            name: 'printingCost',
            header: 'Printing Rate/SqFt',
            required: true,
            width: '3vw',
            editable: this._editable,
            // editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.NUMBER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.printingCost) {
                    return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.printingCost));
                } else {
                    return 0;
                }
            },
        },
        {
            field: 'amount',
            name: 'printingCharge',
            header: 'Printing Charges',
            required: true,
            width: '3vw',
            editable: this._editable,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.NUMBER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.amount) {
                    return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.amount));
                } else {
                    return 0;
                }
            },
        },
        {
            field: 'note',
            name: 'note',
            header: 'Notes',
            required: true,
            width: '6vw',
            editable: this._editable,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.note) {
                    return data.note;
                } else {
                    return "Enter notes";
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
    ];

    mountingColumns: Array<GridColumn> = [
        {
            field: 'srNo',
            name: 'srNo',
            header: 'Sr',
            required: true,
            width: '2vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: false,
            styleClass: 'sr-number',
            type: EditableGridFieldTypes.CUSTOM
        },
        {
            field: 'customId',
            name: 'customId',
            header: 'HID',
            required: true,
            editable: false,
            hidden: false,
            permanent: true,
            default: true,
            width: '4vw',
            sortable: true,
            type: EditableGridFieldTypes.CUSTOM,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false
            },
        },
        {
            field: 'city',
            name: 'city',
            header: 'City',
            required: true,
            width: '6vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'location',
            name: 'location',
            header: 'Location',
            required: true,
            width: '10vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'size',
            name: 'size',
            header: 'Size',
            required: true,
            width: '3vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
        {
            field: 'lightType',
            name: 'lightType',
            header: 'Light',
            required: true,
            editable: false,
            width: '3vw',
            hidden: false,
            default: true,
            sortable: true,
            permanent: true,
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: true
            },
            displayFn: (data) => {
                if (data.lightType) {
                    return HoardingLightEnum[data.lightType];
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'creative',
            name: 'creative',
            header: 'Creative',
            required: true,
            width: '5vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.creative && data.creative !== "Default") {
                    return data.creative;
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'serviceDate',
            name: 'serviceDate',
            header: 'Mounting Date',
            required: true,
            width: '3vw',
            editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.serviceDate) {
                    return DateUtil.dategridFormatter(data.serviceDate);
                } else {
                    return '-';
                }
            },
        },
        {
            field: 'mountingCost',
            name: 'mountingCost',
            header: 'Mounting Rate/SqFt',
            required: true,
            width: '3vw',
            editable: this._editable,
            // editable: false,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.NUMBER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.mountingCost) {
                    return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.mountingCost));
                } else {
                    return 0;
                }
            },
        },
        {
            field: 'amount',
            name: 'mountingCharge',
            header: 'Mounting Charges',
            required: true,
            width: '3vw',
            editable: this._editable,
            default: true,
            hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.NUMBER,
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
            displayFn: (data) => {
                if (data.amount) {
                    return this.currencyPipe.transform(utils.parseValueToDecimalTwoPlaces(data.amount));
                } else {
                    return 0;
                }
            },
        },
        {
            field: 'note',
            name: 'note',
            header: 'Notes',
            required: true,
            width: '6vw',
            editable: this._editable,
            default: true,
            // hidden: false,
            permanent: true,
            sortable: true,
            styleClass: 'word-break-wrap ',
            type: EditableGridFieldTypes.TEXT,
            displayFn: (data) => {
                if (data && data.note) {
                    return data.note;
                } else {
                    return "Enter notes";
                }
            },
            filter: {
                matchMode: 'contains',
                placeHolder: 'Search',
                custom: false,
            },
        },
    ];

    campaign: Campaign = new Campaign();
    campaignId: string = null;

    totalRentRecords: number;
    totalPrintingRecords: number;
    totalMountingRecords: number;

    rentGroup: FormGroup;
    printingGroup: FormGroup;
    mountingGroup: FormGroup;

    rentGridConfig: GridConfig<RentItem> = new GridConfig<RentItem>();
    rentButtonConfig: GridActionButtonConfig;

    printingGridConfig: GridConfig<AdditionalItemWrapper> = new GridConfig<AdditionalItemWrapper>();
    printingButtonConfig: GridActionButtonConfig;

    mountingGridConfig: GridConfig<AdditionalItemWrapper> = new GridConfig<AdditionalItemWrapper>();
    mountingButtonConfig: GridActionButtonConfig;

    rentItems: RentItem[] = [];
    printingItems: AdditionalItem[] = [];
    mountingItems: AdditionalItem[] = [];

    printingItemsWrapper: AdditionalItemWrapper[] = [];
    mountingItemsWrapper: AdditionalItemWrapper[] = [];

    rentUnsub: Subject<boolean> = new Subject<boolean>();
    printUnsub: Subject<boolean> = new Subject<boolean>();
    mountUnsub: Subject<boolean> = new Subject<boolean>();

    lightTypeDropdown: any[] = [];

    _selectedRentRows: any[] = [];
    @Input() set selectedRentRows(selectedRentRows) {
        this._selectedRentRows = selectedRentRows;
        this._selectedRentRows.forEach((row) => {
            row.cost = utils.getDecimalNum(row.cost);
        });
        this.setRentGridGlobalItems();
    }
    _selectedPrintingRows: any[] = [];
    @Input() set selectedPrintingRows(selectedPrintingRows) {
        this._selectedPrintingRows = selectedPrintingRows;
        this._selectedPrintingRows.forEach((row) => {
            row.amount = utils.getDecimalNum(row.amount);
        });
        this.setPrintingGridGlobalItems();
    }
    _selectedMountingRows: any[] = [];
    @Input() set selectedMountingRows(selectedMountingRows) {
        this._selectedMountingRows = selectedMountingRows;
        this._selectedMountingRows.forEach((row) => {
            row.amount = utils.getDecimalNum(row.amount);
        });
        this.setMountingGridGlobalItems();
    }

    get selectedRentRows() {
        return this._selectedRentRows;
    }

    get selectedPrintingRows() {
        return this._selectedPrintingRows;
    }

    get selectedMountingRows() {
        return this._selectedMountingRows;
    }


    rentMinDate: Date;
    rentMaxDate: Date;

    billingGrid: BillingGrid = new BillingGrid();

    subscription: Subscription[] = [];

    @ViewChild('rentGrid')
    rentGridViewChildRef: GridComponent<RentItem>;

    @ViewChild('printingGrid')
    printingGridViewChildRef: GridComponent<AdditionalItemWrapper>;

    @ViewChild('mountingGrid')
    mountingGridViewChildRef: GridComponent<AdditionalItemWrapper>;

    constructor(
        private billingGridService: BillingGridService,
        private billingActionDispatcher: BillingActionDispatcher,
        private route: ActivatedRoute,
        private campaignService: CampaignService,
        private changeDetectorRef: ChangeDetectorRef,
        private calculationService: CalculationService,
        private router: Router,
        private currencyPipe: CurrencyHelperPipe,
        private notificationService: NotificatoinsService,
    ) {
        this.setFormGroupObjects();
    }

    ngOnInit() {
        this.getRouteId();
        this.setFormGroupObjects();
        this.setFilterDropdowns();
        this.setAllGridConfigs();
        setTimeout(() => {
            this.rentGridViewChildRef.refresh();
            this.printingGridViewChildRef.refresh();
            this.mountingGridViewChildRef.refresh();
        }, 100);
        this.subscription.push(this.billingActionDispatcher.isError.subscribe((isError) => {
            if (isError) {
                this.isLoading = false;
                this.billingActionDispatcher.resetError();
            }
        }));
        if (this._selectedRentRows.length > 0) {
            this.onRentGridRowSelect(this._selectedRentRows);
        }
        if (this._selectedPrintingRows.length > 0) {
            this.onPrintingGridRowSelect(this._selectedPrintingRows);
        }
        if (this._selectedMountingRows.length > 0) {
            this.onMountingGridRowSelect(this._selectedMountingRows);
        }
    }

    setFilterDropdowns() {
        this.setLightTypeDropdown();
    }

    setLightTypeDropdown() {
        this.lightTypeDropdown = utils.createDropdown(HoardingLightEnum, true);
    }

    getRouteId() {
        this.subscription.push(this.route.params.subscribe((value: { campaign: boolean }) => {
            if (value.campaign) {
                this.subscription.push(this.route.params.subscribe((params: { id: string }) => {
                    this.campaignId = params.id;
                    this.getCampaign();
                }));
            }
        }));
    }

    getCampaign() {
        this.subscription.push(this.campaignService.getCampaign(this.campaignId).subscribe((campaign: any) => {
            this.campaign = campaign;
            this.campaign.campaignStartDate = new Date(this.campaign.campaignStartDate);
            this.campaign.campaignEndDate = new Date(this.campaign.campaignEndDate);
            this.eEmitCampaign.emit(this.campaign);
            this.setInintialBillingGrid();
        }));
    }

    setFormGroupObjects() {
        this.rentGroup = new FormGroup({
            rentGridControl: new FormControl()
        });

        this.printingGroup = new FormGroup({
            printingGridControl: new FormControl()
        });

        this.mountingGroup = new FormGroup({
            mountingGridControl: new FormControl()
        });
    }

    setAllGridConfigs() {
        this.setRentGridConfig();
        this.setPrintGridConfig();
        this.setMountGridConfig();
    }

    setRentGridConfig() {
        this.rentGridConfig.getDefaultModel = () => new RentItem();
        this.rentGridConfig.model = RentItem;
        this.rentGridConfig.selectionMode = SelectionMode.Multi;
        // this.rentGridConfig.editable = this._editable;
        this.rentGridConfig.editable = true;
        this.rentGridConfig.expandableRows = false;
        this.rentGridConfig.checkboxSelection = this._checkbox;
        this.rentGridConfig.resizableColumns = false;
        this.rentGridConfig.shouldAddRowOnDelete = false;
        this.rentGridConfig.showColorCode = false;
        this.rentGridConfig.lazy = false;
        this.rentGridConfig.enablePagination = true;
        this.rentGridConfig.scrollable = true;

        // this.detectChanges();
        this.rentGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            if (!this.showSelected) {
                return observableOf(this.rentItems);
            } else {
                return observableOf(this._selectedRentRows);
            }
        };
    }

    setPrintGridConfig() {
        this.printingGridConfig.getDefaultModel = () => new AdditionalItemWrapper();
        this.printingGridConfig.model = AdditionalItemWrapper;
        this.printingGridConfig.selectionMode = SelectionMode.Multi;
        // this.printingGridConfig.editable = this._editable;
        this.printingGridConfig.editable = true;
        this.printingGridConfig.expandableRows = false;
        this.printingGridConfig.checkboxSelection = this._checkbox;
        this.printingGridConfig.resizableColumns = false;
        this.printingGridConfig.shouldAddRowOnDelete = false;
        this.printingGridConfig.showColorCode = false;
        this.printingGridConfig.lazy = false;
        this.printingGridConfig.enablePagination = true;
        this.printingGridConfig.scrollable = true;

        // this.detectChanges();
        this.printingGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            if (!this.showSelected) {
                return observableOf(this.printingItemsWrapper);
            } else {
                return observableOf(this._selectedPrintingRows);
            }
        };
    }

    setMountGridConfig() {
        this.mountingGridConfig.getDefaultModel = () => new AdditionalItemWrapper();
        this.mountingGridConfig.model = AdditionalItemWrapper;
        this.mountingGridConfig.selectionMode = SelectionMode.Multi;
        // this.mountingGridConfig.editable = this._editable;
        this.mountingGridConfig.editable = true;
        this.mountingGridConfig.expandableRows = false;
        this.mountingGridConfig.checkboxSelection = this._checkbox;
        this.mountingGridConfig.resizableColumns = false;
        this.mountingGridConfig.shouldAddRowOnDelete = false;
        this.mountingGridConfig.showColorCode = false;
        this.mountingGridConfig.lazy = false;
        this.mountingGridConfig.enablePagination = true;
        this.mountingGridConfig.scrollable = true;

        // this.detectChanges();
        this.mountingGridConfig.dataLoadFunction = (paginationEvent: GridPaginationEvent) => {  // for rendering the data on the screen
            if (!this.showSelected) {
                return observableOf(this.mountingItemsWrapper);
            } else {
                return observableOf(this._selectedMountingRows);
            }
        };
    }

    ngAfterViewInit() { }

    setRentGridGlobalItems() {
        let dateResult = true;
        let rateResult = true;
        this.setGlobalTotal();
        this._selectedRentRows.forEach((row) => {
            row.itemStartDate = new Date(row.itemStartDate);
            row.itemEndDate = new Date(row.itemEndDate);
            if (!row.isGlobalSet) {
                dateResult = false;
            }
            if (!row.globalRateSet) {
                rateResult = false;
            }
        });
        if (dateResult && this._selectedRentRows && this._selectedRentRows.length) {
            this.globalStartDate = this.minDate(this._selectedRentRows);
            this.globalEndDate = this.maxDate(this._selectedRentRows);
        }
        if (rateResult && this._selectedRentRows && this._selectedRentRows.length) {
            this.globalRate = this._selectedRentRows[0].rate;
        }
    }

    setGlobalTotal() {
        this.globalTotal = 0;
        this.selectedRentRows.forEach((row) => {
            this.globalTotal += utils.parseValueToDecimalTwoPlaces(Number(utils.parseValueToDecimalTwoPlaces(row.cost)));
        });
        // this.globalTotal = utils.parseValueToDecimalTwoPlaces(this.globalTotal);
        this.globalTotal = utils.getIntegerRoundedOffValue(this.globalTotal);
    }

    setPrintingGridGlobalItems() {
        let hoardingFlnlResult = true;
        let hoardingBlResult = true;
        let kioskFlnlResult = true;
        let kioskBlResult = true;
        this._selectedPrintingRows.forEach((row) => {
            if (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                this.printingHoardingPresent = true;
            }
            if (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) {
                this.printingKioskPresent = true;
            }
            if (!row.isGlobalSet) {
                switch (HoardingLightEnum[row.lightType]) {
                    case HoardingLightEnum.F_L:
                    case HoardingLightEnum.N_L: {
                        switch (MediaSubCategoryEnum[row.mediaSubCategory]) {
                            case MediaSubCategoryEnum.HOARDING:
                            case MediaSubCategoryEnum.HOARDING_GROUP: {
                                hoardingFlnlResult = false;
                                break;
                            }
                            case MediaSubCategoryEnum.KIOSK: {
                                kioskFlnlResult = false;
                                break;
                            }
                        }
                        break;
                    }
                    case HoardingLightEnum.B_L: {
                        switch (MediaSubCategoryEnum[row.mediaSubCategory]) {
                            case MediaSubCategoryEnum.HOARDING:
                            case MediaSubCategoryEnum.HOARDING_GROUP: {
                                hoardingBlResult = false;
                                break;
                            }
                            case MediaSubCategoryEnum.KIOSK: {
                                kioskBlResult = false;
                                break;
                            }
                        }
                        break;
                    }
                }
            }
        });
        if (this._selectedPrintingRows && this._selectedPrintingRows.length) {
            if (hoardingFlnlResult) {
                const index1 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP));
                const index2 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) && (item.printingPrice.flNlPrice));
                if (index1 !== -1) {
                    if (index2 !== -1) {
                        this.globalHoardingFlNlPrice = this._selectedPrintingRows[index2].printingPrice.flNlPrice;
                    } else {
                        this.globalHoardingFlNlPrice = this._selectedPrintingRows[index1].printingPrice.flNlPrice;
                    }
                }
            }
            if (hoardingBlResult) {
                const index1 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP));
                const index2 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) && (item.printingPrice.blPrice));
                if (index1 !== -1) {
                    if (index2 !== -1) {
                        this.globalHoardingBlPrice = this._selectedPrintingRows[index2].printingPrice.blPrice;
                    } else {
                        this.globalHoardingBlPrice = this._selectedPrintingRows[index1].printingPrice.blPrice;
                    }
                }
            }
            if (kioskFlnlResult) {
                const index1 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.KIOSK));
                const index2 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) && (item.printingPrice.flNlPrice));
                if (index1 !== -1) {
                    if (index2 !== -1) {
                        this.globalKioskFlNlPrice = this._selectedPrintingRows[index2].printingPrice.flNlPrice;
                    } else {
                        this.globalKioskFlNlPrice = this._selectedPrintingRows[index1].printingPrice.flNlPrice;
                    }
                }
            }
            if (kioskBlResult) {
                const index1 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.KIOSK));
                const index2 = this._selectedPrintingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) && (item.printingPrice.blPrice));
                if (index1 !== -1) {
                    if (index2 !== -1) {
                        this.globalKioskBlPrice = this._selectedPrintingRows[index2].printingPrice.blPrice;
                    } else {
                        this.globalKioskBlPrice = this._selectedPrintingRows[index1].printingPrice.blPrice;
                    }
                }
            }
        }
    }

    setMountingGridGlobalItems() {
        let hoardingMountingResult = true;
        let kioskMountingResult = true;
        this._selectedMountingRows.forEach((row) => {
            if (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                this.mountingHoardingPresent = true;
            }
            if (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.KIOSK) {
                this.mountingKioskPresent = true;
            }
            if (!row.isGlobalSet) {
                switch (MediaSubCategoryEnum[row.mediaSubCategory]) {
                    case MediaSubCategoryEnum.HOARDING:
                    case MediaSubCategoryEnum.HOARDING_GROUP: {
                        hoardingMountingResult = false;
                        break;
                    }
                    case MediaSubCategoryEnum.KIOSK: {
                        kioskMountingResult = false;
                        break;
                    }
                }
            }
        });
        if (this._selectedMountingRows && this._selectedMountingRows.length) {
            if (hoardingMountingResult) {
                const index = this._selectedMountingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP));
                if (index !== -1) {
                    this.globalHoardingMountingPrice = this._selectedMountingRows[index].mountingPrice.pricePerSqFt;
                }
            }
            if (kioskMountingResult) {
                const index = this._selectedMountingRows.findIndex(item => (MediaSubCategoryEnum[item.mediaSubCategory] === MediaSubCategoryEnum.KIOSK));
                if (index !== -1) {
                    this.globalKioskMountingPrice = this._selectedMountingRows[index].mountingPrice.pricePerSqFt;
                }
            }
        }
    }


    setGrids(billingGrid) {
        this.isLoading = true;
        this.billingActionDispatcher.getRentItems(billingGrid);
        this.billingActionDispatcher.getPrintingItems(billingGrid);
        this.billingActionDispatcher.getMountingItems(billingGrid);
        this.getRentItems();
    }

    getRentItems() {
        this.subscription.push(this.billingActionDispatcher.rentItems.pipe(takeUntil(this.rentUnsub)).subscribe((rentItems) => {
            if (rentItems !== undefined && rentItems !== null) {
                rentItems['data'].forEach((item) => {
                    item.itemStartDate = new Date(item.itemStartDate);
                    item.itemEndDate = new Date(item.itemEndDate);
                });
                this.rentItems = rentItems['data'];

                this.rentItems.forEach((item) => {
                    this._selectedRentRows.forEach((selectedItem) => {
                        if (item.rateChanged && (selectedItem.customId === item.customId)) {            //sets new rates to rent items in stepper 2 when its new rate is approved
                            selectedItem.itemStartDate = new Date(item.itemStartDate);
                            selectedItem.itemEndDate = new Date(item.itemEndDate);
                            selectedItem.rate = item.rate;
                            selectedItem.cost = item.cost;
                            selectedItem.days = item.days;
                        }
                    })
                })
                this.eEmitInvoiceRentItems.emit(this._selectedRentRows);
                this.setRentGridGlobalItems();
                this.rentGridViewChildRef.refresh();

                this.rentUnsub.next(true);
                this.getPrintingItems();
            }
        }));
    }

    getPrintingItems() {
        this.subscription.push(this.billingActionDispatcher.printingItems.pipe(takeUntil(this.printUnsub)).subscribe((printingItems) => {
            if (printingItems !== undefined && printingItems !== null) {
                printingItems['data'].forEach((item) => {
                    item.serviceDate = new Date(item.serviceDate);
                });
                this.printingItems = printingItems['data'];
                this.printUnsub.next(true);
                this.printingItemsWrapper = [];
                this.printingItems.forEach((item) => {
                    this.printingItemsWrapper.push(new AdditionalItemWrapper().setWrapper(item));
                });
                this.getMountingItems();
            }
        }));
    }

    getMountingItems() {
        this.subscription.push(this.billingActionDispatcher.mountingItems.pipe(takeUntil(this.mountUnsub)).subscribe((mountingItems) => {
            if (mountingItems !== undefined && mountingItems !== null) {
                mountingItems['data'].forEach((item) => {
                    item.serviceDate = new Date(item.serviceDate);
                });
                this.mountingItems = mountingItems['data'];
                this.mountUnsub.next(true);
                this.mountingItemsWrapper = [];
                this.mountingItems.forEach((item) => {
                    this.mountingItemsWrapper.push(new AdditionalItemWrapper().setWrapper(item));
                });
                this.setAllItems();
            }
        }));
    }

    setAllItems() {
        if (this._selectedRentRows.length > 0 && !this.showSelected) {
            this.setSelectedRentRows();
        }
        if (this.rentGridViewChildRef !== undefined) {
            this.rentGridViewChildRef.refresh();
        }
        this.billingActionDispatcher.resetRentItems();
        if (this._selectedMountingRows.length > 0 && !this.showSelected) {
            this.setSelectedMountingRows();
        }
        if (this.mountingGridViewChildRef !== undefined) {
            this.mountingGridViewChildRef.refresh();
        }
        this.billingActionDispatcher.resetMountingItems();
        if (this._selectedPrintingRows.length > 0 && !this.showSelected) {
            this.setSelectedPrintingRows();
        }
        if (this.printingGridViewChildRef !== undefined) {
            this.printingGridViewChildRef.refresh();
        }
        this.billingActionDispatcher.resetPrintingItems();
        this.isLoading = false;
    }

    setSelectedRentRows() {
        const rows = _.cloneDeep(this._selectedRentRows);
        rows.forEach((row) => {
            row.itemStartDate = new Date(row.itemStartDate);
            row.itemEndDate = new Date(row.itemEndDate);
        });
        this._selectedRentRows = [];
        rows.forEach((row) => {
            this.rentItems.forEach((item) => {
                item.itemStartDate = new Date(new Date(item.itemStartDate));
                item.itemEndDate = new Date(new Date(item.itemEndDate));
                if (row.customId === item.customId && new Date(this.calculationService.setToBeginning(row.validationItemStartDate)).valueOf() >= new Date(this.calculationService.setToBeginning(item.validationItemStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.validationItemEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(item.validationItemEndDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.itemStartDate)).valueOf() >= new Date(this.calculationService.setToBeginning(item.itemStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.itemEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(item.itemEndDate)).valueOf()) {
                    row.validationItemStartDate = new Date(item.validationItemStartDate);
                    row.validationItemEndDate = new Date(item.validationItemEndDate);
                    this.rentGridViewChildRef.selected.push(item);
                    this.eEmitChangeSelectedRentItemsValidations.emit(row);
                }
            });
        });
    }

    setSelectedPrintingRows() {
        const rows = _.cloneDeep(this._selectedPrintingRows);
        this._selectedPrintingRows = [];
        rows.forEach((row) => {
            this.printingItemsWrapper.forEach((item) => {
                if (row.customId === item.customId && new Date(this.calculationService.setToBeginning(row.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(item.serviceDate)).valueOf() && row.creative === item.creative) {
                    this.printingGridViewChildRef.selected.push(item);
                }
            });
        });
    }

    setSelectedMountingRows() {
        const rows = _.cloneDeep(this._selectedMountingRows);
        this._selectedMountingRows = [];
        rows.forEach((row) => {
            this.mountingItemsWrapper.forEach((item) => {
                if (row.customId === item.customId && new Date(this.calculationService.setToBeginning(row.serviceDate)).valueOf() === new Date(this.calculationService.setToBeginning(item.serviceDate)).valueOf() && row.creative === item.creative) {
                    this.mountingGridViewChildRef.selected.push(item);
                }
            });
        });
    }



    /**
     * to initially set the billing grid
     *
     * @memberof SuperbillHoardingsComponent
     */
    setInintialBillingGrid() {
        this.rentMinDate = new Date(this.campaign.campaignStartDate);
        this.rentMaxDate = new Date(this.campaign.campaignEndDate);
        this.billingGrid.campaignId = this.campaignId;
        this.billingGrid.itemStartDate = new Date(this.campaign.campaignStartDate);
        this.billingGrid.itemEndDate = new Date(this.campaign.campaignEndDate);
        this.billingGrid.billed = false;
        this.billingGrid.eligibleToGenBill = true;
        this.detectChanges();
        this.setGrids(this.billingGrid);
    }



    /**
    * to upload the PO
    *
    * @memberof SuperbillHoardingsComponent
    */
    uploadPO() {
        this.displayUploadPODialog = true;
    }

    /**
     * to hide the PO dialog
     *
     * @memberof SuperbillHoardingsComponent
     */
    hideUploadPODialog() {
        this.displayUploadPODialog = false;
    }


    /**
     * to refresh all the grids
     *
     * @memberof SuperbillHoardingsComponent
     */
    refreshAllGrids() {
        this.rentGridViewChildRef.refresh();
        this.printingGridViewChildRef.refresh();
        this.mountingGridViewChildRef.refresh();
        this.detectChanges();
    }


    /**
     * to reinitialize all grids with different config properties
     *
     * @memberof SuperbillHoardingsComponent
     */
    reInitializeAllGrids() {
        this.rentGridViewChildRef.ngOnInit();
        this.printingGridViewChildRef.ngOnInit();
        this.mountingGridViewChildRef.ngOnInit();
        this.detectChanges();
    }

    detectChanges() {
        if (this.changeDetectorRef) {
            this.changeDetectorRef.detectChanges();
        }
    }

    onRentGridRowSelect(event) {
        this._selectedRentRows = event;
        this._selectedRentRows.forEach((row) => {
            row.itemStartDate = new Date(row.itemStartDate);
            row.itemEndDate = new Date(row.itemEndDate);
        });
        if (this._selectedRentRows.length !== 0) {
            this.startDate = this.minDate(this._selectedRentRows);
            this.endDate = this.maxDate(this._selectedRentRows);
        } else {
            this.startDate = null;
            this.endDate = null;
        }
        this.eEmitRentRowsSelected.emit(event);
        this.eEmitDates.emit({ startDate: this.startDate, endDate: this.endDate });
    }

    onPrintingGridRowSelect(event) {
        this._selectedPrintingRows = event;
        this.eEmitPrintingRowsSelected.emit(event);
    }

    onMountingGridRowSelect(event) {
        this._selectedMountingRows = event;
        this.eEmitMountingRowsSelected.emit(event);
    }

    minDate(rows) {
        let minDate: Date = rows[0].itemStartDate;
        for (let i = 0; i < rows.length; i++) {
            if (minDate > rows[i].itemStartDate) {
                minDate = new Date(rows[i].itemStartDate);
            }
        }
        return new Date(minDate);
    }

    maxDate(rows) {
        let maxDate: Date = rows[0].itemEndDate;
        for (let i = 0; i < rows.length; i++) {
            if (maxDate < rows[i].itemEndDate) {
                maxDate = new Date(rows[i].itemEndDate);
            }
        }
        return new Date(maxDate);
    }

    onRentRowEdit(event) {
        this.rowData = event.rowData;
        this.rowIndex = event.rowIndex;
        this.rowData.globalRateSet = false;
        this.globalRate = null;
        const days = this.calculationService.calculateDays(this.rowData.itemStartDate, this.rowData.itemEndDate);
        if (event.column.field === "rate") {
            if (utils.testForDecimals(this.rowData.rate)) {
                this.rowData.rate = utils.parseValueToDecimalTwoPlaces(this.rowData.rate);
            }
            this.rowData.cost = utils.parseValueToDecimalTwoPlaces(this.calculationService.calculateCostOfHoarding(this.rowData.rate, days));
        }
        if (event.column.field === "cost") {
            if (utils.testForDecimals(this.rowData.cost)) {
                this.rowData.cost = utils.parseValueToDecimalTwoPlaces(this.rowData.cost);
            }
            this.rowData.rate = utils.parseValueToDecimalTwoPlaces(this.calculationService.calculateRateOfHoarding(this.rowData.cost, days));
        }
        this.setGlobalTotal();
        this.eEmitEditedRentItems.emit(this.rowData);
    }

    onRentRowNoteChange(event) {
        this.rowData = event.rowData;
        this.rowIndex = event.rowIndex;
        this.eEmitEditedRentItems.emit(this.rowData);
    }

    onPrintingRowEdit(event) {
        this.rowData = event.rowData;
        this.rowIndex = event.rowIndex;
        this.rowData.isGlobalSet = false;
        if (event.column.field === "printingCost") {
            if (utils.testForDecimals(this.rowData.printingCost)) {
                this.rowData.printingCost = utils.parseValueToDecimalTwoPlaces(this.rowData.printingCost);
                if (HoardingLightEnum[this.rowData.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[this.rowData.lightType] === HoardingLightEnum.N_L) {
                    this.rowData.printingPrice.flNlPrice = this.rowData.printingCost;
                    if (MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                        this.globalHoardingFlNlPrice = 0;
                    } else {
                        this.globalKioskFlNlPrice = 0;
                    }
                }
                if (HoardingLightEnum[this.rowData.lightType] === HoardingLightEnum.B_L) {
                    this.rowData.printingPrice.blPrice = this.rowData.printingCost;
                    if (MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                        this.globalHoardingBlPrice = 0;
                    } else {
                        this.globalKioskBlPrice = 0;
                    }
                }
            }
            this.rowData.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(this.rowData.printingCost, this.rowData.squareFeet)));
        }
        if (event.column.field === "amount") {
            if (utils.testForDecimals(this.rowData.amount)) {
                this.rowData.amount = utils.parseValueToDecimalTwoPlaces(this.rowData.amount);
            }
            this.rowData.printingCost = utils.parseValueToDecimalTwoPlaces(Number(utils.parseValueToDecimalTwoPlaces(this.rowData.amount) / this.rowData.squareFeet));
            if (HoardingLightEnum[this.rowData.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[this.rowData.lightType] === HoardingLightEnum.N_L) {
                this.rowData.printingPrice.flNlPrice = this.rowData.printingCost;
                if (MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                    this.globalHoardingFlNlPrice = 0;
                } else {
                    this.globalKioskFlNlPrice = 0;
                }
            }
            if (HoardingLightEnum[this.rowData.lightType] === HoardingLightEnum.B_L) {
                this.rowData.printingPrice.blPrice = this.rowData.printingCost;
                if (MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                    this.globalHoardingBlPrice = 0;
                } else {
                    this.globalKioskBlPrice = 0;
                }
            }
            // this.rowData.printingCost = utils.parseValueToDecimalTwoPlaces(Number(utils.parseValueToDecimalTwoPlaces(this.rowData.amount) / this.rowData.squareFeet));
        }
        if (this.rowData.printingPrice.flNlPrice !== 0) {
            this.rowData.printingPrice.flNlPrice = utils.parseValueToDecimalTwoPlaces(this.rowData.printingCost);
        } else if (this.rowData.printingPrice.blPrice !== 0) {
            this.rowData.printingPrice.blPrice = utils.parseValueToDecimalTwoPlaces(this.rowData.printingCost);
        }
        // if (this.rowData.lightType)
        this.eEmitEditedPrintingItems.emit(this.rowData);
    }

    onPrintRowNoteChange(event) {
        this.rowData = event.rowData;
        this.rowIndex = event.rowIndex;
        this.eEmitEditedPrintingItems.emit(this.rowData);
    }

    onMountingRowEdit(event) {
        this.rowData = event.rowData;
        this.rowIndex = event.rowIndex;
        this.rowData.isGlobalSet = false;
        if (event.column.field === "mountingCost") {
            if (utils.testForDecimals(this.rowData.mountingCost)) {
                this.rowData.mountingCost = utils.parseValueToDecimalTwoPlaces(this.rowData.mountingCost);
                this.rowData.mountingPrice.pricePerSqFt = this.rowData.mountingCost;
                if (MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                    this.globalHoardingMountingPrice = 0;
                } else {
                    this.globalKioskMountingPrice = 0;
                }
            }
            this.rowData.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(this.rowData.mountingCost, this.rowData.squareFeet)));
        }
        if (event.column.field === "amount") {
            if (utils.testForDecimals(this.rowData.amount)) {
                this.rowData.amount = utils.parseValueToDecimalTwoPlaces(this.rowData.amount);
            }
            this.rowData.mountingCost = utils.parseValueToDecimalTwoPlaces(Number(utils.parseValueToDecimalTwoPlaces(this.rowData.amount) / this.rowData.squareFeet));
            this.rowData.mountingPrice.pricePerSqFt = this.rowData.mountingCost;
            if (MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[this.rowData.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP) {
                this.globalHoardingMountingPrice = 0;
            } else {
                this.globalKioskMountingPrice = 0;
            }
        }
        if (this.rowData.mountingPrice.pricePerSqFt !== 0) {
            this.rowData.mountingPrice.pricePerSqFt = utils.parseValueToDecimalTwoPlaces(this.rowData.mountingCost);
        } else if (this.rowData.mountingPrice.pricePerUnit !== 0) {
            this.rowData.mountingPrice.pricePerUnit = utils.parseValueToDecimalTwoPlaces(this.rowData.mountingCost);
        }
        this.eEmitEditedMountingItems.emit(this.rowData);
    }

    onMountRowNoteChange(event) {
        this.rowData = event.rowData;
        this.rowIndex = event.rowIndex;
        this.eEmitEditedMountingItems.emit(this.rowData);
    }

    onRentCalenderChange(event) {
        this.validateRentDates(event);
        this.rowData = event.rowData;
        this.rowIndex = event.rowIndex;
        if (new Date(this.calculationService.setToBeginning(this.rowData.itemStartDate)).valueOf() > new Date(this.calculationService.setToBeginning(this.rowData.itemEndDate)).valueOf()) {
            this.rowData.itemEndDate = new Date(this.rowData.itemStartDate);
        }
        this.rowData.days = this.calculationService.calculateDays(this.rowData.itemStartDate, this.rowData.itemEndDate);
        this.rowData.cost = this.calculationService.calculateCostOfHoarding(this.rowData.rate, this.rowData.days);
        this.setGlobalTotal();
        this.startDate = this.minDate(this._selectedRentRows);
        this.endDate = this.maxDate(this._selectedRentRows);
        this.rowData.isGlobalSet = false;
        this.globalStartDate = null;
        this.globalEndDate = null;
        this.eEmitEditedRentItems.emit(this.rowData);
        this.eEmitDates.emit({ startDate: this.startDate, endDate: this.endDate });
    }

    validateRentDates(event) {
        this.validateWithItemStartAndEndDate(event);
    }

    validateWithItemStartAndEndDate(data) {
        const minDate: Date = new Date(data.rowData.validationItemStartDate);
        const maxDate: Date = new Date(data.rowData.validationItemEndDate);
        this.validateDate(data, minDate, maxDate);
    }

    validateDate(data, minDate, maxDate) {
        if (new Date(this.calculationService.setToBeginning(data.rowData.itemStartDate)).valueOf() >= new Date(this.calculationService.setToBeginning(minDate)).valueOf() && new Date(this.calculationService.setToBeginning(data.rowData.itemStartDate)).valueOf() <= new Date(this.calculationService.setToBeginning(maxDate)).valueOf()) {
            // do nothing
        } else {
            data.rowData.itemStartDate = new Date(minDate);
        }
        if (new Date(this.calculationService.setToBeginning(data.rowData.itemEndDate)).valueOf() >= new Date(this.calculationService.setToBeginning(minDate)).valueOf() && new Date(this.calculationService.setToBeginning(data.rowData.itemEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(maxDate)).valueOf()) {
            // do nothing
        } else {
            data.rowData.itemEndDate = new Date(maxDate);
        }
    }


    /**
     * to change the value of editable
     * 
     * @memberof SuperbillHoardingsComponent
     */
    setColums() {
        this.rentColumns.forEach((col) => {
            switch (col.field) {
                case 'rate':
                case 'cost':
                case 'itemStartDate':
                case 'itemEndDate': {
                    col.editable = this._editable;
                    break;
                }
                case 'note': {
                    col.editable = this._editable;
                    col.hidden = !this._editable;
                }
            }
        });
        this.printingColumns.forEach((col) => {
            switch (col.field) {
                case 'printingCost':
                case 'amount': {
                    col.editable = this._editable;
                    break;
                }
                case 'note': {
                    col.editable = this._editable;
                    col.hidden = !this._editable;
                }
            }
        });
        this.mountingColumns.forEach((col) => {
            switch (col.field) {
                case 'mountingCost':
                case 'amount': {
                    col.editable = this._editable;
                    break;
                }
                case 'note': {
                    col.editable = this._editable;
                    col.hidden = !this._editable;
                }
            }
        });
        this.reInitializeAllGrids();
    }

    validateGlobalDates() {
        this.validateWithCampaignRange();
        if (this.globalStartDate) {
            this.onGlobalStartDateChange();
        }
        if (this.globalEndDate) {
            this.onGlobalEndDateChange();
        }
        this.setGlobalTotal();
    }

    validateWithCampaignRange() {
        if (this.globalStartDate) {
            if (new Date(this.calculationService.setToBeginning(this.globalStartDate)).valueOf() >= new Date(this.calculationService.setToBeginning(this.campaign.campaignStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(this.globalStartDate)).valueOf() <= new Date(this.calculationService.setToBeginning(this.campaign.campaignEndDate)).valueOf()) {
                // do nothing
            } else {
                this.globalStartDate = null;
            }
        }
        if (this.globalEndDate) {
            if (this.globalStartDate) {
                if (new Date(this.calculationService.setToBeginning(this.globalEndDate)).valueOf() >= new Date(this.calculationService.setToBeginning(this.globalStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(this.globalEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(this.campaign.campaignEndDate)).valueOf()) {
                    // do nothing
                } else {
                    this.globalEndDate = null;
                }
            } else {
                if (new Date(this.calculationService.setToBeginning(this.globalEndDate)).valueOf() >= new Date(this.calculationService.setToBeginning(this.campaign.campaignStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(this.globalEndDate)).valueOf() <= new Date(this.calculationService.setToBeginning(this.campaign.campaignEndDate)).valueOf()) {
                    // do nothing
                } else {
                    this.globalEndDate = null;
                }
            }
        }
    }

    onGlobalStartDateChange() {
        this._selectedRentRows.forEach((row) => {
            if (new Date(this.calculationService.setToBeginning(row.validationItemStartDate)).valueOf() <= new Date(this.calculationService.setToBeginning(this.globalStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.validationItemEndDate)).valueOf() >= new Date(this.calculationService.setToBeginning(this.globalStartDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.itemEndDate)).valueOf() >= new Date(this.calculationService.setToBeginning(this.globalStartDate)).valueOf()) {
                row.itemStartDate = new Date(this.globalStartDate);
                row.days = this.calculationService.calculateDays(row.itemStartDate, row.itemEndDate);
                row.cost = this.calculationService.calculateCostOfHoarding(row.rate, row.days);
                row.isGlobalSet = true;
                this.eEmitEditedRentItems.emit(row);
            }
        });
        this.startDate = this.minDate(this._selectedRentRows);
        this.endDate = this.maxDate(this._selectedRentRows);
        this.eEmitDates.emit({ startDate: this.startDate, endDate: this.endDate });
    }

    onGlobalEndDateChange() {
        this._selectedRentRows.forEach((row) => {
            if (new Date(this.calculationService.setToBeginning(row.validationItemEndDate)).valueOf() >= new Date(this.calculationService.setToBeginning(this.globalEndDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.validationItemStartDate)).valueOf() <= new Date(this.calculationService.setToBeginning(this.globalEndDate)).valueOf() && new Date(this.calculationService.setToBeginning(row.itemStartDate)).valueOf() <= new Date(this.calculationService.setToBeginning(this.globalEndDate)).valueOf()) {
                row.itemEndDate = new Date(this.globalEndDate);
                row.days = this.calculationService.calculateDays(row.itemStartDate, row.itemEndDate);
                row.cost = this.calculationService.calculateCostOfHoarding(row.rate, row.days);
                row.isGlobalSet = true;
                this.eEmitEditedRentItems.emit(row);
            }
        });
        this.startDate = this.minDate(this._selectedRentRows);
        this.endDate = this.maxDate(this._selectedRentRows);
        this.eEmitDates.emit({ startDate: this.startDate, endDate: this.endDate });
    }

    validateGlobalRate(event, pattern) {
        const result = pattern.test(event.target.value);
        if (result) {
            const twoPlaceResult = this.twoPlaceDecimalNumbers.test(event.target.value); // to test for 2 decimal places
            if (twoPlaceResult) {
                this.globalRate = utils.parseValueToDecimalTwoPlaces(this.globalRate);
                this.setRatesForEachInventory();
            } else {
                event.target.value = utils.parseValueToDecimalTwoPlaces(event.target.value);
                this.validateGlobalRate(event, pattern);
            }
        } else {
            if (String(event.target.value).includes('.')) {
                const parsedInputArray = String(event.target.value).split('.');
                if (parsedInputArray.length > 2) {
                    let fractionalPart = parsedInputArray[1];
                    if (fractionalPart.length > 2) {
                        fractionalPart = String(fractionalPart).substr(0, 2);
                    }
                    event.target.value = Number(parsedInputArray[0] + '.' + parsedInputArray[1]);
                    this.validateGlobalRate(event, pattern);
                }
            }
        }
    }

    validateGlobalTotal(event, pattern) {
        const result = pattern.test(event.target.value);
        if (result) {
            const twoPlaceResult = this.twoPlaceDecimalNumbers.test(event.target.value); // to test for 2 decimal places
            if (twoPlaceResult) {
                this.globalTotal = utils.parseValueToDecimalTwoPlaces(this.globalTotal);
                this.setCostForEachInventory();
            } else {
                event.target.value = utils.parseValueToDecimalTwoPlaces(event.target.value);
                this.validateGlobalTotal(event, pattern);
            }
        } else {
            if (String(event.target.value).includes('.')) {
                const parsedInputArray = String(event.target.value).split('.');
                if (parsedInputArray.length > 2) {
                    let fractionalPart = parsedInputArray[1];
                    if (fractionalPart.length > 2) {
                        fractionalPart = String(fractionalPart).substr(0, 2);
                    }
                    event.target.value = Number(parsedInputArray[0] + '.' + parsedInputArray[1]);
                    this.validateGlobalTotal(event, pattern);
                }
            }
        }
    }

    setRatesForEachInventory() {
        this._selectedRentRows.forEach((row, index) => {
            row.globalRateSet = true;
            row.rate = this.globalRate;
            row.cost = utils.parseValueToDecimalTwoPlaces(this.calculationService.calculateCostOfHoarding(utils.parseValueToDecimalTwoPlaces(row.rate), row.days));
            this.eEmitEditedRentItems.emit(row);
        });
        this.setGlobalTotal();
    }

    setCostForEachInventory() {
        this.globalRate = 0;
        let actualCost = 0;
        this._selectedRentRows.forEach((row) => {
            actualCost += row.cost;
        });
        const diffPctg = this.calculationService.calculateRentPctChange(actualCost, this.globalTotal);
        this._selectedRentRows.forEach((row) => {
            row.cost = this.getDecimalNum(Number(row.cost) + (Number(diffPctg) * Number(row.cost)) / 100, 2);
            row.rate = this.getDecimalNum((this.calculationService.calculateRateOfHoarding(Number(row.cost), Number(row.days))), 2);
            this.eEmitEditedRentItems.emit(row);
        });
    }

    validateGlobalPrintingMounting(event, pattern) {
        const result = pattern.test(event.target.value);
        if (result) {
            const twoPlaceResult = this.twoPlaceDecimalNumbers.test(event.target.value); // to test for 2 decimal places
            if (twoPlaceResult) {
                // this.setRatesForSelectedPrintingSites(event.target.id);
                switch (event.target.id) {
                    case 'hoardingFlnlPrice':
                    case 'hoardingBlPrice':
                    case 'kioskFlnlPrice':
                    case 'kioskBlPrice': {
                        this.setRatesForSelectedPrintingSites(event.target.id);
                        break;
                    }
                    case 'hoardingMountingPrice':
                    case 'kioskMountingPrice': {
                        this.setRatesForSelectedMountingSites(event.target.id);
                    }
                }
            } else {
                event.target.value = utils.parseValueToDecimalTwoPlaces(event.target.value);
                this.validateGlobalPrintingMounting(event, pattern);
            }
        } else {
            if (String(event.target.value).includes('.')) {
                const parsedInputArray = String(event.target.value).split('.');
                if (parsedInputArray.length > 2) {
                    let fractionalPart = parsedInputArray[1];
                    if (fractionalPart.length > 2) {
                        fractionalPart = String(fractionalPart).substr(0, 2);
                    }
                    event.target.value = Number(parsedInputArray[0] + '.' + parsedInputArray[1]);
                    this.validateGlobalPrintingMounting(event, pattern);
                }
            }
        }
    }

    setRatesForSelectedPrintingSites(id) {
        this._selectedPrintingRows.forEach((row) => {
            switch (id) {
                case 'hoardingFlnlPrice': {
                    if ((HoardingLightEnum[row.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[row.lightType] === HoardingLightEnum.N_L) && (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP)) {
                        row.isGlobalSet = true;
                        row.printingCost = utils.parseValueToDecimalTwoPlaces(this.globalHoardingFlNlPrice);
                        row.printingPrice.flNlPrice = row.printingCost;
                    }
                    row.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(row.printingCost, row.squareFeet)));
                    break;
                }
                case 'hoardingBlPrice': {
                    if ((HoardingLightEnum[row.lightType] === HoardingLightEnum.B_L) && (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP)) {
                        row.isGlobalSet = true;
                        row.printingCost = utils.parseValueToDecimalTwoPlaces(this.globalHoardingBlPrice);
                        row.printingPrice.blPrice = row.printingCost;
                    }
                    row.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(row.printingCost, row.squareFeet)));
                    break;
                }
                case 'kioskFlnlPrice': {
                    if ((HoardingLightEnum[row.lightType] === HoardingLightEnum.F_L || HoardingLightEnum[row.lightType] === HoardingLightEnum.N_L) && (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.KIOSK)) {
                        row.isGlobalSet = true;
                        row.printingCost = utils.parseValueToDecimalTwoPlaces(this.globalKioskFlNlPrice);
                        row.printingPrice.flNlPrice = row.printingCost;
                    }
                    row.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(row.printingCost, row.squareFeet)));
                    break;
                }
                case 'kioskBlPrice': {
                    if ((HoardingLightEnum[row.lightType] === HoardingLightEnum.B_L) && (MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.KIOSK)) {
                        row.isGlobalSet = true;
                        row.printingCost = utils.parseValueToDecimalTwoPlaces(this.globalKioskBlPrice);
                        row.printingPrice.blPrice = row.printingCost;
                    }
                    row.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(row.printingCost, row.squareFeet)));
                    break;
                }
            }
            this.eEmitEditedPrintingItems.emit(row);
        });
    }

    setRatesForSelectedMountingSites(id) {
        this._selectedMountingRows.forEach((row) => {
            switch (id) {
                case 'hoardingMountingPrice': {
                    if ((MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING || MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.HOARDING_GROUP)) {
                        row.isGlobalSet = true;
                        row.mountingCost = utils.parseValueToDecimalTwoPlaces(this.globalHoardingMountingPrice);
                        row.mountingPrice.pricePerSqFt = row.mountingCost;
                    }
                    row.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(row.mountingCost, row.squareFeet)));
                    break;
                }
                case 'kioskMountingPrice': {
                    if ((MediaSubCategoryEnum[row.mediaSubCategory] === MediaSubCategoryEnum.KIOSK)) {
                        row.isGlobalSet = true;
                        row.mountingCost = utils.parseValueToDecimalTwoPlaces(this.globalKioskMountingPrice);
                        row.mountingPrice.pricePerSqFt = row.mountingCost;
                    }
                    row.amount = utils.parseValueToDecimalTwoPlaces(Number(utils.multiplyDecimals(row.mountingCost, row.squareFeet)));
                    break;
                }
            }
            this.eEmitEditedMountingItems.emit(row);
        });
    }

    /**
     *setting the min and max date and campaign
     *called from bill view component
     * @memberof SuperbillHoardingsComponent
     */
    setMinAndMaxDateForBillingGrid(billingGrid, campaign) {
        this.rentMinDate = new Date(campaign.campaignStartDate);
        this.rentMaxDate = new Date(campaign.campaignEndDate);
    }

    /* Getting decimal point in Number datatype
    *  @param  {} source
    */
    getDecimalNum(target, decimal) {
        // return Number(target.toFixed(decimal));

        return Number(Math.fround(target).toFixed(decimal));
    }

    onRentGridRowEdit(event) {
        this.eRentRowEditGridChange.emit(event);
    }

    onRentGridNumberRowEdit(event) {
        this.eRentRowEditNumberGridChange.emit(event);
    }

    onRentGridCalendarChange(event) {
        this.eRentCalenderGridChange.emit(event);
    }

    onPrintGridRowEdit(event) {
        this.ePrintRowEditGridChange.emit(event);
    }

    onPrintGridNumberRowEdit(event) {
        this.ePrintRowEditNumberGridChange.emit(event);
    }

    onMountGridRowEdit(event) {
        this.eMountRowEditGridChange.emit(event);
    }

    onMountGridNumberRowEdit(event) {
        this.eMountRowEditNumberGridChange.emit(event);
    }

    getChangedType() {
        if (!this.migratedInvoice) {
            return ActivityLogModuleEnum.INVOICE;
        } else {
            return ActivityLogModuleEnum.MIGRATED_INVOICE;
        }
    }

    /**
     * @description extend days from start Date to generate bill 
     * @author Divya Sachan
     * @date 2019-10-16
     * @param {*} noOfDays
     * @memberof SuperbillHoardingsComponent
     */
    extendDays(noOfDays) {
        this.itemCountAfterValidationForExtendDays = 0;
        this.globalTotal = 0;
        if (this._selectedRentRows) { // if rent rows aelected
            this._selectedRentRows.forEach((rentRow) => {
                if (noOfDays) { // set item end date if days exists
                    rentRow.itemEndDate = new Date(this.calculationService.extendDate(rentRow.itemStartDate, Number(noOfDays) - 1));
                } else { // Otherwise
                    rentRow.itemEndDate = new Date(rentRow.validationItemEndDate);
                }

                // if after extending the days the end date exceeds campaign end date then set it.
                if (new Date(rentRow.itemEndDate).valueOf() > new Date(rentRow.validationItemEndDate).valueOf()) {
                    rentRow.itemEndDate = new Date(rentRow.validationItemEndDate);
                    this.itemCountAfterValidationForExtendDays += 1;
                }
                rentRow.days = this.calculationService.calculateDays(rentRow.itemStartDate, rentRow.itemEndDate); // no of days calculation
                rentRow.cost = this.calculationService.calculateCostOfHoarding(rentRow.rate, rentRow.days); // cost calculation
                this.eEmitEditedRentItems.emit(rentRow);
            });
            this.setRentGridGlobalItems(); // set global items
            this.startDate = this.minDate(this._selectedRentRows);
            this.endDate = this.maxDate(this._selectedRentRows);
            this.eEmitDates.emit({ startDate: this.startDate, endDate: this.endDate });
            this.eEmitNoOfDays.emit(noOfDays);
        }
        this.showNotificationForExtendDays(); // show notification
    }

    /**
     * @description show notification if after extending the days the end date exceeds campaign end date
     * @author Divya Sachan
     * @date 2019-10-16
     * @memberof SuperbillHoardingsComponent
     */
    showNotificationForExtendDays() {
        if (this.itemCountAfterValidationForExtendDays > 0) { // show message if after extending the days the end date exceeds campaign end date
            this.notificationService.info('unbilled days for few item(s) are less than selected no of days.', 'Number of Days')
        }
    }

    ngOnDestroy() {
        this.subscription.forEach((s) => {
            s.unsubscribe();
        });
        this.rentUnsub.unsubscribe();
        this.printUnsub.unsubscribe();
        this.mountUnsub.unsubscribe();
    }
}


