/**
* @file billing-notes.component
* @author pulkitb@meditab.com
* @copyright Meditab Software 2018
*/


import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { Invoice } from "../../../modals/billings/invoice";
import * as _ from 'lodash';

@Component({
    selector: 'sib-billing-notes',
    templateUrl: './billing-notes.component.html',
    styleUrls: ['./billing-notes.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BillingNotesComponent implements OnInit {

    isloading = false;

    @Input() displayBillingNotes = false;
    @Input() invoice: Invoice = new Invoice();

    @Output() displayReset: EventEmitter<boolean> = new EventEmitter();


    ngOnInit() { }

    showDialog() { }

    hideDialog() {
        this.displayBillingNotes = false;
        this.displayReset.emit(false);
    }

}
